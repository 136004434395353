<template>
  <div>
    <template v-if="hasClever">
      <RailsForm method="post" :action="Routes.clever_login_path({ district_id: districtId })">
        <button type="submit" :aria-label="$t('login_via_external.clever_login')" class="btn btn--big btn--clever">
          <LzIcon size="lg" path="icons/clever" />
          {{ $t('login_via_external.clever_login') }}
        </button>
      </RailsForm>
    </template>
    <template v-if="hasSingleIntegration">
      <LzButton
        v-if="isAuthOneRoster"
        tag="a"
        color="green"
        size="big"
        :href="authPreferenceOptions.buttonUrl"
        class="w-full text-center font-bold"
      >
        <LzIcon path="icons/share" />
        <span>{{ authPreferenceOptions.buttonName }}</span>
      </LzButton>
      <a v-else :href="$t('login_via_external.classlink_login_url')" class="inline-block">
        <span class="sr-only">{{ $t('login_via_external.classlink_login') }}</span>
        <ImageTag alt="" path="classlink/classlink-login-small.png" style="width: 250px" />
      </a>
    </template>
  </div>
</template>

<script>
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import ImageTag from 'vue_features/shared/components/ui/ImageTag'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { shared } from 'vue_features/shared/i18n'
import { RailsForm } from 'vue_features/shared/components/forms'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'
import LzButton from 'vue_features/shared/components/ui/LzButton'

export default {
  name: 'LoginViaExternal',
  components: { LzButton, RailsForm, LzIcon, ImageTag },
  i18n: { messages: namespaceLocaleObj(shared, '', { only: ['login_via_external'] }) },
  setup() {
    const { authPreferenceOptions, hasSingleIntegration, hasClever, districtId, isAuthOneRoster } =
      useCurrentCustomerStore()
    return { Routes, authPreferenceOptions, hasSingleIntegration, hasClever, districtId, isAuthOneRoster }
  },
}
</script>
