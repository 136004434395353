<template>
  <div class="listable-editor h-fit w-full px-1">
    <div v-if="isMoving" class="drag-cover" />
    <Ckeditor :content="content" :options="ckeditorOptions" @change="updateBlock" />
  </div>
</template>

<script>
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import { useMovingState } from '../composables/use_draggable_blocks'

export default {
  name: 'HtmlEmbedEditor',
  components: { Ckeditor },
  props: {
    content: { type: String, required: true },
    editorId: { type: String, required: true },
    startupFocus: { type: Boolean, default: false },
  },
  setup() {
    const { isMoving } = useMovingState()
    return { isMoving }
  },
  computed: {
    ckeditorOptions() {
      return {
        toolbar: 'htmlCard',
        inline: true,
        options: { sharedSpaces: { top: this.editorId }, startupFocus: this.startupFocus },
      }
    },
  },
  methods: {
    updateBlock(content) {
      this.$emit('update-block', { content: { content } })
    },
  },
}
</script>
