<!-- eslint-disable vuejs-accessibility/form-control-has-label  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <strong class="h2">
        {{ $t('show.delete_customer.modal.heading', { customer_name: customer.name }) }}
      </strong>
      <p class="smaller">{{ $t('show.delete_customer.modal.warning') }}</p>
      <ul class="list-metadata">
        <li>
          <strong>{{ $t('show.delete_customer.modal.users') }}</strong>
          <span>{{ customer.usersCount }}</span>
        </li>
        <li>
          <strong>{{ $t('show.delete_customer.modal.lesson_plans') }}</strong>
          <span>{{ customer.lessonPlansCount }}</span>
        </li>
        <li>
          <strong>{{ $t('show.delete_customer.modal.resources') }}</strong>
          <span>{{ customer.resourcesCount }}</span>
        </li>
      </ul>
      <strong>{{ $t('show.delete_customer.modal.cannot_undo') }}</strong>
      <i18n-t tag="p" keypath="show.delete_customer.modal.confirm_subdomain" scope="global">
        <template #subdomain>
          <strong>{{ customer.subdomain }}</strong>
        </template>
      </i18n-t>
      <input v-model.trim="subdomain" type="text" />
    </template>
    <template #cancelButton="{ close }">
      <button class="btn" @click="close">
        {{ $t('show.delete_customer.modal.cancel_button') }}
      </button>
    </template>
    <template #confirmButton>
      <button
        :class="['btn btn--primary', isConfirmed ? 'caution' : 'disabled']"
        :disabled="!isConfirmed"
        @click="deleteCustomer"
      >
        {{ $t('show.delete_customer.modal.submit_button') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { location as $location } from 'utils'
import LzConfirmModal from 'vue_features/shared/components/ui/LzConfirmModal'
import { SiteAdminCustomersService } from '../api'
import Routes from 'routes'

export default {
  name: 'DeleteCustomerModal',
  components: { LzConfirmModal },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subdomain: null,
    }
  },
  computed: {
    isConfirmed() {
      return this.subdomain == this.customer.subdomain
    },
  },
  methods: {
    async deleteCustomer() {
      await SiteAdminCustomersService.deleteCustomer()
      $location.assign(Routes.root_path())
    },
  },
}
</script>
