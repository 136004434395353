<template>
  <!-- FIXME: LzConfirmModal? -->
  <LzModal :modal-id="$options.name" class="lzui-modal--confirmation" @hide-modal="hideModal">
    <h2 class="my-2">{{ confirmMsg }}</h2>
    <p>{{ infoMsg }}</p>
    <footer>
      <button class="btn" @click.stop="hideModal">{{ $t('common.cancel') }}</button>
      <AsyncContentLoader :loader="request" class="inline">
        <button class="btn btn--primary" @click="confirm">
          {{ btnMsg }}
        </button>
      </AsyncContentLoader>
    </footer>
  </LzModal>
</template>

<script>
import { AsyncContentLoader, LzModal } from 'vue_features/shared/components/ui'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'

export default {
  name: 'UserStateConfirmModal',
  components: { AsyncContentLoader, LzModal },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateUser } = useUsersIndexStore()
    return { updateUser }
  },
  data() {
    return { request: null }
  },
  computed: {
    confirmMsg() {
      if (this.user.archived) {
        return this.$t('state_modal.confirm_unarchive')
      }
      return this.$t('confirm_modal.confirm_archive_student', { student_to_archive: this.user.name })
    },
    btnMsg() {
      return this.user.archived ? this.$t('common.unarchive') : this.$t('common.archive')
    },
    infoMsg() {
      return this.user.archived ? this.$t('state_modal.unarchive_info') : this.$t('state_modal.archive_info')
    },
  },
  methods: {
    confirm() {
      const updateParams = { archived: !this.user.archived }
      this.request = this.updateUser({ userId: this.user.id, updateParams }).then(this.hideModal.bind(this))
    },
    hideModal() {
      this.$emit('close')
    },
  },
}
</script>
