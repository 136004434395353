<template>
  <a v-if="item" :href="gradingUrl" class="flex items-stretch justify-stretch">
    <span class="sr-only">{{ screenReaderText }}</span>
    <ItemScoreSquare v-if="!isAttempted" :title="`${$t('assignment_report.item_unattempted')}`" rating="unattempted">
      <svg stroke="currentColor" fill="none" width="24" height="24" viewBox="0 0 24 24">
        <circle
          cx="12"
          cy="12"
          r="11"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="3,4"
        />
      </svg>
    </ItemScoreSquare>
    <ItemScoreSquare
      v-else-if="isUnmarked"
      :title="$t('assignment_report.item_needs_grading')"
      icon="needs-grading"
      rating="needs-grading"
    />
    <ItemScoreSquare
      v-else-if="!isCompleted"
      :rating="scoreRating"
      :title="`${grade} ${$t('assignment_report.item_incomplete')}`"
    />
    <ItemScoreSquare v-else :rating="scoreRating" :title="grade" />
  </a>
</template>

<script setup>
import { computed, inject } from 'vue'
import { scoreRating as assignmentScoreRating } from '../../utils/scoring'
import Routes from 'routes'
import ItemScoreSquare from './ItemScoreSquare'

const props = defineProps({
  assignment: {
    type: Object,
    required: true,
  },
  assignmentView: {
    type: Object,
    required: true,
  },
  item: {
    type: Object,
    default: null,
  },
})

const root = inject('useRoot')()

const grade = computed(() => {
  return `${score.value || 0}/${maxScore.value || 0}`
})

const isAttempted = computed(() => {
  return props.item.attempted
})

const isCompleted = computed(() => {
  return maxScore.value === maxScoreOfAttempted.value
})

const isUnmarked = computed(() => {
  return props.item.unmarked
})

const maxScore = computed(() => {
  return props.item.maxScore
})

const maxScoreOfAttempted = computed(() => {
  return props.item.maxScoreOfAttempted
})

const score = computed(() => {
  return props.item.score
})

const scoreRating = computed(() => {
  if (isUnmarked.value) return 'ungraded'
  return assignmentScoreRating(ratio.value)
})

const ratio = computed(() => {
  return score.value / maxScore.value || 0
})

const gradingUrl = computed(() => {
  return Routes.grading_path(props.assignment.code, {
    session_id: props.assignmentView.id,
    item: props.item.ibkReference,
  })
})

const screenReaderText = computed(() => {
  if (!isAttempted.value) {
    return root.$t('assignment_report.item_unattempted')
  } else if (isUnmarked.value) {
    return root.$t('assignment_report.item_needs_grading')
  } else if (!isCompleted.value) {
    return `${grade.value} ${root.$t('assignment_report.item_incomplete')}`
  } else {
    return grade.value
  }
})
</script>
