<template>
  <div class="tile-container group flex flex-1 flex-col justify-center">
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
      :id="`copy-${type}-radio`"
      :checked="modelValue === type"
      name="copy-type"
      type="radio"
      :value="type"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div class="lzui-tile">
      <label class="align-left bg-base mb-0 rounded-t" :for="`copy-${type}-radio`">
        <h5 class="w-full py-3 pl-3">{{ $t(`action_menu.${type}_copy`) }}</h5>
      </label>
      <div class="p-0">
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions | radio operates as expected for keyborad, click added for ease -->
        <div class="bg-neutral-900 relative hidden cursor-pointer md:block" @click="$emit('update:modelValue', type)">
          <InlineSvg aria-hidden="true" class="px-4 py-8 group-hover:opacity-0" :path="`icons/copy-site-${type}`" />
          <div
            aria-hidden="true"
            :style="`backgroundImage: url(${imageUrl})`"
            class="absolute left-0 top-0 h-full w-full bg-contain bg-top bg-origin-content opacity-0 group-hover:opacity-100"
          ></div>
        </div>

        <ul class="list-none px-8 py-4 leading-loose">
          <li v-for="(bulletedItem, index) in bulletedItems" :key="bulletedItem" class="pb-2 text-xs">
            <div class="flex flex-row space-x-4">
              <span class="flex flex-grow-0 pt-1">
                <InlineSvg
                  aria-hidden="true"
                  :class="[
                    'h-5 w-5 stroke-current stroke-2',
                    unavailable.includes(index) ? 'text-red-400' : 'text-green-400',
                  ]"
                  :path="unavailable.includes(index) ? 'icons/x-circle' : 'icons/check-circle-outline'"
                />
              </span>
              <span class="flex-grow-1 flex">
                {{ bulletedItem }}
                <span class="sr-only">
                  {{ ', ' + (unavailable.includes(index) ? $t('common.nope') : $t('common.yeah')) }}
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'CopyTypeOption',
  components: { InlineSvg },
  props: {
    type: { type: String, required: true },
    imageUrl: { type: String, required: true },
    modelValue: {
      type: String,
      required: true,
    },
    unavailable: { type: Array, required: true },
  },

  setup() {
    const root = inject('useRoot')()
    const { name: customerName } = useCurrentCustomerStore()
    const bulletedItems = [
      root.$t(`action_menu.bullet1`),
      root.$t(`action_menu.bullet2`),
      root.$t(`action_menu.bullet3`),
      root.$t(`action_menu.bullet4`),
      root.$t(`action_menu.bullet5`, { name: customerName.value }),
    ]
    return { bulletedItems }
  },
}
</script>

<style scoped>
.tile-container:has(input:focus-visible) {
  @apply ring-primary-accent rounded-md ring-2 ring-offset-2;
}
</style>
