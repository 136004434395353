import messages from './messages'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { useLearnositySessionResponseStore } from 'vue_features/grading/composables/use_learnosity_session_response_store'
import { initAssignmentStore, useAssignmentShowStore, useAssignmentCardsStore } from './composables'
import { initGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'

import { createLzRouter } from 'vue_features/shared/router'
import {
  AssignmentContent,
  AssignmentShow,
  AssignmentReportContent,
  AssignmentCardsContent,
  WhoViewedThisContent,
} from './components'

export const routes = [
  {
    path: '/assignments/:id',
    component: AssignmentContent,
    children: [
      {
        path: '',
        redirect: () => {
          const { isInstructor } = useAssignmentShowStore()
          return { name: isInstructor.value ? 'report' : 'lesson' }
        },
      },
      {
        path: 'student-submissions',
        name: 'report',
        component: AssignmentReportContent,
        meta: { requiresInstructor: true },
      },
      {
        path: 'lesson',
        name: 'lesson',
        component: AssignmentCardsContent,
        props: () => {
          const { isInstructor } = useAssignmentShowStore()
          return {
            wrapperClass: 'what-students-see',
            isStudentPreview: isInstructor.value,
          }
        },
      },
      {
        path: 'assignment-views',
        name: 'who-viewed-this',
        component: WhoViewedThisContent,
        meta: { requiresInstructor: true },
      },
    ],
  },
]

export function setRouterGuards(router) {
  router.beforeEach((to, from, next) => {
    const { isInstructor } = useAssignmentShowStore()
    if (to.meta.requiresInstructor && !isInstructor.value) {
      next({ name: 'lesson', params: to.params, query: to.query })
    } else {
      next()
    }
  })
}

export default function ({ state }) {
  initGoogleClassroomStore(state.googleClassroom)
  initAssignmentStore(state.assignmentState)
  useAssignmentShowStore().$set(state.assignmentShow)
  useAssignmentReportStore().$set({ assignment: state.assignmentState.assignment, ...state.assignmentReport })
  useAssignmentCardsStore().$set({ cards: state.assignmentState.lessonPlan.cards })
  useAssignmentPromptStore().$set({ hasKlasses: state.hasKlasses })
  useLearnosityConfigStore().$set({ learnosityConfig: state.learnosityConfig })

  const sessionId = state.assignmentShow.assignmentView?.learnositySessionId
  if (sessionId && state.sessionReport?.items) {
    const validItems = state.assignmentReport.validLearnosityItems.map((item) => ({ itemReference: item.reference }))
    useLearnositySessionResponseStore().storeResponseScores(sessionId, state.sessionReport, validItems)
  }

  const router = createLzRouter({ routes })
  setRouterGuards(router)
  return { component: AssignmentShow, messages, router }
}
