<template>
  <div :data-card="card.title" tabindex="0" data-focus-anchor class="lesson-card-item relative w-full flex-wrap">
    <div class="lesson-card-item-contents relative z-10 bg-white shadow-lg">
      <div
        class="lesson-card-title border-t-10 border-t-solid flex flex-row items-center border-t border-t-white px-4 py-4 md:px-6"
      >
        <h3
          :id="`card-${card.id}`"
          class="text-md sticky top-0 z-20 my-0 inline-flex w-full font-normal transition-all md:text-lg"
        >
          <span
            class="bg-neutral-400 mr-3 inline-flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-full border border-white md:mr-4 md:h-10 md:w-10"
          >
            {{ index + 1 }}
          </span>
          <div class="flex w-full items-center overflow-hidden text-ellipsis">
            {{ card.title }}
            <LzContentStateBadge v-if="currentCardContent && !cardActive" class="ml-2" :state="currentCardState" />
          </div>
        </h3>
        <ListDropdown
          v-if="(canEditLessonPlan || canViewCardResourceInfo) && !isFullscreen"
          :id="`lesson-card-context-${index + 1}`"
          :dropdown-classes="['right-0']"
        >
          <template #actionButton="{ id, fullButtonClasses, toggle, isOpen }">
            <button
              :data-toggle="id"
              :class="fullButtonClasses"
              :aria-expanded="isOpen ? 'true' : 'false'"
              :aria-label="$t('assignment_prompt.more_options')"
              type="button"
              @click.prevent.stop="toggle"
            >
              <LzIcon path="icons/dots-vertical" size="sm" class="fill-current" />
            </button>
          </template>
          <template #listItems>
            <li v-if="canEditLessonPlan">
              <a :href="Routes.edit_lesson_plan_path(lessonPlan.id, { card: selectedCardId })">
                {{ $t('common.edit') }}
              </a>
            </li>
            <template v-if="canViewCardResourceInfo">
              <li v-if="hasViewLink">
                <a :href="Routes.resource_path(card.content.id)" lt="ignore" target="_blank" rel="nofollow">
                  {{ $t('lesson_plans.context_menu.view_resource') }}
                </a>
              </li>
              <li>
                <button @click="copyToClipboard">
                  {{ $t('lesson_plans.context_menu.copy_resource_id') }}
                </button>
              </li>
            </template>
          </template>
        </ListDropdown>
      </div>
      <div class="lesson-card relative z-20 w-full rounded-b-2xl">
        <ul class="lesson-card-content flex list-none items-start justify-center">
          <CardDeckListItem
            v-bind="{
              card,
              index,
              currentPosition: selectedCardIndex || 0,
              totalCount,
              showCards: true,
              showNotes: false,
              showTool: false,
            }"
            data-test="card-deck-item"
          />
        </ul>
      </div>
    </div>
    <LessonCardNotes
      v-if="!isStudent && !hasAssignment"
      :card="card"
      class="right-0 top-0 z-0 w-48 w-full overflow-y-auto shadow-lg transition-all sm:relative md:absolute md:right-0 md:top-0 md:h-full md:w-48 md:rounded-r-2xl lg:w-80"
      tabindex="0"
    />
  </div>
</template>

<script>
import { get } from 'lodash'
import { computed } from 'vue'
import { useCopyToClipboard } from 'vue_features/shared/composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import CardDeckListItem from 'vue_features/assignments/shared/card_deck/CardDeckListItem'
import LessonCardNotes from './LessonCardNotes'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { isQuestionCard, hasPersistedResource } from 'vue_features/shared/composables/use_card_types'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { useAssignmentStore } from 'vue_features/assignments/show/composables'
import Routes from 'routes'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'LessonCard',
  components: { LzIcon, ListDropdown, CardDeckListItem, LessonCardNotes, LzContentStateBadge },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    card: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: true,
    },
    selectedCardIndex: {
      type: Number,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isTeacherOnly = computed(() => !!get(props.card.content, 'teacherOnly'))
    const { isFullscreen } = useFullscreenStore()
    const { isStudent, authorizedAsTeacher } = useCurrentUserStore()
    const { lessonPlan } = useLessonPlanStore()
    const { isPresenting, actionMenuProps } = useLessonPlanShowStore()
    const { hasAssignment } = useAssignmentStore()
    const resourceId = computed(() => props.card.content.id)
    const { copy: copyToClipboard } = useCopyToClipboard(resourceId)

    const canEditLessonPlan = actionMenuProps.value.actions ? actionMenuProps.value.actions.canEditLessonPlan : false
    const totalCount = computed(() => props.cards.length)

    const currentCardContent = computed(() => props.card.content)
    const currentCardState = computed(() => (currentCardContent.value ? currentCardContent.value.state : null))
    const selectedCard = computed(() => props.cards[props.selectedCardIndex])
    const cardActive = computed(() => isActive(currentCardState))
    const selectedCardId = computed(() => selectedCard.value?.id)
    const canViewCardResourceInfo = computed(
      () => authorizedAsTeacher.value && !props.isPreview && hasPersistedResource(props.card),
    )
    const hasViewLink = computed(() => !isQuestionCard(props.card))

    return {
      Routes,
      isFullscreen,
      isTeacherOnly,
      isStudent,
      selectedCardId,
      canViewCardResourceInfo,
      hasViewLink,
      totalCount,
      lessonPlan,
      canEditLessonPlan,
      currentCardContent,
      currentCardState,
      cardActive,
      isPresenting,
      hasAssignment,
      copyToClipboard,
    }
  },
}
</script>

<style>
.lesson-card-content .slide-viewer-feature {
  width: 100%;
  height: auto;
}

.lesson-card-item .card__resource-contents {
  @apply flex items-center justify-center;
}

.lesson-card-content > h2 {
  align-self: center;
}

.lesson-card-content > div {
  width: 100%;
}

.lesson-card-content .card__content-wrapper {
  border: none;
  padding-bottom: 0;
}
.lesson-card-title {
  background-color: rgb(244 246 254 / 0.925);
  font-size: 1.33em;
}
.lesson-card-item .card__content-wrapper .btn--pill {
  top: -1.5rem !important;
}
.lesson-card-content .card-deck-list-item-grid {
  height: 100%;
}
@media print {
  .lesson-card-item-contents .card__content {
    background: none;
  }

  .lesson-card-content .card-deck-list-item-grid {
    overflow: auto;
    max-height: none;
  }
  .lesson-card-item-contents #slide-viewer-feature {
    height: 100% !important;
    transform: none !important;
    width: 100% !important;
    display: flex !important;
  }
}
</style>
