<!--
  eslint-disable vuejs-accessibility/form-control-has-label
  eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <tr v-if="!navigationOption._destroy">
    <td>
      <input
        v-if="navigationOption.isCustom"
        v-model="navigationOption.key"
        :disabled="!isEditing"
        type="text"
        @change="syncNavigationOption"
      />
      <p v-if="navigationOption.isHome" class="mb-0 pl-2">
        {{ $t('common.home') }}
      </p>
      <span v-if="errors.emptyKey || errors.duplicateKey" class="error--inline">
        {{ errors.emptyKey || errors.duplicateKey }}
      </span>
    </td>
    <td>
      <input v-model="navigationOption.url" :disabled="!isEditing" type="text" @change="syncNavigationOption" />
      <span v-if="errors.invalidUrl" class="error--inline">
        {{ errors.invalidUrl }}
      </span>
    </td>
    <td>
      <div v-if="navigationOption.isCustom" class="switch-toggle">
        <input
          :id="navigationOption.id"
          v-model="navigationOption.enabled"
          :disabled="!isEditing"
          type="checkbox"
          class="switch-toggle__button"
          @change="syncNavigationOption"
        />
        <label>{{ $t('show.navigation.hide_toggle') }}</label>
        <label>{{ $t('show.navigation.show_toggle') }}</label>
      </div>
    </td>
    <td>
      <button
        v-if="navigationOption.isCustom"
        :disabled="!isEditing"
        class="btn btn--small"
        @click="markReadyForDestroy(navigationOption)"
      >
        {{ $t('common.remove') }}
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'NavigationOptionRow',
  props: {
    navOptionProp: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      navigationOption: this.navOptionProp,
    }
  },
  watch: {
    navOptionProp(updatedNavOptionProp) {
      this.navigationOption = updatedNavOptionProp
    },
  },
  methods: {
    syncNavigationOption() {
      this.$emit('update:navOptionProp', this.navigationOption)
    },
    markReadyForDestroy(navigationOption) {
      navigationOption['_destroy'] = true
      this.syncNavigationOption()
    },
  },
}
</script>
