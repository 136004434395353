<script setup>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import DeleteObjectModal from 'vue_features/action_menu/components/DeleteObjectModal'
import PublishRevisionModal from './PublishRevisionModal'
import ChangelogModal from './ChangelogModal'
import MenuButton from 'vue_features/action_menu/components/MenuButton'
import PrintDialog from 'vue_features/print_settings/components/PrintDialog'
import ActionPrint from 'vue_features/action_menu/components/ActionPrint'
import { openModal, closeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import Routes from 'routes'

const props = defineProps({
  objectId: { type: [String, Number], required: true },
  objectType: { type: String, required: true },
  objectName: { type: String, required: true },
  action: { type: String, default: 'show' },
  originalId: { type: [String, Number], default: null },
  isRevision: { type: Boolean, required: true },
  canPrint: { type: Boolean, default: false },
})
const isResource = props.objectType === 'Resource'
const pathToOriginal = isResource
  ? Routes.resource_path(props.originalId || props.objectId)
  : Routes.lesson_plan_path(props.originalId || props.objectId)
const pathToObject = isResource ? Routes.resource_path(props.objectId) : Routes.lesson_plan_path(props.objectId)
</script>

<template>
  <div>
    <DeleteObjectModal
      v-if="canModalShow('DeleteObjectModal')"
      :deletion-path="pathToObject"
      :object-name="isRevision ? $t('common.revision') : objectName"
    />
    <PrintDialog v-if="canModalShow('PrintDialog')" :item-id="objectId" @close="closeModal(PrintDialog)" />
    <PublishRevisionModal
      v-if="canModalShow('PublishRevisionModal')"
      :object-id="objectId"
      :publish-id="originalId"
      :object-type="objectType"
      :object-name="objectName"
      :action="action"
    />
    <ChangelogModal
      v-if="canModalShow('changelog-modal')"
      :object-id="objectId"
      :object-type="objectType"
      :object-is-resource="isResource"
    />
    <ListDropdown id="edit-actions" :button-classes="['inline-flex', 'btn--small', 'gap-1']">
      <template #button>
        {{ $t('common.actions') }}
        <LzIcon size="sm" path="icons/caret" aria-hidden="true" />
      </template>
      <template #listItems>
        <template v-if="isRevision">
          <li>
            <MenuButton
              :label="`${$t('common.publish')} ${$t('common.revision')}`"
              icon="icons/badge-check"
              @click="openModal('PublishRevisionModal')"
            />
          </li>
          <li>
            <MenuButton
              :label="$t('revisions.view_original')"
              :href="pathToOriginal"
              button-type="a"
              icon="icons/external"
              target="_blank"
            />
          </li>
        </template>
        <li v-if="action === 'edit'">
          <MenuButton :label="$t('common.changelog')" icon="icons/clock" @click="openModal('changelog-modal')" />
        </li>
        <li v-if="canPrint">
          <ActionPrint :item-id="objectId" @start-print-dialog="openModal(PrintDialog)" />
        </li>
        <li>
          <button
            class="flex items-center gap-1 whitespace-nowrap text-red-600 hover:bg-red-50"
            @click="openModal('DeleteObjectModal')"
          >
            <LzIcon icon-color-class="text-red-400" size="md" path="icons/delete" />
            {{ $t('common.delete') }}
          </button>
        </li>
      </template>
    </ListDropdown>
  </div>
</template>
