<template>
  <SectionAccordion :name="title">
    <WikiBlock v-for="block in blocks" :key="block.id" :blocks="block" />
  </SectionAccordion>
</template>

<script setup>
import SectionAccordion from 'vue_features/shared/components/ui/SectionAccordion'
import { defineAsyncComponent } from 'vue'

const WikiBlock = defineAsyncComponent(() => import('./WikiBlock'))
defineProps({
  blocks: { type: Array, default: () => [] },
  title: { type: String, default: '' },
})
</script>
