import messages from './wikis/i18n/wiki_resource_messages'
import ResourceForm from 'vue_features/resources/shared/components/ResourceForm'
import { initCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'

export default function ({ state }) {
  useSearchFiltersStore().$set({})

  if (state?.path) {
    initCollectionMapStore(state)
  }

  return { component: ResourceForm, messages }
}
