<template>
  <div ref="cardWrapperEl" :style="cardWrapperStyle" class="relative">
    <div ref="learnosityItemElement" :style="cardContentStyle" class="m-4">
      <div :class="`question-${responseId}`" class="learnosity-response" />
      <div class="learnosity-session-report__response-score mb-6">
        <strong v-if="isGraded">{{ score }} {{ $t('common.out_of') }} {{ maxScore }}</strong>
        <strong v-else>{{ $t('common.ungraded') }}</strong>
        <Comment v-if="comment.content" :comment="comment" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { isFinite as isFinite } from 'lodash'
import Comment from 'vue_features/shared/components/Comment'
import useCardScaling from 'vue_features/resources/shared/composables/use_card_scaling'

export default {
  name: 'ReturnedResponse',
  components: {
    Comment,
  },
  props: {
    responseId: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      default: null,
    },
    maxScore: {
      type: Number,
      default: null,
    },
    comment: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(props) {
    const learnosityItemElement = ref(null)

    const cardWrapperEl = ref()
    const { cardWrapperStyle, cardContentStyle } = useCardScaling(cardWrapperEl, learnosityItemElement, 800)

    return {
      learnosityItemElement,
      cardWrapperEl,
      cardWrapperStyle,
      cardContentStyle,
    }
  },
  computed: {
    isGraded() {
      return isFinite(this.score) && isFinite(this.maxScore)
    },
  },
}
</script>
