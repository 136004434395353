import { ref, computed, onUnmounted } from 'vue'
import { every, get, map, method, some } from 'lodash'
import { useAssignmentCardsStore } from 'vue_features/assignments/show/composables'
import { useLearnosityClientStore, useLearnosityConfig } from './use_learnosity_client_store'
import useLearnosityQuestions from './use_learnosity_questions'

export default function useLearnosityItemResource(itemReference, config = null) {
  if (!itemReference) return

  let learnosityConfig = config
  if (!learnosityConfig) {
    const { learnosityConfigFor } = useAssignmentCardsStore()
    learnosityConfig = learnosityConfigFor(itemReference)
  }
  const { fetchLearnosityClient } = useLearnosityClientStore()
  const { canRetrieveTags, isReviewing } = useLearnosityConfig(learnosityConfig)

  const onQuestionChange = ref(() => {})
  function setOnQuestionChange(f) {
    onQuestionChange.value = f
  }

  const itemsApp = ref(null)
  const questionHandlers = new Map()
  fetchLearnosityClient(learnosityConfig).then((learnosityItemsApp) => {
    if (hasUnmounted) return

    itemsApp.value = learnosityItemsApp
    questions.value.forEach((question) => {
      const handleQuestionChanged = () => {
        touchQuestion.value++
        onQuestionChange.value(question, { itemsApp: itemsApp.value })
      }
      questionHandlers.set(question, handleQuestionChanged)
      question.on('changed', handleQuestionChanged)
    })
  })

  let hasUnmounted = false
  onUnmounted(() => {
    questions.value.forEach((question) => {
      question.off('changed', questionHandlers.get(question))
    })
    hasUnmounted = true
  })

  const touchQuestion = ref(0)
  const hasAttemptedAllQuestions = computed(() => {
    touchQuestion.value
    if (!itemsApp.value) return false
    return every(questions.value, method('isAttempted'))
  })

  const { questions } = useLearnosityQuestions(itemsApp, itemReference)

  const getValidationInfo = computed(() => {
    return questions.value.map((question) => {
      return question.checkValidation()
    })
  })

  const isAutoscorable = computed(() => {
    return some(getValidationInfo.value, (info) => get(info, 'has_validation', false))
  })

  const autoscorableQuestions = computed(() => {
    if (!itemsApp.value) return []
    return questions.value.filter((question) => {
      return question.checkValidation && question.checkValidation().has_validation
    })
  })

  function validate(options = {}) {
    return map(questions.value, method('validate', options))
  }

  function showAnswers() {
    validate({ showCorrectAnswers: true })
  }

  function hideAnswers() {
    questions.value.forEach((question) => question.resetValidationUI())
  }

  function checkAnswers() {
    return validate({ showCorrectAnswer: true })
  }

  function hasCorrectlyAnswered() {
    return every(autoscorableQuestions.value, (question) => {
      const { score, max_score: maxScore } = question.getScore()
      return score === maxScore
    })
  }

  return {
    canRetrieveTags,
    isReviewing,
    questions,
    showAnswers,
    hideAnswers,
    checkAnswers,
    isAutoscorable,
    hasCorrectlyAnswered,
    hasAttemptedAllQuestions,
    setOnQuestionChange,
  }
}
