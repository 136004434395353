<script setup>
import { computed } from 'vue'

const props = defineProps({
  frozen: {
    type: Boolean,
    default: false,
  },
  background: {
    type: String,
    default: null,
  },
  highlight: {
    type: String,
    default: null,
  },
})

const style = computed(() => {
  return {
    ...(props.background ? { '--data-table-row-background': props.background } : {}),
    ...(props.highlight ? { '--data-table-row-highlight': props.highlight } : {}),
  }
})
</script>

<template>
  <div class="data-table-row" :class="{ 'data-table-row--frozen': frozen }" :style="style">
    <slot></slot>
  </div>
</template>
