import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const WikiEditsService = {
  updateCustomerEdits({ wikiId, blockHides, blockInserts, deletedBlockIds, deletedEditIds }) {
    const params = { wikiEdit: { blockHides, blockInserts, deletedBlockIds, deletedEditIds } }

    return HTTP.put(Routes.wiki_wiki_edits_path(wikiId), snakeKeys(params))
  },
}

export default WikiEditsService
