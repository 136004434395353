<!--
  eslint-disable vuejs-accessibility/mouse-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div ref="base" class="relative flex w-full flex-col justify-center">
    <svg :viewBox="viewBox" class="w-full" :height="height" :style="svgStyle">
      <g role="list">
        <g v-for="(group, index) in groups" :key="group.label || `group-${index}`">
          <rect
            :key="group.label || `group-${index}`"
            :class="{ 'cursor-help': showTooltips }"
            :width="`${groupWidths[index]}%`"
            :x="`${groupOffsets[index]}%`"
            :fill="colors[index % colors.length]"
            :aria-describedby="descriptionId(index)"
            :aria-label="groupLabelText(index)"
            height="100%"
            @mouseover="selectedGroup = index"
            @mouseleave="selectedGroup = null"
          />
          <desc :id="descriptionId(index)">
            {{ groupLabelText(index) }}
          </desc>
        </g>
      </g>
      <line
        v-if="accentLine"
        v-bind="accentLineProps"
        y1="25%"
        y2="25%"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-opacity="40%"
        pointer-events="none"
        role="presentation"
        aria-hidden="true"
      />
    </svg>
    <div v-if="showTooltips">
      <div
        v-for="(group, index) in groups"
        :key="`tooltip-${index}`"
        ref="tooltips"
        role="tooltip"
        class="pointer-events-none absolute rounded bg-black bg-opacity-80 p-2 text-xs text-white opacity-0 transition-opacity delay-700 duration-200"
        :class="{ 'opacity-100': selectedGroup === index }"
        :style="tooltipStyle(index)"
        data-test="tooltip"
      >
        {{ groupLabelText(index) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs } from 'vue'
import { sum, schemeAccent } from 'd3'
import { useSvgViewBox } from 'vue_features/shared/composables'
import { nanoid } from 'nanoid'

const defaultValueFunction = ({ value }) => value
const defaultTooltipFunction = (group, index, total) => `${group.label}: ${defaultValueFunction(group)}`

export default {
  name: 'StackedBar',
  components: {},
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 24,
    },
    groups: {
      type: Array,
      required: true,
    },
    groupValue: {
      type: Function,
      default: defaultValueFunction,
    },
    colors: {
      type: Array,
      default: () => schemeAccent,
    },
    accentLine: {
      type: Boolean,
      default: true,
    },
    showTooltips: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: [String, Function],
      default: () => defaultTooltipFunction,
    },
  },
  setup(props) {
    const chartId = ref(nanoid())
    const base = ref(null)
    const selectedGroup = ref(null)
    const tooltips = ref([])
    const { width: initialWidth, height: initialHeight } = toRefs(props)
    const { viewBox, containerWidth, containerHeight } = useSvgViewBox({
      container: base,
      initialWidth,
      initialHeight,
    })
    const valueFor = (group) => {
      return props.groupValue(group)
    }
    const total = computed(() => sum(props.groups, (group) => valueFor(group)))
    const groupWidths = computed(() => props.groups.map((group) => (valueFor(group) / total.value) * 100))
    const svgStyle = computed(() => (props.rounded ? { borderRadius: `${containerHeight.value / 2}px` } : {}))
    const accentLineProps = computed(() => ({
      x1: props.height * 0.42,
      x2: containerWidth.value - props.height * 0.42,
      'stroke-width': props.height * (4 / 24),
    }))

    // Calculate x-offset of each group in the bar by summing the widths of the bands preceding it:
    const groupOffsets = computed(() =>
      groupWidths.value.map((_w, index, widths) =>
        widths.slice(0, index).reduce((xOffset, width) => xOffset + width, 0),
      ),
    )

    const tooltipStyle = (index) => ({
      left: `${groupOffsets.value[index] + groupWidths.value[index] / 2}%`,
      top: `${containerHeight.value + 4}px`,
      transform: 'translateX(-50%)',
    })

    const descriptionId = (index) => `stacked-bar-${chartId.value}-desc-${index}`

    const tooltipFunction = computed(() => {
      if (typeof props.tooltip === 'string') {
        return () => props.tooltip
      } else {
        return props.tooltip
      }
    })

    const groupLabelText = (index) => {
      return tooltipFunction.value(props.groups[index], index, total.value)
    }

    return {
      chartId,
      base,
      viewBox,
      groupWidths,
      groupLabelText,
      svgStyle,
      groupOffsets,
      tooltips,
      selectedGroup,
      tooltipStyle,
      descriptionId,
      containerWidth,
      accentLineProps,
      total,
    }
  },
}
</script>
