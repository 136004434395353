import { get } from 'lodash'

export default function fixToolbarHiding(options) {
  if (get(options, 'sharedSpaces.top')) {
    const toolbarId = options.sharedSpaces.top
    document.querySelectorAll(`#${toolbarId} > div`).forEach((editor, index) => {
      editor.style.display = index === 0 ? 'block' : 'none'
    })
  }
}
