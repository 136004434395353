import { computed } from 'vue'
import Routes from 'routes'

export const BEGIN_CARD_ID = 0

export default function useBeginCard(lessonPlan, root) {
  const notes = computed(() => {
    if (lessonPlan.value.lessonType === 'activity') {
      const lessonPath = Routes.preview_lesson_plan_path(lessonPlan.value.id)
      return `<p>${root.$t('lesson_card_templates.designed_for_collaboration')}</p>
<p>
  <ul>
    <li><a href="${lessonPath}">${root.$t('lesson_card_templates.click_to_preview_assignment')}</a></li>
    <li>${root.$t('lesson_card_templates.click_assign_button')}</li>
  </ul>
</p>`
    } else {
      const readableLessonType = root.$t(`readable_types.shortened.${lessonPlan.value.lessonType}`)
      return root.$t('lesson_card_templates.begin_lesson_instructions', { lesson_type: readableLessonType })
    }
  })
  const title = computed(() => {
    return root.$t('lesson_card_templates.begin_lesson', {
      lesson_type: root.$t(`readable_types.shortened.${lessonPlan.value.lessonType}`),
    })
  })

  return {
    rank: 0,
    id: BEGIN_CARD_ID,
    title,
    notes,
    isBeginLessonCard: true,
  }
}
