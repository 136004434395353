import { ref } from 'vue'
import useResizeObserver from 'vue_features/resources/shared/composables/use_resize_observer'

export default function useCardScaling(cardWrapperEl, cardContentEl, width = 960) {
  const FULL_SIZE = width

  const cardWrapperStyle = ref({})
  const cardContentStyle = ref({})

  cardContentStyle.value = { width: `${FULL_SIZE}px` }

  const resizeCard = (width) => {
    const scale = width / FULL_SIZE

    const height = cardContentEl.value?.clientHeight * scale

    document.documentElement.style.setProperty('--card-scale', scale)

    cardWrapperStyle.value = {
      height: `${height}px`,
    }
    cardContentStyle.value = {
      'transform-origin': 'top left',
      transform: `scale(${scale})`,
      width: `${FULL_SIZE}px`,
    }
  }

  const onResize = () => {
    window.requestAnimationFrame(() => {
      resizeCard(cardWrapperEl.value?.offsetWidth)
    })
  }

  const wrapperObserver = useResizeObserver(cardWrapperEl, onResize)
  const contentObserver = useResizeObserver(cardContentEl, onResize)

  const on = () => {
    wrapperObserver.observe(cardWrapperEl.value)
    contentObserver.observe(cardWrapperEl.value)
  }

  const off = () => {
    wrapperObserver.unobserve(cardWrapperEl.value)
    contentObserver.unobserve(cardWrapperEl.value)
  }

  return { cardWrapperStyle, cardContentStyle, resizeCard, on, off }
}
