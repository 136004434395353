<template>
  <ChartPane
    class="standards-rank-chart"
    v-bind="{ title, description, subtitle, icon, loading, error, hasEnoughData: data && data.length }"
  >
    <template #header>
      <div class="self-start pt-2">
        <LzTooltip tag="span" :title="$t('performance_tooltip_title')" class="v-align-top pt-8 text-gray-700">
          <Chip
            v-if="showStandardsAlignmentWarning"
            data-test="rank-chart-standards-alignment-warning"
            :label="$t('alignment_criteria')"
            color="primary"
            size="sm"
          />
          <template #content>
            <h5 class="font-bold text-gray-600">{{ $t('alignment_criteria') }}</h5>
            <p class="text-sm">
              {{ $t('alignment_criteria_tooltip') }}
            </p>
          </template>
        </LzTooltip>
      </div>
    </template>
    <div v-for="(datum, index) in data" :key="`datum-${index}`" :data-test="`ranked-standard-${index}`" class="mb-0.5">
      <div class="grid grid-cols-2 text-xs leading-4">
        <div>
          <abbr :title="datum.description" class="cursor-help">{{ datum.label }}</abbr>
        </div>
        <div class="text-right">{{ datum.value }}%</div>
      </div>
      <progress
        class="bar relative h-3 w-full appearance-none rounded-3xl bg-gray-200"
        :class="rankTypeClass"
        :value="datum.value"
        max="100"
        :style="{ '--progress': `${datum.value}%` }"
      />
    </div>
  </ChartPane>
</template>

<script>
import { computed } from 'vue'
import ChartPane from './shared/ChartPane'
import { Chip, LzTooltip } from 'vue_features/shared/components/ui'

export default {
  name: 'StandardsRankChart',
  components: {
    ChartPane,
    Chip,
    LzTooltip,
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    rankingType: {
      type: String,
      default: 'top',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    showStandardsAlignmentWarning: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rankTypeClass = computed(
      () => (props.rankingType === 'top' ? 'text-green-400' : 'text-red-400') + ` ${props.rankingType}`,
    )
    const icon = computed(() => (props.rankingType === 'top' ? 'icons/success' : 'icons/rescue'))

    return { icon, rankTypeClass }
  },
}
</script>

<style scoped>
.standards-rank-chart {
  --border-radius: 1.5rem;
  --bottom-bar-color: rgb(var(--low-score-medium));
  --top-bar-color: rgb(var(--high-score-medium));
}

.bar::-webkit-progress-bar {
  background-color: rgb(var(--gray-200));
  border-radius: var(--border-radius);
}

.bar::-webkit-progress-value {
  background-color: var(--top-bar-color);
  color: var(--top-bar-color);
  border-radius: var(--border-radius);
}

.bar::-moz-progress-bar {
  background-color: var(--top-bar-color);
  color: var(--top-bar-color);
  border-radius: var(--border-radius);
}

.bar.bottom::-webkit-progress-value {
  background-color: var(--bottom-bar-color);
  color: var(--bottom-bar-color);
}

.bar.bottom::-moz-progress-bar {
  background-color: var(--bottom-bar-color);
  color: var(--bottom-bar-color);
}

.bar::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 4px;
  width: calc(var(--progress) - 8px);
  height: 3px;
  background: rgb(255, 255, 255, 0.4);
  border-radius: 10px;
  z-index: 1;
}
</style>
