import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const CardEditsService = {
  create(lessonPlanId, params) {
    const url = Routes.lesson_plan_card_edits_path(lessonPlanId)

    return HTTP.create(url, snakeKeys(params))
  },

  update({ activeEdit }, params) {
    const url = Routes.lesson_plan_card_edit_path(activeEdit.lessonPlanId, activeEdit.id)

    return HTTP.put(url, snakeKeys(params))
  },

  destroy({ activeEdit }) {
    const url = Routes.lesson_plan_card_edit_path(activeEdit.lessonPlanId, activeEdit.id)
    return HTTP.delete(url)
  },

  reorder(lessonPlanId, cards) {
    const params = {}
    params.cardEdits = cards.map((card) => {
      const { prevCardId, nextCardId, id } = card.activeEdit
      return { prevCardId, nextCardId, id, rank: card.rank }
    })
    const url = Routes.reorder_lesson_plan_card_edits_path(lessonPlanId)

    return HTTP.patch(url, snakeKeys(params))
  },
}

export default CardEditsService
