<script setup>
import StandardsDownloadService from '../api/standards_download_service'
import { location as $location } from 'utils'
import { ref, computed, watch, onUnmounted } from 'vue'
import { SelectOption } from 'vue_features/shared/components/selects'
import { LzModal, LzButton, LoadingSpinner, Banner } from 'vue_features/shared/components/ui'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

import states from 'vue_features/shared/components/selects/data/states'
import subjects from 'vue_features/shared/components/selects/data/curricula'

const statesToDelete = ['AS', 'GU', 'MP', 'PR', 'UM', 'VI', 'AA', 'AP', 'AE']
statesToDelete.forEach((stateCode) => delete states[stateCode])

const state = ref('')
const subject = ref('')
const isProcessing = ref(false)
const result = ref(null)

const hasErrors = computed(() => result.value?.error?.length > 0)

const isDone = ref(false)

const recordId = ref(null)

async function downloadStandards() {
  isProcessing.value = true
  result.value = null

  const { id } = await StandardsDownloadService.download({
    state: state.value,
    subject: subject.value,
  })

  recordId.value = id
  pollStatus()
}

function pollStatus() {
  setTimeout(async () => {
    const standardDownload = await StandardsDownloadService.getStatus(recordId.value)

    if (standardDownload.status == 'complete' || standardDownload.status == 'failed') {
      result.value = standardDownload
      isProcessing.value = false
      isDone.value = true
    } else {
      pollStatus()
    }
  }, 1000)
}

function handleDone(close) {
  close()
  $location.reload()
}

watch(isProcessing, (val) => {
  const { on, off } = useNamespacedEventListener(window)
  if (val) {
    on('beforeunload.standards', (e) => {
      e.preventDefault()
      return (e.returnValue = '')
    })
  } else {
    off('beforeunload.standards')
  }
})

onUnmounted(() => useNamespacedEventListener(window).off('beforeunload.standards'))
</script>

<template>
  <LzModal
    v-slot="{ close }"
    modal-id="StandardsDownloadModal"
    :modal-title="$t('download.modal_title')"
    :can-cancel="false"
    class="lzui-modal--large"
    data-test="download-modal"
  >
    <div class="flex flex-col gap-y-4 px-8 py-2">
      <p class="bold text-sm">
        <span v-if="isProcessing || result">
          {{ $t('download.processing_title') }}
          {{ states[state] }},
          {{ subjects[subject] }}
        </span>

        <span v-else-if="!isProcessing">
          {{ $t('download.instructions') }}
        </span>
      </p>

      <template v-if="result">
        <p v-if="isDone" class="bold text-sm">
          <Banner v-if="result.status == 'complete'" type="success">
            {{ $t('download.success') }}
            <a data-test="download-link" :href="result.csvFileUrl">{{ $t('common.download') }}</a>
          </Banner>

          <Banner v-if="result.status == 'failed'" type="error" data-test="download-error">
            {{ $t('download.error') }} {{ result.message }}
          </Banner>
        </p>
      </template>

      <div v-if="!result" class="gap-y-2">
        <div v-if="isProcessing" data-test="download-processing" class="flex flex-col items-center justify-center p-8">
          <div class="flex items-center">
            <LoadingSpinner :inline="true" />
            <span class="text-primary-accent text-lg">{{ $t('download.processing') }}</span>
          </div>
          <span class="text-primary-accent text-md">{{ $t('download.processing_info') }}</span>
        </div>

        <div v-else class="flex gap-x-4">
          <SelectOption
            v-model="state"
            :options="states"
            :placeholder="$t('common.select_state')"
            data-test="state-selector"
          />
          <SelectOption
            v-model="subject"
            :options="subjects"
            :placeholder="$t('common.select_curriculum')"
            data-test="subject-selector"
          />
        </div>
      </div>

      <div class="flex gap-x-2">
        <template v-if="isDone">
          <LzButton
            color="primary"
            :aria-label="$t('common.done')"
            class="ml-auto"
            data-test="done-button"
            @click="handleDone(close)"
          >
            {{ $t('common.done') }}
          </LzButton>
        </template>

        <template v-else>
          <div class="ml-auto flex gap-x-2">
            <LzButton
              :aria-label="$t('common.cancel')"
              :disabled="isProcessing"
              data-test="cancel-button"
              @click="close"
            >
              {{ $t('common.cancel') }}
            </LzButton>

            <LzButton
              color="green"
              :aria-label="$t('common.download')"
              :disabled="!state || !subject || isProcessing || hasErrors"
              data-test="download-button"
              @click="downloadStandards"
            >
              {{ $t('common.download') }}
            </LzButton>
          </div>
        </template>
      </div>
    </div>
  </LzModal>
</template>
