<template>
  <FadeTransition>
    <div class="flex items-center space-x-2">
      <input
        :id="inputId"
        :value="copyable"
        :class="inputClass"
        :aria-label="label"
        class="mt-1 inline-block"
        type="text"
        readonly="readonly"
        @focus="$event.target.select()"
        @mouseup.prevent
      />
      <CopyButton :target-text="copyable" />
    </div>
  </FadeTransition>
</template>

<script>
import { CopyButton } from 'vue_features/shared/components/ui'
import { FadeTransition } from 'vue_features/shared/components/transitions'

export default {
  name: 'CopyableInput',
  components: { FadeTransition, CopyButton },
  props: {
    copyable: { type: String, required: true },
    inputId: { type: String, required: true },
    label: { type: String, required: true },
    inputClass: { type: String, default: '' },
  },
}
</script>
