import { nextTick, h } from 'vue'
import { memoize, noop, capitalize } from 'lodash'
import { DetectionMatch, getDetectionAreasFor, vertex } from './models/detection_area'
import { UndoStack } from 'mixins'
import buildSlideContent from './models/slide_content_factory'
import * as viewerComponents from '../components/viewer_contents'
import { createVueApp } from 'initializers/create_vue_features'

const INDICATOR_DISPLAY_DELAY = 125
const droppableSelector = '.slide__content'

export default function useDragDrop(addContent, removeContent, indicators, unselectContent, isEmpty, movingContent) {
  const detectionAreaCache = memoize(noop)
  let emptySlide = false
  let bestMatch = new DetectionMatch()
  let cursorOffset = { top: 0, left: 0 }
  let dragOffset = { top: 0, left: 0 }
  let needsMount = null
  let mountedElement = null
  let movingVm = null

  function onDragStart(event, content, cursorAt) {
    cursorOffset = cursorAt
    if (event.type !== 'mousedown') return startDragging(content)

    needsMount = { target: event.currentTarget, content }
    document.addEventListener('mousemove', onDrag)
    document.addEventListener('mouseup', onDragStop)
  }

  function onDrag(event) {
    event.preventDefault()
    const position = { top: event.pageY, left: event.pageX }
    if (needsMount && (!!event.movementX || !!event.movementY)) {
      const content = needsMount.content
      mountElement()
      startDragging(content)
    }
    if (mountedElement) {
      mountedElement.style.top = event.clientY - dragOffset.top + 'px'
      mountedElement.style.left = event.clientX - dragOffset.left + 'px'
    }

    if (isEmpty.value) {
      emptySlide = true
      return
    }

    const matches = getDetectionAreasContaining(position)
    if (!matches.length) {
      onMiss()
      return
    }
    const newBestMatch = matches[0]
    if (!includes(matches, bestMatch) && !bestMatch.equals(newBestMatch)) {
      bestMatch = newBestMatch
      onMatch(position)
    } else {
      const newCursor = fixedCursorPosition(position)
      indicators.cursorPos.top = newCursor.top
      indicators.cursorPos.left = newCursor.left
    }
  }

  function onDragStop() {
    if (emptySlide) addContent(indicators.content.value)
    else if (indicators.active.value) indicators.drop()
    else if (movingContent.value) UndoStack.undo()
    reset()
  }

  function componentType(type) {
    return viewerComponents[`SlideViewer${capitalize(type)}`]
  }

  function mountElement() {
    const { target, content } = needsMount
    needsMount = null

    // The resource form is the (relative) offsetParent for absolute positioning
    const form = document.querySelector('.form-group')
    const parentCoords = form.getBoundingClientRect()
    const moveClasses = ['absolute', 'cursor-grabbing']
    dragOffset = { top: parentCoords.y + cursorOffset.top, left: parentCoords.x + cursorOffset.left }

    if (content.type) {
      mountedElement = document.createElement('div')
      movingVm = createVueApp({
        render: () => h(componentType(content.type), { content, once: true }),
      })
      mountedElement.classList.add('slide__content--moving', ...moveClasses)
      form.prepend(mountedElement)
      movingVm.mount(mountedElement)
    } else {
      mountedElement = target.cloneNode(true)
      mountedElement.classList.add('button-dragging', ...moveClasses)
      target.before(mountedElement)
    }
  }

  function startDragging(content) {
    unselectContent()

    if (content.type) {
      movingContent.value = content
      UndoStack.track(removeContent(content))
    } else {
      content = buildSlideContent(content)
      movingContent.value = content
    }

    // allow content to be removed when moving existing object
    nextTick(() => {
      document.querySelectorAll(droppableSelector).forEach((element) => {
        detectionAreaCache.cache.set(element, getDetectionAreasFor(element))
      })
    })

    indicators.content.value = content
    trackModalScrolling()
  }

  function includes(matches, detectionMatch) {
    return matches.find((match) => match.equals(detectionMatch))
  }

  function onMatch(cursorPos) {
    removeDropHelpers()
    addMatchIndicators(bestMatch, cursorPos)
  }

  function removeDropHelpers() {
    window.setTimeout(removeMatchIndicators, INDICATOR_DISPLAY_DELAY)
  }

  function removeMatchIndicators() {
    indicators.active.value = false
  }

  function addMatchIndicators(detectionMatch, cursorPos) {
    window.setTimeout(() => {
      if (bestMatch === detectionMatch) indicators.activate(detectionMatch, cursorPos)
    }, INDICATOR_DISPLAY_DELAY)
  }

  function onMiss() {
    bestMatch = new DetectionMatch()
    removeDropHelpers()
  }

  function getDetectionAreasContaining(cursorPos) {
    const matches = []
    document.querySelectorAll(droppableSelector).forEach((element) => {
      const detectionAreas = detectionAreaCache(element)
      if (!detectionAreas) return

      Object.entries(detectionAreas).forEach(([key, area]) => {
        const isInside = area.contains(fixedCursorPosition(cursorPos))
        if (isInside) matches.push(new DetectionMatch(area, key, element))
      })
    })

    return matches
  }

  function reset() {
    resetValues()
    removeMatchIndicators()
  }

  function resetValues() {
    movingContent.value = null
    modal = null
    modalStart = 0
    emptySlide = false
    detectionAreaCache.cache.clear()
    bestMatch = new DetectionMatch()
    if (movingVm) {
      movingVm.unmount()
      movingVm = null
    }
    if (mountedElement) {
      mountedElement.parentNode?.removeChild(mountedElement)
      mountedElement = null
    }
    document.removeEventListener('mousemove', onDrag)
    document.removeEventListener('mouseup', onDragStop)
  }

  function fixedCursorPosition(cursorPos) {
    return vertex(cursorPos.top + modalAdjustment(), cursorPos.left)
  }

  let modal
  let modalStart = 0

  function modalAdjustment() {
    return modal.scrollTop - modalStart
  }

  function trackModalScrolling() {
    if (!modal) modal = document.querySelector('.lzui-modal .slide')?.closest('.lzui-modal') || { scrollTop: 0 }
    modalStart = modal.scrollTop
  }

  function mountDraggable(el, content, cursorAt) {
    el.addEventListener('mousedown', (e) => onDragStart(e, content, cursorAt))
  }

  function unmountDraggable(el, content, cursorAt) {
    if (el) {
      el.removeEventListener('mousedown', (e) => onDragStart(e, content, cursorAt))
    }
  }

  return { reset, onDragStart, onDrag, onDragStop, mountDraggable, unmountDraggable }
}
