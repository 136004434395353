import SiteAdminSingleIntegrationService from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive } from 'vue'

export const useSiteAdminSingleIntegrationStore = defineStore('siteAdminSingleIntegration', () => {
  const state = reactive({
    authPreference: 'ilclassroom',
    authPreferenceOptions: null,
    maintenanceMode: false,
    persisted: false,
    syncActive: false,
    fullSync: false,
    appId: null,
    appSecret: null,
    anyRunningImports: null,
    lastSyncCompletedAt: null,
    customer: null,
    singleIntegrationSyncData: null,
  })

  function update() {
    const params = { siteAdminSingleIntegrationSettings: state }
    return SiteAdminSingleIntegrationService.update(params)
  }

  function toggleDataSync() {
    state.syncActive = !state.syncActive
  }

  function toggleFullSync() {
    state.fullSync = !state.fullSync
    state.lastSyncCompletedAt = !state.fullSync ? state.lastSyncCompletedAt : null
  }

  function toggleMaintenanceMode() {
    state.maintenanceMode = !state.maintenanceMode
  }

  return {
    state,
    update,
    toggleDataSync,
    toggleFullSync,
    toggleMaintenanceMode,
  }
})
