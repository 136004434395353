<template>
  <tbody>
    <template v-if="displayByStandard">
      <tr
        v-for="standard in paginatedRows"
        :key="standard.id"
        :class="`lz-standard-row bg-focus even:bg-neutral-50 initiative-${standard.initiativeId}`"
      >
        <th>
          <StandardCode
            :display-code="displayCode(standard)"
            :name="standard.name"
            :coverage-type="coverageType"
            :class="`lz-standard initiative-${standard.initiativeId}`"
          />
        </th>

        <template v-if="displayByWiki">
          <th v-for="wiki in items" :key="wiki.id" class="center">
            <LzIcon v-if="wikiHasStandard(wiki, standard)" path="icons/check" data-test="wiki-has-standard" />
          </th>
        </template>

        <template v-else>
          <ItemLinks :items="hasContentsWithStandard(standard)" />
        </template>
      </tr>
    </template>

    <template v-else>
      <tr v-for="lessonPlan in paginatedRows" :key="lessonPlan.id" class="bg-focus even:bg-neutral-50">
        <td>
          <a :href="Routes.lesson_plan_path(lessonPlan.id)">
            {{ lessonPlan.title }}
          </a>
        </td>

        <td>
          <span v-for="coverageKey in coverageTypes" :key="coverageKey" class="lz-standards">
            <StandardCode
              v-for="standard in groupBy(lessonPlan.standards, 'coverageType')[coverageKey]"
              :key="standard.id"
              :name="standard.name"
              :display-code="displayCode(standard)"
              :coverage-type="coverageKey"
              :class="`lz-standard initiative-${standard.initiativeId}`"
            />
          </span>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script setup>
import { inject } from 'vue'
import { groupBy } from 'lodash'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import StandardCode from 'vue_features/shared/components/ui/StandardCode'
import ItemLinks from './ItemLinks'
import Routes from 'routes'

const coverageTypes = ['addressed', 'buildingOn', 'buildingTowards']

const { showStandardsPrefix } = useCurrentUserStore()

const { displayByStandard, displayByWiki, items, paginatedRows, coverageType } = inject('coverageTable')

function hasContentsWithStandard(standard) {
  return items.value.filter((item) => {
    const standards = item.standards
    if (typeof standards === 'object') {
      return standards.some((s) => s.coverageType === coverageType.value && s.id === standard.id)
    }
    return false
  })
}

function displayCode(standard) {
  return showStandardsPrefix.value ? standard.prefixedCode : standard.code
}

function wikiHasStandard(wiki, standard) {
  return wiki.standards.some(({ id, coverageType }) => id === standard.id && coverageType === 'addressed')
}
</script>
