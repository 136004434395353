<template>
  <div class="page-head">
    <InfoFormModal v-if="canModalShow('InfoFormModal')" />
    <div class="page-head__wrapper flex flex-col gap-4 pb-8 lg:flex-row">
      <div v-if="hasLogo" class="page-head__image" aria-hidden="true">
        <img :src="logo" alt="logo" />
      </div>
      <div class="page-head__content p-0">
        <div class="page-head__pretext">
          <LzContentStateBadge :state="lessonState" />
          <LzContentPremiumBadge v-if="isPublic" :premium="isPremium" />
        </div>
        <PageHeadMetadata :cards="cards" />
        <PageHeadStandards />
      </div>
      <div v-if="!isCreatingMetaEdits" class="page-head__actions page-head__actions--pretext">
        <div class="page-head__button">
          <button class="lp-editor__info btn btn--small" @click="openModal('InfoFormModal')">
            <LzIcon path="icons/edit" />
            {{
              $t('lesson_plans.page_head_info.edit_lesson_info', { lesson_type: $t(`readable_types.${lessonType}`) })
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import PageHeadMetadata from '../../components/PageHeadMetadata'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import LzContentPremiumBadge from 'vue_features/lz_content/components/LzContentPremiumBadge'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useCardDeckEditorStore } from 'vue_features/lesson_plans/edit/composables/use_card_deck_editor_store'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { LzIcon } from 'vue_features/shared/components/ui'
import InfoFormModal from './InfoFormModal'
import PageHeadStandards from 'vue_features/lesson_plans/components/PageHeadStandards'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'PageHead',
  components: {
    PageHeadStandards,
    LzIcon,
    PageHeadMetadata,
    LzContentPremiumBadge,
    LzContentStateBadge,
    InfoFormModal,
  },
  setup() {
    const { lessonType, logo, lessonState, premium } = useLessonPlanStore()
    const { isCreatingMetaEdits } = useLessonPlanEditStore()
    const { state: cardDeckEditorState } = useCardDeckEditorStore()
    const cards = computed(() => cardDeckEditorState.cards || [])

    const { isPublic } = useCurrentCustomerStore()
    return {
      lessonType,
      openModal,
      canModalShow,
      logo,
      lessonState,
      isPremium: premium,
      isCreatingMetaEdits,
      cards,
      isPublic,
    }
  },
  computed: {
    hasLogo() {
      return !!this.logo
    },
  },
}
</script>
