import { computed, watchEffect } from 'vue'
import { times } from 'lodash'

export default function useLztablePlugin(table) {
  const columnCount = computed(() => {
    return table.value[0].length
  })

  let newCellId = 0

  watchEffect(() => {
    if (table.value) {
      table.value.forEach((row) => {
        row.forEach((cell) => (newCellId = Math.max(cell.id, newCellId)))
      })
    }
  })

  function newCell(headerScopes = []) {
    return { text: '<p></p>', headerScopes, id: newCellId++ }
  }

  function addHeaderScope(tableCell, scope) {
    if (!tableCell.headerScopes.includes(scope)) {
      tableCell.headerScopes.push(scope)
    }
  }

  function removeHeaderScope(tableCell, scope) {
    const index = tableCell.headerScopes.indexOf(scope)
    if (index >= 0) {
      tableCell.headerScopes.splice(index, 1)
    }
  }

  function makeRowHeader(rowIndex) {
    table.value[rowIndex].forEach((tableCell) => {
      addHeaderScope(tableCell, 'row')
    })
  }

  function removeRowHeader(rowIndex) {
    table.value[rowIndex].forEach((tableCell) => {
      removeHeaderScope(tableCell, 'row')
    })
  }

  function buildBlankRow() {
    const row = []
    times(columnCount.value, () => {
      row.push(newCell())
    })
    return row
  }

  function addRowAbove(rowIndex) {
    table.value.splice(rowIndex, 0, buildBlankRow())
  }

  function addRowBelow(rowIndex) {
    table.value.splice(rowIndex + 1, 0, buildBlankRow())
  }

  function deleteRow(rowIndex) {
    table.value.splice(rowIndex, 1)
  }

  function makeColumnHeader(columnIndex) {
    table.value.forEach((row) => addHeaderScope(row[columnIndex], 'col'))
  }

  function removeColumnHeader(columnIndex) {
    table.value.forEach((row) => removeHeaderScope(row[columnIndex], 'col'))
  }

  function addColumnLeft(columnIndex) {
    table.value.forEach((row) => {
      const headerScopes = row[0].headerScopes.includes('row') ? ['row'] : []
      row.splice(columnIndex, 0, newCell(headerScopes))
    })
  }

  function addColumnRight(columnIndex) {
    table.value.forEach((row) => {
      const headerScopes = row[0].headerScopes.includes('row') ? ['row'] : []
      row.splice(columnIndex + 1, 0, newCell(headerScopes))
    })
  }

  function deleteColumn(columnIndex) {
    table.value.forEach((row) => row.splice(columnIndex, 1))
  }

  return {
    makeRowHeader,
    removeRowHeader,
    addRowAbove,
    addRowBelow,
    deleteRow,
    makeColumnHeader,
    removeColumnHeader,
    addColumnLeft,
    addColumnRight,
    deleteColumn,
  }
}
