<script>
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common } from 'vue_features/shared/i18n'

export default {
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: 'or' }) },
}
</script>

<script setup>
defineProps({
  textClass: {
    type: String,
    default: 'bg-white',
  },
})
</script>

<template>
  <div>
    <hr class="hr_text" />
    <p class="mb-0 uppercase">
      <span :class="textClass">
        <slot>{{ $t('common.or') }}</slot>
      </span>
    </p>
  </div>
</template>
