<template>
  <LzConfirmModal :modal-id="modalId">
    <template #confirmBody>
      <h2 class="my-2">{{ $t('are_you_sure_heading') }}</h2>
      <p>{{ $t('data_lost_message') }}</p>
      <p>
        <strong>{{ $t('cannot_undo_message') }}</strong>
      </p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary" @click="confirm">
        {{ $t('delete_user_label') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'UserDeletionConfirmModal',
  components: { LzConfirmModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    function confirm() {
      const { deleteUser } = useUsersIndexStore()
      deleteUser(props.user.id).then(() => {
        closeModal(props.modalId)
      })
    }
    return { confirm }
  },
}
</script>
