<template>
  <component
    :is="resourceBuilder"
    v-if="isBuildingResource"
    :resource="content"
    :lesson-plan="lessonPlan"
    :cards="cards"
    :text-style="lessonPlan.textStyle"
    :in-card-form="true"
    :expanded-form="expandedForm"
    @update="$emit('update', $event)"
  />
  <ExistingBuilder
    v-else-if="isEnteringId"
    v-bind="{ cardType, content, isPersisted, allowLessonPlans, canImportTeachingNotes }"
    @update="$emit('update', $event)"
    @update-card="$emit('update-card', $event)"
  />
</template>

<script>
import { computed } from 'vue'
import useResourceBuilder from 'vue_features/resources/shared/composables/use_resource_builder'
import ExistingBuilder from './ExistingBuilder'

export default {
  name: 'CardContentForm',
  components: { ExistingBuilder },
  props: {
    content: {
      type: Object,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
    isPersisted: {
      type: Boolean,
      default: false,
    },
    lessonPlan: {
      type: Object,
      default: () => ({ textStyle: 'secondary' }),
    },
    cards: {
      type: Array,
      default: () => [],
    },
    allowLessonPlans: {
      type: Boolean,
      default: false,
    },
    canImportTeachingNotes: {
      type: Boolean,
      default: false,
    },
    expandedForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isExisting = computed(() => props.content.type === 'Existing')
    const isLessonPlan = computed(() => props.content.type === 'LessonPlan')
    const isBuildingResource = computed(
      () => !isExisting.value && !isLessonPlan.value && props.content.type !== 'Title',
    )
    const isEnteringId = computed(() => isExisting.value || isLessonPlan.value)
    const persisted = computed(() => props.isPersisted || props.content.id)
    const { resourceBuilder } = useResourceBuilder(props.content.type)

    return { isExisting, isLessonPlan, isBuildingResource, isEnteringId, persisted, resourceBuilder }
  },
}
</script>
