<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <h2 class="h3 mt-0">{{ $t('show.customer_page_title') }}</h2>
    <label class="h5 my-0">{{ $t('show.site_name') }}</label>
    <p class="small">{{ $t('show.site_name_info') }}</p>
    <div class="w-1/2 md:w-1/3">
      <input
        :value="name"
        :disabled="!isEditing"
        class="form-control"
        type="text"
        data-test="customer-name"
        @input="$emit('update:name', $event.target.value)"
      />
    </div>
    <template v-if="authorizedAsCustomerAdmin">
      <label class="h5 mb-0 mt-6">{{ $t('show.subdomain') }}</label>
      <p v-if="isSubdomainUpdated" class="small red" data-test="subdomain-warning">
        {{ $t('show.subdomain_warning') }}
      </p>
      <div class="w-1/2 md:w-1/3">
        <input
          :value="subdomain"
          :disabled="!isEditing"
          class="form-control"
          type="text"
          data-test="customer-subdomain"
          @input="$emit('update:subdomain', $event.target.value.trim())"
        />
      </div>

      <SettingsSelect
        :model-value="siteType"
        :enabled="isEditing"
        :options="siteTypeOptions"
        :label="$t('show.site_type')"
        @update:modelValue="$emit('update:siteType', $event)"
      />

      <SettingsSelect
        :model-value="customerType"
        :enabled="isEditing && ['customer', 'pilot'].includes(siteType)"
        :options="customerTypeOptions"
        :label="$t('show.customer_type')"
        :info="$t('show.customer_type_info')"
        @update:modelValue="$emit('update:customerType', $event)"
      />

      <SettingsSelect
        :model-value="ownerId"
        :enabled="isEditing"
        :options="adminOptions"
        :label="$t('show.owner')"
        @update:modelValue="$emit('update:ownerId', Number.parseInt($event))"
      />
    </template>

    <LogoUploader :value="logoFile" :is-editing="isEditing" :name="name" @input="$emit('update:logoFile', $event)" />

    <template v-if="authorizedAsCustomerAdmin">
      <SettingsToggle
        id="maintenance-mode-toggle"
        :model-value="maintenanceMode"
        :enabled="isEditing"
        :label="$t('show.pause')"
        :info="$t('show.pause_info')"
        class="mt-6"
        @update:modelValue="$emit('update:maintenanceMode', $event)"
      />
    </template>
  </div>
</template>

<script>
import SettingsSelect from './SettingsSelect'
import LogoUploader from './LogoUploader'
import SettingsToggle from './SettingsToggle'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'SiteSettings',
  components: { SettingsSelect, LogoUploader, SettingsToggle },
  props: {
    isEditing: { type: Boolean, required: true },
    name: { type: String, required: true },
    subdomain: { type: String, required: true },
    siteType: { type: String, required: true },
    siteTypeOptions: { type: Array, required: true },
    customerType: { type: String, required: true },
    customerTypeOptions: { type: Array, required: true },
    ownerId: { type: Number, required: true },
    adminOptions: { type: Array, required: true },
    logoFile: { type: Object, required: true },
    availableLocales: { type: Array, required: true },
    maintenanceMode: { type: Boolean, required: true },
  },
  setup() {
    const { authorizedAsCustomerAdmin } = useCurrentUserStore()
    return { authorizedAsCustomerAdmin }
  },
  data() {
    return {
      initialSubdomain: this.subdomain,
    }
  },
  computed: {
    isSubdomainUpdated() {
      return this.subdomain !== this.initialSubdomain
    },
  },
}
</script>
