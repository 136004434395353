import * as filestack from 'filestack-js'
import { pickBy } from 'lodash'
import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

// Currently tied to version 3.19 until bug w/ drag + drop files
// and accepted mimetypes gets fixed: https://github.com/filestack/filestack-js/issues/402

export function picker(options) {
  return createClient(options).picker(pickerOptions(options))
}

export function createClient({ api_key: apiKey, policy, signature, cname } = {}) {
  return filestack.init(apiKey, {
    security: {
      policy,
      signature,
    },
    cname,
  })
}

export function pickerOptions(options) {
  return pickBy({
    rootId: options.rootId,
    accept: options.accept,
    maxSize: options.maxSize,
    fromSources: fromSources(options),
    lang: options.lang,
    onOpen: options.onOpen,
    onClose: options.onClose,
    onUploadDone: options.onUploadDone,
    container: options.container,
    displayMode: options.displayMode,
  })
}

function fromSources(options) {
  const fromArray = [...(options.from_sources || [])]
  if (!(options.accept || []).includes('.mp3') && fromArray.includes('audio')) {
    fromArray.splice(fromArray.indexOf('audio'), 1)
  }

  return fromArray
}

export function getUploadOptions(defaults = {}) {
  return HTTP.getJSON(Routes.filestack_options_path()).then((data) => ({ ...defaults, ...data }))
}

export function getReadOptions(filestackUrl) {
  const queryParams = pickBy({ filestack_url: filestackUrl })
  return HTTP.getJSON(Routes.filestack_options_path(queryParams))
}
