<template>
  <nav
    v-if="breadcrumbs.length > 1 || (breadcrumbs.length === 1 && isCollectionMapAvailable)"
    id="breadcrumb_nav"
    :aria-label="$t('navigation.breadcrumb')"
    class="bg-transparent print:hidden"
  >
    <div id="breadcrumbs" class="mx-auto p-4 lg:px-6">
      <ol itemscope="" itemtype="http://schema.org/BreadcrumbList" class="flex list-none flex-wrap items-start gap-y-2">
        <Breadcrumb
          v-if="collectionMapCrumb"
          id="breadcrumbs__first"
          child-id="first-breadcrumb-text"
          :position="0"
          class="block cursor-pointer overflow-visible"
          @click="openCollectionMap"
        >
          <LzIcon size="sm" path="icons/hamburger" type="span" aria-hidden="true" />
          <span>{{ collectionMapCrumb.title }}</span>
        </Breadcrumb>
        <Breadcrumb
          v-for="(breadcrumb, index) in normalCrumbs"
          :key="breadcrumb.id"
          :href="breadcrumb.url"
          :position="index + 1"
          :separator="index + 1 < normalCrumbs.length"
        >
          {{ breadcrumb.title }}
        </Breadcrumb>
      </ol>
    </div>
  </nav>
</template>

<script>
import { computed } from 'vue'
import { useCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'
import Breadcrumb from './Breadcrumb'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { shared } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'

export default {
  name: 'Breadcrumbs',
  components: { Breadcrumb, LzIcon },
  i18n: { messages: pickLocaleNamespace(shared, 'navigation') },
  props: {
    breadcrumbs: { type: Array, required: true },
    isCollectionMapAvailable: { type: Boolean, required: true },
    // FIXME: remove once related content is removed
    isExpandedRow: { type: Boolean, required: true },
  },
  setup(props) {
    const { openCollectionMap } = useCollectionMapStore()
    const collectionMapCrumb = computed(() => (props.isCollectionMapAvailable ? props.breadcrumbs[0] : null))
    const normalCrumbs = computed(() => (collectionMapCrumb.value ? props.breadcrumbs.slice(1) : props.breadcrumbs))
    return { openCollectionMap, collectionMapCrumb, normalCrumbs }
  },
}
</script>
