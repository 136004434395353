<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <SplitActionDropdown
    :id="`school-actions-${school.id}`"
    button-icon="icons/edit"
    @button-click="open('SchoolModalEdit')"
  >
    <template #label>
      <span class="hidden sm:inline-block">
        {{ $t('common.edit') }}
      </span>
    </template>
    <template #dropdownListItems>
      <li v-if="schools.length > 1">
        <button type="button" @click="open('SchoolModalMerge')">{{ $t('common.merge') }}</button>
      </li>
      <li>
        <button type="button" @click="open('SchoolModalDelete')">{{ $t('common.delete') }}</button>
      </li>
    </template>
  </SplitActionDropdown>
</template>

<script>
import SplitActionDropdown from 'vue_features/shared/components/ui/dropdowns/SplitActionDropdown'
import { useSiteAdminSchoolsStore } from '../store/use_site_admin_schools_store'
import { openModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'SchoolActionDropdown',
  components: { SplitActionDropdown },
  props: { school: { type: Object, required: true } },
  setup(props, { emit }) {
    const { schools } = useSiteAdminSchoolsStore()
    const open = (name) => openModal(name, props.school)
    return { schools, open }
  },
}
</script>
