<template>
  <div id="presenter-view" class="page-content lesson-plan__lesson mx-auto flex h-full max-w-7xl py-0">
    <div class="flex h-full max-h-full flex-1 overflow-hidden">
      <LessonThumbs
        v-if="showThumbs"
        class="mr-2 max-h-screen w-44 flex-none items-stretch overflow-y-auto"
        :cards="cards"
        :selected-card-index="selectedCardIndex"
        @card-selected="updateSelectedCardIndex"
      />
      <div class="flex h-full flex-grow flex-col items-stretch justify-start md:py-4">
        <div class="lesson-notes-presenter__notes relative h-full w-full rounded-2xl shadow-lg">
          <LessonCardNotes ref="lessonCardNotes" :card="cards[selectedCardIndex]" />
        </div>
      </div>

      <div
        class="fixed left-0 flex h-auto w-full items-center justify-center"
        :class="showToolbar ? 'bottom-1' : 'hide -bottom-18'"
      >
        <div class="w-full max-w-7xl">
          <div class="lesson-notes-presenter__toolbar flex-0 flex w-full items-center justify-start rounded-2xl">
            <button
              alt="Show or hide the lesson toolbar"
              class="lesson-toolbar-toggle bg-primary-700 absolute -top-5 left-0 right-0 z-50 m-auto flex h-5 w-20 items-center justify-center rounded-t-lg bg-opacity-40 transition-all hover:bg-opacity-95"
              @click="toggleToolbar"
            >
              <InlineSvg
                aria-label="Show or hide the lesson toolbar"
                class="h-6 w-6 text-white"
                :class="showToolbar ? 'transform-none' : 'rotate-180 transform'"
                size="m"
                path="icons/chevron-down"
              />
            </button>
            <LessonToolbarItem
              class="flex self-stretch rounded-l-2xl"
              icon-path="icons/lp-toolbar-sidebar"
              :label="$t('toolbar.sidebar')"
              @click="toggleThumbs"
            />
            <LessonToolbarPositionIndicator
              :cards="cards"
              :selected-card-index="selectedCardIndex"
              @card-selected="updateSelectedCardIndex"
              @go-previous="goPrevious"
              @go-next="goNext"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import LessonCardNotes from 'vue_features/lesson_plans/show/components/LessonCardNotes'
import LessonThumbs from 'vue_features/lesson_plans/show/components/LessonThumbs'
import LessonToolbarPositionIndicator from 'vue_features/lesson_plans/show/components/LessonToolbarPositionIndicator'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import LessonToolbarItem from 'vue_features/lesson_plans/show/components/LessonToolbarItem'
import {
  useLessonPlayer,
  useLessonPlayerUrlSupport,
  useLessonPlayerPresenterIterator,
  useLessonPlayerPresenterChannel,
} from 'vue_features/lesson_plans/show/composables/use_lesson_player'

export default {
  name: 'LessonPlanPresenter',
  components: {
    InlineSvg,
    LessonCardNotes,
    LessonThumbs,
    LessonToolbarPositionIndicator,
    LessonToolbarItem,
  },
  setup() {
    const root = inject('useRoot')()

    const { lessonPlan } = useLessonPlanStore()
    const showThumbs = ref(false)
    const lessonCardNotes = ref()
    const cards = ref(lessonPlan.value.cards)

    const showToolbar = ref(true)

    const { selectedCardIndex, updateSelectedCardIndex } = useLessonPlayer()
    useLessonPlayerUrlSupport({
      selectedCardIndexRef: selectedCardIndex,
      cardsRef: cards,
      onInitialCardIndex: updateSelectedCardIndex,
      root,
    })
    const { goPrevious, goNext } = useLessonPlayerPresenterIterator({
      selectedCardIndexRef: selectedCardIndex,
      cardsRef: cards,
      cardSelected: updateSelectedCardIndex,
    })

    useLessonPlayerPresenterChannel({
      selectedCardIndexRef: selectedCardIndex,
      cardSelected: updateSelectedCardIndex,
      notesRef: lessonCardNotes,
    })

    const toggleToolbar = () => {
      return (showToolbar.value = !showToolbar.value)
    }

    const toggleThumbs = () => {
      return (showThumbs.value = !showThumbs.value)
    }

    return {
      selectedCardIndex,
      updateSelectedCardIndex,
      goPrevious,
      goNext,
      showThumbs,
      toggleThumbs,
      showToolbar,
      toggleToolbar,
      cards,
      lessonCardNotes,
    }
  },
}
</script>

<style scoped>
.lesson-notes-presenter__toolbar {
  background-color: rgba(var(--primary-700), 0.9125);
}

.lesson-notes-presenter__notes .lesson-notes {
  @apply max-h-screen overflow-auto pb-24;
}

.lesson-toolbar-toggle:hover {
  @apply -top-7 h-7 !important;
}
.lesson-nav.max-w-xs {
  max-width: 18rem !important;
}

.lesson-notes-presenter .lesson-notes__title {
  display: none;
}

.lesson-notes-presenter__notes {
  background-color: rgb(245 248 255 / var(--tw-bg-opacity));
}

.lesson-notes-presenter .lesson-thumbs {
  max-height: calc(100vh - 7rem);
}

@media (min-width: 585px) {
  .lesson-nav.max-w-xs {
    max-width: 23rem !important;
  }
}
</style>
