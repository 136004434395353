<script setup>
import { computed } from 'vue'
import BookmarkItem from './BookmarkItem'
import { useBookmarksStore } from '../composables/use_bookmarks_store'
import LoadMoreList from 'vue_features/shared/components/lists/LoadMoreList'

const { bookmarks, bookmarksPagination, loadMoreBookmarks } = useBookmarksStore()
const bookmarksWithLessonPlans = computed(() => bookmarks.value.filter(({ lessonPlan }) => lessonPlan))

const handleLoadMore = async (done) => {
  await loadMoreBookmarks()
  done()
}
</script>

<template>
  <LoadMoreList
    v-slot="{ item: bookmark }"
    :items="bookmarksWithLessonPlans"
    :start-count="10"
    :total-count="bookmarksPagination.totalCount"
    list-class="space-y-4"
    @load-more="handleLoadMore"
  >
    <BookmarkItem :key="bookmark.id" :bookmark="bookmark" />
  </LoadMoreList>
</template>
