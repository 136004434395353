import Cookies from 'js-cookie'
import { get } from 'lodash'
import googleClassroomService from './api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import useApiMethod from 'vue_features/shared/composables/use_api_method'

const GOOGLE_CLASSROOM_CALL_TO_ACTION_COOKIE = 'has_dismissed_googleclassroom_cta'

function loadHasDismissedCallToActionCookie() {
  const cookieValue = Cookies.get(GOOGLE_CLASSROOM_CALL_TO_ACTION_COOKIE)
  return cookieValue && cookieValue === 'true'
}

function setHasDismissedCallToActionCookie() {
  Cookies.set(GOOGLE_CLASSROOM_CALL_TO_ACTION_COOKIE, 'true')
}

export function initGoogleClassroomStore(initState = {}) {
  const { $set, state } = useGoogleClassroomStore()
  $set(initState)
  state.profile = state.profile || {}
  if (!('hasDismissedCallToAction' in initState)) {
    state.hasDismissedCallToAction = loadHasDismissedCallToActionCookie()
  }
}

export const useGoogleClassroomStore = defineStore('googleClassroom', () => {
  const state = reactive({
    hasLinkedAccount: false,
    linkError: false,
    hasLinkErrorListener: false,
    profile: {
      profileId: undefined,
      email: undefined,
      name: undefined,
    },
    courses: {
      loading: false,
      data: null,
    },
    courseAssignments: {
      loading: false,
      data: null,
    },
    lzStudents: {
      loading: false,
      data: null,
    },
    students: {},
    hasDismissedCallToAction: false,
  })

  const profileId = computed(() => get(state, 'profile.profileId'))
  const profileEmail = computed(() => get(state, 'profile.email'))
  const profileName = computed(() => get(state, 'profile.name'))
  const studentsLoadingForCourse = computed(() => (courseId) => get(state, `students.${courseId}.loading`) || false)
  const studentsForCourse = computed(() => (courseId) => get(state, `students.${courseId}.data`))
  const coursesLoading = computed(() => get(state, 'courses.loading') || false)
  const coursesData = computed(() => get(state, 'courses.data'))
  const courseAssignmentsLoading = computed(() => get(state, 'courseAssignments.loading') || false)
  const courseAssignmentsData = computed(() => get(state, 'courseAssignments.data'))
  const hasCourseAssignments = computed(() => !!state.courseAssignments.data && state.courseAssignments.data.length > 0)
  const lzStudentsData = computed(() => get(state, 'lzStudents.data'))
  const lzStudentsLoading = computed(() => get(state, 'lzStudents.loading'))
  const isConnectedToGoogle = computed(() => {
    const { googleClassroomEnabled } = useCurrentCustomerStore()
    return googleClassroomEnabled.value && state.hasLinkedAccount
  })

  const isLinkError = (e) => {
    return (
      get(e, 'xhr.status') === 401 &&
      ['unauthorized', 'classroom_disabled'].includes(get(e, 'xhr.responseJSON.error') === 'unauthorized')
    )
  }

  function dismissGoogleCallToAction() {
    state.hasDismissedCallToAction = true
    setHasDismissedCallToActionCookie()
  }

  function registerLinkErrorListener() {
    if (state.hasLinkErrorListener) return false

    state.hasLinkErrorListener = true
    return true
  }

  async function loadProfile({ ignoreError = false } = {}) {
    try {
      state.profile = await googleClassroomService.getProfile()
      state.hasLinkedAccount = true
    } catch (e) {
      if (isLinkError(e) && !ignoreError) {
        activateLinkError()
      }
    }
  }

  async function unlinkProfile() {
    try {
      await googleClassroomService.unlinkProfile()
      state.profile = {
        profileId: undefined,
        email: undefined,
        name: undefined,
      }
      state.hasLinkedAccount = false
    } catch (e) {
      // console.error(e)
    }
  }

  function activateLinkError() {
    state.linkError = true
    state.hasLinkedAccount = false
  }

  const getLzStudents = useApiMethod(googleClassroomService.getLzStudents)
  async function loadLzStudents({ force = false } = {}) {
    const hasOrIsLoading = lzStudentsData.value || lzStudentsLoading.value
    if (hasOrIsLoading && !force) return

    state.lzStudents.loading = true

    state.lzStudents.data = []
    let totalPages = 1
    for (let page = 1; page <= totalPages; page++) {
      await getLzStudents.load(page)

      if (getLzStudents.error.value) {
        if (isLinkError(getLzStudents.error.value)) {
          activateLinkError()
        }
        if (getLzStudents.error.value.name !== 'AbortError') {
          state.lzStudents.data = null
        }
        break
      }

      totalPages = getLzStudents.response.value.meta.pagination.totalPages
      state.lzStudents.data.push(...getLzStudents.response.value.students)
    }

    state.lzStudents.loading = false
  }

  const getCourses = useApiMethod(googleClassroomService.getCourses)
  async function loadCourses({ force = false } = {}) {
    const hasOrIsLoading = coursesData.value || coursesLoading.value
    if (hasOrIsLoading && !force) return

    state.courses.loading = true
    state.courses.data = []
    let pageToken = ''
    const loadCoursePage = async () => {
      await getCourses.load(pageToken)
      if (getCourses.error.value) {
        if (isLinkError(getCourses.error.value)) {
          activateLinkError()
        }
        if (getCourses.error.value.name !== 'AbortError') {
          state.courses.data = null
        }
      } else {
        state.courses.data.push(...getCourses.response.value.courses)
        pageToken = getCourses.response.value.page_token
      }
    }

    do {
      await loadCoursePage()
    } while (!!pageToken && !getCourses.error.value)

    state.courses.loading = false
  }

  function loadCourseAssignments({ lzCodeId = null, force = false }) {
    const hasOrIsLoading = courseAssignmentsData.value || courseAssignmentsLoading.value
    if (hasOrIsLoading && !force) return

    const courseAssignments = []
    const codeList = [].concat(lzCodeId)
    state.courseAssignments.loading = true

    try {
      codeList.forEach(async (codeId) => {
        const assignments = await googleClassroomService.getCourseAssignments(codeId)
        courseAssignments.push(...assignments)
      })
      state.courseAssignments.data = courseAssignments
    } catch (e) {
      if (isLinkError(e)) activateLinkError()
      state.courseAssignments.data = null
    } finally {
      state.courseAssignments.loading = false
    }
  }

  function setStudents({ courseId, students }, pageToken) {
    state.students = {
      ...state.students,
      [courseId]: {
        data: students,
        loading: !!pageToken,
      },
    }
  }

  function addStudents({ courseId, students }, pageToken) {
    const studentData = state.students[courseId]?.data || []
    studentData.push(...students)
    state.students = {
      ...state.students,
      [courseId]: {
        data: studentData,
        loading: !!pageToken,
      },
    }
  }

  const getCourseStudents = useApiMethod(googleClassroomService.getStudents)
  async function loadStudentsForCourse({ courseId, force }) {
    const hasOrIsLoading = studentsForCourse.value(courseId) || studentsLoadingForCourse.value(courseId)
    if (hasOrIsLoading && !force) return

    state.students = {
      ...state.students,
      [courseId]: {
        data: null,
        loading: true,
      },
    }

    let pageToken = ''

    const loadStudentPage = async () => {
      await getCourseStudents.load({ courseId, pageToken })
      if (getCourseStudents.error.value) {
        if (isLinkError(getCourseStudents.error.value)) {
          activateLinkError()
        }
        if (getCourseStudents.error.value.name !== 'AbortError') {
          setStudents({ courseId, students: null })
        }
      } else {
        pageToken = getCourseStudents.response.value.page_token
        addStudents({ courseId, students: getCourseStudents.response.value.students }, pageToken)
      }
    }

    do {
      await loadStudentPage()
    } while (!!pageToken && !getCourseStudents.error.value)
  }

  function handleGoogleStudentLinkChange(student) {
    if (!student.googleClassroomUserData) return

    const mapStudent = (s) => {
      if (student.googleClassroomUserData.profileId === s.profileId) {
        return {
          ...s,
          userId: student.id,
        }
      }
      return s
    }

    state.students = Object.keys(state.students).reduce((hash, courseId) => {
      if (!hash[courseId]) return hash

      hash[courseId] = {
        ...state.students[courseId],
        data: state.students[courseId].data.map(mapStudent),
      }
      return hash
    }, {})
  }

  async function associateGoogleStudent(payload) {
    const updatedStudent = await googleClassroomService.associateGoogleStudent(payload)
    handleGoogleStudentLinkChange(updatedStudent)
  }

  async function disassociateGoogleStudent(payload) {
    const updatedStudent = await googleClassroomService.disassociateGoogleStudent(payload)
    handleGoogleStudentLinkChange(updatedStudent)
  }

  async function syncGoogleClassroomData() {
    await googleClassroomService.syncData()
  }

  return {
    state,
    profileId,
    profileEmail,
    profileName,
    studentsLoadingForCourse,
    studentsForCourse,
    coursesLoading,
    coursesData,
    courseAssignmentsLoading,
    courseAssignmentsData,
    hasCourseAssignments,
    lzStudentsData,
    lzStudentsLoading,
    isConnectedToGoogle,
    dismissGoogleCallToAction,
    registerLinkErrorListener,
    loadProfile,
    unlinkProfile,
    loadLzStudents,
    loadCourses,
    loadCourseAssignments,
    loadStudentsForCourse,
    handleGoogleStudentLinkChange,
    associateGoogleStudent,
    disassociateGoogleStudent,
    syncGoogleClassroomData,
  }
})
