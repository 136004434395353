import { useItemAnalysisStore } from './composables/use_item_analysis_store'
import messages from './i18n/item_analysis_messages'
import useRouter from './composables/use_router'
import ItemAnalysis from './components/ItemAnalysis'
import ItemAnalysisService from './api'
import useApiMethod from 'vue_features/shared/composables/use_api_method'

export default function ({ el, locale, state, props }) {
  const { router } = useRouter()
  const { $set } = useItemAnalysisStore()

  $set({
    ...state,
    getItemPreviewConfig: useApiMethod(ItemAnalysisService.getItemPreviewConfig),
    getAvailableGradeLevels: useApiMethod(ItemAnalysisService.getAvailableGradeLevels),
    getSchoolSuggestions: useApiMethod(ItemAnalysisService.getSchoolSuggestions),
    getTeacherSuggestions: useApiMethod(ItemAnalysisService.getTeacherSuggestions),
    getLessonPlanSuggestions: useApiMethod(ItemAnalysisService.getLessonPlanSuggestions),
    getItemScoreCounts: useApiMethod(ItemAnalysisService.getItemScoreCounts),
    getAssignmentItemScoreSummary: useApiMethod(ItemAnalysisService.getAssignmentItemScoreSummary),
    getAssignmentItemStandards: useApiMethod(ItemAnalysisService.getAssignmentItemStandards),
    getAssignmentAverageSessionScore: useApiMethod(ItemAnalysisService.getAssignmentAverageSessionScore),
  })

  return { component: ItemAnalysis, messages, router }
}
