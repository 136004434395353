<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div v-if="!isStudent && collection !== null" class="collection">
    <div class="relative" style="max-width: 99%">
      <div class="collection__content">
        <a
          :class="['cursor-pointer', { expanded: expanded }]"
          :data-test="expanded ? 'expanded' : 'closed'"
          @click="toggleExpand"
        >
          <div class="icon">
            <InlineSvg path="icons/next" />
          </div>
          {{ $t('label') }}
        </a>
        <div class="collection__list space-y-4">
          <div>
            <div class="page-head__pretext">
              {{ $t('heading') }}
            </div>
            <h5 class="bold">
              <a v-if="collection.url" :href="collection.url">{{ collection.title }}</a>
              <template v-else>{{ collection.title }}</template>
            </h5>
          </div>
          <RelatedContentList :collection="collection" :item-id="itemId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InlineSvg } from 'vue_features/shared/components/ui'
import RelatedContentList from './RelatedContentList'
import { ref } from 'vue'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'RelatedContent',
  components: { InlineSvg, RelatedContentList },
  props: {
    collection: { type: Object, default: null },
    itemId: { type: Number, required: true },
  },
  setup() {
    const expanded = ref(false)
    const { isStudent } = useCurrentUserStore()
    return { expanded, isStudent }
  },
  methods: {
    toggleExpand() {
      return (this.expanded = !this.expanded)
    },
  },
}
</script>
