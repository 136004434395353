import { getInsertRank } from '/utils/card_rank_helpers'
import { ref, onBeforeUnmount } from 'vue'

const movingBlock = ref(null)
const isMoving = ref(false)

export function useMovingState() {
  return { isMoving }
}

let placeholder

export default function useDraggableBlocks(props) {
  // global element used for the drag/drop indicator
  if (!placeholder) {
    placeholder = document.createElement('div')
    placeholder.className = 'block-drag-indicator'
    document.body.appendChild(placeholder)
  }
  onBeforeUnmount(() => placeholder.remove())

  function setBlockInsertAttributes(block, index) {
    if (!props.isAddingInserts) {
      return
    }

    if (props.modelValue[index - 1]) {
      block.blockInsert.prevBlockId = props.modelValue[index - 1].id
    } else {
      block.blockInsert.prevBlockId = null
    }

    if (props.modelValue[index + 1]) {
      block.blockInsert.nextBlockId = props.modelValue[index + 1].id
    } else {
      block.blockInsert.nextBlockId = null
    }
  }

  function startMove(block, evt) {
    if (!isMoving.value) {
      isMoving.value = true
      movingBlock.value = block
      setDragImage(evt)
    }
  }

  function insertAt(index, newBlocks) {
    newBlocks.reverse().forEach((block) => {
      block.rank = getInsertRank(index, props.modelValue)
      const oldBlock = props.modelValue[index]
      props.modelValue.splice(index, 0, block)
      setBlockInsertAttributes(block, index, props.modelValue)
      if (oldBlock && oldBlock.blockInsert) {
        setBlockInsertAttributes(oldBlock, index + 1, props.modelValue)
      }
    })
  }

  function endMove({ oldIndex, newIndex }) {
    if (movingBlock.value) {
      removeDraggedBlock({ oldIndex })
      addDraggedBlock({ newIndex })
    }
  }

  function setDragImage(evt) {
    const list = document.querySelector('.wiki-blocks')
    placeholder.style.width = `${list.clientWidth}px`
    evt.dataTransfer.setDragImage(placeholder, 0, 0)
  }

  function removeDraggedBlock({ oldIndex }) {
    props.modelValue.splice(oldIndex, 1)
  }

  function addDraggedBlock({ newIndex }) {
    if (movingBlock.value.sectionId !== props.sectionId) {
      ensureUniqueInsert(props.sectionId)
    }
    movingBlock.value.sectionId = props.sectionId
    if (props.isAddingInserts && !movingBlock.value.blockInsert) {
      movingBlock.value.blockInsert = { type: 'BlockInsert' }
    }
    insertAt(newIndex, [movingBlock.value])
    movingBlock.value = null
  }

  function ensureUniqueInsert() {
    const oldSectionId = movingBlock.value.sectionId
    if (oldSectionId === null || !props.isAddingInserts || !movingBlock.value.blockInsert) {
      return
    }

    const oldSection = getBlock(oldSectionId) || {}
    if (oldSection.blockInsert && oldSection.blockInsert.id === movingBlock.value.blockInsert.id) {
      movingBlock.value.blockInsert = { type: 'BlockInsert' }
    }
  }

  function getBlock(id) {
    let allBlocks = [...props.modelValue]
    while (allBlocks.length > 0) {
      const block = allBlocks.pop()
      if (block.id === id) {
        return block
      } else if (block.content.blocks) {
        allBlocks = allBlocks.concat(block.content.blocks)
      }
    }
    return null
  }

  function endDrag() {
    isMoving.value = false
  }

  return { isMoving, movingBlock, insertAt, startMove, endMove, endDrag, removeDraggedBlock, addDraggedBlock }
}
