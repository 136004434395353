<template>
  <ItemPoolPendingNotice v-if="assignment.itemPoolPending" />
  <div v-else id="assignment-page">
    <Banner v-if="!isLessonPlanAccessible" type="notice">
      {{ $t('show.unshared_lesson_plan_msg') }}
    </Banner>
    <OfflineNotice v-if="!isInstructor && hasQuestionCards" />
    <AssignmentLoginModal
      v-if="showLogin"
      :assignment-id="assignment.id"
      :learnosity-session-id="assignmentViewLearnositySessionId"
      :student-only="needsStudentOnlyLogin"
      data-test="login-modal"
    />
    <div class="page-head page-head--wide">
      <div class="page-head__wrapper px-4 print:px-0">
        <div class="page-head__content mx-auto max-w-7xl flex-1 p-0">
          <div class="lesson-title flex flex-col items-start sm:flex-row">
            <div class="mt-4 flex w-full flex-1 flex-row flex-wrap space-y-4">
              <div class="flex flex-1 flex-col items-start">
                <h2 class="mb-0 pr-0 text-3xl leading-10 sm:pr-16">
                  <div class="flex flex-1 items-start gap-x-4">
                    <div class="hidden w-12 sm:block">
                      <InlineSvg
                        aria-hidden="true"
                        class="border-3 -mt-0.5 h-12 rounded-xl border-white bg-white"
                        :path="`icons/${lessonPlan.lessonType}`"
                      />
                    </div>
                    <div class="inline-flex flex-col gap-x-2">
                      <div class="flex items-center">
                        <span
                          itemprop="educationalUse"
                          class="mb-0 text-sm font-semibold uppercase leading-5 tracking-wider text-blue-500"
                        >
                          {{ $t('show.assignment') }}
                        </span>
                        <LzContentStateBadge
                          v-if="isStudent"
                          :state="assignmentViewState"
                          class="my-0 ml-2 leading-3"
                        />
                      </div>
                      <span data-test="lesson-plan-title">{{ lessonPlan.title }}</span>
                    </div>
                  </div>
                </h2>
                <ul class="page-head__metadata list-metadata mt-4 flex flex-col pl-0 sm:pl-16">
                  <li data-test="assigned-by" class="no-fullstory text-muted">
                    <span class="font-bold">{{ $t('show.assigned_by') }}</span>
                    {{ assignment.userName || $t('show.lz_admin') }}
                    on
                    <LzTooltip
                      v-if="isInstructor"
                      :title="$t('show.snapshot_message')"
                      placement="right"
                      tag="span"
                      class="underline-border text-muted border-inverted"
                      data-test="snapshot-message-tooltip"
                    >
                      {{ friendlyLongDate(assignment.createdAt) }}
                    </LzTooltip>
                    <span v-else>
                      {{ friendlyLongDate(assignment.createdAt) }}
                    </span>
                  </li>
                  <li v-if="isInstructor && totalPoints" class="space-x-1">
                    <strong>{{ $t('show.total_points') }}</strong>
                    <span>{{ totalPoints }}</span>
                  </li>
                  <li v-if="isInstructor" class="space-x-1">
                    <strong>{{ capitalize(lessonPlan.readableType) }}</strong>
                    <a :href="Routes.lesson_plan_path(lessonPlan.id)">{{ lessonPlan.title }}</a>
                  </li>
                </ul>
              </div>
              <template v-if="!isInstructor && assignmentViewState">
                <div class="mt-1 flex flex-col items-end gap-y-2">
                  <div class="space-x-1">
                    <span class="text-sm font-bold">{{ $t('show.assignment_status') }}</span>
                    <Badge class="text-base">{{ capitalize(assignmentViewState) }}</Badge>
                  </div>
                  <div v-if="isAssignmentViewReturned" class="flex items-center gap-1">
                    <span class="text-sm font-bold">{{ $t('show.assignment_score') }}</span>
                    <AssignmentScoreInfo
                      :assignment-id="assignment.id"
                      :assignment-state="assignmentViewState"
                      :learnosity-session-id="assignmentViewLearnositySessionId"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div v-if="isInstructor" class="page-head__actions--pretext mt-4 flex gap-2 lg:flex-col">
              <ProgressCover :visible="isModalBusy" />
              <AssignMenu
                :disabled="!isLessonPlanAccessible"
                :lz-code="assignment.code"
                :assignment-url="assignment.url"
                :lti-url="assignment.ltiLaunchUrl"
                :with-embed="canEmbed"
              />
            </div>
          </div>
          <div v-if="hasClosedNotice" class="lzui-tile bg-orange-50 border-orange-200 my-4 overflow-hidden">
            <LzIcon
              type="div"
              path="icons/close"
              class="text-orange-700 hover:text-orange-900 float-right mr-2 mt-1.5 cursor-pointer"
              @click="hasClosedNotice = false"
            />
            <p class="small py-3 pl-3 pr-6">
              {{ hasClosedGate ? $t('show.secure_assignment_closed') : $t('show.assignment_closed') }}
            </p>
          </div>
          <Comment v-if="comment && comment.content" :comment="comment" />
        </div>
      </div>
      <div v-if="needsStudentOnlyLogin" class="w-full">
        <ImageTag class="mx-auto max-h-80" path="misc/content-placeholder.png" alt="content placeholder" />
      </div>
    </div>
    <template v-if="!needsStudentOnlyLogin">
      <RouterView v-if="!isAssignmentViewReturned" data-test="assignment-content" />
      <div v-else class="page-content" data-test="returned-assignment">
        <div id="lesson" class="active lesson-plan__lesson">
          <Grid disable-default="max-w" class="mx-auto max-w-7xl">
            <div class="col-span-10 md:col-start-2">
              <ReturnedAssignment />
            </div>
          </Grid>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, provide } from 'vue'
import { capitalize } from 'lodash'
import { friendlyLongDate } from 'vue_features/shared/filters/datetime'
import {
  Badge,
  Banner,
  Grid,
  ImageTag,
  InlineSvg,
  LzIcon,
  LzTooltip,
  ProgressCover,
} from 'vue_features/shared/components/ui'
import Comment from 'vue_features/shared/components/Comment'
import { AssignmentScoreInfo, ItemPoolPendingNotice } from 'vue_features/assignments/shared/components'
import AssignMenu from 'vue_features/assignments/prompt/components/AssignMenu'
import OfflineNotice from './OfflineNotice'
import ReturnedAssignment from './content/returned/ReturnedAssignment'
import AssignmentLoginModal from './login/AssignmentLoginModal'
import { getIcon } from 'vue_features/shared/composables/use_content_type_metadata'
import { openModal, canModalShow, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useAssignmentStore, useAssignmentShowStore, useAssignmentCardsStore } from '../composables'
import GradingSessionService from 'vue_features/grading/api/grading_session_service'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import Routes from 'routes'

export default {
  name: 'AssignmentShow',
  components: {
    Badge,
    Banner,
    Grid,
    ImageTag,
    LzIcon,
    LzTooltip,
    LzContentStateBadge,
    ProgressCover,
    Comment,
    AssignmentScoreInfo,
    ItemPoolPendingNotice,
    AssignMenu,
    OfflineNotice,
    ReturnedAssignment,
    AssignmentLoginModal,
    InlineSvg,
  },
  props: {
    canUpdate: { type: Boolean, required: true },
    hasNotBeenConsumed: { type: Boolean, required: true },
    canEmbed: { type: Boolean, required: true },
    comment: { type: Object, default: null },
    hasClosedGate: { type: Boolean, required: true },
    totalPoints: { type: Number, default: null },
  },
  setup() {
    const { assignment, lessonPlan } = useAssignmentStore()
    const {
      assignmentView,
      isAssignmentViewReturned,
      assignmentViewState,
      assignmentViewLearnositySessionId,
      isInstructor,
      isLessonPlanAccessible,
    } = useAssignmentShowStore()
    const { hasQuestionCards } = useAssignmentCardsStore()
    const { isLoggedIn, isStudent } = useCurrentUserStore()

    const hasClosedNotice = ref(false)

    onBeforeMount(() => {
      hasClosedNotice.value = isAssignmentViewReturned.value
      if (!isLoggedIn.value) openModal(AssignmentLoginModal)
      if (assignmentView.value?.id) {
        // Allow remaining comments to be loaded (via AJAX) before marking as "read"
        setTimeout(() => GradingSessionService.markCommentsRead(assignment.value.code, assignmentView.value.id), 3000)
      }
    })

    provide('eventSource', 'assignment')

    return {
      Routes,
      capitalize,
      friendlyLongDate,
      isModalBusy,
      assignment,
      lessonPlan,
      isAssignmentViewReturned,
      assignmentViewState,
      assignmentViewLearnositySessionId,
      isInstructor,
      isLessonPlanAccessible,
      hasQuestionCards,
      hasClosedNotice,
      getIcon,
      isStudent,
      showLogin: computed(() => canModalShow(AssignmentLoginModal)),
      needsStudentOnlyLogin: computed(() => !isLoggedIn.value && assignment.value.assessment),
    }
  },
}
</script>
