<!--this is extended from vSelect in order to grant more control within the template-->
<script>
import vSelect from 'vue-select'

export default {
  name: 'LzSelect',
  extends: vSelect,
  props: {
    ...vSelect.props,
    outsideSelections: { type: Array, default: () => [] },
  },
  updated() {
    this.$nextTick(() => {
      if (this.outsideSelections.length === 0) {
        return
      }

      this.filteredOptions.forEach((option, index) => {
        if (this.outsideSelections.some((value) => this.optionComparator(value, option))) {
          if (this.$refs.dropdownMenu) {
            this.$refs.dropdownMenu.querySelector(`li:nth-child(${index + 1})`).classList.add('active')
          }
        }
      })
    })
  },
}
</script>
