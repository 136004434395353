<!-- eslint-disable vuejs-accessibility/form-control-has-label  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="search">
    <div class="search-box">
      <form @submit.prevent>
        <input
          :value="modelValue"
          :placeholder="placeholder"
          class="search-query form-control"
          type="search"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <button :aria-label="$t('search')" class="search-submit float-right focus:ring-2" type="submit">
          <LzIcon size="sm" icon-color-class="text-white" path="icons/search" class="mx-auto" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { search } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'

export default {
  name: 'SearchBox',
  i18n: { messages: pickLocaleNamespace(search, 'search') },
  components: { LzIcon },
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true },
  },
}
</script>
