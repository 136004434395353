<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <draggable
    :model-value="modelValue"
    :disabled="modelValue.length === 0"
    :item-key="(block) => `${block.rank}-item`"
    :class="[
      'wiki-blocks',
      { moving: isMoving, 'rounded-b-md': modelValue.length > 0, 'rounded-md': modelValue.length === 0 },
    ]"
    tag="ul"
    handle=".drag-wiki"
    ghost-class="ghost-wiki-block"
    group="wiki-blocks"
    @remove="removeDraggedBlock"
    @add="addDraggedBlock"
    @end="endMoveBlock"
  >
    <template #header>
      <li v-if="modelValue.length === 0" class="blank-slate mt-0 p-4 pt-0">
        <EmptyState v-if="inSection" :body="emptyStateBody" />
        <EmptyStateWithIcon v-else path="misc/placeholder-wiki" :body="emptyStateBody" />
        <WikiInsertBtn
          v-bind="{ alwaysOpen: true, sectionId, inSection, totalBlockCount, isAddingInserts }"
          @insert-blocks="insertAt(0, $event)"
        />
      </li>
    </template>
    <template #item="{ element, index }">
      <li
        :class="[
          'rounded-md p-4',
          {
            'meta-insert': blockIsInserted(element),
            'meta-hide': blockIsHidden(element),
            'has-update': hasUpdate(element),
          },
        ]"
        @dragstart="startMove(element, $event)"
      >
        <WikiInsertBtn
          v-bind="{ sectionId, inSection, totalBlockCount, isAddingInserts }"
          class="insert-wiki-block--above"
          @insert-blocks="insertAt(index, $event)"
        />
        <div v-if="blockIsEditable(element)" class="up-down-arrows">
          <InlineSvg :class="{ invisible: index <= 0 }" path="icons/caret" @click="moveUp(element)" />
          <InlineSvg path="icons/move-grip" class="handle drag-wiki" />
          <InlineSvg
            :class="{ invisible: index >= modelValue.length - 1 }"
            path="icons/caret"
            @click="moveDown(element)"
          />
        </div>
        <WikiBlockEditor
          v-if="blockIsEditable(element)"
          v-bind="{ block: element, index, editorId, totalBlockCount, isRefreshing: isTableRefreshing(element) }"
          class="wiki-content w-full"
          @update-block="$emit('update-block', { block: element, updates: $event })"
          @delete-block="deleteBlock(element)"
        />
        <WikiBlockHider
          v-else
          v-bind="{ block: element, index, editorId, totalBlockCount }"
          class="wiki-content w-full"
          @update-block="$emit('update-block', { block: element, updates: $event })"
        />
        <UpdatedNotification
          v-if="isAddingInserts && hasUpdate(element)"
          v-bind="blockUpdate(element)"
          :creation-verb="creationVerb(element)"
        />
        <ListDropdown
          v-if="blockIsEditable(element)"
          id="wiki-card-context"
          :button-classes="['ml-1', 'p-1']"
          :dropdown-classes="['right-0']"
        >
          <template #actionButton="{ id: buttonId, fullButtonClasses, toggle, isOpen }">
            <button
              :data-toggle="buttonId"
              :class="fullButtonClasses"
              :aria-expanded="isOpen ? 'true' : 'false'"
              :aria-label="$t('shared.assignment_prompt.more_options')"
              type="button"
              @click.prevent.stop="toggle"
            >
              <LzIcon path="icons/dots-vertical" size="sm" class="fill-current" />
            </button>
          </template>
          <template #listItems>
            <li v-for="item in menuItems(element)" :key="item.key">
              <button type="button" :data-test="`wiki-card-${item.key}`" @click="menuClick(item)">{{
                item.label
              }}</button>
            </li>
          </template>
        </ListDropdown>
        <WikiInsertBtn
          v-bind="{ sectionId, inSection, totalBlockCount, isAddingInserts }"
          class="insert-wiki-block--below"
          @insert-blocks="insertAt(index + 1, $event)"
        />
      </li>
    </template>
  </draggable>
</template>

<script>
import { ref, inject } from 'vue'
import Routes from 'routes'
import { location } from 'utils'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import WikiInsertBtn from './WikiInsertBtn'
import WikiBlockEditor from './WikiBlockEditor'
import WikiBlockHider from 'vue_features/wiki_edits/components/WikiBlockHider'
import { LzIcon, EmptyState, EmptyStateWithIcon, InlineSvg } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import draggable from 'vuedraggable'
import { isHyperlink } from 'vue_features/shared/composables/use_resource_types'
import useDraggableBlocks from '../composables/use_draggable_blocks'
import useDeletedBlocks from '../composables/use_deleted_blocks'
import UpdatedNotification from 'vue_features/shared/components/UpdatedNotification'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import StandardsCoverageTableService from 'vue_features/standards_coverage_table/api'

export default {
  name: 'WikiDraggableBlocks',
  components: {
    LzIcon,
    EmptyState,
    EmptyStateWithIcon,
    UpdatedNotification,
    WikiInsertBtn,
    WikiBlockEditor,
    WikiBlockHider,
    InlineSvg,
    ListDropdown,
    draggable,
  },
  props: {
    modelValue: { type: Array, required: true },
    editorId: { type: String, required: true },
    sectionId: { type: Number, default: null },
    inSection: { type: Boolean, default: false },
    totalBlockCount: { type: Number, required: true },
    isAddingInserts: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { isMoving, insertAt, startMove, endMove, removeDraggedBlock, addDraggedBlock, endDrag } =
      useDraggableBlocks(props)
    const { addDeletedBlock } = useDeletedBlocks()
    const { id } = useCurrentCustomerStore()

    const refreshingTableIds = ref([])
    const isTableRefreshing = (block) =>
      block.content?.tableId ? refreshingTableIds.value.includes(block.content.tableId) : false
    const root = inject('useRoot')()
    const emptyStateBody = root.$t('wikis.start_label', { type: props.inSection ? 'section' : 'page' })

    const removeBlock = (block) => {
      const currentIndex = props.modelValue.indexOf(block)
      emit('remove-block', currentIndex)
      return currentIndex
    }
    const openStandardsModal = (block) => {
      openModal(`standards-coverage-table-modal`, {
        onSave: (tableProps) => emit('update-block', { block, updates: { content: tableProps } }),
        content: block.content,
      })
    }
    const refresh = (block) => {
      const tableId = block.content.tableId
      refreshingTableIds.value.push(tableId)
      StandardsCoverageTableService.update(tableId).then((tableData) => {
        const { displayBy, contentType, parentWikiId, id } = tableData
        refreshingTableIds.value.splice(refreshingTableIds.value.indexOf(tableId), 1)
        emit('update-block', { content: { displayBy, parentWikiId, contentType, tableId: id } })
      })
    }
    const editLink = (block) => {
      const link = isHyperlink(block.content.resource)
        ? Routes.hyperlink_path(block.content.id)
        : block.content.lzContent.href
      return link + '/edit'
    }
    const viewLink = (block) => {
      return isHyperlink(block.content.resource)
        ? Routes.hyperlink_path(block.content.id)
        : block.content.lzContent.href
    }
    const standardsItems = (block) => {
      return [
        { key: 'edit', label: root.$t('common.edit'), action: () => openStandardsModal(block) },
        { key: 'refresh', label: root.$t('wikis.refresh'), action: () => refresh(block) },
      ]
    }
    const listableItems = (block) => {
      const hasContent = !block.content?.missing || !block.content?.id
      const type = hasContent ? block.content.type || block.content.lzContent.type : null
      const menuOptions = [
        {
          key: 'list',
          label: root.$t('wikis.list_view'),
          action: () => emit('update-block', { block, updates: { display: 'list' } }),
        },
        {
          key: 'block',
          label: root.$t('wikis.block_view'),
          action: () => emit('update-block', { block, updates: { display: 'block' } }),
        },
      ]
      if (['Audio', 'Hyperlink', 'Video', 'Document'].includes(type))
        menuOptions.push({
          key: 'embed',
          label: root.$t('wikis.embed_view'),
          action: () => emit('update-block', { block, updates: { display: 'embed' } }),
        })
      if (block.content?.lzContent?.customerId === id.value)
        menuOptions.push({
          key: 'edit',
          label: root.$t('wikis.edit_content'),
          action: () => location.newTab(editLink(block)),
        })
      else
        menuOptions.push({
          key: 'view',
          label: root.$t('wikis.view_content'),
          action: () => location.newTab(viewLink(block)),
        })
      return menuOptions
    }
    const menuItems = (block) => {
      let items = []
      const displayType = block.display + (block.detailType || block.contentType)
      if (!['embedHtml', 'embedSection'].includes(displayType)) {
        items = displayType === 'embedStandardsCoverageTable' ? standardsItems(block) : listableItems(block)
      }
      items.push({ key: 'delete', label: root.$t('common.delete'), action: () => deleteBlock(block) })
      return items
    }
    const menuClick = (item) => {
      item.action()
    }
    const moveUp = (block) => insertAt(removeBlock(block) - 1, [block])
    const moveDown = (block) => insertAt(removeBlock(block) + 1, [block])
    const deleteBlock = (block) => {
      addDeletedBlock(block)
      removeBlock(block)
    }
    const blockIsInserted = (block) => block.blockInsert?.customerId === id.value
    const blockIsEditable = (block) => !props.isAddingInserts || blockIsInserted(block)
    const blockIsHidden = (block) => !!block.blockHide
    const hasUpdate = (block) => {
      const update = (block.blockHide || block.blockInsert)?.lastUpdate
      return !!update && (blockIsHidden(block) || blockIsEditable(block))
    }
    const blockUpdate = (block) => {
      if (blockIsHidden(block)) return block.blockHide.lastUpdate
      if (block.lastUpdate && block.blockInsert.lastUpdate) {
        return new Date(block.lastUpdate.createdAt) > new Date(block.blockInsert.lastUpdate.createdAt)
          ? block.lastUpdate
          : block.blockInsert.lastUpdate
      }
      return block.blockInsert.lastUpdate
    }
    const creationVerb = (block) => {
      return blockIsHidden(block) ? root.$t('updated_notification.hid') : root.$t('updated_notification.added')
    }
    const endMoveBlock = (event) => {
      endMove(event)
      endDrag()
    }

    return {
      isMoving,
      insertAt,
      startMove,
      removeDraggedBlock,
      addDraggedBlock,
      endDrag,
      id,
      isTableRefreshing,
      emptyStateBody,
      menuItems,
      menuClick,
      moveUp,
      moveDown,
      deleteBlock,
      blockIsInserted,
      blockIsEditable,
      blockIsHidden,
      hasUpdate,
      blockUpdate,
      creationVerb,
      endMoveBlock,
    }
  },
}
</script>
