import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

export default {
  getAssignment(lzCodeId) {
    const url = Routes.lz_code_path(lzCodeId)

    return HTTP.getJSON(url)
  },

  getAssignmentReports({
    code,
    learnosityIdentifier,
    klassId,
    page,
    perPage,
    studentsForCurrentTeacher,
    withResponses,
  }) {
    const url = Routes.lz_code_assignment_reports_path(code)
    const params = snakeKeys({ learnosityIdentifier, klassId, page, perPage, studentsForCurrentTeacher, withResponses })

    return HTTP.getJSON(url, params)
  },

  getKlassesForAssignment(lzCodeId) {
    const url = Routes.lz_code_klasses_path(lzCodeId)

    return HTTP.getJSON(url)
  },

  getKlass(code, id) {
    const url = Routes.lz_code_klass_path(code, id)

    return HTTP.getJSON(url)
  },

  changeAssignmentStateForKlass({ klassId, assignmentId, assignmentViewIds, assignmentState, openAt, closeAt }) {
    const url = Routes.lz_code_klass_path(assignmentId, klassId)
    const params = { lzCodeViewIds: assignmentViewIds, state: assignmentState, openAt, closeAt }

    return HTTP.put(url, snakeKeys(params))
  },
}
