<template>
  <div class="google-classroom-student-search m-2">
    <div class="relative p-0" @click.prevent.stop>
      <label for="student-search-gc" class="sr-only">{{ $t('common.search_placeholder_short') }}</label>
      <input
        id="student-search-gc"
        v-model="filterText"
        class="google-classroom-student-search__input"
        :placeholder="$t('common.search_placeholder_short')"
        type="text"
      />
      <div class="bg-google-blue google-classroom-student-search__icon absolute">
        <LzIcon size="sm" icon-color-class="text-white" path="icons/search" />
      </div>
    </div>
    <ul class="no-bullet list-rows google-classroom-student-search__list">
      <LinkStudentListItem
        v-for="googleStudent in filteredGoogleStudents"
        :key="googleStudent.profileId"
        :google-student="googleStudent"
        :confirming="confirmingStudent && confirmingStudent.profileId == googleStudent.profileId"
        :selected-item="currentGoogleStudentProfileId === googleStudent.profileId"
        @link-student="confirmAndNotify(googleStudent)"
        @link-reject="confirmingStudent = null"
        @link-confirm="confirmAndNotify(googleStudent, true)"
      />
      <li v-if="currentGoogleStudentProfileId" class="google-classroom-associate-dropdown cursor-pointer p-2">
        <button class="small" :title="$t('common.google_classroom.unlink')" @click="unlinkStudent">
          <span class="google-unlink">
            {{ $t('common.google_classroom.unlink') }}
          </span>
        </button>
      </li>
      <li v-if="filteredGoogleStudents.length === 0" class="google-classroom-associate-dropdown p-2">
        <button class="text-accent hover:text-accent-hover" @click="goToCourse">
          <LzTooltip tooltip-classes="google-dark-tooltip">
            <span class="smaller center">
              {{ $t('common.google_classroom.no_students_in_course') }}
            </span>

            <template #content>
              <div class="space-y-4">
                <p>{{ $t('common.google_classroom.no_students_in_course_detail', { course_name: courseName }) }}</p>
                <p>{{ $t('common.google_classroom.click_to_visit_classroom') }}</p>
              </div>
            </template>
          </LzTooltip>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import LinkStudentListItem from './LinkStudentListItem'

export default {
  name: 'StudentSearch',
  components: {
    LzIcon,
    LzTooltip,
    LinkStudentListItem,
  },
  props: {
    currentGoogleStudentProfileId: {
      type: String,
      required: false,
      default: null,
    },
    googleStudents: {
      type: Array,
      required: true,
    },
    courseLink: {
      type: String,
      required: true,
    },
    courseName: {
      type: String,
      required: true,
    },
    inlineConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filterText: '',
      confirmingStudent: null,
    }
  },
  computed: {
    filteredGoogleStudents() {
      if (this.filterText && this.filterText.trim() !== '') {
        const regex = new RegExp(`.*${this.filterText.toLowerCase()}.*`)
        return this.googleStudents.filter((googleStudent) => regex.test(googleStudent.name.toLowerCase()))
      }
      return this.googleStudents
    },
  },
  methods: {
    confirmAndNotify(googleStudent, confirmed) {
      if (this.currentGoogleStudentProfileId === googleStudent.profileId) return

      if (this.inlineConfirm && !confirmed && (this.currentGoogleStudentProfileId || googleStudent.userId)) {
        this.confirmStudent(googleStudent)
        return
      }

      this.$emit('link-google-student', googleStudent)
    },
    confirmStudent(googleStudent) {
      this.confirmingStudent = googleStudent
    },
    unlinkStudent() {
      this.$emit('unlink-google-student')
    },
    goToCourse() {
      if (!this.courseLink) return
      const url = `${this.courseLink.replace(/\/c\//, '/r/')}/sort-last-name`
      window.open(url, '_blank')
    },
  },
}
</script>
