<template>
  <div class="relative flex items-center">
    <button
      type="button"
      :tabindex="tabindex"
      class="focus:outline-none group relative mx-auto inline-flex h-12 w-12 items-center justify-center rounded-lg"
      :class="{ 'bg-inverted text-white': selected, ' hover:bg-base-hover': !selected }"
      :data-test="`shape-button${selected ? '-selected' : ''}`"
      @click="handleClick"
    >
      <InlineSvg
        type="div"
        :data-test="`shape-${modelValue}`"
        :path="`icons/annotate-${modelValue}`"
        class="h-8 w-8"
        svg-classes="m-0"
      />
      <InlineSvg
        type="div"
        path="icons/arrow-popout"
        class="h-2.5 w-2"
        :class="{ 'text-white': selected }"
        svg-classes="m-0"
      />
      <AnnotationToolTip v-show="!selected" :text="$t(`annotation.shapes`)" />
    </button>

    <div
      v-if="isOpen"
      class="border-base absolute bottom-0 left-16 mt-2 w-40 rounded-2xl border bg-white p-2 shadow-lg"
      data-test="shape-picker-modal"
    >
      <button class="fixed left-0 top-0 z-10 h-full w-full bg-transparent" @click="isOpen = false"></button>

      <div class="relative z-20 grid grid-cols-3">
        <div
          v-for="(shape, index) in shapes"
          :key="index"
          class="group flex h-12 w-12 cursor-pointer items-center justify-center"
        >
          <button
            class="hover:bg-base flex h-full w-full rounded-2xl p-2 transition duration-300"
            :data-test="`shape-picker-${shape}`"
            @click="setSelectedShape(shape)"
            @keydown.enter="setSelectedShape(shape)"
          >
            <div
              role="radio"
              tabindex="0"
              :aria-checked="modelValue"
              class="focus:outline-none inline-flex h-full w-full items-center justify-center"
            >
              <InlineSvg type="div" :path="`icons/annotate-${shape}`" svg-classes="m-0" class="h-8 w-8" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'
import AnnotationToolTip from 'vue_features/shared/components/AnnotationToolTip'

export default {
  components: {
    AnnotationToolTip,
    InlineSvg,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    shapes: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const isOpen = ref(false)

    function handleClick() {
      if (props.selected) {
        isOpen.value = !isOpen.value
      } else {
        setSelectedShape(props.modelValue)
      }

      emit('click')
    }

    function setSelectedShape(shape) {
      isOpen.value = false

      emit('update:modelValue', shape)
    }

    return {
      handleClick,
      setSelectedShape,
      isOpen,
    }
  },
}
</script>
