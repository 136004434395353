<template>
  <div class="lz-learnosity-wrapper max-w-7xl px-3 md:px-6">
    <slot />
    <div id="learnosity-author" />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useLearnosityItemEditorStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_editor_store'

export default {
  name: 'LearnosityAuthorWrapper',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updateClient } = useLearnosityItemEditorStore()

    onMounted(async () => {
      await updateClient(props.config)
      emit('client-updated')
    })
  },
}
</script>
