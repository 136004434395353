<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <ProgressCover :visible="loading" />
    <div class="form-group">
      <div :class="{ error: errors.title }">
        <label for="lesson_plan_title">
          {{ $t('info_form.title') }}
          <span v-if="errors.title">{{ errors.title.join(', ') }}</span>
        </label>
        <input id="lesson_plan_title" v-model="title" class="form-control h1" type="text" name="lesson_plan[title]" />
      </div>
      <p class="small help-block italic">
        {{ $t('info_form.title_help', { lesson_type: readableType }) }}
      </p>

      <label for="lesson_plan_authors">
        {{ $t('common.created_by') }}
        <span class="weak regular">{{ $t('info_form.optional') }}</span>
      </label>
      <div class="no-fullstory">
        <input
          id="lesson_plan_authors"
          v-model="authors"
          class="form-control"
          type="text"
          name="lesson_plan[authors]"
        />
      </div>
      <p class="small help-block italic">{{ $t('info_form.created_by_help', { lesson_type: readableType }) }}</p>

      <template v-if="isPublic">
        <label class="no-fullstory" for="lesson_plan_coach">
          {{ $t('info_form.coach_name') }}
          <span class="weak regular">{{ $t('info_form.optional') }}</span>
        </label>
        <input
          id="lesson_plan_coach"
          v-model="coach"
          class="form-control no-fullstory"
          type="text"
          name="lesson_plan[coach]"
        />
        <p class="small help-block italic">{{ $t('info_form.coach_name_help', { lesson_type: readableType }) }}</p>
      </template>

      <Grid disable-default="md:px">
        <div class="col-span-6">
          <label for="lesson_plan_lesson_type">
            {{ $t('info_form.lesson_type') }}
          </label>
          <select id="lesson_plan_lesson_type" v-model="lessonType" name="lesson_plan[lesson_type]">
            <option v-for="option in $options.lessonTypes" :key="option.value" :value="option.value">
              {{ $t(option.translation) }}
            </option>
          </select>
          <p class="small help-block italic">{{ $t('info_form.lesson_type_help') }}</p>
        </div>

        <div class="col-span-6">
          <label for="lesson_plan_text_style" class="flex gap-x-1">
            <LzIcon size="sm" path="icons/users" />
            {{ $t('shared.text_style.label') }}
          </label>
          <select id="lesson_plan_text_style" v-model="textStyle" name="lesson_plan[text_style]">
            <option v-for="option in $options.textStyles" :key="option.value" :value="option.value">
              {{ $t(option.translation) }}
            </option>
          </select>
          <p class="small help-block italic">{{ $t('shared.text_style.help') }}</p>
        </div>
      </Grid>

      <StandardsSelector
        :standards="addressedStandards"
        :all-standards="allStandards"
        :optional="true"
        coverage-type="addressed"
        @set-standards="setAddressedStandards"
      />

      <StandardsSelector
        :standards="buildingOnStandards"
        :all-standards="allStandards"
        :optional="true"
        coverage-type="buildingOn"
        @set-standards="setBuildingOnStandards"
      />

      <StandardsSelector
        :standards="buildingTowardsStandards"
        :all-standards="allStandards"
        :optional="true"
        coverage-type="buildingTowards"
        @set-standards="setBuildingTowardsStandards"
      />

      <TagsSelector v-if="isPublic" :tags="tagNames" :readable-type="readableType" @set-tags="setTags" />

      <StudentToolsSelector :student-tools="studentTools" @set-tools="setTools" />

      <div v-if="authorizedAsAuthor" class="my-4">
        <label>{{ $t('common.subscription_required') }}</label>
        <SwitchToggle v-model="premium" label-off="No" label-on="Yes" />
      </div>

      <Grid disable-default="md:px">
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <CoverImageUpload
            :cover-image="coverImage"
            field-name="lesson_plan[cover_image]"
            placeholder="lessonplan-placeholder.svg"
            @upload-done="setCoverImage"
            @remove-file="setCoverImage"
          />
        </div>
      </Grid>
    </div>

    <footer v-if="isUpdatingLP" class="border-base border-t">
      <div class="mx-auto max-w-7xl p-3 md:p-6">
        <p class="align-right mb-0">
          <button class="btn" @click="cancel">
            {{ $t('common.cancel') }}
          </button>
          <button class="btn btn--primary" name="commit" type="submit" @click="save">
            {{ $t('common.save') }}
          </button>
        </p>
      </div>
    </footer>

    <p v-else class="align-right mb-6">
      <a :href="cancelUrl" class="small btn mr-3">
        {{ $t('common.cancel') }}
      </a>
      <button class="btn btn--primary" name="commit" type="submit" @click="save">
        {{ $t('info_form.save_lp', { lesson_type: readableType }) }}
      </button>
    </p>
  </div>
</template>

<script>
import { location } from 'utils'
import LessonPlansService from '../api/lesson_plans_service'
import StandardsSelector from 'vue_features/standards_selector/components/StandardsSelector'
import TagsSelector from './TagsSelector'
import StudentToolsSelector from './StudentToolsSelector'
import { LzIcon, ProgressCover, SwitchToggle } from 'vue_features/shared/components/ui'
import CoverImageUpload from 'vue_features/cover_image_upload/components/CoverImageUpload'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export const LESSON_TYPES = [
  { value: 'full', translation: 'info_form.full_lesson' },
  { value: 'video', translation: 'info_form.instructional_video' },
  { value: 'assessment', translation: 'info_form.assessment' },
  { value: 'activity', translation: 'common.activity' },
]

export default {
  name: 'InfoForm',
  components: {
    Grid,
    LzIcon,
    ProgressCover,
    CoverImageUpload,
    StudentToolsSelector,
    SwitchToggle,
    StandardsSelector,
    TagsSelector,
  },
  lessonTypes: LESSON_TYPES,
  textStyles: [
    { value: 'elementary', translation: 'shared.text_style.elementary' },
    { value: 'secondary', translation: 'shared.text_style.secondary' },
  ],
  props: {
    lessonPlan: {
      type: Object,
      default() {
        return {}
      },
    },
    cancelUrl: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { isPublic } = useCurrentCustomerStore()
    const { authorizedAsAuthor } = useCurrentUserStore()
    return { isPublic, authorizedAsAuthor }
  },
  data() {
    const tagNames = (this.lessonPlan.tagNames || []).map((tag) => {
      return { name: tag, label: tag }
    })

    const standards = [...this.lessonPlan.standards] || []

    return {
      errors: { title: '' },
      loading: false,
      title: this.lessonPlan.title || '',
      lessonType: this.lessonPlan.lessonType || this.$options.lessonTypes[0].value,
      authors: this.lessonPlan.authors || '',
      coach: this.lessonPlan.coach || '',
      buildingTowardsStandards: standards.filter((s) => s.coverageType === 'buildingTowards'),
      buildingOnStandards: standards.filter((s) => s.coverageType === 'buildingOn'),
      addressedStandards: standards.filter((s) => s.coverageType === 'addressed'),
      studentTools: this.lessonPlan.studentTools ? [...this.lessonPlan.studentTools] : [],
      tagNames,
      premium: this.lessonPlan.premium || false,
      textStyle: this.lessonPlan.textStyle,
      coverImage: this.lessonPlan.coverImage ? { ...this.lessonPlan.coverImage } : {},
      forkedFromId: this.lessonPlan.forkedFromId,
    }
  },
  computed: {
    readableType() {
      return this.$t(`readable_types.${this.lessonType}`)
    },
    isUpdatingLP() {
      return this.lessonPlan.id
    },
    allStandards() {
      const { buildingTowardsStandards, buildingOnStandards, addressedStandards } = this
      return buildingTowardsStandards.concat(buildingOnStandards, addressedStandards)
    },
    persistentAttributes() {
      return {
        title: this.title,
        lessonType: this.lessonType,
        authors: this.authors,
        coach: this.coach,
        lessonPlanStandards: this.allStandards.map((s) => {
          return { standardId: s.id, coverageType: s.coverageType }
        }),
        studentToolIds: this.studentTools || [],
        tagNames: this.tagNames.map((t) => t.name || t),
        premium: this.premium,
        textStyle: this.textStyle,
        coverImage: this.coverImage.baseUrl || '',
        forkedFrom: this.forkedFromId,
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.loading = true
      if (this.isUpdatingLP) {
        LessonPlansService.updateLessonPlan(this.lessonPlan.id, this.persistentAttributes).then(
          (response) => {
            this.$emit('lesson-plan-updated', response)
            this.loading = false
          },
          (errors) => {
            document.getElementById('lp-info-form-modal').scrollTop = 0
            this.errors = errors.responseJSON
            this.loading = false
          },
        )
      } else {
        LessonPlansService.createLessonPlan(this.persistentAttributes).then(
          (response) => {
            const newLessonPlanId = response.id
            location.assign(Routes.edit_lesson_plan_url(newLessonPlanId))
          },
          (errors) => {
            document.getElementsByTagName('html')[0].scrollTop = 0
            this.errors = errors.responseJSON
            this.loading = false
          },
        )
      }
    },
    setCoverImage(coverImage = {}) {
      this.coverImage = coverImage
    },
    setTags(selectedTags) {
      this.tagNames = selectedTags
    },
    setTools(activeTools) {
      this.studentTools = activeTools
    },
    setBuildingTowardsStandards(selectedStandards) {
      this.removeDuplicatedStandards(selectedStandards, this.buildingOnStandards, this.addressedStandards)
      this.buildingTowardsStandards = selectedStandards
    },
    setAddressedStandards(selectedStandards) {
      this.removeDuplicatedStandards(selectedStandards, this.buildingOnStandards, this.buildingTowardsStandards)
      this.addressedStandards = selectedStandards
    },
    setBuildingOnStandards(selectedStandards) {
      this.removeDuplicatedStandards(selectedStandards, this.buildingTowardsStandards, this.addressedStandards)
      this.buildingOnStandards = selectedStandards
    },
    removeDuplicatedStandards(selectedStandards, ...standardLists) {
      selectedStandards.forEach((standard) => {
        standardLists.forEach((list) => this.removeStandard(standard, list))
      })
    },
    removeStandard(standard, standardArray) {
      const index = standardArray.findIndex((s) => s.uuid === standard.uuid)
      if (index >= 0) {
        standardArray.splice(index, 1)
      }
    },
  },
}
</script>
