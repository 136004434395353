<template>
  <DetailListItem :icon="defaultIcon" :href="contentUrl" :show-thumbnail="false" :metadata="metadata" class="relative">
    <slot />
    <template #title>
      <span v-if="isLostContent" class="flex flex-col gap-1">
        <span class="w-full overflow-x-hidden truncate">
          {{ title }}
        </span>
        <span class="max-w-max rounded-full bg-red-200 px-2 text-sm font-bold leading-5 tracking-wide text-red-700">
          {{ $t('index.content_lost') }}
        </span>
      </span>
      <template v-if="isNormalCard">
        <p class="text-accent group-hover:text-accent-hover text-xl font-bold">
          {{ title }}
        </p>
        <LzContentStateBadge :state="content.state" />
      </template>
      <MetadataByline v-if="fromMetadata" :logo="fromMetadata.logo" :name="fromMetadata.name" />
    </template>
  </DetailListItem>
</template>

<script>
import { capitalize } from 'lodash'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { computed } from 'vue'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import MetadataByline from 'vue_features/shared/components/lists/MetadataByline'
import { getIcon, getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { isDraft } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'MaterialDetailItem',
  components: { MetadataByline, DetailListItem, LzContentStateBadge },
  props: {
    card: { type: Object, required: true },
  },
  setup(props) {
    const cardId = computed(() => props.card.id)
    const cardType = computed(() => props.card.type)
    const content = computed(() => props.card.content)
    const title = computed(() => props.card.title)
    const contentUrl = computed(() => {
      if (content.value) {
        return content.value.type === 'Hyperlink' ? content.value.url : content.value.url + `?card_id=${cardId.value}`
      }
      return ''
    })
    const { urlWithCrumbs } = usePathCrumbsStore()
    const href = computed(() => urlWithCrumbs(contentUrl.value))
    const { id } = useCurrentCustomerStore()
    return { cardId, cardType, content, title, contentUrl, id, href }
  },
  computed: {
    defaultIcon() {
      if (this.isLostContent) {
        return 'unavailable-object.svg'
      } else {
        return getIcon(this.content)
      }
    },
    item() {
      return this.isLostContent ? {} : this.content
    },
    isInaccessibleContent() {
      return this.content && isDraft(this.content) && this.content.customerId !== this.id
    },
    isLostContent() {
      return this.isInaccessibleContent || !this.content
    },
    isNormalCard() {
      return !this.isLostContent
    },
    fromMetadata() {
      if (this.isNormalCard) {
        return this.content.fromMetadata
      }
      return null
    },
    metadata() {
      if (this.isLostContent) {
        return {}
      }

      return {
        type: capitalize(this.$t(getLabel(this.content))),
      }
    },
  },
  methods: {
    capitalize,
  },
}
</script>
