import { differenceWith, isEqual, unionWith, pullAllWith } from 'lodash'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive } from 'vue'

import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export const useAnnotationCanvasStore = defineStore('annotationCanvas', () => {
  const state = reactive({
    canvasStates: {},
    history: [],
  })

  const emptyCanvas = { objects: [] }

  const { authorizedAsTeacher } = useCurrentUserStore()

  function storeCanvas(id, canvasState, broadcast = false, liveLearnSubscription) {
    if (!canvasState) return

    const lastCanvasState = state.canvasStates[id]
    const timestamp = Date.now()

    const diff = canvasDiff(lastCanvasState, canvasState)

    if (broadcast && (diff.add || diff.remove)) {
      state.history.push({ id, timestamp, diff })
      broadcastCanvasDiff(id, timestamp, diff, liveLearnSubscription)
    }

    state.canvasStates[id] = canvasState
  }

  function updateCanvas(id, timestamp, diff) {
    const lastCanvasState = state.canvasStates[id] || emptyCanvas

    state.history.push({ id, timestamp, diff })

    state.canvasStates[id] = {
      version: lastCanvasState.version,
      objects: applyCanvasDiff(lastCanvasState, diff),
    }
  }

  function broadcastCanvasDiff(cardId, timestamp, diff, liveLearnSubscription) {
    if (liveLearnSubscription && authorizedAsTeacher.value) {
      liveLearnSubscription.perform('update_annotation', { cardId, timestamp, diff })
    }
  }

  function clearCanvas(cardId, liveLearnSubscription) {
    storeCanvas(cardId, emptyCanvas, true, liveLearnSubscription)
  }

  function clearAllCanvases(liveLearnSubscription) {
    if (liveLearnSubscription && authorizedAsTeacher.value) {
      liveLearnSubscription.perform('clear_all_annotations')
    }
    Object.keys(state.canvasStates).forEach((cardId) => (state.canvasStates[cardId] = emptyCanvas))
  }

  function fetchCanvas(id) {
    return state.canvasStates[id] || {}
  }

  function canvasDiff(canvasA, canvasB) {
    return {
      add: canvasA ? differenceWith(canvasB.objects, canvasA.objects, isEqual) : canvasB.objects,
      remove: canvasA ? differenceWith(canvasA.objects, canvasB.objects, isEqual) : [],
    }
  }

  function applyCanvasDiff(canvas, diff) {
    return unionWith(pullAllWith(canvas.objects, diff.remove, isEqual), diff.add, isEqual)
  }

  function undoCanvasDiff(canvas, diff) {
    return applyCanvasDiff(canvas, { add: diff.remove, remove: diff.add })
  }

  function undo() {
    if (state.history.length > 0) {
      const { id, diff } = state.history.pop()

      const lastCanvasState = state.canvasStates[id] || emptyCanvas

      state.canvasStates[id] = {
        version: lastCanvasState.version,
        objects: undoCanvasDiff(lastCanvasState, diff),
      }
    }
  }

  function broadcastUndo(liveLearnSubscription = {}) {
    if (liveLearnSubscription && authorizedAsTeacher.value) {
      liveLearnSubscription.perform('undo_annotation')
    }
    undo()
  }

  function setHistory(newHistory) {
    state.history.length = 0
    Object.keys(state.canvasStates).forEach((cardId) => (state.canvasStates[cardId] = emptyCanvas))

    newHistory.forEach(({ id, timestamp, diff }) => {
      updateCanvas(id, timestamp, diff)
    })
  }

  return {
    state,
    broadcastUndo,
    clearAllCanvases,
    clearCanvas,
    fetchCanvas,
    setHistory,
    storeCanvas,
    undo,
    updateCanvas,
  }
})
