<template>
  <ReportTable
    :data="data"
    :searchable-headers="['categoryName', 'categoryAverage']"
    :sort-fields-as="{ categoryName: sortCategoryName, lessonsUsed: sortCategoryName }"
    :load-more-text="loadMoreText"
    @row-click="handleRowClick"
  >
    <template #headerTooltipContent="{ colName, tooltip }">
      <template v-if="colName === 'lessonsUsed'">
        <span>{{ $t('lessons_used_tooltip') }}</span>
        <a href="/resources/2677118-data-dashboard/#lessons-used" target="_blank">
          {{ $t('learn_more_about_lessons_used') }}
        </a>
      </template>
      <template v-else-if="colName === 'averageScore'">
        <span>{{ $t('average_score_tooltip') }}</span>
        <a href="/resources/2677118-data-dashboard/#average-score" target="_blank">
          {{ $t('learn_more_about_average_score') }}
        </a>
      </template>
      <template v-else>{{ tooltip }}</template>
    </template>
    <template #cell="{ colName, value, row }">
      <div v-if="colName === 'lessonsUsed'">
        <SparklineBar :max-value="data.maxLessonsUsed" :value="value" />
      </div>
      <div v-else-if="colName === 'averageScore'">
        <LzTooltip tag="span" placement="left">
          <ScoreWithIcon :score="value" />

          <template #content>
            <div>
              <strong>{{ row.sessionCount }}</strong
              >: {{ $t('number_of_assignments_tooltip') }}
            </div>
          </template>
        </LzTooltip>
      </div>
      <div v-else>
        {{ value }}
      </div>
    </template>
  </ReportTable>
</template>

<script>
import { computed, inject } from 'vue'
import ReportTable from './ReportTable'
import SparklineBar from './SparklineBar'
import ScoreWithIcon from './ScoreWithIcon'
import { sortCategoryName } from '../utils'
import { snakeCase } from 'lodash'
import { LzTooltip } from 'vue_features/shared/components/ui'

export default {
  name: 'PerformanceAndUsageTableContent',
  components: {
    ReportTable,
    SparklineBar,
    ScoreWithIcon,
    LzTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const handleRowClick = (row) => {
      emit('row-click', row)
    }

    const loadMoreText = computed(() => {
      const loadMore = root.$t('common.load_more')
      const category = root.$t(`${snakeCase(props.data.drillDownCategory)}_plural`)
      return `${loadMore} ${category}`
    })

    return { sortCategoryName, handleRowClick, loadMoreText }
  },
}
</script>
