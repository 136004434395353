<template>
  <table class="responsive">
    <tbody>
      <tr v-for="(row, rowIndex) in content.text" :key="rowIndex">
        <component
          :is="isHeader(column) ? 'th' : 'td'"
          v-for="(column, columnIndex) in row"
          :key="columnIndex"
          v-mathjax
          :scope="mapHeaderScope(column.headerScopes)"
        >
          <!-- content sanitized in serializer -->
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="slide__text" v-html="column.text" />
        </component>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'SlideViewerTable',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isHeader(column) {
      return column.headerScopes.length > 0
    },
    mapHeaderScope(scopes) {
      // The editor for creating/editing tables uses this headerScopes property to define what rows/cols to SET as headers. Not what scope the header is meant to describe.
      // e.g. This COLUMN should all be headers. But the values in the column should be scoped to its correpsonding ROW.
      // Because of this, we need to take the values set by the editor, and reverse their value to use them as scope in this context.
      if (!scopes || scopes.length === 0 || scopes.length >= 2) {
        return scopes
      } else {
        if (scopes.includes('row')) {
          return ['col']
        }
        if (scopes.includes('col')) {
          return ['row']
        }
      }
    },
  },
}
</script>
