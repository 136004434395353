<template>
  <LzTooltip :class="['common-core-tip', coverageClass]" max-width-class="max-w-screen-sm" tag="span">
    {{ displayCode }}

    <template #content>
      <div>
        <strong class="text-orange-600">{{ titlePrefix }}</strong> {{ name }}
      </div>
    </template>
  </LzTooltip>
</template>

<script>
import LzTooltip from './LzTooltip'

export default {
  name: 'StandardCode',
  components: { LzTooltip },
  props: {
    displayCode: { type: String, required: true },
    name: { type: String, required: true },
    coverageType: { type: String, default: 'addressed' },
  },
  computed: {
    titlePrefix() {
      return `${this.buildingInfo}${this.displayCode}:`
    },
    buildingInfo() {
      switch (this.coverageType) {
        case 'buildingOn':
          return this.$t('building_on_title')
        case 'buildingTowards':
          return this.$t('building_towards_title')
        default:
          return ''
      }
    },
    coverageClass() {
      switch (this.coverageType) {
        case 'buildingOn':
          return 'build-code building-on-code'
        case 'buildingTowards':
          return 'build-code building-towards-code'
        default:
          return ''
      }
    },
  },
}
</script>
