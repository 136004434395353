import { ref, computed } from 'vue'

const deletedHides = ref([])
const deletedInsertIds = ref([])
const deletedEditIds = computed(() => {
  return deletedHides.value.map((e) => e.id).concat(deletedInsertIds.value)
})

export default function useDeletedEdits() {
  function reset() {
    deletedHides.value = []
    deletedInsertIds.value = []
  }

  function deleteHide(hide) {
    if (hide.id && !deletedHides.value.find((h) => h.id === hide.id)) {
      deletedHides.value.push(hide)
    }
  }

  function undeleteOrCreateHide(wikiBlockId) {
    const oldHideIndex = deletedHides.value.findIndex((h) => h.wikiBlockId === wikiBlockId)
    if (oldHideIndex >= 0) {
      const oldHide = deletedHides.value[oldHideIndex]
      deletedHides.value.splice(oldHideIndex, 1)
      return oldHide
    }
    return { wikiBlockId, type: 'BlockHide' }
  }

  function deleteInsert(id) {
    if (!deletedInsertIds.value.includes(id)) {
      deletedInsertIds.value.push(id)
    }
  }

  return { reset, deleteHide, undeleteOrCreateHide, deleteInsert, deletedEditIds }
}
