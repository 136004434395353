export const defaultScoreBandRanges = {
  low: [0, 50],
  medium: [50, 80],
  high: [80, 100],
}

export function ratingForScore(score, scoreBands = defaultScoreBandRanges, { extendRanges = true } = {}) {
  if (isNaN(score) || score === null) return null

  const label = Object.keys(scoreBands).find((band, index, array) => {
    const [min, max] = scoreBands[band]
    const effectiveMin = index === 0 && extendRanges ? -Infinity : min
    const effectiveMax = index === array.length - 1 && extendRanges ? Infinity : max

    return score >= effectiveMin && score < effectiveMax
  })

  return label || null
}

export const scoreBandColors = {
  low: 'rgb(var(--low-score-dark))',
  medium: 'rgb(var(--medium-score-dark))',
  high: 'rgb(var(--high-score-dark))',
}

export const scoreBandFills = {
  low: 'rgb(var(--low-score-medium))',
  medium: 'rgb(var(--medium-score-medium))',
  high: 'rgb(var(--high-score-medium))',
}

export const scoreBandBackgrounds = {
  low: 'rgb(var(--low-score-light))',
  medium: 'rgb(var(--medium-score-medium))',
  high: 'rgb(var(--high-score-light))',
}

export const scoreBandIcons = {
  low: 'close',
  medium: 'minus',
  high: 'check',
}

export const noScoreColor = 'rgb(var(--no-score-dark))'
export const noScoreBackground = 'rgb(var(--no-score-light))'

export function scoreBandColor(rating) {
  return scoreBandColors[rating] || noScoreColor
}

export function scoreBandFill(rating) {
  return scoreBandFills[rating] || noScoreBackground
}

export function scoreBandBackground(rating) {
  return scoreBandBackgrounds[rating] || noScoreBackground
}

export function scoreBandIcon(rating) {
  return scoreBandIcons[rating] || 'square'
}
