<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div :class="['insert-wiki-block', { open: isOpen }]">
    <div class="toggle-btn">
      <label v-if="!alwaysOpen" @click="toggle">
        <div
          :class="['toggle-btn__option', { disabled: hasTooManyBlocks }]"
          style="margin-right: -0.25rem"
          :title="hasTooManyBlocks ? $t('wikis.too_many_blocks') : null"
        >
          <LzIcon :class="{ 'rotate-45 transform': isOpen }" type="div" size="sm" path="icons/plus.svg" />
        </div>
      </label>
      <transition name="slide-toggle">
        <div v-if="isOpen" :class="['insert-options', { 'rounded-2xl': alwaysOpen }]">
          <label>
            <div class="toggle-btn__option">
              0
              <!-- hack - keeps consistent height during animation -->
            </div>
          </label>
          <label title="Text" style="margin-right: -0.25rem" @click="insert([createHtmlBlock()])">
            <div class="toggle-btn__option">
              <LzIcon size="sm" type="div" path="icons/html.svg" />
              <span>{{ $t('wikis.text') }}</span>
            </div>
          </label>
          <label
            v-for="listable in $options.listTypes"
            :key="listable"
            :title="capitalize($t(getLabel(listable)))"
            :style="listable === 'audio' ? 'margin-right: -.25rem' : ''"
            :data-test="`insert-${listable}`"
            class="listable"
            @click="openLzModal(listable)"
          >
            <div class="toggle-btn__option">
              <LzIcon type="div" size="sm" :path="`icons/${getIcon(listable)}`" />
              <span>{{ capitalize($t(getLabel(listable))) }}</span>
            </div>
          </label>
          <label
            v-if="!inSection"
            title="Section"
            style="margin-right: -0.25rem"
            class="label__section-insert"
            @click="insert([createSectionBlock()])"
          >
            <div class="toggle-btn__option section-insert">
              <LzIcon size="sm" type="div" path="icons/section.svg" />
              <span>{{ $t('wikis.section') }}</span>
            </div>
          </label>
          <label
            class="toggle-btn-label__last"
            title="Standards coverage table"
            style="margin-right: -0.25rem"
            @click="openStandardsTableModal"
          >
            <div class="toggle-btn__option toggle-btn__last">
              <LzIcon size="sm" type="div" path="icons/standards-coverage-table.svg" />
              <span>{{ $t('wikis.standards_table') }}</span>
            </div>
          </label>
          <ListDropdown :id="uniqueId('more-options-')" :button-classes="['no-shadow']">
            <template #button>
              {{ $t('wikis.more') }}
            </template>
            <template #listItems>
              <li v-for="listable in $options.listTypes" :key="listable">
                <a :title="capitalize($t(getLabel(listable)))" @click="openLzModal(listable)">
                  <LzIcon type="div" size="sm" :path="`icons/${getIcon(listable)}`" />
                  {{ capitalize($t(getLabel(listable))) }}
                </a>
              </li>
            </template>
          </ListDropdown>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { capitalize, uniqueId } from 'lodash'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import useContentTypeMetadata from 'vue_features/shared/composables/use_content_type_metadata'

const MAX_BLOCK_COUNT = 600

export default {
  name: 'WikiInsertBtn',
  listTypes: ['LessonPlan', 'Document', 'Wiki', 'Hyperlink', 'Video', 'Audio'],
  components: { ListDropdown, LzIcon },
  props: {
    sectionId: { type: Number, default: null },
    inSection: { type: Boolean, default: false },
    alwaysOpen: { type: Boolean, default: false },
    totalBlockCount: { type: Number, required: true },
    isAddingInserts: { type: Boolean, default: false },
  },
  setup() {
    const { id } = useCurrentCustomerStore()
    const { getIcon, getLabel } = useContentTypeMetadata()
    return { id, getIcon, getLabel, uniqueId }
  },
  data() {
    return { isOpen: this.alwaysOpen }
  },
  computed: {
    hasTooManyBlocks() {
      return this.totalBlockCount >= MAX_BLOCK_COUNT
    },
  },
  methods: {
    capitalize,
    openModal,
    toggle() {
      this.isOpen = this.hasTooManyBlocks ? false : !this.isOpen
    },
    insert(blocks) {
      this.$emit('insert-blocks', blocks)
      this.isOpen = false
    },
    openLzModal(type) {
      this.openModal('lz-content-modal', {
        contentType: type,
        onSave: (items, viewType) => {
          const blocks = items.map((item) => {
            const detailType = item.resource.type
            return {
              contentType: detailType === 'LessonPlan' ? 'LessonPlan' : 'Resource',
              detailType,
              display: viewType,
              content: item,
              sectionId: this.sectionId,
              blockInsert: this.createBlockInsert(),
            }
          })
          this.insert(blocks)
        },
      })
    },
    openStandardsTableModal() {
      this.openModal('standards-coverage-table-modal', {
        onSave: (content) => {
          this.insert([
            {
              contentType: 'StandardsCoverageTable',
              sectionId: this.sectionId,
              display: 'embed',
              content,
              blockInsert: this.createBlockInsert(),
            },
          ])
        },
      })
    },
    createHtmlBlock() {
      return {
        contentType: 'Resource',
        detailType: 'Html',
        display: 'embed',
        sectionId: this.sectionId,
        content: { content: '<p></p>' },
        blockInsert: this.createBlockInsert(),
      }
    },
    createSectionBlock() {
      const htmlBlock = this.createHtmlBlock()
      htmlBlock.blockInsert = null
      htmlBlock.sectionId = null
      htmlBlock.rank = '1.0'
      return {
        detailType: 'Section',
        display: 'embed',
        title: 'Section',
        content: { blocks: [htmlBlock] },
        blockInsert: this.createBlockInsert(),
      }
    },
    createBlockInsert() {
      return this.isAddingInserts ? { type: 'BlockInsert', customerId: this.id } : null
    },
  },
}
</script>
