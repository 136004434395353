<template>
  <div :class="$options.wrapperClass">
    <img
      :src="imageSrc"
      :class="['applet-thumbnail', applet ? 'hidden' : 'default-thumbnail block']"
      alt="Geogebra player thumbnail"
    />
    <div :id="instanceId" v-observe-visibility="appletVisibilityChanged" class="applet-container" />
  </div>
</template>

<script>
import debug from 'debug'
const log = debug('app:vue_features/geogebra/components/GeogebraPlayer')
import { debounce } from 'lodash'
import { createApplet } from 'clients/geogebra'
import defaultProps from './props/default'
const generateInstanceId = () => 'ggb' + Math.random().toString().slice(2)
export default {
  name: 'GeogebraPlayer',
  props: {
    ...defaultProps,
    manualWidth: {
      type: Number,
      default: 0,
    },
    manualHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      instanceId: generateInstanceId(),
      width: 0,
      height: 0,
      applet: undefined,
      touchedAt: undefined,
      isVisible: false,
    }
  },
  computed: {
    imageSrc() {
      if (!this.applet) return this.$options.defaultSrc
      log('loading image')
      const imageBase64 = this.applet.getThumbnailBase64()
      return `data:image/png;base64,${imageBase64}`
    },
    dimensions() {
      let { width, height, manualHeight, manualWidth } = this
      if (manualWidth > 0 && manualHeight > 0) {
        width = manualWidth
        height = manualHeight
      }
      return { width, height }
    },
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible) this.resize()
    },
    dimensions(dimensions) {
      if (this.autoscale) {
        this.triggerResizeEvent()
      } else {
        this.setAppletSize(dimensions)
      }
    },
  },
  mounted() {
    this.resize()
    this.injectApplet().then(() => this.setupResizing())
  },
  destroyed() {
    if (this.debouncedResize) {
      window.removeEventListener('resize', this.debouncedResize)
    }
  },
  methods: {
    appletVisibilityChanged(isVisible) {
      log('applet visibility changed', isVisible)
      this.isVisible = isVisible
    },
    resize() {
      const { width, height } = this.$el.getBoundingClientRect()
      log('resize', width, height)
      if (width > 0 && height > 0) {
        this.setDimensions({ width, height })
        return true
      } else {
        return false
      }
    },
    toAppletOptions() {
      return {
        instanceId: this.instanceId,
        materialId: this.materialId,
        width: this.width,
        height: this.height,
        disableAutoScale: !this.autoscale,
        showToolBar: this.showToolbar, // note the slight camelcase difference
        scaleContainerClass: this.$options.wrapperClass,
      }
    },
    setDimensions({ width, height }) {
      this.width = width
      this.height = height
    },
    injectApplet() {
      if (this.appletInjected) return
      log('injecting applet', this.materialId)
      this.appletInjected = true
      return createApplet(this.toAppletOptions())
        .then((applet) => {
          this.applet = applet
          this.$emit('applet-created')
        })
        .catch(() => (this.appletInjected = false))
    },
    setupResizing() {
      // If autoscaling is not handled by Geogebra,
      // then we must resize manually when the page resizes.
      if (!this.autoscale) {
        this.debouncedResize = debounce(this.resize, 2000)
        window.addEventListener('resize', this.debouncedResize)
      }
    },
    triggerResizeEvent() {
      window.dispatchEvent(new window.Event('resize'))
    },
    setAppletSize({ width, height }) {
      return this.applet && this.applet.setSize({ width, height })
    },
  },
  defaultSrc: 'https://cdn.geogebra.org/static/img/GeoGebra-logo.png?v=1517218503',
  wrapperClass: 'geogebra-resource',
}
</script>
