<template>
  <div
    :class="{ closed: !isOpen, '': isOpen }"
    class="section-accordion bg-focus border-neutral-300 relative mb-4 mt-3 rounded-2xl border shadow-sm"
  >
    <button
      class="hover:text-important-hover group m-0 flex w-full cursor-pointer items-center gap-2 rounded-2xl px-4 py-5 text-xl font-bold"
      :aria-expanded="isOpen ? 'true' : 'false'"
      @click="toggle"
    >
      <div
        class="group-hover:bg-base flex h-10 w-10 items-center justify-center rounded-xl transition-colors duration-300"
      >
        <InlineSvg
          path="icons/next"
          aria-hidden="true"
          :class="['text-accent inline-block h-6 w-6 transform transition', { 'rotate-90': isOpen }]"
        />
      </div>
      {{ name }}
    </button>
    <CollapseTransition>
      <div v-show="isOpen" class="section-accordion__body space-y-6 rounded-b-2xl px-3 pb-6 md:px-6">
        <slot />
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import InlineSvg from './InlineSvg'

export default {
  name: 'SectionAccordion',
  components: { InlineSvg, CollapseTransition },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return { isOpen: false }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
