<template>
  <div class="col-span-3 text-center text-gray-500">
    <h3 class="mb-2 text-lg">{{ title }}</h3>
    <p class="text-xs">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'DataError',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
}
</script>
