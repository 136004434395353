import { computed } from 'vue'
import { blockJSON } from 'vue_features/resources/wikis/block_helpers'

export default function useBlockEdits(blocks) {
  const blockEdits = computed(() => {
    const edits = []
    const addEditsFromBlocks = (addedBlocks) => {
      addedBlocks.forEach((block) => {
        if (block.blockInsert || block.blockHide) {
          edits.push(block)
        } else if (block.content.blocks) {
          addEditsFromBlocks(block.content.blocks)
        }
      })
    }
    addEditsFromBlocks(blocks.value)
    return edits
  })

  const blockInserts = computed(() => {
    const inserts = blockEdits.value.filter((b) => b.blockInsert)
    return inserts.map((b) => {
      const block = { ...b }
      const edit = { ...block.blockInsert }
      block.blockInsert = null
      edit.block = blockJSON(block)
      return edit
    })
  })

  const blockInsertIds = computed(() => {
    return blockInserts.value.map((insert) => insert.id)
  })

  const newBlockHides = computed(() => {
    return blockEdits.value.filter((b) => b.blockHide && !b.blockHide.id).map((e) => e.blockHide)
  })

  return { blockInserts, blockInsertIds, newBlockHides }
}
