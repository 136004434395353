<template>
  <li data-test="student-list-item" class="flex items-stretch transition-colors duration-300">
    <button
      class="group inline-flex flex-1 flex-nowrap items-center p-4 text-left font-bold transition-colors duration-300"
      :class="{
        'italic text-gray-300': isSelected && !isConnected,
        'hover:bg-primary-600 italic text-gray-300': !isSelected && !isConnected,
        'hover:bg-primary-600 text-inverted': !isSelected && isConnected,
        'cursor-pointer': isSelectable,
        'cursor-not-allowed opacity-50 hover:bg-transparent': !isSelectable,
        'bg-base text-base hover:bg-opacity-90': isSelected,
      }"
      :disabled="disabled"
      @click="toggleSelectedStudent"
    >
      <div
        :class="[
          'min-w-10 relative inline-flex h-10 w-10 flex-shrink-0 transform items-center justify-center rounded-full ring-2 transition-colors duration-300 group-active:scale-90',
          {
            'bg-primary-accent text-white ring-transparent': isConnected && !isSelected,
            'bg-primary-accent ring-primary text-white': isConnected && isSelected,
            'ring-primary-800 bg-primary-900 text-gray-400': !isConnected && !isSelected,
            'ring-primary-900 bg-primary-800 text-gray-400': !isConnected && isSelected,
          },
        ]"
      >
        {{ extractInitials(student.name) }}
        <span
          v-if="isConnected"
          class="bg-primary absolute -right-1 top-0 grid h-3 w-3 rounded-full p-0.5 transition-colors"
        >
          <span class="bg-green-accent h-full w-full animate-pulse rounded-full"></span>
        </span>
      </div>

      <div class="ml-4 w-full truncate whitespace-nowrap pr-2" data-test="student-drawer-student-name">
        {{ student.name }}
      </div>
    </button>
    <button
      :data-test="`student-position-${isNavigable ? 'shown' : 'hidden'}`"
      class="flex w-6 cursor-pointer items-center justify-center px-6 text-sm font-semibold text-white transition-colors duration-200"
      :class="{
        '-mr-12 hidden': !isNavigable,
        'mr-0 ': isNavigable,
        'bg-base text-muted hover:bg-opacity-90': isSelected && isConnected,
        'hover:bg-primary-600 text-inverted': !isSelected && isConnected,
      }"
      :disabled="disabled"
      @click="selectStudentAndNavigate"
    >
      {{ getStudentPosition(student.id) === -1 ? '' : getStudentPosition(student.id) + 1 }}
    </button>
  </li>
</template>

<script>
import { computed, inject } from 'vue'
import { useLiveLearnStore } from 'vue_features/assignments/show/composables'

export default {
  name: 'StudentsDrawerListItem',
  props: {
    student: { type: Object, required: true },
    isConnected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const {
      loadResponseData,
      isTeacherPaced,
      selectedStudent,
      getStudentPosition,
      initialLrnoAssessmentConfig,
      lzCodeViews,
      updateAssessmentConfig,
      students,
    } = useLiveLearnStore()
    const lzCodeViewForStudent = computed(() =>
      lzCodeViews.value.find((lzCodeView) => parseInt(lzCodeView.studentId, 10) === parseInt(props.student.id, 10)),
    )
    const isNavigable = computed(() => !isTeacherPaced.value && props.isConnected)
    const isSelected = computed(() => selectedStudent.value && selectedStudent.value.id === props.student.id)
    const isSelectable = computed(
      () =>
        !props.disabled &&
        (props.isConnected ||
          students.value[props.student.id]?.last_seen_at ||
          (lzCodeViewForStudent.value && lzCodeViewForStudent.value.viewedAt !== null)),
    )

    const closeStudentDrawerCoachmark = inject('closeStudentDrawerCoachmark')

    function toggleSelectedStudent() {
      isSelected.value ? unselectStudent() : selectStudent()
    }

    function selectStudent() {
      if (!isSelectable.value) {
        return
      }

      closeStudentDrawerCoachmark()
      selectedStudent.value = props.student
      loadResponseData()
    }

    function unselectStudent() {
      selectedStudent.value = null
      updateAssessmentConfig(initialLrnoAssessmentConfig.value)
    }

    function extractInitials(name) {
      const splitName = name.split(' ')
      const firstInitial = splitName[0].charAt(0)
      const lastInitial = splitName[splitName.length - 1].charAt(0)
      return `${firstInitial}${lastInitial}`.toUpperCase()
    }

    function selectStudentAndNavigate() {
      selectStudent()
      emit('navigation-click', getStudentPosition(props.student.id))
    }

    return {
      isNavigable,
      isSelected,
      isSelectable,
      toggleSelectedStudent,
      extractInitials,
      selectStudentAndNavigate,
      getStudentPosition,
    }
  },
}
</script>
