import { ref, computed } from 'vue'
import { uniqBy } from 'lodash'

export default function useCoverageTable({ displayBy, contentType, contents, forPrint, standardInitiatives }) {
  const page = ref(0)
  const perPage = ref(50)
  const coverageType = ref('addressed')

  const displayInline = computed(() => {
    return displayBy === 'Inline'
  })

  const displayByStandard = computed(() => {
    return displayBy === 'Standard' || contentType === 'Wiki'
  })

  const displayByWiki = computed(() => {
    return contentType === 'Wiki'
  })

  const items = computed(() => {
    return contents.items
  })

  const standards = computed(() => {
    return contents.standards || items.value.flatMap((item) => item.standards)
  })

  const currentStandards = computed(() => {
    const usingCoverageType = displayByWiki.value ? 'addressed' : coverageType.value

    return uniqBy(
      standards.value.filter(
        (s) => s.coverageType === usingCoverageType && standardInitiatives.includes(s.initiativeId),
      ),
      'id',
    )
  })

  const rows = computed(() => (displayByStandard.value ? currentStandards.value : items.value))

  const paginatedRows = computed(() => {
    const numberPerPage = forPrint ? rows.value.length : perPage.value
    const offset = page.value * perPage.value
    return rows.value.slice(offset, offset + numberPerPage)
  })

  const totalPages = computed(() => Math.ceil(rows.value.length / perPage.value))

  const hasBuildingStandards = computed(() => {
    const checkStandards = (standard) => standard.coverageType !== null && standard.coverageType !== 'addressed'

    return displayByWiki.value ? false : standards.value.some(checkStandards)
  })

  const hasStandardsFor = (coverageType) => {
    return standards.value.some((s) => s.coverageType === coverageType)
  }

  function getInitialCoverageType() {
    let initialCoverageType = 'addressed'

    if (!hasStandardsFor('addressed')) {
      initialCoverageType = hasStandardsFor('buildingOn') ? 'buildingOn' : 'buildingTowards'
    }

    return initialCoverageType
  }

  coverageType.value = getInitialCoverageType()

  return {
    displayInline,
    displayByStandard,
    displayByWiki,
    items,
    standards,
    currentStandards,
    hasBuildingStandards,
    hasStandardsFor,
    page,
    perPage,
    totalPages,
    rows,
    paginatedRows,
    coverageType,
  }
}
