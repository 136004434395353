import { findCardSpot, sortByRank } from '/utils/card_rank_helpers'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { computed, reactive, ref, toRef } from 'vue'
import useCardEditorApi from '../composables/use_card_editor_api'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'

export const useAdditionalMaterialsStore = defineStore('additionalMaterials', () => {
  const state = reactive({
    additionalMaterials: [],
    inProgressCards: [],
    existingCardsInEdit: [],
  })

  const allCards = computed(() => {
    return sortByRank(state.additionalMaterials.concat(state.inProgressCards))
  })

  const removeCard = (card) => {
    const index = state.inProgressCards.indexOf(card)
    if (index >= 0) {
      state.inProgressCards.splice(index, 1)
    }
  }

  const addCard = (card) => {
    state.inProgressCards.push(card)
  }

  const cardServiceFor = ({ cardInEdit, contentInEdit, isSaving, original, onSubmit }) => {
    return useCardEditorApi({
      isSaving,
      cardInEdit,
      contentInEdit,
      updateObjectKey: (key, payload) => {
        if (key === 'contentInEdit') {
          contentInEdit.value = payload
        }
      },
      original,
      onSubmit: () => {
        if (!cardInEdit.value.id) {
          const index = state.inProgressCards.findIndex((card) => card.rank === cardInEdit.value.rank)

          if (index >= 0) {
            state.inProgressCards.splice(index, 1)
          }
        }
        onSubmit()
      },
      cards: toRef(state, 'additionalMaterials'),
      context: 'additional_material',
      spliceCards: (start, deleteCount, ...newCards) => {
        state.additionalMaterials.splice(start, deleteCount, ...newCards)
      },
    })
  }

  const updateRank = (card, params) => {
    card.rank = params.card.rank

    const { isCreatingMetaEdits } = useLessonPlanEditStore()
    // for calculating prev + next card ids
    if (isCreatingMetaEdits.value) {
      const index = state.additionalMaterials.indexOf(card)
      state.additionalMaterials.splice(index, 1)
      const newIndex = findCardSpot(card, state.additionalMaterials)
      state.additionalMaterials.splice(newIndex, 0, card)
    }

    const { updateRank: updateCardRank } = useCardEditorApi({
      cardInEdit: ref(card),
      cards: state.additionalMaterials,
    })
    return updateCardRank(card, params)
  }

  const isEditingCard = (card) => state.existingCardsInEdit.includes(card)

  const toggleEditing = (card) => {
    if (isEditingCard(card)) {
      const index = state.existingCardsInEdit.findIndex((c) => c.id === card)
      state.existingCardsInEdit.splice(index, 1)
    } else if (card.id) {
      state.existingCardsInEdit.push(card)
    }
  }

  return {
    state,
    isEditingCard,
    toggleEditing,
    cardServiceFor,
    allCards,
    removeCard,
    addCard,
    updateRank,
  }
})
