import { floor } from 'lodash'
import { create, extend } from 'utils'
import { MIN_SPLITTABLE_SIZE } from '../use_sizing_strategy'

export default function getSplitDimensions(content, detectionMatch) {
  return getSplitStrategy(content, detectionMatch).dimensions()
}

function getSplitStrategy(content, detectionMatch) {
  switch (detectionMatch.position) {
    case 'top':
      return create(RowSplit, content)
    case 'bottom':
      return create(BottomSplit, content)
    case 'left':
      return create(ColumnSplit, content)
    case 'right':
      return create(RightSplit, content)
  }
}

const SplitBase = {
  initialize(content) {
    this.content = content
  },

  dimensions() {
    if (this.canSplit()) {
      return {
        row: this.row(),
        column: this.column(),
        rowSpan: this.rowSpan(),
        colSpan: this.colSpan(),
      }
    }

    return false
  },

  canSplit() {},
  row() {
    return this.content.row
  },
  column() {
    return this.content.column
  },
  rowSpan() {
    return this.content.rowSpan
  },
  colSpan() {
    return this.content.colSpan
  },
}

export const RowSplit = extend(SplitBase, {
  canSplit() {
    return this.content.rowSpan >= MIN_SPLITTABLE_SIZE
  },
  rowSpan() {
    return this.splitDimension()
  },
  splitDimension() {
    return floor(this.content.rowSpan / 2)
  },
})

export const BottomSplit = extend(RowSplit, {
  row() {
    return this.content.row + this.rowSpan() + (this.content.rowSpan % 2)
  },
})

export const ColumnSplit = extend(SplitBase, {
  canSplit() {
    return this.content.colSpan >= MIN_SPLITTABLE_SIZE
  },
  colSpan() {
    return this.splitDimension()
  },
  splitDimension() {
    return floor(this.content.colSpan / 2)
  },
})

export const RightSplit = extend(ColumnSplit, {
  column() {
    return this.content.column + this.colSpan() + (this.content.colSpan % 2)
  },
})
