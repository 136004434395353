import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

function formData({ source, file }) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('source', source)
  return formData
}

function postFile(route, { source, file }) {
  return HTTP.ajax(route, { method: 'POST', body: formData({ source, file }) })
}

const UserImportService = {
  previewFile({ source, file }) {
    return postFile(Routes.site_admin_user_import_csv_previews_path(), { source, file })
  },
  startImport({ source, file }) {
    return postFile(Routes.site_admin_user_imports_path(), { source, file })
  },
  requestReport() {
    return HTTP.create(Routes.site_admin_user_reports_path())
  },
}

export default UserImportService
