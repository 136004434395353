<template>
  <a v-if="objectIsResource" :href="newPolymorphicPath(object, { fork: object.id })">
    <slot />
    {{ $t('common.copy') }}
  </a>
  <button v-else @click="openModal($options.CopyObjectModal)">
    <slot />
    {{ $t('common.copy') }}
  </button>
</template>

<script>
import { newPolymorphicPath } from 'routes/route_helpers'
import CopyObjectModal from './CopyObjectModal'
import { openModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'CopyObjectButton',
  CopyObjectModal,
  props: {
    object: {
      type: Object,
      required: true,
    },
    objectIsResource: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { openModal }
  },
  methods: {
    newPolymorphicPath,
  },
}
</script>
