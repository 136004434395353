<template>
  <div ref="recaptchaElement"></div>
</template>

<script>
/* globals grecaptcha */
import $script from 'scriptjs'
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'environment'

export default {
  name: 'Recaptcha',
  props: {
    verifiedToken: {
      type: String,
      required: false,
      default: null,
    },
  },
  mounted() {
    $script('https://www.google.com/recaptcha/api.js?render=explicit', 'google/recaptcha')
    $script.ready('google/recaptcha', () => {
      const { recaptchaElement } = this.$refs
      grecaptcha.ready(() => {
        grecaptcha.render(recaptchaElement, {
          sitekey: GOOGLE_RECAPTCHA_SITE_KEY,
          callback: (token) => this.$emit('update:verifiedToken', token),
          'expired-callback': () => this.$emit('update:verifiedToken', null),
          'error-callback': () => this.$emit('update:verifiedToken', null),
        })
      })
    })
  },
}
</script>
