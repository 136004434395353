<template>
  <span data-test="google-link-account-button-wrapper">
    <slot v-bind="{ initAuthentication, scopesMissing, classroomDisabled }" />
  </span>
</template>

<script>
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'LinkAccountButton',
  setup() {
    const { subdomain } = useCurrentCustomerStore()
    const { profileEmail, loadProfile } = useGoogleClassroomStore()
    return { subdomain, profileEmail, loadProfile }
  },
  data() {
    return {
      scopesMissing: false,
      classroomDisabled: false,
    }
  },
  methods: {
    initAuthentication() {
      const authInitiationPath = Routes.new_google_classroom_auth_initiation_path()
      const re = new RegExp(`^https://${this.subdomain}.`)
      const url = authInitiationPath.replace(re, 'https://')

      const newWindow = window.open(url, 'Authenticate', 'height=600,width=450')
      if (newWindow && newWindow.focus) {
        newWindow.focus()
      }
      const childWindowClosedInterval = setInterval(() => {
        try {
          if (newWindow && !newWindow.closed) {
            return
          }
        } catch (e) {
          /* no-op */
        }

        clearInterval(childWindowClosedInterval)

        this.scopesMissing = window.googleAuthScopesMissing
        this.classroomDisabled = window.googleAuthDisabled

        if (window.googleAuthSucceeded) {
          this.$emit('google-account-linked')
        }
      }, 50)
    },
  },
}
</script>
