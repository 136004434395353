<template>
  <div>
    <div v-if="showForm">
      <p class="center my-3">{{ $t('premium_prompt.request_help') }}</p>
      <ContactForm
        v-bind="{ feedbackTargetId, feedbackTargetType, collectionName, contentOwner }"
        :allow-cancel="true"
        class="text-left"
        @canceled="toggleForm"
        @success="success"
      />
    </div>
    <div v-else-if="requestSubmitted" class="flex flex-col items-center">
      <p class="center max-w-3/4 my-3">{{ $t('premium_prompt.request_submitted') }}</p>
      <ImageTag class="mx-auto my-3 h-24" path="misc/happy_cactus.png" alt="happy cactus" />
      <a class="btn btn--primary m-4 mb-5" href="/">{{ $t('premium_prompt.home') }}</a>
    </div>
    <div v-else class="flex flex-col items-center">
      <p data-test="unshared" class="center max-w-3/4 my-3">{{ $t('premium_prompt.unshared_customer') }}</p>
      <ImageTag class="mx-auto my-3 h-24" path="misc/confused_cactus.png" alt="confused cactus" />
      <div class="m-4 grid grid-cols-2 gap-4 pb-5">
        <a class="btn" href="/">{{ $t('premium_prompt.home') }}</a>
        <button class="btn btn--primary" @click="toggleForm">{{ $t('premium_prompt.help') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageTag } from 'vue_features/shared/components/ui'
import ContactForm from '../../../contact_form/components/ContactForm'
import { ref } from 'vue'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { shared } from 'vue_features/shared/i18n'
import { useContactDetailsStore } from '../../composables/use_contact_details_store'

const messages = pickLocaleNamespace(shared, 'premium_prompt')

export default {
  name: 'PremiumPrompt',
  components: { ContactForm, ImageTag },
  i18n: { messages },
  props: {},
  setup() {
    const { feedbackTargetId, feedbackTargetType, collectionName, contentOwner } = useContactDetailsStore()

    const showForm = ref(false)
    const requestSubmitted = ref(false)

    const toggleForm = () => {
      showForm.value = !showForm.value
    }

    const success = () => {
      requestSubmitted.value = true
      toggleForm()
    }

    return {
      feedbackTargetId,
      feedbackTargetType,
      collectionName,
      contentOwner,
      showForm,
      requestSubmitted,
      toggleForm,
      success,
    }
  },
}
</script>
