<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <tr ref="wrapper" class="flex h-16 items-stretch">
    <td class="flex flex-grow items-center" colspan="2">
      <div class="text-group w-full">
        <input
          ref="inputEl"
          :value="share.subdomain"
          :placeholder="$t('domain_placeholder')"
          type="text"
          @input="$emit('update-subdomain', $event.target.value)"
        />
        <label>.ilclassroom.com</label>
      </div>
    </td>
    <td class="flex flex-shrink-0 items-center">
      <DeleteShareButton @delete-share="$emit('delete', share)" />
    </td>
  </tr>
</template>

<script>
import { onMounted, ref } from 'vue'
import DeleteShareButton from './DeleteShareButton'

export default {
  name: 'NewSharingTableItem',
  components: { DeleteShareButton },
  props: {
    share: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const inputEl = ref()
    const wrapper = ref()
    onMounted(() => {
      inputEl.value.focus()
      wrapper.value.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
    })
    return {
      inputEl,
      wrapper,
    }
  },
}
</script>
