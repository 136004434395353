<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzModal v-slot="{ close }" :modal-title="$t('add_new_collection_modal_title')" :modal-id="$options.name">
    <div class="form-group">
      <label>{{ $t('wiki_resource') }}</label>
      <input v-model="wikiId" type="text" placeholder="1234" />
      <span v-if="errors.path" class="red">{{ errors.path[0] }}</span>
    </div>
    <footer>
      <button class="btn" @click="close">{{ $t('common.cancel') }}</button>
      <button class="btn btn--primary" :disabled="!+wikiId" @click="create">
        {{ $t('common.create') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { useCollectionsIndexStore } from '../../store/use_collections_index_store'
import { LzModal } from 'vue_features/shared/components/ui'
import location from 'utils/location'

export default {
  name: 'CreateCollectionModal',
  components: { LzModal },
  setup() {
    const { createCollection, isProgressCoverShown } = useCollectionsIndexStore()
    return { createCollection, isProgressCoverShown }
  },
  data() {
    return { wikiId: '', errors: {} }
  },
  methods: {
    create() {
      this.isProgressCoverShown = true
      this.createCollection(this.wikiId).then(
        () => {
          location.reload()
        },
        (xhr) => {
          this.errors = xhr.responseJSON
          this.isProgressCoverShown = false
        },
      )
    },
  },
}
</script>
