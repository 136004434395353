<template>
  <div>
    <template v-if="isStudent">
      <EmptyStateWithAnimation
        v-if="!hasAnyCodes"
        :animation-loader="() => import('lottie-animations/getstarted.json')"
        autoplay
        loop
        :heading="$t('student_klasses.show.nothing_to_do')"
        :body="$t('student_klasses.show.no_assignments_yet')"
        data-test="empty-state-no-assignments-student"
      />
      <EmptyStateWithAnimation
        v-else-if="isActive && noActiveAssignments"
        :animation-loader="() => import('lottie-animations/finished-work.json')"
        autoplay
        loop
        :heading="$t('student_klasses.show.all_assignments_complete_title')"
        :body="$t('student_klasses.show.all_assignments_complete')"
        data-test="empty-state-no-active-assignments-student"
      />
      <EmptyStateWithAnimation
        v-else-if="isInactive && noInactiveAssignments"
        :animation-loader="() => import('lottie-animations/pearl-reading.json')"
        autoplay
        loop
        :heading="$t('student_klasses.show.nothing_to_see')"
        :body="$t('student_klasses.show.no_done_assignments')"
        data-test="empty-state-no-inactive-assignments-student"
      />
      <AssignmentsLoadMoreList v-else :klass="klass" :assignment-type="assignmentType" />
    </template>
    <template v-else>
      <EmptyStateWithIcon
        v-if="klass.activeCount === 0"
        path="icons/chalkboard"
        :heading="$t('klasses.show.add_students_to_assign_work')"
        :body="$t('klasses.show.go_to_the_people_tab')"
        data-test="empty-state-no-students-teacher"
      />
      <EmptyStateWithAnimation
        v-else-if="!hasAnyCodes"
        :animation-loader="() => import('lottie-animations/getstarted.json')"
        autoplay
        loop
        :heading="$t('klasses.show.get_started')"
        :body="$t('klasses.show.add_some_assignments')"
        data-test="empty-state-no-assignments-teacher"
      >
        <a :href="Routes.page_path('landing')" class="btn btn--primary">
          {{ $t('klasses.show.find_lessons') }}
        </a>
      </EmptyStateWithAnimation>

      <EmptyStateWithAnimation
        v-else-if="isActive && noActiveAssignments"
        :animation-loader="() => import('lottie-animations/getstarted.json')"
        autoplay
        loop
        :heading="$t('klasses.show.nothing_to_see_yet')"
        :body="$t('klasses.show.no_active_assignments')"
        data-test="empty-state-no-active-assignments-teacher"
      >
        <a :href="Routes.page_path('landing')" class="btn btn--primary">
          {{ $t('klasses.show.assign_content') }}
        </a>
      </EmptyStateWithAnimation>

      <EmptyStateWithAnimation
        v-else-if="isInactive && noInactiveAssignments"
        :animation-loader="() => import('lottie-animations/getstarted.json')"
        autoplay
        loop
        :heading="$t('klasses.show.nothing_to_see_yet')"
        :body="$t('klasses.show.no_archived_assignments')"
        data-test="empty-state-no-inactive-assignments-teacher"
      >
      </EmptyStateWithAnimation>
      <AssignmentsLoadMoreList v-else :klass="klass" :assignment-type="assignmentType" />
    </template>
  </div>
</template>

<script>
import { EmptyStateWithIcon, EmptyStateWithAnimation } from 'vue_features/shared/components/ui'
import AssignmentsLoadMoreList from './AssignmentsLoadMoreList'
import { useKlassAssignmentsStore, useKlassShowStore } from '../store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'AssignmentsTab',
  components: {
    EmptyStateWithIcon,
    EmptyStateWithAnimation,
    AssignmentsLoadMoreList,
  },
  props: {
    klassId: {
      type: String,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
      validator: (value) => ['active', 'inactive'].includes(value),
    },
  },
  setup() {
    const { isStudent } = useCurrentUserStore()
    const { hasAnyCodes, activeAssignments, inactiveAssignments } = useKlassAssignmentsStore()
    const { klass } = useKlassShowStore()
    return { Routes, isStudent, hasAnyCodes, activeAssignments, inactiveAssignments, klass }
  },
  computed: {
    isActive: function () {
      return this.assignmentType === 'active'
    },
    isInactive: function () {
      return this.assignmentType === 'inactive'
    },
    noActiveAssignments: function () {
      return this.activeAssignments.length === 0
    },
    noInactiveAssignments: function () {
      return this.inactiveAssignments.length === 0
    },
  },
}
</script>
