import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

export const SiteAdminCustomersService = {
  deleteCustomer() {
    return HTTP.delete(Routes.site_admin_settings_path())
  },
  updateCustomer(attrs) {
    return HTTP.put(Routes.site_admin_settings_path(), snakeKeys(attrs))
  },
  updateNavigationOptions(navigationOptions) {
    const url = Routes.site_admin_settings_url()
    const attrs = {
      customer: {
        navigationOptionsAttributes: navigationOptions,
      },
    }

    return HTTP.put(url, snakeKeys(attrs))
  },
}

export const SiteAdminUsersRecurringImportService = {
  create(attrs) {
    return HTTP.create(Routes.site_admin_recurring_import_path(), snakeKeys({ customerUsersRecurringImport: attrs }))
  },
  delete() {
    return HTTP.delete(Routes.site_admin_recurring_import_path())
  },
  update(attrs) {
    return HTTP.put(Routes.site_admin_recurring_import_path(), snakeKeys({ customerUsersRecurringImport: attrs }))
  },
}

export const SiteAdminStudentDataRecurringExportService = {
  create(attrs) {
    return HTTP.create(Routes.site_admin_recurring_exports_path(), snakeKeys({ studentDataRecurringExport: attrs }))
  },
  delete(id) {
    return HTTP.delete(Routes.site_admin_recurring_export_path(id))
  },
  update(attrs) {
    return HTTP.put(Routes.site_admin_recurring_export_path(attrs.id), snakeKeys({ studentDataRecurringExport: attrs }))
  },
}

export const SiteAdminUserImportService = {
  fetch() {
    return HTTP.getJSON(Routes.site_admin_user_imports_path(), { cache: false })
  },
}

// export other API functions/objects here
export function createNavOption(options) {
  return {
    id: null,
    key: 'New tab',
    url: 'https://',
    enabled: true,
    isCustom: true,
    ...options,
  }
}
