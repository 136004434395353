<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzModal modal-id="archive-message-modal" :autoclose="false" @close-modal="close">
    <section>
      <label>
        {{ $t('lesson_plans.archive_editor.new_lp_url_label', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
        <span class="weak regular">{{ $t('common.optional') }}</span>
      </label>
      <input v-model="deprecatedByUrl" data-test="deprecated-by-url-input" class="form-control" type="text" />
      <p class="small help-block italic">
        {{ $t('lesson_plans.archive_editor.new_lp_url_help', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
      </p>
      <label>
        {{ $t('lesson_plans.archive_editor.change_message') }}
        <span class="weak regular">{{ $t('lesson_plans.archive_editor.optional') }}</span>
      </label>
      <textarea v-model="deprecatedByMessage" data-test="deprecated-by-textarea" class="form-control" />
      <p class="small help-block italic">
        {{ $t('lesson_plans.archive_editor.change_message_help', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
      </p>
    </section>
    <footer>
      <button class="button btn" @click="close">{{ $t('common.cancel') }}</button>
      <button data-test="archive-message-submit" class="btn btn--primary ml-2" @click="save">
        {{ $t('common.save') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import LzModal from 'vue_features/shared/components/ui/LzModal'
import LessonPlanService from 'vue_features/lesson_plans/api/lesson_plans_service'
import { ref } from 'vue'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import { useLessonPlanStore } from '../../store/use_lesson_plan_store'
import { disableUnloadConfirm } from 'utils'

export default {
  name: 'ArchiveMessageModal',
  components: { LzModal },
  setup() {
    const { lessonPlan, lessonType } = useLessonPlanStore()
    const { isProgressCoverDisplayed } = useLessonPlanEditStore()

    const deprecatedByUrl = ref(lessonPlan.value.deprecatedByUrl)
    const deprecatedByMessage = ref(lessonPlan.value.deprecatedByMessage)

    const save = async () => {
      isProgressCoverDisplayed.value = true
      const response = await LessonPlanService.updateLessonPlan(lessonPlan.value.id, {
        deprecatedByMessage: deprecatedByMessage.value,
        deprecatedByUrl: deprecatedByUrl.value,
      })
      lessonPlan.value = response
      close()
      isProgressCoverDisplayed.value = false
    }

    const close = () => {
      disableUnloadConfirm()
      closeModal('ArchiveMessageModal')
    }

    return { save, close, lessonPlan, lessonType, deprecatedByUrl, deprecatedByMessage }
  },
}
</script>
