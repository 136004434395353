<template>
  <div class="relative flex items-center justify-center rounded-full">
    <button
      :id="[`toolbar-card-indicator-${ordinal + 1}`]"
      :key="card.id"
      :class="classes"
      :data-test="`toolbar-card-indicator-${scoreBand || 'no-score-band'}`"
      @click="$emit('click')"
    >
      {{ ordinal + 1 }}
    </button>
    <div v-if="!isStudent && isQuestion && (scoreBand || hasAnyStudentCompleted)" class="pointer-events-none absolute">
      <svg class="h-full w-full -rotate-90 transform" width="80" height="80" viewBox="0 0 80 80">
        <circle
          class="text-gray-200"
          stroke-width="3"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference - (percentCompleted / 100) * circumference"
          stroke-linecap="round"
          stroke="currentColor"
          fill="transparent"
          :r="radius"
          cx="40"
          cy="40"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed, toRef } from 'vue'
import { get } from 'lodash'
import { ratingForScore } from 'vue_features/shared/utils/scoring'
import { useAssignmentCardsStore, useLiveLearnStore } from 'vue_features/assignments/show/composables'
import useLearnosityItemResource from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_resource'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useCardTypes } from 'vue_features/shared/composables'

export default {
  name: 'PositionIndicator',
  props: {
    card: {
      type: Object,
      required: true,
    },
    ordinal: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const radius = 30
    const { isStudent } = useCurrentUserStore()
    const { assignmentDisplayCards, selectedAssignmentCardId } = useAssignmentCardsStore()
    const { students, aggregateResponses } = useLiveLearnStore()

    const isCardSelected = computed(() => props.card.id === selectedAssignmentCardId.value)
    const itemReference = computed(() => {
      if (!isQuestion.value) return null

      const card = assignmentDisplayCards.value.find((card) => card.id === props.card.id)
      return get(card, 'content.content')
    })
    const { isQuestionCard: isQuestion } = useCardTypes(toRef(props, 'card'))
    const averageScore = computed(() => get(aggregateResponses.value[itemReference.value], 'average_score'))
    const scoreBand = computed(() => {
      const ratio = averageScore.value
      return hasAnyStudentCompleted.value && isFinite(ratio) && ratio !== null ? ratingForScore(ratio * 100) : null
    })
    const hasAnyStudentCompleted = computed(() => !!get(aggregateResponses.value[itemReference.value], 'completed'))

    const learnosityItemResourceStore = useLearnosityItemResource(itemReference.value)
    const hasAttemptedAllQuestions = computed(() => {
      return isStudent.value && itemReference.value ? learnosityItemResourceStore.hasAttemptedAllQuestions.value : null
    })

    const completedStudents = computed(() => get(aggregateResponses.value[itemReference.value], 'completed', 0))
    const totalStudents = computed(() => Object.keys(students.value).length)
    const percentCompleted = computed(() => (completedStudents.value / totalStudents.value) * 100)

    const tooltipScoreClasses = computed(() => ({
      'text-green-600': scoreBand.value === 'high',
      'text-orange-600': scoreBand.value === 'medium',
      'text-red-600': scoreBand.value === 'low',
    }))

    const teacherClasses = computed(() => {
      let bgClasses, textClasses, borderClasses

      if (isCardSelected.value && !hasAnyStudentCompleted.value) {
        bgClasses = 'bg-white'
        textClasses = 'text-base'
      } else if (scoreBand.value === 'low') {
        bgClasses = 'bg-red-100'
        textClasses = `text-red-600`
      } else if (scoreBand.value === 'medium') {
        bgClasses = 'bg-yellow-100'
        textClasses = `text-yellow-600`
      } else if (scoreBand.value === 'high') {
        bgClasses = 'bg-green-100'
        textClasses = `text-green-600`
      } else if (hasAnyStudentCompleted.value) {
        bgClasses = 'bg-primary-600'
        textClasses = 'text-inverted'
      } else {
        textClasses = 'text-inverted'
      }

      if (isQuestion.value && !hasAnyStudentCompleted.value) {
        borderClasses = 'border border-dashed'
        if (isCardSelected.value) borderClasses += ' border-primary-800'
      }

      return [bgClasses, textClasses, borderClasses]
    })

    const studentClasses = computed(() => {
      let bgClasses, textClasses, borderClasses

      if (isCardSelected.value && !hasAttemptedAllQuestions.value) {
        bgClasses = 'bg-focus'
      } else if (hasAttemptedAllQuestions.value) {
        bgClasses = 'bg-inverted'
      }

      if (isCardSelected.value && !hasAttemptedAllQuestions.value) {
        textClasses = 'text-base'
      } else {
        textClasses = 'text-inverted'
      }

      if (isQuestion.value && !hasAttemptedAllQuestions.value) {
        borderClasses = 'border border-dashed'
        if (isCardSelected.value) borderClasses += ' border-primary-800'
      } else if (isQuestion.value && hasAttemptedAllQuestions.value) {
        borderClasses = 'border-2 border-base'
      }

      return [bgClasses, textClasses, borderClasses]
    })
    const classes = computed(() => {
      return [
        'flex items-center justify-center flex-grow-0 flex-shrink-0 mx-2 transition duration-200 rounded-full ring-blue-800 ring-2 ring-inset w-11 h-11 lesson-nav-item',
        isStudent.value ? studentClasses.value : teacherClasses.value,
        {
          'triangle-top-down': isCardSelected.value,
          'border-base border-2': !isQuestion.value,
        },
      ]
    })

    return {
      averageScore,
      circumference: radius * 2 * Math.PI,
      classes,
      completedStudents,
      hasAnyStudentCompleted,
      isQuestion,
      isStudent,
      percentCompleted,
      radius,
      tooltipScoreClasses,
      totalStudents,
      scoreBand,
    }
  },
}
</script>
