import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'

export const useContactDetailsStore = defineStore('contactDetails', () => {
  const state = reactive({
    feedbackTargetId: null,
    feedbackTargetType: null,
    collectionName: null,
    contentOwner: null,
  })

  return { state }
})
