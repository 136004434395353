import { toRef, reactive, computed } from 'vue'

const START_COUNT = 5
const MORE_COUNT = 5

export default function useLoadMoreList({
  itemsRef, // must be reactive since loading more items will mutate it externally
  totalCount, // can either be primitive or a ref
  startCount = START_COUNT,
  moreCount = MORE_COUNT,
}) {
  const state = reactive({
    visibleItemsCount: startCount,
    isLoading: false,
    totalCount,
    visibleItems: computed(() => itemsRef.value.slice(0, state.visibleItemsCount)),
    canLoadMore: computed(() => state.visibleItemsCount < state.totalCount),
    needsMore: computed(() => {
      const loadedItemsCount = itemsRef.value.length
      return loadedItemsCount < state.totalCount && state.visibleItemsCount + moreCount >= loadedItemsCount
    }),
  })

  function showMoreItems(handleLoadMore) {
    const done = () => {
      state.visibleItemsCount = Math.min(state.visibleItemsCount + moreCount, state.totalCount)
      state.isLoading = false
      return Promise.resolve()
    }

    if (state.needsMore) {
      state.isLoading = true
      return handleLoadMore(done)
    } else {
      return done()
    }
  }

  return {
    visibleItems: toRef(state, 'visibleItems'),
    canLoadMore: toRef(state, 'canLoadMore'),
    isLoading: toRef(state, 'isLoading'),
    showMoreItems,
  }
}
