<template>
  <div
    :class="['page-head mb-4 mt-0 pb-4', hasBigCoverImage ? 'page-head--cover-image' : '']"
    :style="coverImageStyle"
    itemscope=""
    itemtype="//schema.org/Webpage"
  >
    <div class="page-head__wrapper">
      <div v-if="logo" class="page-head__image">
        <img :src="logo" alt="logo" aria-labelledby="resource-name" aria-hidden="true" />
      </div>
      <div v-else-if="showThumbnail" class="page-head__image">
        <img :src="resource.thumbnailUrl" alt="thumbnail" aria-labelledby="resource-name" aria-hidden="true" />
      </div>
      <div class="page-head__content">
        <div class="page-head__pretext">
          {{ !isWikiResource ? getLabel(resource) : '' }}
          <LzContentStateBadge v-if="!isActive(resource)" :state="resource.state" />
        </div>
        <h1 id="resource-name" itemprop="name">
          <!--   FIXME: optional card name     -->
          {{ resource.name }}
        </h1>
        <p class="mb-2" itemprop="description">
          {{ resource.description }}
        </p>
        <Badge v-if="resource.teacherOnly" class="mb-4 ml-0">{{ $t('common.teacher_only') }}</Badge>
        <Badge v-if="isPublicCustomer && resource.premium && authorizedAsSiteAdmin" class="mb-4 ml-0">
          {{ $t('common.subscription_required') }}
        </Badge>
      </div>
      <div class="page-head__actions page-head__actions--pretext">
        <div class="page-head__button">
          <!-- FIXME: optional print buttons-->
        </div>
        <!-- FIXME: optional action menu-->
      </div>
    </div>
  </div>
</template>

<script>
import { Badge } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { isWiki } from 'vue_features/shared/composables/use_resource_types'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'Header',
  components: { Badge, LzContentStateBadge },
  props: { resource: { type: Object, required: true } },
  setup() {
    const { isPublicCustomer } = useCurrentCustomerStore()
    const { authorizedAsSiteAdmin } = useCurrentUserStore()
    return { isPublicCustomer, authorizedAsSiteAdmin, getLabel, isActive }
  },
  computed: {
    isWikiResource() {
      return isWiki(this.resource)
    },
    hasCoverImage() {
      return this.resource.coverImageUrl !== null
    },
    hasBigCoverImage() {
      return this.hasCoverImage && this.isWikiResource
    },
    coverImageStyle() {
      if (this.hasBigCoverImage) {
        return {
          'background-image': `linear-gradient(57deg,rgba(var(--primary-800),0.90) 0%, rgba(var(--primary-accent),0.00) 100%), url(${this.resource.coverImageUrl})`,
        }
      }
      return ''
    },
    logo() {
      return this.resource.fromMetadata.logo
    },
    showThumbnail() {
      return !this.hasCoverImage && !this.isWikiResource
    },
  },
}
</script>
