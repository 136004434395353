import { classify } from 'lodash'

export const UI_FACING_TYPES = {
  Audio: 'audio',
  Assignment: 'assignment',
  Document: 'document',
  GeogebraItem: 'geogebraitem',
  Html: 'html',
  Hyperlink: 'url',
  Image: 'image',
  LearnosityItem: 'question',
  Video: 'video',
  Slide: 'slide',
  Wiki: 'page',
  LessonPlan: 'lessonplan',
  Title: 'heading',
  Existing: 'existing',
}

const retrieveValue = (contentOrType) => {
  const type = contentOrType.type || contentOrType.resultType || contentOrType
  const value = UI_FACING_TYPES[classify(type || '')]
  return value ? value : 'nonexistent-type'
}
export const getPlaceholder = (contentOrType) => `${retrieveValue(contentOrType)}-placeholder.svg`
export const getThumbnail = (content) => content.thumbnail || getPlaceholder(content)
export const getIcon = (contentOrType) => `${retrieveValue(contentOrType)}.svg`
export const getLabel = (contentOrType) => `content_type_metadata.${retrieveValue(contentOrType)}.label`
export const getHelpText = (contentOrType) => `content_type_metadata.${retrieveValue(contentOrType)}.help_text`

export default function useContentTypeMetadata() {
  return {
    getPlaceholder,
    getIcon,
    getLabel,
    getHelpText,
    getThumbnail,
  }
}
