<template>
  <div>
    <LessonPlanDraftBanner v-if="isDraft" />

    <CopiesBanner :copies-data="copiesData" :original="lessonPlan" />
    <LzCodeBanner :lz-codes="lzCodes" :lesson-type="readableType" />
    <Breadcrumbs
      v-if="!hasPathCrumbs"
      :breadcrumbs="breadcrumbs"
      :is-collection-map-available="isCollectionMapAvailable"
      :is-expanded-row="isCollectionMapAvailable"
      class="lp-breadcrumbs"
      :class="{ 'lp-breadcrumbs--shift': showAnnotationTools }"
    />
    <PathCrumbs v-else />
    <PortalTarget name="annotations" />
    <LessonPlanContentView />

    <Commentable v-if="isDraft" :commentable-path="commentablePath" />
    <FeedbackPrompt
      v-if="!isStudent && !isFullscreen && !(showFeedback || showConfirmation)"
      @show-feedback="enableFeedback"
    />
    <div
      v-if="showFeedback || showConfirmation"
      class="lesson-plan-contact-form z-100 border-primary-200 max-h-full-24 fixed max-w-xl overflow-y-auto rounded-lg border bg-white p-2 shadow-lg"
    >
      <ContactForm
        v-if="showFeedback"
        v-bind="{ allowCancel: true, ...feedback }"
        class="w-full"
        @canceled="disableFeedback"
        @success="submittedFeedback"
      />
      <div v-else class="lzui-tile m-0 border-green-200 bg-green-50 p-2">
        <span class="text-green">
          <b>{{ $t('form.thanks_for_feedback') }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { PortalTarget } from 'portal-vue'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { ref, computed, provide, inject } from 'vue'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import LessonPlanContentView from './LessonPlanContentView'
import Breadcrumbs from 'vue_features/shared/components/breadcrumbs/Breadcrumbs'
import CopiesBanner from 'vue_features/lesson_plans/components/CopiesBanner'
import LzCodeBanner from './LzCodeBanner'
import Commentable from 'vue_features/shared/components/Commentable'
import FeedbackPrompt from './FeedbackPrompt'
import Routes from 'routes'
import PathCrumbs from 'vue_features/resources/shared/components/path_crumbs/PathCrumbs'
import LessonPlanDraftBanner from 'vue_features/lesson_plans/show/components/LessonPlanDraftBanner'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import ContactForm from 'vue_features/contact_form/components/ContactForm'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useAnnotationToolStore } from 'vue_features/shared/store/composables/use_annotation_tool_store'

export default {
  name: 'LessonPlanShow',
  components: {
    LessonPlanContentView,
    Breadcrumbs,
    Commentable,
    CopiesBanner,
    LzCodeBanner,
    PathCrumbs,
    FeedbackPrompt,
    ContactForm,
    LessonPlanDraftBanner,
    PortalTarget,
  },
  setup() {
    const root = inject('useRoot')()
    const { isFullscreen } = useFullscreenStore()
    const { collection, isCollectionMapAvailable, lzCodes, copiesData, breadcrumbs } = useLessonPlanShowStore()
    const { isDraft, lessonPlanId, lessonPlan, isToolOpen, lessonType, feedback } = useLessonPlanStore()
    const readableType = computed(() => root.$t(`readable_types.${lessonType.value}`))
    const commentablePath = computed(() => Routes.lesson_plan_path(lessonPlanId.value))
    const { isStudent } = useCurrentUserStore()
    const { showAnnotationTools } = useAnnotationToolStore()

    const { hasPathCrumbs } = usePathCrumbsStore()

    const showFeedback = ref(false)
    const showConfirmation = ref(false)
    const enableFeedback = () => {
      showFeedback.value = true
    }
    const disableFeedback = () => {
      showFeedback.value = false
    }

    const submittedFeedback = () => {
      if (showFeedback.value) {
        showFeedback.value = false
        showConfirmation.value = true

        setTimeout(() => {
          showConfirmation.value = false
        }, 3000)
      }
    }

    provide('eventSource', 'lesson')

    return {
      feedback,
      isFullscreen,
      showFeedback,
      showConfirmation,
      enableFeedback,
      disableFeedback,
      submittedFeedback,
      lessonPlan,
      lessonType,
      isToolOpen,
      collection,
      isCollectionMapAvailable,
      lzCodes,
      copiesData,
      breadcrumbs,
      readableType,
      commentablePath,
      isDraft,
      hasPathCrumbs,
      isStudent,
      showAnnotationTools,
    }
  },
}
</script>

<style scoped>
.lesson-plan-contact-form {
  right: 20px;
  bottom: 90px;
}

@media screen and (max-height: 909px) {
  .lp-breadcrumbs {
    transition: padding-left 300ms;
  }
  .lp-breadcrumbs--shift {
    padding-left: 3.75rem;
  }
}
</style>
