<template>
  <div id="sessions-new-feature">
    <template v-if="hasStudentFunctionality">
      <LzTabs>
        <TabLink label="Teacher" target="teacher-login" initial />
        <TabLink label="Student" target="student-login" />
      </LzTabs>
    </template>
    <div id="login-content">
      <div :class="{ 'border-base border-t': hasStudentFunctionality }" class="login__form align-left">
        <div class="col-span-12 px-2.5 py-4 md:px-3.5">
          <TeacherLogin />
          <StudentLogin v-if="hasStudentFunctionality" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LzTabs, TabLink } from 'vue_features/shared/components/ui'
import StudentLogin from './StudentLogin'
import TeacherLogin from './TeacherLogin'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'LoginInModal',
  components: { TeacherLogin, StudentLogin, TabLink, LzTabs },
  setup() {
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    return { hasStudentFunctionality }
  },
}
</script>
