import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'
import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { useAssignmentShowStore, useAssignmentStore } from 'vue_features/assignments/show/composables'

export const useLiveLearnStore = defineStore('liveLearn', () => {
  const state = reactive({
    isLiveLearn: false,
    isTeacherPaced: true,
    connectedStudentIds: [],
    students: {},
    lzCodeViews: [],
    klassId: null,
    selectedStudent: null,
    cachedLrnoConfigs: {},
    isNewDataAvailable: false,
    aggregateResponses: [],
    sharingStudentId: null,
    initialLrnoAssessmentConfig: null,
    lnroConfigBeforeSharing: null,
    loadingResponseData: false,
    showConnectionDebug: false,
  })
  const { waitForLearnosityItemsApp } = useAssignmentShowStore()
  const { resetAll: resetAllLearnosityClients } = useLearnosityClientStore()
  const { learnosityConfig } = useLearnosityConfigStore()
  const { assignment } = useAssignmentStore()

  const allStudentIds = computed(() => Object.keys(state.students).map((id) => parseInt(id)))
  const sortedStudents = computed(() => {
    return Object.values(state.students).sort((a, b) => a.name.localeCompare(b.name))
  })

  function getStudentPosition(studentId) {
    return parseInt(state.students[studentId] ? state.students[studentId].position : -1)
  }

  async function loadResponseData() {
    state.loadingResponseData = true
    const lrnoAssessmentConfig = cachedConfig() || (await retrieveConfig())
    setCachedConfig(lrnoAssessmentConfig)
    await updateAssessmentConfig(lrnoAssessmentConfig)
    state.isNewDataAvailable = false
    state.loadingResponseData = false
  }

  async function updateAssessmentConfig(config) {
    await waitForLearnosityItemsApp()
    resetAllLearnosityClients()
    learnosityConfig.value = config
  }

  function retrieveConfig() {
    const url = Routes.learnosity_answers_preview_configs_path()
    const params = snakeKeys({
      learnosityConfig: {
        userId: state.sharingStudentId || state.selectedStudent?.id,
        lzCodeId: assignment.value.id,
        klassId: state.klassId,
      },
    })

    return HTTP.create(url, params)
  }

  function cachedConfig() {
    const config = state.cachedLrnoConfigs[configCacheKey()]
    return !config ? null : { ...config } // Create new object, which is useful to trigger watcher
  }

  function setCachedConfig(config) {
    const cachedLrnoConfigs = { ...state.cachedLrnoConfigs, [configCacheKey()]: config }
    state.cachedLrnoConfigs = cachedLrnoConfigs
  }

  function configCacheKey() {
    return state.sharingStudentId || state.selectedStudent?.id
  }

  return {
    state,
    allStudentIds,
    loadResponseData,
    sortedStudents,
    getStudentPosition,
    updateAssessmentConfig,
  }
})
