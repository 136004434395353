<script setup>
import { computed } from 'vue'
import { Banner, LzButton, LzIcon, TopSticky } from 'vue_features/shared/components/ui'
import Routes from 'routes'
import EditActionMenu from 'vue_features/shared/components/forms/EditActionMenu'
import { isActive, isRevision } from 'vue_features/shared/composables/use_record_states'
import { activeModal } from 'vue_features/shared/composables/use_global_modals'

const props = defineProps({
  originalId: { type: [String, Number], default: null },
  objectId: { type: [Number, String], default: null },
  objectType: { type: String, required: true },
  objectName: { type: String, required: true },
  actionName: { type: String, default: 'edit' },
  cancelPath: { type: String, default: '' },
  state: { type: String, required: true },
})
const isResource = computed(() => props.objectType === 'Resource')
const editLabel = computed(() => {
  if (props.actionName === 'new') {
    return 'common.new'
  }
  return isActive(props.state) ? 'common.published' : `common.${props.state}`
})
const actionLabel = computed(() => {
  switch (props.actionName) {
    case 'new':
      return 'common.creating'
    case 'customize':
      return 'common.customizing'
    default:
      return 'common.editing'
  }
})
const hasActiveModal = computed(() => !!activeModal.value.id)
</script>

<template>
  <TopSticky :class="['z-20', { 'z-50': hasActiveModal }]">
    <Banner :type="isRevision(state) ? 'default' : 'inverted'" :has-top-border="false" class="bg-opacity-90">
      <p class="hidden text-white md:inline-block">
        <template v-if="isRevision(state)">
          {{ $t('revisions.you_are_revising', { object_name: objectName }) }}
        </template>
        <template v-else>
          {{
            $t('common.you_are_doing', {
              action: $t(actionLabel),
              objectName,
              state: $t(editLabel).toLocaleLowerCase(),
            })
          }}
        </template>
      </p>
      <EditActionMenu
        v-if="isRevision(state)"
        v-bind="{ objectName, objectId, objectType, originalId, isRevision: true, action: 'edit' }"
      />
      <template v-if="isResource">
        <LzButton
          data-test="save-button"
          size="small"
          color="green"
          class="flex items-center gap-1"
          @click="$emit('save')"
        >
          <LzIcon path="icons/check" size="sm" type="span" />
          {{ $t('common.save') }}
        </LzButton>
        <a :href="cancelPath" class="text-inverted hover:text-inverted-hover cursor-pointer text-sm">
          {{ $t('common.cancel') }}
        </a>
      </template>
      <LzButton
        v-else
        :href="Routes.lesson_plan_revision_path(props.originalId)"
        tag="a"
        color="green"
        size="small"
        class="flex items-center gap-1"
      >
        <LzIcon path="icons/check" size="sm" type="span" />
        {{ $t('common.close') }}
      </LzButton>
    </Banner>
  </TopSticky>
</template>
