<template>
  <div class="form-group">
    <div v-if="isPublicCustomer && clientReady && editable" class="max-w-7xl px-3 md:px-6">
      <v-select
        id="question-bank-selector"
        v-model="questionBanks"
        :options="$options.questionBanks"
        :placeholder="$t('learnosity_item_editor.add_to_question_bank_placeholder')"
        name="question_banks"
        multiple
      />
    </div>
    <div v-if="!editable && clientReady" class="max-w-7xl px-3 md:px-6">
      <Alert type="notice" class="mt-4 font-bold">
        <p class="mb-0 text-sm font-bold leading-6 text-yellow-800">
          {{ $t('shared.action_menu.is_shared') }}
          <a href="#" data-test="duplicate-btn" @click="duplicate">
            {{ $t('shared.action_menu.create_copy') }}
          </a>
        </p>
      </Alert>
    </div>
    <LearnosityAuthorWrapper :config="initialConfig" @client-updated="onClientUpdated">
      <p class="m-0 flex justify-end gap-0.5 pr-4 pt-2 text-xs text-blue-700">
        <InlineSvg path="icons/help.svg" class="max-w-4 max-h-4 stroke-current" />
        <a href="https://authorguide.learnosity.com/hc/en-us" target="_blank">
          {{ $t('learnosity_item_editor.learnosity_help') }}
        </a>
      </p>
    </LearnosityAuthorWrapper>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { useLearnosityItemEditorStore } from '../composables/use_learnosity_item_editor_store'
import { AVAILABLE_QUESTION_BANKS } from 'rails_constants'
import { InlineSvg, Alert } from 'vue_features/shared/components/ui'
import { pull } from 'lodash'
import vSelect from 'vue-select'
import LearnosityAuthorWrapper from './LearnosityAuthorWrapper'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'

export default {
  name: 'LearnosityItemEditor',
  components: { InlineSvg, Alert, vSelect, LearnosityAuthorWrapper },
  questionBanks: AVAILABLE_QUESTION_BANKS,
  props: {
    adaptation: { type: Boolean, required: true },
  },
  setup(props) {
    const learnosityConfig = inject('learnosityConfig')
    const { item, isItemEditable, client, updateClient, updateItem, clientReady } = useLearnosityItemEditorStore()
    clientReady.value = false

    const { isRevising } = useLessonPlanEditStore()
    const hasDuplicated = ref(false)

    const editable = computed(() => {
      if (isRevising.value) {
        return hasDuplicated.value || !!item.value.revisionId || !item.value.content || !item.value.id
      }
      return !item.value.content || isItemEditable.value
    })

    const initialConfig = !editable.value
      ? learnosityConfig.value.forUneditableItem
      : learnosityConfig.value.forCreation

    const duplicate = async () => {
      clientReady.value = false

      const oldItemRef = item.value.content
      adjustTagsForDuplicatedItem(oldItemRef)

      const newItemRef = await client.value.duplicateItem()
      await updateClient(learnosityConfig.value.forCreation)
      await loadItem(newItemRef)
      setUpClientCallbacks()
      client.value.beforeSave()
      await client.value.save()

      if (isRevising.value) {
        hasDuplicated.value = true
        updateItem({ id: item.value.id, content: newItemRef, editable: true })
      } else {
        updateItem({ id: null, content: newItemRef, editable: true })
      }
    }

    const setUpClientCallbacks = () => {
      client.value.beforeSave = beforeSave
      // ensures new items get proper tags if user cancels out of creation
      client.value.on('save', beforeSave)
      client.value.on('save:success', afterSave)
    }

    const { isPublicCustomer } = useCurrentCustomerStore()
    const adjustTagsForDuplicatedItem = (originalItemReference) => {
      const tags = client.value.getAllTags()

      tags['Copied from'] = originalItemReference
      tags['Owner'] = `${useCurrentCustomerStore().state.id}`

      if (props.adaptation) {
        tags['duplicated'] = ['true']
      }
      if (isRevising.value) {
        tags['revision'] = ['true']
      }

      pull(tags['Programmatic'], 'imported')

      if (!isPublicCustomer.value) {
        delete tags['Bank']
      }

      client.value.replaceTags(tags)
    }

    const loadItem = async (itemReference) => {
      await client.value.editItem(itemReference)
      const tags = client.value.getAllTags()
      updateItem({ questionBanks: tags['Bank'] || [] })

      if (editable.value) {
        client.value.workAroundCKEditorDialogStylesProblem()
      }

      updateItem({ scoringType: client.value.getScoringType() })
      clientReady.value = true
    }

    const addCustomTags = () => {
      const currentTags = client.value.getAllTags()
      if (props.adaptation) {
        currentTags['duplicated'] = ['true']
      }
      if (isRevising.value) {
        currentTags['revision'] = ['true']
      }
      if (isPublicCustomer.value) {
        currentTags['Bank'] = item.value.questionBanks
      }
      client.value.replaceTags(currentTags)
    }

    const beforeSave = () => {
      addCustomTags()
      client.value.setScoringType(item.value.scoringType)
    }

    const afterSave = async () => {
      updateItem(await client.value.toItem())
    }

    const onClientUpdated = async () => {
      setUpClientCallbacks()
      if (item.value.content) {
        await loadItem(item.value.content)
      } else {
        client.value.createItem()
        updateItem({ scoringType: client.value.getScoringType() })
        client.value.workAroundCKEditorDialogStylesProblem()
        clientReady.value = true
      }
    }

    const questionBanks = computed({
      get: () => item.value.questionBanks,
      set: (newBanks) => {
        updateItem({ questionBanks: newBanks })
      },
    })

    return { clientReady, isPublicCustomer, editable, duplicate, questionBanks, initialConfig, onClientUpdated }
  },
}
</script>
