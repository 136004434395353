import { computed, unref } from 'vue'

const horizontalAlignments = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
}

const verticalAlignments = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
}

export const alignmentProps = {
  horizontalAlignment: {
    type: String,
    default: 'center',
  },
  verticalAlignment: {
    type: String,
    default: 'center',
  },
}

export default function useSharedCellProperties(alignmentOptions) {
  const alignmentClasses = computed(() => {
    const options = unref(alignmentOptions)

    return [horizontalAlignments[options.horizontalAlignment], verticalAlignments[options.verticalAlignment]]
  })

  return { alignmentClasses }
}
