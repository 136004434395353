<template>
  <Badge data-test="assignment-score" class="min-w-16">
    <template v-if="!isLoading">{{ scoreText }}</template>
  </Badge>
</template>

<script>
import { Badge } from 'vue_features/shared/components/ui'
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import { components } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { captureException } from 'clients/sentry'
import Routes from 'routes'

export default {
  name: 'AssignmentScoreInfo',
  i18n: { messages: pickLocaleNamespace(components, 'score_info') },
  components: { Badge },
  props: {
    assignmentState: {
      type: String,
      required: true,
    },
    learnositySessionId: {
      type: String,
      required: false,
      default: null,
    },
    assignmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      score: null,
      maxScore: null,
    }
  },
  computed: {
    canLoadLearnosityData() {
      return this.assignmentState === 'returned' && this.learnositySessionId
    },
    studentResponseUrl() {
      return Routes.lz_code_student_response_path(this.assignmentId, this.learnositySessionId)
    },
    scoreText() {
      return this.hasScore
        ? `${this.percentage}% (${this.score}/${this.maxScore})`
        : this.$t('score_info.not_attempted')
    },
    hasScore() {
      return this.score !== null && this.maxScore !== null
    },
    percentage() {
      if (this.maxScore === 0) return 0

      const ratio = this.score / this.maxScore
      return Math.round(ratio * 1000) / 10
    },
  },
  created() {
    if (!this.canLoadLearnosityData) {
      this.isLoading = false
      return
    }

    HTTP.getJSON(this.studentResponseUrl)
      .then(({ items: itemKeys }) => {
        if (itemKeys) {
          const items = Object.values(itemKeys)
          const scoringMap = items.map((i) => i.scoring)
          const score = scoringMap.reduce((sum, i) => sum + i.score, 0)
          const maxScore = scoringMap.reduce((sum, i) => sum + i.maxScore, 0)

          this.score = Math.round(score * 1000) / 1000
          this.maxScore = maxScore
        }

        this.isLoading = false
      })
      .catch(captureException)
  },
}
</script>
