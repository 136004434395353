import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

const AdminAccessesService = {
  add(userId) {
    return HTTP.create(Routes.site_admin_admin_accesses_path(), { id: userId })
  },
  remove(userId) {
    return HTTP.delete(Routes.site_admin_admin_access_path(userId))
  },
  load({ state, page }) {
    return HTTP.getJSON(Routes.site_admin_admin_accesses_path(), { state, page })
  },
}

export default AdminAccessesService
