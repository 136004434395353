<template>
  <div class="-shadow-md-400 fixed bottom-0 z-30 flex w-full justify-center">
    <div class="bg-primary-700 flex h-24 w-full overflow-x-auto md:justify-center md:overflow-visible">
      <div
        v-if="authorizedAsTeacher"
        class="toolbar-item flex max-w-min flex-col items-center justify-center whitespace-nowrap px-3 py-6 md:px-6"
      >
        <span class="text-xs text-white">{{ $t('common.teacher_paced') }}</span>
        <SwitchToggle
          v-model="isTeacherPaced"
          class="pt-1"
          data-test="teacher-pacing-toggle"
          @input="$emit('toggle-teacher-pacing')"
        />
      </div>

      <LiveLearnToolbarButton
        v-if="authorizedAsTeacher"
        :active="showAnnotationTools"
        :label="$t('live_learn.annotate')"
        :disabled="!canAnnotate"
        icon-path="icons/edit"
        data-test="annotate-button"
        @click="toggleAnnotationTools(subscription)"
      />

      <div
        v-if="assignment.itemPoolPending"
        class="toolbar-item z-200 flex flex-grow items-center justify-center py-2 sm:px-4"
      >
        <LoadingSpinner data-test="assignment-loading-spinner" tag="span" />
      </div>
      <template v-else>
        <div
          data-test="card-navigation"
          :class="[
            'toolbar-item z-200 flex min-w-min flex-grow items-center justify-center py-2 sm:min-w-0 sm:px-4',
            { hidden: !canNavigate },
          ]"
        >
          <div class="relative flex w-full min-w-0 flex-nowrap items-center justify-between">
            <LzIcon
              v-if="canNavigate"
              :class="[
                'mr-1 flex flex-shrink-0 items-center',
                isOnFirstCard ? 'pointer-events-none opacity-25' : 'cursor-pointer',
              ]"
              icon-color-class="text-white hover:text-primary-200 transition-colors"
              size="lg"
              path="icons/chevron-left"
              data-test="previous-button"
              @click="navigateToCard(previousCardId)"
            />
            <div
              v-if="!assignment.itemPoolPending"
              id="toolbar-card-indicators"
              ref="toolbarCardIndicatorPane"
              class="animation--fade-in hidden w-full flex-nowrap overflow-x-auto px-2 py-4 sm:flex"
            >
              <PositionIndicator
                v-for="(card, index) in assignmentDisplayCards"
                ref="cardIndicators"
                :key="card.id"
                :card="card"
                :ordinal="index"
                @click="navigateToCard(card.id)"
              />
              <!-- Add spacing to allow entire bubble with ring to show -->
              <div class="w-1">&nbsp;</div>
            </div>
            <LzIcon
              v-if="canNavigate"
              :class="[
                'ml-1 flex flex-shrink-0 items-center',
                isOnLastCard ? 'pointer-events-none opacity-25' : 'cursor-pointer',
              ]"
              icon-color-class="text-white hover:text-primary-200 transition-colors"
              size="lg"
              path="icons/chevron-right"
              data-test="next-button"
              @click="navigateToCard(nextCardId)"
            />

            <div
              class="shadow-md-500 bg-primary-500 absolute bottom-full left-1/2 z-10 flex -translate-x-1/2 transform items-stretch justify-center overflow-hidden whitespace-nowrap rounded-md transition duration-200"
              :class="{ 'mb-0 opacity-0': !selectedStudent, 'mb-4 opacity-100': selectedStudent }"
              :data-test="`student-info-notch-${selectedStudent ? 'open' : 'closed'}`"
            >
              <button
                v-show="isNewDataAvailable"
                class="bg-accent hover:bg-accent-hover z-0 inline-flex cursor-pointer items-center px-4 py-2 text-white transition duration-300"
                :data-test="`refresh-responses-button-${isNewDataAvailable ? 'visible' : 'hidden'}`"
                @click="refreshResponsesData"
              >
                <LzIcon path="icons/refresh" />
              </button>

              <div class="bg-primary-600 z-10 m-0 px-4 py-2 text-white" data-test="notch-student-name">
                {{ $t('live_learn.viewing') }} {{ selectedStudentName }}
              </div>

              <button
                v-if="selectedStudent && selectedStudentPosition !== -1 && isSelectedStudentConnected"
                class="bg-primary-dark-600 hover:bg-primary-500 z-0 m-0 cursor-pointer px-4 py-2 text-sm font-semibold text-white transition duration-300"
                :class="{ 'ml-0': showPositionInNotch, '-ml-10': !showPositionInNotch }"
                data-test="notch-student-position-button"
                @click="navigateToPosition(selectedStudentPosition)"
              >
                {{ selectedStudentPosition + 1 }}
              </button>

              <button
                v-if="isSelectedQuestionCard"
                class="inline-flex cursor-pointer items-center bg-green-500 px-4 py-2 text-white transition duration-300 hover:bg-green-600"
                data-test="share-student-work-button"
                @click="startSharing"
              >
                {{ $t('live_learn.share') }}
              </button>
            </div>
          </div>
        </div>
        <div
          data-test="disabled-navigation-notice"
          :class="[
            'toolbar-item animation--fade-in flex flex-grow flex-col items-center justify-center px-4 py-2',
            { hidden: canNavigate },
          ]"
        >
          <p class="bg-primary-900 text-neutral-100 rounded px-4 py-3 text-sm">{{
            $t('live_learn.teacher_paced_notice')
          }}</p>
        </div>
      </template>

      <LiveLearnToolbarButton
        :label="$t('live_learn.actions')"
        :icon-path="isDrawerOpen ? `icons/close` : `icons/hamburger`"
        data-test="live-learn-actions"
        class="z-10 md:hidden"
        @click="toggleDrawer"
      />
      <div
        :class="
          isDrawerOpen ? ' rounded-t-xl opacity-100' : ' translate-y-full opacity-0 md:translate-y-0 md:opacity-100'
        "
        class="bg-primary-700 md: fixed right-0 z-0 flex-shrink-0 -translate-y-full transform overflow-hidden transition-all md:static md:bottom-0 md:flex md:translate-y-0 md:rounded-none"
      >
        <LiveLearnToolbarButton
          v-if="authorizedAsTeacher"
          :active="notesActive"
          :label="$t('live_learn.notes')"
          icon-path="icons/notes"
          data-test="notes-button"
          @click="$emit('change-active-drawer', 'notes', 'toggleDrawer')"
        />
        <LiveLearnToolbarButton
          v-if="authorizedAsTeacher"
          id="people-button"
          :active="peopleActive"
          :label="$t('live_learn.people')"
          icon-path="icons/users"
          data-test="people-button"
          @click="$emit('change-active-drawer', 'people', 'toggleDrawer')"
        />
        <LiveLearnToolbarButton
          dangerous
          :label="$t('live_learn.end_live_learn')"
          icon-path="icons/logout"
          data-test="end-live-learn-button"
          @click="endLiveLearn"
        />
      </div>
    </div>
  </div>
</template>

<script>
/** NOTE: This shares much of the look and feel with the new lesson
 * player toolbar (/app/javascript/vue_features/lesson_plans/show/components/LessonToolbar.vue)
 * but they do not share code.  Eventually we'd like to abstract the common behavior into a single
 * class, but until then be aware you may have to make changes to both if UI changes
 * are being made.
 */
import { LzIcon, SwitchToggle } from 'vue_features/shared/components/ui'
import LiveLearnToolbarButton from './LiveLearnToolbarButton'
import PositionIndicator from './PositionIndicator'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { computed, ref, watch, onMounted } from 'vue'
import location from 'utils/location'
import { get } from 'lodash'
import {
  useAssignmentCardsStore,
  useAssignmentStore,
  useLiveLearnChannel,
  useLiveLearnStore,
} from 'vue_features/assignments/show/composables'
import { useCardTypes } from 'vue_features/shared/composables'
import { useAnnotationToolStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'LiveLearnToolbar',
  components: { LzIcon, SwitchToggle, LiveLearnToolbarButton, PositionIndicator, LoadingSpinner },
  props: {
    activeDrawer: {
      type: String,
      default: null,
      validator: function (value) {
        return ['people', 'notes'].includes(value)
      },
    },
  },
  setup(props, { emit }) {
    const selectedStudentName = ref('')
    const cardIndicators = ref([])

    const { toggleAnnotationTools, showAnnotationTools } = useAnnotationToolStore()

    const {
      assignmentDisplayCards,
      selectedAssignmentCardId,
      assignmentDisplayCardsPosition,
      getAssignmentCardPosition,
    } = useAssignmentCardsStore()
    const { authorizedAsTeacher } = useCurrentUserStore()
    const { assignment } = useAssignmentStore()
    const {
      connectedStudentIds,
      isTeacherPaced,
      selectedStudent,
      loadResponseData,
      getStudentPosition,
      isNewDataAvailable,
      klassId,
    } = useLiveLearnStore()
    const { subscription, startSharing } = useLiveLearnChannel()
    const isSelectedStudentConnected = computed(() => connectedStudentIds.value.includes(selectedStudent.value?.id))
    const selectedStudentPosition = computed(() => getStudentPosition(get(selectedStudent.value, 'id')))
    const showPositionInNotch = computed(
      () =>
        !isTeacherPaced.value &&
        selectedStudent.value &&
        !isNaN(getStudentPosition(selectedStudent.value.id)) &&
        isSelectedStudentConnected.value,
    )

    const nextCardId = computed(() => {
      const card = assignmentDisplayCards.value[assignmentDisplayCardsPosition.value + 1]
      return card ? card.id : null
    })

    const previousCardId = computed(() => {
      const card = assignmentDisplayCards.value[assignmentDisplayCardsPosition.value - 1]
      return card ? card.id : null
    })
    const isOnFirstCard = computed(() => {
      const firstCardId = assignmentDisplayCards.value[0] ? assignmentDisplayCards.value[0].id : null
      return selectedAssignmentCardId.value === firstCardId
    })
    const isOnLastCard = computed(() => {
      const lastCard = assignmentDisplayCards.value[assignmentDisplayCards.value.length - 1]
      const lastCardId = lastCard ? lastCard.id : null
      return selectedAssignmentCardId.value === lastCardId
    })
    const selectedCard = computed(() => assignmentDisplayCards.value[assignmentDisplayCardsPosition.value])
    const { isQuestionCard: isSelectedQuestionCard } = useCardTypes(selectedCard)
    const canAnnotate = computed(() => !selectedStudent.value)

    const canNavigate = computed(
      () => !assignment.value.itemPoolPending && (authorizedAsTeacher.value || !isTeacherPaced.value),
    )

    const isDrawerOpen = ref(false)

    function toggleDrawer() {
      isDrawerOpen.value = !isDrawerOpen.value
    }

    function navigateToPosition(position) {
      const card = assignmentDisplayCards.value[position]
      navigateToCard(card.id)
    }

    function navigateToCard(id) {
      if (canNavigate.value) emit('assignment-card-changed', id)
    }

    function endLiveLearn() {
      if (authorizedAsTeacher.value) {
        subscription.value.perform('destroy')
      } else {
        location.assign(Routes.student_klass_path(klassId.value))
      }
    }

    function refreshResponsesData() {
      loadResponseData()
    }

    function scrollToSelectedCard(id) {
      const index = getAssignmentCardPosition(id)

      if (index !== -1) {
        const indicator = cardIndicators.value[index]

        if (indicator) {
          indicator.$el.scrollIntoView({ inline: 'center' })
        }
      }
    }

    onMounted(() => {
      scrollToSelectedCard(selectedAssignmentCardId.value)
    })

    watch(selectedStudent, (selectedStudent) => {
      if (selectedStudent) {
        if (showAnnotationTools.value) {
          toggleAnnotationTools(subscription.value)
        }
        selectedStudentName.value = selectedStudent.name
      }
    })

    watch(selectedAssignmentCardId, (id) => {
      scrollToSelectedCard(id)
    })

    return {
      assignment,
      assignmentDisplayCards,
      authorizedAsTeacher,
      canNavigate,
      endLiveLearn,
      showPositionInNotch,
      selectedAssignmentCardId,
      nextCardId,
      previousCardId,
      navigateToCard,
      navigateToPosition,
      isOnLastCard,
      isOnFirstCard,
      getAssignmentCardPosition,
      isTeacherPaced: computed({
        get: () => isTeacherPaced.value,
        set: (newValue) => {
          subscription.value.perform('update_root_state', {
            position: getAssignmentCardPosition(selectedAssignmentCardId.value),
            teacher_paced: newValue,
          })
        },
      }),
      notesActive: computed(() => props.activeDrawer === 'notes'),
      peopleActive: computed(() => props.activeDrawer === 'people'),
      selectedStudent,
      isDrawerOpen,
      selectedStudentPosition,
      selectedStudentName,
      refreshResponsesData,
      isNewDataAvailable,
      startSharing,
      selectedCard,
      isSelectedQuestionCard,
      cardIndicators,
      isSelectedStudentConnected,
      showAnnotationTools,
      toggleAnnotationTools,
      toggleDrawer,
      canAnnotate,
      subscription,
    }
  },
}
</script>
