<template>
  <div class="lzui-modal" data-test="lz-modal">
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions | has focus trap and close buttons for keyboard -->
    <div class="lzui-modal__wrapper" data-test="background" @click.self="handleClose('bg-click')">
      <div
        ref="modalBody"
        class="lzui-modal__dialog rounded-2xl"
        role="dialog"
        :class="modalClasses"
        :title="modalTitle ? modalTitle : $t('LzModal.dialog')"
      >
        <header v-if="modalTitle">
          <h2 class="flex items-center gap-2">
            <slot name="header-icon"></slot>
            {{ modalTitle }}
          </h2>
        </header>
        <div class="section lzui-modal__body">
          <slot v-bind="{ modalId, close }"></slot>
          <button
            v-if="canCancel"
            class="lzui-modal__close"
            data-test="close-button"
            :aria-label="$t('LzModal.close')"
            @click.prevent.stop="handleClose('close-modal')"
          >
            <InlineSvg path="icons/close" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from './InlineSvg'
import { ref } from 'vue'
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import useFocusTrap from 'vue_features/shared/composables/use_focus_trap'

export default {
  name: 'LzModal',
  components: { InlineSvg },
  i18n: { messages: namespaceLocaleObj(common, 'LzModal', { only: ['close', 'dialog'] }) },
  props: {
    modalClasses: {
      type: String,
      default: '',
    },
    modalId: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    autoclose: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const close = () => closeModal(props.modalId)

    const modalBody = ref()
    useFocusTrap(modalBody)

    function handleClose(eventName) {
      if (props.canCancel) {
        props.autoclose ? close() : emit(eventName, props.modalId)
      }
    }

    return { handleClose, close, modalBody }
  },
}
</script>
