import { merge as _merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { itemAnalysis, dataDashboards, components, common } from 'vue_features/shared/i18n'

export default _merge(
  {},
  itemAnalysis,
  namespaceLocaleObj(dataDashboards, 'data_dashboard', {
    only: [
      'dashboard',
      'download_data',
      'data_out_of_range_message',
      'hero_banner_body',
      'hero_banner_title',
      'view_the_guide',
    ],
  }),
  pickLocaleNamespace(components, 'learnosity_item'),
  namespaceLocaleObj(common, 'common', {
    only: [
      'activity',
      'all',
      'assessment',
      'cancel',
      'class',
      'copy',
      'date_range',
      'end_date',
      'form.status',
      'form.subject',
      'full',
      'full_lesson',
      'grade',
      'graded',
      'last_n_days',
      'needs_grading',
      'next',
      'not_attempted',
      'nothing_selected',
      'ordered',
      'ordinal_suffixes',
      'previous',
      'reset',
      'reset_filters',
      'school',
      'school_year_to_date',
      'search',
      'start_date',
      'subjects',
      'teacher',
      'type',
      'what',
      'when',
      'who',
    ],
  }),
)
