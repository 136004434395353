<script setup>
import StudentToolsPanel from 'vue_features/lesson_plans/show/components/StudentToolsPanel'
import LessonContainer from 'vue_features/lesson_plans/show/components/LessonContainer'
import AnnotationTools from 'vue_features/shared/components/AnnotationTools'
import { Portal } from 'portal-vue'
import LessonThumbs from 'vue_features/lesson_plans/show/components/LessonThumbs'
import LessonToolbar from 'vue_features/lesson_plans/show/components/LessonToolbar'
import { LzIcon } from 'vue_features/shared/components/ui'
import {
  useLessonPlayer,
  useLessonPlayerIterator,
  useLessonPlayerScrollManager,
  useLessonPlayerUrlSupport,
  useLessonPlayerViewManager,
} from 'vue_features/lesson_plans/show/composables/use_lesson_player'
import { computed, inject, onDeactivated, onMounted, ref } from 'vue'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useAnnotationToolStore, useCurrentUserStore, useGlobalStore } from 'vue_features/shared/store/composables'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
const root = inject('useRoot')()
const { lessonPlan, studentTools } = useLessonPlanStore()
const { isFullscreen } = useFullscreenStore()
const cards = ref(lessonPlan.value.cards)
const { allowAnnotations } = useAnnotationToolStore()
const { isPresenting } = useLessonPlanShowStore()
const lessonPlayerElement = ref(null)
const scrollManagerRoot = ref(null)
const {
  selectedCardIndex,
  updateSelectedCardIndex,
  toggleSidebar,
  toggleNotes,
  showNotes,
  showSidebar,
  showToolbar,
  toggleToolbar,
  trackEvent,
} = useLessonPlayer()
const {
  hasScrolledPastThreshold,
  scrollToTop,
  scrollToSelectedCard,
  currentCardElement,
  preserveCard,
  setScrollObserving,
} = useLessonPlayerScrollManager({
  lessonPlayerElementRef: lessonPlayerElement,
  scrollManagerRootRef: scrollManagerRoot,
  selectedCardIndexRef: selectedCardIndex,
  updateSelectedCard: updateSelectedCardIndex,
  trackEvent,
})
const { openPresenterWindow, handleToggleFullscreen } = useLessonPlayerViewManager({
  selectedCardIndexRef: selectedCardIndex,
  trackEvent,
  cardSelected: scrollToSelectedCard,
  preserveCard,
})
const { goPrevious, goNext, activateKeyboardNavigation } = useLessonPlayerIterator({
  rootElRef: scrollManagerRoot,
  showToolbarRef: showToolbar,
  currentCardElementRef: currentCardElement,
  selectedCardIndexRef: selectedCardIndex,
  cardsRef: cards,
  cardSelected: scrollToSelectedCard,
})
useLessonPlayerUrlSupport({
  selectedCardIndexRef: selectedCardIndex,
  cardsRef: cards,
  onInitialCardIndex: preserveCard,
  root,
})
onMounted(() => {
  activateKeyboardNavigation()
})
const toggleFullscreen = () => {
  handleToggleFullscreen({
    onBeforeChange: () => {
      setScrollObserving(false)
      setTimeout(() => {
        setScrollObserving(true)
      }, 1000)
    },
  })
}
const hasStudentTools = computed(() => studentTools.value.length > 0)
const { isContentEmbedded, isMobileDevice } = useGlobalStore()
const { isStudent, authorizedAsTeacher } = useCurrentUserStore()
const allowPresent = computed(() => {
  return authorizedAsTeacher.value && !isContentEmbedded.value && !isFullscreen.value
})
const combine = (...funcs) => {
  funcs.forEach((f) => f())
}

onDeactivated(useLearnosityClientStore().resetAll)
</script>

<template>
  <div
    id="lesson-player"
    ref="lessonPlayerElement"
    class="lesson-player flex w-full flex-col items-center"
    :data-fullscreen="isFullscreen ? true : null"
    :data-active-notes="showNotes ? true : null"
    :data-active-sidebar="showSidebar ? true : null"
  >
    <div v-if="isFullscreen" class="lesson-player-bg bg-neutral-200 fixed left-0 top-0 z-10 h-full w-full" />
    <div ref="scrollManagerRoot" class="lesson-plan-scroll-manager z-10 w-full">
      <div class="lesson-content min-h-96 flex w-full flex-row py-6">
        <LessonThumbs
          class="top-2 hidden flex-col items-center sm:flex"
          :cards="cards"
          :show-sidebar="showSidebar"
          :selected-card-index="selectedCardIndex"
          @card-selected="scrollToSelectedCard"
        />
        <LessonContainer
          :cards="cards"
          :selected-card-index="selectedCardIndex"
          @on-card-selected="updateSelectedCardIndex"
        />
        <StudentToolsPanel />
        <Portal to="annotations">
          <AnnotationTools v-if="allowAnnotations" :selected-card-index="selectedCardIndex" :cards="cards" />
        </Portal>
      </div>
      <LessonToolbar
        v-if="cards.length > 0"
        class="sticky"
        :class="{
          '-bottom-18': !showToolbar,
          'bottom-1': showToolbar,
        }"
        :cards="cards"
        :show-notes="showNotes"
        :show-sidebar="showSidebar"
        :show-toolbar="showToolbar"
        :show-notes-button="!isStudent && !isPresenting"
        :show-annotations-button="allowAnnotations"
        :show-tools-button="!isFullscreen && hasStudentTools"
        :show-presenter-button="allowPresent && !isMobileDevice"
        :show-fullscreen-button="!isContentEmbedded"
        :selected-card-index="selectedCardIndex"
        @open-presenter="combine(preserveCard, openPresenterWindow)"
        @toggle-notes="combine(preserveCard, toggleNotes)"
        @toggle-sidebar="combine(preserveCard, toggleSidebar)"
        @toggle-toolbar="toggleToolbar"
        @card-selected="scrollToSelectedCard"
        @go-previous="goPrevious"
        @go-next="goNext"
        @toggle-fullscreen="combine(preserveCard, toggleFullscreen)"
      />
      <div
        data-test="back-to-top-wrapper"
        class="fixed bottom-20 right-4 z-50 transform transition-all duration-300 2xl:bottom-2 2xl:right-8"
        :class="hasScrolledPastThreshold ? 'translate-y-0 opacity-100' : 'pointer-events-none translate-y-10 opacity-0'"
      >
        <button
          data-test="back-to-top"
          class="lesson-back-to-top z-50 flex flex-col items-center space-y-2"
          aria-labelledby="back-to-top-label"
          @click="scrollToTop"
        >
          <div
            class="text-primary-700 flex h-16 w-16 items-center justify-center rounded-full bg-white p-4 text-center text-opacity-90 shadow-xl transition-all duration-300"
          >
            <LzIcon size="lg" path="icons/arrow-up" />
          </div>
          <span id="back-to-top-label" class="text-sm transition-all duration-300">
            {{ $t('show.back_to_top') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.lesson-back-to-top > span {
  opacity: 0.25;
}
.lesson-back-to-top:hover > button {
  background-color: rgba(var(--primary-700), 0.9125);
  color: white;
}
.lesson-back-to-top:hover > span {
  opacity: 1;
}
</style>
