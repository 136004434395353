import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { lessonPlans, shared, studentTools } from 'vue_features/shared/i18n'
import filestackUploadMessages from 'vue_features/shared/i18n/filestack_upload_messages'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'

export default merge(
  {},
  pickLocaleNamespace(lessonPlans, 'info_form'),
  pickLocaleNamespace(lessonPlans, 'readable_types'),
  namespaceLocaleObj(shared, 'shared', { only: 'text_style' }),
  namespaceLocaleObj(studentTools, 'student_tools'),
  standardsMessages,
  filestackUploadMessages,
)
