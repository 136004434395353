<template>
  <div class="lesson-plan__content w-full">
    <div :class="{ 'premium-gate': premiumGate }">
      <ArchiveBanner />
      <PageHead />
    </div>
    <div v-if="isGated" class="page-content">
      <div class="lesson-plan__lesson">
        <div class="mx-auto max-w-7xl">
          <div class="lp-deck">
            <div class="lp-card__content">
              <ImageTag class="max-h-79 mx-auto" path="misc/content-placeholder.png" alt="Content unavailable" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div v-if="links.length > 1" class="page-head-nav">
        <div class="lesson-tabs mb-5 mt-8 flex flex-1 border-b-2 border-solid">
          <LzTabs :links="links" />
        </div>
      </div>
      <RouterView v-slot="{ Component }">
        <KeepAlive>
          <Component :is="Component" />
        </KeepAlive>
      </RouterView>
    </template>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { provide, computed, ref, inject } from 'vue'
import ArchiveBanner from './ArchiveBanner'
import LzTabs from 'vue_features/shared/components/ui/tabs/LzTabs'
import ImageTag from 'vue_features/shared/components/ui/ImageTag'
import PageHead from './PageHead'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useLessonPlayer } from 'vue_features/lesson_plans/show/composables/use_lesson_player'

export default {
  name: 'LessonPlanContent',
  components: {
    ArchiveBanner,
    ImageTag,
    LzTabs,
    PageHead,
  },
  setup() {
    const root = inject('useRoot')()
    const { premiumGate, isGated } = useLessonPlanShowStore()
    const { lessonPlan, lessonType, lessonPlanTextStyle, originalId, lessonPlanId } = useLessonPlanStore()
    const { isStudent } = useCurrentUserStore()
    const shortenedType = computed(() => root.$t(`lesson_plans.readable_types.shortened.${lessonType.value}`))
    const longType = computed(() => root.$t(`lesson_plans.readable_types.${lessonType.value}`))
    const cards = ref(lessonPlan.value.cards)

    provide('fontTextStyle', lessonPlanTextStyle)

    const { selectedCardIndex } = useLessonPlayer()
    const selectedCard = computed(() => {
      return selectedCardIndex.value ? cards.value[selectedCardIndex.value] : null
    })

    const routeId = originalId.value || lessonPlanId.value
    const links = computed(() => {
      const value = [
        {
          name: 'lesson',
          label: capitalize(longType.value),
          default: true,
          params: { id: routeId },
          query: { card: selectedCard.value?.id },
        },
      ]
      if (lessonPlan.value.additionalMaterials.length > 0 && !isGated.value && !isStudent.value) {
        value.push({
          name: 'additional_materials',
          label: root.$t('show.additional_materials'),
          params: { id: routeId },
        })
      }
      if (!isGated.value) {
        value.push({
          name: 'description',
          label: root.$t('show.about_this_lesson', {
            lesson_type: shortenedType.value,
          }),
          params: { id: routeId },
        })
      }
      return value
    })

    return {
      isStudent,
      links,
      premiumGate,
      isGated,
      cards,
      selectedCardIndex,
    }
  },
}
</script>

<style scoped>
.lesson-tabs {
  border-color: #bac9ff;
}
</style>
