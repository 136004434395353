<template>
  <!-- content sanitized in serializer -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div ref="contentEl" class="ck-editable clear-both" v-html="content" />
</template>

<script>
import { ref } from 'vue'
import { useCrumbableLinks } from 'vue_features/resources/shared/composables/use_crumbable_links'

export default {
  name: 'WikiHtmlBlock',
  props: { content: { type: String, required: true } },
  setup() {
    const contentEl = ref(null)
    useCrumbableLinks(contentEl)
    return { contentEl }
  },
}
</script>
