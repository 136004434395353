<template>
  <div class="flex h-screen w-screen flex-col text-gray-900">
    <Filters />

    <NoAvailableSessionsAlert v-if="!currentSession" />

    <Actions v-if="currentSession" />

    <SidebarContainer v-if="currentSession" :open="showSidebar" @toggle="onToggleSidebar">
      <div ref="mainContentRef" class="bg-primary-400 min-w-96 w-full overflow-auto p-8 pb-16">
        <Alert
          v-if="currentSession.assignmentState === 'opened'"
          dismissible
          :title="$t('assignment_open')"
          :description="$t('close_to_grade')"
        />

        <div
          v-for="item of sessionItems"
          :key="item.reference"
          class="space-y-16"
          :class="shakeClasses"
          @animationend="stopShaking"
        >
          <ResponseCard
            v-for="question of questionsForItem(item.reference)"
            v-show="question.itemReference === currentItem.reference"
            :key="`${question.reference}-${currentSession.id}`"
            :item="item"
            :question="question"
            :response="sessionResponseToQuestion(question)"
            :data-test="`${question.reference}-response`"
          />
        </div>
      </div>

      <template #sidebar>
        <SidebarContent />
      </template>
    </SidebarContainer>

    <ItemNavigation v-if="currentSession" />

    <SessionChooser :open="showSessionChooser" />

    <SessionCommentModal v-if="currentSession && showSessionCommentModal" />

    <TipsModal v-if="showTipsModal" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { Alert } from 'vue_features/shared/components/ui'
import Filters from './Filters'
import Actions from './Actions'
import SidebarContainer from './SidebarContainer'
import SidebarContent from './SidebarContent'
import ResponseCard from './ResponseCard'
import ItemNavigation from './ItemNavigation'
import SessionChooser from './SessionChooser'
import NoAvailableSessionsAlert from './NoAvailableSessionsAlert'
import SessionCommentModal from './SessionCommentModal'
import TipsModal from './TipsModal'
import useKeyboardShortcuts from 'vue_features/shared/composables/use_keyboard_shortcuts'
import { useGradingStore } from '../composables/use_grading_store'

export default {
  name: 'Grading',
  components: {
    Alert,
    Filters,
    Actions,
    ResponseCard,
    SidebarContainer,
    SidebarContent,
    ItemNavigation,
    SessionChooser,
    NoAvailableSessionsAlert,
    SessionCommentModal,
    TipsModal,
  },
  setup() {
    const {
      klasses,
      selectKlass,
      sessionItems,
      filteredSessions,
      currentItem,
      currentItemIndex,
      currentSession,
      currentSessionIndex,
      loadingSession,
      sessionResponsesForItem,
      questionsForItem,
      sessionResponseToQuestion,
      showSessionChooser,
      showSessionCommentModal,
      gotoPreviousSession,
      gotoNextSession,
      gotoItem,
      gotoPreviousItem,
      gotoNextItem,
      showTipsModal,
    } = useGradingStore()

    const { registerKeyboardShortcut } = useKeyboardShortcuts()

    const showSidebar = ref(true)
    const mainContentRef = ref(null)

    const onToggleSidebar = () => {
      showSidebar.value = !showSidebar.value
    }

    const shakeClasses = ref([])
    const shake = () => {
      shakeClasses.value = ['animate-shake animate-iterations-2']
    }
    const stopShaking = () => {
      shakeClasses.value = []
    }

    const showingModal = computed(() => {
      return showSessionChooser.value || showSessionCommentModal.value || showTipsModal.value
    })

    registerKeyboardShortcut(['ArrowLeft'], (e) => {
      e.preventDefault()

      if (showingModal.value) {
        return
      }

      if (!gotoPreviousSession()) {
        shake()
      }
    })

    registerKeyboardShortcut(['ArrowRight'], (e) => {
      e.preventDefault()

      if (showingModal.value) {
        return
      }

      if (!gotoNextSession()) {
        shake()
      }
    })

    registerKeyboardShortcut(['a'], (e) => {
      e.preventDefault()

      if (showingModal.value) {
        return
      }

      if (!gotoPreviousItem()) {
        shake()
      }
    })

    registerKeyboardShortcut(['d'], (e) => {
      e.preventDefault()

      if (showingModal.value) {
        return
      }

      if (!gotoNextItem()) {
        shake()
      }
    })

    registerKeyboardShortcut(['s'], (e) => {
      e.preventDefault()

      if (showingModal.value) {
        return
      }

      showSidebar.value = !showSidebar.value
    })

    registerKeyboardShortcut(['?'], (e) => {
      e.preventDefault()

      if (showingModal.value && !showTipsModal.value) {
        return
      }

      showTipsModal.value = !showTipsModal.value
    })

    watch(currentItemIndex, () => {
      mainContentRef.value.scrollTo(0, 0)
    })

    return {
      klasses,
      selectKlass,
      filteredSessions,
      sessionItems,
      sessionResponsesForItem,
      currentSession,
      currentSessionIndex,
      currentItemIndex,
      currentItem,
      gotoItem,
      showSidebar,
      showSessionChooser,
      loadingSession,
      shake,
      shakeClasses,
      stopShaking,
      questionsForItem,
      sessionResponseToQuestion,
      showSessionCommentModal,
      mainContentRef,
      onToggleSidebar,
      showTipsModal,
    }
  },
}
</script>
