<template>
  <div>
    <LzAccordion
      v-for="(item, index) in items"
      :key="`${item.id}${index}`"
      :label="`${$t('common.item')} ${index + 1}`"
      open
      color="focus"
      class="bg-focus"
    >
      <template #head="{ label, toggle, isOpen }">
        <button class="group flex w-full cursor-pointer items-center gap-2 p-3" @click.prevent="toggle">
          <AccordionIcon :is-open="isOpen" />
          <div class="inline-block font-bold">
            {{ label }}
            <Badge v-if="!attemptedMap[item.reference]" color="gray" data-test="not-attempted-badge">
              {{ $t('assignment_response_report.unattempted') }}
            </Badge>
            <Badge v-else-if="!completeMap[item.reference]" color="gray" data-test="incomplete-badge">
              {{ $t('assignment_response_report.incomplete') }}
            </Badge>
            <div v-if="hasCommentsMap[item.reference]" class="inline-block h-4 w-4" data-test="comment-icon">
              <InlineSvg :path="'icons/bubble-bottom-left'" class="text-primary transition" />
            </div>
          </div>
        </button>
      </template>
      <MathJaxLoadingSpinner>
        <ReturnedResponse
          v-for="response in item.responses"
          :key="`${response.responseId}${item.id}`"
          :response-id="response.responseId"
          :score="response.score"
          :max-score="response.maxScore"
          :comment="response.comment"
          class="m-4"
        />
      </MathJaxLoadingSpinner>
    </LzAccordion>
  </div>
</template>

<script>
import ReturnedResponse from './ReturnedResponse'
import { InlineSvg, LzAccordion, Badge } from 'vue_features/shared/components/ui'
import { newQuestionsApp } from 'clients/learnosity'
import MathJaxLoadingSpinner from 'vue_features/shared/components/MathJaxLoadingSpinner'
import AccordionIcon from 'vue_features/shared/components/ui/AccordionIcon'

export default {
  name: 'ReturnedAssignmentAttempted',
  components: {
    AccordionIcon,
    InlineSvg,
    LzAccordion,
    Badge,
    MathJaxLoadingSpinner,
    ReturnedResponse,
  },

  props: {
    questionsApiInit: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    attemptedMap() {
      return this.items.reduce((obj, item) => {
        obj[item.reference] = item.responses.some((r) => r.response)
        return obj
      }, {})
    },

    hasCommentsMap() {
      return this.items.reduce((obj, item) => {
        obj[item.reference] = item.responses.some((r) => r.comment.content)
        return obj
      }, {})
    },

    completeMap() {
      return this.items.reduce((obj, item) => {
        obj[item.reference] = item.responses.every((r) => r.response)
        return obj
      }, {})
    },
  },

  created() {
    newQuestionsApp(this.questionsApiInit)
  },
}
</script>
