<template>
  <div v-if="hasCopies">
    <CopyObjectModal
      v-if="canModalShow($options.COPY_OBJECT_MODAL_ID)"
      v-bind="{ original, copiesData }"
      :modal-id="$options.COPY_OBJECT_MODAL_ID"
    />
    <Banner type="inverted" dismissible>
      <template v-if="hasSingleCopy">
        {{ $t('copies_banner.you_have_a') }}
        <a
          :href="Routes.lesson_plan_path(copiesData.copies[0].id)"
          class="text-accent-inverted hover:text-accent-inverted-hover underline"
        >
          {{ $t('copies_banner.copy') }}
        </a>
        {{ $t('copies_banner.of_this_lp') }}
      </template>
      <template v-else>
        {{ $t('copies_banner.you_have') }}
        <button class="btn btn--demoted px-0" @click="openModal($options.COPY_OBJECT_MODAL_ID)">
          {{ $t('copies_banner.copies') }}
        </button>
        {{ $t('copies_banner.of_this_lp') }}
      </template>
    </Banner>
  </div>
</template>

<script>
import { Banner } from 'vue_features/shared/components/ui'
import CopyObjectModal from 'vue_features/action_menu/components/CopyObjectModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import Routes from 'routes'

export default {
  name: 'CopiesBanner',
  COPY_OBJECT_MODAL_ID: `Copies${CopyObjectModal.name}`,
  components: { Banner, CopyObjectModal },
  props: {
    copiesData: { type: Object, required: true },
    original: { type: Object, required: true },
  },
  setup() {
    return { Routes, openModal, canModalShow }
  },
  data() {
    return { visible: true }
  },
  computed: {
    hasCopies() {
      return this.copiesData.copies.length > 0
    },
    hasSingleCopy() {
      return this.copiesData.copies.length === 1
    },
  },
}
</script>
