<template>
  <div class="error wistia">
    {{ $t('resources.processing.message', { type: resourceType }) }}
  </div>
</template>

<script>
export default {
  props: {
    resourceType: {
      type: String,
      default: 'video',
    },
  },
}
</script>
