<template>
  <LzModal
    :class="'lzui-modal--large'"
    :modal-id="modalId"
    :modal-title="$t('share_collection_modal_title')"
    style="text-align: left"
  >
    <div class="detail-item__toggle-form">
      <div class="mx-auto max-w-7xl">
        <div class="flex items-center">
          <legend class="mb-0 leading-6">{{ $t('shared_with') }}</legend>
          <button class="btn" @click="addShare">
            <LzIcon size="sm" path="icons/add" class="mr-1" />
            {{ $t('share_explanation') }}
          </button>
        </div>
        <SharingManagementTable
          v-if="!isSharedCollection"
          :collection="collection"
          :new-shares="newShares"
          class="mt-4"
          @added-share="addShare"
          @deleted-share="deleteShare"
          @updated-subdomain="updateSubdomain"
        />
      </div>
      <Alert v-if="showInvalidSubdomains" class="mt-2" type="error">
        <div>
          <div class="inline-flex">
            <p class="mb-0 text-sm font-bold leading-6 text-red-800">
              {{ $t('missing_subdomains') }}
            </p>
          </div>
          <ul class="ml-6 mt-4">
            <li v-for="subdomain in invalidSubdomains" :key="subdomain" class="my-2 px-2">
              {{ subdomain }}
            </li>
          </ul>
        </div>
      </Alert>
      <div class="md: mx-auto mt-4 flex max-w-7xl flex-col items-center gap-4 md:mt-6 md:flex-row md:justify-between">
        <div v-if="saving" class="loading float-right">
          <LoadingSpinner />
        </div>
        <template v-else>
          <div class="flex w-full justify-end">
            <button class="btn" @click.stop="$emit('close')">{{ $t('common.cancel') }}</button>
            <button
              v-if="!isSharedCollection"
              :class="['btn btn--primary ml-2', { disabled: !canSave }]"
              :disabled="!canSave"
              @click="saveForm"
            >
              {{ $t('common.save') }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </LzModal>
</template>

<script>
import { useCollectionsIndexStore } from '../../store/use_collections_index_store'
import { LzModal, LoadingSpinner, Alert, LzIcon } from 'vue_features/shared/components/ui'
import SharingManagementTable from '../SharingManagementTable'

export default {
  name: 'CollectionItemFormModal',
  components: { LoadingSpinner, SharingManagementTable, LzModal, LzIcon, Alert },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { createCollectionShares } = useCollectionsIndexStore()
    return { createCollectionShares }
  },
  data() {
    return {
      invalidSubdomains: [],
      newShares: [],
      saving: false,
    }
  },
  computed: {
    isSharedCollection() {
      return !!this.collection.customerShare
    },
    newSubdomains() {
      return this.newShares.map((share) => share.subdomain.trim())
    },
    showInvalidSubdomains() {
      return this.invalidSubdomains.length > 0
    },
    newShareId() {
      const lastShare = this.newShares[0] || { id: 0 }
      return lastShare.id + 1
    },
    canSave() {
      return this.newShares.length > 0 && this.newSubdomains.every((subdomain) => subdomain !== '')
    },
  },
  mounted() {
    this.addShare()
  },
  methods: {
    addShare() {
      this.newShares.unshift({ id: this.newShareId, subdomain: '' })
    },
    deleteShare(share) {
      this.newShares.splice(this.newShares.indexOf(share), 1)
    },
    updateSubdomain({ newValue, index }) {
      this.newShares[index].subdomain = newValue
    },
    closeForm() {
      this.invalidSubdomains = []
      this.clearForm()
      this.$emit('close')
    },
    clearForm() {
      this.saving = false
      this.newShares = []
    },
    async saveForm() {
      this.saving = true
      this.invalidSubdomains = await this.createCollectionShares({
        collection: this.collection,
        subdomains: this.newSubdomains,
      })
      this.clearForm()
    },
  },
}
</script>
