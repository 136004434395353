<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('common.archive')"
    :modal-id="$options.name"
    class="lzui-modal--medium"
    autoclose
  >
    <template v-if="processed">
      <p>{{ $t('archive_by_date_modal.archive_processed') }}</p>
    </template>
    <template v-else>
      <section>
        <Alert v-if="error" type="error" :title="error" data-test="error" />
        <h5>{{ $t('archive_by_date_modal.bulk_archive_instructions') }}</h5>
        <div class="date-select-wrapper form-box">
          <DateSelect v-model="createdBefore" :label="$t('archive_by_date_modal.created_before')" />
        </div>
        <template v-if="authorizedAsSiteAdmin">
          <p class="mb-2">{{ $t('archive_by_date_modal.archive_type_instructions') }}</p>
          <label for="assignment-type">{{ $t('common.type') + ':' }}</label>
          <select id="assignment-type" v-model="type" class="mb-4">
            <option value="">{{ $t('archive_by_date_modal.my_assignments') }}</option>
            <option value="site_wide">{{ $t('archive_by_date_modal.site_wide') }}</option>
          </select>
        </template>
      </section>
      <footer>
        <button class="btn" @click="close">{{ $t('common.cancel') }}</button>
        <AsyncContentLoader :loader="request" class="loading--inline inline">
          <button class="btn btn--primary" @click="archive">{{ $t('common.archive') }}</button>
        </AsyncContentLoader>
      </footer>
    </template>
  </LzModal>
</template>

<script>
import { LzModal, AsyncContentLoader, Alert } from 'vue_features/shared/components/ui'
import DateSelect from 'vue_features/shared/components/selects/DateSelect'
import AssignmentsService from '../../api/assignments_service'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { location } from 'utils'
import { ref, inject } from 'vue'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'AssignmentsArchiveByDateModal',
  components: { AsyncContentLoader, LzModal, DateSelect, Alert },
  setup() {
    const root = inject('useRoot')()
    const createdBefore = ref(new Date()),
      type = ref(''),
      error = ref(''),
      request = ref(null),
      processed = ref(false)

    const { authorizedAsSiteAdmin } = useCurrentUserStore()

    function archive() {
      error.value = ''
      setTimeToEndOfDay()
      request.value = AssignmentsService.archiveAssignments(createdBefore.value, type.value).then(
        () => {
          processed.value = true
          location.reload()
        },
        () => {
          error.value = root.$t('archive_by_date_modal.archive_error')
        },
      )
    }
    function setTimeToEndOfDay() {
      createdBefore.value.setHours(23)
      createdBefore.value.setMinutes(59)
      createdBefore.value.setSeconds(59)
    }

    return {
      closeModal,
      authorizedAsSiteAdmin,
      createdBefore,
      type,
      error,
      request,
      processed,
      archive,
      setTimeToEndOfDay,
    }
  },
}
</script>
