<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="mx-auto max-w-7xl">
    <Alert v-if="isNoticeShowing" :title="noticeMessage" dismissible type="success" />
    <div>
      <i18n-t tag="p" keypath="user_count" scope="global">
        <template #count>
          <strong>{{ usersCount }}</strong>
        </template>
      </i18n-t>
    </div>
    <div class="flex flex-row-reverse gap-2">
      <ArchivedUsersDeletionConfirmModal
        v-if="canModalShow($options.ArchivedUsersDeletionConfirmModal)"
        @delete-queued="openArchiveDeletionNotice"
      />
      <UserAddModal v-if="canModalShow($options.UserAddModal)" />
      <CsvUserImportModal v-if="canModalShow($options.CsvUserImportModal)" v-bind="{ studentSource, teacherSource }" />

      <LzButton v-if="isPublic" size="small" @click="openModal($options.UserAddModal)">
        {{ $t('add_new_user') }}
      </LzButton>
      <ListDropdown v-else id="add-user-dropdown" :button-classes="['btn--small']">
        <template #button>
          <span class="flex items-center gap-1">
            {{ $t('add_new_user') }}
            <LzIcon path="icons/caret" size="sm" />
          </span>
        </template>
        <template #listItems>
          <li>
            <a @click.prevent="openModal($options.UserAddModal)">
              {{ $t('add_through_modal') }}
            </a>
          </li>
          <li>
            <a data-test="csv-import-link" @click.prevent="openCsvImportModal('student_csv', 'teacher_csv')">
              {{ $t('import_by_csv') }}
            </a>
          </li>
        </template>
      </ListDropdown>
      <div v-if="!isPublic">
        <button
          :disabled="reportRequestStatus !== null"
          type="button"
          class="btn btn--small"
          data-test="export-users-button"
          @click.prevent.once="requestReport"
        >
          {{ $t('export_users') }}
        </button>
      </div>
      <LzButton v-if="isPublic" size="small" @click="openModal($options.ArchivedUsersDeletionConfirmModal)">
        {{ $t('delete_archived_users') }}
      </LzButton>
      <ListDropdown v-else id="delete-user-dropdown" :button-classes="['btn--small caution']">
        <template #button>
          <span class="flex items-center gap-1">
            {{ $t('delete_users') }}
            <LzIcon path="icons/caret" size="sm" />
          </span>
        </template>
        <template #listItems>
          <li>
            <a @click.prevent="openCsvImportModal('student_deletion_csv', 'teacher_deletion_csv')">
              {{ $t('archive_by_csv') }}
            </a>
          </li>
          <li>
            <a @click.prevent="openModal($options.ArchivedUsersDeletionConfirmModal)">
              {{ $t('delete_archived_users') }}
            </a>
          </li>
        </template>
      </ListDropdown>
    </div>
  </div>
</template>

<script>
import ArchivedUsersDeletionConfirmModal from './ArchivedUsersDeletionConfirmModal'
import CsvUserImportModal from './CsvUserImportModal'
import UserAddModal from './UserAddModal'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LzButton, LzIcon, Alert } from 'vue_features/shared/components/ui'
import UserImportService from '../api/user_import_service'
import { openModal, canModalShow, closeModal, activeModal } from 'vue_features/shared/composables/use_global_modals'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'UsersHeader',
  ArchivedUsersDeletionConfirmModal,
  CsvUserImportModal,
  UserAddModal,
  components: {
    ArchivedUsersDeletionConfirmModal,
    LzButton,
    ListDropdown,
    CsvUserImportModal,
    LzIcon,
    Alert,
    UserAddModal,
  },
  setup() {
    const { isPublic } = useCurrentCustomerStore()
    const { email } = useCurrentUserStore()
    const { usersCount } = useUsersIndexStore()
    return { isPublic, email, usersCount, openModal, canModalShow, closeModal, activeModal }
  },
  data() {
    return {
      studentSource: 'student_csv',
      teacherSource: 'teacher_csv',
      reportRequestStatus: null,
      isNoticeShowing: false,
      noticeMessage: '',
    }
  },
  methods: {
    openArchiveDeletionNotice() {
      this.showNotice(this.$t('deleting_archives_notice'))
    },
    showNotice(message) {
      this.isNoticeShowing = true
      this.noticeMessage = message
    },
    openCsvImportModal(studentSource, teacherSource) {
      this.studentSource = studentSource
      this.teacherSource = teacherSource
      this.openModal('CsvUserImportModal')
    },
    requestReport() {
      this.reportRequestStatus = 'pending'
      UserImportService.requestReport().then(() => {
        this.reportRequestStatus = 'requested'
        this.showNotice(this.$t('processing_notice', { email: this.email }))
      })
    },
  },
}
</script>
