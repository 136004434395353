<template>
  <nav v-if="hasMultiplePages" class="pagination">
    <span v-if="prevPage" class="prev cursor-pointer">
      <button type="button" class="hover:text-accent p-2 text-base" @click.stop="getPage(prevPage)">
        <span aria-hidden="true">&lsaquo;</span>
        {{ $t('lz_paginator.previous') }}
      </button>
    </span>
    <LzPaginatorLink
      v-for="(page, index) in pages"
      :key="index"
      :page="page"
      :current-page="currentPage.toString()"
      @page-changed="getPage"
    />
    <span v-if="nextPage" class="next cursor-pointer">
      <button v-if="nextPage" type="button" class="hover:text-accent p-2 text-base" @click.stop="getPage(nextPage)">
        {{ $t('lz_paginator.next') }} <span aria-hidden="true">&rsaquo;</span>
      </button>
    </span>
  </nav>
</template>

<script>
/*
 *  Binds properties from the pagination object in the metadata of a JSON
 *  response.
 *
 *  pagination: {
 *    currentPage: 1,
 *    nextPage: 2,
 *    prevPage: null,
 *    totalPages: 10
 *  }
 *
 *  The parent component specifies a data loader method triggered when
 *  a 'page-changed' event is emitted.
 *
 *  methods: {
 *    getCustomers(page) {
 *      // fetch customer data
 *    },
 *  },
 *
 * Example
 *  <LzPaginator v-bind="pagination" @page-changed="getCustomers"/>
 */
import { each, max, min, range } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { components } from 'vue_features/shared/i18n'
import { pagination as config } from 'utils/config'
import LzPaginatorLink from './LzPaginatorLink'

export default {
  i18n: { messages: namespaceLocaleObj(components, '', { only: 'lz_paginator' }) },
  components: { LzPaginatorLink },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    nextPage: {
      type: Number,
      default: undefined,
    },
    prevPage: {
      type: Number,
      default: undefined,
    },
    totalPages: {
      type: Number,
      // FIXME: Shouldn't be required? You may want to initialize but don't have the totalPages from the server yet.
      required: true,
    },
  },
  data() {
    return {
      windowSize: config.windowSize,
    }
  },
  computed: {
    pages() {
      const windowStart = max([this.currentPage - this.windowSize, 2])
      const windowEnd = min([this.currentPage + this.windowSize, this.totalPages - 1])
      const pages = ['1']

      if (windowStart > this.windowSize) {
        pages.push('...')
      }

      each(range(windowStart, windowEnd + 1), (n) => {
        pages.push(`${n}`)
      })

      if (windowEnd < this.totalPages - 1) {
        pages.push('...')
      }

      pages.push(`${this.totalPages}`)

      return pages
    },
    hasMultiplePages() {
      return this.totalPages > 1
    },
  },
  methods: {
    getPage(page) {
      this.$emit('page-changed', page)
    },
  },
}
</script>
