<template>
  <div class="filestack-upload">
    <div class="space-y-2">
      <slot
        v-bind="{ fileUrl, fileExists, filename, baseUrl: file.baseUrl, launch, fileDropContainer, createDropPane }"
      />
    </div>
  </div>
</template>

<script>
import { uniqueId } from 'lodash'
import useFilestackUploader from 'vue_features/shared/composables/use_filestack_uploader'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'

export default {
  name: 'FilestackUpload',

  props: {
    file: { type: Object, default: () => ({}) },
    accept: { type: Array, required: true },
    maxSize: { type: Number, default: 1048576 }, // 1.megabyte
    showDropPane: { type: Boolean, default: false },
  },

  setup(props, { emit }) {
    const { launch, createDropPane, fileDropContainer, uploadOptions } = useFilestackUploader({
      accept: props.accept,
      maxSize: props.maxSize,
      dropPaneId: uniqueId(),
      showDropPane: props.showDropPane,
      onUploadDone: (response) => {
        const json = response.filesUploaded[0]

        emit('upload-done', {
          baseUrl: json.url,
          metadata: json,
          filestackOptions: { ...uploadOptions.value },
        })
      },
    })

    const { fileExists, filename, fileUrl } = useFilestackAccessor(props, 'file')

    return { launch, createDropPane, fileDropContainer, fileExists, filename, fileUrl }
  },
}
</script>
