import AdminAccessesService from '../api/admin_accesses_service'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { reactive, computed } from 'vue'

export const useAdminAccessesStore = defineStore('adminAccesses', () => {
  const state = reactive({
    addedAdminState: {
      admins: [],
      meta: {
        pagination: {},
      },
    },
    availableAdminState: {
      admins: [],
      meta: {
        pagination: {},
      },
    },
  })

  const addedAdmins = computed(() => state.addedAdminState.admins)
  const availableAdmins = computed(() => state.availableAdminState.admins)

  function toggleAdminState(from, to, admin) {
    const index = from.admins.indexOf(admin)
    from.admins.splice(index, 1)
    to.admins.push(admin)
    to.meta.pagination.totalCount++
    from.meta.pagination.totalCount--
  }

  function addAdmin(admin) {
    return AdminAccessesService.add(admin.id).then(() =>
      toggleAdminState(state.availableAdminState, state.addedAdminState, admin),
    )
  }

  function removeAdmin(admin) {
    return AdminAccessesService.remove(admin.id).then(() =>
      toggleAdminState(state.addedAdminState, state.availableAdminState, admin),
    )
  }

  function loadAdmins({ loadState, page }) {
    return AdminAccessesService.load({ loadState, page }).then((adminState) => {
      if (loadState === 'added') {
        state.addedAdminState.admins.push(...adminState.admins)
        state.addedAdminState.meta = adminState.meta
      } else {
        state.availableAdminState.admins.push(...adminState.admins)
        state.availableAdminState.meta = adminState.meta
      }
    })
  }

  return {
    state,
    addedAdmins,
    availableAdmins,
    addAdmin,
    removeAdmin,
    loadAdmins,
  }
})
