import debug from 'debug'
import CkeditorUpload from 'vue_features/shared/components/filestack_upload/CkeditorUpload'
import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { activerecordAttributes, common } from 'vue_features/shared/i18n'
import { ref } from 'vue'
import { buildVueApp } from 'initializers/create_vue_features'

const log = debug('app:ckeditor/upload')

let dialogDefinition
export const previewUrl = ref(null)

function initializeDialogDefinition(definition) {
  log('initializeDialogDefinition', definition)
  dialogDefinition = definition
  dialogDefinition.minWidth = 700
  dialogDefinition.minHeight = 450
}

function addUploadTab(config) {
  // explicitly remove existing upload tab to avoid id conflict
  dialogDefinition.removeContents('upload')

  dialogDefinition.addContents({
    accessKey: 'I',
    id: 'upload',
    label: 'Upload',
    elements: [
      {
        id: 'filestack',
        type: 'html',
        html: '<div></div>',
        style: 'width: 700px; height: 450px; display: flex;',
        onShow: () => loadFilestack(config),
      },
    ],
  })
}

function customizeImageInfoTab() {
  const infoTab = dialogDefinition.getContents('info')

  infoTab.get('txtUrl').style = 'width: 680px'
  infoTab.get('txtAlt').style = 'width: 680px'
  infoTab.get('htmlPreview').hidden = true
  infoTab.get('basic').type = 'hbox'
}

function loadFilestack(config) {
  const dialog = dialogDefinition.dialog
  const containerId = dialog.getContentElement('upload', 'filestack').domId

  getUploader(containerId, config)
  previewUrl.value = null
}

function getUploader(containerId, config) {
  const uploadContainer = document.getElementById(containerId)

  return uploadContainer.childNodes[0] || ckeditorUploadFeature(uploadContainer, config)
}

function ckeditorUploadFeature(uploadContainer, config) {
  const el = document.createElement('div')
  el.classList.add('w-full')
  uploadContainer.appendChild(el)

  const infoUrl =
    dialogDefinition.dialog.getContentElement('info', 'txtUrl') ||
    dialogDefinition.dialog.getContentElement('info', 'url')

  const messages = merge(
    {},
    namespaceLocaleObj(common, '', { only: ['cancel'] }),
    pickLocaleNamespace(activerecordAttributes, 'resource'),
  )

  buildVueApp({ component: CkeditorUpload, el, messages, props: { config, infoUrl }, attrs: { class: 'w-full' } })
}

export function imageUpload(dialogDefinition) {
  initializeDialogDefinition(dialogDefinition)
  addUploadTab('image')
  customizeImageInfoTab()
}

export function linkUpload(dialogDefinition) {
  initializeDialogDefinition(dialogDefinition)
  addUploadTab('file')
}
