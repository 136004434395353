<template>
  <DataTableRow
    :background="rowBackground"
    :highlight="rowHighlight"
    :data-test="`${assignmentView.studentUsername}-row`"
  >
    <DataTableFrozenColumns class="border-primary-100 border-r">
      <DataTableCell class="px-2">
        <input
          v-if="active"
          ref="inputRef"
          :checked="selectionList.includes(assignmentView.id)"
          type="checkbox"
          :aria-label="$t('assignment_report.select_student')"
          class="mt-0 h-4 w-4"
          :data-test="`${assignmentView.studentUsername}-checkbox`"
          @change="$emit('selection-changed', assignmentView.id, $event.target.checked)"
        />
      </DataTableCell>

      <DataTableCell class="fs-exclude bg-inherit px-2" horizontal-alignment="left">
        {{ assignmentView.studentFirstName }}
      </DataTableCell>

      <DataTableCell class="fs-exclude bg-inherit px-2" horizontal-alignment="left">
        {{ assignmentView.studentLastName }}
      </DataTableCell>
    </DataTableFrozenColumns>

    <DataTableCell v-if="isConnectedToGoogle && hasGoogleClassroomAssignment" class="px-2">
      <button
        v-if="googleStudent"
        class="cursor-pointer p-0"
        @click="$emit('link-google-student', assignmentView, googleStudent)"
      >
        <LzTooltip tooltip-classes="google-dark-tooltip">
          <LzIcon v-if="isPassbackError" size="sm" path="icons/alert-circle" />
          <div
            v-else-if="isPassbackSuccess"
            class="google-classroom-grade-passback-status-indicator bg-focus flex items-center justify-between"
          >
            <LzIcon size="sm" path="icons/google-signin" />
            <LzIcon size="sm" class="text-green" path="icons/check" />
          </div>
          <div
            v-else-if="isPassbackActive"
            class="google-classroom-grade-passback-status-indicator bg-focus flex items-center justify-between"
          >
            <LzIcon size="sm" path="icons/google-signin" />
            <LoadingSpinner class="ml-0.5 inline-block" size="small" />
          </div>
          <LzIcon v-else size="sm" path="icons/google-signin" />

          <template #content>
            {{ googleStudent.name }}
            <br />
            ({{ googleStudent.email }})
          </template>
        </LzTooltip>
      </button>
      <button v-else class="btn btn--small p-1" @click="$emit('link-google-student', assignmentView)">
        <LzIcon svg-classes="grayscale opacity-25" size="sm" path="icons/google-signin" />
      </button>
    </DataTableCell>

    <DataTableCell class="px-2">
      <LzTooltip v-if="showAssignmentStateTooltip" :title="$t('assignment_status.no_date_scheduled')" class="block">
        <span
          class="inline-flex items-center gap-x-1 rounded-full px-3 py-1 text-sm font-bold"
          :class="assignmentStatusColorClasses"
        >
          <LzIcon v-if="isScheduled" path="icons/calendar" size="sm" style="margin-top: -2px" />
          <span>{{ assignmentStatusText }}</span>
        </span>
      </LzTooltip>
      <span
        v-else
        class="inline-flex items-center gap-x-1 rounded-full px-3 py-1 text-sm font-bold"
        :class="assignmentStatusColorClasses"
      >
        <LzIcon v-if="isScheduled" path="icons/calendar" size="sm" style="margin-top: -2px" />
        <span>{{ assignmentStatusText }}</span>
      </span>
    </DataTableCell>

    <DataTableCell v-if="assignment.hasQuestions" class="px-2">
      <LzTooltip :title="fullDates">
        {{ abbreviatedDates }}
      </LzTooltip>
    </DataTableCell>

    <DataTableCell class="px-2">
      <div v-if="assignmentItems.length === 0">
        <div class="flex h-14 items-center text-center">
          <span class="text-neutral-700 mb-0 text-sm">{{ $t('assignment_report.not_attempted') }}</span>
        </div>
      </div>
      <AssignmentScore
        v-else
        :lz-code="assignment.code"
        :lz-code-view-id="assignmentView.id"
        :graded="isGraded"
        :completed="isCompleted"
        :percent="assignmentView.sortableAssignmentScore"
      >
        <span
          v-if="isGraded && isCompleted"
          :class="`results-score--${scoreRating}`"
          class="score-dot static flex-shrink-0 rounded-md"
        >
          <InlineSvg :path="`icons/${iconName}`" class="m-1" />
        </span>
      </AssignmentScore>
    </DataTableCell>

    <DataTableCell v-for="(item, itemIndex) of items" :key="item.itemNumber" class="h-14 w-14">
      <ItemScoreCell
        :assignment="assignment"
        :assignment-view="assignmentView"
        :item="assignmentView.items[itemIndex]"
      />
    </DataTableCell>
  </DataTableRow>
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import { get, reduce, some, toArray } from 'lodash'
import { LoadingSpinner, InlineSvg, LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import DataTableCell from 'vue_features/shared/components/data_table/DataTableCell'
import DataTableRow from 'vue_features/shared/components/data_table/DataTableRow'
import DataTableFrozenColumns from 'vue_features/shared/components/data_table/DataTableFrozenColumns'
import ItemScoreCell from './ItemScoreCell'
import AssignmentScore from './AssignmentScore'
import { friendlyLongDateTime, friendlyShortDate, timeInPast } from 'vue_features/shared/filters/datetime'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'
import { scoreRating as assignmentScoreRating } from 'vue_features/assignments/reports/utils/scoring'

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  assignmentView: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  selectionList: {
    type: Array,
    required: true,
  },
  hasGoogleClassroomAssignment: {
    type: Boolean,
    required: false,
    default: false,
  },
  googlePassbackStudents: {
    type: Object,
    required: false,
    default: null,
  },
  googleStudent: {
    type: Object,
    required: false,
    default: null,
  },
  recentlyAdded: {
    type: Boolean,
    default: false,
  },
})

const root = inject('useRoot')()

const shouldHighlight = ref(props.recentlyAdded)

const { isConnectedToGoogle } = useGoogleClassroomStore()
const { assignment } = useAssignmentReportStore()

const reportData = computed(() => {
  return props.assignmentView.reportData
})

const abbreviatedDates = computed(() => {
  const { openAt, closeAt } = props.assignmentView
  const openAtString = openAt ? friendlyShortDate(openAt) : ''
  const closeAtString = closeAt ? friendlyShortDate(closeAt) : ''
  if (openAtString && closeAtString) {
    return `${openAtString} - ${closeAtString}`
  } else if (openAtString) {
    return `${root.$t('assignment_status.opened')} ${openAtString}`
  } else if (closeAtString) {
    return `${root.$t('assignment_status.closed')} ${closeAtString}`
  } else {
    return ''
  }
})

const fullDates = computed(() => {
  const { openAt, closeAt } = props.assignmentView
  const alreadyOpened = timeInPast(openAt)
  const alreadyClosed = timeInPast(closeAt)
  const openDateString = openAt ? friendlyLongDateTime(openAt) : root.$t('common.not_specified')
  const closeDateString = closeAt ? friendlyLongDateTime(closeAt) : root.$t('common.not_specified')
  const openLabel = alreadyOpened ? root.$t('assignment_status.opened_on') : root.$t('assignment_status.opens_on')
  const closeLabel = alreadyClosed ? root.$t('assignment_status.closed_on') : root.$t('assignment_status.closes_on')

  return `${openLabel} ${openDateString}, ${closeLabel} ${closeDateString}`
})

const studentAssignmentState = computed(() => {
  return props.assignmentView.assignmentState
})

const assignmentStatusText = computed(() => {
  const key = studentAssignmentState.value === 'created' ? 'closed' : studentAssignmentState.value
  return root.$t(`assignment_status.${key}`)
})

const assignmentStatusColorClasses = computed(() => {
  const colorMap = {
    created: 'bg-orange-100 text-orange-700',
    opened: 'bg-blue-50 text-blue-700',
    closed: 'bg-transparent text-muted',
    returned: 'bg-green-100 text-green-500',
  }

  return colorMap[studentAssignmentState.value] || ''
})

const showAssignmentStateTooltip = computed(() => props.assignmentView.openAt === null)

const isScheduled = computed(() => {
  return (
    (studentAssignmentState.value === 'created' && props.assignmentView.openAt !== null) ||
    (studentAssignmentState.value === 'opened' && props.assignmentView.closeAt !== null)
  )
})

const isCompleted = computed(() => {
  return !!reportData.value.timeCompleted && !isUnmarked.value
})

const isUnmarked = computed(() => {
  return some(assignmentItems.value, (item) => item.unmarked)
})

const isGraded = computed(() => {
  const state = props.assignmentView.assignmentState
  return state === 'closed' || state === 'returned'
})

const isReturned = computed(() => {
  return props.assignmentView.assignmentState === 'returned'
})

const studentPassbackStatus = computed(() => {
  if (!props.googlePassbackStudents) return null

  return props.googlePassbackStudents[props.googleStudent.profileId]
})

const isPassbackComplete = computed(() => {
  return (isReturned.value && get(studentPassbackStatus.value, 'complete')) || false
})

const isPassbackActive = computed(() => {
  return (isReturned.value && get(studentPassbackStatus.value, 'active')) || false
})

const isPassbackError = computed(() => {
  return (isReturned.value && get(studentPassbackStatus.value, 'error')) || false
})

const isPassbackSuccess = computed(() => {
  return isReturned.value && isPassbackComplete.value && !isPassbackError.value
})

const assignmentItems = computed(() => {
  return reportData.value ? toArray(reportData.value.items) : []
})

const maxScore = computed(() => {
  return reduce(
    assignmentItems.value,
    (sum, item) => {
      return sum + item.maxScore
    },
    0,
  )
})

const score = computed(() => {
  return reduce(
    assignmentItems.value,
    (sum, item) => {
      return sum + item.score
    },
    0,
  )
})

const ratio = computed(() => {
  return score.value / maxScore.value || 0
})

const scoreRating = computed(() => {
  if (isUnmarked.value) return 'ungraded'
  return assignmentScoreRating(ratio.value)
})

const iconName = computed(() => {
  const icons = { low: 'close', medium: 'minus', high: 'check' }
  return icons[scoreRating.value]
})

const rowBackground = computed(() => {
  return props.index % 2 == 0 ? '#fff' : 'rgb(var(--neutral-50))'
})

const rowHighlight = computed(() => {
  return shouldHighlight.value ? 'rgba(var(--neutral-500), 0.25)' : null
})

onMounted(() => {
  setTimeout(() => {
    shouldHighlight.value = false
  }, 1500)
})
</script>
