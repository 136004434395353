<template>
  <div class="image-resource">
    <img :src="sourceFileUrl" :alt="altText" />
    <DownloadButton v-bind="{ customerId, downloadFileUrl, resourceJson, downloadedEvent }" />
  </div>
</template>

<script>
import { DownloadButton, downloadButtonProps } from 'vue_features/resources'
import { parseJson } from 'utils'

const { customerId, downloadFileUrl, resourceJson, downloadedEvent } = downloadButtonProps

export default {
  name: 'ImageResource',

  components: {
    DownloadButton,
  },

  props: {
    sourceFileUrl: {
      type: String,
      required: true,
    },
    customerId,
    downloadFileUrl,
    resourceJson,
    downloadedEvent,
  },
  computed: {
    altText() {
      const { type, name } = parseJson(this.resourceJson)
      return `${type} ${name}`
    },
  },
}
</script>
