<template>
  <nav
    :aria-label="$t('navigation.breadcrumb')"
    class="pathcrumbs box-border flex max-w-full flex-wrap items-center gap-y-3 px-4 py-4 print:hidden md:px-8"
  >
    <ProgressCover :visible="isRedirecting" :message="''" class="opacity-50" />

    <div
      v-for="(pathCrumb, index) in crumbsStart"
      :key="`crumb-start-${index}`"
      :data-test="`crumbs-start-${index}`"
      class="flex items-center"
    >
      <PathCrumbSelect v-bind="{ pathCrumb }" @select="handleSelect" />
      <Separator v-if="index < crumbsStart.length - 1 || crumbsEnd.length > 0" />
    </div>

    <div v-if="crumbsMore.length > 0" data-test="crumbs-more" class="flex items-center">
      <PathCrumbsMore :crumbs="crumbsMore" @select="handleSelect" />
      <Separator />
    </div>

    <div
      v-for="(pathCrumb, index) in crumbsEnd"
      :key="`crumb-end-${index}`"
      :data-test="`crumbs-end-${index}`"
      class="flex items-center"
    >
      <PathCrumbSelect v-bind="{ pathCrumb }" @select="handleSelect" />
      <Separator v-if="index < crumbsEnd.length - 1" />
    </div>
  </nav>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { ProgressCover } from 'vue_features/shared/components/ui'
import PathCrumbSelect from './PathCrumbSelect'
import PathCrumbsMore from './PathCrumbsMore'
import Separator from './Separator'
import { location as $location } from 'utils'
import { LzAnalytics } from 'clients'
import { createLzRouter } from 'vue_features/shared/router'
import { shared } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'

export default {
  components: {
    ProgressCover,
    PathCrumbSelect,
    PathCrumbsMore,
    Separator,
  },
  i18n: { messages: pickLocaleNamespace(shared, 'navigation') },
  setup() {
    const { pathCrumbs, pathIds, pathIdsTo, currentPath } = usePathCrumbsStore()
    const isRedirecting = ref(false)

    const shownCrumbs = computed(() => pathCrumbs.value.filter(({ items }) => items.length > 0))
    const beforeCount = 1
    const afterCount = 3
    const crumbsStart = computed(() => shownCrumbs.value.slice(0, beforeCount))
    const crumbsEnd = computed(() => shownCrumbs.value.slice(beforeCount).slice(-afterCount))
    const crumbsMore = computed(() =>
      shownCrumbs.value.slice(crumbsStart.value.length + 1, shownCrumbs.value.length - crumbsEnd.value.length + 1),
    )

    function handleSelect({ index, crumbId }) {
      isRedirecting.value = true
      LzAnalytics.track('Path crumb visit', {
        action: 'Path crumb visit',
        category: 'navigation',
        current_path: pathIdsTo(pathCrumbs.value.length - 1),
        next_path: pathIdsTo(index + 1),
        from_resource: pathIds.value[pathIds.value.length - 1],
        to_resource: crumbId,
      })
    }

    onMounted(() => {
      // if path missing from url but exists (pulled from cookie on back-end), sets it here
      const query = $location.parseQuery()
      if (!query.path && currentPath.value) {
        const router = createLzRouter({ routes: [{ path: $location.pathname(), component: { template: '' } }] })
        query.path = currentPath.value
        router.replace({ path: $location.pathname(), query })
      }
    })

    return {
      isRedirecting,
      crumbsStart,
      crumbsMore,
      crumbsEnd,
      handleSelect,
    }
  },
}
</script>
