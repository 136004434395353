<template>
  <LzAccordion
    :id="`assignment-report-${klass.id}`"
    :label="klassNameDisplay"
    :open="isSelectedOrFirst"
    :bordered="false"
    class="border-base bg-focus clear-both overflow-hidden rounded-lg border shadow-sm"
  >
    <template #head="{ label, toggle, isOpen }">
      <div class="relative flex flex-wrap justify-between sm:flex-nowrap">
        <div :class="klassRatingIndicator"></div>
        <div class="inline-block min-w-0 flex-grow space-x-2">
          <button :title="label" class="group flex w-full cursor-pointer items-center gap-2 p-3" @click="toggle">
            <AccordionIcon :is-open="isOpen" class="flex-shrink-0" />
            <h4 class="my-0 inline-block min-w-0 truncate pr-2">
              {{ label }}
            </h4>
            <span
              v-if="!klass.active"
              data-test="archive-badge"
              class="inline-flex items-center rounded-full bg-gray-400 px-2 py-1 text-xs text-white"
            >
              <LzIcon path="icons/archive" />
              <span>{{ $t('common.archived') }}</span>
            </span>
          </button>
          <template v-if="isConnectedToGoogle && klass.hasGoogleClassroomAssignment">
            <template v-if="klass.googleClassroomCourse">
              <div class="ml-2 flex items-center p-1">
                <LzIcon
                  path="icons/google-signin"
                  class="ml-2"
                  :svg-classes="courseAssignmentPending ? 'grayscale opacity-25' : null"
                />
                <span
                  data-test="assignment-report-google-class-name"
                  class="small ml-1 inline-block font-semibold"
                  :class="{ 'opacity-50': courseAssignmentPending }"
                >
                  {{ klass.googleClassroomCourse.name }}
                  <template v-if="courseAssignmentPending">
                    ({{ $t('common.pending') }})
                    <LoadingSpinner :inline="true" tag="span" size="small" />
                  </template>
                  <template v-else-if="klass.googleClassroomCourse.archived"> ({{ $t('common.archived') }}) </template>
                </span>
                <KlassUnlinkedIndicator
                  :klass-id="klass.id"
                  :unlinked-count="klass.googleClassroomUnlinkedCount"
                  class="mx-2 self-center"
                />
              </div>
            </template>
            <LzTooltip v-else class="self-center" tooltip-classes="google-warn-tooltip">
              <LzIcon path="icons/google-signin" class="ml-2" svg-classes="grayscale opacity-25" />

              <template #content>
                <div>{{ $t('assignment_report.not_linked_to_google') }}</div>
              </template>
            </LzTooltip>
          </template>
        </div>
        <section class="p flex flex-shrink-0 flex-row items-center gap-2 px-3 pb-3 sm:pb-0 sm:pl-0">
          <LzButton
            v-if="klass.lzCodeKlass.active && klass.lzCodeKlass.canDelete"
            @click="openModal($options.AssignmentsDeleteModal, { klass, lzCodeKlass: klass.lzCodeKlass })"
          >
            <LzIcon size="sm" path="icons/delete" />
            <span class="hidden md:inline">{{ $t('common.delete') }}</span>
          </LzButton>
          <LzButton
            v-else-if="klass.lzCodeKlass.active"
            @click="openModal($options.AssignmentsArchiveModal, { klass, lzCodeKlass: klass.lzCodeKlass })"
          >
            <LzIcon size="sm" path="icons/archive" />
            <span class="hidden md:inline">{{ $t('common.archive') }}</span>
          </LzButton>
          <LzButton
            v-else
            @click="openModal($options.AssignmentsUnarchiveModal, { klass, lzCodeKlass: klass.lzCodeKlass })"
          >
            <LzIcon size="sm" path="icons/refresh" />
            <span class="hidden md:inline">{{ $t('common.unarchive') }}</span>
          </LzButton>
          <div class="status">
            <b>{{ capitalize($t('common.status')) }}:</b>
            {{ statusDescription }}
          </div>
        </section>
      </div>
    </template>
    <template #body="{ isOpen }">
      <div v-show="isOpen">
        <ItemScoreTable
          :klass="klass"
          :assignment-views="klass.assignmentViews"
          :pagination="klass.pagination"
          :report-data="klass.reportData"
          :is-load-triggered="isOpen"
          :averages="klass.averages"
        />
      </div>
    </template>
  </LzAccordion>
</template>

<script>
import { capitalize, get } from 'lodash'
import {
  AccordionIcon,
  LoadingSpinner,
  LzTooltip,
  LzAccordion,
  LzIcon,
  LzButton,
} from 'vue_features/shared/components/ui'
import ItemScoreTable from './ItemScoreTable'
import KlassUnlinkedIndicator from 'vue_features/shared/components/google_classroom/KlassUnlinkedIndicator'
import { scoreRating } from 'vue_features/assignments/reports/utils/scoring'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import {
  AssignmentsDeleteModal,
  AssignmentsArchiveModal,
  AssignmentsUnarchiveModal,
} from '../../../shared/components/modals'
import { location } from 'utils'
import { computed, watch } from 'vue'

export default {
  name: 'AssignmentReport',
  AssignmentsDeleteModal,
  AssignmentsArchiveModal,
  AssignmentsUnarchiveModal,
  components: {
    AccordionIcon,
    LzTooltip,
    LoadingSpinner,
    ItemScoreTable,
    LzAccordion,
    LzIcon,
    LzButton,
    KlassUnlinkedIndicator,
  },
  props: {
    klass: {
      type: Object,
      required: true,
    },

    position: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { courseAssignmentsData, isConnectedToGoogle } = useGoogleClassroomStore()
    const { listenForGradePassbackStatusUpdates } = useAssignmentReportStore()
    const courseAssignmentForKlass = computed(() => {
      const { googleClassroomCourse } = props.klass
      if (!googleClassroomCourse || !courseAssignmentsData.value) return

      return courseAssignmentsData.value.find(
        (courseAssignment) => courseAssignment.courseId === googleClassroomCourse.courseId,
      )
    })

    watch(
      courseAssignmentForKlass,
      (newAssignment) => {
        if (newAssignment) {
          listenForGradePassbackStatusUpdates({ courseAssignmentId: newAssignment.id })
        }
      },
      { immediate: true },
    )

    return {
      courseAssignmentForKlass,
      courseAssignmentsData,
      isConnectedToGoogle,
      listenForGradePassbackStatusUpdates,
      openModal,
    }
  },
  data() {
    return {
      triggerLoad: false,
    }
  },

  computed: {
    statusDescription() {
      if (this.klass.lzCodeKlass.active) {
        return this.klass.assignmentStatus === 'created'
          ? this.$t('assignment_status.closed')
          : this.$t(`assignment_status.${this.klass.assignmentStatus}`)
      }
      return this.$t('common.archived')
    },
    klassNameDisplay() {
      return this.klass.name
    },
    courseAssignmentPending() {
      return this.courseAssignmentForKlass && this.courseAssignmentForKlass.pending
    },
    klassRatingIndicator() {
      return this.klassRating ? `rating-indicator rating-indicator--left rating-indicator--${this.klassRating}` : null
    },

    klassAverageFromItems() {
      if (this.klass.pagination && this.klass.pagination.currentPage === this.klass.pagination.totalPages) {
        const scores = this.klass.assignmentViews
          .map((av) => Object.values(av.reportData.items).map((i) => ({ score: i.score, maxScore: i.maxScore })))
          .flat()

        if (scores.length > 0) {
          const scoreTotal = scores.reduce((total, s) => total + (s.score || 0.0), 0)
          const maxScoreTotal = scores.reduce((total, s) => total + (s.maxScore || 0.0), 0)

          return scoreTotal / maxScoreTotal
        }
      }
      return null
    },

    klassRating() {
      let average = this.klassAverageFromItems
      if (average === null) {
        average = get(this.klass, 'average', null)
      }

      return average === null ? null : this.rating(average)
    },

    isSelectedOrFirst() {
      if (location.hash()) {
        const klassIdMatch = location.hash().match(/\d+$/)
        return klassIdMatch ? this.klass.id.toString() === klassIdMatch[0] : this.position === 0
      }

      return this.position === 0
    },
  },

  methods: {
    capitalize,
    rating(score) {
      return scoreRating(score)
    },

    initialize() {
      if (!this.triggerLoad) {
        this.triggerLoad = true
      }
    },
  },
}
</script>
