<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <BaseDropdown
    v-slot="{ id, fullButtonClasses, isOpen, fullDropdownClasses, toggle, closeOnClick, close, disabled }"
    v-bind="{ ...$props }"
    @dropdown-toggled="$emit('dropdown-toggled', $event)"
  >
    <div v-bind="$attrs" class="vue-dropdown">
      <slot v-bind="{ fullButtonClasses, id, toggle, isOpen }" name="actionButton">
        <button
          :data-toggle="id"
          :class="fullButtonClasses"
          :disabled="disabled"
          :aria-expanded="isOpen ? 'true' : 'false'"
          type="button"
          @click.prevent.stop="toggle"
        >
          <slot name="button" />
        </button>
      </slot>
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events | keyboards have access to all list elements and dropdowns close automatically -->
      <ul v-if="showList(isOpen)" :id="id" :class="fullDropdownClasses" @click="() => closeOnClick && close()">
        <slot name="listItems" v-bind="{ close }" />
      </ul>
    </div>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from './BaseDropdown'

export default {
  name: 'ListDropdown',
  components: { BaseDropdown },
  props: {
    ...BaseDropdown.props,
    lazyList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    showList(isOpen) {
      return !this.lazyList || isOpen
    },
  },
}
</script>
