<template>
  <div>
    <div v-if="hasData && lessonPlan" class="relative block md:flex">
      <div class="w-full min-w-min flex-auto justify-center">
        <div id="item-analysis" class="mx-auto max-w-7xl space-y-6 px-4 pb-16">
          <PortalTarget name="itemAnalysis" multiple />

          <div class="flex items-center space-x-8">
            <div>
              <h2 class="-mb-2 mt-0 flex py-0 text-left font-bold capitalize">
                <a v-if="selectedLessonPlan" :href="lessonPlanPath(selectedLessonPlan.id)" target="_blank">
                  <span>{{ selectedLessonPlan.title }}</span>
                  <LzIcon path="icons/external" size="sm" />
                </a>
              </h2>

              <div class="text-neutral-700 text-sm">
                {{ selectedLessonPlan ? truncatedBreadcrumbs(selectedLessonPlan) : '' }}
              </div>
            </div>
          </div>

          <Filters class="max-w-7xl" />

          <div class="border-neutral-300 flex space-x-4 rounded-xl border bg-white p-4 shadow-sm">
            <div class="flex flex-grow flex-row items-center space-x-2">
              <span class="text-muted text-md font-bold">{{ $t('assignment_average_label') }}</span>
              <span class="text-md font-bold" :style="{ color: averageSessionScoreColor }">
                {{ averageSessionScore === null ? '' : `${averageSessionScore}%` }}
              </span>
            </div>
            <ScoringLegend class="justify-end">
              <ScoringLegendIcon
                :label="$t('common.not_attempted')"
                icon="square"
                :icon-style="{
                  color: 'rgb(var(--not-attempted-medium))',
                  backgroundColor: 'rgb(var(--not-attempted-medium))',
                }"
              />
            </ScoringLegend>
          </div>

          <div v-if="loading">
            <LoadingSpinner size="large" />
          </div>

          <div v-else class="flex w-full flex-row flex-nowrap gap-x-6">
            <div class="border-neutral-300 flex-1 rounded-xl border bg-white p-6 shadow-sm">
              <div v-if="itemCardData.length > 0" class="content mx-auto w-full max-w-7xl items-center">
                <div class="flex flex-row flex-wrap items-center gap-4">
                  <ToggleButton
                    class="min-w-max flex-1 md:flex-initial"
                    :value="String(sortOrder)"
                    @input="setSortOrder"
                  >
                    <ToggleButtonOption class="flex-grow" value="item">{{ $t('common.ordered') }}</ToggleButtonOption>
                    <ToggleButtonOption class="flex-grow" value="asc">{{ $t('low_to_high') }}</ToggleButtonOption>
                    <ToggleButtonOption class="flex-grow" value="desc">{{ $t('high_to_low') }}</ToggleButtonOption>
                  </ToggleButton>
                </div>
              </div>

              <div class="mt-4">
                <div v-if="itemCardData.length === 0" class="text-center">
                  <div class="inline-block w-full max-w-sm">
                    <InlineSvg path="item_analysis/try-different-filters.svg" />
                  </div>

                  <h2 class="m-0 text-2xl">{{ $t('try_different_filters_heading') }}</h2>
                  <p class="m-0 text-xl">{{ $t('try_different_filters_copy') }}</p>
                </div>

                <div v-else class="grid-cols-auto grid gap-6">
                  <ItemCard
                    v-for="item in itemCardData"
                    v-bind="item"
                    :key="`item-${item.learnosityItemReference}`"
                    :selected="selectedItem === item"
                    :title="itemTitle(item)"
                    :tooltip="itemTooltip(item)"
                    @click="handleItemClick(item)"
                  />
                </div>
              </div>
            </div>
            <ItemDetails v-if="!loading && selectedItem" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!hasData" class="mt-8 pb-8 text-center">
      <div class="inline-block w-full max-w-sm">
        <InlineSvg path="item_analysis/no-data-yet.svg" />
      </div>

      <h2 class="m-0 text-2xl">{{ $t('no_data_yet_heading') }}</h2>
      <p class="mb-8">{{ $t('no_data_yet_copy') }}</p>
    </div>

    <div v-else-if="lessonPlan === null" class="mx-auto mt-8 w-full pb-8 text-center md:max-w-2xl">
      <div class="inline-block w-full max-w-sm">
        <InlineSvg path="item_analysis/no-data-yet.svg" />
      </div>
      <div class="flex flex-col items-center">
        <h2 class="m-0 text-2xl">{{ $t('choose_lesson_heading') }}</h2>
        <a
          :href="HELP_ITEM_ANALYSIS_GUIDE_URL"
          target="_blank"
          class="text-accent flex cursor-pointer items-center gap-x-2 text-sm"
        >
          {{ $t('need_more_info') }}
          <LzIcon path="icons/external" size="sm" icon-color-class="text-accent" />
        </a>

        <LzButton class="mt-4" data-test="choose-initial-lesson-plan-button" @click="showLessonPlanChooser = true">{{
          $t('choose_assignment')
        }}</LzButton>
      </div>
    </div>

    <LessonPlanChooser
      :open="showLessonPlanChooser"
      :lesson-plan="lessonPlan"
      :initial-date-range="{ start: filters.afterDate, end: filters.beforeDate }"
      @cancel="showLessonPlanChooser = false"
      @choose="onChooseLesson"
    />
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { PortalTarget } from 'portal-vue'
import {
  ToggleButton,
  ToggleButtonOption,
  LzIcon,
  LzButton,
  InlineSvg,
  LoadingSpinner,
} from 'vue_features/shared/components/ui'
import ScoringLegend from 'vue_features/shared/components/ScoringLegend'
import ScoringLegendIcon from 'vue_features/shared/components/ScoringLegendIcon'
import ItemDetails from './ItemDetails'
import LessonPlanChooser from './LessonPlanChooser'
import ItemCard from './ItemCard'
import Filters from './Filters'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { useItemAnalysisStore } from '../composables/use_item_analysis_store'
import { scoreBandColor, ratingForScore } from 'vue_features/shared/utils/scoring'
import useKeyboardShortcuts from 'vue_features/shared/composables/use_keyboard_shortcuts'
import { truncatedBreadcrumbs } from '../utils'
import { HELP_ITEM_ANALYSIS_GUIDE_URL } from 'rails_constants'
import { useRouter } from 'vue-router'

export default {
  components: {
    PortalTarget,
    ToggleButton,
    ToggleButtonOption,
    LzIcon,
    LzButton,
    InlineSvg,
    LoadingSpinner,
    ScoringLegend,
    ScoringLegendIcon,
    ItemDetails,
    LessonPlanChooser,
    ItemCard,
    Filters,
  },
  setup() {
    const root = inject('useRoot')()
    const {
      averageSessionScore,
      commitParams,
      defaultFilters,
      filters,
      hasData,
      hideItemDetails,
      itemCardData,
      lessonPlan,
      loading,
      selectedItem,
      selectedLessonPlan,
      selectItem,
      selectLessonPlan,
      selectNextItem,
      selectPreviousItem,
      showLessonPlanChooser,
      sortOrder,
      updateFilters,
    } = useItemAnalysisStore()

    const { registerKeyboardShortcut } = useKeyboardShortcuts()

    const averageSessionScoreColor = computed(() => {
      const rating = ratingForScore(averageSessionScore.value)
      return scoreBandColor(rating)
    })

    const setSortOrder = (value) => {
      sortOrder.value = value
    }

    const lessonPlanPath = Routes.lesson_plan_path

    const router = useRouter()
    const onChooseLesson = (lessonPlan, incomingFilters) => {
      selectLessonPlan(lessonPlan)
      updateFilters({
        ...defaultFilters,
        gradeLevel: incomingFilters.gradeLevel,
        afterDate: incomingFilters.dateRange.start,
        beforeDate: incomingFilters.dateRange.end,
      })
      commitParams(router)
      showLessonPlanChooser.value = false
    }

    const handleItemClick = (item) => {
      selectItem(item)
      hideItemDetails.value = false
    }

    registerKeyboardShortcut(['ArrowRight'], (e) => {
      e.preventDefault()
      selectNextItem()
    })

    registerKeyboardShortcut(['ArrowLeft'], (e) => {
      e.preventDefault()
      selectPreviousItem()
    })

    const itemTitle = (item) => {
      if (item.isRemoved) {
        return root.$t('removed_item')
      }

      return root.$t(item.isCustomization ? 'inserted_item_num' : 'item_num', { num: item.itemNumber })
    }

    const itemTooltip = (item) => {
      if (item.isRemoved) {
        return root.$t('removed_item_tooltip')
      }

      return null
    }

    return {
      averageSessionScore,
      commitParams,
      defaultFilters,
      filters,
      hasData,
      hideItemDetails,
      itemCardData,
      lessonPlan,
      loading,
      selectedItem,
      selectedLessonPlan,
      selectItem,
      selectLessonPlan,
      selectNextItem,
      selectPreviousItem,
      showLessonPlanChooser,
      sortOrder,
      updateFilters,
      averageSessionScoreColor,
      setSortOrder,
      lessonPlanPath,
      onChooseLesson,
      handleItemClick,
      HELP_ITEM_ANALYSIS_GUIDE_URL,
      truncatedBreadcrumbs,
      itemTitle,
      itemTooltip,
    }
  },
}
</script>
