<template>
  <LzIcon
    type="button"
    size="xl"
    :path="icon"
    :title="$t('back_to_top')"
    class="back-to-top opacity-65 fill-primary-500 invisible fixed bottom-10 right-10 z-30 cursor-pointer rounded-full transition-opacity duration-200 md:visible"
    :class="opacityClass"
    @click="scrollToTop"
  />
</template>

<script>
import { computed, ref, onBeforeUnmount } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useNamespacedEventListener, useViewportSize } from 'vue_features/shared/composables'

export default {
  name: 'BackToTop',
  components: {
    LzIcon,
  },
  props: {
    threshold: {
      type: Number,
      default: 50, // visible after scrolling this percentage of viewport height
    },
    icon: {
      type: String,
      default: 'icons/arrow-circle-up',
    },
  },
  setup(props) {
    const { height: viewportHeight } = useViewportSize()
    const { on, off } = useNamespacedEventListener(window)
    const thresholdHeight = computed(() => viewportHeight.value * (props.threshold / 100))
    const opacityClass = ref('opacity-0')

    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    on('scroll.backToTop', () => {
      const { scrollY } = window
      opacityClass.value = scrollY >= thresholdHeight.value ? 'opacity-100' : 'opacity-0'
    })

    onBeforeUnmount(() => {
      off('scroll.backToTop')
    })

    return { opacityClass, scrollToTop }
  },
}
</script>
