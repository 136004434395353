<template>
  <thead>
    <tr>
      <template v-if="displayByWiki">
        <th></th>
        <th v-for="wiki in items" :key="wiki.id">
          <a :href="Routes.wiki_path(wiki.id)" data-test="table-head-wiki-title">{{ wiki.title }}</a>
        </th>
      </template>
      <template v-else-if="displayByStandard">
        <th>{{ $t('standard') }}</th>
        <th>{{ $t('aligned_lessons') }}</th>
      </template>
      <template v-else>
        <th>{{ $t('lesson') }}</th>
        <th>{{ $t('standards_addressed') }}</th>
      </template>
    </tr>
  </thead>
</template>

<script setup>
import { inject } from 'vue'
import Routes from 'routes'

const { displayByStandard, displayByWiki, items } = inject('coverageTable')
</script>
