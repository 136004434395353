import { get, trim } from 'lodash'

// FIXME We may be able to remove this custom plugin once we can upgrade to a
// version of CKEditor that supports the editorplaceholder plugin. But a bug
// was introduced in 4.14 related to setting font size, and we can't upgrade
// until that bug is fixed.
//
// See https://github.com/ckeditor/ckeditor4/issues/4555
window.CKEDITOR.plugins.add('inputplaceholder', {
  lang: 'en',

  init(editor) {
    if (get(editor.config.inputplaceholder, 'disable')) {
      return
    }

    const placeholder = `<p>${editor.lang.inputplaceholder.placeholderText}</p>`
    function setPlaceholder() {
      if (editor.getData() === '') {
        editor.setData(placeholder)
      }
    }

    editor.hasPlaceholder = function () {
      const contents = trim(editor.getData())
      return contents === placeholder
    }

    editor.on('instanceReady', setPlaceholder)
    editor.on('blur', setPlaceholder)
    editor.on('focus', () => {
      if (editor.hasPlaceholder()) {
        editor.setData('<p></p>')
      }
    })
  },
})
