import { computed, reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import titleize from 'lodash_extensions/titleize'

export function initPrintSettingsStore(initialState, viewableAsTeacher) {
  if (initialState.context === 'lesson_plan') {
    if (viewableAsTeacher) {
      initialState.layout = 'sideBySide'
      initialState.includeAboutLesson = JSON.parse(localStorage.getItem('lz.printSettings.includeAboutLesson')) || false
    } else {
      initialState.layout = 'noteTaking'
    }
  } else {
    initialState.range = 'current'
  }
  const { $set, state } = usePrintSettingsStore()
  const initialInstanceState = { ...initialState }
  $set(initialInstanceState)
  state.initialInstanceState = initialInstanceState
}

export const usePrintSettingsStore = defineStore('printSettings', () => {
  const state = reactive({
    layout: 'handout',
    includeAboutLesson: false,
    range: 'all',
    context: 'unidentified',
    hasLearnosityItem: false,
    analyticsProperties: {},
    initialInstanceState: {},
  })

  function resetInstanceState() {
    usePrintSettingsStore().$patch(state.initialInstanceState)
  }

  const willAutoPrint = computed(() => state.context !== 'lesson_plan')
  const title = computed(() => titleize(state.context))
  const analyticsName = computed(() => `${title.value} Used`)

  return {
    state,
    resetInstanceState,
    willAutoPrint,
    analyticsName,
  }
})
