import * as vueFeatures from 'vue_features'
import { createApp, provide, getCurrentInstance, h } from 'vue'
import { createI18n } from 'vue-i18n'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import applyPlugins from 'initializers/vue_plugins'
import { monitorAppErrors } from 'clients/sentry'
import emitter from 'tiny-emitter/instance'
import { createLzRouter } from 'vue_features/shared/router'
import { location as $location } from 'utils'
import { setupDevtools } from 'clients/devtools'

let jsonData = null

export default function () {
  jsonData = jsonData || JSON.parse(document.getElementById('vue-data').textContent)

  document.querySelectorAll('[data-vue-feature]').forEach((parent) => {
    const { name, state = {}, attrs = {}, props = {} } = jsonData[parent.dataset.vueJsonIndex]
    if (!vueFeatures[name]) {
      throw new Error(
        `${name} not found in vue_features/index.js. Did you spell it correctly? (i.e. HelloWorldFeature)`,
      )
    }
    const placeholders = Array.from(parent.childNodes)

    const { component, messages, options, router, props: featureProps } = vueFeatures[name]({ state, props })

    // root_store_setter_feature does not build a component
    if (component) {
      buildVueApp({ el: parent, component, messages, options, router, props: featureProps || props, attrs })
    }

    placeholders.forEach((ele) => ele.remove())
  })
}

export function createVueApp(options) {
  return monitorAppErrors(applyPlugins(createApp(options)))
}

export function buildVueApp({ component, el, messages, options, router, locale, props, attrs }) {
  const i18n = createI18n({ messages, locale: locale || useGlobalStore().state.locale })

  const app = createVueApp({
    ...options,
    setup() {
      provide('useRoot', () => {
        const root = getCurrentInstance().proxy.$root
        if (!root.$on) {
          root.$on = (...args) => emitter.on(...args)
          root.$once = (...args) => emitter.once(...args)
          root.$off = (...args) => emitter.off(...args)
          root.$rootEmit = (...args) => emitter.emit(...args)
        }
        return root
      })
    },
    render: () => h(component, { ...props, ...attrs }),
  })

  if (process.env.NODE_ENV === 'development' || __VUE_PROD_DEVTOOLS__) {
    setupDevtools(app)
  }

  if (router) {
    app.use(router)
  } else {
    app.use(createLzRouter({ routes: [{ path: $location.pathname(), component }] }))
  }
  app.use(i18n)
  app.mount(el)
}
