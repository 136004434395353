<!--
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/alt-text  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="student-instructions">
    <div v-if="!hasStudentFunctionality" class="lzui-tile my-4 border-blue-200 bg-blue-50 p-4">
      <h3>
        {{ $t('students_disabled_title') }}
      </h3>
      <p>
        {{ $t('students_disabled_info') }}
      </p>
    </div>
    <h3>
      {{ $t('step_one_ensure_formatting') }}
    </h3>
    <p>
      {{ $t('student_csv_example_instructions') }}
    </p>
    <p>
      {{ $t('student_upsert_notice') }}
    </p>
    <div class="mb-2">
      <a>
        <ImageTag path="misc/csv_student_example.jpeg" style="width: 698px; height: 131px" />
      </a>
    </div>
    <a href="/site_admin/student-csv-template_school.csv">
      {{ $t('download_student_csv_template') }}
    </a>
  </div>
</template>

<script>
import ImageTag from 'vue_features/shared/components/ui/ImageTag'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'StudentInstructions',
  components: { ImageTag },
  setup() {
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    return { hasStudentFunctionality }
  },
}
</script>
