<script setup>
import { ref, computed } from 'vue'
import draggable from 'vuedraggable'
import BookmarkItem from './BookmarkItem'
import { useBookmarksStore } from '../composables/use_bookmarks_store'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { getInsertRank, MAX_FLOAT_SIZE, sortByRank } from 'utils/card_rank_helpers'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import { ProgressCover } from 'vue_features/shared/components/ui'

const { bookmarks, bookmarksPagination, loadMoreBookmarks, updateRank } = useBookmarksStore()
const compactSortedBookmarks = computed(() => sortByRank(bookmarks.value.filter(({ lessonPlan }) => lessonPlan)))

const {
  visibleItems: visibleBookmarks,
  canLoadMore,
  showMoreItems,
  isLoading,
} = useLoadMoreList({
  itemsRef: compactSortedBookmarks,
  totalCount: bookmarksPagination.value.totalCount,
  startCount: 10,
  moreCount: 5,
})

const handleLoadMore = async () => {
  await showMoreItems(loadMoreBookmarks)
}

const isProgressCoverShown = ref(false)
const sorting = ref(false)

function endSort({ oldIndex, newIndex }) {
  sorting.value = false
  if (oldIndex !== newIndex) {
    const index = oldIndex > newIndex ? newIndex : newIndex + 1
    const newRank = getInsertRank(index, compactSortedBookmarks.value)

    isProgressCoverShown.value = newRank.length > MAX_FLOAT_SIZE
    updateRank(visibleBookmarks.value[oldIndex], newRank).then(() => {
      isProgressCoverShown.value = false
    })
  }
}
</script>

<template>
  <div class="w-full">
    <ProgressCover :visible="isProgressCoverShown" />
    <draggable
      :model-value="visibleBookmarks"
      :class="{ sorting }"
      item-key="id"
      class="space-y-4"
      tag="ul"
      handle=".handle"
      drag-class="dragging"
      @start="sorting = true"
      @end="endSort"
    >
      <template #item="{ element }">
        <BookmarkItem :bookmark="element" />
      </template>
    </draggable>
    <LoadMoreButton v-if="canLoadMore" v-bind="{ isLoading }" @click="handleLoadMore" />
  </div>
</template>
