<template>
  <AsyncContentLoader :loader="loadReturnedAssignmentData">
    <div :id="`learnosity-user-session-${assignmentId}`" class="learnosity-session-report clear-both space-y-6">
      <ReturnedAssignmentAttempted
        v-if="hasAttempted"
        :assignment-id="assignmentId"
        :questions-api-init="questionsApiInit"
        :items="items"
        class="space-y-6"
      />
      <ReturnedAssignmentUnattempted
        v-else
        :assignment-id="assignmentId"
        :item-references="itemReferences"
        :learnosity-assessment-config="learnosityAssessmentConfig"
        class="space-y-6"
      />
    </div>
  </AsyncContentLoader>
</template>

<script>
import { computed } from 'vue'
import ReturnedAssignmentAttempted from './ReturnedAssignmentAttempted'
import ReturnedAssignmentUnattempted from './ReturnedAssignmentUnattempted'
import { values } from 'lodash'
import { HTTP, camelCaseKeys } from 'vue_features/shared/helpers/http_helper'
import { AsyncContentLoader } from 'vue_features/shared/components/ui'
import {
  useAssignmentStore,
  useAssignmentShowStore,
  useAssignmentCardsStore,
} from 'vue_features/assignments/show/composables'
import Routes from 'routes'

export default {
  name: 'ReturnedAssignment',
  components: {
    ReturnedAssignmentAttempted,
    ReturnedAssignmentUnattempted,
    AsyncContentLoader,
  },
  setup() {
    const { assignmentId } = useAssignmentStore()
    const { lrnoAssessmentConfig, learnosityConfigSessionId } = useAssignmentShowStore()
    const { questionCards } = useAssignmentCardsStore()
    const itemReferences = computed(() => questionCards.value.map((questionCard) => questionCard.content.content))
    return {
      assignmentId,
      learnosityAssessmentConfig: lrnoAssessmentConfig,
      sessionId: learnosityConfigSessionId,
      itemReferences,
    }
  },
  data() {
    return {
      questionsApiInit: undefined,
      items: [],
    }
  },

  computed: {
    hasAttempted() {
      return this.items.length > 0
    },

    hasViewedAssignment() {
      return !!this.sessionId
    },
  },

  methods: {
    loadReturnedAssignmentData() {
      if (!this.hasViewedAssignment) return Promise.resolve()

      // FIXME: This is copy/pasted from here:
      // https://github.com/LearnZillion/learnzillion/blob/6a79111b8a66d373784f49d69b1a3c094f402ae8/app/javascript/vue_features/assignments/reports/api/assignment_response_report_service.js#L9
      // Is there a better way to reuse this code that we should be taking?
      const url = Routes.lz_code_student_response_path(this.assignmentId, this.sessionId)

      return HTTP.getJSON(url).then((data) => {
        this.questionsApiInit = data.questionsApiInit
        this.items = values(camelCaseKeys(data.items))
      })
    },
  },
}
</script>
