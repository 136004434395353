<template>
  <div ref="cardDeckEl" :style="cardDeckStyle">
    <slot
      name="header"
      v-bind="{
        currentCardTitle,
        currentPosition,
        setCurrentPosition,
        handleBeforeFullscreenEnter,
        handleFullscreenChange,
        goPrevious,
        goNext,
        isEnabled,
        totalCount,
      }"
    >
      <CardDeckHeader
        v-bind="{
          cardArchived: currentCardIsArchived,
          title: currentCardTitle,
          currentPosition,
          totalCount,
          isEnabled,
          isFullscreenAllowed: true,
        }"
        @go-previous="goPrevious"
        @go-next="goNext"
        @fullscreen-before-enter="handleBeforeFullscreenEnter"
        @fullscreen-change="handleFullscreenChange"
      />
    </slot>
    <div class="lp-deck__body" data-test="cards-body">
      <ul ref="cardList" class="lp-deck__cards">
        <CardDeckListItem
          v-for="(card, index) in cards"
          v-bind="{
            card,
            currentPosition,
            index,
            showCards,
            showNotes,
            showTool,
            totalCount,
          }"
          :key="`card-${card.id}`"
          data-test="card-deck-item"
          @first-card-el="firstCardEl = $event"
        >
          <BeginCard v-if="card.isBeginLessonCard" @begin="setCurrentPosition(1)" />
          <slot v-if="card.id === $options.END_CARD_ID" name="end-card" v-bind="{ setCurrentPosition }" />
        </CardDeckListItem>
      </ul>
    </div>
    <slot name="footer" v-bind="{ goNext, goPrevious, currentPosition, isEnabled, totalCount }" />
  </div>
</template>

<script>
import { ref, computed, watch, provide, inject, onMounted, onDeactivated, onBeforeUnmount } from 'vue'
import CardDeckHeader from './CardDeckHeader'
import CardDeckListItem from './CardDeckListItem'
import useCardDeckFullscreen from './use_card_deck_fullscreen'
import { useKeyboardNavigation } from './use_shortcut_navigation'
import { END_CARD_ID } from 'vue_features/assignments/show/composables'
import BeginCard from './BeginCard'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import { isArchived } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'CardDeck',
  components: { BeginCard, CardDeckHeader, CardDeckListItem },
  END_CARD_ID,
  props: {
    cards: {
      type: Array,
      required: true,
    },
    deckId: {
      type: String,
      required: true,
    },
    activeDeckId: {
      type: String,
      required: true,
    },
    selectedCardId: {
      type: Number,
      default: 0,
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    showCards: {
      type: Boolean,
      default: true,
    },
    showNotes: {
      type: Boolean,
      default: false,
    },
    showTool: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    provide('deckId', props.deckId)

    const currentPosition = ref(0)

    watch(
      () => props.selectedCardId,
      () => {
        const selectedCardIndex = Math.max(0, props.cards.map((card) => card.id).indexOf(props.selectedCardId))
        currentPosition.value = selectedCardIndex
      },
      { immediate: true },
    )

    const currentCard = computed(() => props.cards[currentPosition.value])
    const hasNextCard = computed(() => currentPosition.value < props.cards.length - 1)

    function setCurrentPosition(value) {
      if (!props.isEnabled) return

      if (value >= 0 && value < props.cards.length) {
        currentPosition.value = value
        emit('current-card-change', currentCard.value.id)
      }
    }
    function goPrevious() {
      setCurrentPosition(currentPosition.value - 1)
    }
    function goNext() {
      setCurrentPosition(currentPosition.value + 1)
    }

    const currentCardTitle = computed(() => {
      if (!hasNextCard.value) {
        return props.cards[props.cards.length - 1].title
      }
      return currentCard.value.title
    })

    const currentCardIsArchived = computed(() => {
      return !!(currentCard.value.content && isArchived(currentCard.value.content))
    })

    const totalCount = computed(() => props.cards.length)

    const firstCardEl = ref(null),
      firstCardWidth = ref(0)
    function handleBeforeFullscreenEnter() {
      if (firstCardEl.value) {
        firstCardWidth.value = firstCardEl.value.offsetWidth
      }
    }

    const fullscreenContainerEl = inject('fullscreenContainerEl')
    const cardDeckEl = ref(null)
    const { handleFullscreenChange, cardDeckStyle } = useCardDeckFullscreen(
      fullscreenContainerEl,
      cardDeckEl,
      firstCardWidth,
    )

    const cardList = ref(null)
    const { activate: activateKeyboardNavigation } = useKeyboardNavigation(goPrevious, goNext)

    function resetLearnosityClient() {
      if (props.cards.some((card) => isQuestionCard(card))) {
        useLearnosityClientStore().resetAll()
      }
    }

    function activateShortcuts() {
      if (props.activeDeckId === props.deckId) {
        activateKeyboardNavigation()
      }
    }

    watch(() => props.activeDeckId, activateShortcuts)
    watch(
      () => props.isEnabled,
      (isEnabled) => {
        if (isEnabled) {
          activateShortcuts()
        }
      },
    )
    onMounted(activateShortcuts)

    onBeforeUnmount(resetLearnosityClient)

    // If the card deck is removed from the DOM (i.e. a teacher switches tabs), the Learnosity client must
    // be reset so the Learnosity cards will re-render properly (via onActivated in LearnosityRerenderer.vue).
    onDeactivated(resetLearnosityClient)

    return {
      cardDeckEl,
      cardList,
      currentCard,
      currentPosition,
      setCurrentPosition,
      hasNextCard,
      currentCardTitle,
      firstCardEl,
      handleBeforeFullscreenEnter,
      handleFullscreenChange,
      cardDeckStyle,
      goPrevious,
      goNext,
      totalCount,
      currentCardIsArchived,
    }
  },
}
</script>
