<template>
  <div class="border-base flex-col rounded-xl border py-4 pl-12 pr-4">
    <div class="relative w-full">
      <label :for="`type-${$t(typeKey)}`" class="text-sm font-bold">{{ $t(typeKey) }}</label>
      <input
        :id="`type-${$t(typeKey)}`"
        type="checkbox"
        :checked="active"
        class="absolute -left-6 mt-2 text-lg"
        @change="$emit('toggle', $event.target.checked)"
      />
    </div>
    <div class="w-full">
      <span class="text-muted text-xs leading-4">
        {{ $t(descriptionKey) }}
      </span>
    </div>
    <div v-if="availableCustomerGuidance.length > 0" class="w-full">
      <ul class="list">
        <li v-for="customerGuidance in availableCustomerGuidance" :key="customerGuidance.ids[0]" class="pt-4">
          <div class="flex items-center">
            <LzIcon :path="customerGuidance.iconPath" size="sm" />
            <span class="pl-4 text-xs font-bold">{{ $t(customerGuidance.titleKey) }}</span>
          </div>
          <ul class="list list-none">
            <li v-for="typeGuidanceKey in customerGuidance.typeKeys" :key="typeGuidanceKey" class="text-muted text-xs">
              {{ $t(typeGuidanceKey) }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { intersection } from 'lodash'
import { useDataDashboardStore } from '../composables/data_dashboard_store'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'WhatFilterTypeGuidance',
  components: { LzIcon },
  props: {
    guidance: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { availableCustomers } = useDataDashboardStore()

    const typeKey = computed(() => {
      return props.guidance.type
    })

    const descriptionKey = computed(() => {
      return props.guidance.description
    })

    const customers = computed(() => {
      return props.guidance.customerContent
    })

    const availableCustomerGuidance = computed(() => {
      return customers.value.filter((customer) => {
        return intersection(customer.ids, availableCustomers.value).length > 0
      })
    })

    return {
      typeKey,
      descriptionKey,
      availableCustomerGuidance,
    }
  },
}
</script>
