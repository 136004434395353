<template>
  <div class="google-link-warning mx-auto max-w-7xl px-3 md:px-6">
    <div class="google-classroom-link-course bg-focus border-base clear-both mb-8 mt-2 rounded-xl border px-4 py-2">
      <LzIcon
        path="icons/close"
        class="tm absolute right-0 mr-4 mt-2 cursor-pointer"
        @click="$emit('dismiss-notice')"
      />
      <h2 class="google-classroom-title my-2">{{ $t('klasses.google_classroom.link_notice_title') }}</h2>
      <p class="small">
        {{ $t('klasses.google_classroom.link_notice_call_to_action') }}
      </p>
      <LinkToGoogleCourseDropdown
        v-if="hasLinkedAccount"
        :user-completed-authentication="userCompletedAuthentication"
        @link-course="linkCourse"
      />
      <LinkAccountButton
        v-else
        v-slot="{ initAuthentication, scopesMissing, classroomDisabled }"
        class="flex flex-col items-center"
        data-test="google-classroom-account-linker"
        @google-account-linked="updateAfterLinking"
      >
        <button class="btn my-4" @click="initAuthentication">
          <LzIcon path="icons/google-signin" />
          {{ $t('klasses.google_classroom.select_your_course') }}
          <LzIcon path="icons/caret" size="sm" />
        </button>
        <span v-if="scopesMissing" class="text-orange-600 mb-4 mt-1 inline-block text-sm">
          <LzIcon path="icons/exclamation" />
          {{ $t('common.google_classroom.try_linking_again') }}
        </span>
        <span v-else-if="classroomDisabled" class="text-orange-600 mb-4 mt-1 inline-block text-sm">
          <LzIcon path="icons/exclamation" />
          {{ $t('common.google_classroom.disabled_by_admin') }}
        </span>
      </LinkAccountButton>
    </div>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import LinkAccountButton from 'vue_features/shared/components/google_classroom/LinkAccountButton'
import LinkToGoogleCourseDropdown from './LinkToGoogleCourseDropdown'
import { useKlassShowStore } from '../store'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'LinkToGoogleCallToAction',
  components: {
    LzIcon,
    LinkAccountButton,
    LinkToGoogleCourseDropdown,
  },
  setup() {
    const { klass } = useKlassShowStore()
    const { hasLinkedAccount, loadProfile, loadCourses, loadStudentsForCourse } = useGoogleClassroomStore()
    return { klass, hasLinkedAccount, loadProfile, loadCourses, loadStudentsForCourse }
  },
  data() {
    return {
      userDismissedLinkMessages: false,
      userCompletedAuthentication: false,
    }
  },
  methods: {
    linkCourse(courseId) {
      this.$emit('link-google-course', courseId)
    },
    async updateAfterLinking() {
      await this.loadProfile({ ignoreError: true })

      try {
        if (this.hasLinkedAccount) {
          this.userCompletedAuthentication = true
          this.loadCourses()
        }
      } catch {
        /* no-op */
      }
    },
  },
}
</script>
