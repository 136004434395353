<template>
  <ListDropdown v-bind="$props" :id="id">
    <template #actionButton="{ id: { buttonId }, toggle }">
      <div
        class="btn btn--small group flex cursor-pointer items-stretch gap-1 py-2 transition-colors"
        :class="{ disabled }"
      >
        <slot name="mainButton">
          <button
            v-track-click.once="eventObject"
            type="button"
            class="flex items-center justify-center gap-1 pr-1.5"
            :class="{
              'text-neutral-600 bg-neutral-50': disabled,
              'text-accent group-hover:text-accent-hover': !disabled,
            }"
            :aria-label="buttonAriaLabel"
            data-test="split-action-left"
            @click.prevent.stop="clickIfEnabled('button-click')"
          >
            <LzIcon v-if="buttonIcon" :path="buttonIcon" size="sm" type="span" />
            <slot name="label" :button-label="buttonLabel">
              {{ buttonLabel }}
            </slot>
          </button>
        </slot>
        <button
          id="split-action-right"
          :data-toggle="buttonId"
          :aria-label="$t('split_action_dropdown.see_more_actions')"
          data-test="split-action-right"
          :class="[
            'border-base flex cursor-pointer items-center justify-center border-l border-solid pl-1.5',
            ...dropdownButtonClasses,
          ]"
          :disabled="disabled"
          @click.prevent.stop="toggle"
          @click="$emit('split-button-click')"
        >
          <LzIcon type="span" size="sm" path="icons/caret" />
        </button>
      </div>
    </template>
    <template #listItems>
      <slot name="dropdownListItems" />
    </template>
  </ListDropdown>
</template>

<script>
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { components } from 'vue_features/shared/i18n'
import { trackClick } from 'vue_features/shared/directives'
import ListDropdown from './ListDropdown'
import LzIcon from '../LzIcon'

export default {
  name: 'SplitActionDropdown',
  i18n: { messages: namespaceLocaleObj(components, '', { only: 'split_action_dropdown' }) },
  components: { ListDropdown, LzIcon },
  directives: { trackClick },
  props: {
    ...ListDropdown.props,
    id: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      default: null,
    },
    buttonAriaLabel: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'small', 'big', 'xlarge'].indexOf(value) !== -1
      },
    },
    dropdownButtonClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    eventObject: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const clickIfEnabled = (name) => {
      if (!props.disabled) emit(name)
    }
    return { clickIfEnabled }
  },
}
</script>
