<template>
  <div class="border-neutral-300 bg-focus mb-4 flex flex-col rounded-2xl border border-solid p-4 text-center">
    <div class="flex items-center justify-center">
      <LzIcon v-if="icon" :path="icon" class="mr-1" size="md" />
      <h4 class="mb-0 mt-0">
        {{ title }}
      </h4>
    </div>
    <div class="mt-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'Notice',
  components: {
    LzIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>
