<template>
  <ul v-if="hasMetadata" class="list-metadata flex justify-items-stretch space-x-2 overflow-hidden text-sm">
    <li
      v-for="(value, key) in metadata"
      :key="key"
      style="overflow-wrap: break-word"
      class="text-neutral-700 mb-0 truncate font-bold uppercase"
    >
      <template v-if="key.includes('standard') && value.length > 100">
        <LzTooltip max-width-class="max-w-screen-sm" tag="span" placement="bottom">
          {{ truncate(value, { length: 100, separator: ',' }) }}
          <template #content>
            <strong>{{ capitalize(key) }}</strong>
            <p>{{ value }}</p>
          </template>
        </LzTooltip>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </li>
  </ul>
</template>

<script>
import { capitalize, truncate } from 'lodash'
import { computed } from 'vue'
import LzTooltip from 'vue_features/shared/components/ui/LzTooltip'

export default {
  name: 'MetadataList',
  components: { LzTooltip },
  props: { metadata: { type: Object, required: true } },
  setup(props) {
    const hasMetadata = computed(() => Object.keys(props.metadata).length > 0)
    return { hasMetadata, capitalize, truncate }
  },
}
</script>
