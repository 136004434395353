import consumer from 'channels/consumer.js'
import { camelCaseKeys } from 'vue_features/shared/helpers/http_helper'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'

export default class GradePassbackWebSocketHandler {
  constructor(courseAssignmentId) {
    this.courseAssignmentId = courseAssignmentId
  }

  createGradePassbackListener() {
    const info = this.info(this.courseAssignmentId)
    const identifier = JSON.stringify(info)
    const existingSub = this.fetchSub(identifier)
    if (existingSub) return existingSub

    const sub = consumer.subscriptions.create(this.info(this.courseAssignmentId), {
      connected() {
        this.perform('fetch_passback_status')
      },
      received: (data) => {
        this.handlePassbackActivityData(camelCaseKeys(data))
      },
    })

    return sub
  }

  fetchSub(identifier) {
    return consumer.subscriptions.subscriptions.find((s) => s.identifier === identifier)
  }

  info(courseAssignmentId) {
    return {
      channel: 'GoogleClassroomGradePassbackChannel',
      course_assignment_id: courseAssignmentId,
    }
  }

  handlePassbackActivityData(data) {
    if (!data) return

    useAssignmentReportStore().setGradePassbackStatus(data)
    const passbackComplete = Object.values(data.students).every(({ active }) => !active)
    if (passbackComplete) {
      this.closeConnection()
    }
  }

  closeConnection() {
    const existingSub = this.fetchSub(this.info(this.courseAssignmentId))
    if (existingSub) consumer.subscriptions.remove(existingSub)
  }
}
