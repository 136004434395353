import { unref, onBeforeUnmount } from 'vue'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

export function useSwipeNavigation(el, onLeft, onRight) {
  function deactivate() {
    const { off } = useNamespacedEventListener(unref(el))
    off('touchstart.cardDeck, touchend.cardDeck')
  }
  onBeforeUnmount(deactivate)

  function activate() {
    const { on } = useNamespacedEventListener(unref(el))
    deactivate()
    let xDown = null,
      yDown = null
    on(
      'touchstart.cardDeck',
      (event) => {
        if (ignoreGesturesFromTarget(event.target)) return

        xDown = event.touches[0].clientX
        yDown = event.touches[0].clientY
      },
      false,
    )
    on(
      'touchend.cardDeck',
      (event) => {
        if (!xDown || !yDown) {
          return
        }

        const xUp = event.changedTouches[event.changedTouches.length - 1].clientX
        const yUp = event.changedTouches[event.changedTouches.length - 1].clientY

        const xDiff = xDown - xUp
        const yDiff = yDown - yUp
        const threshold = window.screen.width * 0.33

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff > threshold) {
            onLeft()
          } else if (xDiff < -threshold) {
            onRight()
          }
        } else {
          return
        }

        xDown = null
        yDown = null
      },
      false,
    )
  }

  function ignoreGesturesFromTarget(target) {
    return (
      target.classList.contains('lrn_response_wrapper') ||
      target.closest('.lrn_response_wrapper') ||
      target.classList.contains('GeoGebraFrame') ||
      target.closest('.GeoGebraFrame')
    )
  }

  return { activate, deactivate }
}

export function useKeyboardNavigation(onLeft, onRight, options = {}) {
  const moreNextKeys = options.next || []
  const morePrevKeys = options.prev || []

  const NEXT_KEY = [34, 39, ...moreNextKeys],
    PREVIOUS_KEY = [33, 37, ...morePrevKeys],
    EXCLUDE_TAGS = ['INPUT', 'TEXTAREA']

  function deactivate() {
    const { off } = useNamespacedEventListener(document)
    off('keydown.cardDeck')
  }
  onBeforeUnmount(deactivate)

  function preventDefaultBehavior(e) {
    if (options.preventDefault) {
      e.preventDefault()
    }
  }

  function activate() {
    deactivate()
    const { on } = useNamespacedEventListener(document)
    on('keydown.cardDeck', (e) => {
      if (
        EXCLUDE_TAGS.indexOf(e.target.tagName.toUpperCase()) === -1 &&
        !e.target.classList.contains('cke_editable') &&
        !e.target.classList.contains('lrn_texteditor_editable')
      ) {
        const key = e.which

        if (NEXT_KEY.indexOf(key) > -1) {
          preventDefaultBehavior(e)
          onRight()
          return false
        } else if (PREVIOUS_KEY.indexOf(key) > -1) {
          preventDefaultBehavior(e)
          onLeft()
          return false
        }
      }
    })
  }
  return { activate, deactivate }
}
