import { ref, onBeforeUnmount } from 'vue'
import { throttle } from 'lodash'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import {
  useLessonPlayer,
  useLessonPlayerScrollManager,
  useLessonPlayerIterator,
} from 'vue_features/lesson_plans/show/composables/use_lesson_player'
import { useEventListener } from 'vue_features/shared/composables'

export default function ({ cardsRef, lessonPlayerElementRef, scrollManagerRootRef }) {
  const deckCurrentOffsetTop = ref(0)
  const deckShowToolbar = ref(false)

  const throttledScroll = throttle(() => {
    if (lessonPlayerElementRef.value) {
      deckCurrentOffsetTop.value = lessonPlayerElementRef.value.getBoundingClientRect().top
    }
  }, 250)

  const { selectedCardIndex, updateSelectedCardIndex, showToolbar, toggleToolbar, trackEvent } = useLessonPlayer()

  const { scrollToSelectedCard, currentCardElement, connectScrollHandling } = useLessonPlayerScrollManager({
    selectedCardIndexRef: selectedCardIndex,
    lessonPlayerElementRef,
    scrollManagerRootRef,
    updateSelectedCard: updateSelectedCardIndex,
    trackEvent: trackEvent,
  })
  const { goPrevious, goNext, activateKeyboardNavigation } = useLessonPlayerIterator({
    cardsRef,
    rootElRef: scrollManagerRootRef,
    currentCardElementRef: currentCardElement,
    showToolbarRef: showToolbar,
    selectedCardIndexRef: selectedCardIndex,
    cardSelected: scrollToSelectedCard,
  })

  useEventListener('scroll', throttledScroll)

  onBeforeUnmount(() => {
    if (cardsRef && cardsRef.value.some(isQuestionCard)) {
      useLearnosityClientStore().resetAll()
    }
  })

  return {
    deckCurrentOffsetTop,
    deckShowToolbar,
    // showToolbarChanged,
    selectedCardIndex,
    scrollToSelectedCard,
    currentCardElement,
    connectScrollHandling,
    updateSelectedCardIndex,
    showToolbar,
    toggleToolbar,
    trackEvent,
    goPrevious,
    goNext,
    activateKeyboardNavigation,
  }
}
