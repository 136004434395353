<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <InlineStandards v-if="displayInline" :standards="standards" />

  <div v-else class="relative mt-6 max-w-full">
    <ul v-if="showCoverageTabs" class="lzui-nav-tabs vue-tabs">
      <li v-if="hasStandardsFor('addressed')" :class="{ active: isTabActive('addressed') }">
        <a data-test="addressed-coverage-selector" @click="activate('addressed')">{{ $t('directly_addressing') }}</a>
      </li>

      <li v-if="hasStandardsFor('buildingOn')" :class="{ active: isTabActive('buildingOn') }">
        <a data-test="building-on-coverage-selector" @click="activate('buildingOn')">{{ $t('building_on') }}</a>
      </li>

      <li v-if="hasStandardsFor('buildingTowards')" :class="{ active: isTabActive('buildingTowards') }">
        <a data-test="building-toward-coverage-selector" @click="activate('buildingTowards')">
          {{ $t('building_towards') }}
        </a>
      </li>
    </ul>

    <table
      ref="table"
      :class="['standards-coverage-table', isResponsive ? 'responsive' : '']"
      @scroll="debouncedOverflowUpdate"
    >
      <TableHeader />
      <TableBody />
    </table>

    <Pagination
      v-if="!forPrint"
      class="sticky bottom-0 left-0 w-full bg-white py-4"
      @goto-next-page="gotoNextPage"
      @goto-previous-page="gotoPreviousPage"
    />

    <template v-if="isResponsive">
      <span
        v-if="canScrollLeft"
        class="overflow-indicator-left left-0"
        :style="{ top: `${offsetTop}px`, height: `${tableHeight}px` }"
      />
      <span
        v-if="canScrollRight"
        class="overflow-indicator-right right-0"
        :style="{ top: `${offsetTop}px`, height: `${tableHeight}px` }"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, provide, computed, onMounted } from 'vue'
import { debounce } from 'lodash'
import useCoverageTable from '../composables/use_coverage_table'
import useOverflowIndicators from '../composables/use_overflow_indicators'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import InlineStandards from './InlineStandards'
import Pagination from './Pagination'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

const props = defineProps({
  displayBy: { type: String, required: true },
  contentType: { type: String, required: true },
  contents: { type: Object, required: true },
  forPrint: { type: Boolean, default: false },
})

const { standardInitiatives } = useCurrentCustomerStore()

const coverageTable = useCoverageTable({ ...props, standardInitiatives: standardInitiatives.value })

const {
  displayByStandard,
  displayByWiki,
  displayInline,
  items,
  standards,
  hasBuildingStandards,
  hasStandardsFor,
  page,
  coverageType,
} = coverageTable
const { canScrollRight, canScrollLeft, offsetTop, tableHeight, updateOverflowIndicators } = useOverflowIndicators()

const table = ref(null)

const isResponsive = computed(() => {
  return items.value && items.value.length > 8 && displayByWiki.value
})

const showCoverageTabs = computed(() => {
  return !displayByWiki.value && displayByStandard.value && hasBuildingStandards.value
})

const debouncedOverflowUpdate = debounce(updateIndicators, 100)

function gotoNextPage() {
  page.value += 1
}

function gotoPreviousPage() {
  page.value = Math.max(0, page.value - 1)
}

function activate(type) {
  coverageType.value = type
}

function isTabActive(type) {
  return coverageType.value === type
}

function updateIndicators() {
  if (isResponsive.value) {
    updateOverflowIndicators(table.value)
  }
}

onMounted(updateIndicators)

provide('coverageTable', coverageTable)
</script>
