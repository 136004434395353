<!--
  eslint-disable vuejs-accessibility/alt-text
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="klasses-show" :class="['lg:pb-120 bg-contain bg-bottom bg-no-repeat pb-36 md:pb-96', season]">
    <KlassUpdateModal v-if="canModalShow($options.KlassUpdateModal)" v-bind="{ klass, onShowPage: true }" />
    <KlassDeleteModal
      v-if="canModalShow($options.KlassDeleteModal)"
      v-bind="{ klass, onShowPage: true }"
      @delete-error="showDeleteError = true"
    />
    <AssignmentsArchiveByDateModal v-if="canModalShow($options.AssignmentsArchiveByDateModal)" />
    <LinkErrorListener />

    <ProgressCover :visible="showProgressCover" />
    <div class="page-head">
      <div class="page-head__wrapper">
        <div class="page-head__content">
          <div class="page-head__pretext" data-test="klass-pretext">
            {{ pretext }}
          </div>
          <div class="flex flex-row flex-wrap items-stretch">
            <h1>{{ klass.name }}</h1>
            <span
              v-if="!klass.active"
              data-test="archive-badge"
              class="ml-1 inline-flex max-h-6 items-center self-center rounded-full bg-gray-400 px-2 py-1 text-xs text-white"
            >
              <LzIcon path="icons/archive" />
              <span>{{ $t('common.archived') }}</span>
            </span>
          </div>
          <template v-if="klass.isFromClever">
            <div class="flex items-center">
              <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
              <div data-test="copy-clever-id-wrapper" @click.meta="copyCleverId">
                <LzIcon
                  v-if="klass.isFromClever"
                  path="icons/synced-arrows"
                  :aria-label="$t('klasses.index.synced_from_sis')"
                  size="sm"
                  class="text-neutral-700"
                />
              </div>
              <span class="gray-600 ml-2 text-xs font-bold">{{ $t('klasses.synced_with_clever') }}</span>
              <LzTooltip class="ml-1" :title="$t('klasses.clever_manage')">ℹ️</LzTooltip>
            </div>
          </template>
          <ul v-if="klass.gradeLevelCodes.length" class="page-head__metadata list-metadata">
            <li>
              <strong>Grade levels:</strong>
              {{ klass.gradeLevelCodes.join(', ') }}
            </li>
          </ul>
        </div>

        <div v-if="!isStudent" class="page-head__actions page-head__actions--pretext">
          <div data-test="actions-container" class="float-right mb-4">
            <ListDropdown
              id="edit-class"
              class="float-right"
              :button-classes="['btn--gray', 'btn--small', 'mt-2', 'mb-0']"
            >
              <template #button>
                {{ $t('common.edit') }}
                <LzIcon class="fe-edit float-right" size="sm" path="icons/caret" />
              </template>
              <template #listItems>
                <li>
                  <button type="button" @click="openModal($options.KlassUpdateModal)">
                    {{ $t('klasses.edit_name') }}
                  </button>
                </li>
                <li v-if="klass.active">
                  <button type="button" @click="archiveClass">
                    {{ $t('klasses.archive') }}
                  </button>
                </li>
                <li v-if="!klass.active && !klass.isArchivedBySync">
                  <button type="button" @click="unarchiveClass">
                    {{ $t('klasses.unarchive') }}
                  </button>
                </li>
                <li>
                  <button type="button" @click="openModal($options.AssignmentsArchiveByDateModal)">
                    {{ $t('klasses.archive_assignments_by_date') }}
                  </button>
                </li>
                <li v-if="!klass.isFromClever && klass.canDelete">
                  <button
                    type="button"
                    data-test="delete-action"
                    class="red"
                    @click="openModal($options.KlassDeleteModal)"
                  >
                    {{ $t('klasses.delete') }}
                  </button>
                </li>
              </template>
            </ListDropdown>
          </div>
          <div v-if="isConnectedToGoogle && (hasGoogleCourse || hasDismissedCallToAction)" class="clear-right">
            <GoogleCourseDropdown :klass="klass" @link-google-course="confirmAndLink" />
          </div>
        </div>
      </div>
      <div v-if="showDeleteError" class="mx-auto max-w-7xl px-3 md:px-6">
        <Alert
          :dismissible="true"
          type="error"
          :title="$t('klasses.delete')"
          :description="$t('klasses.cannot_delete_class')"
          @dismissed="showDeleteError = false"
        />
      </div>

      <template v-if="!isStudent && googleClassroomEnabled">
        <div class="mx-auto max-w-7xl px-4">
          <LoadingSpinner v-if="isLinkingInProgress" />
          <Alert
            v-else-if="isShowingGoogleError"
            :dismissible="true"
            type="error"
            :title="$t('klasses.google_classroom.link_error')"
            @dismissed="isShowingGoogleError = false"
          />
          <Alert
            v-else-if="isShowingGoogleSuccess"
            :dismissible="true"
            type="success"
            :title="$t('klasses.google_classroom.link_success_title')"
            :description="$t('klasses.google_classroom.link_success_description')"
            @dismissed="isShowingGoogleSuccess = false"
          />
          <LinkToGoogleCallToAction
            v-else-if="
              !hasGoogleCourse && !hasDismissedCallToAction && !(isShowingGoogleError || isShowingGoogleSuccess)
            "
            @link-google-course="confirmAndLink"
            @dismiss-notice="dismissGoogleCallToAction"
          />
        </div>
      </template>
    </div>

    <div class="page-head-nav">
      <div data-test="klass-tabs" class="mx-auto max-w-7xl px-3 md:px-6">
        <LzTabs :links="tabLinks" />
      </div>
    </div>
    <div class="page-content">
      <RouterView />
    </div>
    <GoogleCourseLinkConfirmModal
      v-if="canModalShow($options.GoogleCourseLinkConfirmModal)"
      :course-id="courseIdToConfirm"
      :course-name="courseNameToConfirm"
      :linked-klass-id="linkedKlassId"
      @confirm-link-update="linkCourse"
    />
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import { get } from 'lodash'
import { Alert, LoadingSpinner, LzTooltip, LzIcon, LzTabs, ProgressCover } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import LinkErrorListener from 'vue_features/shared/components/google_classroom/LinkErrorListener'
import { KlassDeleteModal, KlassUpdateModal } from '../../shared/components/modals'
import { AssignmentsArchiveByDateModal } from 'vue_features/assignments/shared/components'
import LinkToGoogleCallToAction from './LinkToGoogleCallToAction'
import GoogleCourseDropdown from './GoogleCourseDropdown'
import { openModal, canModalShow, closeModal, activeModal } from 'vue_features/shared/composables/use_global_modals'
import GoogleCourseLinkConfirmModal from './GoogleCourseLinkConfirmModal'
import { location as $location } from 'utils'
import { useKlassAssignmentsStore, useKlassShowStore } from '../store'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { currentSeason } from 'vue_features/klasses/shared/utils/dates'
import Routes from 'routes'

export default {
  name: 'KlassesShow',
  KlassDeleteModal,
  KlassUpdateModal,
  AssignmentsArchiveByDateModal,
  GoogleCourseLinkConfirmModal,
  components: {
    LinkErrorListener,
    Alert,
    LoadingSpinner,
    ListDropdown,
    LzIcon,
    LzTooltip,
    ProgressCover,
    LzTabs,
    KlassDeleteModal,
    KlassUpdateModal,
    AssignmentsArchiveByDateModal,
    LinkToGoogleCallToAction,
    GoogleCourseDropdown,
    GoogleCourseLinkConfirmModal,
  },
  setup() {
    const showDeleteError = ref(false)
    const { googleClassroomEnabled } = useCurrentCustomerStore()
    const { klass, teachers, unarchiveKlass, archiveKlass, associateGoogleCourseToKlass, showProgressCover } =
      useKlassShowStore()
    const { hasAnyCodes, activeCount, inactiveCount } = useKlassAssignmentsStore()
    const { authorizedAsTeacher, isStudent } = useCurrentUserStore()
    const {
      state,
      hasDismissedCallToAction,
      coursesData,
      isConnectedToGoogle,
      loadStudentsForCourse,
      loadLzStudents,
      loadCourses,
      dismissGoogleCallToAction,
    } = useGoogleClassroomStore()

    // Access to this is hidden behind a @click.meta, not because it's sensitive
    // data, but because only admin users care about it. But the easiest way to
    // navigate to the class is to impersonate a teacher. So this allows anyone
    // with teacher+ permissions to copy the Clever ID for the class.
    const copyCleverId = () => {
      if (authorizedAsTeacher.value) {
        navigator.clipboard.writeText(klass.value.cleverId)
      }
    }

    const hasGoogleCourse = computed(() => {
      return !!get(klass.value.googleClassroomCourse, 'courseId')
    })
    const root = inject('useRoot')()
    if (!isStudent.value) {
      if (isConnectedToGoogle.value && hasGoogleCourse.value) {
        loadLzStudents()
        loadStudentsForCourse({ courseId: klass.value.googleClassroomCourse.courseId })
      }
      root.$on('new-student-added', () => {
        if (isConnectedToGoogle.value && klass.value.googleClassroomCourse) {
          loadStudentsForCourse({ courseId: klass.value.googleClassroomCourse.courseId })
          loadLzStudents({ force: true })
        }
      })
    }

    return {
      state,
      googleClassroomEnabled,
      klass,
      teachers,
      unarchiveKlass,
      archiveKlass,
      associateGoogleCourseToKlass,
      showDeleteError,
      hasAnyCodes,
      isStudent,
      hasDismissedCallToAction,
      coursesData,
      isConnectedToGoogle,
      loadStudentsForCourse,
      loadLzStudents,
      loadCourses,
      dismissGoogleCallToAction,
      showProgressCover,
      openModal,
      canModalShow,
      closeModal,
      activeModal,
      copyCleverId,
      season: currentSeason(),
      activeCount,
      inactiveCount,
      hasGoogleCourse,
    }
  },
  data() {
    return {
      teacherToRemove: null,
      isShowingGoogleInfo: true,
      isLinkingInProgress: false,
      isShowingGoogleSuccess: false,
      isShowingGoogleError: false,
      courseIdToConfirm: null,
      courseNameToConfirm: null,
      linkedKlassId: null,
    }
  },
  computed: {
    pretext() {
      return this.teachers.map((t) => t.studentFacingName || t.lastName).join(', ')
    },
    googleLinkUrl() {
      return Routes.edit_user_path()
    },
    tabLinks() {
      if (this.isStudent) {
        return [
          {
            name: 'assignments',
            label: this.$t('student_klasses.show.assignments') + ` (${this.activeCount})`,
            default: true,
            params: { role: 'student', id: this.klass.id },
          },
          {
            name: 'assignments-inactive',
            label: this.$t('student_klasses.show.done') + ` (${this.inactiveCount})`,
            params: { role: 'student', id: this.klass.id },
          },
        ]
      }

      return [
        {
          name: 'assignments',
          label: this.$t('klasses.show.active_assignments') + ` (${this.activeCount})`,
          default: true,
          params: { role: 'teacher', id: this.klass.id },
        },
        {
          name: 'assignments-inactive',
          label: this.$t('klasses.show.archived_assignments') + ` (${this.inactiveCount})`,
          params: { role: 'teacher', id: this.klass.id },
        },
        { name: 'people', label: this.$t('klasses.show.people_text'), params: { role: 'teacher', id: this.klass.id } },
      ]
    },
  },
  methods: {
    archiveClass() {
      this.archiveKlass(this.klass)
    },
    async unarchiveClass() {
      await this.unarchiveKlass()
      $location.assign(Routes.teacher_klass_url(this.klass.id, { unarchive: true }))
    },
    confirmAndLink(courseId) {
      const course = this.coursesData.find((c) => c.courseId === courseId)
      if (course.klassId) {
        this.courseIdToConfirm = course.courseId
        this.courseNameToConfirm = course.name
        this.linkedKlassId = course.klassId
        this.openModal(this.$options.GoogleCourseLinkConfirmModal)
        return
      }

      this.linkCourse(course.courseId)
    },
    async linkCourse(courseId) {
      this.courseIdToConfirm = null
      this.courseNameToConfirm = null
      this.linkedKlassId = null

      try {
        this.isLinkingInProgress = true
        await this.associateGoogleCourseToKlass({ klass: this.klass, courseId })
        await Promise.all([
          this.loadCourses({ force: true }),
          this.loadStudentsForCourse({ courseId: this.klass.googleClassroomCourse.courseId }),
          this.loadLzStudents(),
        ])
        this.isShowingGoogleSuccess = true
      } catch {
        this.isShowingGoogleError = true
      } finally {
        this.isLinkingInProgress = false
      }
    },
  },
}
</script>
