<template>
  <Card
    :collapsible="true"
    :header-border="false"
    :header-classes="{ 'bg-neutral-50': klass.fullyAssigned || klass.activeCount === 0 }"
    header-target-classes="py-4 pl-4 gap-x-1"
    header-action-classes="items-start justify-end pt-3 pr-4 space-x-2 sm:space-x-4"
    action-size="x-3-action"
    :select-checked="allSelected"
    :select-disabled="klass.fullyAssigned || !klass.activeCount"
    :select-indeterminate="partlySelected && !allSelected"
    :input-id="klass.id.toString()"
    @open="initialize"
    @select-all="toggleKlassCheck(klass)"
  >
    <template #header>
      <div data-test="class-header" class="max-w-24 sm:max-w-72 flex flex-wrap items-center gap-x-2">
        <span class="text-md truncate text-left text-base font-semibold">{{ klass.name }}</span>
        <span v-if="klass.checked || partlySelected" class="text-xs text-blue-700">
          ({{ studentCount }} <span class="hidden sm:inline-block">{{ selectLabel }}</span
          >)
        </span>
      </div>
    </template>
    <template #actions>
      <AssignToGoogleClassroom
        v-if="isConnectedToGoogle"
        :klass-id="klass.id"
        :course-name="googleCourse('name')"
        :course-id="googleCourse('courseId')"
        :unlinked-count="klass.googleClassroomUnlinkedCount"
        :assignment-list="klass.activeLzCodes.map((code) => code.id)"
        :archived="!!googleCourse('archived')"
        :disabled="!klass.activeCount"
      />
      <div
        :class="[
          'mt-1 items-center space-x-2',
          { 'text-gray-400': klass.activeCount === 0 },
          { 'hidden sm:flex': hasGoogleLink },
          { flex: !hasGoogleLink },
        ]"
      >
        <LzIcon path="icons/user-group" class="h-6 w-6" />
        <span class="text-sm font-bold">{{ klass.activeCount }}</span>
      </div>
    </template>
    <div data-test="assign-student-list" class="flex flex-col rounded-b-2xl">
      <template v-if="initialized">
        <template v-if="totalCount">
          <label
            v-for="student in visibleItems"
            :key="student.id"
            :for="`assign-student-${student.id}`"
            class="no-fullstory border-base m-0 flex items-center space-x-2.5 border-t py-2 pl-10"
          >
            <Checkbox
              :id="`assign-student-${student.id}`"
              data-test="student-select"
              :checked="student.checked || student.allAssigned"
              :disabled="student.allAssigned"
              :indeterminate="!(student.checked || student.allAssigned) && student.anyAssigned"
              class="mt-0"
              @change="toggleStudentCheck({ klass, student })"
            />
            <span :class="{ 'text-gray-600': student.allAssigned }">{{ student.name }}</span>
            <LzIcon
              v-if="student.anyAssignedInOtherClass && !student.allAssigned"
              data-test="conflicting-class-assignment"
              path="icons/exclamation"
              size="sm"
              class="text-orange-600 ml-1"
            />
          </label>
          <div v-if="canLoadMore" class="border-base border-t py-2 pl-10 pr-4">
            <LoadingSpinner v-if="isLoading" />
            <button v-else class="text-accent hover:text-primary-600" @click.stop="showMoreItems(handleLoadMore)">
              {{ $t('student_list.load_more_students') }}
            </button>
          </div>
          <div v-if="hasConflicted" class="border-base flex items-center gap-x-2 border-t py-2 pl-10 pr-4 text-xs">
            <LzIcon data-test="class-has-conflicted" class="text-orange-600" path="icons/exclamation" size="sm" />
            <span>{{ $t('assign_to_student_tab.class_conflict', { klass_name: klass.name }) }}</span>
          </div>
        </template>
        <div v-else class="border-base border-t py-2 pl-10 pr-4">
          <a :href="`${Routes.teacher_klass_path(klass.id)}/people`" data-test="add-students-link">{{
            $t('student_list.add_students')
          }}</a>
        </div>
      </template>
      <LoadingSpinner v-else />
    </div>
  </Card>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { LzIcon, LoadingSpinner, Card, Checkbox } from 'vue_features/shared/components/ui'
import AssignToGoogleClassroom from './AssignToGoogleClassroom'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import Routes from 'routes'

export default {
  name: 'AssignStudentList',
  components: { LzIcon, LoadingSpinner, Card, Checkbox, AssignToGoogleClassroom },
  props: { klass: { type: Object, required: true } },
  setup(props) {
    const root = inject('useRoot')()
    const initialized = ref(!!props.klass.studentsData.meta)
    const _students = computed(() => (initialized.value ? props.klass.studentsData.students : []))
    const _shallowCount = computed(() => !initialized.value && props.klass.checked)
    const _studentsChecked = computed(() => {
      const students = props.klass.studentsData?.students || []
      return students.filter((student) => student.checked).length
    })
    const _studentsCheckable = computed(() => {
      const students = props.klass.studentsData?.students || []
      return students.filter((student) => !student.allAssigned).length
    })

    const totalCount = computed(() => (initialized.value ? props.klass.studentsData.meta.pagination?.totalCount : 0))
    const studentCount = computed(() => {
      const args = _shallowCount.value
        ? { count: props.klass.activeCount, total: props.klass.activeCount }
        : { count: _studentsChecked.value, total: _studentsCheckable.value }
      return root.$t('assign_to_student_tab.student_count', args)
    })

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: _students,
      totalCount: totalCount,
    })
    const { materialsToAssign, loadMoreStudents, toggleKlassCheck, toggleStudentCheck } = useAssignmentPromptStore()
    const { isConnectedToGoogle } = useGoogleClassroomStore()

    const initialize = async () => {
      props.klass.studentsData.meta
        ? (initialized.value = true)
        : await handleLoadMore(() => (initialized.value = true))
    }
    const handleLoadMore = (done) => loadMoreStudents(props.klass).then(done)

    return {
      Routes,
      initialized,
      totalCount,
      studentCount,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      loadMoreStudents,
      toggleKlassCheck,
      toggleStudentCheck,
      isConnectedToGoogle,
      initialize,
      handleLoadMore,
      allSelected: computed(() => props.klass.checked || props.klass.fullyAssigned),
      partlySelected: computed(() => !!_studentsChecked.value),
      hasConflicted: computed(() => _students.value.some((s) => s.anyAssignedInOtherClass && !s.allAssigned)),
      toAssignCount: computed(() => materialsToAssign.value.length),
      selectLabel: computed(() => root.$t(`assign_to_student_tab.select${_shallowCount.value ? 'ed' : 'able'}`)),
      hasGoogleLink: computed(() => isConnectedToGoogle.value && !!props.klass.googleClassroomCourse),
      googleCourse: (key) => (props.klass.googleClassroomCourse ? props.klass.googleClassroomCourse[key] : ''),
    }
  },
}
</script>
