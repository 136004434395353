import { ceil, each, every, floor, maxBy, minBy, some } from 'lodash'
import {
  alignedBottom,
  alignedLeft,
  alignedRight,
  alignedTop,
  bottomRow,
  isAbove,
  isBelow,
  isToLeftOf,
  isToRightOf,
  rightMostColumn,
} from './models/grid_indices'
import { ROWS, COLUMNS } from './use_grid'
import { extend } from 'utils'

export const MIN_GRID_SIZE = 1
export const MIN_SPLITTABLE_SIZE = MIN_GRID_SIZE * 2

export default function useSizingStrategy({
  addAll,
  getItem,
  containsOnly,
  getSurroundingContents,
  getSurroundingAdjustables,
  getAdjustablesInSpace,
}) {
  function fillSpaceOf(content) {
    const { contentsToLeft, contentsToRight, contentsAbove, contentsBelow } = getSurroundingAdjustables(content)

    if (RowAdjustment.canFill(content, contentsToLeft.concat(contentsToRight))) {
      return RowAdjustment.fill(content, contentsToLeft, contentsToRight)
    } else if (ColumnAdjustment.canFill(content, contentsAbove.concat(contentsBelow))) {
      return ColumnAdjustment.fill(content, contentsAbove, contentsBelow)
    } else if (!containsOnly(content)) {
      return DoubleAdjustment.fill(content, getSurroundingContents(content))
    }
  }

  function makeSpaceFor(content) {
    const { contentsToLeft, contentsToRight, contentsAbove, contentsBelow } = getAdjustablesInSpace(content)

    RowAdjustment.shrink(content, contentsToLeft, contentsToRight)
    ColumnAdjustment.shrink(content, contentsAbove, contentsBelow)
  }

  function resize(content, resizeAmount, direction) {
    const adjustment = direction === 'bottom' ? RowAdjustment : ColumnAdjustment
    return adjustment.resize({ getItem, addAll }, content, resizeAmount)
  }

  return { fillSpaceOf, makeSpaceFor, resize }
}

const Adjustments = {
  shrink(addedContent, contentsBefore, contentsAfter) {
    const addedContentLastRow = addedContent[this.span] + addedContent[this.direction]

    each(contentsBefore, (bContent) => {
      return (bContent[this.span] -= bContent[this.direction] + bContent[this.span] - addedContent[this.direction])
    })

    each(contentsAfter, (aContent) => {
      const directionOverlap = addedContentLastRow - aContent[this.direction]
      aContent[this.span] -= directionOverlap
      aContent[this.direction] += directionOverlap
    })

    return contentsBefore.concat(contentsAfter)
  },

  fill(removedContent, contentsBefore, contentsAfter) {
    contentsBefore = this.filterContents(removedContent, contentsBefore)
    contentsAfter = this.filterContents(removedContent, contentsAfter)

    const { spanAdjustmentBefore, spanAdjustmentAfter } = this.getSplittableAdjustments(
      removedContent,
      contentsBefore,
      contentsAfter,
    )

    each(contentsBefore, (bContent) => (bContent[this.span] = bContent[this.span] + spanAdjustmentBefore))
    const newStart = some(contentsBefore)
      ? removedContent[this.direction] + spanAdjustmentBefore
      : removedContent[this.direction]
    each(contentsAfter, (aContent) => {
      aContent[this.span] = aContent[this.span] + spanAdjustmentAfter
      aContent[this.direction] = newStart
    })
    return contentsBefore.concat(contentsAfter)
  },

  getSplittableAdjustments(content, contentsBefore, contentsAfter) {
    const fullAdjustment = content[this.span],
      fullBeforeAllowsReDrop = this.allowsReDrop(contentsBefore, fullAdjustment),
      fullAfterAllowsReDrop = this.allowsReDrop(contentsAfter, fullAdjustment)

    if (!some(contentsAfter) || !some(contentsBefore) || fullBeforeAllowsReDrop || fullAfterAllowsReDrop) {
      return this.getFullAdjustment({
        contentsBefore,
        contentsAfter,
        fullAdjustment,
        fullBeforeAllowsReDrop,
        fullAfterAllowsReDrop,
      })
    }

    return { spanAdjustmentAfter: floor(fullAdjustment / 2), spanAdjustmentBefore: ceil(fullAdjustment / 2) }
  },

  getFullAdjustment({ fullAdjustment, contentsBefore, contentsAfter, fullBeforeAllowsReDrop, fullAfterAllowsReDrop }) {
    const fullAdjustmentBefore = { spanAdjustmentAfter: 0, spanAdjustmentBefore: fullAdjustment },
      fullAdjustmentAfter = { spanAdjustmentAfter: fullAdjustment, spanAdjustmentBefore: 0 }

    if (fullBeforeAllowsReDrop && fullAfterAllowsReDrop) {
      return this.getFullAdjustmentCompromise({
        fullAdjustmentBefore,
        fullAdjustmentAfter,
        contentsBefore,
        contentsAfter,
      })
    }
    return !some(contentsAfter) || fullBeforeAllowsReDrop ? fullAdjustmentBefore : fullAdjustmentAfter
  },

  getFullAdjustmentCompromise({ fullAdjustmentBefore, fullAdjustmentAfter, contentsBefore, contentsAfter }) {
    if (this.onEdge(contentsBefore)) {
      return fullAdjustmentBefore
    } else if (this.onEdge(contentsAfter)) {
      return fullAdjustmentAfter
    }
    return contentsAfter.length < contentsBefore.length ? fullAdjustmentAfter : fullAdjustmentBefore
  },

  fullyInside(content, contents) {
    const highest = minBy(contents, this.oppositeDirection)[this.oppositeDirection]
    const lowest = maxBy(contents, (c) => c[this.oppositeDirection] + c[this.oppositeSpan])
    const contentLowest = content[this.oppositeDirection] + content[this.oppositeSpan]

    return (
      highest === content[this.oppositeDirection] &&
      contentLowest === lowest[this.oppositeDirection] + lowest[this.oppositeSpan]
    )
  },

  allowsReDrop(contents, fullAdjustment) {
    if (!contents.length) return false

    const minSpan = minBy(contents, this.span)[this.span]
    return floor((minSpan + fullAdjustment) / 2) == fullAdjustment
  },

  canFill(content, contentList) {
    return some(this.filterContents(content, contentList))
  },

  filterContents(content, contentList) {
    if (some(contentList) && !this.fullyInside(content, contentList)) {
      return []
    }
    return contentList
  },

  resize({ addAll, getItem }, content, resizeAmount) {
    const { highGroup, lowGroup } = this.getResizeGroups({ getItem }, content)
    const actualResizeValue = this.getResizeAmount(resizeAmount, highGroup, lowGroup)

    each(highGroup, (c) => {
      c[this.oppositeSpan] += actualResizeValue
    })
    each(lowGroup, (c) => {
      c[this.oppositeDirection] += actualResizeValue
      c[this.oppositeSpan] -= actualResizeValue
    })

    addAll(highGroup.concat(lowGroup))
    return actualResizeValue
  },

  getResizeAmount(resizeAmount, highGroup, lowGroup) {
    const shrinkingGroup = resizeAmount > 0 ? lowGroup : highGroup
    const minSize = minBy(shrinkingGroup, this.oppositeSpan)[this.oppositeSpan]
    const largestMagnitudeResize = resizeAmount > 0 ? minSize - MIN_GRID_SIZE : -(minSize - MIN_GRID_SIZE)
    return minSize - Math.abs(resizeAmount) >= MIN_GRID_SIZE ? resizeAmount : largestMagnitudeResize
  },

  onEdge(contents) {
    if (!some(contents)) {
      return false
    }
    return every(
      contents,
      (content) => content[this.direction] === 0 || this.lastDirectionFnc(content) === this.directionMax - 1,
    )
  },
}

const ColumnAdjustment = extend(Adjustments, {
  direction: 'row',
  span: 'rowSpan',
  lastDirectionFnc: bottomRow,
  directionMax: ROWS,
  oppositeDirection: 'column',
  oppositeSpan: 'colSpan',
  getResizeGroups(grid, content) {
    const groupLeft = [content]
    const contentDirectlyRight = grid.getItem(content.row, rightMostColumn(content) + 1)
    const groupRight = [contentDirectlyRight]

    let lowestContentToRight = contentDirectlyRight
    let lowestContentToLeft = content
    while (!alignedBottom(lowestContentToLeft, lowestContentToRight)) {
      if (isBelow(lowestContentToRight, lowestContentToLeft)) {
        lowestContentToLeft = grid.getItem(bottomRow(lowestContentToLeft) + 1, rightMostColumn(lowestContentToLeft))
        groupLeft.push(lowestContentToLeft)
      } else {
        lowestContentToRight = grid.getItem(bottomRow(lowestContentToRight) + 1, lowestContentToRight.column)
        groupRight.push(lowestContentToRight)
      }
    }

    let highestContentToRight = contentDirectlyRight
    let highestContentToLeft = content
    while (!alignedTop(highestContentToLeft, highestContentToRight)) {
      if (isAbove(highestContentToRight, highestContentToLeft)) {
        highestContentToLeft = grid.getItem(highestContentToLeft.row - 1, rightMostColumn(highestContentToLeft))
        groupLeft.push(highestContentToLeft)
      } else {
        highestContentToRight = grid.getItem(highestContentToRight.row - 1, highestContentToRight.column)
        groupRight.push(highestContentToRight)
      }
    }

    return { highGroup: groupLeft, lowGroup: groupRight }
  },
})

const RowAdjustment = extend(Adjustments, {
  direction: 'column',
  span: 'colSpan',
  lastDirectionFnc: rightMostColumn,
  directionMax: COLUMNS,
  oppositeDirection: 'row',
  oppositeSpan: 'rowSpan',
  getResizeGroups(grid, content) {
    const groupAbove = [content]
    const contentDirectlyBelow = grid.getItem(bottomRow(content) + 1, content.column)
    const groupBelow = [contentDirectlyBelow]

    let leftMostContentBelow = contentDirectlyBelow
    let leftMostContentAbove = content
    while (!alignedLeft(leftMostContentAbove, leftMostContentBelow)) {
      if (isToLeftOf(leftMostContentBelow, leftMostContentAbove)) {
        leftMostContentAbove = grid.getItem(bottomRow(leftMostContentAbove), leftMostContentAbove.column - 1)
        groupAbove.push(leftMostContentAbove)
      } else {
        leftMostContentBelow = grid.getItem(leftMostContentBelow.row, leftMostContentBelow.column - 1)
        groupBelow.push(leftMostContentBelow)
      }
    }

    let rightMostContentBelow = contentDirectlyBelow
    let rightMostContentAbove = content
    while (!alignedRight(rightMostContentAbove, rightMostContentBelow)) {
      if (isToRightOf(rightMostContentBelow, rightMostContentAbove)) {
        rightMostContentAbove = grid.getItem(
          bottomRow(rightMostContentAbove),
          rightMostColumn(rightMostContentAbove) + 1,
        )
        groupAbove.push(rightMostContentAbove)
      } else {
        rightMostContentBelow = grid.getItem(rightMostContentBelow.row, rightMostColumn(rightMostContentBelow) + 1)
        groupBelow.push(rightMostContentBelow)
      }
    }

    return { highGroup: groupAbove, lowGroup: groupBelow }
  },
})

const DoubleAdjustment = {
  fill(content, { contentsAbove, contentsToRight, contentsToLeft }) {
    const bottomRowInLeftContents = Math.max(contentsToLeft.map((c) => bottomRow(c))),
      fillWithLeftContents = bottomRow(content) === bottomRowInLeftContents

    if (fillWithLeftContents) {
      contentsAbove.forEach((c) => {
        c.column = content.column + content.colSpan
        c.colSpan -= content.colSpan
      })
      contentsToLeft.forEach((c) => (c.colSpan += content.colSpan))
    } else {
      contentsAbove.forEach((c) => (c.colSpan -= content.colSpan))
      contentsToRight.forEach((c) => {
        c.colSpan += content.colSpan
        c.column = content.column
      })
    }

    return contentsToRight.concat(contentsAbove)
  },
}
