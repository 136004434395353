<template>
  <div class="bg-primary-500 flex flex-grow rounded-xl">
    <h2 class="flex flex-grow items-center justify-center px-20 text-5xl font-bold text-white">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'TitleCardContent',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
