<template>
  <div v-if="isArchived" class="mx-auto mt-4 max-w-7xl px-3 md:px-6">
    <Alert v-if="!(isPublicCustomer || collection) && !(deprecatedByMessage || deprecatedByUrl)" type="notice">
      <p class="text-sm font-extrabold leading-6">
        {{ $t('archive_editor.has_been_archived') }}
        <a :href="Routes.search_path({ query: title })">{{ $t('archive_editor.search_similar') }}</a>
      </p>
      <i18n-t tag="p" keypath="archive_editor.contact_us" scope="global" class="pt-2 text-sm leading-6">
        <template #contactUsLink>
          <a :href="Routes.page_path('contact')">{{ $t('common.contact_us') }}</a>
        </template>
      </i18n-t>
    </Alert>
    <Alert v-if="deprecatedByUrl || deprecatedByMessage" type="notice">
      <a v-if="deprecatedByUrl" :href="deprecatedByUrl" class="text-sm font-extrabold leading-6">
        {{ $t('archive_editor.updated_available', { lesson_type: readableType }) }}
      </a>
      <p v-if="deprecatedByMessage" :class="{ 'pt-2': deprecatedByUrl }" class="text-sm leading-6">
        {{ deprecatedByMessage }}
      </p>
    </Alert>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import Alert from 'vue_features/shared/components/ui/Alert'
import { computed, inject } from 'vue'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'ArchiveBanner',
  components: { Alert },
  setup() {
    const root = inject('useRoot')()
    const { isArchived, deprecatedByUrl, deprecatedByMessage, title, lessonType } = useLessonPlanStore()
    const { collection } = useLessonPlanShowStore()
    const { isPublicCustomer } = useCurrentCustomerStore()
    const readableType = computed(() => root.$t(`readable_types.${lessonType.value}`))
    return {
      Routes,
      isArchived,
      deprecatedByUrl,
      deprecatedByMessage,
      title,
      collection,
      isPublicCustomer,
      readableType,
    }
  },
}
</script>
