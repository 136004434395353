<template>
  <Badge v-if="premium && isPublicCustomer" color="yellow" class="mt-0">
    {{ $t('common.subscription_required') }}
  </Badge>
</template>

<script>
import { Badge } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'LzContentPremiumBadge',
  components: { Badge },
  props: {
    premium: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { isPublicCustomer } = useCurrentCustomerStore()
    return { isPublicCustomer }
  },
}
</script>
