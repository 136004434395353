<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="my-4">
    <label>
      {{ $t('info_form.student_tools') }}
    </label>
    <AsyncContentLoader :loader="getAvailableTools">
      <div class="graphical-checkbox">
        <label v-for="tool in availableTools" :key="tool.id" class="graphical-checkbox__item">
          <input v-model="selectedTools" :value="tool.id" name="student_tools" type="checkbox" @change="setTools" />
          <div class="graphical-checkbox__image">
            <InlineSvg :path="iconPath(tool.name)" />
          </div>
          <div class="graphical-checkbox__label">
            <div class="graphical-checkbox__icon">
              <InlineSvg path="icons/check" />
            </div>
            {{ $t(`student_tools.label.${tool.name}`) }}
          </div>
        </label>
      </div>
    </AsyncContentLoader>
  </div>
</template>

<script>
import StudentToolsService from '../api/student_tools_service'
import { AsyncContentLoader, InlineSvg } from 'vue_features/shared/components/ui'

export default {
  name: 'StudentToolsSelector',
  components: { AsyncContentLoader, InlineSvg },
  props: {
    studentTools: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTools: this.studentTools.map((t) => t.id),
      availableTools: [],
    }
  },
  computed: {},
  methods: {
    iconPath(toolName) {
      return toolName ? `icons/${toolName.replace('_', '-')}-icon` : 'icons/student-tools-placeholder'
    },
    setTools() {
      this.$emit('set-tools', this.selectedTools)
    },
    getAvailableTools() {
      return StudentToolsService.getTools().then((tools) => (this.availableTools = tools))
    },
  },
}
</script>
