<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <ul class="ml-4">
    <li v-for="type in availableTypes" :key="type" class="my-4 cursor-pointer">
      <a @click="$emit('pick-type', type)">
        <LzIcon :path="`icons/${getIcon(type)}`" :size="iconSize" />
        <p class="my-0">
          <strong class="text-primary">
            {{ capitalize($t(getLabel(type))) }}
          </strong>
        </p>
        <p class="small text-gray italic">
          {{ $t(getHelpText(type)) }}
        </p>
      </a>
    </li>
  </ul>
</template>

<script>
import { capitalize } from 'lodash'
import useContentTypeMetadata from 'vue_features/shared/composables/use_content_type_metadata'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'CardTypePicker',
  components: { LzIcon },
  props: {
    availableTypes: { type: Array, default: () => [] },
    iconSize: { type: String, default: 'xl' },
  },
  setup() {
    const { getIcon, getLabel, getHelpText } = useContentTypeMetadata()
    return {
      capitalize,
      getIcon,
      getLabel,
      getHelpText,
    }
  },
}
</script>
