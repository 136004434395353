<template>
  <div data-test="google-classroom-course-dropdown" class="block text-right">
    <ListDropdown
      v-if="hasLinkedAccount"
      id="google-classroom-assigned-dropdown"
      data-test="google-classroom-course-dropdown"
      class="block"
      :disabled="disabled"
      :lazy-list="true"
      :button-classes="['btn--small', 'mb-0']"
      :dropdown-classes="['flow-left max-h-64 overflow-y-scroll google-course-dropdown']"
      @dropdown-toggled="$event ? loadCourses() : undefined"
    >
      <template #button>
        <span :class="['flex items-center gap-2', { 'opacity-50': disabled }]">
          <LzIcon path="icons/google-signin" />
          <template v-if="klass.googleClassroomCourse">
            <span v-if="klass.googleClassroomCourse.archived" class="text-gray-500">
              {{ klass.googleClassroomCourse.name }} ({{ $t('common.archived') }})
            </span>
            <span v-else class="google-blue">{{ klass.googleClassroomCourse.name }}</span>
          </template>
          <span v-else>{{ $t('klasses.google_classroom.select_course') }}</span>
          <LzIcon size="sm" path="icons/caret" />
        </span>
      </template>
      <template #listItems>
        <template v-if="coursesData && !coursesLoading">
          <li v-for="courseData in coursesData" :key="courseData.courseId">
            <button class="py-0 py-2" type="button" :title="courseData.name" @click="linkCourse(courseData.courseId)">
              <span
                v-if="klass.googleClassroomCourse && courseData.courseId == klass.googleClassroomCourse.courseId"
                class="google-blue flex items-center gap-2"
              >
                <LzIcon size="sm" path="icons/check" />
                {{ courseData.name }}
                <template v-if="courseData.archived"> ({{ $t('common.archived') }}) </template>
              </span>
              <span v-else class="text-gray-500">
                <LzIcon v-if="courseData.klassId" size="sm" path="icons/class-linked" />
                {{ courseData.name }}
                <template v-if="courseData.archived"> ({{ $t('common.archived') }}) </template>
              </span>
            </button>
          </li>
          <li>
            <button class="my-0 py-2" type="button" :title="$t('common.google_classroom.unlink')" @click="unlinkCourse">
              <span class="google-unlink text-red-500">
                {{ $t('common.google_classroom.unlink') }}
              </span>
            </button>
          </li>
        </template>
        <template v-else-if="coursesLoading">
          <li class="p-4"><LoadingSpinner /></li>
        </template>
      </template>
    </ListDropdown>
  </div>
</template>

<script>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import { useKlassShowStore } from '../store'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'GoogleCourseDropdown',
  components: {
    ListDropdown,
    LoadingSpinner,
    LzIcon,
  },
  props: {
    klass: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { associateGoogleCourseToKlass, disassociateGoogleCourseFromKlass } = useKlassShowStore()
    const { hasLinkedAccount, coursesData, coursesLoading, loadCourses } = useGoogleClassroomStore()
    return {
      associateGoogleCourseToKlass,
      disassociateGoogleCourseFromKlass,
      hasLinkedAccount,
      coursesData,
      coursesLoading,
      loadCourses,
    }
  },
  methods: {
    linkCourse(courseId) {
      this.$emit('link-google-course', courseId)
    },
    async unlinkCourse() {
      await this.disassociateGoogleCourseFromKlass({ klass: this.klass })
      await this.loadCourses({ force: true })
    },
  },
}
</script>
