<template>
  <div class="update-notif flex w-full px-4 py-2">
    <div>
      <LzIcon size="xl" path="icons/user" />
      <p class="small inline text-blue-700">{{ formattedWho(who, $t) }}</p>
    </div>
    <p class="smallest mb-0 text-blue-700">
      {{ creationVerb || $t(actionVerb(event)) }} {{ $t('common.on').toLocaleLowerCase() }}
      {{ formattedWhen(createdAt) }}
    </p>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import { merge } from 'lodash'
import { common, shared } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { formattedWhen, actionVerb, formattedWho } from 'vue_features/shared/helpers/papertrail_helper'

const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: 'on' }),
  namespaceLocaleObj(shared, 'updated_notification', { only: ['created', 'edited'] }),
)

export default {
  name: 'UpdatedNotification',
  components: { LzIcon },
  i18n: { messages },
  props: {
    who: { type: String, default: null },
    event: { type: String, default: null },
    createdAt: { type: String, default: null },
    creationVerb: { type: String, default: null },
  },
  setup() {
    return { actionVerb, formattedWhen, formattedWho }
  },
}
</script>
