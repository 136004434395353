<template>
  <LzConfirmModal :modal-id="modalId" :modal-title="$t('remove_collection')" class="lzui-modal--large">
    <template #confirmBody>
      <p v-if="isSharedCollection" class="my-2">
        {{ $t('collection_removal_explanation') }}
      </p>
      <p v-else class="my-2">
        {{ $t('collection_deletion_explanation') }}
      </p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution" @click="destroy">
        {{ isSharedCollection ? $t('common.remove') : $t('common.delete') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { useCollectionsIndexStore } from '../../store/use_collections_index_store'
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'DeleteCollectionModal',
  components: { LzConfirmModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { deleteCollection, isProgressCoverShown } = useCollectionsIndexStore()
    return { deleteCollection, isProgressCoverShown, closeModal }
  },
  computed: {
    isSharedCollection() {
      return !!this.collection.customerShare
    },
  },
  methods: {
    destroy() {
      this.isProgressCoverShown = true
      this.closeModal(this.modalId)
      const hideCover = () => (this.isProgressCoverShown = false)
      this.deleteCollection(this.collection).then(() => {
        this.$emit('deleted-collection')
        hideCover()
      }, hideCover.bind(this))
    },
  },
}
</script>
