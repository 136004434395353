import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { unref } from 'vue'

export default {
  updateAssignmentView(assignmentId, assignmentViewId, lzCodeViewParams) {
    const path = Routes.lz_code_lz_code_view_path(unref(assignmentId), unref(assignmentViewId))
    const params = { lzCodeView: lzCodeViewParams }
    return HTTP.put(path, snakeKeys(params))
  },
}
