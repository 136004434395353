<template>
  <div :style="{ backgroundImage: `url(${backgroundImage})` }" class="min-h-screen bg-cover bg-no-repeat p-8 md:pt-16">
    <div class="m-auto max-w-2xl rounded-xl bg-white px-8 py-4 shadow-2xl">
      <Banner v-if="isPublic" type="inverted" data-test="public-session">
        <i18n-t keypath="helpers.authentication.free_accounts_retired" scope="global">
          <template #loginLink>
            <a :href="Routes.customer_selects_path()">{{ $t('helpers.authentication.log_in') }}</a>
          </template>
        </i18n-t>
      </Banner>
      <div class="mx-auto max-w-7xl">
        <div class="greeting w-full text-center">
          <h1 class="p-6">
            {{ greetingLabel }}
          </h1>
          <h4>
            {{ message }}
          </h4>
        </div>
        <Login :previously-activated="previouslyActivated" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, watch } from 'vue'
import Login from 'vue_features/shared/components/login/Login'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'
import Banner from 'vue_features/shared/components/ui/Banner'
import { useRoute } from 'vue-router'

export default {
  name: 'SessionsNew',
  components: { Banner, Login },
  props: {
    message: {
      type: String,
      default: '',
    },
    previouslyActivated: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const root = inject('useRoot')()
    const { isPublic, name: customerName } = useCurrentCustomerStore()
    const greetingLabel = computed(() => {
      if (!isPublic.value && customerName.value) {
        return root.$t('helpers.authentication.welcome_premium', { customer_name: customerName.value })
      }
      return root.$t('helpers.authentication.welcome')
    })
    const isTeacher = ref(false)
    const backgroundImage = computed(() =>
      isTeacher.value ? require('images/misc/hero-teacher.jpg') : require('images/misc/hero-student.jpg'),
    )
    watch(
      () => useRoute()?.name,
      (val) => {
        isTeacher.value = val === 'teacher-login'
      },
      { immediate: true },
    )
    return { Routes, isPublic, greetingLabel, backgroundImage, isTeacher }
  },
}
</script>
