import { HTTP } from 'vue_features/shared/helpers/http_helper'
import Routes from 'routes'
import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { isRevision as isRevisionState } from 'vue_features/shared/composables/use_record_states'

export const useResourceShowStore = defineStore('resourceShow', () => {
  const state = reactive({
    actionMenuProps: {},
    resource: {},
    breadcrumbs: [],
    collection: {},
    isCollectionMapAvailable: null,
    includedLessons: [],
    cardTitle: null,
    isGated: null,
    originalId: null,
    bookmarkId: null,
  })

  const loadIncludedLessons = async () => {
    const loadId = state.originalId || state.resource.id
    state.includedLessons = await HTTP.getJSON(Routes.lesson_plans_resource_path(loadId))
  }

  const isRevision = computed(() => isRevisionState(state.resource.state))

  return { state, isRevision, loadIncludedLessons }
})
