<template>
  <div ref="lessonPlayerElement" class="h-full w-full">
    <div class="relative z-50 overflow-hidden">
      <div ref="scrollManagerRoot" class="h-full max-h-screen overflow-y-auto">
        <div class="assignment-deck-container z-40 w-full overflow-auto rounded-2xl pb-24">
          <LearnosityRerenderer :render-on-mount="true" @on-rerender="connectScrollHandling">
            <div class="lesson-content min-h-96 flex w-full flex-row print:block">
              <LessonContainer
                :cards="cards"
                :selected-card-index="selectedCardIndex"
                :is-preview="isPreview"
                @on-card-selected="scrollToSelectedCard"
                @exit-player="$emit('exit-player')"
              />
            </div>
          </LearnosityRerenderer>
        </div>
      </div>
    </div>
    <div
      class="absolute right-0 z-50 flex w-full items-center justify-center px-2 print:hidden md:px-20"
      :class="{
        'bottom-2': showToolbar,
        '-bottom-18': !showToolbar,
      }"
    >
      <LessonToolbar
        v-if="cards.length > 0"
        class="w-full print:hidden"
        :cards="cards"
        :show-toolbar="showToolbar"
        :show-sidebar-button="false"
        :show-tools-button="false"
        :show-fullscreen-button="false"
        :show-save-and-exit-button="false"
        :selected-card-index="selectedCardIndex"
        @toggle-toolbar="toggleToolbar"
        @card-selected="scrollToSelectedCard"
        @go-previous="goPrevious"
        @go-next="goNext"
        @exit-player="$emit('exit-player')"
      />
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted } from 'vue'
import LearnosityRerenderer from 'vue_features/resources/learnosity_items/components/LearnosityRerenderer'
import LessonContainer from 'vue_features/lesson_plans/show/components/LessonContainer'
import LessonToolbar from 'vue_features/lesson_plans/show/components/LessonToolbar'
import useAssignmentCardsSecondaryDeck from 'vue_features/assignments/show/composables/use_assignment_cards_secondary_deck'

export default {
  name: 'AssignmentCardsTrayDeck',
  components: {
    LessonToolbar,
    LessonContainer,
    LearnosityRerenderer,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const lessonPlayerElement = ref(null)
    const scrollManagerRoot = ref(null)

    const { activateKeyboardNavigation, ...otherProps } = useAssignmentCardsSecondaryDeck({
      lessonPlayerElementRef: lessonPlayerElement,
      scrollManagerRootRef: scrollManagerRoot,
      cardsRef: toRef(props, 'cards'),
    })

    onMounted(() => {
      lessonPlayerElement.value.addEventListener('click', () => {
        activateKeyboardNavigation()
      })
    })

    return {
      ...otherProps,
      activateKeyboardNavigation,
      lessonPlayerElement,
      scrollManagerRoot,
    }
  },
}
</script>
