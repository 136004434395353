<template>
  <a
    :href="result.url"
    data-test="search-result"
    class="bg-focus group overflow-hidden rounded-2xl border shadow transition-all hover:shadow-lg"
  >
    <div class="flex items-stretch md:flex-row">
      <div class="relative h-auto min-h-full w-1/4">
        <div class="relative min-h-full w-full overflow-hidden">
          <div
            :style="thumbnailBackgroundStyle"
            class="absolute left-0 top-0 h-full w-full transform bg-cover bg-center transition-transform duration-700 group-hover:scale-110"
          >
          </div>
        </div>
        <div
          class="bg-focus absolute -right-5 top-4 flex items-center justify-center overflow-hidden rounded-xl border-4 border-white"
        >
          <InlineSvg :path="`icons/${getIcon(result.resultType)}`" class="h-8 w-8" />
        </div>
      </div>
      <div class="flex min-w-0 flex-1 flex-col justify-between gap-4 pb-4 pl-10 pr-4 pt-6">
        <div class="flex min-w-0 flex-row justify-between space-x-4">
          <p
            class="text-accent group-hover:text-accent-hover mb-0 max-h-12 flex-1 overflow-hidden font-bold md:max-h-16 md:text-xl"
          >
            {{ resultTitle }}
          </p>
          <Badge
            v-if="!isActive(result)"
            :data-test="`result-${result.state}`"
            :color="isArchived(result) ? 'pink' : 'base'"
          >
            {{ $t(`common.${result.state}`) }}
          </Badge>
        </div>
        <div class="flex min-w-0 flex-row justify-between space-x-4">
          <div class="flex min-w-0 flex-1 items-center space-x-1">
            <img v-if="result.logo" :src="result.logo" data-test="result-logo" alt="" class="inline-block max-h-4" />
            <p class="text-neutral-700 mb-0 truncate py-1 text-xs font-bold">{{ result.customer }}</p>
          </div>
          <Badge v-if="result.premium && isPublic" data-test="result-premium" color="yellow">
            {{ $t('common.subscription') }}
          </Badge>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { computed } from 'vue'
import { elementFromString } from 'utils'
import useThumbnailUrl from 'vue_features/shared/composables/use_thumbnail_url'
import { Badge, InlineSvg } from 'vue_features/shared/components/ui'
import { getIcon } from 'vue_features/shared/composables/use_content_type_metadata'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { isActive, isDraft, isArchived } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'Result',
  components: { Badge, InlineSvg },
  props: { result: { type: Object, required: true } },
  setup(props) {
    const { isPublic } = useCurrentCustomerStore()
    const { thumbnailUrl } = useThumbnailUrl(props.result.thumbnail, props.result.resultType)
    const resultTitle = computed(() => elementFromString(props.result.name).textContent)
    const thumbnailBackgroundStyle = computed(() =>
      thumbnailUrl.value
        ? `background-image: url(${thumbnailUrl.value}), linear-gradient(white 75%, rgba(var(--neutral-200), .75))`
        : '',
    )

    return { getIcon, isPublic, thumbnailBackgroundStyle, resultTitle, isDraft, isArchived, isActive }
  },
}
</script>
