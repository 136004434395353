import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'
import { toInteger } from 'lodash'

export const useWhoViewedThisStore = defineStore('whoViewedThis', () => {
  const state = reactive({
    assignmentId: null,
    studentActivities: [],
    pagination: { currentPage: null, totalPages: null },
  })

  async function getStudentActivities(page) {
    setPagination({ currentPage: toInteger(page) })
    const response = await HTTP.getJSONEx(Routes.lz_code_lz_code_views_path(state.assignmentId, { page }))
    const responseData = await response.json()
    state.studentActivities = responseData.studentActivities
    const totalPages = toInteger(response.headers.get('X-Pagination-Total-Pages'))
    if (totalPages) setPagination({ totalPages })
  }

  function setPagination(pagination) {
    state.pagination = {
      ...state.pagination,
      ...pagination,
    }
  }

  const myStudents = computed(() => state.studentActivities)

  return {
    state,
    getStudentActivities,
    myStudents,
    setPagination,
  }
})
