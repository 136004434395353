<template>
  <LzModal v-slot="{ close }" :modal-id="$options.name" class="delete-lms-integrations-modal" autoclose>
    <section>
      <h2 class="my-2">{{ $t('remove_integration_prompt') }}</h2>
      <footer>
        <button class="btn" @click="close">{{ $t('nope') }}</button>
        <form :action="Routes.site_admin_lti_v13_integration_path(lmsIntegrationId)" class="button_to" method="post">
          <input type="hidden" name="_method" value="delete" />
          <input :value="$t('yeah')" class="btn btn--primary caution" type="submit" />
          <input :value="authenticityToken" type="hidden" name="authenticity_token" />
        </form>
      </footer>
    </section>
  </LzModal>
</template>

<script>
import { LzModal } from 'vue_features/shared/components/ui'
import Routes from 'routes'

export default {
  name: 'DeleteLtiV13IntegrationModal',
  components: {
    LzModal,
  },
  props: {
    lmsIntegrationId: {
      type: Number,
      required: true,
    },
    authenticityToken: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
}
</script>
