<template>
  <div class="empty-state empty-state--component">
    <InlineSvg path="misc/placeholder-lesson" />
    <p>{{ $t('lesson_plans.lesson_plan.main_content_help', { lesson_type: $t(`readable_types.${lessonType}`) }) }}</p>
    <button class="btn btn--primary" @click="addCard">
      <LzIcon path="icons/add" />
      {{ $t('lesson_plans.lesson_plan.add_first_card') }}
    </button>
  </div>
</template>

<script>
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { InlineSvg, LzIcon } from 'vue_features/shared/components/ui'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'CardDeckBlankSlate',
  components: { LzIcon, InlineSvg },
  setup() {
    const { lessonType } = useLessonPlanStore()
    const { addCard } = useCardDeckEditorStore()
    return { addCard, lessonType }
  },
}
</script>
