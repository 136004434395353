<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <FilestackUpload
    v-slot="{ launch, baseUrl, filename, fileUrl, fileExists, fileDropContainer }"
    v-bind="{ file, accept, maxSize, showDropPane: true }"
    class="file-upload my-4"
    @upload-done="updateFile"
  >
    <div class="space-y-4">
      <h5 class="card-editor--hide">{{ $t(`${resource.type.toLowerCase()}s.form_template.label_upload`) }}</h5>
      <div v-if="fileExists" class="flex justify-center space-x-2">
        <LzButton class="file-upload__buttons" @click="updateFile({ baseUrl: '' })">
          {{ $t('activerecord_attributes.resource.remove') }}
        </LzButton>
        <LzButton v-bypass-unload-confirmation tag="a" :href="fileUrl" class="truncate">{{
          filename || $t('common.download')
        }}</LzButton>
      </div>
      <div class="file-upload__buttons">
        <input :value="baseUrl" :name="`${snakeCase(resource.type)}[source_file_url]`" type="hidden" />
        <LzButton class="file-upload__buttons" @click.prevent="launch">
          {{
            fileExists
              ? $t('activerecord_attributes.resource.replace_file')
              : $t('activerecord_attributes.resource.select_file')
          }}
        </LzButton>
        <div :id="fileDropContainer" class="file-drop-pane" style="display: inline" />
      </div>
      <p class="help-block" style="display: inline-block">
        {{ $t('resources.filestack_upload_template.help') }}
        {{ accept.join(', ') + '.' }}
        <a href="/wikis/2056345-how-to-resize-files" target="_blank">
          {{ $t('resources.filesize_tips') }}
        </a>
      </p>
    </div>
  </FilestackUpload>
</template>

<script>
import { config } from 'utils'
import { LzButton } from 'vue_features/shared/components/ui'
import { bypassUnloadConfirmation } from 'vue_features/shared/directives'
import FilestackUpload from 'vue_features/shared/components/filestack_upload/FilestackUpload'
import { snakeCase } from 'lodash'

export default {
  name: 'ResourceUploader',
  components: { FilestackUpload, LzButton },
  directives: {
    bypassUnloadConfirmation,
  },
  props: { resource: { type: Object, required: true } },
  data() {
    const conf = config[this.resource.type.toLowerCase()]
    return {
      file: this.resource.resourceUrl || {},
      accept: conf.accept,
      maxSize: conf.maxSize,
    }
  },
  methods: {
    updateFile(file) {
      this.$emit('update', { file })
      this.file = file
    },
    snakeCase,
  },
}
</script>
