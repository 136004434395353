// Any changes to this file should also be reflected in _javascript_head.html.erb

export let LEARNOSITY_API_VERSION = 'v2023.3.LTS'
export let GOOGLE_RECAPTCHA_SITE_KEY = ''
export let DATADOG_RUM = {}
export let STATIC_HOST_URL = 'learnzillioncdn.com'
export let ZENDESK_BASE_URL = ''
export let CLEVER = { authenticatedUrl: 'https://clever.com/oauth/authenticated', clientId: 'd5772d6657ef4cfef3ec' }
export let MATHJAX_ASSET_URL =
  'https://static.learnzillioncdn.com/mathjax/2.7.1/MathJax.js?config=TeX-AMS-MML_HTMLorMML'
export let SHORT_DOMAIN = 'lzill.co'

export function initializeEnv(env) {
  LEARNOSITY_API_VERSION = env['LEARNOSITY_API_VERSION']
  GOOGLE_RECAPTCHA_SITE_KEY = env['GOOGLE_RECAPTCHA_SITE_KEY']
  DATADOG_RUM = env['DATADOG_RUM']
  STATIC_HOST_URL = env['STATIC_HOST_URL']
  ZENDESK_BASE_URL = env['ZENDESK_BASE_URL']
  SHORT_DOMAIN = env['SHORT_DOMAIN']
  CLEVER = env['CLEVER']
  MATHJAX_ASSET_URL = env['MATHJAX_ASSET_URL']
}
