import { ref, computed } from 'vue'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export const useCardNav = function () {
  const selectedCardIndex = ref(-1)
  const showSidebar = ref(false)
  const notesActive = ref(true)

  const { isStudent } = useCurrentUserStore()
  const { isPresenting } = useLessonPlanShowStore()

  const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
  }

  const toggleNotes = () => {
    notesActive.value = !notesActive.value
  }

  const allowNotes = computed(() => !isStudent.value && !isPresenting.value)

  const showNotes = computed(() => notesActive.value && allowNotes.value)

  const showToolbar = ref(true)

  const toggleToolbar = () => {
    return (showToolbar.value = !showToolbar.value)
  }

  return {
    selectedCardIndex,
    showSidebar,
    notesActive,
    toggleSidebar,
    toggleNotes,
    allowNotes,
    showNotes,
    showToolbar,
    toggleToolbar,
  }
}
