<template>
  <div ref="sidebarEl" class="collection-page__sidebar animate-push-right z-100 max-w-96 mr-12 w-96 flex-none bg-white">
    <div id="collection-map" class="collection-page__sidebar-inner" :aria-hidden="!isOpen">
      <div class="collection__title">
        <h4>{{ $t('shared.collection_map.collection') }}</h4>
        <button class="float-right text-base" @click="collapse">
          <span class="sr-only">{{ $t('common.close') }}</span>
          <LzIcon path="icons/close" />
        </button>
      </div>
      <template v-if="isOpen">
        <LoadingSpinner v-if="isLoading" :inline="true" />
        <ul v-else class="tree-list">
          <li v-if="canAddParent">
            <button class="smaller text-accent hover:text-accent-hover cursor-pointer" @click="addParent">
              <LzIcon size="sm" class="mt-1 inline-block" path="icons/add" />
              {{ $t('shared.collection_map.load_up') }}
            </button>
          </li>
          <ContentTreeNode :block="parentBlock" />
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import ContentTreeNode from './ContentTreeNode'
import { OPEN_COOKIE, useCollectionMapStore } from '../composables/use_collection_map_store'
import { computed, ref, watch } from 'vue'
import Cookies from 'js-cookie'

export default {
  name: 'CollectionMap',
  components: { ContentTreeNode, LoadingSpinner, LzIcon },
  setup() {
    const { rootId, parentId, parentBlock, loadChild, addParent, isOpen } = useCollectionMapStore()
    const isLoading = ref(true)
    const sidebarEl = ref(null)

    const load = async function () {
      isLoading.value = true
      await loadChild(parentId.value)
      isLoading.value = false
    }
    watch(
      isOpen,
      (isOpen) => {
        if (isOpen) {
          load()
        }
      },
      { immediate: true },
    )

    const canAddParent = computed(() => {
      return parentId.value !== rootId.value
    })
    watch(parentId, load)

    const collapse = () => {
      sidebarEl.value.classList.remove('animate-push-right')
      document.body.classList.remove('menu-push')
      Cookies.set(OPEN_COOKIE, false)
      Cookies.set('sidebar_open', false)
      isOpen.value = false
    }

    return { sidebarEl, collapse, parentBlock, isLoading, canAddParent, addParent, isOpen }
  },
}
</script>
