<template>
  <div class="space-y-8">
    <div v-if="loading">
      <LoadingTable :rows="skeletonRows" :columns="3" />
    </div>
    <div v-else-if="error">
      <Alert type="error" :title="$t('error_loading_data_header')" :description="$t('error_loading_data_message')" />
    </div>
    <div v-else-if="data" class="space-y-8">
      <template v-if="data.groupedByKlass">
        <div
          v-for="(klassPerformanceAndUsageData, index) in data.groups"
          :key="klassPerformanceAndUsageData.groupId"
          :data-test="`performance-group-${klassPerformanceAndUsageData.groupId}`"
        >
          <LzAccordion :label="klassPerformanceAndUsageData.groupName" :open="index === 0" class="overflow-hidden">
            <template #head="{ label, toggle, isOpen }">
              <div class="relative p-4">
                <div
                  class="rating-indicator rating-indicator--left"
                  :class="groupRating(klassPerformanceAndUsageData)"
                ></div>
                <button class="flex cursor-pointer items-center space-x-2 text-blue-700" :title="label" @click="toggle">
                  <InlineSvg
                    path="icons/next"
                    class="h-6 w-6 transform transition-all"
                    :class="{ 'rotate-90': isOpen }"
                  />

                  <span class="flex items-center space-x-2">
                    <span class="text-lg font-bold">{{ label }}</span>
                    <span
                      v-if="!klassPerformanceAndUsageData.groupActive"
                      class="inline-flex items-center space-x-1 rounded-full bg-gray-500 px-2 py-1 text-sm text-white"
                    >
                      <LzIcon path="icons/archive" />
                      <span>Archived</span>
                    </span>
                  </span>
                </button>
              </div>
            </template>

            <div class="py-4">
              <PerformanceAndUsageTableContent :data="klassPerformanceAndUsageData" @row-click="handleRowClick" />
            </div>
          </LzAccordion>
        </div>
      </template>

      <template v-else>
        <PerformanceAndUsageTableContent
          v-for="(ungroupedPerformanceAndUsageData, index) in data.groups"
          :key="index"
          :data="ungroupedPerformanceAndUsageData"
          @row-click="handleRowClick"
        />
      </template>
    </div>

    <div v-if="noCleverGradeLevels">
      <Notice :title="$t('clever_notice_title')" icon="icons/synced-arrows">
        {{ $t('clever_notice_body') }}
      </Notice>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import LoadingTable from './LoadingTable'
import PerformanceAndUsageTableContent from './PerformanceAndUsageTableContent'
import { sortCategoryName } from '../utils'
import { Alert, LzAccordion, LzIcon, InlineSvg } from 'vue_features/shared/components/ui'
import Notice from './shared/Notice'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { useDataDashboardStore } from '../composables/data_dashboard_store'
import { ratingForScore } from 'vue_features/shared/utils/scoring'
import useSkeletonRows from '../composables/use_skeleton_rows'
import { useRouter } from 'vue-router'

export default {
  name: 'PerformanceAndUsageTable',
  components: {
    Alert,
    LoadingTable,
    Notice,
    LzAccordion,
    InlineSvg,
    LzIcon,
    PerformanceAndUsageTableContent,
  },
  setup() {
    const {
      updateFilters,
      filters,
      performanceAndUsageTableData,
      performanceAndUsageTableStatus,
      noCleverGradeLevels,
    } = useDataDashboardStore()

    const router = useRouter()

    const handleRowClick = (row) => {
      if (filters.value.student) {
        window.open(Routes.lz_code_path({ id: row.categoryId }))
      } else {
        updateFilters(router, { [performanceAndUsageTableData.value.drillDownCategory]: row.categoryId })
      }
    }

    const loading = computed(() => performanceAndUsageTableStatus.value.loading)
    const error = computed(() => performanceAndUsageTableStatus.value.error)
    const skeletonRows = useSkeletonRows(performanceAndUsageTableData, { rowsPath: 'groups.0.rows' })

    const groupRating = (group) => {
      const rating = ratingForScore(group.groupAverageScore)
      return `rating-indicator--${rating}`
    }

    return {
      loading,
      error,
      data: performanceAndUsageTableData,
      skeletonRows,
      handleRowClick,
      sortCategoryName,
      noCleverGradeLevels,
      groupRating,
    }
  },
}
</script>
