<template>
  <div>
    <section class="border-base bg-focus mt-12 border-b border-t">
      <div class="mx-auto max-w-7xl px-3 pb-6 md:px-6">
        <h2 class="h3 red mb-0">{{ $t('show.delete_customer.header') }}</h2>
        <p>{{ $t('show.delete_customer.info') }}</p>
        <DeleteCustomerModal v-if="canModalShow($options.DeleteCustomerModal)" :customer="customer" />
        <button class="btn btn--small caution" @click="confirmDelete">
          {{ $t('common.delete') }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import DeleteCustomerModal from './DeleteCustomerModal'
import { canModalShow, openModal, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useSiteAdminCustomerStore } from '../store/use_site_admin_customer_store'

export default {
  name: 'DeleteCustomerSection',
  DeleteCustomerModal,
  components: { DeleteCustomerModal },
  setup() {
    const { customer } = useSiteAdminCustomerStore()
    return { customer, canModalShow, openModal, closeModal }
  },
  methods: {
    cancelDelete() {
      this.closeModal(this.$options.DeleteCustomerModal)
    },
    confirmDelete() {
      this.openModal(this.$options.DeleteCustomerModal)
    },
  },
}
</script>
