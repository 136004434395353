<template>
  <component :is="type" ref="element" class="ck-editable ck-editor-on-demand"></component>
</template>

<script>
import Ckeditor from 'ckeditor_extensions/custom_ckeditor'
import fixToolbarHiding from 'ckeditor_extensions/fix_toolbar_hiding'
import { throttle } from 'lodash'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const preserved = ref(null)

export default {
  name: 'CkeditorOnDemand',
  props: {
    options: { type: Object, required: true },
    content: { type: String, required: true },
    type: { type: String, default: 'textarea' },
  },
  setup(props, { emit }) {
    const element = ref(null)
    const editor = ref(null)

    const hasEditor = computed(() => !!editor.value)
    const hasMathJax = computed(() => props.content.includes('math-tex'))
    const isEditorPreserved = computed(() => preserved.value === editor.value)

    function destroyEditor() {
      if (!hasEditor.value) {
        return
      }

      emit('change', editor.value.getData())

      if (hasMathJax.value) {
        editor.value.lzOnDemand = false
        return
      }

      Ckeditor.destroy(editor.value)
      editor.value = null

      fixToolbarHiding(props.options.options)
    }

    function releaseEditor() {
      preserved.value = null
      destroyEditor()
    }

    function createEditor(onDemand) {
      if (preserved.value !== null) {
        return
      }

      if (hasEditor.value) {
        return
      }

      editor.value = Ckeditor.inline(...[element.value, props.options.toolbar, props.options.options])
      editor.value.setData(props.content || '<p></p>')
      editor.value.emitVueEvent = emit

      emit('created-editor', editor.value)
      editor.value.on(
        'change',
        throttle(
          () => {
            emit('change', editor.value.getData())
          },
          500,
          { trailing: true },
        ),
      )
      editor.value.on('instanceReady', () => {
        if (isEditorPreserved.value) {
          const range = editor.value.createRange()
          range.moveToElementEditEnd(range.root)
          editor.value.getSelection().selectRanges([range])
        }
      })
      editor.value.on('blur', releaseEditor)
      editor.value.release = releaseEditor
      editor.value.lzOnDemand = onDemand
    }

    function preserveEditor() {
      if (editor.value && preserved.value && preserved.value === editor.value) {
        return
      }

      if (preserved.value) {
        preserved.value.release()
      }

      createEditor(true)

      preserved.value = editor.value
    }

    onMounted(() => {
      if (!hasMathJax.value) {
        // set innerHTML one time vs binding that would try to update w/ changes
        if (props.content.length > 0) {
          element.value.innerHTML = props.content
        }
        element.value.addEventListener('focus', preserveEditor)
      } else {
        // mathjax editors do not work well "on demand", so just create
        createEditor(false)
      }
    })

    onBeforeUnmount(() => {
      if (editor.value) {
        Ckeditor.destroy(editor.value)
        fixToolbarHiding(props.options.options)
      }
    })

    return { element }
  },
}
</script>

<style scoped>
.ck-editor-on-demand {
  @apply p-2;
}
.ck-editor-on-demand:empty::after {
  content: 'Empty';
  font-size: 1.53em;
  line-height: 1.4;
  color: transparent;
}
</style>
