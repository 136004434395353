<template>
  <div>
    <section>
      <p>{{ $t('add_student_to_klass_modal.share_message') }}</p>
      <i18n-t tag="p" keypath="add_student_to_klass_modal.share_note" scope="global" class="mt-2">
        <template #contactUsLink>
          <a href="/contact">{{ $t('add_student_to_klass_modal.contact_us') }}</a>
        </template>
      </i18n-t>
      <div class="lzui-tile mb-6 mt-4">
        <div class="modal-table">
          <table class="table-opened condensed-list checkbox-list mb-0">
            <tr v-for="student in students" :key="student.id" class="detail-item">
              <td class="no-fullstory">
                <div class="detail-item__title">{{ student.name }}</div>
              </td>
              <td class="detail-item__metadata no-fullstory">
                <strong>{{ $t('common.label.username') }}</strong>
                {{ student.username }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>
    <footer>
      <button class="btn" @click="$emit('close')">
        {{
          hasClever
            ? $t('add_student_to_klass_modal.close_label')
            : $t('add_student_to_klass_modal.download_later_label')
        }}
      </button>
      <button v-if="!hasClever" class="btn btn--primary ml-2" @click="printEnrollmentCodes">
        {{ $t('add_student_to_klass_modal.download_label') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { location } from 'utils'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'UsernameSharePrompt',
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { hasClever } = useCurrentCustomerStore()
    return { hasClever }
  },
  methods: {
    printEnrollmentCodes() {
      window.open(location.current() + '/rosterview')
    },
  },
}
</script>
