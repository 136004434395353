<template>
  <div class="page-head-nav mx-auto max-w-7xl">
    <LzTabs v-if="isInstructor" :links="links" data-test="tabs" />
    <div class="page-content">
      <RouterView v-slot="{ Component }">
        <KeepAlive>
          <Component :is="Component" />
        </KeepAlive>
      </RouterView>
    </div>
  </div>
</template>

<script>
import { LzTabs } from 'vue_features/shared/components/ui'
import { useAssignmentShowStore } from 'vue_features/assignments/show/composables'
import { location } from 'utils'

export default {
  components: { LzTabs },
  setup() {
    const { assignmentEventJson, isInstructor } = useAssignmentShowStore()
    return {
      isInstructor,
      assignmentEventJson,
    }
  },
  computed: {
    links() {
      return [
        {
          name: 'report',
          label: this.$t('show.student_submissions'),
          eventName: 'Assignment Reports Viewed',
          eventProperties: this.assignmentEventJson,
          default: true,
          hash: location.hash() ?? '',
        },
        { name: 'lesson', label: this.$t('show.what_students_see') },
        {
          name: 'who-viewed-this',
          label: this.$t('show.who_viewed_this'),
          eventName: 'Assignment Data Viewed',
          eventProperties: this.assignmentEventJson,
        },
      ]
    },
  },
}
</script>
