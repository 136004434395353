<template>
  <a
    :href="gradingUrl"
    class="border-base shadow-sm-50 hover:border-base-hover flex h-10 cursor-pointer items-center justify-between space-x-1 rounded-lg border px-2 text-sm font-bold leading-5"
    :class="dynamicButtonClasses"
  >
    <slot></slot>

    <span class="mx-auto w-full text-center">
      {{ label }}
    </span>

    <span class="icon icon--right text-current">
      <InlineSvg path="icons/next" />
    </span>
  </a>
</template>

<script setup>
import { computed, inject } from 'vue'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import Routes from 'routes'

const props = defineProps({
  lzCode: {
    type: String,
    required: true,
  },
  lzCodeViewId: {
    type: Number,
    required: true,
  },
  graded: {
    type: Boolean,
    default: false,
  },
  completed: {
    type: Boolean,
    default: false,
  },
  percent: {
    type: Number,
    default: null,
  },
})

const root = inject('useRoot')()

const label = computed(() => {
  if (props.graded && props.completed) {
    return `${props.percent}%`
  } else if (props.graded) {
    return root.$t('assignment_report.grade')
  } else {
    return root.$t('common.preview')
  }
})

const dynamicButtonClasses = computed(() => {
  if (!props.graded || !props.completed) {
    return ['btn--blue']
  } else {
    return []
  }
})

const gradingUrl = computed(() => {
  return Routes.grading_path(props.lzCode, {
    session_id: props.lzCodeViewId,
  })
})
</script>
