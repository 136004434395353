<template>
  <svg v-if="isTesting" :path="path" />
  <!-- safe because the SVG content is read from our images directory -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <svg v-else v-bind="attrs" :id="uniqueId" v-html="content"></svg>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { namespaceSvgIdsAndClasses, sanitizeSvg } from 'utils'

/*
 * Asynchronously loads and renders an svg directly into html
 *   path: partial path to svg file in app/assets/images
 *
 * Example
 *   <InlineSvg path="characters/k12-kate"/>
 */
const props = defineProps({
  path: {
    type: String,
    required: true,
  },
})

const attrs = ref({})
const content = ref('')
const uniqueId = ref(null)

const isTesting = computed(() => process.env.NODE_ENV === 'test' && process.env.SYSTEM_SPECS !== 'true')

watch(
  () => props.path,
  (value) => {
    if (isTesting.value) {
      return
    }

    import(
      /* webpackChunkName: '[request]' */
      `images/${value.replace('.svg', '')}.svg`
    ).then(({ default: html }) => {
      let svg = new DOMParser().parseFromString(html, 'text/html').querySelector('svg')
      svg = sanitizeSvg(svg)
      attrs.value = [...svg.attributes].reduce((obj, attr) => {
        obj[attr.name] = attr.value
        return obj
      }, {})
      svg = namespaceSvgIdsAndClasses(svg)
      uniqueId.value = svg.id
      content.value = svg.innerHTML
    })
  },
  { immediate: true },
)
</script>
