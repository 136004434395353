<template>
  <div>
    <p class="help-block center">
      {{ $t('slides.form_template.dialogue.direction_prompt') }}
    </p>

    <DialogueDirectionPickerSvg
      v-if="shape === 'speech'"
      :initial-direction="direction"
      class="dialogue-toolbar__direction-selector--speech"
      @selected="$emit('select', $event)"
    >
      <template #right>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M1269.2,313.2l-0.4,172.8h78.5c15.8,0,30.2-9.6,36.2-24.1 c1.8-4.8,3-10.2,3-15c0-10.4-4.4-20.4-11.5-27.6L1269.2,313.2z"
        />
      </template>
      <template #left>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M238.1,313.2L132.4,419.3c-7.2,7.2-11.5,17.2-11.5,27.6 c0,4.8,1.4,10.2,3,15c6,14.6,20.4,24.1,36.2,24.1h78.5L238.1,313.2L238.1,313.2z"
        />
      </template>
      <template #top-left-in>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M437.1,118.3l172.8,0.2V40.2c0-15.6-9.8-30-24.3-36 c-4.8-1.8-10.2-3-15-3c-10.4,0-20.4,4.4-27.6,11.5L437.1,118.3z"
        />
      </template>
      <template #top-center>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M885.5,117.9L780.6,12.8C773.2,5.4,762.8,1,752,1h-0.8 c-10.6,0-21,4.4-28.6,11.8L617.6,117.9H885.5z"
        />
      </template>
      <template #top-right-in>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M1061.1,118.4L954.9,12.4C947.7,5.3,937.7,1,927.3,1 c-4.8,0-10.2,1.6-15,3c-14.6,6.2-24.1,20.4-24.1,36.2v78.4L1061.1,118.4z"
        />
      </template>
      <template #bottom-right-in>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M1060.8,681.7l-172.6-0.4v78.4c0,15.8,9.6,30.2,24.1,36.2 c4.8,1.8,10.2,3,15,3c10.4,0,20.4-4.4,27.6-11.5L1060.8,681.7z"
        />
      </template>
      <template #bottom-center>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M617.6,681.9l104.9,104.9c7.4,7.4,17.8,11.8,28.6,11.8h0.8 c10.6,0,21-4.4,28.6-11.8l104.9-104.9H617.6z"
        />
      </template>
      <template #bottom-left-in>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M437.1,681.7l106.1,105.9c7.2,7.2,17.2,11.5,27.6,11.5 c4.8,0,10.2-1.6,15-3c14.6-6.2,24.1-20.4,24.1-36.2v-78.4L437.1,681.7z"
        />
      </template>
      <template #top-left-out>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M435.6,118.4L329.4,12.4C322.2,5.3,312.2,1,301.8,1 c-4.8,0-10.2,1.6-15,3c-14.6,6.2-24.1,20.4-24.1,36.2v78.4L435.6,118.4z"
        />
      </template>
      <template #top-right-out>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M1063.1,118l172.8,0.2V39.8c0-15.6-9.8-30-24.3-36 c-4.8-1.8-10.2-3-15-3c-10.4,0-20.4,4.4-27.6,11.5L1063.1,118z"
        />
      </template>
      <template #bottom-left-out>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M435.3,681.8l-172.6-0.4v78.4c0,15.8,9.6,30.2,24.1,36.2 c4.8,1.8,10.2,3,15,3c10.4,0,20.4-4.4,27.6-11.5L435.3,681.8z"
        />
      </template>
      <template #bottom-right-out>
        <path
          fill="#4CBDEC"
          stroke="#4CBDEC"
          stroke-miterlimit="10"
          d="M1063.1,681.6l106.1,105.9c7.2,7.2,17.2,11.5,27.6,11.5 c4.8,0,10.2-1.6,15-3c14.6-6.2,24.1-20.4,24.1-36.2v-78.4L1063.1,681.6z"
        />
      </template>
      <template #middle>
        <path
          fill="rgb(var(--primary-600))"
          d="M1235.9,683.1H267.3c-18.3,0-33.1-11.8-33.1-26V142c0-14.4,14.9-26,33.1-26h968.6c18.3,0,33.1,11.8,33.1,26 v515.1C1268.9,671.5,1254,683.1,1235.9,683.1z"
        />
      </template>
    </DialogueDirectionPickerSvg>

    <DialogueDirectionPickerSvg
      v-if="shape === 'thought'"
      :initial-direction="direction"
      class="dialogue-toolbar__direction-selector--thought"
      @selected="$emit('select', $event)"
    >
      <template #right>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M1208.5,398.7c0,6.9,5.2,12.9,12.8,15.7 c1,8.8,10.8,15.7,22.5,15.7c1.9,0,3.7-0.1,5.5-0.5c3.7,5.6,11.3,9.5,20.1,9.5c5.9,0,11.3-1.7,15.2-4.5c3.1,1.2,6.7,1.9,10.4,1.9 c11.8,0,21.4-6.9,22.5-15.6c10.9-1.3,19.3-8.4,19.3-17.2c0-1.5-0.2-2.9-0.8-4.4c1.3-2.2,2-4.6,2-7.2c0-7.8-6.9-14.5-16.4-16.7 c-3-7.1-11.5-12.2-21.7-12.2c-4.5,0-8.6,1-12,2.7c-4.1-3.2-9.9-5.2-16.1-5.2c-8.6,0-16,3.7-19.9,9.1c-3.6-1.7-7.7-2.7-12.2-2.7 c-12.4,0-22.5,7.7-22.5,17.3v0.3C1212.1,388,1208.5,393,1208.5,398.7L1208.5,398.7z"
        />
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M1302.5,399.3c0,3.8,2.9,7.1,7,8.7c0.6,4.8,5.9,8.7,12.4,8.7 c1.1,0,2-0.1,3-0.3c2,3.1,6.2,5.2,11.1,5.2c3.2,0,6.2-0.9,8.4-2.5c1.7,0.6,3.7,1.1,5.7,1.1c6.5,0,11.8-3.8,12.4-8.6 c6-0.7,10.6-4.6,10.6-9.4c0-0.8-0.1-1.6-0.4-2.4c0.7-1.2,1.1-2.5,1.1-3.9c0-4.3-3.8-8-9-9.2c-1.6-3.9-6.3-6.7-11.9-6.7 c-2.5,0-4.7,0.6-6.6,1.5c-2.3-1.8-5.4-2.9-8.9-2.9c-4.7,0-8.8,2-10.9,5c-2-0.9-4.2-1.5-6.7-1.5c-6.8,0-12.4,4.2-12.4,9.5v0.1 C1304.5,393.4,1302.5,396.2,1302.5,399.3L1302.5,399.3z"
        />
      </template>
      <template #left>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M293.2,401.1c0-6.9-5.2-12.9-12.8-15.7 c-1-8.8-10.8-15.7-22.5-15.7c-1.9,0-3.7,0.1-5.5,0.5c-3.7-5.6-11.3-9.5-20.1-9.5c-5.9,0-11.3,1.7-15.2,4.5 c-3.1-1.2-6.7-1.9-10.4-1.9c-11.8,0-21.4,6.9-22.5,15.6c-10.9,1.3-19.3,8.4-19.3,17.2c0,1.5,0.2,2.9,0.8,4.4c-1.3,2.2-2,4.6-2,7.2 c0,7.8,6.9,14.5,16.4,16.7c3,7.1,11.5,12.2,21.7,12.2c4.5,0,8.6-1,12-2.7c4.1,3.2,9.9,5.2,16.1,5.2c8.6,0,16-3.7,19.9-9.1 c3.6,1.7,7.7,2.7,12.2,2.7c12.4,0,22.5-7.7,22.5-17.3v-0.3C289.6,411.8,293.2,406.8,293.2,401.1L293.2,401.1z"
        />
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M199.1,400.5c0-3.8-2.9-7.1-7-8.7c-0.6-4.8-5.9-8.7-12.4-8.7 c-1.1,0-2,0.1-3,0.3c-2-3.1-6.2-5.2-11.1-5.2c-3.2,0-6.2,0.9-8.4,2.5c-1.7-0.6-3.7-1.1-5.7-1.1c-6.5,0-11.8,3.8-12.4,8.6 c-6,0.7-10.6,4.6-10.6,9.4c0,0.8,0.1,1.6,0.4,2.4c-0.7,1.2-1.1,2.5-1.1,3.9c0,4.3,3.8,8,9,9.2c1.6,3.9,6.3,6.7,11.9,6.7 c2.5,0,4.7-0.6,6.6-1.5c2.3,1.8,5.4,2.9,8.9,2.9c4.7,0,8.8-2,10.9-5c2,0.9,4.2,1.5,6.7,1.5c6.8,0,12.4-4.2,12.4-9.5v-0.1 C197.1,406.4,199.1,403.6,199.1,400.5L199.1,400.5z"
        />
      </template>
      <template #top-left-in>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M605.7,98.2c0-5.6-3.6-10.6-9-13.8v-0.3c0-9.6-10.1-17.3-22.5-17.3 c-4.5,0-8.6,1-12.2,2.7c-3.8-5.4-11.3-9.1-19.9-9.1c-6.3,0-12,2-16.1,5.2c-3.4-1.7-7.6-2.7-12-2.7c-10.1,0-18.7,5.1-21.7,12.2 C483,77.3,476,84,476,91.8c0,2.6,0.8,5,2,7.2c-0.5,1.4-0.8,2.8-0.8,4.4c0,8.7,8.5,15.9,19.3,17.2c1.1,8.7,10.8,15.6,22.5,15.6 c3.7,0,7.3-0.8,10.4-1.9c4,2.8,9.4,4.5,15.2,4.5c8.8,0,16.4-3.8,20.1-9.5c1.8,0.4,3.6,0.5,5.5,0.5c11.8,0,21.5-6.9,22.5-15.7 C600.5,111.1,605.7,105.1,605.7,98.2z M607.2,32.1c0-3.1-2-5.8-4.9-7.6v-0.1c0-5.3-5.6-9.5-12.4-9.5c-2.5,0-4.7,0.6-6.7,1.5 c-2.1-3-6.2-5-10.9-5c-3.4,0-6.6,1.1-8.9,2.9c-1.9-0.9-4.2-1.5-6.6-1.5c-5.6,0-10.3,2.8-11.9,6.7c-5.2,1.2-9,4.9-9,9.2 c0,1.4,0.4,2.7,1.1,3.9c-0.3,0.8-0.4,1.5-0.4,2.4c0,4.8,4.7,8.7,10.6,9.4c0.6,4.8,5.9,8.6,12.4,8.6c2,0,4-0.4,5.7-1.1 c2.2,1.5,5.1,2.5,8.4,2.5c4.9,0,9-2.1,11.1-5.2c1,0.2,2,0.3,3,0.3c6.5,0,11.8-3.8,12.4-8.7C604.3,39.2,607.2,35.9,607.2,32.1 L607.2,32.1z"
        />
      </template>
      <template #top-center>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M825,102.3c0-6.9-5.2-12.9-12.8-15.7c-1-8.8-10.8-15.7-22.5-15.7 c-1.9,0-3.7,0.1-5.5,0.5c-3.7-5.6-11.3-9.5-20.1-9.5c-5.9,0-11.3,1.7-15.2,4.5c-3.1-1.2-6.7-1.9-10.4-1.9 c-11.8,0-21.4,6.9-22.5,15.6c-10.9,1.3-19.3,8.4-19.3,17.2c0,1.5,0.2,2.9,0.8,4.4c-1.3,2.2-2,4.6-2,7.2c0,7.8,6.9,14.5,16.4,16.7 c3,7.1,11.5,12.2,21.7,12.2c4.5,0,8.6-1,12-2.7c4.1,3.2,9.9,5.2,16.1,5.2c8.6,0,16-3.7,19.9-9.1c3.6,1.7,7.7,2.7,12.2,2.7 c12.4,0,22.5-7.7,22.5-17.3v-0.3C821.4,113,825,108,825,102.3L825,102.3z M795.8,33.6c0-3.8-2.9-7.1-7-8.7c-0.7-4.8-6-8.6-12.5-8.6 c-1.1,0-2,0.1-3,0.3c-2-3.1-6.2-5.2-11.1-5.2c-3.2,0-6.2,0.9-8.4,2.5c-1.7-0.6-3.7-1.1-5.7-1.1c-6.5,0-11.8,3.8-12.4,8.6 c-6,0.7-10.6,4.6-10.6,9.4c0,0.8,0.1,1.6,0.4,2.4c-0.7,1.2-1.1,2.5-1.1,3.9c0,4.3,3.8,8,9,9.2c1.6,3.9,6.3,6.7,11.9,6.7 c2.5,0,4.7-0.6,6.6-1.5c2.3,1.8,5.4,2.9,8.9,2.9c4.7,0,8.8-2,10.9-5c2,0.9,4.2,1.5,6.7,1.5c6.8,0,12.4-4.2,12.4-9.5v-0.1 C793.8,39.4,795.8,36.7,795.8,33.6L795.8,33.6z"
        />
      </template>
      <template #top-right-in>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M903.7,98.2c0,6.9,5.2,12.9,12.8,15.7c1,8.8,10.8,15.7,22.5,15.7 c1.9,0,3.7-0.1,5.5-0.5c3.7,5.6,11.3,9.5,20.1,9.5c5.9,0,11.3-1.7,15.2-4.5c3.1,1.2,6.7,1.9,10.4,1.9c11.8,0,21.4-6.9,22.5-15.6 c10.9-1.3,19.3-8.4,19.3-17.2c0-1.5-0.2-2.9-0.8-4.4c1.3-2.2,2-4.6,2-7.2c0-7.8-6.9-14.5-16.4-16.7c-3-7.1-11.5-12.2-21.7-12.2 c-4.5,0-8.6,1-12,2.7c-4.1-3.2-9.9-5.2-16.1-5.2c-8.6,0-16,3.7-19.9,9.1c-3.6-1.7-7.7-2.7-12.2-2.7c-12.4,0-22.5,7.7-22.5,17.3v0.3 C907.2,87.6,903.7,92.6,903.7,98.2L903.7,98.2z M902.2,32.1c0,3.8,2.9,7.1,7,8.7c0.6,4.8,5.9,8.7,12.4,8.7c1.1,0,2-0.1,3-0.3 c2,3.1,6.2,5.2,11.1,5.2c3.2,0,6.2-0.9,8.4-2.5c1.7,0.6,3.7,1.1,5.7,1.1c6.5,0,11.8-3.8,12.4-8.6c6-0.7,10.6-4.6,10.6-9.4 c0-0.8-0.1-1.6-0.4-2.4c0.7-1.2,1.1-2.5,1.1-3.9c0-4.3-3.8-8-9-9.2c-1.6-3.9-6.3-6.7-11.9-6.7c-2.5,0-4.7,0.6-6.6,1.5 c-2.3-1.8-5.4-2.9-8.9-2.9c-4.7,0-8.8,2-10.9,5c-2-0.9-4.2-1.5-6.7-1.5c-6.8,0-12.4,4.2-12.4,9.5v0.1 C904.2,26.3,902.2,29,902.2,32.1L902.2,32.1z"
        />
      </template>
      <template #bottom-right-in>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M903.4,703c0,5.6,3.6,10.6,9,13.8v0.3c0,9.6,10.1,17.3,22.5,17.3 c4.5,0,8.6-1,12.2-2.7c3.8,5.4,11.3,9.1,19.9,9.1c6.3,0,12-2,16.1-5.2c3.4,1.7,7.6,2.7,12,2.7c10.1,0,18.7-5.1,21.7-12.2 c9.4-2.2,16.4-8.9,16.4-16.7c0-2.6-0.8-5-2-7.2c0.5-1.4,0.8-2.8,0.8-4.4c0-8.7-8.5-15.9-19.3-17.2c-1.1-8.7-10.8-15.6-22.5-15.6 c-3.7,0-7.3,0.8-10.4,1.9c-4-2.8-9.4-4.5-15.2-4.5c-8.8,0-16.4,3.8-20.1,9.5c-1.8-0.4-3.6-0.5-5.5-0.5c-11.8,0-21.5,6.9-22.5,15.7 C908.6,690,903.4,696.1,903.4,703L903.4,703z M901.9,769.1c0,3.1,2,5.8,4.9,7.6v0.1c0,5.3,5.6,9.5,12.4,9.5c2.5,0,4.7-0.6,6.7-1.5 c2.1,3,6.2,5,10.9,5c3.4,0,6.6-1.1,8.9-2.9c1.9,0.9,4.2,1.5,6.6,1.5c5.6,0,10.3-2.8,11.9-6.7c5.2-1.2,9-4.9,9-9.2 c0-1.4-0.4-2.7-1.1-3.9c0.3-0.8,0.4-1.5,0.4-2.4c0-4.8-4.7-8.7-10.6-9.4c-0.6-4.8-5.9-8.6-12.4-8.6c-2,0-4,0.4-5.7,1.1 c-2.2-1.5-5.1-2.5-8.4-2.5c-4.9,0-9,2.1-11.1,5.2c-1-0.2-2-0.3-3-0.3c-6.5,0-11.8,3.8-12.4,8.7C904.8,762,901.9,765.3,901.9,769.1 L901.9,769.1z"
        />
      </template>
      <template #bottom-center>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M695.5,698.7c0,6.9,5.2,12.9,12.8,15.7c1,8.8,10.8,15.7,22.5,15.7 c1.9,0,3.7-0.1,5.5-0.5c3.7,5.6,11.3,9.5,20.1,9.5c5.9,0,11.3-1.7,15.2-4.5c3.1,1.2,6.7,1.9,10.4,1.9c11.8,0,21.4-6.9,22.5-15.6 c10.9-1.3,19.3-8.4,19.3-17.2c0-1.5-0.2-2.9-0.8-4.4c1.3-2.2,2-4.6,2-7.2c0-7.8-6.9-14.5-16.4-16.7c-3-7.1-11.5-12.2-21.7-12.2 c-4.5,0-8.6,1-12,2.7c-4.1-3.2-9.9-5.2-16.1-5.2c-8.6,0-16,3.7-19.9,9.1c-3.6-1.7-7.7-2.7-12.2-2.7c-12.4,0-22.5,7.7-22.5,17.3v0.3 C699.1,688.1,695.5,693.1,695.5,698.7L695.5,698.7z M724.7,767.5c0,3.8,2.9,7.1,7,8.7c0.6,4.8,5.9,8.7,12.4,8.7c1.1,0,2-0.1,3-0.3 c2,3.1,6.2,5.2,11.1,5.2c3.2,0,6.2-0.9,8.4-2.5c1.7,0.6,3.7,1.1,5.7,1.1c6.5,0,11.8-3.8,12.4-8.6c6-0.7,10.6-4.6,10.6-9.4 c0-0.8-0.1-1.6-0.4-2.4c0.7-1.2,1.1-2.5,1.1-3.9c0-4.3-3.8-8-9-9.2c-1.6-3.9-6.3-6.7-11.9-6.7c-2.5,0-4.7,0.6-6.6,1.5 c-2.3-1.8-5.4-2.9-8.9-2.9c-4.7,0-8.8,2-10.9,5c-2-0.9-4.2-1.5-6.7-1.5c-6.8,0-12.4,4.2-12.4,9.5v0.1 C726.7,761.6,724.7,764.4,724.7,767.5L724.7,767.5z"
        />
      </template>
      <template #bottom-left-in>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M605.4,703c0-6.9-5.2-12.9-12.8-15.7c-1-8.8-10.8-15.7-22.5-15.7 c-1.9,0-3.7,0.1-5.5,0.5c-3.7-5.6-11.3-9.5-20.1-9.5c-5.9,0-11.3,1.7-15.2,4.5c-3.1-1.2-6.7-1.9-10.4-1.9 c-11.8,0-21.4,6.9-22.5,15.6c-10.9,1.3-19.3,8.4-19.3,17.2c0,1.5,0.2,2.9,0.8,4.4c-1.3,2.2-2,4.6-2,7.2c0,7.8,6.9,14.5,16.4,16.7 c3,7.1,11.5,12.2,21.7,12.2c4.5,0,8.6-1,12-2.7c4.1,3.2,9.9,5.2,16.1,5.2c8.6,0,16-3.7,19.9-9.1c3.6,1.7,7.7,2.7,12.2,2.7 c12.4,0,22.5-7.7,22.5-17.3V717C601.9,713.6,605.4,708.6,605.4,703L605.4,703z M606.9,769.1c0-3.8-2.9-7.1-7-8.7 c-0.6-4.8-5.9-8.7-12.4-8.7c-1.1,0-2,0.1-3,0.3c-2-3.1-6.2-5.2-11.1-5.2c-3.2,0-6.2,0.9-8.4,2.5c-1.7-0.6-3.7-1.1-5.7-1.1 c-6.5,0-11.8,3.8-12.4,8.6c-6,0.7-10.6,4.6-10.6,9.4c0,0.8,0.1,1.6,0.4,2.4c-0.7,1.2-1.1,2.5-1.1,3.9c0,4.3,3.8,8,9,9.2 c1.6,3.9,6.3,6.7,11.9,6.7c2.5,0,4.7-0.6,6.6-1.5c2.3,1.8,5.4,2.9,8.9,2.9c4.7,0,8.8-2,10.9-5c2,0.9,4.2,1.5,6.7,1.5 c6.8,0,12.4-4.2,12.4-9.5v-0.1C604.9,774.9,606.9,772.2,606.9,769.1L606.9,769.1z"
        />
      </template>
      <template #bottom-left-out>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M277.4,703c0,5.6,3.6,10.6,9,13.8v0.3c0,9.6,10.1,17.3,22.5,17.3 c4.5,0,8.6-1,12.2-2.7c3.8,5.4,11.3,9.1,19.9,9.1c6.3,0,12-2,16.1-5.2c3.4,1.7,7.6,2.7,12,2.7c10.1,0,18.7-5.1,21.7-12.2 c9.4-2.2,16.4-8.9,16.4-16.7c0-2.6-0.8-5-2-7.2c0.5-1.4,0.8-2.8,0.8-4.4c0-8.7-8.5-15.9-19.3-17.2c-1.1-8.7-10.8-15.6-22.5-15.6 c-3.7,0-7.3,0.8-10.4,1.9c-4-2.8-9.4-4.5-15.2-4.5c-8.8,0-16.4,3.8-20.1,9.5c-1.8-0.4-3.6-0.5-5.5-0.5c-11.8,0-21.5,6.9-22.5,15.7 C282.6,690,277.4,696.1,277.4,703L277.4,703z M275.9,769.1c0,3.1,2,5.8,4.9,7.6v0.1c0,5.3,5.6,9.5,12.4,9.5c2.5,0,4.7-0.6,6.7-1.5 c2.1,3,6.2,5,10.9,5c3.4,0,6.6-1.1,8.9-2.9c1.9,0.9,4.2,1.5,6.6,1.5c5.6,0,10.3-2.8,11.9-6.7c5.2-1.2,9-4.9,9-9.2 c0-1.4-0.4-2.7-1.1-3.9c0.3-0.8,0.4-1.5,0.4-2.4c0-4.8-4.7-8.7-10.6-9.4c-0.6-4.8-5.9-8.6-12.4-8.6c-2,0-4,0.4-5.7,1.1 c-2.2-1.5-5.1-2.5-8.4-2.5c-4.9,0-9,2.1-11.1,5.2c-1-0.2-2-0.3-3-0.3c-6.5,0-11.8,3.8-12.4,8.7C278.8,762,275.9,765.3,275.9,769.1 L275.9,769.1z"
        />
      </template>
      <template #bottom-right-out>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M1238.1,703c0-6.9-5.2-12.9-12.8-15.7c-1-8.8-10.8-15.7-22.5-15.7 c-1.9,0-3.7,0.1-5.5,0.5c-3.7-5.6-11.3-9.5-20.1-9.5c-5.9,0-11.3,1.7-15.2,4.5c-3.1-1.2-6.7-1.9-10.4-1.9 c-11.8,0-21.4,6.9-22.5,15.6c-10.9,1.3-19.3,8.4-19.3,17.2c0,1.5,0.2,2.9,0.8,4.4c-1.3,2.2-2,4.6-2,7.2c0,7.8,6.9,14.5,16.4,16.7 c3,7.1,11.5,12.2,21.7,12.2c4.5,0,8.6-1,12-2.7c4.1,3.2,9.9,5.2,16.1,5.2c8.6,0,16-3.7,19.9-9.1c3.6,1.7,7.7,2.7,12.2,2.7 c12.4,0,22.5-7.7,22.5-17.3V717C1234.6,713.6,1238.1,708.6,1238.1,703L1238.1,703z M1239.6,769.1c0-3.8-2.9-7.1-7-8.7 c-0.6-4.8-5.9-8.7-12.4-8.7c-1.1,0-2,0.1-3,0.3c-2-3.1-6.2-5.2-11.1-5.2c-3.2,0-6.2,0.9-8.4,2.5c-1.7-0.6-3.7-1.1-5.7-1.1 c-6.5,0-11.8,3.8-12.4,8.6c-6,0.7-10.6,4.6-10.6,9.4c0,0.8,0.1,1.6,0.4,2.4c-0.7,1.2-1.1,2.5-1.1,3.9c0,4.3,3.8,8,9,9.2 c1.6,3.9,6.3,6.7,11.9,6.7c2.5,0,4.7-0.6,6.6-1.5c2.3,1.8,5.4,2.9,8.9,2.9c4.7,0,8.8-2,10.9-5c2,0.9,4.2,1.5,6.7,1.5 c6.8,0,12.4-4.2,12.4-9.5v-0.1C1237.6,774.9,1239.6,772.2,1239.6,769.1L1239.6,769.1z"
        />
      </template>
      <template #top-left-out>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M277.4,98.2c0,6.9,5.2,12.9,12.8,15.7c1,8.8,10.8,15.7,22.5,15.7 c1.9,0,3.7-0.1,5.5-0.5c3.7,5.6,11.3,9.5,20.1,9.5c5.9,0,11.3-1.7,15.2-4.5c3.1,1.2,6.7,1.9,10.4,1.9c11.8,0,21.4-6.9,22.5-15.6 c10.9-1.3,19.3-8.4,19.3-17.2c0-1.5-0.2-2.9-0.8-4.4c1.3-2.2,2-4.6,2-7.2c0-7.8-6.9-14.5-16.4-16.7c-3-7.1-11.5-12.2-21.7-12.2 c-4.5,0-8.6,1-12,2.7c-4.1-3.2-9.9-5.2-16.1-5.2c-8.6,0-16,3.7-19.9,9.1c-3.6-1.7-7.7-2.7-12.2-2.7c-12.4,0-22.5,7.7-22.5,17.3v0.3 C280.9,87.6,277.4,92.6,277.4,98.2L277.4,98.2z M275.9,32.1c0,3.8,2.9,7.1,7,8.7c0.6,4.8,5.9,8.7,12.4,8.7c1.1,0,2-0.1,3-0.3 c2,3.1,6.2,5.2,11.1,5.2c3.2,0,6.2-0.9,8.4-2.5c1.7,0.6,3.7,1.1,5.7,1.1c6.5,0,11.8-3.8,12.4-8.6c6-0.7,10.6-4.6,10.6-9.4 c0-0.8-0.1-1.6-0.4-2.4c0.7-1.2,1.1-2.5,1.1-3.9c0-4.3-3.8-8-9-9.2c-1.6-3.9-6.3-6.7-11.9-6.7c-2.5,0-4.7,0.6-6.6,1.5 c-2.3-1.8-5.4-2.9-8.9-2.9c-4.7,0-8.8,2-10.9,5c-2-0.9-4.2-1.5-6.7-1.5c-6.8,0-12.4,4.2-12.4,9.5v0.1 C277.9,26.3,275.9,29,275.9,32.1L275.9,32.1z"
        />
      </template>
      <template #middle>
        <path
          fill="#F9A120"
          d="M1240.7,683.7H271.3c-18.3,0-33.1-11.8-33.1-26V142.1 c0-14.4,14.9-26,33.1-26h969.4c18.3,0,33.1,11.8,33.1,26v515.6C1273.8,672.1,1258.8,683.7,1240.7,683.7z"
        />
      </template>
      <template #top-right-out>
        <path
          fill="#F9A120"
          stroke="#B26C05"
          stroke-miterlimit="10"
          d="M1238.4,98.2c0-5.6-3.6-10.6-9-13.8v-0.3 c0-9.6-10.1-17.3-22.5-17.3c-4.5,0-8.6,1-12.2,2.7c-3.8-5.4-11.3-9.1-19.9-9.1c-6.3,0-12,2-16.1,5.2c-3.4-1.7-7.6-2.7-12-2.7 c-10.1,0-18.7,5.1-21.7,12.2c-9.4,2.2-16.4,8.9-16.4,16.7c0,2.6,0.8,5,2,7.2c-0.5,1.4-0.8,2.8-0.8,4.4c0,8.7,8.5,15.9,19.3,17.2 c1.1,8.7,10.8,15.6,22.5,15.6c3.7,0,7.3-0.8,10.4-1.9c4,2.8,9.4,4.5,15.2,4.5c8.8,0,16.4-3.8,20.1-9.5c1.8,0.4,3.6,0.5,5.5,0.5 c11.8,0,21.5-6.9,22.5-15.7C1233.2,111.1,1238.4,105.1,1238.4,98.2z M1239.9,32.1c0-3.1-2-5.8-4.9-7.6v-0.1c0-5.3-5.6-9.5-12.4-9.5 c-2.5,0-4.7,0.6-6.7,1.5c-2.1-3-6.2-5-10.9-5c-3.4,0-6.6,1.1-8.9,2.9c-1.9-0.9-4.2-1.5-6.6-1.5c-5.6,0-10.3,2.8-11.9,6.7 c-5.2,1.2-9,4.9-9,9.2c0,1.4,0.4,2.7,1.1,3.9c-0.3,0.8-0.4,1.5-0.4,2.4c0,4.8,4.7,8.7,10.6,9.4c0.6,4.8,5.9,8.6,12.4,8.6 c2,0,4-0.4,5.7-1.1c2.2,1.5,5.1,2.5,8.4,2.5c4.9,0,9-2.1,11.1-5.2c1,0.2,2,0.3,3,0.3c6.5,0,11.8-3.8,12.4-8.7 C1237,39.2,1239.9,35.9,1239.9,32.1L1239.9,32.1z"
        />
      </template>
    </DialogueDirectionPickerSvg>
  </div>
</template>

<script>
import DialogueDirectionPickerSvg from './DialogueDirectionPickerSvg'

export default {
  components: { DialogueDirectionPickerSvg },
  props: {
    shape: { type: String, required: true },
    direction: { type: String, required: true },
  },
}
</script>
