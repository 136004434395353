import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import filestackUploadMessages from 'vue_features/shared/i18n/filestack_upload_messages'
import {
  errors,
  common,
  geogebraItems,
  hyperlinks,
  wikis,
  lessonPlans,
  cardEdits,
  standardsCoverageTables,
  shared,
  featureGate,
  studentTools,
  slides,
  htmls,
  videos,
  components,
  standards,
  contentTypeMetadata,
  revisions,
} from 'vue_features/shared/i18n'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'
import actionMenuMessages from 'vue_features/action_menu/i18n/action_menu_messages'

export default merge(
  {},
  cardEdits,
  filestackUploadMessages,
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(geogebraItems, 'geogebra_items'),
  namespaceLocaleObj(hyperlinks, 'hyperlinks'),
  namespaceLocaleObj(wikis, 'wikis'),
  lessonPlans,
  pickLocaleNamespace(lessonPlans, 'info_form'),
  pickLocaleNamespace(lessonPlans, 'readable_types'),
  standardsMessages,
  standardsCoverageTables,
  namespaceLocaleObj(shared, 'shared', {
    only: ['offline', 'text_style', 'action_menu'],
  }),
  pickLocaleNamespace(shared, 'updated_notification'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  namespaceLocaleObj(featureGate, 'feature_gate'),
  namespaceLocaleObj(studentTools, 'student_tools'),
  namespaceLocaleObj(slides, 'slides'),
  namespaceLocaleObj(htmls, 'htmls'),
  namespaceLocaleObj(errors, 'errors'),
  elevateLocaleNamespace(videos, 'form_template'),
  namespaceLocaleObj(components, '', {
    only: ['audio_player', 'check_answers_button', 'learnosity_item', 'scoring_rubric', 'learnosity_item_editor'],
  }),
  namespaceLocaleObj(contentTypeMetadata, 'content_type_metadata'),
  standards,
  namespaceLocaleObj(revisions, 'revisions'),
  actionMenuMessages,
)
