<template>
  <div data-test="page-head" class="lesson-title flex flex-col items-start sm:flex-row">
    <div class="mt-12 flex w-full flex-col items-start gap-2 pt-6 md:flex-row md:pt-6">
      <div class="flex flex-shrink-0 flex-col pb-4">
        <InlineSvg class="lesson-plan-icon -mt-0.5 mr-5 flex-shrink-0 rounded-xl" path="icons/lesson-plan" />
      </div>
      <div class="flex flex-grow flex-row flex-wrap md:flex-nowrap">
        <div class="flex flex-grow flex-col items-start gap-y-2">
          <div class="mb-0 flex flex-grow items-start pr-16 text-3xl leading-10">
            <div class="inline-flex flex-col">
              <div class="flex items-center gap-x-2">
                <span
                  itemprop="educationalUse"
                  class="mb-0 text-sm font-semibold uppercase leading-5 tracking-wider text-blue-500"
                >
                  {{ $t(`readable_types.${lessonType}`) }}
                </span>
                <LzContentStateBadge :state="lessonState" class="my-0 leading-3" />
                <LzContentPremiumBadge v-if="isPublic" :premium="isPremium" />
              </div>
              <span class="text-primary font-semibold">{{ title }}</span>
            </div>
          </div>
          <div class="flex items-start space-x-2 pl-0">
            <div v-if="lastEditor" class="bg-primary-200 text-primary-900 w-auto rounded-2xl px-3 py-1 text-sm">
              {{ $t('common.label.customized_by') }}
              {{ lastEditor }}
            </div>
            <a
              v-if="personalized"
              :href="Routes.lesson_plan_card_edits_path(lessonPlanId, { personalize: true })"
              class="w-auto rounded-2xl bg-green-50 px-3 py-1 text-sm text-green-700 hover:text-green-900"
            >
              {{ $t('common.label.personalized_by') }}
              {{ $t('common.you') }}
            </a>
          </div>
          <div class="flex w-full flex-col gap-y-4 pl-0">
            <div
              v-if="showStandards && allCustomerVisibleStandards.length > 0"
              class="flex flex-wrap items-center gap-3"
            >
              <strong class="text-primary-800 mr-2 block gap-y-3 text-sm">{{ $t('common.label.standards') }}</strong>
              <StandardsBreakdown :standards="allCustomerVisibleStandards" class="gap-x-2 gap-y-3" />
            </div>
            <div v-if="isPublic && authorizedAsAuthor && allStandards.length > 0" class="flex flex-wrap items-center">
              <strong class="text-primary-800 mr-2 block text-sm">{{ $t('common.label.all_standards') }}</strong>
              <StandardsBreakdown
                v-if="isPublic && authorizedAsAuthor && allStandards.length > 0"
                :standards="allStandards"
              />
            </div>
            <PageHeadStandards />
          </div>
        </div>
        <div v-if="!isGated && !isRevision" class="flex w-full flex-col flex-wrap items-start sm:w-auto">
          <div class="flex">
            <Coachmark
              v-if="
                canDismissibleShow($options.CM_BOOKMARK) &&
                !(showAssignCoachmark && canDismissibleShow($options.ASSIGN_BUTTON)) &&
                !isFullscreen
              "
              target="#bookmark-button"
              position="left-start"
              @close="closeDismissible($options.CM_BOOKMARK)"
            >
              <h4 class="my-0 text-base leading-8">{{ $t('bookmark_button.coachmark_title') }}</h4>
              <p class="text-muted mb-2">{{ $t('bookmark_button.coachmark_description') }}</p>
            </Coachmark>
            <div class="flex gap-x-3">
              <BookmarkButton v-if="isLoggedIn && !isArchived" @started="closeDismissible($options.CM_BOOKMARK)" />
              <ActionMenu v-if="!isDraft" v-bind="actionMenuProps" class="flex-grow-0" />

              <template v-if="!isRevision">
                <AssignMenu id="assign-button" :with-preview="true" class="flex-grow-0" />
                <Coachmark
                  v-if="showAssignCoachmark && canDismissibleShow($options.ASSIGN_BUTTON)"
                  target="#assign-button"
                  position="left-start"
                  @close="closeDismissible($options.ASSIGN_BUTTON)"
                >
                  <h4 class="mb-2 mt-0">{{ $t('page_head_info.coachmark.new_view') }}</h4>
                  <p>{{ $t('page_head_info.coachmark.student_ux') }}</p>
                </Coachmark>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import LzContentPremiumBadge from 'vue_features/lz_content/components/LzContentPremiumBadge'
import StandardsBreakdown from 'vue_features/lesson_plans/components/StandardsBreakdown'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import PageHeadStandards from 'vue_features/lesson_plans/components/PageHeadStandards'
import { useDismissibles } from 'vue_features/shared/composables'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import ActionMenu from 'vue_features/action_menu/components/ActionMenu'
import AssignMenu from 'vue_features/assignments/prompt/components/AssignMenu'
import BookmarkButton from './BookmarkButton'
import Coachmark from 'vue_features/shared/components/ui/Coachmark'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import useLessonPlanStandards from 'vue_features/lesson_plans/show/composables/use_lesson_plan_standards'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import Routes from 'routes'

const CM_BOOKMARK = 'bookmark'
const ASSIGN_BUTTON = 'assign-button'

export default {
  CM_BOOKMARK,
  ASSIGN_BUTTON,
  components: {
    InlineSvg,
    ActionMenu,
    AssignMenu,
    Coachmark,
    PageHeadStandards,
    LzContentStateBadge,
    LzContentPremiumBadge,
    StandardsBreakdown,
    BookmarkButton,
  },
  setup() {
    const { isGated, actionMenuProps } = useLessonPlanShowStore()
    const { isFullscreen } = useFullscreenStore()
    const {
      isRevision,
      lessonType,
      lessonState,
      premium,
      title,
      logo,
      lastEditor,
      personalized,
      lessonPlanId,
      isArchived,
      isDraft,
    } = useLessonPlanStore()
    const { showStandards, isPublic } = useCurrentCustomerStore()
    const { isLoggedIn, authorizedAsAuthor, isStudent } = useCurrentUserStore()
    const { allStandards, allCustomerVisibleStandards } = useLessonPlanStandards()
    const { showDismissible, closeDismissible, canDismissibleShow } = useDismissibles()

    const hasLogo = computed(() => {
      return !!logo.value
    })

    showDismissible(ASSIGN_BUTTON)
    showDismissible(CM_BOOKMARK)

    const showAssignCoachmark = computed(() => {
      return !isPublic.value && !isStudent.value && !isFullscreen.value && lessonState.value === 'active'
    })

    return {
      Routes,
      showStandards,
      actionMenuProps,
      isRevision,
      lessonType,
      lessonState,
      isPremium: premium,
      isGated,
      title,
      isPublic,
      isStudent,
      authorizedAsAuthor,
      isLoggedIn,
      allStandards,
      allCustomerVisibleStandards,
      closeDismissible,
      canDismissibleShow,
      hasLogo,
      logo,
      lastEditor,
      personalized,
      lessonPlanId,
      canModalShow,
      openModal,
      isFullscreen,
      isArchived,
      isDraft,
      showAssignCoachmark,
    }
  },
}
</script>

<style scoped>
.lesson-plan-icon {
  width: 46px;
  height: auto;
  stroke: #fff;
  stroke-width: 5px;
}
</style>
