<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="isTeacherResettingStudent ? $t('heading_password') : ''"
    :class="isTeacherResettingStudent ? 'lzui-modal--medium' : ''"
    :modal-id="modalId"
    autoclose
  >
    <div class="align-left">
      <div v-if="errors.server" class="error error--page">
        <h2>{{ $t('errors.common.page_error_heading') }}</h2>
        <p>{{ $t('errors.common.page_error_message_below') }}</p>
      </div>

      <p v-if="requiresOldPassword">
        <label :class="['w-full', { error: errors.old_password }]">
          {{ $t('edit.old_password_label') }}
          <span v-if="errors && errors.old_password">
            {{ $t('errors.old_password') }}
          </span>
          <input ref="oldPassword" v-model="oldPassword" name="old_password" type="password" />
        </label>
      </p>

      <p>
        <label class="w-full">
          {{ $t('password_label') }}
          <span v-if="errors.password" class="inline-help red">
            {{ errors.password[0] }}
          </span>
          <input ref="newPassword" v-model="password" name="new_password" type="password" />
        </label>
      </p>

      <p>
        <label class="w-full">
          {{ $t('confirm_password_label') }}
          <span v-if="errors.password_confirmation" class="inline-help red">
            {{ errors.password_confirmation[0] }}
          </span>
          <input v-model="passwordConfirmation" name="password_confirmation" type="password" />
        </label>
      </p>
    </div>

    <footer>
      <button :disabled="isBusy" name="cancel_button" class="btn" @click="close">
        {{ isTeacherResettingStudent ? $t('common.cancel') : $t('edit.cancel_password') }}
      </button>
      <button
        :class="['btn btn--primary', { disabled: isDisabled }]"
        :disabled="isDisabled"
        name="confirm_button"
        @click="confirmPassword"
      >
        {{ isTeacherResettingStudent ? $t('common.update') : $t('edit.update_password') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { isEmpty } from 'lodash'
import LzModal from 'vue_features/shared/components/ui/LzModal'
import UserService from '../api/user'
import StudentService from '../api/student'
import { location as $location } from 'utils'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'ChangePasswordModal',
  components: { LzModal },
  props: {
    student: {
      type: Object,
      required: false,
      default() {
        return null
      },
    },
    modalId: {
      type: String,
      default: function () {
        return 'ChangePasswordModal'
      },
    },
  },
  setup() {
    const { isStudent, isTeacher, hasPassword: userHasPassword } = useCurrentUserStore()
    return { isStudent, isTeacher, userHasPassword }
  },
  data() {
    return {
      errors: {},
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
      isBusy: false,
    }
  },

  computed: {
    isDisabled() {
      return (
        (this.requiresOldPassword ? isEmpty(this.oldPassword) : false) ||
        isEmpty(this.password) ||
        isEmpty(this.passwordConfirmation) ||
        this.isBusy
      )
    },
    isTeacherResettingStudent() {
      return this.isTeacher && this.student !== null
    },
    requiresOldPassword() {
      return this.userHasPassword && !this.isTeacherResettingStudent
    },
  },

  mounted() {
    const input = this.$refs.oldPassword || this.$refs.newPassword
    input.focus()
  },

  methods: {
    confirmPassword() {
      const passwords = {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      }

      if (this.requiresOldPassword) {
        passwords.oldPassword = this.oldPassword
      }

      if (this.isTeacherResettingStudent) {
        passwords.student = this.student
      }

      this.updatePassword(passwords)
    },

    updatePassword(passwords) {
      const service = this.isTeacherResettingStudent ? StudentService : UserService

      this.isBusy = true
      service
        .update(passwords)
        .then(() => {
          $location.reload()
        })
        .catch((response) => {
          this.isBusy = false
          if (!response.responseJSON) return

          this.errors = {}
          this.errors.server = true
          Object.entries(response.responseJSON.errors).forEach(([key, value]) => {
            this.errors[key] = value
          })
        })
    },
  },
}
</script>
