<template>
  <div class="slide__image max-h-96 lg:max-h-full">
    <FilestackSvg v-if="isSvg" :url="fileUrl" :desc="altText" />
    <img v-else :src="fileUrl" :alt="altText" />
  </div>
</template>

<script>
import FilestackSvg from './FilestackSvg'
import useSlideImage from '../../composables/use_slide_image'

export default {
  name: 'SlideViewerImage',
  components: { FilestackSvg },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { altText, fileUrl, isSvg } = useSlideImage(props)

    return { altText, isSvg, fileUrl }
  },
}
</script>
