import { uniqueId } from 'lodash'

// Dots will only show on IE/Edge or older versions of other browsers,
// in addition to default text. Default text is localized by the browser
// and the dots will likely be ignored by users.
const DEFAULT_CONFIRM = (e) => (e.returnValue = '...')

export let unloadConfirmEnabled = false
let unloadConfirmation = DEFAULT_CONFIRM
let disableId

export function bindUnloadConfirmation(confirm) {
  unloadConfirmation = confirm

  enableUnloadConfirm()
}

export function enableUnloadConfirm() {
  if (!unloadConfirmEnabled) {
    window.addEventListener('beforeunload', unloadConfirmation)
  }
  unloadConfirmEnabled = true
}

export function disableUnloadConfirm() {
  unloadConfirmEnabled = false
  window.removeEventListener('beforeunload', unloadConfirmation)
}

export function disableThenEnableConfirm() {
  if (!unloadConfirmEnabled) return true

  const myId = uniqueId('disable-')
  disableId = myId
  disableUnloadConfirm()

  window.setTimeout(() => {
    if (disableId === myId) {
      enableUnloadConfirm()
    }
  }, 1000)
}
