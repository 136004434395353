<template>
  <div
    class="fabric-tools pointer-events-none fixed top-0 z-40 mb-6 flex h-full leading-6 transition duration-300 print:hidden"
    :class="{
      'left-0': !isCollectionMapVisible,
      'left-96': isCollectionMapVisible,
      '-translate-x-full transform opacity-0': !showAnnotationTools,
      'opacity-100': showAnnotationTools,
    }"
  >
    <div
      class="shadow-md-200 border-base bg-focus m-auto flex w-14 flex-col space-y-1 rounded-r-2xl border-2 border-l-0 border-solid py-1"
      :class="{ 'pointer-events-auto': showAnnotationTools }"
    >
      <button
        type="button"
        class="group relative mx-auto h-12 w-12 rounded-xl transition-colors duration-500"
        :class="{
          'hover:bg-base-hover bg-transparent': isAnnotating,
          'bg-inverted ': !isAnnotating,
        }"
        data-test="interact-button"
        :tabindex="isVisibleTabindex"
        @click="setAnnotationMode('interact')"
      >
        <InlineSvg
          type="div"
          class="mx-auto h-6 w-6 transition-colors duration-500"
          :class="{ 'text-white': !isAnnotating }"
          path="icons/annotate-interact"
        />
        <AnnotationToolTip :text="$t('annotation.interact')" />
      </button>
      <div class="border-base flex w-full flex-col space-y-2 border-b border-t py-2">
        <AnnotationToolButton :tabindex="isVisibleTabindex" tool="draw" @selected="setAnnotationMode" />
        <AnnotationToolButton :tabindex="isVisibleTabindex" tool="highlight" @selected="setAnnotationMode" />
        <AnnotationToolButton :tabindex="isVisibleTabindex" tool="eraser" @selected="setAnnotationMode">
          <template #actions>
            <div
              class="border-base absolute bottom-0 left-16 z-20 flex w-40 flex-col gap-2 overflow-hidden rounded-2xl border bg-white p-2 shadow-lg"
            >
              <h5 class="text-muted text-center">{{ $t('annotation.erase') }}</h5>
              <div>
                <button
                  class="hover:bg-base focus:bg-base focus:ring-primary-accent flex w-full justify-center rounded py-2 transition duration-300 focus:ring"
                  type="button"
                  @click="clearCard"
                >
                  {{ $t('annotation.current_card') }}
                </button>
                <button
                  class="hover:bg-base focus:bg-base focus:ring-primary-accent flex w-full justify-center rounded py-2 transition duration-300 focus:ring"
                  type="button"
                  @click="clearAllCards"
                >
                  {{ $t('annotation.all_cards') }}
                </button>
              </div>
            </div>
          </template>
        </AnnotationToolButton>

        <ShapePicker
          v-model="selectedShape"
          :shapes="shapes"
          :selected="mode == 'shape'"
          :tabindex="isVisibleTabindex"
          @click="setAnnotationMode('shape')"
        />

        <button
          type="button"
          :class="{
            'bg-primary hover:bg-accent-hover text-white': mode === 'text',
            'hover:bg-base-hover': mode !== 'text',
          }"
          :tabindex="isVisibleTabindex"
          class="group relative mx-auto h-12 w-12 rounded-lg transition-colors duration-500"
          @click="setAnnotationMode('text')"
        >
          <InlineSvg type="div" path="icons/annotate-text" class="mx-auto h-10 w-10" />
          <AnnotationToolTip v-show="mode !== 'text'" :text="$t('annotation.text')" />
        </button>

        <button
          type="button"
          :class="{
            'bg-primary hover:bg-accent-hover text-white': mode === 'select',
            'hover:bg-base-hover': mode !== 'select',
          }"
          :tabindex="isVisibleTabindex"
          class="group relative mx-auto h-12 w-12 rounded-lg transition-colors duration-500"
          :data-test="`select-button${mode === 'select' ? '-selected' : ''}`"
          @click="setAnnotationMode('select')"
        >
          <InlineSvg type="div" path="icons/annotate-select" class="mx-auto h-6 w-6" svg-classes="m-0" />
          <AnnotationToolTip v-show="mode !== 'select'" :text="$t('annotation.move')" />
        </button>

        <ColorPicker v-model="strokeColor" :tabindex="isVisibleTabindex" :colors="colors" />
      </div>
      <div class="flex flex-col">
        <button
          type="button"
          class="hover:bg-base-hover hover:border-base-hover group relative mx-auto h-12 w-12 rounded-lg"
          :tabindex="isVisibleTabindex"
          data-test="undo-button"
          @click="undo"
        >
          <InlineSvg type="div" path="icons/reply.svg" class="mx-auto h-6 w-6" />
          <AnnotationToolTip :text="$t('common.undo')" />
        </button>
        <button
          type="button"
          :tabindex="isVisibleTabindex"
          class="hover:bg-base-hover hover:border-base-hover group relative mx-auto h-12 w-12 rounded-lg"
          @click="toggleShowAnnotations(liveLearnSubscription)"
        >
          <InlineSvg v-show="showAnnotations" type="div" path="icons/eye.svg" class="mx-auto h-6 w-6" />
          <InlineSvg v-show="!showAnnotations" type="div" path="icons/eye-off.svg" class="mx-auto h-6 w-6 text-base" />
          <AnnotationToolTip v-if="showAnnotations" :text="$t('annotation.hide_annotations')" />
          <AnnotationToolTip v-else :text="$t('annotation.show_annotations')" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import useEventListener from 'vue_features/shared/composables/use_event_listener'

import { InlineSvg } from 'vue_features/shared/components/ui'
import AnnotationToolButton from 'vue_features/shared/components/AnnotationToolButton'
import AnnotationToolTip from 'vue_features/shared/components/AnnotationToolTip'
import ColorPicker from 'vue_features/shared/components/ColorPicker'
import ShapePicker from 'vue_features/shared/components/ShapePicker'

import { useAnnotationToolStore } from 'vue_features/shared/store/composables/use_annotation_tool_store'
import { useAnnotationCanvasStore } from 'vue_features/lesson_plans/store/use_annotation_canvas_store'
import { useCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useLiveLearnChannel } from 'vue_features/assignments/show/composables'

export default {
  name: 'AnnotationTools',
  components: {
    AnnotationToolButton,
    AnnotationToolTip,
    ColorPicker,
    InlineSvg,
    ShapePicker,
  },
  props: {
    selectedCardIndex: {
      type: Number,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const root = inject('useRoot')()

    const {
      isAnnotating,
      mode,
      selectedShape,
      setAnnotationMode,
      showAnnotationTools,
      showAnnotations,
      strokeColor,
      toggleShowAnnotations,
    } = useAnnotationToolStore()
    const { broadcastUndo, setHistory, clearCanvas, clearAllCanvases } = useAnnotationCanvasStore()
    const { isOpen: isCollectionMapOpen } = useCollectionMapStore()
    const { isFullscreen } = useFullscreenStore()
    const { isCollectionMapAvailable } = useLessonPlanShowStore()

    const colors = ['#DC267F', '#EB3333', '#FE6100', '#FFB000', '#2FF20D', '#648FFF', '#775EF0', '#212121']
    const shapes = ['rectangle', 'ellipse', 'triangle', 'line', 'arrow']

    if (strokeColor.value === null) {
      strokeColor.value = colors[0]
    }

    if (selectedShape.value === null) {
      selectedShape.value = shapes[0]
    }

    const isCollectionMapVisible = computed(() => {
      return isCollectionMapOpen.value && !isFullscreen.value && isCollectionMapAvailable.value
    })

    const isVisibleTabindex = computed(() => {
      return showAnnotationTools.value ? 0 : -1
    })

    function setAnnotationStrokeColor(color) {
      strokeColor.value = color
    }

    function handleKeyDown(event) {
      if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        undo()
      }
    }

    useEventListener('keydown', handleKeyDown)

    const { subscription: liveLearnSubscription } = useLiveLearnChannel()
    function undo() {
      broadcastUndo(liveLearnSubscription.value)
    }

    function clearCard() {
      clearCanvas(props.cards[props.selectedCardIndex].id, liveLearnSubscription.value)
    }

    function clearAllCards() {
      if (window.confirm(root.$t('annotation.clear_all_cards_confirmation'))) {
        clearAllCanvases(liveLearnSubscription.value)
        setHistory([])
      }
    }

    return {
      clearAllCards,
      clearCard,
      colors,
      isAnnotating,
      isCollectionMapVisible,
      isVisibleTabindex,
      isFullscreen,
      mode,
      selectedShape,
      setAnnotationMode,
      setAnnotationStrokeColor,
      shapes,
      showAnnotationTools,
      showAnnotations,
      strokeColor,
      toggleShowAnnotations,
      undo,
      liveLearnSubscription,
    }
  },
}
</script>
