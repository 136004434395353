<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="spacing-y-3">
    <div v-if="lessonPlan.errors && lessonPlan.errors.length > 0" class="mx-auto max-w-7xl px-3 md:px-6">
      <Alert
        :title="$t('errors.common.page_error_heading')"
        :description="lessonPlan.errors.join(', ')"
        :dismissable="true"
        type="error"
      />
    </div>
    <div class="page-head my-4 pb-4">
      <Grid>
        <div class="spacing-x-3 col-span-12 md:col-span-8 md:col-start-4 lg:col-span-9 lg:col-start-4">
          <div>
            <label>{{ $t('info_form.title') }}</label>
            <input v-model="newTitle" type="text" data-test="lp-title-input" />
            <p class="help-block">{{ $t('form_template.title_help') }}</p>
          </div>
          <div>
            <label>{{ $t('common.subscription_required') }}</label>
            <SwitchToggle id="premium-toggle" v-model="newPremium" />
          </div>
        </div>
        <div class="col-span-12 md:col-span-4 md:row-start-1 lg:col-span-3">
          <CoverImageUpload
            :cover-image="newCoverImage"
            @upload-done="newCoverImage = $event"
            @remove-file="newCoverImage = $event"
          />
        </div>
      </Grid>
    </div>
  </div>
</template>

<script>
import SwitchToggle from 'vue_features/shared/components/ui/SwitchToggle'
import { ref, watchEffect } from 'vue'
import Alert from 'vue_features/shared/components/ui/Alert'
import CoverImageUpload from 'vue_features/cover_image_upload/components/CoverImageUpload'
import Grid from 'vue_features/shared/components/ui/Grid'

export default {
  name: 'LessonPlanFormTemplate',
  components: { Grid, Alert, CoverImageUpload, SwitchToggle },
  props: { lessonPlan: { type: Object, required: true } },
  setup(props, { emit }) {
    const newTitle = ref(props.lessonPlan?.title)
    const newPremium = ref(props.lessonPlan?.premium)
    const newCoverImage = ref(props.lessonPlan?.coverImage || {})

    watchEffect(() =>
      emit('update', { title: newTitle.value, premium: newPremium.value, coverImage: newCoverImage.value }),
    )

    return {
      newTitle,
      newPremium,
      newCoverImage,
    }
  },
}
</script>
