import { computed } from 'vue'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'

export default function useSlideImage(props) {
  const filestackOptions = computed(() => {
    if (props.content.policy && props.content.signature) {
      return { policy: props.content.policy, signature: props.content.signature }
    }
    let file
    if (props.content.processedFile && props.content.processedFile.filestackOptions.policy) {
      file = props.content.processedFile
    } else {
      file = props.content.file || {}
    }
    return file.filestackOptions
  })

  const mimetype = computed(() => {
    return props.content.mimetype || ''
  })

  const baseUrl = computed(() => {
    return props.content.processedUrl || props.content.url
  })
  const isSvg = computed(() => {
    return props.content.mimetype && mimetype.value.includes('svg')
  })

  const altText = computed(() => {
    return props.content.altText || ''
  })

  const file = computed(() => {
    return { baseUrl: baseUrl.value, filestackOptions: filestackOptions.value }
  })

  const { fileUrl } = useFilestackAccessor(file)

  return { altText, fileUrl, isSvg }
}
