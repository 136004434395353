<script>
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common } from 'vue_features/shared/i18n'

export default {
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: ['new'] }) },
}
</script>

<script setup>
import dayjs from 'dayjs'
import { computed } from 'vue'

const props = defineProps({
  displayUntil: {
    type: String,
    default: null,
    validator(value) {
      return value === null || dayjs(value).isValid()
    },
  },
})

const visible = computed(() => {
  if (props.displayUntil === null) {
    return true
  }

  const now = dayjs()
  const displayUntil = dayjs(props.displayUntil)

  return now.isBefore(displayUntil)
})
</script>

<template>
  <span v-if="visible" class="rounded-full bg-green-200 px-2 text-xs text-green-700">
    {{ $t('common.new') }}
  </span>
</template>
