<template>
  <LzAccordion
    v-if="mergedLessonPlans.length > 0"
    id="merged-lesson-plans-accordion"
    :label="`${mergedLessonPlans.length} ${$t('lz_content_modal.lz_content_modal.items_selected')}`"
    class="mb-4"
  >
    <template #head="{ label, toggle, isOpen }">
      <a href="#" class="outline-none group flex items-center justify-between py-3 pl-2 pr-4" @click.prevent="toggle">
        <div class="flex items-center gap-2 font-bold">
          <AccordionIcon :is-open="isOpen" />
          {{ label }}
        </div>
        <strong v-if="hasTooManyCards" class="red">
          {{ $t('action_menu.cards_selected', { number: totalCards }) }}
        </strong>
        <span v-else class="text-base text-sm">{{ $t('action_menu.cards_selected', { number: totalCards }) }}</span>
      </a>
    </template>
    <ul class="no-bullet mt-4">
      <li v-for="lessonPlan in mergedLessonPlans" :key="lessonPlan.id" class="lzui-tile mb-2 flex items-center p-2">
        <div style="flex: 1">
          <h5 :title="lessonPlan.title || lessonPlan.name" class="m-0 inline">
            {{ lessonPlanName(lessonPlan) }}
          </h5>

          <span class="small ml-2">
            ({{ `${lessonPlan.cardCount} ${$t('action_menu.cards').toLocaleLowerCase()}` }})
          </span>
        </div>
        <LzIcon
          path="icons/close"
          title="Remove"
          class="cursor-pointer text-red-500 transition-colors hover:text-red-600"
          style="min-width: unset"
          @click="$emit('remove', lessonPlan)"
        />
      </li>
    </ul>
  </LzAccordion>
</template>

<script>
import { truncate } from 'lodash'
import { LzAccordion, LzIcon } from 'vue_features/shared/components/ui'
import AccordionIcon from 'vue_features/shared/components/ui/AccordionIcon'

export default {
  name: 'MergedPlansAccordion',
  components: { AccordionIcon, LzAccordion, LzIcon },
  props: { mergedLessonPlans: { type: Array, required: true }, totalCards: { type: Number, required: true } },
  computed: {
    hasTooManyCards() {
      return this.totalCards > 80
    },
  },
  methods: {
    lessonPlanName(lessonPlan) {
      const name = lessonPlan.title || lessonPlan.name
      return truncate(name, { length: 60, separator: ' ' })
    },
  },
}
</script>
