<template>
  <LearnosityItemEditor v-if="isCreatingOrEditing" :adaptation="adaptation" />
  <LearnosityItemSelector
    v-else
    @create-new="switchToCreatorComponent"
    @reuse-item="onReuseItem"
    @list-items="onListItems"
  />
</template>

<script>
import { computed, onUnmounted, watchEffect, inject } from 'vue'
import LearnosityItemEditor from './LearnosityItemEditor'
import LearnosityItemSelector from './LearnosityItemSelector'
import { isEmpty, debounce, some } from 'lodash'
import { useLearnosityItemEditorStore } from '../composables/use_learnosity_item_editor_store'

export default {
  name: 'LearnosityForm',
  components: { LearnosityItemSelector, LearnosityItemEditor },
  props: {
    lessonPlan: { type: Object, default: null },
    cards: { type: Array, default: () => [] },
  },
  setup(props) {
    const root = inject('useRoot')()
    const {
      item,
      isItemEditable,
      isCreatingOrEditing,
      client,
      updateItem,
      updateClient,
      errors,
      addError,
      resetErrors,
      setEmptyQuestionError,
    } = useLearnosityItemEditorStore()

    setEmptyQuestionError(root.$t('learnosity_item_editor.errors.empty_question'))

    if (item.value.content) {
      isCreatingOrEditing.value = true
    }

    const onReuseItem = () => {
      updateItem(Object.assign({ reused: true }, client.value.toItem()))
      updateErrors()
    }

    const switchToCreatorComponent = () => {
      updateItem({ editable: true })
      isCreatingOrEditing.value = true
    }

    const onListItems = () => {
      isCreatingOrEditing.value = false
      updateItem({ content: null })
      resetErrors()
      addError(root.$t('learnosity_item_editor.errors.no_item_selected'))
    }

    const adaptation = computed(() => {
      return item.value.adaptation || !!(props.lessonPlan && props.lessonPlan.adaptation)
    })

    const isAlreadyIncluded = (itemReference) => {
      if (item.value.id) {
        return false
      }

      return some(props.cards, ['content.content', itemReference])
    }

    const updateErrors = debounce(
      () => {
        if (!client.value) return

        resetErrors()
        const item = client.value.toItem()
        const noItemSelected = !item
        const isTitleMissing = !noItemSelected && isEmpty(item.title)
        const alreadyIncluded = !noItemSelected && isAlreadyIncluded(item.content)

        if (noItemSelected) {
          addError(root.$t('learnosity_item_editor.errors.no_item_selected'))
        } else if (isTitleMissing) {
          addError(root.$t('learnosity_item_editor.errors.title_missing'))
        } else if (alreadyIncluded) {
          addError(root.$t('learnosity_item_editor.errors.already_included'))
        }
      },
      { leading: true },
    )

    watchEffect(() => {
      if (client.value) {
        client.value.on('itemedit:changed', updateErrors)
      }
    })

    onUnmounted(() => {
      updateClient(null)
    })

    return {
      item,
      isItemEditable,
      errors,
      isCreatingOrEditing,
      onReuseItem,
      switchToCreatorComponent,
      onListItems,
      adaptation,
    }
  },
}
</script>
