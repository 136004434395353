<template>
  <section id="contact-section">
    <form @submit.prevent="handleSubmit" @change="clearMessages">
      <div v-if="!isLoggedIn" class="smaller py-4 italic">
        {{ $t('form.all_fields_required') }}
      </div>
      <div class="mb-6 space-y-6">
        <div>
          <label for="contact_name">
            {{ $t('form.your_name') }}
          </label>
          <input
            id="contact_name"
            v-model="name"
            :placeholder="$t('form.enter_your_name')"
            required="true"
            type="text"
          />
        </div>
        <div>
          <label for="contact_email">
            {{ $t('form.your_email_address') }}
          </label>
          <p class="help-block mb-2 mt-0 p-0">
            {{ $t('form.age_consent') }}
          </p>
          <input
            id="contact_email"
            v-model="email"
            :placeholder="$t('form.enter_your_email')"
            required="true"
            type="email"
          />
        </div>
        <div v-if="!hasFeedbackTarget">
          <label for="subject">{{ $t('form.subject') }}</label>
          <select id="subject" v-model="subject" required="true">
            <option disabled value="">-- {{ $t('form.choose_one') }} --</option>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <option v-for="option in subjectOptions" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div>
          <label for="contact_message">{{ $t('form.message') }}</label>
          <p class="help-block mb-2 mt-0 p-0">
            {{ $t('form.technical_issue') }}
          </p>
          <textarea id="contact_message" v-model="message" required="true" style="min-height: 130px"></textarea>
        </div>
        <AttachmentsInput v-if="isLoggedIn" v-model="attachments" />
        <div v-if="!isLoggedIn">
          <Recaptcha v-model:verified-token="verifiedToken" />
        </div>
        <div :class="{ 'flex items-center justify-end': allowCancel }">
          <button
            v-if="allowCancel && !submitting"
            class="text-accent hover:text-primary-600 mr-2 cursor-pointer"
            @click="$emit('canceled')"
          >
            {{ $t('cancel') }}
          </button>
          <button
            :class="{ disabled: submitDisabled, 'btn--updating': submitting, 'btn--primary': !submitting }"
            type="submit"
            :disabled="submitDisabled"
            class="btn my-2 mr-2"
          >
            <div v-if="submitting" class="icon icon-l">
              <LoadingSpinner tag="span" path="icons/loading-spinner" />
            </div>
            {{ submitting ? `${$t('submitting')}...` : $t('submit') }}
          </button>
          <div v-if="error || submittedSuccessfully" class="submit-status" style="display: inline-block">
            <div v-if="error" class="lzui-tile m-0 border-red-200 bg-red-50 p-2">
              <span style="color: #9e2f0a">
                <b>{{ $t('short_generic_error') }}</b>
              </span>
            </div>
            <div v-if="submittedSuccessfully" class="lzui-tile m-0 border-green-200 bg-green-50 p-2">
              <span class="text-green">
                <b>{{ $t('form.thanks_for_feedback') }}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import AttachmentsInput from './AttachmentsInput'
import { LoadingSpinner } from 'vue_features/shared/components/ui'
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import { Recaptcha } from 'vue_features/shared/components/forms'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { ref } from 'vue'
import { ZENDESK_BASE_URL } from 'environment'
import Routes from 'routes'

export const FORM_STATUS = {
  DEFAULT: 0,
  SUBMITTING: 1,
  SUBMITTED_SUCCESSFULLY: 2,
  ERROR: 3,
}
const ZENDESK_UPLOADS_URL = `${ZENDESK_BASE_URL}/api/v2/uploads.json`

export default {
  name: 'ContactForm',
  components: {
    AttachmentsInput,
    LoadingSpinner,
    Recaptcha,
  },
  props: {
    allowCancel: {
      type: Boolean,
      default: false,
    },
    feedbackTargetId: {
      type: Number,
      default: null,
    },
    feedbackTargetType: {
      type: String,
      default: null,
    },
    collectionName: {
      type: String,
      default: '',
    },
    contentOwner: {
      type: String,
      default: '',
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { subdomain } = useCurrentCustomerStore()
    const { isLoggedIn, name: userName, email: userEmail } = useCurrentUserStore()
    const name = ref(userName.value)
    const email = ref(userEmail.value)
    return {
      subdomain,
      isLoggedIn,
      name,
      email,
    }
  },
  data() {
    return {
      subject: '',
      message: '',
      formStatus: FORM_STATUS.DEFAULT,
      verifiedToken: null,
      attachments: [],
    }
  },
  computed: {
    submitDisabled() {
      return this.submitting || !this.verifiedTokenValid
    },
    verifiedTokenValid() {
      return this.isLoggedIn || this.verifiedToken
    },
    hasFeedbackTarget() {
      return this.feedbackTargetId !== null
    },
    feedbackTargetUrl() {
      if (this.hasFeedbackTarget) {
        const routeName = `${this.feedbackTargetType}_url`
        return Routes[routeName](this.feedbackTargetId)
      } else {
        return ''
      }
    },
    submitting() {
      return this.formStatus === FORM_STATUS.SUBMITTING
    },
    submittedSuccessfully() {
      return this.formStatus === FORM_STATUS.SUBMITTED_SUCCESSFULLY
    },
    error() {
      return this.formStatus === FORM_STATUS.ERROR
    },
    subjectOptions() {
      return Object.entries(this.$tm('form.subject_options')).map(([key, translation]) => {
        // Send the subject to our support team in English
        const friendlyKey = key.replace('_', ' ').replace(/./, (match) => match.toUpperCase())
        return { label: translation, value: friendlyKey }
      })
      /* return Object.values(this.$t('form.subject_options')) */
    },
  },
  mounted() {
    this.setSubject()
  },

  methods: {
    async handleSubmit() {
      this.formStatus = FORM_STATUS.SUBMITTING

      // Once we upload the first file to Zendesk, we get back a token. When we
      // send that token in subsequent requests, it groups the files together.
      // Then we include the token in the ticket creation request and Zendesk
      // attaches the whole group of files to the ticket.
      let uploadToken = null
      for (const attachment of this.attachments) {
        const data = await attachment.arrayBuffer()
        let uploadsUrl = `${ZENDESK_UPLOADS_URL}?filename=${attachment.name}`
        if (uploadToken) {
          uploadsUrl = `${uploadsUrl}&token=${uploadToken}`
        }
        try {
          const response = await HTTP.ajax(uploadsUrl, {
            method: 'POST',
            dataType: 'json',
            headers: { 'Content-Type': 'application/binary' },
            body: data,
          })
          uploadToken = response.upload.token
        } catch (error) {
          this.formStatus = FORM_STATUS.ERROR
        }
      }

      const url = Routes.contact_form_submit_path()
      const data = {
        encoding: 'UTF-8',
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        feedback_target_url: this.feedbackTargetUrl,
        customer_subdomain: this.subdomain,
        collection_name: this.collectionName,
        content_owner: this.contentOwner,
        recaptcha_token: this.verifiedToken,
        upload_token: uploadToken,
        ...this.additionalData,
      }

      try {
        await HTTP.create(url, data)
        this.resetForm()
        this.formStatus = FORM_STATUS.SUBMITTED_SUCCESSFULLY
        this.$emit('success')
      } catch {
        this.formStatus = FORM_STATUS.ERROR
      }
    },
    setSubject() {
      this.subject = this.hasFeedbackTarget ? this.$t('form.subject_options.content_feedback') : ''
    },
    resetForm() {
      this.message = ''
      this.setSubject()
      this.attachments = []
    },
    clearMessages() {
      this.formStatus = FORM_STATUS.DEFAULT
    },
  },
}
</script>
