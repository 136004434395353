<template>
  <div>
    <StudentDataRecurringExport
      v-for="(recurringExport, index) in recurringExports"
      :key="index"
      :student-data-recurring-export="recurringExport"
      @remove="removeExport(index)"
      @startEdit="startEdit"
      @finishEdit="finishEdit"
    />
    <LzButton
      v-if="canAddNewExport"
      type="button"
      data-test="add-export-button"
      color="primary"
      :disabled="newExport"
      @click="addExport"
    >
      {{ $t('student_data_recurring_export.create_export') }}
    </LzButton>
  </div>
</template>

<script>
import { LzButton } from 'vue_features/shared/components/ui'
import StudentDataRecurringExport from './StudentDataRecurringExport'

export default {
  name: 'StudentDataRecurringExportsList',
  components: {
    LzButton,
    StudentDataRecurringExport,
  },
  props: {
    studentDataRecurringExports: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const recurringExports = this.studentDataRecurringExports
    const newExport = false

    return {
      recurringExports,
      newExport,
      lessonTypes: this.availableLessonTypes,
    }
  },
  computed: {
    canAddNewExport() {
      return this.recurringExports.length < 2
    },
  },
  methods: {
    addExport() {
      this.recurringExports.push({ id: null })
      this.newExport = true
    },
    removeExport(index) {
      this.recurringExports.splice(index, 1)
      this.newExport = false
    },
    startEdit() {
      this.newExport = true
    },
    finishEdit() {
      this.newExport = false
    },
  },
}
</script>
