<template>
  <div class="w-full">
    <div class="border-primary-100 mb-4 rounded-2xl border shadow-sm">
      <LoadMoreTable
        v-if="studentTotalCount > 0"
        v-bind="{ items: students, totalCount: studentTotalCount }"
        class="mb-0 table-fixed"
        :start-count="20"
        @load-more="handleLoadMore"
      >
        <template #table-head>
          <tr class="text-md font-bold">
            <th class="border-0 px-4 py-2">Name</th>
            <th class="border-0 px-4 py-2">Username</th>
            <th class="border-0 px-4 py-2"></th>
          </tr>
        </template>
        <template #table-rows="{ item: student }">
          <tr
            :data-test="`people-student-list-row-${student.id}`"
            :class="student.isFromClever ? 'bg-base' : 'bg-focus'"
          >
            <td
              :title="student.archived ? $t('common.user_archived_notice') : null"
              class="gray-900 h-12 border-0 border-t px-4 align-middle text-sm font-bold"
            >
              <span class="space-x-2">
                {{ student.name }}
                <template v-if="student.archived">
                  <span class="inline-flex items-center rounded-full bg-gray-400 px-2 py-1 text-xs text-white">
                    <LzIcon path="icons/archive" />
                    <span>{{ $t('klasses.archived_by_admin') }}</span>
                  </span>
                </template>
              </span>
            </td>
            <td class="gray-600 h-12 border-0 border-t px-4 align-middle text-sm font-normal">
              <span>
                {{ student.username }}
              </span>
            </td>
            <td class="h-12 border-0 border-t px-4">
              <div class="flex items-center justify-end gap-2">
                <StudentLinkGoogleDropdown
                  v-if="!student.archived && showGoogleLink && lzStudentForStudent(student)"
                  data-test="student-link-google-dropdown"
                  :student="lzStudentForStudent(student)"
                  :course="klass.googleClassroomCourse"
                  @google-classroom-linked-student="handleLinkedStudentChange"
                  @google-classroom-unlinked-student="handleLinkedStudentChange"
                />
                <StudentEditButton :student="student" :klass-is-from-clever="klass.isFromClever" />
                <LzIcon
                  v-if="klass.isFromClever && student.isFromClever"
                  data-test="clever-image"
                  path="icons/synced-arrows"
                  size="sm"
                  class="text-neutral-600"
                />
              </div>
            </td>
          </tr>
        </template>
        <template #button="{ canLoadMore, isLoading, showMoreItems }">
          <tr v-if="canLoadMore" class="cursor-pointer bg-white">
            <td colspan="3" class="center gray-900 h-12 border-0 border-t px-4 align-middle text-sm font-bold">
              <button
                v-if="!isLoading"
                type="button"
                class="text-accent hover:text-accent-hover font-bold"
                @click="showMoreItems(handleLoadMore)"
              >
                {{ $t('common.load_more') }}
              </button>
            </td>
          </tr>
        </template>
      </LoadMoreTable>
    </div>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import LoadMoreTable from 'vue_features/shared/components/lists/LoadMoreTable'
import StudentLinkGoogleDropdown from './StudentLinkGoogleDropdown'
import StudentEditButton from './StudentEditButton'
import { useKlassShowStore } from '../store'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'PeopleStudentList',
  components: {
    LzIcon,
    LoadMoreTable,
    StudentLinkGoogleDropdown,
    StudentEditButton,
  },
  props: {
    students: {
      type: Array,
      required: true,
    },
    studentTotalCount: {
      type: Number,
      required: true,
    },
    klass: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const { loadMoreStudents, reloadKlass } = useKlassShowStore()
    const { lzStudentsData, isConnectedToGoogle, loadLzStudents } = useGoogleClassroomStore()
    return { loadMoreStudents, reloadKlass, lzStudentsData, isConnectedToGoogle, loadLzStudents }
  },
  computed: {
    showGoogleLink() {
      return this.klass && this.isConnectedToGoogle && this.klass.googleClassroomCourse && this.lzStudentsData
    },
  },
  methods: {
    handleLoadMore(done) {
      return this.loadMoreStudents().then(done)
    },
    handleLinkedStudentChange() {
      this.loadLzStudents({ force: true })
      this.reloadKlass({ klass: this.klass })
    },
    lzStudentForStudent(student) {
      if (!this.lzStudentsData) return null

      return this.lzStudentsData.find((lzStudent) => lzStudent.id === student.id)
    },
  },
}
</script>
