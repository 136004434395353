<template>
  <div class="form-group">
    <LearnosityAuthorWrapper :config="config" @client-updated="onClientUpdated" />
  </div>
</template>

<script>
import { useLearnosityItemEditorStore } from '../composables/use_learnosity_item_editor_store'
import LearnosityAuthorWrapper from './LearnosityAuthorWrapper'
import { inject } from 'vue'

export default {
  name: 'LearnosityItemSelector',
  components: { LearnosityAuthorWrapper },
  setup(props, { emit }) {
    const learnosityConfig = inject('learnosityConfig')
    const { client, clientReady } = useLearnosityItemEditorStore()
    clientReady.value = false

    const onClientUpdated = () => {
      client.value.on('render:itemlist', () => (clientReady.value = true))
      client.value.on('navigate', (lrnoEvent) => {
        const route = lrnoEvent.data.route
        if (route === 'items/new') {
          lrnoEvent.preventDefault()
          emit('create-new')
        } else if (route === 'items' || route.startsWith('items/search/')) {
          emit('list-items')
        }
      })
      client.value.on('render:item', () => {
        emit('reuse-item')
      })
      client.value.beforeSave = () => {}
      clientReady.value = true
    }

    return { clientReady, config: learnosityConfig.value.forSelection, onClientUpdated }
  },
}
</script>
