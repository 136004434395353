<template>
  <LzModal
    :modal-title="$t('modals.merge_schools.title')"
    :modal-id="$options.name"
    class="lzui-modal--medium"
    :autoclose="false"
    @close-modal="hideModal"
  >
    <i18n-t tag="p" keypath="modals.merge_schools.message" scope="global" class="my-2">
      <template #destinationSchool>
        <strong>{{ school.name }}</strong>
      </template>
    </i18n-t>
    <v-select
      v-model.number="selectedId"
      :options="mergeableSchools(school.id)"
      :reduce="(opt) => opt.id"
      label="name"
      index="id"
      :placeholder="$t('modals.search_schools')"
    />
    <footer>
      <button class="btn" @click.stop="hideModal">
        {{ $t('common.cancel') }}
      </button>
      <button class="btn btn--primary" @click="merge">
        {{ $t('common.merge') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { ref, inject } from 'vue'
import { LzModal } from 'vue_features/shared/components/ui'
import vSelect from 'vue-select'
import useSchoolModals from './use_school_modals.js'
import { useSiteAdminSchoolsStore } from '../../store/use_site_admin_schools_store'

const NAME = 'SchoolModalMerge'

export default {
  name: NAME,
  components: { LzModal, vSelect },
  setup(_, { emit }) {
    const selectedId = ref(null)
    const root = inject('useRoot')()
    const { mergeableSchools, mergeSchools } = useSiteAdminSchoolsStore()
    const { school, hideModal } = useSchoolModals(NAME)
    const merge = async () => {
      await mergeSchools({ targetSchoolId: school.value.id, sourceSchoolId: selectedId.value })
      hideModal()
      emit('show-notice', root.$t('index.success_merge_schools'))
    }

    return { selectedId, mergeableSchools, mergeSchools, school, hideModal, merge }
  },
  methods: {},
}
</script>
