import { ref } from 'vue'

const deletedBlocks = ref([])

export default function useDeletedBlocks() {
  function reset() {
    deletedBlocks.value = []
  }

  function addDeletedBlock(block) {
    if (block.id && !deletedBlocks.value.find((b) => b.id === block.id)) {
      deletedBlocks.value.push(block)
      if (block.content.blocks) {
        block.content.blocks.forEach((b) => addDeletedBlock(b))
      }
    }
  }

  return { reset, deletedBlocks, addDeletedBlock }
}
