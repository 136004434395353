<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="space-y-2">
    <p class="align-left bold m-0 text-xs">
      {{ $t('print_dialog.range') }}
    </p>
    <ListDropdown id="range-select" class="relative">
      <template #button>
        <span v-if="range === 'all'">{{ $t('print_dialog.all_cards') }}</span>
        <span v-if="range === 'current'">{{ $t('print_dialog.current_card') }}</span>
        <LzIcon path="icons/caret" />
      </template>
      <template #listItems>
        <li class="select-print-range-all" @click="range = 'all'">
          <a href="#" @click.prevent>
            {{ $t('print_dialog.all_cards') }}
          </a>
        </li>
        <li class="select-print-range-current" @click="range = 'current'">
          <a href="#" @click.prevent>
            {{ $t('print_dialog.current_card') }}
          </a>
        </li>
      </template>
    </ListDropdown>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import { usePrintSettingsStore } from '../store/use_print_settings_store'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import messages from '../messages'

export default {
  components: { LzIcon, ListDropdown },
  i18n: { messages },
  setup() {
    const { range } = usePrintSettingsStore()
    return { stateRange: range }
  },
  computed: {
    range: {
      get() {
        return this.stateRange
      },
      set(range) {
        this.stateRange = range
      },
    },
  },
}
</script>
