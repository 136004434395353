<template>
  <div ref="container" class="lottie-animation" :class="{ loading: isLoading, 'has-error': !!loadError }" />
</template>

<script>
import { ref, toRefs, watch, onMounted, onUnmounted, computed } from 'vue'
import lottie from 'lottie-web'
import { useAsync } from 'vue_features/shared/composables'

export default {
  name: 'LottieAnimation',
  props: {
    data: {
      type: [Function, Promise],
      required: true,
    },
    speed: {
      type: Number,
      default: 1,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    teardownSignal: {
      type: Object,
      default: null,
    },
    rendererSettings: {
      type: Object,
      default: () => ({
        scaleMode: 'centerCrop',
        clearCanvas: true,
        progressiveLoad: false,
        hideOnTransparent: true,
      }),
    },
  },
  setup(props, { emit }) {
    const { speed, autoplay, loop, rendererSettings } = toRefs(props)
    const isLoading = ref(true)
    const container = ref(null)
    const animation = ref(null)

    /*
    HACK:
    We need to deep clone the animation data here in order to work around a major performance issue.
    Lottie mutates the animationData objects it's passed. When these objects contain repeater directives,
    it causes Lottie to balloon their size on subsequent initializations if the same data object is passed in
    (which it is due to how dynamic imports work in Webpack). Even though we clean up and re-initialize Lottie
    itself on each mount, it will reuse the same mutated data object and further mutate it, which means it can
    grow by orders of magnitude each time.
    We can run into this issue e.g. using vue-router when moving between different tabs that show animations.

    See: https://github.com/airbnb/lottie-web/issues/2070
    */
    const { data: animationData, error: loadError } = useAsync(props.data)
    const animationDataCleanCopy = computed(() => JSON.parse(JSON.stringify(animationData.value)))

    watch([animationData, container, speed, autoplay, rendererSettings], () => {
      // If an animation exists and we're re-creating it due to reactivity changes, tear down the old one first
      if (animation.value) {
        isLoading.value = true
        animation.value.destroy()
      }

      animation.value = lottie.loadAnimation({
        container: container.value,
        renderer: 'svg',
        loop: loop.value,
        autoplay: autoplay.value,
        animationData: animationDataCleanCopy.value,
        rendererSettings: rendererSettings.value,
      })

      animation.value.setSpeed(speed.value)

      isLoading.value = false

      emit('animation-ready', animation.value)

      if (props.autoplay) {
        emit('play', animation.value)
        animation.value.play()
      }
    })

    const teardown = () => {
      if (animation.value) {
        animation.value.destroy()
      }
    }

    onMounted(() => {
      if (props.teardownSignal) {
        props.teardownSignal.onEmit(teardown)
      }
    })

    onUnmounted(() => {
      if (!animation.value) return
      if (props.teardownSignal) return
      animation.value.destroy()
    })

    return {
      container,
      loadError,
      isLoading,
      animation,
    }
  },
}
</script>
