<template>
  <div v-if="errors.length" class="error error--page">
    <h2 class="align-left">{{ $t('error_messages.general') }}</h2>
    <ul class="align-left small mb-0 pl-6">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SettingsErrors',
  props: { errors: { type: Array, required: true } },
}
</script>
