<template>
  <div id="klasses-index" :class="['lg:pb-120 flex-1 bg-contain bg-bottom bg-no-repeat pb-36 md:pb-96', season]">
    <PageHead />
    <div class="page-head-nav">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <LzTabs>
          <TabLink
            :label="authorizedAsTeacher ? $t('common.active') : $t('student_klasses.index.my_classes')"
            target="active-classes"
          />
          <TabLink :label="$t('common.archived')" target="archived-classes" />
        </LzTabs>
      </div>
    </div>
    <div class="page-content">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <KlassCreateModal v-if="canModalShow($options.KlassCreateModal)" class="text-left" />
        <KlassesTab klass-type="active" />
        <KlassesTab klass-type="archived" />
        <template v-if="authorizedAsTeacher && (hasClever || hasSingleIntegration)">
          <div class="mt-12 flex flex-row space-x-2">
            <div class="mb-4 flex items-center gap-2" data-test="clever-legend">
              <LzIcon path="icons/synced-arrows" size="sm" />
              <span>{{ $t('klasses.index.synced_from_sis') }}</span>
              <a :href="$options.helpUrl">{{ $t('common.learn_more') }}</a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { LzTabs, TabLink, LzIcon } from 'vue_features/shared/components/ui'
import { HELP_CLEVER_LOGIN_URL } from 'rails_constants'
import PageHead from './PageHead'
import KlassesTab from './KlassesTab'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { currentSeason } from 'vue_features/klasses/shared/utils/dates'
import { KlassCreateModal } from '../../shared/components/modals'
import { canModalShow } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'KlassesIndex',
  helpUrl: HELP_CLEVER_LOGIN_URL,
  KlassCreateModal,
  components: { LzTabs, TabLink, PageHead, KlassesTab, KlassCreateModal, LzIcon },
  setup() {
    const { authorizedAsTeacher } = useCurrentUserStore()
    const { hasClever, hasSingleIntegration } = useCurrentCustomerStore()

    return {
      authorizedAsTeacher,
      season: currentSeason(),
      canModalShow,
      hasClever,
      hasSingleIntegration,
    }
  },
}
</script>
