<template>
  <Ckeditor
    :content="text"
    :options="ckeditorOptions"
    type="div"
    :class="['slide__text', ...backgroundCss]"
    contenteditable="true"
    @change="updateText"
    @created-editor="$emit('update', { ckeditorInstance: $event })"
  />
</template>

<script>
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import { ref, computed } from 'vue'
import { CKEDITOR_OPTIONS } from './ckeditor_options'

export default {
  name: 'SlideEditorText',
  components: { Ckeditor },
  props: { content: { type: Object, required: true } },
  setup(props, { emit }) {
    const text = ref(props.content.text || '')
    const backgroundCss = computed(() => {
      return [`slide__text--${props.content.background}`, { slide__caption: props.content.isCaption }]
    })

    const ckeditorOptions = computed(() => {
      const options = { ...CKEDITOR_OPTIONS }
      options.options = { ...options.options }
      if (props.content.isCaption) {
        options.options.fontSize_sizes = '11 Attribution/fs-xsmall;20 Small/fs-small'
        options.options.fontSize_defaultLabel = '11 Attribution'
      }
      return options
    })

    function updateText(newText) {
      text.value = newText
      emit('update', { text: newText })
    }

    return { text, backgroundCss, ckeditorOptions, updateText }
  },
}
</script>
