import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const SiteAdminSingleIntegrationService = {
  update(params) {
    if (params.siteAdminSingleIntegrationSettings.persisted) {
      return HTTP.put(Routes.site_admin_single_integration_path(), snakeKeys(params))
    } else {
      return HTTP.create(Routes.site_admin_single_integration_path(), snakeKeys(params))
    }
  },
}

export default SiteAdminSingleIntegrationService
