import { get } from 'lodash'

export default function useCardPersistable(context) {
  const didCardChange = (original, current) => {
    return (
      !current.id ||
      original.title !== current.title ||
      original.contentId !== (get(current.content, 'id') || current.contentId) ||
      original.voiceoverId !== current.voiceoverId ||
      original.type !== current.type ||
      original.checkableAnswers !== current.checkableAnswers
    )
  }

  const getCardParams = (card) => {
    return {
      card: {
        rank: card.rank,
        contentId: card.contentId,
        title: card.title,
        type: card.type,
        contexts: card.contexts,
        checkableAnswers: card.checkableAnswers,
        teachingNotes: card.teachingNotes,
        context,
      },
      includesCard: card.includesCard,
    }
  }

  return { didCardChange, getCardParams }
}
