import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const AssignmentsService = {
  createAssignment(lessonPlanId) {
    return HTTP.create(Routes.lz_cdes_path(), { lzCode: { lessonPlanIds: [lessonPlanId] } })
  },

  assignStudents({ lessonPlanIds, klassIds, studentsByKlassId, klassesToAssignToGoogle, autoOpen }) {
    const attrs = { lzCode: { lessonPlanIds, klassIds, studentsByKlassId, klassesToAssignToGoogle, autoOpen } }
    return HTTP.create(Routes.lz_codes_path(), snakeKeys(attrs))
  },

  getAssignments({ klassId, assignmentType, nextPage }) {
    const url = Routes.lz_codes_path()
    const attrs = { klassId, state: assignmentType, page: nextPage, lessonType: true, activities: true }
    return HTTP.getJSON(url, snakeKeys(attrs))
  },

  updateAssignmentActiveForKlass({ lzCodeId, id, isActive }) {
    const url = Routes.lz_code_lz_code_klass_path(lzCodeId, id)
    const attrs = {
      lzCodeKlass: {
        active: isActive,
      },
    }
    return HTTP.put(url, snakeKeys(attrs))
  },

  deleteAssignmentForKlass({ lzCodeId, id }) {
    return HTTP.delete(Routes.lz_code_lz_code_klass_path(lzCodeId, id))
  },

  archiveAssignments(createdBefore, type) {
    const url = Routes.assignments_archive_path()
    return HTTP.put(url, snakeKeys({ createdBefore, type }))
  },

  getItemPoolStatus(id) {
    return HTTP.getJSON(Routes.lz_code_item_pool_status_path(id))
  },

  autoOpenKlass({ lzCode, klassId }) {
    return HTTP.put(Routes.lz_code_path(lzCode), snakeKeys({ lzCode: { klassIds: [klassId], autoOpen: true } }))
  },
}

export default AssignmentsService
