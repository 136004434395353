<template>
  <LinkErrorModal v-if="isActive && canModalShow($options.LinkErrorModal)" />
</template>

<script>
import LinkErrorModal from 'vue_features/shared/components/google_classroom/LinkErrorModal'
import { openModal, canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'LinkErrorListener',
  LinkErrorModal,
  components: {
    LinkErrorModal,
  },
  setup() {
    const { linkError, registerLinkErrorListener } = useGoogleClassroomStore()
    return { linkError, registerLinkErrorListener, openModal, canModalShow, closeModal }
  },
  data() {
    return {
      isActive: false,
    }
  },
  watch: {
    linkError() {
      if (this.isActive && this.linkError) {
        this.openModal(this.$options.LinkErrorModal)
      }
    },
  },
  async mounted() {
    this.isActive = await this.registerLinkErrorListener()
  },
}
</script>
