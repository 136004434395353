import StudentDataReportsService from '../api'
import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'

export const useStudentDataReportsStore = defineStore('studentDataReports', () => {
  const state = reactive({
    studentDataReports: [],
    pageCount: 0,
    reportScopes: [],
    scoreScopes: [],
  })

  const reportsByStatus = computed(() => (status) => {
    return state.studentDataReports.filter((report) => report.status === status)
  })
  const activeReports = computed(() => reportsByStatus.value('active'))
  const generatingReports = computed(() => reportsByStatus.value('generating'))
  const reportList = computed(() => {
    // sort by id, newest first
    const active = activeReports.value.sort((a, b) => b.id - a.id)
    const generating = generatingReports.value.sort((a, b) => b.id - a.id)
    return [...generating, ...active]
  })

  function createStudentDataReport(payload) {
    return StudentDataReportsService.createReport(payload).then((report) => {
      state.studentDataReports.push(report)
    })
  }

  function deleteStudentDataReport(id) {
    return StudentDataReportsService.deleteReport(id).then(() => {
      const index = state.studentDataReports.findIndex((report) => report.id === id)
      state.studentDataReports.splice(index, 1)
    })
  }

  function fetchReports(page) {
    return StudentDataReportsService.getReports(page).then((response) => {
      state.pageCount = response.meta.pagination.totalPages
      state.studentDataReports = response.studentDataReports
    })
  }

  return {
    state,
    activeReports,
    generatingReports,
    reportList,
    createStudentDataReport,
    deleteStudentDataReport,
    fetchReports,
  }
})
