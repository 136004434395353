<template>
  <div class="question__check-answers space-x-4">
    <button
      v-if="isAutoscorable && !isCorrect"
      type="button"
      :class="['btn btn--primary', { 'disabled btn--updating': isChecking }]"
      data-test="check-button"
      @click="checkAnswers"
    >
      <span v-if="!isChecking">{{ $t('check_answers_button.check_answers') }}</span>
      <template v-else>
        <LzIcon type="div" size="lg" path="icons/loading-spinner" />
        {{ $t('common.loading') }}...
      </template>
    </button>
    <div
      v-if="isIncorrect"
      class="text-orange-600 text-orange flex items-center gap-2 text-lg font-bold italic"
      data-test="incorrect"
    >
      <span class="bg-orange-600 flex h-8 w-8 rounded-full p-1 text-white">
        <InlineSvg class="h-full w-full" path="icons/close" />
      </span>
      {{ $t('check_answers_button.answers_incorrect') }}
    </div>
    <div v-if="isCorrect" class="flex items-center gap-2 text-lg font-bold italic text-green-600" data-test="correct">
      <span class="flex h-8 w-8 rounded-full bg-green-600 p-1 text-white">
        <InlineSvg class="h-full w-full" path="icons/check" />
      </span>
      {{ $t('check_answers_button.answers_correct') }}
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { LzIcon, InlineSvg } from 'vue_features/shared/components/ui'
import useLearnosityItemResource from '../composables/use_learnosity_item_resource'

const STATES = {
  notAnswered: 'not_answered',
  checking: 'checking',
  correct: 'correct',
  incorrect: 'incorrect',
}

export default {
  name: 'CheckAnswersButton',
  components: { LzIcon, InlineSvg },
  props: {
    itemReference: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isAutoscorable, hasCorrectlyAnswered } = useLearnosityItemResource(props.itemReference, props.config)
    const responseState = ref(STATES.notAnswered)

    const isChecking = computed(() => responseState.value === STATES.checking)
    const isCorrect = computed(() => responseState.value === STATES.correct)
    const isIncorrect = computed(() => responseState.value === STATES.incorrect)

    function checkAnswers() {
      if (isChecking.value) return
      responseState.value = STATES.checking

      if (hasCorrectlyAnswered()) {
        responseState.value = STATES.correct
      } else {
        window.setTimeout(() => (responseState.value = STATES.incorrect), 200)
      }
    }

    return {
      isChecking,
      isCorrect,
      isIncorrect,
      checkAnswers,
      isAutoscorable,
    }
  },
}
</script>
