<template>
  <ul class="lzui-nav-tabs vue-tabs">
    <slot></slot>
    <li v-if="isSlotUsed('overflow')" class="lzui-nav-tabs__overflow">
      <button class="lzui-nav-tabs__overflow__toggle" type="button" @click="toggleOverflow">
        {{ $t('common.more') }} +
      </button>
      <ul :class="{ is_open: isOverflowVisible }" class="lzui-nav-tabs__overflow__tabs">
        <slot name="overflow"></slot>
      </ul>
    </li>
  </ul>
</template>

<script>
import { each, find } from 'lodash'
import { reactive, provide, onMounted, ref } from 'vue'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

export default {
  name: 'TabMenu',

  setup(_, { emit }) {
    const tabs = reactive({})
    const isOverflowVisible = ref(false)
    const registerTab = (target, tab) => {
      tabs[target] = tab
    }
    provide('registerWithTabParent', registerTab)

    const tabActivated = (target) => {
      each(tabs, (tab, key) => {
        if (key !== target) {
          tab.deactivate()
        }
      })

      isOverflowVisible.value = false
      emit('activated-tab', target)
    }
    provide('tabActivated', tabActivated)

    const activateInitial = () => {
      let active

      active = find(tabs, (tab) => !!tab.initial)

      if (!active) {
        active = tabs[Object.keys(tabs)[0]]
      }

      each(tabs, (tab) => {
        tab === active ? tab.activate() : tab.deactivate()
      })
    }

    onMounted(activateInitial)

    return { isOverflowVisible, isSlotUsed }
  },

  methods: {
    toggleOverflow() {
      this.isOverflowVisible = !this.isOverflowVisible
    },
  },
}
</script>
