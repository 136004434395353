<template>
  <div class="page-content">
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <div class="text-right">
        <div class="icon small">
          <InlineSvg path="icons/help" />
        </div>
        <a :href="singleIntegrationHelpUrl">{{ $t('single_integration_setup_help') }}</a>
      </div>

      <h3>{{ $t('app_id_heading') }}</h3>
      <div :class="{ error: appIdError }">
        <label v-if="appIdError" for="appIdInput">
          {{ $t('app_id') }}
          <span>{{ appIdError }}</span>
        </label>
        <input
          id="appIdInput"
          ref="appIdInput"
          class="mt-6"
          data-test="single-integration-app-id-field"
          :disabled="isSingleIntegrationConfigured || !canEdit"
          :value="appId"
          type="text"
          placeholder="Ex: 4fd43cc56d11340000000005"
          @input="handleAppIdChange"
        />
      </div>

      <h3>{{ $t('app_secret_heading') }}</h3>
      <div :class="{ error: appSecretError }">
        <label v-if="appSecretError" for="appSecretInput">
          {{ $t('app_secret') }}
          <span>{{ appSecretError }}</span>
        </label>
        <input
          id="appSecretInput"
          ref="appSecreInput"
          class="mt-6"
          data-test="single-integration-app-secret-field"
          :disabled="isSingleIntegrationConfigured || !canEdit"
          :value="appSecret"
          type="text"
          placeholder="Ex: 4fd43cc56d11340000000005"
          @input="handleAppSecretChange"
        />
      </div>

      <section v-if="isSingleIntegrationConfigured" data-test="data-syncing">
        <h3>{{ $t('data_syncing_heading') }}</h3>
        <p>
          <span>{{ $t('data_syncing_description') }}</span>
          <a :href="dataSyncingHelpUrl">{{ $t('common.learn_more') }}</a>
        </p>
        <div v-if="!mayConfigureDataSync">
          <Alert v-if="errorTitle" type="notice" data-test="last-sync-reason" :title="errorTitle" />
        </div>
        <div class="switch-toggle">
          <input
            id="dataSyncToggle"
            :disabled="!mayConfigureDataSync || !canEdit"
            :checked="syncActive"
            type="checkbox"
            class="switch-toggle__button"
            @change="toggleDataSync"
          />
          <label for="dataSyncToggle">{{ $t('common.off') }}</label>
          <label for="dataSyncToggle" class="pl-2">{{ $t('common.on') }}</label>
          <p class="weak small center m-6">{{ $t('last_sync') }} {{ timeAgo(lastSyncCompletedAt) }}</p>
        </div>
        <div class="switch-toggle">
          <input
            id="fullSyncToggle"
            :disabled="!mayConfigureDataSync || !canEdit"
            :checked="fullSync"
            type="checkbox"
            class="switch-toggle__button"
            @change="toggleFullSync"
          />
          <label for="fullSyncToggle">{{ $t('common.off') }}</label>
          <label for="fullSyncToggle" class="pl-2">{{ $t('common.on') }}</label>
          <p class="weak small center m-6">Full Sync</p>
        </div>
      </section>

      <section v-if="isSingleIntegrationConfigured" data-test="primary-auth-setting">
        <h3>{{ $t('primary_auth_heading') }}</h3>
        <p>{{ primaryAuthDescription }}</p>
        <div class="flex gap-x-4">
          <label for="authPrefIlClassroom">
            <input
              id="authPrefIlClassroom"
              :checked="authPreference === 'ilclassroom'"
              :disabled="!hasAppId || !canEdit"
              type="radio"
              value="ilclassroom"
              @change="authPreference = 'ilclassroom'"
            />
            {{ $t('primary_auth_default') }}
          </label>
          <label for="authPrefClasslink">
            <input
              id="authPrefClasslink"
              :checked="authPreference === 'classlink'"
              :disabled="!hasAppId || !canEdit"
              type="radio"
              value="classlink"
              @change="authPreference = 'classlink'"
            />
            Classlink
          </label>
          <label for="authPrefOneRoster">
            <input
              id="authPrefOneRoster"
              :checked="isOneRoster"
              :disabled="!hasAppId || !canEdit"
              type="radio"
              value="oneroster"
              @change="authPreference = 'oneroster'"
            />
            OneRoster
          </label>
        </div>
        <CollapseTransition>
          <div v-if="isOneRoster" class="space-y-4">
            <div>
              <label for="authPrefButtonName">
                <strong>{{ $t('button_name') }}</strong>
              </label>
              <input
                id="authPrefButtonName"
                ref="authPrefButtonNameInput"
                class=""
                data-test="single-integration-button-name-field"
                :value="buttonName"
                type="text"
                maxlength="40"
                :placeholder="$t('button_name_placeholder')"
                @input="handleButtonNameChange"
              />
            </div>
            <div>
              <label for="authPrefButtonUrl">
                <strong>{{ $t('button_url') }}</strong>
              </label>
              <input
                id="authPrefButtonUrl"
                ref="buttonUrlInput"
                class=""
                data-test="single-integration-button-url-field"
                :value="buttonUrl"
                type="text"
                :placeholder="$t('button_url_placeholder')"
                @input="handleButtonUrlChange"
              />
            </div>
          </div>
        </CollapseTransition>
      </section>

      <div class="my-12">
        <div v-if="generalError" class="error--inline">
          {{ generalError }}
        </div>
        <button
          :disabled="!maySubmit || saving || !canEdit"
          class="btn btn--primary"
          data-test="save"
          @click.prevent="saveChanges"
        >
          {{ $t('common.save_changes') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import Alert from 'vue_features/shared/components/ui/Alert'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import { useSiteAdminSingleIntegrationStore } from '../store/use_site_admin_single_integration_store'

export default {
  name: 'SiteAdminSingleIntegration',
  components: { InlineSvg, Alert, CollapseTransition },
  props: {
    dataSyncingHelpUrl: {
      type: String,
      required: true,
    },
    singleIntegrationHelpUrl: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      authPreference,
      authPreferenceOptions,
      maintenanceMode,
      appId,
      appSecret,
      persisted,
      syncActive,
      fullSync,
      anyRunningImports,
      lastSyncCompletedAt,
      toggleDataSync,
      toggleFullSync,
      update,
    } = useSiteAdminSingleIntegrationStore()
    return {
      authPreference,
      authPreferenceOptions,
      maintenanceMode,
      appId,
      appSecret,
      persisted,
      syncActive,
      fullSync,
      anyRunningImports,
      lastSyncCompletedAt,
      toggleDataSync,
      toggleFullSync,
      update,
    }
  },
  data() {
    return {
      appIdError: '',
      generalError: '',
      appSecretError: '',
      saving: false,
    }
  },
  computed: {
    errorTitle() {
      if (this.anyRunningImports) return this.$t('sync_locked.running')
      if (this.maintenanceMode) return this.$t('sync_locked.maintenance_mode')
      return ''
    },

    buttonName() {
      return this.authPreferenceOptions ? this.authPreferenceOptions.buttonName : null
    },

    buttonUrl() {
      return this.authPreferenceOptions ? this.authPreferenceOptions.buttonUrl : null
    },

    hasAppId() {
      return !!this.appId
    },

    hasAppSecret() {
      return !!this.appSecret
    },

    hasButtonName() {
      return !!this.buttonName
    },

    hasButtonUrl() {
      return !!this.buttonUrl
    },

    hasOneRosterConfigured() {
      return !this.isOneRoster || (this.hasButtonName && this.hasButtonUrl)
    },

    maySubmit() {
      return this.hasAppId && this.hasAppSecret && this.hasOneRosterConfigured
    },

    isSingleIntegrationConfigured() {
      return this.persisted
    },

    isOneRoster() {
      return this.authPreference === 'oneroster'
    },

    mayConfigureDataSync() {
      return !this.maintenanceMode && !this.anyRunningImports
    },

    primaryAuthDescription() {
      const binding =
        this.authPreference === 'single_integration' ? 'Single Integration' : this.$t('primary_auth_default')
      return this.$t('primary_auth_description', { binding })
    },
  },
  methods: {
    timeAgo,
    saveChanges() {
      this.saving = true
      if (!this.isOneRoster) this.authPreferenceOptions = {}
      this.update().then(
        (response) => (window.location = response.redirect),
        (error) => {
          this.saving = false
          if (error.responseJSON) {
            if (error.responseJSON.app_id) {
              this.appIdError = error.responseJSON.app_id.toString()
              this.$refs.appIdInput.focus()
            } else {
              this.generalError = error.responseJSON.errors
            }
          } else {
            this.generalError = error.statusText
          }
        },
      )
    },
    handleAppIdChange(event) {
      this.appIdError = this.generalError = ''
      this.appId = event.target.value
    },
    handleAppSecretChange(event) {
      this.appSecretError = this.generalError = ''
      this.appSecret = event.target.value
    },
    handleButtonNameChange(event) {
      this.authPreferenceOptions = {
        ...this.authPreferenceOptions,
        buttonName: event.target.value,
      }
    },
    handleButtonUrlChange(event) {
      this.authPreferenceOptions = {
        ...this.authPreferenceOptions,
        buttonUrl: event.target.value,
      }
    },
  },
}
</script>
