<!-- eslint-disable vuejs-accessibility/media-has-caption  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="center">
    <audio ref="audio" :src="audio.secureFileUrl" />
    <div class="audio-controls my-2 flex w-full items-center justify-center space-x-2">
      <button type="button" :aria-label="$t('audio_player.play')" class="flex" @click="togglePlay">
        <LzIcon v-show="isPlaying" path="icons/pause" />
        <LzIcon v-show="!isPlaying" path="icons/play" />
      </button>
      <button
        type="button"
        :aria-label="$t('audio_player.progress_bar')"
        class="audio-seekbar rounded-full"
        @click="clickSeek"
      >
        <div :style="seekbarWidth" class="audio-seekbar__progress rounded-full" />
      </button>
      <button type="button" class="mr-2 flex" :aria-label="$t('audio_player.restart')" @click="restart">
        <LzIcon path="icons/rewind" />
      </button>
      <DivDropdown
        :id="dropdownId"
        :label="$t('audio_player.volume')"
        :close-on-click="true"
        :dropdown-classes="['top', 'volume-dropdown']"
      >
        <template #button>
          <LzIcon path="icons/audio-no-circle" aria-hidden="true" />
        </template>
        <template #divContent>
          <label for="volume-control" class="sr-only">
            <span>{{ $t('audio_player.volume') }}</span>
          </label>
          <input id="volume-control" v-model="volume" type="range" min="0" max="100" />
        </template>
      </DivDropdown>
    </div>
  </div>
</template>

<script>
import { isNil, uniqueId } from 'lodash'
import DivDropdown from 'vue_features/shared/components/ui/dropdowns/DivDropdown'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'AudioPlayer',
  components: { DivDropdown, LzIcon },
  props: {
    // TODO: props should be audioId and secureFileUrl instead of entire audio object
    audio: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { shortDomain } = useCurrentCustomerStore()
    return { shortDomain }
  },
  data() {
    return {
      audioDuration: 0,
      isPlaying: false,
      events: {},
      currentPosition: 0,
      volume: 50,
    }
  },
  computed: {
    dropdownId() {
      return uniqueId('audio')
    },
    resourcePageUrl() {
      if (this.audio.id) {
        return Routes.short_resource_url(this.audio.id, { host: this.shortDomain })
      }
      return ''
    },
    seekbarWidth() {
      const percentWidth = this.audioDuration === 0 ? 0 : (this.currentPosition / this.audioDuration) * 100
      return { width: percentWidth.toString() + '%' }
    },
  },
  watch: {
    volume: {
      handler(newVolume) {
        this.fireEvent('volumeChange', newVolume)
      },
    },
  },
  mounted() {
    const audio = this.$refs.audio
    let audioPlaying = false

    if (!audio.play) return // don't bind when browser doesn't support audio

    this.registerEvent('play', () => {
      audioPlaying = true
      audio.play()
    })
    this.registerEvent('pause', () => {
      audioPlaying = false
      audio.pause()
    })
    this.registerEvent('seek', (toSeconds) => {
      audio.currentTime = toSeconds
      if (audioPlaying && !audio.audioPlaying) {
        audio.play()
      }
    })
    this.registerEvent('volumeChange', (newVolume) => {
      audio.volume = newVolume / 100.0
    })

    if (!isNil(this.currentPosition)) {
      audio.addEventListener('timeupdate', () => {
        this.currentPosition = audio.currentTime
      })
      audio.addEventListener('durationchange', () => {
        this.audioDuration = audio.duration
      })
    }
  },
  methods: {
    clickSeek(event) {
      if (event.button !== 0) return

      const rect = event.currentTarget.getBoundingClientRect(),
        clickFraction = (event.clientX - rect.left) / (rect.width + 0.1)

      this.fireEvent('seek', clickFraction * this.audioDuration)
    },
    restart() {
      this.fireEvent('seek', 0)
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.fireEvent('play') : this.fireEvent('pause')
    },
    fireEvent(eventName, ...args) {
      const handler = this.events[eventName]
      if (handler) {
        handler.apply(this, args)
      }
    },
    registerEvent(eventName, handler) {
      this.events[eventName] = handler
    },
  },
}
</script>
