<template>
  <Badge :color="badgeColor">
    <LzIcon v-if="showCheckIcon" path="icons/check-circle" class="-ml-1.5 flex-shrink-0 align-middle" />
    {{ $t(`assignment_status.${status}`) }}
  </Badge>
</template>

<script>
import { Badge, LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'AssignmentStatusBadge',
  components: { Badge, LzIcon },
  props: {
    assignmentState: {
      type: String,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    badgeColor() {
      return this.showCheckIcon ? 'green' : 'gray'
    },
    showCheckIcon() {
      return this.status === 'submitted' || this.status === 'returned'
    },
    status() {
      if (this.assignmentState === 'opened' && this.submitted) {
        return 'submitted'
      }
      return this.assignmentState
    },
  },
}
</script>
