import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

const SiteAdminSchoolsService = {
  deleteSchool(id) {
    return HTTP.delete(Routes.site_admin_school_path(id))
  },

  getAllSchools() {
    return HTTP.getJSON(Routes.site_admin_schools_path())
  },

  getSchools(page) {
    return HTTP.getJSON(Routes.site_admin_schools_path({ page: page }))
  },

  mergeSchools({ targetSchoolId, sourceSchoolId }) {
    return HTTP.put(Routes.site_admin_school_merge_school_path(targetSchoolId, sourceSchoolId))
  },

  updateSchool({ id, ...attributes }) {
    return HTTP.put(Routes.site_admin_school_path(id), { school: attributes })
  },
}

export default SiteAdminSchoolsService
