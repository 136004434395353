<template>
  <div class="align-center flex flex-col items-center justify-center gap-y-4 text-center">
    <template v-if="isComplete">
      <div role="status">
        <h2 class="m-0">{{ $t('progress.job_complete', { job: capitalize(job) }) }}</h2>
      </div>
      <transition
        enter-active-class="transition-opacity duration-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
      >
        <LottieAnimation :data="() => import('lottie-animations/success.json')" autoplay class="h-40" />
      </transition>
    </template>
    <template v-else>
      <div role="status">
        <h2 id="job_progress" class="m-0">{{ $t('progress.job_in_progress', { job: capitalize(job) }) }}</h2>
      </div>
      <LottieAnimation :data="() => import('lottie-animations/loading1.json')" loop autoplay class="h-40" />
    </template>
    <Poller
      :active="active"
      :interval="750"
      role="progressbar"
      aria-labelledby="job_progress"
      :aria-valuenow="currentWidth"
      @tick="$emit('tick', $event)"
    >
      <div class="progress-bar">
        <div :class="[completionColor, 'completion-bar']" :style="completionStyle" />
      </div>
      {{ currentWidth }} %
    </Poller>
    <p class="w-3/4">{{ $t('progress.you_make_a_difference') }}</p>
  </div>
</template>

<script>
import Poller from './Poller'
import { components as messages } from 'vue_features/shared/i18n'
import { capitalize } from 'lodash'
import LottieAnimation from 'vue_features/shared/components/LottieAnimation'

const MIN_REFRESH_RATE = 10

export default {
  i18n: { messages },
  components: { LottieAnimation, Poller },
  props: {
    active: { type: Boolean, required: true },
    percent: {
      type: Number,
      required: true,
    },
    job: { type: String, required: true },
  },
  data() {
    return {
      currentWidth: this.percent || 1,
      capitalize,
      isComplete: false,
    }
  },
  computed: {
    completionColor() {
      return this.currentWidth === 100 ? 'bg-green-400' : 'loading-background'
    },
    completionStyle() {
      return { width: `${this.currentWidth}%` }
    },
    refreshRate() {
      if (this.percent === 100) {
        return MIN_REFRESH_RATE
      } else {
        return Math.max(MIN_REFRESH_RATE, 100 - Math.ceil(this.percent - this.currentWidth))
      }
    },
  },
  created() {
    const refresh = () => {
      if (this.currentWidth < this.percent) {
        this.currentWidth += 1
      }
      if (this.currentWidth < 100) {
        window.setTimeout(refresh, this.refreshRate)
      } else {
        this.isComplete = true
        this.$emit('complete')
      }
    }
    refresh()
  },
  destroyed() {
    window.clearInterval(this.interval)
  },
}
</script>
