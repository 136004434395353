<template>
  <Poller v-if="isFetchingTable" :active="isFetchingTable" @tick="fetchTable">
    <LoadingSpinner />
  </Poller>
  <p v-else-if="failedToLoad" class="red">
    {{ $t('cannot_generate') }}
  </p>
  <StandardsCoverageTable v-else v-bind="{ displayBy, contentType, contents }" />
</template>

<script>
import { ref, watch, inject } from 'vue'
import { LoadingSpinner, Poller } from 'vue_features/shared/components/ui'
import StandardsCoverageTableService from 'vue_features/standards_coverage_table/api'
import StandardsCoverageTable from './StandardsCoverageTable'

export default {
  name: 'StandardsCoverageTableLoader',
  components: { StandardsCoverageTable, LoadingSpinner, Poller },
  props: {
    parentWikiId: { type: [String, Number], default: '' },
    contentType: { type: String, default: 'Wiki' },
    displayBy: { type: String, default: 'Standard' },
    tableId: { type: [String, Number], default: '' },
    isRefreshing: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const contents = ref(null)
    const failedToLoad = ref(false)
    const id = ref(props.tableId)
    watch(
      () => [props.contentType, props.displayBy, props.parentWikiId],
      () => {
        contents.value = null
        failedToLoad.value = false
      },
    )
    watch(
      () => props.tableId,
      (newValue) => {
        id.value = newValue
      },
    )
    watch(
      () => props.isRefreshing,
      (newValue) => {
        if (newValue) {
          contents.value = null
        }
      },
    )
    const root = inject('useRoot')()
    const createTable = () => {
      const { parentWikiId, contentType, displayBy } = props
      return StandardsCoverageTableService.create({ parentWikiId, contentType, displayBy }).then(
        (data) => {
          id.value = data.id
          // FIXME: remove root.$emit once ckeditor plugin no longer required
          root.$rootEmit('new-table-id', data.id)
          emit('new-table-id', data.id)
          contents.value = data.refreshing ? null : data.contents
        },
        () => (failedToLoad.value = true),
      )
    }
    return { contents, failedToLoad, id, createTable }
  },
  computed: {
    isFetchingTable() {
      return !this.contents && !this.failedToLoad
    },
  },
  mounted() {
    if (this.isFetchingTable) {
      this.fetchTable(() => {})
    }
  },
  methods: {
    fetchTable(done) {
      if (!this.id) {
        return this.createTable().then(done)
      }

      return StandardsCoverageTableService.get(this.id).then(
        (data) => {
          this.contents = data.refreshing ? null : data.contents
          done()
        },
        () => (this.failedToLoad = true),
      )
    },
  },
}
</script>
