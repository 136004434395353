<template>
  <div id="admin-accesses-index" class="page-content">
    <ProgressCover :visible="showProgressCover" />
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <h3>{{ $t('page_heading') }}:</h3>
      <p v-if="addedAdmins.length === 0">
        {{ $t('no_added_admins') }}
      </p>
      <Alert v-show="error" type="error" :title="error" />
      <LoadMoreList
        v-if="addedAdmins.length > 0"
        v-bind="{ items: addedAdmins, totalCount: totalAddedCount, startCount: pageSize, moreCount: pageSize }"
        id="added-admins"
        v-slot="{ item: admin }"
        @load-more="handleMoreAddedAdmins"
      >
        <DetailListItem v-bind="{ item: admin, showThumbnail: false, icon: 'user' }" :key="admin.id">
          <template #title>
            {{ admin.name }}
          </template>
          <template #metadata>
            <div class="flex flex-col">
              <p>
                <span class="font-bold">{{ capitalize($t('common.from')) }}</span>
                {{ admin.customer }}
              </p>
              <p>
                <span class="font-bold">{{ $t('common.email') }}</span>
                {{ admin.email }}
              </p>
            </div>
          </template>
          <template #actions>
            <button class="btn btn--small caution" @click.prevent="removeAccess(admin)">
              {{ $t('common.remove') }}
            </button>
          </template>
        </DetailListItem>
      </LoadMoreList>
      <h3>{{ $t('add_access_heading') }}:</h3>
      <p v-if="availableAdmins.length === 0">
        {{ $t('no_admins_available') }}
      </p>
      <LoadMoreList
        v-if="availableAdmins.length > 0"
        v-bind="{
          items: availableAdmins,
          totalCount: totalAvailableCount,
          startCount: pageSize,
          moreCount: pageSize,
        }"
        id="available-admins"
        v-slot="{ item: admin }"
        @load-more="handleMoreAvailableAdmins"
      >
        <DetailListItem v-bind="{ item: admin, showThumbnail: false, icon: 'user' }" :key="admin.id">
          <template #title>
            {{ admin.name }}
          </template>
          <template #metadata>
            <div class="flex flex-col">
              <p>
                <span class="font-bold">{{ capitalize($t('common.from')) }}</span>
                {{ admin.customer }}
              </p>
              <p>
                <span class="font-bold">{{ $t('common.email') }}</span>
                {{ admin.email }}
              </p>
            </div>
          </template>
          <template #actions>
            <button class="btn btn--small btn--primary" @click.prevent="grantAccess(admin)">
              {{ $t('common.add') }}
            </button>
          </template>
        </DetailListItem>
      </LoadMoreList>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import { ProgressCover, Alert } from 'vue_features/shared/components/ui'
import LoadMoreList from 'vue_features/shared/components/lists/LoadMoreList'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import { useAdminAccessesStore } from 'vue_features/site_admin/admin_accesses/store/use_admin_accesses_store'

export default {
  name: 'AdminAccessIndex',
  components: { DetailListItem, LoadMoreList, ProgressCover, Alert },
  setup() {
    const { addedAdminState, availableAdminState, availableAdmins, addedAdmins, addAdmin, removeAdmin, loadAdmins } =
      useAdminAccessesStore()
    const pageSize = 100
    return {
      addedAdminState,
      availableAdminState,
      availableAdmins,
      addedAdmins,
      addAdmin,
      removeAdmin,
      loadAdmins,
      pageSize,
    }
  },
  data() {
    return { showProgressCover: false, error: '' }
  },
  computed: {
    totalAddedCount() {
      return this.addedAdminState.meta.pagination.totalCount
    },
    totalAvailableCount() {
      return this.availableAdminState.meta.pagination.totalCount
    },
  },
  methods: {
    capitalize,
    grantAccess(admin) {
      this.showProgressCover = true
      this.error = ''
      this.addAdmin(admin).then(() => (this.showProgressCover = false), this.handleError.bind(this))
    },
    removeAccess(admin) {
      this.showProgressCover = true
      this.error = ''
      this.removeAdmin(admin).then(() => (this.showProgressCover = false), this.handleError.bind(this))
    },
    handleError(error) {
      this.showProgressCover = false
      if (error.responseJSON.email) {
        this.error = error.responseJSON.email[0]
      } else {
        this.error = this.$t('components.failure_modal.error_header')
      }
      const offset = document.getElementById('admin-accesses-index').offsetTop
      window.scrollTo({ top: offset, behavior: 'smooth' })
    },
    handleLoadMore(loadState, page, done) {
      return this.loadAdmins({ loadState, page }).then(done)
    },
    handleMoreAddedAdmins(done) {
      this.handleLoadMore('added', this.addedAdminState.meta.pagination.nextPage, done)
    },
    handleMoreAvailableAdmins(done) {
      this.handleLoadMore('available', this.availableAdminState.meta.pagination.nextPage, done)
    },
  },
}
</script>
