<template>
  <div ref="divEl" class="drag-overlay" draggable />
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'

export default {
  name: 'DragOverlay',
  setup(_, { emit }) {
    const divEl = ref(null)
    onMounted(() => emit('mount', divEl.value))
    onBeforeUnmount(() => emit('unmount', divEl.value))
    return { divEl }
  },
}
</script>
