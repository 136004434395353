<template>
  <div :data-test="'comment-' + id">
    <CommentDeleteModal
      v-if="canModalShow(confirmModalId)"
      :modal-id="confirmModalId"
      @confirm="confirmDeleteComment"
    />
    <div
      class="mx-auto flex max-w-7xl justify-between px-3 md:px-6"
      :class="{ 'comment__first-level': topLevel, 'comment__second-level py-4': !topLevel }"
    >
      <div class="flex">
        <div class="comment__avatar mr-4" :class="{ 'w-14': topLevel, 'ml-12 w-12 md:ml-16': !topLevel }">
          <InlineSvg path="icons/user" />
        </div>

        <div class="comment__content">
          <p class="mb-2">
            <span class="font-bold">{{ author }}</span>
            <span class="comment__date">{{ createdAtInWords }} ago</span>
          </p>

          <CommentForm
            v-if="editing"
            :id="id"
            :top-level="true"
            :commentable-path="commentablePath"
            :content="content"
            :file="{
              baseUrl: filestackBaseUrl,
              filename: sourceFilename,
            }"
            @cancel-edit="cancelEdit"
            @update-comment="updateComment"
          />

          <div v-else>
            <!-- content sanitized in serializer -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="whitespace-pre-line" :class="{ italic: softDeleted }" v-html="content"></p>

            <p v-if="secureFileUrl">
              <a :href="secureFileUrl" target="_blank">{{
                sourceFilename || $t('components.comments.comment.attached_file')
              }}</a>
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-end justify-between space-y-6">
        <ListDropdown v-if="canEdit" id="comment-actions" :button-classes="['btn', 'btn--small']">
          <template #button>
            <LzIcon path="icons/caret" />
          </template>

          <template #listItems>
            <li>
              <button type="button" data-test="edit-button" @click="editComment">
                {{ $t('common.edit') }}
              </button>
            </li>

            <li>
              <button type="button" data-test="delete-button" @click="deleteComment">
                {{ $t('common.delete') }}
              </button>
            </li>
          </template>
        </ListDropdown>
        <button v-if="topLevel" type="button" class="flex cursor-pointer items-center gap-1 text-sm" @click="reply">
          <LzIcon path="icons/reply" />
          {{ $t('components.comments.comment.reply') }}
        </button>
      </div>
    </div>

    <Comment
      v-for="comment in subComments"
      v-bind="comment"
      :key="comment.id"
      :top-level="false"
      @update-comment="updateSubComment"
    />

    <CommentForm
      :hidden="replyHidden"
      :top-level="false"
      :commentable-path="commentablePath"
      @new-comment="newComment"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import { useCurrentUserStore } from 'vue_features/shared/store/composables'

import CommentableService from '../commentable/api'
import { openModal, closeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { InlineSvg, LzIcon } from 'vue_features/shared/components/ui'
import Routes from 'routes'
import CommentForm from './CommentForm'
import CommentDeleteModal from './CommentDeleteModal'

export default {
  name: 'Comment',
  components: { CommentDeleteModal, CommentForm, InlineSvg, ListDropdown, LzIcon },

  props: {
    id: { type: Number, required: true },
    userId: { type: Number, required: true },
    topLevel: { type: Boolean, default: false },
    softDeleted: { type: Boolean, default: false },
    sourceFileUrl: { type: String, default: '' },
    sourceFilename: { type: String, default: '' },
    secureFileUrl: { type: String, default: '' },
    filestackBaseUrl: { type: String, default: '' },
    author: { type: String, required: true },
    createdAtInWords: { type: String, required: true },
    content: { type: String, default: '' },
    comments: { type: Array, default: () => [] },
  },

  setup(props, { emit }) {
    const subComments = ref([...props.comments])

    const replyHidden = ref(true)
    const editing = ref(false)
    const confirmModalId = ref(`comment${props.id}`)

    const { id: currentUserId } = useCurrentUserStore()

    const commentablePath = computed(() => Routes.comment_path(props.id))
    const canEdit = computed(() => props.userId == currentUserId.value && !props.softDeleted)

    const newComment = (comment) => {
      subComments.value.push(comment)
      replyHidden.value = true
    }
    const editComment = () => (editing.value = true)
    const cancelEdit = () => (editing.value = false)
    const updateComment = (params) => {
      editing.value = false
      emit('update-comment', params)
    }
    const updateSubComment = (params) => {
      const comment = subComments.value.find((comment) => comment.id == params.id)
      Object.assign(comment, params)
    }
    const deleteComment = () => openModal(confirmModalId.value)
    const confirmDeleteComment = () => {
      CommentableService.deleteComment(commentablePath.value).then((response) => {
        emit('update-comment', response)
        closeModal(confirmModalId.value)
      })
    }
    const reply = () => (replyHidden.value = false)

    return {
      subComments,
      replyHidden,
      editing,
      confirmModalId,

      commentablePath,
      canEdit,

      newComment,
      editComment,
      cancelEdit,
      updateComment,
      updateSubComment,
      deleteComment,
      confirmDeleteComment,
      reply,

      canModalShow,
    }
  },
}
</script>
