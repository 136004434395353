import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const StandardsDownloadService = {
  download({ state, subject }) {
    const params = snakeKeys({ state, subject })

    return HTTP.create(Routes.site_admin_standard_downloads_path(), params)
  },

  getStatus(id) {
    return HTTP.getJSON(Routes.site_admin_standard_download_path(id))
  },
}

export default StandardsDownloadService
