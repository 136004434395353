<template>
  <li v-bind="dataAttributes" :class="['w-full', classes]">
    <div>
      <Comment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :question-number="multiQuestion ? questionNumbers[comment.questionReference] : 0"
      />
    </div>
    <div
      v-if="showCards"
      ref="cardWrapperEl"
      :style="cardWrapperStyle"
      class="relative"
      :class="[
        'card__content col-span-12',
        { 'lg:col-span-8': !showTool && showNotes && !isFullscreen },
        { 'row-start-2 lg:col-span-6 lg:row-start-1': showTool },
        fontTextStyle,
      ]"
    >
      <div ref="cardContentEl" :style="cardContentStyle" class="card__content-wrapper">
        <Annotatable :canvas-state="canvasState" @update-canvas="updateCanvas">
          <div ref="cardEl" :class="['card__resource', { 'card__resource--audio': hasVoiceover }]">
            <div class="card__resource-contents">
              <slot>
                <CardContent v-if="!isTeacherOnly || authorizedAsTeacher" :card="card" data-test="card-content" />
                <div
                  v-else-if="isTeacherOnly && !authorizedAsTeacher"
                  data-test="not-authorized"
                  class="flex w-full items-center justify-center"
                >
                  <p class="flex items-center gap-x-2 rounded-xl bg-red-500 px-4 py-2 text-white">
                    <LzIcon size="sm" path="icons/exclamation" />
                    <span>{{ $t('lesson_card_templates.must_be_teacher') }}</span>
                  </p>
                </div>
              </slot>
            </div>
            <div v-if="hasVoiceover" class="card__audio center">
              <AudioPlayer :audio="card.voiceover" />
            </div>
          </div>
        </Annotatable>
      </div>
    </div>
    <StudentTool v-if="showTool" class="row-start-1" />
    <CardNotes
      v-if="showNotes"
      v-bind="{
        content: card.content,
        notes: cardNotes,
      }"
    />
  </li>
</template>

<script setup>
import { defineAsyncComponent, ref, inject, toRef, computed, watchEffect } from 'vue'
import Comment from 'vue_features/shared/components/Comment'
import AudioPlayer from 'vue_features/resources/audios/components/AudioPlayer'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import CardContent from './CardContent'
import { get } from 'lodash'
import CardNotes from './CardNotes'
import StudentTool from 'vue_features/assignments/shared/card_deck/StudentTool'
import { useAnnotationCanvasStore } from 'vue_features/lesson_plans/store/use_annotation_canvas_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useCardTypes } from 'vue_features/shared/composables'
import { useLearnositySessionResponseStore } from 'vue_features/grading/composables/use_learnosity_session_response_store'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useAssignmentShowStore } from 'vue_features/assignments/show/composables/use_assignment_show_store'
import useCardScaling from 'vue_features/resources/shared/composables/use_card_scaling'
import { useLiveLearnStore } from 'vue_features/assignments/show/composables/use_live_learn_store'
import { useLiveLearnChannel } from 'vue_features/assignments/show/composables'

const Annotatable = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'Annotatable' */
    'vue_features/shared/components/Annotatable'
  ),
)
const props = defineProps({
  card: { type: Object, default: () => ({}) },
  index: { type: Number, required: true },
  currentPosition: { type: Number, required: true },
  showCards: { type: Boolean, default: true },
  showNotes: { type: Boolean, default: false },
  showTool: { type: Boolean, default: false },
  totalCount: { type: Number, required: true },
})
const { authorizedAsTeacher } = useCurrentUserStore()
const { isFullscreen } = useFullscreenStore()
const { assignmentView } = useAssignmentShowStore()
const { learnosityResponseScoresBySession } = useLearnositySessionResponseStore()

const { storeCanvas, fetchCanvas } = useAnnotationCanvasStore()

const canvasState = computed(() => {
  return fetchCanvas(props.card.id)
})

const { isTitleCard, contentType } = useCardTypes(toRef(props, 'card'))

const cardEl = ref(null)
const cardWrapperEl = ref()
const cardContentEl = ref()
const { cardWrapperStyle, cardContentStyle } = useCardScaling(cardWrapperEl, cardContentEl)

const dataAttributes = computed(() => ({
  'data-content-type': contentType.value || 'Unknown',
  'data-title-card': isTitleCard.value ? isTitleCard.value : null,
  'data-role': 'content',
}))
const questionNumbers = computed(() => {
  const scores = learnosityResponseScoresBySession.value[assignmentView.value.learnositySessionId]
  if (!scores) return {}

  const itemRef = props.card.content.content
  const indexQuestions = (questions, item) => {
    if (item.itemReference === itemRef) questions[item.questionReference] = item.number
    return questions
  }
  return scores.reduce(indexQuestions, {})
})
const comments = computed(() => {
  const scores = learnosityResponseScoresBySession.value[assignmentView.value?.learnositySessionId]
  if (!scores || !props.card?.content?.lrnoPreviewConfig?.request?.items?.length) return []

  const itemRef = props.card.content.content
  const filterComments = (comments, item) => {
    if (item.itemReference === itemRef && item.comment.content) comments.push(item.comment)
    return comments
  }
  return scores.reduce(filterComments, [])
})
const cardNotes = computed(() => {
  if (props.card.isBeginLessonCard) return props.card.notes
  return props.card.teachingNotes ? props.card.teachingNotes.content : ''
})
const isActive = computed(() => props.index === props.currentPosition)

const emit = defineEmits(['first-card-el'])

if (isActive.value) {
  watchEffect(() => {
    if (cardEl.value) emit('first-card-el', cardEl.value)
  })
}

const { subscription: liveLearnSubscription } = useLiveLearnChannel()
function updateCanvas(state) {
  storeCanvas(props.card.id, state, true, liveLearnSubscription.value)
}

const LIVE_LEARN_MAX_VISIBLE = 40
const classes = computed(() => {
  const { isLiveLearn } = useLiveLearnStore()
  const isHidden = !isActive.value && isLiveLearn.value && props.totalCount > LIVE_LEARN_MAX_VISIBLE
  return { active: isActive.value, hidden: isHidden, 'flex-col space-y-2': !props.showNotes }
})

const fontTextStyle = inject('fontTextStyle')
const hasVoiceover = computed(() => !!props.card.voiceover)
const isTeacherOnly = computed(() => !!get(props.card.content, 'teacherOnly'))
const multiQuestion = computed(() => Object.keys(questionNumbers.value).length > 1)
</script>
