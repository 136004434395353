<template>
  <div class="flex flex-grow items-center justify-center">
    <div class="inline-block w-96 rounded-lg bg-gray-100 p-4 text-center">
      {{ $t('no_available_sessions') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoAvailableSessionsAlert',
  props: {},
  setup() {},
}
</script>
