import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const StandardsCoverageTableService = {
  create(params) {
    const url = Routes.standards_coverage_tables_path()
    return HTTP.create(url, snakeKeys({ standardsCoverageTable: params }))
  },

  get(id) {
    const url = Routes.standards_coverage_table_path(id)
    return HTTP.getJSON(url)
  },

  update(id) {
    const url = Routes.standards_coverage_table_path(id)
    return HTTP.put(url, {})
  },
}

export default StandardsCoverageTableService
