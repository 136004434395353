import messages from './i18n/messages'
import {
  initAssignmentStore,
  useAssignmentShowStore,
  useAssignmentCardsStore,
  useLiveLearnStore,
} from 'vue_features/assignments/show/composables'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'
import LiveLearn from './components/LiveLearn'

export default function ({ state }) {
  const { lessonPlan } = state.assignmentState

  initAssignmentStore(state.assignmentState)
  useAssignmentShowStore().$set(state.assignmentShow)
  useLiveLearnStore().$set(state.liveLearnState)
  useAssignmentCardsStore().$set({ cards: lessonPlan.cards })
  useLearnosityConfigStore().$set({ learnosityConfig: state.learnosityConfig })

  return { component: LiveLearn, messages, options: { components: { LiveLearn } } }
}
