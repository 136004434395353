import { createLzRouter } from 'vue_features/shared/router'
import messages from './i18n/messages'
import LessonPlanShow from './components/LessonPlanShow'
import LessonPlanRevisionShow from './components/LessonPlanRevisionShow'
import LessonPlayer from './components/LessonPlayer'
import AdditionalMaterials from './components/AdditionalMaterials'
import Description from './components/Description'
import { initLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { initGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { initPathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'

export default function ({ state }) {
  initLessonPlanShowStore(state)
  useAssignmentPromptStore().$set({ hasKlasses: state.hasKlasses })
  initGoogleClassroomStore(state.googleClassroom)
  state.pathCrumbsState.currentResource = state.lessonPlanState.lessonPlan
  initPathCrumbsStore(state.pathCrumbsState)
  useLearnosityConfigStore().$set({ learnosityConfig: state.learnosityConfig })

  // sets up lesson plan scrolling to work correctly
  document.documentElement.classList.remove('scroll-smooth')

  const { lessonPlan, isRevision } = useLessonPlanStore()

  const basePath = `/lesson_plans/:id${isRevision.value ? '/revision' : ''}`
  const routes = [
    { path: basePath, component: LessonPlayer },
    { path: `${basePath}/lesson`, name: 'lesson', component: LessonPlayer },
    {
      path: `${basePath}/additional_materials`,
      name: 'additional_materials',
      component: lessonPlan.value.additionalMaterials.length > 0 ? AdditionalMaterials : LessonPlayer,
    },
    { path: `${basePath}/description`, name: 'description', component: Description },
  ]
  const router = createLzRouter({ routes })
  const component = isRevision.value ? LessonPlanRevisionShow : LessonPlanShow

  return { component, messages, router }
}
