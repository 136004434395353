<template>
  <div v-if="totalCount > 1" class="lp-deck__footer relative z-10 mx-auto max-w-7xl px-3 md:px-6">
    <ul class="lp-navigation list-center list-inline-block my-0">
      <li class="flex justify-center space-x-2">
        <button
          :aria-label="$t('lesson_plan.previous')"
          :disabled="!isPreviousEnabled"
          class="btn slideshow__icon--prev flex items-center"
          @click="$emit('go-previous')"
        >
          <LzIcon path="icons/previous" />
          <span class="hidden md:inline">{{ $t('lesson_plan.previous') }}</span>
        </button>
        <button
          :aria-label="$t('lesson_plan.next')"
          :disabled="!isNextEnabled"
          data-test="footer-next-btn"
          class="btn slideshow__icon--next flex items-center"
          @click="$emit('go-next')"
        >
          <span class="hidden md:inline">{{ $t('lesson_plan.next') }}</span>
          <LzIcon path="icons/next" />
        </button>
      </li>
    </ul>
    <p class="small center text-muted mt-4 italic">
      {{ $t('lesson_plan.touch_device_tip', { lesson_type: $t(`readable_types.${lessonPlan.lessonType}`) }) }}
    </p>
  </div>
</template>

<script>
import { computed } from 'vue'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'CardDeckFooter',
  components: { LzIcon },
  props: {
    currentPosition: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    isEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { lessonPlan } = useLessonPlanStore()
    const isPreviousEnabled = computed(() => props.isEnabled && props.currentPosition > 0)
    const isNextEnabled = computed(() => props.isEnabled && props.currentPosition < props.totalCount - 1)

    return { lessonPlan, isPreviousEnabled, isNextEnabled }
  },
}
</script>
