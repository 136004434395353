<template>
  <li class="flex items-center rounded-lg">
    <div v-if="isCurrentItem || !href" class="flex h-20 w-full overflow-hidden rounded-lg">
      <DetailListItemThumbnail class="h-full" :thumbnail="getThumbnail(lzContent)" :icon="getIcon(lzContent)" />
      <div class="flex items-center py-4 pl-6 pr-2">
        <p class="line-clamp-2 text-base font-bold leading-6">{{ title }}</p>
      </div>
    </div>
    <a
      v-else
      :href="href"
      class="border-base bg-focus group flex h-20 w-full cursor-pointer overflow-hidden rounded-lg border shadow hover:shadow-md"
    >
      <DetailListItemThumbnail class="h-full" :thumbnail="getThumbnail(lzContent)" :icon="getIcon(lzContent)" />
      <div class="flex items-center py-4 pl-6 pr-2">
        <p data-test="link-title" class="group-hover:text-accent-hover line-clamp-2 text-accent font-bold leading-6">{{
          title
        }}</p>
        <LzContentPremiumBadge :premium="lzContent.premium" />
        <LzContentStateBadge v-if="isActive(lzContent)" :state="lzContent.state" />
      </div>
    </a>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import LzContentPremiumBadge from 'vue_features/lz_content/components/LzContentPremiumBadge'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import DetailListItemThumbnail from 'vue_features/shared/components/lists/DetailListItemThumbnail'
import { getIcon, getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'
import { isActive } from 'vue_features/shared/composables/use_record_states'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  currentItemId: { type: Number, required: true },
})

const isCurrentItem = computed(() => props.item.content.id === props.currentItemId)
const lzContent = computed(() => props.item.content.lzContent)
const href = computed(() => lzContent.value.href)
const title = computed(() => lzContent.value.title)
</script>
