<template>
  <div class="comment bg-primary-50 border-primary-200 mb-3 rounded-2xl border pt-4">
    <div class="comment__assignment flex max-w-7xl flex-col gap-y-2 rounded-2xl px-3 md:px-6">
      <div v-if="questionNumber">
        <p class="text-lg font-bold">{{ `${$t('common.question')} ${questionNumber}` }}</p>
      </div>
      <div class="flex">
        <div class="comment__content mb-4">
          <p class="mb-2 flex items-center gap-x-2 pt-2">
            <span class="comment__avatar text-primary-500 w-6 flex-shrink-0">
              <InlineSvg path="icons/user" />
            </span>
            <span class="font-bold">{{ comment.author }}</span>
            <span class="comment__date text-neutral-700 text-sm italic">{{ comment.createdAtInWords }} ago</span>
            <Chip v-if="comment.read === false" :label="$t('common.new')" size="sm" />
          </p>
          <!-- content sanitized in serializer -->
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="text-neutral-800 mx-8 text-justify" v-html="comment.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InlineSvg, Chip } from 'vue_features/shared/components/ui'

export default {
  name: 'Comment',
  components: { InlineSvg, Chip },
  props: {
    comment: { type: Object, required: true },
    questionNumber: { type: Number, default: 0 },
  },
}
</script>
