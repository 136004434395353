<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/form-control-has-label
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="users" class="no-fullstory mx-auto max-w-7xl px-3 md:px-6">
    <ProgressCover :visible="showCover" :message="$t('common.loading')" />
    <div v-if="!isPublic" class="align-right mb-4 flex items-center justify-end gap-1">
      <LzIcon type="div" path="icons/help" size="sm" />
      <a :href="helpUrl">{{ $t('user_setup_help') }}</a>
    </div>
    <UsersHeader />
    <div class="mx-auto max-w-7xl">
      <div class="search-box my-6">
        <div class="form-group">
          <input
            id="search-query"
            v-model="term"
            :placeholder="$t('search_users_placeholder')"
            type="text"
            label="query"
            class="form-control search-query"
            @keyup.enter="getPage(1)"
          />
        </div>
        <button id="site-admin-search" class="search-submit float-right" :aria-label="$t('search')" @click="getPage(1)">
          <LzIcon class="text-white" path="icons/search" />
        </button>
      </div>
    </div>
    <Grid disable-default="md:px">
      <div id="search-filters" class="col-span-12 space-y-4 md:col-span-3">
        <FilterBox :label="$t('role')">
          <span v-for="role in filterableRoles" :key="role" class="checkbox">
            <label :label="$t(`common.${role}`)" type="checkbox">
              <input
                :id="`${role}-toggle`"
                :value="role"
                :checked="filteredRoles.includes(role)"
                class="check_boxes"
                type="checkbox"
                @click="toggleFilter(role)"
              />
              {{ $t(`common.${role}`) }}
            </label>
          </span>
        </FilterBox>
        <FilterBox :label="$t('state')">
          <span v-for="state in availableStates" :key="state" class="checkbox">
            <label :label="$t(`common.${state}`)" type="checkbox">
              <input
                :id="`${state}-toggle`"
                :value="state"
                :checked="filteredStates.includes(state)"
                class="check_boxes"
                type="checkbox"
                @click="toggleFilter(state)"
              />
              {{ $t(`common.${state}`) }}
            </label>
          </span>
        </FilterBox>
      </div>
      <div id="search-results-container" class="col-span-12 mt-4 md:col-span-9 md:mt-0">
        <div v-if="hasFilters" id="activeFilters" class="mb-4">
          <ul class="flex flex-wrap items-center gap-1">
            <li
              v-for="filter in currentFilters"
              :key="filter"
              class="filter-query bg-focus border-base inline-flex cursor-default items-center rounded-2xl border py-0.5 pl-2 pr-0.5 text-sm font-bold leading-5"
            >
              {{ $t(`common.${filter}`) }}
              <button
                class="hover:bg-base text-neutral-600 ml-1 flex h-6 w-6 cursor-pointer items-center rounded-full leading-6 transition-colors hover:text-red-400"
                @click="toggleFilter(filter)"
              >
                <LzIcon path="icons/x--small" />
              </button>
            </li>
            <li
              v-if="query"
              class="filter-query bg-focus border-base inline-flex max-w-max cursor-default items-center rounded-2xl border py-0.5 pl-2 pr-0.5 text-sm font-bold leading-5"
            >
              {{ query }}
              <button
                class="hover:bg-base text-neutral-600 ml-1 flex h-6 w-6 cursor-pointer items-center rounded-full leading-6 transition-colors hover:text-red-400"
                @click="clearQuery"
              >
                <LzIcon path="icons/x--small" />
              </button>
            </li>
            <li>
              <a id="clear-selection" class="inline-block cursor-pointer text-sm leading-6" @click="clearSearch">
                {{ $t('clear_selections') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="mb-4">
          <i18n-t v-if="users.length" tag="div" keypath="user_display_count" scope="global">
            <template #range>
              <strong>{{ userRange }}</strong>
            </template>
            <template #total>
              <strong>{{ userCount }}</strong>
            </template>
            <template #query>
              <strong>"{{ query }}"</strong>
            </template>
          </i18n-t>
          <i18n-t v-else tag="div" keypath="no_users_display_count" scope="global">
            <template #count>
              <strong>0</strong>
            </template>
            <template #query>
              <strong>"{{ query }}"</strong>
            </template>
          </i18n-t>
        </div>
        <div v-if="users.length" class="mb-12">
          <ul class="mb-12 space-y-4">
            <UserEditTile v-for="user in users" :key="user.id" :user="user" />
          </ul>
          <div class="center">
            <LzPaginator v-bind="paginationData" @page-changed="getPage" />
          </div>
        </div>
      </div>
    </Grid>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { paginationHelpers } from 'utils'
import { LzIcon, LzPaginator, ProgressCover } from 'vue_features/shared/components/ui'
import UsersHeader from './UsersHeader'
import UserEditTile from './UserEditTile'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Grid from 'vue_features/shared/components/ui/Grid'
import { HELP_TEACHER_ACCOUNTS_URL } from 'rails_constants'
import FilterBox from 'vue_features/shared/components/search/FilterBox'
import { ACTIVE, ARCHIVED } from 'vue_features/shared/composables/use_record_states'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'UsersSearch',
  components: { FilterBox, Grid, LzIcon, LzPaginator, ProgressCover, UsersHeader, UserEditTile },
  props: { currentSearch: { type: Object, required: true } },
  setup(props) {
    const { isPublic } = useCurrentCustomerStore()
    const { usersData, filterableRoles, getUsers } = useUsersIndexStore()
    const availableStates = [ACTIVE, ARCHIVED]
    const filteredRoles = ref([...props.currentSearch.roles])
    const filteredStates = ref([...props.currentSearch.states])
    const term = ref('')
    const query = ref('')
    const showCover = ref(false)
    const users = computed(() => usersData.value.users)
    const paginationData = computed(() => usersData.value.meta.pagination)

    const getPage = (page) => {
      const args = { users: { page: page || 1, roles: filteredRoles.value, states: filteredStates.value } }
      query.value = term.value
      if (query.value) args.users.query = query.value
      showCover.value = true
      getUsers(args).then(() => {
        document.getElementsByTagName('html')[0].scrollTop = 0
        showCover.value = false
      })
    }
    const toggleFilter = (value) => {
      const filters = availableStates.includes(value) ? filteredStates : filteredRoles
      filters.value.includes(value) ? filters.value.splice(filters.value.indexOf(value), 1) : filters.value.push(value)
      getPage(1)
    }
    const clearQuery = () => {
      term.value = ''
      getPage(1)
    }
    const clearSearch = () => {
      filteredRoles.value.splice(0, filteredRoles.value.length)
      filteredStates.value.splice(0, filteredStates.value.length)
      clearQuery()
    }

    const route = useRoute()
    if (route.query.query) {
      // If the path included a query string, we eat it to keep user PII out of the browser history
      term.value = route.query.query
      query.value = route.query.query
      useRouter().replace({ path: route.path })
    }

    return {
      helpUrl: HELP_TEACHER_ACCOUNTS_URL,
      isPublic,
      filterableRoles,
      availableStates,
      filteredRoles,
      filteredStates,
      term,
      query,
      showCover,
      users,
      paginationData,
      toggleFilter,
      getPage,
      clearQuery,
      clearSearch,
      currentFilters: computed(() => filteredRoles.value.concat(filteredStates.value)),
      hasFilters: computed(() => query.value.length || filteredRoles.value.length || filteredStates.value.length),
      userCount: computed(() => paginationData.value.totalCount.toLocaleString()),
      userRange: computed(() => paginationHelpers.calculateRangeOnPage(users.value, usersData.value.meta.pagination)),
    }
  },
}
</script>
