<template>
  <button
    class="flex h-10 w-10 items-center justify-center text-red-600 transition-colors hover:text-red-400"
    @click="$emit('delete-share')"
  >
    <LzIcon path="icons/close" />
  </button>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'DeleteShareButton',
  components: { LzIcon },
}
</script>
