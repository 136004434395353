<template>
  <div>
    <h3 v-if="customers.length === 0" class="center">
      {{ $t('index.no_customers') }}
    </h3>
    <ul class="space-y-4">
      <CustomerListItem v-for="customer in customers" :key="customer.id" :customer="customer" />
    </ul>
  </div>
</template>

<script>
import CustomerListItem from './CustomerListItem'

export default {
  name: 'CustomerList',
  components: { CustomerListItem },
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
}
</script>
