<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="switch-toggle">
    <!-- since forms do not submit a falsy checkbox value-->
    <input :name="fieldName" :value="modelValue ? 1 : 0" type="hidden" />
    <input
      :id="id"
      ref="input"
      :checked="modelValue"
      :disabled="disabled"
      :name="fieldName"
      type="checkbox"
      class="switch-toggle__button"
      @change="update"
    />
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events | keyboard interacts with input field directly -->
    <label :for="id" @click="turnOff">{{ turnOffLabel }}</label>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events | keyboard interacts with input field directly -->
    <label :for="id" @click="turnOn">{{ turnOnLabel }}</label>
  </div>
</template>

<script>
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { uniqueId } from 'lodash'

export default {
  name: 'SwitchToggle',
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: ['off', 'on'] }) },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelOff: {
      type: String,
      default: undefined,
    },
    labelOn: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: function () {
        return uniqueId('switch-toggle-')
      },
    },
    fieldName: {
      type: String,
      default: null,
    },
  },
  computed: {
    turnOffLabel() {
      return this.labelOff === undefined ? this.$t('common.off') : this.labelOff
    },
    turnOnLabel() {
      return this.labelOn === undefined ? this.$t('common.on') : this.labelOn
    },
  },
  methods: {
    turnOff(e) {
      if (!this.modelValue) e.preventDefault()
    },

    turnOn(e) {
      if (this.modelValue) e.preventDefault()
    },

    update() {
      this.$emit('update:modelValue', this.$refs.input.checked)
    },
  },
}
</script>
