<script setup>
import { ref } from 'vue'
import { LzConfirmModal, LoadingSpinner } from 'vue_features/shared/components/ui'
import LzButton from 'vue_features/shared/components/ui/LzButton'
import { useRouter } from 'vue-router'
import { useBookmarksStore } from 'vue_features/bookmarks_index/composables/use_bookmarks_store'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

const router = useRouter()
const destroy = async () => {
  const { deleteFolder } = useBookmarksStore()
  await deleteFolder()
  await router.push({ path: '/saved' })
  closeModal('DeleteFolderModal')
}

const isDeleting = ref(false)
</script>

<template>
  <LzConfirmModal modal-id="DeleteFolderModal" :modal-title="$t('delete_folder')" class="lzui-modal--medium">
    <template #confirmBody>
      <LoadingSpinner v-if="isDeleting" />
      <p v-else class="my-2">
        {{ $t('folder_deletion_explanation') }}
      </p>
    </template>
    <template #confirmButton>
      <LzButton :disabled="isDeleting" color="primary" caution data-test="confirm-delete-btn" @click="destroy">
        {{ $t('common.delete') }}
      </LzButton>
    </template>
  </LzConfirmModal>
</template>
