<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <a class="cursor-pointer" @click="openCollectionMap">
    {{ title }}
  </a>
</template>

<script>
import { useCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'

export default {
  name: 'EditCrumb',
  props: { title: { type: String, required: true } },
  setup() {
    const { openCollectionMap } = useCollectionMapStore()
    return { openCollectionMap }
  },
}
</script>
