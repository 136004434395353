<script setup>
import { getFormDataObject, HTTP, Routes } from 'vue_features/shared/helpers/http_helper'
import { disableThenEnableConfirm, location } from 'utils'
import EditCrumb from 'vue_features/shared/components/breadcrumbs/EditCrumb'
import RailsForm from 'vue_features/shared/components/forms/RailsForm'
import { ref, computed, toRef, inject, provide } from 'vue'
import SharedForm from 'vue_features/resources/shared/components/SharedForm'
import useResourceBuilder from 'vue_features/resources/shared/composables/use_resource_builder'
import { snakeCase } from 'lodash'
import { enableUnloadConfirm } from 'utils'
import useCoverImageAttributes from '../composables/use_cover_image_attributes'
import Grid from 'vue_features/shared/components/ui/Grid'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import AdminBar from 'vue_features/shared/components/AdminBar'
import { useResourceTypes } from 'vue_features/shared/composables'
import AffectedLessonPlans from 'vue_features/shared/components/ui/AffectedLessonPlans'
import CollectionMap from 'vue_features/collection_map/components/CollectionMap'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import ResourceArchiveModal from './ResourceArchiveModal'
import EditBanner from 'vue_features/shared/components/forms/EditBanner'
import { bypassUnloadConfirmation as vBypassUnloadConfirmation } from 'vue_features/shared/directives'
import { isState, ACTIVE, ARCHIVED, DRAFT, REVISION } from 'vue_features/shared/composables/use_record_states'

const availableStates = [DRAFT, ACTIVE, ARCHIVED]

const props = defineProps({
  collectionMapAvailable: { type: Boolean, default: false },
  collectionTitle: { type: String, default: null },
  canPublish: { type: Boolean, required: true },
  resource: { type: Object, required: true },
  originalId: { type: [String, Number], default: null },
  cancelPath: { type: String, required: true },
  actionName: { type: String, required: true },
  affectedLessonPlans: { type: Array, default: () => [] },
  containerId: { type: String, default: null },
})

const root = inject('useRoot')()
const resourceForm = ref(null)
provide('resourceForm', resourceForm)

const stateLabel = (state) => {
  switch (state) {
    case DRAFT:
      return root.$t('common.draft')
    case ARCHIVED:
      return root.$t('common.archived')
    default:
      return root.$t('components.state_changer.published')
  }
}
const optionDescription = (state) => {
  switch (state) {
    case DRAFT:
      return root.$t('components.state_changer.available_to_authors', { type: props.resource.type })
    case ARCHIVED:
      return root.$t('components.state_changer.available_by_share')
    default:
      return root.$t('components.state_changer.available_to_all')
  }
}
const isPatching = computed(() => !!props.resource.id)
const savePath = computed(() => (isPatching.value ? Routes.resource_path(props.resource.id) : Routes.resources_path()))

const readableResourceType = computed(() => getLabel(props.resource.type))

const coverImageUrl = ref(() => props.resource.coverImageUrl)
const coverImageAttributes = useCoverImageAttributes(coverImageUrl, props.resource.fromMetadata?.logo)
const onSharedFormUpdate = (event) => {
  coverImageUrl.value = event.coverImageUrl
  enableUnloadConfirm()
}

const showLessonPlans = computed(() => {
  return (
    props.affectedLessonPlans.length > 0 &&
    !(props.affectedLessonPlans.length !== 1 && props.affectedLessonPlans[0].id === props.containerId)
  )
})

const { resourceBuilder } = useResourceBuilder(props.resource.type)

const { isSlide, isHyperlink } = useResourceTypes(toRef(props, 'resource'))

const currentState = ref(props.resource.state)
const originalState = computed(() => props.resource.state)
const isPersisted = computed(() => !!props.resource.id)
const isRevision = computed(() => isState(originalState.value, REVISION))

const isOptionDisabled = (option) => {
  if (isState(option, DRAFT)) {
    return isState(originalState, ARCHIVED)
  }
  if (isState(option, ACTIVE)) {
    return false
  }
  return isState(originalState, DRAFT) || !isPersisted.value
}
const isOptionSelected = (option) => currentState.value === option
const selectOption = (option) => {
  if (!isOptionDisabled(option)) {
    currentState.value = option
  }
}
const isSaving = ref(false)
const errors = ref([])
const handleSave = async () => {
  if (isSaving.value) {
    return
  }

  isSaving.value = true
  if (isRevision.value) {
    const formData = getFormDataObject(resourceForm.value.$el)
    try {
      await HTTP.put(Routes.resource_path(props.resource.id), formData)
      disableThenEnableConfirm()
      location.assign(Routes.resource_revision_path(props.originalId))
    } catch (e) {
      if (e.responseJSON) {
        errors.value = e.responseJSON
      } else {
        errors.value = ['Something went wrong']
      }
      document.documentElement.scrollTop = 0
      isSaving.value = false
    }
  } else {
    resourceForm.value.$el.submit()
  }
}
const handleConfirm = () => {
  if (isState(currentState, ARCHIVED) && isState(originalState, ACTIVE)) {
    openModal(ResourceArchiveModal)
  } else {
    handleSave()
  }
}
</script>

<template>
  <!--
    eslint-disable vuejs-accessibility/click-events-have-key-events
    eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
  -->
  <div>
    <EditBanner
      v-bypass-unload-confirmation
      v-bind="{ actionName, originalId, objectId: resource.id, cancelPath, state: resource.state }"
      :object-name="$t(readableResourceType)"
      object-type="Resource"
      @save="handleConfirm"
    />
    <RailsForm
      id="resource"
      ref="resourceForm"
      :action="savePath"
      method="post"
      :class="{ 'collection-page': collectionMapAvailable }"
    >
      <ResourceArchiveModal v-if="canModalShow(ResourceArchiveModal)" />
      <input v-if="isPatching" type="hidden" name="_method" value="patch" />
      <input :value="snakeCase(resource.type)" type="hidden" name="resource_type" />
      <CollectionMap v-if="collectionMapAvailable" />
      <div :class="{ 'collection-page__content': collectionMapAvailable }">
        <div v-if="collectionMapAvailable" class="edit-collection-bar px-4 py-2">
          <div class="mx-auto max-w-7xl px-3 md:px-6">
            <h5 class="small text-muted mt-2">
              {{ $t('shared.related_content.collection') }}
              <EditCrumb :title="collectionTitle" />
            </h5>
          </div>
        </div>
        <div v-if="!isRevision" class="mx-auto max-w-7xl px-3 md:px-6">
          <AdminBar>
            <template #settings>
              <input :name="`${snakeCase(resource.type)}[state]`" type="hidden" :value="currentState" />
              <ListDropdown v-if="canPublish" id="state-changer" :dropdown-classes="['select-dropdown__options']">
                <template #button>
                  <LzIcon path="icons/caret" />
                  <span>{{ stateLabel(currentState) }}</span>
                </template>
                <template #listItems>
                  <li
                    v-for="option in availableStates"
                    :key="option"
                    :data-test="`state-${option}`"
                    :class="[
                      'select-dropdown__option',
                      {
                        disabled: isOptionDisabled(option),
                        'select-dropdown__option--selected': isOptionSelected(option),
                      },
                    ]"
                    @click="selectOption(option)"
                  >
                    <LzIcon path="icons/complete" />
                    <div class="select-dropdown__option-description">
                      <h5>
                        {{ stateLabel(option) }}
                        <span v-if="isOptionDisabled(option)">
                          {{ $t('components.state_changer.not_available') }}
                        </span>
                      </h5>
                      <p class="smallest">{{ optionDescription(option) }}</p>
                    </div>
                  </li>
                </template>
              </ListDropdown>
            </template>
          </AdminBar>
        </div>
        <div
          class="cover-image-form min-h-50 mb-4 flex flex-col items-stretch gap-2 bg-cover bg-center bg-no-repeat pb-12 pt-2 sm:gap-4"
          :style="coverImageAttributes"
        >
          <div class="mx-auto w-full max-w-7xl px-3 md:px-6">
            <h1 class="text-white">{{
              $t(`resources.form.${actionName}`, { resource_type: $t(readableResourceType) })
            }}</h1>
          </div>
          <SharedForm
            :resource="resource"
            :errors="errors"
            input-class="h1"
            text-area-rows="3"
            @update="onSharedFormUpdate"
          />
        </div>
        <Grid class="mx-auto">
          <component
            :is="resourceBuilder"
            :resource="resource"
            :expanded-form="true"
            :class="[{ 'col-span-12': !isSlide, 'flex flex-col': isHyperlink }]"
            @update="enableUnloadConfirm"
          />
        </Grid>
        <AffectedLessonPlans
          v-if="showLessonPlans"
          :affected-lesson-plans="affectedLessonPlans"
          edit-type="resource"
          class="max-w-7xl"
        />
      </div>
    </RailsForm>
  </div>
</template>
