<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <Grid disable-default="md:px" class="lp-deck__head">
    <DeleteCardModal v-if="canModalShow(deleteCardModalId)" :modal-id="deleteCardModalId" @deleting="deleteCard" />
    <CardReorderModal v-if="canModalShow('CardReorderModal')" />
    <CardsDropdown
      :cards="slideshowCards"
      :current-position="currentPosition"
      :total-count="totalCount"
      @set-current-position="$emit('set-current-position', $event)"
    />
    <div class="align-right col-span-12 lg:col-span-5">
      <div v-if="!currentCard.isBeginLessonCard" class="margin-top-card-count card-actions">
        <button v-if="canBeHidden" data-test="hide-card-btn" class="btn" @click="hideCard(currentCard)">
          {{ capitalize($t('common.hide')) }}
        </button>
        <button v-if="isHidden" data-test="show-card-btn" class="btn" @click="showCard(currentCard)">
          {{ capitalize($t('common.show')) }}
        </button>
        <SplitActionDropdown
          v-if="isEditable"
          id="edit-card-actions"
          :button-label="$t('lesson_plan.edit_card')"
          button-icon="icons/edit"
          @button-click="edit(currentCard)"
        >
          <template #dropdownListItems>
            <template v-if="hasContentId">
              <li v-if="hasViewLink">
                <a :href="Routes.resource_path(currentCard.content.id)" lt="ignore" target="_blank" rel="nofollow">
                  {{ $t('lesson_plans.context_menu.view_resource') }}
                </a>
              </li>
              <li>
                <button @click="copyToClipboard">
                  {{ $t('lesson_plans.context_menu.copy_resource_id') }}
                </button>
              </li>
            </template>
            <li v-if="isCopyable">
              <button
                type="button"
                :title="hasTooManyCards ? $t('lesson_plan.too_many_cards') : null"
                :class="{ disabled: hasTooManyCards }"
                data-test="copy-card-btn"
                @click="copyCurrentCard(currentCard)"
              >
                {{ $t('lesson_plan.copy_card') }}
              </button>
            </li>
            <li>
              <button type="button" data-test="delete-card-btn" @click="openModal(deleteCardModalId)">
                {{ $t('lesson_plan.delete_card') }}
              </button>
            </li>
          </template>
        </SplitActionDropdown>
        <button
          :disabled="slideshowCards.length < 2"
          data-test="reorder-cards-btn"
          class="btn btn--demoted btn--small"
          @click="openModal('CardReorderModal')"
        >
          <LzIcon path="icons/list" />
          {{ $t('lesson_plan.reorder_cards') }}
        </button>
        <button
          :disabled="hasTooManyCards"
          :title="hasTooManyCards ? $t('lesson_plan.too_many_cards') : null"
          data-test="add-card-btn"
          class="btn btn--demoted btn--small"
          @click="addCard(currentPosition)"
        >
          <LzIcon path="icons/add" />
          {{ $t('lesson_plan.add_card') }}
        </button>
      </div>
    </div>
  </Grid>
</template>

<script>
import { computed } from 'vue'
import Routes from 'routes'
import { useCopyToClipboard } from 'vue_features/shared/composables'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { LzIcon, Grid } from 'vue_features/shared/components/ui'
import SplitActionDropdown from 'vue_features/shared/components/ui/dropdowns/SplitActionDropdown'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import DeleteCardModal from './DeleteCardModal'
import CardReorderModal from './CardReorderModal'
import { capitalize } from 'lodash'
import { useCardTypes, hasPersistedResource } from 'vue_features/shared/composables/use_card_types'
import useCardActiveEdit from 'vue_features/lesson_plans/edit/composables/use_card_active_edit'
import CardsDropdown from 'vue_features/lesson_plans/components/CardsDropdown'

const MAX_CARDS = 80

export default {
  name: 'CardDeckEditorHeader',
  components: { Grid, CardsDropdown, SplitActionDropdown, LzIcon, DeleteCardModal, CardReorderModal },
  props: {
    currentPosition: { type: Number, required: true },
    totalCount: { type: Number, required: true },
  },
  setup(props) {
    const { slideshowCards, edit, addCard, destroyCard, copyCard, hideCard, showCard } = useCardDeckEditorStore()
    const currentCard = computed(() => slideshowCards.value[props.currentPosition])
    const deleteCardModalId = 'deck-header-delete-modal'

    const { isQuestionCard, isDeprecatedResourceTypeCard } = useCardTypes(currentCard)

    const resourceId = computed(() => currentCard.value.content.id)
    const { copy: copyToClipboard } = useCopyToClipboard(resourceId)

    const isCopyable = computed(() => !isQuestionCard.value && !isDeprecatedResourceTypeCard.value)
    const deleteCard = () => destroyCard(currentCard.value)

    const { isHidden, canBeHidden, isEditable } = useCardActiveEdit(computed(() => currentCard.value.activeEdit))
    const hasTooManyCards = computed(() => slideshowCards.value.length >= MAX_CARDS)
    const hasContentId = computed(() => hasPersistedResource(currentCard))
    const hasViewLink = computed(() => !isQuestionCard.value)

    const copyCurrentCard = (card) => {
      if (!hasTooManyCards.value) {
        copyCard(card)
      }
    }

    return {
      Routes,
      slideshowCards,
      currentCard,
      edit,
      addCard,
      openModal,
      canModalShow,
      deleteCardModalId,
      deleteCard,
      isCopyable,
      copyCurrentCard,
      copyToClipboard,
      hideCard,
      showCard,
      isHidden,
      canBeHidden,
      isEditable,
      hasTooManyCards,
      hasContentId,
      hasViewLink,
      capitalize,
    }
  },
}
</script>
