import { ref, computed, reactive, watch } from 'vue'
import { assign } from 'lodash'
import getSpanDimensions from './models/span_dimensions'
import getSplitDimensions from './models/split_dimensions'
import buildSlideContent from './models/slide_content_factory'
import useContentParsers from './use_content_parsers'

export default function useSlideIndicator(grid, insert) {
  const { gridStyle } = useContentParsers()

  const active = ref(false)
  const cursorPos = reactive({ top: 0, left: 0 })
  const spanDimensions = ref(null)
  const splitDimensions = ref(null)
  const tempContent = ref(null)
  const content = ref(null)
  let detectionMatch = null

  watch(cursorPos, setContentDimensions)

  const isSplitting = computed(() => {
    if (!spanDimensions.value || !detectionMatch) {
      return true
    }
    const triangle = detectionMatch.triangle
    const pos = detectionMatch.isVertical() ? 'top' : 'left'
    const middle = triangle.point1[pos]
    const base = triangle.point2[pos]
    const diff = detectionMatch.isBefore() ? middle - base : base - middle
    const cursorValue = cursorPos[pos]
    return detectionMatch.isBefore() ? cursorValue > base + diff / 2 : cursorValue < base - diff / 2
  })

  const style = computed(() => {
    if (tempContent.value) {
      return gridStyle(tempContent.value)
    }
    return {}
  })

  function activate(newMatch, cursor) {
    active.value = true
    detectionMatch = newMatch
    tempContent.value = buildSlideContent(content.value)
    const contentMatch = JSON.parse(detectionMatch.element.dataset.grid)
    splitDimensions.value = getSplitDimensions(contentMatch, detectionMatch)
    spanDimensions.value = getSpanDimensions(grid, contentMatch, detectionMatch)
    cursorPos.top = cursor.top
    cursorPos.left = cursor.left
  }

  function drop() {
    if (active.value) {
      setDimensions(content, tempContent.value)
      insert(content.value)
    }
  }

  function setContentDimensions() {
    if (splitDimensions.value) {
      setDimensions(tempContent, isSplitting.value ? splitDimensions.value : spanDimensions.value)
    } else {
      active.value = false
    }
  }

  function setDimensions(content, { row, column, rowSpan, colSpan }) {
    assign(content.value, { row, column, rowSpan, colSpan })
  }

  return { active, activate, drop, cursorPos, content, setContentDimensions, style }
}
