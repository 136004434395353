import KlassesIndexService from '../../index/api'
import KlassesShowService from '../api/klasses_show_service'
import EnrollmentsService from '../api/enrollments_service'
import { findIndex } from 'lodash'
import { location } from 'utils'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive } from 'vue'

export const useKlassShowStore = defineStore('klassShow', () => {
  const state = reactive({
    klass: {},
    teachers: [],
    studentsData: {
      students: [],
      meta: {
        pagination: {},
      },
    },
    showProgressCover: false,
    addedToStudentList: false,
    newlyUnenrolledIds: [],
    unenrolledStudents: [],
    unenrolledCount: 0,
    loadedUnenrolledStudents: false,
    googleCourses: null,
    teacherNames: [],
    addedTeacherToKlass: false,
  })

  function loadMoreStudents() {
    const page = state.studentsData.meta.pagination.nextPage

    return KlassesShowService.getStudents({ klassId: state.klass.id, page }).then((studentsData) => {
      state.studentsData.students.push(...studentsData.students)
      state.studentsData.meta = studentsData.meta
    })
  }

  function addStudentsToClass({ students }) {
    state.showProgressCover = true
    const payload = { klassId: state.klass.id, studentIds: students.map((s) => s.id) }

    return EnrollmentsService.addToClass(payload).then(() => {
      state.showProgressCover = false
      state.addedToStudentList = true
    })
  }

  function deleteStudent({ student }) {
    state.showProgressCover = true
    const done = () => {
      const studentData = state.studentsData
      const students = state.studentsData.students
      const studentIndex = findIndex(students, (s) => s.id === student.id)

      students.splice(studentIndex, 1)
      studentData.meta.pagination.totalCount = studentData.meta.pagination.totalCount - 1

      state.showProgressCover = false
    }

    return EnrollmentsService.removeFromKlass({ klassId: state.klass.id, studentId: student.id }).then(done)
  }

  function archiveKlass(klass) {
    return KlassesIndexService.archiveKlass(klass).then(() => {
      location.assign(Routes.teacher_klasses_url({ archive: true }))
    })
  }

  function updateKlassName({ klass, name }) {
    return KlassesIndexService.updateKlassName({ klass, name }).then((klassData) => {
      state.klass = klassData
    })
  }

  function reloadKlass({ klass }) {
    return KlassesIndexService.getKlass({ klassId: klass.id }).then((klassData) => {
      state.klass = klassData
    })
  }

  function unarchiveKlass() {
    return KlassesIndexService.unarchiveKlass(state.klass).then(() => {
      state.klass.active = true
    })
  }

  function deleteKlass(klass) {
    return KlassesIndexService.deleteKlass({ klass }).then(() => {
      location.assign(Routes.teacher_klasses_url())
    })
  }

  function unenrollStudent(student) {
    state.unenrolledStudents.unshift(student)
    state.unenrolledCount += 1
    state.newlyUnenrolledIds.push(student.id)
  }

  function associateGoogleCourseToKlass({ klass, courseId }) {
    return KlassesIndexService.linkToGoogleCourse({ klass, courseId }).then((klassData) => {
      state.klass = klassData
      return klassData
    })
  }

  function disassociateGoogleCourseFromKlass({ klass }) {
    return KlassesIndexService.unlinkToGoogleCourse({ klass }).then((klassData) => {
      state.klass = klassData
      return klassData
    })
  }

  function addTeachersToClass({ teacherIds }) {
    return EnrollmentsService.addTeachersToClass({
      klassId: state.klass.id,
      teacherIds,
    }).then((teachersData) => {
      state.teachers = [...teachersData]
      state.addedTeacherToKlass = true
    })
  }

  function removeMeFromClass() {
    return EnrollmentsService.removeMeFromClass({
      klassId: state.klass.id,
    }).then((teachersData) => {
      state.teachers = [...teachersData]
    })
  }

  function reloadTeachers({ klass }) {
    return KlassesIndexService.getTeachersForKlass(klass).then((teachersData) => {
      state.teachers = [...teachersData]
    })
  }

  return {
    state,
    loadMoreStudents,
    addStudentsToClass,
    deleteStudent,
    archiveKlass,
    updateKlassName,
    reloadKlass,
    unarchiveKlass,
    deleteKlass,
    unenrollStudent,
    associateGoogleCourseToKlass,
    disassociateGoogleCourseFromKlass,
    addTeachersToClass,
    removeMeFromClass,
    reloadTeachers,
  }
})
