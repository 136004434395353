import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, errors, standards } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(errors, 'errors'),

  standards,
)
