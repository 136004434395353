<template>
  <DetailListItem
    :key="item.id"
    :item-id="item.id"
    :title="displayName(item)"
    :href="item.href"
    :target="'_blank'"
    :thumbnail="getThumbnail(item)"
    :icon="getIcon(item)"
    :class="[
      ' border hover:shadow-xl',
      { 'border-green-400 ring-2 ring-green-400': isSelected },
      { 'border-base  shadow-md': !isSelected },
    ]"
    thumbnail-size="m"
  >
    <template #title>
      <p class="flex max-w-min items-center gap-1 text-lg font-bold">
        <span class="truncate">
          {{ displayName(item) }}
        </span>
        <LzIcon path="icons/share" class="flex-shrink-0" icon-color-class="text-neutral-600" />
      </p>
      <LzContentPremiumBadge :premium="item.premium" />
      <LzContentStateBadge :state="item.state" />
    </template>
    <template #metadata>
      <MetadataByline :logo="item.logo" :name="item.customer" />
    </template>
    <template #handle>
      <div class="flex items-center px-4">
        <LzIcon
          :class="[
            'rounded-full border',
            {
              'border-transparent text-green-400': isSelected,
              'border-base text-transparent': !isSelected,
            },
          ]"
          size="lg"
          path="icons/complete"
        />
      </div>
    </template>
  </DetailListItem>
</template>

<script>
import { LzContentPremiumBadge, LzContentStateBadge } from 'vue_features/lz_content/components'
import { LzIcon } from 'vue_features/shared/components/ui'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import MetadataByline from 'vue_features/shared/components/lists/MetadataByline'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import { getThumbnail, getIcon } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'SearchResult',
  components: { LzContentPremiumBadge, LzContentStateBadge, DetailListItem, MetadataByline, LzIcon },
  props: {
    item: { type: Object, required: true },
    isSelected: { type: Boolean, required: true },
  },
  setup() {
    const { displayName } = useModalSearch()
    return { displayName, getThumbnail, getIcon }
  },
}
</script>
