<template>
  <div
    class="lesson-last-card lesson-card-wrapper relative mb-0 flex w-full flex-col items-center gap-y-8 py-10 shadow-lg"
    :class="fontTextStyle"
  >
    <template v-if="isAssessment">
      <EmptyStateWithAnimation
        v-if="isAssignmentViewSubmitted"
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.end_of_set')"
        :body="$t('lesson_card_templates.submitted_assessment')"
        data-test="submitted-assessment"
      >
        <LzButton data-test="assessment-done" color="primary" @click.prevent="$emit('exit-player')">
          {{ $t('common.done') }}
        </LzButton>
      </EmptyStateWithAnimation>
      <template v-else>
        <EmptyStateWithAnimation
          v-if="hasCompleted"
          key="empty-state-completed"
          ref="emptyState"
          :animation-loader="() => import('lottie-animations/finished-work.json')"
          autoplay
          loop
          :heading="$t('lesson_card_templates.end_of_assessment')"
          :body="$t('lesson_card_templates.may_review_change')"
          data-test="completed-assessment"
        >
          <div class="flex items-center gap-2">
            <LzButton class="m-0" data-test="assessment-review" @click.prevent="$emit('on-card-selected', 0)">
              <LzIcon type="div" path="icons/previous" />
              {{ $t('common.review') }}
            </LzButton>
            <LzButton class="m-0" data-test="assessment-submit" color="primary" @click.prevent="submitCards">
              {{ $t('common.submit') }}
            </LzButton>
          </div>
        </EmptyStateWithAnimation>
        <EmptyStateWithAnimation
          v-else
          key="empty-state-not-completed"
          ref="emptyState"
          :animation-loader="() => import('lottie-animations/finished-work.json')"
          autoplay
          loop
          :heading="unattemptedQuestionsHeading"
          :body="$t('lesson_card_templates.request_review_change')"
        >
          <div class="flex items-center gap-2">
            <LzButton
              class="m-0"
              color="primary"
              data-test="assessment-review"
              @click.prevent="$emit('on-card-selected', 0)"
            >
              <LzIcon type="div" path="icons/previous" />
              {{ $t('common.review') }}
            </LzButton>
            <LzButton class="m-0" data-test="assessment-submit" @click.prevent="submitCards">
              {{ $t('common.submit') }}
            </LzButton>
          </div>
        </EmptyStateWithAnimation>
      </template>
    </template>
    <template v-else>
      <EmptyStateWithAnimation
        v-if="questionCardsCount > 0"
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.thanks_for_the_effort')"
        :body="$t('lesson_card_templates.may_review_change')"
      >
        <div class="flex">
          <LzButton class="mr-3" data-test="non-assessment-review" @click.prevent="$emit('on-card-selected', 0)">
            <LzIcon type="div" path="icons/previous" />
            {{ $t('common.review') }}
          </LzButton>
          <LzButton data-test="non-assessment-done" color="primary" @click.prevent="submitAndReturnToList">
            {{ $t('common.done') }}
          </LzButton>
        </div>
      </EmptyStateWithAnimation>
      <EmptyStateWithAnimation
        v-else
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.thanks_for_the_effort')"
      >
        <button
          class="btn btn--primary"
          data-test="non-assessment-without-questions-done"
          @click.prevent="submitAndReturnToList"
        >
          {{ $t('common.done') }}
        </button>
      </EmptyStateWithAnimation>
    </template>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import {
  useAssignmentStore,
  useAssignmentShowStore,
  useAssignmentCardsStore,
} from 'vue_features/assignments/show/composables'
import { EmptyStateWithAnimation, LzIcon, LzButton } from 'vue_features/shared/components/ui'

export default {
  name: 'LessonEndCard',
  components: { EmptyStateWithAnimation, LzIcon, LzButton },
  setup(_, { emit }) {
    const root = inject('useRoot')()
    const { isAssessment } = useAssignmentStore()

    const { isAssignmentViewSubmitted } = useAssignmentShowStore()
    const { attemptedQuestionCardsCount, questionCardsCount, submitCards } = useAssignmentCardsStore()
    const hasCompleted = computed(() => attemptedQuestionCardsCount.value === questionCardsCount.value)
    const unattemptedQuestionCardsCount = computed(() => questionCardsCount.value - attemptedQuestionCardsCount.value)
    const unattemptedQuestionsHeading = computed(() => {
      if (unattemptedQuestionCardsCount.value === 1) {
        return root.$t('lesson_card_templates.unanswered_question')
      } else {
        return root.$t('lesson_card_templates.unanswered_questions', { count: unattemptedQuestionCardsCount.value })
      }
    })

    const submitAndReturnToList = async () => {
      await submitCards()
      emit('exit-player')
    }

    return {
      isAssessment,
      isAssignmentViewSubmitted,
      hasCompleted,
      unattemptedQuestionsHeading,
      submitCards,
      submitAndReturnToList,
      questionCardsCount,
      fontTextStyle: inject('fontTextStyle'),
    }
  },
}
</script>
