<template>
  <div id="item-analysis">
    <div class="page-head-nav border-base border-b">
      <div class="mx-auto max-w-7xl px-6">
        <h1 class="mt-10">{{ $t('item_analysis') }}</h1>
        <LzTabs class="lzui-nav-tabs vue-tabs mx-auto">
          <li>
            <a
              v-track-click.sendBeacon.once="{ eventName: 'Data Dashboard UI tab clicked' }"
              data-test="data-dashboard-tab-link"
              :href="dataDashboardUrl"
            >
              {{ $t('data_dashboard.dashboard') }}
            </a>
          </li>
          <TabLink
            v-track-click.sendBeacon.once="{ eventName: 'Item Analysis UI tab clicked' }"
            data-test="item-analysis-tab-link"
            target="item_analysis"
            initial
          >
            <span>{{ $t('item_analysis') }}</span>
            <span class="absolute bottom-full right-0 inline-flex">
              <NewBadge class="translate-x-2 translate-y-2 transform" display-until="2023-05-01" />
            </span>
          </TabLink>
          <li>
            <a
              v-track-click.sendBeacon.once="{ eventName: 'Download Data tab clicked' }"
              data-test="download-data-tab-link"
              :href="dataDownloadUrl"
            >
              {{ $t('data_dashboard.download_data') }}
            </a>
          </li>
        </LzTabs>
      </div>
    </div>

    <RouterView />
  </div>
</template>

<script setup>
import { onUnmounted, onMounted } from 'vue'
import { LzTabs, TabLink, NewBadge } from 'vue_features/shared/components/ui'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { trackClick as vTrackClick } from 'vue_features/shared/directives'
import { useItemAnalysisStore } from '../composables/use_item_analysis_store'
import { filtersFromParams } from '../utils'
import { useRoute, useRouter } from 'vue-router'

const {
  state,
  withAutoCleanDisabled,
  reloadAllSuggestions,
  defaultFilters,
  updateFilters,
  selectLessonPlan,
  reloadFilteredItemData,
} = useItemAnalysisStore()

const dataDownloadUrl = Routes.student_data_reports_path()
const dataDashboardUrl = Routes.data_dashboard_index_path()

let stopRouteListener = null

const router = useRouter()
const route = useRoute()
onMounted(() => {
  stopRouteListener = router.afterEach((to) => {
    withAutoCleanDisabled(() => {
      updateFilters({ ...defaultFilters, ...filtersFromParams(to.query) })

      if (to.query.lesson_plan_id && to.query.lesson_plan_id != state.lessonPlan?.id) {
        selectLessonPlan({ id: to.query.lesson_plan_id }, true)
      } else {
        reloadFilteredItemData()
      }

      reloadAllSuggestions()
    })
  })

  if (state.lessonPlan?.id) {
    selectLessonPlan({ id: state.lessonPlan.id }, true)
  }

  updateFilters(filtersFromParams(route.query))
})

onUnmounted(() => {
  if (stopRouteListener) {
    stopRouteListener()
    stopRouteListener = null
  }
})
</script>
