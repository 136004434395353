const imMeta = [[400, 2266, 2292], 'icons/authoring/im-logo.svg', 'what_filter_modal.authors.im']
const elMeta = [[451], 'icons/authoring/el-logo.svg', 'what_filter_modal.authors.el']
const guidebooksMeta = [[401, 1427], 'icons/authoring/guidebooks-logo.svg', 'what_filter_modal.authors.guidebooks']
const odellMeta = [[524], 'icons/authoring/odell-logo.svg', 'what_filter_modal.authors.odell']

function generateAuthoringSection(ids, iconPath, titleKey, typeKeys) {
  return {
    ids,
    iconPath,
    titleKey,
    typeKeys: typeKeys.map((key) => `what_filter_modal.types.${key}`),
  }
}

export const assessmentsGuidance = {
  type: 'what_filter_modal.assessments',
  description: 'what_filter_modal.assessments_description',
  customerContent: [
    generateAuthoringSection(...imMeta, ['mid_unit', 'end_of_unit']),
    generateAuthoringSection(...elMeta, ['mid_unit', 'end_of_unit']),
    generateAuthoringSection(...guidebooksMeta, ['section_quizzes', 'cold_read_tasks', 'culminating_tasks']),
    generateAuthoringSection(...odellMeta, ['section_diagnostics', 'culminating_tasks']),
  ],
}

export const activitiesGuidance = {
  type: 'what_filter_modal.activities',
  description: 'what_filter_modal.activities_description',
  customerContent: [
    generateAuthoringSection(...imMeta, [
      'check_your_readiness_assessment',
      'cool_downs',
      'practice_problems',
      'student_task_statements',
      'checkpoints',
      'applets',
    ]),
    generateAuthoringSection(...elMeta, [
      'vocabulary_toolkits',
      'vocabulary_logs',
      'independent_reading_journals',
      'homework',
      'note_catchers',
      'all_block_journals',
      'skills_block_activities',
    ]),
    generateAuthoringSection(...odellMeta, ['section_diagnostic_checklists', 'culminating_task_checklists']),
  ],
}

export const fullLessonsGuidance = {
  type: 'what_filter_modal.full_lessons',
  description: 'what_filter_modal.full_lessons_description',
  customerContent: [
    generateAuthoringSection(...imMeta, ['lessons_presentations_with_applets']),
    generateAuthoringSection(...elMeta, ['daily_lessons']),
    generateAuthoringSection(...odellMeta, ['question_sets', 'content_specific_tools']),
  ],
}
