<template>
  <AsyncContentLoader :loader="loader">
    <div class="overflow-hidden">
      <div class="border-primary-100 sticky top-0 z-10 border-b border-solid">
        <div v-if="klassAndCodeKlassActive" class="flex-start flex space-x-3 overflow-auto bg-white p-3 pt-0 sm:pt-3">
          <LzButton
            :disabled="noneSelected"
            class="whitespace-nowrap"
            data-test="open-now-button"
            @click="changeStateForSelected('opened')"
          >
            {{ $t('assignment_status.assignment_actions.open_now') }}
          </LzButton>
          <LzButton
            :disabled="noneSelected || someCreatedStatus"
            data-test="close-now-button"
            class="whitespace-nowrap"
            @click="changeStateForSelected('closed')"
          >
            {{ $t('assignment_status.assignment_actions.close_now') }}
          </LzButton>
          <LzButton
            :disabled="noneSelected || someCreatedStatus"
            data-test="return-now-button"
            class="whitespace-nowrap"
            @click="changeStateForSelected('returned')"
          >
            {{ $t('assignment_status.assignment_actions.return') }}
          </LzButton>
          <LzButton
            class="relative whitespace-nowrap"
            :disabled="noneSelected || someClosedOrReturned"
            data-test="schedule-assignment-button"
            @click="scheduleAssignment"
          >
            {{ $t('assignment_status.assignment_actions.schedule_assignment') }}
          </LzButton>
        </div>
        <div v-if="allSelected && canLoadMore" class="bg-gray-300 py-2 text-sm" data-test="select-all-in-class">
          {{ $t('assignment_report.all_students_shown_are_selected', { visible_count: visibleItems.length }) }}
          <button class="text-accent hover:text-primary cursor-pointer" @click="showHiddenAndSelectAll">
            {{ $t('assignment_report.select_all_students_in_the_class', { total_count: totalCount }) }}
          </button>
        </div>
      </div>

      <div class="bg-neutral-50 overflow-auto">
        <DataTable :compact="true">
          <DataTableRow class="text-primary-500 font-bold" :frozen="true">
            <DataTableFrozenColumns class="border-primary-100 border-r">
              <DataTableColumn name="checkbox" class="bg-primary-50 p-4">
                <input
                  v-if="klassAndCodeKlassActive"
                  type="checkbox"
                  class="mt-0 h-4 w-4"
                  :checked="allSelected"
                  :aria-label="$t('assignment_report.select_all_in_class')"
                  data-test="select-all-checkbox"
                  @change="toggleSelectAll"
                />
              </DataTableColumn>

              <DataTableColumn
                name="studentFirstName"
                sortable
                class="bg-primary-50 py-4 pl-2 pr-6 capitalize"
                horizontal-alignment="left"
              >
                {{ $t('common.first_name') }}
              </DataTableColumn>

              <DataTableColumn
                name="studentLastName"
                sortable
                class="bg-primary-50 py-4 pl-2 pr-6 capitalize"
                horizontal-alignment="left"
              >
                {{ $t('common.last_name') }}
              </DataTableColumn>
            </DataTableFrozenColumns>

            <DataTableColumn
              v-if="isConnectedToGoogle && klass.hasGoogleClassroomAssignment"
              name="google"
              class="bg-primary-50 p-4"
            >
              <LzIcon size="sm" path="icons/google-signin" />
            </DataTableColumn>

            <DataTableColumn name="assignmentState" sortable class="bg-primary-50 py-4 pl-2 pr-6 capitalize">
              {{ $t('common.status') }}
            </DataTableColumn>

            <DataTableColumn name="date" sortable class="bg-primary-50 p-4">{{ $t('common.date') }}</DataTableColumn>

            <DataTableColumn
              name="sortableAssignmentScore"
              sortable
              :auto-place-sort-arrow="false"
              class="bg-primary-50"
            >
              <div class="flex h-full w-full items-center justify-center p-2">
                <span>{{ capitalize($t('assignment_report.score')) }}</span>
                <dt-sort-arrow column="sortableAssignmentScore" />
              </div>

              <div
                class="absolute bottom-0 left-0 h-1 w-full"
                :class="indicatorClass(klassRating)"
                :data-test="`rating-indicator-${klassRating}`"
              />
            </DataTableColumn>

            <DataTableColumn
              v-for="item of items"
              :key="item.itemNumber"
              :name="`item-${item.itemNumber}`"
              sortable
              :auto-place-sort-arrow="false"
              class="bg-primary-50 relative"
            >
              <div class="flex h-full w-full items-center justify-center p-2">
                <span>{{ item.itemNumber }}</span>
              </div>

              <dt-sort-arrow
                :column="`item-${item.itemNumber}`"
                class="absolute right-0 top-1/2 -translate-y-1/2 transform"
              />

              <div class="absolute bottom-0 left-0 h-1 w-full" :class="indicatorClassForItem(item)" />
            </DataTableColumn>
          </DataTableRow>

          <ItemScoreRow
            v-for="(row, index) of rows"
            :key="row.id"
            :ref="studentRows"
            :assignment-view="row"
            :index="index"
            :items="items"
            :active="klassAndCodeKlassActive"
            :selection-list="selectionList"
            :recently-added="afterFirstLoad"
            :has-google-classroom-assignment="klass.hasGoogleClassroomAssignment"
            :google-student="googleStudentForStudent(row)"
            :google-passback-students="passbackStudentsForKlass"
            @link-google-student="linkStudentInClass"
            @selection-changed="updateSelection"
          />
        </DataTable>
      </div>

      <div v-if="canLoadMore" class="border-primary-100 flex items-center justify-center border-t py-2">
        <LoadMoreButton class="p-0" v-bind="{ isLoading }" @click="loadMore" />
      </div>
    </div>
  </AsyncContentLoader>
</template>

<script setup>
import { computed, ref, inject, toRef, watch } from 'vue'
import { capitalize, get } from 'lodash'
import { AsyncContentLoader, LzButton, LzIcon } from 'vue_features/shared/components/ui'
import ItemScoreRow from './ItemScoreRow'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { scoreRating } from 'vue_features/assignments/reports/utils/scoring'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useAssignmentReportStore, useItemScoreDataTable } from 'vue_features/assignments/reports/store'
import DataTable from 'vue_features/shared/components/data_table/DataTable'
import DataTableFrozenColumns from 'vue_features/shared/components/data_table/DataTableFrozenColumns'
import DataTableColumn from 'vue_features/shared/components/data_table/DataTableColumn'
import DataTableRow from 'vue_features/shared/components/data_table/DataTableRow'
import DtSortArrow from 'vue_features/shared/components/data_table/DataTableSortArrow'

const props = defineProps({
  klass: {
    type: Object,
    required: false,
    default: () => null,
  },
  assignmentViews: {
    type: Array,
    required: true,
    default: () => [],
  },
  pagination: {
    type: Object,
    required: true,
  },
  reportData: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  isLoadTriggered: {
    type: Boolean,
    default: false,
  },
})

const rowsPerPage = 30
const totalCount = computed(() => props.pagination.totalCount || 0)

const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
  itemsRef: toRef(props, 'assignmentViews'),
  totalCount: totalCount,
  moreCount: rowsPerPage,
  startCount: rowsPerPage,
})
const { gradePassbackStatus, getAssignmentReports, changeAssignmentStateForKlass } = useAssignmentReportStore()
const { lzStudentsData, isConnectedToGoogle } = useGoogleClassroomStore()
const { rows, items } = useItemScoreDataTable(visibleItems)

const linkGoogleStudentInClass = inject('linkGoogleStudentInClass', () => {})

const selectionList = ref([])
const studentRows = ref([])
const loader = ref(null)
const afterFirstLoad = ref(false)

const klassId = computed(() => (props.klass ? props.klass.id : null))
const selectedItems = computed(() => visibleItems.value.filter((item) => selectionList.value.includes(item.id)))
const someCreatedStatus = computed(() => selectedItems.value.some((item) => item.assignmentState === 'created'))
const someClosedOrReturned = computed(() =>
  selectedItems.value.some((item) => item.assignmentState === 'closed' || item.assignmentState === 'returned'),
)
const klassAndCodeKlassActive = computed(() => {
  return props.klass.active && props.klass.lzCodeKlass.active
})
const allSelected = computed(() => {
  return selectionList.value.length > 0 && selectionList.value.length === visibleItems.value.length
})
const noneSelected = computed(() => {
  return selectionList.value.length === 0
})
const passbackStudentsForKlass = computed(() => {
  if (!isConnectedToGoogle.value || !gradePassbackStatus.value || !props.klass.id) return null

  const klass = gradePassbackStatus.value.find((status) => status.klassId === props.klass.id)
  return get(klass, 'students')
})
const validResponses = computed(() => {
  if (get(props.assignmentViews, 'length') && showingFullList.value) {
    return props.assignmentViews.filter((assignmentView) => Object.keys(assignmentView.reportData.items).length > 0)
  }
  return []
})
const scoresByItem = computed(() => {
  const itemScores = {}
  validResponses.value.map(({ reportData: { items } }) => {
    Object.values(items).forEach((itemResponse) => {
      const { ibkReference, score, maxScore } = itemResponse
      const itemScore = itemScores[ibkReference] || { score: 0, maxScore: 0 }

      itemScore.score += score || 0
      itemScore.maxScore += maxScore

      itemScores[ibkReference] = itemScore
    })
  })

  return itemScores
})
const klassRating = computed(() => {
  const scoreValues = Object.values(scoresByItem.value)
  if (!scoreValues.length) return 'none'

  const { score, maxScore } = scoreValues.reduce(
    ({ score, maxScore }, item) => {
      return {
        score: score + (item.score || 0),
        maxScore: maxScore + (item.maxScore || 0),
      }
    },
    { score: 0, maxScore: 0 },
  )
  return rating(score / maxScore)
})
const showingFullList = computed(() => {
  return props.pagination && props.pagination.currentPage === props.pagination.totalPages
})

// FIXME Simplify this with a Set after we're on Vue 3
function updateSelection(assignmentViewId, selected) {
  const index = selectionList.value.indexOf(assignmentViewId)

  if (selected) {
    if (index === -1) selectionList.value.push(assignmentViewId)
  } else {
    if (index > -1) selectionList.value.splice(selectionList.value.indexOf(assignmentViewId), 1)
  }
}

function selectAll() {
  selectionList.value = visibleItems.value.map((item) => item.id)
}

function toggleSelectAll(event) {
  if (event.target.checked) {
    selectAll()
  } else {
    selectionList.value = []
  }
}

async function showHiddenAndSelectAll() {
  while (canLoadMore.value) {
    await showMoreItems(handleLoadMore)
    selectAll()
  }
}

function loadReportData() {
  return getAssignmentReports({
    klassId: klassId.value,
    page: props.pagination.nextPage || 1,
    perPage: rowsPerPage,
  })
}

async function handleLoadMore(done) {
  await loadReportData()
  done()
}

function changeStateForSelected(assignmentState) {
  return changeAssignmentStateForKlass({
    klassId: klassId.value,
    assignmentViewIds: selectionList.value,
    assignmentState,
  })
}

async function loadMore() {
  afterFirstLoad.value = true
  const latestRowIndex = visibleItems.value.length
  await showMoreItems(handleLoadMore)
  studentRows.value[latestRowIndex]?.$refs.inputRef?.focus()
}

function rating(score) {
  if (score === null) return 'none'
  return scoreRating(score)
}

function indicatorClassForItem({ ibkReference }) {
  const scoresForItem = scoresByItem.value[ibkReference]
  if (scoresForItem) {
    const average = scoresForItem.score / scoresForItem.maxScore
    const itemRating = rating(average)
    return itemRating === null ? '' : indicatorClass(itemRating)
  }
}

function indicatorClass(rating) {
  if (rating === 'low') {
    return 'bg-red-300'
  } else if (rating === 'medium') {
    return 'bg-yellow-300'
  } else if (rating === 'high') {
    return 'bg-green-300'
  } else {
    return ''
  }
}

function linkStudentInClass(assignmentView, googleStudent) {
  linkGoogleStudentInClass({
    klass: props.klass,
    studentId: assignmentView.studentId,
    studentName: assignmentView.studentName,
    googleStudent,
  })
}

function googleStudentForStudent(assignmentView) {
  if (!lzStudentsData.value) return null

  const lzStudent = lzStudentsData.value.find((lzStudent) => lzStudent.id === assignmentView.studentId)
  return get(lzStudent, 'googleClassroomUserData')
}

watch(
  () => props.isLoadTriggered,
  (isTriggered) => {
    if (isTriggered && props.assignmentViews.length === 0) {
      loader.value = loadReportData()
    }
  },
  { immediate: true },
)

const root = inject('useRoot')()
function scheduleAssignment() {
  root.$rootEmit('schedule-assignment', { klassId: klassId.value, assignmentViews: selectedItems.value })
}
</script>
