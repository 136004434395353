<template>
  <div ref="lessonPlayerElement" class="h-full w-full">
    <div ref="scrollManagerRoot" class="h-full w-full">
      <div class="lesson-content flex w-full flex-row">
        <LessonThumbs
          class="top-2 hidden flex-col items-center sm:flex"
          :cards="cards"
          :show-sidebar="showSidebar"
          :selected-card-index="selectedCardIndex"
          @card-selected="scrollToSelectedCard"
        />

        <LessonContainer
          :cards="cards"
          :selected-card-index="selectedCardIndex"
          :is-preview="isPreview"
          @on-card-selected="scrollToSelectedCard"
          @exit-player="$emit('exit-player')"
        />
        <StudentToolsPanel />
      </div>

      <div
        class="sticky z-50"
        :class="{
          'bottom-1': showToolbar,
          'hide -bottom-18': !showToolbar,
        }"
      >
        <LessonToolbar
          v-if="cards.length > 0"
          class="print:hidden"
          :cards="cards"
          :show-toolbar="showToolbar"
          :show-tools-coachmark="true"
          :show-sidebar-button="allowSidebar"
          :show-annotations-button="false"
          :show-tools-button="!isFullscreen && hasStudentTools"
          :show-fullscreen-button="!isContentEmbedded && allowFullscreen"
          :selected-card-index="selectedCardIndex"
          :show-save-and-exit-button="true"
          @toggle-sidebar="combine(preserveCard, toggleSidebar)"
          @toggle-toolbar="toggleToolbar"
          @card-selected="scrollToSelectedCard"
          @go-previous="goPrevious"
          @go-next="goNext"
          @exit-player="$emit('exit-player')"
          @toggle-fullscreen="fullscreenHandler"
        />
      </div>
      <div
        v-if="showBackToTop"
        data-test="back-to-top-wrapper"
        class="fixed bottom-20 right-4 z-50 transform transition-all duration-300 2xl:bottom-2 2xl:right-8"
        :class="hasScrolledPastThreshold ? 'translate-y-0 opacity-100' : 'pointer-events-none translate-y-10 opacity-0'"
      >
        <button
          data-test="back-to-top"
          class="lesson-back-to-top z-50 flex flex-col items-center space-y-2"
          aria-labelledby="back-to-top-label"
          @click="scrollToTop"
        >
          <div
            class="text-primary-700 flex h-16 w-16 items-center justify-center rounded-full bg-white p-4 text-center text-opacity-90 shadow-xl transition-all duration-300"
          >
            <LzIcon size="lg" path="icons/arrow-up" />
          </div>
          <span id="back-to-top-label" class="text-sm transition-all duration-300">{{ $t('show.back_to_top') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject, toRef, onMounted, watch, watchEffect, provide } from 'vue'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import { LzIcon } from 'vue_features/shared/components/ui'
import LessonThumbs from 'vue_features/lesson_plans/show/components/LessonThumbs'
import LessonContainer from 'vue_features/lesson_plans/show/components/LessonContainer'
import LessonToolbar from 'vue_features/lesson_plans/show/components/LessonToolbar'
import StudentToolsPanel from 'vue_features/lesson_plans/show/components/StudentToolsPanel'
import { END_CARD_ID, useAssignmentCardsStore } from '../../composables/use_assignment_cards_store'
import {
  useLessonPlayer,
  useLessonPlayerViewManager,
  useLessonPlayerScrollManager,
  useLessonPlayerUrlSupport,
  useLessonPlayerIterator,
} from 'vue_features/lesson_plans/show/composables/use_lesson_player'

export default {
  name: 'AssignmentCardsPrimaryDeck',
  components: {
    LessonThumbs,
    LessonContainer,
    LessonToolbar,
    StudentToolsPanel,
    LzIcon,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    showBackToTop: {
      type: Boolean,
      default: true,
    },
    allowSidebar: {
      type: Boolean,
      default: false,
    },
    allowNotes: {
      type: Boolean,
      default: false,
    },
    allowFullscreen: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()

    const { isFullscreen } = useFullscreenStore()
    const { studentTools, lessonPlanTextStyle } = useLessonPlanStore()
    const { isContentEmbedded } = useGlobalStore()
    const { viewedAllCards } = useAssignmentCardsStore()

    const lessonPlayerElement = ref(null)
    const scrollManagerRoot = ref(null)
    const cards = toRef(props, 'cards')

    provide('fontTextStyle', lessonPlanTextStyle)

    const checkForViewAll = (idx) => {
      const selectedCard = cards.value[idx]
      if (selectedCard && selectedCard.id === END_CARD_ID) {
        viewedAllCards()
      }
    }

    const {
      selectedCardIndex,
      updateSelectedCardIndex,
      showSidebar,
      showToolbar,
      toggleToolbar,
      trackEvent,
      toggleSidebar,
    } = useLessonPlayer()
    const { hasScrolledPastThreshold, scrollToTop, scrollToSelectedCard, currentCardElement, preserveCard } =
      useLessonPlayerScrollManager({
        selectedCardIndexRef: selectedCardIndex,
        lessonPlayerElementRef: lessonPlayerElement,
        scrollManagerRootRef: scrollManagerRoot,
        updateSelectedCard: updateSelectedCardIndex,
        trackEvent: trackEvent,
      })
    const { handleToggleFullscreen } = useLessonPlayerViewManager({
      selectedCardIndexRef: selectedCardIndex,
      cardSelectedRef: updateSelectedCardIndex,
      trackEvent: trackEvent,
      preserveCard,
    })
    useLessonPlayerUrlSupport({
      selectedCardIndexRef: selectedCardIndex,
      cardsRef: cards,
      onInitialCardIndex: preserveCard,
      root,
    })
    const { goPrevious, goNext, activateKeyboardNavigation } = useLessonPlayerIterator({
      cardsRef: cards,
      rootElRef: scrollManagerRoot,
      currentCardElementRef: currentCardElement,
      showToolbarRef: showToolbar,
      selectedCardIndexRef: selectedCardIndex,
      cardSelected: scrollToSelectedCard,
    })

    const hasStudentTools = computed(() => studentTools.value.length > 0)

    const combine = (...funcs) => {
      funcs.forEach((f) => f())
    }

    const fullscreenHandler = () => {
      preserveCard()
      handleToggleFullscreen()
    }

    watch(selectedCardIndex, () => {
      checkForViewAll(selectedCardIndex.value)
    })

    watchEffect(() => {
      emit('show-sidebar', showSidebar.value)
    })

    onMounted(() => {
      activateKeyboardNavigation()
      lessonPlayerElement.value.addEventListener('click', () => {
        activateKeyboardNavigation()
      })
    })

    return {
      fullscreenHandler,
      isFullscreen,
      activateKeyboardNavigation,
      isContentEmbedded,
      hasStudentTools,
      combine,
      checkForViewAll,
      hasScrolledPastThreshold,
      handleToggleFullscreen,
      scrollToTop,
      scrollToSelectedCard,
      currentCardElement,
      preserveCard,
      selectedCardIndex,
      showToolbar,
      showSidebar,
      toggleToolbar,
      trackEvent,
      lessonPlayerElement,
      scrollManagerRoot,
      goPrevious,
      goNext,
      toggleSidebar,
    }
  },
}
</script>
