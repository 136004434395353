<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <label class="h5 mb-0 mt-6">{{ $t('show.logo.header') }}</label>
    <p class="small">{{ $t('show.logo.info') }}</p>
    <div v-if="isEditing" class="file-upload space-y-2">
      <FilestackUpload :file="logoFile" :accept="$options.accept" :max-size="$options.maxSize" @upload-done="setLogo">
        <template #default="{ launch, filename, fileUrl, fileExists }">
          <div v-if="fileExists" class="flex space-x-2">
            <LzButton class="file-upload__button" @click="setLogo({})">
              {{ $t('components.cover_image_upload.remove') }}
            </LzButton>
            <LzButton v-bypass-unload-confirmation tag="a" :href="fileUrl" class="truncate">{{
              filename || $t('components.cover_image_upload.download')
            }}</LzButton>
          </div>
          <div class="file-upload__buttons">
            <LzButton class="file-upload__button" @click="launch">
              {{
                fileExists
                  ? $t('activerecord_attributes.resource.replace_file')
                  : $t('activerecord_attributes.resource.select_file')
              }}
            </LzButton>
          </div>
        </template>
      </FilestackUpload>
      <p class="help-block">{{ $t('show.logo.help') }}</p>
    </div>
    <LogoPreview :name="name" :logo="logoFile" />
  </div>
</template>

<script>
import { bypassUnloadConfirmation } from 'vue_features/shared/directives'
import LogoPreview from './LogoPreview'
import { LzButton } from 'vue_features/shared/components/ui'
import FilestackUpload from 'vue_features/shared/components/filestack_upload/FilestackUpload'
import { config } from 'utils'
const { accept, maxSize } = config.image

export default {
  name: 'LogoUploader',
  accept,
  maxSize,
  components: { FilestackUpload, LogoPreview, LzButton },
  directives: { bypassUnloadConfirmation },
  props: {
    value: { type: Object, required: true },
    isEditing: { type: Boolean, required: true },
    name: { type: String, required: true },
  },
  computed: {
    logoFile() {
      return this.value
    },
  },
  methods: {
    setLogo(logo = {}) {
      this.$emit('input', logo)
    },
  },
}
</script>
