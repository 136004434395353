<script setup>
import { inject, ref } from 'vue'
import { LzModal, LzButton, Alert } from 'vue_features/shared/components/ui'
import Routes from 'routes'
import { HTTP, snakeKeys, getFormDataObject } from 'vue_features/shared/helpers/http_helper'
import ProgressBar from 'vue_features/shared/components/ui/loaders/ProgressBar'
import useSidekiqPoller from 'vue_features/shared/composables/use_sidekiq_poller'

const props = defineProps({
  objectId: { type: [Number, String], required: true },
  publishId: { type: [Number, String], required: true },
  objectType: { type: String, required: true },
  objectName: { type: String, required: true },
  action: { type: String, default: 'show' },
})

const isPublishing = ref(false)
const updateUrl = (bid) =>
  Routes.revision_publish_job_url(props.publishId, snakeKeys({ objectType: props.objectType, bid }))

const isResource = props.objectType === 'Resource'
const mustSubmit = isResource && props.action === 'edit'
const form = mustSubmit ? inject('resourceForm') : null
const errors = ref([])
const startMethod = () => {
  const url = Routes.revision_publish_jobs_path(snakeKeys({ objectId: props.publishId, objectType: props.objectType }))
  return HTTP.create(url)
}
const { startSidekiqBatch, pollerActive, percent, updatePercent, isComplete } = useSidekiqPoller(startMethod, updateUrl)
const finishUrl = isResource ? Routes.resource_path(props.publishId) : Routes.lesson_plan_path(props.publishId)
const publish = async () => {
  isPublishing.value = true

  if (mustSubmit) {
    const formData = getFormDataObject(form.value.$el)
    try {
      await HTTP.put(Routes.resource_path(props.objectId), formData)
      percent.value = 20
    } catch (e) {
      if (e.responseJSON) {
        errors.value = e.responseJSON
      } else {
        errors.value = ['Something went wrong']
      }
      isPublishing.value = false
      return
    }
  }

  await startSidekiqBatch()
}
</script>

<script>
import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, revisions } from 'vue_features/shared/i18n'

export default {
  i18n: {
    messages: merge(
      namespaceLocaleObj(revisions, 'PublishRevisionModal', {
        only: ['publish_reminder', 'publish_explanation', 'go_to', 'encountered_error'],
      }),
      namespaceLocaleObj(common, 'PublishRevisionModal', { only: ['publish', 'revision', 'cancel'] }),
    ),
  },
}
</script>

<template>
  <LzModal v-slot="{ close }" :can-cancel="false" modal-id="PublishRevisionModal" class="lzui-modal--confirmation">
    <div class="p-8">
      <ProgressBar
        v-if="isPublishing"
        :active="pollerActive"
        :job="$t('PublishRevisionModal.publish')"
        :percent="percent"
        @tick="updatePercent"
        @complete="isComplete = true"
      />
      <template v-else>
        <Alert
          v-if="errors.length"
          :description="errors"
          :title="$t('PublishRevisionModal.encountered_error')"
          type="error"
          dismissible
          class="text-left"
        />
        <h2 class="my-2">{{ $t('PublishRevisionModal.publish_reminder') }}</h2>
        <p class="max-w-prose">{{ $t('PublishRevisionModal.publish_explanation') }}</p>
      </template>
      <footer>
        <template v-if="isPublishing">
          <a v-if="isComplete" :href="finishUrl" class="btn btn--green">{{
            $t('PublishRevisionModal.go_to', { object_name: objectName })
          }}</a>
        </template>
        <template v-else>
          <LzButton @click="close">{{ $t('PublishRevisionModal.cancel') }}</LzButton>
          <LzButton data-test="publish-confirm-btn" color="green" @click="publish">
            {{ $t('PublishRevisionModal.publish') }}
          </LzButton>
        </template>
      </footer>
    </div>
  </LzModal>
</template>
