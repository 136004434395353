import { isEmpty, isInteger } from 'lodash'
import { validationHelpers } from 'utils'

export default class Validator {
  constructor({ rules }) {
    this.rules = rules || {}
    this.validators = {
      presence: (value) => {
        if (value && value.constructor === Array) {
          value = value.filter(function (item) {
            return item !== (undefined || null || '')
          })
        }
        return !isEmpty(value) || isInteger(value)
      },
      maxlength: (value, maxlength) => !value || value.length <= maxlength,
      minlength: (value, minlength) => !value || value.length >= minlength,
      emailAddress: (value) => !value || value.match(validationHelpers.emailAddressRegExp),
    }
  }

  validate(field, value, callback) {
    const invalid = {}
    for (const [type, criteria] of Object.entries(this.rules[field])) {
      if (!this.validators[type](value, criteria)) invalid[type] = true
    }
    if (!isEmpty(invalid)) callback(invalid)
  }
}
