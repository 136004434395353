<template>
  <LzModal
    class="lzui-modal--large text-left"
    :modal-id="modalId"
    :modal-title="$t('action_menu.merge_lesson_plans_modal_title')"
    :autoclose="false"
    @close-modal="cancel"
    @bg-click="cancel"
  >
    <section>
      <template v-if="settingUpMerge">
        <div class="form-group">
          <label for="lesson_plan_title">
            {{ $t('action_menu.title_of_merged') }}
          </label>
          <input id="lesson_plan_title" v-model="copyTitle" class="form-control h1 mb-6" type="text" />
          <h5 class="pb-1 text-sm font-semibold">
            {{ $t('action_menu.merge_list_label') }}
          </h5>
          <ul id="merged-lesson-plans" class="no-bullet">
            <li class="lzui-tile mb-2 border-pink-700 p-2">
              <h4 class="m-0 inline">{{ original.title }}</h4>
              <span class="small ml-2">({{ original.cardCount }} cards)</span>
            </li>
          </ul>
          <div class="text-center">
            <LzIcon type="div" path="icons/add" size="lg" icon-color-class="text-pink-700" />
          </div>
          <MergedPlansAccordion
            :merged-lesson-plans="mergedLessonPlans"
            :total-cards="totalCards"
            @remove="removePlan"
          />
          <LessonPlanSearch
            class="merged-search"
            :merged-lesson-plan-ids="mergedLessonPlanIds"
            :can-copy-assessments="canCopyAssessments"
            @toggle="toggleSelected"
          />
        </div>
        <div
          v-if="authorizedAsAuthor"
          id="linked-copy-container"
          class="lzui-tile mx-auto my-6 flex max-w-7xl p-2"
          style="align-items: center"
        >
          <input id="is-linked-copy" v-model="isLinkedCopy" type="checkbox" class="mt-0" />
          <LzIcon path="icons/linked-copy" size="lg" class="fill-primary-100 stroke-primary-600 px-2" />
          <label for="is-linked-copy" class="m-0 flex-grow">{{ $t('action_menu.want_linked') }}</label>
          <LzTooltip :title="$t('action_menu.linked_description')">
            <LzIcon path="icons/help" icon-color-class="text-neutral-700" />
          </LzTooltip>
        </div>
      </template>
      <ProgressBar
        v-else
        :active="pollerActive"
        :percent="percent"
        :job="$t('common.copy')"
        @complete="isComplete = true"
        @tick="updatePercent"
      />
    </section>
    <div v-if="hasTooManyCards || error" class="center my-4">
      <strong v-if="hasTooManyCards" class="red">
        {{ $t('action_menu.over_limit_message') }}
      </strong>
      <strong v-if="error" class="red">
        {{ error }}
      </strong>
    </div>
    <footer>
      <button class="btn" @click.stop="cancel">
        {{ $t('common.cancel') }}
      </button>
      <a v-if="isComplete" :href="copyUrl" class="btn btn--primary">
        {{ $t('action_menu.go_to_copy') }}
      </a>
      <button v-if="settingUpMerge" class="btn btn--primary" :disabled="hasTooManyCards" @click="copy">
        {{ $t('common.merge') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { ref, watch, inject } from 'vue'
import useCopyLessonPlan from '../composables/use_copy_lesson_plan'
import { LzModal, LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import ProgressBar from 'vue_features/shared/components/ui/loaders/ProgressBar'
import LessonPlanSearch from './LessonPlanSearch'
import MergedPlansAccordion from './MergedPlansAccordion'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'MergeLessonPlansModal',
  components: {
    LzTooltip,
    LzIcon,
    LzModal,
    ProgressBar,
    LessonPlanSearch,
    MergedPlansAccordion,
  },
  props: {
    modalId: {
      type: String,
      default: 'MergeLessonPlansModal',
    },
    original: {
      type: Object,
      required: true,
    },
    canCopyAssessments: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { authorizedAsAuthor } = useCurrentUserStore()
    const copyTitle = ref(root.$t('common.copy_of', { name: props.original.title }))
    const { copyType, copyId, bid, percent, isComplete, startCopy, pollerActive, updatePercent, copyUrl, cancel } =
      useCopyLessonPlan(props.original, props.modalId, authorizedAsAuthor)

    const isLinkedCopy = ref(authorizedAsAuthor.value)
    watch(isLinkedCopy, (newValue) => {
      copyType.value = newValue ? 'linked' : 'personal'
    })

    return {
      authorizedAsAuthor,
      copyTitle,
      copyType,
      copyId,
      bid,
      percent,
      isComplete,
      startCopy,
      pollerActive,
      updatePercent,
      copyUrl,
      cancel,
      isLinkedCopy,
    }
  },
  data() {
    return {
      error: '',
      settingUpMerge: true,
      mergedLessonPlans: [],
    }
  },
  computed: {
    mergedLessonPlanIds() {
      return this.mergedLessonPlans.map((lp) => lp.id)
    },
    totalCards() {
      return (
        this.original.cardCount +
        this.mergedLessonPlans.reduce((previousValue, currentValue) => previousValue + currentValue.cardCount, 0)
      )
    },
    hasTooManyCards() {
      return this.totalCards > 80
    },
  },
  methods: {
    addPlan(plan) {
      if (!this.isPlanSelected(plan)) {
        this.mergedLessonPlans.push(plan)
      }
    },
    toggleSelected(plan) {
      this.isPlanSelected(plan) ? this.removePlan(plan) : this.addPlan(plan)
    },
    isPlanSelected(plan) {
      return this.mergedLessonPlanIds.includes(plan.id)
    },
    removePlan(plan) {
      const index = this.mergedLessonPlans.findIndex((lp) => lp.id === plan.id)
      if (index >= 0) {
        this.mergedLessonPlans.splice(index, 1)
      }
    },
    copy() {
      this.settingUpMerge = false
      this.error = ''
      this.startCopy({
        copyType: this.copyType,
        title: this.copyTitle,
        mergedLessonPlanIds: this.mergedLessonPlanIds,
      }).catch((err) => {
        this.settingUpMerge = true
        this.error = err.status === 401 ? this.$t(`action_menu.assessment_copy`) : err.responseJSON.error
      })
    },
  },
}
</script>
