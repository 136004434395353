const RESOURCE_DEFAULTS = [
  'state',
  'adaptation',
  'coverImage',
  'description',
  'name',
  'premium',
  'teacherOnly',
  'textStyle',
  'sourceFileUrl',
  'ownerId',
]

const TYPE_SPECIFIC = {
  Video: ['videoCaptionParams'],
  Slide: ['contents', 'ordered'],
  LearnosityItem: ['content', 'learnosityItemUpdatedAt', 'publicItem', 'scoringRubricId', 'learnosityItemStandards'],
  GeogebraItem: ['materialId', 'showToolbar', 'autoscale'],
  Wiki: ['blocks', 'deletedBlocks'],
  Hyperlink: ['content', 'iframeable'],
  Html: ['content'],
}

export function toParams(resource) {
  return RESOURCE_DEFAULTS.concat(TYPE_SPECIFIC[resource.type] || []).reduce((params, key) => {
    if (key === 'sourceFileUrl') {
      const file = resource.file || resource.resourceUrl || {}
      params[key] = file.baseUrl || resource.sourceFileUrl
    } else if (key === 'coverImage') {
      const file = resource.coverImage || resource.coverImageUrl || {}
      params[key] = file.baseUrl || resource.coverImageUrl
    } else if (key === 'learnosityItemStandards') {
      params[key] = resource.standards.map((standard) => ({ standard_id: standard.id }))
    } else {
      params[key] = resource[key]
    }
    return params
  }, {})
}
