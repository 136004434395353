import { merge } from 'lodash'
import { elevateLocaleNamespace, namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { errors, common, shared, users } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(errors, 'errors'),
  namespaceLocaleObj(common, 'common'),
  elevateLocaleNamespace(shared, 'edit_student_modal'),
  users,
)
