<template>
  <table class="border-collapse">
    <tr v-for="row in rows" :key="`row-${row}`">
      <td v-for="column in columns" :key="`row-${row}-col-${column}`" class="p-0">
        <div class="relative h-12 overflow-hidden">
          <div
            class="animate-sliding-gradient w-2x-full absolute inset-0 bg-gradient-to-r from-white via-gray-200 to-white"
          />
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'LoadingTable',
  props: {
    rows: {
      type: Number,
      default: 3,
    },
    columns: {
      type: Number,
      default: 3,
    },
  },
}
</script>
