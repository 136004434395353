<template>
  <component :is="type" v-if="type" />
</template>

<script>
import StudentInstructions from './StudentInstructions'
import TeacherInstructions from './TeacherInstructions'
import StudentArchivalInstructions from './StudentArchivalInstructions'
import TeacherArchivalInstructions from './TeacherArchivalInstructions'

export default {
  name: 'ImportInstructions',
  components: { StudentInstructions, TeacherInstructions, StudentArchivalInstructions, TeacherArchivalInstructions },
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    type() {
      switch (this.source) {
        case 'student_csv':
          return 'StudentInstructions'
        case 'teacher_csv':
          return 'TeacherInstructions'
        case 'student_deletion_csv':
          return 'StudentArchivalInstructions'
        case 'teacher_deletion_csv':
          return 'TeacherArchivalInstructions'
        default:
          return false
      }
    },
  },
}
</script>
