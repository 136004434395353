<template>
  <LzModal id="user-import-modal" :modal-title="title" :modal-id="$options.name" class="lzui-modal--large" autoclose>
    <ImportUpload
      v-if="showingUpload"
      v-bind="{ studentSource, teacherSource }"
      @close="close"
      @upload="processUpload"
    />
    <ImportPreview
      v-if="showingPreview"
      v-bind="{ file, users, source, warnings }"
      :requires-confirmation="isForDeletion"
      @success="showSuccess"
      @close="close"
    />
    <template v-if="showingSuccess">
      <p>{{ $t('import_started') }}</p>
      <p>{{ $t('navigating_away') }}</p>
      <LoadingSpinner />
    </template>
  </LzModal>
</template>

<script>
import { LoadingSpinner, LzModal } from 'vue_features/shared/components/ui'
import ImportPreview from './ImportPreview'
import ImportUpload from './ImportUpload'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'CsvUserImportModal',
  components: {
    ImportUpload,
    ImportPreview,
    LoadingSpinner,
    LzModal,
  },
  props: {
    studentSource: {
      type: String,
      required: true,
    },
    teacherSource: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { closeModal }
  },
  data() {
    return {
      currentAction: 'upload',
      warnings: [],
      file: null,
      users: [],
      source: 'select',
    }
  },
  computed: {
    isForDeletion() {
      return this.teacherSource.includes('deletion')
    },
    showingPreview() {
      return this.currentAction === 'preview'
    },
    showingUpload() {
      return this.currentAction === 'upload'
    },
    showingSuccess() {
      return this.currentAction === 'success'
    },
    title() {
      return this.isForDeletion ? this.$t('bulk_archive_title') : this.$t('add_multiple_users_header')
    },
  },
  methods: {
    close() {
      this.closeModal(this.$options.name)
    },
    processUpload({ users, warnings, file, source }) {
      this.users = users
      this.warnings = warnings || []
      this.file = file
      this.source = source
      this.currentAction = 'preview'
    },
    showSuccess() {
      this.currentAction = 'success'
    },
  },
}
</script>
