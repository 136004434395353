import AssignmentPromptService from '../api'
import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

export const useAssignmentPromptStore = defineStore('assignmentPrompt', () => {
  const state = reactive({
    hasKlasses: false,
    assignStep: 1,
    autoOpen: false,
    materialsToAssign: [],
    klassesToAssignToGoogle: [],
    klasses: [],
    pagination: { totalCount: 0 },
  })

  const initializeKlasses = () => {
    return AssignmentPromptService.getKlasses({ page: 1, assignableIds: state.materialsToAssign }).then((klassData) => {
      if (klassData) {
        state.klasses.splice(0, state.klasses.length, ...klassData.klasses)
        state.pagination = klassData.meta.pagination
      }
    })
  }
  const loadMoreKlasses = () => {
    const page = state.pagination.nextPage
    return AssignmentPromptService.getKlasses({ page, assignableIds: state.materialsToAssign }).then((klassData) => {
      state.klasses.push(...klassData.klasses)
      state.pagination = klassData.meta.pagination
    })
  }
  const loadMoreStudents = (klass) => {
    const page = klass.studentsData.meta ? klass.studentsData.meta.pagination.nextPage : 1
    const params = { page, klassId: klass.id, assignableIds: state.materialsToAssign }
    return AssignmentPromptService.getStudents(params).then((studentsData) => {
      klass.studentsData = {
        ...klass.studentsData,
        students: [...klass.studentsData.students, ...studentsData.students],
        meta: { ...studentsData.meta },
      }
      if (klass.checked) klass.studentsData.students.forEach((s) => (s.checked = !s.allAssigned))
    })
  }

  const selectMaterial = (material, select = true) => {
    const pos = state.materialsToAssign.indexOf(material)
    const found = pos !== -1
    if (select === found) return // Already (un-)selected, nothing to do
    select ? state.materialsToAssign.push(material) : state.materialsToAssign.splice(pos, 1)
  }
  const _clearGoogleAssign = (klassId) => {
    const pos = state.klassesToAssignToGoogle.indexOf(klassId)
    if (pos >= 0) state.klassesToAssignToGoogle.splice(pos, 1)
  }
  const toggleKlassCheck = (klass) => {
    const checkStudent = (student) => ({ ...student, checked: klass.checked && !student.allAssigned })
    klass.checked = !klass.checked
    klass.studentsData.students = klass.studentsData.students.map(checkStudent)
    if (!klass.checked) _clearGoogleAssign(klass.id)
  }
  const setSelectedKlass = (selectedKlass) => {
    // This variant is for choosing one klass by radio button for Live Learn
    state.klasses = state.klasses.map((klass) => ({ ...klass, checked: klass.id === selectedKlass.id }))
  }
  const toggleStudentCheck = ({ klass, student }) => {
    student.checked = !student.checked
    if (!student.checked) {
      klass.checked = false
      _clearGoogleAssign(klass.id)
    } else if (klass.studentsData.students.every((s) => s.checked || s.allAssigned)) klass.checked = true
  }
  const selectedKlasses = computed(() => state.klasses.filter((klass) => klass.checked))
  const hasSelections = computed(() => {
    if (state.klassesToAssignToGoogle.length || state.klasses.find((klass) => klass.checked)) return true
    return !!state.klasses.find((klass) => {
      return !!klass.studentsData?.students?.find((student) => student.checked && !student.allAssigned)
    })
  })

  const assignToGoogleCourse = ({ klassId, assign }) => {
    const pos = state.klassesToAssignToGoogle.indexOf(klassId)
    if (assign && pos < 0) state.klassesToAssignToGoogle.push(klassId)
    else if (!assign && pos >= 0) state.klassesToAssignToGoogle.splice(pos, 1)

    // Always force-check klass when assigning, but also uncheck if fully assigned (and klass check disabled)
    const klass = state.klasses.find((k) => k.id === klassId)
    if (!klass) return
    if ((assign && !klass.checked) || (!assign && klass.fullyAssigned)) toggleKlassCheck(klass)
  }
  const linkGoogleCourseToKlass = ({ klass, courseId }) => {
    return AssignmentPromptService.linkGoogleCourseToKlass({ klass, courseId }).then((klassData) => {
      state.klasses.forEach((klass) => {
        if (klass.id === klassData.id) {
          klass.googleClassroomCourse = klassData.googleClassroomCourse
        }
      })
      return klassData
    })
  }
  const _selectedKlassStudentIds = () => {
    return state.klasses
      .filter((klass) => !klass.checked)
      .reduce((obj, klass) => {
        const studentsToAssign = klass.studentsData.students.filter(
          (student) => student.checked && !student.allAssigned,
        )
        if (studentsToAssign.length > 0) obj[klass.id] = studentsToAssign.map((student) => student.id)
        return obj
      }, {})
  }
  const assignStudents = () => {
    return AssignmentsService.assignStudents({
      lessonPlanIds: state.materialsToAssign,
      klassIds: selectedKlasses.value.map((klass) => klass.id),
      studentsByKlassId: _selectedKlassStudentIds(),
      klassesToAssignToGoogle: state.klassesToAssignToGoogle,
      autoOpen: state.autoOpen,
    })
  }

  return {
    state,
    initializeKlasses,
    loadMoreKlasses,
    loadMoreStudents,
    selectMaterial,
    toggleKlassCheck,
    setSelectedKlass,
    toggleStudentCheck,
    selectedKlasses,
    hasSelections,
    assignToGoogleCourse,
    linkGoogleCourseToKlass,
    assignStudents,
    klassesTotal: computed(() => state.pagination.totalCount),
  }
})
