import { computed } from 'vue'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { intersection } from 'lodash'

const CONTENT_WIDTH = 800
const CONTENT_HEIGHT = 800
const TARGET_PREFERENCES = ['iframe', 'frame', 'window']

export default function useContentItemsJson({ selectedContents, mediaTypes = [], targets = [] }) {
  const acceptableTargets = intersection(TARGET_PREFERENCES, targets)
  const preferredTarget = acceptableTargets.length > 0 ? acceptableTargets[0] : targets[0] || 'iframe'

  const htmlItemJson = (item) => {
    return {
      '@type': 'ContentItem',
      mediaType: 'text/html',
      url: embedUrl(item),
      placementAdvice: {
        displayHeight: CONTENT_HEIGHT,
        displayWidth: CONTENT_WIDTH,
        presentationDocumentTarget: preferredTarget,
      },
    }
  }

  const returnQueryParams = (item) => {
    if (mediaTypes.includes('lti_launch_url')) {
      return {
        return_type: 'lti_launch_url',
        url: launchUrl(item),
        title: item.name,
      }
    } else if (mediaTypes.includes('iframe')) {
      return {
        return_type: 'iframe',
        url: embedUrl(item),
        title: item.name,
        width: CONTENT_WIDTH,
        height: CONTENT_HEIGHT,
      }
    } else if (mediaTypes.includes('url')) {
      return {
        return_type: 'url',
        url: embedUrl(item),
        title: item.name,
      }
    }
  }

  const embedUrl = (item) => Routes.lesson_plan_url({ id: item.id })

  const ltiLinkItemJson = (item) => {
    return {
      '@type': 'LtiLinkItem',
      mediaType: 'application/vnd.ims.lti.v1.ltilink',
      url: launchUrl(item),
      placementAdvice: { presentationDocumentTarget: preferredTarget },
    }
  }

  const launchUrl = (item) => Routes.auth_callback_url('lti', { type: item.resultType }) + `&id=${item.id}`

  // from a product perspective, we want to prefer that the LMS uses an LTI link (so that we get
  // authentication information sent in the request) rather than just our iframe embed.  so this
  // checks the LMS's list of preferences and only chooses text/html if it is first in the list
  // in front of */* and application/vnd.ims.lti.v1.ltilink
  const prefersHtmlMedia = mediaTypes[0] === 'text/html'

  const contentItemJson = (item) => {
    const json = {
      '@id': ':' + item.id + '-' + item.resultType,
      title: item.name,
      text: item.name,
    }

    if (item.thumbnailUrl) {
      json['thumbnail'] = { '@id': item.thumbnailUrl }
    }

    return prefersHtmlMedia ? { ...json, ...htmlItemJson(item) } : { ...json, ...ltiLinkItemJson(item) }
  }

  const contentItemsJson = computed(() => {
    return JSON.stringify({
      '@context': 'http://purl.imsglobal.org/ctx/lti/v1/ContentItem',
      '@graph': selectedContents.value.map((item) => contentItemJson(item)),
    })
  })

  return { contentItemsJson, returnQueryParams }
}
