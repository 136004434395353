<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/form-control-has-label
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div
    :class="{ dragover: dropStyle }"
    class="filedrop"
    @dragenter.stop.prevent="addDropStyle"
    @dragover.stop.prevent="addDropStyle"
    @dragleave.stop.prevent="removeDropStyle"
    @drop.stop.prevent="dropFile"
  >
    <input ref="fileInput" type="file" name="file" style="display: none" @change="fileChanged" />
    <div class="upload-btn btn btn--green mr-4" @click.stop.prevent="chooseFile">
      {{ $t('select_document') }}
    </div>
    <em>
      {{ $t('common.or') }}
    </em>
    {{ $t('drag_target') }}
    <div v-if="file" class="my-4">{{ file.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'CsvUploader',
  data() {
    return { dropStyle: '', file: null }
  },
  methods: {
    addDropStyle() {
      this.dropStyle = true
    },
    removeDropStyle() {
      this.dropStyle = false
    },
    dropFile(e) {
      this.removeDropStyle()
      this.setFile(e.dataTransfer.files[0])
    },
    chooseFile() {
      this.$refs.fileInput.click()
    },
    fileChanged(e) {
      const file = e.target.files[0]
      if (file) {
        this.setFile(file)
      }
    },
    setFile(file) {
      this.file = file
      this.$emit('uploaded', file)
    },
  },
}
</script>
