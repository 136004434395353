import { omit } from 'lodash'
import { computed } from 'vue'

export default function useMetadata(metadata) {
  const itemMetadataExcludingFromKey = computed(() => {
    const excludingFrom = omit(metadata, 'from')
    Object.keys(excludingFrom).forEach((key) => {
      if (!excludingFrom[key]) {
        delete excludingFrom[key]
      }
    })
    return excludingFrom
  })

  const fromMetadata = computed(() => {
    return metadata ? metadata.from : null
  })

  return { fromMetadata, itemMetadataExcludingFromKey }
}
