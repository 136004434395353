import { TRANSLATIONS } from './i18n_strings.js'

export const {
  annotation,
  common,
  errors,
  components,
  customers,
  customerSelects,
  htmls,
  resources,
  lzCodes,
  klasses,
  studentKlasses,
  shared,
  pages,
  siteAdmin,
  bookmarks,
  users,
  studentDataReports,
  lessonPlans,
  activerecordAttributes,
  activerecordErrors,
  slides,
  standards,
  studentTools,
  simpleForm,
  hyperlinks,
  helpers,
  geogebraItems,
  wikis,
  cardEdits,
  standardsCoverageTables,
  videos,
  decorators,
  featureGate,
  dataDashboards,
  documents,
  images,
  audios,
  contentTypeMetadata,
  search,
  lti,
  ltiV13,
  grading,
  itemAnalysis,
  revisions,
} = TRANSLATIONS
