<!--
  eslint-disable vuejs-accessibility/mouse-events-have-key-events
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="dialogue-toolbar__direction-selector">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="120.3 0.3 1271.1 800">
      <g
        v-for="direction in directions"
        :key="direction"
        :class="[
          'dialogue-toolbar__direction',
          `dialogue-toolbar__direction--${direction}`,
          { 'dialogue-toolbar__direction--selected': selectedDirection === direction },
        ]"
        @click="select(direction)"
        @mouseover="preview(direction)"
        @mouseout="reset"
      >
        <title>{{ labelFor(direction) }}</title>
        <a>
          <slot :name="direction" />
        </a>
      </g>

      <slot name="middle" />
    </svg>

    <p class="dialogue-toolbar__center">
      {{ labelFor(selectedDirection) }}
    </p>
  </div>
</template>

<script>
import { without } from 'lodash'

export default {
  props: {
    initialDirection: { type: String, required: true },
  },
  data() {
    return {
      selectedDirection: null,
    }
  },
  computed: {
    directions() {
      return without(Object.keys(this.$slots), 'middle')
    },
  },
  watch: {
    initialDirection: {
      immediate: true,
      handler(direction) {
        this.selectedDirection = direction
      },
    },
  },
  methods: {
    labelFor(direction) {
      return this.$t(`slides.form_template.dialogue.directions.${direction}`)
    },
    preview(direction) {
      this.selectedDirection = direction
    },
    reset() {
      this.selectedDirection = this.initialDirection
    },
    select(direction) {
      this.$emit('selected', direction)
    },
  },
}
</script>
