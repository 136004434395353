<template>
  <AsyncContentLoader :loader="loadRelatedItems">
    <ul class="space-y-2">
      <RelatedContentItem
        v-for="relatedItem in relatedItems"
        :key="relatedItem.id"
        :item="relatedItem"
        :current-item-id="itemId"
      />
      <li v-if="hasMaxItems" class="detail-item center">
        <a :href="collection.url">
          {{ $t('view_all') }}
        </a>
      </li>
    </ul>
  </AsyncContentLoader>
</template>

<script setup>
import { ref, computed } from 'vue'
import { AsyncContentLoader } from 'vue_features/shared/components/ui'
import { orderedEmbeds } from 'vue_features/resources/wikis/block_helpers'
import RelatedContentItem from './RelatedContentItem'
import ResourcesService from 'vue_features/resources/api'

const MAX_LENGTH = 25

const props = defineProps({
  collection: { type: Object, required: true },
  itemId: { type: Number, required: true },
})

const relatedItems = ref([])

const hasMaxItems = computed(() => relatedItems.value.length >= MAX_LENGTH)

const loadRelatedItems = () =>
  ResourcesService.get(props.collection.id).then((itemsData) => {
    relatedItems.value = orderedEmbeds(itemsData)
  })
</script>
