import { getAllPages } from 'clients/pdf'
import { isOverflowing, renderParentChain } from './page'
import { breakPage } from './pager'
import debug from 'debug'

const log = debug('app:initializers/print')

export function isPdf(element) {
  return element.classList.contains('pdf-document')
}

export async function renderPdf(document, parentChain, pages) {
  try {
    await addPdf(document, parentChain, pages)
  } catch (e) {
    log('Could not retrieve pdf', document.getAttribute('data-src'), e)
  }
}

async function addPdf(parent, parentChain, pages) {
  const src = parent.getAttribute('data-src')
  const pdfPages = await getAllPages(src)

  const scale = 1
  const outputScale = window.devicePixelRatio || 1

  for (const pdfPage of pdfPages) {
    const viewport = pdfPage.getViewport({ scale: scale })

    const canvas = document.createElement('canvas')

    const page = pages[pages.length - 1]
    const renderedParent = renderParentChain(parentChain, page)
    const newParent = renderedParent.appendChild(parent.cloneNode(false))
    newParent.appendChild(canvas)

    const context = canvas.getContext('2d')

    setPdfDimensions(canvas, viewport, outputScale)

    const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null

    const renderContext = {
      canvasContext: context,
      transform: transform,
      viewport: viewport,
    }

    await pdfPage.render(renderContext).promise
    if (isOverflowing(page)) {
      const { newPage } = breakPage(canvas, parentChain, page)
      pages.push(newPage)
    }
  }
}

function setPdfDimensions(element, viewport, outputScale) {
  element.width = Math.floor(viewport.width * outputScale)
  element.height = Math.floor(viewport.height * outputScale)
  element.style.maxWidth = '7.5in'
  element.style.maxHeight = '10in'
}
