<template>
  <div
    class="shadow-left absolute right-0 top-0 h-full flex-none bg-white transition duration-300 lg:relative"
    :class="classes"
  >
    <div class="absolute right-full top-4">
      <button
        class="shadow-left inline-flex items-center whitespace-nowrap bg-white px-1 py-2 text-gray-500 transition"
        :title="open ? $t('collapse_sidebar') : $t('open_sidebar')"
        style="letter-spacing: -0.2em; border-top-left-radius: 4px; border-bottom-left-radius: 4px"
        @click="$emit('toggle')"
      >
        <LzIcon
          path="icons/chevron-double-right"
          size="sm"
          :icon-color-class="`transform ${open ? 'rotate-0' : 'rotate-180'}`"
        />
      </button>
    </div>

    <div class="h-full overflow-hidden" :class="widthClass">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'Sidebar',
  components: { LzIcon },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const widthClass = 'w-96'

    const classes = computed(() => {
      return props.open ? [widthClass] : ['w-1']
    })

    return {
      classes,
      widthClass,
    }
  },
}
</script>

<style scoped>
.shadow-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1);
}
</style>
