import _ from 'lodash'

import classify from './classify'
import renameKeys from './rename_keys'

// FIXME drop use of _.mixin with our lodash extensions: Once app code dependent
// on our lodash extensions is fully migrated to ES6, the value of mixing in
// behavior with lodash is nominal as we can now import these functions directly
// like another utility, i.e.:
//
// import 'classify' from 'lodash_extensions/classify';
//
_.mixin({ classify, renameKeys })
