import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { pick } from 'lodash'

export function initAssignmentStore(initState) {
  useAssignmentStore().$set(pick(initState, ['assignment', 'canManageAssignment']))
  useLessonPlanStore().$set({
    lessonPlan: initState.lessonPlan,
    analyticsProperties: initState.analyticsProperties,
    hasAssignment: true,
  })
}

export const useAssignmentStore = defineStore('assignment', () => {
  const state = reactive({
    assignment: null,
    canManageAssignment: null,
  })

  const { lessonPlan, lessonPlanTextStyle, analyticsProperties } = useLessonPlanStore()
  const hasAssignment = computed(() => !!state.assignment)

  const isAssessment = computed(() => lessonPlan.value.lessonType === 'assessment')

  const assignedKlasses = computed(() => {
    return state.assignment && state.assignment.klasses
  })

  return {
    state,
    isAssessment,
    hasAssignment,
    lessonPlanTextStyle,
    assignmentId: computed(() => state.assignment.id),
    assignedKlasses,
    lessonPlan,
    analyticsProperties,
  }
})
