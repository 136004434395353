<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/form-control-has-label
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="content-menu" ref="contentMenuEl" :class="toolbarClasses">
    <div v-show="showingTextOptions" class="block-toolbar">
      <div id="text-editor-toolbar" />
      <a class="block-toolbar__button" @click="$emit('deselect')">
        <LzIcon path="icons/close" />
      </a>
    </div>
    <div v-if="!showingTextOptions" class="block-toolbar">
      <a class="block-toolbar__button" data-test="edit-btn" @click="$emit('edit', currentSelection)">
        <LzIcon path="icons/edit" />
        <span>{{ $t('common.edit') }}</span>
      </a>
      <template v-if="showingDialogueOptions">
        <ListDropdown
          id="shape-dropdown"
          :button-classes="['block-toolbar__button']"
          :dropdown-classes="['block-toolbar__options']"
        >
          <template #button>
            <LzIcon :path="shapeIconPath" />
            <span>
              {{ $t('slides.form_template.dialogue.shape') }}
              <LzIcon path="icons/caret" size="sm" />
            </span>
          </template>
          <template #listItems>
            <a class="block-toolbar__button" @click="$emit('update-selected', { shape: 'speech' })">
              <LzIcon path="icons/comment" />
              <span>{{ $t('slides.form_template.dialogue.shapes.speech') }}</span>
            </a>
            <a class="block-toolbar__button" @click="$emit('update-selected', { shape: 'thought' })">
              <LzIcon path="icons/thought" />
              <span>{{ $t('slides.form_template.dialogue.shapes.thought') }}</span>
            </a>
          </template>
        </ListDropdown>
        <DivDropdown
          id="direction-dropdown"
          :button-classes="['block-toolbar__button']"
          :dropdown-classes="['dialogue-toolbar']"
        >
          <template #button>
            <LzIcon path="icons/bubble-center" />
            <span>
              {{ $t('slides.form_template.dialogue.direction') }}
              <LzIcon size="sm" path="icons/caret" />
            </span>
          </template>
          <template #divContent>
            <DialogueDirectionPicker
              v-if="currentSelection"
              :shape="currentSelection.shape"
              :direction="currentSelection.direction"
              @select="updateDirection"
            />
          </template>
        </DivDropdown>
      </template>

      <ListDropdown
        id="valign-dropdown"
        data-test="valign-dropdown"
        :button-classes="['block-toolbar__button']"
        :dropdown-classes="['block-toolbar__options']"
      >
        <template #button>
          <LzIcon :path="valignIconPath" />
          <span>
            {{ $t('slides.form_template.vertical_alignment') }}
            <LzIcon size="sm" path="icons/caret" />
          </span>
        </template>
        <template #listItems>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': alignedTop }]"
            data-test="valign-top"
            @click="setVerticalJustification('top')"
          >
            <LzIcon path="icons/valign-top" />
            <span>{{ $t('slides.form_template.top') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': alignedCenter }]"
            data-test="valign-center"
            @click="setVerticalJustification('center')"
          >
            <LzIcon path="icons/valign-center" />
            <span>{{ $t('slides.form_template.center') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': alignedBottom }]"
            data-test="valign-bottom"
            @click="setVerticalJustification('bottom')"
          >
            <LzIcon path="icons/valign-bottom" />
            <span>{{ $t('slides.form_template.bottom') }}</span>
          </a>
        </template>
      </ListDropdown>

      <ListDropdown
        v-if="!textIsSelected"
        id="halign-dropdown"
        :button-classes="['block-toolbar__button']"
        :dropdown-classes="['block-toolbar__options']"
      >
        <template #button>
          <LzIcon :path="halignIconPath" />
          <span>
            {{ $t('slides.form_template.horizontal_alignment') }}
            <LzIcon size="sm" path="icons/caret" />
          </span>
        </template>
        <template #listItems>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': alignedLeft }]"
            @click="setHorizontalJustification('left')"
          >
            <LzIcon path="icons/halign-left" />
            <span>{{ $t('slides.form_template.left') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': !alignedLeft && !alignedRight }]"
            @click="setHorizontalJustification('center')"
          >
            <LzIcon path="icons/halign-center" />
            <span>{{ $t('slides.form_template.center') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': alignedRight }]"
            @click="setHorizontalJustification('right')"
          >
            <LzIcon path="icons/halign-right" />
            <span>{{ $t('slides.form_template.right') }}</span>
          </a>
        </template>
      </ListDropdown>

      <ListDropdown
        v-if="textIsSelected && currentSelection && !currentSelection.isCaption"
        id="background-dropdown"
        data-test="background-dropdown"
        :button-classes="['block-toolbar__button']"
        :dropdown-classes="['block-toolbar__options']"
      >
        <template #button>
          <LzIcon path="icons/swatch" />
          <span>
            {{ $t('slides.form_template.background') }}
            <LzIcon size="sm" path="icons/caret" />
          </span>
        </template>
        <template #listItems>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': textBackground === 'none' }]"
            data-test="set-none"
            @click="setTextBackground('none')"
          >
            <LzIcon path="icons/color-none" />
            <span>{{ $t('slides.form_template.none') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': textBackground === 'callout' }]"
            data-test="set-callout"
            @click="setTextBackground('callout')"
          >
            <LzIcon path="icons/type" class="block-toolbar__icon--callout h-10 w-12" />
            <span>{{ $t('slides.form_template.callout') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': textBackground === 'excerpt' }]"
            data-test="set-excerpt"
            @click="setTextBackground('excerpt')"
          >
            <LzIcon path="icons/type" class="block-toolbar__icon--excerpt h-10 w-12" />
            <span>{{ $t('slides.form_template.excerpt') }}</span>
          </a>
          <a
            :class="['block-toolbar__button', { 'slide-content__button--selected': textBackground === 'note' }]"
            data-test="set-note"
            @click="setTextBackground('note')"
          >
            <LzIcon path="icons/type" class="block-toolbar__icon--note h-10 w-12" />
            <span>{{ $t('slides.form_template.note') }}</span>
          </a>
        </template>
      </ListDropdown>

      <ListDropdown
        v-if="showImageOptions"
        id="alt-dropdown"
        :button-classes="['block-toolbar__button']"
        :dropdown-classes="['block-toolbar__options', 'min-w-208']"
        :close-on-click="false"
      >
        <template #button>
          <LzIcon path="icons/text" />
          <span>
            {{ $t('slides.form_template.alt_text') }}
            <LzIcon size="sm" path="icons/caret" />
          </span>
        </template>
        <template #listItems>
          <textarea
            v-model="altText"
            class="small m-4 mr-0 w-full resize-none"
            maxlength="300"
            data-test="alt-text-input"
          />
          <LzTooltip tag="span" class="alt-hover has-tip mx-2 my-6">
            <LzIcon size="sm" path="icons/help" />

            <template #content>
              <div>{{ $t('slides.form_template.alt_text_tooltip') }}</div>
            </template>
          </LzTooltip>
        </template>
      </ListDropdown>

      <a class="block-toolbar__button" data-test="remove-btn" @click="$emit('remove-content')">
        <LzIcon path="icons/delete" />
        <span>{{ $t('common.remove') }}</span>
      </a>

      <a class="block-toolbar__button" @click="$emit('deselect')">
        <LzIcon path="icons/close" />
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from 'vue'
import DivDropdown from 'vue_features/shared/components/ui/dropdowns/DivDropdown'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import { ckeditorDialogsClosed } from 'ckeditor_extensions/custom_ckeditor'
import DialogueDirectionPicker from './DialogueDirectionPicker'
import fixToolbarHiding from 'ckeditor_extensions/fix_toolbar_hiding'

const MENU_TOP_OFFSET = 13

export default {
  name: 'EditorToolbar',
  components: { LzTooltip, ListDropdown, DivDropdown, LzIcon, DialogueDirectionPicker },
  props: {
    sortedContents: { type: Array, required: true },
    isOrderingContent: { type: Boolean, required: true },
    currentSelection: { type: Object, default: null },
    editedSelection: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const contentMenuEl = ref(null)
    const altText = ref('')

    watch(
      () => props.currentSelection,
      () => {
        if (props.currentSelection) {
          altText.value = props.currentSelection.altText
        }
      },
    )

    watch(altText, () => {
      if (altText.value !== props.currentSelection.altText) {
        emit('update-selected', { altText: altText.value })
      }
    })

    const showingTextOptions = computed(() => {
      return (
        props.currentSelection &&
        props.editedSelection &&
        ['dialogue', 'table', 'text'].includes(props.currentSelection.type) &&
        !props.currentSelection.isCreating
      )
    })

    watch(showingTextOptions, moveToolbar)
    const toolbarClasses = ref([])

    const getParentOffset = (el) => {
      const parents = []
      for (let op = el.offsetParent; op; op = op.offsetParent) parents.unshift(op)
      if (parents.length === 0) return [0, 0]

      const isModal = parents[0] !== document.body
      const scrollTop = isModal ? parents[0].scrollTop : window.pageYOffset
      const scrollLeft = isModal ? parents[0].scrollLeft : window.pageXOffset

      const [top, left] = parents.reduce((coords, p) => [coords[0] + p.offsetTop, coords[1] + p.offsetLeft], [0, 0])
      return [top - scrollTop, left - scrollLeft]
    }

    const setOffset = (el, top, left) => {
      const [offsetTop, offsetLeft] = getParentOffset(el)
      el.style.setProperty('top', top - offsetTop + 'px')
      el.style.setProperty('left', left - offsetLeft + 'px')
    }

    function moveToolbar() {
      if (!props.currentSelection || props.isOrderingContent) return hideToolbar()

      const menuWidth = contentMenuEl.value.clientWidth
      setOffset(contentMenuEl.value, 0, 0)
      toolbarClasses.value.splice(0, toolbarClasses.value.length)

      window.requestAnimationFrame(() => {
        const index = props.sortedContents.indexOf(props.currentSelection)
        const slideArea = document.querySelector('.slide-toolbar')
        const slideContent = document.querySelectorAll('.slide-editor .slide__content')[index]
        if (!slideArea || !slideContent) return

        const areaBounds = slideArea.getBoundingClientRect()
        const contentBounds = slideContent.getBoundingClientRect()
        const areaLeft = areaBounds.left
        const contentLeft = contentBounds.left
        const contentWidth = slideContent.clientWidth

        const middlePoint = contentLeft - (menuWidth - contentWidth) / 2
        const leftPoint = contentLeft + contentWidth - menuWidth
        const farRight = areaLeft + slideArea.clientWidth

        const overflowedRight = contentLeft + menuWidth > farRight
        const overflowedLeft = leftPoint < areaLeft
        const overflowedMiddleLeft = areaLeft > middlePoint
        const overflowedMiddleRight = middlePoint + menuWidth > farRight

        let left = contentLeft
        if (!(overflowedMiddleLeft || overflowedMiddleRight) && overflowedLeft && overflowedRight) {
          left = contentLeft - (menuWidth - contentWidth) / 2
          toolbarClasses.value.push('content-menu--center')
        } else if (overflowedRight && (!overflowedLeft || overflowedMiddleRight)) {
          left = leftPoint
          toolbarClasses.value.push('content-menu--left')
        }

        // set left first so the next line can get the height after wrapping
        setOffset(contentMenuEl.value, 0, left)
        const top = contentBounds.top - contentMenuEl.value.clientHeight - MENU_TOP_OFFSET
        setOffset(contentMenuEl.value, top, left)

        // tables don't pull editor focus, so sometimes need the toolbar shown manually
        if (showingTextOptions.value && props.editedSelection.type === 'table')
          fixToolbarHiding({ sharedSpaces: { top: 'text-editor-toolbar' } })
      })
    }

    function hideToolbar(event = {}) {
      if (!event.target || clickedOutsideOf(event)) emit('deselect')
    }

    function clickedOutsideOf(event) {
      if (event.target.clientHeight === 0 && event.target.clientWidth === 0) return false
      if (!ckeditorDialogsClosed()) return
      const contentClass = 'slide__content'
      if (event.target === contentMenuEl.value || event.target.classList.contains(contentClass)) return false
      return !(event.target.closest(`.${contentClass}`) || event.target.closest(`#${contentMenuEl.value.id}`))
    }

    watch(() => props.currentSelection, moveToolbar)
    document.addEventListener('mousedown', hideToolbar)
    window.addEventListener('resize', moveToolbar)

    onUnmounted(() => {
      document.removeEventListener('mousedown', hideToolbar)
      window.removeEventListener('resize', moveToolbar)
    })

    const isHorizontallyAligned = (direction) => props.currentSelection?.horizontalAlignment === direction
    const isVerticallyAligned = (direction) => props.currentSelection?.verticalAlignment === direction

    return {
      contentMenuEl,
      showingTextOptions,
      altText,
      toolbarClasses,
      setVerticalJustification: (just) => emit('update-selected', { verticalAlignment: just }),
      setHorizontalJustification: (just) => emit('update-selected', { horizontalAlignment: just }),
      setTextBackground: (background) => emit('update-selected', { background }),
      updateDirection: (direction) => emit('update-selected', { direction }),
      valignIconPath: computed(() => `icons/valign-${props.currentSelection?.verticalAlignment || 'bottom'}`),
      halignIconPath: computed(() => `icons/halign-${props.currentSelection?.horizontalAlignment || 'center'}`),
      alignedTop: computed(() => isVerticallyAligned('top')),
      alignedBottom: computed(() => isVerticallyAligned('bottom')),
      alignedCenter: computed(() => isVerticallyAligned('center')),
      alignedRight: computed(() => isHorizontallyAligned('right')),
      alignedLeft: computed(() => isHorizontallyAligned('left')),
      textBackground: computed(() => props.currentSelection?.textBackground || ''),
      showImageOptions: computed(() => props.currentSelection?.type === 'image'),
      showingDialogueOptions: computed(() => props.currentSelection?.type === 'dialogue'),
      textIsSelected: computed(() => props.currentSelection?.type === 'text'),
      shapeIconPath: computed(() => `icons/${props.currentSelection?.shape === 'comment' ? 'comment' : 'thought'}`),
    }
  },
}
</script>
