/**
 “String” Method

 _.classify([string=''])

 Converts string to UpperCamelCase.  Named after rails #classify

 Arguments

 [string=''] (string): The string to convert.
 Returns

 (string): Returns the UpperCamelCase string.

 Example

 _.classify('Foo Bar');
 // => 'FooBar'

 _.classify('--foo-bar--');
 // => 'FooBar'

 _.classify('__FOO_BAR__');
 // => 'FooBar'
 */

import { flow, toString, upperFirst, camelCase } from 'lodash'

export default (string) => flow([toString, camelCase, upperFirst])(string)
