import { ref } from 'vue'
import consumer from 'channels/consumer.js'

export const useLzCodeViewChannel = () => {
  const subscription = ref(null)
  const isConnecting = ref(false)

  const noop = () => {}

  const connect = (id, options = {}) => {
    return new Promise((resolve, reject) => {
      const onReceived = options.onReceived || noop

      isConnecting.value = true

      subscription.value = consumer.subscriptions.create(
        {
          channel: 'LzCodeViewChannel',
          id,
        },
        {
          connected() {
            isConnecting.value = false
            resolve()
          },
          disconnected() {
            subscription.value = null
          },
          rejected() {
            isConnecting.value = false
            subscription.value = null
            reject()
          },
          received(message) {
            onReceived(message)
          },
        },
      )
    })
  }

  const disconnect = () => {
    subscription.value.unsubscribe()
  }

  return { subscription, isConnecting, connect, disconnect }
}
