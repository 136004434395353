import Routes from 'routes'
import { location } from 'utils'

export function redirectToLogin(isAuthenticated, source) {
  if (!isAuthenticated) {
    location.assign(Routes.login_path({ source: source }))
  }

  return !isAuthenticated
}
