const FIRST_NAMES = [
  'Adorable',
  'Adventurous',
  'Angelic',
  'Awesome',
  'Beautiful',
  'Blue',
  'Brave',
  'Bright',
  'Calm',
  'Capable',
  'Charming',
  'Cheerful',
  'Chewy',
  'Clever',
  'Colorful',
  'Competitive',
  'Confident',
  'Courageous',
  'Creative',
  'Delightful',
  'Eager',
  'Elegant',
  'Excited',
  'Famous',
  'Fearless',
  'Friendly',
  'Funny',
  'Giant',
  'Good',
  'Graceful',
  'Green',
  'Gumby',
  'Happy',
  'Hardworking',
  'Hilarious',
  'Impressed',
  'Incredible',
  'Interesting',
  'Jolly',
  'Kind',
  'Loyal',
  'Mysterious',
  'Outrageous',
  'Outstanding',
  'Pleasant',
  'Polite',
  'Quick',
  'Red',
  'Shiny',
  'Silly',
  'Sleepy',
  'Sneezy',
  'Splendid',
  'Super',
  'Tall',
  'Thoughtful',
  'Tiny',
  'Yellow',
]

const LAST_NAMES = [
  'Alligator',
  'Ant',
  'Badger',
  'Bear',
  'Bird',
  'Canary',
  'Car',
  'Cat',
  'Chinchilla',
  'Chipmunk',
  'Cow',
  'Dinosaur',
  'Dog',
  'Dolphin',
  'Dragon',
  'Eagle',
  'Elephant',
  'Falcon',
  'Fox',
  'Frog',
  'Gecko',
  'Giraffe',
  'Hamster',
  'Hippo',
  'Ice Cream Truck',
  'Iguana',
  'Impala',
  'Kangaroo',
  'Lion',
  'Lizard',
  'Luchador',
  'Mole',
  'Moose',
  'Ninja',
  'Oak Tree',
  'Ostrich',
  'Panda',
  'Pearl',
  'Penguin',
  'Pig',
  'Pony',
  'Porcupine',
  'Pteradactyl',
  'Rabbit',
  'Rowboat',
  'Salamander',
  'Snake',
  'Squirrel',
  'Tiger',
  'Walrus',
  'Zebra',
]

const generate = () => {
  const firstNameIndex = Math.floor(Math.random() * FIRST_NAMES.length)
  const lastNameIndex = Math.floor(Math.random() * LAST_NAMES.length)

  return {
    firstName: FIRST_NAMES[firstNameIndex],
    lastName: LAST_NAMES[lastNameIndex],
  }
}

export default {
  generate,
}
