<template>
  <div>
    <Modal
      v-if="hasStudentFunctionality && canModalShow($options.name)"
      id="assignment-modal"
      :modal-id="$options.name"
      :modal-title="$t('modal_title', { step: showMaterials ? 1 : 2, total: 2 })"
      :modal-prompt="$t(showMaterials ? 'what' : 'who')"
      :modal-description="showMaterials ? $t('assign_related') : ''"
      :autoclose="false"
      :footer-classes="showMaterials ? '' : 'flex-col gap-y-4 max-w-full'"
    >
      <AssignMaterials v-if="showMaterials" />
      <AssignToStudents v-else />
      <template #footer>
        <template v-if="showMaterials">
          <button type="button" data-test="assign-cancel-button" class="text-important px-4 py-2" @click="clearModal">
            {{ $t('common.cancel') }}
          </button>
          <button
            type="button"
            data-test="assign-materials-button"
            class="btn btn--primary w-24"
            :disabled="!nextEnabled"
            @click="stepTwo"
          >
            {{ $t('common.next') }}
            <LzIcon path="icons/chevron-right" />
          </button>
        </template>
        <template v-else>
          <div class="text-muted flex gap-6 px-4 text-sm">
            <span class="whitespace-normal">{{ `${$t('students_see')} ${thisLabel} ${$t('once_open')}` }}</span>
            <SwitchToggle
              v-model="autoOpen"
              :disabled="!hasSelections || isLoading"
              :label-on="$t('open_now')"
              label-off=""
              class="min-w-16 sm:min-w-32 -mb-1"
            />
          </div>
          <div class="border-base flex flex-wrap content-center justify-center gap-x-2.5 gap-y-1 border-t pt-4">
            <button
              type="button"
              data-test="assign-cancel-button"
              class="text-important mb-1 flex-none px-4 py-2"
              :disabled="isLoading"
              @click="clearModal"
            >
              {{ $t('common.cancel') }}
            </button>
            <button
              type="button"
              data-test="assign-back-button"
              class="btn mb-1 w-24 flex-none"
              :disabled="!nextEnabled || isLoading"
              @click="clearStepTwo"
            >
              <LzIcon size="sm" path="icons/chevron-left" />
              {{ $t('common.back') }}
            </button>
            <button
              type="button"
              data-test="assign-button"
              :disabled="!hasSelections || isLoading"
              class="btn btn--secondary mb-1 ml-0 w-48"
              @click="assign"
            >
              <LoadingSpinner v-if="isLoading" :inline="true" size="small" />
              {{ createLabel }}
            </button>
          </div>
        </template>
      </template>
    </Modal>
    <LinkErrorListener />
  </div>
</template>

<script>
import Routes from 'routes'
import { location } from 'utils'
import { ref, computed, onMounted, inject } from 'vue'
import { LzIcon, Modal, SwitchToggle, LoadingSpinner } from 'vue_features/shared/components/ui'
import LinkErrorListener from 'vue_features/shared/components/google_classroom/LinkErrorListener'
import AssignMaterials from './AssignMaterials'
import AssignToStudents from './AssignToStudents'
import { canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'AssignmentModal',
  components: {
    Modal,
    LzIcon,
    SwitchToggle,
    LoadingSpinner,
    LinkErrorListener,
    AssignMaterials,
    AssignToStudents,
  },
  setup() {
    const root = inject('useRoot')()
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    const {
      assignStep,
      autoOpen,
      materialsToAssign,
      selectedKlasses,
      hasSelections,
      assignStudents,
      klassesToAssignToGoogle,
    } = useAssignmentPromptStore()
    const { isConnectedToGoogle, loadCourses } = useGoogleClassroomStore()

    const isLoading = ref(false)
    const clearStepTwo = () => {
      klassesToAssignToGoogle.value.splice(0, klassesToAssignToGoogle.value.length)
      assignStep.value = 1
    }
    const clearModal = () => {
      clearStepTwo()
      materialsToAssign.value.splice(0, materialsToAssign.value.length)
      closeModal('AssignmentModal')
    }
    const _redirectUrl = (assignments) => {
      if (assignments.length === 1) return Routes.lz_code_path(assignments[0].code)
      if (selectedKlasses.value.length === 1) return Routes.teacher_klass_path(selectedKlasses.value[0].id)
      return Routes.teacher_klasses_path()
    }
    const assign = async () => {
      isLoading.value = true
      const assignments = await assignStudents()
      const url = _redirectUrl(assignments)
      clearModal()
      location.assign(url)
    }

    onMounted(() => isConnectedToGoogle.value && loadCourses())

    return {
      canModalShow,
      hasStudentFunctionality,
      autoOpen,
      hasSelections,
      isLoading,
      clearStepTwo,
      clearModal,
      assign,
      showMaterials: computed(() => assignStep.value === 1 && !isLoading.value),
      nextEnabled: computed(() => materialsToAssign.value.length !== 0),
      createLabel: computed(() => root.$t(materialsToAssign.value.length > 1 ? 'create_all' : 'create')),
      thisLabel: computed(() => root.$t(materialsToAssign.value.length > 1 ? 'these_assignments' : 'this_assignment')),
      stepTwo: () => (assignStep.value = 2),
    }
  },
}
</script>
