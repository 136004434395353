import { isEmpty as blank } from 'lodash'
import { computed, reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import { isState, ACTIVE, ARCHIVED, DRAFT, REVISION } from 'vue_features/shared/composables/use_record_states'

export const useLessonPlanStore = defineStore('lessonPlan', () => {
  const state = reactive({
    lessonPlan: {},
    analyticsProperties: {},
    customerStandardInitiatives: [],
    hasAssignment: false,
    totalPoints: 0,
    openTool: '',
    feedback: {},
    originalId: null,
  })

  const lessonPlan = computed(() => state.lessonPlan || {})
  const lessonPlanId = computed(() => lessonPlan.value.id)
  const lessonState = computed(() => lessonPlan.value.state)
  const logo = computed(() => lessonPlan.value.fromMetadata.logo)
  const customerOwner = computed(() => lessonPlan.value.fromMetadata.name)
  const lessonPlanTextStyle = computed(() => (lessonPlan.value ? `font-${lessonPlan.value.textStyle}` : ''))
  const isDescriptionBlank = computed(() => blank(lessonPlan.value.description))
  const isArchived = computed(() => isState(lessonPlan, ARCHIVED))
  const isActive = computed(() => isState(lessonPlan, ACTIVE))
  const isDraft = computed(() => isState(lessonPlan, DRAFT))
  const isRevision = computed(() => isState(lessonPlan, REVISION))
  const isToolOpen = computed(() => !!state.openTool)
  const hasItemCards = computed(() => lessonPlan.value.cards && lessonPlan.value.cards.some(isQuestionCard))

  function lessonPlanComputeds(keys) {
    return keys.reduce((original, key) => {
      original[key] = computed(() => lessonPlan.value[key])
      return original
    }, {})
  }

  const cards = computed(() => lessonPlan.value.cards || [])
  return {
    state,
    lessonState,
    logo,
    customerOwner,
    lessonPlanId,
    lessonPlanTextStyle,
    isDescriptionBlank,
    isActive,
    isArchived,
    isDraft,
    isRevision,
    isToolOpen,
    hasItemCards,
    cards,
    ...lessonPlanComputeds([
      'authors',
      'title',
      'customerVisibleStandards',
      'standards',
      'studentTools',
      'lessonType',
      'adaptation',
      'premium',
      'tagNames',
      'description',
      'source',
      'ownerName',
      'forkedFromTitle',
      'forkedFromId',
      'lastEditor',
      'deprecatedByUrl',
      'deprecatedByMessage',
      'personalized',
    ]),
  }
})
