import useEventListener from 'vue_features/shared/composables/use_event_listener'

const shortcuts = []
const keysDown = new Set()

let enabled = false

const findActiveShortcuts = () => {
  return shortcuts.filter((shortcut) => shortcut.keys.every((key) => keysDown.has(key)))
}

const listenForKeyDown = () => {
  useEventListener('keydown', (e) => {
    if (e.repeat) {
      return
    }

    // Whenever the meta key is pressed while another key is down, the `keyup` event for that key
    // will never fire. Because of this, we have to ignore key sequences that include the `metaKey`.
    // Ex: User presses Cmd + A then releases A. The `keyup` event for `A` never fires so `A`
    // always looks like it is pressed.
    if (e.metaKey) {
      keysDown.clear()
      return
    }

    // This prevents keyboard shortcuts from firing when the user is focussed on a text input.
    if (document.activeElement) {
      if (['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName)) {
        return
      }
    }

    keysDown.add(e.key)

    const activeShortcuts = findActiveShortcuts()

    for (const shortcut of activeShortcuts) {
      shortcut.action(e)
    }
  })
}

const listenForKeyUp = () => {
  useEventListener('keyup', (e) => {
    keysDown.delete(e.key)
  })
}

const enable = () => {
  if (enabled) {
    return
  }

  listenForKeyDown()
  listenForKeyUp()

  enabled = true
}

export default () => {
  const registerKeyboardShortcut = (keys, action) => {
    enable()

    const id = performance.now()

    shortcuts.push({
      id,
      keys,
      action,
    })

    return id
  }

  const unregisterKeyboardShortcut = (id) => {
    const shortcutIndex = shortcuts.findIndex((shortcut) => shortcut.id === id)

    if (shortcutIndex !== -1) {
      shortcuts.splice(shortcutIndex, 1)
    }
  }

  return {
    registerKeyboardShortcut,
    unregisterKeyboardShortcut,
  }
}
