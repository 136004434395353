import { createLzRouter } from 'vue_features/shared/router'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import DataDashboardUi from '../components/DataDashboardUi'

const useRouter = () => {
  const router = createLzRouter({
    routes: [
      {
        path: Routes.data_dashboard_index_path(),
        name: 'data_dashboard',
        component: DataDashboardUi,
      },
    ],
  })

  return { router }
}

export default useRouter
