import { createLzRouter, scrollToTop } from 'vue_features/shared/router'
import messages from './i18n/messages'
import { useBookmarksStore } from './composables/use_bookmarks_store'
import BookmarksIndex from './components/BookmarksIndex'

export default function ({ state }) {
  useBookmarksStore().$set(state)

  const routes = [
    { path: '/saved', name: 'saved', component: BookmarksIndex },
    { path: '/saved/:id', name: 'saved-id', component: BookmarksIndex },
  ]
  const router = createLzRouter({ scrollBehavior: scrollToTop, routes })
  router.beforeEach((to, _from, next) => {
    useBookmarksStore().loadInitialBookmarks(to.params.id)
    next()
  })

  return { component: BookmarksIndex, messages, router }
}
