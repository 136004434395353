import { onUnmounted, watch } from 'vue'

export default function useResizeObserver(elementRef, callback) {
  const observer = new ResizeObserver(callback)
  onUnmounted(() => observer.disconnect())

  watch(
    elementRef,
    (newVal, oldVal) => {
      if (newVal) observer.observe(newVal)
      if (oldVal) observer.disconnect()
    },
    { immediate: true },
  )

  return observer
}
