import { throttle } from 'lodash'
import { createClient, getUploadOptions } from 'clients/filestack'
import { config } from 'utils'
import ajaxLoader from 'images/tools/ajax-loader.gif'
import dataImageError from 'images/misc/data-image-error.png'
import URI from 'urijs'

const INVALID_SOURCES = ['googleusercontent.com', 'data:image', 'mail.google.com']
let uploadClient, uploadOptions

export default function fixImageSrc(editor) {
  editor.on('change', throttle(removeInvalidImages.bind(null, editor), 500))

  editor.on('imageReuploaded', ({ data }) => {
    replaceReuploadedImage(editor, data)
  })
}

function removeInvalidImages(editor) {
  const editorData = editor.getData()

  // For performance reasons do not parse data if it does not contain img tag
  if (!editorData.match(/<img/i)) {
    return
  }

  const invalidImages = []
  editor.document.find('img').$.forEach((img) => {
    const hasInvalidSrc = INVALID_SOURCES.some((src) => {
      return img.src.match(src)
    })

    if (hasInvalidSrc) {
      invalidImages.push({
        oldSrc: img.src,
        alt: img.alt,
        width: img.style.width,
        height: img.style.height,
      })
      img.alt = img.src // alt used to hold old src as ckeditor strips other attrs
      img.src = ajaxLoader
      img.style.width = '90px'
      img.style.height = '90px'
      editor.fire('change')
    }
  })

  if (invalidImages.length > 0) {
    reuploadImages(invalidImages, editor)
  }
}

async function reuploadImages(images, editor) {
  if (!uploadClient) {
    uploadOptions = await getUploadOptions(config.image)
    uploadClient = createClient(uploadOptions)
  }

  const promises = images.map((img) => {
    return reuploadImage(img).then((newSrc) => {
      editor.fire('imageReuploaded', { ...img, newSrc })
    })
  })

  await Promise.all(promises)
}

async function reuploadImage({ oldSrc }) {
  let image = oldSrc
  if (oldSrc.match('.com')) {
    try {
      const imageResponse = await fetch(oldSrc)
      image = await imageResponse.blob()
    } catch {
      return dataImageError
    }
  }
  return new Promise((resolve) => {
    uploadClient
      .upload(image)
      .then((res) => {
        resolve(imageUrl(res))
      })
      .catch(() => {
        resolve(dataImageError)
      })
  })
}

function imageUrl(filestackResponse) {
  return URI(filestackResponse.url)
    .search(() => {
      return {
        policy: uploadOptions.policy,
        signature: uploadOptions.signature,
      }
    })
    .toString()
}

async function replaceReuploadedImage(editor, { oldSrc, alt, newSrc, width, height }) {
  const ckeditorImg = editor.document.findOne(`img[alt~="${oldSrc}"]`)
  if (ckeditorImg) {
    const img = ckeditorImg.$
    img.alt = alt
    img.src = newSrc
    img.setAttribute('data-cke-saved-src', newSrc)
    await setImageDimensions(img, width, height)
    editor.fire('change')
  }
}

async function setImageDimensions(img, width, height) {
  if (img.src === dataImageError) {
    img.style.width = '238px'
    img.style.height = '127px'
  } else if (width || height) {
    img.style.width = width
    img.style.height = height
  } else {
    const { width, height } = await getImageWidthAndHeight(img.src)
    img.style.width = `${width}px`
    img.style.height = `${height}px`
  }
}

function getImageWidthAndHeight(src) {
  return new Promise((resolve, error) => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      resolve({ height: img.height, width: img.width })
    }
    img.onerror = () => {
      error({ height: 0, width: 0 })
    }
  })
}
