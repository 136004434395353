<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div ref="buttonEl" class="slide-toolbar__button">
    <label>
      <LzIcon :path="path" />
      <span>{{ label }}</span>
      <LzIcon class="mb-0" svg-classes="w-2 h-2" path="icons/move-grip" />
    </label>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'ContentDragButton',
  components: { LzIcon },
  props: {
    path: { type: String, required: true },
    label: { type: String, required: true },
  },
  setup(_, { emit }) {
    const buttonEl = ref(null)
    onMounted(() => emit('mount', buttonEl.value))
    onBeforeUnmount(() => emit('unmount', buttonEl.value))
    return { buttonEl }
  },
}
</script>
