<template>
  <div class="h-full w-full" :data-score-rating="rating">
    <div class="item-score-square">
      <LzTooltip :title="title" class="inline" popover-wrapper-classes="inline" cursor-class="cursor-pointer">
        <slot>
          <InlineSvg v-if="iconName" :path="`icons/${iconName}`" :aria-label="$t('assignment_report.score_dot')" />
        </slot>
      </LzTooltip>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { InlineSvg, LzTooltip } from 'vue_features/shared/components/ui'

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  rating: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
})

const iconName = computed(() => {
  if (props.icon) return props.icon
  const icons = { low: 'close', medium: 'minus', high: 'check', unattempted: 'close' }
  return icons[props.rating]
})
</script>

<style scoped>
.item-score-square {
  --default-size: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--score-size, var(--default-size));
  height: var(--score-size, var(--default-size));
  background: var(--score-background);
  margin: 0;
}
:deep(.item-score-square svg) {
  stroke: var(--score-text);
  color: var(--score-text);
  fill: transparent;
  width: calc(var(--score-size, var(--default-size)) / 2);
  height: calc(var(--score-size, var(--default-size)) / 2);
  margin: 0;
}
[data-score-rating='high'] {
  --score-background: rgb(var(--green-100));
  --score-text: rgb(var(--green-500));
}
[data-score-rating='medium'] {
  --score-background: rgb(var(--yellow-200));
  --score-text: rgb(var(--yellow-600));
}
[data-score-rating='low'] {
  --score-background: rgb(var(--red-100));
  --score-text: rgb(var(--red-500));
}
[data-score-rating='needs-grading'] {
  --score-background: rgb(var(--primary-200));
  --score-text: rgb(var(--primary-600));
}
[data-score-rating='unattempted'] {
  --score-background: rgb(var(--neutral-100));
  --score-text: rgb(var(--neutral-600));
}
</style>
