<template>
  <section class="border-neutral-200 mb-4 flex w-full flex-col rounded-xl border-2 border-solid p-0">
    <div class="border-neutral-200 flex w-full flex-row items-center justify-between gap-4 border-b-2 border-solid p-3">
      <div class="flex flex-grow items-center gap-3">
        <LzIcon :path="icon" size="lg" />
        <h5 class="flex-grow text-lg">{{ title }}</h5>
      </div>

      <slot name="actions"></slot>
    </div>

    <slot />
  </section>
</template>

<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'

defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: 'icons/pie-chart',
  },
})
</script>
