<template>
  <LzConfirmModal :modal-id="$options.name" :modal-title="$t('manage_modal.unarchive')">
    <template #confirmBody>
      <p class="my-2">{{ $t('manage_modal.confirm_unarchive_prompt', { name: activeModal.payload.klass.name }) }}</p>
      <p class="my-2">{{ $t('manage_modal.confirm_unarchive_details') }}</p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary" @click="handleConfirm">
        {{ $t('manage_modal.unarchive') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import AssignmentsService from '../../api/assignments_service'
import { openModal, closeModal, activeModal, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { location as $location } from 'utils'
import AssignmentsErrorUnarchiveModal from './AssignmentsErrorUnarchiveModal'

export default {
  name: 'AssignmentsUnarchiveModal',
  AssignmentsErrorUnarchiveModal,
  components: { LzConfirmModal },
  setup() {
    return { openModal, closeModal, activeModal, isModalBusy }
  },
  methods: {
    async handleConfirm() {
      this.isModalBusy = true
      const { lzCodeId, id } = this.activeModal.payload.lzCodeKlass
      try {
        await AssignmentsService.updateAssignmentActiveForKlass({ lzCodeId, id, isActive: true })
        $location.reload()
      } catch {
        this.isModalBusy = false
        this.closeModal(this.$options.name)
        this.openModal(this.$options.AssignmentsErrorUnarchiveModal)
      }
    },
  },
}
</script>
