<template>
  <div v-if="visible" v-once class="loading loading-spinner__overlay">
    <div class="loading__message">
      <LoadingSpinner style="width: inherit" />
      <p>{{ message === undefined ? $t('progress.please_wait') : message }}</p>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'

export default {
  components: { LoadingSpinner },
  i18n: { messages: namespaceLocaleObj(components, '', { only: 'progress' }) },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: undefined,
    },
  },
}
</script>
