<template>
  <LzModal
    :modal-title="$t('enable_new_lms1_3')"
    :modal-id="$options.name"
    class="lms-integrations-modal lzui-modal--medium"
    autoclose
  >
    <section>
      <form
        id="new_lms_integration"
        :action="Routes.site_admin_lti_v13_integrations_path()"
        class="simple_form new_lms_integration"
        accept-charset="UTF-8"
        method="post"
      >
        <input name="utf8" type="hidden" value="✓" />
        <input :value="authenticityToken" type="hidden" name="authenticity_token" />
        <div class="form-box select required lms_integration_name">
          <label class="select required" for="lms_integration_name">{{ $t('lms_name') }}</label>
          <select
            id="lms_integration_name"
            v-model="selectedIntegration"
            class="select required"
            name="lti_v13_integration[name]"
          >
            <option v-for="integrationName in inactiveLmsIntegrations" :key="integrationName" :value="integrationName">
              {{ integrationName }}
            </option>
          </select>

          <hr class="mb-2" />

          <template v-if="isCanvasSelected">
            <p class="mb-2">{{ $t('required_canvas_info_header') }}</p>
            <p v-if="isCanvasSelected" class="mb-2">
              <strong>{{ $t('lti_endpoint_url') }}</strong>
              <br />
              {{ ltiEndpointUrl }}
            </p>
            <p v-if="isCanvasSelected" class="mb-2">
              <strong>{{ $t('configuration_url') }}</strong>
              <br />
              {{ configurationInfoUrl }}
            </p>
          </template>
          <template v-else>
            <p class="mb-2">{{ $t('required_generic_info_header') }}</p>
            <p class="mb-2">
              <strong>{{ $t('oidc_url') }}</strong>
              <br />
              {{ oidcUrl }}
            </p>
            <p class="mb-2">
              <strong>{{ $t('jwks_url') }}</strong>
              <br />
              {{ lti_v13_jwks_url }}
            </p>
            <p class="mb-2">
              <strong>{{ $t('lti_endpoint_url') }}</strong>
              <br />
              {{ ltiEndpointUrl }}
            </p>
          </template>

          <hr class="mb-2" />
          <template v-if="isCanvasSelected">
            <label for="external_host">{{ $t('external_host') }}</label>
            <input
              id="external_host"
              placeholder="https://yourschoolname.instructure.com"
              type="text"
              name="lti_v13_integration[external_host]"
            />
            <p class="help-block">{{ $t('external_host_help') }}</p>
            <label for="external_client_id">{{ $t('canvas_lti_13_key') }}</label>
            <input
              id="external_client_id"
              placeholder="910200000XXXXX"
              type="text"
              name="lti_v13_integration[external_client_id]"
            />
            <p class="help-block">{{ $t('canvas_lti_13_key_help') }}</p>
            <input
              id="iss"
              readonly
              value="https://canvas.instructure.com"
              placeholder
              type="hidden"
              name="lti_v13_integration[iss]"
            />
            <input
              id="deployment_id"
              readonly
              placeholder
              type="hidden"
              value="canvas"
              name="lti_v13_integration[deployment_id]"
            />
          </template>
          <template v-else>
            <label for="external_client_id">{{ $t('client_id') }}</label>
            <input
              id="external_client_id"
              placeholder="123ABC"
              type="text"
              name="lti_v13_integration[external_client_id]"
              required
            />
            <p class="help-block">{{ $t('value_provided_by_lms') }}</p>
            <label for="iss">{{ $t('iss') }}</label>
            <input id="iss" type="text" placeholder="https://example.com" name="lti_v13_integration[iss]" required />
            <p class="help-block">{{ $t('value_provided_by_lms') }}</p>
            <label for="oidc_address">{{ $t('oidc_url') }}</label>
            <input
              id="oidc_address"
              type="text"
              placeholder="https://example.com/oidc"
              name="lti_v13_integration[oidc_address]"
              required
            />
            <p class="help-block">{{ $t('value_provided_by_lms') }}</p>
            <label for="jwks_address">{{ $t('jwks_url') }}</label>
            <input
              id="jwks_address"
              type="text"
              placeholder="https://example.com/jwks"
              name="lti_v13_integration[jwks_address]"
              required
            />
            <p class="help-block">{{ $t('value_provided_by_lms') }}</p>
            <label for="oauth2_address">{{ $t('api_token_url') }}</label>
            <input
              id="oauth2_address"
              type="text"
              required
              placeholder="https://example.com/api_token"
              name="lti_v13_integration[oauth2_address]"
            />
            <p class="help-block">{{ $t('value_provided_by_lms') }}</p>
            <input
              id="deployment_id"
              readonly
              placeholder
              type="hidden"
              value="generic"
              name="lti_v13_integration[deployment_id]"
            />
          </template>
        </div>

        <footer class="px-0">
          <div v-if="isCanvasSelected" class="icon small float-left">
            <LzIcon path="icons/help" />
            <a :href="$options.helpUrl" target="_blank" rel="noopener noreferrer">
              {{ $t('canvas_setup_help') }}
            </a>
          </div>
          <input :value="$t('create')" type="submit" name="commit" class="btn btn--primary float-right" />
          <div class="clear-both"></div>
        </footer>
      </form>
    </section>
  </LzModal>
</template>

<script>
import { HELP_CANVAS_LTI_13_URL } from 'rails_constants'
import { LzModal, LzIcon } from 'vue_features/shared/components/ui'
import Routes from 'routes'

export default {
  name: 'AddLtiV13IntegrationModal',
  helpUrl: HELP_CANVAS_LTI_13_URL,
  components: {
    LzModal,
    LzIcon,
  },
  props: {
    inactiveLmsIntegrations: {
      type: Array,
      required: true,
    },
    authenticityToken: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
  data() {
    return {
      selectedIntegration: this.inactiveLmsIntegrations[0],
    }
  },
  computed: {
    isCanvasSelected() {
      return this.selectedIntegration === 'canvas'
    },
    oidcUrl() {
      return Routes.lti_v13_login_initiations_url()
    },
    ltiEndpointUrl() {
      return Routes.auth_callback_url({ provider: 'ltiv13' })
    },
    configurationInfoUrl() {
      return Routes.lti_v13_config_url('canvas', { format: 'json' })
    },
    lti_v13_jwks_url() {
      return Routes.lti_v13_jwks_url({})
    },
  },
}
</script>
