import platform from 'platform'

import debug from 'debug'

import { current } from 'utils'

const log = debug('app:initializers/debug')

export default function () {
  log('ENV', process.env.NODE_ENV)
  log('Platform (including Browser Info)', platform)
  log('OS', platform.os)
  log('LZ.current', current)
}
