import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import AssignmentReportService from 'vue_features/assignments/reports/api/assignment_report_service'
import { newReportsApp } from 'clients/learnosity'
import { useCurrentUserStore } from 'vue_features/shared/store/composables/use_current_user_store'

export const useLearnositySessionItemStore = defineStore('learnositySessionItemStore', () => {
  const state = reactive({
    learnosityItemScoresBySession: {},
  })

  const loadAssignmentReport = (assignment, params = {}) => {
    const { isTeacher } = useCurrentUserStore()

    const teacherParams = isTeacher.value ? { studentsForCurrentTeacher: true } : {}

    return AssignmentReportService.getAssignmentReports({
      code: assignment.code,
      withResponses: true,
      ...teacherParams,
      ...params,
    }).then((data) => {
      const hasReportData = data.learnosityReportConfig?.request

      if (hasReportData) {
        const { id: reportId, activity_id: activityId } = data.learnosityReportConfig.request.reports[0]

        return newReportsApp(data.learnosityReportConfig).then((reportsApp) => {
          return new Promise((resolve, reject) => {
            const learnosityReport = reportsApp.getReport(reportId)

            learnosityReport.on('load:data', (reportData) => {
              if (reportData) {
                resolve(reportData[activityId])
              } else {
                reject()
              }
            })
          })
        })
      }
    })
  }

  const loadItemScores = async (assignment, items, params = {}) => {
    const assignmentReport = await loadAssignmentReport(assignment, params)
    const validItems = new Set(items.map((item) => item.itemReference))

    for (const userScores of Object.values(assignmentReport.users)) {
      const learnositySessionId = Object.values(userScores.items)[0].session_id
      const validItemScores = Object.values(userScores.items).filter((item) => validItems.has(item.ibk_reference))

      Object.assign(state.learnosityItemScoresBySession, {
        [learnositySessionId]: validItemScores.map((item) => ({
          reference: item.ibk_reference,
          attempted: item.attempted,
          unmarked: item.unmarked,
          score: item.score,
          maxScore: item.max_score,
        })),
      })
    }
  }

  const storeUpdatedItemScore = (session, updatedItemScore) => {
    const itemIndex = state.learnosityItemScoresBySession[session.learnositySessionId].findIndex(
      (item) => item.reference === updatedItemScore.reference,
    )

    if (itemIndex === -1) {
      return
    }

    const existingItem = state.learnosityItemScoresBySession[session.learnositySessionId][itemIndex]
    const updatedSession = state.learnosityItemScoresBySession[session.learnositySessionId]
    updatedSession.splice(itemIndex, 1, {
      ...existingItem,
      reference: updatedItemScore.reference,
      attempted: updatedItemScore.scoring.attempted,
      unmarked: updatedItemScore.scoring.unmarked,
      score: updatedItemScore.scoring.score,
      maxScore: updatedItemScore.scoring.maxScore,
    })

    state.learnosityItemScoresBySession[session.learnositySessionId] = updatedSession
  }

  return {
    state,
    loadItemScores,
    storeUpdatedItemScore,
  }
})
