<template>
  <LoadingSpinner v-if="uploading" size="large" class="large" />
  <div v-else-if="fileUrl" class="slide__image">
    <FilestackSvg v-if="isSvg" :url="fileUrl" :desc="altText" />
    <img v-else :src="fileUrl" :alt="altText" />
  </div>
  <div v-else class="slide__placeholder">
    <LzIcon :desc="altText" path="icons/image-no-circle.svg" />
  </div>
</template>

<script>
import { computed } from 'vue'
import FilestackSvg from '../viewer_contents/FilestackSvg'
import useSlideImage from '../../composables/use_slide_image'
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'SlideEditorImage',
  components: { LzIcon, FilestackSvg, LoadingSpinner },
  props: { content: { type: Object, required: true } },
  setup(props) {
    const uploading = computed(() => {
      return props.content.uploading
    })
    const { altText, fileUrl, isSvg } = useSlideImage(props)
    return { uploading, altText, isSvg, fileUrl }
  },
}
</script>
