<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="card__audio">
    <template v-if="hasAudio">
      <AudioPlayer :audio="voiceoverInEdit" />
      <button class="caution btn--demoted text-sm" @click="removeVoiceover">{{ $t('common.remove') }}</button>
    </template>
    <template v-else>
      <Grid v-if="usingExistingResource" class="card__audio-select my-4">
        <div class="align-left col-span-8">
          <div :class="{ error: hasInvalidVoiceOver || isBlank }">
            <label>
              {{ $t('lesson_plans.card_form_templates.enter_audio_id') }}
              <span v-if="hasInvalidVoiceOver">{{ $t('lesson_plans.card_form_templates.invalid_voiceover') }}</span>
              <span v-if="isBlank">{{ $t('lesson_plans.card_form_templates.is_required') }}</span>
            </label>
            <input v-model="voiceoverId" type="text" />
          </div>
        </div>
        <div class="align-right col-span-4">
          <button class="btn mr-1" @click="usingExistingResource = false">
            {{ $t('common.cancel') }}
          </button>
          <button :disable="hasInvalidVoiceOver" class="btn btn--green" @click="saveVoiceoverFromInput">
            {{ $t('common.save') }}
          </button>
        </div>
      </Grid>
      <div v-else class="card__audio-uploader">
        <InlineUploader @uploaded="onUploaded">
          <template #button="{ launch, isOpen }">
            <button class="btn btn--small mx-4 mt-6" @click="launch">
              <LzIcon size="sm" path="icons/audio-no-circle" />
              <template v-if="isOpen">
                {{ $t('common.cancel') }}
              </template>
              <template v-else>
                {{ $t('lesson_plans.card_form_templates.add_voiceover') }}
              </template>
            </button>
          </template>
        </InlineUploader>
        <div v-if="canUseExistingResource" class="ml-2 inline-block">
          <a class="smaller cursor-pointer" @click="usingExistingResource = true">
            {{ $t('lesson_plans.card_form_templates.use_existing_audio') }}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import AudioPlayer from 'vue_features/resources/audios/components/AudioPlayer'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import InlineUploader from 'vue_features/shared/components/filestack_upload/InlineUploader'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'
import ResourcesService from 'vue_features/resources/api'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { isAudio } from 'vue_features/shared/composables/use_resource_types'

export default {
  name: 'VoiceoverUploader',
  components: { Grid, LzIcon, AudioPlayer, InlineUploader },
  setup() {
    const { voiceoverInEdit, updateEditedVoiceover } = useCardDeckEditorStore()
    const { lessonPlan } = useLessonPlanStore()
    const { authorizedAsAuthor } = useCurrentUserStore()
    const canUseExistingResource = computed(() => !lessonPlan.value.adaptation || authorizedAsAuthor.value)
    const usingExistingResource = ref(false)
    const hasAudio = computed(() => !!voiceoverInEdit.value.secureFileUrl)
    const hasInvalidVoiceOver = ref(false)
    const isBlank = ref(false)
    const voiceoverId = computed({
      get: () => voiceoverInEdit.value.id,
      set: (id) => updateEditedVoiceover({ id }),
    })

    watch(usingExistingResource, () => {
      voiceoverId.value = null
      isBlank.value = false
      hasInvalidVoiceOver.value = false
    })

    const saveVoiceoverFromInput = async () => {
      isBlank.value = false
      hasInvalidVoiceOver.value = false

      if (!voiceoverId.value) {
        isBlank.value = true
        return
      }

      try {
        const audio = await ResourcesService.get(voiceoverId.value)
        if (isAudio(audio)) {
          updateEditedVoiceover(audio)
        } else {
          hasInvalidVoiceOver.value = true
        }
      } catch (errors) {
        hasInvalidVoiceOver.value = true
      }
    }

    const onUploaded = (newFile) => {
      const { fileUrl } = useFilestackAccessor(newFile)
      updateEditedVoiceover({ sourceFileUrl: newFile.baseUrl, secureFileUrl: fileUrl.value, file: newFile })
    }

    const removeVoiceover = () => {
      updateEditedVoiceover({ id: null, sourceFileUrl: null, secureFileUrl: null })
    }

    return {
      saveVoiceoverFromInput,
      hasAudio,
      usingExistingResource,
      voiceoverInEdit,
      hasInvalidVoiceOver,
      isBlank,
      voiceoverId,
      onUploaded,
      removeVoiceover,
      canUseExistingResource,
    }
  },
}
</script>
