<template>
  <LzAccordion :bordered="false" class="border-base mt-8 border-t">
    <template #head="{ toggle, isOpen }">
      <button
        class="outline-none group flex w-full items-center justify-center p-4"
        :aria-expanded="isOpen ? 'true' : 'false'"
        @click="toggle"
      >
        <div class="text-primary-accent flex items-center gap-1 font-bold">
          <AccordionIcon :is-open="isOpen" />
          {{ $t('feedback_form.feedback_label') }}
        </div>
      </button>
    </template>
    <template #body="{ isOpen }">
      <div v-show="isOpen" class="mx-auto flex max-w-7xl justify-center px-3 py-2 md:px-6">
        <ContactForm
          v-bind="{ feedbackTargetId, feedbackTargetType, collectionName, contentOwner }"
          class="w-full lg:w-2/3"
        />
      </div>
    </template>
  </LzAccordion>
</template>

<script>
import LzAccordion from 'vue_features/shared/components/ui/LzAccordion'
import ContactForm from 'vue_features/contact_form/components/ContactForm'
import AccordionIcon from 'vue_features/shared/components/ui/AccordionIcon'
import { useContactDetailsStore } from '../../shared/composables/use_contact_details_store'

export default {
  name: 'FeedbackAccordion',
  components: { ContactForm, LzAccordion, AccordionIcon },
  setup() {
    const { feedbackTargetId, feedbackTargetType, collectionName, contentOwner } = useContactDetailsStore()

    return {
      feedbackTargetId,
      feedbackTargetType,
      collectionName,
      contentOwner,
    }
  },
}
</script>
