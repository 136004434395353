<template>
  <ToggleButtonOption :value="dateRangeString" class="flex items-center justify-center">
    <slot />
  </ToggleButtonOption>
</template>

<script>
import ToggleButtonOption from 'vue_features/shared/components/ui/ToggleButtonOption'
import { computed } from 'vue'

export default {
  name: 'DateRangeSelectPreset',
  components: { ToggleButtonOption },
  props: {
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
  },
  setup(props) {
    const dateRangeString = computed(() => JSON.stringify({ start: props.start, end: props.end }))
    return { dateRangeString }
  },
}
</script>
