import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const UsersService = {
  search({ searchParams }) {
    return HTTP.getJSON(Routes.site_admin_users_path(), searchParams)
  },
  update({ userId, updateParams }) {
    const params = { siteAdminUser: updateParams }
    return HTTP.put(Routes.site_admin_user_path(userId), snakeKeys(params))
  },
  delete(userId) {
    return HTTP.delete(Routes.site_admin_user_path(userId))
  },
  create(usersData) {
    const params = snakeKeys({ siteAdminUser: { users: usersData } })

    return HTTP.create(Routes.site_admin_users_path(), params)
  },
  deleteArchivedUsers() {
    return HTTP.create(Routes.site_admin_archived_users_deletions_path())
  },
}

export default UsersService
