import { disableThenEnableConfirm } from 'utils'

function beforeMount(el) {
  el.addEventListener('click', () => {
    return disableThenEnableConfirm()
  })
}

export default {
  beforeMount,
}
