/*
  The mathjax plugin only matches font size whereas the page render matches font height (better!)
  This adjusts the plugin to support the scaling difference for the two font heights we use
  (open sans and the custom elementary font)
  see: https://app.clubhouse.io/learnzillion/story/27419
*/
function getFontSize(from, to, currentSize) {
  const fontFamily = from.getComputedStyle('font-family'),
    scaling = fontFamily.includes('Open Sans') ? 1.191 : 1.09

  to.setStyle('position', 'relative')
  to.setStyle('bottom', `${1.5}px`)
  return `${parseFloat(currentSize) * scaling}px`
}

export function customCopyStyles(from, to) {
  const stylesToCopy = ['color', 'font-style', 'font-weight', 'font-variant', 'font-size']

  for (let i = 0; i < stylesToCopy.length; i++) {
    const key = stylesToCopy[i]
    let val = from.getComputedStyle(key)
    if (val) {
      if (key === 'font-size') {
        val = getFontSize(from, to, val)
      }
      to.setStyle(key, val)
    }
  }
}
