<template>
  <li v-if="tagNames.length > 0" class="page-head__tags" :class="{ 'text-gray-600': !bold }">
    <span class="float-left" :class="{ 'text-bold': bold }">{{ $t('common.label.tags') }}</span>
    <ul>
      <li v-for="tag in tagNames" :key="tag">
        <a :href="tagUrl(tag)">{{ tag }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
import Routes from 'routes'

export default {
  name: 'TagList',
  props: {
    tagNames: { type: Array, default: () => [] },
    bold: { type: Boolean, default: false },
  },
  setup() {
    const tagUrl = (tag) => Routes.search_path({ m: 'LessonPlan', tags: [tag] })
    return { tagUrl }
  },
}
</script>
