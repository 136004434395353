import { ref, computed } from 'vue'
import { invoke } from 'lodash'
import useCardPersistable from 'vue_features/lesson_plans/edit/composables/use_card_persistable'
import ResourcesService from 'vue_features/resources/api'
import ResourcesRevisionService from 'vue_features/resources/api/resources_revision_service'
import CardsService from 'vue_features/lesson_plans/edit/api/cards_service'
import CardEditsService from '../api/card_edits_service'
import { toParams } from 'vue_features/resources/shared/composables/use_resource_persistable'
import { getInsertRank, sortByRank } from 'utils/card_rank_helpers'
import { useLearnosityItemEditorStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_editor_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { ACTIVE } from 'vue_features/shared/composables/use_record_states'

let forceRender = 0

function cardFromEdit(edit) {
  const newCard = { ...edit.card },
    newEdit = { ...edit }
  delete newEdit.card
  newCard.activeEdit = newEdit
  return newCard
}

export default function useCardEditorApi({
  selectedCardId = { value: null },
  cardInEdit,
  isContentAndNoteImport = { value: false },
  contentInEdit,
  voiceoverInEdit = { value: {} },
  updateObjectKey,
  original,
  isEditingQuestion = { value: false },
  cards,
  onSubmit,
  context = 'lesson',
  spliceCards,
}) {
  const { isCreatingMetaEdits, isCreatingPersonalEdits, isRevising, ownerId } = useLessonPlanEditStore()
  const { didCardChange, getCardParams } = useCardPersistable(context)
  const {
    item,
    client: learnosityClient,
    clientReady: learnosityClientReady,
    errors: learnosityErrors,
    checkEmptyQuestionError,
  } = useLearnosityItemEditorStore()
  const { lessonPlan } = useLessonPlanStore()

  const isSaving = ref(false)
  const errors = ref([])
  const resetErrors = () => (errors.value = [])
  const errorCheckers = ref([])

  const addErrorChecker = (errorChecker) => {
    errorCheckers.value.push(errorChecker)
  }

  const removeErrorChecker = (errorChecker) => {
    const index = errorCheckers.value.findIndex((checker) => errorChecker === checker)

    if (index !== -1) {
      const updatedErrorCheckers = [...errorCheckers.value]
      updatedErrorCheckers.splice(index, 1)
      errorCheckers.value = updatedErrorCheckers
    }
  }

  const checkErrors = () => {
    const title = cardInEdit.value.title.trim()
    if (title.length <= 0) {
      errors.value.push('required')
    } else if (title.length >= 250) {
      errors.value.push('maxlength')
    } else if (isEditingQuestion.value && learnosityErrors.value.length > 0) {
      errors.value.push(...learnosityErrors.value)
      if (!item.value.title) {
        invoke(document.querySelector('input[data-authorapi-selector=item-title]'), 'focus')
      }
    }
    for (const errorChecker of errorCheckers.value) {
      errorChecker()
    }
  }

  const importTeachingNotes = async (cardId) => {
    return await CardsService.getTeachingNotes(cardId)
  }

  const submit = async () => {
    if (isSaving.value) {
      return
    }

    resetErrors()
    checkErrors()
    if (errors.value.length) {
      return
    }

    isSaving.value = true

    try {
      await saveVoiceover()
      if (!cardInEdit.value.includesCard) {
        await saveContent()
      }
      await saveCard()

      isSaving.value = false
      onSubmit()
    } catch (error) {
      if (typeof error === 'string') {
        errors.value.push(error)
      } else if (error.status === 422) {
        errors.value = error.responseJSON
      } else {
        errors.value.push('unknown')
      }
      isSaving.value = false
    }
  }

  const saveVoiceover = async () => {
    const voiceover = voiceoverInEdit.value
    if (voiceover.sourceFileUrl && voiceoverChanged(voiceover) && !voiceoverInEdit.value.id) {
      voiceover.name = voiceover.name || `Audio for - ${cardInEdit.value.title}`
      const audio = await saveResource(voiceover)
      updateObjectKey('voiceoverInEdit', audio)
    }
  }

  const voiceoverChanged = (voiceover) => {
    const originalVoiceover = original.value.voiceover
    if (originalVoiceover) {
      return originalVoiceover.id !== voiceover.id || originalVoiceover.sourceFileUrl !== voiceover.sourceFileUrl
    } else {
      return true
    }
  }

  const saveContent = async () => {
    const content = isEditingQuestion.value ? item.value : contentInEdit.value
    const card = cardInEdit.value
    await saveLearnosityWidget()

    if (isSaveableContent(content, card)) {
      content.name = content.name || card.title
      content.textStyle = content.textStyle || lessonPlan.value.textStyle
      const response = await saveResource(content)
      updateObjectKey('contentInEdit', response)
    }
  }

  const isSaveableContent = (content, card) => {
    const isTypeSaveable = content.type !== 'Title' && content.type !== 'Existing'
    const isResourceCard = card.type === 'ResourceCard'
    const isEditable = !content.id || content.editable
    return isTypeSaveable && isResourceCard && isEditable
  }

  const saveLearnosityWidget = async () => {
    if (isEditingQuestion.value) {
      await learnosityClient.value.beforeSave()
      await learnosityClient.value.save()
      checkEmptyQuestionError()
      checkErrors()
    }
  }

  const saveResource = (content) => {
    if (isRevising.value) {
      return saveRevision(content)
    } else {
      if (content.id) {
        return ResourcesService.update(content.id, content.type, toParams(content))
      } else {
        content.adaptation = lessonPlan.value.adaptation || !!isCreatingPersonalEdits.value
        content.ownerId = ownerId.value
        content.state = lessonPlan.value.state
        return ResourcesService.create(content.type, toParams(content))
      }
    }
  }

  const saveRevision = async (content) => {
    if (!content.id) {
      content.state = ACTIVE
      content.ownerId = ownerId.value
      const response = await ResourcesService.create(content.type, toParams(content))
      if (!isEditingQuestion.value || item.value.reused) {
        return response
      }
      content.id = response.id
    }

    if (!content.revisionId) {
      const resource = await ResourcesRevisionService.create(content.id, content.type)
      content.revisionId = resource.revisionId
      if (resource.type === 'Wiki') {
        setRevisionBlockIds(content, resource)
      }
      if (isEditingQuestion.value && resource.content === content.content) {
        content.content = await createLearnosityItemRevision(content.content)
      }
    }

    content.state = 'revision'
    const revision = await ResourcesService.update(content.revisionId, content.type, toParams(content))
    revision.revisionId = revision.id
    revision.id = content.id
    return revision
  }

  const setRevisionBlockIds = (content, contentWithRevisionIds) => {
    const equivalentBlock = (block) => {
      return contentWithRevisionIds.blocks.find((blockWithId) => blockWithId.id === block.id)
    }

    const matchBlocks = (blocks) => {
      blocks.forEach((block) => {
        if (block.id) {
          block.id = equivalentBlock(block)
          if (block.content.blocks) {
            matchBlocks(block.content.blocks)
          }
        }
      })
    }

    matchBlocks(content.blocks)
  }

  const createLearnosityItemRevision = async (oldItemRef) => {
    learnosityClientReady.value = false
    const tags = learnosityClient.value.getAllTags()
    tags['Copied from'] = oldItemRef
    tags['Owner'] = `${useCurrentCustomerStore().state.id}`
    tags['revision'] = ['true']
    learnosityClient.value.replaceTags(tags)
    return await learnosityClient.value.duplicateItem()
  }

  const saveCard = async () => {
    const card = cardInEdit.value
    const { includesCard, ...params } = getCardParams(card)
    if (includesCard) {
      const importKey = isContentAndNoteImport.value ? 'contentAndNoteImportId' : 'contentImportId'
      params.card[importKey] = contentInEdit.value.id
    } else {
      params.card.contentId = contentInEdit.value.id
    }
    params.card.voiceoverId = voiceoverInEdit.value.id

    if (didCardChange(original, params)) {
      addCardEditParams(params)
      const response = card.id ? await updateCard(card, params) : await createCard(params)
      selectedCardId.value = response.id
    }
  }

  const cardApi = computed(() => (isCreatingMetaEdits.value ? CardEditsService : CardsService))

  const updateCard = async (card, params) => {
    const response = await cardApi.value.update({ id: card.id, activeEdit: card.activeEdit }, params)
    const updatedCard = isCreatingMetaEdits.value ? cardFromEdit(response) : response
    const index = cards.value.findIndex((c) => c.id === card.id)
    spliceCards(index, 1, updatedCard)
    if (isEditingQuestion.value) {
      updatedCard.forceRerender = forceRender++ // learnosity does not automatically trigger updates
    }
    return updatedCard
  }

  const createCard = async (params) => {
    const response = await cardApi.value.create(lessonPlan.value.id, params)
    const newCard = isCreatingMetaEdits.value ? cardFromEdit(response) : response
    const newCards = sortByRank(cards.value.concat(newCard))
    const index = newCards.findIndex((c) => c.id === newCard.id)
    spliceCards(index, 0, newCard)
    return newCard
  }

  const updateRank = (card, params) => {
    if (isCreatingMetaEdits.value) {
      params.cardEdit = {
        nextCardId: cardIdAtOffset(1),
        prevCardId: prevCardId.value,
      }
      return CardEditsService.update(card, params).then((response) => {
        const index = cards.value.indexOf(card)
        spliceCards(index, 1, cardFromEdit(response))
      })
    } else {
      return CardsService.update(card, params)
    }
  }

  const cardIdAtOffset = (offset) => {
    const sortedCards =
      cardInEdit.value.id && cards.value.some((card) => card.id === cardInEdit.value.id)
        ? cards.value
        : sortByRank(cards.value.concat(cardInEdit.value))
    const index = sortedCards.findIndex((card) => card.id === cardInEdit.value.id) + offset
    if (index >= 0 && index < sortedCards.length) {
      return (sortedCards[index] || { id: null }).id
    }
    return null
  }

  const prevCardId = computed(() => {
    return cardIdAtOffset(-1)
  })
  const nextCardId = computed(() => {
    return cardIdAtOffset(1)
  })

  const addCardEditParams = (params) => {
    if (isCreatingMetaEdits.value) {
      params.cardEdit = {
        type: 'CardInsert',
        prevCardId: prevCardId.value,
        nextCardId: nextCardId.value,
        userId: isCreatingPersonalEdits.value ? ownerId.value : null,
      }
    }
  }

  const copyCard = async (card) => {
    const index = cards.value.indexOf(card)
    const response = await cardApi.value.create(lessonPlan.value.id, copyParams(index, card))
    const copy = isCreatingMetaEdits.value ? cardFromEdit(response) : response
    spliceCards(index + 1, 0, copy)
    selectedCardId.value = copy.id
    return copy
  }

  const copyParams = (index, card) => {
    const copyRank = getInsertRank(index + 1, cards.value)
    const params = {
      card: { contexts: ['lesson'], context: 'lesson', fork: card.id, rank: copyRank },
    }
    if (isCreatingMetaEdits.value) {
      const hasNextCard = cards.value.length > 1 && index < cards.value.length - 1
      params.cardEdit = {
        type: 'CardInsert',
        prevCardId: card.id,
        nextCardId: hasNextCard ? cards.value[index + 1].id : null,
        userId: isCreatingPersonalEdits.value ? ownerId.value : null,
      }
    }
    return params
  }

  const destroyCard = async (card) => {
    await cardApi.value.destroy(card)
    const index = cards.value.indexOf(card)
    spliceCards(index, 1)
    if (index > 0) {
      selectedCardId.value = cards.value[index - 1].id
    } else if (cards.value.length > 0) {
      selectedCardId.value = cards.value[0].id
    }
  }

  const hideCard = async (card) => {
    const params = {
      cardEdit: { type: 'CardHide', cardId: card.id, userId: isCreatingPersonalEdits.value ? ownerId.value : null },
    }
    const response = await CardEditsService.create(lessonPlan.value.id, params)
    const index = cards.value.indexOf(card)
    cards.value[index].activeEdit = response
  }

  const showCard = async (card) => {
    await CardEditsService.destroy(card)
    const index = cards.value.indexOf(card)
    cards.value[index].activeEdit = null
  }

  return {
    isSaving,
    errors,
    resetErrors,
    importTeachingNotes,
    submit,
    copyCard,
    updateRank,
    destroyCard,
    hideCard,
    showCard,
    addErrorChecker,
    removeErrorChecker,
  }
}
