<template>
  <Breadcrumbs
    v-if="!hasPathCrumbs"
    :breadcrumbs="breadcrumbs"
    :is-collection-map-available="isCollectionMapAvailable"
    :is-expanded-row="collection !== null && !isWiki"
  />
  <PathCrumbs v-else />
</template>

<script>
import Breadcrumbs from 'vue_features/shared/components/breadcrumbs/Breadcrumbs'
import PathCrumbs from 'vue_features/resources/shared/components/path_crumbs/PathCrumbs'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { useResourceTypes } from 'vue_features/shared/composables'

export default {
  name: 'Crumbs',
  components: {
    Breadcrumbs,
    PathCrumbs,
  },
  setup() {
    const { resource, breadcrumbs, isCollectionMapAvailable, collection } = useResourceShowStore()
    const { isWiki } = useResourceTypes(resource)
    const { hasPathCrumbs } = usePathCrumbsStore()

    return {
      isWiki,
      isCollectionMapAvailable,
      resource,
      hasPathCrumbs,
      breadcrumbs,
      collection,
    }
  },
}
</script>
