import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import GradingSessionService from '../api/grading_session_service'
import GradingSessionResponseService from '../api/grading_session_response_service'
import { useLearnositySessionItemStore } from './use_learnosity_session_item_store'

export const useLearnositySessionResponseStore = defineStore('learnositySessionResponseStore', () => {
  const state = reactive({
    learnosityQuestionsApiConfig: null,
    learnosityResponseScoresBySession: {},
  })

  const loadResponseScores = async (session, items) => {
    const data = await GradingSessionResponseService.getSessionResponses(session.lzCode, session.id)
    storeResponseScores(session.learnositySessionId, data, items)
  }

  const storeResponseScores = (sessionId, data, items) => {
    const itemScores = []
    const responseScores = []
    const loadedItems = data.items || []
    const validItems = new Set(items.map((item) => item.itemReference))

    for (const item of loadedItems) {
      if (!validItems.has(item.reference)) continue
      responseScores.push(
        item.responses.map((response, index) => {
          return {
            number: index + 1,
            responseId: response.responseId,
            itemReference: item.reference,
            questionReference: response.questionReference,
            attempted: response.attempted,
            score: response.score,
            maxScore: response.maxScore,
            updating: response.updating,
            comment: response.comment,
          }
        }),
      )
      itemScores.push({
        reference: item.reference,
        attempted: item.scoring?.attempted,
        unmarked: item.scoring?.unmarked,
        score: item.scoring?.score,
        maxScore: item.scoring?.maxScore,
      })
    }

    const { learnosityItemScoresBySession } = useLearnositySessionItemStore()
    learnosityItemScoresBySession.value = Object.assign({}, learnosityItemScoresBySession.value, {
      [sessionId]: itemScores,
    })
    state.learnosityResponseScoresBySession = Object.assign({}, state.learnosityResponseScoresBySession, {
      [sessionId]: responseScores.flat(),
    })
    state.learnosityQuestionsApiConfig = data.questionsApiInit
  }

  const storeUpdatedResponse = (session, updatedResponseScore) => {
    const sessionResponses = state.learnosityResponseScoresBySession[session.learnositySessionId] || []
    const responseIndex = sessionResponses.findIndex(
      (response) => response.responseId === updatedResponseScore.responseId,
    )

    if (responseIndex === -1) {
      return
    }

    const existingResponse = state.learnosityResponseScoresBySession[session.learnositySessionId][responseIndex]

    const updatedSession = state.learnosityResponseScoresBySession[session.learnositySessionId]
    updatedSession.splice(responseIndex, 1, { ...existingResponse, ...updatedResponseScore })

    state.learnosityResponseScoresBySession[session.learnositySessionId] = updatedSession
  }

  const updateResponseScores = (session, response, { score = null, maxScore = null }) => {
    const assignmentId = session.lzCode
    const newScore = score ?? response.score
    const newMaxScore = maxScore ?? response.maxScore
    const updating = newScore !== null

    storeUpdatedResponse(session, {
      responseId: response.responseId,
      score: newScore,
      maxScore: newMaxScore,
      updating,
    })

    if (updating) {
      const studentResponses = {
        responseScores: [
          {
            responseId: response.responseId,
            score: newScore,
            maxScore: newMaxScore,
          },
        ],
      }

      GradingSessionResponseService.updateSessionResponses(assignmentId, session.id, studentResponses)
    }
  }

  const updateResponseComment = async (session, response, comment, publish) => {
    const existingComment = response.comment || {}
    const updatedComment = { ...existingComment, content: comment, published: comment && publish }
    const data = await GradingSessionResponseService.updateSessionResponseComment(
      session.lzCode,
      session.id,
      response.responseId,
      updatedComment,
      publish,
    )
    storeUpdatedResponse(session, { ...response, comment: data.comment })
  }

  const deleteResponseComment = async (session, response, id) => {
    const data = await GradingSessionService.deleteComment(id)
    if (data) storeUpdatedResponse(session, { ...response, comment: {} })
  }

  return {
    state,
    storeUpdatedResponse,
    loadResponseScores,
    storeResponseScores,
    updateResponseScores,
    updateResponseComment,
    deleteResponseComment,
  }
})
