import debug from 'debug'
import { reactive, toRefs } from 'vue'

const log = debug('app:vue_features/shared/composables/use_api_method')

function useApiMethod(apiMethod) {
  const request = reactive({
    done: false,
    loading: false,
    response: null,
    error: null,
  })

  let activeRequest = null

  function reset() {
    request.response = null
    request.loading = false
    request.error = null
    request.done = false
  }

  async function load(params) {
    if (activeRequest) {
      activeRequest.abort()
    }

    const thisRequest = new AbortController()
    activeRequest = thisRequest

    reset()

    try {
      request.loading = true

      const response = await apiMethod(params, { signal: thisRequest.signal })

      if (!response.ok) {
        throw new Error(`The server responded with an error status ${response.status}`)
      }

      const json = await response.json()

      if (activeRequest === thisRequest) {
        log('api response: %O', json)
        request.response = json
      }
    } catch (error) {
      log('api response failed: %s', error.message)
      if (activeRequest === thisRequest) {
        request.error = error.message
      }
    } finally {
      if (activeRequest === thisRequest) {
        request.done = true
        request.loading = false
        activeRequest = null
      }
    }
  }

  return { load, reset, ...toRefs(request) }
}

export default useApiMethod
