<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <LzModal :can-cancel="false" modal-id="card-form-modal" class="lzui-modal--xlarge">
    <!-- this is to handle the odd case where the card has an upload as well.  Without this, when CkEditor is loaded in the page,
         the styles will overwrite those of the picker.  This is a problem for the image upload as it relies on the filestack picker
         styles that are overwritten.  This fixes that by forcing Ckeditor styles to be included first, no matter what, so filestack
         styles can come later and keep their necessary preference. -->
    <div class="hidden">
      <Ckeditor
        content=""
        :options="{
          toolbar: 'richText',
          options: {
            resize_enabled: false,
            uiColor: '#fcf9dd',
          },
        }"
      />
    </div>
    <div class="card-editor">
      <template v-if="isLoadingCard">
        <div class="flex items-center justify-center" style="min-height: 540px">
          <LoadingSpinner size="large" />
        </div>
      </template>
      <template v-else>
        <Grid disable-default="max-w" class="mb-4">
          <div class="col-span-8">
            <div :class="{ error: errors.includes('required') || errors.includes('maxlength') }">
              <label for="title">
                {{ $t('lesson_plans.card_form_templates.title') }}
                <span v-if="errors.includes('required')">{{ $t('lesson_plans.card_form_templates.is_required') }}</span>
                <span v-if="errors.includes('maxlength')">{{
                  $t('lesson_plans.card_form_templates.is_too_long')
                }}</span>
              </label>
              <input id="title" v-model="cardInEdit.title" class="h3" type="text" name="title" required />
            </div>
            <p class="small help-block italic">{{ $t('lesson_plans.card_form_templates.title_help') }}</p>
          </div>
          <div class="col-span-4 flex items-center justify-center">
            <div v-if="allowAssignmentCards" class="card-editor__assignment mb-2">
              <label class="mb-0">
                <input v-model="isAssignmentCard" type="checkbox" />
                {{ $t('lesson_plans.card_form_templates.assignment_card') }}
              </label>
              <p class="help-block">{{ $t('lesson_plans.card_form_templates.assignment_card_help') }}</p>
            </div>
          </div>
        </Grid>
        <Grid disable-default="max-w" class="card">
          <div class="card__content lp-deck__card-editor col-span-8">
            <template v-if="hasType">
              <div
                :class="[
                  'card__replace-content',
                  { 'card__replace-content--preview': canShowResourcePreview || !contentInEdit.editable },
                ]"
              >
                <div
                  :data-type="contentInEdit.type.toLocaleLowerCase()"
                  :class="['card__content-wrapper', `font-${lessonPlan.textStyle}`]"
                >
                  <!-- FIXME: Need to adjust the change content button -->
                  <a
                    class="card-editor__remove-content hover:bg-accent-hover bg-accent -right-4 -top-4 flex min-w-min items-center rounded-full border-2 border-solid border-gray-300 p-0.5 text-sm transition-colors hover:text-white"
                    @click="clearContent"
                  >
                    <span class="right-3 text-xs font-semibold text-white transition-opacity">
                      {{ $t('lesson_plans.card_form_templates.change_content') }}
                    </span>
                    <LzIcon type="div" icon-color-class="text-white" path="icons/close" size="sm" />
                  </a>
                  <div class="card__resource">
                    <div class="card__resource-contents" style="overflow: inherit">
                      <h2 v-if="isTitleCard" class="lp-card__title-content">
                        {{ cardInEdit.title }}
                      </h2>
                      <div v-else class="card__resource-editor">
                        <div class="card__resource-container">
                          <ResourcePreview v-if="canShowResourcePreview" />
                          <div v-if="contentInEdit.editable || isEditingQuestion" class="card__resource-form">
                            <CardContentForm
                              :content="contentInEdit"
                              :card-type="cardInEdit.type"
                              :lesson-plan="lessonPlan"
                              :cards="cards"
                              :allow-lesson-plans="false"
                              :expanded-form="true"
                              :can-import-teaching-notes="true"
                              @update="updateEditedContent"
                              @update-card="updateEditedCard"
                            />
                          </div>
                          <div v-else class="card-editor__preview-content-wrapper">
                            <div class="card-editor__preview-content center p-6">
                              <button class="btn btn--large btn--disabled" disabled>
                                <LzTooltip
                                  :title="$t('lesson_plans.card_form_templates.content_uneditable_help')"
                                  type="span"
                                >
                                  {{ $t('lesson_plans.card_form_templates.content_uneditable') }}
                                </LzTooltip>
                              </button>
                              <p class="mt-4">{{ $t('index.content_uneditable') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VoiceoverUploader v-if="canHaveVoiceover" />
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="card__content-wrapper">
              <div class="card__resource">
                <div class="card__resource-contents">
                  <CardTypePicker
                    :available-types="$options.availableTypes"
                    icon-size="xl"
                    class="card-editor__content-types"
                    @pick-type="assignType"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card__notes relative col-span-4 flex overflow-visible">
            <div
              v-if="isContentAndNoteImport"
              class="card__notes-wrapper bg-base border-base flex items-center justify-center p-4"
            >
              <em class="bg-focus text-primary-700 px-8 py-4 text-center text-sm">
                {{ $t('lesson_plans.card_form_templates.teaching_notes_copied') }}
              </em>
            </div>
            <div v-else-if="cardInEdit.teachingNotes !== null" class="card__notes-wrapper overflow-visible">
              <a
                class="card-editor__remove-notes hover:bg-accent-hover bg-accent -right-4 -top-4 flex min-w-min items-center rounded-full border-2 border-solid border-gray-300 p-0.5 text-sm transition-colors hover:text-white"
                @click="clearNotesAndUrl"
              >
                <span class="right-3 text-xs font-semibold text-white transition-opacity">
                  {{ $t('lesson_plans.card_form_templates.clear_notes') }}
                </span>
                <LzIcon type="div" icon-color-class="text-white" path="icons/close" size="sm" />
              </a>
              <div class="card__notes-head">
                <div class="flex w-full items-center justify-between">
                  <span>{{ $t('lesson_plans.card_form_templates.teaching_notes') }}</span>
                  <button v-if="!editableNotes" class="btn btn--demoted btn--small" @click="cloneNotes">
                    {{ $t('lesson_plans.card_form_templates.copy_and_edit_notes') }}
                  </button>
                  <button
                    v-else-if="affectedTeachingNotes.length > 1"
                    class="btn btn--demoted btn--small"
                    @click="cloneNotes"
                  >
                    {{ $t('lesson_plans.card_form_templates.make_unique_notes') }}
                  </button>
                </div>
              </div>
              <div class="card__notes-body">
                <Ckeditor
                  v-if="editableNotes"
                  id="card_notes"
                  :content="notes || ''"
                  :resize-to-fill-parent="true"
                  :options="{
                    toolbar: 'richText',
                    options: {
                      resize_enabled: false,
                      uiColor: '#fcf9dd',
                    },
                  }"
                  class="lp-notes__textarea rich-textarea"
                  @change="updateTeachingNotes($event)"
                />
                <!-- notes sanitized in serializer -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-else v-mathjax class="p-2" v-html="notes" />
              </div>
            </div>
            <div v-else-if="importedNotes" class="card__notes-wrapper overflow-visible">
              <a class="card-editor__remove-notes" @click="clearImport">
                <span class="small">{{ $t('lesson_plans.card_form_templates.clear_imported_notes') }}</span>
                <LzIcon type="div" path="icons/close" />
              </a>
              <div class="card__notes-head">
                <div class="flex w-full items-center justify-between">
                  <span>{{ $t('lesson_plans.card_form_templates.teaching_notes') }}</span>
                  <button class="btn btn--demoted btn--small" @click="cloneNotes">
                    {{ $t('less_plans.card_form_templates.copy_and_edit_notes') }}
                  </button>
                </div>
              </div>
              <div class="card__notes-body p-2">
                <!-- notes sanitized in serializer -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="importedNotes.content" />
              </div>
            </div>
            <div v-else class="flex w-full">
              <div class="flex w-full flex-col">
                <template v-if="showImportingNotesForm">
                  <label class="w-full text-left">{{ $t('lesson_plans.card_form_templates.card_url') }}</label>
                  <input
                    id="teaching-note"
                    v-model="importNotesUrl"
                    :disabled="isImporting"
                    :placeholder="$t('lesson_plans.card_form_templates.card_url')"
                    class="w-full text-xs"
                    type="text"
                    name="teaching-notes"
                  />
                  <div class="mt-2 flex w-full items-center justify-end">
                    <button class="btn btn--demoted btn--small" @click="cancelImportNotes">Cancel</button>
                    <button
                      :disabled="isImporting || !importUrlIsCard"
                      class="btn btn--blue btn--small ml-2"
                      @click="importNotesFromUrl"
                    >
                      {{ $t('lesson_plans.card_form_templates.import') }}
                    </button>
                  </div>
                  <div v-if="importNotesUrl !== '' && !importUrlIsCard" class="mt-4 w-full text-center">
                    <p class="error--inline">
                      {{ $t('card_form_templates.import_error_url') }}
                      {{
                        $t('card_form_templates.url_id_extract_example', {
                          url: Routes.lesson_plan_url('12345-some-lesson', { card: 46431 }),
                        })
                      }}
                    </p>
                  </div>
                  <div v-else-if="importError" class="mt-4 w-full text-center">
                    <p class="error--inline">{{ $t('card_form_templates.import_error') }}</p>
                    <p class="error--inline">{{ $t('card_form_templates.import_error_sub') }}</p>
                  </div>
                </template>
                <template v-else>
                  <div class="w-full">
                    <ul>
                      <EditTeachingNotesItem
                        :name="$t('lesson_plans.card_form_templates.create_new_teaching_notes')"
                        icon-path="icons/plus-circle"
                        @click="addNotes"
                      />
                      <EditTeachingNotesItem
                        class="mt-2"
                        :name="$t('lesson_plans.card_form_templates.import_existing_teaching_notes')"
                        icon-path="icons/upload"
                        @click="showImportingNotesForm = true"
                      />
                      <li v-if="cardInEdit.originalNotes" class="flex items-center justify-center">
                        <a class="mt-4 cursor-pointer text-sm" @click="unclearNotes">{{
                          $t('lesson_plans.card_form_templates.nevermind')
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-span-8">
            <AffectedLessonPlans
              v-if="contentInEdit.editable && affectedLessonPlans.length > 1"
              :affected-lesson-plans="affectedLessonPlans"
              edit-type="resource"
            />
          </div>
          <div class="col-span-4">
            <AffectedLessonPlans
              v-if="editableNotes && affectedTeachingNotes.length > 1"
              :affected-lesson-plans="affectedTeachingNotes"
              edit-type="notes"
            />
          </div>
        </Grid>
        <LearnosityExtendedOptions
          v-if="isQuestion"
          :checkable-answers="checkableAnswers"
          @set-checkable-answers="setCheckableAnswers"
        />
      </template>
      <footer class="px-2.5 md:px-3.5">
        <Grid disable-default="max-w md:px" class="card-editor__save">
          <div class="col-span-8">
            <div v-if="hasErrors" id="error_explanation" class="error error--page my-0">
              <h2>{{ $t('errors.common.page_error_heading') }}</h2>
              <div v-if="hasHighlightedError">
                <p>{{ $t('errors.common.page_error_message_above') }}</p>
              </div>
              <div v-else-if="errors.includes('unknown')">
                <p>{{ $t('lesson_plans.failure_message_template.failure_details') }}</p>
              </div>
              <ul v-else class="list-none">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
          </div>
          <div class="col-span-4 flex justify-end space-x-2" style="height: fit-content">
            <button :disabled="isSavingApi" data-test="cancel-card-modal-btn" class="btn" @click="cancelEdit">
              {{ $t('common.cancel') }}
            </button>
            <button
              :disabled="isSavingApi || isLoadingCard"
              data-test="submit-card-modal-btn"
              class="btn btn--primary"
              @click="submit"
            >
              {{ $t('common.save') }}
            </button>
          </div>
        </Grid>
      </footer>
    </div>
  </LzModal>
</template>

<script>
import { LoadingSpinner, LzModal, LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import { computed, provide, ref } from 'vue'
import CardTypePicker from './CardTypePicker'
import CardContentForm from './CardContentForm'
import VoiceoverUploader from './VoiceoverUploader'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import ResourcePreview from './ResourcePreview'
import LearnosityExtendedOptions from 'vue_features/resources/learnosity_items/components/LearnosityExtendedOptions'
import Grid from 'vue_features/shared/components/ui/Grid'
import EditTeachingNotesItem from './EditTeachingNotesItem'
import { useCardTypes, useResourceTypes } from 'vue_features/shared/composables'
import AffectedLessonPlans from 'vue_features/shared/components/ui/AffectedLessonPlans'
import Routes from 'routes'

const HIGHLIGHTED_ERRORS = new Set(['required', 'maxlength', 'invalidExistingItemUrl'])

export default {
  name: 'CardEditorModal',
  availableTypes: [
    'Title',
    'Slide',
    'LearnosityItem',
    'Video',
    'Hyperlink',
    'Wiki',
    'Image',
    'Document',
    'Html',
    'Audio',
    'Existing',
  ],
  nonResourceTypes: ['Existing', 'Title', 'LessonPlan'],
  components: {
    AffectedLessonPlans,
    EditTeachingNotesItem,
    Grid,
    LearnosityExtendedOptions,
    LoadingSpinner,
    LzTooltip,
    LzIcon,
    Ckeditor,
    LzModal,
    CardTypePicker,
    CardContentForm,
    VoiceoverUploader,
    ResourcePreview,
  },
  setup() {
    const {
      cards,
      cardInEdit,
      contentInEdit,
      updateEditedContent,
      updateEditedCard,
      submit,
      cancelEdit,
      clearContent,
      clearNotes,
      unclearNotes,
      cloneNotes,
      clearImport,
      importNotes,
      addNotes,
      errors,
      hasErrors,
      learnosityConfig,
      isSavingApi,
      isEditingQuestion,
      isContentAndNoteImport,
      isLoadingCard,
    } = useCardDeckEditorStore()
    const { lessonPlan } = useLessonPlanStore()

    const showImportingNotesForm = ref(false)
    const isImporting = ref(false)
    const importNotesUrl = ref('')
    const importError = ref(false)
    const importedNotes = computed(() => {
      if (contentInEdit.value.teachingNotes && contentInEdit.value.teachingNotes.sourceCardId)
        return contentInEdit.value.teachingNotes
      return null
    })
    const hasType = computed(() => !!contentInEdit.value.type)
    const notes = computed(() => {
      return cardInEdit.value.teachingNotes ? cardInEdit.value.teachingNotes.content : null
    })
    const editableNotes = computed(() => {
      return !notes.value || cardInEdit.value.teachingNotes.editable
    })

    const allowAssignmentCards = computed(() => {
      return !(lessonPlan.value.lessonType === 'assessment') && (!lessonPlan.value.adaptation || isAssignmentCard.value)
    })
    const isAssignmentCard = computed({
      get: () => cardInEdit.value.contexts.includes('assignment'),
      set: (newValue) => {
        if (newValue) {
          cardInEdit.value.contexts.push('assignment')
        } else {
          const index = cardInEdit.value.contexts.indexOf('assignment')
          cardInEdit.value.contexts.splice(index, 1)
        }
      },
    })

    provide('learnosityConfig', learnosityConfig)

    const canShowResourcePreview = computed(() => {
      const resourceType = contentInEdit.value.type
      return (
        ['Image', 'Video', 'Document', 'GeogebraItem'].includes(resourceType) &&
        (contentInEdit.value.sourceFileUrl || contentInEdit.value.materialId) &&
        contentInEdit.value.editable
      )
    })

    const checkableAnswers = computed(() => cardInEdit.value.checkableAnswers)
    const setCheckableAnswers = (newCheckableAnswers) => {
      updateEditedCard({ checkableAnswers: newCheckableAnswers })
    }

    const { isTitleCard } = useCardTypes(cardInEdit)
    const canHaveVoiceover = computed(() => !isTitleCard.value)
    const { isQuestion } = useResourceTypes(contentInEdit)

    const affectedLessonPlans = computed(() => contentInEdit.value.lessonPlans || [])
    const affectedTeachingNotes = computed(() => cardInEdit.value.teachingNotes?.lessonPlans || [])

    const importUrlIsCard = computed(() => {
      if (importNotesUrl.value === '') return false

      return importNotesUrl.value.match(/^http.*\/lesson_plans\/.*card=\d+/)
    })

    const updateTeachingNotes = (content) => {
      const { teachingNotes } = cardInEdit.value

      updateEditedCard({
        teachingNotes: {
          ...(teachingNotes || {}),
          content,
        },
      })
    }

    const cancelImportNotes = () => {
      showImportingNotesForm.value = false
      importNotesUrl.value = ''
      importError.value = false
    }

    const importNotesFromUrl = async () => {
      importError.value = false
      isImporting.value = true
      try {
        await importNotes(importNotesUrl.value)
        importNotesUrl.value = ''
        showImportingNotesForm.value = false
      } catch {
        importError.value = true
      } finally {
        isImporting.value = false
      }
    }

    const clearNotesAndUrl = () => {
      clearNotes()
      importNotesUrl.value = ''
    }

    const hasHighlightedError = computed(() => errors.value.some((error) => HIGHLIGHTED_ERRORS.has(error)))

    return {
      Routes,
      importUrlIsCard,
      lessonPlan,
      errors,
      hasErrors,
      hasHighlightedError,
      allowAssignmentCards,
      cardInEdit,
      hasType,
      updateEditedContent,
      contentInEdit,
      updateEditedCard,
      submit,
      cancelEdit,
      notes,
      isAssignmentCard,
      clearContent,
      clearNotesAndUrl,
      unclearNotes,
      cloneNotes,
      clearImport,
      addNotes,
      canShowResourcePreview,
      cards,
      checkableAnswers,
      setCheckableAnswers,
      isSavingApi,
      isEditingQuestion,
      isTitleCard,
      canHaveVoiceover,
      isQuestion,
      affectedLessonPlans,
      affectedTeachingNotes,
      updateTeachingNotes,
      importNotesUrl,
      isImporting,
      showImportingNotesForm,
      importNotesFromUrl,
      cancelImportNotes,
      importedNotes,
      importNotes,
      editableNotes,
      isContentAndNoteImport,
      importError,
      isLoadingCard,
    }
  },
  methods: {
    assignType(contentType) {
      this.updateEditedContent({ type: contentType })

      const isResourceCard = !this.$options.nonResourceTypes.includes(contentType) || contentType === 'Existing'
      this.updateEditedCard({ type: isResourceCard ? 'ResourceCard' : `${contentType}Card` })
    },
  },
}
</script>

<style>
/* This should not be required, but using anything more recent than Learnosity 2022.1.LTS causes
  problems with the order in which styles are applied (likely due to load order), which allows the below
  setting to be overwritten.  We need this one to "win", so we can just apply our own styling to make sure this
  is honored while leaving Learnosity card content alone */
.card__notes-body .cke_chrome {
  visibility: inherit !important;
}
</style>
