<template>
  <ListDropdown
    id="crumb-more"
    ref="rootEl"
    data-test="crumb-more"
    :button-classes="['rounded-xl w-10 text-center btn--small']"
    :dropdown-classes="['w-auto max-w-none max-h-screen-64 overflow-y-auto']"
  >
    <template #button>
      <span>&hellip;</span>
    </template>

    <template #listItems>
      <li v-for="(item, index) in crumbs" :key="`more-crumb-${index}-${item.crumbId}`" class="block border-l-0">
        <PathCrumbItem :item="item" :index="indexFor(item)" @select="$emit('select', $event)" />
      </li>
    </template>
  </ListDropdown>
</template>

<script>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import PathCrumbItem from './PathCrumbItem'

export default {
  name: 'PathCrumbsMore',
  components: { ListDropdown, PathCrumbItem },
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { pathCrumbs } = usePathCrumbsStore()

    function indexFor(pathCrumb) {
      return pathCrumbs.value.indexOf(pathCrumb) - 1
    }
    return { indexFor }
  },
}
</script>
