<template>
  <div class="video-player min-w-1/2 w-full max-w-full">
    <div
      v-if="wistiaVideoId"
      v-observe-visibility="onVisibilityChange"
      data-test="video-wrapper"
      class="min-w-1/2 w-full max-w-full"
    >
      <div
        :id="videoContainerId"
        :class="`wistia_async_${wistiaVideoId}`"
        class="wistia_embed videoFoam=true wmode=transparent mx-auto my-0 h-full w-full"
      ></div>
    </div>
    <ProcessingMessage v-else />
  </div>
</template>

<script>
import { uniqueId } from 'lodash'
import { WistiaApi, LzAnalytics } from 'clients'
import { ProcessingMessage } from 'vue_features/resources'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'VideoPlayer',
  components: {
    ProcessingMessage,
  },
  inheritAttrs: false,

  props: {
    id: {
      type: Number,
      default: null,
    },
    wistiaVideoId: {
      type: String,
      default: '',
    },
    playedEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { shortDomain } = useCurrentCustomerStore()
    return { shortDomain }
  },
  computed: {
    videoContainerId() {
      return `wistia-${this.wistiaVideoId}-${uniqueId()}`
    },

    resourcePageUrl() {
      if (this.id) {
        return Routes.short_resource_url(this.id, { host: this.shortDomain })
      }
      return ''
    },
  },

  watch: {
    videoContainerId: {
      handler(videoContainerId) {
        WistiaApi.pushOntoQueue(videoContainerId, this.onPlay)
      },
      immediate: true,
    },
  },

  methods: {
    onVisibilityChange(isVisible) {
      if (!isVisible) WistiaApi.pauseAllVideos()
    },

    onPlay(wistiaVideo) {
      wistiaVideo.bind('play', () => {
        LzAnalytics.track('Resource Used', this.playedEvent)
        LzAnalytics.increment('watched videos', 1)

        return wistiaVideo.unbind
      })

      wistiaVideo.bind('play', () => WistiaApi.pauseOtherVideos(wistiaVideo))
    },
  },
}
</script>

<style>
.video-player .w-playbar-wrapper canvas {
  background-color: transparent;
}

/* Resolves a bug with Wistia toolbars flickering when scaled */
/* !important needed to override inline styles set by JavaScript */
.video-player .w-control-bar {
  opacity: 1 !important;
  transform: translate3d(0px, 0px, 0px);
}
</style>
