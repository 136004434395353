<template>
  <table class="table-opened condensed-list mb-0">
    <tr
      v-for="student in visibleItems"
      :key="student.id"
      class="clickable cursor-pointer"
      @click="$emit('add-student', student)"
    >
      <td class="no-fullstory">
        <!-- search results sanitized in LzIndexing::SearchResult -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="student.highlight.name ? student.highlight.name[0] : student.name" />
      </td>
      <td class="no-fullstory">
        <strong>{{ $t('common.label.username') }}</strong>
        <!-- search results sanitized in LzIndexing::SearchResult -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="student.highlight.username ? student.highlight.username[0] : student.username" />
      </td>
    </tr>
    <tr v-if="canLoadMore">
      <td colspan="2">
        <LoadMoreButton v-bind="{ isLoading }" @click="showMoreItems(handleLoadMore)" />
      </td>
    </tr>
  </table>
</template>

<script>
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { toRef } from 'vue'

export default {
  components: { LoadMoreButton },
  props: {
    searchedStudents: {
      type: Array,
      required: true,
    },
    searchTotal: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: toRef(props, 'searchedStudents'),
      totalCount: toRef(props, 'searchTotal'),
    })

    return {
      visibleItems,
      showMoreItems,
      canLoadMore,
      isLoading,
    }
  },
}
</script>
