<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <DetailListItem :title="integration.name" :show-thumbnail="false" icon="integration" data-test="lms-integration-item">
    <template #actions>
      <a name="delete" class="btn btn--small caution mr-1" @click.prevent="$emit('delete', integration.id)">
        {{ $t('delete') }}
      </a>
    </template>
    <template #metadata>
      <template v-if="integration.isCanvas">
        <ul class="list-metadata text-sm">
          <li>
            <strong>{{ $t('canvas_host') }}</strong>
            {{ integration.externalHost }}
          </li>
          <li>
            <strong>{{ $t('canvas_client_id') }}</strong>
            {{ integration.externalClientId }}
          </li>
          <li class="mb-6">
            <strong>{{ $t('canvas_secret') }}</strong>
            {{ integration.externalSecret }}
          </li>
        </ul>
      </template>
      <ul class="list-metadata text-sm">
        <li>
          <strong>{{ $t('app_id') }}</strong>
          {{ integration.appId }}
        </li>
        <li>
          <strong>{{ $t('app_secret') }}</strong>
          {{ integration.appSecret }}
        </li>
        <li>
          <strong>{{ configurationInfoTitle }}</strong>
          {{ configurationInfoUrl }}
        </li>
        <li>
          <strong>{{ $t('date_created') }}</strong>
          {{ createdAt }}
        </li>
      </ul>
    </template>
  </DetailListItem>
</template>

<script>
import dayjs from 'dayjs'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import Routes from 'routes'

export default {
  name: 'LMSIntegrationListItem',
  components: {
    DetailListItem,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      return dayjs(this.integration.createdAt).format('MMM D, YYYY @k:mmA UTC')
    },
    configurationInfoTitle() {
      if (this.integration.isCanvas) {
        return this.$t('configuration_url')
      } else {
        return this.$t('lti_launch_url')
      }
    },
    configurationInfoUrl() {
      if (this.integration.isCanvas) {
        return Routes.lti_config_url('canvas', { format: 'xml' })
      } else {
        return Routes.auth_callback_url({ provider: 'lti' })
      }
    },
  },
}
</script>
