import { ref, computed } from 'vue'
import { HTTP, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import Routes from 'routes'
import useSidekiqPoller from 'vue_features/shared/composables/use_sidekiq_poller'

export default function useCopyLessonPlan(original, modalId, authorizedAsAuthor) {
  const copyType = ref(authorizedAsAuthor.value ? 'linked' : 'personal')
  const copyId = ref(null)
  const startMethod = (params) => {
    const url = Routes.lesson_plan_lesson_plan_copies_url(original.id, snakeKeys(params))
    return HTTP.create(url)
  }
  const updateUrl = (bid) => Routes.lesson_plan_copy_path(copyId.value, { bid })
  const { startSidekiqBatch, pollerActive, bid, percent, isComplete, updatePercent } = useSidekiqPoller(
    startMethod,
    updateUrl,
  )

  const startCopy = async (params) => {
    const response = await startSidekiqBatch(params)
    copyId.value = response.copyId
    percent.value = copyType.value === 'linked' ? 100 : 20
  }

  const copyUrl = computed(() => {
    return copyId.value ? Routes.lesson_plan_path(copyId.value) : ''
  })

  const cancel = () => {
    if (copyId.value && !isComplete.value) {
      HTTP.delete(Routes.lesson_plan_url(copyId.value))
    }
    closeModal(modalId)
  }

  return { pollerActive, copyType, bid, percent, isComplete, copyId, startCopy, updatePercent, copyUrl, cancel }
}
