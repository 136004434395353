<template>
  <div>
    <slot name="presets" v-bind="state">
      <div>
        <DateRangePresetSelect :label="$t('common.date_range')">
          <DateRangePreset
            v-for="preset in defaultPresets"
            :key="`date-range-preset-${preset.numDays}`"
            :start="preset.start"
            :end="preset.end"
            :name="`${isNumeric(preset.numDays) && 'last_'}${preset.numDays}`"
          >
            {{ isNumeric(preset.numDays) ? $t('common.last_n_days', { num: preset.numDays }) : $t(preset.numDays) }}
          </DateRangePreset>
        </DateRangePresetSelect>
      </div>
    </slot>

    <slot name="calendars" v-bind="state">
      <div class="flex items-center justify-between space-x-2">
        <DateRangeCalendar type="start" :max-date="maxDate" inline hide-input :label="$t('common.start_date')" />
        <DateRangeCalendar type="end" :max-date="maxDate" inline hide-input :label="$t('common.end_date')" />
      </div>
    </slot>
  </div>
</template>

<script>
import { computed, reactive, provide, ref } from 'vue'
import DateRangePreset from './DateRangePreset'
import DateRangePresetSelect, { getDefaultPresets } from './DateRangePresetSelect'
import DateRangeCalendar from './DateRangeCalendar'
import { filter, isNumeric } from 'utils'
import dayjs from 'dayjs'

export default {
  name: 'DateRangeSelect',
  components: { DateRangeCalendar, DateRangePresetSelect, DateRangePreset },
  props: {
    maxDate: {
      type: Date,
      default: () => new Date(),
    },
    value: {
      default: () => ({ start: new Date(), end: new Date() }),
      validator: (val) => typeof val === 'object' && Object.values(val).every((v) => dayjs(v).isValid()),
    },
  },
  setup(props, { emit }) {
    const defaultPresets = ref(getDefaultPresets())

    const updateDateRange = ({ start, end }) => {
      const update = { start, end }

      if (start === undefined && end < dateRange.value.start) {
        update.start = end
      }

      if (end === undefined && start > dateRange.value.end) {
        update.end = start
      }

      const newDateRange = {
        ...state.dateRange,
        ...filter(update, Boolean),
      }

      emit('update:modelValue', newDateRange)
    }

    const dateRange = computed(() => props.value)

    const state = reactive({
      dateRange,
      updateDateRange,
    })

    provide('dateRangeSelectState', state)

    return {
      state,
      defaultPresets,
      isNumeric,
    }
  },
}
</script>
