<template>
  <div class="relative flex items-center">
    <button
      type="button"
      class="group relative h-12 w-12"
      :tabindex="tabindex"
      :data-test="`${tool}-button${isSelected ? '-selected' : ''}`"
      @click="handleClick"
    >
      <InlineSvg
        type="div"
        :path="`icons/annotate-${tool}`"
        class="h-8 transform transition-transform duration-500"
        :class="{ 'translate-x-0': isSelected, '-translate-x-10 group-hover:-translate-x-8': !isSelected }"
        svg-classes="m-0"
      />
      <InlineSvg
        v-if="isSlotUsed('actions')"
        type="div"
        path="icons/arrow-popout"
        class="absolute right-0 top-1/2 h-2.5 w-2 -translate-y-1/2 transform transition-transform duration-500"
        :class="{ 'translate-x-10': isSelected, 'translate-x-0 group-hover:translate-x-2': !isSelected }"
        svg-classes="m-0"
      />
      <AnnotationToolTip :text="$t(`annotation.${tool}`)" />
    </button>

    <div v-if="isSlotUsed('actions') && isOpen">
      <button class="fixed left-0 top-0 z-10 h-full w-full bg-transparent" @click="isOpen = false"></button>

      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'
import AnnotationToolTip from 'vue_features/shared/components/AnnotationToolTip'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'
import { useAnnotationToolStore } from 'vue_features/shared/store/composables/use_annotation_tool_store'

export default {
  name: 'AnnotationToolButton',

  components: {
    AnnotationToolTip,
    InlineSvg,
  },

  props: {
    tool: {
      type: String,
      required: true,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit, slots }) {
    const isOpen = ref(false)

    const { mode } = useAnnotationToolStore()

    const isSelected = computed(() => mode.value == props.tool)

    function handleClick() {
      if (slots.actions && isSelected.value) {
        isOpen.value = !isOpen.value
      }

      emit('selected', props.tool)
    }

    watch(isSelected, (value) => {
      if (!value) {
        isOpen.value = false
      }
    })

    return { mode, isSelected, handleClick, isOpen, isSlotUsed }
  },
}
</script>
