<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <RouterLink v-slot="{ navigate, href }" :to="to" custom active-class="active">
    <li :class="{ active: isActive }">
      <a v-track-click.once="trackingData" :href="href" @keyup.enter="go(navigate)" @click.prevent="go(navigate)">{{
        link.label
      }}</a>
    </li>
  </RouterLink>
</template>

<script>
import { trackClick } from 'vue_features/shared/directives'
import { computed } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

export default {
  name: 'RoutingLink',
  components: { RouterLink },
  directives: { trackClick },
  props: {
    link: { type: Object, required: true },
    isActive: { type: Boolean, required: true },
  },
  setup(props) {
    const route = useRoute()
    const to = computed(() => {
      return { ...props.link, query: route.query }
    })

    // FIXME: links weren't navigating without this proxy function
    // this shouldn't be necessary; remove as vue router gets updated?
    const go = (nav) => nav()

    return { to, go }
  },
  computed: {
    trackingData() {
      const { eventName, eventProperties } = this.link
      return { eventName, eventProperties }
    },
  },
}
</script>
