<script setup>
import { ref } from 'vue'
import { LzButton, LzIcon } from 'vue_features/shared/components/ui'

const props = defineProps({
  modelValue: { type: File, default: null },
})
const emit = defineEmits(['update:modelValue'])

const dropStyle = ref('')
const file = ref(props.modelValue)
const fileInputEl = ref(null)

const addDropStyle = () => (dropStyle.value = true)
const removeDropStyle = () => (dropStyle.value = false)

function setFile(f) {
  file.value = f
  emit('update:modelValue', f)
}

function dropFile(e) {
  removeDropStyle()
  setFile(e.dataTransfer.files[0])
}

function chooseFile() {
  fileInputEl.value.click()
}

function fileChanged(e) {
  const file = e.target.files[0]
  if (file) {
    setFile(file)
  }
}

function clearFile() {
  setFile(null)
}
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <div
    class="flex flex-col items-center p-8"
    :class="{ dragover: dropStyle, 'bg-inverted text-inverted': !!file }"
    @dragenter.stop.prevent="addDropStyle"
    @dragover.stop.prevent="addDropStyle"
    @dragleave.stop.prevent="removeDropStyle"
    @drop.stop.prevent="dropFile"
  >
    <template v-if="!file">
      <span>{{ $t('file_instructions') }}</span>
      <input
        ref="fileInputEl"
        aria-label="file"
        type="file"
        name="file"
        style="display: none"
        data-test="input-file"
        @change="fileChanged"
      />
      <LzButton color="primary" data-test="select-file-button" @click.stop.prevent="chooseFile">
        {{ $t('select_file') }}
      </LzButton>
    </template>
    <template v-if="file">
      <div class="bold text-inverted text-sm">{{ $t('import.ready_title') }}</div>
      <div class="text-sm">{{ file.name }}</div>
      <LzButton size="small" :aria-label="$t('deselect_file')" data-test="deselect-file-button" @click="clearFile">
        <LzIcon size="sm" path="icons/close" />
        {{ $t('deselect_file') }}
      </LzButton>
    </template>
  </div>
</template>
