<template>
  <div
    :class="['border-base max-w-1/2 rounded border p-2 text-left shadow', code, failedValidation ? 'error' : null]"
    style="width: 100%"
  >
    <div class="language-name">{{ name }}{{ failedValidation ? ` - ${$t('failed_validation')}` : '' }}</div>
    <FilestackUpload
      v-slot="{ launch }"
      :accept="$options.acceptedExtensions"
      data-test="filestack-upload"
      @upload-done="setCaptionFile"
    >
      <input
        type="hidden"
        class="video-captions-language-code"
        name="video[video_caption_params][][language_code]"
        :value="code"
      />
      <input
        type="hidden"
        class="video-captions-delete"
        name="video[video_caption_params][][delete]"
        :value="isMarkedForDeletion"
      />
      <span v-if="isMarkedForDeletion">
        <input type="hidden" class="video-captions-file_url" name="video[video_caption_params][][file_url]" value="" />
        <span class="captions-will-be-deleted-text">{{ $t('captions_will_be_deleted') }}</span>
        <button class="btn btn--small cancel-button" @click.prevent="unmarkCaptionForDeletion">
          {{ $t('cancel') }}
        </button>
      </span>
      <span v-else-if="fileUrl.length > 0">
        <input
          type="hidden"
          class="video-captions-file_url"
          name="video[video_caption_params][][file_url]"
          :value="fileUrl"
        />
        <span class="captions-will-be-text">
          {{ isReplaceCaptions ? $t('captions_will_be_replaced') : $t('captions_will_be_added') }}
        </span>
        <button class="btn btn--small cancel-button" @click.prevent="removeFileUrl">{{ $t('cancel') }}</button>
      </span>
      <span v-else>
        <input
          type="hidden"
          class="video-captions-file_url"
          name="video[video_caption_params][][file_url]"
          :value="fileUrl"
        />
        <span class="file-upload__buttons">
          <span v-if="isReplaceCaptions">
            <button
              class="btn btn--small file-upload__remove btn-delete-captions"
              @click.prevent="markCaptionForDeletion"
            >
              <LzIcon size="sm" path="icons/close" />
              <span>{{ $t('remove_captions') }}</span>
            </button>
          </span>
          <button class="btn btn--small file-upload__button" @click.prevent="launch">
            <LzIcon size="sm" path="icons/upload" />
            <span class="file-upload-text">
              {{ isReplaceCaptions ? $t('replace_captions') : $t('add_captions') }}
            </span>
          </button>
        </span>
      </span>
    </FilestackUpload>
  </div>
</template>

<script>
import { config } from 'utils'
import FilestackUpload from 'vue_features/shared/components/filestack_upload/FilestackUpload'
import { LzIcon } from 'vue_features/shared/components/ui'
import { ref, watch } from 'vue'

const { accept: acceptedExtensions } = config.videoCaption

export default {
  acceptedExtensions,
  components: { FilestackUpload, LzIcon },
  props: {
    code: { type: String, required: true },
    name: { type: String, required: true },
    isReplaceCaptions: { type: Boolean, required: true },
    failedValidation: { type: Boolean, required: true },
    fileUrlFromParams: { type: String, required: true },
    deleteFromParams: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const isMarkedForDeletion = ref(props.deleteFromParams)
    const fileUrl = ref(props.fileUrlFromParams)

    watch([isMarkedForDeletion, fileUrl], () => {
      emit('update', { languageCode: props.code, delete: isMarkedForDeletion.value, fileUrl: fileUrl.value })
    })

    return { isMarkedForDeletion, fileUrl }
  },
  methods: {
    setCaptionFile({ baseUrl }) {
      this.fileUrl = baseUrl
    },
    markCaptionForDeletion() {
      this.isMarkedForDeletion = true
    },
    unmarkCaptionForDeletion() {
      this.isMarkedForDeletion = false
    },
    removeFileUrl() {
      this.fileUrl = ''
    },
  },
}
</script>
