import { round } from 'lodash'
import { computed } from 'vue'
import { modelGridStyle } from './use_content_parsers'
import { ROWS, COLUMNS } from './use_grid'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

const MARGIN = 7
const SLIDE_CSS = '.slide'

export default function useGutters(contents, resize) {
  const gutters = computed(() => {
    return contents.value.map((c) => {
      return {
        resized: 0,
        bottomStyle: modelGridStyle(c.row + c.rowSpan, c.column + 1, 2, c.colSpan),
        rightStyle: modelGridStyle(c.row + 1, c.column + c.colSpan, c.rowSpan, 2),
        showBottom: c.row + c.rowSpan !== ROWS,
        showRight: c.column + c.colSpan !== COLUMNS,
        content: c,
      }
    })
  })

  let moveListener
  const { on: documentOn, off: documentOff } = useNamespacedEventListener(window.document)

  function startResize(gutter, direction, event) {
    event.preventDefault()
    setDirection(gutter, event.target, direction)
    gutter.resized = 0
    documentOn('mouseup.stopResize, touchend.stopResize, touchcancel.stopResize', stopResize)

    moveListener = move.bind(null, gutter)
    documentOn('mousemove.moveListener, touchmove.moveListener', moveListener)
  }

  function move(gutter, event) {
    event.preventDefault()

    const resizeAmount = round((event[gutter.page] - gutter.start) / gutter.gridSize - gutter.resized)
    if (resizeAmount !== 0) {
      gutter.resized += resize(gutter.content, resizeAmount, gutter.direction)
    }
  }

  function stopResize() {
    documentOff('mouseup.stopResize, touchend.stopResize, touchcancel.stopResize')
    documentOff('mousemove.moveListener, touchmove.moveListener')
  }

  function setDirection(gutter, element, direction) {
    gutter.direction = direction
    const bounds = element.getBoundingClientRect()
    const offset = { top: bounds.top + window.pageYOffset, left: bounds.left + window.pageXOffset }
    const slide = document.querySelector(SLIDE_CSS)
    if (direction === 'bottom') {
      gutter.start = offset.top + MARGIN
      gutter.gridSize = slide.clientHeight / ROWS
      gutter.page = 'pageY'
    } else {
      gutter.start = offset.left + MARGIN
      gutter.gridSize = slide.clientWidth / COLUMNS
      gutter.page = 'pageX'
    }
  }

  return { gutters, startResize }
}
