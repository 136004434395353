import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

export default {
  get({ collection, page }) {
    const url = Routes.site_admin_collection_collection_shares_path(collection.id)

    return HTTP.getJSON(url, snakeKeys({ page }))
  },

  accept(share) {
    return this.update({ share, status: 'accepted' })
  },

  destroy(share) {
    const url = Routes.site_admin_collection_collection_share_path(share.collectionId, share.id)

    return HTTP.delete(url)
  },

  reject(share) {
    return this.update({ share, status: 'rejected' })
  },

  update({ share, status }) {
    const url = Routes.site_admin_collection_collection_share_path(share.collectionId, share.id)
    return HTTP.put(url, snakeKeys({ collectionShares: { status: status } }))
  },
}
