import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const StandardsImportService = {
  import({ file, id }) {
    const formData = new FormData()
    const params = snakeKeys({ file, id })
    Object.entries(params).forEach(([key, value]) => value && formData.append(key, value))

    return HTTP.ajax(Routes.site_admin_standard_imports_path(), { method: 'POST', body: formData })
  },

  getStatus(id) {
    return HTTP.getJSON(Routes.site_admin_standard_import_path(id))
  },
}

export default StandardsImportService
