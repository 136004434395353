<template>
  <div>
    <PortalTarget name="root" :multiple="true" />

    <div class="z-1002 relative">
      <PortalTarget name="popovers" :multiple="true" />
    </div>
  </div>
</template>

<script setup>
import { PortalTarget } from 'portal-vue'
</script>
