<template>
  <div>
    <div v-if="loading">
      <LoadingTable :rows="skeletonRows" :columns="5" />
    </div>
    <div v-else-if="error">
      <Alert type="error" :title="$t('standards_notice_title')" :description="$t('standards_notice_body')" />
    </div>
    <div v-else-if="data">
      <ReportTable
        :data="data"
        :searchable-headers="['categoryName']"
        :sort-fields-as="sortableStandardsColumns"
        :cell-style="standardsTableCellStyle"
        :sticky-columns="1"
        :load-more-text="loadMoreText"
        sticky-header
        @row-click="handleRowClick"
      >
        <template #cell="{ colName, value }">
          <div v-if="colName === 'categoryName'">
            <span>{{ value }}</span>
          </div>
          <div v-else-if="colName === 'categoryAverage' || isNumeric(value)">
            <ScoreWithIcon :score="value" />
          </div>
          <div v-else>
            {{ value }}
          </div>
        </template>
      </ReportTable>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import LoadingTable from './LoadingTable'
import ReportTable from './ReportTable'
import { sortCategoryName, standardsTableCellStyle } from '../utils'
import { Alert } from 'vue_features/shared/components/ui'
import ScoreWithIcon from './ScoreWithIcon'
import { isNumeric } from 'utils'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { useDataDashboardStore } from '../composables/data_dashboard_store'
import { snakeCase } from 'lodash'
import useSkeletonRows from '../composables/use_skeleton_rows'
import { useRouter } from 'vue-router'

export default {
  name: 'StandardsTable',
  components: {
    Alert,
    ReportTable,
    LoadingTable,
    ScoreWithIcon,
  },
  setup() {
    const root = inject('useRoot')()
    const { filters, standardsTableData, standardsTableStatus, updateFilters } = useDataDashboardStore()
    const router = useRouter()
    const handleRowClick = (row) => {
      if (filters.value.student) {
        window.open(Routes.lz_code_path({ id: row.categoryId }))
      } else {
        updateFilters(router, { [standardsTableData.value.drillDownCategory]: row.categoryId })
      }
    }

    const loading = computed(() => standardsTableStatus.value.loading)
    const error = computed(() => standardsTableStatus.value.error)
    const sortableStandardsColumns = computed(() => {
      if (!standardsTableData.value) return {}
      return standardsTableData.value.columns
        .map(({ field }) => field)
        .reduce((out, fieldName) => {
          out[fieldName] = sortCategoryName
          return out
        }, {})
    })
    const skeletonRows = useSkeletonRows(standardsTableData)

    const loadMoreText = computed(() => {
      const loadMore = root.$t('common.load_more')
      const category = root.$t(`${snakeCase(standardsTableData.value.drillDownCategory)}_plural`)
      return `${loadMore} ${category}`
    })

    return {
      loading,
      error,
      data: standardsTableData,
      skeletonRows,
      handleRowClick,
      sortableStandardsColumns,
      standardsTableCellStyle,
      isNumeric,
      loadMoreText,
    }
  },
}
</script>
