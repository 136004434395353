<template>
  <Grid class="mx-auto pb-6">
    <div class="col-span-12 md:col-span-9">
      <h1>
        {{ $t('contact') }}
      </h1>
      <h4 class="mt-0">
        {{ $t('send_a_note') }}
      </h4>
      <ContactForm :customer-subdomain="subdomain" :name="name" :email="email" />
      <div class="contact-detail small">{{ LZ_MAIL_ADDRESS }}</div>
    </div>
  </Grid>
</template>

<script>
import ContactForm from 'vue_features/contact_form/components/ContactForm'
import { LZ_MAIL_ADDRESS } from 'rails_constants'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { Grid } from 'vue_features/shared/components/ui'

export default {
  name: 'ContactFormPage',
  components: { Grid, ContactForm },
  setup() {
    const { isPublicCustomer, subdomain } = useCurrentCustomerStore()
    const { name, email } = useCurrentUserStore()
    return { LZ_MAIL_ADDRESS, isPublicCustomer, subdomain, name, email }
  },
}
</script>
