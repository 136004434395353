<script setup>
import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import LzModal from 'vue_features/shared/components/ui/LzModal'
import LzButton from 'vue_features/shared/components/ui/LzButton'
import { ref, onMounted } from 'vue'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { formattedWhen, actionVerb, formattedWho } from 'vue_features/shared/helpers/papertrail_helper'
import { useCardDeckEditorStore } from 'vue_features/lesson_plans/edit/composables/use_card_deck_editor_store'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'
import { useRouter } from 'vue-router'

const props = defineProps({
  objectId: { type: [String, Number], required: true },
  objectType: { type: String, required: true },
  objectIsResource: { type: Boolean, required: true },
})

const initialLoad = ref(true)
const changes = ref([])
const total = ref(0)
let page = 1

const loadChanges = async () => {
  const response = await HTTP.getJSON(
    Routes.changelog_index_path(snakeKeys({ objectId: props.objectId, objectType: props.objectType, page })),
  )
  changes.value.push(...response.changes)
  total.value = response.meta.pagination.totalCount
  page = response.meta.pagination.nextPage
}

onMounted(async () => {
  await loadChanges()
  initialLoad.value = false
})

const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
  itemsRef: changes,
  totalCount: total,
  startCount: 5,
  moreCount: 5,
})

const handleLoadMore = () => {
  showMoreItems((done) => {
    loadChanges().then(done)
  })
}

const { slideshowCards, selectedCardId } = useCardDeckEditorStore()
const { additionalMaterials } = useAdditionalMaterialsStore()
const equivalentCard = (cards, change) => {
  if (change.itemType === 'Card') {
    return cards.value.find((card) => card.id === change.itemId)
  } else {
    return cards.value.find((card) => card.content?.id === change.itemId)
  }
}
const equivalentSlideShowCard = (change) => {
  return equivalentCard(slideshowCards, change)
}
const equivalentAdditionalMaterial = (change) => {
  return equivalentCard(additionalMaterials, change)
}
const hasEquivalentCard = (change) => !!equivalentAdditionalMaterial(change) || !!equivalentSlideShowCard(change)
const router = useRouter()
const goToCard = (change) => {
  const slideshowCard = equivalentSlideShowCard(change)
  if (slideshowCard) {
    selectedCardId.value = slideshowCard.id
    router.push({ name: 'lesson', query: { card: slideshowCard.id } })
  } else {
    router.push({ name: 'additional_materials' })
  }
}
</script>

<script>
import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, shared } from 'vue_features/shared/i18n'

const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: ['close', 'on', 'changelog'] }),
  pickLocaleNamespace(shared, 'updated_notification', {
    only: ['created', 'destroyed', 'edited', 'none_yet', 'card', 'resource'],
  }),
)
export default {
  i18n: { messages },
}
</script>

<template>
  <LzModal v-slot="{ close }" modal-id="changelog-modal" class="lzui-modal--medium">
    <div class="space-y-2 p-4 md:p-6">
      <h2 class="m-0">{{ $t('common.changelog') }}</h2>
      <LoadingSpinner v-if="initialLoad" />
      <template v-else>
        <div v-if="changes.length === 0" class="center pt-4">
          <LzIcon path="icons/clock" size="xl" class="text-primary" />
          <p class="text-primary font-bold">{{ $t('updated_notification.none_yet') }}</p>
        </div>
        <ul v-else class="space-y-4">
          <li
            v-for="change in visibleItems"
            :key="change.createdAt"
            data-test="change"
            class="bg-base border-neutral-400 flex items-center gap-4 rounded-xl border p-2 shadow md:p-4"
          >
            <LzIcon size="lg" path="icons/user" icon-color-class="text-primary" />
            <div class="flex flex-col gap-2">
              <p class="font-bold">{{ formattedWho(change.who, $t) }}</p>
              <p class="small">
                <template v-if="objectIsResource">
                  {{ $t(actionVerb(change.event)) }}
                </template>
                <template v-else>
                  <button
                    v-if="hasEquivalentCard(change)"
                    class="text-accent font-bold"
                    @click="() => goToCard(change) || close()"
                  >
                    {{ `${$t(`updated_notification.${change.itemType.toLocaleLowerCase()}`)}#${change.itemId}` }}
                  </button>
                  <span v-else class="text-muted font-bold">
                    {{ `${$t(`updated_notification.${change.itemType.toLocaleLowerCase()}`)}#${change.itemId}` }}
                  </span>
                  {{ $t(actionVerb(change.event)).toLocaleLowerCase() }}
                </template>
                {{ $t('common.on').toLocaleLowerCase() }} {{ formattedWhen(change.createdAt) }}
              </p>
            </div>
          </li>
          <li v-if="canLoadMore">
            <LoadMoreButton :is-loading="isLoading" @click="handleLoadMore" />
          </li>
        </ul>
      </template>
      <footer class="center">
        <LzButton @click="close">{{ $t('common.close') }}</LzButton>
      </footer>
    </div>
  </LzModal>
</template>
