<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/form-control-has-label
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="customers-index">
    <ProgressCover :visible="showCover" :message="$t('common.loading')" />
    <div class="page-head">
      <div class="page-head__wrapper">
        <div class="page-head__content">
          <h1>{{ $t('index.customers') }}</h1>
        </div>
        <div class="page-head__actions">
          <a :href="Routes.new_customer_path()" class="btn btn--primary">
            {{ $t('index.customer_create_link') }}
          </a>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="search-box my-6">
          <div class="form-group">
            <input
              id="search-query"
              v-model="query"
              :placeholder="$t('index.search_customers_placeholder')"
              type="text"
              label="query"
              class="form-control search-query"
              @keyup.enter="getPage(1)"
            />
          </div>
          <button
            id="site-admin-search"
            class="search-submit float-right focus:ring-2"
            :aria-label="$t('search')"
            @click="getPage(1)"
          >
            <LzIcon size="sm" icon-color-class="text-white" path="icons/search" class="mx-auto" />
          </button>
        </div>
      </div>
      <Grid class="mx-auto pb-6">
        <div id="search-filters" class="col-span-12 space-y-4 md:col-span-3">
          <FilterBox :label="$t('new.owner')">
            <select id="owner-select" v-model="selectedOwner" class="mt-4 text-base">
              <option v-for="owner in filterableOwners" :key="owner.id" :value="owner.id" class="text-sm">
                {{ startCase(owner.name) }}
              </option>
            </select>
          </FilterBox>
          <FilterBox :label="$t('new.site_type')">
            <span v-for="siteType in filterableSiteTypes" :key="siteType.value" class="checkbox">
              <label :label="filterLabelFor('siteTypes', siteType)" type="checkbox">
                <input
                  :id="`${siteType}-toggle`"
                  :value="siteType"
                  :checked="filteredSiteTypes.includes(siteType)"
                  class="check_boxes"
                  type="checkbox"
                  @click="toggleFilter('siteTypes', siteType)"
                />
                {{ filterLabelFor('siteTypes', siteType) }}
              </label>
            </span>
          </FilterBox>
          <FilterBox :label="$t('new.customer_type')">
            <span v-for="customerType in filterableCustomerTypes" :key="customerType.value" class="checkbox">
              <label :label="filterLabelFor('customerTypes', customerType)" type="checkbox">
                <input
                  :id="`${customerType}-toggle`"
                  :value="customerType"
                  :checked="filteredCustomerTypes.includes(customerType)"
                  class="check_boxes"
                  type="checkbox"
                  @click="toggleFilter('customerTypes', customerType)"
                />
                {{ filterLabelFor('customerTypes', customerType) }}
              </label>
            </span>
          </FilterBox>
          <FilterBox :label="$t('new.clever')">
            <span v-for="cleverType in filterableCleverTypes" :key="cleverType" class="checkbox">
              <label :label="filterLabelFor('cleverTypes', cleverType)" type="checkbox">
                <input
                  :id="`clever-${cleverType}-toggle`"
                  :value="cleverType"
                  :checked="filteredCleverTypes.includes(cleverType)"
                  class="check_boxes"
                  type="checkbox"
                  @click="toggleFilter('cleverTypes', cleverType)"
                />
                {{ filterLabelFor('cleverTypes', cleverType) }}
              </label>
            </span>
          </FilterBox>
        </div>
        <div id="search-results-container" class="col-span-12 md:col-span-9">
          <div v-if="hasFilters" id="activeFilters">
            <ul class="flex flex-wrap items-center gap-1">
              <li
                v-for="filter in currentFilters"
                :key="filter.value"
                class="filter-query bg-focus border-base inline-flex max-w-max cursor-default items-center space-x-1 rounded-2xl border py-0.5 pl-2 pr-0.5 text-sm font-bold leading-5"
              >
                {{ filterLabelFor(filter.type, filter.value) }}
                <button
                  class="hover:bg-base text-neutral-600 ml-1 flex h-6 w-6 cursor-pointer items-center rounded-full leading-6 transition-colors hover:text-red-400"
                  @click="toggleFilter(filter.type, filter.value)"
                >
                  <LzIcon path="icons/x--small" />
                </button>
              </li>
              <li
                v-if="currentSearch.query"
                class="filter-query bg-focus border-base inline-flex max-w-max cursor-default items-center space-x-1 rounded-2xl border py-0.5 pl-2 pr-0.5 text-sm font-bold leading-5"
              >
                {{ currentSearch.query }}
                <button
                  class="hover:bg-base text-neutral-600 ml-1 flex h-6 w-6 cursor-pointer items-center rounded-full leading-6 transition-colors hover:text-red-400"
                  @click="clearQuery"
                >
                  <LzIcon path="icons/x--small" />
                </button>
              </li>
              <li>
                <a id="clear-selection" class="small" @click="clearSearch">
                  {{ $t('index.clear_selections') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="mb-4">
            <i18n-t v-if="customers.length" tag="p" keypath="index.customers_display_count" scope="global">
              <template #range>
                <strong>{{ range }}</strong>
              </template>
              <template #total>
                <strong>{{ pagination.totalCount }}</strong>
              </template>
              <template #query>
                <strong>"{{ currentSearch.query }}"</strong>
              </template>
            </i18n-t>
            <i18n-t v-else tag="p" keypath="index.no_customers_display_count" scope="global">
              <template #count>
                <strong>0</strong>
              </template>
              <template #query>
                <strong>"{{ currentSearch.query }}"</strong>
              </template>
            </i18n-t>
          </div>
          <CustomerList :customers="customers" />
          <div class="my-6">
            <LzPaginator v-bind="pagination" @page-changed="getPage" />
          </div>
        </div>
      </Grid>
    </div>
  </div>
</template>

<script>
import { paginationHelpers } from 'utils'
import { LzIcon, LzPaginator, ProgressCover } from 'vue_features/shared/components/ui'
import { startCase, upperFirst, camelCase, snakeCase } from 'lodash'
import CustomerList from './CustomerList'
import { captureException } from 'clients/sentry'
import { useCustomersIndexStore } from '../store/use_customers_index_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Grid from 'vue_features/shared/components/ui/Grid'
import FilterBox from 'vue_features/shared/components/search/FilterBox'
import Routes from 'routes'

export default {
  name: 'CustomersSearch',
  components: { FilterBox, Grid, CustomerList, LzPaginator, LzIcon, ProgressCover },
  props: {
    currentSearch: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      customersData,
      filterableOwners,
      filterableSiteTypes,
      filterableCustomerTypes,
      filterableCleverTypes,
      customers,
      getCustomers,
    } = useCustomersIndexStore()
    const { id: currentUserId } = useCurrentUserStore()
    return {
      Routes,
      customersData,
      filterableOwners,
      filterableSiteTypes,
      filterableCustomerTypes,
      filterableCleverTypes,
      customers,
      currentUserId,
      getCustomers,
    }
  },
  data() {
    return {
      selectedOwner: null,
      filteredSiteTypes: [...this.currentSearch.site_types],
      filteredCustomerTypes: [...this.currentSearch.customer_types],
      filteredCleverTypes: [...this.currentSearch.clever_types],
      query: this.currentSearch.query,
      showCover: false,
    }
  },
  computed: {
    currentFilters() {
      const filtersFor = (type) => this.currentSearch[type].map((value) => ({ type, value }))

      return [
        ...filtersFor('site_types') /* eslint-disable-line */,
        ...filtersFor('customer_types') /* eslint-disable-line */,
        ...filtersFor('clever_types') /* eslint-disable-line */,
      ]
    },
    pagination() {
      return this.customersData.meta.pagination
    },

    hasFilters() {
      return this.currentFilters.length > 0 || this.currentSearch.query.length
    },

    range() {
      return paginationHelpers.calculateRangeOnPage(this.customers, this.pagination)
    },
  },
  // FIXME: Investigate whether there is a better router pattern. Tests cause this watcher to fire after they are run.
  // Uncomment '/customers': '', line in karma.conf.js to see the warnings it causes.
  watch: {
    $route: {
      handler($route) {
        this.showCover = true
        const {
          query,
          owner,
          site_types /* eslint-disable-line */,
          customer_types /* eslint-disable-line */,
          clever_types /* eslint-disable-line */,
        } = this.currentSearch
        this.getCustomers({
          page: $route.query.page || 1,
          query,
          owner,
          site_types,
          customer_types,
          clever_types,
        })
          .then(() => {
            if (query) this.query = query
            this.filteredSiteTypes = [...site_types] /* eslint-disable-line */
            this.filteredCustomerTypes = [...customer_types] /* eslint-disable-line */
            this.filteredCleverTypes = [...clever_types] /* eslint-disable-line */
            this.showCover = false
          })
          .catch(captureException)
      },
    },
    selectedOwner() {
      this.getPage(1)
    },
  },
  created() {
    const { isFilterable, currentUserId, currentSearch, filterableOwners } = this
    if (isFilterable(currentSearch.owner)) {
      this.selectedOwner = currentSearch.owner
    } else if (isFilterable(currentUserId)) {
      this.selectedOwner = currentUserId
    } else {
      this.selectedOwner = filterableOwners[0].id
    }
  },
  methods: {
    startCase,

    isFilterable(userId) {
      return !!this.filterableOwners.find((owner) => owner.id == userId)
    },
    getPage(page) {
      const searchQuery = {
        page,
        owner: this.selectedOwner,
        site_types: this.filteredSiteTypes,
        customer_types: this.filteredCustomerTypes,
        clever_types: this.filteredCleverTypes,
      }
      if (this.query) searchQuery.query = this.query

      this.$router.push({ path: '/sites', query: searchQuery })
    },
    toggleFilter(type, value) {
      const filters = this[`filtered${upperFirst(camelCase(type))}`]
      if (filters.includes(value)) {
        filters.splice(filters.indexOf(value), 1)
      } else {
        filters.push(value)
      }
      this.getPage(1)
    },
    clearSearch() {
      this.filteredSiteTypes = []
      this.filteredCustomerTypes = []
      this.filteredCleverTypes = []
      this.clearQuery()
    },
    clearQuery() {
      this.query = ''
      this.getPage(1)
    },
    filterLabelFor(type, filter) {
      return this.$t(`new.${snakeCase(type)}.${filter}`)
    },
  },
}
</script>
