// A bug was introduced in ckeditor 4.14.0 related to setting font size via
// classes, and we can't upgrade until that bug is fixed (unless we switch to
// having ckeditor set font-size directly).
//
// See https://github.com/ckeditor/ckeditor4/issues/4555 and
// https://app.clubhouse.io/learnzillion/story/35983

import 'ckeditor4'
import 'ckeditor4/lang/en'
import 'ckeditor4/styles'
import './plugins'

import { imageUpload, linkUpload } from './upload'
import { MATHJAX_ASSET_URL, STATIC_HOST_URL } from 'environment'
import { customCopyStyles } from './mathjax_copy_styles'
import fixImageSrc from './fix_image_src'

// This tells Webpack to compile the contents.scss stylesheet, save it to public/packs, and return its public URL instead of
// loading it into the page with a link tag. We then pass the URL to CKEditor, which uses it to add a link tag to its editor
// iframe instead of the parent page.
import contentsCss from '../sass/ckeditor/contents.scss?publicUrl'

export function ckeditorDialogsClosed() {
  return !![...document.querySelectorAll('.cke_dialog[role="presentation"], .lz-cke_dialog')].filter(
    (dialog) => dialog.style.visibility !== 'hidden',
  )
}

export function onReady() {
  const mathjaxPlugin = window.CKEDITOR.plugins.get('mathjax')

  if (mathjaxPlugin) {
    window.CKEDITOR.plugins.setLang('mathjax', 'en', {
      ...mathjaxPlugin.langEntries.en,
      docUrl: 'https://demo.wiris.com/mathtype/en/developers.php#mathml-latex',
      docLabel: 'Click here for a visual TeX editor',
    })
  }

  window.CKEDITOR.on('dialogDefinition', (event) => {
    const dialogDefinition = event.data.definition
    const dialogName = event.data.name
    const uploadList = {
      image: imageUpload,
      link: linkUpload,
    }

    if (uploadList[dialogName]) {
      uploadList[dialogName](dialogDefinition)
    }
  })
}

function languageKeyConversion(language) {
  return {
    en: { scayt_sLang: 'en_US', language: 'en' },
  }[language]
}

const lzStandardsAllowedContent = {
  'lz-standards span': {
    classes: '!lz-standards',
  },
  'lz-standards span span': {
    attributes: '!title,!data-uuid',
    classes: '!lz-standard,has-tip,initiative-*',
  },
}

const commonPlugins = ['mathjax', 'justify', 'sharedspace']

function extraPlugins(...args) {
  return args.reduce((plugins, arg) => plugins.concat(arg), []).join(',')
}

function fixMathJaxFontScaling() {
  window.CKEDITOR.plugins.mathjax.copyStyles = customCopyStyles
}

const CustomCKEditor = {
  defaults: {
    autoParagraph: false,
    contentsCss: [contentsCss],
    coreStyles_bold: { element: 'b', overrides: 'strong' },
    coreStyles_italic: { element: 'i', overrides: 'em' },
    customConfig: '', // skip loading assets/config.js via ajax
    fontSize_sizes:
      '20 Small/fs-small;24 Normal/fs-normal;30 Large/fs-large;38 Extra large/fs-xlarge;49 XX-Large/fs-xxlarge;60 Title/fs-xxxlarge',
    fontSize_defaultLabel: '24 Normal',
    fontSize_style: {
      element: 'span',
      attributes: { class: '#(size)' },
      overrides: [
        {
          element: 'font',
          attributes: { size: null },
        },
      ],
    },
    language: 'en',
    // tells MathJax to also load an LZ-specific config
    mathJaxLib: MATHJAX_ASSET_URL + ',local/local-LZ',
    removeDialogTabs: 'image:Link;image:Upload;image:advanced;link:advanced;link:target',
    scayt_autoStartup: true,
    scayt_moreSuggestions: 'off',
    scayt_contextCommands: 'language',
    scayt_customerid: '1:AT0Aw-00alt-bRSgP2-vaP9H-B7i1g4-D5PW1-Ycy9B1-FbodE4-gpw9R1-M1g7i-M5jb1-aG1',
    scayt_disableOptionsStorage: 'all',
    scayt_inlineModeImmediateMarkup: true,
    scayt_sLang: 'en_US',
    scayt_srcUrl: `https://wsc.${STATIC_HOST_URL}/spellcheck3/lf/scayt3/ckscayt/ckscayt.js`,
    scayt_uiTabs: '1,1,0',
    scayt_elementsToIgnore: 'standards-coverage-table', // This appears broken w/ multiple elements using commas
  },

  configurations: {
    // We would like to be able to use the mathjax plugin here, so that
    // teachers can include math equations in their grading feedback comments.
    // But I haven't been able to get the math rendering to work when the
    // comment editor is initially hidden in a collapsed accordion, as the
    // question-level comment editors are in the assignment response report.
    //
    // We have code in app/javascript/clients/mathjax.js,
    // for rendering previously hidden math, but I wasn't able to get it
    // to work inside of CKEditor.
    //
    // See https://groups.google.com/g/mathjax-users/c/AHnbYmjs8cw.
    commentEditor: {
      extraPlugins: 'olstart,sharedspace',
      toolbar: [
        ['Bold', 'Italic', 'Underline', 'Strike', 'Superscript', 'Subscript'],
        ['Link', 'Unlink'],
        ['NumberedList', 'BulletedList', '-', 'Blockquote'],
        ['Image', 'Table', 'SpecialChar'],
        ['RemoveFormat'],
      ],
    },
    richText: {
      extraAllowedContent: {
        ...lzStandardsAllowedContent,
      },
      extraPlugins: 'mathjax,justify,lzstandards,olstart,sharedspace',
      toolbar: [
        ['Bold', 'Italic'],
        ['Link', 'Unlink'],
        ['JustifyLeft', 'JustifyCenter'],
        ['NumberedList', 'BulletedList'],
        ['Image', 'Mathjax', 'Lzstandards'],
      ],
    },
    htmlCard: {
      extraAllowedContent: {
        ...lzStandardsAllowedContent,
      },
      extraPlugins: extraPlugins(commonPlugins, 'lzstandards', 'olstart', 'sourcedialog'),
      format_tags: 'h2;h3;h4;p',
      toolbar: [
        '/',
        ['Format'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'Superscript', 'Subscript'],
        ['Link', 'Unlink', 'Anchor'],
        ['JustifyLeft', 'JustifyCenter'],
        ['NumberedList', 'BulletedList', '-', 'Blockquote'],
        ['Image', 'Table', 'HorizontalRule', 'SpecialChar', 'Mathjax', 'Lzstandards'],
        ['RemoveFormat'],
      ],
    },

    slide: {
      disallowedContent: 'br',
      extraAllowedContent: ['span(fs-*);placeholder'],
      extraPlugins: extraPlugins(commonPlugins, 'inputplaceholder,font,olstart'),
      removePlugins: 'tableselection', // randomly adds css to tables even if not using the plugin
      toolbar: [
        ['FontSize'],
        ['Bold', 'Italic', 'Underline'],
        ['JustifyLeft', 'JustifyCenter'],
        ['NumberedList', 'BulletedList'],
        ['Mathjax'],
        ['RemoveFormat'],
      ],
    },
  },

  replace(id, configuration, overrides) {
    const editor = window.CKEDITOR.replace(id, this.configurationToOptions(configuration, overrides))
    fixMathJaxFontScaling()
    fixImageSrc(editor)
    return editor
  },

  inline(id, configuration, overrides) {
    const editor = window.CKEDITOR.inline(id, this.configurationToOptions(configuration, overrides))
    fixMathJaxFontScaling()
    fixImageSrc(editor)
    return editor
  },

  destroy(editor) {
    // stops blur call on destroyed object: https://dev.ckeditor.com/ticket/16825
    editor.focusManager.blur(true)
    editor.destroy()
  },

  configurationToOptions(configuration, overrides) {
    return {
      ...this.defaults,
      ...this.configurations[configuration],
      ...overrides,
      ...languageKeyConversion(overrides.language),
    }
  },
}

export default CustomCKEditor
