<template>
  <component :is="type" ref="editor" :name="formFieldName" class="ck-editable" />
</template>

<script>
import Ckeditor from 'ckeditor_extensions/custom_ckeditor'
import fixToolbarHiding from 'ckeditor_extensions/fix_toolbar_hiding'
import { throttle } from 'lodash'

export default {
  name: 'Ckeditor',
  props: {
    options: { type: Object, required: true },
    content: { type: String, required: true },
    type: { type: String, default: 'textarea' },
    readOnly: { type: Boolean, required: false, default: false },
    formFieldName: { type: String, default: undefined },
    resizeToFillParent: { type: Boolean, default: false },
  },
  data() {
    return { editor: null }
  },
  watch: {
    readOnly() {
      this.editor.setReadOnly(this.readOnly)
    },
  },
  mounted() {
    const element = this.$refs.editor
    const { toolbar, options, inline, loadFailure } = this.options

    const args = [element, toolbar, options]
    this.editor = inline ? Ckeditor.inline(...args) : Ckeditor.replace(...args)

    if (!this.editor) {
      loadFailure()
    }

    this.$emit('created-editor', this.editor)

    this.editor.setData(this.content)
    this.editor.emitVueEvent = this.$emit.bind(this)
    this.editor.on(
      'change',
      throttle(() => {
        this.$emit('change', this.editor.getData()), 500, { leading: true, trailing: true }
      }),
    )

    this.editor.on('instanceReady', () => {
      if (this.readOnly) {
        this.editor.setReadOnly(true)
      }
      if (this.resizeToFillParent) {
        this.editor.resize('100%', element.parentElement.offsetHeight)
      }
    })
  },
  beforeUnmount() {
    Ckeditor.destroy(this.editor)
    fixToolbarHiding(this.options.options)
  },
}
</script>
