<template>
  <div class="mx-auto max-w-7xl p-2.5 md:p-3.5">
    <div id="who-viewed-this">
      <h5 v-if="!assignment.hasViews" data-test="no-views">
        {{ $t('who_viewed_this.no_views_yet', { lz_code: assignment.code }) }}
      </h5>
      <AsyncContentLoader v-else :loader="pageLoadRequest">
        <template v-if="hasStudentFunctionality">
          <table class="assignment-who-viewed-this icon-list mt-6 rounded-xl">
            <thead>
              <tr>
                <th class="w-5/12 p-4 md:w-1/3">{{ $t('who_viewed_this.name') }}</th>
                <th class="w-1/4 p-4 md:w-1/3">{{ $t('who_viewed_this.first_viewed') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="myStudent in myStudents"
                :key="myStudent.id"
                :data-test="`my-student${myStudent.id}`"
                class="even:bg-primary-50"
              >
                <td class="p-4">
                  <div class="flex items-center space-x-4">
                    <LzIcon
                      type="div"
                      size="lg"
                      path="icons/user"
                      icon-color-class="text-neutral-600"
                      class="flex items-center"
                    />
                    <div>
                      <div class="font-bold" data-test="description">{{ nameDescription(myStudent) }}</div>
                      <ul class="list-metadata">
                        <li class="text-muted space-x-1">
                          <span>{{ $t('common.label.username') }}</span>
                          <span class="font-bold">{{ myStudent.studentUsername }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td class="p-4 align-middle">
                  <span v-if="howLongAgo(myStudent)">{{ howLongAgo(myStudent) }}</span>
                  <span v-else>{{ $t('who_viewed_this.not_viewed') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </AsyncContentLoader>
      <LzPaginator v-bind="pagination" data-test="pagination" @page-changed="getStudentsByPage" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useAssignmentStore } from 'vue_features/assignments/show/composables'
import { useWhoViewedThisStore } from 'vue_features/assignments/show/composables/use_who_viewed_this_store'
import { LzIcon, LzPaginator, AsyncContentLoader } from 'vue_features/shared/components/ui'
import dayjs from 'dayjs'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'WhoViewedThisContent',
  components: { AsyncContentLoader, LzIcon, LzPaginator },
  setup() {
    const {
      state: whoViewedThisState,
      getStudentActivities,
      myStudents,
      setPagination,
      pagination,
    } = useWhoViewedThisStore()
    const { assignment, assignmentId, canManageAssignment } = useAssignmentStore()
    whoViewedThisState.assignmentId = assignmentId.value

    const pageLoadRequest = ref(null)
    function getStudentsByPage(page) {
      pageLoadRequest.value = getStudentActivities(page)
    }

    function howLongAgo(activity) {
      if (activity.viewedAt) {
        return dayjs(activity.viewedAt).fromNow()
      } else {
        return null
      }
    }

    function nameDescription(myStudent) {
      if (myStudent.studentName && myStudent.studentName.length > 25) {
        return myStudent.studentName.slice(0, 25) + '...'
      } else {
        return myStudent.studentName
      }
    }

    setPagination({ currentPage: 1, totalPages: 1 })
    getStudentsByPage(pagination.value.currentPage)

    const { hasStudentFunctionality } = useCurrentCustomerStore()

    return {
      assignment,
      getStudentsByPage,
      myStudents,
      canManageAssignment,
      howLongAgo,
      nameDescription,
      pagination,
      pageLoadRequest,
      hasStudentFunctionality,
    }
  },
}
</script>
