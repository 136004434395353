export const SUPPORTED_OSES = {
  Windows: { min: '10', max: '1000' },
  'OS X': { min: '10.15', max: '1000' },
  iOS: { min: '13', max: '1000' },
  Android: { min: '8', max: '1000' },
  'Chrome OS': true,
  Linux: true,
}

export const SUPPORTED_BROWSERS = {
  'Microsoft Edge': { min: '92', max: '1000' },
  Chrome: { min: '92', max: '1000' },
  Safari: { min: '13.1', max: '1000' },
}

export function requestJavascriptAsset(javascriptObject) {
  return new Promise((resolve, reject) => {
    window[javascriptObject] !== undefined ? resolve() : reject()
  })
}

export function viewportWithin(width, height) {
  return window.innerWidth > width && window.innerHeight > height
}

export function parseVersion(versionNumberString) {
  if (typeof versionNumberString !== 'string') {
    throw new Error('parseVersion() must receive a String')
  }
  const splitString = versionNumberString.split('.')
  const versions = {}
  versions.major = parseInt(splitString[0])

  if (splitString[1] !== undefined) {
    versions.minor = parseInt(splitString[1])
  }

  if (splitString[2] !== undefined) {
    versions.patch = parseInt(splitString[2])
  }

  if (Object.values(versions).includes(NaN)) {
    throw new Error('parseVersion() must receive a string in Semantic Versioning 2.0 format (XX.xx.xxx OR XX.xx OR XX)')
  }

  return versions
}

export function requestImageAsset(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = resolve
    img.onerror = reject
  })
}
