<!--
  eslint-disable vuejs-accessibility/no-onchange
  eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="mx-auto max-w-7xl px-3 md:px-6">
    <label class="small">
      {{ $t('role_select_instructions') }}
    </label>
    <div class="form-group">
      <select :value="modelValue" class="form-control" @change="$emit('update:modelValue', $event.target.value)">
        <option disabled="disabled" value="select">
          {{ $t('select_one') }}
        </option>
        <option :value="teacherSource">
          {{ $t('staff') }}
        </option>
        <option :value="studentSource">
          {{ $t('common.student') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTypeSelector',
  props: {
    teacherSource: {
      type: String,
      required: true,
    },
    studentSource: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
}
</script>
