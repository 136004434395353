import { ref, computed } from 'vue'
import { HTTP } from 'vue_features/shared/helpers/http_helper'

export default function useSidekiqPoller(startMethod, updateUrl) {
  let bid = null
  const percent = ref(0)
  const isComplete = ref(false)

  const startSidekiqBatch = async (params) => {
    const response = await startMethod(params)
    bid = response.bid
    percent.value = 20
    return response
  }

  const pollerActive = computed(() => {
    return percent.value >= 20 && percent.value < 100
  })

  const raisePercent = (newPercent) => {
    if (percent.value >= newPercent) {
      percent.value = Math.min(99, percent.value + 5)
    } else {
      percent.value = Math.min(99, newPercent) // only complete on complete = true
    }
  }

  const updatePercent = (done) => {
    HTTP.getJSON(updateUrl(bid)).then((json) => {
      if (json.complete) {
        percent.value = 100
      } else {
        raisePercent((json.percent || 0) * 100)
      }
      done()
    })
  }

  return { startSidekiqBatch, pollerActive, percent, isComplete, updatePercent, raisePercent }
}
