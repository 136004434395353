import { assign, castArray, flatMap, get, groupBy, isEmpty, map, mapValues, omitBy } from 'lodash'

import debug from 'debug'
const log = debug('app:clients/learnosity/learnosity_author_tags')

export default function useLearnosityAuthorTags(app) {
  const all = () => {
    const grouped = groupBy(app.getItemTags(), 'type')
    return mapValues(grouped, (tag) => map(tag, 'name'))
  }

  const ofType = (type) => {
    return get(all(), type, [])
  }

  const replace = (tags) => {
    const filtered = omitBy(tags, isEmpty)

    if (JSON.stringify(filtered) === JSON.stringify(all())) {
      return
    }

    // converts from V0 to V3 format:
    // https://docs.learnosity.com/authoring/author/knowledgebase/tag-formats
    const transformed = flatMap(filtered, (names, type) => {
      return map(castArray(names), (name) => {
        return { type: type, name: name }
      })
    })

    if (!app.setItemTags(transformed)) {
      log('LearnosityAuthorTags.replace failed given tags', transformed)
      throw new Error('LearnosityAuthorTags.replace failed unexpectedly updating tags')
    }
  }

  const update = (tags) => {
    replace(assign(all(), tags))
  }

  return { all, ofType, update, replace }
}
