<template>
  <div v-if="googleCourseIsValid" class="accordion__action assign-to-google-accordion-action" @click.stop>
    <p
      v-if="showAssignedState"
      data-test="assign-to-google-classroom-assigned"
      class="smaller mt-2 w-56 truncate text-right text-gray-700"
    >
      {{ $t('google_classroom.assigned_to_google', { course_name: courseName }) }}
    </p>
    <label
      v-else-if="showAssignPrompt"
      :for="`assign-to-google-${klassId}`"
      class="btn max-w-32 sm:max-w-48 z-20 -mt-1 flex items-center gap-2 px-3 py-1"
    >
      <input
        :id="`assign-to-google-${klassId}`"
        data-test="assign-to-google-classroom-assign-toggle"
        type="checkbox"
        :checked="assignToGoogle"
        :disabled="disablePrompt"
        class="switch-toggle__button mb-1 flex-none"
        @input="toggleAssignToGoogle"
      />
      <LzIcon size="sm" path="icons/google-signin" class="flex-none" />
      <span class="truncate text-sm">{{ courseName }}</span>
      <span v-if="archived" class="hidden flex-none sm:inline-block">({{ $t('common.archived') }})</span>
      <KlassUnlinkedIndicator
        v-if="showUnlinkedWarning"
        :klass-id="klassId"
        :unlinked-count="unlinkedCount"
        class="mt-1 flex-none"
      />
    </label>
  </div>
</template>

<script>
import { computed, onMounted, inject } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import KlassUnlinkedIndicator from 'vue_features/shared/components/google_classroom/KlassUnlinkedIndicator'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'AssignToGoogleClassroom',
  components: { LzIcon, KlassUnlinkedIndicator },
  props: {
    klassId: { type: Number, required: true },
    courseName: { type: String, default: '' },
    courseId: { type: String, default: '' },
    unlinkedCount: { type: Number, default: 0 },
    assignmentList: { type: Array, default: () => [] },
    archived: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { klassesToAssignToGoogle, assignToGoogleCourse } = useAssignmentPromptStore()
    const { coursesData, isConnectedToGoogle, loadCourseAssignments, courseAssignmentsData } = useGoogleClassroomStore()

    const showAssignedState = computed(
      () => props.courseId && !!courseAssignmentsData.value?.find((a) => a.courseId === props.courseId),
    )
    const disabledMessage = computed(() => {
      if (!props.disabled) return ''
      return root.$t(`google_classroom.cannot_assign_to_${props.archived ? 'archived' : 'unavailable'}`)
    })
    const assignToGoogle = computed(() => klassesToAssignToGoogle.value.includes(props.klassId))
    const toggleAssignToGoogle = () => {
      if (!props.disabled) assignToGoogleCourse({ klassId: props.klassId, assign: !assignToGoogle.value })
    }

    onMounted(() => {
      if (props.courseId) loadCourseAssignments({ lzCodeId: props.assignmentList })
    })

    return {
      showAssignedState,
      assignToGoogle,
      toggleAssignToGoogle,
      disabledMessage,
      googleCourseIsValid: computed(() => coursesData.value.find((c) => c.courseId === props.courseId)),
      showAssignPrompt: computed(() => props.courseId && isConnectedToGoogle.value),
      disablePrompt: computed(() => props.disabled || props.archived),
      showUnlinkedWarning: computed(() => props.courseId && props.unlinkedCount > 0),
    }
  },
}
</script>
