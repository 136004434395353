<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div id="choose-search">
    <label>{{ $t('lz_content_search.add_content') }}</label>
    <div class="max-w-7xl">
      <SearchBox v-model="tempQuery" :placeholder="$t('common.search_placeholder_short')" @submit="resetSearch" />
    </div>
    <SearchResults
      v-bind="{
        showHeader: true,
        hasActiveSearch,
        canLoadMore,
        isLoadingSearchResults,
        isLoading,
        searchResults,
        visibleItems,
        selectedItems: searchSelection,
        hiddenFilters,
      }"
      @load-more="handleLoadMore"
      @deselected-item="(itemIndex) => searchSelection.splice(itemIndex, 1)"
      @selected-item="(item) => searchSelection.push(item)"
    >
      <template #header-action>
        <div class="col-span-9 md:col-span-8">
          <button
            :disabled="items.length <= 0"
            class="btn btn--demoted btn--small my-2 ml-0 p-0"
            @click.prevent="$emit('manage-content')"
          >
            {{ items.length }}
            {{ $t('lz_content_modal.items_selected') }}
          </button>
        </div>
      </template>
    </SearchResults>
  </div>
</template>

<script>
import { capitalize, isEqual, snakeCase } from 'lodash'
import { watch, watchEffect } from 'vue'
import { useLzContentModalStore } from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import SearchBox from 'vue_features/shared/components/search/SearchBox'
import SearchResults from 'vue_features/shared/components/search/SearchResults'

export default {
  name: 'SearchForm',
  components: {
    SearchResults,
    SearchBox,
  },
  setup() {
    const { items, searchSelection, currentTab, contentType } = useLzContentModalStore()

    const {
      tempQuery,
      search,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      totalSearchCount,
      hasActiveSearch,
      modalFilters,
    } = useModalSearch()

    modalFilters.facets = true
    const updateSearch = () => {
      resetSearch()
      search()
    }

    const { filterParams, clearFilters, query } = useSearchFiltersStore()

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: searchResults,
      totalCount: totalSearchCount,
      startCount: 5,
      moreCount: 5,
    })

    const hiddenFilters = ['content_type']
    clearFilters()
    if (contentType.value === 'LessonPlan') {
      modalFilters.m = 'LessonPlan'
    } else {
      modalFilters.m = 'Resource'
      modalFilters['resource_type[]'] = [snakeCase(contentType.value)]
      hiddenFilters.push('subject', 'grades', 'standards')
    }

    const lastSearch = filterParams.value
    updateSearch()

    watch(filterParams, () => {
      if (!isEqual(filterParams.value, lastSearch.value)) {
        tempQuery.value = query.value // TODO: what?
        updateSearch()
      }
    })

    const getItemIndex = (item) => searchSelection.value.findIndex((i) => i.id === item.id)

    watchEffect(() => {
      if (currentTab.value === 'choose-search') {
        items.value = searchSelection.value
      }
    })
    const handleLoadMore = () => showMoreItems(search)

    return {
      items,
      tempQuery,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      getItemIndex,
      totalSearchCount,
      hasActiveSearch,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      handleLoadMore,
      capitalize,
      contentType,
      hiddenFilters,
      searchSelection,
    }
  },
}
</script>
