<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('klasses.show.add_teachers')"
    :modal-id="MODAL_NAME"
    class="lzui-modal--large"
    autoclose
  >
    <TeacherSearch :klass-id="klassId" @added-teachers="addedTeachers" @cancel="close" />
  </LzModal>
</template>

<script>
import LzModal from 'vue_features/shared/components/ui/LzModal'
import TeacherSearch from '../../../show/components/TeacherSearch'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

const MODAL_NAME = 'AddTeacherModal'

export default {
  name: MODAL_NAME,
  components: { LzModal, TeacherSearch },
  props: {
    klassId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    function addedTeachers(newTeachers) {
      emit('added-teachers', newTeachers)
      closeModal(MODAL_NAME)
    }

    return { addedTeachers, MODAL_NAME }
  },
}
</script>
