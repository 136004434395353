import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { klasses, common, components, studentKlasses } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(klasses, 'klasses'),
  namespaceLocaleObj(studentKlasses, 'student_klasses'),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(components, '', { only: ['confirm_modal'] }),
)
