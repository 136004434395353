<template>
  <div ref="rootEl" class="sparkline-bar flex flex-col justify-center">
    <svg :viewBox="viewBoxDimensions" :style="{ maxHeight: `${height}px` }">
      <g>
        <rect width="100%" :height="height" :rx="borderRadius" fill="#e6e6e6" />
        <rect
          class="chart transition-width duration-500"
          :width="barWidth"
          :height="height"
          :rx="borderRadius"
          :fill="fillColor"
        />
        <text class="sparkline-bar__label text-xs" x="10" :y="textY" :fill="labelColor" :style="textStyles">
          {{ label }}
        </text>
        <line
          x1="6%"
          x2="96%"
          y1="25%"
          y2="25%"
          stroke="#FFFFFF"
          stroke-linecap="round"
          stroke-width="4"
          pointer-events="none"
          role="presentation"
          aria-hidden="true"
          stroke-opacity=".4"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { computed, ref, watch, toRefs, onMounted, inject } from 'vue'
import { scaleLinear } from 'd3'
import { useElementDimensions } from 'vue_features/shared/composables'
import { formatNumberForLocale } from 'utils'

export default {
  name: 'SparklineBar',
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 24,
    },
    value: {
      type: Number,
      default: 0,
    },
    fillColor: {
      type: String,
      default: '#4cbdec',
    },
    textColor: {
      type: String,
      default: '#ffffff',
    },
    textStyles: {
      type: Object,
      default: () => ({
        fontWeight: 'bold',
      }),
    },
    maxValue: {
      type: Number,
      default: 1,
    },
    minValue: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { value, width } = toRefs(props)
    const rootEl = ref(null)
    const { width: rootWidth } = useElementDimensions(rootEl)
    const containerWidth = ref(props.width || 0)
    const viewBoxDimensions = computed(() => `0 0 ${containerWidth.value} ${props.height}`)
    const barScale = computed(() =>
      scaleLinear().domain([props.minValue, props.maxValue]).range([28, containerWidth.value]),
    )
    const barWidth = computed(() => (props.value < 1 ? 0 : barScale.value(props.value)) + 'px')
    const borderRadius = computed(() => props.height / 2)
    const textY = computed(() => props.height * 0.7)
    const label = computed(() => (props.value > 0 ? formatNumberForLocale(props.value) : root.$t('not_applicable')))
    const labelColor = computed(() => (props.value > 0 ? props.textColor : '#666'))

    /*
     * We need to reassign the viewBox attribute of the SVG whenever one of the following happens:
     *   + The viewport resizes
     *   + The rootEl ref value changes (i.e. when the component first renders vs. when setup first runs)
     *   + The value prop changes
     *   + the width prop changes
     *
     * Otherwise the browser ends up scaling the SVG proportionally to fit and shrinking the text
     */
    onMounted(() => {
      containerWidth.value = rootWidth.value || props.width
    })

    watch([rootWidth, rootEl, value, width], () => {
      if (!rootEl.value) {
        containerWidth.value = props.width || 0
        return
      }

      containerWidth.value = rootWidth.value || props.width || 0
    })

    return {
      rootEl,
      viewBoxDimensions,
      barWidth,
      borderRadius,
      textY,
      label,
      barScale,
      labelColor,
    }
  },
}
</script>

<style scoped>
svg {
  overflow: visible;
}

.chart:hover {
  filter: drop-shadow(var(--drop-shadow));
}
</style>
