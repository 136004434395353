<template>
  <div class="flex w-full px-4" :class="isCollectionMapOpen ? 'justify-start gap-x-4' : 'justify-center'">
    <CollectionMap v-if="isCollectionMapAvailable && isCollectionMapOpen" class="-ml-4" />
    <LessonPlanContent class="max-w-7xl flex-grow" />
  </div>
</template>

<script>
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import LessonPlanContent from './LessonPlanContent'
import CollectionMap from 'vue_features/collection_map/components/CollectionMap'
import { useCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'

export default {
  name: 'LessonPlanContentView',
  components: {
    LessonPlanContent,
    CollectionMap,
  },
  setup() {
    const { isCollectionMapAvailable } = useLessonPlanShowStore()
    const { isOpen: isCollectionMapOpen } = useCollectionMapStore()

    return {
      isCollectionMapAvailable,
      isCollectionMapOpen,
    }
  },
}
</script>
