<template>
  <div id="content-trees-index" class="mx-auto max-w-7xl px-3 md:px-6">
    <ProgressCover :visible="isProgressCoverShown" :message="$t('common.please_wait')" />
    <div>
      <p>{{ $t('explanation') }}</p>
      <div class="lzui-tile bg-orange-50 border-orange-200 mt-4">
        <p class="center m-0 p-4">{{ $t('slowness_warning') }}</p>
      </div>
      <InviteList />
    </div>
    <PageHead />
    <CollectionList />
  </div>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import { ProgressCover } from 'vue_features/shared/components/ui'
import CollectionList from './CollectionList'
import PageHead from './PageHead'
import InviteList from './InviteList'

export default {
  name: 'CollectionsIndex',
  components: { CollectionList, InviteList, PageHead, ProgressCover },
  setup() {
    const { isProgressCoverShown } = useCollectionsIndexStore()
    return { isProgressCoverShown }
  },
}
</script>
