<template>
  <div class="mx-auto px-3 pt-4 md:px-6">
    <LzAccordion :bordered="false" class="border-orange-200 rounded-lg border">
      <template #head="{ toggle, verb, isOpen }">
        <a
          data-test="accordion-head"
          href="#"
          class="bg-orange-200 text-orange-800 hover:text-orange-900 group flex items-center justify-between p-4"
          @click.prevent="toggle"
        >
          <div class="flex items-center gap-2 font-bold">
            <AccordionIcon
              :is-open="isOpen"
              accordion-color-classes="group-hover:bg-orange-300 transition-colors text-orange-700 group-hover:text-orange-800"
            />
            {{
              $t(
                editType.toLowerCase() === 'resource'
                  ? 'resources.resource_updates_affect'
                  : 'resources.notes_updates_affect',
              )
            }}
            {{ affectedLessonPlans.length }}
            {{
              $t(affectedLessonPlans.length > 1 ? 'resources.form_template.lessons' : 'resources.form_template.lesson')
            }}
          </div>
          <div class="text-sm">
            <span>{{ capitalize(verb) }}</span>
          </div>
        </a>
      </template>
      <template #body="{ isOpen }">
        <div v-show="isOpen" class="bg-orange-50 p-4">
          <LzContentList :lz-contents="affectedLessonPlans" class="icon-list" />
        </div>
      </template>
    </LzAccordion>
  </div>
</template>

<script>
import LzContentList from 'vue_features/lz_content/components/LzContentList'
import { AccordionIcon, LzAccordion } from 'vue_features/shared/components/ui/index'
import { capitalize } from 'lodash'

export default {
  name: 'AffectedLessonPlans',
  components: { LzAccordion, LzContentList, AccordionIcon },
  props: {
    affectedLessonPlans: {
      type: Array,
      required: true,
    },
    editType: {
      type: String,
      required: true,
      validator: (value) => {
        return value !== null && ['resource', 'notes'].includes(value.toLowerCase())
      },
    },
  },
  setup() {
    return {
      capitalize,
    }
  },
}
</script>
