<template>
  <div class="border-base bg-focus rounded border p-4">
    <h6 class="mb-0">{{ $t('common.preview') }}</h6>
    <p class="help-block">{{ $t('show.logo.preview_help') }}</p>
    <div class="image-preview">
      <div class="image-preview__large">
        <p class="small mb-0">
          <strong class="text-muted">{{ $t('show.logo.large') }}</strong>
        </p>
        <div class="page-head">
          <div class="page-head__wrapper px-0 pt-4">
            <div class="page-head__image">
              <LogoImage :logo="logo" />
            </div>
            <div class="page-head__content pb-4">
              <div class="page-head__pretext">{{ $t('show.logo.lesson_plan') }}</div>
              <h1>{{ $t('show.logo.lesson_plan_title') }}</h1>
              <ul class="page-head__metadata list-metadata">
                <li>
                  <strong>{{ $t('common.label.created_by') }}</strong>
                  {{ name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="image-preview__medium">
        <p class="small mb-0">
          <strong class="text-muted">{{ $t('show.logo.medium') }}</strong>
        </p>
        <div class="page-head">
          <div class="page-head__wrapper pl-0 pt-4">
            <div class="page-head__image">
              <LogoImage :logo="logo" />
            </div>
            <div class="page-head__content pb-4">
              <div class="page-head__pretext">{{ $t('show.logo.lesson_plan') }}</div>
              <h1>{{ $t('show.logo.lesson_plan_title') }}</h1>
              <ul class="page-head__metadata list-metadata">
                <li>
                  <strong>{{ $t('common.label.created_by') }}</strong>
                  {{ name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="image-preview__small">
        <p class="small mb-0">
          <strong class="text-muted">{{ $t('show.logo.small') }}</strong>
        </p>
        <div class="page-head">
          <div class="page-head__wrapper px-0 pt-4">
            <div class="page-head__content pb-4">
              <div class="page-head__pretext">{{ $t('show.logo.lesson_plan') }}</div>
              <h1>{{ $t('show.logo.lesson_plan_title') }}</h1>
              <ul class="page-head__metadata list-metadata">
                <li>
                  <strong>{{ $t('common.label.created_by') }}</strong>
                  <LogoImage :logo="logo" class="inline-block max-h-5" />
                  {{ name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImage from './LogoImage'

export default {
  name: 'LogoPreview',
  components: { LogoImage },
  props: {
    name: { type: String, required: true },
    logo: { type: Object, required: true },
  },
}
</script>
