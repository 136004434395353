<template>
  <div id="resource">
    <PremiumPrompt v-if="premiumGate" :show-unshared-premium-gate="showUnsharedPremiumGate" />
    <TeacherOnlyPrompt v-if="teacherOnlyGate" />
    <ResourceContent />
  </div>
</template>

<script>
import { toRef, watchEffect } from 'vue'
import PremiumPrompt from 'vue_features/shared/components/login/PremiumPrompt'
import TeacherOnlyPrompt from './TeacherOnlyPrompt'
import ResourceContent from './ResourceContent'
import { useResourceTypes } from 'vue_features/shared/composables'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'

export default {
  name: 'ResourceGatedShow',
  components: {
    PremiumPrompt,
    TeacherOnlyPrompt,
    ResourceContent,
  },
  props: {
    premiumGate: { type: Boolean, required: true },
    showUnsharedPremiumGate: { type: Boolean, required: true },
    teacherOnlyGate: { type: Boolean, required: true },
  },
  setup(props) {
    const { resource, cardTitle } = useResourceShowStore()

    const { isWiki } = useResourceTypes(toRef(props, 'resource'))
    watchEffect(() => {
      if (!props.premiumGate && !props.teacherOnlyGate) {
        throw new Error('This component requires gating to be specified.')
      }
    })
    return { isWiki, resource, cardTitle }
  },
}
</script>
