<template>
  <div class="relative flex items-center">
    <button
      type="button"
      :tabindex="tabindex"
      class="focus:outline-none hover:bg-base-hover rounded-2x group mx-auto inline-flex h-12 w-12 items-center rounded-lg transition-colors duration-150"
      data-test="color-button"
      :data-selected-color="modelValue"
      @click="isOpen = !isOpen"
    >
      <svg
        :style="{ fill: modelValue, color: 'white' }"
        class="ring-primary-200 mx-auto h-8 w-8 rounded-full border-4 border-white ring-2 transition-colors duration-150"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" />
      </svg>
      <AnnotationToolTip v-show="!isOpen" :text="$t(`annotation.color`)" />
    </button>

    <div
      v-if="isOpen"
      class="border-base absolute bottom-0 left-16 mt-2 w-40 rounded-2xl border bg-white p-2 shadow-lg"
      data-test="color-picker-modal"
    >
      <button class="fixed left-0 top-0 z-10 h-full w-full bg-transparent" @click="isOpen = false"></button>

      <div class="relative z-20 grid grid-cols-3">
        <div
          v-for="(color, index) in colors"
          :key="index"
          class="group flex h-12 w-12 cursor-pointer items-center justify-center"
        >
          <button
            class="hover:bg-base-hover flex h-full w-full rounded-2xl p-2 transition duration-300"
            :data-test="`color-${color}`"
            @click="setSelectedColor(color)"
            @keydown.enter="setSelectedColor(color)"
          >
            <div
              role="radio"
              tabindex="0"
              :aria-checked="modelValue"
              class="focus:outline-none inline-flex h-full w-full items-center justify-center rounded-full border-4 border-white"
              :class="{ 'ring-primary-200 ring-2': modelValue === color }"
              :style="{ background: color }"
            >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import AnnotationToolTip from 'vue_features/shared/components/AnnotationToolTip'

export default {
  components: {
    AnnotationToolTip,
  },
  props: {
    colors: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const isOpen = ref(false)

    function setSelectedColor(color) {
      isOpen.value = false

      emit('update:modelValue', color)
    }

    return {
      setSelectedColor,
      isOpen,
    }
  },
}
</script>
