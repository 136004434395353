<template>
  <div class="mb-12">
    <ul v-if="collections.length" class="space-y-4">
      <CollectionItem v-for="collection in collections" :key="collection.id" :collection="collection" />
    </ul>
    <div v-else class="lzui-tile">
      {{ $t('no_collections') }}
    </div>
    <LzPaginator v-bind="pagination" @page-changed="getPage" />
  </div>
</template>

<script>
import { LzPaginator } from 'vue_features/shared/components/ui'
import CollectionItem from './CollectionItem'
import { canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useCollectionsIndexStore } from '../store/use_collections_index_store'

export default {
  name: 'CollectionList',
  components: { CollectionItem, LzPaginator },
  setup() {
    const { collectionsData, collections, isProgressCoverShown, getCollections } = useCollectionsIndexStore()
    return { canModalShow, collectionsData, collections, isProgressCoverShown, getCollections }
  },
  computed: {
    pagination() {
      return this.collectionsData.meta.pagination
    },
  },
  methods: {
    getPage(page) {
      this.isProgressCoverShown = true
      const hideCover = () => (this.isProgressCoverShown = false)
      this.getCollections(page).then(hideCover, hideCover)
    },
  },
}
</script>
