import { computed } from 'vue'

export default function useSlideDialogue({ content }) {
  const shapeClass = computed(() => {
    return `dialogue--${content.value.shape || 'speech'}`
  })
  const directionClass = computed(() => {
    return `dialogue--${content.value.direction || 'bottom-center'}`
  })
  const hasMathJax = computed(() => {
    return (content.value.text || '').includes('math-tex')
  })

  return { shapeClass, directionClass, hasMathJax }
}
