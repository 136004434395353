import { intersection } from 'lodash'
import { config } from 'utils'

export default function useSlideContentDrop({ onDragStart, onDrag, onDragStop, uploadFile, movingContent, reset }) {
  let dragging = false
  let droppedImage = null

  function onContentDrag(event) {
    event.preventDefault()

    // dataTransfer.items lets us reject bad file types immediately, but isn't available until the drop event
    if (event.dataTransfer.items && !canAcceptContent(event.dataTransfer)) return
    if (!dragging) {
      onDragStart(event, 'image', { top: 0, left: 0 })
      dragging = true
    }

    const ui = { offset: { top: event.pageY, left: event.pageX } }
    onDrag(event, ui)
    if (!droppedImage) droppedImage = movingContent.value
  }

  function onContentDrop(event) {
    event.preventDefault()
    const item = getItem(event.dataTransfer)
    if (canAcceptContent(event.dataTransfer)) {
      onDragStop(event)
      droppedImage.uploading = true

      const image = item.getAsFile ? item.getAsFile() : item
      const finishUpload = droppedImage
      uploadFile(image, () => {
        finishUpload.url = image.baseUrl
        finishUpload.mimetype = image.mimetype
        finishUpload.file.filestackOptions = image.filestackOptions
        finishUpload.uploading = false
      })
    }
    onContentDragEnd(event)
  }

  function onContentDragEnd(event) {
    event.preventDefault()

    dragging = false
    droppedImage = null
  }

  function getItem(dataTransfer) {
    const fileInterface = dataTransfer.items || dataTransfer.files

    return fileInterface[0]
  }

  function canAcceptContent(dataTransfer) {
    const item = getItem(dataTransfer)
    return (
      item &&
      intersection(
        config.imageWithSvg.accept,
        item.type.split('/').map((t) => `.${t}`),
      ).length
    )
  }

  function onLeave(event) {
    event.preventDefault()

    reset()
    dragging = false
    droppedImage = null
  }

  function addContentDropEventListeners(element) {
    element.addEventListener('dragover', onContentDrag)
    element.addEventListener('dragleave', onLeave)
    element.addEventListener('drop', onContentDrop)
    element.addEventListener('dragend', onContentDragEnd)
  }

  function removeContentDropEventListeners(element) {
    if (element) {
      element.removeEventListener('dragover', onContentDrag)
      element.removeEventListener('dragleave', onLeave)
      element.removeEventListener('drop', onContentDrop)
      element.removeEventListener('dragend', onContentDragEnd)
    }
  }

  return { addContentDropEventListeners, removeContentDropEventListeners }
}
