import { times } from 'lodash'

export function getAllPages(src) {
  return new Promise((resolve, reject) => {
    getDocument(src)
      .then(getAllPdfPages)
      .then((pages) => resolve(pages), reject)
  })
}

function getDocument(src) {
  // Using import statement in this way allows Webpack
  // to treat pdf.js as an async dependency so we can
  // avoid adding it to one of the main bundles
  return import(
    /* webpackChunkName: 'pdfjs-dist' */
    'pdfjs-dist/webpack'
  ).then((pdfjs) => {
    return pdfjs.getDocument(src).promise
  })
}

// pdf: instance of PDFDocumentProxy
// page: instance of PDFPageProxy
// see docs for PDF.js for more info
function getAllPdfPages(pdf) {
  const allPages = times(pdf.numPages).map((index) => {
    const num = index + 1
    return pdf.getPage(num)
  })
  return Promise.all(allPages)
}
