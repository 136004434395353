import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, resources, lessonPlans, activerecordAttributes, components } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(components, 'components'),
  namespaceLocaleObj(resources, 'resources'),
  namespaceLocaleObj(lessonPlans, 'lesson_plans'),
  namespaceLocaleObj(activerecordAttributes, 'activerecord_attributes'),
)
