<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'

const props = defineProps({
  label: { type: String, required: true },
  description: { type: String, default: '' },
  href: { type: String, default: '' },
  icon: { type: String, required: true },
})

const buttonType = props.href ? 'a' : 'button'
</script>

<template>
  <component :is="buttonType" :href="href" class="flex items-start gap-x-2" @click="$emit('click')">
    <LzIcon :path="icon" size="md" class="flex-shrink-0" />
    <span v-if="description" class="flex flex-col">
      <span class="text-base">{{ label }}</span>
      <p class="text-muted whitespace-normal text-xs">{{ description }}</p>
    </span>
    <template v-else>
      {{ label }}
    </template>
  </component>
</template>
