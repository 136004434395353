<script setup>
import { ref, nextTick } from 'vue'
import { useBookmarksStore } from '../composables/use_bookmarks_store'
import { ProgressCover, LzButton, LzIcon, Coachmark } from 'vue_features/shared/components/ui'
import { useDismissibles } from 'vue_features/shared/composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import FolderLink from './FolderLink'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import { useRouter } from 'vue-router'

const CM_NEW_BOOKMARK_FOLDER = 'new-bookmark-folder'

const { closeDismissible, showDismissible, canDismissibleShow } = useDismissibles()

const { authorizedAsTeacher, authorizedAsAuthor } = useCurrentUserStore()

const { folders, loadMoreFolders, totalFolders, createFolder, allowPersonalCopies } = useBookmarksStore()
const isCreatingFolder = ref(false)

const {
  visibleItems: visibleFolders,
  canLoadMore,
  showMoreItems,
  isLoading,
} = useLoadMoreList({
  itemsRef: folders,
  totalCount: totalFolders,
  startCount: 10,
  moreCount: 10,
})

const handleLoadMore = async () => {
  await showMoreItems(loadMoreFolders)
}

const startedCreatingFolder = ref(false)
const newTitle = ref('')
const folderNameInput = ref(null)

const startCreatingFolder = () => {
  newTitle.value = ''
  startedCreatingFolder.value = true
  nextTick(() => folderNameInput.value.focus())
}

const router = useRouter()
const createNewFolder = async () => {
  isCreatingFolder.value = true
  const folder = await createFolder(newTitle.value)
  await router.push(`/saved/${folder.id}`)
  isCreatingFolder.value = false
  startedCreatingFolder.value = false
}

const cancel = () => {
  startedCreatingFolder.value = false
}

showDismissible(CM_NEW_BOOKMARK_FOLDER)
</script>

<template>
  <div class="border-neutral-400 border-b md:flex md:w-80 md:border-b-0 md:border-r">
    <Coachmark
      v-if="canDismissibleShow(CM_NEW_BOOKMARK_FOLDER)"
      target="#folder-header"
      position="right"
      class="z-1000 relative"
      @close="closeDismissible(CM_NEW_BOOKMARK_FOLDER)"
    >
      <h4 class="my-0 text-base leading-8">{{ $t('coachmark_title') }}</h4>
      <p class="text-muted mb-2">{{ $t('coachmark_description') }}</p>
    </Coachmark>
    <div class="flex w-full flex-col gap-4">
      <ProgressCover :visible="isCreatingFolder" />
      <div class="flex flex-col gap-3">
        <div class="flex h-10 items-center justify-between">
          <h3 class="text-md m-0 flex-shrink leading-5">{{ $t('folders') }}</h3>

          <LzButton
            id="folder-header"
            class="text-accent outline-none hover:text-accent-hover hover:bg-focus hover:border-base hover:ring-neutral-400 flex h-8 appearance-none items-center rounded-lg bg-transparent pl-2 pr-4 text-xs font-bold shadow-none transition-colors"
            @click="startCreatingFolder"
          >
            <LzIcon path="icons/add" />
            {{ $t('new_folder') }}
          </LzButton>
        </div>
        <div
          v-if="startedCreatingFolder"
          class="bg-focus border-base z-0 flex w-full flex-col gap-2 rounded-lg border p-2"
        >
          <div class="flex flex-col gap-2">
            <label class="mb-0" for="folder-name">{{ $t('name_label') }}</label>
            <input
              id="folder-name"
              ref="folderNameInput"
              v-model="newTitle"
              :placeholder="$t('enter_folder_name')"
              type="text"
            />
          </div>
          <div class="align-right">
            <template v-if="!isCreatingFolder">
              <LzButton size="small" demoted @click="cancel">{{ $t('common.cancel') }}</LzButton>
              <LzButton size="small" color="green" :disabled="newTitle.trim().length <= 0" @click="createNewFolder">{{
                $t('common.create')
              }}</LzButton>
            </template>
          </div>
        </div>
        <ul class="border-neutral-400 space-y-2 border-b pb-4">
          <FolderLink :title="$t('all_favorites')" to="/saved" />
          <FolderLink
            v-for="folder in visibleFolders"
            :key="folder.id"
            :to="`/saved/${folder.id}`"
            :title="folder.title"
          />
          <li v-if="canLoadMore">
            <LoadMoreButton :is-loading="isLoading" button-class="btn--demoted" @click="handleLoadMore" />
          </li>
        </ul>
      </div>
      <template v-if="authorizedAsAuthor || (allowPersonalCopies && authorizedAsTeacher)">
        <div class="flex h-10 items-center">
          <h3 class="text-md m-0 leading-5">{{ $t('my_content') }}</h3>
        </div>
        <ul class="border-neutral-400 space-y-2 pb-4 md:border-b">
          <FolderLink v-if="authorizedAsAuthor" :title="$t('authored_lps')" to="/saved/authored_lps" />
          <FolderLink v-if="allowPersonalCopies" :title="$t('personal_copies')" to="/saved/personal_copies" />
        </ul>
      </template>
    </div>
  </div>
</template>
