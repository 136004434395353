import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

const fetchRouteWithFilters = (route, routeParams = {}) => {
  const url = Routes[route](routeParams)

  return (filterParams, fetchParams = {}) => {
    return HTTP.fetch(url, { query: filterParams, ...fetchParams })
  }
}

const DataDashboardService = {
  getAllowedFilters: fetchRouteWithFilters('data_dashboard_index_path', { format: 'json' }),
  getUsageData: fetchRouteWithFilters('data_dashboard_usage_index_path'),
  getCategoryItemsRollup: fetchRouteWithFilters('data_dashboard_performance_category_items_rollup_index_path'),
  getCategoryRollup: fetchRouteWithFilters('data_dashboard_performance_category_rollup_index_path'),
  getStandardsAlignment: fetchRouteWithFilters('data_dashboard_performance_standards_alignment_index_path'),
  getCategoryStandardsRollup: fetchRouteWithFilters('data_dashboard_performance_category_standards_rollup_index_path'),
  getRankedStandards: fetchRouteWithFilters('data_dashboard_performance_ranked_standards_path'),
  getScoreBands: fetchRouteWithFilters('data_dashboard_performance_score_bands_path'),
  getAvailableCustomers: fetchRouteWithFilters('data_dashboard_performance_available_customers_path'),
}

export default DataDashboardService
