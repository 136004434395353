import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const LessonPlanService = {
  createLessonPlan(lessonPlan) {
    const path = Routes.lesson_plans_path()
    const data = snakeKeys({ lessonPlan })
    return HTTP.create(path, data)
  },
  updateLessonPlan(id, lessonPlan) {
    const path = Routes.lesson_plan_path(id)
    const data = snakeKeys({ lessonPlan })
    return HTTP.put(path, data)
  },
}

export default LessonPlanService
