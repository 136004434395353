<template>
  <div>
    <ResourceUploader :resource="resource" @update="$emit('update', $event)" />
    <VideoCaptions :captions="resource.videoCaptions" @update="$emit('update', $event)" />
  </div>
</template>

<script>
import ResourceUploader from 'vue_features/resources/shared/components/ResourceUploader'
import VideoCaptions from 'vue_features/video_captions/components/VideoCaptions'

export default {
  name: 'VideoEdit',
  components: { ResourceUploader, VideoCaptions },
  props: {
    resource: { type: Object, required: true },
  },
}
</script>
