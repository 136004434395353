<script setup>
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

defineProps({
  settingsClasses: { type: [String, Array, Object], default: '' },
})
</script>

<template>
  <div
    data-role="admin-bar"
    class="border-base bg-focus mx-0 my-4 flex flex-col flex-wrap justify-end rounded-md border px-4 pb-0 pt-4 align-middle md:flex-row"
  >
    <div v-if="isSlotUsed('settings')" class="flex flex-grow flex-wrap items-center md:mb-0" :class="settingsClasses">
      <div data-role="admin-setting" class="mb-4 mr-4 inline-block lg:mr-8">
        <slot name="settings" />
      </div>
    </div>
    <div
      v-if="isSlotUsed('actions')"
      class="border-primary-200 mb-4 flex w-full items-center justify-end border-t border-solid pt-4 md:w-auto md:border-t-0 md:pt-0"
    >
      <slot name="actions" />
    </div>
  </div>
</template>
