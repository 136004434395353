<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div
    class="hover:scale-102 min-w-min transform cursor-pointer space-y-4 rounded-2xl border-solid bg-white p-4 shadow-sm transition duration-500 ease-in-out hover:shadow-lg"
    :class="selected ? 'border-neutral-500 border-2' : 'border-neutral-400 hover:border-neutral-500 border'"
    @click="$emit('click')"
  >
    <div class="flex items-center space-x-3">
      <h4 class="text-primary-accent m-0 w-1/4 flex-grow align-top text-sm font-extrabold">
        <LzTooltip v-if="tooltip" placement="left" reference-tag="div">
          {{ title }}
          <template #content>
            {{ tooltip }}
          </template>
        </LzTooltip>
        <template v-else>
          {{ title }}
        </template>
      </h4>

      <div data-test="max-points" class="text-center">
        <div class="text-primary-800 text-sm font-semibold leading-5">{{ maxScoreRange }}</div>
        <div class="text-xs leading-4 text-gray-500">{{ $t('max_points') }}</div>
      </div>

      <div data-test="num-responses" class="text-center">
        <div class="text-primary-800 text-sm font-semibold leading-5">{{ totalResponses }}</div>
        <div class="text-xs leading-4 text-gray-500">{{ $t('responses') }}</div>
      </div>

      <div data-test="avg-score" class="text-center">
        <div class="text-sm font-semibold leading-5" :style="{ color: itemScoreColor }"> {{ averageScore }}% </div>
        <div class="text-xs leading-4 text-gray-500">{{ $t('average_score_abbr') }}</div>
      </div>
    </div>

    <StackedBar
      :groups="scoreBands"
      :group-value="(group) => group.count"
      :tooltip="
        (group, _index, total) => {
          return `${$t('responses')}: ${group.count} / ${total} | ${$t('avg_score')}: ${Math.round(
            group.averageScore || 0,
          )}%`
        }
      "
      :colors="[
        'rgb(var(--not-attempted-medium))',
        'rgb(var(--low-score-medium))',
        'rgb(var(--medium-score-medium))',
        'rgb(var(--high-score-medium))',
      ]"
    />

    <div class="flex flex-row items-center justify-end space-x-4">
      <LzButton size="small" @click="$emit('click')">
        {{ $t('view_details') }}
      </LzButton>
      <LzButton
        size="small"
        :disabled="!enableViewResponsesButton"
        :title="enableViewResponsesButton ? $t('view_question') : $t('view_question_disabled')"
        @click.stop="gotoResponses"
      >
        {{ $t('view_responses') }}
      </LzButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import StackedBar from 'vue_features/shared/components/charts/StackedBar'
import { LzTooltip, LzButton } from 'vue_features/shared/components/ui'
import { formatMaxScoreRange } from '../utils'
import { scoreBandColor, ratingForScore } from 'vue_features/shared/utils/scoring'
import { location } from 'utils'
import Routes from 'routes'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  tooltip: {
    type: String,
    default: '',
  },
  averageScore: {
    type: Number,
    default: 0,
  },
  itemNumber: {
    type: Number,
    required: true,
  },
  maxScoreRangeBegin: {
    type: Number,
    default: 0,
  },
  maxScoreRangeEnd: {
    type: Number,
    default: 0,
  },
  totalAssigned: {
    type: Number,
    default: 0,
  },
  totalResponses: {
    type: Number,
    default: 0,
  },
  isCustomization: {
    type: Boolean,
    default: false,
  },
  teachers: {
    type: Object,
    default: () => ({}),
  },
  lzCodes: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: Boolean,
    default: false,
  },
  scoreBands: StackedBar.props.groups,
  learnosityItemReference: {
    type: String,
    required: true,
  },
})

const maxScoreRange = computed(() => formatMaxScoreRange(props.maxScoreRangeBegin, props.maxScoreRangeEnd))
const itemScoreColor = computed(() => scoreBandColor(ratingForScore(props.averageScore)))
const enableViewResponsesButton = computed(() => props.lzCodes.length === 1)

const gotoResponses = () => {
  const lzCode = props.lzCodes[0]
  location.openTab(Routes.grading_path(lzCode, { item: props.learnosityItemReference }), '_blank')
}
</script>
