<script setup>
import { inject, computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'

const props = defineProps({
  dataTable: {
    type: Object,
    default: () => ({}),
  },
  column: {
    type: String,
    default: null,
  },
})

const { sortingBy } = inject('dataTable', props.dataTable)

const dynamicClasses = computed(() => {
  const classes = ['transition-all transform scale-75']

  if (sortingBy.value === props.column) {
    return classes
  } else if (sortingBy.value === `-${props.column}`) {
    classes.push('rotate-180')
  } else {
    classes.push('opacity-10')
  }

  return classes
})
</script>

<template>
  <LzIcon path="icons/active-indicator" size="sm" :class="dynamicClasses" />
</template>
