<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <DetailListItem
    :item-id="wiki.id"
    :title="wiki.title"
    :thumbnail="getThumbnail(wiki)"
    :href="wiki.href"
    :icon="getIcon(wiki)"
    :description="wiki.description"
  >
    <template #metadata>
      <ul class="list-metadata">
        <li>
          <strong>{{ capitalize($t('common.from')) }}:</strong>
          {{ invite.customerName }}
        </li>
      </ul>
    </template>
    <template #actions>
      <template v-if="isPending">
        <a class="btn caution btn--primary" data-test="reject-button" @click.prevent="reject">{{ $t('reject') }}</a>
        <a class="btn btn--green" data-test="accept-button" @click.prevent="accept">{{ $t('accept') }}</a>
      </template>
      <AsyncContentLoader v-else :loader="activeLoader" class="flex flex-col items-center space-y-2 text-center">
        <template v-if="isAccepted">
          <span
            class="flex h-8 w-8 items-center justify-center rounded-full bg-green-200 text-green-700"
            data-test="invite-accepted"
          >
            <InlineSvg class="h-6 w-6" path="icons/check" />
          </span>
          <span class="font-bold leading-5">{{ $t('invite_accepted') }}</span>
        </template>
        <template v-if="isRejected">
          <span
            class="flex h-8 w-8 items-center justify-center rounded-full bg-red-200 text-red-700"
            data-test="invite-rejected"
          >
            <InlineSvg class="h-6 w-6" path="icons/close" />
          </span>
          <span class="font-bold leading-5">{{ $t('invite_rejected') }}</span>
        </template>
      </AsyncContentLoader>
    </template>
  </DetailListItem>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import { capitalize } from 'lodash'
import { InlineSvg, AsyncContentLoader } from 'vue_features/shared/components/ui'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import { getIcon, getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'Invite',
  components: { AsyncContentLoader, DetailListItem, InlineSvg },
  props: {
    invite: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { acceptCollectionShare, rejectCollectionShare } = useCollectionsIndexStore()
    return { acceptCollectionShare, rejectCollectionShare, getThumbnail, getIcon }
  },
  data() {
    return { activeLoader: null }
  },
  computed: {
    wiki() {
      return this.invite.wiki
    },
    isAccepted() {
      return this.share.status === 'accepted'
    },
    isPending() {
      return this.share.status === 'pending'
    },
    isRejected() {
      return this.share.status === 'rejected'
    },
    share() {
      return this.invite.customerShare
    },
  },
  methods: {
    capitalize,
    accept() {
      this.activeLoader = this.acceptCollectionShare(this.share)
    },
    reject() {
      this.activeLoader = this.rejectCollectionShare(this.share)
    },
  },
}
</script>
