<template>
  <Grid
    id="assignment"
    :class="[
      'mx-auto max-w-7xl gap-y-4 md:gap-x-6',
      `lesson-type-${lessonPlan.lessonType}`,
      { 'tool-open': isToolOpen },
    ]"
    disable-default="max-w md:gap-x"
  >
    <div
      :class="[
        'student-tools z-1  col-span-12',
        {
          'row-start-1 lg:col-span-6 lg:col-start-7': isToolOpen,
          'lg:col-span-10 lg:col-start-2': !isToolOpen && !hasBothCardTypes,
        },
      ]"
    >
      <div
        class="relative z-10 flex justify-end"
        :class="{ '-mb-11 mt-1': isLiveLearn && (authorizedAsTeacher || hasStudentTools) }"
      >
        <div class="tool-header">
          <StudentToolsDropdown data-test="student-tools" />
        </div>
      </div>
      <StudentTool :class="{ 'pt-16': isLiveLearn }" />
    </div>
    <AssignmentCardDeck
      v-if="hasLessonCards"
      id="lesson-cards"
      v-model:selected-card-id="selectedLessonCardId"
      data-test="lesson-card-deck"
      type="lesson"
      class="z-0"
      :cards="lessonDisplayCards"
      :is-tool-open="isToolOpen"
    />
    <AssignmentCardDeck
      v-if="hasAssignmentCards"
      id="assignment-cards"
      v-model:selected-card-id="selectedAssignmentCardId"
      data-test="assignment-card-deck"
      type="assignment"
      class="z-0"
      :cards="assignmentDisplayCards"
      :is-tool-open="isToolOpen"
    />
    <div v-if="isToolOpen && hasBothCardTypes" class="col-span-3 col-start-1 row-start-2 hidden lg:block" />
  </Grid>
</template>

<script>
import { computed, provide } from 'vue'
import {
  useAssignmentStore,
  useAssignmentCardsStore,
  useLiveLearnStore,
} from 'vue_features/assignments/show/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import StudentToolsDropdown from 'vue_features/lesson_plans/components/StudentToolsDropdown'
import AssignmentCardDeck from './AssignmentCardDeck'
import StudentTool from 'vue_features/assignments/shared/card_deck/StudentTool'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'AssignmentCardDeckGroup',
  components: { Grid, StudentTool, StudentToolsDropdown, AssignmentCardDeck },
  setup(_props, { emit }) {
    const { openTool, isToolOpen } = useLessonPlanStore()
    const { assignment, lessonPlan, lessonPlanTextStyle } = useAssignmentStore()
    provide('fontTextStyle', lessonPlanTextStyle)

    const {
      assignmentCards,
      lessonCards,
      hasAssignmentCards,
      hasLessonCards,

      selectedAssignmentCardId,
      selectedLessonCardId,
      assignmentDisplayCards,
      lessonDisplayCards,
    } = useAssignmentCardsStore()
    const { isLiveLearn } = useLiveLearnStore()

    const hasBothCardTypes = computed(() => hasAssignmentCards.value && hasLessonCards.value)

    selectedAssignmentCardId.value =
      selectedAssignmentCardId.value || (hasAssignmentCards.value ? assignmentCards.value[0].id : 0)
    selectedLessonCardId.value = hasLessonCards.value ? lessonCards.value[0].id : 0

    const { authorizedAsTeacher } = useCurrentUserStore()
    return {
      assignment,
      authorizedAsTeacher,
      isLiveLearn,
      lessonPlan,
      openTool,
      isToolOpen,
      hasAssignmentCards,
      hasBothCardTypes,
      selectedAssignmentCardId: computed({
        get: () => selectedAssignmentCardId.value,
        set: (newCardId) => emit('assignment-card-changed', newCardId),
      }),
      selectedLessonCardId: computed({
        get: () => selectedLessonCardId.value,
        set: (newCardId) => emit('lesson-card-changed', newCardId),
      }),
      hasStudentTools: computed(() => lessonPlan.value.studentTools.length > 0),
      lessonCards,
      hasLessonCards,
      assignmentDisplayCards,
      lessonDisplayCards,
    }
  },
}
</script>
