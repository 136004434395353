<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div ref="root" class="relative">
    <div
      class="outline-none inline-flex min-w-max cursor-pointer items-center space-x-2 overflow-y-auto rounded-lg border border-gray-300 px-4 py-2"
      tabindex="0"
      @click="showOptions = !showOptions"
      @keypress="showOptions = !showOptions"
    >
      <span class="font-normal text-gray-900">{{ value }}</span>
      <LzIcon path="icons/chevron-down" size="md" />
    </div>

    <div
      v-show="showOptions"
      class="absolute left-1/2 top-full z-10 mt-0.5 h-52 w-full min-w-max -translate-x-1/2 transform overflow-y-hidden rounded-xl bg-white"
      style="box-shadow: 0px 20px 13px rgba(0, 0, 124, 0.03), 0px 8px 5px rgba(0, 0, 124, 0.08)"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import useEventListener from 'vue_features/shared/composables/use_event_listener'
import { ref } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'FilterMenu',
  components: { LzIcon },
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const showOptions = ref(false)
    const root = ref(null)

    const checkForOutsideClick = (e) => {
      if (showOptions.value) {
        if (root.value.contains(e.target)) {
          return
        }

        showOptions.value = false
      }
    }

    useEventListener('click', checkForOutsideClick)

    return {
      showOptions,
      root,
    }
  },
}
</script>
