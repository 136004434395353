let newUserId = 1

export default class User {
  constructor(
    { firstName, middleName, lastName, email, role, username, preventUserChanges } = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      role: 'teacher',
      username: '',
      preventUserChanges: false,
    },
  ) {
    this.id = newUserId++
    this.firstName = firstName
    this.middleName = middleName
    this.lastName = lastName
    this.email = email
    this.role = role
    this.username = username
    this.preventUserChanges = preventUserChanges
    this.password = ''
    this.passwordConfirmation = ''
    this.errors = []
    this.persisted = false
    this.isUpdatingPassword = false
  }

  checkErrors(translate) {
    this.errors = []

    if (!this.firstName) {
      this.errors.push(translate('blank_first_name'))
    }
    if (!this.lastName) {
      this.errors.push(translate('blank_last_name'))
    }
    if (this.isStudent() && !this.username) {
      this.errors.push(translate('blank_username'))
    }
    if (!this.isStudent() && !this.email) {
      this.errors.push(translate('blank_email'))
    }
    if (this.isUpdatingPassword) {
      if (!this.password) {
        this.errors.push(translate('blank_password'))
      }
      if (!this.passwordConfirmation) {
        this.errors.push(translate('blank_pw_conf'))
      }
      if (this.password !== this.passwordConfirmation) {
        this.errors.push(translate('pw_conf_mismatch'))
      }
    }
  }

  isStudent() {
    return this.role === 'student'
  }

  attrs() {
    const additionalAttrs = this.isStudent() ? {} : { email: this.email }
    return Object.assign(additionalAttrs, this.sharedAttrs())
  }

  sharedAttrs() {
    const attrs = {
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      username: this.username,
      role: this.role,
      preventUserChanges: this.preventUserChanges,
    }
    if (this.isUpdatingPassword) {
      Object.assign(attrs, {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      })
    }
    return attrs
  }
}
