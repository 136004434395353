import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive } from 'vue'
import { capitalize, replace, snakeCase } from 'lodash'

export function initLzContentModalStore(contentType) {
  const { $reset, $set } = useLzContentModalStore()
  $reset()
  const displayType = replace(snakeCase(contentType), '_', ' ')
  $set({
    contentType: contentType,
    displayType,
    content: {
      title: capitalize(displayType),
      name: capitalize(displayType),
      type: contentType,
      coverImageUrl: {},
      premium: false,
      teacherOnly: false,
    },
  })
}

export const useLzContentModalStore = defineStore('lzContentModal', () => {
  const state = reactive({
    currentTab: 'choose-new',
    contentType: '',
    displayType: '',
    viewType: 'list',
    searchSelection: [],
    items: [],
    previews: [],
    content: { title: '', name: '', type: '', premium: false, teacherOnly: false },
    listOfContents: '',
  })

  return { state }
})
