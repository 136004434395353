import { ref, watch } from 'vue'
import { get } from 'lodash'

export default function useSkeletonRows(data, { initial = 3, rowsPath = 'rows' } = {}) {
  const skeletonRows = ref(initial)

  watch(data, (newData) => {
    const newRows = get(newData, rowsPath, []).length
    if (!newRows) return
    skeletonRows.value = newRows
  })

  return skeletonRows
}
