<template>
  <LzModal
    :modal-title="title"
    :modal-id="$options.name"
    class="lzui-modal--large"
    :autoclose="false"
    @bg-click="hideModal"
    @close-modal="hideModal"
  >
    <template v-if="isShowingSharePrompt">
      <UsernameSharePrompt :students="newStudents" @close="hideModal" />
    </template>
    <template v-else>
      <StudentSearch :klass-id="klassId" @added-students="addedStudents" @cancel="hideModal" />
    </template>
  </LzModal>
</template>

<script>
import LzModal from 'vue_features/shared/components/ui/LzModal'
import StudentSearch from './add_student_to_klass/StudentSearch'
import UsernameSharePrompt from './add_student_to_klass/UsernameSharePrompt'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'AddStudentToKlassModal',
  components: { UsernameSharePrompt, LzModal, StudentSearch },
  props: {
    klassId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return { closeModal }
  },
  data() {
    return {
      isShowingSharePrompt: false,
      newStudents: [],
    }
  },
  computed: {
    showTitle() {
      return this.$t('add_student_to_klass_modal.header_share')
    },
    title() {
      return this.isShowingSharePrompt ? this.showTitle : this.$t('add_student_to_klass_modal.heading')
    },
  },
  methods: {
    hideModal() {
      this.closeModal(this.$options.name)
      this.$emit('closing-add-student-to-klass-modal')
    },
    addedStudents(students) {
      this.isShowingSharePrompt = true
      this.newStudents = students
    },
  },
}
</script>
