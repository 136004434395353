import { computed, reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { initCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'
import { initPrintSettingsStore } from 'vue_features/print_settings/store/use_print_settings_store'
import { useLessonPlanStore } from '../../store/use_lesson_plan_store'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import { useAssignmentStore } from 'vue_features/assignments/show/composables'
import { useBookmarkButtonStore } from './use_bookmark_button_store'
import { useContactDetailsStore } from 'vue_features/shared/composables/use_contact_details_store'

export function initLessonPlanShowStore(initialState) {
  useAssignmentStore().$reset()
  useBookmarkButtonStore().$set(initialState.bookmarkButtonState)
  useLessonPlanShowStore().$set(initialState.lessonPlanShowState)
  useLessonPlanStore().$set(initialState.lessonPlanState)
  useContactDetailsStore().$set(initialState.contactState)

  const hasLearnosityItem = initialState.lessonPlanState.lessonPlan.cards?.some(isQuestionCard)
  initPrintSettingsStore(
    {
      context: 'lesson_plan',
      analyticsProperties: initialState.lessonPlanState.analyticsProperties,
      hasLearnosityItem,
    },
    initialState.viewableAsTeacher,
  )
  if (initialState.collectionMapState && initialState.collectionMapState.path) {
    initCollectionMapStore(initialState.collectionMapState)
  }
}

export const useLessonPlanShowStore = defineStore('lessonPlanShow', () => {
  const state = reactive({
    premiumGate: false,
    showUnsharedPremiumGate: false,
    isGated: false,
    canPrint: false,
    lzCodes: [],
    copiesData: {},
    breadcrumbs: [],
    actionMenuProps: {},
    isCollectionMapAvailable: false,
    channel: null,
    presenterWindow: null,
    isFullscreen: false,
  })

  const isPresenting = computed(() => {
    return !!state.presenterWindow
  })

  return {
    state,
    isPresenting,
  }
})
