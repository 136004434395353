import { throttle } from 'lodash'

export default function useDragTable(dragTable, size, builderSize, { isDragging, cornerTd }) {
  const expandTable = (e) => {
    const rows = dragTable.value.tBodies[0].rows,
      colCount = rows[0].cells.length,
      rect = dragTable.value.getClientRects()[0]

    if (e.clientY > rect.bottom && rows.length < size.maxRows) {
      builderSize.rowCount = builderSize.rowCount + 1
      size.rows = builderSize.rowCount
    }

    if (e.clientX > rect.right && colCount < size.maxCols) {
      builderSize.columnCount = builderSize.columnCount + 1
      size.columns = builderSize.columnCount
    }
  }

  const onMouseOver = (e) => {
    e.preventDefault()
    const td = e.target
    if (td.nodeName === 'TD' && td !== cornerTd && dragTable.value.contains(td)) {
      cornerTd = td
      select(td)
    }
  }

  const onMouseDown = (e) => {
    e.preventDefault()
    const td = e.target
    if (td.nodeName === 'TD') {
      if (isDragging) {
        onMouseUp(e)
      } else {
        isDragging = true
        cornerTd = td
        select(td)

        dragTable.value.addEventListener('mouseleave', onMouseLeave)
        document.addEventListener('mouseover', onMouseOver)
        document.addEventListener('mouseup', onMouseUp)
      }
    }
  }

  const onMouseUp = (e) => {
    e.preventDefault()
    isDragging = false

    dragTable.value.removeEventListener('mouseleave', onMouseLeave)
    dragTable.value.removeEventListener('mouseover', removeMouseMoveListener)

    document.removeEventListener('mouseover', onMouseOver)
    document.removeEventListener('mouseup', onMouseUp)
    document.removeEventListener('mousemove', onMouseMove)
  }

  const onMouseLeave = (e) => {
    e.preventDefault()
    expandTable(e)
    dragTable.value.addEventListener('mouseover', removeMouseMoveListener)
    document.addEventListener('mousemove', onMouseMove)
  }

  const throttledTableExpand = throttle(expandTable, 100)

  const removeMouseMoveListener = () => {
    document.removeEventListener('mousemove', onMouseMove)
    dragTable.value.removeEventListener('mouseover', removeMouseMoveListener)
    throttledTableExpand.cancel()
  }

  const onMouseMove = (e) => {
    e.preventDefault()
    throttledTableExpand(e)
  }

  const select = (td) => {
    size.rows = td.parentNode.rowIndex + 1
    size.columns = td.cellIndex + 1
  }

  const removeEventListeners = () => {
    if (dragTable.value) {
      dragTable.value.removeEventListener('mouseDown', onMouseDown)
      dragTable.value.removeEventListener('mouseleave', onMouseLeave)
      dragTable.value.removeEventListener('mouseover', removeMouseMoveListener)
    }

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseover', onMouseOver)
    document.removeEventListener('mouseup', onMouseUp)
  }

  return { onMouseDown, removeEventListeners }
}
