<template>
  <div>
    <div class="mb-6">
      <h3>{{ $t('user_imports.index.this_weeks_uploads') }}</h3>
      <UserImportThisWeeksUploads :imports="currentUserImports" />
    </div>

    <div>
      <h3>{{ $t('user_imports.index.prior_uploads') }}</h3>
      <UserImportPriorUploads :imports="expiredUserImports" />
    </div>

    <div>
      <h3>{{ $t('user_imports.index.recurring_user_import') }}</h3>
      <UsersRecurringImport :users-recurring-import="usersRecurringImport" />
    </div>

    <div>
      <h3>{{ $t('user_imports.index.recurring_score_export') }}</h3>
      <StudentDataRecurringExportsList :student-data-recurring-exports="studentDataRecurringExports" />
    </div>
  </div>
</template>

<script>
import UserImportThisWeeksUploads from './UserImportThisWeeksUploads'
import UserImportPriorUploads from './UserImportPriorUploads'
import UsersRecurringImport from './UsersRecurringImport'
import StudentDataRecurringExportsList from './StudentDataRecurringExportsList'
import { SiteAdminUserImportService } from '../api'

export default {
  name: 'UserImports',
  components: {
    UserImportThisWeeksUploads,
    UserImportPriorUploads,
    UsersRecurringImport,
    StudentDataRecurringExportsList,
  },
  props: {
    currentImports: {
      type: Array,
      required: true,
    },
    expiredImports: {
      type: Array,
      required: true,
    },
    usersRecurringImport: {
      type: Object,
      require: false,
      default: null,
    },
    studentDataRecurringExports: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      currentUserImports: this.currentImports,
      expiredUserImports: this.expiredImports,
    }
  },
  created() {
    setInterval(this.fetchImports, 2000)
  },
  methods: {
    fetchImports() {
      SiteAdminUserImportService.fetch().then((data) => {
        this.currentUserImports = data.current_imports
        this.expiredUserImports = data.expired_imports
      })
    },
  },
}
</script>
