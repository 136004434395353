import { HTTP } from 'vue_features/shared/helpers/http_helper'

const CommentableService = {
  fetchComments(commentablePath) {
    const path = commentablePath + '/comments'
    return HTTP.getJSON(path)
  },

  createComment(commentablePath, params) {
    const path = commentablePath + '/comments'
    return HTTP.create(path, { comment: params })
  },

  updateComment(commentablePath, params) {
    const path = commentablePath
    return HTTP.patch(path, { comment: params })
  },

  deleteComment(commentablePath) {
    const path = commentablePath
    return HTTP.delete(path)
  },
}

export default CommentableService
