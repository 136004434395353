<template>
  <div class="relative flex h-full overflow-hidden">
    <div class="flex flex-grow">
      <slot />
    </div>

    <Sidebar :open="open" @toggle="$emit('toggle')">
      <slot name="sidebar" />
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from './Sidebar'

export default {
  name: 'SidebarContainer',
  components: { Sidebar },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
