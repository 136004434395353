<template>
  <div class="troubleshooter mx-auto max-w-7xl px-3 md:px-6">
    <noscript class="my-4 block overflow-hidden rounded-md bg-yellow-50 p-4">
      {{ $t('troubleshooter.js-disabled-notice') }}
    </noscript>

    <div v-if="isTestingResources || isTestingWebSockets" id="testing">
      <h3>{{ $t('troubleshooter.testing.header') }}</h3>
      <p>{{ $t('troubleshooter.testing.delay') }}</p>
    </div>

    <div v-else id="finished-testing">
      <div v-if="!hasFailures" id="success">
        <h3>{{ $t('troubleshooter.pass.header') }}</h3>
        <p>{{ $t('troubleshooter.pass.summary') }}</p>

        <i18n-t scope="global" tag="p" keypath="troubleshooter.pass.speed_issue_suggestions">
          <template #speedTestLink>
            <a href="http://speedtest.net" target="_blank">
              {{ $t('troubleshooter.pass.connection_speed_test') }}
            </a>
          </template>
        </i18n-t>
        <i18n-t scope="global" tag="p" keypath="troubleshooter.pass.contact_if_other_issues">
          <template #contactUsLink>
            <a :href="Routes.page_path('contact')">{{ capitalize($t('common.contact_us')) }}</a>
          </template>
        </i18n-t>
      </div>

      <div v-else id="failure">
        <h3>{{ $t('troubleshooter.fail.header') }}</h3>
        <i18n-t scope="global" tag="p" keypath="troubleshooter.fail.address_and_contact_us">
          <template #contactUsLink>
            <a :href="Routes.page_path('contact')">{{ capitalize($t('common.contact_us')) }}</a>
          </template>
        </i18n-t>
      </div>

      <div v-if="hasCdnFailures" id="cdn">
        <h1>{{ $t('troubleshooter.cdn.header') }}</h1>

        <p>
          {{ $t('troubleshooter.cdn.summary_whitelist') }}
          <strong>{{ $t('troubleshooter.cdn.causes_all_test_failure') }}</strong>
          {{ $t('troubleshooter.browser_must_reach_urls_info') }}
        </p>

        <ul v-for="resource in resources['cdn'].images" :key="resource.url">
          <li>{{ resource.url }}</li>
        </ul>
      </div>

      <div v-if="hasUrlsToWhitelist" id="whitelisting" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.whitelisting.header') }}</h3>
        <i18n-t scope="global" tag="p" keypath="troubleshooter.whitelisting.summary" class="mb-2 break-words text-sm">
          <template #andSubdomains>
            <strong>{{ $t('troubleshooter.whitelisting.and_subdomains') }}</strong>
          </template>
        </i18n-t>

        <ul class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">learnosity.com</li>
          <li class="break-words text-sm">learnositymediaprocessed.s3.amazonaws.com</li>
          <li class="break-words text-sm">ilclassroom.com</li>
          <li class="break-words text-sm">{{ assetHost }}</li>
          <li class="break-words text-sm">wistia.com</li>
          <li class="break-words text-sm">wistia.net</li>
        </ul>

        <p class="mb-0 break-words text-sm">{{ $t('troubleshooter.whitelisting.cannot_whitelist_all') }}</p>
      </div>

      <div v-if="!isBrowserSupported()" id="browser" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.browser.header') }}</h3>

        <p class="mb-2 break-words text-sm">
          <i18n-t scope="global" tag="span" keypath="troubleshooter.browser.recommend_chrome">
            <template #googleChromeLink>
              <a href="https://www.google.com/chrome/browser/" target="_blank" class="font-bold underline">
                Google Chrome
              </a>
            </template>
          </i18n-t>
          {{ $t('troubleshooter.browser.support') }}
        </p>

        <ul class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">{{ $t('troubleshooter.browser.versions.chrome') }}</li>
          <li class="break-words text-sm">{{ $t('troubleshooter.browser.versions.safari') }}</li>
          <li class="break-words text-sm">{{ $t('troubleshooter.browser.versions.edge') }}</li>
          <li class="break-words text-sm">{{ $t('troubleshooter.browser.versions.mobile-safari') }}</li>
          <li class="break-words text-sm">{{ $t('troubleshooter.browser.versions.android-browser') }}</li>
        </ul>
      </div>

      <div v-if="!isOsSupported()" id="operating-system" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.os.header') }}</h3>
        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.os.summary') }}</p>

        <ul class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">
            Microsoft
            <ul class="ml-0 pl-8">
              <li class="break-words text-sm">Windows 10</li>
            </ul>
          </li>
          <li class="break-words text-sm">
            Apple
            <ul class="ml-0 pl-8">
              <li class="break-words text-sm">macOS Catalina (10.15)</li>
              <li class="break-words text-sm">macOS Big Sur (11)</li>
              <li class="break-words text-sm">iOS 13</li>
              <li class="break-words text-sm">iOS 14</li>
            </ul>
          </li>
          <li class="break-words text-sm">
            Google
            <ul class="ml-0 pl-8">
              <li class="break-words text-sm">Chrome OS</li>
              <li class="break-words text-sm">
                Android Oreo ({{
                  $t('troubleshooter.os.version_range', {
                    old_version: '8.0',
                    new_version: '8.1',
                  })
                }})
              </li>
              <li class="break-words text-sm">Android Pie (9)</li>
              <li class="break-words text-sm">Android 10</li>
              <li class="break-words text-sm">Android 11</li>
            </ul>
          </li>
        </ul>
      </div>

      <div v-if="!isWebSocketsSupported" id="websockets" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.websockets.header') }}</h3>
        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.websockets.summary') }}</p>
        <p class="mb-0 break-words text-sm">{{ $t('troubleshooter.websockets.whitelist_notice') }}</p>
      </div>

      <div v-if="hasWistiaFailures" id="wistia" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.wistia.header') }}</h3>
        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.wistia.summary_whitelist') }}</p>
        <i18n-t scope="global" tag="p" keypath="troubleshooter.wistia.cannot_whitelist_all">
          <template #whitelistingLink>
            <a href="#whitelisting" class="font-bold underline">{{ $t('troubleshooter.wistia.whitelisting') }}</a>
          </template>
        </i18n-t>

        <ul class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">distillery.wistia.com</li>
          <li class="break-words text-sm">embed-ssl.wistia.com</li>
          <li class="break-words text-sm">embed.wistia.com</li>
          <li class="break-words text-sm">embed-a.wistia.com</li>
          <li class="break-words text-sm">embed-e.wistia.com</li>
          <li class="break-words text-sm">fast.wistia.com</li>
          <li class="break-words text-sm">fast.wistia.net</li>
        </ul>

        <i18n-t
          scope="global"
          tag="p"
          keypath="troubleshooter.wistia.upstream_documentation"
          class="mb-2 break-words text-sm"
        >
          <template #wistiaRequirementsLink>
            <a href="http://wistia.com/doc/viewer-requirements" class="font-bold underline">
              {{ $t('troubleshooter.wistia.video_viewing_requirements') }}
            </a>
          </template>
        </i18n-t>
        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.browser_must_reach_urls_info') }}</p>

        <ul v-for="resource in resources['wistia'].images" :key="resource.url" class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">{{ resource.url }}</li>
        </ul>
      </div>

      <div v-if="hasFilestackFailures" id="filestack" class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4">
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.filestack.header') }}</h3>
        <p class="mb-2 break-words text-sm">{{
          $t('troubleshooter.filestack.summary_whitelist', { filestackHost })
        }}</p>
        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.filestack.cannot_whitelist_all') }}</p>

        <ul class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">{{ `api.${filestackHost}` }}</li>
          <li class="break-words text-sm">{{ `assets.${filestackHost}` }}</li>
          <li class="break-words text-sm">{{ `cdn.${filestackHost}` }}</li>
          <li class="break-words text-sm">{{ `dialog.${filestackHost}` }}</li>
          <li class="break-words text-sm">{{ `process.${filestackHost}` }}</li>
          <li class="break-words text-sm">{{ `www.${filestackHost}` }}</li>
        </ul>

        <p class="mb-2 break-words text-sm">{{ $t('troubleshooter.browser_must_reach_urls_info') }}</p>

        <ul v-for="resource in resources['filestack'].images" :key="resource.url" class="mb-0 ml-0 pl-8">
          <li class="break-words text-sm">{{ resource.url }}</li>
        </ul>
      </div>

      <div v-if="hasYoutubeFailures" id="youtube" class="my-4 overflow-hidden rounded-md bg-blue-100 p-4">
        <h3 class="text-blue mb-2 mt-0 text-base">{{ $t('troubleshooter.youtube.header') }}</h3>
        <i18n-t
          scope="global"
          tag="p"
          keypath="troubleshooter.youtube.summary_whitelist"
          class="mb-0 break-words text-sm"
        >
          <template #youtubeLink>
            <a href="https://support.google.com/a/topic/6206681" class="font-bold underline">
              {{ $t('troubleshooter.youtube.whitelisting_youtube_safely') }}
            </a>
          </template>
        </i18n-t>
      </div>

      <div v-if="hasLearnosityFailures" id="learnosity" class="my-4 overflow-hidden rounded-md bg-blue-100 p-4">
        <h3 class="text-blue mb-2 mt-0 text-base">{{ $t('troubleshooter.learnosity.header') }}</h3>
        <i18n-t
          scope="global"
          tag="p"
          keypath="troubleshooter.learnosity.summary_whitelist"
          class="mb-0 break-words text-sm"
        >
          <template #learnosityLink>
            <a href="https://docs.learnosity.com/faqs/system" class="font-bold underline">
              {{ $t('troubleshooter.learnosity.whitelist_learnosity_domains') }}
            </a>
          </template>
        </i18n-t>
      </div>

      <div
        v-if="!viewportLargeEnoughForViewing()"
        id="viewport-viewing"
        class="my-4 overflow-hidden rounded-md bg-yellow-50 p-4"
      >
        <h3 class="mb-2 mt-0 text-base text-red-600">{{ $t('troubleshooter.viewport.viewing.header') }}</h3>
        <p class="mb-0 break-words text-sm">{{ $t('troubleshooter.viewport.viewing.summary') }}</p>
      </div>

      <div
        v-if="!viewportLargeEnoughForEditing()"
        id="viewport-editing"
        class="my-4 overflow-hidden rounded-md bg-blue-100 p-4"
      >
        <h3 class="text-blue mb-2 mt-0 text-base">{{ $t('troubleshooter.viewport.editing.header') }}</h3>
        <p class="mb-0 break-words text-sm">{{ $t('troubleshooter.viewport.editing.summary') }}</p>
      </div>

      <div v-if="!isFullscreenSupported()" id="fullscreen" class="my-4 overflow-hidden rounded-md bg-blue-100 p-4">
        <h3 class="text-blue mb-2 mt-0 text-base">{{ $t('troubleshooter.viewport.fullscreen.header') }}</h3>
        <p class="mb-0 break-words text-sm">{{ $t('troubleshooter.viewport.fullscreen.summary') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// FIXME: add analytics testing to make sure we can hit services like mixpanel (https://cdn.mxpnl.com)
import { onMounted } from 'vue'
import { capitalize, concat, forOwn, includes, map } from 'lodash'
import { asset } from 'utils/config'
import URI from 'urijs'
import {
  SUPPORTED_OSES,
  SUPPORTED_BROWSERS,
  requestImageAsset,
  requestJavascriptAsset,
  viewportWithin,
  parseVersion,
} from '../api'
import Routes from 'routes'
import platform from 'platform'
import useWebSocketSupport from 'vue_features/shared/composables/use_web_socket_support'

export default {
  name: 'Troubleshooter',
  props: {
    filestackHost: { type: String, required: true },
    resources: { type: Object, required: true },
  },
  setup() {
    const {
      testing: isTestingWebSockets,
      supported: isWebSocketsSupported,
      check: checkWebSocketSupport,
    } = useWebSocketSupport()

    onMounted(checkWebSocketSupport)

    const assetHost = new URI(asset.hostUrl || '').hostname()

    return { Routes, capitalize, isWebSocketsSupported, isTestingWebSockets, assetHost }
  },

  data() {
    return {
      failedResources: [],
      isTestingResources: true,
    }
  },

  computed: {
    hasCdnFailures() {
      return includes(this.failedResources, 'cdn')
    },

    hasFilestackFailures() {
      return includes(this.failedResources, 'filestack')
    },

    hasLearnosityFailures() {
      return includes(this.failedResources, 'learnosity')
    },

    hasWistiaFailures() {
      return includes(this.failedResources, 'wistia')
    },

    hasYoutubeFailures() {
      return includes(this.failedResources, 'youtube')
    },

    hasFailures() {
      return (
        this.failedResources.length > 0 ||
        !this.isWebSocketsSupported ||
        !this.isBrowserSupported() ||
        !this.isOsSupported() ||
        !this.viewportLargeEnoughForViewing() ||
        !this.viewportLargeEnoughForEditing() ||
        !this.isFullscreenSupported()
      )
    },

    hasUrlsToWhitelist() {
      return this.hasCdnFailures || this.hasWistiaFailures || this.hasFilestackFailures
    },
  },

  mounted() {
    this.checkResources()
  },

  methods: {
    viewportLargeEnoughForViewing() {
      return viewportWithin(320, 568)
    },

    viewportLargeEnoughForEditing() {
      return viewportWithin(940, 520)
    },

    isVersionSupported(current, max, min) {
      if (current.major == max.major) {
        if (max.minor === undefined) {
          return true
        } else {
          if (max.major == min.major) {
            return current.minor <= max.minor && current.minor >= min.minor
          } else {
            return current.minor <= max.minor
          }
        }
      } else if (current.major == min.major) {
        if (min.minor === undefined) {
          return true
        } else {
          return current.minor >= min.minor
        }
      } else {
        return current.major >= min.major && current.major <= max.major
      }
    },

    isOsSupported() {
      let osFamily = platform.os.family
      if (osFamily.includes('Windows')) {
        osFamily = 'Windows'
      }
      if (osFamily.includes('Chrome OS')) {
        osFamily = 'Chrome OS'
      }
      if (!Object.keys(SUPPORTED_OSES).includes(osFamily)) {
        return false
      }
      if (SUPPORTED_OSES[osFamily] === true) return true

      const current = parseVersion(platform.os.version)
      const max = parseVersion(SUPPORTED_OSES[osFamily].max)
      const min = parseVersion(SUPPORTED_OSES[osFamily].min)

      return this.isVersionSupported(current, max, min)
    },

    isBrowserSupported() {
      const browserName = platform.name
      if (!Object.keys(SUPPORTED_BROWSERS).includes(browserName)) {
        return false
      }

      const current = parseVersion(platform.version)
      const max = parseVersion(SUPPORTED_BROWSERS[browserName].max)
      const min = parseVersion(SUPPORTED_BROWSERS[browserName].min)

      return this.isVersionSupported(current, max, min)
    },

    isFullscreenSupported() {
      return (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      )
    },

    checkResource(assets, resourceType, requestMethod) {
      if (!assets) return []

      return map(assets, (asset) => {
        return requestMethod(asset.url || asset.object).catch(() => {
          this.failedResources.push(resourceType)
        })
      })
    },

    checkResources() {
      let allRequests = []

      forOwn(this.resources, (assets, resourceType) => {
        allRequests = concat(
          allRequests,
          this.checkResource(assets.images, resourceType, requestImageAsset),
          this.checkResource(assets.jsImports, resourceType, requestJavascriptAsset),
        )
      })

      Promise.all(allRequests).then(() => (this.isTestingResources = false))
    },
  },
}
</script>
