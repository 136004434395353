<template>
  <li class="flex flex-col rounded-2xl px-4 py-6 shadow">
    <ProgressCover :visible="isSaving" class="loading-spinner__cover" />
    <slot />
    <div class="flex-grow">
      <CardBuilder
        v-if="hasType"
        v-bind="{ cardType, content: contentInEdit, isPersisted, errors }"
        v-model="cardTitle"
        @clear-content="clearContent"
        @update-card="assignCardType($event.type, $event.includesCard)"
        @update-content="updateContent"
      />
      <CardTypePicker
        v-else
        :available-types="$options.availableTypes"
        class="card-types my-6"
        @pick-type="assignType"
      />
      <Alert
        v-if="errors.length > 0 && !(errors.includes('required') || errors.includes('maxlength'))"
        :title="$t('index.encountered_errors')"
        :description="errors"
        data-test="item-errors"
        type="error"
        dismissible
        class="my-4"
      />
    </div>
    <div class="flex justify-end gap-2">
      <button v-if="isPersisted || hasType" class="btn" @click="back">
        {{ isPersisted ? $t('common.cancel') : $t('common.back') }}
      </button>
      <button v-else class="btn btn--primary caution" @click="removeCard(card)">{{ $t('common.remove') }}</button>
      <button
        v-if="hasType"
        data-test="additional-material-save"
        class="btn btn--secondary"
        style="display: inline-block"
        @click="save"
      >
        {{ $t('common.save') }}
      </button>
    </div>
  </li>
</template>

<script>
import CardTypePicker from './CardTypePicker'
import CardBuilder from './CardBuilder'
import { Alert, ProgressCover } from 'vue_features/shared/components/ui'
import { ref, computed, inject } from 'vue'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'

export default {
  availableTypes: ['LessonPlan', 'Document', 'Wiki', 'Video', 'Hyperlink', 'Title', 'Image', 'Audio', 'Existing'],
  nonResourceTypes: ['Existing', 'Title', 'LessonPlan'],
  name: 'CardEditItem',
  components: { ProgressCover, Alert, CardTypePicker, CardBuilder },
  props: { card: { type: Object, required: true } },
  setup(props) {
    const root = inject('useRoot')()
    const { cardServiceFor, removeCard, toggleEditing } = useAdditionalMaterialsStore()

    const cardTitle = ref(props.card.title || '')
    const hasMissingContent = computed(() => props.card.content === null && !isTitleCard(props.card))
    const cardType = ref(hasMissingContent.value ? null : props.card.type)
    const includesCard = ref(false)
    const cardInEdit = computed(() => {
      return {
        ...props.card,
        title: cardTitle.value,
        type: cardType.value,
        includesCard: includesCard.value,
        contexts: ['additional_material'],
      }
    })

    const originalContent = computed(() => props.card.content || {})
    const contentInEdit = ref(originalContent.value)
    if (isTitleCard(props.card)) {
      contentInEdit.value.type = 'Title'
    }

    const { submit, isSaving, errors, resetErrors } = cardServiceFor({
      cardInEdit,
      contentInEdit,
      original: originalContent,
      onSubmit: () => {
        toggleEditing(props.card)
      },
    })

    const save = () => {
      if (contentInEdit.value.type === 'Existing' && !contentInEdit.value.id) {
        errors.value = [root.$t('card_form_templates.enter_url_error')]
      } else {
        submit()
      }
    }

    const updateContent = (payload) => {
      Object.entries(payload).forEach(([key, value]) => {
        contentInEdit.value[key] = value
      })
    }

    return {
      save,
      errors,
      resetErrors,
      contentInEdit,
      isSaving,
      hasMissingContent,
      cardTitle,
      cardType,
      includesCard,
      removeCard,
      updateContent,
      toggleEditing,
    }
  },
  computed: {
    hasType() {
      return !!this.cardType
    },
    isPersisted() {
      return !!this.card.id
    },
  },
  methods: {
    assignCardTypeFrom(contentType) {
      if (this.$options.nonResourceTypes.includes(contentType)) {
        this.cardType = contentType === 'Existing' ? 'ResourceCard' : `${contentType}Card`
      } else {
        this.cardType = 'ResourceCard'
      }
    },
    assignCardType(cardType, includesCard) {
      this.cardType = cardType
      this.includesCard = includesCard
    },
    assignType(contentType) {
      this.contentInEdit = { name: this.cardTitle, type: contentType }
      this.assignCardTypeFrom(contentType)
    },
    clearContent() {
      this.assignCardType(null)
      this.includesCard = false
      this.contentInEdit = {}
    },
    back() {
      this.resetErrors()
      if (this.isPersisted) {
        this.toggleEditing(this.card)
      } else {
        this.cardTitle = ''
        this.cardType = null
      }
    },
  },
}
</script>
