<template>
  <LzModal
    v-if="shouldModalShow && canModalShow($options.name)"
    id="live-learn-modal"
    data-test="live-learn-modal"
    :modal-id="$options.name"
    :modal-title="$t('live_learn_prompt.start_live_learn')"
    :autoclose="false"
    class="lzui-modal--large"
    @close-modal="closeModal($options.name)"
  >
    <template v-if="hasKlasses">
      <div v-if="isTestingWebSockets" class="flex items-center gap-x-2" data-test="checking-for-websocket-support">
        <LoadingSpinner class="m-0" />
        <span>{{ $t('live_learn_prompt.checking_for_support') }}</span>
      </div>

      <EmptyState
        v-else-if="!isWebSocketsSupported"
        :heading="$t('live_learn_prompt.not_supported_heading')"
        :body="$t('live_learn_prompt.not_supported_body')"
        data-test="websockets-not-supported"
      />

      <AsyncContentLoader v-else :loader="initializeKlasses">
        <Alert
          v-show="lessonType === 'assessment'"
          type="notice"
          :title="$t('live_learn_prompt.warning')"
          :description="$t('live_learn_prompt.assignments_will_open')"
        />
        <Alert
          v-if="canDismissibleShow(ALERT_ACCESSIBILITY)"
          dismissible
          @dismissed="closeDismissible(ALERT_ACCESSIBILITY)"
        >
          <p class="mb-0 text-sm leading-6 text-blue-800">
            <span class="font-bold">{{ $t('live_learn_prompt.a11y_alert_title') }}</span>
            <i18n-t tag="span" keypath="live_learn_prompt.a11y_alert_description" scope="global">
              <template #link>
                <a
                  class="font-bold"
                  href="https://ilclassroom.com/resources/11148273-accessibility-compliance?path=Wiki.1783053/Wiki.1783046"
                  target="_blank"
                  >{{ $t('common.here') }}</a
                >
              </template>
            </i18n-t>
          </p>
        </Alert>

        <ul class="live-learn-class-list no-bullet space-y-3">
          <li
            v-for="klass in visibleItems"
            :key="klass.id"
            class="no-fullstory bg-focus rounded-md border border-b-2 border-solid transition-all"
            :class="
              klass.activeCount > 0
                ? 'hover:border-base-hover hover:bg-focus-hover shadow hover:shadow-md'
                : 'border-gray-400'
            "
          >
            <label
              class="mb-0 flex items-center gap-3 px-3 py-4"
              :for="`live-learn-klass-${klass.id}`"
              :class="{ 'cursor-not-allowed': klass.activeCount === 0, 'cursor-pointer': klass.activeCount > 0 }"
            >
              <input
                :id="`live-learn-klass-${klass.id}`"
                :value="klass.id"
                :disabled="klass.activeCount === 0"
                name="selected-klass"
                type="radio"
                class="outline-none m-0 inline h-4 w-4"
                data-test="klass-list-option"
                @input="setSelectedKlass(klass)"
              />
              <p
                class="mb-0 inline-block flex-grow truncate font-bold leading-6"
                :class="klass.activeCount > 0 ? 'text-blue-700' : 'text-gray-400'"
              >
                {{ klass.name }}
              </p>
              <div :class="['flex items-center ', { 'text-gray-400': !klass.activeCount }]">
                <LzIcon path="icons/user-group" :class="klass.activeCount > 0 ? 'text-neutral-700' : 'text-gray-400'" />
                <span class="w-7 text-center text-sm font-bold">{{ klass.activeCount }}</span>
              </div>
            </label>
          </li>

          <li v-if="canLoadMore">
            <LoadMoreButton class="pt-2" v-bind="{ isLoading }" @click="showMoreItems(handleLoadMore)">
              {{ $t('assign_to_student_tab.load_more_classes') }}
            </LoadMoreButton>
          </li>
        </ul>
      </AsyncContentLoader>

      <footer>
        <button
          id="primary-button"
          class="btn btn--primary"
          data-test="primary-modal-button"
          :disabled="!selectedKlasses.length"
          @click="assign"
        >
          {{ $t('common.get_started') }}
        </button>
      </footer>
    </template>
    <NoKlassesEmptyState v-else class="w-screen" />
  </LzModal>
</template>

<script>
import { computed, onMounted, inject } from 'vue'
import Routes from 'routes'
import location from 'utils/location'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import NoKlassesEmptyState from './NoKlassesEmptyState'
import { LoadMoreButton } from 'vue_features/shared/components/lists'
import {
  LzIcon,
  LzModal,
  AsyncContentLoader,
  EmptyState,
  Alert,
  LoadingSpinner,
} from 'vue_features/shared/components/ui'
import { LzAnalytics } from 'clients'
import { useGlobalStore, useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { useDismissibles } from 'vue_features/shared/composables'
import useWebSocketSupport from 'vue_features/shared/composables/use_web_socket_support'

const ALERT_ACCESSIBILITY = 'alert-accessibility'

export default {
  name: 'LiveLearnModal',
  components: {
    LzIcon,
    LzModal,
    NoKlassesEmptyState,
    AsyncContentLoader,
    LoadMoreButton,
    EmptyState,
    Alert,
    LoadingSpinner,
  },
  props: {
    lzCode: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isContentEmbedded } = useGlobalStore()
    const { authorizedAsTeacher } = useCurrentUserStore()
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    const { lessonPlanId, lessonType, analyticsProperties } = useLessonPlanStore()
    const { hasKlasses, klasses, klassesTotal, selectedKlasses, initializeKlasses, loadMoreKlasses, setSelectedKlass } =
      useAssignmentPromptStore()
    const { closeDismissible, showDismissible, canDismissibleShow } = useDismissibles()
    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: klasses,
      totalCount: klassesTotal,
      startCount: 5,
      moreCount: 5,
    })
    const {
      testing: isTestingWebSockets,
      supported: isWebSocketsSupported,
      check: checkWebSocketSupport,
    } = useWebSocketSupport()

    const eventSource = inject('eventSource', '')

    const shouldModalShow = computed(
      () => hasStudentFunctionality.value && authorizedAsTeacher.value && !isContentEmbedded.value,
    )

    function trackLiveLearnLaunched(lzCode) {
      const eventName = 'Live Learn Launched'

      LzAnalytics.track(eventName, {
        ...analyticsProperties.value,
        action: eventName,
        category: 'assignments',
        'LZ Code': lzCode,
        source: eventSource,
      })
    }

    const assign = async () => {
      const klassId = selectedKlasses.value[0]?.id

      let lzCode

      if (props.lzCode == '') {
        const assignments = await AssignmentsService.assignStudents({
          lessonPlanIds: [lessonPlanId.value],
          klassIds: [klassId],
          autoOpen: true,
        })
        lzCode = assignments[0].code
      } else {
        await AssignmentsService.autoOpenKlass({ lzCode: props.lzCode, klassId })
        lzCode = props.lzCode
      }

      await trackLiveLearnLaunched(lzCode)

      location.assign(Routes.live_learn_klass_path(lzCode, klassId))
    }

    showDismissible(ALERT_ACCESSIBILITY)

    onMounted(checkWebSocketSupport)

    return {
      canModalShow,
      closeModal,
      lessonType,
      hasKlasses,
      isWebSocketsSupported,
      isTestingWebSockets,
      selectedKlasses,
      initializeKlasses,
      setSelectedKlass,
      visibleItems,
      canLoadMore,
      ALERT_ACCESSIBILITY,
      closeDismissible,
      canDismissibleShow,
      showDismissible,
      showMoreItems,
      isLoading,
      shouldModalShow,
      assign,
      handleLoadMore: (done) => loadMoreKlasses().then(done),
    }
  },
}
</script>
