<template>
  <LzModal
    :auto="false"
    :can-cancel="false"
    modal-id="lp-info-form-modal"
    class="lzui-modal--large lzui-modal--confirmation"
    style="text-align: left"
  >
    <InfoForm
      :lesson-plan="lessonPlan"
      class="m-auto max-w-7xl p-3 md:p-6"
      @cancel="closeModal('InfoFormModal')"
      @lesson-plan-updated="updateLessonPlan"
    />
  </LzModal>
</template>

<script>
import InfoForm from '../../components/InfoForm'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import LzModal from 'vue_features/shared/components/ui/LzModal'

export default {
  name: 'InfoFormModal',
  components: { LzModal, InfoForm },
  setup() {
    const { lessonPlan } = useLessonPlanStore()

    const updateLessonPlan = (response) => {
      lessonPlan.value = response
      closeModal('InfoFormModal')
    }

    return { lessonPlan, closeModal, updateLessonPlan }
  },
}
</script>
