import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const KlassesShowService = {
  getStudents({ klassId, page }) {
    return HTTP.getJSON(Routes.students_teacher_klass_path(klassId), { page })
  },
  search(klassId, query, excludedIds, offset) {
    const params = { klassId, query, excludedIds, offset }

    return HTTP.getJSON(Routes.teacher_students_search_path(), snakeKeys(params))
  },
}

export default KlassesShowService
