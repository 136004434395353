<template>
  <img v-if="fileExists" :src="fileUrl" alt="logo" aria-hidden="true" />
  <InlineSvg v-else path="logo/logo-emblem.svg" aria-hidden="true" />
</template>

<script>
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'

export default {
  name: 'LogoImage',
  components: { InlineSvg },
  props: {
    logo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { fileExists, filename, fileUrl } = useFilestackAccessor(props, 'logo')
    return { fileExists, filename, fileUrl }
  },
}
</script>
