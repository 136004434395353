<template>
  <div class="lp-deck__start left-0 top-0 h-full w-full" style="position: absolute">
    <div
      :class="['card__resource-contents', { 'card__resource-contents--cover': coverImageExists }]"
      :style="`backgroundImage: ${coverImageBackground}`"
    >
      <button class="btn btn--primary btn--xlarge" @click="$emit('begin')">
        <span>
          {{
            $t('lesson_card_templates.begin_lesson', {
              lesson_type: $t(`readable_types.shortened.${lessonPlan.lessonType}`),
            })
          }}
        </span>
        <LzIcon icon-color-class="text-white" path="icons/next" />
      </button>
    </div>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { computed } from 'vue'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'

export default {
  name: 'BeginCard',
  components: { LzIcon },
  setup() {
    const { lessonPlan } = useLessonPlanStore()
    const coverImageExists = computed(() => {
      return lessonPlan.value.coverImage && lessonPlan.value.coverImage.baseUrl
    })
    const coverImageBackground = computed(() => {
      if (coverImageExists.value) {
        const { fileUrl } = useFilestackAccessor(lessonPlan.value.coverImage)
        return `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${fileUrl.value})`
      } else {
        return ''
      }
    })
    return { lessonPlan, coverImageExists, coverImageBackground }
  },
}
</script>
