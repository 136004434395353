import consumer from 'channels/consumer.js'

export const testWebSocketConnection = (waitFor = 5000) => {
  return new Promise((resolve) => {
    let troubleshooterChannel
    let waitTimer

    {
      waitTimer = setTimeout(() => {
        if (troubleshooterChannel) {
          troubleshooterChannel.unsubscribe()
        }

        resolve(false)
      }, waitFor)
    }

    {
      troubleshooterChannel = consumer.subscriptions.create('TroubleshooterChannel', {
        connected() {
          this.send({})
        },
        received() {
          clearTimeout(waitTimer)
          this.unsubscribe()
          resolve(true)
        },
      })
    }
  })
}
