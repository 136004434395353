import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import {
  CollectionExportService,
  CollectionSharesService,
  CollectionsService,
  PublishJobsService,
  SharesBatchService,
} from '../api'

export const useCollectionsIndexStore = defineStore('collectionsIndex', () => {
  const state = reactive({
    collectionsData: {
      collections: [],
      meta: {
        pagination: {
          totalCount: 0,
          currentPage: 1,
          totalPages: 1,
        },
      },
    },
    pendingInvitesData: {
      collections: [],
      meta: {
        pagination: {
          totalCount: 0,
          currentPage: 1,
          totalPages: 1,
        },
      },
    },
    isProgressCoverShown: false,
  })

  const collections = computed(() => state.collectionsData.collections)
  const pendingInvites = computed(() => state.pendingInvitesData.collections)

  function getCollections(page) {
    return CollectionsService.get({ status: 'active', page }).then((collectionsData) => {
      state.collectionsData = collectionsData
    })
  }

  function getPendingInvites(page) {
    return CollectionsService.get({ status: 'pending', page }).then((pendingInvitesData) => {
      state.pendingInvitesData = pendingInvitesData
    })
  }

  function createCollection(wikiId) {
    return CollectionsService.create(wikiId)
  }

  function destroyCollection(collection) {
    const collections = state.collectionsData.collections
    const index = collections.indexOf(collection)
    collections.splice(index, 1)

    state.collectionsData.meta.pagination.totalCount--
  }

  function deleteCollection(collection) {
    if (collection.customerShare) {
      return CollectionSharesService.destroy(collection.customerShare).then(() => {
        destroyCollection(collection)
      })
    } else {
      return CollectionsService.destroy(collection).then(() => {
        destroyCollection(collection)
      })
    }
  }

  function exportCollection(collectionId) {
    return CollectionExportService.get(collectionId)
  }

  function createCollectionShares({ collection, subdomains }) {
    return SharesBatchService.create({ collection, subdomains }).then((newCollectionData) => {
      const collections = state.collectionsData.collections
      const index = collections.indexOf(collection)
      if (index >= 0) {
        collections[index].sharesData = newCollectionData.shares_data
      }
      return newCollectionData.invalid_subdomains
    })
  }

  function updateShare({ share, updatedShare }) {
    share.status = updatedShare.status
  }

  function acceptCollectionShare(share) {
    return CollectionSharesService.accept(share).then((updatedShare) => {
      updateShare({ share, updatedShare })
    })
  }

  function deleteCollectionShare(share) {
    return CollectionSharesService.destroy(share).then((updatedCollection) => {
      const collections = state.collectionsData.collections
      const index = collections.findIndex((collection) => collection.id === updatedCollection.id)
      if (index >= 0) {
        collections.splice(index, 1, updatedCollection)
      }
    })
  }

  function loadMoreCollectionShares({ collection, page }) {
    return CollectionSharesService.get({ collection, page }).then((newSharesData) => {
      const currentSharesData = collection.sharesData
      currentSharesData.shares.push(...newSharesData.shares)
      currentSharesData.meta = newSharesData.meta
    })
  }

  function updateCollectionShare({ share, status }) {
    return CollectionSharesService.update({ share, status }).then((updatedShare) => {
      updateShare({ share, updatedShare })
    })
  }

  function rejectCollectionShare(share) {
    return CollectionSharesService.reject(share).then((updatedShare) => {
      updateShare({ share, updatedShare })
    })
  }

  function publishCollection(collectionId) {
    return PublishJobsService.create(collectionId)
  }

  return {
    state,
    collections,
    pendingInvites,
    getCollections,
    getPendingInvites,
    createCollection,
    deleteCollection,
    exportCollection,
    createCollectionShares,
    acceptCollectionShare,
    deleteCollectionShare,
    loadMoreCollectionShares,
    updateCollectionShare,
    rejectCollectionShare,
    publishCollection,
  }
})
