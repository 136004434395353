<script setup>
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import StandardCode from 'vue_features/shared/components/ui/StandardCode'

defineProps({ standards: { type: Array, required: true } })
const { showStandardsPrefix } = useCurrentUserStore()
</script>

<template>
  <div data-test="inline-standards-table">
    <h3 data-test="inline-standards-header">{{ $t('standards_addressed') }}:</h3>
    <span class="lz-standards">
      <StandardCode
        v-for="standard in standards"
        :key="standard.id"
        :display-code="showStandardsPrefix ? standard.prefixedCode : standard.code"
        :name="standard.name"
        :coverage-type="standard.coverageType"
        :class="`lz-standard initiative-${standard.initiativeId}`"
      />
    </span>
  </div>
</template>
