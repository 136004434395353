<template>
  <ul :class="['my-6 w-full', { 'space-y-4': !isBlock, 'flex flex-wrap': isBlock }]">
    <LzContentItem
      v-for="item in lzContents"
      :key="item.id"
      :item="item"
      :is-block="isBlock"
      :class="['bg-focus', { 'min-h-32': !isBlock }]"
      thumbnail-size="m"
    />
  </ul>
</template>

<script>
import LzContentItem from './LzContentItem'

export default {
  name: 'LzContentList',
  components: { LzContentItem },
  props: {
    lzContents: {
      type: Array,
      required: true,
    },
    isBlock: { type: Boolean, default: false },
  },
}
</script>
