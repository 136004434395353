<template>
  <CardDeck
    v-bind="{ cards, selectedCardId, isEnabled: isNavigationEnabled, activeDeckId }"
    ref="cardDeckEl"
    :deck-id="type"
    :class="['lp-deck col-span-12 mt-4 rounded-2xl', cardClasses]"
    :data-test="`${type}-card-deck`"
    @current-card-change="$emit('update:selectedCardId', $event)"
    @click="activeDeckId = type"
  >
    <template #end-card="{ setCurrentPosition }">
      <AssignmentEndCard :is-active="selectedCardId === END_CARD_ID" @review="setCurrentPosition(0)" />
    </template>
  </CardDeck>
</template>

<script>
import { computed, watch, ref } from 'vue'
import {
  useAssignmentStore,
  useAssignmentCardsStore,
  useLiveLearnStore,
  END_CARD_ID,
} from 'vue_features/assignments/show/composables'
import CardDeck from 'vue_features/assignments/shared/card_deck/CardDeck'
import AssignmentEndCard from './AssignmentEndCard'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { scrollIntoViewWithOffset } from 'utils/scrolling'

export default {
  name: 'AssignmentCardDeck',
  components: { CardDeck, AssignmentEndCard },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['assignment', 'lesson'].indexOf(value) !== -1,
    },
    selectedCardId: {
      type: Number,
      required: false,
      default: 0,
    },
    isToolOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const cardDeckEl = ref(null)
    const { activeFullscreenId } = useFullscreenStore()
    const { hasLessonCards, hasAssignmentCards, viewedAllCards, activeDeckId } = useAssignmentCardsStore()
    const { isLiveLearn, isTeacherPaced, sharingStudentId } = useLiveLearnStore()

    const hasBothTypes = computed(() => hasLessonCards.value && hasAssignmentCards.value)
    const { isAssessment } = useAssignmentStore()
    const { authorizedAsTeacher } = useCurrentUserStore()

    const cardClasses = computed(() => {
      if (activeFullscreenId.value === props.type) {
        return isAssessment.value ? 'assessment-deck' : ''
      } else if (activeFullscreenId.value) {
        return 'hidden'
      } else if (props.isToolOpen) {
        return hasBothTypes.value && props.type === 'lesson'
          ? 'lg:col-span-6 row-start-3 lg:row-start-2'
          : 'lg:col-span-6 row-start-2 lg:row-start-1 lg:col-start-1'
      } else if (hasBothTypes.value) {
        return props.type === 'assignment' ? 'lg:col-span-6' : 'lg:col-span-6'
      }

      return 'one-column lg:col-span-12 lg:col-start-1'
    })
    const isNavigationEnabled = computed(() => {
      if (sharingStudentId.value !== null) {
        return false
      }

      return !isLiveLearn.value || !isTeacherPaced.value || authorizedAsTeacher.value
    })

    watch(
      () => props.selectedCardId,
      (newCardId) => {
        if (newCardId === END_CARD_ID) viewedAllCards()
        // must delay the scrolling until after the card has finished fading into view
        window.requestAnimationFrame(() => {
          if (cardDeckEl.value) scrollIntoViewWithOffset(cardDeckEl.value.$el, 30)
        })
      },
    )

    activeDeckId.value = hasBothTypes.value ? 'lesson' : 'assignment'

    return {
      END_CARD_ID,
      cardDeckEl,
      cardClasses,
      activeDeckId,
      isNavigationEnabled,
    }
  },
}
</script>
