<script setup>
import { inject, computed, onMounted, onUnmounted } from 'vue'
import DtSortArrow from './DataTableSortArrow.vue'
import useSharedCellProperties, { alignmentProps } from './use_shared_cell_properties'

const props = defineProps({
  dataTable: {
    type: Object,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  sortable: {
    type: Boolean,
    default: false,
  },
  autoPlaceSortArrow: {
    type: Boolean,
    default: true,
  },
  ...alignmentProps,
})

const { sortBy } = inject('dataTable', props.dataTable)
const registerColumn = inject('registerColumn', (column) => {})
const unregisterColumn = inject('unregisterColumn', (column) => {})

const { alignmentClasses } = useSharedCellProperties(props)

const buttonClasses = computed(() => {
  if (props.sortable) {
    return ['cursor-pointer']
  } else {
    return ['cursor-auto']
  }
})

function handleClick(event) {
  sortBy(props.name)
}

onMounted(() => {
  registerColumn(props.name)
})

onUnmounted(() => {
  unregisterColumn(props.name)
})
</script>

<template>
  <component
    :is="sortable ? 'button' : 'span'"
    class="bg-inherit relative flex"
    :class="[buttonClasses, alignmentClasses]"
    style="font-weight: inherit; text-transform: inherit"
    @click="handleClick"
  >
    <slot></slot>
    <dt-sort-arrow
      v-if="sortable && autoPlaceSortArrow"
      :column="name"
      class="absolute right-0 top-1/2 -translate-y-1/2 transform"
    />
  </component>
</template>
