<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <DetailListItem
    :item-id="wiki.id"
    :title="wiki.title"
    :thumbnail="getThumbnail(wiki)"
    :href="wiki.href"
    :icon="getIcon(wiki)"
    :description="wiki.description"
    thumbnail-class="min-h-32 rounded-l-2xl"
    thumbnail-style="rounded-l-2xl"
    thumbnail-size="m"
    class="relative"
  >
    <template #header>
      <Alert
        v-if="messageText"
        class="mt-2"
        :description="messageText"
        :dismissible="true"
        @dismissed="messageText = ''"
      />
    </template>
    <template #actions>
      <ListDropdown v-if="!isPublicCustomer" :id="'export-' + collection.id" class="md:ml-auto">
        <template #button>
          {{ $t('export') }}
          <LzIcon size="sm" path="icons/caret" />
        </template>
        <template #listItems>
          <li>
            <a @click.prevent="createCommonCartridge">
              {{ $t('common_cartridge') }}
            </a>
          </li>
        </template>
      </ListDropdown>
      <ListDropdown v-if="!isGloballyShared" :id="'actions-' + collection.id" class="float-right">
        <template #button>
          {{ $t('common.actions') }}
          <LzIcon size="sm" path="icons/caret" />
        </template>
        <template #listItems>
          <li>
            <a v-if="canShareCollection" @click.prevent="openModal(collectionItemFormModalId)">
              {{ $t('share') }}
            </a>
          </li>
          <li>
            <a v-if="!isSharedCollection" @click.prevent="openModal(publishCollectionModalId)">
              {{ $t('common.publish') }}
            </a>
          </li>
          <li>
            <a class="red" @click.prevent="openModal(deleteCollectionModalId)">
              {{ $t('common.remove') }}
            </a>
          </li>
        </template>
      </ListDropdown>
    </template>
    <template v-if="isSharedCollection || isGloballyShared || !isActive" #metadata>
      <li v-if="!isActive">
        <LzContentStateBadge :state="wiki.state" />
      </li>
      <li
        v-if="isSharedCollection || isGloballyShared"
        data-test="from-metadata"
        class="text-neutral-700 hidden max-w-prose text-xs font-bold sm:block"
      >
        {{ collection.customerName }}
      </li>
    </template>
    <template #unlinked-content>
      <DeleteCollectionModal
        v-if="canModalShow(deleteCollectionModalId)"
        :modal-id="deleteCollectionModalId"
        :collection="collection"
      />
      <PublishCollectionModal
        v-if="canModalShow(publishCollectionModalId)"
        :modal-id="publishCollectionModalId"
        :collection="collection"
        @published-collection="showPublishMessage"
      />
      <CollectionItemFormModal
        v-if="canModalShow(collectionItemFormModalId)"
        :modal-id="collectionItemFormModalId"
        :collection="collection"
        @close="closeModal(collectionItemFormModalId)"
      />
    </template>
  </DetailListItem>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { Alert, LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import CollectionItemFormModal from './modals/CollectionItemFormModal'
import DeleteCollectionModal from './modals/DeleteCollectionModal'
import PublishCollectionModal from './modals/PublishCollectionModal'
import { openModal, closeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { getIcon, getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'CollectionItem',
  components: {
    Alert,
    DeleteCollectionModal,
    PublishCollectionModal,
    ListDropdown,
    LzContentStateBadge,
    DetailListItem,
    CollectionItemFormModal,
    LzIcon,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { exportCollection } = useCollectionsIndexStore()
    const { authorizedAsCustomerAdmin } = useCurrentUserStore()
    const { isPublicCustomer } = useCurrentCustomerStore()
    return {
      openModal,
      closeModal,
      canModalShow,
      exportCollection,
      authorizedAsCustomerAdmin,
      isPublicCustomer,
      getIcon,
      getThumbnail,
    }
  },
  data() {
    return {
      isMessageVisible: false,
      messageText: '',
    }
  },
  computed: {
    deleteCollectionModalId() {
      return DeleteCollectionModal.name + this.collection.id
    },
    publishCollectionModalId() {
      return PublishCollectionModal.name + this.collection.id
    },
    collectionItemFormModalId() {
      return CollectionItemFormModal.name + this.collection.id
    },
    canShareCollection() {
      return !this.isSharedCollection && this.authorizedAsCustomerAdmin && this.isActive
    },
    wiki() {
      return this.collection.wiki
    },
    isActive() {
      return isActive(this.wiki)
    },
    isSharedCollection() {
      return !!this.collection.customerShare
    },
    isGloballyShared() {
      return this.collection.globallyShared
    },
  },
  methods: {
    createCommonCartridge() {
      this.exportCollection(this.collection.id)
      this.showExportMessage()
    },
    showExportMessage() {
      this.messageText = this.$t('export_collection_message')
    },
    showPublishMessage() {
      this.messageText = this.$t('publish_collection_message')
    },
  },
}
</script>
