<template>
  <li class="bg-focus border-neutral flex w-full items-center gap-4 border-b-2 border-opacity-20 px-2 py-4">
    <LzIcon path="icons/user" class="text-neutral-500 h-10 w-10" />
    <div class="space-y-1">
      <p class="text-md text-base font-bold leading-6">{{ fullName }}</p>
      <div class="flex flex-wrap gap-2 md:gap-4">
        <p v-if="user.username" class="text-muted text-sm leading-4">{{ user.username }}</p>
        <p v-if="user.email" class="text-muted text-sm leading-4">{{ user.email }}</p>
        <p v-if="user.role" class="border-base text-muted border-l pl-2 text-sm leading-4 md:pl-4">{{ user.role }}</p>
      </div>
    </div>
  </li>
</template>

<script>
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { useNameHelpers } from 'vue_features/shared/composables/use_name_helpers'

export default {
  name: 'UserTile',
  components: { LzIcon },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { fullName } = useNameHelpers(props.user)
    return { fullName }
  },
}
</script>
