<template>
  <div class="lesson-notes">
    <div class="card__notes-wrapper" tabindex="0">
      <div class="lesson-notes__title p-4 text-center">
        <div class="text-primary mt-2 text-lg font-semibold">{{ $t('lesson_plans.card.teaching_notes') }}</div>
      </div>

      <div class="card__notes-body min-h-0">
        <!-- notes sanitized in serializer -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-mathjax v-html="visibleNotes" />
        <template v-if="isLoggedIn">
          <div v-if="showStandards && standardsHtml">
            <strong>{{ $t('common.label.standards') }}</strong>
            <span>{{ standardsHtml }}</span>
          </div>
          <div v-if="isItemCard && contentAvailable && scoringTypeLabel" class="space-x-1">
            <strong>{{ $t('common.scoring_type') }}</strong>
            <span>{{ scoringTypeLabel }}</span>
          </div>
          <div v-if="isItemCard && contentAvailable && pointsDisplay !== null" class="space-x-1">
            <strong>{{ $t('common.label.total_points') }}</strong>
            <span>{{ pointsDisplay }}</span>
          </div>
        </template>
        <div v-if="hasScoringRubric" class="card__notes__scoring-rubric">
          <div class="item-standards">
            <ScoringRubricLink :rubric-url="scoringRubricUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, watch } from 'vue'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useResourceTypes } from 'vue_features/shared/composables'
import ScoringRubricLink from 'vue_features/shared/components/ScoringRubricLink'
import Routes from 'routes'
import { isActive } from 'vue_features/shared/composables/use_record_states'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import useLearnosityItemMetadata from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_metadata'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'

export default {
  name: 'LessonCardNotes',
  components: { ScoringRubricLink },
  props: {
    card: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { isLoggedIn, isStudent } = useCurrentUserStore()
    const { isPublicCustomer, showStandards } = useCurrentCustomerStore()
    const { learnosityConfig } = useLearnosityConfigStore()
    const isItemCard = computed(() => {
      const { isQuestion } = useResourceTypes(props.card.content)
      return isQuestion.value
    })
    const standards = computed(() => (isItemCard.value ? props.card.content.standards : []))
    const customerVisibleStandards = computed(() =>
      isItemCard.value ? props.card.content.customerVisibleStandards : [],
    )
    const currentInitiativeStandards = computed(() => {
      const withCurrentInitiative = isPublicCustomer.value ? standards.value : customerVisibleStandards.value
      return withCurrentInitiative.map((s) => s.displayCode)
    })
    const standardsHtml = computed(() => currentInitiativeStandards.value.join(', '))
    const contentAvailable = computed(() => isActive(props.card.content) || props.card.content?.editable)

    const scoringTypeRef = ref('')
    const availablePointsRef = ref([])

    const { isLoaded } = useLearnosityClientStore()

    watch(
      isLoaded,
      () => {
        if (isItemCard.value && contentAvailable.value) {
          const { scoringType, availablePoints } = useLearnosityItemMetadata(
            props.card.content.content,
            learnosityConfig.value,
          )
          watch(scoringType, () => {
            scoringTypeRef.value = scoringType.value
          })

          watch(availablePoints, () => {
            availablePointsRef.value = availablePoints.value
          })
        }
      },
      {
        immediate: true,
      },
    )

    const scoringTypeLabel = computed(() => {
      if (scoringTypeRef.value === '') return null

      switch (scoringTypeRef.value) {
        case 'dependent':
          return root.$t('common.ebsr_dependent')
        case 'per-question':
          return root.$t('common.per_question')
        default:
          return root.$t(`common.${scoringTypeRef.value}`)
      }
    })
    const hasScoringRubric = computed(() => props.card.content && props.card.content.scoringRubricId)
    const scoringRubricUrl = computed(() => {
      return hasScoringRubric.value ? Routes.resource_url(props.card.content.scoringRubricId) : ''
    })

    const pointsDisplay = computed(() => {
      if (availablePointsRef.value.length === 0) return null

      const round = (p) => Math.round(p * 100) / 100
      const points = availablePointsRef.value
      if (points.length === 1) {
        return round(points[0])
      }

      const total = points.reduce((sum, p) => sum + p, 0)
      return `${round(total)} (${points.map((p) => round(p)).join(',')})`
    })

    const notes = computed(() => {
      return props.card.teachingNotes ? props.card.teachingNotes.content : ''
    })

    const visibleNotes = computed(() => {
      return isStudent.value ? '' : notes.value
    })

    return {
      contentAvailable,
      visibleNotes,
      isLoggedIn,
      showStandards,
      standardsHtml,
      isItemCard,
      scoringTypeLabel,
      hasScoringRubric,
      scoringRubricUrl,
      pointsDisplay,
    }
  },
}
</script>

<style>
.lesson-notes {
  background-color: rgb(245 248 255 / var(--tw-bg-opacity));
}

@media screen {
  .lesson-notes .card__notes-wrapper {
    border: none;
    background-color: unset;
  }
}

.lesson-notes .card__notes-wrapper .card__notes-head {
  border-color: rgba(30 26 101 / 0.55);
}
</style>
