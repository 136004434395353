<template>
  <div class="slide__container ck-editable flex items-center">
    <div ref="slide" :class="['slide bg-white', textStyleClass]">
      <div
        v-for="content in sortedContents"
        :key="content.id"
        :style="gridStyle(content)"
        :class="contentClasses(content)"
      >
        <component :is="componentType(content)" :content="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import * as viewerComponents from './viewer_contents'
import useContentParsers from '../composables/use_content_parsers'
import useSortedContents from '../composables/use_sorted_contents'

export default {
  name: 'SlideViewer',
  components: { ...viewerComponents },
  props: {
    slideJson: {
      type: Object,
      required: true,
    },
    textStyle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { sortedContents } = useSortedContents(props)
    const { gridStyle, contentClasses } = useContentParsers()
    return { sortedContents, gridStyle, contentClasses }
  },
  computed: {
    textStyleClass() {
      return `font-${this.textStyle}`
    },
  },
  methods: {
    componentType(content) {
      return `SlideViewer${capitalize(content.type)}`
    },
  },
}
</script>
