<template>
  <div>
    <button v-if="isShowingAnswers" class="btn--pill hidden" data-test="print-answers-shown-label">
      {{ $t('learnosity_item.answers_shown') }}
    </button>
    <button class="btn--pill" data-test="toggle-button" @click="toggleAnswers">
      {{ isShowingAnswers ? $t('learnosity_item.hide_answers') : $t('learnosity_item.show_answers') }}
    </button>
  </div>
</template>

<script>
import { ref } from 'vue'
import useLearnosityItemResource from '../composables/use_learnosity_item_resource'

export default {
  name: 'LearnosityShowAnswersButton',
  props: {
    itemReference: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isShowingAnswers = ref(false)
    function toggleAnswers() {
      isShowingAnswers.value = !isShowingAnswers.value
      if (isShowingAnswers.value) {
        showAnswers()
      } else {
        hideAnswers()
      }
    }

    const { showAnswers, hideAnswers } = useLearnosityItemResource(props.itemReference, props.config)
    return {
      isShowingAnswers,
      toggleAnswers,
      showAnswers,
      hideAnswers,
    }
  },
}
</script>
