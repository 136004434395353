<template>
  <LzModal
    :modal-title="$t('embed_code_modal.embed_code_title')"
    :modal-id="$options.name"
    class="lzui-modal--medium"
    autoclose
  >
    <p class="text-muted mt-0">
      {{ $t('embed_code_modal.embed_code_instructions') }}
    </p>
    <div id="embed" class="lzui-tile my-4 p-2">
      <code id="embed-code" class="font-mono text-sm">
        {{ embedHtml }}
      </code>
    </div>
    <CopyButton :target-text="embedHtml" />
  </LzModal>
</template>

<script>
import { LzModal, CopyButton } from 'vue_features/shared/components/ui'
import { defaultTo } from 'lodash'
import { config } from 'utils'
import Routes from 'routes'

export default {
  name: 'EmbedCodeModal',
  components: { LzModal, CopyButton },
  props: {
    embedUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    embedHtml() {
      /* eslint no-useless-escape: "off" */
      const embedJsUrl = defaultTo(config.asset.hostUrl, Routes.root_url()) + 'javascripts/lz_embed.js'
      return `<iframe width="100%" height="400" style="height: 80vh; min-height: 400px;" frameborder="0" allowfullscreen src="${this.embedUrl}"></iframe>
      <script type="text/javascript" src="${embedJsUrl}"><\/script>`
    },
  },
}
</script>
