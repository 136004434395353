<template>
  <div class="slide-orderer">
    <div ref="slide" :class="['slide', `font-${textStyle}`]">
      <div v-if="isEmpty" class="center text-muted p-4">
        {{ $t('slides.editor_templates.orderer_empty') }}
      </div>
      <draggable
        v-else
        id="slide-ordered-list"
        v-model="orderedContents"
        item-key="id"
        ghost-class="outline-placeholder"
        drag-class="ui-sortable-helper"
        class="slide__fill"
      >
        <template #item="{ element: content }">
          <div class="slide__content slide__content--vcenter slide__content--hcenter">
            <div class="drag-overlay" />
            <component
              :is="componentType(content)"
              :content="content"
              @update="$emit('update', { content, updates: $event })"
            />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import { ref, computed } from 'vue'
import draggable from 'vuedraggable'
import * as editorComponents from './editor_components'

export default {
  name: 'SlideOrderer',
  components: { ...editorComponents, draggable },
  props: {
    contents: { type: Array, required: true },
    textStyle: { type: String, required: true },
  },
  setup(props, { emit }) {
    const slide = ref(null)
    const isEmpty = computed(() => {
      return props.contents.length <= 0
    })
    const orderedContents = computed({
      get() {
        return props.contents
      },
      set(value) {
        emit('update-order', value)
      },
    })
    function componentType(content) {
      return `SlideEditor${capitalize(content.type)}`
    }

    return { isEmpty, componentType, slide, orderedContents }
  },
}
</script>
