<template>
  <div id="choose-new">
    <LessonPlanFormTemplate v-if="contentType === 'LessonPlan'" :lesson-plan="content" @update="updateContent" />
    <ResourceFormTemplate v-else :resource="content" @update="updateContent" />
  </div>
</template>

<script>
import { watchEffect } from 'vue'
import LessonPlanFormTemplate from 'vue_features/lesson_plans/components/LessonPlanFormTemplate'
import ResourceFormTemplate from 'vue_features/resources/shared/components/ResourceFormTemplate'
import { useLzContentModalStore } from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'

export default {
  name: 'NewForm',
  components: { LessonPlanFormTemplate, ResourceFormTemplate },
  setup() {
    const { contentType, content, currentTab, items } = useLzContentModalStore()

    const updateContent = (update) => {
      Object.keys(update).forEach((key) => (content.value[key] = update[key]))
    }
    watchEffect(() => {
      if (currentTab.value === 'choose-new') {
        items.value = [content.value]
      }
    })
    return { contentType, content, updateContent }
  },
}
</script>
