import { merge } from 'lodash'
import { elevateLocaleNamespace, namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, components, siteAdmin } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(components, 'components'),
  elevateLocaleNamespace(siteAdmin, 'admin_accesses'),
)
