import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, dataDashboards, shared, itemAnalysis } from 'vue_features/shared/i18n'

export default merge(
  {},
  dataDashboards,
  namespaceLocaleObj(common, 'common'),
  pickLocaleNamespace(shared, 'navigation'),
  namespaceLocaleObj(itemAnalysis, 'item_analysis'),
)
