import { ref, computed } from 'vue'

export default function useOverflowIndicators() {
  const canScrollLeft = computed(() => {
    return tableWidth.value + 1 < tbodyWidth.value && scrollLeft.value > 0
  })
  const canScrollRight = computed(() => {
    return tableWidth.value + 1 < tbodyWidth.value && scrollLeft.value < tbodyWidth.value - tableWidth.value
  })
  const offsetTop = ref(0)
  const tableWidth = ref(0)
  const tbodyWidth = ref(0)
  const scrollLeft = ref(0)
  const tableHeight = ref(0)

  function updateOverflowIndicators(table) {
    tableWidth.value = table.clientWidth
    tbodyWidth.value = table.querySelector('tbody').clientWidth
    scrollLeft.value = table.scrollLeft
    offsetTop.value = table.offsetTop
    tableHeight.value = table.clientHeight
  }

  return {
    canScrollLeft,
    canScrollRight,
    offsetTop,
    tableHeight,
    updateOverflowIndicators,
  }
}
