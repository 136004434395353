<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div v-if="isCreatingOrEditing && clientReady && isItemEditable">
    <Grid disable-default="max-w">
      <div class="col-span-3 mt-4">
        <label class="mr-2 inline" for="scoring-type">{{ $t('common.label.scoring_type') }}</label>
        <select id="scoring-type" v-model="newScoringType" class="select--inline">
          <option value="per-question">{{ $t('common.per_question') }}</option>
          <option value="dependent">{{ $t('common.ebsr_dependent') }}</option>
          <option value="dichotomous">{{ $t('common.dichotomous') }}</option>
        </select>
      </div>
      <div class="col-span-5 mt-4">
        <label class="mb-0">
          <input v-model="newCheckableAnswers" type="checkbox" />
          {{ $t('card_form_templates.allow_answers_checked') }}
          <p class="help-block m-0 ml-4 italic">{{ $t('card_form_templates.allow_answers_checked_help') }}</p>
        </label>
      </div>
    </Grid>
    <div class="px-2.5 md:px-3.5">
      <div class="mt-4">
        <LzAccordion :verbs="true" :label="$t('scoring_rubric.add_scoring_guidance')" color="base">
          <div class="mb-6">
            <label for="scoring-rubric-url-id">{{ $t('scoring_rubric.scoring_rubric_label') }}</label>
            <input id="scoring-rubric-url-id" v-model="scoringRubricUrlId" type="text" />
            <i18n-t tag="p" keypath="scoring_rubric.scoring_rubric_help" scope="global" class="help-block">
              <template #baseUrl>{{ Routes.resources_url({ trailing_slash: true }) }}</template>
              <template #resourceId><span class="highlight">46431</span></template>
            </i18n-t>
            <a v-if="hasScoringRubric" :href="scoringRubricUrl" class="btn" target="_blank">{{ $t('common.view') }}</a>
          </div>
          <Grid v-if="authorizedAsAuthor" disable-default="md:px" class="border-base bg-base border-t p-4">
            <h5 class="smaller col-span-12">
              {{ $t('scoring_rubric.new_resource_header') }}
            </h5>
            <div class="col-span-12 md:col-span-6">
              <a :href="Routes.new_resource_path({ resource_type: 'document' })" class="small" target="_blank">
                {{ $t('scoring_rubric.new_document') }}
              </a>
              <p class="smaller italic">{{ $t('scoring_rubric.new_document_description') }}</p>
            </div>
            <div class="col-span-12 md:col-span-6">
              <p class="mb-0">
                <a :href="Routes.new_resource_path({ resource_type: 'wiki' })" class="small" target="_blank">
                  {{ $t('scoring_rubric.new_page') }}
                </a>
              </p>
              <p class="smaller italic">{{ $t('scoring_rubric.new_page_description') }}</p>
            </div>
          </Grid>
        </LzAccordion>
      </div>
    </div>
    <div v-if="showStandards" class="mt-4 px-2.5 md:px-3.5">
      <LzAccordion :verbs="true" :label="$t('card_form_templates.add_standards')" color="base">
        <StandardsSelector :standards="addedStandards" :optional="true" class="pb-4" @set-standards="setStandards" />
      </LzAccordion>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { getIdFromContentUrlId } from 'utils'
import { useLearnosityItemEditorStore } from '../composables/use_learnosity_item_editor_store'
import LzAccordion from 'vue_features/shared/components/ui/LzAccordion'
import StandardsSelector from 'vue_features/standards_selector/components/StandardsSelector'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'LearnosityExtendedOptions',
  components: { Grid, StandardsSelector, LzAccordion },
  props: {
    checkableAnswers: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const { authorizedAsAuthor } = useCurrentUserStore()
    const { showStandards } = useCurrentCustomerStore()
    const { isCreatingOrEditing, clientReady, isItemEditable, standards, scoringRubricId, scoringType, updateItem } =
      useLearnosityItemEditorStore()

    const newScoringType = computed({
      get: () => scoringType.value,
      set(newType) {
        updateItem({ scoringType: newType })
      },
    })
    const newCheckableAnswers = computed({
      get: () => props.checkableAnswers,
      set(newCheckableAnswers) {
        emit('set-checkable-answers', newCheckableAnswers)
      },
    })

    const scoringRubricUrlId = ref(scoringRubricId.value || '')
    watch(scoringRubricUrlId, () => {
      const newId = getIdFromContentUrlId(scoringRubricUrlId.value)
      if (newId || scoringRubricUrlId.value.trim().length === 0) {
        updateItem({ scoringRubricId: newId })
      }
    })
    watch(scoringRubricId, () => {
      if (scoringRubricId.value !== getIdFromContentUrlId(scoringRubricUrlId.value)) {
        scoringRubricUrlId.value = scoringRubricId.value
      }
    })
    const hasScoringRubric = computed(() => {
      return !!parseInt(scoringRubricId.value)
    })
    const scoringRubricUrl = computed(() => {
      return hasScoringRubric.value ? Routes.resource_url(scoringRubricId.value) : null
    })

    const addedStandards = ref([...standards.value])
    watch(standards, () => {
      addedStandards.value = standards.value
    })
    const setStandards = (newStandards) => {
      updateItem({ standards: newStandards })
    }

    return {
      Routes,
      isCreatingOrEditing,
      clientReady,
      isItemEditable,
      addedStandards,
      newScoringType,
      newCheckableAnswers,
      scoringRubricUrlId,
      hasScoringRubric,
      scoringRubricUrl,
      authorizedAsAuthor,
      setStandards,
      showStandards,
    }
  },
}
</script>
