import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, components, klasses, lessonPlans, lzCodes, shared, studentKlasses } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(shared, '', {
    only: ['add_student_to_klass_modal', 'assignment_prompt', 'premium_prompt', 'live_learn_prompt'],
  }),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(klasses, 'klasses'),
  namespaceLocaleObj(studentKlasses, 'student_klasses'),
  namespaceLocaleObj(components, '', { only: ['confirm_modal', 'live_learn', 'google_classroom'] }),
  namespaceLocaleObj(lzCodes, '', {
    only: ['archive_by_date_modal', 'assignment_status', 'load_more_list', 'manage_button', 'manage_modal'],
  }),
  namespaceLocaleObj(lessonPlans, '', { only: ['readable_types'] }),
)
