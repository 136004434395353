<template>
  <div :id="id">
    <Alert
      v-if="showDeleteError"
      :dismissible="true"
      type="error"
      :title="$t('klasses.delete')"
      :description="$t('klasses.cannot_delete_class')"
      @dismissed="showDeleteError = false"
    />
    <div v-if="hasKlasses">
      <div class="mb-12 mt-4">
        <LoadMoreList
          v-slot="{ item: klass }"
          v-bind="{ items: klasses, startCount: 12, moreCount: 12, totalCount }"
          list-class="grid gap-4 justify-items-stretch p-0 list-none grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-6 sm:flex-row"
          @load-more="handleLoadMore"
        >
          <KlassListItem :klass="klass" @delete-error="showDeleteError = true" />
        </LoadMoreList>
      </div>
    </div>
    <ListBlankSlate v-else :klass-type="klassType" />
  </div>
</template>

<script>
import { ref } from 'vue'
import LoadMoreList from 'vue_features/shared/components/lists/LoadMoreList'
import { Alert } from 'vue_features/shared/components/ui'
import KlassListItem from './KlassListItem'
import ListBlankSlate from './ListBlankSlate'
import { useKlassesIndexStore } from '../store/use_klasses_index_store'

export default {
  name: 'KlassesTab',
  components: { Alert, KlassListItem, ListBlankSlate, LoadMoreList },
  props: {
    klassType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { klassDataOfType, klassesOfType, klassTotalOfType, loadMoreKlasses } = useKlassesIndexStore()
    const showDeleteError = ref(false)
    return { klassDataOfType, klassesOfType, klassTotalOfType, loadMoreKlasses, showDeleteError }
  },
  computed: {
    id() {
      return `${this.klassType}-classes`
    },
    klassesData() {
      return this.klassDataOfType(this.klassType)
    },
    klasses() {
      return this.klassesOfType(this.klassType)
    },
    hasKlasses() {
      return this.klasses.length > 0
    },
    totalCount() {
      return this.klassTotalOfType(this.klassType)
    },
  },
  methods: {
    handleLoadMore(done) {
      this.loadMoreKlasses({
        klassType: this.klassType,
        nextPage: this.klassesData.meta.pagination.nextPage,
      }).then(done)
    },
  },
}
</script>
