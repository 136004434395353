<template>
  <div class="table-wrapper">
    <table v-if="hasImports" class="table-opened responsive csv-uploads-history">
      <thead>
        <tr>
          <th>{{ $t('user_imports.index.date') }}</th>
          <th>{{ $t('user_imports.index.source') }}</th>
          <th>{{ $t('common.type') }}</th>
          <th>{{ $t('user_imports.index.status') }}</th>
          <th>{{ $t('user_imports.index.error_reports') }}</th>
        </tr>
      </thead>
      <tbody>
        <UserImport v-for="userImport in imports" v-bind="userImport" :key="userImport.id" />
      </tbody>
    </table>
    <div v-if="!hasImports">{{ $t('user_imports.index.no_recent_uploads') }}</div>
  </div>
</template>

<script>
import UserImport from './UserImport'

export default {
  name: 'UserImportThisWeeksUploads',
  components: { UserImport },
  props: {
    imports: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasImports() {
      return this.imports.length > 0
    },
  },
}
</script>
