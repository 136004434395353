<template>
  <div class="fixed left-0 top-0 m-4 rounded-lg bg-white p-4 shadow-lg" style="z-index: 1002">
    <div class="space-y-2">
      <h1 class="m-0 p-0 text-lg">Debug Connection</h1>

      <div class="flex gap-2">
        <LzButton :disabled="hasActiveConnection" @click="connect">Connect</LzButton>
        <LzButton :disabled="!hasActiveConnection" @click="disconnect">Disconnect</LzButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useLiveLearnChannel } from 'vue_features/assignments/show/composables'
import LzButton from 'vue_features/shared/components/ui/LzButton'

const { subscription, connected } = useLiveLearnChannel()

const hasActiveConnection = computed(() => subscription.value && connected.value)

function disconnect() {
  subscription.value.consumer.disconnect()
}

function connect() {
  subscription.value.consumer.connect()
}
</script>
