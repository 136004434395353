<template>
  <component :is="wrapperTag" class="relative">
    <component
      :is="href ? 'a' : 'div'"
      v-bind="href ? { href } : ''"
      data-test="multi-click-target"
      :class="['relative z-10 h-full w-full', { block: href }, compTargetClasses]"
      @click="$emit('main-click', $event)"
    >
      <slot />
    </component>
    <div v-if="isSlotUsed('actions')" data-test="multi-click-actions" :class="['absolute', compActionClasses]">
      <slot name="actions" />
    </div>
  </component>
</template>

<script>
/*
 * MultiClickable provides a fully clickable link/button which supports an "actions" region in which clickable hotspots
 * will react to hover and click events in isolation from the rest of the clickable field. This is achieved by
 * establishing three layers: a base layer for absolutely positioning actions (at `z-0`), a middle layer displaying the
 * main click target on `z-10`, and the bleed-through layer on `z-20` (or above) where individual actions appear above
 * the main target. Thus, adding links or buttons to the `#actions` slot on `z-20` will isolate them in the click field.
 * To avoid covering non-clickable content in the actions region, put bg classes on the wrapper instead of the target.
 */
import { computed } from 'vue'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

/*
 * Per-axis preset sizes establish common split points for dividing the multi-layer clickable areas in responsive ways
 * - Target gets padding (b/c margin isn't clickable) to keep content out of actions area
 * - Actions get corresponding max widths/heights paired with absolute edge positioning
 * - Positioning start/end keys apply classes specific to the top/left and bottom/right edges of the axis
 */
const SIZES = {
  'x-full': {
    // X-axis, no width restrictions
    target: { start: '', end: '' },
    actions: { all: 'top-0 bottom-0', start: 'left-0', end: 'right-0' },
  },
  'x-1-action': {
    // X-axis, width 25%(phone) 6rem/96px(tablet+) [~1 action]
    target: { start: 'pl-1/4 sm:pl-24', end: 'pr-1/4 sm:pr-24' },
    actions: { all: 'max-w-1/4 sm:max-w-24 top-0 bottom-0', start: 'left-0', end: 'right-0' },
  },
  'x-3-action': {
    // X-axis, width 50%(phone) 20rem/320px(tablet+) [~3 actions]
    target: { start: 'md:pl-1/2 sm:pl-80', end: 'sm:pr-80' },
    actions: {
      all: 'w-full md:max-w-max flex-shrink-0 border-t border-base sm:border-t-0 top-auto sm:top-0 bottom-0',
      start: 'left-0',
      end: 'right-0',
    },
  },
  'y-full': {
    // Y-axis, no height restrictions
    target: { start: '', end: '' },
    actions: { all: 'left-0 right-0', start: 'top-0', end: 'bottom-0' },
  },
}

// Positions specify the start (top/left) or end (bottom/right) of the size axis
const POSITIONS = ['start', 'end']

export default {
  name: 'MultiClickable',
  props: {
    wrapperTag: { type: String, default: 'div' },
    href: { type: String, default: '' },
    size: { type: String, default: Object.keys(SIZES)[0], validator: (value) => Object.keys(SIZES).includes(value) },
    position: { type: String, default: POSITIONS[1], validator: (value) => POSITIONS.includes(value) },
    targetClasses: { type: [String, Array, Object], default: '' },
    actionClasses: { type: [String, Array, Object], default: '' },
  },
  setup(props) {
    return {
      isSlotUsed,
      compTargetClasses: computed(() => [SIZES[props.size].target[props.position], props.targetClasses]),
      compActionClasses: computed(() => [
        SIZES[props.size].actions.all,
        SIZES[props.size].actions[props.position],
        props.actionClasses,
      ]),
    }
  },
}
</script>
