<template>
  <li
    class="border-neutral-200 min-h-max group flex rounded-2xl border shadow transition-shadow"
    :class="[{ 'hover:shadow-lg focus:shadow-lg': href }, background]"
    :data-test="`detail-list-item-id-${itemId}`"
  >
    <a
      class="flex min-w-0 max-w-full flex-grow rounded-2xl"
      :class="{ 'cursor-pointer': clickable }"
      :href="href ? href : null"
      :target="target ? target : null"
    >
      <slot name="handle" />
      <div class="flex min-w-0 max-w-full flex-grow">
        <DetailListItemThumbnail
          v-if="hasImage"
          v-bind="{
            showThumbnail,
            title,
            href,
            icon,
            thumbnail,
            size: thumbnailSize,
            hasHandle: isSlotUsed('handle'),
            clickable,
          }"
          :class="thumbnailClass"
        />
        <div class="flex min-w-0 flex-grow gap-2">
          <div class="flex min-w-0 flex-grow flex-col justify-between gap-4 px-4 py-6 pl-8 sm:gap-2 sm:pr-6">
            <slot name="header" />
            <slot name="meta-label" />
            <div class="flex min-w-0 flex-grow flex-col justify-between gap-2 md:flex-row">
              <div class="flex min-w-0 flex-grow flex-col gap-2 overflow-hidden">
                <span class="outline-none line-clamp-2 min-w-0 font-bold leading-6 md:text-xl">
                  <slot name="title">
                    <p class="text-accent font-bold leading-6">{{ title }}</p>
                  </slot>
                </span>
                <div
                  v-if="description"
                  class="text-muted line-clamp-3 min-h-max hidden max-w-prose text-sm print:block sm:block"
                >
                  {{ description }}
                </div>
              </div>
              <div
                v-if="hasActions"
                class="ml-auto flex max-w-min flex-shrink-0 content-start gap-1 md:flex-col md:gap-2"
              >
                <slot name="actions" />
              </div>
            </div>
            <div class="-mb-2 flex items-center justify-between gap-4 overflow-hidden">
              <MetadataByline
                v-if="fromMetadata"
                :logo="fromMetadata.logo"
                :name="fromMetadata.name"
                class="flex-shrink-0"
              />
              <slot name="metadata">
                <div class="text-neutral-700 flex min-w-0 items-center gap-1">
                  <LzIcon
                    v-if="metadata['addressed standards'] && metadata['addressed standards'].length > 0"
                    path="icons/badge-check"
                    class="flex-shrink-0"
                  />
                  <MetadataList :metadata="itemMetadataExcludingFromKey" class="max-w-fit flex-shrink truncate" />
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <slot />
    </a>
    <slot name="unlinked-content" />
  </li>
</template>

<script>
import DetailListItemThumbnail from './DetailListItemThumbnail'
import MetadataList from './MetadataList'
import MetadataByline from './MetadataByline'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import useMetadata from 'vue_features/shared/composables/use_metadata'
import { computed } from 'vue'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

export default {
  name: 'DetailListItem',
  components: { MetadataByline, MetadataList, DetailListItemThumbnail, LzIcon },
  props: {
    showThumbnail: { type: Boolean, default: true },
    itemId: { type: [String, Number], default: '' },
    title: { type: String, default: '' },
    href: { type: String, default: '' },
    target: { type: String, default: '' },
    icon: { type: String, default: '' },
    thumbnail: { type: String, default: '' },
    description: { type: String, default: '' },
    thumbnailSize: { type: String, default: '' },
    thumbnailClass: { type: String, default: '' },
    metadata: { type: Object, default: () => ({}) },
    background: { type: String, default: 'bg-focus' },
    clickable: { type: Boolean, default: true },
  },
  setup(props) {
    const { fromMetadata, itemMetadataExcludingFromKey } = useMetadata(props.metadata)
    const hasImage = computed(() => {
      return props.showThumbnail || (!props.showThumbnail && props.icon)
    })
    return { fromMetadata, itemMetadataExcludingFromKey, hasImage, isSlotUsed }
  },
  computed: {
    hasActions() {
      return !!this.isSlotUsed('actions')
    },
  },
}
</script>
