import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import { common, components, siteAdmin, activerecordAttributes, customers, shared } from 'vue_features/shared/i18n'

export default merge(
  {},
  elevateLocaleNamespace(siteAdmin, 'customers'),
  namespaceLocaleObj(common, 'common'),
  pickLocaleNamespace(customers, 'form'),
  namespaceLocaleObj(activerecordAttributes, 'activerecord_attributes'),
  pickLocaleNamespace(shared, 'error_messages'),
  namespaceLocaleObj(components, 'components'),
  pickLocaleNamespace(siteAdmin, 'user_imports'),
)
