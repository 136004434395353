<!-- eslint-disable vuejs-accessibility/form-control-has-label  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <h2 class="my-2">{{ $t('archived_are_you_sure_heading') }}</h2>
      <p>{{ $t('archived_data_lost_message') }}</p>
      <strong>{{ $t('show.delete_customer.modal.cannot_undo') }}</strong>
      <i18n-t tag="p" keypath="show.delete_customer.modal.confirm_subdomain" scope="global">
        <template #subdomain>
          <strong>{{ subdomain }}</strong>
        </template>
      </i18n-t>
      <input v-model.trim="enteredSubdomain" type="text" />
      <p>
        <strong>{{ $t('cannot_undo_message') }}</strong>
      </p>
      <ImportErrors :errors="errors" />
    </template>
    <template #confirmButton>
      <button
        :class="['btn btn--primary', isConfirmed ? 'caution' : 'disabled']"
        :disabled="!isConfirmed"
        @click="confirm"
      >
        {{ $t('delete_users') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import UsersService from '../api/users_service'
import ImportErrors from './ImportErrors'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { get } from 'lodash'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'ArchivedUsersDeletionConfirmModal',
  components: { LzConfirmModal, ImportErrors },
  setup() {
    const { subdomain } = useCurrentCustomerStore()
    return { subdomain, closeModal }
  },
  data() {
    return { enteredSubdomain: '', errors: [] }
  },
  computed: {
    isConfirmed() {
      return this.enteredSubdomain === this.subdomain
    },
  },
  methods: {
    confirm() {
      UsersService.deleteArchivedUsers()
        .then(() => {
          this.$emit('delete-queued')
          this.closeModal(this.$options.name)
        })
        .catch((errorResponse) => {
          this.errors = get(errorResponse, 'responseJSON.errors') || [this.$t('delete_archived_users_error')]
        })
    },
  },
}
</script>
