<template>
  <div
    :style="coverImageAttributes"
    class="min-h-50 flex flex-col items-stretch gap-2 bg-cover bg-center bg-no-repeat sm:gap-4"
  >
    <Crumbs />
    <div class="mx-start flex flex-row space-x-4 px-4 md:px-8">
      <div class="max-w-7xl flex-grow space-y-4 pb-12 pt-8 lg:px-6">
        <div class="flex items-center space-x-3">
          <InlineSvg
            :path="`icons/${getIcon(resource)}`"
            class="border-3 m-0 h-12 w-12 rounded-2xl border-white bg-white"
          />
          <LzContentTeacherOnlyBadge :teacher-only="resource.teacherOnly" />
          <LzContentPremiumBadge :premium="resource.premium" />
          <LzContentStateBadge v-if="!isActive(resource)" :state="resource.state" />
        </div>
        <div class="flex w-3/4 flex-col gap-y-4 lg:w-1/2">
          <h1 id="resource-name" itemprop="name" class="m-0 flex flex-grow text-white">{{
            cardTitle || resource.name
          }}</h1>
          <div v-if="isWiki && resource.customizedBy" class="mt-2">
            <span class="bg-primary-200 rounded px-3 py-1 text-sm">
              {{ $t('common.label.customized_by') }}
              {{ resource.customizedBy }}
            </span>
          </div>
          <p itemprop="description" class="leading-normal text-white">{{ resource.description }}</p>
        </div>

        <div class="flex flex-row space-x-2">
          <div class="page-head__actions flex items-center space-x-3 self-start">
            <ActionMenu v-if="!isGated && !isRevision" v-bind="actionMenuProps" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCoverImageAttributes from '../composables/use_cover_image_attributes'
import { toRefs } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'
import LzContentTeacherOnlyBadge from 'vue_features/lz_content/components/LzContentTeacherOnlyBadge'
import ActionMenu from 'vue_features/action_menu/components/ActionMenu'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import LzContentPremiumBadge from 'vue_features/lz_content/components/LzContentPremiumBadge'
import { useResourceTypes } from 'vue_features/shared/composables'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { isActive } from 'vue_features/shared/composables/use_record_states'
import Crumbs from 'vue_features/resources/shared/components/Crumbs'
import { getIcon } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'ResourceHeader',
  components: {
    ActionMenu,
    LzContentStateBadge,
    LzContentTeacherOnlyBadge,
    LzContentPremiumBadge,
    Crumbs,
    InlineSvg,
  },
  setup() {
    const { actionMenuProps, isGated, isRevision, cardTitle, resource } = useResourceShowStore()

    const { isWiki } = useResourceTypes(resource)
    const { coverImageUrl } = toRefs(resource.value)
    const coverImageAttributes = useCoverImageAttributes(coverImageUrl, resource.value.fromMetadata?.logo)

    return {
      isWiki,
      coverImageAttributes,
      actionMenuProps,
      isGated,
      cardTitle,
      resource,
      isRevision,
      isActive,
      getIcon,
    }
  },
}
</script>
