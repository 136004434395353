import { includes, intersection, map, range } from 'lodash'
import { bottomRow, rightMostColumn, isAbove, isBelow, isToLeftOf, isToRightOf } from './models/grid_indices'
import useSizingStrategy from './use_sizing_strategy'
import { watch } from 'vue'

export const ROWS = 8
export const COLUMNS = 12

export default function useGrid(contents) {
  const grid = map(range(ROWS), () => [])
  const sizingStrategy = useSizingStrategy({
    addAll,
    getItem,
    containsOnly,
    getSurroundingContents,
    getSurroundingAdjustables,
    getAdjustablesInSpace,
  })

  contents.value.forEach((content) => {
    add(content)
  })

  watch(
    () => [...contents.value],
    (newContents) => {
      addAll(newContents)
    },
  )

  function containsOnly(content) {
    return content.rowSpan === ROWS && content.colSpan === COLUMNS
  }

  function add(content) {
    iterateRows(content, (row) => {
      iterateColumns(content, (column) => {
        grid[row][column] = content
      })
    })
  }

  function addAll(contents) {
    contents.forEach((c) => add(c))
  }

  function getItem(row, column) {
    return grid[row][column]
  }

  function reclaimSpace(removedContent) {
    const adjustedContent = sizingStrategy.fillSpaceOf(removedContent)
    if (adjustedContent) {
      addAll(adjustedContent)
    }
  }

  function rowsInline(content, otherContent) {
    return !isAbove(otherContent, content) && !isBelow(otherContent, content)
  }

  function columnsInline(content, otherContent) {
    return !isToLeftOf(otherContent, content) && !isToRightOf(otherContent, content)
  }

  function getContentsToLeft(content) {
    const contentsToLeft = [],
      columnToLeft = content.column - 1

    if (columnToLeft >= 0) {
      iterateRows(content, (row) => {
        const leftContent = grid[row][columnToLeft]
        if (!includes(contentsToLeft, leftContent)) {
          contentsToLeft.push(leftContent)
        }
      })
    }

    return contentsToLeft
  }

  function getAdjustablesToLeft(content) {
    return getContentsToLeft(content).filter((c) => rowsInline(content, c))
  }

  function getContentsToRight(content) {
    const contentsToRight = [],
      columnToRight = rightMostColumn(content) + 1

    if (columnToRight < COLUMNS) {
      iterateRows(content, (row) => {
        const rightContent = grid[row][columnToRight]
        if (!includes(contentsToRight, rightContent)) {
          contentsToRight.push(rightContent)
        }
      })
    }

    return contentsToRight
  }

  function getAdjustablesToRight(content) {
    return getContentsToRight(content).filter((c) => rowsInline(content, c))
  }

  function getContentsAbove(content) {
    const contentsAbove = [],
      rowAbove = content.row - 1

    if (rowAbove >= 0) {
      iterateColumns(content, (column) => {
        const aboveContent = grid[rowAbove][column]
        if (!includes(contentsAbove, aboveContent)) {
          contentsAbove.push(aboveContent)
        }
      })
    }

    return contentsAbove
  }

  function getAdjustablesAbove(content) {
    return getContentsAbove(content).filter((c) => columnsInline(content, c))
  }

  function getContentsBelow(content) {
    const contentsBelow = [],
      rowBelow = bottomRow(content) + 1

    if (rowBelow < ROWS) {
      iterateColumns(content, (column) => {
        const belowContent = grid[rowBelow][column]
        if (!includes(contentsBelow, belowContent)) {
          contentsBelow.push(belowContent)
        }
      })
    }

    return contentsBelow
  }

  function getAdjustablesBelow(content) {
    return getContentsBelow(content).filter((c) => columnsInline(content, c))
  }

  function getSurroundingContents(content) {
    return {
      contentsToLeft: getContentsToLeft(content),
      contentsToRight: getContentsToRight(content),
      contentsAbove: getContentsAbove(content),
      contentsBelow: getContentsBelow(content),
    }
  }

  function getSurroundingAdjustables(content) {
    return {
      contentsToLeft: getAdjustablesToLeft(content),
      contentsToRight: getAdjustablesToRight(content),
      contentsAbove: getAdjustablesAbove(content),
      contentsBelow: getAdjustablesBelow(content),
    }
  }

  function makeRoomFor(content) {
    sizingStrategy.makeSpaceFor(content)
  }

  function getAdjustablesInSpace(content) {
    const contentsInSpace = []
    iterateRows(content, (row) => {
      iterateColumns(content, (column) => {
        const gridContent = grid[row][column]
        if (!includes(contentsInSpace, gridContent)) {
          contentsInSpace.push(gridContent)
        }
      })
    })
    let { contentsToLeft, contentsToRight, contentsAbove, contentsBelow } = getSurroundingAdjustables(content)

    contentsToLeft = intersection(contentsToLeft, contentsInSpace)
    contentsToRight = intersection(contentsToRight, contentsInSpace)
    contentsAbove = intersection(contentsAbove, contentsInSpace)
    contentsBelow = intersection(contentsBelow, contentsInSpace)

    return { contentsToLeft, contentsToRight, contentsAbove, contentsBelow }
  }

  function getContiguousRow(content, row, filter) {
    const rowContents = []
    for (let column = content.column; column >= 0; column--) {
      const rowContent = grid[row][column]
      if (!filter(rowContent)) {
        break
      } else if (!includes(rowContents, rowContent)) {
        rowContents.push(rowContent)
      }
    }
    for (let column = rightMostColumn(content); column < COLUMNS; column++) {
      const rowContent = grid[row][column]
      if (!filter(rowContent)) {
        break
      } else if (!includes(rowContents, rowContent)) {
        rowContents.push(rowContent)
      }
    }

    return rowContents
  }

  function getContiguousColumn(content, column, filter) {
    const columnContents = []
    for (let row = content.row; row >= 0; row--) {
      const columnContent = grid[row][column]
      if (!filter(columnContent)) {
        break
      } else if (!includes(columnContents, columnContent)) {
        columnContents.push(columnContent)
      }
    }
    for (let row = bottomRow(content); row < ROWS; row++) {
      const columnContent = grid[row][column]
      if (!filter(columnContent)) {
        break
      } else if (!includes(columnContents, columnContent)) {
        columnContents.push(columnContent)
      }
    }

    return columnContents
  }

  // private

  function iterateRows(content, fnc) {
    for (let row = content.row; row <= bottomRow(content); row++) {
      fnc(row)
    }
  }

  function iterateColumns(content, fnc) {
    for (let column = content.column; column <= rightMostColumn(content); column++) {
      fnc(column)
    }
  }

  return {
    reclaimSpace,
    makeRoomFor,
    addAll,
    getItem,
    containsOnly,
    getSurroundingContents,
    getSurroundingAdjustables,
    getAdjustablesInSpace,
    getContiguousColumn,
    getContiguousRow,
    resize: sizingStrategy.resize,
  }
}
