<template>
  <div class="space-y-2">
    <p class="align-left bold m-0 text-xs">
      {{ $t('print_dialog.layout') }}
    </p>
    <div class="space-y-1">
      <div v-if="viewableAsTeacher" class="relative flex w-full">
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': layout === 'sideBySide' }"
          class="print-radio-option align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="layout-side-by-side-radio"
        >
          <input
            id="layout-side-by-side-radio"
            v-model="layout"
            class="sr-only absolute left-0 top-0"
            name="layout"
            type="radio"
            value="sideBySide"
          />
          <span class="inline h-14 w-10">
            <InlineSvg path="icons/side-by-side" aria-hidden="true" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('print_dialog.layout_side_by_side_title') }}
            </h5>
            <p class="text-sm">
              {{ $t('print_dialog.layout_side_by_side_description') }}
            </p>
          </span>
        </label>
      </div>
      <div v-if="viewableAsTeacher" class="relative flex w-full">
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': layout === 'reference' }"
          class="print-radio-option align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="layout-reference-teacher-radio"
        >
          <input
            id="layout-reference-teacher-radio"
            v-model="layout"
            class="sr-only absolute left-0 top-0"
            name="layout"
            type="radio"
            value="reference"
          />
          <span class="h-14 w-10">
            <InlineSvg path="icons/reference" aria-hidden="true" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('print_dialog.layout_reference_title') }}
            </h5>
            <p class="text-sm">
              {{ $t('print_dialog.layout_reference_description') }}
            </p>
          </span>
        </label>
      </div>
      <div v-if="viewableAsTeacher && layout === 'reference'">
        <label class="align-left" for="layout-reference-include-description">
          <input id="layout-reference-include-description" v-model="includeAboutLesson" type="checkbox" />
          Include
          <em>About this lesson</em>
        </label>
      </div>
      <div class="relative flex w-full">
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': layout === 'noteTaking' }"
          class="print-radio-option align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="layout-note-taking-radio"
        >
          <input
            id="layout-note-taking-radio"
            v-model="layout"
            class="sr-only absolute left-0 top-0"
            name="layout"
            type="radio"
            value="noteTaking"
          />
          <span class="inline h-14 w-10">
            <InlineSvg path="icons/reference_writing" aria-hidden="true" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('print_dialog.layout_note_taking_title') }}
            </h5>
            <p class="text-sm">
              {{ $t('print_dialog.layout_note_taking_description') }}
            </p>
          </span>
        </label>
      </div>
      <div class="relative flex w-full">
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': layout === 'handout' }"
          class="print-radio-option align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="layout-handout-radio"
        >
          <input
            id="layout-handout-radio"
            v-model="layout"
            name="layout"
            type="radio"
            class="sr-only absolute left-0 top-0"
            value="handout"
          />
          <span class="inline h-14 w-10">
            <InlineSvg path="icons/handout" aria-hidden="true" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('print_dialog.layout_handout_title') }}
            </h5>
            <p class="text-sm">
              {{ $t('print_dialog.layout_handout_description') }}
            </p>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { InlineSvg } from 'vue_features/shared/components/ui'
import { usePrintSettingsStore } from '../store/use_print_settings_store'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import messages from '../messages'

export default {
  components: {
    InlineSvg,
  },
  i18n: { messages },
  setup() {
    const { layout, includeAboutLesson } = usePrintSettingsStore()
    const { viewableAsTeacher } = useCurrentCustomerStore()
    const { authorizedAsTeacher } = useCurrentUserStore()
    return { stateLayout: layout, stateIncludeAboutLesson: includeAboutLesson, viewableAsTeacher, authorizedAsTeacher }
  },
  computed: {
    layout: {
      get() {
        return this.stateLayout
      },
      set(layout) {
        this.stateLayout = layout
      },
    },
    includeAboutLesson: {
      get() {
        return this.stateIncludeAboutLesson
      },
      set(includeAboutLesson) {
        this.stateIncludeAboutLesson = includeAboutLesson
        localStorage.setItem('lz.printSettings.includeAboutLesson', includeAboutLesson)
      },
    },
  },
}
</script>

<style scoped>
.print-radio-option:has(input:focus-visible) {
  @apply ring-primary-accent rounded-md ring-2 ring-offset-2;
}
</style>
