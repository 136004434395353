<template>
  <div>
    <ProgressCover :visible="isModalBusy" />
    <div class="tabs-content">
      <div class="active content mx-auto max-w-7xl px-3 md:px-6">
        <AssignmentsDeleteModal v-if="showDeleteModal" />
        <AssignmentsArchiveModal v-if="showArchiveModal" />
        <AssignmentsUnarchiveModal v-if="showUnarchiveModal" />
        <AssignmentsErrorModal v-if="showErrorModal" />
        <AssignmentsErrorUnarchiveModal v-if="showErrorUnarchiveModal" />
        <ShareAssignmentModal v-if="showShareModal" />
        <div class="mb-2 ml-4 text-sm">{{ $t(`load_more_list.sorted_by_${isStudent ? 'open' : 'created'}_date`) }}</div>
        <LoadMoreList
          v-slot="{ item: assignment }"
          :key="assignmentType"
          :items="assignments"
          :total-count="totalCount"
          :start-count="10"
          :more-count="10"
          class="mb-12"
          @load-more="handleLoadMore"
        >
          <AssignmentListItem
            :key="assignment.id"
            :klass="klass"
            :assignment="assignment"
            :assignment-type="assignmentType"
          />
        </LoadMoreList>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { ProgressCover } from 'vue_features/shared/components/ui'
import LoadMoreList from 'vue_features/shared/components/lists/LoadMoreList'
import {
  AssignmentsArchiveModal,
  AssignmentsDeleteModal,
  AssignmentsErrorModal,
  AssignmentsErrorUnarchiveModal,
  AssignmentsUnarchiveModal,
} from 'vue_features/assignments/shared/components'
import ShareAssignmentModal from './modals/ShareAssignmentModal'
import AssignmentListItem from './AssignmentListItem'
import { canModalShow, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useKlassAssignmentsStore } from '../store'
import useWebSocketSupport from 'vue_features/shared/composables/use_web_socket_support'

export default {
  name: 'AssignmentsLoadMoreList',
  components: {
    LoadMoreList,
    ProgressCover,
    AssignmentsArchiveModal,
    AssignmentsDeleteModal,
    AssignmentsErrorModal,
    AssignmentsErrorUnarchiveModal,
    AssignmentsUnarchiveModal,
    ShareAssignmentModal,
    AssignmentListItem,
  },
  props: {
    klass: {
      type: Object,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
      validator: (value) => ['active', 'inactive'].includes(value),
    },
  },
  setup(props) {
    const { isStudent } = useCurrentUserStore()
    const { activeAssignmentsData, inactiveAssignmentsData, loadMoreAssignments } = useKlassAssignmentsStore()
    const { check: checkWebSocketSupport } = useWebSocketSupport()

    const isActive = computed(() => props.assignmentType === 'active')
    const _assignmentsData = computed(() =>
      isActive.value ? activeAssignmentsData.value : inactiveAssignmentsData.value,
    )
    const handleLoadMore = (done) => {
      loadMoreAssignments({
        klassId: props.klass.id,
        assignmentType: props.assignmentType,
        nextPage: _assignmentsData.value.meta.pagination.nextPage,
      }).then(done)
    }

    onMounted(checkWebSocketSupport)

    return {
      isStudent,
      isModalBusy,
      isActive,
      handleLoadMore,
      totalCount: computed(() => _assignmentsData.value.meta.pagination.totalCount),
      assignments: computed(() => _assignmentsData.value.assignments),
      showDeleteModal: computed(() => canModalShow(AssignmentsDeleteModal)),
      showArchiveModal: computed(() => canModalShow(AssignmentsArchiveModal)),
      showUnarchiveModal: computed(() => canModalShow(AssignmentsUnarchiveModal)),
      showErrorModal: computed(() => canModalShow(AssignmentsErrorModal)),
      showErrorUnarchiveModal: computed(() => canModalShow(AssignmentsErrorUnarchiveModal)),
      showShareModal: computed(() => canModalShow(ShareAssignmentModal)),
    }
  },
}
</script>
