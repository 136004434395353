<template>
  <Modal content-classes="p-4" @backdrop-click="onBackdropClick">
    <SessionCommentEditor :session="currentSession" />
  </Modal>
</template>

<script>
import { useGradingStore } from '../composables/use_grading_store'
import Modal from './Modal'
import SessionCommentEditor from './SessionCommentEditor'

export default {
  name: 'SessionCommentModal',
  components: { Modal, SessionCommentEditor },
  setup() {
    const { currentSession, showSessionCommentModal } = useGradingStore()

    const onBackdropClick = () => {
      showSessionCommentModal.value = false
    }

    return {
      currentSession,
      onBackdropClick,
    }
  },
}
</script>
