import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const BookmarksService = {
  create({ lessonPlanId, path }) {
    const url = Routes.bookmarks_path()

    return HTTP.create(url, snakeKeys({ bookmark: { lessonPlanId, path } }))
  },

  destroy(bookmarkId) {
    const url = Routes.bookmark_path(bookmarkId)

    return HTTP.delete(url)
  },

  get(page) {
    const url = Routes.bookmarks_path()

    return HTTP.getJSON(url, { page })
  },
}

export default BookmarksService
