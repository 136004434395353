<template>
  <!-- content sanitized in serializer -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-mathjax :class="['slide__text', backgroundCss]" v-html="content.text" />
</template>

<script>
export default {
  name: 'SlideViewerText',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundCss() {
      return this.content.isCaption ? 'slide__caption' : `slide__text--${this.content.background}`
    },
  },
}
</script>
