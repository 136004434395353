<template>
  <div>
    <PremiumPrompt :show-unshared-premium-gate="showUnsharedPremiumGate" />
    <LessonPlanContentView />
  </div>
</template>

<script>
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import PremiumPrompt from 'vue_features/shared/components/login/PremiumPrompt'
import LessonPlanContentView from './LessonPlanContentView'
import { toggleNoScroll } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'LessonPlanGatedShow',
  components: {
    LessonPlanContentView,
    PremiumPrompt,
  },
  setup() {
    toggleNoScroll(true)

    const { showUnsharedPremiumGate } = useLessonPlanShowStore()
    return {
      showUnsharedPremiumGate,
    }
  },
}
</script>
