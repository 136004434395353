import { each, kebabCase } from 'lodash'

window.CKEDITOR.plugins.add('lztable', {
  lang: 'en',
  requires: 'contextmenu',

  init(editor) {
    const listenCommands = {}
    const emitVueEvent = editor.emitVueEvent

    // we don't support pasting, and it clogs up menu, so remove it
    editor.removeMenuItem('paste')

    function command(name) {
      emitVueEvent(`lztable-${kebabCase(name)}`)
    }

    function createGroup(groupName, commands, getItems) {
      const group = { name: groupName }
      editor.addMenuGroup(groupName)

      addCommandsToGroup(group, commands)
      addGroupToEditor(group, getItems)
      listenCommands[groupName] = window.CKEDITOR.TRISTATE_OFF
    }

    function addCommandsToGroup(group, commands) {
      each(commands, (command) => addCommandToGroup(group, command))
    }

    function addCommandToGroup(group, commandName) {
      editor.addCommand(commandName, { exec: command.bind(null, commandName) })

      group[commandName] = {
        label: editor.lang.lztable[commandName],
        command: commandName,
        group: group.name,
      }
    }

    function addGroupToEditor(group, getItems) {
      group[group.name] = {
        label: editor.lang.lztable[group.name],
        group: group.name,
        order: 1,
        getItems: getItems,
      }

      editor.addMenuItems(group)
    }

    function getRowItems() {
      const isRowHeader = editor.container.$.parentElement.dataset.scopes.includes('row')
      return {
        makeRowHeader: isRowHeader ? window.CKEDITOR.TRISTATE_DISABLED : window.CKEDITOR.TRISTATE_OFF,
        removeRowHeader: isRowHeader ? window.CKEDITOR.TRISTATE_OFF : window.CKEDITOR.TRISTATE_DISABLED,
        addRowAbove: window.CKEDITOR.TRISTATE_OFF,
        addRowBelow: window.CKEDITOR.TRISTATE_OFF,
        deleteRow: window.CKEDITOR.TRISTATE_OFF,
      }
    }

    function getColumnItems() {
      const isColumnHeader = editor.container.$.parentElement.dataset.scopes.includes('col')
      return {
        makeColumnHeader: isColumnHeader ? window.CKEDITOR.TRISTATE_DISABLED : window.CKEDITOR.TRISTATE_OFF,
        removeColumnHeader: isColumnHeader ? window.CKEDITOR.TRISTATE_OFF : window.CKEDITOR.TRISTATE_DISABLED,
        addColumnRight: window.CKEDITOR.TRISTATE_OFF,
        addColumnLeft: window.CKEDITOR.TRISTATE_OFF,
        deleteColumn: window.CKEDITOR.TRISTATE_OFF,
      }
    }

    createGroup(
      'lztablerow',
      ['makeRowHeader', 'removeRowHeader', 'addRowAbove', 'addRowBelow', 'deleteRow'],
      getRowItems,
    )
    createGroup(
      'lztablecolumn',
      ['makeColumnHeader', 'removeColumnHeader', 'addColumnRight', 'addColumnLeft', 'deleteColumn'],
      getColumnItems,
    )

    editor.contextMenu.addListener(() => listenCommands)
  },
})
