<template>
  <FadeTransition>
    <Banner v-show="isOffline" type="error" class="z-100 sticky top-0">
      <LzIcon size="lg" path="icons/connection-lost" class="mr-4 inline-block text-white" />
      <h4 class="max-auto m-0 inline-block max-w-7xl p-0 text-center text-white">
        {{ $t('shared.offline.device_offline') }}
      </h4>
    </Banner>
  </FadeTransition>
  <div v-if="showOverlay && isOffline" class="bg-focus z-100 fixed left-0 top-0 h-full w-full opacity-50" />
</template>

<script>
import { ref } from 'vue'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { FadeTransition } from 'vue_features/shared/components/transitions'
import { useEventListener } from 'vue_features/shared/composables'
import Banner from 'vue_features/shared/components/ui/Banner'

export default {
  name: 'OfflineNotice',
  components: { Banner, LzIcon, FadeTransition },
  props: { showOverlay: { type: Boolean, default: true } },
  setup() {
    const isOffline = ref(!window.navigator.onLine)

    useEventListener('online', () => (isOffline.value = false))
    useEventListener('offline', () => (isOffline.value = true))

    return { isOffline }
  },
}
</script>
