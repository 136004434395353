import { datadogRum } from '@datadog/browser-rum'
import { DATADOG_RUM } from 'environment'
import debug from 'debug'
import { pick } from 'lodash'

const { applicationId, clientToken, service, site } = DATADOG_RUM
const log = debug('app:initializers/datadog_rum')

// This sets up Datadog Real User Monitoring in the browser and allows us to analyze realtime performance
// and replay user sessions.
// See docs: https://docs.datadoghq.com/real_user_monitoring/browser/
export default function initDatadogRum() {
  if (process.env.RAILS_ENV !== 'production') {
    log('Not initializing DataDog RUM in non-production environment')
    return
  }

  try {
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      site,
      env: process.env.RAILS_ENV,
      sampleRate: 3,
      replaySampleRate: 0, // if not included - default 100
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })

    const contextProps = pick(window.LZ.analyticsProps, [
      'current_user_id',
      'current_customer',
      'impersonating_user',
      'impersonator_id',
    ])

    for (const [prop, value] of Object.entries(contextProps)) {
      datadogRum.addRumGlobalContext(prop, value)
    }
  } catch (err) {
    log('Datadog RUM init failed with error: %O', err)
  }
}
