import {
  defaultScoreBandRanges,
  ratingForScore,
  scoreBandBackground,
  scoreBandColor,
  scoreBandFill,
  scoreBandIcon,
} from 'vue_features/shared/utils/scoring'

export { scoreBandBackground, scoreBandColor, scoreBandFill, scoreBandIcon }

export function standardsTableCellStyle(field, value, scoreBands = defaultScoreBandRanges) {
  if (field === 'categoryName') return {}
  return {
    backgroundColor: scoreBandBackground(ratingForScore(value, scoreBands)),
  }
}
