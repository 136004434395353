import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const EnrollmentsService = {
  addToClass({ klassId, studentIds }) {
    const url = Routes.teacher_klass_enrollments_path(klassId),
      attrs = { enrollment: { studentIds } }

    return HTTP.create(url, snakeKeys(attrs))
  },

  removeFromKlass({ klassId, studentId }) {
    const url = Routes.teacher_klass_enrollment_path(klassId, studentId)

    return HTTP.delete(url)
  },

  addTeachersToClass({ klassId, teacherIds }) {
    const url = Routes.teacher_klass_teacher_klasses_path(klassId),
      attrs = { teacher_klasses: { teachers: teacherIds } }

    return HTTP.create(url, snakeKeys(attrs))
  },

  removeMeFromClass({ klassId }) {
    const url = Routes.teacher_klass_teacher_klasses_path(klassId)

    return HTTP.delete(url)
  },
}

export default EnrollmentsService
