import { trim } from 'lodash'

import URI from 'urijs'

export function getIdFromContentUrlId(urlOrId) {
  const value = trim(urlOrId)
  if (parseInt(value)) {
    return parseInt(value)
  } else {
    const match = URI(trim(value))
      .path()
      .match(/\/(\d+)/)
    return match ? match[1] : urlOrId
  }
}
