<template>
  <li data-test="assignment-item">
    <MultiClickable
      :href="assignmentUrl"
      :aria-label="assignment.name"
      size="x-3-action"
      class="rounded-2xl bg-white"
      :target-classes="{
        'min-h-32 flex flex-col md:flex-row rounded-2xl sm:pb-0 shadow-md hover:shadow-lg group overflow-hidden sm:pr-4 sm:pl-0': true,
        'pb-10': isStudent && !isActive,
        'pb-16': !isStudent,
      }"
      :action-classes="{
        'flex flex-wrap items-center justify-end gap-x-4 gap-y-1 py-2 w-full pr-4 md:pr-10': !isStudent,
        'border-none': isStudent,
      }"
    >
      <DetailListItemThumbnail
        data-test="assignment-thumbnail"
        default-size-class="w-52"
        :icon="assignment.lessonType"
        :thumbnail="getThumbnail({ type: 'Assignment', ...assignment })"
        class="hidden md:block"
      />
      <div data-test="assignment-info" class="flex flex-grow flex-col gap-y-2 px-4 py-4 md:pl-10 md:pr-0">
        <span class="flex items-center gap-2">
          <span class="text-xs font-bold uppercase leading-7 text-blue-500">{{
            $t(`readable_types.${assignment.lessonType}`)
          }}</span>
          <Chip v-if="isNew" :label="$t('klasses.is_new')" color="primary" size="sm" />
          <Chip v-if="assignment.unreadComments" :label="$t('common.new_comments')" color="primary" size="sm" />
        </span>
        <span class="text-md font-bold sm:text-lg">{{ assignment.name }}</span>
        <div class="text-muted flex gap-x-1 text-sm">
          <template v-if="!isStudent">
            <span>{{ openLabel }}</span>
            <span data-test="open-at">{{ openDate }}</span>
          </template>
          <template v-if="isStudent || hasClose">
            <span v-if="!isStudent" class="opacity-25">|</span>
            <span>{{ closeLabel }}</span>
            <span data-test="close-at">{{ closeDate }}</span>
          </template>
        </div>
      </div>
      <template #actions>
        <template v-if="isStudent">
          <div class="flex items-end justify-end gap-2 p-2 text-sm md:flex-col">
            <AssignmentStatusBadge
              v-if="!isActive && assignment.assignmentState"
              :assignment-state="assignment.assignmentState"
              :submitted="assignment.submitted"
            />
            <AssignmentScoreInfo
              v-if="isReturned"
              :assignment-id="assignment.id"
              :assignment-state="assignment.assignmentState"
              :learnosity-session-id="assignment.assignmentLearnositySessionId"
            />
            <a
              v-if="assignment.activeLiveLearnSession"
              :href="liveLearnLink"
              class="btn btn--primary btn--small relative z-20 flex items-center gap-2"
              data-test="join-live-learn"
            >
              <LzIcon size="sm" path="icons/live" class="text-white" />
              <span>{{ $t('live_learn') }}</span>
            </a>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center">
            <span class="text-md text-primary-700 sm:text-xl">{{ completed }}</span>
            <span class="text-primary-600 text-xs sm:text-sm">{{ $t('assignment_status.submitted') }}</span>
          </div>
          <ListDropdown
            v-if="isActive"
            :id="`assignment_state_${assignment.id}`"
            data-test="state-opts"
            class="-top-1 z-20"
            :button-classes="['w-24 sm:w-28 text-sm sm:text-md']"
            :dropdown-classes="['mt-1 w-24 sm:w-28']"
          >
            <template #button>
              <span>{{ status }}</span>
              <LzIcon size="sm" path="icons/caret" />
            </template>
            <template #listItems>
              <li v-for="opt in bulkOptions" :key="opt">
                <button type="button" :data-test="`${opt}-button`" @click="bulkChange(opt)">{{
                  $t(`load_more_list.bulk_actions.${opt}`)
                }}</button>
              </li>
            </template>
          </ListDropdown>
          <BaseDropdown :id="`assignment_actions_${assignment.id}`" v-slot="{ id, isOpen, toggle }">
            <div data-test="action-opts" class="relative text-sm">
              <button
                type="button"
                data-test="actions-open-button"
                :data-toggle="id"
                :aria-label="$t('assignment_prompt.more_options')"
                class="text-important hover:text-important-hover hover:bg-base relative -top-1 z-20 flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl focus:ring-2"
                @click.prevent.stop="toggle"
              >
                <LzIcon path="icons/dots-vertical" class="fill-current" />
              </button>
              <div
                v-show="isOpen"
                :id="id"
                class="shadow-dropdown absolute right-0 top-0 z-30 mt-10 flex w-11 flex-col rounded-lg bg-white md:w-32"
              >
                <template v-if="isActive">
                  <button
                    data-test="live-learn-button"
                    :class="actionClasses"
                    :disabled="!allowLiveLearn"
                    :title="liveLearnButtonTitle"
                    @click="openLiveLearn"
                  >
                    <LoadingSpinner v-if="isTestingWebSockets" size="small" class="m-0" />
                    <LzIcon v-else size="sm" path="icons/live" />
                    <span class="hidden md:inline">{{ $t('live_learn_prompt.live_learn') }}</span>
                  </button>
                  <button data-test="share-button" :class="actionClasses" @click="openShare">
                    <LzIcon size="sm" path="icons/connect" />
                    <span class="hidden md:inline">{{ $t('assignment_prompt.share') }}</span>
                  </button>
                </template>
                <AssignmentsManageButton
                  type="archive"
                  :assignment="assignment"
                  :klass="klass"
                  :class="actionClasses"
                />
                <AssignmentsManageButton
                  type="unarchive"
                  :assignment="assignment"
                  :klass="klass"
                  :class="actionClasses"
                />
                <AssignmentsManageButton type="delete" :assignment="assignment" :klass="klass" :class="actionClasses" />
              </div>
            </div>
          </BaseDropdown>
        </template>
      </template>
    </MultiClickable>
  </li>
</template>

<script>
import { computed, inject } from 'vue'
import Routes from 'routes'
import location from 'utils/location'
import { MultiClickable, LzIcon, Chip, LoadingSpinner } from 'vue_features/shared/components/ui'
import BaseDropdown from 'vue_features/shared/components/ui/dropdowns/BaseDropdown'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import DetailListItemThumbnail from 'vue_features/shared/components/lists/DetailListItemThumbnail'
import { AssignmentsManageButton, AssignmentScoreInfo } from 'vue_features/assignments/shared/components'
import AssignmentStatusBadge from './AssignmentStatusBadge'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'
import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import { AssignmentReportService } from 'vue_features/assignments/reports/api'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useKlassAssignmentsStore } from '../store'
import { LzAnalytics } from 'clients'
import useWebSocketSupport from 'vue_features/shared/composables/use_web_socket_support'

const OPEN_DATE = 'openAt'
const CLOSE_DATE = 'closeAt'
const CREATE_STATE = 'created'
const OPEN_STATE = 'opened'
const CLOSE_STATE = 'closed'
const RETURN_STATE = 'returned'
const MIXED_STATE = 'mixed'
const BULK_OPTIONS = {
  created: [OPEN_STATE],
  opened: [CLOSE_STATE, RETURN_STATE],
  closed: [OPEN_STATE, RETURN_STATE],
  returned: [OPEN_STATE, CLOSE_STATE],
  mixed: [OPEN_STATE, CLOSE_STATE],
}
const MS_PER_HOUR = 1000 * 60 * 60

export default {
  name: 'AssignmentListItem',
  components: {
    MultiClickable,
    LzIcon,
    Chip,
    BaseDropdown,
    ListDropdown,
    DetailListItemThumbnail,
    AssignmentsManageButton,
    AssignmentScoreInfo,
    AssignmentStatusBadge,
    LoadingSpinner,
  },
  props: {
    klass: {
      type: Object,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
      validator: (value) => ['active', 'inactive'].includes(value),
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { isStudent } = useCurrentUserStore()
    const { shareAssignment, updateStudentActivities } = useKlassAssignmentsStore()
    const liveLearnLink = Routes.live_learn_klass_path(props.assignment.code, props.klass.id)
    const { testing: isTestingWebSockets, supported: isWebSocketsSupported } = useWebSocketSupport()

    const _selectDate = (key) => {
      if (props.assignment[key]) return new Date(props.assignment[key])
      if (!props.assignment.studentActivities) return undefined
      const _farthestDate = (found, activity) => {
        if (!activity[key]) return found
        const newDate = new Date(activity[key])
        if (!found) return newDate
        const useNew = key === OPEN_DATE ? newDate < found : newDate > found
        return useNew ? newDate : found
      }
      return props.assignment.studentActivities.reduce(_farthestDate, undefined)
    }
    const _formatDate = (key) => {
      const dtFormat = Intl.DateTimeFormat([], { weekday: 'short', month: 'short', day: 'numeric' })
      if (isStudent.value)
        return props.assignment[key]
          ? dtFormat.format(new Date(props.assignment[key]))
          : root.$t('load_more_list.no_date')
      const selected = _selectDate(key)
      return selected ? dtFormat.format(selected) : ''
    }
    const _status = computed(() => {
      const _openReturned = (result, activity) => {
        if (activity.assignmentState === CREATE_STATE) result.created += 1
        else if (activity.assignmentState === OPEN_STATE) result.opened += 1
        else if (activity.assignmentState === RETURN_STATE) result.returned += 1
        return result
      }
      const totals = props.assignment.studentActivities.reduce(_openReturned, { created: 0, opened: 0, returned: 0 })
      const total = props.assignment.studentActivities.length
      if (totals.created === total) return CREATE_STATE
      if (totals.opened === total) return OPEN_STATE
      if (totals.returned === total) return RETURN_STATE
      if (totals.created + totals.opened + totals.returned === 0) return CLOSE_STATE
      return MIXED_STATE
    })

    const bulkChange = (opt) => {
      return AssignmentReportService.changeAssignmentStateForKlass({
        klassId: props.klass.id,
        assignmentId: props.assignment.id,
        assignmentViewIds: props.assignment.studentActivities.map((activity) => activity.id),
        assignmentState: opt,
      }).then((assignmentViews) => updateStudentActivities(props.assignmentType, props.assignment.id, assignmentViews))
    }
    const openLiveLearn = () => {
      trackLiveLearnEvent('Live Learn Button Clicked')
      trackLiveLearnEvent('Live Learn Launched')

      // Open live learn directly - skip modal (i.e.: not using LiveLearnButton)
      AssignmentsService.autoOpenKlass({ lzCode: props.assignment.code, klassId: props.klass.id }).then(() =>
        location.assign(liveLearnLink),
      )
    }
    const trackLiveLearnEvent = (eventName) => {
      LzAnalytics.track(eventName, {
        ...props.assignment.analyticsProperties,
        action: eventName,
        category: 'assignments',
        'LZ Code': props.assignment.code,
        source: 'class',
      })
    }
    const openShare = () => {
      shareAssignment.value = props.assignment
      openModal('ShareAssignmentModal')
    }

    const openLabel = computed(() => {
      const selected = _selectDate(OPEN_DATE)
      if (!selected) return root.$t('load_more_list.unopened')
      const nowish = new Date()
      nowish.setMinutes(nowish.getMinutes() + 5)
      const key = selected < nowish ? 'opened' : 'opens'
      return root.$t(`load_more_list.${key}`) + ':'
    })
    const closeLabel = computed(() => {
      const key = _selectDate(CLOSE_DATE) < new Date() ? 'closed' : 'closes'
      return root.$t(`load_more_list.${key}`) + ':'
    })
    const completed = computed(() => {
      const submitted = props.assignment.studentActivities
        .filter((activity) => activity.submitted)
        .length.toLocaleString()
      const total = props.assignment.studentActivities.length.toLocaleString()
      return `${submitted}/${total}`
    })

    const assignmentUrl = computed(() => {
      const url = new URL(props.assignment.url)
      url.hash = `assignment-report-${props.klass.id}`
      return url.toString()
    })

    const allowLiveLearn = computed(() => !isTestingWebSockets.value && isWebSocketsSupported.value)
    const liveLearnButtonTitle = computed(() => {
      if (isTestingWebSockets.value) {
        return 'Checking for Live Learn support...'
      } else if (!isWebSocketsSupported.value) {
        return 'Live Learn is not supported on your device'
      } else {
        return ''
      }
    })

    return {
      getThumbnail,
      isStudent,
      liveLearnLink,
      bulkChange,
      openLiveLearn,
      openShare,
      openLabel,
      closeLabel,
      completed,
      bulkOptions: computed(() => BULK_OPTIONS[_status.value]),
      isNew: computed(() => new Date(props.assignment.createdAt) > Date.now() - 18 * MS_PER_HOUR),
      isActive: computed(() => props.assignmentType === 'active'),
      isReturned: computed(() => props.assignment.assignmentState === RETURN_STATE),
      status: computed(() => root.$t(`assignment_status.${_status.value}`)),
      openDate: computed(() => _formatDate(OPEN_DATE)),
      closeDate: computed(() => _formatDate(CLOSE_DATE)),
      hasClose: computed(() => !!_selectDate(CLOSE_DATE)),
      actionClasses: 'hover:bg-neutral-200 flex items-center gap-2 p-2.5 leading-normal',
      assignmentUrl,
      isTestingWebSockets,
      allowLiveLearn,
      liveLearnButtonTitle,
    }
  },
}
</script>
