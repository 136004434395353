<template>
  <LzModal
    :class="authorizedAsAuthor ? 'lzui-modal--xlarge' : 'lzui-modal--small'"
    :modal-id="modalId"
    modal-title="Copy"
    style="text-align: left"
    :autoclose="false"
    @close-modal="cancel"
    @bg-click="cancel"
  >
    <section>
      <template v-if="choosingCopyType">
        <CopiesLoadMoreList v-if="hasCopies" v-bind="{ copiesData, original }" />
        <CopyTypePicker v-if="choosingCopyType" v-model="copyType" />
      </template>
      <ProgressBar
        v-else
        :active="pollerActive"
        :percent="percent"
        :job="$t('common.copy')"
        @complete="isComplete = true"
        @tick="updatePercent"
      />
    </section>
    <div v-if="error" class="center my-4">
      <strong class="red">
        {{ error }}
      </strong>
    </div>
    <footer>
      <button class="btn" @click.stop="cancel">
        {{ $t('common.cancel') }}
      </button>
      <a v-if="isComplete" :href="copyUrl" class="btn btn--primary">
        {{ $t('action_menu.go_to_copy') }}
      </a>
      <button v-if="choosingCopyType" class="btn btn--primary" @click="copy">
        {{ hasCopies ? $t('action_menu.create_new') : $t('common.copy') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { get } from 'lodash'
import { LzModal } from 'vue_features/shared/components/ui'
import ProgressBar from 'vue_features/shared/components/ui/loaders/ProgressBar'
import CopiesLoadMoreList from './CopiesLoadMoreList'
import CopyTypePicker from './CopyTypePicker'
import useCopyLessonPlan from '../composables/use_copy_lesson_plan'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'CopyObjectModal',
  components: { CopiesLoadMoreList, CopyTypePicker, LzModal, ProgressBar },
  props: {
    modalId: {
      type: String,
      default: 'CopyObjectModal',
    },
    original: {
      type: Object,
      required: true,
    },
    copiesData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { authorizedAsAuthor } = useCurrentUserStore()
    const { percent, isComplete, startCopy, pollerActive, updatePercent, copyUrl, copyType, cancel } =
      useCopyLessonPlan(props.original, props.modalId, authorizedAsAuthor)
    return {
      copyType,
      percent,
      isComplete,
      startCopy,
      pollerActive,
      updatePercent,
      copyUrl,
      cancel,
      authorizedAsAuthor,
    }
  },
  data() {
    return {
      choosingCopyType: true,
      error: '',
    }
  },
  computed: {
    hasCopies() {
      return get(this.copiesData, 'copies.length') > 0
    },
  },
  methods: {
    copy() {
      this.error = ''
      this.choosingCopyType = false
      this.startCopy({ copyType: this.copyType }).catch((err) => {
        this.choosingCopyType = true
        this.error = err.responseJSON.error
      })
    },
  },
}
</script>
