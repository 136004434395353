<template>
  <div id="wiki-content">
    <StandardsCoverageTableModal
      v-if="canModalShow('standards-coverage-table-modal')"
      v-bind="standardsTableContent"
      :on-save="onSave"
    />
    <LzContentModal
      v-if="canModalShow('lz-content-modal') && canAddContents"
      :content-type="contentType"
      :on-save="onSave"
    />
    <input
      v-for="block in blocks"
      :key="`${block.id}-${block.rank}`"
      :value="JSON.stringify(snakeKeys(blockJSON(block)))"
      type="hidden"
      name="wiki[blocks][]"
    />
    <input
      v-for="block in deletedBlocks"
      :key="block.id"
      :value="block.id"
      type="hidden"
      name="wiki[deleted_blocks][]"
    />
    <div class="form-group">
      <TopSticky :id="editorId" class="wiki-editor-toolbar" />
      <WikiDraggableBlocks
        v-model="blocks"
        :editor-id="editorId"
        :total-block-count="totalBlockCount"
        @update-block="updateBlock"
        @remove-block="removeBlock"
      />
    </div>
  </div>
</template>

<script>
import { get, uniqueId } from 'lodash'
import { computed, watch } from 'vue'
import WikiDraggableBlocks from './WikiDraggableBlocks'
import StandardsCoverageTableModal from 'vue_features/standards_coverage_table/components/StandardsCoverageTableModal'
import { activeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { snakeKeys } from 'vue_features/shared/helpers/http_helper'
import useDeletedBlocks from '../composables/use_deleted_blocks'
import useEditableBlocks from '../composables/use_editable_blocks'
import { blockJSON } from '../block_helpers'
import LzContentModal from './lz_content_modal/LzContentModal'
import TopSticky from 'vue_features/shared/components/ui/TopSticky'

export default {
  name: 'WikiEdit',
  components: { TopSticky, WikiDraggableBlocks, StandardsCoverageTableModal, LzContentModal },
  props: { originalBlocks: { type: Array, required: true }, canAddContents: { type: Boolean, default: true } },
  setup(props, { emit }) {
    const onSave = computed(() => {
      return get(activeModal.value.payload, 'onSave')
    })
    const standardsTableContent = computed(() => {
      return get(activeModal.value.payload, 'content')
    })
    const contentType = computed(() => {
      return get(activeModal.value.payload, 'contentType') || ''
    })

    const { deletedBlocks } = useDeletedBlocks()
    watch(
      () => [...deletedBlocks.value],
      (newDeletedBlocks) => {
        emit('deleted-blocks', newDeletedBlocks)
      },
    )

    const { blocks, totalBlockCount, updateBlock, removeBlock } = useEditableBlocks(props.originalBlocks)
    watch(
      () => [...blocks.value],
      (newBlocks) => {
        emit('updated-blocks', newBlocks)
      },
    )

    return {
      blocks,
      totalBlockCount,
      updateBlock,
      removeBlock,
      activeModal,
      canModalShow,
      onSave,
      standardsTableContent,
      contentType,
      deletedBlocks,
    }
  },
  computed: {
    editorId() {
      return uniqueId('wiki-editor-toolbar-')
    },
  },
  methods: {
    snakeKeys,
    blockJSON,
  },
}
</script>
