import { once } from 'lodash'
import { controllablePromise } from 'utils'
import { newAuthorApp } from 'clients/learnosity'
import useLearnosityAuthorTags from './use_learnosity_author_tags'

let stylesWorkaroundInstalled = false

export default function useLearnosityAuthorClient(config) {
  let tags = null
  let app = null

  const loadApp = async () => {
    app = await newAuthorApp(config)
    tags = useLearnosityAuthorTags(app)
  }

  const save = () => {
    const promise = controllablePromise()
    try {
      if (app.save()) {
        app.on('save:success', once(promise.resolve))
        app.on('save:error', once(promise.reject))
      } else {
        const addingWidget = app.getLocation().route.includes('widgets/new')
        addingWidget ? promise.reject('Please finish editing this question') : promise.resolve(false)
      }
    } catch (e) {
      promise.resolve(false)
    }

    return promise
  }

  const createItem = (...args) => {
    app.createItem(...args)
  }

  const duplicateItem = (...args) => {
    return new Promise((resolve) => {
      app.duplicateItem(...args)
      app.on('save:success', () => resolve(app.getItemReference()))
    })
  }

  const editItem = (...args) => {
    return new Promise((resolve) => {
      app.editItem(...args)
      app.on('render:item', resolve)
    })
  }

  const toItem = () => {
    const currentLearnosityItem = app.getItem()
    const hasItem = !!currentLearnosityItem.item

    if (!currentLearnosityItem) return null

    return {
      learnosityItem: currentLearnosityItem || null,
      content: app.getItemReference() || null,
      title: hasItem ? currentLearnosityItem.item.title : null,
      owner: getTagsOfType('Owner')[0] || null,
      learnosityItemUpdatedAt: hasItem ? currentLearnosityItem.item.dt_updated : null,
    }
  }

  // References:
  //  https://help.learnosity.com/hc/en-us/requests/4756
  //  https://help.learnosity.com/hc/en-us/requests/13712
  const workAroundCKEditorDialogStylesProblem = () => {
    // only do this once on a page, even if multiple components request it
    if (stylesWorkaroundInstalled) return

    const intervalId = setInterval(function () {
      if (!window.LRNCKEDITOR) return // not loaded yet, tick

      window.LRNCKEDITOR.on('dialogDefinition', function (e) {
        e.data.definition.dialog.on('show', function () {
          /* eslint-disable no-invalid-this */
          // can't use a css class here because Lrno is setting an inline style on the element
          this.getElement().setStyle('z-index', 10010)
          this.getElement().findOne('.cke_dialog').setStyles({
            'z-index': null,
            top: '10vh',
            left: null,
            margin: null,
          })
          /* eslint-enable no-invalid-this */
        })
      })

      stylesWorkaroundInstalled = true
      clearInterval(intervalId)
    }, 500)
  }

  const useApp = (method, ...args) => {
    return app[method](...args)
  }

  const destroy = () => useApp('destroy')
  const on = (...args) => useApp('on', ...args)
  const getScoringType = () => useApp('getScoringType')
  const setScoringType = (scoringType) => useApp('setScoringType', scoringType)

  const useTags = (method, ...args) => {
    return tags[method](...args)
  }

  const getAllTags = () => useTags('all')
  const getTagsOfType = (type) => useTags('ofType', type) || []
  const updateTags = (tags) => useTags('update', tags)
  const replaceTags = (tags) => useTags('replace', tags)

  return {
    save,
    config,
    createItem,
    duplicateItem,
    editItem,
    toItem,
    workAroundCKEditorDialogStylesProblem,
    destroy,
    on,
    getAllTags,
    getTagsOfType,
    updateTags,
    replaceTags,
    getScoringType,
    setScoringType,
    loadApp,
  }
}
