<template>
  <div id="video-captions" class="space-y-3">
    <VideoCaptionLanguage
      v-for="{ code, name, isReplaceCaptions, failedValidation, fileUrlFromParams, deleteFromParams } in languageCodes"
      v-bind="{ code, name, isReplaceCaptions, failedValidation, fileUrlFromParams, deleteFromParams }"
      :key="code"
      @update="updateParams"
    />
  </div>
</template>

<script>
import { CONFIG } from 'rails_constants'
import VideoCaptionLanguage from './VideoCaptionLanguage'
import { ref } from 'vue'

export default {
  name: 'VideoCaptions',
  components: {
    VideoCaptionLanguage,
  },
  props: {
    captions: { type: Array, default: () => [] },
    params: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const videoCaptionParams = ref([])
    const updateParams = (newParam) => {
      const index = videoCaptionParams.value.findIndex((p) => p.languageCode === newParam.languageCode)
      if (index >= 0) {
        videoCaptionParams.value.splice(index, 1, newParam)
      } else {
        videoCaptionParams.value.push(newParam)
      }

      emit('update', { videoCaptionParams: videoCaptionParams.value })
    }

    return { videoCaptionParams, updateParams }
  },
  computed: {
    languageCodes() {
      const languageCodes = CONFIG.videoCaption.languageCodes

      return Object.keys(languageCodes).map((languageCode) => {
        const videoCaption = this.captions.find((videoCaption) => videoCaption.language_code === languageCode)
        const videoCaptionsParam = this.params.find((param) => param.language_code === languageCode)

        return {
          code: languageCode,
          name: languageCodes[languageCode],
          isReplaceCaptions: !!videoCaption && !videoCaption.failed_validation,
          failedValidation: !!videoCaption && !!videoCaption.failed_validation,
          fileUrlFromParams: videoCaptionsParam ? videoCaptionsParam.file_url : '',
          deleteFromParams: videoCaptionsParam ? videoCaptionsParam.delete : false,
        }
      })
    },
  },
  methods: {},
}
</script>
