<template>
  <div :data-reference="itemReference" class="learnosity-item" />
</template>

<script>
export default {
  name: 'LearnosityItem',
  props: {
    itemReference: {
      type: String,
      required: true,
    },
  },
}
</script>
