import { computed, reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { BookmarkFoldersService } from 'vue_features/bookmarks_index/api'

export const useBookmarkButtonStore = defineStore('bookmark', () => {
  const state = reactive({
    bookmarkId: null,
    currentFolderIds: [],
    foldersData: {
      folders: [],
      meta: { pagination: {} },
    },
  })

  const folders = computed(() => state.foldersData.folders)
  const totalFolders = computed(() => state.foldersData.meta.pagination.totalCount)

  const includedIn = (folder) => state.currentFolderIds.includes(folder.id)

  let loadedFolders = false
  const initializeFolders = async () => {
    if (!loadedFolders) {
      loadedFolders = true
      state.foldersData = await BookmarkFoldersService.getFolders(1)
    }
  }
  const loadMoreFolders = async () => {
    const nextPage = await BookmarkFoldersService.getFolders(state.meta.pagination.nextPage)
    state.foldersData.meta = nextPage.meta
    state.foldersData.folders.push(...nextPage.folders)
  }
  const createFolder = async (title) => {
    const newFolder = await BookmarkFoldersService.create(title)
    state.foldersData.folders.unshift(newFolder)
    state.foldersData.meta.pagination.totalCount++
    return newFolder
  }

  return {
    state,
    folders,
    includedIn,
    initializeFolders,
    loadMoreFolders,
    createFolder,
    totalFolders,
  }
})
