<template>
  <div v-if="isActive && !isPublic && !isStudent && (hasStudentFunctionality || withPreview)">
    <AssignmentModal />
    <LiveLearnModal :lz-code="lzCode" />
    <ShareAssignmentModal v-if="showShare" />
    <EmbedCodeModal v-if="showEmbed" :embed-url="embedUrl" />
    <ListDropdown
      id="assign-menu"
      :disabled="disabled"
      :button-classes="['btn--green h-11 px-4 inline-flex items-center']"
      :dropdown-classes="['max-w-sm w-auto flow-left']"
      class="w-full"
    >
      <template #button>
        {{ $t('assign') }}
        <LzIcon size="sm" class="ml-2" path="icons/caret" aria-hidden="true" />
      </template>
      <template #listItems>
        <template v-if="hasStudentFunctionality">
          <li :class="itemClasses">
            <AssignmentButton icon-size="md" />
          </li>
          <li v-if="authorizedAsTeacher && !isContentEmbedded" :class="itemClasses">
            <LiveLearnButton :lz-code="lzCode" icon-size="md" />
          </li>
          <li v-if="withShare" :class="itemClasses">
            <button data-test="share-button" :class="buttonClasses" @click="openShare">
              <LzIcon size="md" path="icons/connect" />
              {{ $t('assignment_prompt.share') }}
            </button>
          </li>
          <li v-if="withEmbed" :class="itemClasses">
            <button data-test="embed-button" :class="buttonClasses" @click="openEmbed">
              <LzIcon size="md" path="icons/embed" />
              {{ $t('embed_code_modal.embed_link') }}
            </button>
          </li>
          <li v-if="withPreview" :class="itemClasses">
            <a :href="previewUrl" data-test="preview-assignment" :class="buttonClasses">
              <LzIcon size="md" path="icons/assign-preview" />
              {{ $t('assign_preview') }}
            </a>
          </li>
        </template>
        <li v-else :class="itemClasses">
          <div :class="['p-2.5 opacity-50 hover:opacity-50', buttonClasses]" data-test="preview-assignment-disabled">
            <LzIcon size="md" path="icons/assign-preview" />
            {{ $t('assign_preview') }}
          </div>
        </li>
      </template>
    </ListDropdown>
  </div>
</template>

<script>
import Routes from 'routes'
import { computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import LiveLearnModal from './LiveLearnModal'
import LiveLearnButton from './LiveLearnButton'
import AssignmentModal from './AssignmentModal'
import AssignmentButton from './AssignmentButton'
import ShareAssignmentModal from 'vue_features/klasses/show/components/modals/ShareAssignmentModal'
import EmbedCodeModal from 'vue_features/action_menu/components/EmbedCodeModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useGlobalStore, useCurrentUserStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useKlassAssignmentsStore } from 'vue_features/klasses/show/store'

export default {
  name: 'AssignMenu',
  components: {
    LzIcon,
    ListDropdown,
    LiveLearnModal,
    LiveLearnButton,
    AssignmentModal,
    AssignmentButton,
    ShareAssignmentModal,
    EmbedCodeModal,
  },
  props: {
    lzCode: { type: String, default: '' },
    assignmentUrl: { type: String, default: '' },
    ltiUrl: { type: String, default: '' },
    withEmbed: { type: Boolean, default: false },
    withPreview: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const { isContentEmbedded } = useGlobalStore()
    const { authorizedAsTeacher, isStudent } = useCurrentUserStore()
    const { hasStudentFunctionality, isPublic } = useCurrentCustomerStore()
    const { isActive, lessonPlanId, lessonType } = useLessonPlanStore()
    const { shareAssignment } = useKlassAssignmentsStore()

    const withShare = computed(() => !!props.assignmentUrl && !!props.ltiUrl)
    const openShare = () => {
      shareAssignment.value = { lessonType, code: props.lzCode, url: props.assignmentUrl, ltiLaunchUrl: props.ltiUrl }
      openModal(ShareAssignmentModal)
    }

    return {
      isContentEmbedded,
      authorizedAsTeacher,
      isStudent,
      hasStudentFunctionality,
      isPublic,
      isActive,
      withShare,
      openShare,
      itemClasses: 'flex whitespace-nowrap pr-2',
      buttonClasses: 'flex items-center gap-x-2',
      embedUrl: props.lzCode ? Routes.lz_code_url(props.lzCode) : '',
      previewUrl: Routes.preview_lesson_plan_path(lessonPlanId.value),
      showShare: computed(() => withShare.value && canModalShow(ShareAssignmentModal)),
      showEmbed: computed(() => props.withEmbed && canModalShow(EmbedCodeModal)),
      openEmbed: () => openModal(EmbedCodeModal),
    }
  },
}
</script>
