<template>
  <transition
    enter-active-class="transition-all transition-fast ease-out"
    leave-active-class="transition-all transition-fast ease-out"
    enter-from="translate-x-full"
    enter-to="translate-x-0"
    leave-from="translate-x-0"
    leave-to="overflow-hidden translate-x-full"
    class="transform"
  >
    <aside
      v-if="!hideItemDetails"
      class="border-neutral-300 fixed bottom-0 top-0 w-full flex-grow transform rounded-xl border bg-white shadow-sm transition-all ease-out print:hidden md:static"
      style="max-width: 450px"
    >
      <div class="sticky top-0 pb-6">
        <div
          class="border-neutral-300 z-1000 top-0 flex items-center justify-between gap-2 rounded-t-xl border-b px-4 py-3 md:rounded"
        >
          <div class="flex items-center gap-2">
            <button
              class="hover:text-accent hover:bg-base flex transform items-center text-base transition hover:-translate-y-1 md:hidden"
              @click="hideItemDetails = true"
            >
              <LzIcon class="text-neutral-600 mx-auto" path="icons/close" />
            </button>

            <div class="flex flex-col gap-1 overflow-hidden">
              <h4 class="m-0 text-base text-xl leading-8">
                {{
                  $t(selectedItem.isCustomization ? 'inserted_item_num' : 'item_num', { num: selectedItem.itemNumber })
                }}
              </h4>

              <span class="text-neutral-700 text-xs leading-none">
                {{ $t('last_data_sync', { date: lastSyncDate }) }}
              </span>

              <span
                v-if="selectedItem.isCustomization"
                class="text-neutral-600 mt-2 text-xs font-semibold leading-none"
              >
                {{ $t('custom_item_message') }}
              </span>
            </div>
          </div>

          <div class="flex">
            <button
              class="flex h-10 w-10 items-center rounded-lg text-base transition-colors"
              :class="[!atFirstItem ? `hover:bg-base-hover hover:text-accent` : `pointer-events-none opacity-25 `]"
              @click="selectPreviousItem"
            >
              <LzIcon class="mx-auto" path="icons/chevron-left" />
            </button>

            <button
              class="flex h-10 w-10 items-center rounded-lg text-base transition-colors"
              :class="[!atLastItem ? `hover:bg-base-hover hover:text-accent` : `pointer-events-none opacity-25 `]"
              @click="selectNextItem"
            >
              <LzIcon class="mx-auto" path="icons/chevron-right" />
            </button>
          </div>
        </div>

        <div class="mt-6 space-y-4 px-4">
          <InlineDetailsRow :title="$t('item_stats')" icon="icons/graduate-cap">
            <span class="flex items-center space-x-4">
              <div class="space-y-1 text-center">
                <div class="text-lg font-semibold leading-none">{{ maxPointsRange }}</div>
                <div class="text-muted text-xs leading-none text-gray-600">{{ $t('max_points') }}</div>
              </div>

              <div class="space-y-1 text-center">
                <div class="text-lg font-semibold leading-none">{{ selectedItem.totalResponses }}</div>
                <div class="text-muted text-xs leading-none text-gray-600">{{ $t('responses') }}</div>
              </div>

              <div class="space-y-1 text-center">
                <div class="text-lg font-semibold leading-none" :style="{ color: averageScoreColor }">
                  {{ selectedItem.averageScore }}%
                </div>
                <div class="text-muted flex-shrink-0 text-xs leading-none text-gray-600">{{ $t('avg_score') }}</div>
              </div>
            </span>
          </InlineDetailsRow>

          <StackedDetailsRow :title="$t('standards')" icon="icons/badge-checkmark">
            <div class="flex flex-row flex-wrap gap-1 p-4">
              <Badge
                v-for="standard in standards"
                :key="`${standard.initiative}.${standard.code}`"
                class="bg-primary-100 text-primary-500 text-xs font-bold"
                :title="standard.name"
              >
                {{ standard.initiative }}.{{ standard.code }}
              </Badge>

              <div v-if="standards.length === 0" class="text-muted">
                {{ $t('not_aligned_to_standards') }}
              </div>
            </div>
          </StackedDetailsRow>

          <StackedDetailsRow :title="$t('score_breakdown')">
            <ScoreBreakdownSection
              v-for="score in topItemScoreCounts"
              :key="`score-breakdown-${score.score}`"
              :count-label="$t('num_students', { num: score.total })"
              :score="score.score"
              :students="score.students"
              :allow-detail-toggle="enableStudentNameDisplay"
            />

            <div v-if="itemScoreCounts.length > topItemScoreCounts.length" class="px-4 py-2 text-sm">
              {{ $t('only_showing_top_item_scores_copy') }}
            </div>
          </StackedDetailsRow>
        </div>

        <div v-if="selectedItem.learnosityItemReference && itemPreviewConfig" class="overflow-auto p-4">
          <LearnosityRerenderer>
            <LearnosityItemResource
              :config="itemPreviewConfig"
              :item-reference="selectedItem.learnosityItemReference"
              :has-checkable-answers="false"
            />
          </LearnosityRerenderer>
        </div>
      </div>
    </aside>
  </transition>
</template>

<script setup>
import { computed } from 'vue'
import { Badge, LzIcon } from 'vue_features/shared/components/ui'
import { useItemAnalysisStore } from '../composables/use_item_analysis_store'
import dayjs from 'dayjs'
import { scoreBandColor, ratingForScore } from 'vue_features/shared/utils/scoring'
import { formatMaxScoreRange } from '../utils'
import InlineDetailsRow from './InlineDetailsRow'
import StackedDetailsRow from './StackedDetailsRow'
import ScoreBreakdownSection from './ScoreBreakdownSection'
import LearnosityRerenderer from 'vue_features/resources/learnosity_items/components/LearnosityRerenderer'
import { LearnosityItemResource } from 'vue_features/resources'

const MAX_ITEM_SCORES = 8

const {
  selectedItem,
  atFirstItem,
  atLastItem,
  selectNextItem,
  selectPreviousItem,
  lastUpdatedAt,
  hideItemDetails,
  itemPreviewConfig,
  itemStandardsForItemReference,
  itemScoreCounts,
  distinctLzCodes,
} = useItemAnalysisStore()
const lastSyncDate = computed(() => dayjs(lastUpdatedAt.value).format('M/D/YYYY'))
const averageScoreColor = computed(() => scoreBandColor(ratingForScore(selectedItem.value?.averageScore)))
const maxPointsRange = computed(() =>
  formatMaxScoreRange(selectedItem.value.maxScoreRangeBegin, selectedItem.value.maxScoreRangeEnd),
)

const standards = computed(() => itemStandardsForItemReference(selectedItem.value.learnosityItemReference))

const topItemScoreCounts = computed(() =>
  itemScoreCounts.value
    .filter((row) => row.learnosity_item_reference === selectedItem.value.learnosityItemReference)
    .sort((a, b) => {
      if (a.total === b.total) {
        return a.score - b.score
      } else {
        return b.total - a.total
      }
    })
    .slice(0, MAX_ITEM_SCORES)
    .sort((a, b) => a.score - b.score),
)

const enableStudentNameDisplay = computed(() => distinctLzCodes.value.length === 1)
</script>
