<template>
  <div class="lesson-cards z-10 flex w-full flex-col transition-all">
    <ul id="lesson-cards-list" class="3/2xl:max-w-6xl flex w-full max-w-5xl flex-1 flex-col self-center 2xl:max-w-7xl">
      <li
        v-for="(card, index) in cards"
        :key="card.id"
        class="lesson-card-wrapper relative mb-16 mt-0 w-full pt-4 first:pt-0 last:mb-0"
      >
        <LessonEndCard
          v-if="card.id === END_CARD_ID"
          data-lesson-card
          :data-card-index="index"
          @exit-player="$emit('exit-player')"
          @on-card-selected="$emit('on-card-selected', $event)"
        />
        <template v-else>
          <div class="hidden w-full">
            <div class="card-title h3 bold">{{ card.title }}</div>
            <p class="small text-muted">
              {{ $t('lesson_plan.progress', { current_card: index + 1, total_cards: cards.length }) }}
            </p>
          </div>
          <LessonCard
            :card="card"
            :cards="cards"
            :index="index"
            :selected-card-index="selectedCardIndex"
            :data-card-index="index"
            :is-preview="isPreview"
            data-lesson-card
            :current-position="selectedCardIndex"
          />
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import LessonCard from './LessonCard'
import LessonEndCard from './LessonEndCard'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { END_CARD_ID } from 'vue_features/assignments/show/composables'

export default {
  name: 'LessonContainer',
  components: { LessonCard, LessonEndCard },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    selectedCardIndex: {
      type: Number,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isFullscreen } = useFullscreenStore()

    return {
      isFullscreen,
      END_CARD_ID,
    }
  },
}
</script>

<style>
.lesson-card-wrapper {
  flex-basis: auto;
}

.lesson-card-wrapper.fs-ss {
  margin-left: 11rem !important;
  width: calc(100% - 11rem) !important;
}

.lesson-last-card {
  background: linear-gradient(160deg, rgba(var(--neutral-100), 1) 25%, rgba(var(--primary-100), 1) 85%);
}

@media print {
  .lesson-card-wrapper .card__resource {
    height: 100% !important;
  }

  .lesson-card-wrapper {
    break-inside: avoid;
  }
}
</style>
