import { computed } from 'vue'
import { pick, values } from 'lodash'

function throwError(id) {
  throw new Error(`Could not locate Learnosity item for itemReference: ${id}`)
}
export default function useLearnosityQuestions(itemsApp, itemReference) {
  const clientItem = computed(() => {
    if (!itemsApp.value) return {}
    return itemsApp.value.getItems()[itemReference] || throwError(itemReference)
  })

  const questions = computed(() => {
    if (!itemsApp.value) return []
    return values(pick(itemsApp.value.questions(), clientItem.value.response_ids))
  })

  return { clientItem, questions }
}
