<template>
  <div id="assignment-page">
    <AssignmentLoginModal v-if="showLogin" :student-only="needsStudentOnlyLogin" data-test="login-modal" />
    <div class="page-head page-head--wide">
      <div class="page-head__wrapper px-4 print:px-0">
        <div class="page-head__content mx-auto max-w-7xl flex-1 p-0">
          <div class="lesson-title flex flex-col items-start sm:flex-row">
            <div class="mt-4 flex w-full flex-1 flex-row flex-wrap space-y-4">
              <h2 class="mb-0 flex flex-1 flex-col items-start pr-0 text-3xl leading-10 sm:pr-16">
                <div class="inline-flex flex-1 items-start gap-x-4">
                  <div class="hidden w-12 sm:block">
                    <InlineSvg
                      class="border-3 -mt-0.5 h-12 rounded-xl border-white bg-white"
                      :path="`icons/${lessonPlan.lessonType}`"
                    />
                  </div>
                  <div class="inline-flex flex-col gap-x-2">
                    <div class="flex items-center">
                      <span
                        itemprop="educationalUse"
                        class="mb-0 text-sm font-semibold uppercase leading-5 tracking-wider text-blue-500"
                      >
                        {{ $t('show.assignment') }}
                      </span>
                    </div>
                    <span data-test="lesson-plan-title">{{ lessonPlan.title }}</span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <ImageTag class="mx-auto max-h-80" path="misc/content-placeholder.png" alt="content placeholder" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { ImageTag, InlineSvg } from 'vue_features/shared/components/ui'
import AssignmentLoginModal from './login/AssignmentLoginModal'
import { useAssignmentStore } from '../composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'AssignmentGatedShow',
  components: { ImageTag, AssignmentLoginModal, InlineSvg },
  setup() {
    const { lessonPlan, assignment } = useAssignmentStore()
    const { isLoggedIn } = useCurrentUserStore()

    onMounted(() => {
      if (!isLoggedIn.value) openModal(AssignmentLoginModal)
    })

    return {
      lessonPlan,
      AssignmentLoginModal,
      showLogin: computed(() => canModalShow(AssignmentLoginModal)),
      needsStudentOnlyLogin: computed(() => !isLoggedIn.value && assignment.value.assessment),
    }
  },
}
</script>
