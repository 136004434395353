<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="card-editor flex flex-col gap-2">
    <button
      v-if="isPersisted && cardType !== null"
      data-test="card-editor__remove-content"
      class="hover:bg-base group absolute right-3 top-3 flex gap-1 rounded-lg p-2 transition-colors"
      @click="$emit('clear-content')"
    >
      <LzIcon icon-color-class="text-neutral-600 group-hover:text-accent" type="div" path="icons/refresh" />
      <span class="text-neutral-600 block text-sm font-bold leading-6 group-hover:text-base">{{
        $t('lesson_plans.card_form_templates.swap_content')
      }}</span>
    </button>
    <div :class="{ error: errors.includes('required') || errors.includes('maxlength') }">
      <label class="mb-0 w-full">
        {{ $t('lesson_plans.info_form.title') }}
        <span v-if="errors.includes('required')">{{ $t('lesson_plans.card_form_templates.is_required') }}</span>
        <span v-if="errors.includes('maxlength')">{{ $t('lesson_plans.card_form_templates.is_too_long') }}</span>
        <input
          :value="modelValue"
          type="text"
          class="h3 form-control"
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </label>
    </div>
    <CardContentForm
      v-if="isEditable"
      v-bind="{ isPersisted, content, cardType, allowLessonPlans: true }"
      @update="$emit('update-content', $event)"
      @update-card="$emit('update-card', $event)"
    />
    <p v-else>
      {{ $t('index.content_uneditable') }}
    </p>
  </div>
</template>

<script>
import CardContentForm from './CardContentForm'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'CardBuilder',
  components: { CardContentForm, LzIcon },
  props: {
    modelValue: { type: String, required: true },
    cardType: { type: String, required: true },
    content: { type: Object, required: true },
    isPersisted: { type: Boolean, required: true },
    errors: { type: Array, required: true },
  },
  computed: {
    isEditable() {
      return this.content.editable === undefined || this.content.editable
    },
  },
}
</script>
