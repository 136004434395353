<template>
  <li>
    <MultiClickable
      :href="klassUrl"
      class="bg-focus relative h-full justify-between rounded-2xl"
      target-classes="group flex flex-col rounded-2xl shadow-md transition hover:shadow-lg"
      action-classes="items-start pt-1 pr-1"
    >
      <div class="flex flex-grow flex-col gap-y-2 pb-6 pl-4 pt-4" :class="[authorizedAsTeacher ? 'pr-12' : 'pr-3']">
        <div class="flex items-center gap-x-1">
          <LzTooltip
            tooltip-classes="max-w-xs"
            cursor-class="w-full cursor-help"
            :title="teacherNamesList"
            class="inline-flex truncate"
            tag="span"
          >
            <div class="text-muted my-0.5 w-full truncate text-xs font-bold leading-6">{{ teacherNamesList }}</div>
          </LzTooltip>
          <div class="flex flex-shrink-0 flex-grow justify-end">
            <Chip v-if="klass.hasNewAssignments" :label="$t('klasses.has_new')" color="primary" size="sm" />
            <Chip v-if="klass.unreadComments" :label="$t('common.new_comments')" color="primary" size="sm" />
          </div>
        </div>
        <div class="text-accent line-clamp-3 group-hover:text-accent-hover m-0 text-xl font-bold leading-tight">{{
          klass.name
        }}</div>
      </div>
      <div v-if="authorizedAsTeacher" class="flex items-center justify-between rounded-b-2xl px-4 py-2">
        <LzIcon
          v-if="klass.isFromClever"
          path="icons/synced-arrows"
          :aria-label="$t('klasses.index.synced_from_sis')"
          size="sm"
          class="text-neutral-700"
        />
        <div class="flex flex-grow items-center justify-end gap-x-2">
          <LzIcon path="icons/user-group" class="text-neutral-700" />
          <span class="text-neutral-700 text-sm font-bold">{{ klass.activeCount }}</span>
        </div>
      </div>
      <template v-if="authorizedAsTeacher" #actions>
        <LoadingSpinner v-if="loading" />
        <template v-else>
          <KlassUpdateModal v-if="canModalShow(klassUpdateModalId)" v-bind="{ klass, modalId: klassUpdateModalId }" />
          <KlassDeleteModal
            v-if="canModalShow(klassDeleteModalId)"
            v-bind="{ klass, modalId: klassDeleteModalId }"
            @delete-error="$emit('delete-error')"
          />
          <ListDropdown
            :id="`edit_klass_${klass.id}`"
            class="z-20 flex-none"
            default-btn-class=""
            :button-classes="[
              'text-important hover:text-important-hover hover:bg-base -top-1',
              'flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl',
            ]"
            :dropdown-classes="['right-0']"
          >
            <template #button>
              <span class="sr-only">{{ $t('klasses.edit_class') }}</span>
              <LzIcon path="icons/dots-vertical" class="fill-current" />
            </template>
            <template #listItems>
              <li>
                <button @click="openModal(klassUpdateModalId)">{{ $t('klasses.edit_name') }}</button>
              </li>
              <li v-if="klass.active">
                <button @click="archive">{{ $t('klasses.archive') }}</button>
              </li>
              <li v-if="!klass.active && !klass.isArchivedBySync">
                <button @click="unarchive">{{ $t('klasses.unarchive') }}</button>
              </li>
              <li v-if="!klass.isFromClever && klass.canDelete">
                <button data-test="delete-action" class="text-red" @click="openModal(klassDeleteModalId)">
                  {{ $t('klasses.delete') }}
                </button>
              </li>
            </template>
          </ListDropdown>
        </template>
      </template>
    </MultiClickable>
  </li>
</template>

<script>
import { ref, computed } from 'vue'
import Routes from 'routes'
import { LzIcon, LzTooltip, MultiClickable, Chip, LoadingSpinner } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { KlassUpdateModal, KlassDeleteModal } from '../../shared/components/modals'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useKlassesIndexStore } from '../store/use_klasses_index_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'KlassListItem',
  components: {
    LzIcon,
    LzTooltip,
    MultiClickable,
    Chip,
    LoadingSpinner,
    ListDropdown,
    KlassUpdateModal,
    KlassDeleteModal,
  },
  props: { klass: { type: Object, required: true } },
  setup(props) {
    const { authorizedAsTeacher } = useCurrentUserStore()
    const { unarchiveKlass, archiveKlass } = useKlassesIndexStore()

    const loading = ref(false)
    const klassUrl = computed(() =>
      authorizedAsTeacher.value ? Routes.teacher_klass_path(props.klass.id) : Routes.student_klass_path(props.klass.id),
    )
    const archive = () => {
      loading.value = true
      archiveKlass(props.klass).then(() => (loading.value = false))
    }
    const unarchive = () => {
      loading.value = true
      unarchiveKlass(props.klass).then(() => (loading.value = false))
    }

    return {
      openModal,
      canModalShow,
      authorizedAsTeacher,
      loading,
      klassUrl,
      archive,
      unarchive,
      teacherNamesList: computed(() => (props.klass ? props.klass.teacherNames.join(', ') : null)),
      klassUpdateModalId: computed(() => `${KlassUpdateModal.name}-${props.klass.id}`),
      klassDeleteModalId: computed(() => `${KlassDeleteModal.name}-${props.klass.id}`),
    }
  },
}
</script>
