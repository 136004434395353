<template>
  <div class="listable-editor">
    <SectionEditor
      v-if="block.detailType === 'Section'"
      v-bind="{
        blocks: block.content.blocks,
        title: block.title,
        sectionId: block.id,
        index,
        totalBlockCount,
        editorId,
        isAddingInserts: true,
      }"
      @update-block="$emit('update-block', $event)"
    />
    <WikiBlock v-else :blocks="[block]" class="w-full" />
    <div class="pl-3 pt-2">
      <button :data-test="`hide-btn-${block.id}`" class="btn btn--small" @click="toggleHidden">
        {{ capitalize(isHidden ? $t('common.show') : $t('common.hide')) }}
      </button>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import WikiBlock from 'vue_features/resources/wikis/components/WikiBlock'
import useDeletedEdits from '../store/use_deleted_edits'
import SectionEditor from 'vue_features/resources/wikis/components/SectionEditor'

export default {
  name: 'WikiBlockHider',
  components: { SectionEditor, WikiBlock },
  props: {
    block: { type: Object, required: true },
    index: { type: Number, required: true },
    totalBlockCount: { type: Number, required: true },
    editorId: { type: String, required: true },
  },
  setup() {
    const { deleteHide, undeleteOrCreateHide } = useDeletedEdits()
    return { deleteHide, undeleteOrCreateHide }
  },
  computed: {
    isHidden() {
      return this.block.blockHide
    },
  },
  methods: {
    capitalize,
    toggleHidden() {
      if (this.isHidden) {
        this.deleteHide(this.block.blockHide)
        this.$emit('update-block', { blockHide: null })
      } else {
        this.$emit('update-block', { blockHide: this.undeleteOrCreateHide(this.block.id) })
      }
    },
  },
}
</script>
