<template>
  <div
    class="lesson-toolbar__button-wrapper relative h-auto flex-none flex-col items-stretch justify-center transition-colors"
  >
    <button
      data-test="toolbar-item-button"
      :disabled="disabled"
      class="lesson-toolbar__button flex h-full w-full flex-col items-center justify-center px-4 xl:px-6"
      :class="{ active: active, 'disabled opacity-50': disabled }"
      @click.stop="handleClick"
    >
      <InlineSvg class="h-8 w-8 text-white" size="xl" :path="iconPath" />
      <span class="mb-0 mt-1 text-sm text-white">{{ label }}</span>
    </button>
    <div
      v-if="hasSubmenu && showingMenu"
      data-test="toolbar-item-submenu"
      class="lesson-toolbar__button-menu opacity-1 absolute space-y-2 rounded-2xl transition-all"
    >
      <slot name="menu" />
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import { useNamespacedEventListener } from 'vue_features/shared/composables'
import { ref, onBeforeUnmount, watch } from 'vue'

export default {
  name: 'LessonToolbarItem',
  components: { InlineSvg },
  props: {
    iconPath: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const hideContentFromDom = ref(false)

    const { on: bodyOn, off: bodyOff } = useNamespacedEventListener(document.body)
    const showingMenu = ref(false)
    const CLICK = 'click.lessontoolbaritem'

    const handleClick = () => {
      if (props.disabled) return

      if (!props.hasSubmenu) {
        return emit('click')
      }

      showingMenu.value = !showingMenu.value
    }

    const menuHandler = () => {
      showingMenu.value = false
      bodyOff(CLICK)
    }

    watch(showingMenu, () => {
      if (showingMenu.value) {
        hideContentFromDom.value = false
        setTimeout(() => {
          bodyOn(CLICK, menuHandler)
        }, 1)
      } else {
        bodyOff(CLICK)
        setTimeout(() => {
          hideContentFromDom.value = true
        }, 250)
      }
    })

    onBeforeUnmount(() => {
      bodyOff(CLICK)
    })

    return {
      handleClick,
      showingMenu,
      hideContentFromDom,
    }
  },
}
</script>

<style scoped>
.lesson-toolbar__button.disabled {
  background-color: transparent;
  pointer-events: none;
}

.lesson-toolbar__button:hover,
.lesson-toolbar__button.active {
  @apply bg-primary-accent;
}
.lesson-toolbar__button {
  @apply transition-all;
  border-radius: inherit;
}
.lesson-toolbar__button-menu {
  width: auto;
  bottom: 80px;
  left: 0;
  background-color: rgba(30, 26, 101, 0.95);
}
</style>
