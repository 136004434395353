import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

function wrapData(data) {
  return snakeKeys({ klass: data })
}

const KlassesIndexService = {
  createKlass({ klassName }) {
    const url = Routes.teacher_klasses_path()

    return HTTP.create(url, wrapData({ name: klassName }))
  },

  getKlasses({ klassType, nextPage, excludedIds, includedIds }) {
    const url = Routes.teacher_klasses_path()
    const attrs = { state: klassType, page: nextPage, excludedIds, includedIds }
    return HTTP.getJSON(url, snakeKeys(attrs))
  },

  getKlass({ klassId }) {
    const url = Routes.teacher_klass_path(klassId)
    return HTTP.getJSON(url)
  },

  deleteKlass({ klass }) {
    const url = Routes.teacher_klass_path(klass.id)
    return HTTP.delete(url)
  },

  updateKlassName({ klass, name }) {
    const url = Routes.teacher_klass_path(klass.id)
    const data = wrapData({ id: klass.id, name })

    return HTTP.put(url, data)
  },

  linkToGoogleCourse({ klass, courseId }) {
    const url = Routes.teacher_klass_google_classroom_course_path(klass.id)
    const attrs = { googleClassroomCourseId: courseId }

    return HTTP.create(url, snakeKeys(attrs))
  },

  unlinkToGoogleCourse({ klass }) {
    const url = Routes.teacher_klass_google_classroom_course_path(klass.id)
    return HTTP.delete(url)
  },

  archiveKlass(klass) {
    const url = Routes.teacher_klass_path(klass.id)

    return HTTP.put(url, wrapData({ active: false }))
  },

  unarchiveKlass(klass) {
    const url = Routes.teacher_klass_path(klass.id)

    return HTTP.put(url, wrapData({ active: true }))
  },

  getTeachersForKlass(klass) {
    const url = Routes.teacher_klass_teachers_path(klass.id)

    return HTTP.getJSON(url)
  },
}

export default KlassesIndexService
