<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <Banner v-if="lzCodes.length > 0 && isVisible" type="inverted" dismissible>
    <div>
      {{ notifyMessage }}
      <div v-if="lzCodes.length > 1" key="multipleLzCodes" class="ml-1.5 inline-block space-x-1">
        <ListDropdown id="lz-codes-dropdown" :button-classes="['btn']" :dropdown-classes="['left-0 max-w-64']">
          <template #button>
            <div class="select-dropdown no-fullstory flex items-center justify-center">
              <span>{{ selectedLzCode }}</span>
              <LzIcon type="div" class="ml-4" path="icons/caret" />
            </div>
          </template>
          <template #listItems>
            <li
              v-for="lzCode in unselectedLzCodes"
              :key="lzCode.code"
              class="select-dropdown__option"
              @click="selectedLzCode = lzCode.code"
            >
              <LzIcon type="div" path="icons/complete" />
              <div class="select-dropdown__option-description">
                <h5>
                  {{ lzCode.code }}
                </h5>
                <div v-if="lzCode.assigner" key="assignedFromWhom">
                  <p class="smallest">
                    {{ lzCode.assigner }}
                  </p>
                </div>
                <div v-else key="createdAt">
                  <p class="smallest">
                    {{ $t('created') }}
                    {{ timeAgo(lzCode.createdAt) }}
                  </p>
                </div>
              </div>
            </li>
          </template>
        </ListDropdown>
        <a id="go" :href="Routes.lz_code_path(selectedLzCode)" class="btn btn--green">
          {{ $t('Go') }}
        </a>
      </div>
      <div v-else key="singleLzCodes" class="inline-block">
        <a
          :href="Routes.lz_code_path(selectedLzCode)"
          class="text-accent-inverted hover:text-accent-inverted-hover inline-block underline"
        >
          {{
            $t('show.go_to_assignment', {
              lz_code: selectedLzCode,
              assigner: defaultLzCode.assigner,
            })
          }}
        </a>
      </div>
    </div>
  </Banner>
</template>

<script>
import { ref, computed, inject } from 'vue'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { Banner, LzIcon } from 'vue_features/shared/components/ui'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'LzCodeBanner',
  components: { Banner, LzIcon, ListDropdown },
  props: {
    lzCodes: { type: Array, required: true },
    lessonType: { type: String, required: true },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { isStudent } = useCurrentUserStore()
    const isVisible = ref(true)
    const notifyMessage = computed(() => {
      return root.$t('show.notify_have_assignments', {
        count: props.lzCodes.length,
        assignments: root.$tc('show.vue_assignment', props.lzCodes.length),
        lesson_type: props.lessonType,
      })
    })
    const defaultLzCode = computed(() => (props.lzCodes.length > 0 ? props.lzCodes[0] : {}))
    const selectedLzCode = ref(defaultLzCode.value.code)
    const unselectedLzCodes = computed(() => props.lzCodes.filter((lzCode) => lzCode.code !== selectedLzCode.value))
    return {
      Routes,
      isStudent,
      isVisible,
      selectedLzCode,
      notifyMessage,
      unselectedLzCodes,
      defaultLzCode,
      timeAgo,
    }
  },
}
</script>
