<!--
  eslint-disable vuejs-accessibility/alt-text
  eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <FilestackUpload
    v-slot="{ launch, baseUrl, fileUrl, fileExists, filename }"
    :file="coverImage"
    :accept="$options.accept"
    :max-size="$options.maxSize"
    @upload-done="onUploadDone"
  >
    <h5>
      {{ $t('lesson_plans.form_template.cover_image') }}
      <span class="weak regular">
        {{ $t('lesson_plans.info_form.optional') }}
      </span>
    </h5>

    <div class="file-upload space-y-4">
      <!-- with cover image -->
      <div v-if="fileExists" class="flex-col">
        <div class="file-upload__preview">
          <img :src="fileUrl" />
        </div>
        <p class="file-upload__button file-upload__button align-center flex w-full flex-grow flex-wrap space-y-2">
          <LzButton class="w-full" data-test="cover-image-remove" @click.prevent="removeFile">
            {{ $t('components.cover_image_upload.remove') }}
          </LzButton>
          <LzButton v-bypass-unload-confirmation tag="a" class="w-full" :href="fileUrl">
            <span v-if="filename" key="has-filename" class="truncate">{{ filename }}</span>
            <span v-else key="no-filename">{{ $t('components.cover_image_upload.download') }}</span>
          </LzButton>
        </p>
      </div>

      <!-- no cover image -->
      <div v-if="!fileExists" class="cover-image-placeholder rounded">
        <InlineSvg :path="placeholderPath" />
      </div>

      <p class="text-muted text-sm drop-shadow-none">
        <strong>{{ $t('components.cover_image_upload.formats') }}</strong>
        {{ $t('components.cover_image_upload.upload_image_explanation', { file_types: $options.accept.join(', ') }) }}
        <strong>{{ $t('components.cover_image_upload.dimension_title') }}</strong>
        {{ $t('components.cover_image_upload.dimensions') }}
      </p>
      <p>
        <strong
          ><a class="text-primary text-sm drop-shadow-none" :href="helpUrl" target="_blank">
            {{ $t('resources.filesize_tips') }}
          </a></strong
        >
      </p>

      <input v-if="fieldName" :value="baseUrl" :name="fieldName" type="hidden" />
      <button class="btn file-upload__button" type="button" @click="launch">
        <div v-if="fileUrl" key="replace-file">
          {{ $t('activerecord_attributes.resource.replace_file') }}
        </div>
        <div v-else key="select-file">
          {{ $t('activerecord_attributes.resource.select_file') }}
        </div>
      </button>
    </div>
  </FilestackUpload>
</template>

<script>
import { bypassUnloadConfirmation } from 'vue_features/shared/directives'
import { InlineSvg, LzButton } from 'vue_features/shared/components/ui'
import FilestackUpload from 'vue_features/shared/components/filestack_upload/FilestackUpload'
import { HELP_RESIZE_FILES_URL } from 'rails_constants'
import { config } from 'utils'

const { accept, maxSize } = config.coverImage

export default {
  name: 'CoverImageUpload',

  accept,
  maxSize,

  components: {
    InlineSvg,
    FilestackUpload,
    LzButton,
  },

  directives: {
    bypassUnloadConfirmation,
  },

  props: {
    coverImage: { type: Object, required: true },
    fieldName: { type: String, default: null },
    placeholder: { type: String, default: 'lessonplan-placeholder.svg' },
  },
  computed: {
    placeholderPath() {
      return `icons/${this.placeholder}`
    },

    helpUrl() {
      return HELP_RESIZE_FILES_URL
    },
  },

  methods: {
    onUploadDone(coverImage) {
      this.$emit('upload-done', coverImage)
    },

    removeFile() {
      this.$emit('remove-file', {})
    },
  },
}
</script>
