<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <p class="my-2">Are you sure you want to revoke access from {{ share.customerName }}?</p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution" data-test="revoke-button" @click="destroy">Revoke</button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { useCollectionsIndexStore } from '../../store/use_collections_index_store'
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'DeleteShareModal',
  components: { LzConfirmModal },
  props: {
    share: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { deleteCollectionShare, isProgressCoverShown } = useCollectionsIndexStore()
    return { closeModal, deleteCollectionShare, isProgressCoverShown }
  },
  methods: {
    destroy() {
      this.isProgressCoverShown = true
      const hideCover = () => (this.isProgressCoverShown = false)
      this.deleteCollectionShare(this.share).then(() => {
        this.closeModal(this.$options.name)
        hideCover()
      }, hideCover.bind(this))
    },
  },
}
</script>
