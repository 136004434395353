<template>
  <div class="flex h-full flex-col">
    <div class="flex flex-none items-center border-b border-gray-100 p-4">
      <div class="flex-grow">
        <span class="font-bold">{{ currentItem.name }}</span>
        <p class="space-x-1">
          <span class="text-muted text-sm">{{ $t('average_score') }}</span>
          <span class="text-base text-sm font-bold">{{ classAverageScore }}%</span>
        </p>
      </div>
    </div>

    <div
      class="basis-0 shrink-0 flex-grow overflow-y-auto overflow-x-hidden p-4"
      style="flex-grow: 1; flex-shrink: 0; flex-basis: 0"
    >
      <div>
        <h3 class="m-0 mb-1 flex items-center space-x-2 text-sm font-semibold text-gray-700">
          <LzIcon path="icons/flag" size="sm" />
          <span>{{ $t('standards') }}</span>
        </h3>

        <div class="flex flex-wrap gap-2">
          <span v-if="standards.length === 0" class="text-base">{{ $t('none') }}</span>

          <LzTooltip
            v-for="standard of standards"
            :key="standard.id"
            strategy="fixed"
            max-width-class="max-w-sm w-full "
            tag="span"
          >
            <template #content>
              <div class="flex flex-col">
                <p class="text-primary-600 font-bold"> [{{ standard.displayCode }}] </p>

                <p class="text-muted">
                  {{ standard.name }}
                </p>
              </div>
            </template>

            <span class="bg-primary-100 text-primary-600 break-normal rounded-full px-2 py-1 text-xs font-semibold">
              {{ standard.displayCode }}
            </span>
          </LzTooltip>
        </div>
      </div>

      <Alert v-if="currentItem.scoringType === 'dependent'" type="notice" class="mt-2">
        {{ $t('dependent_scoring') }}
      </Alert>

      <Alert v-if="currentItem.scoringType === 'dichotomous'" type="notice" class="mt-2">
        {{ $t('dichotomous_scoring') }}
      </Alert>

      <div v-if="currentItem && currentItem.scoringRubric">
        <div v-if="currentItem.scoringRubric.type === 'Wiki'" class="pt-4 text-sm">
          <WikiResource
            :blocks="currentItem.scoringRubric.blocks"
            :inserted-blocks="currentItem.scoringRubric.insertedBlocks"
          />
        </div>

        <div v-if="currentItem.scoringRubric.type === 'Document'" class="pt-4">
          <a :href="currentItem.scoringRubric.downloadFileUrl" class="">{{ $t('download_scoring_guidance') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { LzIcon, LzTooltip, Alert } from 'vue_features/shared/components/ui'
import WikiResource from 'vue_features/resources/wikis/components/WikiResource'
import { useGradingStore } from '../composables/use_grading_store'

export default {
  name: 'SidebarContent',
  components: { LzIcon, LzTooltip, WikiResource, Alert },
  setup() {
    const { currentItem, loadingSession, averageScoreForItem } = useGradingStore()

    const standards = computed(() => currentItem.value.customerVisibleStandards)

    const itemHasUpdatingScores = computed(() => currentItem.value.updating)

    const classAverageScore = computed(() => Math.round(averageScoreForItem(currentItem.value.reference)))

    return {
      currentItem,
      standards,
      loadingSession,
      itemHasUpdatingScores,
      classAverageScore,
    }
  },
}
</script>
