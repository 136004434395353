<template>
  <div class="checkbox">
    <label :for="inputId">
      <input :id="inputId" v-model="localValue" :disabled="disabled" :value="optionValue" type="checkbox" />
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
