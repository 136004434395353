<template>
  <LzConfirmModal
    modal-id="publish-confirm-modal"
    :modal-title="
      $t('lesson_plans.admin_bar.publish_confirmation_message', { lesson_type: $t(`readable_types.${lessonType}`) })
    "
  >
    <template #confirmBody>
      <p class="bold">
        {{
          $t('lesson_plans.admin_bar.once_published_warning_header', {
            lesson_type: $t(`readable_types.${lessonType}`),
          })
        }}
      </p>
      <ol class="align-left my-4 pl-10">
        <li>
          {{ $t('lesson_plans.admin_bar.cannot_revert_to_draft', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
        </li>
        <li>
          {{
            $t('lesson_plans.admin_bar.question_attaches_permanently', {
              lesson_type: $t(`readable_types.${lessonType}`),
            })
          }}
        </li>
      </ol>
    </template>
    <template #cancelButton>
      <button class="btn" @click="hideModal">
        {{
          $t('components.state_changer.keep_lp_as_draft', {
            type: $t(`readable_types.${lessonType}`),
            state: lessonPlan.state,
          })
        }}
      </button>
    </template>
    <template #confirmButton>
      <button data-test="confirm-publish-btn" class="btn btn--primary" @click="publish">
        {{ $t('components.state_changer.publish_lp', { type: $t(`readable_types.${lessonType}`) }) }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import LzConfirmModal from 'vue_features/shared/components/ui/LzConfirmModal'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import LessonPlanService from 'vue_features/lesson_plans/api/lesson_plans_service'
import { disableUnloadConfirm, location as $location } from 'utils'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import Routes from 'routes'
import { ACTIVE } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'PublishConfirmModal',
  components: { LzConfirmModal },
  setup() {
    const { lessonPlan, lessonType } = useLessonPlanStore()
    const { isProgressCoverDisplayed } = useLessonPlanEditStore()

    const publish = async () => {
      isProgressCoverDisplayed.value = true
      try {
        await LessonPlanService.updateLessonPlan(lessonPlan.value.id, { state: ACTIVE })
        disableUnloadConfirm()
        $location.assign(Routes.lesson_plan_path(lessonPlan.value.id))
      } catch {
        isProgressCoverDisplayed.value = false
      }
    }

    const hideModal = () => {
      disableUnloadConfirm()
      closeModal('PublishConfirmModal')
    }

    return { publish, hideModal, lessonPlan, lessonType }
  },
}
</script>
