<template>
  <tr>
    <td>{{ friendlyCreatedAt }}</td>
    <td>
      <a v-if="isDownloadable" :href="originalUrl">{{ filename }}</a>
      <span v-else>{{ filename }}</span>
    </td>
    <td>{{ type }}</td>
    <td class="flex items-center gap-1 whitespace-nowrap leading-6">
      <LoadingSpinner v-if="running" :inline="true" size="small" />
      <LzIcon v-if="!running" :path="'icons/' + status.replace(/_/g, '-')" icon-color-class="text-green-400" />

      {{ $t('user_imports.index.' + status) }}
    </td>
    <td v-if="expired" class="text-gray">
      {{ $t('user_imports.index.expired') }}
    </td>
    <td v-else-if="status == 'queued' || status == 'running'">
      {{ $t('user_imports.index.pending') }}
    </td>
    <td v-else-if="status == 'complete'">
      {{ $t('user_imports.index.no_errors') }}
    </td>
    <td v-else-if="status == 'complete_with_errors'">
      <a :href="reportUrl">{{ $t('common.download') }}</a>
    </td>
    <td v-else-if="status == 'invalid_format'">
      {{ $t('user_imports.index.unavailable') }}
    </td>
    <td v-else>
      <a href="/contact">{{ $t('user_imports.index.contact_support') }}</a>
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'
import Routes from 'routes'

import { LzIcon, LoadingSpinner } from 'vue_features/shared/components/ui'

export default {
  name: 'UserImport',
  components: { LzIcon, LoadingSpinner },
  props: {
    id: { type: Number, required: true },
    createdAt: { type: String, required: true },
    filename: { type: String, required: true },
    type: { type: String, required: true },
    status: { type: String, required: true },
    source: { type: String, default: '' },
    expired: { type: Boolean, default: false },
  },
  computed: {
    isDownloadable() {
      return !this.expired && !this.isClever
    },
    isClever() {
      return this.source === 'clever'
    },
    originalUrl() {
      return Routes.site_admin_user_import_original_path(this.id)
    },
    reportUrl() {
      return Routes.site_admin_user_import_report_path(this.id)
    },
    friendlyCreatedAt() {
      return dayjs(this.createdAt).fromNow()
    },
    running() {
      return this.status == 'created' || this.status == 'running' || this.status == 'queued'
    },
  },
}
</script>
