import { ref, computed } from 'vue'

export default function useEditableBlocks(originalBlocks) {
  const blocks = ref([...originalBlocks])

  const totalBlockCount = computed(() => {
    let count = blocks.value.length
    let sectionBlocks = blocks.value.filter((b) => b.detailType === 'Section')

    while (sectionBlocks.length > 0) {
      const block = sectionBlocks.pop()
      count += block.content.blocks.length
      sectionBlocks = sectionBlocks.concat(block.content.blocks.filter((b) => b.detailType === 'Section'))
    }

    return count
  })

  const updateBlock = ({ block, updates }) => {
    const index = blocks.value.indexOf(block)
    if (index >= 0) {
      blocks.value.splice(index, 1, { ...block, ...updates })
    }
  }

  const removeBlock = (index) => {
    blocks.value.splice(index, 1)
  }

  return { blocks, totalBlockCount, updateBlock, removeBlock }
}
