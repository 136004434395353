<template>
  <button
    class="hover:bg-neutral-50 group flex flex-wrap items-center gap-2 px-4 py-3 transition-colors"
    :style="buttonStyle"
    @click="toggleStudentList"
  >
    <div
      v-if="allowDetailToggle"
      class="group-hover:bg-base-hover group-hover:text-accent flex cursor-pointer items-center rounded-lg p-1 text-base transition-colors"
    >
      <LzIcon path="icons/chevron-right" class="transition-transform" :style="arrowStyle" />
    </div>

    <div class="flex items-center gap-1">
      <LzIcon path="icons/color" size="custom" class="h-4 w-4" :style="{ color: scoreFill }" />
      <div :style="{ color: scoreColor }" class="text-sm font-bold">
        {{ scoreLabel }}
      </div>
      <div class="pl-2 text-base text-sm leading-5">{{ countLabel }}</div>
    </div>

    <div class="w-full" :class="{ hidden: !studentListOpen }">
      <ul class="border-neutral-500 border-t-0.5 grid grid-cols-2 border-solid py-2">
        <li v-for="(name, index) in truncatedStudents" :key="`${name}-${index}`" class="text-muted text-xs">
          {{ name }}
        </li>
      </ul>

      <div v-if="numNotShown > 0">{{ $t('plus_n_more_students', { num: numNotShown }) }}</div>
    </div>
  </button>
</template>

<script setup>
import { ref, computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import { scoreBandColor, scoreBandFill, ratingForScore } from 'vue_features/shared/utils/scoring'
import { isNumeric } from 'utils'

const props = defineProps({
  score: {
    type: [Number, String],
    default: 'N/A',
  },
  countLabel: {
    type: String,
    required: true,
  },
  allowDetailToggle: {
    type: Boolean,
    default: false,
  },
  students: {
    type: Array,
    default: () => [],
  },
})

const studentListOpen = ref(false)
const scoreColor = computed(() => scoreBandColor(ratingForScore(props.score)))
const scoreFill = computed(() => scoreBandFill(ratingForScore(props.score)))
const scoreLabel = computed(() => (isNumeric(props.score) ? `${props.score}%` : props.score))
const buttonStyle = computed(() => (!props.allowDetailToggle ? { cursor: 'unset' } : {}))
const studentCount = computed(() => props.students.length)
const numNotShown = computed(() => studentCount.value - 100)
const truncatedStudents = computed(() => props.students.slice(0, 100))
const arrowStyle = computed(() => ({
  transform: `rotate(${studentListOpen.value ? 90 : 0}deg)`,
}))
const toggleStudentList = () => {
  if (!props.allowDetailToggle) return
  studentListOpen.value = !studentListOpen.value
}
</script>
