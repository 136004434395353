import dayjs from 'dayjs'

export const formattedWhen = (when) => {
  return dayjs(when).format('MMM D, YYYY - h:mm A')
}
export const actionVerb = (what) => {
  switch (what) {
    case 'create':
      return 'updated_notification.created'
    case 'destroy':
      return 'updated_notification.destroyed'
    default:
      return 'updated_notification.edited'
  }
}

export const formattedWho = (who, $t) => who || $t('common.unknown')
