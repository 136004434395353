<template>
  <div class="relative">
    <label for="new_comment" class="sr-only">
      {{ $t('comment') }}
    </label>
    <textarea
      id="new_comment"
      ref="commentInputRef"
      v-model="comment"
      class="resize-none border-none px-2 pb-6 pt-2 shadow-none"
      :class="disabledClasses"
      :disabled="disabled"
    ></textarea>
    <div v-show="comment === ''" class="absolute bottom-0 left-0 w-full space-x-2 overflow-auto whitespace-nowrap p-2">
      <LzButton
        v-for="commentSuggestion of commentSuggestions"
        :key="commentSuggestion"
        :disabled="disabled"
        class="border-base hover:text-accent-hover hover:bg-base hover:border-accent text-accent rounded-full border px-2 py-1 text-sm font-bold shadow-none transition-colors"
        @click="appendComment(commentSuggestion)"
      >
        {{ commentSuggestion }}
      </LzButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, inject } from 'vue'
import { LzButton } from 'vue_features/shared/components/ui'

export default {
  name: 'CommentBox',
  components: { LzButton },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const comment = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      },
    })

    const disabledClasses = computed(() => (props.disabled ? ['bg-neutral-100 rounded-lg cursor-not-allowed'] : []))

    const commentInputRef = ref(null)
    const commentSuggestions = [
      root.$t('nicely_done'),
      root.$t('great_job'),
      root.$t('show_your_work'),
      root.$t('come_see_me'),
      '👍',
      '♥️',
    ]
    const appendComment = (text) => {
      comment.value += `${text} `
      commentInputRef.value.scrollTop = commentInputRef.value.scrollHeight
    }

    onMounted(() => {
      commentInputRef.value.focus()
    })

    return {
      comment,
      commentInputRef,
      commentSuggestions,
      appendComment,
      disabledClasses,
    }
  },
}
</script>
