<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="border-base bg-focus rounded border shadow">
    <label class="filter-label border-base bold small m-0 w-full border-b px-3 py-2">
      {{ label }}
    </label>
    <div class="px-3 pb-3">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterBox',
  props: { label: { type: String, required: true } },
}
</script>
