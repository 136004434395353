<template>
  <div class="card-content--vcenter text-center">
    <template v-if="isAssessment">
      <EmptyStateWithAnimation
        v-if="isAssignmentViewSubmitted"
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.end_of_set')"
        :body="$t('lesson_card_templates.submitted_assessment')"
        data-test="submitted-assessment"
      >
        <button class="btn btn--primary" data-test="assessment-done" @click.prevent="returnToAssignmentsList">
          {{ $t('common.done') }}
        </button>
      </EmptyStateWithAnimation>
      <template v-else>
        <EmptyStateWithAnimation
          v-if="hasCompleted"
          key="empty-state-completed"
          ref="emptyState"
          :animation-loader="() => import('lottie-animations/finished-work.json')"
          autoplay
          loop
          :heading="$t('lesson_card_templates.end_of_assessment')"
          :body="$t('lesson_card_templates.may_review_change')"
          data-test="completed-assessment"
        >
          <div class="flex items-center gap-2">
            <button class="btn m-0" data-test="assessment-review" @click.prevent="$emit('review')">
              <LzIcon type="div" path="icons/previous" />
              {{ $t('common.review') }}
            </button>
            <button class="btn btn--primary m-0" data-test="assessment-submit" @click.prevent="submitCards">
              {{ $t('common.submit') }}
            </button>
          </div>
        </EmptyStateWithAnimation>
        <EmptyStateWithAnimation
          v-else
          key="empty-state-not-completed"
          ref="emptyState"
          :animation-loader="() => import('lottie-animations/finished-work.json')"
          autoplay
          loop
          :heading="unattemptedQuestionsHeading"
          :body="$t('lesson_card_templates.request_review_change')"
        >
          <div class="flex items-center gap-2">
            <button class="btn btn--primary m-0" data-test="assessment-review" @click.prevent="$emit('review')">
              <LzIcon type="div" path="icons/previous" />
              {{ $t('common.review') }}
            </button>
            <button class="btn m-0" data-test="assessment-submit" @click.prevent="submitCards">
              {{ $t('common.submit') }}
            </button>
          </div>
        </EmptyStateWithAnimation>
      </template>
    </template>
    <template v-else>
      <EmptyStateWithAnimation
        v-if="questionCardsCount > 0"
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.thanks_for_the_effort')"
        :body="$t('lesson_card_templates.may_review_change')"
      >
        <div class="flex">
          <button class="btn mr-3" data-test="non-assessment-review" @click.prevent="$emit('review')">
            <LzIcon type="div" path="icons/previous" />
            {{ $t('common.review') }}
          </button>
          <button class="btn btn--primary" data-test="non-assessment-done" @click.prevent="submitAndReturnToList">
            {{ $t('common.done') }}
          </button>
        </div>
      </EmptyStateWithAnimation>
      <EmptyStateWithAnimation
        v-else
        ref="emptyState"
        :animation-loader="() => import('lottie-animations/lesson-done.json')"
        autoplay
        :heading="$t('lesson_card_templates.thanks_for_the_effort')"
      >
        <button
          class="btn btn--primary"
          data-test="non-assessment-without-questions-done"
          @click.prevent="submitAndReturnToList"
        >
          {{ $t('common.done') }}
        </button>
      </EmptyStateWithAnimation>
    </template>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { location as $location } from 'utils'
import { EmptyStateWithAnimation, LzIcon } from 'vue_features/shared/components/ui'
import {
  useAssignmentStore,
  useAssignmentShowStore,
  useAssignmentCardsStore,
  useLiveLearnStore,
} from 'vue_features/assignments/show/composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'AssignmentEndCard',
  components: {
    EmptyStateWithAnimation,
    LzIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const root = inject('useRoot')()
    const { isAssessment } = useAssignmentStore()
    const { assignmentViewKlassId, isAssignmentViewSubmitted } = useAssignmentShowStore()
    const { isLiveLearn } = useLiveLearnStore()
    const { attemptedQuestionCardsCount, questionCardsCount, submitCards, allowNavigationAway } =
      useAssignmentCardsStore()

    const unattemptedQuestionCardsCount = computed(() => questionCardsCount.value - attemptedQuestionCardsCount.value)
    const unattemptedQuestionsHeading = computed(() => {
      if (unattemptedQuestionCardsCount.value === 1) {
        return root.$t('lesson_card_templates.unanswered_question')
      } else {
        return root.$t('lesson_card_templates.unanswered_questions', { count: unattemptedQuestionCardsCount.value })
      }
    })
    const hasCompleted = computed(() => attemptedQuestionCardsCount.value === questionCardsCount.value)
    const { isLoggedIn, isStudent } = useCurrentUserStore()
    const returnToAssignmentsList = async () => {
      await allowNavigationAway()
      if (isLiveLearn.value) return

      if (!isLoggedIn.value) return $location.assign(Routes.root_path())

      let path
      if (isStudent.value && assignmentViewKlassId.value) {
        path = Routes.student_klass_path(assignmentViewKlassId.value)
      } else {
        path = isStudent.value ? Routes.student_klasses_path() : Routes.teacher_klasses_path()
      }
      $location.assign(path)
    }
    const submitAndReturnToList = async () => {
      await allowNavigationAway()
      await submitCards()
      returnToAssignmentsList()
    }

    return {
      isAssessment,
      isAssignmentViewSubmitted,
      hasCompleted,
      questionCardsCount,
      returnToAssignmentsList,
      submitAndReturnToList,
      submitCards,
      unattemptedQuestionCardsCount,
      unattemptedQuestionsHeading,
    }
  },
  watch: {
    isActive: function (newValue) {
      if (newValue) {
        this.$refs.emptyState.play()
      }
    },
  },
}
</script>
