import { unref } from 'vue'
import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const StudentDataReportsService = {
  createReport({ startDate, endDate, lessonTypes, reportScope, scoreScope }) {
    const attrs = snakeKeys({
      lessonTypes: unref(lessonTypes),
      scoreScope: unref(scoreScope),
      studentDataReport: {
        dataStartAt: unref(startDate),
        dataEndAt: unref(endDate),
        reportScope: unref(reportScope),
      },
    })
    return HTTP.create(Routes.student_data_reports_path(), attrs)
  },

  deleteReport(id) {
    return HTTP.delete(Routes.student_data_report_path(id))
  },

  getReports(page = 1) {
    const url = Routes.student_data_reports_path({ page })
    return HTTP.getJSON(url)
  },
}

export default StudentDataReportsService
