<!-- eslint-disable vuejs-accessibility/click-events-have-key-events  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="mx-auto max-w-screen-lg px-4 py-10">
    <div class="w-full">
      <h1>{{ $t('index.download_data') }}</h1>
      <p class="mt-4 max-w-prose">{{ $t('index.description') }}</p>

      <div class="date-select-wrapper form-box mt-6" :class="{ 'field-error': !isDateRangeValid }">
        <DateSelect v-model="startDate" :label="$t('index.date_from')" :disabled="isUserInputDisabled" />
        <DateSelect v-model="endDate" :label="$t('index.date_to')" :disabled="isUserInputDisabled" class="ml-2" />
      </div>
      <div v-if="!isDateRangeValid" class="text-red p-1 text-xs" data-test="invalid-dates-notice">
        {{ $t('index.invalid_dates') }}
      </div>
      <div class="mt-8">
        <h5 class="text-sm font-semibold">{{ $t('index.select_lesson_type') }}</h5>
        <div class="inline-selection-wrapper space-x-10">
          <TypeCheckbox v-model="lessonTypes" :disabled="isUserInputDisabled" option-value="full" input-id="full">
            {{ $t('show.lesson_plan_type.full') }}
          </TypeCheckbox>
          <TypeCheckbox
            v-model="lessonTypes"
            :disabled="isUserInputDisabled"
            option-value="assessment"
            input-id="assessment"
          >
            {{ $t('show.lesson_plan_type.assessment') }}
          </TypeCheckbox>
          <TypeCheckbox
            v-model="lessonTypes"
            :disabled="isUserInputDisabled"
            option-value="activity"
            input-id="activity"
          >
            {{ $t('show.lesson_plan_type.activity') }}
          </TypeCheckbox>
        </div>
      </div>

      <div v-show="reportScopes.length > 1" class="mt-8 md:w-1/2">
        <label for="report-scope-select">{{ $t('index.report_scope.label') }}</label>
        <select id="report-scope-select" v-model="reportScope" :disabled="isUserInputDisabled">
          <option v-for="scope in reportScopes" :key="scope" :value="scope">
            {{ $t(`index.report_scope.${scope}`) }}
          </option>
        </select>
      </div>

      <div class="mt-8 md:w-1/2">
        <label for="score-scope-select">{{ $t('index.scores_type.label') }}</label>
        <select id="score-scope-select" v-model="scoreScope" :disabled="isUserInputDisabled">
          <option v-for="scope in scoreScopes" :key="scope" :value="scope">
            {{ $t(`index.scores_type.${scope}_scores`) }}
          </option>
        </select>
      </div>

      <button
        :disabled="!isGenerateButtonEnabled"
        type="button"
        class="btn btn--primary mb-4 mr-6 mt-8"
        @click="createReport"
      >
        {{ $t('index.generate_report') }}
      </button>

      <div class="mt-10">
        <template v-if="reportList.length > 0">
          <h4>{{ $t('index.my_files') }}</h4>

          <Poller :active="anyGenerating" @tick="pollReports">
            <table class="table-opened">
              <thead>
                <tr>
                  <th>{{ $t('index.date') }}</th>
                  <th>{{ $t('index.filename') }}</th>
                  <th>{{ $t('index.status') }}</th>
                </tr>
              </thead>

              <tbody>
                <StudentDataReport v-for="reportData in reportList" :key="reportData.id" :report-data="reportData" />
              </tbody>
            </table>
          </Poller>
        </template>

        <LzPaginator :current-page="page" :total-pages="pageCount" @page-changed="getPage" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import StudentDataReport from './StudentDataReport'
import { Poller, LzPaginator } from 'vue_features/shared/components/ui'
import dayjs from 'dayjs'
import DateSelect from 'vue_features/shared/components/selects/DateSelect'
import TypeCheckbox from './TypeCheckbox'
import { captureException } from 'clients/sentry'
import { useStudentDataReportsStore } from '../composables/use_student_data_reports_store'

export default {
  name: 'StudentDataReports',
  components: { StudentDataReport, Poller, DateSelect, TypeCheckbox, LzPaginator },
  setup() {
    const isBusy = ref(false),
      startDate = ref(dayjs().subtract(30, 'day').toDate()),
      endDate = ref(new Date()),
      lessonTypes = ref(['full', 'assessment', 'activity']),
      reportScope = ref(null),
      scoreScope = ref('total'),
      page = ref(1)

    const {
      reportScopes,
      scoreScopes,
      generatingReports,
      reportList,
      createStudentDataReport,
      fetchReports,
      pageCount,
    } = useStudentDataReportsStore()
    const canGenerateNew = computed(() => generatingReports.value.length === 0)
    const anyGenerating = computed(() => generatingReports.value.length > 0)
    const isDateRangeValid = computed(() => startDate.value <= endDate.value)
    const isUserInputDisabled = computed(() => isBusy.value || !canGenerateNew.value)
    const isGenerateButtonEnabled = computed(
      () => !isUserInputDisabled.value && isDateRangeValid.value && lessonTypes.value.length > 0,
    )

    function getPage(newPage) {
      page.value = parseInt(newPage)
      fetchReports(page.value).catch(captureException)
    }

    function createReport() {
      isBusy.value = true
      createStudentDataReport({ startDate, endDate, lessonTypes, reportScope, scoreScope }).then(() => {
        isBusy.value = false
      })
    }

    function pollReports(done) {
      fetchReports(page.value).catch(captureException).finally(done)
    }
    watch(reportScopes, () => (reportScope.value = reportScopes.value[0]), { immediate: true })

    return {
      startDate,
      endDate,
      lessonTypes,
      reportScope,
      scoreScope,
      reportScopes,
      scoreScopes,
      reportList,
      createReport,
      pollReports,
      page,
      pageCount,
      getPage,
      anyGenerating,
      isDateRangeValid,
      isUserInputDisabled,
      isGenerateButtonEnabled,
    }
  },
}
</script>
