import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const ResourcesRevisionService = {
  create(originalId, type, payload) {
    const url = Routes.resource_revision_path(originalId),
      params = {
        resourceType: type,
        [type]: payload,
      }

    return HTTP.create(url, snakeKeys(params))
  },

  update(originalId, id, type, payload) {
    const url = Routes.resource_revision_path(originalId, id),
      params = {
        resourceType: type,
        [type]: payload,
      }

    return HTTP.put(url, snakeKeys(params))
  },
}

export default ResourcesRevisionService
