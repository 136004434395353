<!--
  eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div v-if="open" class="z-100 fixed left-0 top-0 h-screen w-screen" data-test="lesson-plan-chooser">
    <div class="overlay absolute left-0 top-0 z-10 h-full w-full"></div>

    <div
      ref="modalBody"
      class="absolute left-1/2 top-1/2 z-20 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col bg-white shadow-lg xl:h-5/6 xl:w-4/5 xl:rounded-2xl"
    >
      <PortalTarget name="itemAnalysisLessonPlanChooser" />

      <div class="flex-none p-8">
        <p v-if="!lessonPlan" class="text-primary-500 text-lg font-semibold">{{ $t('choose_lesson_heading') }}</p>
        <p v-else class="text-primary-500 text-lg font-semibold">{{ $t('choose_different_lesson_heading') }}</p>
        <p class="text-neutral-700">{{ $t('choose_lesson_help_text') }}</p>
      </div>

      <div class="flex flex-grow items-stretch space-x-8 overflow-hidden px-8">
        <div class="border-primary-100 bg-neutral-50 flex flex-grow flex-col justify-items-stretch rounded-lg border">
          <form class="flex flex-none space-x-4 p-4" @submit.prevent="searchLessons">
            <input
              id="lesson-plan-search"
              v-model="lessonPlanTitle"
              class="ml-0"
              type="text"
              :placeholder="$t('lesson_plan_title')"
            />
            <label for="lesson-plan-search" class="sr-only">
              {{ $t('lesson_plan_title') }}
            </label>
            <LzButton type="submit">{{ $t('common.search') }}</LzButton>
          </form>

          <div class="flex flex-col overflow-auto">
            <div v-if="loading" class="inline-flex flex-none items-center space-x-2 p-4">
              <LoadingSpinner />
            </div>

            <div v-else-if="lessonPlanSuggestions.length === 0" class="w-full flex-none p-4">
              {{ $t('no_lessons_match_copy') }}
            </div>

            <button
              v-for="lessonPlanSuggestion of lessonPlanSuggestions"
              v-else
              :key="lessonPlanSuggestion.id"
              class="w-100 lesson-plan-picker-option hover:bg-primary-accent group relative flex cursor-pointer flex-col justify-start px-4 py-1 text-left xl:py-4"
              :class="isLessonPlanSelected(lessonPlanSuggestion) ? 'bg-primary-accent text-white' : ''"
              @click="selectedLessonPlan = lessonPlanSuggestion"
            >
              <div
                class="font-semibold group-hover:text-white"
                :class="isLessonPlanSelected(lessonPlanSuggestion) ? 'text-white' : 'text-primary-500'"
              >
                {{ lessonPlanSuggestion.title || '&nbsp;' }}
              </div>

              <div
                class="hidden text-xs group-hover:text-white xl:block"
                :class="isLessonPlanSelected(lessonPlanSuggestion) ? 'text-white' : 'text-primary-300'"
              >
                {{ truncatedBreadcrumbs(lessonPlanSuggestion) || '&nbsp;' }}
              </div>

              <div v-if="lessonPlanSuggestion.isAdaptation" class="absolute right-0 top-0 m-2">
                <span class="bg-orange-200 text-orange-700 inline-block rounded-full px-2 py-0.5 text-sm">
                  {{ $t('common.copy') }}
                </span>
              </div>
            </button>
          </div>

          <div class="border-primary-100 w-full flex-none border-t p-4 text-center xl:border-none">
            <LzButton :disabled="lessonPlanPagination.prevPage === null" @click="onPrevPage">
              {{ $t('common.previous') }}
            </LzButton>

            <LzButton :disabled="lessonPlanPagination.nextPage === null" @click="onNextPage">
              {{ $t('common.next') }}
            </LzButton>
          </div>
        </div>

        <div class="flex w-1/3 min-w-min flex-wrap overflow-auto pb-2">
          <div class="space-y-4">
            <div class="flex flex-col md:flex-row md:space-x-6">
              <!-- Type -->
              <div class="flex flex-col">
                <label class="block">
                  <LzTooltip tag="span">
                    <template #default>
                      {{ $t('common.type') }}
                    </template>

                    <template #content>
                      <a :href="lessonTypeHelpUrl">
                        {{ $t('lesson_type_help_tooltip') }}
                      </a>
                    </template>
                  </LzTooltip>
                </label>
                <div class="flex flex-col items-start gap-y-1">
                  <label
                    v-for="lessonTypeOption in allLessonTypes"
                    :key="lessonTypeOption.key"
                    class="flex items-baseline gap-x-2"
                  >
                    <input
                      type="checkbox"
                      :checked="lessonTypes.includes(lessonTypeOption.key)"
                      @change.stop.prevent="toggleLessonType(lessonTypeOption.key, $event.target.checked)"
                    />
                    {{ $t(`common.${lessonTypeOption.labelKey}`) }}
                  </label>
                </div>
              </div>

              <!-- Subject -->
              <div class="flex flex-col">
                <label class="block">
                  <LzTooltip tag="span">
                    <template #default>
                      {{ $t('common.form.subject') }}
                    </template>

                    <template #content>
                      {{ $t('subject_help_tooltip') }}
                    </template>
                  </LzTooltip>
                </label>

                <ToggleButton class="space-y-1" name="subject" :value="subject" @input="(value) => (subject = value)">
                  <ToggleButtonOption
                    v-if="availableSubjects.length === 0 || availableSubjects.length > 1"
                    :value="null"
                    class="capitalize"
                    data-test="subject-all"
                  >
                    {{ $t('common.all') }}
                  </ToggleButtonOption>

                  <ToggleButtonOption
                    v-for="subjectOption in availableSubjects"
                    :key="`subject-option-${subjectOption}`"
                    :value="subjectOption"
                    :data-test="`subject-${subjectOption}`"
                  >
                    {{ $t(`common.subjects.${subjectOption}`) }}
                  </ToggleButtonOption>
                </ToggleButton>
              </div>
            </div>

            <!-- Grade Level -->
            <div class="space-y-2">
              <label class="block">{{ $t('grade_level') }}</label>

              <div
                v-if="!availableGradeLevelsForLessonPlans || availableGradeLevelsForLessonPlans.length === 0"
                class="bg-orange-50 flex flex-row justify-start rounded-lg p-2"
              >
                <LzIcon path="icons/synced-arrows" size="md" class="mr-2" />
                <p class="text-orange-900 mt-0.5 flex flex-col text-sm font-semibold leading-5">
                  {{ $t('sis_grades_not_available') }}
                  <span class="font-normal">{{ $t('sis_use_roster_sync') }}</span>
                </p>
              </div>

              <CheckButtons show-reset :value="gradeLevel" @input="(value) => (gradeLevel = value)">
                <CheckButtonOption
                  v-for="gradeLevelOption in allGradeLevels"
                  :key="`grade-level-${gradeLevelOption}`"
                  :value="gradeLevelOption"
                  :disabled="!availableGradeLevelsForLessonPlans.includes(gradeLevelOption)"
                >
                  {{ gradeLevelOption }}
                </CheckButtonOption>
              </CheckButtons>
            </div>

            <div>
              <label class="block">{{ $t('date_range') }}</label>
              <DropdownPanel
                dropdown-placement="bottom-start"
                dropdown-class="w-full max-w-3xl"
                portal-name="itemAnalysisLessonPlanChooser"
                :button-text="whenButtonLabel"
                @toggle="choosingDateRange = !choosingDateRange"
              >
                <template #buttonContent="panelProps">
                  <DropdownButton v-bind="panelProps" />
                </template>

                <template #default>
                  <DateRangeSelect
                    :value="dateRange"
                    class="mt-6 flex max-w-4xl flex-wrap justify-center"
                    @update:model-value="setDateRange"
                  />
                </template>
              </DropdownPanel>
            </div>
          </div>
        </div>
      </div>

      <div class="border-primary-100 mt-10 flex-none space-x-4 border-t px-8 py-4 text-right">
        <LzButton class="font-semibold" @click="emit('cancel')">{{ $t('common.cancel') }}</LzButton>

        <LzButton color="green" class="font-semibold" :disabled="selectedLessonPlan === null" @click="onChooseLesson">
          {{ lessonPlan && selectedLessonPlan.id === lessonPlan.id ? $t('keep_assignment') : $t('choose_assignment') }}
        </LzButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { useItemAnalysisStore } from '../composables/use_item_analysis_store'
import { PortalTarget } from 'portal-vue'
import {
  CheckButtons,
  CheckButtonOption,
  ToggleButton,
  ToggleButtonOption,
  LzButton,
  LoadingSpinner,
  DropdownPanel,
  DropdownButton,
  LzTooltip,
  LzIcon,
} from 'vue_features/shared/components/ui'
import DateRangeSelect from 'vue_features/shared/components/selects/DateRangeSelect'
import { filter } from 'utils'
import { truncatedBreadcrumbs, formatDateFilter } from '../utils'
import dayjs from 'dayjs'
import { HELP_ITEM_ANALYSIS_GUIDE_URL } from 'rails_constants'
import useFocusTrap from 'vue_features/shared/composables/use_focus_trap'

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  lessonPlan: {
    type: Object,
    default: () => null,
  },
  initialDateRange: {
    type: Object,
    default: () => null,
  },
})

const emit = defineEmits(['choose', 'cancel'])

const root = inject('useRoot')()
const {
  state,
  allGradeLevels,
  availableGradeLevelsForLessonPlans,
  availableSubjects,
  allLessonTypes,
  getLessonPlanSuggestions,
} = useItemAnalysisStore()
const selectedLessonPlan = ref(props.lessonPlan)
const choosingDateRange = ref(false)
const gradeLevel = ref([])
const subject = ref(availableSubjects.value.length === 1 ? availableSubjects.value[0] : null)
const lessonTypes = ref(['assessment'])
const lessonPlanTitle = ref('')
const page = ref(1)
const dateRange = ref(props.initialDateRange)
const setDateRange = ({ start, end }) => {
  dateRange.value = { start, end }
}
const lessonPlanSuggestions = ref([])
const lessonPlanPagination = ref({})
const loading = computed(() => getLessonPlanSuggestions.value.loading)
const modalBody = ref()
useFocusTrap(modalBody)

const lessonTypeHelpUrl = `${HELP_ITEM_ANALYSIS_GUIDE_URL}#types`

const isLessonPlanSelected = (lessonPlan) => {
  return selectedLessonPlan.value && selectedLessonPlan.value.id === lessonPlan.id
}

const searchLessons = async () => {
  if (!props.open) {
    return
  }

  await getLessonPlanSuggestions.value.load(
    filter({
      grade_level: gradeLevel.value,
      subject: subject.value,
      lesson_types: lessonTypes.value,
      after_date: formatDateFilter(dateRange.value.start),
      before_date: formatDateFilter(dateRange.value.end),
      q: lessonPlanTitle.value,
      page: page.value,
    }),
  )
}

const onChooseLesson = () => {
  emit('choose', selectedLessonPlan.value, { dateRange: dateRange.value, gradeLevel: gradeLevel.value })
}

const whenButtonLabel = computed(() => {
  if (dateRange.value.start && dateRange.value.end) {
    return `${dayjs(dateRange.value.start).format('MMM D, YYYY')} - ${dayjs(dateRange.value.end).format('MMM D, YYYY')}`
  }
  return root.$t('common.nothing_selected')
})

const onPrevPage = () => {
  page.value = lessonPlanPagination.value.prevPage
  searchLessons()
}

const onNextPage = () => {
  page.value = lessonPlanPagination.value.nextPage
  searchLessons()
}

const reset = () => {
  selectedLessonPlan.value = props.lessonPlan
  page.value = 1
  lessonPlanTitle.value = ''
  gradeLevel.value = []
  subject.value = availableSubjects.value.length === 1 ? availableSubjects.value[0] : null
  lessonTypes.value = ['assessment']
  dateRange.value = props.initialDateRange
}

watch(
  () => props.open,
  (isOpen) => {
    if (!isOpen) {
      reset()
    }
  },
)

watch(
  () => state.getLessonPlanSuggestions?.response,
  (lessonPlanSuggestionsResponse) => {
    if (lessonPlanSuggestionsResponse) {
      lessonPlanSuggestions.value = lessonPlanSuggestionsResponse.suggestions
      lessonPlanPagination.value = lessonPlanSuggestionsResponse.pagination
    }
  },
)

watch(
  () => props.initialDateRange,
  (initialDateRange) => {
    dateRange.value = initialDateRange
  },
)

watch([gradeLevel, subject, lessonTypes, dateRange], () => {
  page.value = 1
  searchLessons()
})

watch(
  () => props.lessonPlan,
  (lessonPlan) => {
    selectedLessonPlan.value = lessonPlan
  },
)

const toggleLessonType = (key, val) => {
  if (val) {
    lessonTypes.value = [...lessonTypes.value, key]
  } else {
    lessonTypes.value = lessonTypes.value.filter((k) => k != key)
  }
}
</script>

<style scoped>
.overlay {
  background: linear-gradient(180deg, rgba(var(--primary-300), 0.41), rgb(var(--primary-700)));
}
</style>
