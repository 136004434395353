<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div id="choose-id">
    <label>
      {{ $t('lz_content_choose.ids_label_html', { type: displayType }) }}
    </label>
    <textarea
      v-model="listOfContents"
      :placeholder="$t('lz_content_choose.ids_placeholder')"
      rows="5"
      class="auto-height lz-content-modal__url-input mb-0 resize"
      data-test="id-text-input"
    />
    <span v-if="hasInvalidIds" class="errors--inline small red">
      {{ $t('lz_content_choose.ids_invalid_html', { type: displayType }) }}
    </span>
  </div>
</template>

<script>
import { computed, watchEffect, watch } from 'vue'
import { reject, isEmpty } from 'lodash'
import URI from 'urijs'
import { location } from 'utils'
import { useLzContentModalStore } from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'

export default {
  name: 'IdForm',
  setup() {
    const { contentType, displayType, items, listOfContents, currentTab } = useLzContentModalStore()
    const enteredIds = computed(() => reject(listOfContents.value.trim().split(/[\s\r\n,]+/), isEmpty))

    const isNumberId = (id) => /^\d+$/.test(id)

    const forLessonPlan = computed(() => contentType.value === 'LessonPlan')
    const urlRegex = computed(() =>
      forLessonPlan.value ? /lesson_plans\/(\d+)/ : /(?:resources|documents|videos|audios|geogebra_items|wikis)\/(\d+)/,
    )
    const isValidUrlId = (id) => {
      const currentDomain = new URI(location.current()).domain()
      return !!(id.match(urlRegex.value) && id.includes(currentDomain))
    }

    const isValidId = (id) => isNumberId(id) || isValidUrlId(id)
    const hasInvalidIds = computed(() => enteredIds.value.some((id) => !isValidId(id)))

    const getNumericId = (enteredId) => {
      const matches = isValidUrlId(enteredId) ? enteredId.match(urlRegex.value) : enteredId.match(/\d+/g)

      return matches ? matches[matches.length - 1] : ''
    }
    const allNumericIds = computed(() => enteredIds.value.map((enteredId) => ({ id: getNumericId(enteredId) })))
    watchEffect(() => {
      if (currentTab.value === 'choose-id') {
        items.value = allNumericIds.value
      }
    })
    watch(allNumericIds, () => {
      items.value = allNumericIds.value
    })

    return { displayType, listOfContents, hasInvalidIds }
  },
}
</script>
