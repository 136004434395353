<template>
  <div>
    <label for="lesson_plan_tags">
      {{ $t('info_form.tags') }}
      <span class="weak regular">{{ $t('info_form.optional') }}</span>
    </label>
    <v-select
      id="lesson_plan_tags"
      v-model="selectedTags"
      :options="availableTags"
      :placeholder="$t('info_form.add_tags_placeholder', { lesson_type: readableType })"
      multiple
      @update:modelValue="setTags"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import TagsService from '../api/tags_service'

export default {
  name: 'TagsSelector',
  components: { vSelect },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    readableType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTags: [...this.tags],
      availableTags: [],
    }
  },
  mounted() {
    TagsService.getTags().then((tags) => (this.availableTags = tags))
  },
  methods: {
    setTags() {
      this.$emit('set-tags', this.selectedTags)
    },
  },
}
</script>
