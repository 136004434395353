<template>
  <LzConfirmModal :modal-id="$options.name" :modal-title="$t('resources.archive_modal.title')">
    <template #confirmBody>
      <p class="max-w-row">{{ $t('resources.archive_modal.prompt') }}</p>
    </template>
    <template #confirmButton>
      <button v-bypass-unload-confirmation class="btn btn--primary caution" @click="handleConfirm">
        {{ $t('resources.archive_modal.action') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { bypassUnloadConfirmation } from 'vue_features/shared/directives'
import { LzConfirmModal } from 'vue_features/shared/components/ui'

export default {
  name: 'ResourceArchiveModal',
  directives: { bypassUnloadConfirmation },
  components: { LzConfirmModal },
  setup(props, { emit }) {
    const handleConfirm = () => {
      emit('save')
    }
    return { handleConfirm }
  },
}
</script>
