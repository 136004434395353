<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <LzModal class="lzui-modal--confirmation" modal-id="teacher-only-prompt" modal-title="Teacher login required">
    <template v-if="isLoggedIn">
      <p>
        {{ $t('show.for_teachers') }}
      </p>
      <p>
        {{ $t('show.logged_as_student') }}
      </p>
      <button class="btn mt-6" @click="goBack">{{ $t('go_back') }}</button>
    </template>
    <TeacherLogin v-else :center-btns="true" class="align-left" />
  </LzModal>
</template>

<script>
import { LzModal } from 'vue_features/shared/components/ui'
import TeacherLogin from 'vue_features/shared/components/login/TeacherLogin'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'TeacherOnlyPrompt',
  components: { TeacherLogin, LzModal },
  setup() {
    const { isLoggedIn } = useCurrentUserStore()
    return { isLoggedIn }
  },
  methods: {
    goBack() {
      const prevPage = window.location.href
      history.back()
      window.setTimeout(() => {
        if (window.location.href === prevPage) {
          location.assign(Routes.lz_codes_url())
        }
      }, 1500)
    },
  },
}
</script>
