<template>
  <div class="flex items-start justify-between space-x-5 text-center">
    <section>
      <p class="text-xs font-bold text-blue-800">Responses</p>
      <p class="mt-1 text-xl font-bold text-blue-600">{{ completedStudents }} / {{ totalStudents }}</p>
    </section>
    <section>
      <p class="text-xs font-bold text-blue-800">Avg Score</p>
      <p v-if="scoreBand" :class="[tooltipScoreClasses, 'mt-1 text-xl font-bold']">
        {{ averageScore * 100 }}<span class="ml-0.5 text-base">%</span>
      </p>
      <div v-else-if="hasAnyStudentCompleted" class="">
        <p class="mt-1 text-sm font-bold text-blue-600">Requires<br />Grading</p>
      </div>
      <p v-else class="mt-1 text-xl font-bold text-blue-600">N/A</p>
    </section>
  </div>
</template>

<script>
import { computed } from 'vue'
import { get } from 'lodash'
import { ratingForScore } from 'vue_features/shared/utils/scoring'
import { useAssignmentCardsStore, useLiveLearnStore } from 'vue_features/assignments/show/composables'

export default {
  name: 'PositionIndicator',
  props: { card: { type: Object, required: true } },
  setup(props) {
    const { assignmentDisplayCards } = useAssignmentCardsStore()
    const { students, aggregateResponses } = useLiveLearnStore()

    const itemReference = computed(() => {
      const card = assignmentDisplayCards.value.find((card) => card.id === props.card.id)
      return get(card, 'content.content')
    })
    const averageScore = computed(() => get(aggregateResponses.value[itemReference.value], 'average_score'))
    const scoreBand = computed(() => {
      const ratio = averageScore.value
      return hasAnyStudentCompleted.value && isFinite(ratio) && ratio !== null ? ratingForScore(ratio * 100) : null
    })
    const hasAnyStudentCompleted = computed(() => !!get(aggregateResponses.value[itemReference.value], 'completed'))

    const completedStudents = computed(() => get(aggregateResponses.value[itemReference.value], 'completed', 0))
    const totalStudents = computed(() => Object.keys(students.value).length)

    const tooltipScoreClasses = computed(() => ({
      'text-green-600': scoreBand.value === 'high',
      'text-orange-600': scoreBand.value === 'medium',
      'text-red-600': scoreBand.value === 'low',
    }))

    return {
      averageScore,
      completedStudents,
      tooltipScoreClasses,
      totalStudents,
      hasAnyStudentCompleted,
      scoreBand,
    }
  },
}
</script>
