import dayjs from 'dayjs'

export function timeAgo(value) {
  if (!value) return 'never'
  return dayjs(value).fromNow()
}

export function timeInPast(value) {
  if (!value) return false
  return dayjs(value).isBefore(dayjs())
}

export function formatDate(value, tokens) {
  return dayjs(value).format(tokens)
}

export function friendlyLongDate(value) {
  return formatDate(value, 'MMMM D, YYYY')
}

export function friendlyLongDateTime(value) {
  return formatDate(value, 'MMMM D, YYYY - h:mm A')
}

export function friendlyShortDate(value) {
  return formatDate(value, 'MMM D')
}
