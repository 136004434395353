<script setup>
import { ref, computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'

const props = defineProps({
  kind: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  log: {
    type: Array,
    default: () => [],
  },
})

const COLORS = {
  success: {
    wrapper: 'border-green-300 bg-green-100',
    check: 'bg-green-300',
  },
  error: {
    wrapper: 'border-red-300 bg-red-100',
    check: 'bg-red-300',
  },
  info: {
    wrapper: 'border-yellow-300 bg-yellow-100',
    check: 'bg-yellow-300',
  },
}

const isOpen = ref(false)
const displayLog = computed(() => props.log.join('\n'))
const toggleOpen = () => (isOpen.value = !isOpen.value)
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <div
    :class="['flex cursor-pointer items-center rounded-xl border', COLORS[kind].wrapper]"
    tabindex="0"
    @click="toggleOpen"
    @keyup.enter="toggleOpen"
  >
    <div :class="['flex flex-none items-center self-stretch rounded-l-lg p-2', COLORS[kind].check]">
      <LzIcon path="icons/check" />
    </div>
    <div class="bold flex-1 pl-2 text-left text-sm">
      <span :data-test="`import-${kind}-result-label`">({{ log.length }}) {{ text }}</span>
      <div v-if="isOpen" class="p-1">
        <textarea
          :value="displayLog"
          :aria-label="`${kind} results`"
          readonly
          class="rounded-none text-sm leading-tight"
          rows="10"
          wrap="off"
          @click.stop
        />
      </div>
    </div>
  </div>
</template>
