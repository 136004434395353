<template>
  <div class="flex max-w-full items-center gap-1">
    <slot />
    <img v-if="logo" :src="logo" alt="logo" class="inline-block max-h-4 flex-shrink-0" aria-hidden="true" />
    <p class="text-neutral-700 mb-0 truncate text-xs font-bold uppercase tracking-wide">
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'MetadataByline',
  props: {
    logo: { type: String, default: '' },
    name: { type: String, default: '' },
  },
}
</script>
