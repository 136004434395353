<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <div class="space-y-4 p-4 pb-0">
        <h2 class="m-0">{{ $t('action_menu.delete_modal_heading', { object_name: objectName }) }}</h2>
        <p class="text-gray-800">{{ $t('action_menu.delete_modal_message') }}</p>
      </div>
    </template>
    <template #cancelButton="{ close }">
      <button class="btn mb-4" @click.stop="close">
        {{ $t('action_menu.delete_modal_cancel', { object_name: objectName }) }}
      </button>
    </template>
    <template #confirmButton>
      <DeleteButton
        :action="deletionPath"
        :value="$t('action_menu.delete_modal_submit', { object_name: objectName })"
      />
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import DeleteButton from 'vue_features/shared/components/DeleteButton'
import { shared } from 'vue_features/shared/i18n'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'

export default {
  name: 'DeleteObjectModal',
  components: { DeleteButton, LzConfirmModal },
  i18n: { messages: pickLocaleNamespace(shared, 'action_menu') },
  props: {
    deletionPath: { type: String, required: true },
    objectName: { type: String, required: true },
  },
}
</script>
