<script setup>
import { ref, computed, provide } from 'vue'

const props = defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
  gridTemplateColumns: {
    type: String,
    default: null,
  },
})

const registeredColumns = ref([])

const gridStyle = computed(() => {
  const gridTemplateColumns =
    props.gridTemplateColumns ?? `repeat(${calculatedColumnCount.value}, ${defaultGridTemplateColumnWidth()})`

  return {
    display: 'grid',
    gridTemplateColumns,
    gridTemplateRows: 'auto',
    whiteSpace: 'nowrap',
  }
})

const calculatedColumnCount = computed(() => {
  return registeredColumns.value.length
})

function registerColumn(column) {
  registeredColumns.value = [...registeredColumns.value, column]
}

function unregisterColumn(column) {
  const index = registeredColumns.value.indexOf(column)
  registeredColumns.value.splice(index, 1)
}

function defaultGridTemplateColumnWidth() {
  return props.compact ? 'min-content' : 'minmax(min-content, auto)'
}

provide('registerColumn', registerColumn)
provide('unregisterColumn', unregisterColumn)
</script>

<template>
  <div :style="gridStyle">
    <slot />
  </div>
</template>

<style>
.data-table {
  --data-table-row-background: transparent;
  --data-table-row-highlight: none;
}
.data-table-frozen-columns {
  @apply sticky left-0 grid;
  z-index: 10;
}
.data-table-row {
  @apply contents;
}
.data-table-row > * {
  background: var(--data-table-row-background);
}
.data-table-row > :not(.data-table-frozen-columns) {
  position: relative;
}
.data-table-row > :after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background 300ms ease-in-out;
  background: var(--data-table-row-highlight);
  pointer-events: none;
}
.data-table-row--frozen > * {
  @apply sticky top-0 z-20;
}
.data-table-row--frozen > .data-table-frozen-columns {
  @apply z-30;
}
</style>
