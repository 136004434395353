import { ref, computed } from 'vue'
import { testWebSocketConnection } from 'utils/web_socket_troubleshooter'

let checked = false
const testing = ref(true)
const supported = ref(false)

async function check() {
  if (checked) return
  checked = true
  supported.value = await testWebSocketConnection(5000)
  testing.value = false
}

function reset() {
  checked = false
  testing.value = true
  supported.value = false
}

export default () => {
  return {
    check,
    reset,
    testing: computed(() => testing.value),
    supported: computed(() => supported.value),
  }
}
