<template>
  <a
    :title="item.title"
    :href="href"
    :class="[
      'flex items-center gap-x-2 whitespace-nowrap py-2.5 pl-2.5',
      { 'bg-primary-200 font-semibold': isSelected() },
    ]"
    @click="select"
  >
    <LzIcon :path="`icons/${getIcon(item.type)}`" size="md" class="p-px" />
    <span>{{ trunc(item.title) }}</span>
  </a>
</template>

<script>
import { computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { getIcon } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'PathCrumbItem',
  components: {
    LzIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { selectedPathIds, findSelected, urlWithCrumbs, trunc } = usePathCrumbsStore()

    const href = computed(() => urlWithCrumbs(props.item.href, props.index))

    function select(event) {
      if (event.ctrlKey || event.shiftKey || event.metaKey) {
        return
      }
      const { crumbId } = props.item
      selectedPathIds.value.splice(props.index, 1, crumbId)
      emit('select', { index: props.index, crumbId })
    }

    function isSelected() {
      if (props.item.items) return false
      const selected = findSelected(props.index)
      return selected?.crumbId === props.item.crumbId
    }

    return {
      select,
      isSelected,
      getIcon,
      href,
      trunc,
    }
  },
}
</script>
