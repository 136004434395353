<template>
  <div class="search-box">
    <label for="search-students">{{ $t('add_student_to_klass_modal.name_label') }}</label>
    <div class="no-fullstory mb-4">
      <input
        id="search-students"
        v-model="queryString"
        :placeholder="$t('add_student_to_klass_modal.name_placeholder')"
        class="form-control search-query"
        type="search"
        @keyup="updateOnQuery"
      />
    </div>
    <button class="search-submit float-right flex items-center" style="top: 33px">
      <LzIcon icon-color-class="text-white" path="icons/search" class="mx-auto" size="sm" />
    </button>
    <div v-if="noMatches || isSearching || searchedStudents.length > 0" class="search-box__results mb-4 mt-8">
      <div v-if="isSearching" class="search-roster">
        <LoadingSpinner />
      </div>
      <div v-if="noMatches && !isSearching" class="lzui-tile bg-orange-50 border-orange-200 center">
        <strong>{{ $t('add_student_to_klass_modal.no_matches') }}</strong>
      </div>
      <div v-if="!noMatches && !isSearching" class="small-modal_table">
        <StudentSearchResults v-bind="{ searchedStudents, searchTotal }" :key="queryString" @add-student="addStudent" />
      </div>
    </div>

    <p>{{ $t('add_student_to_klass_modal.students_label') }}</p>

    <div v-if="newStudents.length > 0" class="lzui-tile">
      <div class="small-modal_table">
        <table class="table-opened condensed-list mb-0">
          <tr v-for="student in newStudents" :key="student.id" class="detail-item">
            <td>
              <div class="detail-item__title">
                {{ student.name }}
              </div>
            </td>
            <td class="detail-item__actions">
              <button class="btn btn--small btn--gray float-right" @click="removeStudent(student)">
                {{ $t('common.remove') }}
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <footer>
      <button class="btn" @click="$emit('cancel')">{{ $t('common.cancel') }}</button>
      <button :disabled="newStudents.length === 0" class="btn btn--primary ml-2" @click="save">
        {{ $t('add_student_to_klass_modal.add_label') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import { useKlassShowStore } from 'vue_features/klasses/show/store'
import KlassesShowService from 'vue_features/klasses/show/api/klasses_show_service'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import StudentSearchResults from './StudentSearchResults'
import { debounce } from 'lodash'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'StudentSearch',
  components: { StudentSearchResults, LoadingSpinner, LzIcon },
  props: {
    klassId: {
      type: Number,
      required: true,
    },
  },
  setup(_props, { emit }) {
    const { addStudentsToClass } = useKlassShowStore()
    const root = inject('useRoot')()
    const newStudents = ref([])
    const save = () => {
      addStudentsToClass({ students: newStudents.value }).then(() => {
        emit('added-students', newStudents.value)
        root.$rootEmit('new-student-added')
      })
    }
    return { addStudentsToClass, newStudents, save }
  },
  data() {
    return {
      queryString: '',
      isSearching: false,
      noMatches: false,
      searchedStudents: [],
      searchTotal: 0,
    }
  },
  computed: {
    excludedIds() {
      return this.newStudents.map((student) => student.id)
    },
  },
  created() {
    this.updateOnQuery = debounce(this.updateOnQuery, 300)
  },
  methods: {
    addStudent(student) {
      this.newStudents.push({
        id: student.id,
        name: student.name,
        username: student.username,
      })
      this.resetSearch()
    },
    removeStudent(student) {
      const index = this.newStudents.indexOf(student)
      this.newStudents.splice(index, 1)
      this.resetSearch()
    },
    resetSearch() {
      this.queryString = ''
      this.searchedStudents = []
      this.noMatches = false
    },
    updateOnQuery() {
      this.noMatches = false
      this.searchedStudents = []
      if (this.queryString) {
        this.isSearching = true
        this.search().then((searchData) => {
          this.searchedStudents = searchData.members
          this.searchTotal = searchData.total
          this.noMatches = this.searchTotal === 0
          this.isSearching = false
        })
      }
    },
    handleLoadMore(done) {
      this.search().then((searchData) => {
        this.searchedStudents.push(...searchData.members)
        this.searchTotal = searchData.total
        done()
      })
    },
    search() {
      return KlassesShowService.search(this.klassId, this.queryString, this.excludedIds, this.searchedStudents.length)
    },
  },
}
</script>
