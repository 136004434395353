<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('title')"
    modal-id="standards-coverage-table-modal"
    class="lzui-modal--medium lz-cke_dialog"
  >
    <form role="form" class="space-y-4">
      <div>
        <label for="parent-wiki-id">{{ $t('parent_wiki_id') }}</label>
        <input id="parent-wiki-id" v-model="newWikiId" placeholder="16894" type="text" />
      </div>
      <div>
        <label for="content-type">{{ $t('content_type') }}</label>
        <select id="content-type" v-model="newContentType">
          <option value="Wiki">{{ $t('pages') }}</option>
          <option value="LessonPlan">{{ $t('lessons') }}</option>
        </select>
      </div>
      <div v-if="showDisplayBy">
        <label for="display-by">{{ $t('display_by') }}</label>
        <select id="display-by" v-model="newDisplayBy">
          <option value="Content">{{ $t('content') }}</option>
          <option value="Standard">{{ $t('standard') }}</option>
          <option value="Inline">{{ $t('inline') }}</option>
        </select>
      </div>
    </form>
    <footer>
      <button class="btn" @click="close">{{ $t('cancel') }}</button>
      <button v-if="canInsert" class="btn btn--primary" @click="insertTable(close)">{{ $t('insert') }}</button>
    </footer>
  </LzModal>
</template>

<script>
import { LzModal } from 'vue_features/shared/components/ui'

export default {
  name: 'StandardsCoverageTableModal',
  components: { LzModal },
  props: {
    parentWikiId: { type: [String, Number], default: '' },
    contentType: { type: String, default: '' },
    displayBy: { type: String, default: '' },
    onSave: { type: Function, required: true },
    tableId: { type: [String, Number], default: '' },
  },
  data() {
    return {
      newWikiId: this.parentWikiId,
      newContentType: this.contentType || 'Wiki',
      newDisplayBy: this.displayBy || 'Standard',
      newTableId: this.tableId,
    }
  },
  computed: {
    canInsert() {
      return this.newWikiId && this.newContentType
    },
    showDisplayBy() {
      return this.newContentType === 'LessonPlan'
    },
    isTableUpdated() {
      return [this.newContentType, this.newDisplayBy, this.newWikiId]
    },
  },
  watch: {
    tableId(newVal) {
      this.newTableId = newVal
      this.newWikiId = this.parentWikiId
      this.newContentType = this.contentType
      this.newDisplayBy = this.displayBy
    },
    isTableUpdated() {
      this.newTableId = null
    },
  },
  methods: {
    insertTable(close) {
      this.onSave({
        tableId: this.newTableId,
        parentWikiId: this.newWikiId,
        contentType: this.newContentType,
        displayBy: this.newContentType === 'Wiki' ? 'Standard' : this.newDisplayBy,
      })
      close()
    },
  },
}
</script>
