<!-- eslint-disable vuejs-accessibility/form-control-has-label  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="page-content">
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <form :action="Routes.site_admin_question_banks_path()" accept-charset="UTF-8" method="post">
        <input value="✓" type="hidden" name="utf8" />
        <input value="put" type="hidden" name="_method" />
        <input :value="authenticityToken" type="hidden" name="authenticity_token" />
        <table class="table-opened">
          <thead>
            <tr>
              <th>{{ $t('question_bank') }}</th>
              <th>{{ $t('enabled') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="questionBank of availableQuestionBanks" :key="questionBank">
              <td>
                {{ questionBank }}
              </td>
              <td>
                <div class="switch-toggle">
                  <input
                    :value="questionBank"
                    :checked="isChecked(questionBank)"
                    class="switch-toggle__button"
                    type="checkbox"
                    name="question_banks[]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <input type="submit" value="Save changes" name="commit" class="btn btn--primary" />
      </form>
    </div>
  </div>
</template>

<script>
import { metaTags } from 'utils'
import Routes from 'routes'

export default {
  name: 'QuestionBanks',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
  data() {
    return {
      authenticityToken: metaTags.csrfToken(),
    }
  },
  computed: {
    enabledQuestionBanks() {
      return this.customer.questionBanks
    },
    availableQuestionBanks() {
      return this.customer.availableQuestionBanks
    },
  },
  methods: {
    isChecked(questionBank) {
      return this.enabledQuestionBanks.includes(questionBank)
    },
  },
}
</script>
