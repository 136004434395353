import messages from './i18n/users_messages'
import UsersIndex from './components/UsersIndex'
import UsersSearch from './components/UsersSearch'
import { useUsersIndexStore } from './store/use_users_index_store'
import { createLzRouter } from 'vue_features/shared/router'

function parseSearchParams(route) {
  const roles = typeof route.query.roles === 'string' ? [route.query.roles] : route.query.roles,
    states = typeof route.query.states === 'string' ? [route.query.states] : route.query.states

  return {
    currentSearch: {
      query: route.query.query || '',
      roles: roles || [],
      states: states || [],
    },
  }
}

export default function ({ state }) {
  const routes = [
    { path: '/site_admin/users', component: UsersSearch, props: parseSearchParams },
    { path: '/site_admin', component: UsersSearch, props: parseSearchParams },
  ]

  const router = createLzRouter({ routes })

  useUsersIndexStore().$set(state)

  return { component: UsersIndex, messages, router }
}
