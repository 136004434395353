<template>
  <LzConfirmModal :modal-id="modalId">
    <template #confirmBody>
      <h2 class="my-2">{{ $t('components.lz_comments.delete_confirm_header') }}</h2>
      <p>
        {{ $t('components.lz_comments.delete_confirm_explication') }}
      </p>
    </template>
    <template #confirmButton>
      <LzButton color="primary" caution data-test="confirm-button" @click="confirm">
        {{ $t('components.lz_comments.confirm_delete_link') }}
      </LzButton>
    </template>
    <template #cancelButton="{ close }">
      <LzButton data-test="cancel-button" @click="close">
        {{ $t('components.lz_comments.cancel_delete_link') }}
      </LzButton>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzButton, LzConfirmModal } from 'vue_features/shared/components/ui'

export default {
  name: 'CommentDeleteModal',
  components: { LzButton, LzConfirmModal },
  props: {
    modalId: {
      type: String,
      default: function () {
        return 'CommentDeleteModal'
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
