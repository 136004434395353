<template>
  <div class="page-content">
    <div class="no-fullstory mx-auto max-w-7xl px-3 pb-6 md:px-6">
      <div v-if="schoolCount > 0" class="space-y-6">
        <SchoolActionNotice :message="actionNotice" />
        <!-- FIXME: May be able to use $tc-style pluralization in Vue 3/vue-i18n 9 -->
        <i18n-t tag="p" :keypath="`index.school_count.${schoolCount === 1 ? 'one' : 'other'}`" scope="global">
          <template #count>
            <strong>{{ $n(schoolCount) }}</strong>
          </template>
        </i18n-t>
        <SchoolList @show-notice="actionNotice = $event" />
        <div class="my-6">
          <LzPaginator v-bind="pagination" @page-changed="getSchools" />
        </div>
      </div>
      <EmptyStateWithIcon v-else path="icons/school" :heading="$t('index.no_schools')" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import LzPaginator from 'vue_features/shared/components/ui/pagination/LzPaginator'
import SchoolActionNotice from './SchoolActionNotice'
import SchoolList from './SchoolList'
import { EmptyStateWithIcon } from 'vue_features/shared/components/ui'
import { useSiteAdminSchoolsStore } from '../store/use_site_admin_schools_store'

export default {
  name: 'SiteAdminSchoolsIndex',
  components: {
    EmptyStateWithIcon,
    LzPaginator,
    SchoolActionNotice,
    SchoolList,
  },
  setup() {
    const actionNotice = ref(null)
    const { pagination, schoolCount, getSchools, loadAllSchools } = useSiteAdminSchoolsStore()
    return { actionNotice, pagination, schoolCount, getSchools, loadAllSchools }
  },
  created() {
    this.loadAllSchools()
  },
}
</script>
