import { createLzRouter } from 'vue_features/shared/router'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import ItemAnalysisUi from '../components/ItemAnalysisUi'
import qs from 'qs'
import dayjs from 'dayjs'

export default function useRouter() {
  const router = createLzRouter({
    routes: [
      {
        path: Routes.item_analysis_index_path(),
        name: 'item_analysis',
        component: ItemAnalysisUi,
      },
    ],
    stringifyQuery(query) {
      const result = qs.stringify(query, {
        arrayFormat: 'brackets',
        encode: false,
        serializeDate: (d) => dayjs(d).format('yyyy-MM-dd'),
      })

      return result ? `?${result}` : ''
    },
    parseQuery(query) {
      return qs.parse(query, { comma: true })
    },
  })

  return { router }
}
