<template>
  <div id="wiki-edit-feature" class="col-span-12">
    <WikiEdit
      :original-blocks="originalBlocks"
      :can-add-contents="canAddContents"
      @updated-blocks="updateBlocks"
      @deleted-blocks="updateDeletedBlocks"
    />
  </div>
</template>

<script>
import { blockJSON, structureBlocks } from '../block_helpers'
import WikiEdit from './WikiEdit'
import { snakeKeys } from 'vue_features/shared/helpers/http_helper'

export default {
  name: 'WikiForm',
  components: { WikiEdit },
  props: { resource: { type: Object, required: true }, canAddContents: { type: Boolean, default: true } },
  setup(props) {
    const originalBlocks = structureBlocks(props.resource.blocks || [])

    return { originalBlocks }
  },
  methods: {
    updateBlocks(value) {
      const blocks = value.map((b) => JSON.stringify(snakeKeys(blockJSON(b))))
      this.$emit('update', { blocks })
    },
    updateDeletedBlocks(value) {
      const deletedBlocks = value.map((b) => b.id)
      this.$emit('update', { deletedBlocks })
    },
  },
}
</script>
