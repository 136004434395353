<template>
  <div>
    <p class="lzui-modal__header-link">
      <a :href="Routes.login_path({ one_time_return_to: defaultOneTimeReturnToPath })" data-test="teacher-login">
        {{ $t('show.i_am_a_teacher') }}
      </a>
    </p>
    <StudentLogin :in-assignment-prompt="true" :has-greeting="false" />
  </div>
</template>

<script>
import { StudentLogin } from 'vue_features/shared/components/login'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'AssignmentStudentLoginForm',
  components: { StudentLogin },
  setup() {
    const { defaultOneTimeReturnToPath } = useGlobalStore()
    return { Routes, defaultOneTimeReturnToPath }
  },
}
</script>
