<template>
  <!-- SVG sanitized by Filestack; interpolated description sanitized in serializer -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <svg v-bind="attrs" :id="uniqueId" role="img" v-html="contentWithDesc"></svg>
</template>

<script>
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import { ref, computed, onMounted } from 'vue'
import { escape } from 'lodash'
import { namespaceSvgIdsAndClasses, sanitizeSvg } from 'utils'

export default {
  name: 'FilestackSvg',
  props: {
    url: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const content = ref('')
    const attrs = ref({})
    const uniqueId = ref(null)

    const contentWithDesc = computed(() => {
      const svgDesc = props.desc ? `<desc>${escape(props.desc)}</desc>` : ''
      return svgDesc + content.value
    })

    onMounted(() => {
      HTTP.fetch(props.url)
        .then((response) => response.text())
        .then((text) => new DOMParser().parseFromString(text, 'text/html'))
        .then((html) => {
          let svg = html.querySelector('svg')
          svg = sanitizeSvg(svg)
          attrs.value = [...svg.attributes].reduce((obj, attr) => {
            if (attr.name !== 'height' && attr.name !== 'width') {
              obj[attr.name] = attr.value
            }
            return obj
          }, {})
          svg = namespaceSvgIdsAndClasses(svg)
          uniqueId.value = svg.id
          content.value = svg.innerHTML
        })
    })

    return {
      content,
      attrs,
      uniqueId,
      contentWithDesc,
    }
  },
}
</script>
