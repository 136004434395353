<template>
  <Alert v-show="!!message" type="success" dismissible :title="message" />
</template>

<script>
import { Alert } from 'vue_features/shared/components/ui'

export default {
  name: 'SchoolActionNotice',
  components: { Alert },
  props: { message: { type: String, default: null } },
}
</script>
