import messages from './i18n/data_dashboard_messages'
import DataDashboard from './components/DataDashboard'
import { useDataDashboardStore } from './composables/data_dashboard_store'
import useRouter from './composables/router'
import useApiMethod from 'vue_features/shared/composables/use_api_method'
import DataDashboardService from './api'
import 'css/data_dashboard.css'

export default function ({ state }) {
  const { router } = useRouter()

  useDataDashboardStore().$set({
    ...state,
    standardsTableFilter: 'top',
    getAllowedFilters: useApiMethod(DataDashboardService.getAllowedFilters),
    getUsageData: useApiMethod(DataDashboardService.getUsageData),
    getScoreBands: useApiMethod(DataDashboardService.getScoreBands),
    getRankedStandards: useApiMethod(DataDashboardService.getRankedStandards),
    getCategoryRollup: useApiMethod(DataDashboardService.getCategoryRollup),
    getStandardsAlignment: useApiMethod(DataDashboardService.getStandardsAlignment),
    getCategoryItemsRollup: useApiMethod(DataDashboardService.getCategoryItemsRollup),
    getCategoryStandardsRollup: useApiMethod(DataDashboardService.getCategoryStandardsRollup),
    getAvailableCustomers: useApiMethod(DataDashboardService.getAvailableCustomers),
  })

  return { component: DataDashboard, messages, router }
}
