<template>
  <LzModal
    v-slot="{ close }"
    v-bind="{ modalId, modalTitle }"
    :can-cancel="false"
    class="lzui-modal--confirmation"
    data-test="lz-confirm-modal"
  >
    <slot name="confirmBody"></slot>
    <footer>
      <slot name="cancelButton" :close="close">
        <button class="btn" data-test="cancel-button" @click.stop="close">
          {{ $t('LzConfirmModal.cancel') }}
        </button>
      </slot>
      <slot name="confirmButton"></slot>
    </footer>
  </LzModal>
</template>

<script>
import LzModal from './LzModal'
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'

export default {
  i18n: { messages: namespaceLocaleObj(common, 'LzConfirmModal', { only: 'cancel' }) },
  name: 'LzConfirmModal',
  components: { LzModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      default: '',
    },
  },
}
</script>
