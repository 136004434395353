<template>
  <LzConfirmModal :modal-id="modalId">
    <template #confirmBody>
      <h2 class="my-2">{{ $t('users_recurring_import.delete_confirm_modal.are_you_sure_heading') }}</h2>
      <p>
        <strong>{{ $t('users_recurring_import.delete_confirm_modal.cannot_undo_message') }}</strong>
      </p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary" data-test="confirm-button" @click="confirm">
        {{ $t('users_recurring_import.delete_confirm_modal.delete_user_label') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'UsersRecurringImportDeletionConfirmModal',
  components: { LzConfirmModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    function confirm() {
      emit('confirm')
      closeModal(props.modalId)
    }
    return { confirm }
  },
}
</script>
