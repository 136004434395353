<template>
  <DetailListItem
    :item-id="lessonPlan.id"
    :title="displayName(lessonPlan)"
    :href="lessonPlan.href"
    :target="'_blank'"
    :thumbnail="lessonPlan.thumbnail || placeholder"
    :class="[
      'border hover:border-green-400',
      { 'border-green-400': isPlanSelected(lessonPlan) },
      { 'border-transparent': !isPlanSelected(lessonPlan) },
    ]"
    :background="isPlanSelected(lessonPlan) ? 'bg-green-50' : 'bg-focus'"
    thumbnail-size="m"
    icon="lesson-plan"
    @click="$emit('merge', lessonPlan)"
  >
    <template #handle>
      <div v-if="canMerge(lessonPlan)" class="flex items-center px-3">
        <LzIcon
          :class="[
            'rounded-full border',
            {
              'border-transparent text-green-400': isPlanSelected(lessonPlan),
              'border-base text-transparent': !isPlanSelected(lessonPlan),
            },
          ]"
          size="lg"
          path="icons/complete"
        />
      </div>
    </template>
    <template #title>
      <p class="text-bold text-lg">
        {{ displayName(lessonPlan) }}
      </p>
      <MetadataByline
        :logo="lessonPlan.logo || (lessonPlan.fromMetadata || {}).logo"
        :name="lessonPlan.customer || (lessonPlan.fromMetadata || {}).customerName"
      >
        <span v-if="!canMerge(lessonPlan)" class="red font-weight-bold block">
          {{ $t('action_menu.prevent_assessment_merge') }}
        </span>
      </MetadataByline>
    </template>
    <template #metadata>
      <ul class="list-metadata">
        <li v-if="isActive(lessonPlan)">
          <LzContentStateBadge :state="lessonPlan.state" />
        </li>
        <li v-if="standardsFor(lessonPlan).length > 0" data-test="search-result">
          <strong>{{ $t('action_menu.addressed_label') }}</strong>
          {{ standardsFor(lessonPlan).join(', ') }}
        </li>
        <li>
          <strong>{{ $t('action_menu.cards') }}</strong>
          {{ lessonPlan.cardCount }}
        </li>
      </ul>
    </template>
  </DetailListItem>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import MetadataByline from 'vue_features/shared/components/lists/MetadataByline'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import { getPlaceholder } from 'vue_features/shared/composables/use_content_type_metadata'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'LessonPlanSearchResult',
  components: { MetadataByline, DetailListItem, LzIcon, LzContentStateBadge },
  props: {
    lessonPlan: { type: Object, required: true },
    mergedLessonPlanIds: { type: Array, required: true },
    canCopyAssessments: { type: Boolean, required: true },
  },
  setup(props) {
    const isPlanSelected = (plan) => props.mergedLessonPlanIds.includes(plan.id)
    const { displayName } = useModalSearch()
    const canMerge = (plan) => props.canCopyAssessments || plan.lessonType !== 'assessment'
    const standardsFor = (plan) => {
      return plan.customerVisibleStandardCodes || plan.customerVisibleStandards.map((s) => s.displayCode)
    }
    const placeholder = getPlaceholder('LessonPlan')
    return { isPlanSelected, displayName, canMerge, standardsFor, placeholder, isActive }
  },
}
</script>
