import { assign, isString } from 'lodash'

let id = 1

const DEFAULTS = {
  row: 0,
  rowSpan: 0,
  column: 0,
  colSpan: 0,
  horizontalAlignment: 'center',
  verticalAlignment: 'center',
  isCreating: false,
}

const DIALOGUE_DEFAULTS = { text: '', shape: 'speech', direction: 'bottom-center' }
const IMAGE_DEFAULTS = {
  altText: '',
  uploading: false,
  url: null,
  processedUrl: null,
  mimetype: null,
  file: { filestackOptions: {} },
  processedFile: { filestackOptions: {} },
  policy: null,
  signature: null,
}
const TABLE_DEFAULTS = { text: null }
const TEXT_DEFAULTS = { text: '', background: 'none', isCaption: false }
const CAPTION_DEFAULTS = { text: '', background: 'none', isCaption: true, verticalAlignment: 'top' }

const UNSAVED_ATTRS = ['isCreating', 'uploading', 'ckeditorInstance', 'file', 'signature', 'policy']

export function slideContentJSON(content) {
  const json = {}
  Object.keys(content).forEach((key) => {
    if (!UNSAVED_ATTRS.includes(key)) {
      json[key] = content[key]
    }
  })
  if (
    content.ckeditorInstance &&
    content.ckeditorInstance.hasPlaceholder &&
    content.ckeditorInstance.hasPlaceholder()
  ) {
    json.text = ''
  }
  return json
}

export function initNewContentId(contents) {
  if (contents.value) {
    contents.value.forEach((content) => {
      id = Math.max(content.id, id)
    })
  }
  id++
}

export default function buildSlideContent(content) {
  if (isString(content)) {
    content = { type: content }
  }

  content = { ...assign({}, DEFAULTS, defaultsForType(content.type), content) }
  content.id = id++

  if (content.type === 'caption') {
    content.type = 'text'
  }

  return content
}

function defaultsForType(type) {
  switch (type) {
    case 'dialogue':
      return DIALOGUE_DEFAULTS
    case 'image':
      return IMAGE_DEFAULTS
    case 'table':
      return TABLE_DEFAULTS
    case 'text':
      return TEXT_DEFAULTS
    case 'caption':
      return CAPTION_DEFAULTS
    default:
      return DEFAULTS
  }
}
