import { minBy, reduce, some } from 'lodash'
import { create, extend } from 'utils'
import { RowSplit, ColumnSplit } from './split_dimensions'
import { bottomRow, rightMostColumn } from './grid_indices'

export default function getSpanDimensions(grid, content, detectionMatch) {
  return getSpanningStrategy(grid, content, detectionMatch).getDimensions()
}

function getSpanningStrategy(grid, content, detectionMatch) {
  switch (detectionMatch.position) {
    case 'top':
      return create(TopSpan, grid, content)
    case 'bottom':
      return create(BottomSpan, grid, content)
    case 'left':
      return create(LeftSpan, grid, content)
    case 'right':
      return create(RightSpan, grid, content)
  }
}

const GridSpanBase = {
  initialize(grid, content) {
    this.grid = grid
    this.content = content
  },

  getSpannedContents() {
    if (!this.spannedContents) {
      this.spannedContents = this.determineSpannedContents(this.grid, this.content)
    }
    return this.spannedContents
  },

  getDimensions() {
    if (some(this.getSpannedContents())) {
      return {
        row: this.row(),
        column: this.column(),
        rowSpan: this.rowSpan(),
        colSpan: this.colSpan(),
      }
    }

    return false
  },

  // abstract methods

  determineSpannedContents() {},
  row() {},
  column() {},
  rowSpan() {},
  colSpan() {},
}

const RowSpanBase = extend(GridSpanBase, {
  canSplit(content) {
    return create(RowSplit, content).canSplit()
  },

  column() {
    return getMinimum(this.getSpannedContents(), 'column')
  },

  rowSpan() {
    return getSplitMinimum(this.getSpannedContents(), RowSplit)
  },

  colSpan() {
    return getSum(this.getSpannedContents(), 'colSpan')
  },
})

const TopSpan = extend(RowSpanBase, {
  determineSpannedContents(grid, content) {
    return grid.getContiguousRow(content, content.row, (c) => c.row === content.row && this.canSplit(c))
  },

  row() {
    return this.content.row
  },
})

const BottomSpan = extend(RowSpanBase, {
  determineSpannedContents(grid, content) {
    return grid.getContiguousRow(content, bottomRow(content), (c) => {
      return bottomRow(c) === bottomRow(content) && this.canSplit(c)
    })
  },

  row() {
    return this.content.row + this.content.rowSpan - this.rowSpan()
  },
})

const ColumnSpanBase = extend(GridSpanBase, {
  canSplit(content) {
    return create(ColumnSplit, content).canSplit()
  },

  row() {
    return getMinimum(this.getSpannedContents(), 'row')
  },

  rowSpan() {
    return getSum(this.getSpannedContents(), 'rowSpan')
  },

  colSpan() {
    return getSplitMinimum(this.getSpannedContents(), ColumnSplit)
  },
})

const LeftSpan = extend(ColumnSpanBase, {
  determineSpannedContents(grid, content) {
    return grid.getContiguousColumn(content, content.column, (c) => {
      return c.column === content.column && this.canSplit(c)
    })
  },

  column() {
    return this.content.column
  },
})

const RightSpan = extend(ColumnSpanBase, {
  determineSpannedContents(grid, content) {
    return grid.getContiguousColumn(content, rightMostColumn(content), (c) => {
      return rightMostColumn(c) === rightMostColumn(content) && this.canSplit(c)
    })
  },

  column() {
    return this.content.column + this.content.colSpan - this.colSpan()
  },
})

function getSplitMinimum(contents, splitType) {
  const min = minBy(contents, (c) => create(splitType, c).splitDimension())
  return create(splitType, min).splitDimension()
}

function getMinimum(contents, direction) {
  return minBy(contents, direction)[direction]
}

function getSum(contents, span) {
  return reduce(contents, (sum, c) => sum + c[span], 0)
}
