<script setup>
import useSharedCellProperties, { alignmentProps } from './use_shared_cell_properties'

const props = defineProps({
  ...alignmentProps,
})

const { alignmentClasses } = useSharedCellProperties(props)
</script>

<template>
  <div class="bg-inherit flex" :class="alignmentClasses">
    <slot></slot>
  </div>
</template>
