import SiteAdminCleverService from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive } from 'vue'

export const useSiteAdminCleverStore = defineStore('siteAdminClever', () => {
  const state = reactive({
    authPreference: 'ilclassroom',
    districtId: null,
    maintenanceMode: false,
    persisted: false,
    syncActive: false,
    anyRunningImports: null,
    lastSyncCompletedAt: null,
  })

  function update() {
    const params = { siteAdminCleverSettings: state }
    return SiteAdminCleverService.update(params)
  }

  function toggleDataSync() {
    state.syncActive = !state.syncActive
  }

  function toggleMaintenanceMode() {
    state.maintenanceMode = !state.maintenanceMode
  }

  return {
    state,
    update,
    toggleDataSync,
    toggleMaintenanceMode,
  }
})
