import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { get } from 'lodash'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import debug from 'debug'

const log = debug('app:vue_features/assignments/show/composables/use_assignment_show_store')

export const useAssignmentShowStore = defineStore('assignmentShow', () => {
  const state = reactive({
    assignmentView: null,
    isInstructor: null,
    isLessonPlanAccessible: null,
    lrnoAssessmentConfig: null, // TODO: use the learnosity config store instead
    assignmentEventJson: null,
  })

  const hasDescription = computed(() => {
    const { lessonPlan } = useLessonPlanStore()
    return !!lessonPlan.value.description && lessonPlan.value.description.length > 0
  })

  const assignmentViewState = computed(() => get(state.assignmentView, 'assignmentState'))
  const assignmentViewKlassId = computed(() => get(state.assignmentView, 'klassId'))
  const isAssignmentViewReturned = computed(() => assignmentViewState.value === 'returned')
  const isAssignmentViewSubmitted = computed(() => get(state.assignmentView, 'submitted'))

  const assignmentViewLearnositySessionId = computed(() => get(state.assignmentView, 'learnositySessionId'))
  const learnosityConfigSessionId = computed(() => get(state.lrnoAssessmentConfig, 'request.session_id'))

  function setAssignmentViewSubmitted(newValue) {
    state.assignmentView.submitted = newValue
  }

  async function waitForLearnosityItemsApp() {
    const { fetchLearnosityClient } = useLearnosityClientStore()

    try {
      await fetchLearnosityClient(state.lrnoAssessmentConfig)
    } catch (e) {
      // We disregard errors from Learnosity here because we're only
      // using this function to know if Learnosity is finished doing
      // its thing - whether successful or not.
      log('The Learnosity items app had an error during rendering, but we are ignoring it.')
    }
  }

  return {
    state,
    hasDescription,
    assignmentViewState,
    assignmentViewKlassId,
    isAssignmentViewReturned,
    isAssignmentViewSubmitted,
    setAssignmentViewSubmitted,
    assignmentViewLearnositySessionId,
    learnosityConfigSessionId,
    waitForLearnosityItemsApp,
  }
})
