import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, search, contentTypeMetadata } from 'vue_features/shared/i18n'

const searchFormMessages = namespaceLocaleObj(search, 'search')
const searchIndexMessages = merge(
  {},
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(search, 'search'),
  namespaceLocaleObj(contentTypeMetadata, 'contentTypeMetadata'),
)

export { searchFormMessages, searchIndexMessages }
