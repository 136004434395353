<template>
  <div class="flex items-center justify-start">
    <template v-if="!inProgress">
      <DivDropdown
        :id="`google-associate-${student.id}`"
        :lazy-list="true"
        :button-classes="['btn--small']"
        :dropdown-classes="['flow-left']"
        @dropdown-toggled="$event ? loadStudentsForCourse({ courseId }) : undefined"
      >
        <template #trigger="{ buttonClasses, id, toggle }">
          <StudentLinkGoogleDropdownButton
            :id="id"
            :linked-google-student="linkedGoogleStudent"
            :google-classroom-id-for-student="googleClassroomIdForStudent"
            :google-classroom-name-for-student="googleClassroomNameForStudent"
            :button-classes="buttonClasses"
            @toggle-dropdown="toggle"
          />
        </template>
        <template #divContent>
          <template v-if="studentsForCourse(courseId) && !studentsLoadingForCourse(courseId)">
            <StudentSearch
              :google-students="studentsForCourse(courseId)"
              :current-google-student-profile-id="linkedGoogleStudent ? linkedGoogleStudent.profileId : null"
              :course-name="course.name"
              :course-link="course.link"
              @link-google-student="linkGoogleStudent"
              @unlink-google-student="unlinkGoogleStudent"
            />
          </template>
          <template v-else-if="studentsLoadingForCourse(courseId)">
            <LoadingSpinner class="p-4" />
          </template>
        </template>
      </DivDropdown>
    </template>
    <LoadingSpinner v-else :inline="true" tag="span" size="small" />

    <LinkGoogleConfirmModal
      v-if="canModalShow(confirmModalId)"
      v-bind="{
        studentName: student.name,
        googleClassroomStudent: googleClassroomStudentToConfirm,
        modalId: confirmModalId,
      }"
      @cancel="confirmationCanceled"
      @confirm="confirmationComplete"
    />
  </div>
</template>

<script>
import { get } from 'lodash'
import { LoadingSpinner } from 'vue_features/shared/components/ui'
import DivDropdown from 'vue_features/shared/components/ui/dropdowns/DivDropdown'
import LinkGoogleConfirmModal from './modals/LinkGoogleConfirmModal'
import StudentLinkGoogleDropdownButton from './StudentLinkGoogleDropdownButton'
import StudentSearch from 'vue_features/shared/components/google_classroom/StudentSearch'
import { openModal, canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'StudentLinkGoogleDropdown',
  components: {
    LoadingSpinner,
    DivDropdown,
    LinkGoogleConfirmModal,
    StudentSearch,
    StudentLinkGoogleDropdownButton,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      studentsForCourse,
      studentsLoadingForCourse,
      associateGoogleStudent,
      disassociateGoogleStudent,
      loadStudentsForCourse,
    } = useGoogleClassroomStore()
    return {
      studentsForCourse,
      studentsLoadingForCourse,
      associateGoogleStudent,
      disassociateGoogleStudent,
      loadStudentsForCourse,
      openModal,
      canModalShow,
      closeModal,
    }
  },
  data() {
    return {
      inProgress: false,
    }
  },
  computed: {
    courseId() {
      return this.course.courseId
    },
    linkedGoogleStudent() {
      return this.student.googleClassroomUserData
    },
    confirmModalId() {
      return `${LinkGoogleConfirmModal.name}-${this.student.id}`
    },
    googleClassroomIdForStudent() {
      return get(this.student.googleClassroomUserData, 'profileId')
    },
    googleClassroomNameForStudent() {
      return get(this.student.googleClassroomUserData, 'name')
    },
  },
  methods: {
    linkGoogleStudent(googleClassroomStudent) {
      if (this.student.googleClassroomUserData || googleClassroomStudent.userId) {
        this.googleClassroomStudentToConfirm = googleClassroomStudent
        this.openModal(this.confirmModalId)
      } else {
        return this.linkStudent(googleClassroomStudent)
      }
    },

    linkStudent(googleClassroomUserData) {
      this.inProgress = true
      return this.associateGoogleStudent({
        studentId: this.student.id,
        googleClassroomStudentId: googleClassroomUserData.profileId,
      })
        .then(() => {
          return this.$emit('google-classroom-linked-student', { student: this.student, googleClassroomUserData })
        })
        .finally(() => {
          this.inProgress = false
          this.loadStudentsForCourse({ courseId: this.courseId, force: true })
        })
    },

    unlinkGoogleStudent() {
      this.inProgress = true
      return this.disassociateGoogleStudent({
        studentId: this.student.id,
      })
        .then(() => {
          return this.$emit('google-classroom-unlinked-student', { student: this.student })
        })
        .finally(() => {
          this.inProgress = false
          this.loadStudentsForCourse({ courseId: this.courseId, force: true })
        })
    },

    confirmationCanceled() {
      this.googleClassroomStudentToConfirm = null
    },
    confirmationComplete(googleClassroomStudent) {
      this.linkStudent(googleClassroomStudent)
      this.googleClassroomStudentToConfirm = null
    },
  },
}
</script>
