<template>
  <div class="ck-editable flex-grow">
    <div v-if="isLearnosityVisible" data-test="learnosity-content">
      <MathJaxLoadingSpinner>
        <ShowAnswersButton
          v-if="isShowAnswersVisible"
          v-bind="{ itemReference, config: learnosityConfig }"
          data-test="show-answers-button"
        />
        <div ref="learnosityItemElement" class="lz-learnosity-item" data-test="learnosity-item">
          <LearnosityItem v-bind="{ itemReference }" />
        </div>

        <CheckAnswersButton
          v-if="hasCheckableAnswers"
          v-bind="{ itemReference, config: learnosityConfig }"
          data-test="check-answers-button"
        />
      </MathJaxLoadingSpinner>
    </div>
    <div v-else class="card__resource-contents--signup">
      <EmptyStateWithIcon
        v-if="isStudent"
        path="icons/content-gate"
        :heading="$t('learnosity_item.no_access_unassigned')"
        :body="$t('learnosity_item.ask_your_teacher_to_assign')"
      />
      <p v-else data-test="login-link">
        <strong>
          <span>
            <a :href="loginPath" class="login_required">
              {{ $t('common.login') }}
            </a>
            {{ $t('learnosity_item.to_view_question') }}
          </span>
        </strong>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, inject, reactive, ref, watch } from 'vue'
import ShowAnswersButton from './ShowAnswersButton'
import useLearnosityItemResource from '../composables/use_learnosity_item_resource'
import CheckAnswersButton from './CheckAnswersButton'
import { useAssignmentStore, useAssignmentCardsStore } from 'vue_features/assignments/show/composables'
import LearnosityItem from './LearnosityItem'
import { EmptyStateWithIcon } from 'vue_features/shared/components/ui'
import MathJaxLoadingSpinner from 'vue_features/shared/components/MathJaxLoadingSpinner'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'LearnosityItemResource',
  components: {
    LearnosityItem,
    EmptyStateWithIcon,
    MathJaxLoadingSpinner,
    ShowAnswersButton,
    CheckAnswersButton,
  },
  props: {
    itemReference: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: false,
      default: null,
      validator: (val) => typeof val === 'object' || val === null,
    },
    hasCheckableAnswers: {
      type: Boolean,
      required: true,
    },
    lessonPlanCustomerId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { hasAssignment } = useAssignmentStore()
    const { saveQuestionResponse, learnosityConfigFor, setQuestionAttempted } = useAssignmentCardsStore()
    const learnosityConfig = computed(() => reactive(props.config || learnosityConfigFor(props.itemReference)))
    const { authorizedAsTeacher: isAuthorizedAsTeacher, isStudent } = useCurrentUserStore()
    const { viewableAsTeacher, isPublicCustomer, id: currentCustomerId } = useCurrentCustomerStore()
    const isLearnosityVisible = computed(() => {
      // FIXME: make a computed?
      const belongsToCustomer = props.lessonPlanCustomerId === currentCustomerId.value
      return (
        isAuthorizedAsTeacher.value ||
        (!isPublicCustomer.value && belongsToCustomer && viewableAsTeacher.value) ||
        hasAssignment.value
      )
    })
    const loginPath = computed(() => (isPublicCustomer.value ? Routes.customer_selects_path() : Routes.login_path()))
    const learnosityItemElement = ref(null)

    if (!isLearnosityVisible.value) {
      return {
        learnosityConfig,
        isLearnosityVisible,
        isAuthorizedAsTeacher,
        hasAssignment,
        isStudent,
        isShowAnswersVisible: false,
        isAutoscorable: false,
        hasAttemptedAllQuestions: false,
        loginPath,
      }
    }

    const { isAutoscorable, hasAttemptedAllQuestions, isReviewing, setOnQuestionChange } = useLearnosityItemResource(
      props.itemReference,
      learnosityConfig.value,
    )

    const isShowAnswersVisible = computed(() => {
      return isAuthorizedAsTeacher.value && !hasAssignment.value && isAutoscorable.value
    })

    if (hasAssignment.value) {
      if (!isReviewing.value && !isAuthorizedAsTeacher.value) {
        setOnQuestionChange(saveQuestionResponse)
      }
      watch(hasAttemptedAllQuestions, (newHasAttemptedAllQuestions) => {
        if (newHasAttemptedAllQuestions) {
          setQuestionAttempted(props.itemReference)
        }
      })
    }

    const rerender = inject('rerender', () => {})
    watch(learnosityConfig, rerender)

    return {
      learnosityConfig,
      learnosityItemElement,
      isLearnosityVisible,
      isAuthorizedAsTeacher,
      hasAssignment,
      isStudent,
      isShowAnswersVisible,
      isAutoscorable,
      hasAttemptedAllQuestions,
    }
  },
}
</script>
