<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div>
    <PublishConfirmModal v-if="canModalShow('PublishConfirmModal')" />
    <ArchiveMessageModal v-if="canModalShow('ArchiveMessageModal')" />
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <AdminBar settings-classes="select-dropdown">
        <template v-if="!isCreatingMetaEdits" #settings>
          <label>
            {{ $t('components.state_changer.visibility') }}
          </label>
          <ListDropdown v-if="canManageState" id="state-changer" :dropdown-classes="['select-dropdown__options']">
            <template #button>
              <LzIcon path="icons/caret" size="sm" />
              <span>{{ stateLabel(currentState) }}</span>
            </template>
            <template #listItems>
              <li
                v-for="option in availableStates"
                :key="option"
                :data-test="`state-${option}`"
                :class="[
                  'select-dropdown__option',
                  {
                    disabled: isOptionDisabled(option),
                    'select-dropdown__option--selected': isOptionSelected(option),
                  },
                ]"
                @click="selectOption(option)"
              >
                <LzIcon path="icons/complete" />
                <div class="select-dropdown__option-description">
                  <h5>
                    {{ stateLabel(option) }}
                    <span v-if="isOptionDisabled(option)">
                      {{ $t('components.state_changer.not_available') }}
                    </span>
                  </h5>
                  <p class="smallest">{{ optionDescription(option) }}</p>
                </div>
              </li>
            </template>
          </ListDropdown>
          <div v-else>
            <strong class="mr-1 mt-2 block">{{ stateLabel(currentState) }}</strong>
          </div>
        </template>
        <template #actions>
          <button
            v-if="isPublishing"
            data-test="publish-btn"
            class="btn btn--primary"
            @click="open('PublishConfirmModal')"
          >
            {{ $t('lesson_plans.admin_bar.publish', { lesson_type: readableType }) }}
          </button>
          <button v-else-if="didStateChange" data-test="state-submit-btn" class="btn" @click="updateState">
            {{ $t('index.done_editing') }}
          </button>
          <a
            v-else
            :href="Routes.lesson_plan_path(lessonPlan.id, { card: selectedCardId })"
            data-test="done-editing"
            class="btn"
          >
            {{ $t('index.done_editing') }}
          </a>
        </template>
      </AdminBar>
    </div>
    <div v-if="isArchived(currentState) && canManageState" class="mx-auto mt-4 max-w-7xl px-3 text-right md:px-6">
      <button data-test="archive-message-btn" class="btn btn--small" @click="open('ArchiveMessageModal')">
        <LzIcon :path="lessonPlan.deprecatedByMessage ? 'icons/edit' : 'icons/add'" />
        {{
          lessonPlan.deprecatedByMessage
            ? $t('lesson_plans.archive_editor.edit_message')
            : $t('lesson_plans.archive_editor.add_message')
        }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import AdminBar from 'vue_features/shared/components/AdminBar'
import ArchiveMessageModal from './ArchiveMessageModal'
import PublishConfirmModal from './PublishConfirmModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import LessonPlanService from 'vue_features/lesson_plans/api/lesson_plans_service'
import { enableUnloadConfirm, location as $location } from 'utils'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import Routes from 'routes'
import {
  DRAFT,
  ACTIVE,
  ARCHIVED,
  isDraft,
  isArchived,
  isActive,
} from 'vue_features/shared/composables/use_record_states'

const availableStates = [DRAFT, ACTIVE, ARCHIVED]
const root = inject('useRoot')()
const { isProgressCoverDisplayed, canManageState, isCreatingMetaEdits } = useLessonPlanEditStore()
const { lessonPlan, lessonType } = useLessonPlanStore()
const currentState = ref(lessonPlan.value.state)
const readableType = computed(() => root.$t(`readable_types.${lessonType.value}`))
const stateLabel = (state) => {
  switch (state) {
    case DRAFT:
      return root.$t('common.draft')
    case ARCHIVED:
      return root.$t('common.archived')
    default:
      return root.$t('components.state_changer.published')
  }
}
const optionDescription = (state) => {
  switch (state) {
    case DRAFT:
      return isOptionDisabled(DRAFT) && lessonPlan.value['hasBeenAssigned?']
        ? root.$t('components.state_changer.lesson_has_been_assigned', { type: readableType.value })
        : root.$t('components.state_changer.available_to_authors', { type: readableType.value })
    case ARCHIVED:
      return root.$t('components.state_changer.available_by_share')
    default:
      return root.$t('components.state_changer.available_to_all')
  }
}

const originalState = computed(() => lessonPlan.value.state)
const isOptionDisabled = (option) => {
  if (isDraft(option)) {
    return lessonPlan.value['hasBeenAssigned?'] || isArchived(originalState)
  }
  return isArchived(option) && isDraft(originalState)
}
const isOptionSelected = (option) => currentState.value === option
const selectOption = (option) => {
  if (!isOptionDisabled(option)) {
    currentState.value = option
  }
}
const didStateChange = computed(() => lessonPlan.value.state !== currentState.value)
const isPublishing = computed(() => isActive(currentState) && didStateChange.value)

const updateState = async () => {
  isProgressCoverDisplayed.value = true
  await LessonPlanService.updateLessonPlan(lessonPlan.value.id, { state: currentState.value })
  $location.assign(Routes.lesson_plan_path(lessonPlan.value.id))
}
const open = (modalId) => {
  enableUnloadConfirm()
  openModal(modalId)
}

const { selectedCardId } = useCardDeckEditorStore()
</script>
