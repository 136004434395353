<template>
  <LzModal v-slot="{ close }" :modal-id="modalId" class="lzui-modal--confirmation">
    <div class="p-8">
      <h2 class="my-2">{{ $t('publish_reminder') }}</h2>
      <p class="my-2 max-w-prose">
        {{ $t('collection_publish_explanation') }}
      </p>
      <footer>
        <LzButton @click="close">{{ $t('common.cancel') }}</LzButton>
        <LzButton data-test="publish-button" color="green" @click="publish">{{ $t('common.publish') }}</LzButton>
      </footer>
    </div>
  </LzModal>
</template>

<script>
import { useCollectionsIndexStore } from '../../store/use_collections_index_store'
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import LzButton from 'vue_features/shared/components/ui/LzButton'

export default {
  name: 'PublishCollectionModal',
  components: { LzButton, LzModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { publishCollection } = useCollectionsIndexStore()
    return { publishCollection, closeModal }
  },
  methods: {
    publish() {
      this.publishCollection(this.collection.id)
      this.$emit('published-collection')
      this.closeModal(this.modalId)
    },
  },
}
</script>
