<template>
  <div class="px-3 py-6 md:px-6">
    <div class="mx-auto max-w-7xl space-y-2">
      <div
        data-test="search-header"
        class="flex flex-grow flex-col-reverse items-center justify-between gap-4 sm:flex-row"
      >
        <div class="w-full space-y-2">
          <LzButton @click="toggleFilters">
            <LzIcon path="icons/controls" />
            <span class="text-sm font-bold">{{ $t('search.filters.title') }}</span>
            <span class="text-xs">({{ $t(`search.filters.${showFilters ? 'hide' : 'show'}`) }})</span>
          </LzButton>
          <ActiveFilters />
        </div>
        <ListDropdown
          v-if="authorizedAsAuthor"
          id="search_new_content"
          data-test="new-content"
          :button-classes="['btn--primary', 'w-48', 'transform', 'hover:translate-y-0.5', 'focus:translate-y-0.5']"
          :dropdown-classes="['w-full', 'mt-2']"
        >
          <template #button>
            <LzIcon path="icons/plus" />
            {{ $t('search.header.add_new_content') }}
          </template>
          <template #listItems>
            <li v-for="option in newContentOpts" :key="option.key">
              <a :href="option.url" data-test="content-option" class="flex items-center space-x-2 px-1 py-2">
                <InlineSvg
                  :path="`icons/${option.key}.svg`"
                  class="h-8 w-8 flex-none rounded-lg border-2 border-white"
                />
                <div class="flex flex-col">
                  <span class="text-sm font-bold">{{ option.label }}</span>
                  <span class="text-muted text-xs italic">{{ $t(`contentTypeMetadata.${option.key}.help_text`) }}</span>
                </div>
              </a>
            </li>
          </template>
        </ListDropdown>
      </div>
      <div class="flex flex-row">
        <SlideXLeftTransition>
          <SearchFilters v-if="showFilters" key="filters" />
        </SlideXLeftTransition>
        <div v-if="showResults" key="results" data-test="search-results" class="min-w-0 flex-auto">
          <div v-if="loading" data-test="search-loading" class="pt-32 text-center">
            <LoadingSpinner size="large" />
            <p>{{ $t('common.loading') }}</p>
          </div>
          <div v-else-if="results.length">
            <div class="space-y-4">
              <div class="flex flex-row flex-wrap items-center justify-between gap-2">
                <p role="status" class="text-muted mb-0 flex-grow text-xs font-semibold md:text-sm">{{
                  $t('search.showing_results', { count: resultRange, total: recordCount })
                }}</p>
                <ListDropdown
                  id="search_results_sort"
                  class="flex-auto text-right sm:flex-initial"
                  data-test="results-sort"
                  :button-classes="[
                    'w-full',
                    'text-sm',
                    'text-left',
                    'bg-white',
                    'hover:bg-white',
                    'sm:w-auto',
                    'sm:text-base',
                  ]"
                  :dropdown-classes="['w-unset flow-left']"
                >
                  <template #button>
                    {{ sortOpts[sort] }}
                    <LzIcon class="float-right" path="icons/caret" />
                  </template>
                  <template #listItems>
                    <li v-for="(value, key) in sortOpts" :key="key">
                      <button
                        type="button"
                        data-test="sort-option"
                        :data-test-key="key"
                        class="p-2"
                        @click="sort = key"
                      >
                        {{ value }}
                      </button>
                    </li>
                  </template>
                </ListDropdown>
              </div>
              <div data-test="result-items" class="flex flex-col space-y-2">
                <Result v-for="result in results" :key="result.id" :result="result" />
              </div>
            </div>
            <div class="center py-4">
              <LzPaginator :current-page="page" :total-pages="pageCount" @page-changed="getPage" />
            </div>
          </div>
          <EmptyStateWithIcon
            v-else
            :heading="$t('search.no_matches')"
            :body="$t('search.try_broader')"
            path="misc/search-illustration.svg"
            data-test="search-empty"
            class="center"
          >
            <LzButton data-test="clear-empty" type="button" color="primary" @click="clearFilters">{{
              $t('search.reset_filters')
            }}</LzButton>
          </EmptyStateWithIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { upperFirst, isEqual } from 'lodash'
import Routes from 'routes'
import { ref, computed, watch, onMounted, onUnmounted, inject } from 'vue'
import { SlideXLeftTransition } from 'vue_features/shared/components/transitions'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useSearchFiltersStore } from '../store/use_search_filters_store'
import { getResults } from '../api'
import {
  LzButton,
  EmptyStateWithIcon,
  InlineSvg,
  LoadingSpinner,
  LzIcon,
  LzPaginator,
} from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import SearchFilters from './SearchFilters'
import ActiveFilters from './ActiveFilters'
import screenSize from 'utils/screen_size'
import Result from './Result'
import { useRoute, useRouter } from 'vue-router'
import { stringifyQuery } from 'vue_features/shared/router'

const root = inject('useRoot')()
const { authorizedAsAuthor } = useCurrentUserStore()
const {
  applyAggregates,
  selectedOptions,
  setOption,
  sort,
  page,
  query,
  filterParams,
  routeParams,
  requestParams,
  clearFilters,
  RESULTS_PER_PAGE,
} = useSearchFiltersStore()

const isXs = ref(screenSize.isXs())
const loading = ref(false)
const results = ref([])
const resultCount = ref(0)
const showFilters = ref(!isXs.value)
const showResults = ref(true)

const route = useRoute()
const router = useRouter()
watch(filterParams, async () => {
  if (!isEqual(route.query, filterParams.value)) {
    const navigationFailure = await router.push({ path: Routes.search_path(), query: routeParams.value })
    // the query parsing is causing array filters to show up as duplicate routes
    // this is a bug in vue router
    if (navigationFailure) {
      window.history.pushState({}, '', Routes.search_path() + '?' + stringifyQuery(routeParams.value))
    }
  }
})

const getPage = (pageEvent) => {
  page.value = parseInt(pageEvent, 10)
  router.push({ path: Routes.search_path(), query: routeParams.value })
}

// React to screen resize
const checkScreenWidth = () => (isXs.value = screenSize.isXs())
onMounted(() => window.addEventListener('resize', checkScreenWidth))
onUnmounted(() => window.removeEventListener('resize', checkScreenWidth))
watch(isXs, (newValue) => {
  if (!newValue) {
    showResults.value = true
  }
})

// Lists
const _newContentKeys = {
  page: 'wiki',
  lessonplan: null,
  audio: 'audio',
  document: 'document',
  image: 'image',
  url: 'hyperlink',
  html: 'html',
  slide: 'slide',
  video: 'video',
}
const sortOpts = {
  relevance: root.$t('search.sort_options.relevance'),
  'name.raw': root.$t('search.sort_options.title'),
  updated_at: root.$t('search.sort_options.date'),
}

// Header methods and helpers
const _makeNewContentOpt = ([key, rtype]) => ({
  key,
  url: rtype ? Routes.new_resource_path({ resource_type: rtype }) : Routes.new_lesson_plan_path(),
  label: upperFirst(root.$t(`contentTypeMetadata.${key}.label`)),
})

// Filter card helpers
const toggleFilters = () => {
  if (isXs.value) showResults.value = showFilters.value
  showFilters.value = !showFilters.value
}
// Results helpers
const updateResults = async () => {
  loading.value = true
  const data = await getResults(requestParams.value)
  results.value = data.results
  resultCount.value = data.resultCount
  applyAggregates(data.aggregates)
  loading.value = false
}

const _getResultsRange = () => {
  const prior = (page.value - 1) * RESULTS_PER_PAGE
  const firstResult = prior + 1
  const lastResult = prior + results.value.length
  return `${firstResult.toLocaleString()} - ${lastResult.toLocaleString()}`
}

const setFilterDataFromUrl = (urlParams) => {
  if (urlParams.q) {
    query.value = urlParams.q
  }
  if (urlParams.sort) {
    sort.value = urlParams.sort
  }
  const newPage = parseInt(urlParams.p, 10)
  _applyParamFilters(urlParams)
  if (newPage > 0) page.value = newPage // Set new page after filters (which set page to 1)
}

const _applyParamFilters = (filterParams) => {
  Object.entries(selectedOptions.value).forEach(([filter, keys]) => {
    let urlValue = filterParams[`${filter}[]`] || filterParams[filter]
    if (urlValue) {
      urlValue = Array.isArray(urlValue) ? urlValue : [urlValue]
      keys.concat(urlValue).forEach((key) => setOption(filter, key, urlValue.includes(key)))
    } else {
      keys.forEach((key) => setOption(filter, key, false))
    }
  })
}

watch(
  route,
  () => {
    setFilterDataFromUrl(route.query)
    updateResults()
  },
  { immediate: true },
)

const newContentOpts = Object.entries(_newContentKeys).map(_makeNewContentOpt)
const resultRange = computed(_getResultsRange)
const recordCount = computed(() => resultCount.value.toLocaleString())
const pageCount = computed(() => Math.ceil(resultCount.value / RESULTS_PER_PAGE))
</script>
