<script setup>
import { computed } from 'vue'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { Banner, TopSticky, LzButton, LzIcon } from 'vue_features/shared/components/ui'
import { PortalTarget } from 'portal-vue'
import ActionMenu from 'vue_features/action_menu/components/ActionMenu'
import { activeModal } from 'vue_features/shared/composables/use_global_modals'
import Routes from 'routes'

const { actionMenuProps } = useLessonPlanShowStore()
const { lessonPlan } = useLessonPlanStore()

const hasActiveModal = computed(() => !!activeModal.value.id)

const actionMenuPropsWithEditRemoved = computed(() => {
  return {
    ...actionMenuProps.value,
    actions: {
      ...actionMenuProps.value.actions,
      canEditResource: false,
      canEditLessonPlan: false,
    },
  }
})

const editLessonPlanPath = computed(() => `${Routes.edit_lesson_plan_path(actionMenuProps.value.object.idSlug)}`)
</script>

<template>
  <TopSticky :class="['z-20', { 'z-50': hasActiveModal }]" data-test="top-sticky">
    <PortalTarget name="action-modals" />
    <Banner type="neutral" :has-top-border="false" class="bg-opacity-90">
      {{
        $t('common.you_are_doing', {
          action: $t('common.viewing'),
          objectName: $t(`readable_types.${lessonPlan.lessonType}`).toLocaleLowerCase(),
          state: $t('common.draft').toLocaleLowerCase(),
        })
      }}
      <div class="flex gap-x-2">
        <ActionMenu
          v-bind="actionMenuPropsWithEditRemoved"
          modal-portal="action-modals"
          :neutral-theme="true"
          class="flex-grow-0"
          size="sm"
        />
        <LzButton
          :href="editLessonPlanPath"
          tag="a"
          color="green"
          size="small"
          class="btn--neutral-hover flex items-center gap-1"
        >
          <LzIcon path="icons/action-edit" size="md" type="span" />
          {{ $t('common.edit') }} {{ $t('common.lesson') }}
        </LzButton>
      </div>
    </Banner>
  </TopSticky>
</template>
