<template>
  <div ref="lessonPlayerElement" class="h-full w-full">
    <div ref="scrollManagerRoot" class="h-full max-h-screen overflow-y-auto">
      <div class="assignment-deck-container z-40 -mx-4 w-full min-w-full rounded-2xl px-4">
        <LearnosityRerenderer @on-rerender="connectScrollHandling">
          <div class="lesson-content min-h-96 flex w-full flex-row print:block">
            <LessonContainer
              ref="lessonContainerRef"
              :cards="cards"
              :selected-card-index="selectedCardIndex"
              :is-preview="isPreview"
              @on-card-selected="scrollToSelectedCard"
              @exit-player="$emit('exit-player')"
            />
          </div>
        </LearnosityRerenderer>
      </div>

      <div
        id="toolbar"
        ref="toolbarRef"
        :style="toolbarStyle"
        class="secondary-toolbar right-0 z-50 print:hidden"
        :class="{
          'bottom-1': showToolbar,
          '-bottom-18 hide': !showToolbar,
          'sticky w-full pr-8': stickyDeck,
          'fixed bottom-1': !stickyDeck,
        }"
      >
        <LessonToolbar
          v-if="cards.length > 0"
          class="w-full print:hidden"
          :cards="cards"
          :show-toolbar="showToolbar"
          :show-sidebar-button="false"
          :show-tools-button="false"
          :show-fullscreen-button="false"
          :show-save-and-exit-button="false"
          :selected-card-index="selectedCardIndex"
          @toggle-toolbar="toggleToolbar"
          @card-selected="scrollToSelectedCard"
          @go-previous="goPrevious"
          @go-next="goNext"
          @exit-player="$emit('exit-player')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LearnosityRerenderer from 'vue_features/resources/learnosity_items/components/LearnosityRerenderer'
import LessonContainer from 'vue_features/lesson_plans/show/components/LessonContainer'
import LessonToolbar from 'vue_features/lesson_plans/show/components/LessonToolbar'
import { toRef, ref, onMounted, watch, computed } from 'vue'
import useAssignmentCardsSecondaryDeck from 'vue_features/assignments/show/composables/use_assignment_cards_secondary_deck'
import { useViewportSize } from 'vue_features/shared/composables'

export default {
  name: 'AssignmentCardsSecondaryDeck',
  components: {
    LessonToolbar,
    LessonContainer,
    LearnosityRerenderer,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const lessonPlayerElement = ref(null)
    const scrollManagerRoot = ref(null)

    const { activateKeyboardNavigation, deckCurrentOffsetTop, ...otherProps } = useAssignmentCardsSecondaryDeck({
      lessonPlayerElementRef: lessonPlayerElement,
      scrollManagerRootRef: scrollManagerRoot,
      cardsRef: toRef(props, 'cards'),
    })

    const lessonContainerRef = ref(null)
    const toolbarRef = ref(null)

    const { width } = useViewportSize()

    const stickyDeck = computed(() => {
      return deckCurrentOffsetTop.value < 0
    })

    watch(width, () => syncToolbarWidthToCards())
    watch(stickyDeck, () => syncToolbarWidthToCards())

    const toolbarStyle = ref({})
    const syncToolbarWidthToCards = () => {
      const box = lessonContainerRef.value.$el.getBoundingClientRect()

      if (stickyDeck.value) {
        delete toolbarStyle.value['left']
        delete toolbarStyle.value['width']
        delete toolbarStyle.value['padding-left']
      } else {
        toolbarStyle.value['left'] = `${box.left}px !important`
        toolbarStyle.value['width'] = `${box.width}px !important`
        toolbarStyle.value['padding-left'] = '0 !important'
      }
    }

    onMounted(() => {
      lessonPlayerElement.value.addEventListener('click', () => {
        activateKeyboardNavigation()
      })
      syncToolbarWidthToCards()
    })

    return {
      ...otherProps,
      stickyDeck,
      activateKeyboardNavigation,
      lessonPlayerElement,
      lessonContainerRef,
      scrollManagerRoot,
      toolbarRef,
      toolbarStyle,
    }
  },
}
</script>

<style scoped>
.assignment-deck-container {
  width: calc(100% + 2rem);
}
</style>
