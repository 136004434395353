import { reactive, watch, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { get } from 'lodash'
import { defaultScoreBandRanges } from 'vue_features/shared/utils/scoring'
import {
  paramsFromFilters,
  generateFilteredUsageData,
  generateDonutChartData,
  generateRankedStandardsData,
  generatePerformanceAndUsageTableData,
  generateStandardsTableData,
} from '../utils'
import { defaultDateRange } from 'vue_features/item_analysis/utils'

export const LESSON_TYPES = {
  ASSESSMENT: 'assessment',
  ACTIVITY: 'activity',
  FULL_LESSON: 'full',
}

export const useDataDashboardStore = defineStore('dataDashboard', () => {
  const state = reactive({
    filters: {
      subject: null,
      gradeLevel: null,
      school: null,
      teacher: null,
      student: null,
      gradingStatus: null,
      lessonTypes: [LESSON_TYPES.ASSESSMENT],
      ...defaultDateRange(),
    },
    availableCustomers: [],
    availableSubjects: [],
    standardsTableFilter: null,
    getAllowedFilters: null,
    getUsageData: null,
    getScoreBands: null,
    getRankedStandards: null,
    getCategoryRollup: null,
    getStandardsAlignment: null,
    getCategoryItemsRollup: null,
    getCategoryStandardsRollup: null,
    getAvailableCustomers: null,
  })

  const filterParams = computed(() => paramsFromFilters(state.filters))

  const scoreBandsParams = Object.keys(defaultScoreBandRanges).reduce((all, key) => {
    all[`score_bands[${key}]`] = defaultScoreBandRanges[key][1]
    return all
  }, {})

  function reloadData() {
    state.getAllowedFilters.load(filterParams.value)
    state.getScoreBands.load({ ...filterParams.value, ...scoreBandsParams })
    state.getRankedStandards.load(filterParams.value)
    state.getStandardsAlignment.load(filterParams.value)
    state.getCategoryRollup.load(filterParams.value)
    state.getCategoryItemsRollup.load(filterParams.value)
    state.getUsageData.load(filterParams.value)
    state.getCategoryStandardsRollup.reset()
  }

  async function loadAvailableCustomers() {
    return await state.getAvailableCustomers.load(filterParams.value)
  }

  const donutChartStatus = computed(() => ({
    loading: !state.getScoreBands.done,
    error: state.getScoreBands.error,
  }))
  const topStandardsStatus = computed(() => ({
    loading: !state.getRankedStandards.done,
    error: state.getRankedStandards.error,
  }))
  const bottomStandardsStatus = computed(() => ({
    loading: !state.getRankedStandards.done,
    error: state.getRankedStandards.error,
  }))
  const performanceAndUsageTableStatus = computed(() => ({
    loading: !state.getCategoryRollup.done,
    error: state.getCategoryRollup.error,
  }))
  const standardsTableStatus = computed(() => ({
    loading:
      !state.getCategoryItemsRollup.done ||
      (!state.getCategoryItemsRollup.error && !state.getCategoryStandardsRollup.done),
    error: state.getCategoryItemsRollup.error || state.getCategoryStandardsRollup.error,
  }))

  const allowedFilters = computed(() => get(state.getAllowedFilters, ['response', 'filters'], null))

  const donutChartData = computed(() => {
    const scoreBandsResponse = get(state.getScoreBands, 'response')
    return scoreBandsResponse ? generateDonutChartData({ scoreBandsResponse }) : null
  })

  const topStandards = computed(() => {
    const rankedStandardsResponse = get(state.getRankedStandards, 'response')
    return rankedStandardsResponse ? generateRankedStandardsData({ rankedStandardsResponse, dataSet: 'top5' }) : null
  })

  const bottomStandards = computed(() => {
    const rankedStandardsResponse = get(state.getRankedStandards, 'response')
    return rankedStandardsResponse ? generateRankedStandardsData({ rankedStandardsResponse, dataSet: 'bottom5' }) : null
  })

  const standardsAlignmentData = computed(() => {
    const standardsAlignment = get(state.getStandardsAlignment, 'response')
    return standardsAlignment ? standardsAlignment.data.status : null
  })

  const isPartiallyAligned = computed(() => {
    return standardsAlignmentData.value === 'partial'
  })

  const performanceAndUsageTableData = computed(() => {
    const categoryRollupResponse = get(state.getCategoryRollup, 'response')
    const usageDataResponse = get(state.getUsageData, 'response')
    const filteredUsageData = usageDataResponse ? generateFilteredUsageData({ usageDataResponse }) : {}

    if (!categoryRollupResponse) return null

    return generatePerformanceAndUsageTableData({ categoryRollupResponse, filteredUsageData })
  })

  const standardsTableData = computed(() => {
    const rankedStandardsResponse = get(state.getRankedStandards, 'response')
    const categoryItemsRollupResponse = get(state.getCategoryItemsRollup, 'response')
    const categoryStandardsRollupResponse = get(state.getCategoryStandardsRollup, 'response')
    const standardsFilter = state.standardsTableFilter

    if (!rankedStandardsResponse || !categoryItemsRollupResponse || !categoryStandardsRollupResponse) return null

    return generateStandardsTableData({
      rankedStandardsResponse,
      categoryItemsRollupResponse,
      categoryStandardsRollupResponse,
      standardsFilter,
    })
  })

  const noCleverGradeLevels = computed(() => {
    const performanceDataGroups = get(performanceAndUsageTableData.value, 'groups', [])
    const standardsData = standardsTableData.value
    const missingGradeLevels = (data) =>
      get(data, 'drillDownCategory') === 'gradeLevel' &&
      get(data, ['rows', 'length']) === 1 &&
      get(data, ['rows', 0, 'categoryName']) === 'Unknown'

    if (performanceDataGroups.every(missingGradeLevels) || missingGradeLevels(standardsData)) return true

    return false
  })

  const hasAnyOverviewData = computed(
    () =>
      get(donutChartData.value, 'bands', []).some(({ value }) => value > 0) ||
      get(topStandards.value, 'length') ||
      get(bottomStandards.value, 'length'),
  )

  const overviewLoading = computed(
    () => donutChartStatus.value.loading || topStandardsStatus.value.loading || bottomStandardsStatus.value.loading,
  )

  const hasPerformanceData = computed(
    () => get(performanceAndUsageTableData.value, ['groups', 0, 'rows', 'length'], 0) > 0,
  )

  const hasStandardsData = computed(() => get(standardsTableData.value, ['rows', 'length'], 0) > 0)

  const hasAnyData = computed(() => hasAnyOverviewData.value || hasPerformanceData.value || hasStandardsData.value)

  const loadingData = computed(
    () => overviewLoading.value || performanceAndUsageTableStatus.value.loading || standardsTableStatus.value.loading,
  )

  // Refresh category standards rollup data when either ranked standards or category items have changed
  watch(
    [() => get(state.getRankedStandards, 'response'), () => get(state.getCategoryItemsRollup, 'response')],
    ([rankedStandardsResponse, categoryItemsRollupResponse]) => {
      if (!rankedStandardsResponse || !categoryItemsRollupResponse) return
      if (
        rankedStandardsResponse.data &&
        categoryItemsRollupResponse.data &&
        !state.getCategoryStandardsRollup.loading.value
      ) {
        state.getCategoryStandardsRollup.load(filterParams.value)
      }
    },
  )

  const updateFilters = (router, values = {}) => {
    router.push({
      path: Routes.data_dashboard_index_path(),
      query: paramsFromFilters({
        ...state.filters,
        ...values,
      }),
    })
  }

  watch(
    () => get(state.getAvailableCustomers, 'response'),
    (customersResponse) => {
      if (!customersResponse) return
      state.availableCustomers = [...customersResponse]
    },
  )

  return {
    state,
    allowedFilters,
    donutChartData,
    bottomStandards,
    topStandards,
    performanceAndUsageTableData,
    standardsTableData,
    updateFilters,
    hasAnyOverviewData,
    overviewLoading,
    reloadData,
    donutChartStatus,
    topStandardsStatus,
    bottomStandardsStatus,
    performanceAndUsageTableStatus,
    isPartiallyAligned,
    standardsTableStatus,
    noCleverGradeLevels,
    hasPerformanceData,
    hasStandardsData,
    hasAnyData,
    loadingData,
    loadAvailableCustomers,
  }
})
