<template>
  <div>
    <DeleteShareModal v-if="canModalShow($options.DeleteShareModal)" :share="deletedShare" />
    <fieldset>
      <div class="bg-neutral-50 border-base mx-auto max-w-7xl overflow-hidden rounded-sm border">
        <table
          class="table-opened mb-0 flex w-full table-auto border-collapse flex-col justify-items-stretch overflow-y-auto"
        >
          <thead class="sticky top-0 flex">
            <tr class="flex flex-grow">
              <th class="flex-grow">{{ $t('common.site') }}</th>
              <th class="w-40">{{ $t('common.status') }}</th>
              <th class="w-14"></th>
            </tr>
          </thead>
          <tbody v-if="hasAnyShares" data-test="shares-table" class="max-h-120">
            <NewSharingTableItem
              v-for="(share, index) in newShares"
              :key="share.id"
              :share="share"
              @delete="deleteNewShare"
              @update-subdomain="(newValue) => updateSubdomain(newValue, index)"
            />
            <SharingTableItem
              v-for="item in visibleItems"
              :key="item.id"
              :share="item"
              class="bg-transparent"
              @delete="openDeleteShareModal"
            />
            <tr v-if="canLoadMore" class="flex justify-items-stretch">
              <td class="flex flex-grow justify-center p-0">
                <LoadMoreButton v-bind="{ isLoading }" @click="showMoreItems(handleLoadMore)" />
              </td>
            </tr>
          </tbody>
          <tbody v-else data-test="no-shares-table">
            <tr>
              <td class="text-neutral-700" colspan="3">{{ $t('not_shared') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import SharingTableItem from './SharingTableItem'
import NewSharingTableItem from './NewSharingTableItem'
import DeleteShareModal from './modals/DeleteShareModal'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { computed } from 'vue'

export default {
  name: 'SharingManagementTable',
  DeleteShareModal,
  components: {
    LoadMoreButton,
    DeleteShareModal,
    NewSharingTableItem,
    SharingTableItem,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
    newShares: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const shares = computed(() => props.collection.sharesData.shares)
    const sharesTotalCount = computed(() => props.collection.sharesData.meta.pagination.totalCount)

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: shares,
      totalCount: sharesTotalCount,
    })

    const { loadMoreCollectionShares } = useCollectionsIndexStore()
    return {
      loadMoreCollectionShares,
      visibleItems,
      showMoreItems,
      canLoadMore,
      isLoading,
      shares,
      openModal,
      canModalShow,
    }
  },
  data() {
    return {
      deletedShare: {},
    }
  },
  computed: {
    hasAnyShares() {
      return this.newShares.length > 0 || this.shares.length > 0
    },
    newShareId() {
      const lastShare = this.newShares[0] || { id: 0 }
      return lastShare.id + 1
    },
  },
  methods: {
    deleteNewShare(share) {
      this.$emit('deleted-share', share)
    },
    updateSubdomain(newValue, index) {
      this.$emit('updated-subdomain', { newValue, index })
    },
    openDeleteShareModal(share) {
      this.deletedShare = share
      this.openModal(this.$options.DeleteShareModal)
    },
    handleLoadMore(done) {
      this.loadMoreCollectionShares({
        collection: this.collection,
        page: this.collection.sharesData.meta.pagination.nextPage,
      }).then(done)
    },
  },
}
</script>
