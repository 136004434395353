<script>
import { h } from 'vue'
import LzContentList from 'vue_features/lz_content/components/LzContentList'
import StandardsCoverageTable from 'vue_features/standards_coverage_table/components/StandardsCoverageTable'
import LzContentEmbed from './LzContentEmbed'
import WikiHtmlBlock from './WikiHtmlBlock'
import WikiSection from './WikiSection'

export default {
  name: 'WikiBlock',
  props: { blocks: { type: Array, required: true } },
  render(props, context) {
    const block = props.blocks[0]
    if (block.display === 'block' || block.display === 'list') {
      return h(LzContentList, {
        ...context.attrs,
        lzContents: this.blocks.map((b) => b.content.lzContent),
        isBlock: block.display === 'block',
      })
    } else if (block.detailType === 'Html' && block.display === 'embed') {
      return h(WikiHtmlBlock, { ...context.attrs, content: block.content.content })
    } else if (block.detailType === 'Section') {
      return h(WikiSection, { ...context.attrs, title: block.title, blocks: block.content.blocks })
    } else if (block.contentType === 'StandardsCoverageTable') {
      return block.content.contents ? h(StandardsCoverageTable, { ...context.attrs, ...block.content }) : null
    } else {
      return h(LzContentEmbed, { ...context.attrs, ...block.content })
    }
  },
}
</script>
