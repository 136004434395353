<template>
  <EmptyStateWithIcon
    :heading="$t('assign_to_student_tab.no_classes_heading')"
    :body="$t('assign_to_student_tab.manage_students')"
    path="icons/chalkboard"
  >
    <LzButton tag="a" :href="Routes.teacher_klasses_path()" color="primary" data-test="classes-page-button">
      {{ $t('assign_to_student_tab.manage_classes_button') }}
    </LzButton>
  </EmptyStateWithIcon>
</template>

<script>
import { LzButton, EmptyStateWithIcon } from 'vue_features/shared/components/ui'
import Routes from 'routes'

export default {
  name: 'NoKlassesEmptyState',
  components: {
    LzButton,
    EmptyStateWithIcon,
  },
  setup() {
    return {
      Routes,
    }
  },
}
</script>
