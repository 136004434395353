<!--
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/alt-text  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="teacher-instructions">
    <h3>
      {{ $t('step_one_ensure_formatting') }}
    </h3>
    <p class="mb-4">
      {{ $t('staff_csv_example_instructions') }}
    </p>
    <p>
      {{ $t('staff_upsert_notice') }}
    </p>
    <h5 class="bold my-0">
      {{ $t('user_roles.acceptable_roles') }}
    </h5>
    <p class="smaller mb-4">
      {{ $t('user_roles.blank') }}
    </p>
    <ul class="pl-6">
      <li class="mb-2">
        <p class="small bold inline">Teacher</p>
        <p v-if="!isEnglish" class="small bold inline">{{ $t('user_roles.teacher') }}</p>
      </li>
      <li class="mb-2">
        <p class="small bold inline">School Admin</p>
        <p v-if="!isEnglish" class="small bold inline">{{ $t('user_roles.school_admin') }}</p>
      </li>
      <li class="mb-2">
        <p class="small bold inline">Author</p>
        <p v-if="!isEnglish" class="small bold inline">{{ $t('user_roles.author_description') }}</p>
      </li>
      <li class="mb-2">
        <p class="small bold inline">Site Admin</p>
        <p v-if="!isEnglish" class="small bold inline">{{ $t('user_roles.site_admin_description') }}</p>
      </li>
    </ul>
    <div class="mb-2">
      <a>
        <ImageTag path="misc/csv_staff_example.jpeg" style="width: 736px; height: 131px" />
      </a>
    </div>
    <a href="/site_admin/staff-csv-template_school.csv">
      {{ $t('download_staff_csv_template') }}
    </a>
  </div>
</template>

<script>
import ImageTag from 'vue_features/shared/components/ui/ImageTag'

export default {
  name: 'TeacherInstructions',
  components: { ImageTag },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
}
</script>
