import { reactive } from 'vue'
// import { WistiaApi } from 'clients'
import { useNamespacedEventListener } from 'vue_features/shared/composables'
import { defineStore } from 'vue_features/shared/composables/store_helpers'

const FULLSCREEN_API = {
  native: {
    enter: 'requestFullscreen',
    exit: 'exitFullscreen',
    changeEvent: 'fullscreenchange.cardDeck',
  },
  webkit: {
    enter: 'webkitRequestFullscreen',
    exit: 'webkitExitFullscreen',
    changeEvent: 'webkitfullscreenchange.cardDeck',
  },
  moz: {
    enter: 'mozRequestFullScreen',
    exit: 'mozCancelFullScreen',
    changeEvent: 'mozfullscreenchange.cardDeck',
  },
  ms: {
    enter: 'msRequestFullscreen',
    exit: 'msExitFullscreen',
    changeEvent: 'MSFullscreenChange.cardDeck',
  },
}

function apiType() {
  if (document.fullscreenEnabled) {
    return 'native'
  } else if (document.webkitFullscreenEnabled) {
    return 'webkit'
  } else if (document.mozFullScreenEnabled) {
    return 'moz'
  } else if (document.msFullscreenEnabled) {
    return 'ms'
  }
}

const fullscreenApi = FULLSCREEN_API[apiType()]

function nativeEnterFullscreen(elem) {
  const enterPromise = (elem ? elem : document.body)[fullscreenApi.enter]()
  return Promise.resolve(enterPromise)
}

function nativeExitFullscreen() {
  const exitPromise = document[fullscreenApi.exit]()
  return Promise.resolve(exitPromise)
}

function isNativeFullscreen() {
  return !!(
    document.fullscreenElement ||
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement
  )
}

export const useFullscreenStore = defineStore('fullScreen', () => {
  const state = reactive({
    isFullscreen: false,
    activeFullscreenId: '',
  })

  const { on: documentOn, off: documentOff } = useNamespacedEventListener(document)

  function toggleFullscreen(id, emit, elem = null) {
    if (state.isFullscreen) {
      return exitFullscreen(false, emit)
    } else {
      return enterFullscreen(id, emit, elem)
    }
  }

  async function enterFullscreen(id, emit, elem) {
    if (state.isFullscreen) return Promise.reject()

    if (!fullscreenApi) return Promise.reject()

    emit('fullscreen-before-enter')

    await nativeEnterFullscreen(elem)
    window.dispatchEvent(new Event('enter-fullscreen'))
    document.body.classList.add('is_full')

    state.activeFullscreenId = id
    state.isFullscreen = true

    // document.querySelectorAll('#lesson .lp-deck').forEach(el => {
    //   WistiaApi.pushOntoQueue({
    //     id: el.id,
    //     options: {
    //       videoFoam: {
    //         maxHeight: el.parentNode.clientHeight,
    //       },
    //     },
    //   })
    // })
    documentOn(fullscreenApi.changeEvent, () => {
      if (!isNativeFullscreen()) {
        exitFullscreen(true, emit)
      }
    })
    emit('fullscreen-change', state.isFullscreen)
  }

  async function exitFullscreen(isFromNativeExit, emit) {
    if (!state.isFullscreen) return Promise.reject()

    emit('fullscreen-before-exit')

    document.body.classList.remove('is_full')
    documentOff(fullscreenApi.changeEvent)

    await new Promise((resolve) => {
      if (!isFromNativeExit) {
        nativeExitFullscreen().then(resolve)
      } else {
        resolve()
      }
    })

    window.dispatchEvent(new Event('exit-fullscreen'))
    state.isFullscreen = false
    state.activeFullscreenId = ''
    emit('fullscreen-change', state.isFullscreen)
  }

  return {
    state,
    toggleFullscreen,
    enterFullscreen,
    exitFullscreen,
  }
})
