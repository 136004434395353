<template>
  <div id="additional-materials-editor">
    <ProgressCover :visible="isProgressCoverShown" />
    <Alert
      v-if="showRebalanceIssue"
      type="error"
      :title="$t('index.rebalance_error')"
      :description="$t('index.rebalance_next_steps')"
    />
    <template v-if="allCards.length > 0">
      <div class="align-right mb-4 max-w-7xl">
        <button class="btn btn--small ml-2" @click="insertLast">
          <LzIcon path="icons/add" />
          {{ $t('additional_materials.add_item') }}
        </button>
      </div>
      <draggable
        :model-value="allCards"
        :item-key="(card) => card.id || card.rank"
        :class="{ sorting: sorting }"
        class="space-y-4"
        tag="ul"
        handle=".handle"
        drag-class="dragging"
        @start="startSort"
        @end="endSort"
      >
        <template #item="{ element: card, index }">
          <AdditionalMaterialItem
            ref="additionalMaterialItems"
            :card="card"
            data-test="additional-material-item"
            class="gap-4"
          >
            <InsertBtn @click.prevent="insertAfter(index)" />
          </AdditionalMaterialItem>
        </template>
      </draggable>
    </template>
    <EmptyStateWithIcon v-else path="misc/placeholder-list-item" :body="$t('index.supplemental_help')">
      <button class="btn btn--primary" @click="insertFirst">
        <LzIcon path="icons/add.svg" />
        {{ $t('additional_materials.add_first_item') }}
      </button>
    </EmptyStateWithIcon>
  </div>
</template>

<script setup>
import AdditionalMaterialItem from './AdditionalMaterialItem'
import InsertBtn from './InsertBtn'
import { MAX_FLOAT_SIZE, getInsertRank } from '/utils/card_rank_helpers'
import { EmptyStateWithIcon, LzIcon, ProgressCover, Alert } from 'vue_features/shared/components/ui'
import draggable from 'vuedraggable'
import { ref, computed, watch, nextTick } from 'vue'
import { enableUnloadConfirm, disableUnloadConfirm } from 'utils'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'

const sorting = ref(false)
const isProgressCoverShown = ref(false)
const showRebalanceIssue = ref(false)
const additionalMaterialItems = ref([])

const { allCards, addCard, updateRank, inProgressCards, existingCardsInEdit } = useAdditionalMaterialsStore()

const allEditedCards = computed(() => inProgressCards.value.concat(existingCardsInEdit.value))

function insertAfter(index) {
  const rank = getInsertRank(index + 1, allCards.value)
  addCard({ rank })
}

function insertFirst() {
  const rank = getInsertRank(0, allCards.value)
  addCard({ rank })
}

async function insertLast() {
  insertAfter(allCards.value.length - 1)
  await nextTick()
  scrollToListBottom()
}

function scrollToListBottom() {
  additionalMaterialItems.value.at(-1).$el.scrollIntoView({ behavior: 'smooth' })
}

function startSort() {
  sorting.value = true
}

function endSort({ oldIndex, newIndex }) {
  sorting.value = false
  if (oldIndex !== newIndex) {
    const index = oldIndex > newIndex ? newIndex : newIndex + 1,
      newRank = getInsertRank(index, allCards.value),
      params = { card: { rank: newRank } }

    isProgressCoverShown.value = newRank.length > MAX_FLOAT_SIZE
    updateRank(allCards.value[oldIndex], params).then(() => {
      showRebalanceIssue.value = isProgressCoverShown.value
      isProgressCoverShown.value = false
    })
  }
}

watch(allEditedCards, () => {
  if (allEditedCards.value.length > 0) {
    enableUnloadConfirm()
  } else {
    disableUnloadConfirm()
  }
})
</script>
