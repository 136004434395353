<template>
  <button
    data-test="div-dropdown-trigger"
    aria-labelledby="button-caption"
    :data-toggle="id"
    :class="['btn', ...buttonClasses]"
    type="button"
    @click.prevent.stop="$emit('toggle-dropdown')"
  >
    <template v-if="linkedGoogleStudent">
      <LzIcon path="icons/google-signin" />
      <span data-test="linked-student-name" class="google-blue">
        {{ linkedGoogleStudent.name }}
      </span>
      <LzIcon path="icons/caret" />
    </template>

    <template v-else-if="googleClassroomIdForStudent">
      <LzTooltip tooltip-classes="google-error-tooltip">
        <LzIcon path="icons/alert-circle" class="google-exclamation" />
        <span class="red">{{ googleClassroomNameForStudent }}</span>
        <LzIcon class="red" path="icons/caret" />

        <template #content>
          <div>{{ $t('common.google_classroom.no_access', { student_name: student.name }) }}</div>
        </template>
      </LzTooltip>
    </template>

    <template v-else>
      <LzIcon svg-classes="grayscale" path="icons/google-signin" />
      <span>{{ $t('common.google_classroom.link_student') }}</span>
      <LzIcon path="icons/caret" />
    </template>
  </button>
</template>

<script>
import { LzIcon, LzTooltip } from 'vue_features/shared/components/ui'

export default {
  name: 'StudentLinkGoogleDropdownButton',
  components: {
    LzIcon,
    LzTooltip,
  },
  props: {
    buttonClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    linkedGoogleStudent: {
      type: Object,
      required: false,
      default: null,
    },
    googleClassroomIdForStudent: {
      type: String,
      required: false,
      default: null,
    },
    googleClassroomNameForStudent: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>
