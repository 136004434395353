<template>
  <RightDrawer
    title-color="white"
    :is-open="isOpen"
    background-color="primary"
    :data-test="`people-drawer-${isOpen ? 'open' : 'closed'}`"
  >
    <template #title>
      <div class="flex flex-col gap-y-2 p-6">
        <h5 class="sticky m-0 text-xl text-white">{{ $t('live_learn.people') }}</h5>
        <span class="font-bold" :class="connectedStudentIds.length > 0 ? 'text-white' : 'text-neutral-400'">{{
          `${$tc('live_learn.student', connectedStudentIds.length)} ${$t('live_learn.connected')}`
        }}</span>
      </div>
    </template>
    <div class="space-y-8">
      <div class="px-3">
        <button
          type="button"
          class="flex w-full items-center gap-4 rounded-lg px-4 py-3 font-bold transition-colors"
          :class="[selectedStudent == null ? 'bg-base text-base' : 'bg-primary-700 hover:bg-primary-600 text-white']"
          :disabled="loadingResponseData"
          @click="clearSelectedStudent"
        >
          <LzIcon
            class="transition-colors"
            size="lg"
            :class="[selectedStudent == null ? ' text-accent ' : 'text-white']"
            path="icons/user-group"
          />
          {{ $t('live_learn.class_view') }}
        </button>
      </div>
      <div class="border-inverted relative border-t-2 pt-8">
        <span
          class="text-primary-100 bg-primary absolute -top-3 left-1/2 -translate-x-1/2 transform rounded-full px-4 py-1 text-xs leading-4 tracking-wide"
        >
          {{ $t('common.students') }}
        </span>
        <ul id="student-list" data-test="student-list" class="overflow-hidden">
          <StudentsDrawerListItem
            v-for="(student, index) in sortedStudents"
            :id="`students-list-item-${index}` /* Used to show coachmarks */"
            :key="student.id"
            :student="student"
            :is-connected="connectedStudentIds.includes(parseInt(student.id))"
            :disabled="loadingResponseData"
            @navigation-click="navigateTo(getStudentPosition(student.id))"
          />
        </ul>
      </div>
    </div>
  </RightDrawer>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import RightDrawer from './RightDrawer'
import StudentsDrawerListItem from './StudentsDrawerListItem'
import { useLiveLearnStore, useAssignmentCardsStore } from 'vue_features/assignments/show/composables'

export default {
  name: 'PeopleDrawer',
  components: { RightDrawer, StudentsDrawerListItem, LzIcon },
  props: {
    isOpen: { type: Boolean, default: false },
  },

  setup(_props, { emit }) {
    const { assignmentDisplayCards } = useAssignmentCardsStore()
    const {
      connectedStudentIds,
      sortedStudents,
      getStudentPosition,
      selectedStudent,
      initialLrnoAssessmentConfig,
      updateAssessmentConfig,
      loadingResponseData,
    } = useLiveLearnStore()

    function navigateTo(position) {
      emit('assignment-card-changed', assignmentDisplayCards.value[position].id)
    }

    function clearSelectedStudent() {
      if (selectedStudent.value) {
        selectedStudent.value = null
        updateAssessmentConfig(initialLrnoAssessmentConfig.value)
      }
    }

    return {
      connectedStudentIds,
      sortedStudents,
      getStudentPosition,
      navigateTo,
      clearSelectedStudent,
      selectedStudent,
      loadingResponseData,
    }
  },
}
</script>
