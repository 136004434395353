import UsersService from '../api/users_service'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { reactive } from 'vue'

export const useUsersIndexStore = defineStore('usersIndex', () => {
  const state = reactive({
    usersData: {
      users: [],
      meta: { pagination: {} },
    },
    filterableRoles: [],
    usersCount: '',
  })

  function getUsers(searchParams) {
    return UsersService.search({ searchParams }).then((newUsersData) => {
      state.usersData = newUsersData
    })
  }

  function updateUser({ userId, updateParams }) {
    return UsersService.update({ userId, updateParams }).then((newUserData) => {
      const users = state.usersData.users
      const userIndex = users.findIndex((u) => u.id === newUserData.id)
      users.splice(userIndex, 1, newUserData)
    })
  }

  function deleteUser(userId) {
    return UsersService.delete(userId).then(() => {
      const users = state.usersData.users,
        user = users.find((u) => u.id === userId)

      user.isPendingDeletion = true
    })
  }

  return {
    state,
    getUsers,
    updateUser,
    deleteUser,
  }
})
