import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { useAssignmentStore, useAssignmentShowStore, useLiveLearnStore } from './'
import { debounce, get } from 'lodash'
import { location as $location } from 'utils'
import AssignmentShowService from 'vue_features/assignments/show/api/assignment_show_service'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import { isDraft } from 'vue_features/shared/composables/use_record_states'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

export const END_CARD_ID = -1

export const useAssignmentCardsStore = defineStore('assignmentCards', () => {
  const state = reactive({
    cards: null,
    attemptedQuestionCardIds: [],
    activeDeckId: '',
    selectedAssignmentCardId: null,
    selectedLessonCardId: null,
  })

  // TODO: Pull the learnosity config from the learnosity config store instead
  const { assignmentView, lrnoAssessmentConfig, setAssignmentViewSubmitted, isAssignmentViewReturned } =
    useAssignmentShowStore()

  const { isLiveLearn } = useLiveLearnStore()

  const hasAssignmentCards = computed(() => assignmentCards.value.length > 0)
  const hasLessonCards = computed(() => lessonCards.value.length > 0)

  const questionCards = computed(() => {
    return state.cards.filter((card) => isQuestionCard(card) && !isDraft(card.content))
  })
  const assignmentCards = computed(() => {
    const { isAssessment } = useAssignmentStore()
    if (isAssessment.value || isLiveLearn.value) return state.cards
    return state.cards.filter((card) => card.contexts.includes('assignment'))
  })
  const lessonCards = computed(() => {
    return state.cards.filter((card) => !assignmentCards.value.includes(card))
  })
  function cardsForDisplay(cards) {
    const lastCard = cards[cards.length - 1]
    const hasEndCard = lastCard && !isAssignmentViewReturned.value && !isLiveLearn.value
    return hasEndCard ? [...cards, { id: END_CARD_ID, title: lastCard.title }] : cards
  }
  const assignmentDisplayCards = computed(() => cardsForDisplay(assignmentCards.value))
  const lessonDisplayCards = computed(() => cardsForDisplay(lessonCards.value))
  const assignmentDisplayCardsPosition = computed(() => getAssignmentCardPosition(state.selectedAssignmentCardId))

  function getAssignmentCardPosition(id) {
    return assignmentDisplayCards.value.findIndex((card) => card.id === id)
  }

  function findQuestionCard(itemReference) {
    return questionCards.value.find((card) => card.content.content === itemReference)
  }

  function setQuestionAttempted(itemReference) {
    const questionCard = findQuestionCard(itemReference)
    const exists = state.attemptedQuestionCardIds.includes(questionCard.id)
    if (!exists) state.attemptedQuestionCardIds.push(questionCard.id)
  }

  const assignmentViewId = computed(() => get(assignmentView.value, 'id'))

  function updateLastAnsweredAt() {
    const { assignmentId } = useAssignmentStore()
    return AssignmentShowService.updateAssignmentView(assignmentId, assignmentViewId, { lastAnsweredAt: new Date() })
  }

  const lastAnsweredAtDelay = computed(() => (isLiveLearn.value ? 1500 : 10000))
  const updateViewedAllCards = debounce(updateLastAnsweredAt, lastAnsweredAtDelay.value, { leading: true })
  function viewedAllCards() {
    if (assignmentViewId.value) {
      updateViewedAllCards()
    }
  }

  const saveQuestionResponse = debounce(
    async function (_question, { itemsApp }) {
      if (!assignmentViewId.value) return

      try {
        // This will fail if the assignment is not in opened state, meaning that
        // the student is not allowed to change answers at this point.
        await updateLastAnsweredAt()
        return new Promise((resolve, reject) => {
          const submitSettings = {
            success: resolve,
            error: reject,
          }
          itemsApp.submit(submitSettings)
        }).then(() => {
          return itemsApp
        })
      } catch {
        itemsApp.suppressReloadSiteConfirmation = true
        $location.reload()
      }
    },
    lastAnsweredAtDelay.value,
    { leading: true, maxWait: 30000 },
  )

  const { on, off } = useNamespacedEventListener(window)
  on('beforeunload.flushquestions', () => saveQuestionResponse.flush())
  const allowNavigationAway = async () => {
    off('beforeunload.flushquestions')
    const itemsApp = await saveQuestionResponse.flush()
    if (itemsApp) {
      itemsApp.suppressReloadSiteConfirmation = true
    }
  }

  async function submitCards() {
    if (!assignmentViewId.value) return

    const { assignmentId } = useAssignmentStore()
    await AssignmentShowService.updateAssignmentView(assignmentId, assignmentViewId, { submitted: true })
    setAssignmentViewSubmitted(true)
  }

  function learnosityConfigFor(itemReference) {
    if (lrnoAssessmentConfig.value) return lrnoAssessmentConfig.value
    const questionCard = findQuestionCard(itemReference)
    if (questionCard) {
      return questionCard.content.lrnoPreviewConfig
    }
    return null
  }

  return {
    state,
    questionCards,
    assignmentCards,
    lessonCards,
    setQuestionAttempted,
    saveQuestionResponse,
    submitCards,
    learnosityConfigFor,
    viewedAllCards,
    getAssignmentCardPosition,
    hasAssignmentCards,
    hasLessonCards,
    assignmentDisplayCards,
    lessonDisplayCards,
    assignmentDisplayCardsPosition,
    selectedAssignmentCardNotes: computed(() => {
      const selectedCard = state.cards.find((card) => card.id === state.selectedAssignmentCardId)
      if (selectedCard === undefined) return null

      if (selectedCard.isBeginLessonCard) {
        return selectedCard.notes
      }
      return selectedCard.teachingNotes ? selectedCard.teachingNotes.content : ''
    }),
    hasQuestionCards: computed(() => questionCards.value.length > 0),
    questionCardsCount: computed(() => questionCards.value.length),
    attemptedQuestionCardsCount: computed(() => state.attemptedQuestionCardIds.length),
    allowNavigationAway,
  }
})
