import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

export default {
  getSessionResponses(lzCode, sessionId) {
    const url = Routes.grading_session_responses_path(lzCode, sessionId)

    return HTTP.getJSON(url)
  },

  updateSessionResponses(lzCode, sessionId, responses) {
    const url = Routes.grading_session_responses_path(lzCode, sessionId)

    return HTTP.put(url, snakeKeys(responses))
  },

  updateSessionResponseComment(lzCode, sessionId, learnosityResponseId, comment, publish) {
    const url = Routes.grading_session_response_comment_path(lzCode, sessionId, learnosityResponseId)

    return HTTP.put(
      url,
      snakeKeys({
        comment: {
          id: comment.id,
          content: comment.content,
          publish,
        },
      }),
    )
  },
}
