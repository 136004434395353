<template>
  <!-- content sanitized in serializer -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-mathjax :class="`html-resource ck-editable font-${textStyle}`" v-html="content" />
</template>

<script>
export default {
  name: 'HtmlViewer',
  props: {
    content: {
      type: String,
      required: true,
    },
    textStyle: {
      type: String,
      required: true,
    },
  },
}
</script>
