import KlassesIndexService from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export const useKlassesIndexStore = defineStore('klassesIndex', () => {
  const state = reactive({
    activeKlassesData: {
      klasses: [],
      meta: { pagination: { totalCount: 0 } },
    },
    archivedKlassesData: {
      klasses: [],
      meta: { pagination: { totalCount: 0 } },
    },
    newlyActiveKlasses: [],
    newlyArchivedKlasses: [],
  })

  const klassDataOfType = computed(() => (type) => {
    if (isActive(type)) {
      return state.activeKlassesData
    } else {
      return state.archivedKlassesData
    }
  })
  const klassesOfType = computed(() => (type) => klassDataOfType.value(type).klasses)
  const klassTotalOfType = computed(() => (type) => klassDataOfType.value(type).meta.pagination.totalCount)

  function setKlassesData({ klassesData, klassType }) {
    const currentKlassesData = state[`${klassType}KlassesData`]
    currentKlassesData.klasses.push(...klassesData.klasses)
    currentKlassesData.meta = klassesData.meta
  }

  function loadMoreKlasses({ klassType, nextPage }) {
    const excludedKlasses = isActive(klassType) ? state.newlyActiveKlasses : state.newlyArchivedKlasses,
      includedKlasses = isActive(klassType) ? state.newlyArchivedKlasses : state.newlyActiveKlasses,
      excludedIds = excludedKlasses.map((k) => k.id),
      includedIds = includedKlasses.map((k) => k.id)

    return KlassesIndexService.getKlasses({ klassType, nextPage, excludedIds, includedIds }).then((klassesData) => {
      setKlassesData({ klassesData, klassType })
    })
  }

  function createKlass({ klassName }) {
    return KlassesIndexService.createKlass({ klassName }).then((klassData) => {
      state.activeKlassesData.klasses.unshift(klassData)
      state.activeKlassesData.meta.pagination.totalCount++
      return klassData
    })
  }

  function updateKlassName({ klass, name }) {
    return KlassesIndexService.updateKlassName({ klass, name }).then((klassData) => {
      const klasses = klassData.active ? state.activeKlassesData.klasses : state.archivedKlassesData.klasses
      const klassIndex = klasses.findIndex((klass) => klass.id === klassData.id)
      if (klassIndex > -1) {
        klasses.splice(klassIndex, 1, klassData)
      }
    })
  }

  function deleteKlass(klass) {
    return KlassesIndexService.deleteKlass({ klass }).then(() => {
      const klasses = klass.active ? state.activeKlassesData.klasses : state.archivedKlassesData.klasses
      const index = klasses.indexOf(klass)
      klasses.splice(index, 1)
      klass.active
        ? state.activeKlassesData.meta.pagination.totalCount--
        : state.archivedKlassesData.meta.pagination.totalCount--
    })
  }

  function archiveKlass(klass) {
    return KlassesIndexService.archiveKlass(klass).then(() => {
      klass.active = false

      const index = state.activeKlassesData.klasses.indexOf(klass)
      state.activeKlassesData.klasses.splice(index, 1)

      state.archivedKlassesData.klasses.unshift(klass)

      state.archivedKlassesData.meta.pagination.totalCount++
      state.activeKlassesData.meta.pagination.totalCount--

      if (state.newlyActiveKlasses.includes(klass)) {
        state.newlyActiveKlasses.splice(state.newlyArchivedKlasses.indexOf(klass), 1)
      } else {
        state.newlyArchivedKlasses.push(klass)
      }
    })
  }

  function unarchiveKlass(klass) {
    return KlassesIndexService.unarchiveKlass(klass).then(() => {
      klass.active = true

      const index = state.archivedKlassesData.klasses.indexOf(klass)
      state.archivedKlassesData.klasses.splice(index, 1)

      state.activeKlassesData.klasses.unshift(klass)

      state.activeKlassesData.meta.pagination.totalCount++
      state.archivedKlassesData.meta.pagination.totalCount--

      if (state.newlyArchivedKlasses.includes(klass)) {
        state.newlyArchivedKlasses.splice(state.newlyArchivedKlasses.indexOf(klass), 1)
      } else {
        state.newlyActiveKlasses.push(klass)
      }
    })
  }

  return {
    state,
    klassDataOfType,
    klassesOfType,
    klassTotalOfType,
    loadMoreKlasses,
    createKlass,
    updateKlassName,
    deleteKlass,
    archiveKlass,
    unarchiveKlass,
  }
})
