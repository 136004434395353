// This file consolidates inclusion of ckeditor plugins in one place
// Using require instead of import for continuity use of require.context below
require('ckeditor4/plugins/dialogadvtab/plugin')

require('ckeditor4/plugins/font/plugin')
require('ckeditor4/plugins/font/lang/en')

require('ckeditor4/plugins/image/dialogs/image')

require('ckeditor4/plugins/indentblock/plugin')

require('ckeditor4/plugins/justify/plugin')

require('ckeditor4/plugins/link/dialogs/anchor')
require('ckeditor4/plugins/link/dialogs/link')

require('ckeditor4/plugins/mathjax/plugin')
require('ckeditor4/plugins/mathjax/dialogs/mathjax')
require('ckeditor4/plugins/mathjax/lang/en')

require('ckeditor4/plugins/pastetools/filter/common')
require('ckeditor4/plugins/pastefromword/filter/default')

require('ckeditor4/plugins/sharedspace/plugin')

require('ckeditor4/plugins/sourcedialog/plugin')
require('ckeditor4/plugins/sourcedialog/dialogs/sourcedialog')
require('ckeditor4/plugins/sourcedialog/lang/en')

require('ckeditor4/plugins/specialchar/dialogs/specialchar')
require('ckeditor4/plugins/specialchar/dialogs/lang/en')

require('ckeditor4/plugins/table/dialogs/table')

require('ckeditor4/plugins/scayt/dialogs/options')

require('./plugins/lzstandards/plugin')
require('./plugins/lzstandards/plugin/lang/en')

require('./plugins/inputplaceholder/plugin')
require('./plugins/inputplaceholder/lang/en')

require('./plugins/lztable/plugin')
require('./plugins/lztable/lang/en')

require('./plugins/olstart/dialogs/olstart')
require('./plugins/olstart/plugin')
require('./plugins/olstart/lang/en')

// Make static assets available for dynamic CKEditor requests
require.context('ckeditor4/plugins', true, /\.(css|gif|jpe?g|png)$/)
require.context('ckeditor4/skins/moono-lisa/', true, /\.(css|gif|jpe?g|png)$/)
require.context('ckeditor_extensions/', true, /\.(css|gif|jpe?g|png)$/)

// For some reason these language files need to be copied statically
require.context('ckeditor4/plugins/specialchar/dialogs/lang/', false, /\.js$/)
