<template>
  <Badge v-if="teacherOnly" color="yellow">
    {{ $t('common.teacher_only') }}
  </Badge>
</template>

<script>
import { Badge } from 'vue_features/shared/components/ui'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'LzContentTeacherOnlyBadge',
  components: { Badge },
  props: {
    teacherOnly: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { authorizedAsTeacher } = useCurrentUserStore()
    return { authorizedAsTeacher }
  },
}
</script>
