<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="date-select" :class="containerClass">
    <label :style="labelStyle">{{ label }}</label>
    <div class="relative">
      <FlatPickr
        ref="datepicker"
        v-model="date"
        :placeholder="$t('common.select_date')"
        :config="config"
        :disabled="disabled"
        class="pl-11"
        @on-change="onDateChange"
      />
      <div v-if="!hideInput" class="date-picker_icon" @click="showCalendar">
        <InlineSvg path="icons/calendar-add" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'
import FlatPickr from 'vue-flatpickr-component'
import en from 'flatpickr/dist/l10n/default'
import pt from 'flatpickr/dist/l10n/pt'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common } from 'vue_features/shared/i18n'

export default {
  components: { InlineSvg, FlatPickr },
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: ['select_date'] }) },
  props: {
    modelValue: {
      type: Date,
      required: true,
    },
    minDate: {
      type: [Date, String],
      default: '',
    },
    maxDate: {
      type: [Date, String],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableTime: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    minuteIncrement: {
      type: Number,
      default: 5,
    },
    locale: {
      type: String,
      default: 'en',
    },
  },

  setup(props) {
    const date = computed({
      get() {
        return props.modelValue ? props.modelValue.getTime() : null
      },
      // no-op as we call onDateChange instead
      set() {},
    })
    return { date }
  },

  computed: {
    altFormat() {
      // FIXME: If we add support for other languages, or EN localizations that format dates differently from US standard, this may need to be tweaked.
      const dateFormat = this.locale.startsWith('en') ? 'm/d/Y' : 'd/m/Y'
      return this.enableTime ? `${dateFormat} H:i` : dateFormat
    },
    config() {
      return {
        altInput: true,
        altFormat: this.altFormat,
        dateFormat: this.enableTime ? 'Y-m-d H:i:S' : 'Y-m-d',
        ariaDateFormat: this.enableTime ? 'Y-m-d H:i:S' : 'Y-m-d',
        enableTime: this.enableTime,
        defaultDate: this.modelValue,
        inline: this.inline,
        minDate: this.minDate || undefined,
        maxDate: this.maxDate || undefined,
        minuteIncrement: this.minuteIncrement,
      }
    },
    language() {
      const dateLocale = this.props.locale.replace(/-\w{2}$/, '')
      return { pt, en }[dateLocale]
    },
    containerClass() {
      const onlyCalendar = this.inline && this.hideInput
      return {
        'rounded-lg': onlyCalendar,
        'border-2': onlyCalendar,
        'border-primary-200': onlyCalendar,
        'p-4': onlyCalendar,
      }
    },
    labelStyle() {
      if (this.inline && this.hideInput) {
        return {
          display: 'block',
          textAlign: 'center',
        }
      }
      return {}
    },
  },

  mounted() {
    if (this.hideInput) {
      const input = this.$refs.datepicker.$el.nextElementSibling
      input.style.display = 'none'
    }
  },

  methods: {
    showCalendar() {
      this.$refs.datepicker.fp.toggle()
    },
    onDateChange([date], dateStr) {
      if (date) {
        this.$emit('update:modelValue', date, dateStr)
      }
    },
  },
}
</script>

<style>
@import url('flatpickr/dist/flatpickr.css');

.inline-calendar.hidden-input {
  @apply border-2;
  @apply border-solid;
  @apply rounded-2xl;
  @apply p-6;
}

/* Flatpickr overrides */
.flatpickr-calendar {
  @apply pb-2;
  @apply w-auto;
  max-width: 332px;
}

.flatpickr-calendar.inline {
  max-width: unset;
}

span.flatpickr-weekday {
  @apply text-base;
}

.flatpickr-day:not(.selected):not(.flatpickr-disabled) {
  @apply text-base;
}

.flatpickr-calendar.inline .flatpickr-innerContainer {
  @apply border-b-0;
}

.flatpickr-months {
  @apply flex;
  @apply flex-grow;
  @apply h-14;
}

.flatpickr-months .flatpickr-month {
  @apply h-14;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply shadow-none;
  @apply bg-primary-500;
  @apply text-white;
  @apply border-primary-500;
}

.flatpickr-day.prevMonthDay:not(.selected, .flatpickr-disabled),
.flatpickr-day.nextMonthDay:not(.selected, .flatpickr-disabled) {
  @apply text-gray-600;
}

.flatpickr-current-month {
  @apply h-10;
  @apply flex;
  @apply flex-row;
  @apply text-sm;
  @apply text-primary-500;
}

.numInputWrapper,
.flatpickr-time .numInputWrapper {
  @apply shadow-none;
  @apply rounded;
  @apply text-sm;
  @apply border-0;
  @apply ml-2;
}

.flatpickr-current-month .numInputWrapper {
  @apply flex;
  @apply w-24;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply text-sm;
  @apply shadow-none;
  @apply flex-grow;
  @apply rounded;
  @apply border;
  @apply border-solid;
  @apply border-gray-300;
}

.date-select-wrapper {
  @apply flex;
  @apply items-center;
  @apply mb-6;
}

.numInputWrapper input .cur-year {
  @apply flex;
  @apply flex-grow;
  @apply p-0;
  @apply pt-1;
  @apply pl-2;
  @apply border;
  @apply border-solid;
  @apply border-gray-300;
  @apply rounded;
}

.flatpickr-rContainer {
  @apply m-auto;
}

.flatpickr-innerContainer {
  @apply pb-2;
  @apply border-t-0;
  @apply border-r-0;
  @apply border-l-0;
  @apply border-b;
  @apply border-gray-300;
}

.numInputWrapper {
  @apply border;
  @apply border-solid;
  @apply border-gray-300;
  @apply shadow-none;
  @apply rounded;
}

.numInputWrapper span {
  @apply border-0;
}

.flatpickr-time {
  @apply inline;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  @apply pl-2;
}

.flatpickr-time .numInputWrapper {
  @apply w-1/6;
  @apply inline-block;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply p-4;
  @apply block;
  @apply static;
}

.flatpickr-current-month input.cur-year {
  @apply pl-2;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  @apply pt-2;
  @apply h-10;
  @apply flex;
  @apply flex-grow;
  @apply mx-16;
  @apply border-t-0;
  @apply leading-8;
}

.numInputWrapper span.arrowUp {
  @apply top-1;
}

.numInputWrapper span.arrowDown {
  @apply top-2/4;
}

.flatpickr-time .flatpickr-am-pm {
  @apply w-1/5;
  @apply ml-2;
  @apply p-0;
  @apply mt-0;
  @apply mb-0.5;
  @apply h-8;
  @apply border;
  @apply border-solid;
  @apply border-gray-300;
}

.flatpickr-time input.flatpickr-hour {
  @apply font-normal;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  @apply font-normal;
}

.flatpickr-time input {
  @apply border;
  @apply border-solid;
  @apply border-gray-300;
  @apply rounded;
  @apply h-8;
}
</style>
