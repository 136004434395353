<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div v-if="hasContent" class="listable-editor w-full min-w-0">
    <div class="menu-overlay" @click.prevent />
    <LzContentEmbed v-if="isEmbed" v-bind="content" class="content" />
    <LzContentList v-else :lz-contents="[content.lzContent]" :is-block="isBlock" class="content" />
  </div>
  <div v-else class="listable-editor min-w-0 flex-col">
    <p class="red">{{ $t('wikis.content_not_found', { id: content.id, type: startCase(content.type) }) }}</p>
    <p v-if="content.customer" class="red">{{ $t('wikis.revoked_content', { customer: content.customer }) }}</p>
  </div>
</template>

<script>
import { computed } from 'vue'
import { startCase } from 'lodash'
import LzContentEmbed from './LzContentEmbed'
import LzContentList from 'vue_features/lz_content/components/LzContentList'

export default {
  name: 'ListableEditor',
  components: { LzContentEmbed, LzContentList },
  props: {
    display: { type: String, required: true },
    content: { type: Object, default: () => null },
  },
  setup(props) {
    return {
      startCase,
      hasContent: computed(() => !props.content?.missing || !props.content?.id),
      isEmbed: computed(() => props.display === 'embed'),
      isBlock: computed(() => props.display === 'block'),
    }
  },
}
</script>
