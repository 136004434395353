<template>
  <div class="response-card relative mx-auto w-full space-y-4 rounded-2xl bg-white p-8 pb-12">
    <div class="flex items-center">
      <div class="flex-grow">
        <h2 class="m-0 text-lg font-bold">{{ item.number }}.{{ question.number }}</h2>
      </div>

      <div class="flex-none text-center">
        <button
          class="hover:bg-base group rounded-lg px-2 py-1"
          data-test="response-comment-toggle"
          @click="showComments = !showComments"
        >
          <IconBadge
            path="icons/comment"
            :badge="hasComment ? '1' : ''"
            icon-classes="text-base group-hover:text-important-hover"
            badge-classes="group-hover:bg-primary-accent bg-neutral-900 text-white"
          />
          <div class="group-hover:text-important-hover -mt-2 text-base text-sm font-semibold">{{
            showComments ? $t('hide_comments') : $t('comment')
          }}</div>
        </button>
      </div>
    </div>

    <ResponseCommentEditor v-if="showComments" :response="response" data-test="response-comment-editor" />

    <div v-show="loadingSession" class="bg-neutral-100 w-full rounded-lg px-4 py-8 text-center">
      <LoadingSpinner />
    </div>

    <div v-if="response" v-show="!loadingSession" ref="learnosityItemElement">
      <div :class="`question-${response.responseId}`" class="learnosity-response h-36 w-full" />
    </div>

    <div class="absolute left-0 top-10 -translate-x-1/2 -translate-y-1/2 transform">
      <div class="flex h-11 w-11 items-center justify-center rounded-full border-4" :class="indicator.classes">
        <LoadingSpinner v-if="loadingSession" />
        <LzIcon v-if="!loadingSession && indicator.icon" :path="indicator.icon" size="md" />
      </div>
    </div>

    <div v-if="!scoringHidden" class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 transform">
      <div
        class="border-3 flex items-center rounded-full border-white bg-white p-0.5"
        :class="shakeClasses"
        @animationend="stopShaking"
      >
        <fieldset
          :aria-label="
            $t('manual_score_label', {
              currentScore: response.score !== null ? response.score : $t('empty_score'),
              maxScore: response.maxScore,
            })
          "
          tabindex="0"
          class="flex flex-grow items-center focus-visible:ring-2 focus-visible:ring-blue-400"
        >
          <div class="group relative flex-none">
            <button
              class="text-gray-base group relative inline-flex w-20 items-center justify-center gap-1 rounded-full py-2 pl-2 pr-3 text-xs ring-offset-0 transition-colors focus-within:bg-red-100 focus-within:text-red-500 focus-within:ring-red-500"
              :class="lowestScoreButtonClasses"
              :disabled="scoringDisabled || response.maxScore === null || response.maxScore === 0"
              data-test="min-score-button"
              :aria-label="$t('give_min_score')"
              @click="onMinScoreButtonClick"
            >
              <LzIcon aria-hidden="true" path="icons/close" size="md" />
              <span class="text-sm font-bold">{{ $t('min') }}</span>
            </button>
            <div
              aria-hidden="true"
              class="bg-neutral-900 pointer-events-none absolute -top-8 left-1/2 min-w-max -translate-x-1/2 transform rounded px-1 py-0.5 text-xs font-bold text-white opacity-0 transition delay-700 duration-500 group-hover:translate-y-0 group-hover:opacity-100"
            >
              {{ $t('min_score') }}
            </div>
          </div>
          <div class="flex flex-grow items-center px-2">
            <label
              :aria-label="$t('enter_score', { maxScore: response.maxScore })"
              :for="`manual-score-input-${item.number}-${question.number}`"
              class="sr-only"
            >
              {{ $t('enter_score') }}
            </label>
            <input
              :id="`manual-score-input-${item.number}-${question.number}`"
              type="text"
              class="w-16 text-center"
              :class="updating ? 'cursor-wait' : scoringDisabled ? 'cursor-not-allowed' : 'cursor-auto'"
              :value="response.score"
              :disabled="scoringDisabled || response.maxScore === null"
              data-test="manual-score-input"
              @focus="(event) => event.target.select()"
              @change="(event) => onManuallyUpdatedScore(event)"
            />
            <span class="inline-block whitespace-nowrap px-1 text-gray-600">/</span>
            <span
              v-if="question.maxScore !== null"
              class="inline-block w-16 text-center font-bold"
              data-test="max-score-label"
              :aria-label="$t('out_of', { maxScore: response.maxScore })"
            >
              {{ response.maxScore }}
            </span>

            <span v-else>
              <label :for="`manual-max-score-input-${item.number}-${question.number}`" class="sr-only">
                {{ $t('custom_max_score') }}
              </label>
              <input
                :id="`manual-max-score-input-${item.number}-${question.number}`"
                type="text"
                class="w-16 text-center"
                :class="updating ? 'cursor-wait' : scoringDisabled ? 'cursor-not-allowed' : 'cursor-auto'"
                :value="response.maxScore"
                :disabled="scoringDisabled"
                data-test="manual-max-score-input"
                @focus="(event) => event.target.select()"
                @change="(event) => onManuallyUpdatedMaxScore(event)"
              />
            </span>
          </div>

          <div class="group relative flex-none">
            <button
              class="text-gray-base group inline-flex w-20 items-center justify-center gap-1 rounded-full py-2 pl-2 pr-3 ring-offset-0 transition-colors focus-within:bg-green-100 focus-within:text-green-500 focus-within:ring-green-500"
              :class="highestScoreButtonClasses"
              :disabled="scoringDisabled || response.maxScore === null || response.maxScore === 0"
              data-test="max-score-button"
              :aria-label="$t('give_max_score', { maxScore: response.maxScore })"
              @click="onMaxScoreButtonClick"
            >
              <LzIcon aria-hidden="true" path="icons/check" size="md" />
              <span class="text-sm font-bold">{{ $t('max') }}</span>
            </button>
            <div
              aria-hidden="true"
              class="bg-neutral-900 pointer-events-none absolute -top-8 left-1/2 min-w-max -translate-x-1/2 transform rounded px-1 py-0.5 text-xs text-white opacity-0 transition delay-700 duration-500 group-hover:translate-y-0 group-hover:opacity-100"
            >
              {{ $t('max_score') }}
            </div>
          </div>
        </fieldset>

        <div v-if="question.maxScore === null" class="absolute left-1/2 top-full -translate-x-1/2 transform">
          <label
            :for="`exclude_from_score-${item.number}-${question.number}`"
            class="flex cursor-pointer items-center space-x-2 whitespace-nowrap p-2"
          >
            <input
              :id="`exclude_from_score-${item.number}-${question.number}`"
              type="checkbox"
              class="m-0"
              :checked="response.maxScore === 0"
              :disabled="updating"
              @change="onExcludeFromScore"
            />
            <span class="font-semibold leading-6 text-white">{{ $t('exclude_from_score') }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { LzIcon, IconBadge, LoadingSpinner } from 'vue_features/shared/components/ui'
import { scoreThemeForResponse } from '../utils/score_theme'
import { useGradingStore } from '../composables/use_grading_store'
import ResponseCommentEditor from './ResponseCommentEditor'

export default {
  name: 'ResponseCard',
  components: { LzIcon, IconBadge, LoadingSpinner, ResponseCommentEditor },
  props: {
    item: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      default: null,
    },
    response: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { currentSession, loadingSession, updateResponseScores, currentUserTeachesKlass } = useGradingStore()

    const showComments = ref(false)
    const hasComment = computed(() => !!props.response.comment?.content)
    const updating = computed(() => props.response.updating)
    const scoreTheme = computed(() => scoreThemeForResponse(props.response, loadingSession.value))
    const responsePending = computed(
      () => !props.response || props.response.maxScore === null || loadingSession.value || updating.value,
    )

    const scoringDisabledClasses = ['text-gray-600', 'bg-gray-100']
    const scoringPendingClasses = ['text-gray-600', 'bg-gray-100']
    const lowestInactiveClasses = ['text-gray-600', ' hover:bg-red-100', ' hover:text-red-500 ']
    const highestInactiveClasses = ['text-gray-600', ' hover:bg-green-100', ' hover:text-green-500 ']
    const activeClasses = computed(() => [scoreTheme.value.backgroundColorClass, scoreTheme.value.textColorClass])

    const lowestScoreButtonClasses = computed(() => {
      if (scoringDisabled.value || props.response.maxScore === 0) {
        return [...scoringDisabledClasses, updating.value ? 'cursor-wait' : 'cursor-not-allowed']
      } else if (responsePending.value) {
        return scoringPendingClasses
      } else if (props.response.score === 0) {
        return activeClasses.value
      } else {
        return lowestInactiveClasses
      }
    })

    const highestScoreButtonClasses = computed(() => {
      if (scoringDisabled.value || props.response.maxScore === 0) {
        return [...scoringDisabledClasses, updating.value ? 'cursor-wait' : 'cursor-not-allowed']
      } else if (responsePending.value) {
        return scoringPendingClasses
      } else if (props.response.score === props.response.maxScore) {
        return activeClasses.value
      } else {
        return highestInactiveClasses
      }
    })

    const indicator = computed(() => {
      const scoreTheme = scoreThemeForResponse(props.response, loadingSession.value, true)
      const backgroundColorClass =
        scoreTheme.backgroundColorClass === 'bg-transparent' ? 'bg-white' : scoreTheme.backgroundColorClass

      return {
        classes: [scoreTheme.textColorClass, backgroundColorClass, scoreTheme.borderClass],
        icon: scoreTheme.icon,
      }
    })

    const onMinScoreButtonClick = () => {
      updateResponseScores(currentSession.value, props.response, { score: 0 })
    }

    const onMaxScoreButtonClick = () => {
      updateResponseScores(currentSession.value, props.response, { score: props.response.maxScore })
    }

    const onManuallyUpdatedScore = (event) => {
      const numericScore = Number(event.target.value)
      const scoreIsNaN = Number.isNaN(numericScore)
      const scoreIsNegative = numericScore < 0
      const scoreIsTooHigh = numericScore > props.response.maxScore

      if (scoreIsNaN || scoreIsNegative || scoreIsTooHigh) {
        event.target.value = props.response.score
        shake()
      } else {
        updateResponseScores(currentSession.value, props.response, { score: numericScore })
      }
    }

    const onManuallyUpdatedMaxScore = (event) => {
      const numericScore = Number(event.target.value)
      const maxScoreIsNaN = Number.isNaN(numericScore)
      const maxScoreIsNegative = numericScore < 0
      const maxScoreIsNotWhole = Math.floor(numericScore) !== numericScore
      const maxScoreIsTooLow = props.response.score !== null && numericScore < props.response.score

      if (maxScoreIsNaN || maxScoreIsNegative || maxScoreIsNotWhole || maxScoreIsTooLow) {
        event.target.value = props.response.maxScore
        shake()
      } else {
        updateResponseScores(currentSession.value, props.response, { maxScore: numericScore })
      }
    }

    const onExcludeFromScore = () => {
      const alreadyHidden = props.response.maxScore === 0

      if (alreadyHidden) {
        updateResponseScores(currentSession.value, props.response, { score: 1, maxScore: 1 })
      } else {
        updateResponseScores(currentSession.value, props.response, { score: 0, maxScore: 0 })
      }
    }

    const scoringHidden = computed(() => {
      return currentSession.value.assignmentState === 'opened'
    })

    const scoringDisabled = computed(() => {
      return (
        loadingSession.value ||
        updating.value ||
        currentSession.value.assignmentState === 'opened' ||
        !currentUserTeachesKlass.value
      )
    })

    const learnosityItemElement = ref(null)

    const shakeClasses = ref([])
    const shake = () => {
      shakeClasses.value = ['animate-shake animate-iterations-2']
    }
    const stopShaking = () => {
      shakeClasses.value = []
    }

    return {
      currentUserTeachesKlass,
      hasComment,
      highestScoreButtonClasses,
      indicator,
      learnosityItemElement,
      loadingSession,
      lowestScoreButtonClasses,
      onExcludeFromScore,
      onManuallyUpdatedMaxScore,
      onManuallyUpdatedScore,
      onMaxScoreButtonClick,
      onMinScoreButtonClick,
      scoringDisabled,
      scoringHidden,
      shakeClasses,
      showComments,
      stopShaking,
      updating,
    }
  },
}
</script>

<style scoped>
:disabled {
  cursor: not-allowed;
}
.response-card {
  min-width: 450px;
  max-width: 900px;
}
</style>
