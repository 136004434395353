<template>
  <LzModal
    modal-id="standards-selector-modal"
    class="lzui-modal--medium standards-selector-modal lz-cke_dialog"
    :autoclose="false"
    @close-modal="hideModal"
  >
    <StandardsSelector :standards="standards" @set-standards="setStandards" />

    <footer>
      <button name="cancel_button" class="btn" @click="hideModal">
        {{ $t('common.cancel') }}
      </button>

      <button class="btn btn--primary caution" name="confirm_button" @click="selectStandards">
        {{ $t('common.select') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { ref, inject } from 'vue'
import { merge } from 'lodash'
import LzModal from 'vue_features/shared/components/ui/LzModal'
import StandardsSelector from './StandardsSelector'

export default {
  name: 'StandardsSelectorModal',
  components: {
    LzModal,
    StandardsSelector,
  },

  props: {
    standards: {
      type: Array,
      required: true,
    },
    editor: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const root = inject('useRoot')()
    const selectedStandards = ref([])
    const selectStandards = () => {
      root.$rootEmit('select-standards', { standards: selectedStandards.value, editor: props.editor })

      hideModal()
    }

    const clearSelectedStandards = () => {
      selectedStandards.value = []
    }

    const hideModal = () => {
      clearSelectedStandards()

      root.$rootEmit('hide-modal', 'StandardsSelectorModal')
    }

    return { selectedStandards, selectStandards, clearSelectedStandards, hideModal }
  },

  methods: {
    setStandards(selectedStandards) {
      this.clearSelectedStandards()

      merge(this.selectedStandards, selectedStandards)
    },
  },
}
</script>
