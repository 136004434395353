import { computed } from 'vue'
import { sortBy } from 'lodash'
import buildSlideContent from './models/slide_content_factory'

export default function useSortedContents(props) {
  const sortedContents = computed(() => {
    const slideJSON = props.slideJson || props.resource.json || {}
    if (slideJSON.ordered) {
      return slideJSON.contents.map((c) => buildSlideContent(c))
    } else {
      return sortBy(slideJSON.contents, ['row', 'column']).map((c) => buildSlideContent(c))
    }
  })

  return { sortedContents }
}
