import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const CardsService = {
  create(lessonPlanId, params) {
    const url = Routes.lesson_plan_cards_path(lessonPlanId)

    return HTTP.create(url, snakeKeys(params))
  },

  update(card, params) {
    const url = Routes.card_path(card.id)

    return HTTP.put(url, snakeKeys(params))
  },

  destroy(card) {
    const url = Routes.card_path(card.id)
    return HTTP.delete(url)
  },

  reorder(lessonPlanId, cards) {
    const data = { context: 'lesson', cards: cards.map((c) => ({ id: c.id, rank: c.rank })) }
    const url = Routes.reorder_lesson_plan_cards_path(lessonPlanId)

    return HTTP.patch(url, data)
  },

  getTeachingNotes(cardId) {
    const url = Routes.card_teaching_notes_path(cardId)

    return HTTP.getJSON(url)
  },

  getCard(id) {
    const url = Routes.card_path(id)

    return HTTP.getJSON(url)
  },
}

export default CardsService
