<template>
  <CommentEditor :comment="comment" :is-returned="isReturned" @update="updateComment" @delete="deleteComment" />
</template>

<script>
import { computed } from 'vue'
import { useGradingStore } from '../composables/use_grading_store'
import CommentEditor from './CommentEditor'

export default {
  name: 'SessionCommentEditor',
  components: { CommentEditor },
  props: {
    session: { type: Object, default: () => {} },
  },
  setup(props) {
    const { updateSessionComment, deleteSessionComment, storeUpdatedSession } = useGradingStore()

    const comment = computed(() => props.session?.comment)
    const isReturned = computed(() => props.session?.assignmentState === 'returned')

    const updateComment = async (value, publish, done) => {
      const session = props.session

      try {
        await updateSessionComment(session, value, publish)
        done()
      } catch (e) {
        storeUpdatedSession(session)
        done(false)
      }
    }
    const deleteComment = async (id, done) => {
      try {
        await deleteSessionComment(props.session, id)
        done()
      } catch (e) {
        storeUpdatedSession(props.session)
        done(false)
      }
    }

    return { comment, isReturned, updateComment, deleteComment }
  },
}
</script>
