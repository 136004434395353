<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <LzButton
    v-if="actions.length === 1"
    tag="a"
    :aria-label="ariaLabelForAction(primaryAction)"
    :event-object="eventObjectForAction(primaryAction)"
    @click="$emit('click', primaryAction.label)"
  >
    <LzIcon v-if="iconForAction(primaryAction)" :path="iconForAction(primaryAction)" />
    <slot name="primary-action-label" :text="primaryAction.label">
      {{ primaryAction.label }}
    </slot>
  </LzButton>
  <SplitActionDropdown
    v-else
    :id="$attrs.id"
    :event-object="eventObjectForAction(primaryAction)"
    :button-label="primaryAction.label"
    :button-icon="iconForAction(primaryAction)"
    :button-aria-label="ariaLabelForAction(primaryAction)"
    :dropdown-button-classes="dropdownButtonClasses"
    :dropdown-classes="dropdownClasses"
    @button-click="$emit('click', primaryAction.label)"
    @split-button-click="$emit('split-button-click')"
  >
    <template #label="{ buttonLabel }">
      <slot name="primary-action-label" :text="buttonLabel">
        {{ buttonLabel }}
      </slot>
    </template>
    <template #dropdownListItems>
      <li v-for="action in otherActions" :key="action.label">
        <a
          v-track-click.once="eventObjectForAction(action)"
          :aria-label="ariaLabelForAction(action)"
          @click="$emit('click', action.label)"
        >
          <LzIcon v-if="iconForAction(action)" :path="iconForAction(action)" aria-hidden="true" />
          {{ action.label }}
        </a>
      </li>
    </template>
  </SplitActionDropdown>
</template>

<script>
import { LzIcon, LzButton } from 'vue_features/shared/components/ui'
import { trackClick } from 'vue_features/shared/directives'
import SplitActionDropdown from 'vue_features/shared/components/ui/dropdowns/SplitActionDropdown'

export default {
  name: 'SplitActionButton',
  components: { LzIcon, LzButton, SplitActionDropdown },
  directives: { trackClick },
  props: {
    actions: {
      type: Array,
      required: true,
      validator: (value) => {
        const actionsAllHaveRequiredKeys = value.every((action) => Object.keys(action).includes('label'))
        return value.length > 0 && actionsAllHaveRequiredKeys
      },
    },
    dropdownButtonClasses: {
      type: Array,
      default: () => [],
    },
    dropdownClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    primaryAction() {
      return this.actions[0]
    },

    otherActions() {
      return this.actions.slice(1)
    },
  },
  methods: {
    ariaLabelForAction(action) {
      return action.ariaLabel || ''
    },
    eventObjectForAction(action) {
      return action.eventObject || {}
    },
    iconForAction(action) {
      return action.icon || null
    },
  },
}
</script>
