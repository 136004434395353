import UserService from '../api/user'
import { camelCase, isString, isEmpty, isNull, isUndefined, noop, remove } from 'lodash'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { reactive, computed } from 'vue'
import Validator from './Validator'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

function deepInitialize(to, from) {
  Object.entries(from).forEach(([snakeKey, value]) => {
    const key = camelCase(snakeKey)

    if (isUndefined(to[key]) || isUndefined(value) || isNull(value)) return

    if (value.constructor === Object) {
      deepInitialize(to[key], value)
    } else if (!isNull(to[key]) && to[key].constructor === Array && value.constructor === String) {
      try {
        to[key] = JSON.parse(value)
      } catch (e) {
        noop()
      }
    } else {
      to[key] = value
    }

    if (!isNull(to[key]) && to[key].constructor === Array) {
      remove(to[key], (item) => isEmpty(item))
    }
  })
}

export function initUsersEditStore(initState) {
  const { $reset, state } = useUsersEditStore()
  const profile = initState.user.profile
  $reset()
  // TODO: review the need for deepInitialize() over $set()
  deepInitialize(state, initState)
  if (!profile) state.user.profile.feedbackOptin = false
  else if (isString(profile.feedbackOptin)) state.user.profile.feedbackOptin = profile.feedbackOptin === 'true'
}

export const useUsersEditStore = defineStore('usersEdit', () => {
  const state = reactive({
    redirect: '',
    errors: {},
    afterSignup: false,
    googleClassroomEmail: '',
    user: {
      name: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      username: '',
      preventUserChanges: false,
      profile: {
        feedbackOptin: true,
        studentFacingName: '',
      },
    },
  })

  const profile = computed(() => state.user.profile)

  function clearErrors() {
    state.errors = {}
  }

  function addErrors(errors) {
    Object.entries(errors).forEach(([key, value]) => {
      if (!state.errors[key]) {
        state.errors[key] = {}
      }

      if (key === 'server') return

      Object.entries(value).forEach(([k, v]) => {
        if (Number.isInteger(parseInt(k))) k = 'server'
        state.errors[key][k] = v
      })
    })
  }

  function update() {
    if (state.afterSignup) {
      state.user.afterSignup = true
    }

    const { firstName, middleName, lastName, email, profile } = state.user
    const params = { firstName, middleName, lastName, email, profile }
    UserService.update(params)
      .then((response) => {
        state.redirect = response.redirect
      })
      .catch((response) => {
        if (!response.responseJSON) return

        const errors = response.responseJSON.errors || {}
        errors.server = 'generic'
        addErrors(errors)
      })
  }

  function updateProfileFields(payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state.user.profile[key] = isString(value) ? value.trim() : value
    })
  }

  function updateUserFields(payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state.user[key] = isString(value) ? value.trim() : value
    })
  }

  const updateFirstName = (firstName) => updateUserFields({ firstName })
  const updateMiddleName = (middleName) => updateUserFields({ middleName })
  const updateLastName = (lastName) => updateUserFields({ lastName })
  const updateEmail = (email) => updateUserFields({ email })
  const updateStudentFacingName = (studentFacingName) => updateProfileFields({ studentFacingName })
  const updateFeedbackOptin = (feedbackOptin) => updateProfileFields({ feedbackOptin })

  const usersEditValidator = new Validator({
    rules: {
      firstName: {
        presence: true,
        maxlength: 255,
      },
      middleName: {
        maxlength: 255,
      },
      lastName: {
        presence: true,
        maxlength: 255,
      },
      email: {
        presence: true,
        maxlength: 255,
        emailAddress: true,
      },
      studentFacingName: {
        maxlength: 255,
      },
    },
  })

  const validateFirstName = () =>
    usersEditValidator.validate('firstName', state.user.firstName, (errors) => addErrors({ firstName: errors }))
  const validateMiddleName = () =>
    usersEditValidator.validate('middleName', state.user.middleName, (errors) => addErrors({ middleName: errors }))
  const validateLastName = () =>
    usersEditValidator.validate('lastName', state.user.lastName, (errors) => addErrors({ lastName: errors }))
  const validateEmail = () => {
    if (useCurrentUserStore().authorizedAsTeacher.value) {
      usersEditValidator.validate('email', state.user.email, (errors) => addErrors({ email: errors }))
    }
  }

  const validateStudentFacingName = () => {
    if (useCurrentUserStore().authorizedAsTeacher.value) {
      usersEditValidator.validate('studentFacingName', state.user.profile.studentFacingName, (errors) =>
        addErrors({ studentFacingName: errors }),
      )
    }
  }

  function validate() {
    validateFirstName()
    validateMiddleName()
    validateLastName()
    validateEmail()
    validateStudentFacingName()
  }

  return {
    state,
    profile,
    clearErrors,
    addErrors,
    update,
    updateFirstName,
    updateMiddleName,
    updateLastName,
    updateEmail,
    updateStudentFacingName,
    updateFeedbackOptin,
    validate,
    validateFirstName,
    validateMiddleName,
    validateLastName,
    validateEmail,
    validateStudentFacingName,
  }
})
