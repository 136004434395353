<template>
  <div class="h-full flex-grow">
    <div class="document-viewer">
      <FilestackPreview
        :url="baseUrl"
        :filestack-options="filestackOptions"
        :title="previewTitle"
        class="document-viewer print:hidden"
      />
    </div>
    <DownloadButton v-bind="{ customerId, downloadFileUrl, resourceJson, downloadedEvent }" />
  </div>
</template>

<script>
import FilestackPreview from './FilestackPreview'
import { DownloadButton, downloadButtonProps } from 'vue_features/resources'

const { customerId, downloadFileUrl, resourceJson, downloadedEvent } = downloadButtonProps

export default {
  name: 'DocumentViewer',
  components: {
    FilestackPreview,
    DownloadButton,
  },
  inheritAttrs: false,
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    filestackOptions: {
      type: Object,
      required: true,
    },
    customerId,
    downloadFileUrl,
    resourceJson,
    downloadedEvent,
  },
  computed: {
    previewTitle() {
      const { type, name } = this.resourceJson
      return `${type} ${name}`
    },
  },
}
</script>
