<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="col-span-6 md:col-span-7">
    <template v-if="cards.length > 1">
      <p class="small text-muted mb-0">
        {{ $t('lesson_plan.progress', { current_card: currentPosition + 1, total_cards: totalCount }) }}
      </p>
      <ListDropdown
        id="lp-outline"
        :button-classes="['lp-deck__slide-title mbs space-x-2']"
        :dropdown-classes="['lp-deck__outline']"
        default-btn-class=""
        class="w-full"
      >
        <template #button>
          <span class="card-title">{{ cardTitle(currentCard) }}</span>
          <LzContentStateBadge v-if="currentCardContent && !cardActive" class="ml-2" :state="currentCardState" />
          <LzIcon :path="'icons/caret'" type="div" />
        </template>
        <template #listItems>
          <li
            v-for="(card, index) in cards"
            :key="card.id"
            :class="{
              active: index === currentPosition,
              'lp-outline__title': isTitleCard(card),
              'meta-hide': isCardHidden(card),
            }"
            @click.prevent="$emit('set-current-position', index)"
          >
            <a href="#">
              <span>{{ cardTitle(card) }}</span>
            </a>
          </li>
        </template>
      </ListDropdown>
    </template>
    <div v-else class="lp-deck__slide-title mb-2">
      <span>{{ cardTitle(currentCard) }}</span>
      <LzContentStateBadge
        v-if="currentCardContent && !cardActive"
        class="ml-2 text-xs text-white"
        :state="currentCardState"
      />
    </div>
  </div>
</template>

<script>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import useCardActiveEdit from 'vue_features/lesson_plans/edit/composables/use_card_active_edit'
import { computed, inject } from 'vue'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'

export default {
  name: 'CardsDropdown',
  components: { ListDropdown, LzContentStateBadge, LzIcon },
  props: {
    cards: { type: Array, required: true },
    currentPosition: { type: Number, required: true },
    totalCount: { type: Number, required: true },
  },
  setup(props) {
    const root = inject('useRoot')()
    const currentCard = computed(() => props.cards[props.currentPosition])
    const isCardHidden = (card) => useCardActiveEdit({ value: card.activeEdit }).isHidden.value
    const cardTitle = (card) => (isCardHidden(card) ? `${card.title} (${root.$t('common.hidden')})` : card.title)
    const currentCardContent = computed(() => currentCard.value.content)
    const currentCardState = computed(() => (currentCardContent.value ? currentCardContent.value.state : null))
    const cardActive = computed(() => currentCardState.value === 'active')
    return { currentCard, isCardHidden, cardTitle, isTitleCard, cardActive, currentCardState, currentCardContent }
  },
}
</script>
