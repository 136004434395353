<template>
  <div id="html-edit" class="ck-editable">
    <LabeledEditor
      ckeditor-presets="richText"
      :content="content"
      form-field-name="html[content]"
      :label="$t('htmls.rich_text')"
      :content-area-classes="['min-h-64']"
      @update-content="updateContent"
    />
  </div>
</template>

<script>
import LabeledEditor from 'vue_features/shared/components/LabeledEditor'

export default {
  name: 'HtmlEdit',
  components: { LabeledEditor },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  computed: {
    content() {
      return this.resource.content || ''
    },
  },
  methods: {
    updateContent(newContent) {
      this.$emit('update', { content: newContent })
    },
  },
}
</script>
