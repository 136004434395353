<template>
  <div>
    <ProgressCover :visible="isProgressCoverDisplayed" />
    <OfflineNotice :show-overlay="false" />
    <EditBanner
      v-if="isRevision"
      :original-id="lessonPlanId"
      :object-id="objectId"
      :object-name="longType"
      :state="lessonState"
      object-type="LessonPlan"
    />
    <StateChanger v-else />
    <PageHead />
    <div class="page-head-nav">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <LzTabs :links="links" />
      </div>
    </div>
    <div class="page-content">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <RouterView v-slot="{ Component }">
          <KeepAlive>
            <Component :is="Component" />
          </KeepAlive>
        </RouterView>
      </div>
    </div>

    <Commentable v-if="isDraft" :commentable-path="commentablePath" />
  </div>
</template>

<script>
import { capitalize } from 'lodash'
import PageHead from './PageHead'
import StateChanger from './StateChanger'
import LzTabs from 'vue_features/shared/components/ui/tabs/LzTabs'
import ProgressCover from 'vue_features/shared/components/ui/loaders/ProgressCover'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import Commentable from 'vue_features/shared/components/Commentable'
import { computed, inject } from 'vue'
import Routes from 'routes'
import OfflineNotice from 'vue_features/assignments/show/components/OfflineNotice'
import EditBanner from 'vue_features/shared/components/forms/EditBanner'

export default {
  name: 'LessonPlanEdit',
  components: { OfflineNotice, ProgressCover, LzTabs, PageHead, StateChanger, Commentable, EditBanner },
  setup() {
    const root = inject('useRoot')()
    const { isDraft, lessonType, isRevision, lessonState, lessonPlanId: objectId, originalId } = useLessonPlanStore()
    const { basePath, isProgressCoverDisplayed, isCreatingMetaEdits } = useLessonPlanEditStore()
    const shortenedType = computed(() => root.$t(`lesson_plans.readable_types.shortened.${lessonType.value}`))
    const longType = computed(() => root.$t(`lesson_plans.readable_types.${lessonType.value}`))

    const lessonPlanId = originalId.value || objectId.value
    const links = computed(() => {
      const value = [
        {
          name: 'lesson',
          label: capitalize(longType.value),
          default: true,
          params: { id: lessonPlanId },
        },
        {
          name: 'additional_materials',
          label: root.$t('show.additional_materials'),
          params: { id: lessonPlanId },
        },
      ]
      if (!isCreatingMetaEdits.value) {
        value.push({
          name: 'description',
          label: root.$t('show.about_this_lesson', {
            lesson_type: shortenedType.value,
          }),
          params: { id: lessonPlanId },
        })
      }
      return value
    })

    const commentablePath = computed(() => Routes.lesson_plan_path(lessonPlanId))

    return {
      basePath,
      isDraft,
      isProgressCoverDisplayed,
      lessonPlanId,
      links,
      isRevision,
      objectId,
      longType,
      lessonState,
      commentablePath,
    }
  },
}
</script>
