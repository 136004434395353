import { ref, onUnmounted, onBeforeMount } from 'vue'
import { createClient, getUploadOptions, pickerOptions as getPickerOptions } from 'clients/filestack'
import { intersection } from 'lodash'
import { controllablePromise } from 'utils'

// The overlay of a picker with the displayMode 'dropPane' interfere with
// other pickers. When a default picker opens, we close the active drop pane picker.
// When a default picker closes, we reopen the drop pane picker.
const dropPanePickers = new Map()
const dropPanePickerFor = (uploader) => {
  return dropPanePickers.get(uploader)
}
const registerDropPanePicker = (uploader, picker) => {
  dropPanePickers.set(uploader, picker)
}
const unregisterDropPanePicker = (uploader) => dropPanePickers.delete(uploader)
const closeDropPanePicker = () => dropPanePickers.forEach((picker) => picker.close())
const openDropPanePicker = () => dropPanePickers.forEach((picker) => picker.open())

export default function useFilestackUploader({
  accept,
  maxSize,
  onUploadDone,
  dropPaneId,
  showDropPane,
  customPickerOptions = {},
}) {
  const setupPromise = controllablePromise()

  const uploadOptions = ref(null)
  const pickerOptions = ref(null)
  const client = ref(null)
  const fileDropContainer = `drop-pane-${dropPaneId}`
  let picker

  const launch = async () => {
    await setupPromise
    picker = client.value.picker(pickerOptions.value)
    picker.open()
  }

  const closePicker = () => {
    if (picker) {
      picker.close()
    }
    picker = undefined
  }

  const createDropPane = async () => {
    if (!dropPaneId) {
      throw Error('No drop pane id provided!')
    }

    if (dropPanePickerFor(dropPaneId)) {
      const dropPanePicker = dropPanePickerFor(dropPaneId)
      dropPanePicker.open()
    } else {
      await setupPromise
      const dropPaneOptions = {
        ...pickerOptions.value,
        displayMode: 'dropPane',
        container: fileDropContainer,
        dropPane: { showIcon: false },
        onClose: () => {},
        onOpen: () => {},
      }
      const dropPanePicker = client.value.picker(dropPaneOptions)
      await dropPanePicker.open()
      registerDropPanePicker(dropPaneId, dropPanePicker)
    }
  }

  let upload
  const uploadFile = async (file, callback = () => {}) => {
    if (canUpload(file)) {
      if (upload) {
        await upload
      }
      await completeUpload(file)
      callback(file)
    }
  }

  const canUpload = (file) => {
    return (
      file.size <= maxSize &&
      intersection(
        accept,
        file.type.split('/').map((t) => `.${t}`),
      ).length
    )
  }

  const completeUpload = async (file) => {
    await setupPromise
    upload = client.value.upload(file)
    const response = await upload
    file.filestackOptions = uploadOptions.value
    file.baseUrl = response.url
    file.metadata = response
    file.mimetype = response.mimetype
  }

  onBeforeMount(async () => {
    uploadOptions.value = await getUploadOptions({ accept, maxSize })
    pickerOptions.value = getPickerOptions({
      ...uploadOptions.value,
      onUploadDone,
      onClose: openDropPanePicker,
      onOpen: closeDropPanePicker,
      ...customPickerOptions,
    })
    client.value = await createClient(uploadOptions.value)
    setupPromise.resolve()

    if (showDropPane) {
      await createDropPane()
    }
  })

  onUnmounted(() => {
    unregisterDropPanePicker(dropPaneId)
  })

  return {
    fileDropContainer,
    pickerOptions,
    uploadOptions,
    launch,
    createDropPane,
    uploadFile,
    setupPromise,
    closePicker,
  }
}
