<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <Grid data-test="google-classroom-user-link" class="mx-auto pb-12">
    <div class="col-span-6 rounded border border-blue-200 bg-blue-50 p-6">
      <h5 class="text-xl">
        {{ $t('edit.google_classroom.title') }}
      </h5>
      <template v-if="profileEmail">
        <p data-test="google-classroom-user-link-existing" class="small mt-2">
          {{ $t('edit.google_classroom.connected_as') }}
        </p>
        <div class="my-6 inline-block space-y-4 rounded-md border border-blue-200 bg-white p-6">
          <h5 class="divide-y divide-solid">
            <LzIcon size="sm" path="icons/google-signin" />
            {{ profileName }}
          </h5>
          <p class="small">{{ profileEmail }}</p>
          <div class="flex items-center justify-between space-x-2">
            <p class="small">
              <a
                data-test="google-sync-data-button"
                :disabled="syncing"
                class="small btn cursor-pointer"
                @click="syncData"
              >
                <template v-if="syncing">
                  <LoadingSpinner :inline="true" tag="span" />
                </template>
                <template v-else>Sync Data</template>
              </a>
            </p>
            <a data-test="google-unlink-account-button" class="small btn cursor-pointer" @click="unlinkGoogleAccount">
              {{ $t('edit.google_classroom.unlink_action') }}
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="small mb-2 mt-4">{{ $t('edit.google_classroom.not_connected') }}</p>
        <LinkAccountButton
          v-slot="{ initAuthentication, scopesMissing, classroomDisabled }"
          @google-account-linked="reloadProfile"
        >
          <LzButton @click="initAuthentication">
            <LzIcon size="lg" path="icons/google-signin" />
            <span class="text-sm">{{ $t('common.google_classroom.link_action') }}</span>
          </LzButton>
          <div v-if="scopesMissing" class="text-orange-600 pt-4 text-sm">
            <LzIcon path="icons/exclamation" />
            {{ $t('common.google_classroom.try_linking_again') }}
          </div>
          <div v-else-if="classroomDisabled" class="text-orange-600 pt-4 text-sm">
            <LzIcon path="icons/exclamation" />
            {{ $t('common.google_classroom.disabled_by_admin') }}
          </div>
        </LinkAccountButton>
      </template>
      <i18n-t tag="p" keypath="common.google_classroom.usage_docs" scope="global" class="pt-6 text-xs">
        <template #link>
          <a href="/wikis/2492780-how-il-classroom-handles-google-classroom-user-data" target="_blank">
            {{ $t('common.here') }}
          </a>
        </template>
      </i18n-t>
    </div>
  </Grid>
</template>

<script>
import { LzButton, LzIcon, LoadingSpinner } from 'vue_features/shared/components/ui'
import LinkAccountButton from 'vue_features/shared/components/google_classroom/LinkAccountButton'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import Grid from 'vue_features/shared/components/ui/Grid'

export default {
  name: 'UserGoogleClassroomLink',
  components: { Grid, LzButton, LzIcon, LoadingSpinner, LinkAccountButton },
  setup() {
    const { profileEmail, profileName, loadProfile, unlinkProfile, syncGoogleClassroomData } = useGoogleClassroomStore()
    return {
      profileEmail,
      profileName,
      loadProfile,
      unlinkProfile,
      syncGoogleClassroomData,
    }
  },
  data() {
    return {
      syncing: false,
    }
  },
  mounted() {
    if (!this.profileEmail) {
      this.loadProfile()
    }
  },
  methods: {
    reloadProfile() {
      this.loadProfile({ ignoreError: true })
    },
    unlinkGoogleAccount() {
      this.unlinkProfile()
    },
    async syncData() {
      this.syncing = true
      try {
        await this.syncGoogleClassroomData()
      } finally {
        this.syncing = false
      }
    },
  },
}
</script>
