import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

const AuthoredLessonPlansService = {
  get(page) {
    const url = Routes.authored_lesson_plans_path()

    return HTTP.getJSON(url, { page })
  },
}

export default AuthoredLessonPlansService
