<script setup>
import { computed, useAttrs } from 'vue'
import Separator from 'vue_features/resources/shared/components/path_crumbs/Separator'

const props = defineProps({
  href: { type: String, default: '' },
  childId: { type: String, default: null },
  position: { type: Number, required: true },
  separator: { type: Boolean, default: true },
})
const { onClick } = useAttrs()
const clickable = computed(() => !!onClick)
const linked = computed(() => !!props.href)
const componentType = computed(() => {
  if (clickable.value) return 'button'
  return linked.value ? 'a' : 'span'
})
</script>

<template>
  <li
    class="inline-flex items-center pb-1 text-sm lg:truncate"
    itemprop="itemListElement"
    itemscope=""
    itemtype="http://schema.org/ListItem"
  >
    <component
      :is="componentType"
      :type="clickable ? 'button' : null"
      :href="linked ? href : null"
      itemprop="item"
      itemscope=""
      itemtype="http://schema.org/Thing"
      class="btn btn--small py-1 leading-6"
      :class="{ 'text-important truncate': linked }"
    >
      <span :id="childId" itemprop="name" :class="['text-accent', { 'flex items-center space-x-1': clickable }]">
        <slot />
      </span>
    </component>
    <meta itemprop="position" :content="position" />
    <Separator v-if="separator" style="filter: drop-shadow(1px 0 0 rgba(var(--primary-800), 0.5))" />
  </li>
</template>
