<template>
  <div>
    <InlineUploader :config="config" @uploaded="updateFile">
      <template #button="{ launch, isOpen }">
        <button id="ckeditor-upload-button" class="mx-auto mt-4" @click="launch">
          <template v-if="isOpen">
            {{ $t('cancel') }}
          </template>
          <template v-if="!isOpen && previewUrl">
            {{ $t('resource.replace_file') }}
          </template>
          <template v-if="!isOpen && !previewUrl">
            {{ $t('resource.select_file') }}
          </template>
        </button>
      </template>
    </InlineUploader>
    <img v-if="config === 'image' && previewUrl" :src="previewUrl" alt="Image preview" class="mx-auto mt-4" />
  </div>
</template>

<script>
import InlineUploader from './InlineUploader'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'
import { getReadOptions } from 'clients/filestack'
import { previewUrl } from 'ckeditor_extensions/upload'

export default {
  name: 'CkeditorUpload',
  components: { InlineUploader },
  props: {
    infoUrl: { type: Object, required: true },
    config: { type: String, required: true },
  },
  setup(props) {
    const updateFile = async (newFile) => {
      newFile.filestackOptions = await getReadOptions(newFile.baseUrl)
      const { fileUrl } = useFilestackAccessor(newFile)
      props.infoUrl.setValue(fileUrl.value)
      previewUrl.value = fileUrl.value
    }
    return { previewUrl, updateFile }
  },
}
</script>
