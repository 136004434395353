<template>
  <div id="data-dashboard">
    <BackToTop />

    <div class="page-head">
      <div class="page-head__wrapper">
        <div class="page-head__content">
          <h1 class="m-0">{{ $t('dashboard') }}</h1>
        </div>
      </div>

      <div class="page-head-nav">
        <div class="mx-auto mt-4 max-w-7xl px-3 md:px-6">
          <LzTabs class="lzui-nav-tabs vue-tabs">
            <TabLink
              v-track-click.sendBeacon.once="{ eventName: 'Data Dashboard UI tab clicked' }"
              data-test="data-dashboard-tab-link"
              target="dashboard"
              :label="$t('dashboard')"
              initial
            />
            <li class="text-center">
              <a
                v-track-click.sendBeacon.once="{ eventName: 'Item Analysis UI tab clicked' }"
                data-test="item-analysis-tab-link"
                class="relative"
                :href="itemAnalysisUrl"
              >
                <span>{{ $t('item_analysis.item_analysis') }}</span>
                <span class="absolute bottom-full right-0 inline-flex">
                  <NewBadge class="translate-x-2 translate-y-2 transform" display-until="2023-05-01" />
                </span>
              </a>
            </li>
            <li class="text-center">
              <a
                v-track-click.sendBeacon.once="{ eventName: 'Download Data tab clicked' }"
                data-test="download-data-tab-link"
                :href="dataDownloadUrl"
              >
                {{ $t('download_data') }}
              </a>
            </li>
          </LzTabs>
        </div>
      </div>
    </div>

    <RouterView />
  </div>
</template>

<script>
import { watch, onUnmounted } from 'vue'
import { LzTabs, TabLink, NewBadge } from 'vue_features/shared/components/ui'
import BackToTop from './BackToTop'
import { filtersFromParams } from '../utils'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { useDataDashboardStore } from '../composables/data_dashboard_store'
import { trackClick } from 'vue_features/shared/directives'
import { HELP_DATA_DASHBOARD_GUIDE_URL } from 'rails_constants'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'DataDashboard',
  components: {
    LzTabs,
    TabLink,
    BackToTop,
    NewBadge,
  },
  directives: { trackClick },
  setup() {
    const { reloadData, filters } = useDataDashboardStore()

    let stopRouteListener = null

    const router = useRouter()
    const route = useRoute()

    watch(filters, () => {
      reloadData()
    })

    let initialized = false
    stopRouteListener = router.afterEach((to) => {
      if (initialized || (route.query && Object.keys(route.query).length > 0)) {
        filters.value = filtersFromParams(to.query)
      } else {
        reloadData()
      }
      initialized = true
    })

    onUnmounted(() => {
      if (stopRouteListener) {
        stopRouteListener()
        stopRouteListener = null
      }
    })

    return {
      dataDownloadUrl: Routes.student_data_reports_path(),
      HELP_DATA_DASHBOARD_GUIDE_URL,
      itemAnalysisUrl: Routes.item_analysis_index_path(),
    }
  },
}
</script>
