import { ref, reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { isEmpty } from 'lodash'
import useLearnosityAuthorClient from './use_learnosity_author_client'

const authoringClient = ref(null)
let emptyQuestionError = 'Cannot be empty'

export const useLearnosityItemEditorStore = defineStore('learnosityItemEditor', () => {
  const state = reactive({
    isCreatingOrEditing: false,
    clientReady: false,
    editedItem: {
      type: 'LearnosityItem',
      learnosityItem: null,
      id: null,
      revisionId: null,
      reused: false,
      content: null,
      title: null,
      owner: null,
      learnosityItemUpdatedAt: null,
      editable: false,
      adaptation: false,
      scoringType: 'per-question',
      scoringRubricId: null,
      standards: [],
      questionBanks: [],
    },
    errors: [],
  })

  const item = computed(() => state.editedItem)
  const standards = computed(() => item.value.standards)
  const scoringType = computed(() => item.value.scoringType)
  const isItemEditable = computed(() => item.value.editable)
  const scoringRubricId = computed(() => item.value.scoringRubricId)
  const client = computed(() => authoringClient.value)

  const updateItem = (newItem) => {
    Object.keys(state.editedItem).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(newItem, key)) {
        state.editedItem[key] = newItem[key]
      }
    })
  }
  const updateClient = async (config) => {
    if (authoringClient.value) {
      authoringClient.value.destroy()
    }

    const newClient = config ? useLearnosityAuthorClient(config) : null

    if (newClient) {
      await newClient.loadApp()
    }

    authoringClient.value = newClient
  }

  const addError = (newError) => {
    state.errors.push(newError)
  }

  const resetErrors = () => {
    state.errors = []
  }

  const isItemEmpty = (item) => {
    return item.learnosityItem && isEmpty(item.learnosityItem.questions) && isEmpty(item.learnosityItem.features)
  }

  const setEmptyQuestionError = (msg) => (emptyQuestionError = msg)
  const checkEmptyQuestionError = () => {
    if (isItemEmpty(authoringClient.value.toItem())) {
      addError(emptyQuestionError)
    }
  }

  return {
    state,
    item,
    standards,
    scoringType,
    scoringRubricId,
    client,
    updateClient,
    isItemEditable,
    updateItem,
    isItemEmpty,
    addError,
    resetErrors,
    setEmptyQuestionError,
    checkEmptyQuestionError,
  }
})
