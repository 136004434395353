<!--
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="space-y-4">
    <div v-if="newUser.errors.length" class="lzui-tile border-red-200 bg-red-50 p-4">
      <strong class="mt-4">{{ $t('error_encountered') }}</strong>
      <ul>
        <li v-for="error in newUser.errors" :key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="space-y-4 md:flex md:gap-4 md:space-y-0">
      <div class="flex-grow">
        <label>
          <strong>{{ $t('common.first_name') }}</strong>
        </label>
        <input v-model="newUser.firstName" class="no-fullstory" type="text" data-test="first-name-input" />
      </div>
      <div class="flex-grow">
        <label>
          <strong>{{ $t('common.middle_name') }}</strong>
          <span class="weak">{{ $t('common.optional') }}</span>
        </label>
        <input v-model="newUser.middleName" class="no-fullstory" type="text" data-test="middle-name-input" />
      </div>
    </div>
    <div>
      <label>
        <strong>{{ $t('common.last_name') }}</strong>
      </label>
      <input v-model="newUser.lastName" class="no-fullstory" type="text" data-test="last-name-input" />
    </div>
    <div v-if="isStudent || user.username">
      <label>
        <strong>{{ $t('common.username') }}</strong>
      </label>
      <input v-model="newUser.username" class="no-fullstory" type="text" data-test="user-name-input" />
    </div>
    <div v-if="!isStudent || user.email">
      <label>
        <strong>{{ $t('common.email') }}</strong>
      </label>
      <input v-model="newUser.email" class="no-fullstory" type="text" />
    </div>
    <div>
      <label>
        <strong>
          {{ $t('label.role') }}
        </strong>
      </label>
      <select v-model="newUser.role" :disabled="isStudent">
        <option v-for="role in availableRoles" :key="role" :value="role">
          {{ $t(`common.${role}`) }}
        </option>
      </select>
    </div>
    <template v-if="newUser.isUpdatingPassword">
      <div>
        <label>
          <strong>{{ $t('label.new_password') }}</strong>
        </label>
        <input v-model="newUser.password" class="no-fullstory" type="password" />
      </div>
      <div>
        <label>
          <strong>
            {{ $t('label.confirm_new_password') }}
          </strong>
        </label>
        <input v-model="newUser.passwordConfirmation" class="no-fullstory" type="password" />
      </div>
    </template>
    <a v-if="!newUser.isUpdatingPassword" class="block" @click="editPassword">
      {{ $t('change_user_password') }}
    </a>
    <div>
      <label>{{ $t('lock_user_info') }}</label>
      <div>
        <SwitchToggle v-model="newUser.preventUserChanges" label-off="No" label-on="Yes" class="inline-flex" />
      </div>

      <AsyncContentLoader :loader="transaction">
        <button class="btn btn--small btn--primary float-right" @click="save">
          {{ $t('common.save') }}
        </button>
        <button class="btn btn--small float-right mr-2" @click="$emit('cancel')">
          {{ $t('common.cancel') }}
        </button>
      </AsyncContentLoader>
    </div>
  </div>
</template>

<script>
import { AsyncContentLoader, SwitchToggle } from 'vue_features/shared/components/ui'
import User from '../user'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'

export default {
  name: 'UserEditForm',
  components: { AsyncContentLoader, SwitchToggle },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { filterableRoles, updateUser } = useUsersIndexStore()
    return { filterableRoles, updateUser }
  },
  data() {
    const newUser = new User({
      firstName: this.user.firstName,
      middleName: this.user.middleName,
      lastName: this.user.lastName,
      role: this.user.role,
      email: this.user.email,
      username: this.user.username,
      preventUserChanges: this.user.preventUserChanges,
    })

    return {
      newUser,
      transaction: null,
    }
  },
  computed: {
    availableRoles() {
      return this.filterableRoles.filter((role) => this.roleAvailable(role))
    },
    isStudent() {
      return this.newUser.isStudent()
    },
  },
  methods: {
    editPassword() {
      this.newUser.isUpdatingPassword = true
    },
    roleAvailable(role) {
      return this.isStudent === (role === 'student')
    },
    save() {
      this.newUser.checkErrors(this.$t.bind(this))
      if (this.newUser.errors.length === 0) {
        this.transaction = this.updateUser({ userId: this.user.id, updateParams: this.newUser.attrs() }).then(
          () => {
            this.$emit('saved')
          },
          (response) => {
            this.newUser.errors = response.responseJSON.errors
          },
        )
      }
    },
  },
}
</script>
