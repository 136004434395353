<template>
  <div class="lz-content-modal__choose">
    <TabMenu class="border-base border-b px-3 md:px-6" @activated-tab="switchTab">
      <TabLink
        :initial="currentTab === 'choose-new'"
        :label="$t('lz_content_choose.new')"
        target="choose-new"
        data-test="new-tab-link"
      />
      <TabLink
        :initial="currentTab === 'choose-search'"
        :label="$t('lz_content_choose.search')"
        target="choose-search"
        data-test="search-tab-link"
      />
      <TabLink
        :initial="currentTab === 'choose-id'"
        :label="$t('lz_content_choose.by_url')"
        target="choose-id"
        data-test="id-tab-link"
      />
    </TabMenu>
    <NewForm class="my-4" />
    <IdForm class="my-4 px-3 md:px-6" />
    <SearchForm class="my-4 px-3 md:px-6" @manage-content="$emit('manage-content')" />
  </div>
</template>

<script>
import TabMenu from 'vue_features/shared/components/ui/tabs/TabMenu'
import TabLink from 'vue_features/shared/components/ui/tabs/TabLink'
import NewForm from './NewForm'
import IdForm from './IdForm'
import SearchForm from './SearchForm'
import { useLzContentModalStore } from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'

export default {
  name: 'ChooseDisplay',
  components: { TabLink, TabMenu, NewForm, IdForm, SearchForm },
  setup() {
    const { currentTab } = useLzContentModalStore()

    const switchTab = (newTab) => {
      currentTab.value = newTab
    }

    return { currentTab, switchTab }
  },
}
</script>
