<!--
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/alt-text  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div id="student-instructions">
    <h3>
      {{ $t('step_one_ensure_formatting') }}
    </h3>
    <p>
      {{ $t('student_csv_archival_instructions') }}
    </p>
    <div class="mb-2">
      <a>
        <ImageTag path="misc/csv_student_deletion_example.jpeg" style="width: 698px; height: 131px" />
      </a>
    </div>
    <a href="/site_admin/student-csv-template_school.csv">
      {{ $t('download_student_csv_template') }}
    </a>
  </div>
</template>

<script>
import ImageTag from 'vue_features/shared/components/ui/ImageTag'

export default {
  name: 'StudentArchivalIntructions',
  components: { ImageTag },
}
</script>
