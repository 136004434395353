<template>
  <div v-mathjax class="col-span-12 break-words py-4">
    <section class="space-y-6">
      <WikiBlock v-for="block in displayBlocks" :key="block[0].id" :blocks="block" />
    </section>
  </div>
</template>

<script>
import { computed } from 'vue'
import WikiBlock from './WikiBlock'
import { structureBlocks, combineListItems } from '../block_helpers'

export default {
  name: 'WikiResource',
  components: { WikiBlock },
  props: {
    blocks: { type: Array, default: () => [] },
    insertedBlocks: { type: Array, default: () => [] },
  },
  setup(props) {
    const displayBlocks = computed(() => {
      return props.blocks.length || props.insertedBlocks.length
        ? combineListItems(structureBlocks(props.blocks, props.insertedBlocks))
        : []
    })

    return {
      displayBlocks,
    }
  },
}
</script>
