import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import ResourcesService from 'vue_features/resources/api'
import { orderedEmbeds } from 'vue_features/resources/wikis/block_helpers'
import { toBoolean } from 'utils'
import Cookies from 'js-cookie'

export const OPEN_COOKIE = 'sidebar_open'

export function initCollectionMapStore(initialState) {
  const { $set, state, getNewParentId, activeItemId, openCollectionMap } = useCollectionMapStore()
  $set({
    isOpen: toBoolean(Cookies.get(OPEN_COOKIE)) || initialState.isOpen,
    openedItems: [],
    loadedChildren: {},
    path: initialState.path,
  })
  state.parentId = getNewParentId()
  state.openedItems.push({ level: 0, id: state.parentId })
  if (state.parentId !== activeItemId.value) {
    state.openedItems.push({ level: 1, id: activeItemId.value })
  }
  if (state.isOpen) {
    openCollectionMap()
  }
}

export const useCollectionMapStore = defineStore('collectionMap', () => {
  const state = reactive({
    path: '',
    parentId: '',
    loadedChildren: {},
    parentBlock: {},
    openedItems: [],
    isOpen: false,
  })

  const getNewParentId = () => {
    const treeIds = state.path.split('.')

    let searchStart = state.parentId ? treeIds.indexOf(`Wiki_${state.parentId}`) - 1 : treeIds.length - 2
    while (searchStart > 0) {
      const [type, id] = treeIds[searchStart].split('_')
      if (type === 'Wiki') {
        return id
      }
      searchStart--
    }

    return treeIds[Math.max(0, searchStart)].split('_').pop()
  }

  const openCollectionMap = () => {
    state.isOpen = true
    const sidebar = document.querySelector('.collection-page__sidebar')
    if (sidebar) sidebar.classList.add('animate-push-right')
    document.body.classList.add('menu-push')
    Cookies.set(OPEN_COOKIE, true)
  }

  const getPathIds = (path) => {
    return path.split('.').map((treeId) => treeId.split('_').pop())
  }

  const parentBlockFromData = (data) => {
    return {
      detailType: 'Wiki',
      content: { id: data.id, lzContent: { href: data.url, title: data.name } },
    }
  }

  const pathIds = computed(() => {
    return getPathIds(state.path)
  })

  const rootId = computed(() => {
    return pathIds.value[0]
  })

  const activeItemId = computed(() => {
    return pathIds.value.slice(-1).pop()
  })

  async function loadChild(childId) {
    if (!state.loadedChildren[childId]) {
      const childData = await ResourcesService.get(childId)
      if (childId === state.parentId) {
        state.parentBlock = parentBlockFromData(childData)
      }
      state.loadedChildren[childId] = orderedEmbeds(childData)
    }
    return state.loadedChildren[childId]
  }

  const addParent = () => {
    state.parentId = getNewParentId()
    state.parentBlock = { content: { id: state.parentId }, lzContent: {} }
    state.openedItems.forEach((o) => o.level++)
    state.openedItems.push({ level: 0, id: state.parentId })
  }

  const toggleOpened = (level, id) => {
    const index = state.openedItems.findIndex((o) => o.level === level && o.id === id)
    if (index >= 0) {
      state.openedItems.splice(index, 1)
    } else {
      state.openedItems.push({ level, id })
    }
  }

  return {
    state,
    loadChild,
    getNewParentId,
    openCollectionMap,
    activeItemId,
    pathIds,
    rootId,
    addParent,
    toggleOpened,
  }
})
