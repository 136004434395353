<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="page-content hide-elements-until-ko-loaded" data-bind="loaded: true">
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <div class="mb-4 text-right">
        <div class="icon small">
          <InlineSvg path="icons/help" />
        </div>
        <a :href="cleverHelpUrl">{{ $t('clever_setup_help') }}</a>
      </div>

      <h3 class="mt-0">{{ $t('district_id_heading') }}</h3>
      <p>{{ $t('district_id_description') }}</p>
      <i18n-t tag="p" keypath="district_id_warning" scope="global" class="small mt-2">
        <template #noChanges>
          <strong>{{ $t('district_id_cannot_be_changed') }}</strong>
        </template>
      </i18n-t>

      <div :class="{ error: districtIdError }">
        <label v-if="districtIdError">
          {{ $t('district_id') }}
          <span>{{ districtIdError }}</span>
        </label>
        <input
          ref="districtIdInput"
          class="mt-6"
          data-test="clever-district-id-field"
          :disabled="isCleverConfigured || !canEdit"
          :value="districtId"
          type="text"
          placeholder="Ex: 4fd43cc56d11340000000005"
          @input="handleDistrictIdChange"
        />
      </div>

      <section v-if="isCleverConfigured" data-test="data-syncing">
        <h3>{{ $t('data_syncing_heading') }}</h3>
        <p>
          <span>{{ $t('data_syncing_description') }}</span>
          <a :href="dataSyncingHelpUrl">{{ $t('common.learn_more') }}</a>
        </p>
        <div v-if="!mayConfigureDataSync">
          <Alert v-if="errorTitle" type="notice" data-test="last-sync-reason" :title="errorTitle" />
        </div>
        <div class="switch-toggle">
          <input
            :disabled="!mayConfigureDataSync || !canEdit"
            :checked="syncActive"
            type="checkbox"
            class="switch-toggle__button"
            @change="toggleDataSync"
          />
          <label>{{ $t('common.off') }}</label>
          <label class="pl-2">{{ $t('common.on') }}</label>
          <p class="weak small center m-6">{{ $t('last_sync') }} {{ timeAgo(lastSyncCompletedAt) }}</p>
        </div>
      </section>

      <section v-if="isCleverConfigured" data-test="primary-auth-setting">
        <h3>{{ $t('primary_auth_heading') }}</h3>
        <p>{{ primaryAuthDescription }}</p>
        <label class="mr-4">
          <input
            :checked="authPreference == 'ilclassroom'"
            :disabled="!hasDistrictId || !canEdit"
            type="radio"
            value="ilclassroom"
            @change="authPreference = 'ilclassroom'"
          />
          {{ $t('primary_auth_default') }}
        </label>
        <label class="mr-4">
          <input
            :checked="authPreference == 'clever'"
            :disabled="!hasDistrictId || !canEdit"
            type="radio"
            value="clever"
            @change="authPreference = 'clever'"
          />
          Clever
        </label>
      </section>

      <div class="my-12">
        <div v-if="generalError" class="error--inline">
          {{ generalError }}
        </div>
        <button
          :disabled="!maySubmit || saving || !canEdit"
          class="btn btn--primary"
          data-test="save"
          @click.prevent="saveChanges"
        >
          {{ $t('common.save_changes') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from 'vue_features/shared/components/ui/Alert'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import { useSiteAdminCleverStore } from '../store/use_site_admin_clever_store'

export default {
  name: 'SiteAdminClever',
  components: { InlineSvg, Alert },
  props: {
    dataSyncingHelpUrl: {
      type: String,
      required: true,
    },
    cleverHelpUrl: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      authPreference,
      districtId,
      maintenanceMode,
      persisted,
      syncActive,
      anyRunningImports,
      lastSyncCompletedAt,
      toggleDataSync,
      update,
    } = useSiteAdminCleverStore()
    return {
      authPreference,
      districtId,
      maintenanceMode,
      persisted,
      syncActive,
      anyRunningImports,
      lastSyncCompletedAt,
      toggleDataSync,
      update,
    }
  },
  data() {
    return {
      districtIdError: '',
      generalError: '',
      saving: false,
    }
  },
  computed: {
    errorTitle() {
      if (this.anyRunningImports) return this.$t('sync_locked.running')
      if (this.maintenanceMode) return this.$t('sync_locked.maintenance_mode')
      return ''
    },

    hasDistrictId() {
      return !!this.districtId
    },

    maySubmit() {
      return this.hasDistrictId
    },

    isCleverConfigured() {
      return this.persisted
    },

    mayConfigureDataSync() {
      return !this.maintenanceMode && !this.anyRunningImports
    },

    primaryAuthDescription() {
      const binding = this.authPreference === 'clever' ? 'Clever' : this.$t('primary_auth_default')
      return this.$t('primary_auth_description', { binding })
    },
  },
  methods: {
    timeAgo,
    saveChanges() {
      this.saving = true
      this.update().then(
        (response) => (window.location = response.redirect),
        (error) => {
          this.saving = false
          if (error.responseJSON && error.responseJSON.district_id) {
            this.districtIdError = error.responseJSON.district_id.toString()
            this.$refs.districtIdInput.focus()
          } else {
            this.generalError = error.statusText
          }
        },
      )
    },
    handleDistrictIdChange(event) {
      this.districtIdError = this.generalError = ''
      this.districtId = event.target.value
    },
  },
}
</script>
