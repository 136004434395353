<template>
  <Grid class="mx-auto">
    <input :name="inputName('forked_from_id')" :value="resource.forkedFromId" type="hidden" />
    <div v-if="resourceErrors.length > 0" class="col-span-12">
      <Alert :title="$t('errors.common.page_error_heading')" :description="resourceErrors" dismissable type="error" />
    </div>
    <div class="resource__title col-span-12 md:col-span-8 md:col-start-5 md:row-start-2 lg:col-span-9 lg:col-start-4">
      <div>
        <label for="title-input">{{ $t('resources.title_label') }}</label>
        <input id="title-input" v-model="newName" :name="inputName('name')" :class="inputClass" type="text" />
        <p class="help-block">{{ $t('resources.form_template.title_help') }}</p>
      </div>
      <div>
        <label for="description-textarea">
          {{ $t('resources.description_label') }}
          <span class="weak regular">{{ $t('resources.optional') }}</span>
        </label>
        <textarea
          id="description-textarea"
          v-model="newDescription"
          :name="inputName('description')"
          :rows="textAreaRows"
        />
        <p class="help-block">{{ $t('resources.description_help') }}</p>
      </div>
      <Grid disable-default="md:px">
        <div class="col-span-12 md:col-span-6">
          <label for="login-required">{{ $t('common.teacher_login_required') }}</label>
          <SwitchToggle id="login-required" v-model="newTeacherOnly" :field-name="inputName('teacher_only')" />
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="premium-toggle">{{ $t('common.subscription_required') }}</label>
          <SwitchToggle id="premium-toggle" v-model="newPremium" :field-name="inputName('premium')" />
        </div>
      </Grid>
    </div>
    <div class="col-span-12 md:col-span-1 md:col-span-4 md:row-start-2 lg:col-span-3 lg:col-start-1">
      <CoverImageUpload
        :cover-image="newCoverImage"
        :placeholder="coverImagePlaceholder"
        :field-name="inputName('cover_image')"
        @upload-done="newCoverImage = $event"
        @remove-file="newCoverImage = $event"
      />
    </div>
  </Grid>
</template>

<script>
import Alert from 'vue_features/shared/components/ui/Alert'
import { ref, computed, watchEffect } from 'vue'
import CoverImageUpload from 'vue_features/cover_image_upload/components/CoverImageUpload'
import SwitchToggle from 'vue_features/shared/components/ui/SwitchToggle'
import { snakeCase } from 'lodash'
import Grid from 'vue_features/shared/components/ui/Grid'
import { getPlaceholder } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'SharedForm',
  components: { Grid, Alert, CoverImageUpload, SwitchToggle },
  props: {
    resource: { type: Object, required: true },
    errors: { type: Array, default: () => [] },
    inputClass: { type: String, default: null },
    textAreaRows: { type: String, default: null },
  },
  setup(props, { emit }) {
    const newName = ref(props.resource?.name)
    const newDescription = ref(props.resource?.description)
    const newTeacherOnly = ref(!!props.resource?.teacherOnly)
    const newPremium = ref(!!props.resource?.premium)
    const newCoverImage = ref(props.resource?.coverImageUrl || {})
    const coverImagePlaceholder = ref(props.resource?.coverImagePlacholder || getPlaceholder(props.resource.type))
    const fieldName = computed(() => snakeCase(props.resource.type))
    const inputName = (input) => `${fieldName.value}[${input}]`

    watchEffect(() =>
      emit('update', {
        name: newName.value,
        description: newDescription.value,
        teacherOnly: newTeacherOnly.value,
        premium: newPremium.value,
        coverImageUrl: newCoverImage.value,
      }),
    )

    const resourceErrors = computed(() => (props.resource.errors || []).concat(...props.errors))

    return {
      newName,
      newDescription,
      newTeacherOnly,
      newPremium,
      newCoverImage,
      coverImagePlaceholder,
      inputName,
      resourceErrors,
    }
  },
}
</script>
