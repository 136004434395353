<template>
  <div class="lesson-standards text-primary flex flex-wrap gap-2">
    <StandardCode
      v-for="standard in shownStandards"
      :key="standard.uuid"
      :name="standard.name"
      :display-code="standard.displayCode"
      :coverage-type="standard.coverageType"
      class="lz-standard inline-flex"
      :class="standardClasses"
    />
    <button
      v-if="hasMoreStandards"
      data-test="show-all-standards"
      class="text-accent hover:text-primary-600 ml-1 text-sm"
      @click="toggle"
    >
      {{ showingAll ? $t('breakdown.hide') : $t('breakdown.show_all') }}
    </button>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { groupBy } from 'lodash'
import StandardCode from 'vue_features/shared/components/ui/StandardCode'

export default {
  name: 'StandardsBreakdown',
  components: { StandardCode },
  props: { standards: { type: Array, required: true }, standardClasses: { type: String, default: '' } },
  setup(props) {
    const orderedStandards = computed(() => {
      return [...props.standards].sort((standard1, standard2) => {
        const firstSort = standard1.coverageType.localeCompare(standard2.coverageType)

        if (firstSort === 0) {
          return standard1.displayCode.localeCompare(standard2.displayCode)
        } else {
          return firstSort
        }
      })
    })

    const DEFAULT_SHOWN = 25
    const showingAll = ref(false)
    const hasMoreStandards = computed(() => {
      return orderedStandards.value.length > DEFAULT_SHOWN
    })
    const toggle = () => {
      showingAll.value = !showingAll.value
    }
    const shownStandards = computed(() => {
      return showingAll.value ? orderedStandards.value : orderedStandards.value.slice(0, DEFAULT_SHOWN)
    })

    return { showingAll, shownStandards, hasMoreStandards, toggle, groupBy }
  },
}
</script>
