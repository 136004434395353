import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const ResourcesService = {
  create(type, payload) {
    const params = { resourceType: type, [type]: payload }
    return HTTP.create(Routes.resources_path(), snakeKeys(params))
  },

  update(id, type, payload) {
    const params = { resourceType: type, [type]: payload }
    return HTTP.put(Routes.resource_path(id), snakeKeys(params))
  },

  get(id, params = {}) {
    return HTTP.getJSON(Routes.resource_path(id), snakeKeys(params))
  },

  embed(type, ids) {
    return HTTP.getJSON(Routes.embed_path(), { type, ids })
  },
}

export default ResourcesService
