<template>
  <div>
    <EmptyStateWithAnimation
      v-if="!longerWaitForItemPool"
      :animation-loader="() => import('lottie-animations/loading1.json')"
      autoplay
      :heading="$t('show.almost_ready')"
      :body="$t('show.assignment_being_prepared')"
      data-test="almost-ready"
    />
    <transition
      enter-active-class="transition-opacity duration-1000"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <EmptyStateWithAnimation
        v-if="longerWaitForItemPool"
        :animation-loader="() => import('lottie-animations/loading2.json')"
        loop
        autoplay
        :heading="$t('show.hang_tight')"
        :body="$t('show.longer_than_expected')"
        data-test="hang-tight"
      />
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { EmptyStateWithAnimation } from 'vue_features/shared/components/ui'
import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import { useAssignmentStore } from 'vue_features/assignments/show/composables'

export const ITEM_POOL_STATUS_CHECK_INTERVAL = 5000

export default {
  name: 'ItemPoolPendingNotice',
  components: {
    EmptyStateWithAnimation,
  },
  setup() {
    const { assignment } = useAssignmentStore()
    const longerWaitForItemPool = ref(false)

    onMounted(() => {
      if (assignment.value.itemPoolPending) {
        const itemPoolStatusCheckInterval = setInterval(async () => {
          const response = await AssignmentsService.getItemPoolStatus(assignment.value.id)
          if (response.itemPoolPending) {
            longerWaitForItemPool.value = true
          } else {
            assignment.value = { ...assignment.value, itemPoolPending: false }
            clearInterval(itemPoolStatusCheckInterval)
          }
        }, ITEM_POOL_STATUS_CHECK_INTERVAL)
      }
    })

    return {
      assignment,
      longerWaitForItemPool,
    }
  },
}
</script>
