export const DETECTION_SPOT_REACH = 50 // length in px that the detection areas extends past the slide

export class DetectionMatch {
  constructor(triangle = new DetectionTriangle(), position, element) {
    this.triangle = triangle
    this.position = position
    this.element = element
  }

  isBefore() {
    return this.position === 'top' || this.position === 'left'
  }

  isVertical() {
    return this.position === 'top' || this.position === 'bottom'
  }

  equals(otherHit) {
    return otherHit.position === this.position && otherHit.triangle.equals(this.triangle)
  }
}

export class DetectionTriangle {
  constructor(point1, point2, point3) {
    this.point1 = point1
    this.point2 = point2
    this.point3 = point3
  }

  // see https://stackoverflow.com/questions/2049582/how-to-determine-if-a-point-is-in-a-2d-triangle
  contains(point) {
    const b1 = sign(point, this.point1, this.point2) < 0.0
    const b2 = sign(point, this.point2, this.point3) < 0.0
    const b3 = sign(point, this.point3, this.point1) < 0.0

    return b1 === b2 && b2 === b3
  }

  equals(otherTriangle) {
    return (
      otherTriangle.point1 === this.point1 &&
      otherTriangle.point2 === this.point2 &&
      otherTriangle.point3 === this.point3
    )
  }
}

function sign(v1, v2, v3) {
  return (v1.left - v3.left) * (v2.top - v3.top) - (v2.left - v3.left) * (v1.top - v3.top)
}

export function vertex(top, left) {
  return { top: top, left: left }
}

export function getDetectionAreasFor(contentBox) {
  const bounds = contentBox.getBoundingClientRect()
  const offset = { top: bounds.top + window.pageYOffset, left: bounds.left + window.pageXOffset }
  const w = contentBox.clientWidth
  const h = contentBox.clientHeight

  const centerVertex = vertex(offset.top + h / 2, offset.left + w / 2)

  const verticalTriangleReach = (w * DETECTION_SPOT_REACH) / h
  const verticalTriangleLeft = offset.left - verticalTriangleReach
  const verticalTriangleRight = offset.left + w + verticalTriangleReach

  const topBase = offset.top - DETECTION_SPOT_REACH
  const topTriangle = new DetectionTriangle(
    centerVertex,
    vertex(topBase, verticalTriangleLeft),
    vertex(topBase, verticalTriangleRight),
  )

  const bottomBase = offset.top + h + DETECTION_SPOT_REACH
  const bottomTriangle = new DetectionTriangle(
    centerVertex,
    vertex(bottomBase, verticalTriangleLeft),
    vertex(bottomBase, verticalTriangleRight),
  )

  const horizontalTriangleReach = (h * DETECTION_SPOT_REACH) / w
  const horizontalTriangleTop = offset.top - horizontalTriangleReach
  const horizontalTriangleBottom = offset.top + h + horizontalTriangleReach

  const leftBase = offset.left - DETECTION_SPOT_REACH
  const leftTriangle = new DetectionTriangle(
    centerVertex,
    vertex(horizontalTriangleTop, leftBase),
    vertex(horizontalTriangleBottom, leftBase),
  )

  const rightBase = offset.left + DETECTION_SPOT_REACH + w
  const rightTriangle = new DetectionTriangle(
    centerVertex,
    vertex(horizontalTriangleTop, rightBase),
    vertex(horizontalTriangleBottom, rightBase),
  )

  return {
    top: topTriangle,
    right: rightTriangle,
    left: leftTriangle,
    bottom: bottomTriangle,
  }
}
