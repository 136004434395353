<template>
  <Modal v-if="open" class="py-10" content-classes="flex flex-col h-full min-h-full" @backdrop-click="onBackdropClick">
    <div class="bg-focus sticky top-0 z-10 flex flex-shrink-0 items-center justify-between rounded-t-xl p-4">
      <ListDropdown id="session-chooser-klass-selector" class="btn--small p-0 shadow-none" data-test="klass-selector">
        <template #button>
          {{ klass.name }}
          <LzIcon path="icons/caret" />
        </template>

        <template #listItems>
          <li v-for="klassOption of klasses" :key="klassOption.id">
            <button :data-test="`klass-option-${klassOption.id}`" @click="klass = klassOption">
              {{ klassOption.name }}
            </button>
          </li>
        </template>
      </ListDropdown>

      <NameSortButton id="grading-sort-by" cookie-name="assignment_sort_by" @sort-changed="setSortNamesBy" />
    </div>

    <div
      v-if="sessionsForKlass(klass).length > 0"
      class="border-base bg-base z-0 h-full overflow-y-scroll rounded-b-xl border"
      data-test="sessions-for-klass"
    >
      <div
        v-for="session of sessionsForKlass(klass)"
        :key="session.id"
        class="even:bg-focus flex items-center shadow-sm"
        :class="sessionClasses(session)"
      >
        <span
          class="border-base fs-mask sticky left-0 z-20 inline-block w-48 flex-shrink-0 flex-nowrap truncate border-r bg-white p-4 leading-6"
        >
          {{ session.studentName }}</span
        >
        <div class="flex h-full px-2 py-1.5">
          <ItemBubble
            v-for="(item, index) of session.items"
            v-bind="itemBubbleProps(session, item, index)"
            :key="item.number"
            ref="itemBubbleRefs"
            :data-test="`choose-session-${session.id}-item-${index}`"
            @click="onSessionItemClick(session, index)"
          >
            <span>{{ index + 1 }}</span>
          </ItemBubble>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useGradingStore } from '../composables/use_grading_store'
import Modal from './Modal'
import ItemBubble from './ItemBubble'
import { scoreThemeForItem } from '../utils/score_theme'
import NameSortButton from 'vue_features/shared/components/NameSortButton'
import { LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const {
  klasses,
  filteredSessions,
  currentSession,
  currentSessionKlass,
  currentItemIndex,
  loadingSession,
  showSessionChooser,
  gotoSession,
  gotoItem,
  sortNamesBy,
} = useGradingStore()

const getDefaultKlass = () => currentSessionKlass.value || klasses.value.at(0)

const klass = ref(getDefaultKlass())

watch(
  () => props.open,
  (isOpen) => {
    if (isOpen) {
      klass.value = getDefaultKlass()
    }
  },
)

const sessionsByKlass = computed(() => {
  return filteredSessions.value.reduce((all, session) => {
    all[session.klassId] ||= []
    all[session.klassId].push(session)
    return all
  }, {})
})

const sessionsForKlass = (klass) => {
  return sessionsByKlass.value[klass.id] || []
}

const sessionClasses = (session) => {
  const base = session.assignmentState === 'opened' ? ['linear-gradient-striped'] : []

  if (session.id === currentSession.value?.id) {
    return [...base, 'font-bold']
  } else if (loadingSession.value) {
    return [...base, 'border-gray-400', 'bg-gray-100']
  } else {
    return base
  }
}

const onSessionItemClick = (chosenSession, chosenItemIndex) => {
  const sessionIndex = filteredSessions.value.findIndex((session) => session.id === chosenSession.id)

  if (sessionIndex !== -1) {
    gotoSession(sessionIndex)
  }

  if (chosenItemIndex !== currentItemIndex.value) {
    gotoItem(chosenItemIndex)
  }

  showSessionChooser.value = false
}

const onBackdropClick = () => {
  showSessionChooser.value = false
}

const itemBubbleProps = (session, item, index) => {
  const scoreTheme = scoreThemeForItem(item, loadingSession.value, true)

  const isSelected = session === currentSession.value && index === currentItemIndex.value
  const ifSelected = isSelected ? { ringColorClass: 'text-primary-600', ringPercentage: 100 } : {}

  return { ...scoreTheme, ...ifSelected }
}

const setSortNamesBy = (value) => {
  sortNamesBy.value = value
}
</script>

<style scoped>
.linear-gradient-striped {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.025) 0,
    rgba(0, 0, 0, 0.025) 4px,
    rgba(0, 0, 0, 0) 4px,
    rgba(0, 0, 0, 0) 8px,
    rgba(0, 0, 0, 0.025) 8px
  );
}
</style>
