<!-- eslint-disable vuejs-accessibility/anchor-has-content  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div v-if="unlinkedCount > 0" data-test="klass-unlinked-indicator" class="inline-block">
    <LzTooltip tooltip-classes="google-warn-tooltip">
      <a :href="classPath" class="small" @click.stop>
        <LzIcon size="sm" path="icons/exclamation-triangle" />
      </a>
      <template #content>
        <div>{{ googleUnlinkWarningText }}</div>
      </template>
    </LzTooltip>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import Routes from 'routes'
import { LzIcon, LzTooltip } from 'vue_features/shared/components/ui'

export default {
  name: 'KlassUnlinkedIndicator',
  components: { LzIcon, LzTooltip },
  props: {
    klassId: { type: Number, required: true },
    unlinkedCount: { type: Number, required: true },
  },
  setup(props) {
    const root = inject('useRoot')()
    return {
      googleUnlinkWarningText: computed(() =>
        root.$t('common.google_classroom.unlinked_warning', { unlinked_count: props.unlinkedCount }),
      ),
      classPath: `${Routes.teacher_klass_path(props.klassId)}/people`,
    }
  },
}
</script>
