<template>
  <ListDropdown :id="id" class="btn--small p-0">
    <template #button>
      <LzIcon size="sm" path="icons/sort-alpha-asc" />
      {{ buttonText }}
      <LzIcon path="icons/caret" />
    </template>
    <template #listItems>
      <li>
        <button type="button" data-test="sort-by-first-name" @click="setNameSort('first_name')">
          {{ $t('name_sort_button.sort_by_first_name') }}
        </button>
      </li>
      <li>
        <button type="button" data-test="sort-by-last-name" @click="setNameSort('last_name')">
          {{ $t('name_sort_button.sort_by_last_name') }}
        </button>
      </li>
    </template>
  </ListDropdown>
</template>

<script>
import Cookies from 'js-cookie'
import { merge } from 'lodash'
import { LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, shared } from 'vue_features/shared/i18n'

const messages = merge(
  {},
  namespaceLocaleObj(common, '', { only: ['first_name', 'last_name'] }),
  pickLocaleNamespace(shared, 'name_sort_button'),
)

export default {
  name: 'NameSortButton',
  i18n: { messages },
  components: {
    LzIcon,
    ListDropdown,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    cookieName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameSort: Cookies.get(this.cookieName) || 'first_name',
    }
  },
  computed: {
    buttonText() {
      return this.$t(this.nameSort)
    },
  },
  methods: {
    setNameSort(value) {
      if (value !== this.nameSort) {
        this.nameSort = value
        Cookies.set(this.cookieName, value, { sameSite: 'None', secure: true, expires: 10 * 365 })
        this.$emit('sort-changed', value)
      }
    },
  },
}
</script>
