<template>
  <div :class="['file-upload', { 'has-inline-picker': isOpen }]">
    <div :id="containerId" :class="['file-upload__inline-container', { open: isOpen }]" />
    <slot name="button" v-bind="{ launch, isOpen }" />
  </div>
</template>

<script>
import { config } from 'utils'
import { ref } from 'vue'
import { uniqueId } from 'lodash'
import useFilestackUploader from 'vue_features/shared/composables/use_filestack_uploader'

export default {
  name: 'InlineUploader',
  props: { config: { type: String, default: 'audio' } },
  setup(props, { emit }) {
    const { accept, maxSize } = config[props.config]
    const containerId = uniqueId('inline-fs-container-')
    const rootId = uniqueId('fs-picker-root-')
    const isOpen = ref(false)

    const onUploadDone = (response) => {
      const json = response.filesUploaded[0]
      emit('uploaded', {
        baseUrl: json.url,
        metadata: json,
        filestackOptions: { ...uploadOptions.value },
      })
      closePicker()
      isOpen.value = false
    }

    const {
      launch: launchPicker,
      closePicker,
      uploadOptions,
    } = useFilestackUploader({
      accept,
      maxSize,
      onUploadDone,
      customPickerOptions: {
        container: containerId,
        displayMode: 'inline',
        rootId,
      },
    })

    const launch = () => {
      isOpen.value ? closePicker() : launchPicker()
      isOpen.value = !isOpen.value
    }

    return { launch, containerId, isOpen }
  },
}
</script>
