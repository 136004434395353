<template>
  <div>
    <UsersRecurringImportDeletionConfirmModal
      v-if="canModalShow(confirmModalId)"
      :modal-id="confirmModalId"
      @confirm="removeImport"
    />
    <div v-if="isFormVisible" class="mx-auto max-w-7xl">
      <form
        data-test="import-form"
        class="lzui-tile relative grid w-full grid-cols-3 gap-4 p-4 md:w-1/2"
        @submit.prevent="saveImport"
      >
        <div
          v-if="isLoading"
          class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-y-4 rounded bg-white bg-opacity-90"
        >
          <LoadingSpinner />
          <p>{{ $t('users_recurring_import.connection_testing') }}</p>
        </div>
        <div class="sftp-host required col-span-2">
          <label class="sftp-host required" for="sftp_host">{{ $t('users_recurring_import.sftp_host') }}</label>
          <input
            id="sftp_host"
            v-model="sftpHost"
            :disabled="!isEditing"
            placeholder="host.example.com"
            class="required form-control no-fullstory"
            type="text"
            required="required"
            aria-required="true"
          />
        </div>
        <div class="sftp-port required col-span-1">
          <label class="sftp-port required" for="sftp_port">
            {{ $t('users_recurring_import.sftp_port') }}
          </label>
          <input
            id="sftp_port"
            v-model="sftpPort"
            :disabled="!isEditing"
            placeholder="22"
            class="required form-control no-fullstory"
            type="text"
            required="required"
            aria-required="true"
          />
        </div>
        <div class="sftp-username required col-span-3">
          <label class="sftp-username required" for="sftp_username">
            {{ $t('users_recurring_import.sftp_username') }}
          </label>
          <input
            id="sftp_username"
            v-model="sftpUsername"
            :disabled="!isEditing"
            placeholder="sftp_user"
            class="required form-control no-fullstory"
            type="text"
            required="required"
            aria-required="true"
          />
        </div>
        <div class="sftp-password required col-span-3">
          <label class="sftp-password required" for="sftp_password">
            {{ $t('users_recurring_import.sftp_password') }}
          </label>
          <input
            id="sftp_password"
            v-model="sftpPassword"
            :disabled="!isEditing"
            placeholder="password"
            class="required form-control no-fullstory"
            type="password"
            required="required"
            aria-required="true"
          />
        </div>
        <div class="sftp-directory required col-span-3">
          <label class="sftp-directory required" for="sftp_directory">
            {{ $t('users_recurring_import.sftp_directory') }}
          </label>
          <input
            id="sftp_directory"
            v-model="sftpDirectory"
            :disabled="!isEditing"
            placeholder="~/files"
            class="required form-control no-fullstory"
            type="text"
            required="required"
            aria-required="true"
          />
        </div>
        <div class="frequency required col-span-3">
          <label class="frequency required" for="frequency">{{ $t('users_recurring_import.frequency') }}</label>
          <p>{{ $t('users_recurring_import.nightly_at_11pm') }}</p>
        </div>

        <p v-if="successMessage" class="col-span-3 text-green-500">{{ successMessage }}</p>

        <p v-if="errorMessage" class="col-span-3 text-red-500">{{ errorMessage }}</p>

        <div v-if="isEditing" class="col-span-3">
          <LzButton v-if="isEditing" type="button" class="mr-2" data-test="cancel-button" @click="cancelForm">
            {{ $t('common.cancel') }}
          </LzButton>
          <LzButton v-if="isEditing" type="submit" color="primary" data-test="submit-button">{{
            formButtonText
          }}</LzButton>
        </div>
        <div v-else class="col-span-3">
          <LzButton type="button" class="float-right ml-2" color="primary" data-test="edit-button" @click="editForm">
            {{ $t('common.edit') }}
          </LzButton>
          <LzButton
            type="button"
            class="float-right"
            caution
            data-test="remove-button"
            @click="openModal(confirmModalId)"
          >
            {{ $t('common.remove') }}
          </LzButton>
        </div>
      </form>
    </div>
    <LzButton v-else type="button" data-test="add-import-button" color="primary" @click="addImport">
      {{ $t('users_recurring_import.create_import') }}
    </LzButton>
  </div>
</template>

<script>
import { LzButton } from 'vue_features/shared/components/ui'
import { SiteAdminUsersRecurringImportService } from '../api'
import UsersRecurringImportDeletionConfirmModal from './UsersRecurringImportDeletionConfirmModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'

const DEFAULT_IMPORT = {
  sftpHost: '',
  sftpPort: '22',
  sftpUsername: '',
  sftpPassword: '',
  sftpDirectory: '~/',
}

export default {
  name: 'UsersRecurringImport',
  components: {
    LzButton,
    LoadingSpinner,
    UsersRecurringImportDeletionConfirmModal,
  },
  props: {
    usersRecurringImport: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    return { openModal, canModalShow }
  },
  data() {
    const { sftpHost, sftpPort, sftpUsername, sftpPassword, sftpDirectory } = this.usersRecurringImport || {}

    return {
      isEditing: false,
      isLoading: false,
      errorMessage: '',
      successMessage: '',
      isFormVisible: !!this.usersRecurringImport,
      importExists: !!this.usersRecurringImport,
      confirmModalId: UsersRecurringImportDeletionConfirmModal.name,
      sftpHost,
      sftpPort,
      sftpUsername,
      sftpPassword,
      sftpDirectory,
    }
  },
  computed: {
    formButtonText() {
      return this.importExists ? this.$t('common.update') : this.$t('common.create')
    },
  },
  methods: {
    addImport() {
      this.isFormVisible = true
      this.isEditing = true

      this.sftpHost = DEFAULT_IMPORT.sftpHost
      this.sftpPort = DEFAULT_IMPORT.sftpPort
      this.sftpUsername = DEFAULT_IMPORT.sftpUsername
      this.sftpPassword = DEFAULT_IMPORT.sftpPassword
      this.sftpDirectory = DEFAULT_IMPORT.sftpDirectory
    },

    editForm() {
      this.isEditing = true
    },

    cancelForm() {
      this.isEditing = false
      this.isFormVisible = this.importExists
    },

    saveImport() {
      this.errorMessage = ''
      this.successMessage = ''
      this.isLoading = true

      this.serviceMethod()({
        sftpHost: this.sftpHost,
        sftpPort: this.sftpPort,
        sftpUsername: this.sftpUsername,
        sftpPassword: this.sftpPassword,
        sftpDirectory: this.sftpDirectory,
      })
        .then(() => {
          this.importExists = true
          this.isEditing = false
          this.successMessage = this.$t('users_recurring_import.connection_success')
        })
        .catch((error) => {
          const connectionError = error.responseJSON.errors.base[0]

          if (connectionError) {
            this.errorMessage = this.$t('users_recurring_import.connection_error', { error: connectionError })
          } else {
            this.errorMessage = error
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    removeImport() {
      SiteAdminUsersRecurringImportService.delete()
        .then(() => {
          this.importExists = false
          this.isFormVisible = false
        })
        .catch((error) => {
          alert(JSON.new(error.responseJson.errors))
        })
    },

    serviceMethod() {
      return this.importExists
        ? SiteAdminUsersRecurringImportService.update
        : SiteAdminUsersRecurringImportService.create
    },
  },
}
</script>
