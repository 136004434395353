<template>
  <div>
    <h2 class="h3 mt-12">{{ $t('show.options.header') }}</h2>

    <SettingsToggle
      id="student-function-toggle"
      :model-value="studentFunctionality"
      :enabled="isEditing"
      :label="$t('show.student_functionality.header')"
      :info="$t('show.student_functionality.toggle_info')"
      class="mb-0 mt-6"
      @update:modelValue="$emit('update:studentFunctionality', $event)"
    />

    <fieldset class="mb-0 ml-12 mt-6">
      <SettingsToggle
        id="google-classroom-enabled-toggle"
        :model-value="googleClassroomEnabled"
        :enabled="isEditing && studentFunctionality"
        :label="$t('show.google_classroom_integration.header')"
        :info="$t('show.google_classroom_integration.toggle_info')"
        @update:modelValue="$emit('update:googleClassroomEnabled', $event)"
      />
      <i18n-t tag="p" keypath="common.google_classroom.usage_docs" scope="global" class="smaller text-muted mt-4">
        <template #link>
          <a href="/wikis/2492780-how-il-classroom-handles-google-classroom-user-data" target="_blank">
            {{ $t('common.here') }}
          </a>
        </template>
      </i18n-t>
    </fieldset>
  </div>
</template>

<script>
import SettingsToggle from './SettingsToggle'

export default {
  name: 'OptionsSettings',
  components: { SettingsToggle },
  props: {
    isEditing: { type: Boolean, required: true },
    studentFunctionality: { type: Boolean, required: true },
    googleClassroomEnabled: { type: Boolean, required: true },
  },
}
</script>
