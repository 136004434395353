<template>
  <div>
    <section>
      <UserTypeSelector v-bind="{ teacherSource, studentSource }" v-model="source" />
      <ImportInstructions :source="source" />
      <template v-if="source !== 'select'">
        <h3>
          {{ $t('step_two_upload_file') }}
        </h3>
        <CsvUploader @uploaded="setFile" />
      </template>
      <ImportErrors :errors="errors" />
    </section>
    <footer>
      <AsyncContentLoader :loader="currentRequest">
        <button class="btn" @click.prevent.stop="close">
          {{ $t('common.cancel') }}
        </button>
        <button :disabled="!hasFile" class="btn btn--primary" @click.prevent.stop="upload">
          {{ $t('upload') }}
        </button>
      </AsyncContentLoader>
    </footer>
  </div>
</template>

<script>
import { get } from 'lodash'
import { AsyncContentLoader } from 'vue_features/shared/components/ui'
import ImportErrors from './ImportErrors'
import CsvUploader from './CsvUploader'
import UserTypeSelector from './UserTypeSelector'
import ImportInstructions from './ImportInstructions'
import UserImportService from '../api/user_import_service'

export default {
  name: 'ImportUpload',
  components: {
    AsyncContentLoader,
    ImportErrors,
    CsvUploader,
    UserTypeSelector,
    ImportInstructions,
  },
  props: {
    teacherSource: {
      type: String,
      required: true,
    },
    studentSource: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentRequest: null,
      file: null,
      source: 'select',
      errors: [],
    }
  },
  computed: {
    hasFile() {
      return this.file !== null
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setFile(file) {
      this.file = file
    },
    upload() {
      const formData = { source: this.source, file: this.file },
        success = this.success.bind(this),
        failure = this.failure.bind(this)

      this.currentRequest = UserImportService.previewFile(formData).then(success, failure)
    },
    success(previewData) {
      this.$emit('upload', {
        users: previewData.users,
        warnings: previewData.warnings,
        file: this.file,
        source: this.source,
      })
    },
    failure(errorResponse) {
      this.errors = get(errorResponse, 'responseJSON.errors') || [this.$t('upload_error')]
    },
  },
}
</script>
