<script setup>
import { computed } from 'vue'
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import { useBookmarksStore } from '../composables/use_bookmarks_store'
import { Badge } from 'vue_features/shared/components/ui'
import { DetailListItem, DragHandle } from 'vue_features/shared/components/lists'
import LzContentItem from 'vue_features/lz_content/components/LzContentItem'
import URI from 'urijs'
import { getIcon, getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'

const props = defineProps({
  bookmark: {
    type: Object,
    required: true,
  },
})
const { deleteBookmark, inFolder } = useBookmarksStore()
const remove = () => deleteBookmark(props.bookmark)

const pathHref = (path, href) => {
  return path && href ? URI(href).addSearch('path', path).href() : href
}
const lessonPlan = computed(() => {
  const lp = { ...props.bookmark.lessonPlan }
  lp.href = pathHref(props.bookmark.path, lp.href)
  return lp
})
</script>

<template>
  <CollapseTransition>
    <LzContentItem v-if="bookmark.viewable" v-bind="{ item: lessonPlan }" thumbnail-size="m" thumbnail-class="min-h-32">
      <template #handle>
        <DragHandle v-if="inFolder" />
      </template>
      <template v-if="bookmark.bookmarkId" #actions>
        <button class="btn btn--small remove-bookmark-btn mr-1" @click.prevent="remove">
          {{ $t('common.remove') }}
        </button>
      </template>
    </LzContentItem>
    <DetailListItem
      v-else
      :title="lessonPlan.title"
      :description="$t('common.no_content_access_notice')"
      :thumbnail="getThumbnail(lessonPlan)"
      :icon="getIcon(lessonPlan)"
      :clickable="false"
      background="bg-base"
      thumbnail-size="m"
      thumbnail-class="min-h-32"
    >
      <template #title>
        <p class="text-muted mb-0 max-h-12 flex-1 overflow-hidden truncate font-bold md:max-h-16 md:text-xl">
          {{ lessonPlan.title }}
          <Badge data-test="subscription-required" color="yellow" class="mt-0">
            {{ $t('common.subscription_required') }}
          </Badge>
        </p>
      </template>
      <template #actions>
        <button class="btn btn--small remove-bookmark-btn mr-1" @click.prevent="remove">
          {{ $t('common.remove') }}
        </button>
      </template>
    </DetailListItem>
  </CollapseTransition>
</template>
