<template>
  <div
    v-if="showThumbnail"
    :class="['outline-none relative flex w-1/4 flex-shrink-0', thumbnailSize]"
    data-test="item-thumbnail"
  >
    <!-- Overflow div allows the child div with background image to scale -->
    <div :class="['flex min-h-full w-full items-stretch overflow-hidden', { 'rounded-l-2xl': !hasHandle }]">
      <div
        class="h-auto min-h-full w-full bg-cover bg-center"
        :class="{ 'transform transition-transform duration-700 group-hover:scale-105': clickable }"
        :style="backgroundStyle"
      >
      </div>
    </div>
    <div v-if="hasIcon" class="bg-focus absolute -right-5 top-4 overflow-hidden rounded-xl border-4 border-white">
      <InlineSvg :path="iconPath" class="m-0 h-8 w-8" />
    </div>
  </div>
  <div v-else class="inline-flex max-w-max items-center justify-center pl-6 pt-6 sm:pt-0">
    <InlineSvg :path="iconPath" class="m-0 h-10 w-10" />
  </div>
</template>

<script>
import { InlineSvg } from 'vue_features/shared/components/ui'
import useThumbnailUrl from '../../composables/use_thumbnail_url'

export const SIZES = ['', 's', 'm', 'l']

export default {
  name: 'DetailListItemThumbnail',
  components: { InlineSvg },
  props: {
    title: {
      type: String,
      default: '',
    },

    href: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    thumbnail: {
      type: String,
      default: '',
    },

    showThumbnail: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: '',
      validator: (value) => SIZES.includes(value),
    },

    hasHandle: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: false,
    },

    defaultSizeClass: {
      type: String,
      default: 'w-1/3',
    },
  },

  setup(props) {
    const { thumbnailUrl } = useThumbnailUrl(props.thumbnail)
    return { thumbnailUrl }
  },

  computed: {
    backgroundStyle() {
      return this.hasThumbnailUrl
        ? `background-image: url(${this.thumbnailUrl}), linear-gradient(white 75%, rgba(var(--neutral-200), .75))`
        : ''
    },

    hasIcon() {
      return this.icon
    },

    hasThumbnailUrl() {
      return !!this.thumbnailUrl
    },

    iconPath() {
      return `icons/${this.icon}`
    },

    thumbnailSize() {
      switch (this.size) {
        case 's':
          return 'lg:w-1/5'
        case 'm':
          return 'lg:w-1/4'
        default:
          return ''
      }
    },
  },
}
</script>
