import URI from 'urijs'
import { location } from 'utils'
import { nanoid } from 'nanoid'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import Routes from 'routes'
import consumer from 'channels/consumer'

export default function usePresenter({ lessonPlan, position, emit }) {
  const { channel, presenterWindow } = useLessonPlanShowStore()

  const currentUri = URI(location.current().toString())
  const providedSlideshowSessionId = location.parseQuery().slideshow_session_id
  const slideshowSessionId =
    channel.value?.id ||
    providedSlideshowSessionId ||
    `${nanoid()}${lessonPlan.value.id ? `_lesson_plan_${lessonPlan.value.id}` : null}`

  function createWebSocketConnection() {
    let oldPosition = position.value
    const subscription = consumer.subscriptions.create(
      {
        channel: 'SlideshowChannel',
        slideshow_session_id: slideshowSessionId,
        current_position: position.value,
      },
      {
        received({ position }) {
          oldPosition = position
          emit('set-current-position', position)
        },
      },
    )

    return {
      id: slideshowSessionId,
      updatePosition(position) {
        if (position !== oldPosition) {
          oldPosition = position
          subscription.perform('update_root_state', { position })
        }
      },
    }
  }

  if (providedSlideshowSessionId) {
    channel.value = createWebSocketConnection()
  }

  const createPresenterWindow = () => {
    const presenterPathname = URI(Routes.presenter_lesson_plan_path(lessonPlan.value.id)).pathname()
    const presenterUrl = currentUri.pathname(presenterPathname).search({ slideshow_session_id: slideshowSessionId })
    return window.open(
      presenterUrl.toString(),
      'presenterWindow',
      `location=no,top=0,left=0,width=610,height=${window.screen.availHeight}`,
    )
  }

  const openPresenterWindow = () => {
    if (presenterWindow.value && !presenterWindow.value.closed) {
      presenterWindow.value.focus()
    } else {
      emit('before-create-presenter-window')
      presenterWindow.value = createPresenterWindow()
      addPresenterWindowEventListeners()
      if (!channel.value) {
        channel.value = createWebSocketConnection()
      }
    }
  }

  const addPresenterWindowEventListeners = () => {
    const forceNotesLinksToOpenInNewWindow = () => {
      const links = presenterWindow.value.document.querySelectorAll('.card__notes-body a')
      for (const element of links) {
        element.target = '_blank'
      }
    }

    presenterWindow.value.addEventListener('load', forceNotesLinksToOpenInNewWindow)

    // We want to be able to detect when the user closes the presenter window.
    // Typically, we would use `beforeunload` to check if the window was closing.
    // Unfortunately, it appears `beforeunload` isn't reliably being triggered
    // on windows opened with `window.open` in Chrome.
    //
    // We can, however, use the `pagehide` event as a substitute. This, however,
    // leads to another issue. `window.open` doesn't immediately load the requested
    // URL. It first loads with "about:blank" and then loads the requested URL after
    // the current script block finishes executing. When it loads the requested URL,
    // a `pagehide` event is triggered even though the window isn't being closed.
    //
    // For details about `window.open` initially loading with "about:blank" see:
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#description
    //
    // It seems the only reliable way to detect if the window is being closed is to
    // set a timeout in the `pagehide` event handler that checks the `closed` property
    // of the presenter window after a brief delay.
    //
    // For details about the suggested ways to handle page/window lifecycles, see:
    // https://developer.chrome.com/blog/page-lifecycle-api/#the-unload-event
    const checkIfPresenterWindowClosed = () => {
      setTimeout(() => {
        if (presenterWindow.value && presenterWindow.value.closed) {
          emit('before-clear-presenter-window')
          presenterWindow.value = null
        }
      }, 250)
    }

    presenterWindow.value.addEventListener('pagehide', checkIfPresenterWindowClosed)

    window.addEventListener('beforeunload', () => {
      if (presenterWindow.value) presenterWindow.value.close()
    })
  }

  return { openPresenterWindow }
}
