<template>
  <LzModal
    :modal-title="$t('assignment_status.assignment_actions.schedule_assignment')"
    class="lzui-modal--large"
    :modal-id="modalId"
    autoclose
  >
    <template #header-icon>
      <LzIcon path="icons/calendar" size="lg" class="text-neutral-600 fill-current" />
    </template>
    <div class="md:flex">
      <div class="flex-grow px-8 py-2">
        <h3 class="mb-2 mt-0 text-base">{{ $t('schedule_assignment_modal.open_date') }}</h3>
        <p class="text-sm">{{ $t('schedule_assignment_modal.when_to_open') }}</p>
        <div class="md:h-20">
          <label for="no-date" class="w-full">
            <input id="no-date" v-model="openType" name="open_type" type="radio" value="none" :disabled="someOpened" />
            {{ $t('schedule_assignment_modal.no_date_specified') }}
          </label>
          <label for="scheduled" class="w-full">
            <input
              id="scheduled"
              v-model="openType"
              name="open_type"
              type="radio"
              value="scheduled"
              :disabled="someOpened"
            />
            {{ $t('schedule_assignment_modal.scheduled') }}
          </label>
          <label for="open-now" class="w-full">
            <input id="open-now" v-model="openType" name="open_type" type="radio" value="now" :disabled="someOpened" />
            {{ $t('schedule_assignment_modal.open_now') }}
          </label>
        </div>
        <DateSelect
          v-if="isOpenTypeScheduled"
          v-model="openDate"
          enable-time
          inline
          label=""
          :min-date="minimumDate"
          :minute-increment="1"
          data-test="open-date-select"
        />
      </div>
      <div class="border-base flex-grow border-0 border-solid px-8 py-2 md:border-l">
        <h3 class="mb-2 mt-0 text-base">{{ $t('schedule_assignment_modal.close_date') }}</h3>
        <p class="text-sm">{{ $t('schedule_assignment_modal.when_to_close') }}</p>
        <div class="md:h-20">
          <label for="close-no-date" class="w-full">
            <input
              id="close-no-date"
              v-model="closeType"
              name="close_type"
              type="radio"
              value="none"
              :disabled="noOpenDateSpecified"
            />
            {{ $t('schedule_assignment_modal.no_date_specified') }}
          </label>
          <label for="close-scheduled" class="w-full">
            <input
              id="close-scheduled"
              v-model="closeType"
              name="close_type"
              type="radio"
              value="scheduled"
              :disabled="noOpenDateSpecified"
            />
            {{ $t('schedule_assignment_modal.scheduled') }}
          </label>
        </div>
        <DateSelect
          v-if="isCloseTypeScheduled"
          v-model="closeDate"
          enable-time
          inline
          label=""
          :min-date="minimumDate"
          :minute-increment="1"
          data-test="close-date-select"
        />
        <p
          v-if="closeDateBeforeOpenDate"
          class="text-orange-600 mb-0 pt-4 text-center text-sm"
          data-test="invalid-dates-error"
        >
          <LzIcon path="icons/exclamation" />
          {{ $t('schedule_assignment_modal.close_date_is_before_open_date') }}
        </p>
      </div>
    </div>
    <LzAccordion :label="$t('schedule_assignment_modal.what_are_my_options')" class="bg-neutral-50 bg-base mt-8">
      <p class="px-8 pt-2 text-sm">
        <strong>{{ $t('schedule_assignment_modal.no_date_specified') }}</strong>
        {{ $t('schedule_assignment_modal.no_date_specified_explanation') }}
      </p>
      <p class="px-8 pt-2 text-sm">
        <strong>{{ $t('schedule_assignment_modal.scheduled') }}</strong>
        {{ $t('schedule_assignment_modal.scheduled_explanation') }}
      </p>
      <p class="px-8 pt-2 text-sm">
        <strong>{{ $t('schedule_assignment_modal.open_now') }}</strong>
        {{ $t('schedule_assignment_modal.open_now_explanation') }}
      </p>
    </LzAccordion>
    <footer>
      <button class="btn" @click="hideModal">{{ $t('common.cancel') }}</button>
      <button
        :disabled="closeDateBeforeOpenDate"
        class="btn btn--primary"
        data-test="done-button"
        @click="updateScheduling"
      >
        {{ $t('common.done') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { LzAccordion, LzIcon, LzModal } from 'vue_features/shared/components/ui'
import { activeModal, closeModal, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import DateSelect from 'vue_features/shared/components/selects/DateSelect'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'

export default {
  name: 'ScheduleAssignmentModal',
  components: { DateSelect, LzAccordion, LzIcon, LzModal },
  props: {
    modalId: {
      type: String,
      default: function () {
        return 'ScheduleAssignmentModal'
      },
    },
  },

  setup(props) {
    const minimumDate = new Date()
    minimumDate.setSeconds(0)

    const { klassId, assignmentViews } = activeModal.value.payload
    const dateSort = (a, b) => a.getTime() - b.getTime()
    const earliestScheduledOpen = assignmentViews
      .filter((av) => av.assignmentState === 'created' && av.openAt)
      .map((av) => new Date(av.openAt))
      .sort(dateSort)[0]
    const earliestScheduledClose = assignmentViews
      .filter((av) => av.closeAt)
      .map((av) => new Date(av.closeAt))
      .sort(dateSort)[0]
    const initialOpenDate = earliestScheduledOpen || new Date()
    let initialCloseDate
    if (earliestScheduledClose && earliestScheduledClose > initialOpenDate) {
      initialCloseDate = earliestScheduledClose
    } else {
      initialCloseDate = initialOpenDate > new Date() ? new Date(initialOpenDate) : new Date()
      initialCloseDate.setHours(23, 59, 0, 0)
    }
    const someOpened = assignmentViews.some((av) => av.assignmentState === 'opened')

    const openType = ref(someOpened ? 'now' : 'scheduled')
    const openDate = ref(initialOpenDate)
    const closeType = ref(earliestScheduledClose ? 'scheduled' : 'none')
    const closeDate = ref(initialCloseDate)

    const noOpenDateSpecified = computed(() => openType.value === 'none')
    const isOpenTypeScheduled = computed(() => openType.value === 'scheduled')
    const isCloseTypeScheduled = computed(() => closeType.value === 'scheduled')

    const openAt = computed(() => {
      if (openType.value === 'none') {
        return null
      } else if (isOpenTypeScheduled.value) {
        return openDate.value
      } else {
        return new Date()
      }
    })

    const closeAt = computed(() => {
      return closeType.value === 'none' ? null : closeDate.value
    })

    const closeDateBeforeOpenDate = computed(() => isCloseTypeScheduled.value && closeDate.value < openDate.value)

    watch(noOpenDateSpecified, (newValue) => {
      if (newValue) {
        closeType.value = 'none'
      }
    })

    const hideModal = () => closeModal(props.modalId)

    const { changeAssignmentStateForKlass } = useAssignmentReportStore()

    const updateScheduling = () => {
      isModalBusy.value = true
      return changeAssignmentStateForKlass({
        klassId,
        assignmentViewIds: assignmentViews.map((av) => av.id),
        openAt: openAt.value,
        closeAt: closeAt.value,
      }).then(() => {
        isModalBusy.value = false
        hideModal()
      })
    }

    return {
      minimumDate,
      someOpened,
      openType,
      openDate,
      closeType,
      closeDate,
      noOpenDateSpecified,
      isOpenTypeScheduled,
      isCloseTypeScheduled,
      closeDateBeforeOpenDate,
      hideModal,
      isModalBusy,
      updateScheduling,
    }
  },
}
</script>
