<template>
  <div
    v-if="!isDismissed"
    :class="['mb-4 flex items-start space-x-4 overflow-hidden rounded-2xl border-2 border-solid', alertShell]"
    data-test="alert"
  >
    <InlineSvg :path="stateInfo.path" :class="['ml-4 mt-4 h-6 w-6 flex-shrink-0']" />
    <div :class="['flex flex-grow items-start justify-between', stateInfo.foreground]">
      <div class="flex-grow px-3 py-4">
        <slot>
          <p :class="['mb-0 space-x-1 text-sm leading-6', stateInfo.textColor]" data-test="error-content">
            <span class="font-bold">{{ title }}</span>
            <span v-if="!Array.isArray(description)">{{ description }}</span>
          </p>
          <ul v-if="Array.isArray(description)" class="ml-6 list-disc">
            <li v-for="item in description" :key="item" :class="['text-sm', stateInfo.textColor]">
              {{ item }}
            </li>
          </ul>
        </slot>
      </div>
      <button
        v-if="dismissible"
        data-test="alert-close-button"
        class="mt-2 h-10 cursor-pointer p-2 text-current"
        :aria-label="$t('dismiss')"
        @click="handleDismiss"
      >
        <span class="sr-only">{{ $t('common.close') }}</span>
        <InlineSvg path="icons/close" :class="['h-6 w-6', stateInfo.textColor]" />
      </button>
    </div>
  </div>
</template>
<script>
import InlineSvg from './InlineSvg'
import { ref, computed, watch, toRef } from 'vue'
import { components, common } from 'vue_features/shared/i18n'
import { pickLocaleNamespace, namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { merge } from 'lodash'

const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: 'close' }),
  pickLocaleNamespace(components, 'failure_modal.dismiss'),
)
export const STATES = {
  default: {
    alertBackground: 'bg-blue-200',
    alertBorder: 'border-blue-200',
    foreground: 'bg-blue-50',
    textColor: 'text-blue-800',
    path: 'icons/info',
  },
  alt: {
    alertBackground: 'bg-neutral-400',
    alertBorder: 'border-neutral-500',
    foreground: 'bg-neutral-200',
    textColor: 'text-base',
    path: 'icons/info',
  },
  error: {
    alertBackground: 'bg-red-200',
    alertBorder: 'border-red-200',
    foreground: 'bg-red-50',
    textColor: 'text-red-800',
    path: 'icons/exclamation',
  },
  notice: {
    alertBackground: 'bg-orange-200',
    alertBorder: 'border-orange-200',
    foreground: 'bg-orange-50',
    textColor: 'text-orange-800',
    path: 'icons/notice',
  },
  success: {
    alertBackground: ['bg-green-200'],
    alertBorder: 'border-green-200',
    foreground: 'bg-green-50',
    textColor: 'text-green-800',
    path: 'icons/check',
  },
}
export default {
  name: 'Alert',
  components: { InlineSvg },
  i18n: { messages },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (value) => Object.keys(STATES).includes(value),
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: [Array, String],
      default: '',
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isDismissed = ref(false)
    const stateInfo = computed(() => STATES[props.type])
    const alertShell = computed(
      () => `${stateInfo.value.alertBackground} ${stateInfo.value.alertBorder} ${stateInfo.value.textColor}`,
    )
    function handleDismiss() {
      isDismissed.value = true
      emit('dismissed')
    }
    watch(toRef(props.description), () => (isDismissed.value = false))
    return { handleDismiss, isDismissed, stateInfo, alertShell }
  },
}
</script>
