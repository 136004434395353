<template>
  <div class="page-head">
    <div class="page-head__wrapper">
      <div class="page-head__content">
        <h1>
          {{ $t('klasses.index.title') }}
        </h1>
      </div>
      <div class="page-head__actions">
        <KlassCreateButton v-if="authorizedAsTeacher" />
      </div>
    </div>
  </div>
</template>

<script>
import KlassCreateButton from './KlassCreateButton'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'PageHead',
  components: { KlassCreateButton },
  setup() {
    const { authorizedAsTeacher } = useCurrentUserStore()
    return { authorizedAsTeacher }
  },
}
</script>
