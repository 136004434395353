<script setup>
import { ref } from 'vue'
import { LzModal } from 'vue_features/shared/components/ui'
import Routes from 'routes'
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import ProgressBar from 'vue_features/shared/components/ui/loaders/ProgressBar'
import LzButton from 'vue_features/shared/components/ui/LzButton'
import useSidekiqPoller from 'vue_features/shared/composables/use_sidekiq_poller'

const props = defineProps({
  objectId: { type: [String, Number], required: true },
  objectIsResource: { type: Boolean, required: true },
  objectName: { type: String, required: true },
})

const editRevisionPath = props.objectIsResource
  ? Routes.edit_resource_revision_path(props.objectId)
  : Routes.edit_lesson_plan_revision_path(props.objectId)
const startedRevision = ref(false)

const startMethod = () => {
  startedRevision.value = true
  const url = props.objectIsResource
    ? Routes.resource_revision_path(props.objectId)
    : Routes.lesson_plan_revision_path(props.objectId)
  return HTTP.create(url)
}
const { startSidekiqBatch, pollerActive, percent, raisePercent, isComplete } = useSidekiqPoller(startMethod)

const startRevision = async () => {
  await startSidekiqBatch()
  percent.value = 100
}
const updatePercent = () => raisePercent(percent.value)
</script>

<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="startedRevision ? '' : $t('action_menu.ready_to_start_revision')"
    :can-cancel="!startedRevision"
    :class="{ 'lzui-modal--confirmation': startedRevision }"
    modal-id="StartRevisionModal"
  >
    <div class="p-8">
      <section>
        <ProgressBar
          v-if="startedRevision"
          :active="pollerActive"
          :percent="percent"
          :job="$t('common.revision')"
          @complete="isComplete = true"
          @tick="updatePercent"
        />
        <div v-else class="align-left space-y-4 pb-4">
          <p class="max-w-prose">{{ $t('action_menu.revision_explanation', { object_name: objectName }) }}</p>
          <p class="max-w-prose">
            <span class="font-bold">
              {{ $t('action_menu.why_revisions') }}
            </span>
            {{ $t('action_menu.revision_justification') }}
          </p>
        </div>
      </section>
      <footer>
        <a v-if="isComplete" :href="editRevisionPath" class="btn btn--primary">
          {{ $t('action_menu.go_to_revision') }}
        </a>
        <template v-else-if="!startedRevision">
          <LzButton @click="close">{{ $t('common.cancel') }}</LzButton>
          <LzButton data-test="start-revision-btn" color="green" @click.once="startRevision">
            {{ $t('common.start_revision') }}
          </LzButton>
        </template>
      </footer>
    </div>
  </LzModal>
</template>
