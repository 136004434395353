import { compact, get, startCase } from 'lodash'
import { max } from 'd3'
import { keyTransform } from 'utils'

export const generateFilteredUsageData = ({ usageDataResponse }) => {
  const { data, filters } = usageDataResponse

  if (!data?.lessonsViewed || !filters) return {}

  const indexedResponse = data.lessonsViewed.reduce((output, { category, lessonsViewed }) => {
    output[category] = lessonsViewed
    return output
  }, {})

  return indexedResponse
}

export const generateDonutChartData = ({ scoreBandsResponse }) => {
  const { data } = scoreBandsResponse

  return {
    averageScore: data.averageScore,
    bands: data.bands.map((band, index, arr) => ({
      band: band.band,
      label: `${band.minScore} - ${index < arr.length - 1 ? band.maxScore - 1 : band.maxScore}%`,
      value: band.sessionCount,
    })),
  }
}

export const generateRankedStandardsData = ({ rankedStandardsResponse, dataSet }) => {
  const { data } = rankedStandardsResponse

  return data[dataSet].map((standard) => ({
    label: standard.standardCode,
    description: standard.standardDescription,
    value: standard.averageScore,
  }))
}

export const generatePerformanceAndUsageTableData = ({ categoryRollupResponse, filteredUsageData = {} }) => {
  const { data, filters } = categoryRollupResponse
  const usageDataPresent = Object.keys(filteredUsageData).length > 0
  const includeUsageData = ['gradeLevel', 'school'].includes(filters.drillDownLevel)
  const maxLessonsUsed = usageDataPresent ? max(Object.values(filteredUsageData)) : 0
  const drillDownCategory = filters.drillDownLevel

  const { groups, groupScores, groupedByKlass } = data

  const columns = compact([
    { field: 'categoryName', label: startCase(filters.drillDownLevel) },
    { field: 'averageScore', label: 'Average score', tooltip: 'Average score' },
    includeUsageData && { field: 'lessonsUsed', label: 'Lessons used', tooltip: 'Lessons used' },
  ])

  const groupedRows = groups.map((group) => {
    const { categories = [], categoryScores = {} } = groupScores[group.id] || {}
    const formattedCategoryScores = keyTransform(categoryScores, (key) => key.toString().toLowerCase())

    const rows = categories.map((category) => {
      const row = formattedCategoryScores[category.id.toString().toLowerCase()]
      const lessonsUsed = includeUsageData ? { lessonsUsed: filteredUsageData[category.name] } : {}

      return {
        categoryId: category.id,
        categoryName: category.name,
        averageScore: row.averageScore,
        sessionCount: row.sessionCount,
        ...lessonsUsed,
      }
    })

    const groupAverageScore = rows.reduce((sum, row) => sum + row.averageScore, 0) / rows.length

    return {
      groupId: group.id,
      groupName: group.name || 'Ungrouped',
      groupActive: group.active,
      groupAverageScore,
      columns,
      rows,
      maxLessonsUsed,
      drillDownCategory,
    }
  })

  return {
    groups: groupedRows,
    groupedByKlass,
    drillDownCategory,
  }
}

export const generateStandardsTableData = ({
  rankedStandardsResponse,
  categoryItemsRollupResponse,
  categoryStandardsRollupResponse,
  standardsFilter,
}) => {
  const { filters } = categoryStandardsRollupResponse
  const { categories, standards: categoryStandards, categoryStandardScores } = categoryStandardsRollupResponse.data
  const { categoryScores } = categoryItemsRollupResponse.data
  const rankedStandards = rankedStandardsResponse.data
  const categoryLabel = startCase(filters.drillDownLevel)
  const formattedCategoryScores = keyTransform(categoryScores, (key) => key.toString().toLowerCase())
  const formattedCategoryStandardScores = keyTransform(categoryStandardScores, (key) => key.toString().toLowerCase())

  let standards = []

  if (['top', 'bottom'].includes(standardsFilter)) {
    const key = `${standardsFilter}5`

    standards = rankedStandards[key].map((standard) => ({
      id: standard.standardId,
      code: standard.standardCode,
      description: standard.standardDescription,
      standardInitiative: standard.standardInitiative,
    }))
  } else {
    standards = categoryStandards.sort((a, b) => {
      const aWithStandardInitiative = `${a.standardInitiative}.${a.code}`
      const bWithStandardInitiative = `${b.standardInitiative}.${b.code}`
      return aWithStandardInitiative.localeCompare(bWithStandardInitiative, 'en', { sensitivity: 'base' })
    })
  }

  const columns = [
    { field: 'categoryName', label: categoryLabel },
    ...standards.map((standard) => ({
      field: `standard_${standard.id}`,
      label: standard.code,
      tag: standard.standardInitiative,
      tooltip: `[${standard.code}] ${standard.description}`,
    })),
  ]

  const rows = categories.map((category) => {
    const averageScore = get(formattedCategoryScores, [category.id.toLowerCase(), 'averageScore'], null)
    const standardFields = standards.reduce((fields, standard) => {
      const standardValue = get(
        formattedCategoryStandardScores,
        [category.id.toString().toLowerCase(), standard.id.toString().toLowerCase()],
        {},
      )
      fields[`standard_${standard.id}`] = standardValue.averageScore
      return fields
    }, {})
    const orderField = category.order ? { categoryOrder: category.order } : {}

    return {
      categoryId: category.id,
      categoryName: category.name,
      categoryAverage: averageScore,
      ...orderField,
      ...standardFields,
    }
  })

  return { columns, rows, standards, drillDownCategory: filters.drillDownLevel }
}
