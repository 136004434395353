<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzModal
    :modal-title="$t('modals.edit_school.title')"
    :modal-id="$options.name"
    class="lzui-modal--small"
    @close-modal="hideModal"
  >
    <div class="form-group">
      <label>{{ $t('common.name') }}</label>
      <span v-if="isNameEmpty" class="error--inline">
        {{ $t('common.field_required') }}
      </span>
      <input v-model.trim="schoolName" class="form-control" type="text" @keyup.enter="save" />
    </div>
    <footer>
      <button class="btn" @click.stop="hideModal">
        {{ $t('common.cancel') }}
      </button>
      <button :disabled="isNameEmpty" class="btn btn--primary" @click="save">
        {{ $t('common.save') }}
      </button>
    </footer>
  </LzModal>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { isEmpty } from 'lodash'
import LzModal from 'vue_features/shared/components/ui/LzModal'
import useSchoolModals from './use_school_modals.js'
import { useSiteAdminSchoolsStore } from '../../store/use_site_admin_schools_store'

const NAME = 'SchoolModalEdit'

export default {
  name: NAME,
  components: { LzModal },
  setup(_, { emit }) {
    const root = inject('useRoot')()
    const { updateSchool } = useSiteAdminSchoolsStore()
    const { school, hideModal } = useSchoolModals(NAME)
    const schoolName = ref(school.value.name)
    const isNameEmpty = computed(() => isEmpty(schoolName.value))

    const save = async () => {
      await updateSchool({ id: school.value.id, name: schoolName.value })
      hideModal()
      emit('show-notice', root.$t('index.success_update_school'))
    }

    return { schoolName, isNameEmpty, save, hideModal }
  },
}
</script>
