import { onUnmounted, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import location from 'utils/location'
import { useAssignmentStore, useAssignmentCardsStore, useLiveLearnStore, useAssignmentShowStore } from './'
import consumer from 'channels/consumer.js'
import Routes from 'routes'
import { useAnnotationToolStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useAnnotationCanvasStore } from 'vue_features/lesson_plans/store/use_annotation_canvas_store'

const THREE_SECONDS = 3000
const subscription = ref(null)
const isConnecting = ref(false)
const connected = ref(false)
const userHeartbeater = ref(null)
let componentsUsingSubscription = 0

export default function useLiveLearnChannel() {
  const { assignment } = useAssignmentStore()
  const { isStudent } = useCurrentUserStore()
  const {
    selectedAssignmentCardId,
    assignmentDisplayCards,
    assignmentDisplayCardsPosition,
    getAssignmentCardPosition,
  } = useAssignmentCardsStore()
  const {
    state: liveLearnState,
    klassId,
    isTeacherPaced,
    students,
    lzCodeViews,
    allStudentIds,
    selectedStudent,
    sharingStudentId,
    loadResponseData,
    updateAssessmentConfig,
  } = useLiveLearnStore()
  const { state: assignmentShowState, waitForLearnosityItemsApp } = useAssignmentShowStore()

  function handleNewPosition(position) {
    if (position === undefined || position === null) {
      return
    }

    const currentPosition = assignmentDisplayCardsPosition.value

    if (position === currentPosition) {
      return
    }

    const cardAtPosition = assignmentDisplayCards.value[position]

    if (cardAtPosition && (isTeacherPaced.value || sharingStudentId.value || isConnecting.value)) {
      hideLearnosityCalculator()
      selectedAssignmentCardId.value = cardAtPosition.id
    }
  }

  function hideLearnosityCalculator() {
    // Originally written to close the calculator when the teacher navigates
    // to a new card, and the student has the calculator open.
    //
    // If a student has the calculator open, and the card changes, this
    // will similate a click off of the question element to close the calculator,
    // so the student can't see it anymore.
    //
    // This is a better approach than applying `display: none` since it will
    // work better with how LRNO has engineered their calculator to work.
    //
    // When going back to the card and clicking on a field that brings up the
    // calculator, Learnosity's code makes it reappear
    //
    // For more details, see the LRNO support ticket:
    // https://help.learnosity.com/hc/requests/18009
    let dummyElement = document.querySelector('#learnosity-calculator-dummy')
    if (!dummyElement) {
      dummyElement = document.createElement('div')
      dummyElement.setAttribute('id', 'learnosity-calculator-dummy')
      document.body.appendChild(dummyElement)
    }
    dummyElement.click()
  }

  function updateConnectionStatuses() {
    const connectedStudentIds = allStudentIds.value.filter((key) => {
      const tenSecondsAgo = dayjs().subtract(10, 'second')
      const studentLastSeenAt = students.value[key] && students.value[key].last_seen_at
      return studentLastSeenAt && dayjs(studentLastSeenAt).isAfter(tenSecondsAgo)
    })
    liveLearnState.connectedStudentIds = connectedStudentIds
  }

  function startSharing() {
    subscription.value.perform('update_root_state', {
      sharing_student_id: parseInt(selectedStudent.value.id, 10),
      position: getAssignmentCardPosition(selectedAssignmentCardId.value),
    })
  }

  function stopSharing() {
    subscription.value.perform('update_root_state', {
      sharing_student_id: null,
    })
  }

  async function handleNewSharingStudentId(sharingStudentId, position) {
    await waitForLearnosityItemsApp()

    liveLearnState.lnroConfigBeforeSharing = assignmentShowState.lrnoAssessmentConfig
    liveLearnState.sharingStudentId = sharingStudentId
    handleNewPosition(position)
    loadResponseData()
  }

  function handleNullSharingStudentId() {
    const config = liveLearnState.lnroConfigBeforeSharing || liveLearnState.initialLrnoAssessmentConfig
    liveLearnState.lnroConfigBeforeSharing = null
    liveLearnState.sharingStudentId = null
    updateAssessmentConfig({ ...config })
  }

  if (!subscription.value && assignment.value && liveLearnState.isLiveLearn) {
    isConnecting.value = true
    subscription.value = consumer.subscriptions.create(
      {
        channel: 'SlideshowChannel',
        slideshow_session_id: `live_learn_${assignment.value.code}_${klassId.value}`,
        lz_code: assignment.value.code,
        klass_id: klassId.value,
      },
      {
        connected() {
          connected.value = true
          this.perform('update_user_state', { position: assignmentDisplayCardsPosition.value || 0 })
        },
        disconnected() {
          connected.value = false
          isConnecting.value = true
        },
        received(message) {
          const { showAnnotationTools, showAnnotations } = useAnnotationToolStore()
          const { setHistory, updateCanvas, undo } = useAnnotationCanvasStore()

          if ('destroyed' in message && message['destroyed'] === true) {
            location.assign(
              isStudent.value
                ? Routes.student_klass_path(klassId.value)
                : Routes.lz_code_path(assignment.value.code, { anchor: `assignment-report-${klassId.value}` }),
            )
          } else {
            if ('teacher_paced' in message) {
              liveLearnState.isTeacherPaced = message['teacher_paced']
            }

            if ('sharing_student_id' in message && message['sharing_student_id'] !== liveLearnState.sharingStudentId) {
              const id = message['sharing_student_id']

              if (id) {
                handleNewSharingStudentId(id, message['position'])
              } else {
                handleNullSharingStudentId()
              }
            } else if ('position' in message) {
              handleNewPosition(message['position'])
            }

            if ('students' in message) {
              liveLearnState.students = { ...students.value, ...message['students'] }
            }

            if ('aggregate_responses' in message && !isStudent.value) {
              liveLearnState.aggregateResponses = message['aggregate_responses']
            }

            if ('show_annotation_tools' in message) {
              showAnnotationTools.value = message['show_annotation_tools']
            }

            if ('show_annotations' in message) {
              showAnnotations.value = message['show_annotations']
            }

            if ('undo_annotation' in message) {
              undo()
            }

            if ('diff' in message) {
              updateCanvas(message['card_id'], message['timestamp'], message['diff'])
            }

            if ('canvas_history' in message) {
              setHistory(message['canvas_history'])
            }

            if (!isStudent.value) updateConnectionStatuses()
            isConnecting.value = false
          }
        },
        rejected() {
          location.assign(Routes.lz_code_path(assignment.value.code))
        },
      },
    )

    if (!isStudent.value) {
      lzCodeViews.value.forEach((lzCodeView) => {
        consumer.subscriptions.create(
          { channel: 'LzCodeViewChannel', id: lzCodeView.id },
          {
            received({ studentId }) {
              const isUpdatedStudentSelected = selectedStudent.value.id && selectedStudent.value.id === studentId
              if (isUpdatedStudentSelected) liveLearnState.isNewDataAvailable = true
            },
          },
          {},
        )
      })
    }
  }

  if (!userHeartbeater.value && subscription.value) {
    userHeartbeater.value = setInterval(
      () => subscription.value.perform('update_user_state', { position: assignmentDisplayCardsPosition.value }),
      THREE_SECONDS,
    )
    onUnmounted(() => clearInterval(userHeartbeater.value))
  }

  onMounted(() => componentsUsingSubscription++)
  onUnmounted(() => {
    componentsUsingSubscription--
    if (!componentsUsingSubscription && subscription.value) {
      subscription.value.unsubscribe()
      subscription.value = null
    }
  })

  return { subscription, connected, isConnecting, startSharing, stopSharing }
}
