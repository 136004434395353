import { reactive, computed } from 'vue'
import {
  BookmarksService,
  FolderPlacementsService,
  BookmarkFoldersService,
  PersonalCopiesService,
  AuthoredLessonPlansService,
} from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'

export const useBookmarksStore = defineStore('bookmarks', () => {
  const state = reactive({
    bookmarksData: {
      bookmarks: [],
      meta: {
        pagination: { totalCount: 0 },
      },
      title: '',
      description: '',
    },
    activeFolderId: null,
    foldersData: {
      folders: [],
      meta: {
        pagination: { totalCount: 0 },
      },
    },
    isLoadingBookmarks: true,
    allowPersonalCopies: true,
  })

  const bookmarks = computed(() => state.bookmarksData.bookmarks)
  const bookmarksPagination = computed(() => state.bookmarksData.meta.pagination)

  const folders = computed(() => state.foldersData.folders)
  const foldersPagination = computed(() => state.foldersData.meta.pagination)
  const totalFolders = computed(() => state.foldersData.meta.pagination.totalCount)

  const inFolder = computed(() => state.activeFolderId && Number.parseInt(state.activeFolderId) > 0)

  const getBookmarks = (page) => {
    if (state.activeFolderId === 'personal_copies') {
      return PersonalCopiesService.get(page)
    } else if (state.activeFolderId === 'authored_lps') {
      return AuthoredLessonPlansService.get(page)
    } else if (state.activeFolderId) {
      return BookmarkFoldersService.get(state.activeFolderId, page)
    }
    return BookmarksService.get(page)
  }

  async function loadInitialBookmarks(id) {
    if (state.activeFolderId !== id) {
      state.activeFolderId = id
      state.isLoadingBookmarks = true
      state.bookmarksData = await getBookmarks(1)
      state.isLoadingBookmarks = false
    }
  }
  async function loadMoreBookmarks() {
    const newData = await getBookmarks(bookmarksPagination.value.nextPage)
    state.bookmarksData.meta = newData.meta
    state.bookmarksData.folderTitle = newData.title
    state.bookmarksData.folderDescription = newData.description
    state.bookmarksData.bookmarks.push(...newData.bookmarks)
  }

  async function loadMoreFolders() {
    const newData = await BookmarkFoldersService.getFolders(foldersPagination.value.nextPage)
    state.foldersData.meta = newData.meta
    state.foldersData.folders.push(...newData.folders)
  }

  async function createFolder(title) {
    const folder = await BookmarkFoldersService.create(title)
    state.foldersData.folders.unshift(folder)
    state.foldersData.meta.pagination.totalCount++
    return folder
  }

  async function updateRank(placement, newRank) {
    placement.rank = newRank
    await FolderPlacementsService.update(placement.bookmarkId, placement.id, newRank)
  }

  async function deleteBookmark(bookmark) {
    if (state.activeFolderId) {
      await FolderPlacementsService.destroy(bookmark.bookmarkId, state.activeFolderId)
    } else {
      await BookmarksService.destroy(bookmark.id)
    }
    const index = bookmarks.value.indexOf(bookmark)
    state.bookmarksData.bookmarks.splice(index, 1)
    state.bookmarksData.meta.pagination.totalCount--
  }

  async function deleteFolder() {
    const id = state.activeFolderId
    await BookmarkFoldersService.destroy({ id })
    const index = folders.value.findIndex((folder) => `${folder.id}` === id)
    state.foldersData.folders.splice(index, 1)
    state.foldersData.meta.pagination.totalCount--
  }

  const folderTitle = computed(() => state.bookmarksData.title)

  const updateFolder = async ({ title, description }) => {
    const newFolderData = await BookmarkFoldersService.update(state.activeFolderId, { title, description })
    state.bookmarksData.title = newFolderData.title
    state.bookmarksData.description = newFolderData.description
    state.foldersData.folders.every((folder) => {
      if (`${folder.id}` === state.activeFolderId) {
        folder.title = newFolderData.title
        return false
      }
      return true
    })
  }

  const folderDescription = computed(() => state.bookmarksData.description)

  return {
    state,
    loadInitialBookmarks,
    loadMoreBookmarks,
    deleteBookmark,
    bookmarks,
    bookmarksPagination,
    folders,
    foldersPagination,
    loadMoreFolders,
    createFolder,
    folderTitle,
    folderDescription,
    updateFolder,
    deleteFolder,
    inFolder,
    updateRank,
    totalFolders,
  }
})
