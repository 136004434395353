<template>
  <div v-if="totalPages > 1" class="flex items-center justify-center gap-x-2">
    <LzButton :disabled="disablePreviousButton" @click="emit('goto-previous-page')">
      <LzIcon path="icons/chevron-left" class="flex-shrink-0" />
      {{ $t('common.previous') }}
    </LzButton>

    <span>
      {{ currentPagination }}
    </span>

    <LzButton :disabled="disableNextButton" @click="emit('goto-next-page')">
      {{ $t('common.next') }}
      <LzIcon path="icons/chevron-right" class="flex-shrink-0" />
    </LzButton>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import LzButton from 'vue_features/shared/components/ui/LzButton'

const emit = defineEmits(['goto-previous-page', 'goto-next-page'])

const root = inject('useRoot')()
const { page, perPage, totalPages, rows } = inject('coverageTable')

const disablePreviousButton = computed(() => page.value === 0)
const disableNextButton = computed(() => page.value === totalPages.value - 1)
const currentPagination = computed(() =>
  root.$t('common.current_pagination', {
    start: page.value * perPage.value + 1,
    end: Math.min((page.value + 1) * perPage.value, rows.value.length),
    total: rows.value.length,
  }),
)
</script>
