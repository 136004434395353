import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import { common, resources, shared, simpleForm, siteAdmin } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(common, 'common'),
  pickLocaleNamespace(shared, 'login_via_external'),
  pickLocaleNamespace(shared, 'login_teacher'),
  pickLocaleNamespace(shared, 'login'),
  pickLocaleNamespace(simpleForm, 'labels.session'),
  elevateLocaleNamespace(siteAdmin, 'user_import_modals'),
  resources,
)
