<template>
  <ScoringLegendIcon :icon="icon" :icon-class="iconClass" :label="scoreText" :color="iconColor" />
</template>

<script>
import { computed } from 'vue'
import { scoreBandIcon } from '../utils'
import { scoreBandColors } from 'vue_features/shared/utils/scoring'
import { defaultScoreBandRanges, ratingForScore } from 'vue_features/shared/utils/scoring'
import ScoringLegendIcon from 'vue_features/shared/components/ScoringLegendIcon'

export default {
  name: 'ScoreWithIcon',
  components: {
    ScoringLegendIcon,
  },
  props: {
    scoreBands: {
      type: Object,
      default: () => defaultScoreBandRanges,
    },
    score: {
      type: Number,
      default: null,
      validator: (val) => typeof val === 'number' || val === null,
    },
  },
  setup(props) {
    const scoreBands = computed(() => props.scoreBands || {})
    const hasScore = computed(() => typeof props.score === 'number' && props.score >= 0)
    const rating = computed(() => (hasScore.value ? ratingForScore(props.score, scoreBands.value) : 'no-score'))
    const icon = computed(() => (hasScore.value ? scoreBandIcon(rating.value) : 'square'))
    const iconClass = computed(() => `results-score--${rating.value}`)
    const scoreText = computed(() => (hasScore.value ? `${props.score}%` : 'No score'))
    const iconColor = computed(() => scoreBandColors[rating.value])

    return { iconClass, icon, scoreText, iconColor }
  },
}
</script>
