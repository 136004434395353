<template>
  <div
    class="min-h-screen bg-cover bg-no-repeat p-8 md:pt-16"
    :style="{ backgroundImage: `url('${require('images/misc/hero-student-background.jpg')}')` }"
  >
    <div
      class="mx-auto flex w-11/12 flex-col items-center rounded-xl bg-white px-4 py-8 shadow-2xl md:max-w-xl md:p-3 md:px-8 md:py-8"
    >
      <h2 class="mb-0">{{ $t('customer_selects.show.customer_subdomain_heading') }}</h2>
      <p class="text-primary mb-5">{{ $t('customer_selects.show.customer_subdomain_prompt') }}</p>
      <div class="">
        <label for="subdomain-input" class="relative w-64 min-w-full" data-domain="ilclassroom.com">
          <span class="sr-only">{{ $t('customer_selects.show.customer_subdomain_prompt') }}</span>
          <input
            id="subdomain-input"
            v-model="subdomain"
            class="w-100 pr-200 relative box-border block"
            type="text"
            :placeholder="$t('customer_selects.show.domain_placeholder')"
            name="subdomain"
            @keyup.enter="navigate"
          />
        </label>
      </div>
      <div class="center">
        <div class="max-w-sm pt-4">
          <LzButton class="w-full" color="primary" :disabled="!subdomain" @click="navigate">
            {{ $t('customer_selects.show.continue') }}
          </LzButton>
          <p class="small center text-shadow py-5 text-base"> {{ $t('customer_selects.show.unsure_of_subdomain') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { LzButton } from 'vue_features/shared/components/ui'
import { location as $location } from 'utils'
import Routes from 'routes'

export default {
  name: 'CustomerSelects',
  components: {
    LzButton,
  },
  setup(_props) {
    const subdomain = ref('')
    const navigate = () => {
      let url
      if (subdomain.value === 'www') {
        // Because only IL employees log in on the root site, we sort of hide
        // the login page by having the login button link directly to
        // customer_selects. An employee can get to the login page by entering
        // www as the subdomain . . . or just navigate to /login manually.
        url = Routes.login_path()
      } else {
        url = `${$location.protocol()}//${subdomain.value}.${$location.domain()}${$location.search()}`
      }

      $location.assign(url)
    }

    return {
      subdomain,
      navigate,
    }
  },
}
</script>

<style scoped>
label::after {
  content: '.' attr(data-domain);
  position: absolute;
  top: 9px;
  right: 8px;
}
</style>
