<template>
  <div class="mx-auto max-w-7xl px-3 md:px-6">
    <ProgressCover :visible="submitting" />
    <div class="my-4 lg:w-3/4">
      <h1 class="h3 m-0">{{ $t('embed_header') }}</h1>
      <RailsForm
        v-show="acceptMultiple"
        id="lti-content-form"
        ref="ltiContentForm"
        :action="Routes.launch_lti_v13_content_items_path()"
        method="post"
      >
        <LzAccordion
          v-if="selectedContents.length > 0"
          :label="$t('selected_contents')"
          :open="true"
          color="base"
          class="mb-6 mt-4"
        >
          <ul data-test="selected-contents" class="my-6 space-y-2">
            <DetailListItem
              v-for="item in selectedContents"
              :key="item.id"
              :item-id="item.id"
              :title="item.name"
              :thumbnail="getThumbnail(item)"
              :href="item.url"
              thumbnail-size="m"
            />
          </ul>
        </LzAccordion>
        <input id="content_items" :value="contentItemsJson" name="content_items" type="hidden" />
        <div v-if="showSubmit" class="align-right">
          <button data-test="clear-btn" class="btn btn--demoted" @click="selectedContents = []">
            {{ $t('common.clear_selections') }}
          </button>
          <button data-test="submit-btn" type="submit" class="btn btn--primary">
            {{ $t('add_content') }}
          </button>
        </div>
      </RailsForm>
    </div>
    <div class="space-y-6">
      <div class="lg:w-3/4">
        <SearchBox v-model="tempQuery" :placeholder="$t('common.search_placeholder_short')" @submit="resetSearch" />
        <SearchResults
          v-bind="{
            showHeader: true,
            hasActiveSearch,
            canLoadMore,
            isLoadingSearchResults,
            isLoading,
            searchResults,
            visibleItems,
            selectedItems: selectedContents,
            hiddenFilters: ['content_type'],
          }"
          @load-more="handleLoadMore"
          @selected-item="selectContent"
          @deselected-item="(itemIndex) => selectedContents.splice(itemIndex, 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { ref, computed, watch, nextTick } from 'vue'
import RailsForm from 'vue_features/shared/components/forms/RailsForm'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import SearchBox from 'vue_features/shared/components/search/SearchBox'
import SearchResults from 'vue_features/shared/components/search/SearchResults'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import useContentItemsJson from 'vue_features/lti/composables/use_content_items_json'
import ProgressCover from 'vue_features/shared/components/ui/loaders/ProgressCover'
import LzAccordion from 'vue_features/shared/components/ui/LzAccordion'
import { getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'
import Routes from 'routes'

export default {
  name: 'ContentItemsV13Index',
  components: { ProgressCover, LzAccordion, SearchBox, SearchResults, DetailListItem, RailsForm },
  props: {
    isAssignmentSelectionRequest: { type: Boolean, required: true },
    acceptMediaTypes: { type: Array, required: true },
    acceptMultiple: { type: Boolean, required: true },
  },
  setup(props) {
    const {
      tempQuery,
      search,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      totalSearchCount,
      hasActiveSearch,
      modalFilters,
    } = useModalSearch()
    modalFilters.facets = true
    if (props.isAssignmentSelectionRequest) {
      modalFilters.lesson_type = 'assessment'
    }

    const updateSearch = () => {
      resetSearch()
      search()
    }

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: searchResults,
      totalCount: totalSearchCount,
      startCount: 20,
      moreCount: 20,
    })
    const handleLoadMore = () => showMoreItems(search)

    modalFilters.m = 'LessonPlan'

    const { filterParams, query } = useSearchFiltersStore()
    const lastSearch = filterParams.value

    updateSearch()

    watch(filterParams, () => {
      if (!isEqual(filterParams.value, lastSearch.value)) {
        tempQuery.value = query.value
        updateSearch()
      }
    })

    const ltiContentForm = ref(null)
    const selectedContents = ref([])
    const showSubmit = computed(() => {
      return selectedContents.value.length > 0
    })
    const submitting = ref(false)
    const selectContent = async (item) => {
      selectedContents.value.push(item)
      if (!props.acceptMultiple) {
        submitting.value = true
        await nextTick()
        ltiContentForm.value.$el.submit()
      }
    }
    const { contentItemsJson } = useContentItemsJson({
      selectedContents,
      mediaTypes: props.acceptMediaTypes,
    })

    return {
      Routes,
      tempQuery,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      hasActiveSearch,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      handleLoadMore,
      selectedContents,
      showSubmit,
      selectContent,
      contentItemsJson,
      ltiContentForm,
      submitting,
      getThumbnail,
    }
  },
}
</script>
