<template>
  <RightDrawer
    :title="$t('lesson_card_templates.teaching_notes')"
    :is-open="isOpen"
    background-color="gray"
    :data-test="`teaching-notes-drawer-${isOpen ? 'open' : 'closed'}`"
  >
    <!-- notes sanitized in serializer -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div ref="contentEl" class="px-6 text-left text-sm" v-html="isOpen ? content : ''" />
  </RightDrawer>
</template>

<script>
import { onUpdated, ref } from 'vue'
import { rerenderMathJax } from 'clients/mathjax'
import RightDrawer from './RightDrawer'

export default {
  name: 'TeachingNotesDrawer',
  components: { RightDrawer },
  props: {
    isOpen: {
      type: Boolean,
      require: false,
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup() {
    const contentEl = ref(null)

    const resetScroll = (ref) => {
      ref?.value?.offsetParent?.scrollTo(0, 0)
    }

    onUpdated(() => {
      rerenderMathJax(contentEl.value)
      resetScroll(contentEl)
    })

    return { contentEl }
  },
}
</script>
