export function alignedLeft(content, contentCheckedAgainst) {
  return content.column === contentCheckedAgainst.column
}

export function alignedBottom(content, contentCheckedAgainst) {
  return bottomRow(content) === bottomRow(contentCheckedAgainst)
}

export function alignedRight(content, contentCheckedAgainst) {
  return rightMostColumn(content) === rightMostColumn(contentCheckedAgainst)
}

export function alignedTop(content, contentCheckedAgainst) {
  return content.row === contentCheckedAgainst.row
}

export function isAbove(content, contentCheckedAgainst) {
  return content.row < contentCheckedAgainst.row
}

export function isBelow(content, contentCheckedAgainst) {
  return bottomRow(content) > bottomRow(contentCheckedAgainst)
}

export function isToLeftOf(content, contentCheckedAgainst) {
  return content.column < contentCheckedAgainst.column
}

export function isToRightOf(content, contentCheckedAgainst) {
  return rightMostColumn(content) > rightMostColumn(contentCheckedAgainst)
}

export function bottomRow(content) {
  return content.row + content.rowSpan - 1
}

export function rightMostColumn(content) {
  return content.column + content.colSpan - 1
}
