import { computed } from 'vue'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'

export default function useCoverImageAttributes(coverImageUrl, logoUrl) {
  return computed(() => {
    const { fileUrl } = useFilestackAccessor(coverImageUrl)
    let background = 'linear-gradient(57deg,rgba(var(--primary-800),0.90) 0%, rgba(var(--primary-accent),0.00) 100%)'
    if (fileUrl.value || logoUrl) {
      background += `, url(${fileUrl.value || logoUrl})`
    }
    return {
      backgroundImage: background,
    }
  })
}
