/*
  Essentially a fallback for test environments (local and CI) that don't spin up our Rails app.
  See config/initializers/rails_constants.rb
*/

export const DATA = {
  config: {
    asset: {
      hostUrl: null,
    },
    coverImage: {
      accept: ['.jpg', '.JPG', '.jpeg', '.png', '.gif', '.webp'],
      maxSize: 1048576,
    },
    webSocketConfig: {
      path: '/cable',
    },
    customer: {
      siteTypesRequiringCustomerType: ['customer', 'pilot'],
    },
    pagination: {
      windowSize: 2,
    },
    image: {
      accept: ['.jpg', '.JPG', '.jpeg', '.png', '.gif', '.webp'],
      maxSize: 5242880,
    },
    imageWithSvg: {
      accept: ['.jpg', '.JPG', '.jpeg', '.png', '.gif', '.webp', '.svg', '.svg+xml'],
      maxSize: 5242880,
    },
    video: {
      accept: ['.mp4', '.m4a', '.avi', '.mpg', '.wmv', '.mov', '.m4v'],
      maxSize: 12884901888,
    },
    videoCaption: {
      accept: ['.srt'],
      languageCodes: { eng: 'English', spa: 'Spanish' },
    },
    document: {
      accept: ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx'],
      maxSize: 3358720,
    },
    wiki: {
      accept: ['.jpg', '.JPG', '.jpeg', '.png', '.gif', '.webp'],
      maxSize: 1048576,
    },
    audio: {
      accept: ['.mp3'],
      maxSize: 16777216,
    },
    file: {
      accept: [
        '.pdf',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx',
        '.xls',
        '.xlsx',
        '.jpg',
        '.jpeg',
        '.gif',
        '.png',
        '.mp4',
        '.m4a',
        '.avi',
        '.mpg',
        '.wmv',
        '.mov',
        '.m4v',
        '.txt',
        '.csv',
        '.mp3',
      ],
      maxSize: 12884901888,
    },
    lzCode: {
      code: {
        length: 7,
        regexp: ['^[ABCDEFGHJKMNPQRSTUVWXYZ23456789]+$', 'i'],
      },
    },
    fullStory: {
      expires: 30,
    },
    publicCustomerId: 0,
    returnToCookieName: 'return_to_without_domain',
  },
  availableQuestionBanks: [
    'Additional Practice',
    'Digitized Cold Read Tasks',
    'CenterPoint Sample Math 6-8',
    'EL Unit Assessments',
    'ELA Bank',
    'ELA Quizzes',
    'Guided Collaborative Tasks',
    'Illustrative Mathematics K-5',
    'Illustrative Mathematics 6-8',
    'Illustrative Mathematics - Algebra 1',
    'Illustrative Mathematics - Algebra 2',
    'Illustrative Mathematics - Geometry',
    'Math Unit Assessments',
    'Math Bank',
    'Numbered Skills',
    'Online Practice',
  ],
  deprecatedResourceTypes: ['GeogebraItem'],
  helpLmsIntegrationUrl: '/wikis/1891336-how-to-integrate-il-classroom-with-your-lms',
  helpResizeFilesUrl: '/wikis/2056345-how-to-resize-files',
  helpCleverLoginUrl: '/wikis/1891356-clever-roster-sync-and-instant-login',
  helpTeacherAccountsUrl: '/wikis/1891348-how-to-create-teacher-and-student-accounts',
  helpCanvasLti13Url: ' /wikis/2391294-integrating-il-classroom-with-canvas-lti-1-3',
  helpItemScoringUrl: '/lesson_plans/123833',
  helpDataDashboardGuideUrl: '/wikis/1783046-general-concepts-for-everyone%23Data_Dashboard',
  helpAssignmentSchedulingGuideUrl: '/lesson_plans/104998?card=3424038',
  lzMailAddress: '8860 East Chaparral Rd, Suite 100, Scottsdale AZ 85250',
  defaultCustomerOwnerEmail: 'matheusharder@learnzillion.com',
}
