<script>
import { h } from 'vue'
import IframeHyperlinkResource from './IframeHyperlinkResource'
import SimpleHyperlinkResource from './SimpleHyperlinkResource'

export default {
  name: 'HyperlinkResource',
  props: {
    iframeable: { type: Boolean, default: false },
    description: { type: String, default: null },
    metadata: { type: Object, default: () => ({}) },
    name: { type: String, required: true },
    url: { type: String, required: true },
  },
  render(props, context) {
    const { description, metadata, name, url } = props
    if (props.iframeable) {
      return h(IframeHyperlinkResource, { ...context.attrs, name, url })
    } else {
      return h(SimpleHyperlinkResource, { ...context.attrs, description, metadata, name, url })
    }
  },
}
</script>
