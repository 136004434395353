export default function ({ cards, callback }) {
  const searchParams = new URLSearchParams(window.location.search)

  const cardQueryId = searchParams.get('card')
  const initialCardId = cardQueryId ? (cards.value.find((c) => c.id === parseInt(cardQueryId, 10)) || {}).id : null

  const changeCard = (cardId) => {
    callback(cardId)
    if (cardId !== 0) {
      searchParams.set('card', cardId)
      window.history.replaceState(window.history.state, null, `?${searchParams.toString()}`)
    }
  }

  return {
    changeCard,
    initialCardId,
  }
}
