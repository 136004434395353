import { ref, computed, reactive, watch, toRef, toRefs } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { openModal, closeModal } from 'vue_features/shared/composables/use_global_modals'
import CardEditorModal from '../components/CardEditorModal'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { getInsertRank } from '/utils/card_rank_helpers'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'
import { useLearnosityItemEditorStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_editor_store'
import useCardEditorApi from './use_card_editor_api'
import { enableUnloadConfirm, disableUnloadConfirm } from 'utils'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import useBeginCard from 'vue_features/assignments/shared/card_deck/use_begin_card'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'
import { useResourceTypes } from 'vue_features/shared/composables'
import CardsService from 'vue_features/lesson_plans/edit/api/cards_service'
import URI from 'urijs'
import { DRAFT } from 'vue_features/shared/composables/use_record_states'

const original = ref({})
const beginLessonCard = ref({})

export function initCardDeckEditorStore(initState) {
  const { $set, selectedCardId } = useCardDeckEditorStore()
  $set(initState)
  selectedCardId.value = initState.cards.length > 0 ? initState.cards[0].id : 0
}

export const useCardDeckEditorStore = defineStore('cardDeckEditor', () => {
  const state = reactive({
    isLoadingCard: false,
    isEditingCard: false,
    isSaving: false,
    selectedCardId: 0,
    isContentAndNoteImport: false,
    cards: [],
    learnosityConfig: {
      forCreation: {},
      forUneditableItem: {},
      forSelection: {},
    },
    cardInEdit: {
      id: null,
      title: '',
      contexts: [],
      checkableAnswers: false,
      teachingNotes: null,
      includesCard: false,
      originalNotes: null,
      containerId: null,
      rank: null,
      type: null,
      activeEdit: null,
    },
    voiceoverInEdit: {
      id: null,
      revisionId: null,
      sourceFileUrl: null,
      secureFileUrl: null,
      name: null,
      type: 'Audio',
    },
    contentInEdit: {
      id: null,
      revisionId: null,
      adaptation: false,
      autoscale: null,
      content: null,
      contents: null,
      iframeable: false,
      learnosityItemUpdatedAt: null,
      materialId: null,
      name: null,
      ordered: null,
      owner: null,
      scoringRubricId: null,
      showToolbar: null,
      sourceFileUrl: null,
      secureFileUrl: null,
      textStyle: null,
      blocks: [],
      deletedBlocks: [],
      url: null,
      videoCaptions: [],
      state: DRAFT,
      videoCaptionParams: [],
      editable: true,
      json: null,
      type: null,
      resourceUrl: null,
      virusDetected: false,
      wistiaVideoId: null,
      scoringType: 'per-question',
      standards: [],
      questionBanks: [],
      lessonPlans: [],
    },
  })

  const { lessonPlan } = useLessonPlanStore()

  const { isQuestion: isEditingQuestion } = useResourceTypes(toRef(state, 'contentInEdit'))

  const updateObjectKey = (objectKey, object) => {
    Object.keys(state[objectKey]).forEach((key) => {
      if (object[key] !== undefined) {
        state[objectKey][key] = object[key]
      }
    })
  }

  const importNotes = async (url) => {
    if (!state.cardInEdit) return

    const currentDomain = new URI(window.location.href).domain()
    const urlURI = new URI(url)
    const urlDomain = urlURI.domain()
    const cardId = new URLSearchParams(urlURI.query()).get('card')

    if (currentDomain !== urlDomain || cardId === null) throw new Error('Invalid domain or card ID')

    const { content, id } = await importTeachingNotes(cardId)

    state.cardInEdit.teachingNotes = {
      content,
      id,
      sourceCardId: cardId,
      lessonPlans: null,
      editable: false,
    }
  }

  const fetchCard = (cardId) => {
    if (!cardId) return null
    return CardsService.getCard(cardId)
  }
  const edit = async (card) => {
    state.isLoadingCard = true
    openModal(CardEditorModal.name)
    original.value = card
    resetErrors()
    clearContent()
    state.cardInEdit.id = null
    state.cardInEdit.teachingNotes = null

    updateObjectKey('cardInEdit', card)

    if (isTitleCard(card)) {
      updateObjectKey('contentInEdit', { type: 'Title' })
      const updatedCardInfo = await fetchCard(card.id)
      if (updatedCardInfo) {
        updateObjectKey('cardInEdit', updatedCardInfo)
      }
    } else {
      try {
        const updatedCardInfo = (await fetchCard(card.id, { includeLessonPlans: true })) || {}
        updateObjectKey('contentInEdit', updatedCardInfo.content || {})
        if (updatedCardInfo) {
          updateObjectKey('cardInEdit', updatedCardInfo)
        }
      } catch {
        updateObjectKey('contentInEdit', {})
      }
      if (state.contentInEdit.resourceUrl) {
        const { fileUrl: sourceFileUrl } = useFilestackAccessor(card.content.resourceUrl)
        state.contentInEdit.sourceFileUrl = sourceFileUrl
      }
    }

    if (card.voiceover) {
      updateObjectKey('voiceoverInEdit', card.voiceover)
    }

    if (isEditingQuestion.value) {
      useLearnosityItemEditorStore().updateItem(state.contentInEdit)
    }

    state.isEditingCard = true
    state.isLoadingCard = false
  }

  const addCard = (currentPosition) => {
    currentPosition = hasBeginCard.value ? currentPosition - 1 : currentPosition
    edit({
      title: '',
      rank: getInsertRank(currentPosition + 1, state.cards),
      teachingNotes: {
        id: null,
        editable: true,
        sourceCardId: null,
        content: '',
        lessonPlans: null,
        state: lessonPlan.value.state,
      },
      content: {},
      contexts: ['lesson'],
      containerId: lessonPlan.value.id,
    })
  }

  const cancelEdit = () => {
    clearEditing()
    closeModal(CardEditorModal.name)
  }

  const updateEditedContent = (payload) => {
    Object.keys(payload).forEach((key) => {
      state.contentInEdit[key] = payload[key]
      if (key === 'file') {
        const { fileUrl } = useFilestackAccessor(payload.file)
        state.contentInEdit.sourceFileUrl = fileUrl
        state.contentInEdit.resourceUrl = payload.file
        state.contentInEdit.wistiaVideoId = null
      }
    })
  }

  const updateEditedVoiceover = (payload) => {
    updateObjectKey('voiceoverInEdit', payload)
  }

  const updateEditedCard = (payload) => {
    updateObjectKey('cardInEdit', payload)
  }

  const clearContent = () => {
    useCardDeckEditorStore().$reset(['contentInEdit', 'voiceoverInEdit'])
    useLearnosityItemEditorStore().$reset()
    state.cardInEdit.type = null
    state.cardInEdit.checkableAnswers = false
    state.cardInEdit.includesCard = false
    state.isContentAndNoteImport = false
  }

  const clearNotes = () => {
    if (!state.cardInEdit) return

    state.cardInEdit.originalNotes = state.cardInEdit.teachingNotes
    state.cardInEdit.teachingNotes = null
  }

  const unclearNotes = () => {
    if (!state.cardInEdit) return

    state.cardInEdit.teachingNotes = state.cardInEdit.originalNotes
    state.cardInEdit.originalNotes = null
  }

  const cloneNotes = () => {
    const { content } = state.cardInEdit.teachingNotes
    state.cardInEdit = {
      ...state.cardInEdit,
      teachingNotes: {
        id: null,
        content,
        editable: true,
        sourceCardId: null,
        lessonPlans: null,
        state: lessonPlan.value.state,
      },
    }
  }

  const clearImport = () => {
    state.cardInEdit.teachingNotes = null
  }

  const addNotes = () => {
    state.cardInEdit.teachingNotes = {
      id: null,
      content: '',
      editable: true,
      sourceCardId: null,
      lessonPlans: null,
      state: lessonPlan.value.state,
    }
  }

  const {
    submit,
    copyCard,
    destroyCard,
    hideCard,
    showCard,
    errors,
    resetErrors,
    addErrorChecker,
    removeErrorChecker,
    isSaving,
    importTeachingNotes,
  } = useCardEditorApi({
    ...toRefs(state),
    cards: toRef(state, 'cards'),
    isEditingQuestion,
    original,
    updateObjectKey,
    edit,
    onSubmit: () => {
      closeModal(CardEditorModal.name)
      clearEditing()
    },
    spliceCards: (start, deleteCount, ...newCards) => {
      state.cards.splice(start, deleteCount, ...newCards)
    },
  })
  const hasErrors = computed(() => errors.value.length > 0)

  watch(
    () => state.isEditingCard,
    () => {
      if (state.isEditingCard.value) {
        enableUnloadConfirm()
      } else {
        disableUnloadConfirm()
      }
    },
  )

  const clearEditing = () => {
    state.isEditingCard = false
    state.isContentAndNoteImport = false
    state.cardInEdit = {
      ...state.cardInEdit,
      teachingNotes: null,
      originalNotes: null,
    }
  }

  const createBeginLessonCard = (root) => {
    beginLessonCard.value = useBeginCard(lessonPlan, root)
  }

  const hasBeginCard = computed(() => {
    return state.cards.length > 1
  })
  const slideshowCards = computed(() => {
    const cardArray = [...state.cards]
    if (hasBeginCard.value) {
      cardArray.splice(0, 0, beginLessonCard.value)
    }
    return cardArray
  })

  return {
    state,
    createBeginLessonCard,
    slideshowCards,
    edit,
    submit,
    isEditingQuestion,
    updateEditedContent,
    updateEditedCard,
    updateEditedVoiceover,
    importNotes,
    clearImport,
    cancelEdit,
    clearContent,
    clearNotes,
    unclearNotes,
    cloneNotes,
    addNotes,
    addCard,
    hasErrors,
    addErrorChecker,
    removeErrorChecker,
    destroyCard: async (card) => {
      const { isProgressCoverDisplayed } = useLessonPlanEditStore()
      isProgressCoverDisplayed.value = true
      await destroyCard(card)
      isProgressCoverDisplayed.value = false
    },
    hideCard,
    showCard,
    copyCard: async (card) => {
      const copy = await copyCard(card)
      edit(copy)
    },
    errors,
    isSavingApi: isSaving,
  }
})
