<template>
  <div class="relative flex h-11 w-11 flex-none items-center justify-center">
    <button
      class="relative z-10 flex h-3/4 w-3/4 items-center justify-center rounded-full font-bold transition duration-200"
      :class="innerClasses"
      :style="bubbleStyles"
      @click="$emit('click')"
    >
      <slot></slot>
    </button>

    <div class="absolute z-0">
      <svg class="h-full w-full -rotate-90 transform" width="80" height="80" viewBox="0 0 80 80">
        <circle
          :class="ringColorClass"
          stroke-width="4"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumferencePercentage"
          stroke-linecap="round"
          stroke="currentColor"
          fill="transparent"
          :r="radius"
          cx="40"
          cy="40"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'ItemBubble',
  components: {},
  props: {
    textColorClass: {
      type: String,
      default: 'text-black',
    },
    borderClass: {
      type: String,
      default: 'border-none',
    },
    backgroundColorClass: {
      type: String,
      default: 'bg-white',
    },
    ringColorClass: {
      type: String,
      default: 'text-blue-800',
    },
    ringPercentage: {
      type: Number,
      default: 0,
    },
    bubbleStyles: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const radius = 36
    const circumference = radius * Math.PI * 2

    const innerClasses = computed(() => [props.backgroundColorClass, props.textColorClass, props.borderClass])
    const circumferencePercentage = computed(() => circumference - (props.ringPercentage / 100) * circumference)

    return {
      radius,
      circumference,
      innerClasses,
      circumferencePercentage,
    }
  },
}
</script>
