<template>
  <div id="lp-info">
    <h1 itemprop="name">
      {{ title }}
    </h1>
    <ul class="page-head__metadata list-metadata">
      <li class="space-x-1">
        <strong class="capitalize">{{ $t('common.from') }}</strong>
        <span v-if="hasLogo" class="page-head__logo">
          <img :src="logo" alt="logo" />
        </span>
        <span>{{ customerOwner }}</span>
      </li>
      <li v-if="adaptation && forkedFromTitle" class="space-x-1">
        <strong>{{ $t('common.label.copied_from') }}</strong>
        <a :href="Routes.lesson_plan_path(forkedFromId)">{{ forkedFromTitle }}</a>
      </li>
      <li v-if="adaptation && ownerName" class="space-x-1">
        <strong>{{ $t('common.label.personalized_by') }}</strong>
        {{ ownerName }}
      </li>
      <li v-if="authors" class="space-x-1">
        <strong>{{ $t('common.label.created_by') }}</strong>
        <span class="no-fullstory" itemprop="author">
          {{ authors }}
        </span>
      </li>
      <li v-if="authorizedAsTeacher && hasItemCards" class="space-x-1">
        <InfoTotalPoints :total-points="totalPoints" />
      </li>
      <li v-if="showStandards && allCustomerVisibleStandards.length > 0" class="flex gap-x-2">
        <strong>{{ $t('common.label.standards') }}</strong>
        <StandardsBreakdown :standards="allCustomerVisibleStandards" />
      </li>
      <li v-if="isPublic && authorizedAsAuthor && allStandards.length > 0" class="flex gap-x-2">
        <strong>{{ $t('common.label.all_standards') }}</strong>
        <StandardsBreakdown :standards="allStandards" />
      </li>
      <TagList :tag-names="tagNames" bold />
      <div v-if="lastEditor || personalized" class="mt-2 space-x-2">
        <span v-if="lastEditor" class="bg-neutral-500 text-primary-900 rounded px-3 py-1 text-sm">
          {{ $t('common.label.customized_by') }}
          {{ lastEditor }}
        </span>
        <a
          v-if="personalized"
          :href="Routes.lesson_plan_card_edits_path(lessonPlanId, { personalize: true })"
          class="rounded bg-green-50 px-3 py-1 text-sm text-green-700 hover:text-green-900"
        >
          {{ $t('common.label.personalized_by') }}
          {{ $t('common.you') }}
        </a>
      </div>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue'
import Routes from 'routes'
import InfoTotalPoints from 'vue_features/lesson_plans/components/InfoTotalPoints'
import StandardsBreakdown from 'vue_features/lesson_plans/components/StandardsBreakdown'
import TagList from './TagList'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import useLessonPlanStandards from 'vue_features/lesson_plans/show/composables/use_lesson_plan_standards'

export default {
  name: 'PageHeadMetadata',
  components: { InfoTotalPoints, StandardsBreakdown, TagList },
  props: {
    cards: { type: Array, default: () => [] },
  },
  setup(props) {
    const {
      title,
      logo,
      authors,
      customerOwner,
      tagNames,
      adaptation,
      forkedFromTitle,
      forkedFromId,
      ownerName,
      lastEditor,
      totalPoints,
      hasItemCards,
      personalized,
      lessonPlanId,
    } = useLessonPlanStore()

    const { allStandards, allCustomerVisibleStandards } = useLessonPlanStandards()
    const hasLogo = computed(() => !!logo.value)

    const { showStandards, isPublic } = useCurrentCustomerStore()
    const { authorizedAsAuthor, authorizedAsTeacher } = useCurrentUserStore()

    return {
      Routes,
      title,
      logo,
      authors,
      customerOwner,
      tagNames,
      adaptation,
      forkedFromTitle,
      forkedFromId,
      ownerName,
      lastEditor,
      totalPoints,
      hasItemCards,
      personalized,
      lessonPlanId,
      allStandards,
      allCustomerVisibleStandards,
      hasLogo,
      showStandards,
      isPublic,
      authorizedAsAuthor,
      authorizedAsTeacher,
    }
  },
}
</script>
