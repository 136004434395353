import { SiteAdminCustomersService } from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed, watch } from 'vue'

const DEFAULT_NAVIGATION_OPTIONS = [
  {
    _destroy: false,
    enabled: false,
    id: 0,
    isCustom: true,
    isHome: false,
    key: '',
    url: '',
  },
]

export const useSiteAdminCustomerStore = defineStore('siteAdminCustomer', () => {
  const state = reactive({
    customer: {},
  })

  const navigationOptions = computed(() => state.customer.navigationOptions)

  function updateCustomer(payload) {
    return SiteAdminCustomersService.updateCustomer(payload).then((customer) => (state.customer = customer))
  }

  function updateNavigationOptions(changedNavOptions) {
    return SiteAdminCustomersService.updateNavigationOptions(changedNavOptions).then((customer) => {
      state.customer = customer
    })
  }

  watch(
    () => state.customer,
    () => {
      if (state.customer.navigationOptions.length === 0) {
        state.customer.navigationOptions = DEFAULT_NAVIGATION_OPTIONS
      }
    },
  )

  return {
    state,
    navigationOptions,
    updateCustomer,
    updateNavigationOptions,
  }
})
