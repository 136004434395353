<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <h2 class="h3 mt-12">{{ $t('show.security.header') }}</h2>
    <SettingsToggle
      id="content-open-toggle"
      :model-value="isContentOpen"
      :enabled="isEditing"
      :label="$t('show.security.guest_access')"
      :info="$t('show.security.guest_access_info')"
      class="mb-0"
      @update:modelValue="$emit('update:isContentOpen', $event)"
    />

    <div v-if="isContentOpen">
      <SettingsToggle
        id="bypass-homepage-toggle"
        :model-value="bypassHomepage"
        :enabled="isEditing"
        :label="$t('show.security.bypass_homepage_label')"
        :info="$t('show.security.bypass_homepage_helptext')"
        class="mb-0 mt-6"
        @update:modelValue="$emit('update:bypassHomepage', $event)"
      />
    </div>

    <label class="h5 mb-0 mt-6">{{ $t('show.security.session_timeout_label') }}</label>
    <p class="small">{{ $t('show.security.session_timeout_info') }}</p>
    <div :class="['pvs', 'phn', 'man', { error: hasErrors }]" style="display: flex">
      <SwitchToggle
        id="session-timeout-toggle"
        :model-value="hasSessionTimeout"
        :disabled="!isEditing"
        @update:modelValue="$emit('update:hasSessionTimeout', $event)"
      />
      <input
        v-if="hasSessionTimeout"
        :value="sessionTimeout"
        :disabled="!isEditing"
        type="number"
        class="ml-4 p-0"
        style="width: 5rem; display: inline; text-align: right"
        @input="$emit('update:sessionTimeout', Number.parseInt($event.target.value))"
      />
    </div>
    <p v-if="hasSessionTimeout" class="small">{{ $t('show.security.session_timeout_helptext') }}</p>
  </div>
</template>

<script>
import { SwitchToggle } from 'vue_features/shared/components/ui'
import SettingsToggle from './SettingsToggle'

export default {
  name: 'SecuritySettings',
  components: { SettingsToggle, SwitchToggle },
  props: {
    isEditing: { type: Boolean, required: true },
    hasErrors: { type: Boolean, required: true },
    isContentOpen: { type: Boolean, required: true },
    bypassHomepage: { type: Boolean, required: true },
    hasSessionTimeout: { type: Boolean, required: true },
    sessionTimeout: { type: Number, default: null },
  },
}
</script>
