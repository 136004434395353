<script setup>
import CollectionMap from 'vue_features/collection_map/components/CollectionMap'
import RelatedContent from 'vue_features/related_content/components/RelatedContent'
import ResourceContent from './ResourceContent'
import { useResourceTypes } from 'vue_features/shared/composables'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { useCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'

const { isOpen } = useCollectionMapStore()

const { collection, isCollectionMapAvailable, isGated, resource } = useResourceShowStore()
isGated.value = false

defineProps({ canPrint: { type: Boolean, default: false } })

const { isWiki } = useResourceTypes(resource)
</script>

<template>
  <div>
    <div
      id="resource"
      :class="[
        { 'in-collection': collection !== null && !isWiki, 'collection-page': isCollectionMapAvailable && isWiki },
      ]"
    >
      <CollectionMap v-if="isCollectionMapAvailable && isOpen" />
      <ResourceContent :can-print="canPrint" />
      <RelatedContent v-if="!isWiki" :collection="collection" :item-id="resource.id" />
    </div>
  </div>
</template>
