<template>
  <button :class="dotClasses" :aria-label="$t('toolbar.non_question_item')">
    <slot />
  </button>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'PositionIndicatorDot',
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const dotClasses = computed(() => {
      return props.isSelected
        ? 'text-primary-700 bg-white ring-2 ring-inset ring-blue-700 triangle-top-down'
        : 'bg-primary-700 hover:bg-primary-500 text-white'
    })

    return { dotClasses }
  },
}
</script>
