<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('common.google_classroom.link_error_title')"
    :modal-id="modalId"
    class="lzui-modal--medium"
  >
    <i18n-t tag="div" keypath="common.google_classroom.link_error" scope="global">
      <template #editUserLink>
        <a :href="Routes.edit_user_path()">{{ $t('common.here') }}</a>
      </template>
    </i18n-t>
    <footer>
      <button class="btn" @click="close">{{ $t('common.close') }}</button>
    </footer>
  </LzModal>
</template>

<script>
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import Routes from 'routes'

export default {
  name: 'LinkErrorModal',
  components: { LzModal },
  props: {
    modalId: {
      type: String,
      default: function () {
        return 'LinkErrorModal'
      },
    },
  },
  setup() {
    return { Routes, closeModal }
  },
}
</script>
