<template>
  <component
    :is="tag"
    v-track-click.once="eventObject"
    :type="tag === 'input' ? 'submit' : type"
    :class="['btn', colorClass, demotedClass, cautionClass, disabledClass, sizeClass]"
    :disabled="isProcessing"
  >
    <slot />
    <LoadingSpinner v-if="isProcessing" inline size="small" />
  </component>
</template>

<script>
import { trackClick } from 'vue_features/shared/directives'
import LoadingSpinner from './LoadingSpinner'

export default {
  name: 'LzButton',
  components: { LoadingSpinner },
  directives: { trackClick },
  props: {
    color: {
      type: String,
      default: undefined,
      validator: (value) => {
        return ['primary', 'green', 'blue', undefined].indexOf(value) !== -1
      },
    },
    caution: {
      type: Boolean,
      default: false,
    },
    demoted: {
      type: Boolean,
      default: false,
    },
    eventObject: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: undefined,
      validator: (value) => {
        return ['small', 'big', 'xlarge', undefined].indexOf(value) !== -1
      },
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'input', 'a'].indexOf(value) !== -1
      },
    },
    type: {
      type: String,
      default: 'button',
    },
    isProcessing: { type: Boolean, default: false },
  },
  computed: {
    colorClass() {
      switch (this.color) {
        case 'primary':
          return 'btn--primary'
        case 'green':
          return 'btn--green'
        case 'blue':
          return 'btn--blue'
        default:
          return null
      }
    },
    demotedClass() {
      return this.demoted ? 'btn--demoted' : null
    },
    cautionClass() {
      return this.caution && !this.isProcessing ? 'caution' : null
    },
    disabledClass() {
      const isDisabled = this.$attrs.disabled || this.$attrs.disabled === ''
      return isDisabled ? 'disabled' : null
    },
    sizeClass() {
      switch (this.size) {
        case 'small':
          return 'btn--small'
        case 'big':
          return 'btn--big'
        case 'xlarge':
          return 'btn--xlarge'
        default:
          return null
      }
    },
  },
}
</script>
