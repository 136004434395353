<template>
  <LzModal
    id="when-filter-modal"
    :modal-id="$options.name"
    :modal-title="$t('when_filter_modal.title')"
    modal-classes="w-full max-w-4xl"
    :autoclose="true"
    @close-modal="closeModal($options.name)"
  >
    <div class="when-filter-content w-full max-w-4xl overflow-auto px-4 pb-4">
      <DateRangeSelect :value="pendingRange" class="flex flex-wrap justify-center" @update:model-value="setDateRange" />
    </div>
    <div class="border-base w-full border-t p-6">
      <button class="btn btn--green" @click="applyRangeChanges">
        {{ $t('view_results') }}
      </button>
    </div>
  </LzModal>
</template>

<script>
import { ref } from 'vue'
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import DateRangeSelect from 'vue_features/shared/components/selects/DateRangeSelect'

export default {
  name: 'WhenFilterModal',
  components: { LzModal, DateRangeSelect },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pendingRange = ref({ start: props.dateRange.start, end: props.dateRange.end })

    const applyRangeChanges = () => {
      const { start, end } = pendingRange.value
      emit('update-date-range', { start, end })
    }

    const setDateRange = ({ start, end }) => {
      pendingRange.value = { start, end }
    }

    return {
      closeModal,
      pendingRange,
      applyRangeChanges,
      setDateRange,
    }
  },
}
</script>

<style>
.when-filter-content {
  max-height: min(60vh, 600px);
}
#when-filter-modal .lzui-modal__body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#when-filter-modal .lzui-modal__dialog > header {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
