<template>
  <CardEditItem v-if="isBuildingCard" :card="card" class="bg-focus">
    <slot />
  </CardEditItem>
  <MaterialDetailItem v-else v-bind="{ cardId: card.id, cardType: card.type, ...cardProps }" class="bg-focus">
    <slot />
  </MaterialDetailItem>
</template>

<script>
import CardEditItem from './CardEditItem'
import MaterialDetailItem from './MaterialDetailItem'
import { computed } from 'vue'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'

export default {
  name: 'AdditionalMaterialItem',
  components: { CardEditItem, MaterialDetailItem },
  props: { card: { type: Object, required: true } },
  setup(props) {
    const { isEditingCard } = useAdditionalMaterialsStore()

    const isEditing = computed(() => {
      return isEditingCard(props.card)
    })

    return { isEditing }
  },
  computed: {
    cardProps() {
      const { title, content, activeEdit } = this.card
      return { title, content, activeEdit, card: this.card }
    },
    isBuildingCard() {
      return this.isEditing || !this.card.id
    },
  },
}
</script>
