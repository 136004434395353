import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { reactive } from 'vue'

export const useLearnosityConfigStore = defineStore('learnosityConfig', () => {
  const state = reactive({
    learnosityConfig: null,
  })

  return { state }
})
