import { reactive } from 'vue'
import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

export default function useOauthSignature(contentItemsJson, returnUrl, data) {
  const oauthSignature = reactive({
    version: '',
    nonce: '',
    timestamp: '',
    consumerKey: '',
    callback: '',
    signatureMethod: '',
    signature: '',
  })

  const updateFormSignature = async () => {
    const signatureData = {
      content_items: contentItemsJson.value,
      lti_message_type: 'contentItemSelection',
      lti_version: 'LTI-1p0',
      return_url: returnUrl,
    }

    if (data) signatureData.data = data

    await createSignature(signatureData)
  }

  const createSignature = async (signatureData) => {
    const newSignature = await HTTP.create(Routes.lti_security_signatures_path(), signatureData)
    Object.entries(newSignature).forEach(([key, value]) => (oauthSignature[key] = value))
  }

  return { updateFormSignature, oauthSignature }
}
