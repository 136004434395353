<template>
  <div class="flex flex-wrap items-center gap-2">
    <slot />

    <button
      v-if="showReset"
      class="text-primary-400 inline-flex items-center text-xs"
      :title="$t('common.clear_selections')"
      @click="resetSelections"
    >
      {{ $t('common.reset') }}

      <LzIcon
        v-if="showReset"
        data-test="reset-button"
        path="icons/close"
        size="sm"
        class="text-primary-400"
        type="button"
        :title="$t('common.clear_selections')"
      />
    </button>
  </div>
</template>

<script>
import { provide, ref, watch } from 'vue'
import LzIcon from './LzIcon'
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'

const DEFAULT_BUTTON_CLASSES = ['font-bold', 'cursor-pointer', 'focus:border-primary-500']

export default {
  name: 'CheckButtons',
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: ['reset', 'clear_selections'] }) },
  components: { LzIcon },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: [Array, String, Object],
      default: () => DEFAULT_BUTTON_CLASSES,
    },
  },
  setup(props, { emit }) {
    const selections = ref([])
    const toggle = (val) => {
      if (selections.value.includes(val)) {
        selections.value = selections.value.filter((v) => v !== val)
      } else {
        selections.value = [...selections.value, val]
      }

      emit('input', selections.value)
    }
    const resetSelections = () => {
      selections.value = []
      emit('input', selections.value)
    }

    watch(
      () => [...props.value],
      (newSelections) => {
        selections.value = newSelections
      },
      { immediate: true },
    )

    provide('name', props.name)
    provide('toggle', toggle)
    provide('selections', selections)

    return { selections, toggle, resetSelections }
  },
}
</script>
