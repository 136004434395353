<template>
  <div>
    <div
      v-for="standard in customerVisibleStandards"
      :id="`standard-${standard.uuid}`"
      :key="standard.uuid"
      itemprop="educationalAlignment"
      itemscope
      itemtype="//schema.org/AlignmentObject"
      class="lrmi-alignment hidden"
    >
      <span itemprop="alignmentType">teaches</span>
      <span itemprop="educationalFramework">{{ standard.initiativeName || $t('common.ccss') }}</span>
      <span itemprop="targetName">{{ standard.code }}</span>
      <span itemprop="targetUrl">{{ standard.referenceUrl }}</span>
    </div>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'PageHeadStandards',
  setup() {
    const { customerVisibleStandards } = useLessonPlanStore()
    return { customerVisibleStandards }
  },
}
</script>
