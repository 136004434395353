import { closeModal, activeModal } from 'vue_features/shared/composables/use_global_modals'
import { computed } from 'vue'

export default function useSchoolModals(modalId) {
  function hideModal() {
    closeModal(modalId)
  }

  return {
    school: computed(() => activeModal.value.payload),
    hideModal,
  }
}
