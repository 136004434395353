<template>
  <div id="order-toolbar" class="px-4 py-1" style="min-height: 60px">
    <h5 class="m-0 text-white">{{ $t('slides.form_template.manage_order') }}</h5>
    <p class="m-0 text-sm text-white">{{ $t('slides.form_template.orderer_explanation') }}</p>
  </div>
</template>

<script>
export default {
  name: 'OrdererToolbar',
}
</script>
