<template>
  <LzConfirmModal :modal-id="modalId" class="lzui-modal--confirmation">
    <template #confirmBody>
      <div class="form-group">
        <p>{{ $t('confirm_modal.confirm_delete_class', { class_to_delete: klass.name }) }}</p>
        <p>{{ $t('confirm_modal.cannot_undo') }}</p>
      </div>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution ml-2" @click="submit">
        {{ $t('common.delete') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useKlassesIndexStore } from 'vue_features/klasses/index/store/use_klasses_index_store'
import { useKlassShowStore } from 'vue_features/klasses/show/store'

export default {
  name: 'KlassDeleteModal',
  components: { LzConfirmModal },
  props: {
    klass: {
      type: Object,
      required: true,
    },
    onShowPage: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: function () {
        return 'KlassDeleteModal'
      },
    },
  },
  setup() {
    const { deleteKlass: deleteIndexKlass } = useKlassesIndexStore()
    const { deleteKlass: deleteShowKlass } = useKlassShowStore()
    return { deleteIndexKlass, deleteShowKlass, closeModal }
  },
  methods: {
    submit() {
      const deleteKlass = this.onShowPage ? this.deleteShowKlass : this.deleteIndexKlass
      deleteKlass(this.klass)
        .catch((e) => {
          if (e.status === 405) {
            this.$emit('delete-error')
          } else {
            throw e
          }
        })
        .finally(() => {
          this.closeModal(this.modalId)
        })
    },
  },
}
</script>
