<template>
  <!-- FIXME: LzConfirmModal? -->
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('common.remove')"
    :modal-id="modalId"
    class="lzui-modal--medium"
    autoclose
  >
    <div class="no-fullstory">
      <p>{{ $t('confirm_modal.confirm_remove_student', { student_to_delete: student.name }) }}</p>
    </div>
    <footer class="mt-4">
      <button class="btn" @click="close">{{ $t('common.cancel') }}</button>
      <button class="btn btn--primary caution" data-test="remove-confirm-button" @click="confirm">{{
        $t('confirm_modal.confirm')
      }}</button>
    </footer>
  </LzModal>
</template>

<script>
import { useKlassShowStore } from 'vue_features/klasses/show/store'
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'RemoveConfirmModal',
  components: { LzModal },
  props: {
    student: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
      default: function () {
        return 'RemoveConfirmModal'
      },
    },
  },
  setup() {
    const { deleteStudent, unenrollStudent } = useKlassShowStore()
    return { deleteStudent, unenrollStudent, closeModal }
  },
  methods: {
    confirm() {
      this.closeModal(this.modalId)
      this.deleteStudent({ student: this.student })
      this.unenrollStudent(this.student)
    },
  },
}
</script>
