import messages from './i18n/resource_show_messages'
import ResourceGatedShow from 'vue_features/resources/shared/components/ResourceGatedShow'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { useContactDetailsStore } from 'vue_features/shared/composables/use_contact_details_store'

export default function ({ state }) {
  useResourceShowStore().$set(state.resourceShowState)
  useContactDetailsStore().$set(state.contactState)
  return { component: ResourceGatedShow, messages }
}
