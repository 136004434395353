<template>
  <div>
    <LzButton color="green" @click="openModal($options.KlassCreateModal)">
      <LzIcon path="icons/plus-sm" />
      {{ $t('klasses.new_class') }}
    </LzButton>
  </div>
</template>

<script>
import { LzButton, LzIcon } from 'vue_features/shared/components/ui'
import { KlassCreateModal } from '../../shared/components/modals'
import { openModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'KlassCreateButton',
  KlassCreateModal,
  components: {
    LzButton,
    LzIcon,
  },
  setup() {
    return { openModal }
  },
}
</script>
