<template>
  <div ref="container" class="report-table">
    <Dataset
      ref="dataset"
      :ds-data="rows"
      :ds-sortby="sortBy ? [sortBy] : []"
      :ds-sort-as="sortFieldsAs"
      :ds-search-in="searchableFields"
    >
      <template #default>
        <div v-if="showLimitSelect" class="report-table__dataset-show">
          <DatasetShow :ds-show-entries="rowCount" />
        </div>
        <div v-if="showSearch" class="report-table__search">
          <DatasetSearch ds-search-placeholder="Search..." :wait="300" />
        </div>
        <div class="relative w-full max-w-full overflow-x-auto" :class="{ 'overflow-auto': hasStickyColumns }">
          <table
            ref="table"
            class="mb-0 border-0"
            :class="{
              'table-auto': hasStickyColumns,
              'border-separate': hasStickyColumns,
              'has-sticky-columns': hasStickyColumns,
            }"
          >
            <thead data-test="report-table-head">
              <tr>
                <th
                  v-for="(header, index) in columns"
                  :key="header.field"
                  class="z-10 cursor-pointer align-bottom"
                  :class="cellClass(index, true)"
                  :style="stickyOffsetStyle(index)"
                  @click="sortByColumn($event, index)"
                >
                  <div class="flex-no-wrap flex items-center">
                    <div :title="header.tooltip">
                      <div class="flex-grow space-y-1" data-test="report-table-header-text">
                        <div v-if="header.tag" class="text-xs font-thin uppercase">
                          {{ header.tag }}
                        </div>
                        <span :class="{ 'font-normal': header.tag }">{{ header.label }}</span>
                      </div>
                    </div>

                    <div class="sort-icon w-5">
                      <LzIcon v-if="isSortingBy(header.field)" size="sm" :path="sortIcon(header.field)" />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <DatasetItem tag="tbody" data-test="report-table-body">
              <template #default="{ row }">
                <tr @click="$emit('row-click', row)">
                  <td
                    v-for="(header, key) in columns"
                    :key="row.categoryId + ',' + header.field"
                    :style="{ ...cellStyle(header.field, row[header.field]), ...stickyOffsetStyle(key) }"
                    :class="cellClass(key)"
                  >
                    <slot name="cell" :col-name="header.field" :row="row" :value="row[header.field]">
                      {{ row[header.field] }}
                    </slot>
                  </td>
                </tr>
              </template>
              <template #noDataFound>
                <tr>
                  <td class="text-center" :colspan="columns.length">
                    <h4 class="m-0 py-6">{{ $t('no_results_found') }}</h4>
                  </td>
                </tr>
              </template>
            </DatasetItem>
          </table>
        </div>
        <LoadMoreButton
          v-if="showLoadMoreButton"
          :is-loading="false"
          class="mt-2 bg-transparent p-0"
          data-test="load-more-button"
          @click="loadMore"
        >
          {{ loadMoreText }}
        </LoadMoreButton>
      </template>
    </Dataset>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUpdated } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import { LoadMoreButton } from 'vue_features/shared/components/lists'
import { Dataset, DatasetItem, DatasetSearch, DatasetShow } from 'vue-dataset'
import debug from 'debug'
import useViewportSize from 'vue_features/shared/composables/use_viewport_size'

const log = debug('app:vue_features/data_dashboard/components/ReportTable')

const emptyArray = () => []

export default {
  name: 'ReportTable',
  components: {
    LzIcon,
    Dataset,
    DatasetItem,
    DatasetSearch,
    DatasetShow,
    LoadMoreButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({ columns: [], rows: [] }),
    },
    searchableFields: {
      type: Array,
      default: emptyArray,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showLimitSelect: {
      type: Boolean,
      default: false,
    },
    initialRowCount: {
      type: Number,
      default: 15,
    },
    sortFieldsAs: {
      type: Object,
      default: () => ({}),
    },
    cellStyle: {
      type: Function,
      default: () => ({}),
    },
    stickyColumns: {
      type: Number,
      default: 0,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    loadMoreText: {
      type: String,
      default: '',
    },
  },
  setup(props, { attrs }) {
    const container = ref(null)
    const table = ref(null)
    const columns = computed(() => props.data.columns)
    const columnWidths = ref([])
    const rows = computed(() => props.data.rows)
    const dataset = ref(null)
    const sortBy = ref(null)
    const rowCount = ref(props.initialRowCount)
    const colNames = computed(() => columns.value.map(({ field }) => field))
    const sortFieldName = computed(() => (sortBy.value || '').replace('-', ''))
    const hasStickyColumns = computed(() => props.stickyColumns > 0)
    const showLoadMoreButton = computed(() => rows.value.length > rowCount.value)
    const isSortingBy = (field) => [field, `-${field}`].includes(sortBy.value)
    const sortIcon = () => {
      if ((sortBy.value || '').startsWith('-')) return 'icons/caret-reverse'
      return 'icons/caret'
    }
    const handleRowClick = computed(() => (row) => {
      log('row clicked: %O', row)
      attrs.onRowClick(row)
    })
    const { width: viewportWidth } = useViewportSize()

    const isStickyColumn = (index) => index <= props.stickyColumns - 1

    const cellClass = (index, isHeader = false) => {
      const isSticky = isStickyColumn(index)

      return {
        relative: !isSticky,
        sticky: isSticky,
        'left-0': isSticky && index === 0,
        'z-10': isSticky && !isHeader,
        'z-20': isSticky && isHeader,
        'bg-white': isSticky && !isHeader,
        'w-10vw': hasStickyColumns.value,
      }
    }

    const stickyOffsetStyle = (index) => {
      const isSticky = isStickyColumn(index)

      if (!isSticky || !columnWidths.value[index]) return {}

      const left = index === 0 ? 0 : columnWidths.value.slice(0, index).reduce((offset, width) => offset + width, 0)

      return { left: `${left}px` }
    }

    const getColumnWidths = () => [...table.value.querySelectorAll('th')].map(({ offsetWidth }) => offsetWidth)

    const sortByColumn = (event, index) => {
      const field = colNames.value[index]

      if (!field) return

      const sortedAsc = sortBy.value === field
      const sortedDesc = sortBy.value === `-${field}`

      if (sortedAsc) {
        sortBy.value = `-${field}`
      } else if (sortedDesc) {
        sortBy.value = null
      } else {
        sortBy.value = field
      }

      log('sortBy changed: %s', sortBy.value)
    }

    const loadMore = () => {
      rowCount.value += props.initialRowCount
    }

    onMounted(() => {
      dataset.value.dsShowEntries = rowCount.value
      columnWidths.value = getColumnWidths()

      // Prevent inadvertent history navigation when horizontally scrolling the table:
      if (props.stickyColumns > 0) {
        document.body.style.overscrollBehaviorX = 'none'
      }
    })

    watch(
      () => viewportWidth.value,
      () => {
        columnWidths.value = getColumnWidths()
      },
    )

    watch(rowCount, (value) => {
      dataset.value.dsShowEntries = value
    })

    onUpdated(() => {
      columnWidths.value = getColumnWidths()
    })

    return {
      table,
      container,
      columns,
      rows,
      dataset,
      sortBy,
      rowCount,
      colNames,
      sortIcon,
      hasStickyColumns,
      isSortingBy,
      sortFieldName,
      handleRowClick,
      cellClass,
      stickyOffsetStyle,
      showLoadMoreButton,
      sortByColumn,
      loadMore,
    }
  },
}
</script>

<style scoped>
tr:hover {
  background-color: rgb(var(--gray-50));
  cursor: pointer;
}

th:hover {
  background-color: var(--table-border);
}

.sort-icon {
  margin-left: auto;
}

span.has-tip {
  font-weight: bold;
}

table.has-sticky-columns th,
table.has-sticky-columns td {
  border-left: 0;
  border-bottom: 0;
}

table.has-sticky-columns th:first-child,
table.has-sticky-columns td:first-child {
  border-left: 1px solid var(--table-border);
}

table.has-sticky-columns tbody tr:last-child td {
  border-bottom: 1px solid var(--table-border);
}

table.table-fixed th,
table.table-fixed td {
  max-width: 200px;
}
</style>
