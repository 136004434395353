import { computed } from 'vue'
import GeogebraForm from 'vue_features/resources/geogebra/components/GeogebraForm'
import HyperlinkForm from 'vue_features/resources/hyperlinks/components/HyperlinkForm'
import WikiForm from 'vue_features/resources/wikis/components/WikiForm'
import SlideForm from 'vue_features/resources/slides/components/SlideForm'
import HtmlEdit from 'vue_features/resources/html/components/HtmlEdit'
import VideoEdit from 'vue_features/resources/videos/components/VideoEdit'
import LearnosityForm from 'vue_features/resources/learnosity_items/components/LearnosityForm'
import ResourceUploader from 'vue_features/resources/shared/components/ResourceUploader'

export default function useResourceBuilder(resourceType) {
  return {
    resourceBuilder: computed(() => {
      switch (resourceType) {
        case 'GeogebraItem':
          return GeogebraForm
        case 'Hyperlink':
          return HyperlinkForm
        case 'Wiki':
          return WikiForm
        case 'Slide':
          return SlideForm
        case 'Html':
          return HtmlEdit
        case 'Video':
          return VideoEdit
        case 'LearnosityItem':
          return LearnosityForm
        default:
          return ResourceUploader
      }
    }),
  }
}
