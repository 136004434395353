<template>
  <div class="flex flex-none items-center space-x-4 border-b-2 border-purple-100 py-1 pl-1 pr-4">
    <div class="relative flex flex-grow items-center text-center">
      <div>
        <button
          class="hover:bg-base hover:text-important-hover text-important inline-flex h-11 w-11 items-center justify-center rounded-xl transition-colors"
          :class="currentSessionIndex === 0 ? 'opacity-10' : 'opacity-100'"
          :disabled="currentSessionIndex === 0"
          data-test="previous-session-button"
          :title="$t('previous_session')"
          @click="onPreviousSessionButtonClick"
        >
          <LzIcon path="icons/chevron-left" size="md" />
        </button>

        <button
          class="hover:bg-base hover:text-important-hover text-important inline-flex h-11 w-11 items-center justify-center rounded-xl transition-colors"
          :disabled="currentSessionIndex === filteredSessions.length - 1"
          :class="currentSessionIndex === filteredSessions.length - 1 ? 'opacity-10' : 'opacity-100'"
          data-test="next-session-button"
          :title="$t('next_session')"
          @click="onNextSessionButtonClick"
        >
          <LzIcon path="icons/chevron-right" size="md" />
        </button>
      </div>

      <button
        class="hover:bg-base group inline-flex flex-none items-center space-x-2 rounded-lg px-2 py-1 transition-colors"
        data-test="session-chooser-toggle"
        @click="onSessionChooserClick"
      >
        <div
          class="relative flex h-12 w-12 items-center justify-center overflow-hidden rounded-full border-2 border-white transition-colors duration-500"
          :class="currentSessionClasses"
        >
          <span class="fs-mask font-bold text-black">{{ currentSessionInitials }}</span>
          <span
            class="absolute -top-1 transform text-4xl transition-all duration-500"
            :class="monkeySeeClasses"
            style="transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55) !important"
          >
            {{ '🙈' }}
          </span>
        </div>

        <span class="text-important fs-mask group-hover:text-important-hover font-bold">{{
          currentSession.studentName
        }}</span>

        <LzIcon
          path="icons/chevron-down"
          size="md"
          icon-color-class="text-important group-hover:text-important-hover"
        />
      </button>
    </div>

    <div class="flex-none text-center">
      <!--   Button ID required to reset focus when modal closed   -->
      <button
        id="session-comment-toggle"
        class="hover:bg-base group rounded-lg px-2 py-1"
        data-test="session-comment-toggle"
        @click="onCommentButtonClick"
      >
        <IconBadge
          path="icons/comment"
          :badge="hasComment ? '1' : ''"
          icon-classes="text-base group-hover:text-important-hover"
          badge-classes="group-hover:bg-primary-accent bg-neutral-900 text-white"
        />
        <div class="group-hover:text-important-hover -mt-2 text-base text-sm font-semibold">{{ $t('comment') }}</div>
      </button>
    </div>

    <div class="inline-flex flex-none items-center space-x-1">
      <LoadingSpinner v-if="changingAssignmentState" size="small" />

      <label for="assignment_state">
        <span class="sr-only">{{ $t('assignment_state') }}</span>
        <select
          id="assignment_state"
          :value="assignmentState"
          :disabled="changingAssignmentState || !currentUserTeachesKlass"
          :title="$t('assignment_state')"
          data-test="assignment-state-select"
          @input="onAssignmentStateChange"
        >
          <option value="opened">{{ $t('assignment_state_opened') }}</option>
          <option value="closed">{{ $t('assignment_state_closed') }}</option>
          <option value="returned">{{ $t('assignment_state_returned') }}</option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { LzIcon, IconBadge, LoadingSpinner } from 'vue_features/shared/components/ui'
import { useGradingStore } from '../composables/use_grading_store'

export default {
  name: 'Actions',
  components: { LzIcon, IconBadge, LoadingSpinner },
  setup() {
    const {
      state,
      hideNames,
      filteredSessions,
      currentSession,
      currentSessionIndex,
      loadingSession,
      sessionResponses,
      subscriptionForSession,
      gotoPreviousSession,
      gotoNextSession,
      updateSessionState,
      storeUpdatedSession,
      currentUserTeachesKlass,
    } = useGradingStore()

    const subscription = computed(() => subscriptionForSession(currentSession.value).subscription)
    const isConnecting = computed(() => subscriptionForSession(currentSession.value).isConnecting)
    const hidingNames = computed(() => hideNames.value)
    const assignmentState = computed(() => currentSession.value.assignmentState)
    const hasComment = computed(() => !!currentSession.value.comment?.content)

    const currentSessionInitials = computed(() => {
      return currentSession.value.studentInitials
    })

    const currentSessionClasses = computed(() => {
      if (isConnecting.value) {
        return ['bg-blue-500']
      } else if (subscription.value) {
        return ['bg-green-200']
      } else {
        return ['bg-red-300']
      }
    })

    const monkeySeeClasses = computed(() => {
      if (hidingNames.value) {
        return ['translate-y-0']
      } else {
        return ['translate-y-full']
      }
    })

    const hasResponsesUpdating = computed(() => sessionResponses.value.some((response) => response.updating))

    const onPreviousSessionButtonClick = () => {
      gotoPreviousSession()
    }

    const onNextSessionButtonClick = () => {
      gotoNextSession()
    }

    const onSessionChooserClick = () => {
      state.showSessionChooser = true
    }

    const onCommentButtonClick = () => {
      state.showSessionCommentModal = true
    }

    const changingAssignmentState = ref(false)

    const onAssignmentStateChange = async (e) => {
      changingAssignmentState.value = true

      const previousState = currentSession.value.assignmentState

      try {
        const response = await updateSessionState(currentSession.value, e.target.value)
        storeUpdatedSession({ ...currentSession.value, ...response })
      } catch (error) {
        storeUpdatedSession({ ...currentSession.value, assignmentState: previousState })
      }

      changingAssignmentState.value = false
    }

    return {
      filteredSessions,
      currentSession,
      currentSessionIndex,
      currentSessionInitials,
      currentSessionClasses,
      loadingSession,
      hasResponsesUpdating,
      hidingNames,
      assignmentState,
      hasComment,
      monkeySeeClasses,
      onPreviousSessionButtonClick,
      onNextSessionButtonClick,
      onSessionChooserClick,
      onCommentButtonClick,
      onAssignmentStateChange,
      changingAssignmentState,
      currentUserTeachesKlass,
    }
  },
}
</script>
