/**
"Object" Method

_.renameKeys(object, keyMap)

Similar to _.mapKeys; this method creates an object with the same
values but renames the keys using the keyMap given.

Arguments

object (Object): The object receiving new key names
keyMap (Object): The object holding old to new key names

Returns

(Object): Returns object with renamed keys

Examples

_.renameKeys({f: 1, i: 12}, {f:"feet", i: "inches"})
// => {feet:1, inches:12}

_.renameKeys({f:3, i:36, y:1}, {y:"yard"})
// => {f:3, i:36, yard:1}
*/

import { isObject, mapKeys } from 'lodash'

export default function renameKeys(object, keyMap) {
  if (!isObject(keyMap)) return object
  return mapKeys(object, function (_, key) {
    return keyMap[key] || key
  })
}
