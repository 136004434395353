import messages from './i18n/messages'
import UserImports from './components/UserImports'

export default function ({ state }) {
  return {
    component: UserImports,
    messages,
    options: {
      provide: {
        availableLessonTypes: state.availableLessonTypes,
        availableScoreScopes: state.availableScoreScopes,
        availableReportScopes: state.availableReportScopes,
      },
    },
  }
}
