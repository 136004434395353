<template>
  <div v-if="isLoggedIn && downloadFileUrl" class="download__button center mt-4 print:hidden">
    <a
      v-track-click.sendBeacon="{ eventName: 'Resource Used', eventProperties: downloadedEventObject }"
      v-bypass-unload-confirmation
      :href="downloadFileUrl"
      :data-resource-json="resourceJsonString"
      data-test="download-button"
      class="btn bg-base hover:bg-base-hover btn--small mt-4 transition-colors"
    >
      <LzIcon path="icons/download" />

      <span>
        {{ $t('common.download') }}
      </span>
    </a>
  </div>
</template>

<script>
import { jsonStringify, parseJson } from 'utils'
import { trackClick, bypassUnloadConfirmation } from 'vue_features/shared/directives'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export const sharedProps = {
  customerId: {
    type: Number,
    default: 0,
  },
  downloadFileUrl: {
    type: String,
    default: '',
  },
  resourceJson: {
    type: [String, Object],
    default: () => ({}),
  },
  downloadedEvent: {
    type: [String, Object],
    default: () => ({}),
  },
}

export default {
  components: {
    LzIcon,
  },

  directives: {
    trackClick,
    bypassUnloadConfirmation,
  },

  props: {
    ...sharedProps,
  },
  setup() {
    const { isLoggedIn } = useCurrentUserStore()
    return { isLoggedIn }
  },
  computed: {
    content() {
      return { customerId: this.customerId }
    },

    // FIXME `resourceJson` and `downloadedEvent` are currently
    // stringified by KO model, resource.js for use in the KO download
    // button template. Once we can remove that template, we can probably
    // pass this props around as JS objects instead
    downloadedEventObject() {
      return parseJson(this.downloadedEvent)
    },

    resourceJsonString() {
      return jsonStringify(this.resourceJson)
    },
  },
}
</script>
