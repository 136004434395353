<template>
  <div class="description-wrapper text-l flex w-full flex-col px-8 pb-16 md:flex-row">
    <ul class="description-meta top-2 mr-2 mt-4 w-72 space-y-2 pl-4">
      <li v-if="logo" class="flex w-32 items-center p-4">
        <img :src="logo" alt="logo" />
      </li>
      <li class="text-lg font-bold uppercase">{{ $t(`readable_types.${lessonType}`) }}</li>
      <li class="text-gray-600">{{ $t('description.from_customer', { customer_name: customerOwner }) }}</li>
      <li v-if="adaptation && forkedFromTitle" class="text-gray-600">
        {{ $t('common.label.copied_from') }}
        <a :href="Routes.lesson_plan_path(forkedFromId)">{{ forkedFromTitle }}</a>
      </li>
      <li v-if="adaptation && ownerName" class="text-gray-600">
        {{ $t('common.label.personalized_by') }}
        {{ ownerName }}
      </li>
      <li v-if="authors" class="text-gray-600">
        {{ $t('common.label.created_by') }}
        <span class="no-fullstory" itemprop="author">
          {{ authors }}
        </span>
      </li>
      <li v-if="authorizedAsTeacher && hasItemCards" class="text-gray-600 text-gray-600">
        <InfoTotalPoints :total-points="totalPoints" />
      </li>
      <TagList :tag-names="tagNames" />
    </ul>
    <div
      id="description"
      class="mx-auto my-6 max-w-4xl flex-1 rounded-lg px-10 py-2"
      :class="{ hidden: isDescriptionBlank }"
    >
      <h1 class="hidden text-3xl font-bold">
        {{ title }}
      </h1>
      <div class="ck-editable" :class="{ 'whitespace-pre-line': isFromTeachfest }">
        <!-- description sanitized in serializer -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-mathjax class="description-container text-lg" itemprop="description" v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import InfoTotalPoints from 'vue_features/lesson_plans/components/InfoTotalPoints'
import TagList from '../../components/TagList'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'Description',
  components: { InfoTotalPoints, TagList },
  setup() {
    const {
      totalPoints,
      lessonType,
      logo,
      description,
      isDescriptionBlank,
      title,
      source,
      customerOwner,
      hasItemCards,
      lastEditor,
      personalized,
      lessonPlanId,
      adaptation,
      tagNames,
      authors,
      forkedFromTitle,
      forkedFromId,
      ownerName,
    } = useLessonPlanStore()
    const { authorizedAsAuthor, authorizedAsTeacher } = useCurrentUserStore()
    const isFromTeachfest = computed(() => source.value?.length > 0)

    return {
      Routes,
      lessonPlanId,
      lastEditor,
      personalized,
      totalPoints,
      authorizedAsTeacher,
      authorizedAsAuthor,
      lessonType,
      description,
      logo,
      isDescriptionBlank,
      title,
      isFromTeachfest,
      customerOwner,
      hasItemCards,
      adaptation,
      tagNames,
      forkedFromTitle,
      forkedFromId,
      ownerName,
      authors,
    }
  },
}
</script>

<style scoped>
.description-container p {
  font-size: 1.125rem;
}
.description-meta .info-total-points strong {
  font-weight: normal;
}
.description-meta .lz-standard {
  margin: 0.25rem;
}
#description {
  background-color: #f4f6fe;
}
</style>
