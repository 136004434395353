<template>
  <Modal content-classes="max-w-sm p-4 space-y-8 overflow-y-scroll h-auto " @backdrop-click="onBackdropClick">
    <h5 class="text-xl">{{ $t('keyboard_shortcuts') }}</h5>
    <div class="space-y-2">
      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('previous_student') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('left_arrow') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('next_student') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('right_arrow') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('previous_question') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('a') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('next_question') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('d') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('submit_partial_credit') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('enter') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('toggle_sidebar') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('s') }}</span>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-muted">{{ $t('show_tips') }}</span>
        <span class="bg-base-hover rounded-lg px-2 py-1 text-base text-sm font-bold">{{ $t('shift') }} + ?</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import { useGradingStore } from '../composables/use_grading_store'
import Modal from './Modal'

export default {
  name: 'TipsModal',
  components: { Modal },
  setup() {
    const { showTipsModal } = useGradingStore()

    const onBackdropClick = () => {
      showTipsModal.value = false
    }

    return {
      onBackdropClick,
    }
  },
}
</script>
