<template>
  <div v-if="hasWarning" class="lzui-tile bg-orange-50 border-orange-200 mb-4 p-4">
    <h3>{{ $t('has_warnings') }}</h3>
    <ul>
      <li v-for="(warning, index) in warnings" :key="index">{{ warning }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CsvWarnings',
  props: {
    warnings: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasWarning() {
      return this.warnings.length > 0
    },
  },
}
</script>
