<template>
  <td>
    <a v-for="(lp, index) in items" :key="`${lp.id}-${index}`" :href="Routes.lesson_plan_path(lp.id)" class="pr-2">
      {{ title(lp, index) }}
    </a>
  </td>
</template>

<script>
import Routes from 'routes'

export default {
  name: 'ItemLinks',
  props: { items: { type: Array, required: true } },
  setup() {
    return { Routes }
  },
  methods: {
    title(lp, index) {
      if (index < this.items.length - 1) {
        return `${lp.title},`
      }
      return lp.title
    },
  },
}
</script>
