export default function useIntersectionObserver(onCardScrolledIntoView, cardElementParent, rootElement) {
  const cardRatios = []
  let observer = null

  const checkIntersections = (entries) => {
    entries.forEach((entry) => {
      const idx = parseInt(entry.target.getAttribute('data-card-index'), 10)
      cardRatios[idx] = entry.intersectionRatio
    })

    let maxIdx = null
    let maxVal = -1
    cardRatios.forEach((ratio, idx) => {
      if (ratio > maxVal) {
        maxIdx = idx
        maxVal = ratio
      }
    })

    onCardScrolledIntoView(maxIdx)
  }

  const observerOptions = {
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    rootMargin: '50px 50px 50px 50px',
    root: rootElement || null,
  }

  const observeCards = () => {
    observer = new IntersectionObserver(checkIntersections, observerOptions)
    const cardElements = Array.from(cardElementParent.value.querySelectorAll(`[data-lesson-card]`))
    cardElements.forEach((element, idx) => {
      cardRatios[idx] = 0
      observer.observe(element)
    })
  }

  const unobserveCards = () => {
    if (observer) {
      observer.disconnect()
      observer = null
    }
  }

  return {
    observeCards,
    unobserveCards,
  }
}
