<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <ChartPane
    v-bind="{
      title,
      subtitle,
      loading,
      error,
      hasEnoughData: bands && bands.length && totalDataPoints > 0,
    }"
  >
    <div ref="root" class="grid grid-cols-2">
      <svg
        ref="chart"
        class="drop-shadow-offset-md self-center overflow-visible pl-1 filter"
        :viewBox="donutViewBox"
        :width="chartWidth"
      >
        <g :transform="groupTransform">
          <g>
            <path
              v-for="(arc, index) in arcPaths"
              :key="`arc-${index}`"
              ref="pathRefs"
              class="transform cursor-pointer stroke-current stroke-1 text-white transition-transform duration-300 hover:scale-105"
              :style="{ fill: datasetColor(index) }"
              :class="{ active: selectedDatum === index }"
              :d="arc"
              :aria-label="labels[index]"
              @mouseover="selectedDatum = index"
              @focus="selectedDatum = index"
              @mouseleave="selectedDatum = null"
              @blur="selectedDatum = null"
              @mousemove="moveTooltip(index, $event)"
            />
          </g>
          <g>
            <text text-anchor="middle" y="-8" class="text-xs font-bold uppercase text-gray-500">
              {{ $t('average') }}
            </text>
            <text text-anchor="middle" class="text-2xl font-bold uppercase text-gray-600" dy="20" y="-6">
              {{ averageScore }}%
            </text>
          </g>
        </g>
        <g :transform="groupTransform" style="pointer-events: none">
          <circle
            cx="0"
            cy="0"
            :r="radius * 0.75"
            stroke="white"
            stroke-opacity="0.23"
            fill="transparent"
            stroke-width="9"
          />
        </g>
      </svg>
      <div
        v-for="(arc, index) in arcPaths"
        :key="`tooltip-${index}`"
        ref="tooltips"
        class="pointer-events-none absolute rounded-sm bg-black bg-opacity-50 p-2 text-xs text-white opacity-0 transition-opacity duration-200"
        :class="{ 'opacity-100': selectedDatum === index }"
      >
        {{ labels[index] }}
      </div>
      <div class="col-span-1 p-4 pr-0">
        <div class="text-xs text-gray-500">
          <div v-for="(value, index) in formattedValues" :key="index">
            <div class="icon-s dataset mt-2 flex pb-1 text-xs">
              <InlineSvg
                path="icons/circle"
                :style="{ fill: datasetColor(index) }"
                class="mr-2 stroke-current stroke-2 text-white"
              />
              {{ value }} {{ totalLabel }}
            </div>
          </div>
        </div>
        <div class="mt-2 text-sm" data-test="total-assignments">{{ totalLabel }} = {{ formattedTotal }}</div>
      </div>
    </div>
  </ChartPane>
</template>

<script>
import { ref, computed } from 'vue'
import { useElementDimensions } from 'vue_features/shared/composables'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { pie, arc } from 'd3'
import { formatNumberForLocale } from 'utils'
import { scoreBandFill } from '../utils'
import ChartPane from './shared/ChartPane'
import { get } from 'lodash'

export default {
  name: 'DonutChart',
  components: { InlineSvg, ChartPane },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    legendTitle: {
      type: String,
      required: true,
    },
    totalLabel: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 190,
    },
    margin: {
      type: Number,
      default: 4,
    },
    innerRadius: {
      type: Number,
      default: 45,
    },
  },
  setup(props) {
    const root = ref(null)
    const chart = ref(null)
    const selectedDatum = ref(null)
    const pathRefs = ref([])
    const tooltips = ref([])
    const bands = computed(() => get(props.data, 'bands', []))
    const averageScore = computed(() => get(props.data, 'averageScore', 0))
    const { width: rootWidth } = useElementDimensions(root)
    const chartWidth = computed(() => rootWidth.value / 2)
    const groupTransform = computed(() => `translate(${chartWidth.value / 2}, ${props.height / 2})`)
    const donutViewBox = computed(() => `0 0 ${chartWidth.value} ${props.height}`)
    const radius = computed(() => Math.max(Math.min(chartWidth.value, props.height) / 2 - props.margin, 0))
    const pieGenerator = pie().value(({ value }) => value)
    const arcGenerator = computed(() => arc().innerRadius(props.innerRadius).outerRadius(radius.value))
    const pieData = computed(() => pieGenerator(bands.value))
    const totalDataPoints = computed(() => bands.value.reduce((total, { value }) => total + value, 0))
    const formattedTotal = computed(() => formatNumberForLocale(totalDataPoints.value))
    const arcPaths = computed(() => pieData.value.map((datum) => arcGenerator.value(datum)))
    const labels = computed(() => bands.value.map(({ label, value }) => `${label}: ${formatNumberForLocale(value)}`))
    const formattedValues = computed(() => bands.value.map(({ value }) => formatNumberForLocale(value)))

    return {
      root,
      chart,
      averageScore,
      bands,
      pathRefs,
      tooltips,
      chartWidth,
      donutViewBox,
      groupTransform,
      radius,
      arcPaths,
      labels,
      formattedValues,
      selectedDatum,
      totalDataPoints,
      formattedTotal,
    }
  },
  methods: {
    datasetColor(index) {
      const band = this.bands[index].band
      return scoreBandFill(band)
    },
    tooltipPosition(index) {
      const pathRef = this.pathRefs[index]

      if (!pathRef) return {}

      const { width, height, left, top } = pathRef.getBoundingClientRect()

      return {
        left: left + width + 'px',
        top: top + height / 2 + 'px',
      }
    },
    moveTooltip(index, $event) {
      const tooltip = this.tooltips[index]

      if (!tooltip) return

      const { pageX, pageY } = $event

      tooltip.style.left = `${pageX}px`
      tooltip.style.top = `${pageY}px`
    },
  },
}
</script>
