<!--
  eslint-disable vuejs-accessibility/anchor-has-content
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <ListDropdown v-if="studentTools.length > 0" id="student-tools-dropdown" :button-classes="['btn--small']">
    <template #button>
      <LzIcon class="tool-icon" path="icons/toolbox" aria-hidden="true" />
      {{ $t('student_tools.tools') }}
      <LzIcon size="sm" path="icons/caret" aria-hidden="true" />
    </template>
    <template #listItems>
      <li v-for="tool in studentTools" :key="tool.id" :class="{ active: tool === openTool }">
        <a :title="$t(`student_tools.label.${tool.name}`)" class="dropdown-item-link" @click="toggleTool(tool)">
          <LzIcon :path="`icons/${tool.name.replace('_', '-')}-small`" />
          {{ $t(`student_tools.label.${tool.name}`) }}
        </a>
        <a
          v-if="tool.helpUrl"
          :title="`${$t(`student_tools.label.${tool.name}`)} ${$t('common.help')}`"
          :href="tool.helpUrl"
          class="dropdown-item-help"
          target="_blank"
        >
          <LzIcon path="icons/help" />
        </a>
      </li>
    </template>
  </ListDropdown>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'StudentToolsDropdown',
  components: { ListDropdown, LzIcon },
  setup() {
    const { openTool, studentTools } = useLessonPlanStore()
    const toggleTool = (newTool) => {
      const isClosingTool = !!openTool.value && newTool.name === openTool.value.name
      openTool.value = isClosingTool ? '' : newTool
    }
    return { studentTools, openTool, toggleTool }
  },
}
</script>
