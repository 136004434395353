import { isEmpty } from 'lodash'
import SiteAdminSchoolsService from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

export const useSiteAdminSchoolsStore = defineStore('siteAdminSchools', () => {
  const state = reactive({
    allSchools: [],
    schoolsData: {
      schools: [],
      meta: {
        pagination: {},
      },
    },
  })

  const mergeableSchools = computed(() => (schoolId) => {
    return state.allSchools.filter((school) => school.id !== schoolId)
  })

  const pagination = computed(() => state.schoolsData.meta.pagination)
  const schools = computed(() => state.schoolsData.schools)
  const schoolCount = computed(() => pagination.value.totalCount)

  function stateDeleteSchool(id) {
    const allSchoolsIndex = state.allSchools.findIndex((s) => s.id === id)
    const schoolsIndex = state.schoolsData.schools.findIndex((s) => s.id === id)

    if (allSchoolsIndex >= 0) {
      state.allSchools.splice(allSchoolsIndex, 1)
    }

    if (schoolsIndex >= 0) {
      state.schoolsData.schools.splice(schoolsIndex, 1)
      state.schoolsData.meta.pagination.totalCount -= 1
    }
  }

  function stateUpdateSchool(school) {
    const index = state.schoolsData.schools.findIndex((s) => s.id === school.id)
    state.schoolsData.schools.splice(index, 1, school)
  }

  function deleteSchool(id) {
    return SiteAdminSchoolsService.deleteSchool(id).then(() => stateDeleteSchool(id))
  }

  function getSchools(page) {
    return SiteAdminSchoolsService.getSchools(page).then((schoolsData) => {
      state.schoolsData = schoolsData
    })
  }

  function loadAllSchools() {
    if (isEmpty(state.allSchools)) {
      SiteAdminSchoolsService.getAllSchools().then((allSchools) => {
        state.allSchools = allSchools
      })
    }
  }

  function mergeSchools({ targetSchoolId, sourceSchoolId }) {
    return SiteAdminSchoolsService.mergeSchools({ targetSchoolId, sourceSchoolId }).then((targetSchool) => {
      stateUpdateSchool(targetSchool)
      stateDeleteSchool(sourceSchoolId)
    })
  }

  function updateSchool(attributes) {
    return SiteAdminSchoolsService.updateSchool(attributes).then((school) => {
      stateUpdateSchool(school)
    })
  }

  return {
    state,
    mergeableSchools,
    pagination,
    schools,
    schoolCount,
    deleteSchool,
    getSchools,
    loadAllSchools,
    mergeSchools,
    updateSchool,
  }
})
