import { ref, computed } from 'vue'
import { get } from 'lodash'
import { useLearnosityClientStore } from './use_learnosity_client_store'
import useLearnosityQuestions from './use_learnosity_questions'

export default function useLearnosityItemMetadata(itemReference, learnosityConfig) {
  if (!itemReference || !learnosityConfig) return
  const { fetchLearnosityClient } = useLearnosityClientStore()

  const itemsApp = ref(null)
  fetchLearnosityClient(learnosityConfig).then((learnosityItemsApp) => {
    itemsApp.value = learnosityItemsApp
  })

  const { questions, clientItem } = useLearnosityQuestions(itemsApp, itemReference)

  const metadata = computed(() => {
    if (!itemsApp.value) return ''
    return clientItem.value.metadata
  })

  const scoringType = computed(() => metadata.value.scoring_type)

  function partialMatchQuestionPoints(validation) {
    const optionsCount = (get(validation, 'valid_response.value') || []).flat().length
    const pointsPerStem = get(validation, 'valid_response.score') || 0
    return optionsCount * pointsPerStem
  }

  function questionPoints(validation) {
    return (
      get(validation, 'max_score') || get(validation, 'valid_response.score') || get(validation, 'valid_score') || 0
    )
  }

  const availablePoints = computed(() => {
    return questions.value.map((question) => {
      const { validation } = question.getQuestion()
      if (
        get(validation, 'scoring_type') === 'partialMatch' ||
        get(validation, 'scoring_type') === 'partialMatchElement'
      ) {
        return partialMatchQuestionPoints(validation)
      }

      return questionPoints(validation)
    })
  })

  return {
    availablePoints,
    scoringType,
  }
}
