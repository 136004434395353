<template>
  <div data-test="assign-materials" class="flex flex-col space-y-6">
    <template v-if="hasKlasses">
      <Card
        data-test="assign-lesson"
        header-classes="bg-white"
        header-target-classes="pt-4 pb-5 pl-4 pr-6 gap-x-1 shadow-inner-xl"
        :select-checked="lessonCard.checked"
        :input-id="lessonCard.contentId.toString()"
        @select-all="selectLesson(lessonCard)"
      >
        <template #header>
          <div class="flex flex-grow flex-col items-start pl-1">
            <span class="text-muted text-xs font-bold uppercase tracking-widest">{{
              $t(`readable_types.${lessonCard.lessonType}`)
            }}</span>
            <span class="text-lg font-bold text-black">{{ lessonCard.title }}</span>
          </div>
        </template>
      </Card>
      <Card
        v-for="group in groupedMaterials"
        :key="group.id"
        :input-id="group.id.toString()"
        data-test="assign-material-group"
        header-target-classes="px-4 py-4 gap-x-1"
        :select-checked="group.checked"
        @select-all="selectGroup(group)"
      >
        <template #header>
          <span class="text-lg font-bold text-black">{{ group.title }}</span>
        </template>
        <div class="flex flex-col">
          <div v-for="card in group.cards" :key="card.id" class="border-base flex items-start border-t px-4 py-2">
            <label :for="`materials-card-${card.id}`" class="flex flex-grow items-start gap-x-3">
              <input
                :id="`materials-card-${card.id}`"
                type="checkbox"
                data-test="assign-material"
                class="my-0 mt-1 h-4 w-4 flex-none"
                :checked="card.checked"
                @change="selectLesson(card, group)"
              />
              <div class="flex flex-grow flex-col">
                <span class="text-muted text-xs font-bold uppercase tracking-widest">{{
                  $t(`readable_types.${card.lessonType}`)
                }}</span>
                <span class="text-sm text-black">{{ card.title }}</span>
              </div>
            </label>
            <a
              :href="card.url"
              :aria-label="$t('page_head_info.preview_assignment')"
              class="mt-1 flex-none"
              target="_blank"
            >
              <LzIcon path="icons/share" />
            </a>
          </div>
        </div>
      </Card>
    </template>
    <NoKlassesEmptyState v-else />
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { LzIcon, Card } from 'vue_features/shared/components/ui'
import NoKlassesEmptyState from './NoKlassesEmptyState'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'AssignMaterials',
  components: { LzIcon, Card, NoKlassesEmptyState },
  setup() {
    const root = inject('useRoot')()
    const { lessonPlan } = useLessonPlanStore()
    const { hasKlasses, materialsToAssign, selectMaterial } = useAssignmentPromptStore()

    // Pre-select the lesson plan
    selectMaterial(lessonPlan.value.id, true)

    const lessonCard = {
      lessonType: lessonPlan.value.lessonType,
      title: lessonPlan.value.title,
      contentId: lessonPlan.value.id,
      checked: materialsToAssign.value.includes(lessonPlan.value.id),
    }
    const _materialCard = (card) => ({
      id: card.id,
      lessonType: card.content.lessonType,
      title: card.title,
      url: card.content.url,
      contentId: card.content.id,
      checked: materialsToAssign.value.includes(card.content.id),
    })
    const groupedMaterials = computed(() => {
      // In the case of a parent LP, its card will come before the first title card
      // The initial group here will get the title set below in that case, or get popped on first title card
      const groups = [{ id: 0, title: null, checked: false, cards: [] }]
      const clearEmpty = () => groups.at(-1).cards.length || groups.pop()
      lessonPlan.value.additionalMaterials.forEach((card, index) => {
        if (isTitleCard(card)) {
          // Drop last group if empty and add new
          clearEmpty()
          groups.push({ id: index, title: card.title, checked: false, cards: [] })
        } else if (card.content?.lessonType) {
          // Have LP card - add to current group (add title for parent LP if missing)
          const currentGroup = groups.at(-1)
          if (!currentGroup.title) currentGroup.title = root.$t('lp_header')
          currentGroup.cards.push(_materialCard(card))
        }
      })

      // Clear last group if empty and check any fully-checked groups
      clearEmpty()
      groups.forEach((group) => (group.checked = !group.cards.find((card) => !card.checked)))
      return groups
    })
    const selectLesson = (card, group) => {
      card.checked = !card.checked
      selectMaterial(card.contentId, card.checked)
      if (!group) return
      if (!card.checked) return (group.checked = false)
      group.checked = !group.cards.find((card) => !card.checked)
    }
    const selectGroup = (group) => {
      group.checked = !group.checked
      group.cards.forEach((card) => {
        card.checked = group.checked
        selectMaterial(card.contentId, group.checked)
      })
    }

    return { hasKlasses, lessonCard, groupedMaterials, selectLesson, selectGroup }
  },
}
</script>
