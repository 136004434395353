import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import URI from 'urijs'
import { viewableBlocks } from 'vue_features/resources/wikis/block_helpers'

export function initPathCrumbsStore(initialState) {
  const { state, pathIds, findCrumbItem, $set } = usePathCrumbsStore()
  $set(initialState)

  // Filter items by permissions
  state.pathCrumbs.forEach((pathCrumb) => {
    const itemBlocks = pathCrumb.items.filter(({ block }) => !!block).map(({ block }) => block)
    const allowedBlocks = viewableBlocks(itemBlocks)
    pathCrumb.items = pathCrumb.items.filter(({ block }) => allowedBlocks.includes(block))
  })

  // Ensure all crumbs making up the 'path' query will render in a breadcrumb dropdown.
  // Case: Visit a Wiki, open a section and visit a link inside an HTML block.
  // Since that link doesn't appear in the Wiki crumb's content dropdown, it's explicitly added.
  state.pathCrumbs.forEach((pathCrumb, index) => {
    if (index < state.pathCrumbs.length - 1) {
      const nextCrumb = { ...state.pathCrumbs[index + 1] }
      if (!findCrumbItem({ index, crumbId: nextCrumb.crumbId })) {
        delete nextCrumb.items
        pathCrumb.items.push({ ...nextCrumb })
      }
    }
  })
  state.selectedPathIds = pathIds.value.slice(1)
}

const RESOURCE_HREF =
  /[/"'](lesson_plans|wikis|resources|documents|videos|htmls|hyperlinks|images|audios|geogebra_items|slides)\/\d+/

export const usePathCrumbsStore = defineStore('pathCrumbs', () => {
  const state = reactive({
    pathCrumbs: [],
    currentResource: {},
    selectedPathIds: [],
  })

  const pathIds = computed(() => state.pathCrumbs.map(({ crumbId }) => crumbId))

  const rootResource = computed(() => (state.pathCrumbs.length > 0 ? state.pathCrumbs[0] : state.currentResource))

  const hasCrumbs = computed(() => state.selectedPathIds.length > 0)

  function findCrumbItemIndex({ index, crumbId }) {
    return state.pathCrumbs[index].items.findIndex((content) => content.crumbId === crumbId)
  }

  function findCrumbItem({ index, crumbId }) {
    const itemIndex = findCrumbItemIndex({ index, crumbId })
    return state.pathCrumbs[index].items[itemIndex]
  }

  const hasPathCrumbs = computed(
    () => state.pathCrumbs.length > 0 && state.pathCrumbs.some(({ items }) => items.length > 0),
  )

  function pathIdsTo(index) {
    return pathIds.value.slice(0, index)
  }

  function urlWithCrumbs(href = '', index = state.pathCrumbs.length - 1) {
    const uri = URI(href)
    if (hasPathCrumbs.value && !uri.fragment() && href.match(RESOURCE_HREF)) {
      const queryPath = pathIdsTo(index + 1).join('/')
      if (queryPath) return uri.addSearch('path', queryPath).href()
    }
    return href
  }

  const currentPath = computed(() => pathIdsTo(state.pathCrumbs.length - 1).join('/'))

  function findSelectedIndex(index) {
    const crumbId = state.selectedPathIds[index]
    return findCrumbItemIndex({ index, crumbId })
  }

  function findSelected(index) {
    const crumbId = state.selectedPathIds[index]
    return findCrumbItem({ index, crumbId })
  }

  function urlForSelectedCrumb(index) {
    const href = findSelected(index).href
    return urlWithCrumbs(href, index)
  }

  function trunc(str, max = 36) {
    if (str.length <= max) return str
    const half = Math.ceil(max / 2)
    return str.slice(0, half) + '...' + str.slice(-half)
  }

  return {
    state,
    rootResource,
    urlForSelectedCrumb,
    urlWithCrumbs,
    hasCrumbs,
    pathIds,
    findSelectedIndex,
    findSelected,
    findCrumbItem,
    hasPathCrumbs,
    trunc,
    pathIdsTo,
    currentPath,
  }
})
