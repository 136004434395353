<template>
  <LzModal
    id="share-modal"
    class="lzui-modal--medium"
    modal-title="Share"
    :modal-id="$options.name"
    :can-cancel="true"
    :autoclose="false"
    @close-modal="close"
  >
    <section>
      <div data-test="share-lz-code">
        <p class="flex flex-col space-y-2">
          <span class="bold text-lg">{{
            $t('assignment_prompt.share_lz_code_tab.heading_assign', { content_type: readableType })
          }}</span>
          <span class="text-xs">{{ $t('assignment_prompt.share_lz_code_tab.assign_message') }}</span>
        </p>
        <div class="my-5">
          <CopyableInput
            v-if="assignment.code"
            :copyable="assignment.code"
            :label="$t('assignment_prompt.share_lz_code_tab.copy_value')"
            data-test="copy-lz-code"
            input-id="copy-lz-code"
            input-class="w-40 py-2"
          />
          <LoadingSpinner v-else data-test="loading-lz-code" :inline="true" tag="span" size="small" />
        </div>
      </div>
      <div data-test="share-url" class="border-primary-200 border-t pt-4">
        <span class="bold text-lg">{{
          $t('assignment_prompt.share_lz_code_tab.with_url', { content_type: readableType })
        }}</span>
        <div class="my-5">
          <CopyableInput
            v-if="assignment.url"
            :copyable="assignment.url"
            :label="$t('assignment_prompt.share_lz_code_tab.copy_value')"
            data-test="copy-url-launch-link"
            input-id="copy-url-launch-link"
            input-class="w-4/5 py-2"
          />
          <LoadingSpinner v-else data-test="loading-url-launch-link" :inline="true" tag="span" size="small" />
        </div>
      </div>
      <div v-if="hasLmsIntegrations" data-test="share-lms" class="border-primary-200 border-t pt-4">
        <p class="flex flex-col space-y-2">
          <span class="bold text-lg">{{ $t('assignment_prompt.lms_tab.lms_heading') }}</span>
          <span class="text-xs">{{ $t('assignment_prompt.lms_tab.lms_message') }}</span>
        </p>
        <div class="my-5">
          <CopyableInput
            v-if="assignment.ltiLaunchUrl"
            :copyable="assignment.ltiLaunchUrl"
            :label="$t('assignment_prompt.share_lz_code_tab.copy_value')"
            data-test="copy-lti-launch-link"
            input-id="copy-lti-launch-link"
            input-class="w-4/5 py-2"
          />
          <LoadingSpinner v-else data-test="loading-lti-launch-link" />
        </div>
      </div>
      <p v-else data-test="lms-message-setup" class="space-x-1 py-4">
        <span>{{ $t('assignment_prompt.lms_tab.lms_message_setup') }}</span>
        <a :href="helpUrl" target="_blank" rel="noopener noreferrer">{{ $t('common.learn_more') }}</a>
      </p>
      <footer v-if="!googleClassroomEnabled && assignment.url" class="flex px-2 py-4">
        <div class="flex items-center space-x-2">
          <p class="text-sm">{{ $t('google_classroom.share_in_google_classroom') }}</p>
          <div id="google-classroom" :data-url="assignment.url" data-size="32" class="g-sharetoclassroom" />
        </div>
        <p class="text-sm">{{ $t('assignment_prompt.google_classroom.enable_passback') }}</p>
      </footer>
    </section>
  </LzModal>
</template>

<script>
import { computed, onMounted, inject } from 'vue'
import $script from 'scriptjs'
import { HELP_LMS_INTEGRATION_URL } from 'rails_constants'
import { LzModal, LoadingSpinner } from 'vue_features/shared/components/ui'
import CopyableInput from './CopyableInput'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useKlassAssignmentsStore } from '../../store'

const name = 'ShareAssignmentModal'

export default {
  name,
  components: { LzModal, LoadingSpinner, CopyableInput },
  setup() {
    const root = inject('useRoot')()
    const { googleClassroomEnabled, hasLmsIntegrations } = useCurrentCustomerStore()
    const { shareAssignment: assignment } = useKlassAssignmentsStore()

    const helpUrl = HELP_LMS_INTEGRATION_URL
    const readableType = computed(() => root.$t(`readable_types.${assignment.value.lessonType}`))
    const close = () => closeModal(name)

    onMounted(() => {
      // see https://developers.google.com/classroom/guides/sharebutton
      if (window.gapi) window.gapi.sharetoclassroom.render('google-classroom')
      else $script('https://apis.google.com/js/platform.js')
    })

    return { assignment, hasLmsIntegrations, readableType, close, googleClassroomEnabled, helpUrl }
  },
}
</script>
