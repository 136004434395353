<template>
  <DateSelect v-if="value" v-bind="calendarProps" :model-value="value" @update:modelValue="onInput" />
</template>

<script>
import { computed, reactive, inject, toRefs } from 'vue'
import DateSelect from '../DateSelect'
import { filter } from 'utils'

const dateSelectPropNames = Object.keys(DateSelect.props).filter((key) => key !== 'modelValue')
const { minDate, maxDate, label, disabled, enableTime, inline, hideInput, minuteIncrement, locale } = filter(
  DateSelect.props,
  (_propDef, name) => name !== 'modelValue',
)

export default {
  name: 'DateRangeCalendar',
  components: { DateSelect },
  props: {
    minDate,
    maxDate,
    label,
    disabled,
    enableTime,
    inline,
    hideInput,
    minuteIncrement,
    locale,
    type: {
      type: String,
      validator: (val) => ['start', 'end'].includes(val),
      required: true,
    },
  },
  setup(props) {
    const state = inject('dateRangeSelectState')
    const calendarProps = reactive(filter(toRefs(props), (_val, key) => dateSelectPropNames.includes(key)))
    const value = computed(() => state.dateRange[props.type])

    const onInput = (date) => {
      state.updateDateRange({ field: props.type, [props.type]: date })
    }

    return { onInput, state, calendarProps, value }
  },
}
</script>
