<template>
  <div class="space-y-4">
    <div
      v-if="confirm"
      class="bg-primary-100 border-primary-200 flex flex-col items-center justify-center gap-2 rounded-lg border p-3"
    >
      <h5>{{ $t('delete_confirm') }}</h5>
      <div class="flex gap-2">
        <button data-test="confirm-cancel" class="btn btn-small h-8 text-sm" @click="confirm = false">{{
          $t('common.cancel')
        }}</button>
        <button data-test="confirm-delete" class="btn btn-small btn--primary h-8 text-sm" @click="deleteComment">{{
          $t('yes_delete')
        }}</button>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col gap-2 rounded-xl border p-3 transition"
      :class="[editing ? 'border-primary-200 shadow-sm' : 'bg-primary-50 border-primary-100']"
    >
      <div class="flex items-center gap-2">
        <span class="comment__avatar text-primary-500 w-6 flex-shrink-0">
          <InlineSvg path="icons/user" />
        </span>
        <span class="font-bold">{{ comment.author || userName }}</span>
        <span class="comment__date text-neutral-700 mt-0.5 text-xs italic">{{
          editing ? hint : commentCreatedAtInWords
        }}</span>
        <div v-if="!editing" class="flex flex-grow justify-end gap-2">
          <span v-if="saved" class="animation--slide-in text-green-500">{{ $t('comment_saved') }}</span>
          <button
            data-test="edit-comment"
            class="text-primary flex items-center rounded-md transition-colors hover:bg-white"
            @click="editing = true"
          >
            <LzIcon size="sm" path="icons/action-edit" />
          </button>
          <button
            v-if="commentPersisted"
            data-test="delete-comment"
            class="text-primary flex items-center rounded-md transition-colors hover:bg-white"
            @click="confirm = true"
          >
            <LzIcon size="sm" path="icons/delete" />
          </button>
        </div>
      </div>
      <div class="text-neutral-800 mb-2 ml-6 break-words text-justify">
        <CommentBox v-if="editing" v-model="text" :disabled="disabled" data-test="comment-box" class="bg-white" />
        <span v-else data-test="comment-display" class="p-2">{{ text }}</span>
      </div>
    </div>
    <div v-if="editing" class="flex items-center justify-end gap-4">
      <span v-if="saved" class="text-green-500">{{ $t('comment_saved') }}</span>
      <button v-if="!newComment" type="button" class="text-important px-4 py-2" @click="editing = false">{{
        $t('common.cancel')
      }}</button>
      <LzButton
        v-if="buttonLabels.length <= 1"
        data-test="submit-comment"
        :aria-label="buttonLabels[0]"
        :disabled="disabled || !buttonLabels.length || textIsEmpty"
        @click="update(buttonLabels[0])"
      >
        {{ buttonLabels[0] }}
      </LzButton>
      <SplitActionDropdown
        v-else
        id="comment-now-later"
        data-test="update-comment-button"
        :dropdown-classes="['max-w-64 right-0']"
        :button-label="buttonLabels[0]"
        :button-aria-label="buttonLabels[0]"
        :disabled="disabled || textIsEmpty"
        @button-click="update(buttonLabels[0])"
      >
        <template #dropdownListItems>
          <li v-for="label in buttonLabels" :key="label">
            <button
              type="button"
              data-test="submit-comment"
              :aria-label="label"
              :disabled="disabled || textIsEmpty"
              @click="update(label)"
            >
              {{ label }}
            </button>
          </li>
        </template>
      </SplitActionDropdown>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue'
import { LzButton, LzIcon, InlineSvg } from 'vue_features/shared/components/ui'
import SplitActionDropdown from 'vue_features/shared/components/ui/dropdowns/SplitActionDropdown'
import CommentBox from './CommentBox'
import { useGradingStore } from '../composables/use_grading_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'CommentEditor',
  components: { LzButton, LzIcon, SplitActionDropdown, InlineSvg, CommentBox },
  props: {
    comment: { type: Object, default: () => ({}) },
    isReturned: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const { id: currentUserId, name: userName } = useCurrentUserStore()
    const { currentUserTeachesKlass } = useGradingStore()
    const text = ref(props.comment?.content || '')
    const editing = ref(text.value === '')
    const confirm = ref(false)
    const saved = ref(false)
    const _updating = ref(false)

    const newComment = computed(() => !props.comment?.content)
    const commentPersisted = computed(() => !!props.comment?.id)
    const _isPublished = computed(() => !!props.comment?.published)
    const _teacherCanComment = computed(
      () => props.comment?.userId === undefined || props.comment.userId === currentUserId.value,
    )
    const _nowLabel = computed(() => root.$t(newComment.value ? 'comment_now' : 'update_now'))
    const _returnLabel = computed(() => root.$t(newComment.value ? 'when_returned' : 'update_show'))
    const buttonLabels = computed(() => {
      const list = []
      if (!props.isReturned) list.push(_returnLabel.value)
      list.push(_nowLabel.value)
      if (_isPublished.value) list.reverse()
      return list
    })
    const hint = computed(() => {
      if (newComment.value) return root.$t('new_comment')
      return root.$t(_isPublished.value ? 'update_published' : 'update_unpublished')
    })
    const commentCreatedAtInWords = computed(() =>
      props.comment?.createdAtInWords ? props.comment?.createdAtInWords + ' ago' : '',
    )
    const disabled = computed(() => _updating.value || !currentUserTeachesKlass.value || !_teacherCanComment.value)
    const textIsEmpty = computed(() => !text.value || (text.value && text.value.length === 0))

    const _flashSaved = () => {
      saved.value = true
      setTimeout(() => (saved.value = false), 4000)
    }
    const update = (actionLabel) => {
      const publish = actionLabel === _nowLabel.value
      _updating.value = true

      emit('update', text.value, publish, (success = true) => {
        if (success) {
          _flashSaved()
          editing.value = false
        }
        _updating.value = false
      })
    }
    const deleteComment = () => {
      emit('delete', props.comment.id, (success = true) => {
        if (success) {
          confirm.value = false
          editing.value = true
        }
      })
    }

    watch(
      () => props.comment,
      (newValue) => (text.value = newValue.content),
    )

    return {
      userName,
      text,
      editing,
      confirm,
      saved,
      newComment,
      buttonLabels,
      hint,
      disabled,
      update,
      deleteComment,
      commentCreatedAtInWords,
      commentPersisted,
      textIsEmpty,
    }
  },
}
</script>
