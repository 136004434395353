import { get as _get } from 'lodash'
import { snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { ref, computed, reactive, watch } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { paramsFromFilters, paramsFromState, defaultDateRange, scoreBandsParams } from '../utils'
import ItemAnalysisService from '../api'

export const useItemAnalysisStore = defineStore('ItemAnalysis', () => {
  const state = reactive({
    allGradeLevels: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    allLessonTypes: [
      {
        key: 'assessment',
        labelKey: 'assessment',
      },
      {
        key: 'activity',
        labelKey: 'activity',
      },
      {
        key: 'full',
        labelKey: 'full_lesson',
      },
    ],
    availableGradeLevelsForFilters: [],
    availableGradeLevelsForLessonPlans: [],
    availableSubjects: [],
    dirty: false,
    earliestDate: new Date(2021, 8, 1),
    getAssignmentAverageSessionScore: null,
    getAssignmentItemScoreSummary: null,
    getAssignmentItemStandards: null,
    getAvailableGradeLevels: null,
    getItemPreviewConfig: null,
    getItemScoreCounts: null,
    getLessonPlanSuggestions: null,
    getSchoolSuggestions: null,
    getTeacherSuggestions: null,
    hideItemDetails: false,
    klassFilterOptions: [],
    lastUpdatedAt: null,
    lessonPlan: null,
    loading: false,
    schoolFilterOptions: [],
    selectedItemRef: null,
    showLessonPlanChooser: false,
    sortOrder: 'item',
    teacherFilterOptions: [],
    filters: {
      gradeLevel: [],
      teacher: [],
      school: [],
      klass: [],
      gradingStatus: null,
      ...defaultDateRange(),
    },
  })

  const selectedLessonPlan = computed(() => state.lessonPlan)
  const selectedLessonPlanId = computed(() => state.lessonPlan?.id)
  const selectLessonPlan = async (lessonPlan, loadSupportingData = false) => {
    if (loadSupportingData) {
      const response = await ItemAnalysisService.getLessonPlanSuggestions({ id: lessonPlan.id })
      const json = await response.json()
      state.lessonPlan = json.suggestions[0]
    } else {
      state.lessonPlan = lessonPlan
    }
  }

  const hasData = computed(() => state.lastUpdatedAt !== null)
  const filterParams = computed(() => paramsFromFilters(state.filters))
  const itemScoreSummary = computed(() => _get(state.getAssignmentItemScoreSummary, ['response', 'data'], []))
  const itemStandards = computed(() => state.getAssignmentItemStandards.response || [])
  const itemStandardsByItemReference = computed(() =>
    itemStandards.value.reduce((all, item) => {
      all[item.learnosity_item_reference] = item.standards
      return all
    }, {}),
  )
  const itemStandardsForItemReference = (itemReference) => {
    return itemStandardsByItemReference.value[itemReference] || []
  }

  const averageSessionScore = computed(() =>
    _get(state.getAssignmentAverageSessionScore, ['response', 'average_score'], null),
  )

  const itemPreviewConfig = computed(() => _get(state.getItemPreviewConfig, 'response', null))

  const itemScoreCounts = computed(() => state.getItemScoreCounts.response || [])

  const itemCardData = computed(() =>
    itemScoreSummary.value
      .map((item) => ({
        ...item,
        standards: itemStandards.value[item.learnosityItemReference],
      }))
      .sort((a, b) => {
        const customizedA = Number(a.isCustomization)
        const customizedB = Number(b.isCustomization)
        const removedA = Number(a.isRemoved)
        const removedB = Number(b.isRemoved)

        switch (state.sortOrder) {
          case 'asc':
            return a.averageScore - b.averageScore
          case 'desc':
            return b.averageScore - a.averageScore
          default:
            if (removedA !== removedB) return removedA - removedB
            if (customizedA !== customizedB) return customizedA - customizedB
            return a.itemNumber - b.itemNumber
        }
      }),
  )

  const itemRefs = computed(() => itemCardData.value.map(({ learnosityItemReference }) => learnosityItemReference))

  const selectedItem = computed(() =>
    itemCardData.value.find((item) => item.learnosityItemReference === state.selectedItemRef),
  )
  const currentItemIndex = computed(() => itemRefs.value.indexOf(selectedItem.value?.learnosityItemReference))
  const atFirstItem = computed(() => currentItemIndex.value === 0)
  const atLastItem = computed(() => currentItemIndex.value === itemRefs.value.length - 1)

  const selectPreviousItem = () => {
    if (atFirstItem.value) return
    state.selectedItemRef = itemRefs.value[currentItemIndex.value - 1]
  }
  const selectNextItem = () => {
    if (atLastItem.value) return
    state.selectedItemRef = itemRefs.value[currentItemIndex.value + 1]
  }

  const totalResponses = computed(() => itemCardData.value.reduce((max, item) => Math.max(max, item.totalResponses), 0))

  const distinctLzCodes = computed(() => [...new Set(itemCardData.value.flatMap(({ lzCodes }) => lzCodes))])

  const dateRange = computed(() => ({ start: state.filters.afterDate, end: state.filters.beforeDate }))
  const dateRangeParams = computed(() => ({ afterDate: dateRange.value.start, beforeDate: dateRange.value.end }))

  function selectItem(item) {
    state.selectedItemRef = item.learnosityItemReference
  }

  const defaultFilters = {
    gradeLevel: [],
    teacher: [],
    school: [],
    klass: [],
    subject: null,
    gradingStatus: null,
    lessonTypes: null,
    ...defaultDateRange(),
  }

  function setDateRange({ start, end }) {
    updateFilters({ afterDate: start, beforeDate: end })
  }

  const setFilter = (prop) => (value) => {
    state.dirty = true
    updateFilters({ [prop]: value })
  }

  const updateFilters = (values = {}) => {
    state.filters = { ...state.filters, ...values }
  }

  const commitParams = (router) => {
    state.dirty = false
    router.push({
      path: Routes.item_analysis_index_path(),
      query: paramsFromState(state),
    })
  }

  const resetFilters = (router) => {
    updateFilters(defaultFilters)
    commitParams(router)
  }

  function reloadFilteredItemData() {
    if (state.lessonPlan && !state.loading) {
      state.loading = true

      Promise.all([
        state.getAssignmentItemScoreSummary.load({
          lesson_plan_id: selectedLessonPlanId.value,
          ...filterParams.value,
          ...scoreBandsParams,
        }),
        state.getAssignmentItemStandards.load({ ...filterParams.value, lesson_plan_id: selectedLessonPlanId.value }),
        state.getAssignmentAverageSessionScore.load({
          ...filterParams.value,
          lesson_plan_id: selectedLessonPlanId.value,
        }),
      ]).then(() => {
        state.loading = false
      })
    }
  }

  const autoCleanFilters = ref(true)
  const withAutoCleanDisabled = (callback) => {
    const previous = autoCleanFilters.value
    autoCleanFilters.value = false
    callback()
    autoCleanFilters.value = previous
  }

  const loadAvailableGradeLevels = async () => {
    const updatedAvailableGradeLevels = await ItemAnalysisService.getAvailableGradeLevels({
      lesson_plan_id: selectedLessonPlanId.value,
    }).then((r) => r.json())

    state.availableGradeLevelsForFilters = updatedAvailableGradeLevels.map((option) => option.id)

    if (autoCleanFilters.value) {
      state.filters.gradeLevel = state.filters.gradeLevel.filter((gradeLevel) =>
        state.availableGradeLevelsForFilters.includes(gradeLevel),
      )
    }
  }

  const loadSchoolSuggestions = async () => {
    if (!state.lessonPlan) {
      state.schoolFilterOptions = []
      return
    }

    state.schoolFilterOptions = await ItemAnalysisService.getSchoolSuggestions(
      snakeKeys({
        lesson_plan_id: selectedLessonPlanId.value,
        ...(state.filters.gradeLevel.length ? { grade_level: state.filters.gradeLevel } : {}),
        ...dateRangeParams.value,
      }),
    ).then((r) => r.json())

    if (autoCleanFilters.value) {
      const schoolOptionIds = new Set(state.schoolFilterOptions.map((option) => String(option.id)))
      state.filters.school = state.filters.school.filter((school) => schoolOptionIds.has(school))
    }
  }

  const loadTeacherSuggestions = async () => {
    state.teacherFilterOptions = await ItemAnalysisService.getTeacherSuggestions(
      snakeKeys({
        lessonPlanId: selectedLessonPlanId.value,
        school: state.filters.school,
        ...dateRangeParams.value,
      }),
    ).then((r) => r.json())

    if (autoCleanFilters.value) {
      const teacherOptionIds = new Set(state.teacherFilterOptions.map((option) => String(option.id)))
      state.filters.teacher = state.filters.teacher.filter((teacher) => teacherOptionIds.has(teacher))
    }
  }

  const loadKlassSuggestions = async () => {
    state.klassFilterOptions = await ItemAnalysisService.getKlassSuggestions(
      snakeKeys({
        lessonPlanId: selectedLessonPlanId.value,
        school: state.filters.school,
        teacher: state.filters.teacher,
        ...dateRangeParams.value,
      }),
    ).then((r) => r.json())

    if (autoCleanFilters.value) {
      const klassOptionIds = new Set(state.klassFilterOptions.map((option) => String(option.id)))
      state.filters.klass = state.filters.klass.filter((klass) => klassOptionIds.has(klass))
    }
  }

  const reloadAllSuggestions = () => {
    loadAvailableGradeLevels()
    loadSchoolSuggestions()
    loadTeacherSuggestions()
    loadKlassSuggestions()
  }

  watch(
    () => state.lessonPlan,
    async (lessonPlan, previousLessonPlan) => {
      if (lessonPlan?.id && lessonPlan?.id !== previousLessonPlan?.id) {
        reloadFilteredItemData()
        await loadAvailableGradeLevels()
        loadSchoolSuggestions()
      }
    },
  )

  watch(itemCardData, (cards) => {
    if (cards.length) {
      const current = cards.find((card) => card.learnosityItemReference === state.selectedItemRef)

      if (!current) {
        state.selectedItemRef = cards[0].learnosityItemReference
      }
    } else {
      state.selectedItemRef = null
    }
  })

  watch(selectedItem, (item, previousItem) => {
    if (!item) {
      return
    }

    if (item?.learnosityItemReference === previousItem?.learnosityItemReference) {
      return
    }

    if (item.learnosityItemReference) {
      state.getItemScoreCounts.load({
        ...filterParams.value,
        lesson_plan_id: selectedLessonPlanId.value,
        learnosity_item_reference: encodeURIComponent(item.learnosityItemReference),
      })
      state.getItemPreviewConfig.load(item.learnosityItemReference)
    }
  })

  watch(
    () => state.filters.gradeLevel,
    (gradeLevels, previousGradeLevels) => {
      if (autoCleanFilters.value && gradeLevels.length !== previousGradeLevels.length) {
        loadSchoolSuggestions()
      }
    },
  )

  watch(
    () => state.filters.school,
    (newSchools, oldSchools) => {
      const schoolsChanged =
        newSchools.length !== oldSchools.length || newSchools.some((id) => !oldSchools.includes(id))

      if (autoCleanFilters.value && schoolsChanged) {
        if (newSchools.length > 0) {
          loadTeacherSuggestions()
        } else {
          state.filters.teacher = []
        }
      }
    },
  )

  watch(
    () => state.filters.teacher,
    (newTeachers, oldTeachers) => {
      const teachersChanged =
        newTeachers.length !== oldTeachers.length || newTeachers.some((id) => !oldTeachers.includes(id))

      if (autoCleanFilters.value && teachersChanged) {
        if (newTeachers.length > 0) {
          loadKlassSuggestions()
        } else {
          state.filters.klass = []
        }
      }
    },
  )

  return {
    atFirstItem,
    atLastItem,
    averageSessionScore,
    commitParams,
    currentItemIndex,
    dateRange,
    defaultFilters,
    distinctLzCodes,
    hasData,
    itemCardData,
    itemPreviewConfig,
    itemRefs,
    itemScoreCounts,
    itemScoreSummary,
    itemStandards,
    itemStandardsForItemReference,
    reloadFilteredItemData,
    resetFilters,
    selectItem,
    selectLessonPlan,
    selectNextItem,
    selectPreviousItem,
    selectedItem,
    selectedLessonPlan,
    setDateRange,
    setFilter,
    state,
    totalResponses,
    updateFilters,
    withAutoCleanDisabled,
    reloadAllSuggestions,
  }
})
