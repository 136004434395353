import { computed } from 'vue'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

const uniqueBy = (list = [], uniquenessIdentifier = (a) => a) => {
  return Object.values(
    list.reduce((uniqueItems, item) => {
      uniqueItems[uniquenessIdentifier(item)] ||= item
      return uniqueItems
    }, {}),
  )
}

export default function useLessonPlanStandards() {
  const { standards, customerVisibleStandards } = useLessonPlanStore()

  const allStandards = computed(() => uniqueBy(standards.value, (standard) => standard.displayCode))

  const allCustomerVisibleStandards = computed(() =>
    uniqueBy(customerVisibleStandards.value, (standard) => standard.displayCode),
  )

  return {
    allStandards,
    allCustomerVisibleStandards,
  }
}
