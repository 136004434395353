import { ref } from 'vue'
import { throttle } from 'lodash'
import useEventListener from './use_event_listener'

export default function useViewportSize(throttleTimeout = 50) {
  const width = ref(window.innerWidth)
  const height = ref(window.innerHeight)

  useEventListener(
    'resize',
    throttle(() => {
      width.value = window.innerWidth
      height.value = window.innerHeight
    }, throttleTimeout),
  )

  return { width, height }
}
