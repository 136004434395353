export default function (element, containerElement, vertical = false) {
  if (!element) return

  const elementLeadingEdge = element[vertical ? 'offsetTop' : 'offsetLeft']
  const containerScroll = containerElement[vertical ? 'scrollTop' : 'scrollLeft']
  const containerDimension = containerElement[vertical ? 'offsetHeight' : 'offsetWidth']
  const elementDimension = element[vertical ? 'offsetHeight' : 'offsetWidth']

  const displayCenter = containerDimension * 0.5 - elementDimension * 0.5

  const scrollCenter = Math.floor(containerScroll + displayCenter)

  if (elementLeadingEdge != scrollCenter) {
    containerElement[vertical ? 'scrollTop' : 'scrollLeft'] = elementLeadingEdge - displayCenter
  }
}
