<template>
  <div>
    <LzAccordion
      v-for="(itemReference, index) in itemReferences"
      :key="`${itemReference}`"
      :label="`${$t('common.item')} ${index + 1}`"
      open
      color="focus"
      class="bg-focus"
    >
      <template #head="{ label, toggle, isOpen }">
        <button class="group flex w-full cursor-pointer items-center gap-2 p-3" @click.prevent="toggle">
          <AccordionIcon :is-open="isOpen" />
          <div class="inline-block font-bold">
            {{ label }}
            <Badge color="gray" data-test="not-attempted-badge">
              {{ $t('assignment_response_report.unattempted') }}
            </Badge>
          </div>
        </button>
      </template>
      <MathJaxLoadingSpinner>
        <!-- TODO: replace with LearnosityItem.vue -->
        <div :data-reference="itemReference" class="learnosity-item m-4" />
      </MathJaxLoadingSpinner>
    </LzAccordion>
  </div>
</template>

<script>
import { AccordionIcon, LzAccordion, Badge } from 'vue_features/shared/components/ui'
import { fetchItemsApp } from 'clients/learnosity'
import MathJaxLoadingSpinner from 'vue_features/shared/components/MathJaxLoadingSpinner'

export default {
  name: 'ReturnedAssignmentUnattempted',
  components: {
    LzAccordion,
    Badge,
    AccordionIcon,
    MathJaxLoadingSpinner,
  },

  props: {
    itemReferences: {
      type: Array,
      default: () => [],
    },
    learnosityAssessmentConfig: {
      type: Object,
      required: true,
    },
  },

  created() {
    fetchItemsApp(this.learnosityAssessmentConfig)
  },
}
</script>
