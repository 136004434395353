<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="listable-editor w-full">
    <StandardsCoverageTableLoader
      v-bind="content"
      :is-refreshing="isRefreshing"
      class="pr-2.5"
      @new-table-id="updateTableId"
    />
  </div>
</template>

<script>
import StandardsCoverageTableLoader from 'vue_features/standards_coverage_table/components/StandardsCoverageTableLoader'

export default {
  name: 'StandardsTableEditor',
  components: { StandardsCoverageTableLoader },
  props: {
    content: { type: Object, default: () => null },
    isRefreshing: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    return { updateTableId: (newId) => emit('update-block', { content: { ...props.content, tableId: `${newId}` } }) }
  },
}
</script>
