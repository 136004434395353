import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { initCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'
import { useLessonPlanStore } from '../../store/use_lesson_plan_store'

export function initLessonPlanEditStore(initState) {
  useLessonPlanEditStore().$set(initState.lessonPlanEditState)
  useLessonPlanStore().$set(initState.lessonPlanState)
  initCardDeckEditorStore(initState.deckEditorState)
  useAdditionalMaterialsStore().$set(initState.additionalMaterialsState)
}

export const useLessonPlanEditStore = defineStore('lessonPlanEdit', () => {
  const state = reactive({
    isProgressCoverDisplayed: false,
    canManageState: false,
    isCreatingMetaEdits: false,
    isCreatingPersonalEdits: false,
    isRevising: false,
    ownerId: null,
  })

  return {
    state,
  }
})
