<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="page-content">
    <DeleteLMSIntegrationModal
      v-if="canModalShow($options.DeleteLMSIntegrationModal)"
      id="lms-delete"
      :lms-integration-id="activeModal.payload"
      :authenticity-token="authenticityToken"
    />
    <AddLMSIntegrationModal
      v-if="canModalShow($options.AddLMSIntegrationModal)"
      id="lms-integration-modal"
      :inactive-lms-integrations="inactiveLmsIntegrations"
      :authenticity-token="authenticityToken"
    />
    <DeleteLtiV13IntegrationModal
      v-if="canModalShow($options.DeleteLtiV13IntegrationModal)"
      id="lms-13-delete"
      :lms-integration-id="activeModal.payload"
      :authenticity-token="authenticityToken"
    />
    <AddLtiV13IntegrationModal
      v-if="canModalShow($options.AddLtiV13IntegrationModal)"
      id="lms-13-integration-modal"
      :inactive-lms-integrations="inactiveLtiV13Integrations"
      :authenticity-token="authenticityToken"
    />

    <div id="help-link">
      <div v-if="helpUrl !== null" class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="mb-4 text-right">
          <div class="icon icon small">
            <LzIcon path="icons/help" />
            <a :href="helpUrl" target="_blank" rel="noopener noreferrer">
              {{ $t('lms_setup_help') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="lms-13-integrations">
      <Grid v-if="hasActiveLti13Integrations" tag="header" class="mx-auto">
        <div class="col-span-8">
          <h2 class="mt-0">{{ $t('active_integrations1_3') }}</h2>
        </div>
        <div class="col-span-4">
          <a
            v-if="hasInactiveLti13Integrations"
            class="btn btn--primary add-integration float-right mb-4"
            @click="handleAddLti13Integration"
          >
            {{ $t('enable_new_lms1_3') }}
          </a>
        </div>
      </Grid>
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="w-full">
          <EmptyStateWithIcon
            v-if="!hasActiveLti13Integrations"
            path="icons/integration"
            :heading="$t('no_current_integrations1_3')"
          >
            <a
              v-if="hasInactiveLti13Integrations"
              class="btn btn--primary add-integration mb-4"
              @click="handleAddLti13Integration"
            >
              {{ $t('enable_new_lms1_3') }}
            </a>
          </EmptyStateWithIcon>
          <ul v-else class="space-y-2">
            <LtiV13IntegrationListItem
              v-for="integration of activeLtiV13Integrations"
              :key="integration.id"
              :integration="integration"
              @delete="handleDeleteLti13ButtonClick"
            />
          </ul>
        </div>
      </div>
    </div>

    <div id="lms-integrations" class="mt-12">
      <div class="mx-auto max-w-7xl">
        <Grid v-if="hasActiveIntegrations" tag="header">
          <div class="col-span-8">
            <h2 class="mt-0">{{ $t('active_integrations1_1') }}</h2>
          </div>
          <div class="col-span-4">
            <a
              v-if="hasInactiveIntegrations"
              class="btn btn--primary add-integration float-right mb-4"
              @click="handleAddIntegration"
            >
              {{ $t('enable_new_lms1_1') }}
            </a>
          </div>
        </Grid>
      </div>
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="w-full">
          <EmptyStateWithIcon
            v-if="!hasActiveIntegrations"
            path="icons/integration"
            :heading="$t('no_current_integrations1_1')"
          >
            <a
              v-if="hasInactiveIntegrations"
              class="btn btn--primary add-integration mb-4"
              @click="handleAddIntegration"
            >
              {{ $t('enable_new_lms1_1') }}
            </a>
          </EmptyStateWithIcon>
          <ul v-else class="space-y-2">
            <LMSIntegrationListItem
              v-for="integration of activeLmsIntegrations"
              :key="integration.id"
              :integration="integration"
              @delete="handleDeleteButtonClick"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmptyStateWithIcon, LzIcon } from 'vue_features/shared/components/ui'
import { metaTags } from 'utils'
import AddLMSIntegrationModal from './AddLMSIntegrationModal'
import AddLtiV13IntegrationModal from './AddLtiV13IntegrationModal'
import DeleteLMSIntegrationModal from './DeleteLMSIntegrationModal'
import DeleteLtiV13IntegrationModal from './DeleteLtiV13IntegrationModal'
import LMSIntegrationListItem from './LMSIntegrationListItem'
import LtiV13IntegrationListItem from './LtiV13IntegrationListItem'
import { openModal, canModalShow, activeModal } from 'vue_features/shared/composables/use_global_modals'
import Grid from 'vue_features/shared/components/ui/Grid'

export default {
  name: 'LMSIntegrations',
  AddLMSIntegrationModal,
  DeleteLMSIntegrationModal,
  AddLtiV13IntegrationModal,
  DeleteLtiV13IntegrationModal,
  components: {
    Grid,
    LzIcon,
    EmptyStateWithIcon,
    AddLMSIntegrationModal,
    AddLtiV13IntegrationModal,
    DeleteLMSIntegrationModal,
    DeleteLtiV13IntegrationModal,
    LMSIntegrationListItem,
    LtiV13IntegrationListItem,
  },
  props: {
    availableLmsIntegrations: {
      type: Array,
      required: true,
    },
    activeLmsIntegrations: {
      type: Array,
      required: true,
    },
    availableLtiV13Integrations: {
      type: Array,
      required: true,
    },
    activeLtiV13Integrations: {
      type: Array,
      required: true,
    },
    helpUrl: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { openModal, canModalShow, activeModal }
  },
  data() {
    return {
      authenticityToken: metaTags.csrfToken(),
    }
  },
  computed: {
    inactiveLmsIntegrations() {
      const activeIntegrationNames = this.activeLmsIntegrations.map((integration) => integration.name)
      return this.availableLmsIntegrations.filter((integration) => !activeIntegrationNames.includes(integration))
    },
    hasActiveIntegrations() {
      return this.activeLmsIntegrations.length > 0
    },
    hasInactiveIntegrations() {
      return this.inactiveLmsIntegrations.length > 0
    },
    inactiveLtiV13Integrations() {
      const activeIntegrationNames = this.activeLtiV13Integrations.map((integration) => integration.name)
      return this.availableLtiV13Integrations.filter((integration) => !activeIntegrationNames.includes(integration))
    },
    hasActiveLti13Integrations() {
      return this.activeLtiV13Integrations.length > 0
    },
    hasInactiveLti13Integrations() {
      return this.inactiveLtiV13Integrations.length > 0
    },
  },
  methods: {
    handleAddLti13Integration() {
      this.openModal(this.$options.AddLtiV13IntegrationModal)
    },
    handleDeleteLti13ButtonClick(id) {
      this.openModal(this.$options.DeleteLtiV13IntegrationModal, id)
    },
    handleAddIntegration() {
      this.openModal(this.$options.AddLMSIntegrationModal)
    },
    handleDeleteButtonClick(id) {
      this.openModal(this.$options.DeleteLMSIntegrationModal, id)
    },
  },
}
</script>
