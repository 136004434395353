<template>
  <div
    class="lesson-thumbs opacity-1 sticky z-0 flex-shrink-0 pt-2 transition-all"
    :class="showSidebar ? 'show-sidebar' : 'hide-sidebar'"
    :aria-hidden="showSidebar ? 'false' : 'true'"
  >
    <ul ref="lessonThumbs" class="w-full space-y-4 overflow-y-auto px-2 pb-6 pt-3">
      <li v-for="(card, index) in cards" :key="card.id">
        <button
          ref="lessonThumb"
          type="button"
          :tabindex="showSidebar ? 0 : -1"
          class="lesson-thumb btn relative flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-4 shadow-md ring-2 ring-offset-2 transition duration-200"
          :class="[{ 'hover:shadow-xl': selectedCardIndex !== index, 'active-card': selectedCardIndex === index }]"
          @click="$emit('card-selected', index)"
        >
          <div class="absolute left-0 top-0 text-xs opacity-50" :class="{ 'text-white': selectedCardIndex === index }">
            {{ index + 1 }}
          </div>
          <div class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap py-6 text-center text-xs">
            <span :class="[{ 'text-white': selectedCardIndex === index }]">
              {{ card.id === END_CARD_ID ? $t('common.review') : card.title }}
            </span>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { toRef, ref, watch, watchEffect } from 'vue'
import scrollItemToView from 'vue_features/lesson_plans/show/helpers/scroll_item_to_view'
import { END_CARD_ID } from 'vue_features/assignments/show/composables/use_assignment_cards_store'

export default {
  name: 'LessonThumbs',
  props: {
    cards: {
      type: Array,
      required: true,
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    selectedCardIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const lessonThumbs = ref(null)
    const lessonThumb = ref([])

    const scrollToCurrentCard = () => {
      const element = lessonThumb.value[props.selectedCardIndex]
      if (!element) return
      scrollItemToView(element, lessonThumbs.value, true)
    }

    watchEffect(() => {
      if (!lessonThumb.value) return

      if (lessonThumb.value.length > props.selectedCardIndex) {
        scrollToCurrentCard()
      }
    })

    const showSidebar = toRef(props, 'showSidebar')
    watch(showSidebar, () => {
      if (showSidebar.value) {
        scrollToCurrentCard()
      }
    })

    return {
      lessonThumb,
      lessonThumbs,
      END_CARD_ID,
    }
  },
}
</script>

<style scoped>
.lesson-thumb {
  border-color: #f4f6fe;
  background-color: #f4f6fe;
  --tw-ring-color: #f4f6fe;
}

.lesson-thumb.active-card {
  border-color: #4f47f3;
  background-color: #4f47f3;
  --tw-ring-color: #4f47f3;
}

.lesson-thumb:focus-visible {
  border-color: #4f47f3;
}
.lesson-thumbs.show-sidebar {
  height: calc(100vh - 5rem);
}
.lesson-thumbs.hide-sidebar {
  @apply max-h-full !important;
  height: calc(100vh - 1.5rem);
}
</style>
