// Uses global vars: $, mixpanel

// Much usage of the `window.mixpanel` object is handled by Segment's analytics.js

import { dropRight, get, result, toPath } from 'lodash'

import debug from 'debug'
const log = debug('app:clients/mixpanel')

function stub(...args) {
  log('window.mixpanel not found; failed invocation', ...args)
}

function mixpanelApi(path, ...args) {
  const thisArg = get(window.mixpanel, dropRight(toPath(path), 1).join('.'))
  get(window.mixpanel, path, stub).call(thisArg || window.mixpanel, ...args)
}

export function getConfig(...args) {
  return mixpanelApi('get_config', ...args)
}

export function setConfig(config = {}) {
  mixpanelApi('set_config', config)
}

export async function withTempConfig(config = {}, fn) {
  const prevConfig = { ...window.mixpanel.config }
  setConfig(config)
  await fn()
  setConfig(prevConfig)
}

export function identify(userId, traits, { updateTraits = false } = {}) {
  log('Identify user %s', userId)

  // identify() associates a session with a user's distinct ID
  // See: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
  mixpanelApi('identify', userId)

  if (updateTraits) {
    // people.set() updates user profile traits, and should be called in the same context as identify()
    // See: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset
    log('Update user traits: %O', traits)
    mixpanelApi('people.set', traits)
  }
}

// Call mixpanel.register() to add a set of super properties, which are included with every event
// See: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister
export function registerSuperProperties(userId, traits) {
  const superProps = { id: userId, ...traits, user_agent: navigator.userAgent }

  log('Register super properties: %O', superProps)
  mixpanelApi('register', superProps)
}

export function increment(trait, count) {
  const data = {}
  data[trait] = count
  mixpanelApi('people.increment', data)
}

export function track(event, properties, options) {
  mixpanelApi('customer.track', event, properties, options)
}

export function clearCookie() {
  mixpanelApi('cookie.clear')
}

export function cookieProperties() {
  return result(window.mixpanel, 'cookie.properties', {})
}
