import { computed, isRef } from 'vue'
import { get, isEmpty } from 'lodash'
import URI from 'urijs'

export default function useFilestackAccessor(propsOrFile, key = 'value') {
  if (key === 'value' && !isRef(propsOrFile)) {
    propsOrFile = { value: propsOrFile }
  }

  if (get(propsOrFile, key).baseUrl && !isEmpty(URI(get(propsOrFile, key).baseUrl).search(true))) {
    throw new Error('Error: FilestackAccessor.baseUrl can not contain params')
  }

  const fileExists = computed(() => !!get(propsOrFile, key).baseUrl)
  const filename = computed(() => get(propsOrFile, key).metadata?.filename)
  const fileUrl = computed(() => {
    if (get(propsOrFile, key).baseUrl) {
      return URI(get(propsOrFile, key).baseUrl)
        .search(() => {
          return {
            dl: true,
            policy: get(propsOrFile, key).filestackOptions.policy,
            signature: get(propsOrFile, key).filestackOptions.signature,
          }
        })
        .toString()
    }

    return null
  })

  return {
    fileExists,
    filename,
    fileUrl,
  }
}
