<template>
  <LzConfirmModal :modal-id="$options.name" :modal-title="$t('manage_button.error')">
    <template #confirmBody>
      <p class="my-2">{{ $t('manage_modal.error_processing') }}</p>
      <p class="my-2">{{ $t('manage_modal.reload_prompt') }}</p>
    </template>
    <template #cancelButton="{ close }">
      <button class="btn" @click.stop="close">
        {{ $t('manage_modal.ok') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'

export default {
  name: 'AssignmentsErrorModal',
  components: { LzConfirmModal },
}
</script>
