<template>
  <li :class="['flex flex-col rounded-2xl shadow', user.archived ? 'bg-gray-100' : 'bg-white']">
    <div v-if="user.isPendingDeletion" class="lzui-tile mb-4 border-blue-200 bg-blue-50 p-4">
      <strong class="mt-4">
        {{ $t('user_deletion_notice') }}
      </strong>
    </div>
    <div class="grid grid-cols-12 p-3">
      <LzIcon :path="iconPath" class="text-primary-500 col-span-1 mx-auto h-6 w-6 md:h-8 md:w-8" />
      <UserEditForm
        v-if="editing"
        :user="user"
        class="col-span-11 px-4 pb-4"
        @cancel="toggleEditing"
        @saved="toggleEditing"
      />
      <UserInfo v-else :user="user" class="col-span-11" @edit="toggleEditing" />
    </div>
  </li>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import UserEditForm from './UserEditForm'
import UserInfo from './UserInfo'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'UserEditTile',
  components: { UserEditForm, LzIcon, UserInfo },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { authorizedAsCustomerAdmin } = useCurrentUserStore()
    return { authorizedAsCustomerAdmin }
  },
  data() {
    return { editing: false }
  },
  computed: {
    iconPath() {
      return this.user.isFromClever && this.authorizedAsCustomerAdmin ? 'icons/sis-class' : 'icons/user'
    },
  },
  methods: {
    toggleEditing() {
      if (this.user.isPendingDeletion) {
        this.editing = false
      } else {
        this.editing = !this.editing
      }
    },
  },
}
</script>
