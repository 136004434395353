import { cloneDeep } from 'lodash'
import { sortByRank, addBlockInserts } from 'utils/card_rank_helpers'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { ACTIVE, ARCHIVED } from 'vue_features/shared/composables/use_record_states'

export function blockJSON(block) {
  const { id, contentType, detailType, sectionId, rank, display } = block
  switch (detailType || contentType) {
    case 'Html':
      return block
    case 'Section':
      return {
        id,
        detailType,
        sectionId,
        rank,
        display,
        title: block.title,
        content: { blocks: block.content.blocks.map((b) => blockJSON(b)) },
      }
    default:
      return {
        id,
        contentType,
        sectionId,
        rank,
        display,
        contentId: block.content.id || block.content.tableId,
      }
  }
}

export function viewableBlocks(blocks) {
  return blocks.filter((b) => {
    if (b.detailType !== 'Html' && (b.contentType === 'Resource' || b.contentType === 'LessonPlan')) {
      return [ACTIVE, ARCHIVED].includes(b.content.lzContent.state) || useCurrentUserStore().authorizedAsAuthor.value
    }
    return true
  })
}

export function structureBlocks(blocks, insertedBlocks = []) {
  return placeInSections(
    addBlockInserts(sortByRank(viewableBlocks(blocks)), insertSections(sortByRank(viewableBlocks(insertedBlocks)))),
  )
}

export function insertSections(insertedBlocks) {
  const newBlocks = cloneDeep(insertedBlocks)
  const sectionIds = []
  newBlocks.forEach((block) => {
    if (block.detailType === 'Section') {
      sectionIds.push(block.id)
      block.content.blocks = newBlocks.filter((b) => b.sectionId === block.id)
    }
  })
  return newBlocks.filter((b) => !sectionIds.includes(b.sectionId))
}

export function placeInSections(blocks) {
  const newBlocks = cloneDeep(blocks)
  newBlocks.forEach((block) => {
    if (block.detailType === 'Section' && block.content.blocks.length === 0) {
      const isPersisted = !!block.id
      const id = isPersisted ? 'id' : 'oldId'
      const sectionId = isPersisted ? 'sectionId' : 'oldSectionId'
      block.content.blocks = newBlocks.filter((b) => b[sectionId] === block[id])
    }
  })
  return newBlocks.filter((b) => !b.sectionId && !b.oldSectionId)
}

const LIST_DISPLAYS = ['list', 'block']

export function combineListItems(blocks) {
  const newBlocks = cloneDeep(blocks)
  const combinedBlocks = []
  let currentList = [newBlocks.shift()]

  const pushItem = (block) => {
    const item = currentList[0]
    if (item && currentList.length === 1 && item.detailType === 'Section') {
      item.content.blocks = combineListItems(item.content.blocks)
    }
    combinedBlocks.push(currentList)
    currentList = [block]
  }

  newBlocks.forEach((block) => {
    if (!LIST_DISPLAYS.includes(block.display) || block.display !== currentList[0].display) {
      pushItem(block)
    } else {
      currentList.push(block)
    }
  })
  pushItem()

  return combinedBlocks
}

export function flattenSections(blocks) {
  const flatten = (blocks) => {
    return blocks
      .map((block) => {
        return block.detailType === 'Section' ? [block, flatten(block.content.blocks)] : block
      })
      .flat(2)
  }
  return flatten(blocks)
}

export function orderedEmbeds({ blocks, insertedBlocks }) {
  return flattenSections(structureBlocks(blocks, insertedBlocks)).filter((b) => b.display !== 'embed')
}
