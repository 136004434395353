<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <label class="h5 mb-0 mt-6" style="display: block">
    {{ label }}
    <p v-if="info" class="small">{{ info }}</p>
    <select
      :value="modelValue"
      :disabled="!enabled"
      class="form-control w-1/2 md:w-1/3"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option v-for="option in options" :key="option[1]" :value="option[1]">
        {{ option[0] }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'SettingsSelect',
  props: {
    label: { type: String, required: true },
    options: { type: Array, required: true },
    enabled: { type: Boolean, required: true },
    modelValue: { type: [String, Number], required: true },
    info: { type: String, default: '' },
  },
}
</script>
