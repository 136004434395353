<template>
  <button
    :class="{
      'bg-sky-300 hover:bg-sky-200 text-primary-700': hasAttemptedAllQuestions,
      'border-2 border-white ring-2 ring-inset ring-blue-700': hasAttemptedAllQuestions,
      'border border-dashed border-white': !hasAttemptedAllQuestions,
      'triangle-top-down': isSelected,
      'text-primary-700 bg-white': isSelected,
      'bg-primary-700 hover:bg-primary-500 text-white': !isSelected,
    }"
    :aria-label="hasAttemptedAllQuestions ? $t('toolbar.answered_item') : $t('toolbar.unanswered_item')"
  >
    <slot />
  </button>
</template>

<script>
import useLearnosityItemResource from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_resource'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'

export default {
  name: 'PositionIndicatorStatusDot',
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { learnosityConfig } = useLearnosityConfigStore()
    const { hasAttemptedAllQuestions } = useLearnosityItemResource(props.card.content.content, learnosityConfig.value)

    return {
      hasAttemptedAllQuestions,
    }
  },
}
</script>

<style scoped>
.bg-sky-300 {
  background-color: #7dd3fc !important;
}

.hover\:bg-sky-200:hover {
  background-color: #bae6fd !important;
}
</style>
