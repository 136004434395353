<template>
  <RailsForm
    id="login_form"
    :action="Routes.auth_callback_path({ provider: 'identity' })"
    class="simple_form session"
    method="post"
  >
    <div class="space-y-4">
      <div class="form-box email required session_email">
        <label class="email required" for="session_email">{{ $t('common.email') }}</label>
        <input
          id="session_email"
          ref="email"
          name="auth_key"
          class="string email required form-control no-fullstory"
          required="required"
          aria-required="true"
          type="email"
          autocomplete="email"
        />
      </div>
      <div class="form-box password required session_password">
        <label class="password required" for="session_password">{{ $t('common.password') }}</label>
        <input
          id="session_password"
          name="password"
          class="password required form-control no-fullstory"
          required="required"
          aria-required="true"
          type="password"
          autocomplete="current-password"
        />
      </div>
      <div class="md:flex md:justify-between">
        <div class="space-x-1">
          <template v-if="!hasSessionTimeout">
            <input
              id="session_stay_signed_in_teacher"
              value="1"
              checked="checked"
              type="checkbox"
              name="session[stay_signed_in]"
              data-test="stay-signed-in-checkbox"
            />
            <label class="string required regular" for="session_stay_signed_in_teacher">
              {{ $t('session.stay_signed_in') }}
            </label>
          </template>
        </div>
        <a :href="Routes.new_password_reset_path()" class="block">{{ $t('common.forgot_password') }}</a>
      </div>
      <div class="space-y-2">
        <input
          :value="inAssignmentPrompt ? $t('lesson_plan.begin_assignment') : $t('common.login')"
          :class="['btn', 'btn--primary', 'w-full', ...assignmentBtnClasses]"
          type="submit"
          name="commit"
        />
        <div class="text-center">
          <a v-if="!isPublic && !inAssignmentPrompt" :href="Routes.activate_path()" data-test="activate-link">
            {{ $t('common.need_to_activate') }}
          </a>
        </div>
      </div>
    </div>
  </RailsForm>
</template>

<script>
import { watch, ref } from 'vue'
import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, decorators, simpleForm } from 'vue_features/shared/i18n'
import { RailsForm } from 'vue_features/shared/components/forms'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

const messages = merge(
  {},
  namespaceLocaleObj(decorators, '', { only: 'lesson_plan.begin_assignment' }),
  namespaceLocaleObj(common, 'common', {
    only: ['login', 'email', 'password', 'forgot_password', 'need_to_activate', 'or'],
  }),
  pickLocaleNamespace(simpleForm, 'labels.session'),
)

export default {
  name: 'TeacherLoginForm',
  components: { RailsForm },
  i18n: { messages },
  props: {
    inAssignmentPrompt: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const email = ref(null)
    watch(email, (val) => val && val.focus())

    const { authPreference, isPublic, hasSessionTimeout } = useCurrentCustomerStore()
    return { Routes, authPreference, isPublic, hasSessionTimeout, email }
  },
  computed: {
    assignmentBtnClasses() {
      return this.inAssignmentPrompt ? ['w-full', 'py-4'] : []
    },
  },
}
</script>
