<template>
  <div>
    <section>
      <CsvWarnings :warnings="warnings" />
      <div v-if="hasUsers" class="space-y-2">
        <p>
          {{ $t('preview_instructions') }}
        </p>
        <ul id="preview-list" class="no-fullstory mb-12">
          <UserTile v-for="user in users" :key="user.id" :user="user" />
        </ul>
      </div>
      <p v-if="requiresConfirmation">
        {{ $t('archive_note') }}
      </p>
      <ImportErrors :errors="errors" />
    </section>
    <footer>
      <AsyncContentLoader :loader="currentRequest">
        <button class="btn" @click.prevent.stop="close">
          {{ $t('common.cancel') }}
        </button>
        <button :class="['btn btn--primary', { caution: requiresConfirmation }]" @click.prevent.stop="startImport">
          {{ confirmText }}
        </button>
      </AsyncContentLoader>
    </footer>
  </div>
</template>

<script>
import { get } from 'lodash'
import { location } from 'utils'
import { AsyncContentLoader } from 'vue_features/shared/components/ui'
import ImportErrors from './ImportErrors'
import CsvWarnings from './CsvWarnings'
import UserTile from './UserTile'
import UserImportService from '../api/user_import_service'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'ImportPreview',
  components: { AsyncContentLoader, ImportErrors, CsvWarnings, UserTile },
  props: {
    file: {
      type: File,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    warnings: {
      type: Array,
      required: true,
    },
    requiresConfirmation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { subdomain } = useCurrentCustomerStore()
    return { subdomain }
  },
  data() {
    return { currentRequest: null, enteredSubdomain: '', errors: [] }
  },
  computed: {
    hasUsers() {
      return this.users.length > 0
    },
    confirmText() {
      return this.requiresConfirmation ? this.$t('common.archive') : this.$t('upload')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    startImport() {
      const formData = { source: this.source, file: this.file },
        success = this.success.bind(this),
        failure = this.failure.bind(this)

      this.currentRequest = UserImportService.startImport(formData).then(success, failure)
    },
    success(creationData) {
      this.$emit('success')
      location.assign(Routes.site_admin_user_imports_path({ new: creationData.id }))
    },
    failure(errorResponse) {
      this.errors = get(errorResponse, 'responseJSON.errors') || [this.$t('upload_error')]
    },
  },
}
</script>
