<script setup>
import StandardsImportService from '../api/standards_import_service'
import FileSelector from './FileSelector'
import ResultLog from './ResultLog'
import { location as $location } from 'utils'
import { ref, computed, watch, onUnmounted } from 'vue'
import { Banner, LzButton, ImageTag, LoadingSpinner, LzModal } from 'vue_features/shared/components/ui'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

const file = ref(null)
const isProcessing = ref(false)
const step = ref(0)
const result = ref(null)

const hasErrors = computed(() => result.value?.error?.length > 0)
const hasChanges = computed(() => result.value?.success?.length > 0)

const isDryRunReady = computed(() => step.value === 0)
const isReady = computed(() => step.value === 1)
const isDone = computed(() => step.value === 2)

const recordId = ref(null)

async function importFile() {
  isProcessing.value = true
  result.value = null
  try {
    const { id } = await StandardsImportService.import({
      file: file.value,
      id: recordId.value,
    })
    recordId.value = id
    pollStatus()
  } catch (e) {
    result.value = e.responseJSON
    if (result.value) {
      step.value++
      isProcessing.value = false
    } else {
      file.value = null
      resetImport()
    }
  }
}

function pollStatus() {
  setTimeout(async () => {
    try {
      const log = await StandardsImportService.getStatus(recordId.value)
      if (log) {
        result.value = log
        step.value++
        isProcessing.value = false
      } else {
        pollStatus()
      }
    } catch (e) {
      result.value = e.xhr.responseJSON
      step.value++
      isProcessing.value = false
    }
  }, 1000)
}

function resetImport() {
  isProcessing.value = false
  step.value = 0
  recordId.value = null
  result.value = null
}

function handleDone(close) {
  close()
  $location.reload()
}

watch(isProcessing, (val) => {
  const { on, off } = useNamespacedEventListener(window)
  if (val) {
    on('beforeunload.standards', (e) => {
      e.preventDefault()
      return (e.returnValue = '')
    })
  } else {
    off('beforeunload.standards')
  }
})
onUnmounted(() => useNamespacedEventListener(window).off('beforeunload.standards'))
</script>

<template>
  <LzModal
    v-slot="{ close }"
    modal-id="StandardsUploadModal"
    :modal-title="$t('import.modal_title')"
    :can-cancel="false"
    class="lzui-modal--large"
    data-test="import-modal"
  >
    <div class="flex flex-col gap-y-6 px-8 py-2">
      <p class="bold text-sm">
        <span v-if="isProcessing || result">
          {{ $t('import.processing_title') }}
          <span class="text-primary italic">{{ file.name }}</span>
        </span>
        <span v-else-if="!isProcessing">
          {{ $t('import.instructions') }}
        </span>
      </p>

      <div v-if="!result">
        <p>{{ $t('import.detailed_instructions') }}</p>

        <h3>{{ $t('import.detailed_instructions_standards_heading') }}</h3>

        <p>
          <ImageTag path="misc/standards_csv_example.png" width="713" :alt="$t('import.csv_screenshot_alt')" />
        </p>

        <a href="/site_admin/standards/standards_template.csv" download>
          {{ $t('import.download_standards_template') }}
        </a>

        <h3>{{ $t('import.detailed_instructions_alignments_heading') }}</h3>

        <p>
          <ImageTag
            path="misc/standards_alignments_csv_example.png"
            width="287"
            :alt="$t('import.csv_screenshot_alt')"
          />
        </p>

        <a href="/site_admin/standards/standard_alignments_template.csv" download>
          {{ $t('import.download_alignments_template') }}
        </a>
      </div>

      <template v-if="result">
        <p v-if="isReady" class="bold text-sm">
          <Banner type="notice">
            <span v-if="hasErrors">
              {{ $t('error_title', { count: result.error.length }) }}
            </span>
            <span v-else-if="!hasChanges">{{ $t('import.no_changes') }}</span>
            <span v-else>{{ $t('import.proceed', { count: result.success.length }) }}</span>
          </Banner>
        </p>

        <ResultLog kind="error" :text="$t('common.error')" :log="result.error" />
        <ResultLog kind="success" :text="$t('common.success')" :log="result.success" />
        <ResultLog kind="info" :text="$t('common.info')" :log="result.info" />
      </template>

      <div v-if="!result" class="border-primary-200 gap-y-2 rounded-xl border">
        <div v-if="isProcessing" class="flex flex-col items-center justify-center p-8">
          <div class="flex items-center">
            <LoadingSpinner :inline="true" />
            <span class="text-primary-accent text-lg">{{ $t('import.processing') }}</span>
          </div>
          <span class="text-primary-accent text-md">{{ $t('import.processing_info') }}</span>
        </div>
        <FileSelector v-if="!isProcessing" v-model="file" data-test="import-file-selector" />
      </div>

      <div class="flex gap-x-2">
        <template v-if="isDone">
          <LzButton
            color="primary"
            :aria-label="$t('common.done')"
            class="ml-auto"
            data-test="import-done-button"
            @click="handleDone(close)"
          >
            {{ $t('common.done') }}
          </LzButton>
        </template>
        <template v-else>
          <LzButton
            v-if="isReady && !isProcessing"
            :aria-label="$t('common.back')"
            :disabled="isProcessing"
            data-test="import-back-button"
            @click="resetImport"
          >
            {{ $t('common.back') }}
          </LzButton>
          <div class="ml-auto flex gap-x-2">
            <LzButton
              :aria-label="$t('common.cancel')"
              :disabled="isProcessing"
              data-test="import-cancel-button"
              @click="close"
            >
              {{ $t('common.cancel') }}
            </LzButton>
            <LzButton
              color="green"
              :aria-label="$t('common.import')"
              :disabled="!file || isProcessing || hasErrors || (isReady && !hasChanges)"
              data-test="import-button"
              @click="importFile"
            >
              {{ isDryRunReady ? $t('import.dry_run_button') : $t('common.import') }}
            </LzButton>
          </div>
        </template>
      </div>
    </div>
  </LzModal>
</template>
