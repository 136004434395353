<template>
  <li class="detail-block group flex flex-col rounded-2xl shadow transition-shadow hover:shadow-xl">
    <a class="grid-rows-block-item grid h-full grid-cols-1 rounded-2xl" :href="href ? href : ''" :aria-label="title">
      <div class="relative col-span-1 rounded-t-2xl print:h-10" style="aspect-ratio: 16/9">
        <div class="flex h-full overflow-hidden rounded-t-2xl">
          <div
            class="h-auto w-full transform overflow-hidden rounded-t-2xl bg-cover bg-center transition-transform duration-700 group-hover:scale-105"
            :style="backgroundStyle"
          >
          </div>
        </div>
        <div class="bg-focus border-3 absolute -bottom-4 left-4 rounded-xl border-white">
          <InlineSvg :path="`icons/${icon}`" class="m-0 h-8 w-8" />
        </div>
      </div>
      <div class="row-span-1 flex flex-1 flex-col flex-wrap gap-2 px-6 pb-4 pt-8">
        <div class="flex w-full flex-1 flex-col space-y-2">
          <MetadataList :metadata="itemMetadataExcludingFromKey" />
          <span class="line-clamp-2 flex text-xl font-bold leading-6">
            <slot name="title">
              <a :href="href" style="overflow-wrap: break-word">
                {{ title }}
              </a>
            </slot>
          </span>
          <div class="text-muted line-clamp-3 text-sm">
            {{ description }}
          </div>
        </div>
        <MetadataByline :logo="fromMetadata.logo" :name="fromMetadata.name" class="mb-0 h-5" />
      </div>
    </a>
  </li>
</template>

<script>
import { computed } from 'vue'
import useMetadata from 'vue_features/shared/composables/use_metadata'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import MetadataByline from './MetadataByline'
import MetadataList from './MetadataList'
import useThumbnailUrl from 'vue_features/shared/composables/use_thumbnail_url'

export default {
  name: 'BlockItem',
  components: { MetadataList, InlineSvg, MetadataByline },
  props: {
    itemId: { type: [String, Number], default: '' },
    title: { type: String, default: '' },
    href: { type: String, default: '' },
    icon: { type: String, default: '' },
    thumbnail: { type: String, default: '' },
    description: { type: String, default: '' },
    metadata: { type: Object, default: () => {} },
  },
  setup(props) {
    const { fromMetadata, itemMetadataExcludingFromKey } = useMetadata(props.metadata)
    const { thumbnailUrl } = useThumbnailUrl(props.thumbnail)
    const backgroundStyle = computed(() => {
      return thumbnailUrl.value
        ? `background-image: url(${thumbnailUrl.value}), linear-gradient(white 75%, rgba(var(--neutral-200), .75))`
        : ''
    })
    return { backgroundStyle, fromMetadata, itemMetadataExcludingFromKey }
  },
}
</script>
