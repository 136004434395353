<!--
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/iframe-has-title  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div>
    <div v-if="isUrlValid && expandedForm" class="hyperlink__preview order-last mb-4">
      <div class="hyperlink__preview-wrapper">
        <div
          v-if="hasEmbedView"
          v-show="isIframeVisible"
          :class="[
            'flex h-full w-full flex-col items-center justify-center text-center',
            { 'absolute left-0': inCardForm },
          ]"
        >
          <h5 class="card-editor--hide mt-2">{{ $t('common.preview') }}</h5>
          <div class="link-iframe--wrapper">
            <LoadingSpinner v-if="isLoadingPreview" :inline="true" />
            <iframe
              v-show="!isLoadingPreview"
              :src="throttledPreviewUrl"
              allowfullscreen
              width="400"
              @load="isLoadingPreview = false"
            />
          </div>
          <a :href="previewUrl" class="smaller block p-2" target="_blank">
            {{ $t('hyperlinks.content.view_original') }}
            <LzIcon path="icons/external" />
          </a>
          <p class="help-block">{{ $t('hyperlinks.form_template.preview_info') }}</p>
        </div>
        <div
          v-if="!isIframeVisible"
          :class="[
            'flex h-full w-full flex-col items-center justify-center text-center',
            { 'absolute left-0': inCardForm },
          ]"
          style="top: -2rem"
        >
          <div class="detail-item__content">
            <div class="detail-item__title">
              <a :href="previewUrl" target="_blank">{{ name }}</a>
            </div>
          </div>
          <p class="detail-item__description">{{ resource.description }}</p>
          <ul class="detail-item__metadata list-metadata">
            <li v-for="(value, key) in metadata" :key="key" class="space-x-1">
              <strong>{{ key }}</strong>
              <span>{{ value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="max-w-7xl">
      <label for="hyperlink-url">{{ $t('hyperlinks.form_template.enter_url_label') }}</label>
      <input
        id="hyperlink-url"
        v-model="url"
        name="hyperlink[url]"
        type="text"
        @input="$emit('update', { content: content })"
      />
      <p v-if="isUrlInvalid" class="error--inline">
        {{ $t('hyperlinks.form_template.no_valid_url') }}
      </p>
    </div>
    <div v-if="expandedForm" class="hyperlink__layout mt-4 max-w-7xl">
      <template v-if="hasEmbedView">
        <label>{{ $t('hyperlinks.form_template.layout_heading') }}</label>
        <div class="toggle-btn">
          <label>
            <input id="link-view" v-model="iframeable" :value="false" type="radio" name="hyperlink[iframeable]" />
            <div class="toggle-btn__option">
              <LzIcon size="lg" path="icons/link" />
              <span>{{ $t('hyperlinks.form_template.link_label') }}</span>
            </div>
          </label>
          <label>
            <input id="embed-view" v-model="iframeable" :value="true" type="radio" name="hyperlink[iframeable]" />
            <div class="toggle-btn__option">
              <LzIcon size="lg" path="icons/embed" />
              <span>{{ $t('hyperlinks.form_template.embed_label') }}</span>
            </div>
          </label>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isEmpty, throttle, capitalize } from 'lodash'
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import {
  extractUrl,
  applyUrlReplacements,
  sanitizePreviewUrl,
  prependProtocol,
  isValidIframeSrc,
} from 'vue_features/resources/hyperlinks/composables/use_url_helpers'
import { ref, computed, watchEffect, inject } from 'vue'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'HyperlinkForm',
  components: { LoadingSpinner, LzIcon },
  props: {
    resource: { type: Object, required: true },
    expandedForm: { type: Boolean, default: false },
    inCardForm: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const url = ref(props.resource.content || '')
    const iframeable = ref(
      props.resource.id === null || props.resource.iframeable === null || !!props.resource.iframeable,
    )
    const isLoadingPreview = ref(true)

    const name = computed(() => props.resource.name || 'Link')
    const previewUrl = computed(() => sanitizePreviewUrl(url.value) || prependProtocol(url.value))
    const isUrlInvalid = computed(() => !isEmpty(url.value) && isEmpty(extractUrl(url.value)))
    const isUrlValid = computed(() => !isEmpty(url.value) && !isEmpty(extractUrl(url.value)))
    const hasEmbedView = computed(() => isValidIframeSrc(previewUrl.value))
    const isIframeVisible = computed(() => iframeable.value && isValidIframeSrc(previewUrl.value))
    const content = computed(() => applyUrlReplacements(url.value))
    const metadata = computed(() => ({
      Type: capitalize(root.$t(getLabel(props.resource.type))),
      URL: previewUrl.value,
    }))

    const throttledPreviewUrl = ref(previewUrl.value)
    const updateThrottledUrl = throttle(
      (newUrl) => {
        throttledPreviewUrl.value = newUrl
      },
      500,
      { trailing: true },
    )

    watchEffect(() => {
      emit('update', { iframeable: iframeable.value })
    })

    watchEffect(() => updateThrottledUrl(previewUrl.value))

    return {
      url,
      iframeable,
      throttledPreviewUrl,
      previewUrl,
      isUrlInvalid,
      isUrlValid,
      hasEmbedView,
      content,
      isIframeVisible,
      name,
      isLoadingPreview,
      metadata,
    }
  },
}
</script>
