import { onMounted } from 'vue'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'

export function useCrumbableLinks(el) {
  const { urlWithCrumbs } = usePathCrumbsStore()
  onMounted(() => {
    if (el.value) {
      el.value.querySelectorAll('a[href]').forEach((link) => {
        const href = urlWithCrumbs(link.href)
        if (href !== link.href) {
          link.href = href
          // mark to make obvious the href was changed
          link.dataset.crumbable = true
        }
      })
    }
  })
}
