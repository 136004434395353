export default {
  data() {
    return {
      error: '',
    }
  },
  computed: {
    hasNameBlankError() {
      return this.error === 'blank'
    },
    hasNameTooLongError() {
      return this.error === 'maxLength'
    },
  },
  methods: {
    checkForErrors(name) {
      this.error = ''
      name = name.trim()
      if (name.length < 1) {
        this.error = 'blank'
      } else if (name.length > 512) {
        this.error = 'maxLength'
      }

      return this.error
    },
  },
}
