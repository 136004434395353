import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { lzCodes, lessonPlans, helpers, common } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(helpers, 'helpers', { only: 'authentication' }),
  namespaceLocaleObj(lzCodes, '', {
    only: ['show'],
  }),
  namespaceLocaleObj(common, 'common', { only: ['teacher', 'student'] }),
  namespaceLocaleObj(lessonPlans, '', {
    only: ['lesson_plan', 'show'],
  }),
)
