<template>
  <div class="search-box">
    <label for="search-teachers" class="my-6 font-normal text-gray-500">{{
      $t('klasses.show.all_teachers_can_manage')
    }}</label>
    <div class="no-fullstory mb-6">
      <input
        id="search-teachers"
        v-model="queryString"
        :placeholder="$t('klasses.show.teacher_name')"
        class="form-control search-query"
        type="search"
        @keyup="updateOnQuery"
      />
    </div>
    <button class="search-submit flex items-center justify-center" style="top: 73px">
      <LzIcon icon-color-class="text-white" path="icons/search" size="sm" />
    </button>
    <div v-if="noMatches || isSearching || searchedTeachers.length > 0" class="search-box__results mb-4 mt-8">
      <div v-if="isSearching" class="search-roster">
        <LoadingSpinner />
      </div>
      <div v-if="noMatches && !isSearching" class="lzui-tile bg-orange-50 border-orange-200 center">
        <strong>{{ $t('klasses.show.no_matching_teachers') }}</strong>
      </div>
      <div v-if="!noMatches && !isSearching" class="small-modal_table">
        <TeacherSearchResults v-bind="{ searchedTeachers, searchTotal }" :key="queryString" @add-teacher="addTeacher" />
      </div>
    </div>

    <div v-if="newTeachers.length > 0" class="lzui-tile">
      <div class="small-modal_table">
        <table class="table-opened condensed-list mb-0">
          <tr v-for="teacher in newTeachers" :key="teacher.id">
            <td>
              {{ teacher.name }}
            </td>
            <td>
              {{ teacher.email }}
            </td>
            <td>
              <button class="btn btn--small btn--gray float-right" @click="removeTeacher(teacher)">
                {{ $t('common.remove') }}
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <footer>
      <button class="btn" @click="$emit('cancel')">{{ $t('common.cancel') }}</button>
      <button :disabled="newTeachers.length === 0" class="btn btn--primary ml-2" @click="save">
        {{ $t('klasses.show.add_teachers') }}
      </button>
    </footer>
  </div>
</template>

<script>
import TeachersSearchService from '../api/teachers_search_service'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import TeacherSearchResults from './TeacherSearchResults'
import { debounce } from 'lodash'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useKlassShowStore } from '../store'

export default {
  name: 'TeacherSearch',
  components: { TeacherSearchResults, LoadingSpinner, LzIcon },
  props: {
    klassId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { addTeachersToClass } = useKlassShowStore()
    return { addTeachersToClass }
  },
  data() {
    return {
      queryString: '',
      isSearching: false,
      newTeachers: [],
      noMatches: false,
      searchedTeachers: [],
      searchTotal: 0,
    }
  },
  computed: {
    excludedIds() {
      return this.newTeachers.map((teacher) => teacher.id)
    },
  },
  created() {
    this.updateOnQuery = debounce(this.updateOnQuery, 300)
  },
  methods: {
    addTeacher(teacher) {
      this.newTeachers.push({
        name: teacher.name,
        email: teacher.email,
        firstName: teacher.firstName,
        middleName: teacher.middleName,
        lastName: teacher.lastName,
        id: teacher.id,
      })

      this.resetSearch()
    },
    removeTeacher(teacher) {
      const index = this.newTeachers.indexOf(teacher)
      this.newTeachers.splice(index, 1)
      this.resetSearch()
    },
    resetSearch() {
      this.queryString = ''
      this.searchedTeachers = []
      this.noMatches = false
    },
    updateOnQuery() {
      this.noMatches = false
      this.searchedTeachers = []
      if (this.queryString) {
        this.isSearching = true
        this.search().then((searchData) => {
          this.searchedTeachers = searchData.members
          this.searchTotal = searchData.total
          this.noMatches = this.searchTotal === 0
          this.isSearching = false
        })
      }
    },
    handleLoadMore(done) {
      this.search().then((searchData) => {
        this.searchedTeachers.push(...searchData.members)
        this.searchTotal = searchData.total
        done()
      })
    },
    search() {
      return TeachersSearchService.teacherSearch(
        this.klassId,
        this.queryString,
        this.excludedIds,
        this.searchedTeachers.length,
      )
    },
    save() {
      const members = this.newTeachers.reverse()
      this.addTeachersToClass({ teacherIds: members.map((m) => m.id) }).then((newTeachers) => {
        this.$emit('added-teachers', newTeachers)
      })
    },
  },
}
</script>
