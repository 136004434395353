import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const BookmarkFoldersService = {
  create(title) {
    const url = Routes.bookmark_folders_path()

    return HTTP.create(url, snakeKeys({ bookmarkFolder: { title } }))
  },

  update(folderId, { title, description }) {
    const url = Routes.bookmark_folder_path(folderId)

    return HTTP.put(url, snakeKeys({ bookmarkFolder: { title, description } }))
  },

  destroy(folder) {
    const url = Routes.bookmark_folder_path(folder.id)

    return HTTP.delete(url)
  },

  get(id, page) {
    const url = Routes.bookmark_folder_path(id)

    return HTTP.getJSON(url, { page })
  },

  getFolders(page) {
    const url = Routes.bookmark_folders_path()

    return HTTP.getJSON(url, { page })
  },
}

export default BookmarkFoldersService
