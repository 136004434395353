export default {
  materialId: {
    type: String,
    required: true,
  },
  showToolbar: {
    type: Boolean,
    default: false,
  },
  autoscale: {
    type: Boolean,
    default: false,
  },
}
