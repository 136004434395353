<!-- eslint-disable vuejs-accessibility/alt-text  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzContentList v-if="isWikiResource" :lz-contents="[lzContent]" />
  <div v-else>
    <div v-if="showPremiumGate || showTeacherOnlyGate" class="resource-embedded">
      <ContentUnavailable />
    </div>
    <div v-else v-mathjax :data-title="resource.name" :data-type="resource.type" class="resource-embedded">
      <ImageTag v-if="decoratedResource.useEmbedPlaceholder" :path="`icons/${decoratedResource.placeholder}`" />
      <ResourceFromType v-if="resource.type" :type="resource.type" :serialized-resource="serializedResource" />
    </div>
  </div>
</template>

<script>
import LzContentList from 'vue_features/lz_content/components/LzContentList'
import ContentUnavailable from 'vue_features/shared/components/views/ContentUnavailable'
import ImageTag from 'vue_features/shared/components/ui/ImageTag'
import ResourceFromType from 'vue_features/resources/shared/components/ResourceFromType'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { isWiki } from 'vue_features/shared/composables/use_resource_types'

export default {
  components: {
    LzContentList,
    ContentUnavailable,
    ImageTag,
    ResourceFromType,
  },
  props: {
    lzContent: {
      type: Object,
      default: null,
    },
    resource: {
      type: Object,
      required: true,
    },
    decoratedResource: {
      type: Object,
      default: null,
    },
    showPremiumGate: {
      type: Boolean,
      required: true,
    },
    serializedResource: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { authorizedAsTeacher } = useCurrentUserStore()
    return { authorizedAsTeacher }
  },
  computed: {
    isWikiResource() {
      return isWiki(this.resource)
    },
    showTeacherOnlyGate() {
      return (this.serializedResource || {}).teacherOnly && !this.authorizedAsTeacher
    },
  },
}
</script>
