import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const GoogleClassroomService = {
  getProfile() {
    return HTTP.getJSON(Routes.google_classroom_profile_path())
  },
  getCourses(pageToken, fetchParams) {
    return HTTP.fetch(Routes.google_classroom_courses_path(), snakeKeys({ query: { pageToken }, ...fetchParams }))
  },
  getCourseAssignments(lzCodeId) {
    return HTTP.getJSON(Routes.lz_code_google_classroom_course_assignments_path(lzCodeId))
  },
  getStudents({ courseId, pageToken }, fetchParams) {
    return HTTP.fetch(
      Routes.google_classroom_course_students_path(courseId),
      snakeKeys({ query: { pageToken }, ...fetchParams }),
    )
  },
  getLzStudents(page, fetchParams) {
    return HTTP.fetch(Routes.teacher_students_path(), { query: { page }, ...fetchParams })
  },
  associateGoogleStudent({ studentId, googleClassroomStudentId }) {
    return HTTP.put(Routes.link_to_google_classroom_teacher_student_path(studentId), {
      google_classroom_student_id: googleClassroomStudentId,
    })
  },
  disassociateGoogleStudent({ studentId }) {
    return HTTP.put(Routes.link_to_google_classroom_teacher_student_path(studentId), {
      google_classroom_student_id: null,
    })
  },
  unlinkProfile() {
    return HTTP.delete(Routes.teacher_google_classroom_token_path())
  },
  syncData() {
    return HTTP.create(Routes.google_classroom_data_sync_requests_path())
  },
}

export default GoogleClassroomService
