<template>
  <LzConfirmModal :modal-title="$t('klasses.confirm_remove_teacher_title')" :modal-id="modalId">
    <template #confirmBody>
      <p class="text-gray-500">{{ $t('klasses.confirm_remove_teacher') }}</p>
    </template>
    <template #cancelButton="{ close }">
      <button class="btn" data-test="cancel-button" @click.stop="close">{{ $t('common.cancel') }}</button>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution" data-test="confirm-leave-button" @click="confirm">{{
        $t('klasses.leave_class')
      }}</button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { useKlassShowStore } from 'vue_features/klasses/show/store'
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'RemoveTeacherModal',
  components: { LzConfirmModal },
  props: {
    modalId: {
      type: String,
      default: function () {
        return 'RemoveTeacherModal'
      },
    },
  },
  setup(props, { emit }) {
    function confirm() {
      const { removeMeFromClass } = useKlassShowStore()
      removeMeFromClass()
        .then(() => {
          emit('remove-me-from-klass')
        })
        .catch((response) => {
          if (response.status === 409) {
            response.responseJSON.length === 1 ? emit('alert-last-teacher') : emit('alert-unable-to-remove')
          }
        })
        .finally(() => {
          closeModal(props.modalId)
        })
    }
    return { confirm }
  },
}
</script>
