<template>
  <tr class="student-data-report-row">
    <td class="middle">
      {{ timeAgo(reportData.createdAt) }}
      <div v-if="reportData.nextScheduledReport" class="smallest text-gray">
        {{ $t('index.repeats') }} {{ timeAgo(reportData.nextScheduledReport) }}
      </div>
      <div v-else-if="reportData.generatedByScheduler" class="smallest text-gray">
        {{ $t('index.generated_by_scheduler') }}
      </div>
    </td>
    <td class="middle">
      {{ reportData.source }}
    </td>
    <td class="middle">
      <div v-if="isGenerating" class="small">
        <LoadingSpinner :inline="false" tag="span" size="small" class="inline-block align-top" />
        <div class="inline-block">
          <p class="bold smaller my-0">
            {{ $t('index.status_generating') }}
          </p>
          <p class="smaller mb-0">
            {{ $t('index.status_notify_email') }}
          </p>
          <p class="bold smaller my-0">
            <a href="#" @click.prevent="cancel">{{ $t('index.cancel') }}</a>
          </p>
        </div>
      </div>
      <a v-else :href="Routes.student_data_report_path(reportData.id)" class="flex items-center">
        <LzIcon path="icons/download" class="mr-1" />
        {{ $t('index.status_download') }}
      </a>
    </td>
  </tr>
</template>

<script>
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import { useStudentDataReportsStore } from '../composables/use_student_data_reports_store'
import { computed, inject } from 'vue'
import Routes from 'routes'

export default {
  components: { LoadingSpinner, LzIcon },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { generatingReports, deleteStudentDataReport } = useStudentDataReportsStore()
    const isGenerating = computed(() => props.reportData.status === 'generating')
    function cancel() {
      if (window.confirm(root.$t('index.confirm_delete'))) {
        deleteStudentDataReport(props.reportData.id).catch(({ responseJSON }) => {
          alert(responseJSON.error)
        })
      }
    }
    return {
      Routes,
      generatingReports,
      isGenerating,
      cancel,
      timeAgo,
    }
  },
}
</script>
