<script setup>
import { ref, computed, watch, onMounted } from 'vue'

const self = ref(null)
const columnCount = ref(0)
const supportsSubgrid = ref(false)

const subgridStyle = computed(() => {
  return {
    gridColumn: `1 / ${columnCount.value + 1}`,
    gridTemplateColumns: 'subgrid',
  }
})

watch(
  self,
  (currentSelf) => {
    if (currentSelf) {
      columnCount.value = self.value.children.length
    } else {
      columnCount.value = 0
    }
  },
  { immediate: true },
)

onMounted(() => {
  supportsSubgrid.value = CSS.supports('grid-template-columns: subgrid')
})
</script>

<template>
  <div v-if="supportsSubgrid" ref="self" class="data-table-frozen-columns" :style="subgridStyle">
    <slot />
  </div>

  <div v-else class="contents">
    <slot />
  </div>
</template>
