import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

export default {
  get({ status, page }) {
    const url = Routes.site_admin_collections_path()

    return HTTP.getJSON(url, { status, page })
  },

  create(wikiId) {
    const url = Routes.site_admin_collection_path(wikiId)

    return HTTP.put(url)
  },

  destroy(collection) {
    const url = Routes.site_admin_collection_path(collection.id)

    return HTTP.delete(url)
  },
}
