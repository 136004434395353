<script setup>
import { ref, computed, watch } from 'vue'
import BookmarkList from './BookmarkList'
import DraggableBookmarkList from './DraggableBookmarkList'
import FolderSidebar from './FolderSidebar'
import { EmptyStateWithAnimation, LoadingSpinner } from 'vue_features/shared/components/ui'
import { useBookmarksStore } from '../composables/use_bookmarks_store'
import Routes from 'routes'
import LzButton from 'vue_features/shared/components/ui/LzButton'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import DeleteFolderModal from './DeleteFolderModal'

const {
  bookmarksPagination,
  isLoadingBookmarks,
  folderTitle,
  folderDescription,
  activeFolderId,
  updateFolder,
  inFolder,
} = useBookmarksStore()

const hasBookmarks = computed(() => {
  return bookmarksPagination.value.totalCount > 0
})
const landingPagePath = computed(() => {
  return Routes.search_path()
})

const isEditingFolder = ref(false)
const newFolderTitle = ref('')
const newFolderDescription = ref('')
const isSavingFolderChanges = ref(false)

const editFolder = () => {
  newFolderTitle.value = folderTitle.value || ''
  newFolderDescription.value = folderDescription.value || ''
  isEditingFolder.value = true
}

const saveFolderChanges = async () => {
  isSavingFolderChanges.value = true
  await updateFolder({ title: newFolderTitle.value.trim(), description: newFolderDescription.value.trim() })
  isEditingFolder.value = false
  isSavingFolderChanges.value = false
}

watch(activeFolderId, () => (isEditingFolder.value = false))
const emptyStateHeading = computed(() => {
  switch (activeFolderId.value) {
    case 'personal_copies':
      return 'no_personal_copies'
    case 'authored_lps':
      return 'no_authored_lps'
    default:
      return 'nothing_saved_header'
  }
})
const emptyStateBody = computed(() => {
  switch (activeFolderId.value) {
    case 'personal_copies':
      return 'explore_personal_copies_prompt'
    case 'authored_lps':
      return 'create_lesson_prompt'
    default:
      return 'explore_lesson_plans_prompt'
  }
})
</script>

<template>
  <div id="bookmark-index" class="max-w-screen-full mx-auto flex flex-col md:min-h-screen md:flex-row">
    <DeleteFolderModal v-if="canModalShow('DeleteFolderModal')" />
    <FolderSidebar class="z-10 flex-shrink-0 px-4 pb-6 pt-4" />
    <div class="bg-focus flex min-h-screen min-w-0 flex-grow flex-col items-center gap-10 px-3 py-6 md:px-6">
      <div v-if="!isLoadingBookmarks" class="w-full max-w-7xl">
        <LoadingSpinner v-if="isSavingFolderChanges" size="large" />
        <template v-else-if="inFolder && isEditingFolder">
          <div class="flex w-full flex-wrap justify-between gap-4 sm:flex-nowrap">
            <div class="flex flex-grow flex-col gap-2">
              <div class="flex-1">
                <label for="folder-title">{{ $t('title_label') }}</label>
                <input id="folder-title" v-model="newFolderTitle" type="text" />
              </div>
              <div class="flex-1">
                <label for="folder-description">{{ $t('description_label') }}</label>
                <textarea id="folder-description" v-model="newFolderDescription" />
              </div>
            </div>
            <div class="flex flex-col space-y-2">
              <LzButton @click="isEditingFolder = false">{{ $t('common.cancel') }}</LzButton>
              <LzButton
                :disabled="newFolderTitle.trim().length <= 0"
                data-test="save-folder-btn"
                color="green"
                @click="saveFolderChanges"
                >{{ $t('common.save') }}</LzButton
              >
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex w-full flex-wrap justify-between gap-4 sm:flex-nowrap">
            <div>
              <h2 class="m-0 font-bold">{{ folderTitle }}</h2>
              <p v-if="folderDescription" class="text-muted max-w-4xl">{{ folderDescription }}</p>
            </div>
            <div v-if="activeFolderId === 'authored_lps'">
              <a :href="Routes.new_lesson_plan_path()" class="btn btn--primary">
                {{ $t('create_lesson') }}
              </a>
            </div>
            <div v-if="!isEditingFolder && inFolder" class="flex flex-col space-y-2">
              <LzButton data-test="edit-folder-btn" @click="editFolder">{{ $t('common.edit') }}</LzButton>
              <LzButton caution data-test="delete-folder-btn" @click="openModal('DeleteFolderModal')">{{
                $t('common.delete')
              }}</LzButton>
            </div>
          </div>
        </template>
      </div>
      <EmptyStateWithAnimation
        v-if="!hasBookmarks && !isLoadingBookmarks"
        :animation-loader="() => import('lottie-animations/nothing-saved.json')"
        autoplay
        loop
        :heading="$t(emptyStateHeading)"
        :body="$t(emptyStateBody)"
      >
        <a v-if="activeFolderId === 'authored_lps'" :href="Routes.new_lesson_plan_path()" class="btn btn--primary">
          {{ $t('create_lesson') }}
        </a>
        <a v-else :href="landingPagePath" class="btn btn--primary">
          {{ $t('find_lessons') }}
        </a>
      </EmptyStateWithAnimation>
      <div v-else class="flex w-full max-w-7xl">
        <LoadingSpinner v-if="isLoadingBookmarks" size="large" />
        <DraggableBookmarkList v-else-if="inFolder" />
        <BookmarkList v-else class="w-full" />
      </div>
    </div>
  </div>
</template>
