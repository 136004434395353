<template>
  <div class="page-min-height">
    <AddStudentToKlassModal
      v-if="canModalShow($options.AddStudentToKlassModal)"
      :klass-id="klass.id"
      @closing-add-student-to-klass-modal="handleNewStudent"
    />
    <AddTeacherModal
      v-if="canModalShow($options.AddTeacherModal)"
      :klass-id="klass.id"
      @added-teachers="handleNewTeachers"
    />
    <RemoveTeacherModal
      v-if="canModalShow($options.RemoveTeacherModal)"
      :teacher="teacherToRemove"
      @remove-me-from-klass="handleRemoveTeacherComplete"
      @alert-last-teacher="handleLastTeacherWarning"
      @alert-unable-to-remove="showUnableToRemoveWarning = true"
    />
    <div class="tabs-content">
      <div class="content mx-auto max-w-7xl space-y-10 px-3 md:px-6">
        <div>
          <Alert
            v-if="showLastTeacherWarning"
            :title="$t('klasses.show.last_teacher_warning_title')"
            :dismissible="true"
            :description="$t('klasses.show.last_teacher_warning_description')"
            type="error"
          />
          <Alert
            v-else-if="showUnableToRemoveWarning"
            :title="$t('klasses.show.unable_to_remove_teacher_warning_title')"
            :dismissible="true"
            :description="$t('klasses.show.unable_to_remove_teacher_warning_description')"
            type="error"
          />
          <div class="flex items-start justify-between">
            <div class="flex items-center space-x-2">
              <p class="m-0 text-xl font-bold">
                {{ $t('common.teachers') }}
                <span data-test="teacher-count">({{ teachers.length }})</span>
              </p>
              <NameSortButton
                :id="`teacher-sort-by-${klass.id}`"
                class="p-0"
                cookie-name="teacher_sort_by"
                @sort-changed="reload"
              />
              <p :class="[klass.isFromClever ? 'smaller mb-0 block text-gray-500' : 'hidden']">
                {{ $t('klasses.show.sis_notice', { type: 'teacher' }) }}
              </p>
            </div>
            <button class="btn btn--small btn--primary m-0" @click="openAddTeacherModal">
              {{ $t('klasses.show.add_teacher') }}
            </button>
          </div>
          <div class="mt-4">
            <PeopleTeacherList :teachers="teachers" @remove-me-from-klass="handleRemoveTeacher" />
          </div>
        </div>
        <div class="space-y-6">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2 text-xl font-bold">
              <p class="text-xl font-bold">
                {{ $t('common.students') }}
              </p>
              <template v-if="!studentsEmpty">
                <span data-test="student-count">({{ studentTotal }})</span>
                <NameSortButton
                  :id="`student-sort-by-${klass.id}`"
                  class="p-0"
                  cookie-name="student_sort_by"
                  @sort-changed="reload"
                />
                <span v-if="klass && showGoogleUnlinkedWarning" class="google-warning small ml-6 inline-block">
                  <LzIcon path="icons/exclamation-triangle" />
                  {{
                    $t('common.google_classroom.unlinked_count', {
                      unlinked_count: klass.googleClassroomUnlinkedCount,
                    })
                  }}
                </span>
              </template>
              <p v-if="!studentsEmpty" :class="[klass.isFromClever ? 'smaller mb-0 block text-gray-500' : 'hidden']">
                {{ $t('klasses.show.sis_notice', { type: 'students' }) }}
              </p>
            </div>
            <div class="flex items-center space-x-2">
              <button v-if="!hasClever && !studentsEmpty" class="btn btn--small" @click="goToInstructions">
                <LzIcon path="icons/print" type="div" />
                {{ $t('klasses.show.print_instructions') }}
              </button>
              <button v-if="!klass.isFromClever" class="btn btn--small btn--primary" @click="openAddStudentModal">
                {{ $t('klasses.show.add_student') }}
              </button>
            </div>
          </div>
          <div class="mt-4">
            <PeopleStudentList :students="studentsData.students" :student-total-count="studentTotal" :klass="klass" />
          </div>

          <EmptyStateWithAnimation
            v-if="studentsEmpty"
            :animation-loader="() => import('lottie-animations/loading2.json')"
            autoplay
            loop
            :heading="$t('klasses.show.add_student_to_start')"
            :body="null"
            data-test="empty-state-no-students"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NameSortButton from 'vue_features/shared/components/NameSortButton'
import { Alert, EmptyStateWithAnimation, LzIcon } from 'vue_features/shared/components/ui'
import { location as $location } from 'utils'
import AddTeacherModal from 'vue_features/klasses/shared/components/modals/AddTeacherModal'
import AddStudentToKlassModal from './modals/AddStudentToKlassModal'
import RemoveTeacherModal from 'vue_features/klasses/shared/components/modals/RemoveTeacherModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import PeopleTeacherList from './PeopleTeacherList'
import PeopleStudentList from './PeopleStudentList'
import { useKlassShowStore } from '../store'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import Routes from 'routes'

export default {
  name: 'PeopleTab',
  AddStudentToKlassModal,
  AddTeacherModal,
  RemoveTeacherModal,
  components: {
    Alert,
    LzIcon,
    EmptyStateWithAnimation,
    NameSortButton,
    PeopleTeacherList,
    PeopleStudentList,
    RemoveTeacherModal,
    AddTeacherModal,
    AddStudentToKlassModal,
  },
  setup() {
    const { hasClever } = useCurrentCustomerStore()
    const { klass, teachers, addedTeacherToKlass, addedToStudentList, reloadTeachers, studentsData } =
      useKlassShowStore()
    const { isConnectedToGoogle } = useGoogleClassroomStore()
    return {
      klass,
      teachers,
      addedTeacherToKlass,
      addedToStudentList,
      reloadTeachers,
      studentsData,
      canModalShow,
      openModal,
      hasClever,
      isConnectedToGoogle,
    }
  },
  data() {
    return {
      showLastTeacherWarning: false,
      showUnableToRemoveWarning: false,
    }
  },
  computed: {
    showGoogleUnlinkedWarning() {
      return (
        this.klass &&
        this.isConnectedToGoogle &&
        this.klass.googleClassroomCourse &&
        this.klass.googleClassroomUnlinkedCount
      )
    },
    studentsEmpty() {
      return this.studentTotal === 0
    },
    studentTotal() {
      return this.studentsData.meta.pagination.totalCount
    },
  },
  methods: {
    openAddStudentModal() {
      this.openModal(this.$options.AddStudentToKlassModal)
    },
    openAddTeacherModal() {
      this.openModal(this.$options.AddTeacherModal)
    },
    goToInstructions() {
      window.open($location.current() + '/rosterview')
    },
    handleRemoveTeacher(teacher) {
      this.teacherToRemove = teacher
      this.openModal(this.$options.RemoveTeacherModal)
    },
    handleRemoveTeacherComplete() {
      $location.assign(Routes.teacher_klasses_path())
    },
    handleLastTeacherWarning() {
      this.showLastTeacherWarning = true
      this.reloadTeachers({ klass: this.klass })
    },
    handleNewStudent() {
      if (this.addedToStudentList) {
        this.reload()
      }
    },
    handleNewTeachers() {
      if (this.addedTeacherToKlass) {
        this.reload()
      }
    },
    reload: $location.reload,
  },
}
</script>
