<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <LoadingSpinner v-if="loadingKlass" />
      <div v-else class="mx-auto max-w-7xl p-4 pb-0">
        <h2 class="mb-2 mt-0">{{ $t('klasses.google_classroom.update_link', { course_name: courseName }) }}</h2>
        <i18n-t tag="p" keypath="klasses.google_classroom.update_link_info.currently_linked" scope="global">
          <template #course>
            <strong>{{ courseName }}</strong>
          </template>
          <template #klass>
            <strong>{{ klass.name }}</strong>
          </template>
        </i18n-t>
        <p>{{ $t('klasses.google_classroom.update_link_info.associating_will_remove') }}</p>
        <p class="mt-4">{{ $t('klasses.google_classroom.update_link_info.are_you_sure') }}</p>
      </div>
    </template>
    <template #cancelButton="{ close }">
      <button class="btn mb-4" @click.stop="close">Cancel</button>
    </template>
    <template #confirmButton>
      <button :disabled="loadingKlass" class="btn" @click.stop="updateAndClose">Confirm</button>
    </template>
  </LzConfirmModal>
</template>

<script>
import KlassesIndexService from '../../index/api'
import { LoadingSpinner, LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'GoogleCourseLinkConfirmModal',
  components: { LoadingSpinner, LzConfirmModal },
  props: {
    courseId: {
      type: String,
      required: true,
    },
    courseName: {
      type: String,
      required: true,
    },
    linkedKlassId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return { closeModal }
  },
  data() {
    return {
      loadingKlass: true,
      klass: null,
    }
  },
  async mounted() {
    try {
      this.klass = await KlassesIndexService.getKlass({ klassId: this.linkedKlassId })
    } finally {
      this.loadingKlass = false
    }
  },
  methods: {
    updateAndClose() {
      this.$emit('confirm-link-update', this.courseId)
      this.closeModal(this.$options.name)
    },
  },
}
</script>
