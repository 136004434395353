import debug from 'debug'
import * as Sentry from '@sentry/vue'
import { location } from 'utils'
import { pick } from 'lodash'

const log = debug('app:clients/sentry')

let isMonitoring = false

/* eslint-enable no-invalid-this */

const ignoredErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',

  // unhelpful or triggered constantly by third party integrations resulting in too much noise
  'Permission denied',
  'Error loading script',
  'Script error.',
  'UnknownError',
  'Unspecified error.',
  'No error message',
  'Access is denied.',
  'this.questionsApiInstance.safeToUnload is not a function',
  "Cannot read properties of undefined (reading 'safeToUnload')",

  // non-breaking error on ios devices with iframe processing
  'Blocked a frame with origin "https://ilclassroom.com"',
  'target.postMessage is not a function.',
  "Unable to get property '1' of undefined or null reference",

  // triggered by Wistia
  "Cannot read property 'turnOn' of undefined",
  "Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.",

  // triggered by BrainingCamp JS included by Learnosity
  'ResizeObserver loop limit exceeded',

  /^error$/,
  /^timeout$/,
]

export function monitor(env, domain, release) {
  if ((env === 'production' || env === 'staging') && isOnline()) {
    Sentry.init({
      dsn: 'https://2a85cb1ddff84e4b89c832df03a700cc@app.getsentry.com/75546',
      tags: { environment: env, domain: domain },
      environment: env,
      ignoreErrors: ignoredErrors,
      release: release,
      sampleRate: 0.2,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          // FIXME: currently blowing up error quota, but disabling means we miss out on potentially useful info
          onunhandledrejection: false,
        }),
      ],
    })

    Sentry.setContext(
      'user',
      pick(window.LZ.analyticsProps, ['current_user_id', 'current_customer', 'impersonating_user', 'impersonator_id']),
    )

    isMonitoring = true
  }
}

export function captureException(...args) {
  if (isMonitoring) {
    Sentry.captureException(...args)
    log('Sent to sentry:', ...args)
  } else {
    log('Not sent to sentry:', ...args)
  }
}

export function captureMessage(...args) {
  if (isMonitoring) {
    Sentry.captureMessage(...args)
    log('Sent to sentry:', ...args)
  } else {
    log('Not sent to sentry:', ...args)
  }
}

function isOnline() {
  return location.hostname !== ''
}

export function monitorAppErrors(app) {
  if (isMonitoring) {
    app.mixin(Sentry.createTracingMixins({ trackComponents: true }))
    Sentry.attachErrorHandler(app, { attachProps: true, logErrors: true })
  }
  return app
}
