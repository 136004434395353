import { DATA } from '../../../spec/javascript/support/rails_constants_fixture.js'

const RAILS_CONSTANTS = [process.env.RAILS_ENV, process.env.NODE_ENV].includes('test')
  ? DATA
  : JSON.parse(process.env.RAILS_CONSTANTS)

export const {
  config: CONFIG,
  availableQuestionBanks: AVAILABLE_QUESTION_BANKS,
  deprecatedResourceTypes: DEPRECATED_RESOURCE_TYPES,
  helpLmsIntegrationUrl: HELP_LMS_INTEGRATION_URL,
  helpResizeFilesUrl: HELP_RESIZE_FILES_URL,
  helpCleverLoginUrl: HELP_CLEVER_LOGIN_URL,
  helpTeacherAccountsUrl: HELP_TEACHER_ACCOUNTS_URL,
  helpCanvasLtiUrl: HELP_CANVAS_LTI_URL,
  helpCanvasLti13Url: HELP_CANVAS_LTI_13_URL,
  helpItemScoringUrl: HELP_ITEM_SCORING_URL,
  helpDataDashboardGuideUrl: HELP_DATA_DASHBOARD_GUIDE_URL,
  helpItemAnalysisGuideUrl: HELP_ITEM_ANALYSIS_GUIDE_URL,
  helpAssignmentSchedulingGuideUrl: HELP_ASSIGNMENT_SCHEDULING_GUIDE_URL,
  lzMailAddress: LZ_MAIL_ADDRESS,
  defaultCustomerOwnerEmail: DEFAULT_CUSTOMER_OWNER_EMAIL,
} = RAILS_CONSTANTS
