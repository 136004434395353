<template>
  <div class="items bg-neutral-100 flex flex-none space-x-4 p-2 pr-4">
    <div class="flex-grow">
      <a
        :href="
          Routes.lz_code_path(assignment.code, {
            anchor: currentSession ? `assignment-report-${currentSession.klassId}` : '',
          })
        "
        class="text-important hover:text-important-hover hover:bg-base inline-flex h-11 items-center rounded-lg pl-1 pr-3 text-sm font-bold"
      >
        <LzIcon path="icons/chevron-left" size="md" />
        <span class="flex-shrink-0">{{ $t('student_responses') }}</span>
      </a>
    </div>

    <div class="flex space-x-2">
      <FilterMenu :value="studentFilterDisplay" data-test="review-session-filter">
        <div class="h-full space-y-4 overflow-y-auto">
          <div v-for="klass of klasses" :key="klass.id">
            <p class="bg-base sticky top-0 px-3 py-2 text-base text-sm font-bold">{{ klass.name }}</p>

            <label
              v-for="(session, index) of sessionsForKlass(klass)"
              :key="`${klass.id}-${index}`"
              class="mb-0 flex cursor-pointer items-center space-x-2 px-3 py-2"
              :data-test="`session-option-${session.id}`"
              :for="`session-option-${session.id}`"
            >
              <input
                :id="`session-option-${session.id}`"
                type="checkbox"
                class="m-0"
                :checked="reviewingSessionIds.includes(session.id)"
                :disabled="reviewingSessionIds.length === 1 && reviewingSessionIds.includes(session.id)"
                @change="toggleSession(session)"
              />
              <span class="fs-mask font-normal text-gray-900">{{ session.studentName }}</span>
            </label>
          </div>
        </div>
      </FilterMenu>
    </div>

    <div class="flex-col md:flex">
      <span class="text-sm">{{ $t('hide') }}</span>
      <div class="flex space-x-3">
        <div class="flex-none">
          <label
            class="m-0 flex cursor-pointer items-center space-x-2 text-base font-semibold"
            data-test="hide-graded-toggle"
            for="hide-graded-toggle"
          >
            <input
              id="hide-graded-toggle"
              type="checkbox"
              class="m-0"
              :checked="hideGraded"
              :disabled="!canHideGraded"
              @change="onToggleHideGraded"
            />
            <span>{{ $t('graded') }}</span>
          </label>
        </div>

        <div class="flex-none">
          <label
            class="m-0 flex cursor-pointer items-center space-x-2 text-base font-semibold"
            data-test="hide-names-toggle"
            for="hide-names-toggle"
          >
            <input
              id="hide-names-toggle"
              type="checkbox"
              class="m-0"
              :checked="hideNames"
              @change="onToggleHideNames"
            />
            <span>{{ $t('names') }}</span>
          </label>
        </div>

        <div class="flex-none">
          <label
            class="m-0 flex cursor-pointer items-center space-x-2 text-base font-semibold"
            data-test="hide-open-assignments-toggle"
            for="hide-open-assignments-toggle"
          >
            <input
              id="hide-open-assignments-toggle"
              type="checkbox"
              class="m-0"
              :checked="hideOpenAssignments"
              @change="onToggleHideOpenAssignments"
            />
            <span>{{ $t('open_assignments') }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useGradingStore } from '../composables/use_grading_store'
import { LzIcon } from 'vue_features/shared/components/ui'
import FilterMenu from './FilterMenu'
import Routes from 'routes'

const root = inject('useRoot')()
const {
  currentSession,
  klasses,
  assignment,
  unfilteredSessions,
  reviewingSessionIds,
  hideGraded,
  hideNames,
  hideOpenAssignments,
} = useGradingStore()

const unfilteredSessionsByKlass = computed(() => {
  return unfilteredSessions.value.reduce((all, session) => {
    all[session.klassId] = all[session.klassId] || []
    all[session.klassId].push(session)
    return all
  }, {})
})

const sessionsForKlass = (klass) => {
  return unfilteredSessionsByKlass.value[klass.id]
}

const toggleSession = (session) => {
  if (reviewingSessionIds.value.includes(session.id)) {
    reviewingSessionIds.value = reviewingSessionIds.value.filter((id) => id !== session.id)
  } else {
    reviewingSessionIds.value = [...reviewingSessionIds.value, session.id]
  }
}

const studentFilterDisplay = computed(() => {
  const length = reviewingSessionIds.value.length
  return root.$t('reviewing_students', { count: length, total: unfilteredSessions.value.length })
})

const canHideGraded = computed(() => {
  return unfilteredSessions.value.some((session) => session.unmarked)
})

const onToggleHideGraded = () => {
  hideGraded.value = !hideGraded.value
}

const onToggleHideNames = () => {
  hideNames.value = !hideNames.value
}

const onToggleHideOpenAssignments = () => {
  hideOpenAssignments.value = !hideOpenAssignments.value
}
</script>
