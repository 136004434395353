<template>
  <LzModal
    :modal-id="$options.name"
    :modal-title="$t('action_menu.customize.how_to')"
    class="text-left"
    :autoclose="false"
    @close-modal="cancel"
    @bg-click="cancel"
  >
    <section>
      <h5 class="mb-3">{{ $t('action_menu.customize.select_type') }}</h5>

      <div class="relative mb-2 flex h-full w-full">
        <input
          id="is-personal-radio"
          v-model="isPersonal"
          :value="true"
          class="absolute left-0 top-0 hidden"
          name="personal-type"
          type="radio"
        />
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': isPersonal }"
          class="align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="is-personal-radio"
        >
          <span class="h-10 w-10">
            <InlineSvg path="icons/user-solo" class="fill-current" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('action_menu.customize.personal_label') }}
            </h5>
            <p class="max-w-md text-sm">
              {{ $t('action_menu.customize.personal_explanation') }}
            </p>
          </span>
        </label>
      </div>

      <div class="relative flex h-full w-full">
        <input
          id="site-wide-radio"
          v-model="isPersonal"
          :value="false"
          class="absolute left-0 top-0 hidden"
          name="personal-type"
          type="radio"
        />
        <label
          :class="{ 'border-green-300 bg-green-50 text-green-700 shadow-lg': !isPersonal }"
          class="align-left border-base flex h-full w-full cursor-pointer flex-row space-x-4 rounded border border-solid bg-white p-4 shadow-sm"
          for="site-wide-radio"
        >
          <span class="h-10 w-10">
            <InlineSvg path="icons/user-group-fill" class="fill-current" />
          </span>
          <span>
            <h5 class="m-0">
              {{ $t('action_menu.customize.sitewide_label', { customer_name: customerName }) }}
            </h5>
            <p class="max-w-md text-sm">
              {{ $t('action_menu.customize.sitewide_explanation', { customer_name: customerName }) }}
            </p>
          </span>
        </label>
      </div>
    </section>
    <footer>
      <button class="btn" @click.stop="cancel">
        {{ $t('common.cancel') }}
      </button>
      <a
        data-test="get-started-link"
        class="btn btn--primary"
        :href="Routes.lesson_plan_card_edits_path(lessonPlanId, customizeParams)"
      >
        {{ $t('action_menu.customize.get_started') }}
      </a>
    </footer>
  </LzModal>
</template>

<script>
import LzModal from 'vue_features/shared/components/ui/LzModal'
import { ref, computed } from 'vue'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

export default {
  name: 'CustomizationChoiceModal',
  components: { InlineSvg, LzModal },
  props: {
    lessonPlanId: { type: Number, required: true },
  },
  setup() {
    const { name: customerName } = useCurrentCustomerStore()
    const isPersonal = ref(true)
    const customizeParams = computed(() => ({ personalize: isPersonal.value }))
    const cancel = () => {
      isPersonal.value = false
      closeModal('CustomizationChoiceModal')
    }
    return { Routes, isPersonal, customizeParams, cancel, customerName }
  },
}
</script>
