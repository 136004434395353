import { ref } from 'vue'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

export default function useCardDeckFullscreen(fullscreenContainerEl, cardDeckEl, firstCardWidth) {
  const cardDeckStyle = ref(null)

  function handleFullscreenChange(isFullscreen) {
    const fullscreenProportion = () => {
      const bodyWidth = fullscreenContainerEl.value.offsetWidth,
        bodyHeight = fullscreenContainerEl.value.offsetHeight

      const elWidth = cardDeckEl.value.offsetWidth,
        elHeight =
          cardDeckEl.value.offsetHeight +
          parseInt(window.getComputedStyle(cardDeckEl.value).getPropertyValue('margin-top'))

      const scale = Math.min(bodyWidth / elWidth, bodyHeight / elHeight)
      cardDeckStyle.value = { width: firstCardWidth.value + 'px', transform: 'scale(' + scale + ')' }
    }

    const { on: windowOn, off: windowOff } = useNamespacedEventListener(window)
    if (isFullscreen) {
      fullscreenProportion()
      windowOn('resize.fullscreenCards, orientationchange.fullscreenCards', fullscreenProportion)
    } else {
      cardDeckStyle.value = {}
      windowOff('resize.fullscreenCards, orientationchange.fullscreenCards')
    }
  }

  return { handleFullscreenChange, cardDeckStyle }
}
