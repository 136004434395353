<template>
  <div
    :class="[
      'transition-margin shadow-lg-100 fixed right-0 top-0 z-10 flex h-full w-80 duration-300 md:relative',
      isOpen ? 'mr-0' : '-mr-80',
    ]"
  >
    <div
      :class="[
        'transition-margin absolute right-0 top-0 h-full w-full overflow-y-auto pb-12 text-center shadow-lg duration-300',
        { '-mr-full': !isOpen },
        {
          'bg-white': backgroundColor === 'white',
          'bg-neutral-100': backgroundColor === 'gray',
          'bg-primary-800': backgroundColor === 'primary',
        },
      ]"
    >
      <slot name="title">
        <h5
          v-if="title"
          :class="[
            'sticky m-0 p-6 text-xl',
            {
              'text-primary-700': titleColor === 'primary',
              'text-green': titleColor === 'green',
              'text-gray-600': titleColor === 'gray',
              'text-white': titleColor === 'white',
            },
          ]"
        >
          {{ title }}
        </h5>
      </slot>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightDrawer',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'green', 'gray', 'white'].includes(value)
      },
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: function (value) {
        return ['white', 'gray', 'primary'].includes(value)
      },
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
