import { h, provide, getCurrentInstance } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from './i18n/standards_selector_messages'
import StandardsSelectorModal from './components/StandardsSelectorModal'
import { openModal, canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import emitter from 'tiny-emitter/instance'
import { createVueApp } from 'initializers/create_vue_features'

export default function () {
  const i18n = createI18n({ locale: useGlobalStore().state.locale, messages })

  const app = createVueApp({
    StandardsSelectorModal,
    setup() {
      const root = getCurrentInstance().proxy.$root
      root.$rootEmit = (...args) => emitter.emit(...args)
      provide('useRoot', () => root)
      return { openModal, canModalShow, closeModal, on: emitter.on }
    },
    data() {
      return {
        standards: [],
        editor: null,
      }
    },
    render() {
      if (this.canModalShow(this.$options.StandardsSelectorModal)) {
        return h(StandardsSelectorModal, this.$data)
      }
      return null
    },
  })
  app.use(i18n)
  return app
}
