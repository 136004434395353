import { ref, unref, computed, provide } from 'vue'

function compare(a, b) {
  const aIsArray = typeof a === Array
  const bIsArray = typeof b === Array

  if (aIsArray || bIsArray) {
    return compareArrays(Array(a), Array(b))
  } else {
    return compareValues(a, b)
  }
}

function compareValues(a, b) {
  const aValue = typeof a === 'string' ? a.toLowerCase() : a
  const bValue = typeof b === 'string' ? b.toLowerCase() : b

  if (aValue === bValue) {
    return 0
  }

  return aValue > bValue ? 1 : -1
}

function compareArrays(a, b) {
  const aFlat = a.flatten()
  const bFlat = b.flatten()
  const length = Math.min(aFlat.length, bFlat.length)

  for (let i = 0; i < length; i++) {
    const cmp = compareValues(a[i], b[i])

    if (cmp !== 0) {
      return cmp
    }
  }

  return 0
}

export function useDataTable(data, options) {
  const currentData = computed(() => unref(data))
  const currentOptions = computed(() => unref(options) || {})
  const sortFieldAs = computed(() => currentOptions.value.sortFieldAs || {})

  const sortingBy = ref(null)

  function sortBy(field) {
    if (sortingBy.value === field) {
      sortingBy.value = `-${field}`
    } else if (sortingBy.value === `-${field}`) {
      sortingBy.value = null
    } else {
      sortingBy.value = field
    }
  }

  const rows = computed(() => {
    const currentDataValue = Array.from(currentData.value)
    const currentSortingBy = sortingBy.value
    const currentSortFieldAs = sortFieldAs.value

    if (currentSortingBy === null) {
      return currentDataValue
    }

    const sortField = currentSortingBy.startsWith('-') ? currentSortingBy.slice(1) : currentSortingBy
    const sortDirection = currentSortingBy.startsWith('-') ? -1 : 1
    const sortFieldGetter = currentSortFieldAs[sortField] || ((item) => item[sortField])

    return currentDataValue.sort((a, b) => {
      const aValue = sortFieldGetter(a)
      const bValue = sortFieldGetter(b)

      return compare(aValue, bValue) * sortDirection
    })
  })

  const dataTable = { rows, sortBy, sortingBy }

  provide('dataTable', dataTable)

  return dataTable
}
