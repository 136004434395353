<template>
  <div v-if="isCreating" class="slide--container__table">
    <table ref="dragTable" class="drag__table">
      <tbody>
        <tr v-for="row in builderSize.rowCount" :key="`${row}-row`">
          <td
            v-for="column in builderSize.columnCount"
            :key="`${column}-column`"
            :class="{ selected: column <= size.columns && row <= size.rows }"
          />
        </tr>
      </tbody>
    </table>
    <button :disabled="!canCreate" class="btn mt-2" @click.prevent="createTable">{{ $t('common.create') }}</button>
  </div>
  <table v-else v-mathjax>
    <tbody>
      <tr v-for="(row, rowIndex) in editedText" :key="rowIndex" class="editor-row">
        <!-- the .th class is used so that vue does not have to re-render the element -->
        <!-- in view mode, the table will appear correctly -->
        <td
          v-for="(column, columnIndex) in row"
          :key="column.id"
          :data-scopes="column.headerScopes.join('|')"
          class="editor-cell"
          :class="{ th: isHeader(column) }"
        >
          <CkeditorOnDemand
            :content="column.text"
            :options="ckeditorOptions"
            type="div"
            class="slide__text"
            contenteditable="true"
            @change="updateText(column, $event)"
            @lztable-make-row-header="makeRowHeader(rowIndex)"
            @lztable-remove-row-header="removeRowHeader(rowIndex)"
            @lztable-add-row-above="addRowAbove(rowIndex)"
            @lztable-add-row-below="addRowBelow(rowIndex)"
            @lztable-delete-row="deleteRow(rowIndex)"
            @lztable-make-column-header="makeColumnHeader(columnIndex)"
            @lztable-remove-column-header="removeColumnHeader(columnIndex)"
            @lztable-add-column-right="addColumnRight(columnIndex)"
            @lztable-add-column-left="addColumnLeft(columnIndex)"
            @lztable-delete-column="deleteColumn(columnIndex)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { watch, ref, computed, reactive, onMounted, onBeforeUnmount, watchEffect } from 'vue'
import { CKEDITOR_OPTIONS } from './ckeditor_options'
import CkeditorOnDemand from './CkeditorOnDemand'
import useDragTable from '../../composables/use_drag_table'
import useLzTablePlugin from '../../composables/use_lztable_plugin'
import { times } from 'lodash'

const DEFAULT_ROWS = 5
const DEFAULT_COLUMNS = 5

export default {
  name: 'SlideEditorTable',
  components: { CkeditorOnDemand },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ckeditorOptions = { ...CKEDITOR_OPTIONS }
    ckeditorOptions.options = { ...ckeditorOptions.options }
    ckeditorOptions.options.extraPlugins = 'mathjax,justify,sharedspace,font,lztable,olstart'

    const size = reactive({ rows: 0, columns: 0, maxRows: props.content.rowSpan, maxCols: props.content.colSpan })
    const isCreating = computed(() => !props.content.text)
    const builderStartSize = Math.min(props.content.rowSpan, DEFAULT_ROWS, props.content.colSpan, DEFAULT_COLUMNS)
    const builderSize = reactive({
      rowCount: builderStartSize,
      columnCount: builderStartSize,
    })
    const canCreate = computed(() => {
      return size.rows > 0 && size.columns > 0
    })

    const dragTable = ref(null)
    if (isCreating.value) {
      const { onMouseDown, removeEventListeners } = useDragTable(dragTable, size, builderSize, {
        isDragging: false,
        cornerTd: null,
      })

      onMounted(() => {
        dragTable.value.addEventListener('mousedown', onMouseDown)
      })

      watch(isCreating, (newIsCreating) => {
        if (!newIsCreating) {
          removeEventListeners()
        }
      })

      onBeforeUnmount(removeEventListeners)
    }

    const editedText = ref(props.content.text)

    if (editedText.value) {
      let id = 0
      editedText.value.forEach((row) => row.forEach((cell) => (cell.id = id++)))
    }

    const createTable = () => {
      const text = []
      let id = 0
      times(size.rows, (row) => {
        text[row] = []
        times(size.columns, (column) => {
          text[row][column] = { text: '<p></p>', headerScopes: [], id: id++ }
        })
      })

      editedText.value = text
      emit('update', { isCreating: false })
    }

    emit('update', { isCreating: isCreating.value })

    const {
      makeRowHeader,
      removeRowHeader,
      addRowAbove,
      addRowBelow,
      deleteRow,
      makeColumnHeader,
      removeColumnHeader,
      addColumnRight,
      addColumnLeft,
      deleteColumn,
    } = useLzTablePlugin(editedText)

    watchEffect(() => {
      emit('update', { text: editedText.value })
    })

    return {
      makeRowHeader,
      removeRowHeader,
      addRowAbove,
      addRowBelow,
      deleteRow,
      makeColumnHeader,
      removeColumnHeader,
      addColumnRight,
      addColumnLeft,
      deleteColumn,
      editedText,
      createTable,
      dragTable,
      size,
      isCreating,
      canCreate,
      builderSize,
      ckeditorOptions,
    }
  },
  methods: {
    isHeader(column) {
      return column.headerScopes.length > 0
    },
    updateText(column, newText) {
      column.text = newText
    },
  },
}
</script>

<style scoped>
.editor-row {
  @apply p-0 !important;
  height: 1px;
}
.editor-cell {
  @apply p-0 !important;
  height: inherit;
  vertical-align: top;
}
</style>
