<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div class="flex">
    <UserDeletionConfirmModal
      v-if="canModalShow(userDeletionConfirmModalId)"
      :modal-id="userDeletionConfirmModalId"
      :user="user"
    />
    <UserStateConfirmModal
      v-if="canModalShow(userStateConfirmModalId)"
      :user="user"
      @close="closeModal(userStateConfirmModalId)"
    />
    <div class="w-5/6">
      <div class="no-fullstory">
        {{ user.name }}
      </div>
      <ul class="list-metadata">
        <li v-if="user.preventUserChanges">
          {{ $t('user_is_locked') }}
        </li>
        <li v-if="user.username">
          <label>
            <strong>
              {{ $t('common.label.username') }}
            </strong>
          </label>
          {{ user.username }}
        </li>
        <li>
          <label>
            <strong>
              {{ $t('label.role') }}
            </strong>
          </label>
          {{ $t(`common.${user.role}`) }}
        </li>
        <li v-if="user.email">
          <label>
            <strong>
              {{ $t('common.label.email') }}
            </strong>
          </label>
          {{ user.email }}
        </li>
        <li v-if="authorizedAsCustomerAdmin && user.isFromClever">
          <label v-if="hasClever">
            <strong>
              {{ $t('label.clever_id') }}
            </strong>
          </label>
          <label v-if="hasSingleIntegration">
            <strong>
              {{ $t('label.sourced_id') }}
            </strong>
          </label>
          {{ user.cleverId }}
        </li>
        <li>
          <label>
            <strong>
              {{ $t('label.last_visited') }}
            </strong>
          </label>
          {{ user.lastVisited }}
        </li>
        <li>
          <label>
            <strong>
              {{ $t('label.date_created') }}
            </strong>
          </label>
          {{ user.createdAt }}
        </li>
        <li v-if="user.archived">
          <label>
            <strong>
              {{ $t('label.date_archived') }}
            </strong>
          </label>
          {{ user.updatedAt }}
        </li>
      </ul>
    </div>
    <div v-if="isActionable">
      <ListDropdown :id="`actions-${user.id}`" :button-classes="['btn--small']">
        <template #button>
          <span class="flex items-center gap-1">
            {{ $t('common.actions') }}
            <LzIcon path="icons/caret" size="sm" />
          </span>
        </template>
        <template #listItems>
          <li v-if="active">
            <a @click="$emit('edit')">
              {{ $t('common.edit') }}
            </a>
          </li>
          <li v-if="user.id !== id && !isDefaultCustomerOwner">
            <a @click="openModal(userDeletionConfirmModalId)">
              {{ $t('common.delete') }}
            </a>
          </li>
          <li v-if="active && !impersonated">
            <RailsForm
              :action="user.startImpersonationPath"
              :title="`${$t('impersonate')} ${user.name}`"
              method="post"
              class="inline"
            >
              <input
                :value="$t('impersonate')"
                type="submit"
                class="w-full cursor-pointer bg-transparent px-1.5 py-2.5 text-left text-base"
              />
            </RailsForm>
          </li>
          <li v-if="!isDefaultCustomerOwner">
            <a @click="openModal(userStateConfirmModalId)">
              {{ active ? $t('common.archive') : $t('common.unarchive') }}
            </a>
          </li>
        </template>
      </ListDropdown>
    </div>
  </div>
</template>

<script>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LzIcon } from 'vue_features/shared/components/ui'
import UserDeletionConfirmModal from './UserDeletionConfirmModal'
import UserStateConfirmModal from './UserStateConfirmModal'
import { openModal, canModalShow, closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { RailsForm } from 'vue_features/shared/components/forms'
import { DEFAULT_CUSTOMER_OWNER_EMAIL } from 'rails_constants'
import { computed } from 'vue'

export default {
  name: 'UserInfo',
  components: { RailsForm, UserDeletionConfirmModal, UserStateConfirmModal, ListDropdown, LzIcon },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { id, authorizedAsCustomerAdmin, impersonated } = useCurrentUserStore()
    const { hasClever, hasSingleIntegration, isPublic } = useCurrentCustomerStore()
    const isDefaultCustomerOwner = computed(() => props.user.email === DEFAULT_CUSTOMER_OWNER_EMAIL)

    return {
      id,
      authorizedAsCustomerAdmin,
      impersonated,
      isPublic,
      isDefaultCustomerOwner,
      openModal,
      canModalShow,
      closeModal,
      hasClever,
      hasSingleIntegration,
    }
  },
  data() {
    return { transaction: null }
  },
  computed: {
    isActionable() {
      return !this.user.isPendingDeletion
    },
    userDeletionConfirmModalId() {
      return UserDeletionConfirmModal.name + this.user.id
    },
    userStateConfirmModalId() {
      return UserStateConfirmModal.name + this.user.id
    },
    active() {
      return !this.user.archived
    },
  },
}
</script>
