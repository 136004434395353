import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const TeachersSearchService = {
  teacherSearch(klassId, query, excludedIds, offset) {
    const params = { klassId, query, excludedIds, offset }

    return HTTP.getJSON(Routes.teacher_teachers_search_path(), snakeKeys(params))
  },
}

export default TeachersSearchService
