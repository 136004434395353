<template>
  <LzModal
    :modal-title="canLogin ? $t('premium_prompt.title') : $t('premium_prompt.title_subscription')"
    :can-cancel="false"
    modal-id="premium-prompt"
    class="lzui-modal--confirmation lzui-modal--medium"
  >
    <template v-if="isPublicCustomer">
      <p class="mx-auto my-3">{{ $t('premium_prompt.requires_login') }}</p>
      <InlineSvg class="mx-auto my-6 h-24" path="misc/unicorn_login.svg" alt="Happy unicorn" />
      <div class="row mb-6 flex justify-center">
        <button class="btn text-accent mr-3" @click="goBack">{{ $t('premium_prompt.back') }}</button>
        <a class="btn btn--primary" :href="`${Routes.customer_selects_path()}${returnToUrl}`">{{
          $t('premium_prompt.login')
        }}</a>
      </div>
      <!-- vvv Uncomment and add href once Parent help guide is done -->
      <!--      <p class="center small mt-6">-->
      <!--        <InlineSvg class="mx-auto my-3 inline h-6" path="icons/info.svg" alt="Info" />-->
      <!--        {{ $t('premium_prompt.check_out_guide') }}-->
      <!--        <a href="">{{ $t('premium_prompt.learn_more') }}</a>-->
      <!--      </p>-->
    </template>
    <PremiumPromptGuide v-if="showUnsharedPremiumGate" />
    <LoginInModal v-if="canLogin" />
  </LzModal>
</template>

<script>
import { InlineSvg, LzModal } from 'vue_features/shared/components/ui'
import { computed } from 'vue'
import LoginInModal from './LoginInModal'
import PremiumPromptGuide from './PremiumPromptGuide'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { shared } from 'vue_features/shared/i18n'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'

const messages = pickLocaleNamespace(shared, 'premium_prompt')

export default {
  name: 'PremiumPrompt',
  components: { LoginInModal, PremiumPromptGuide, LzModal, InlineSvg },
  i18n: { messages },
  props: { showUnsharedPremiumGate: { type: Boolean, required: true } },
  setup(props) {
    const { isPublicCustomer } = useCurrentCustomerStore()
    const canLogin = computed(() => !isPublicCustomer.value && !props.showUnsharedPremiumGate)
    const returnToUrl = '?one_time_return_to=' + window.location.pathname
    const goBack = () => {
      window.history.back()
    }
    return { Routes, isPublicCustomer, canLogin, returnToUrl, goBack }
  },
}
</script>
