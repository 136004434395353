<template>
  <Grid
    disable-default="md:px"
    :class="['z-20 mb-0 pb-1 md:pb-4', isContentEmbedded ? 'embed-lp-deck__head' : 'lp-deck__head']"
  >
    <div class="col-span-9 lg:col-span-7">
      <div class="text-muted small" data-test="progress">
        {{
          $t('assignment_slideshow_header.progress', {
            current_card: currentPosition + 1,
            total_cards: totalCount,
          })
        }}
      </div>
      <div class="h3 bold" data-test="title">
        {{ title }}
        <LzContentStateBadge v-if="cardArchived" class="ml-2 text-xs text-white" state="archived" />
      </div>
    </div>
    <div class="col-span-3 lg:col-span-5">
      <div class="text-right">
        <a
          v-if="isFullscreenAllowed && !isContentEmbedded"
          :aria-label="$t('feature_gate.action.fullscreen')"
          class="lp-deck__full-screen btn btn--small mt-4 hidden md:inline-flex"
          href="#"
          data-test="fullscreen-button"
          @click.prevent="handleToggleFullscreen"
        >
          <LzIcon v-if="isFullscreen" type="div" path="icons/fullscreen-contract" data-test="is-fullscreen" />
          <LzIcon v-else type="div" path="icons/fullscreen-expand" data-test="not-fullscreen" />
        </a>
        <template v-if="totalCount > 1">
          <a
            :aria-label="$t('lesson_plan.previous')"
            :class="['btn btn--small slideshow__icon--prev ml-2', { disabled: !isPreviousEnabled }]"
            href="#"
            data-test="go-previous"
            @click.prevent="isPreviousEnabled && $emit('go-previous')"
          >
            <LzIcon type="div" path="icons/previous" />
          </a>
          <a
            :aria-label="$t('lesson_plan.next')"
            :class="['btn btn--small slideshow__icon--next', { disabled: !isNextEnabled }]"
            href="#"
            data-test="go-next"
            @click.prevent="isNextEnabled && $emit('go-next')"
          >
            <LzIcon type="div" path="icons/next" />
          </a>
        </template>
      </div>
    </div>
  </Grid>
</template>

<script>
import { inject, computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'

export default {
  name: 'CardDeckHeader',
  components: { Grid, LzIcon, LzContentStateBadge },
  props: {
    title: {
      type: String,
      required: true,
    },
    currentPosition: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    isFullscreenAllowed: {
      type: Boolean,
      required: true,
    },
    isEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    cardArchived: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deckId = inject('deckId')
    const { isFullscreen, toggleFullscreen } = useFullscreenStore()

    const isPreviousEnabled = computed(() => props.isEnabled && props.currentPosition > 0)
    const isNextEnabled = computed(() => props.isEnabled && props.currentPosition < props.totalCount - 1)

    function handleToggleFullscreen() {
      toggleFullscreen(deckId, emit)
    }

    const { isContentEmbedded } = useGlobalStore()
    return {
      isFullscreen,
      isPreviousEnabled,
      isNextEnabled,
      handleToggleFullscreen,
      isContentEmbedded,
    }
  },
}
</script>
