<template>
  <div>
    <div class="login-content align-left pb-4">
      <h2 class="my-2 text-center">
        {{ $t('helpers.authentication.welcome') }}
      </h2>
      <p class="smaller text-center" data-test="login-org">
        {{ $t('helpers.authentication.log_in_with_organization', { organization: loginOrganization }) }}
      </p>
    </div>
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <template v-if="hasStudentFunctionality">
        <div class="border-base mb-4 border-b py-0" data-test="tabs">
          <LzTabs>
            <TabLink target="student_content" :label="$t('common.student')" initial />
            <TabLink target="teacher_content" :label="$t('common.teacher')" />
          </LzTabs>
        </div>
        <div class="block w-full text-left">
          <div id="student_content">
            <StudentLogin :in-assignment-prompt="true" :has-greeting="false" />
          </div>
          <div id="teacher_content">
            <TeacherLogin :in-assignment-prompt="true" :has-greeting="false" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="border-base mb-4 border-b py-0" data-test="teacher-only">
          <div class="block w-full text-left">
            <div id="teacher" class="active">
              <TeacherLogin :in-assignment-prompt="true" :has-greeting="false" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { StudentLogin, TeacherLogin } from 'vue_features/shared/components/login'
import { LzTabs, TabLink } from 'vue_features/shared/components/ui'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default {
  name: 'AssignmentLoginForm',
  components: { LzTabs, TabLink, StudentLogin, TeacherLogin },
  setup() {
    const { hasStudentFunctionality, name: customerName } = useCurrentCustomerStore()
    return { hasStudentFunctionality, customerName }
  },
  computed: {
    loginOrganization() {
      return this.$t('helpers.authentication.lz_customer', { customer_name: this.customerName })
    },
  },
}
</script>
