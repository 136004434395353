import $script from 'scriptjs'

// Creates Geogebra applet via window.GGBApplet and
// injects the applet to the given instanceId
export function createApplet(params) {
  const { materialId, instanceId, showToolBar, width, height, scaleContainerClass, disableAutoScale } = params

  const options = {
    material_id: (materialId || '').split('/').pop(),
    id: instanceId,
    showToolBar,
    disableAutoScale,
    scaleContainerClass,
    allowUpscale: true,
    showMenuBar: false,
    showResetIcon: true,
    enable3d: true,
    enableFileFeatures: false,
    enableShiftDragZoom: true,
    enableRightClick: false,
    showToolBarHelp: false,
    allowStyleBar: true,
    algebraInputPosition: 'top',
    borderColor: 'FFFFFF',
    showLogging: false,
  }

  if (disableAutoScale) {
    Object.assign(options, {
      width,
      height,
      scaleContainerClass: '',
    })
  }

  // Load external script for window.GGBApplet
  $script('https://cdn.geogebra.org/apps/deployggb.js', 'deployggb')
  return new Promise((resolve) => {
    const appletOnLoad = (appletObject, ...args) => resolve(new GeogebraApplet(appletObject, ...args))
    $script.ready('deployggb', () => {
      const applet = new window.GGBApplet(Object.assign({}, options, { appletOnLoad }), '5.0')
      applet.inject(instanceId)
    })
  })
}

// Wrapper object for Geogebra appletObject instance
// A future improvement may be to track changes using the Geogebra
// applet events API.
// For methods on appletObject, see:
// https://wiki.geogebra.org/en/Reference:GeoGebra_Apps_API
class GeogebraApplet {
  constructor(appletObject) {
    this.appletObject = appletObject
  }

  setSize({ width, height }) {
    return this.appletObject.setSize(width, height)
  }

  getThumbnailBase64({ exportScale = 1, transparent = false } = {}) {
    return this.appletObject.getPNGBase64(exportScale, transparent)
  }
}
