<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div>
    <CreateCollectionModal v-if="canModalShow($options.CreateCollectionModal)" />
    <Grid disable-default="md:px">
      <div class="col-span-6">
        <h2 class="h6 mb-4">{{ collectionTotal }} {{ $t('collections') }}</h2>
      </div>
      <div class="col-span-6">
        <a class="btn btn--primary float-right mb-2 mt-4" @click="openModal($options.CreateCollectionModal)">
          <span class="icon">
            <InlineSvg path="icons/add" />
          </span>
          {{ $t('create_collection') }}
        </a>
      </div>
    </Grid>
  </div>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import { InlineSvg } from 'vue_features/shared/components/ui'
import CreateCollectionModal from './modals/CreateCollectionModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import Grid from 'vue_features/shared/components/ui/Grid'

export default {
  name: 'PageHead',
  CreateCollectionModal,
  components: { Grid, CreateCollectionModal, InlineSvg },
  setup() {
    const { collectionsData } = useCollectionsIndexStore()
    return { openModal, canModalShow, collectionsData }
  },
  computed: {
    collectionTotal() {
      return this.collectionsData.meta.pagination.totalCount
    },
  },
}
</script>
