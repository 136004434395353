<template>
  <div class="page-content">
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'UsersIndex',
}
</script>
