import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import { siteAdmin, common, shared, components, activerecordErrors, search } from 'vue_features/shared/i18n'

export default merge(
  {},
  elevateLocaleNamespace(siteAdmin, 'users.index'),
  elevateLocaleNamespace(siteAdmin, 'customers'),
  elevateLocaleNamespace(siteAdmin, 'localization'),
  elevateLocaleNamespace(siteAdmin, 'user_delete_modal'),
  elevateLocaleNamespace(siteAdmin, 'user_import_modals'),
  elevateLocaleNamespace(siteAdmin, 'user_import_errors'),
  elevateLocaleNamespace(siteAdmin, 'users_deletion'),
  elevateLocaleNamespace(siteAdmin, 'user_add_modal'),
  elevateLocaleNamespace(activerecordErrors, 'models.user_import'),
  namespaceLocaleObj(common, 'common'),
  elevateLocaleNamespace(shared, 'frontend_error_messages'),
  components,
  pickLocaleNamespace(search, 'search'),
)
