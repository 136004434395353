// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.
/* eslint-disable */
import { createConsumer } from '@rails/actioncable'
import { webSocketConfig } from 'utils/config'
import location from 'utils/location'

const { subdomain: currentSubdomain } = location

function pathWithSubdomain(path) {
  const subdomain = currentSubdomain()
  return subdomain ? `${path}?subdomain=${subdomain}` : path
}

export default createConsumer(pathWithSubdomain(webSocketConfig.path))
