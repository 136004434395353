import { useSlots } from 'vue'
import { isArray } from 'lodash'

// see discourse on slot detection: https://github.com/vuejs/rfcs/discussions/453
export const isSlotUsed = (slotName) => {
  const slots = useSlots()
  return !!slots[slotName] && slots[slotName]({}).some((vnode) => !isVNodeEmpty(vnode))
}

function isVNodeEmpty(vnode) {
  const isEmpty = (node) => {
    return (
      node.type === Comment ||
      (node.type.toString() === 'Symbol(v-fgt)' && node.el === null && !node.children?.length) ||
      (node.type === Text && !node.children?.length)
    )
  }
  return !vnode || isArray(vnode) ? vnode.every(isEmpty) : isEmpty(vnode)
}
