<template>
  <div
    data-test="lesson-toolbar"
    class="lesson-toolbar 3/2xl:max-w-6xl mx-auto flex w-full max-w-5xl flex-shrink-0 items-stretch justify-around rounded-2xl transition-all 2xl:max-w-7xl"
    :class="isFullscreen ? 'z-50' : 'z-20'"
  >
    <button
      title="Show or hide the lesson toolbar"
      class="lesson-toolbar-toggle bg-primary-700 absolute -top-5 left-0 right-0 z-50 m-auto flex h-5 w-20 items-center justify-center rounded-t-lg bg-opacity-40 transition-all hover:bg-opacity-95"
      @click="$emit('toggle-toolbar')"
    >
      <InlineSvg
        aria-label="Show or hide the lesson toolbar"
        class="h-6 w-6 text-white"
        :class="showToolbar ? 'transform-none' : 'rotate-180 transform'"
        size="m"
        path="icons/chevron-down"
      />
    </button>
    <LessonToolbarItem
      v-if="showSidebarButton"
      class="hidden rounded-l-2xl md:flex"
      data-test="toolbar-toggle-sidebar"
      :active="showSidebar"
      icon-path="icons/lp-toolbar-sidebar"
      :label="$t('toolbar.sidebar')"
      @click="$emit('toggle-sidebar')"
    />
    <LessonToolbarItem
      :has-submenu="true"
      data-test="toolbar-toggle-menu"
      class="hidden sm:flex md:hidden"
      :class="showSidebarButton ? 'rounded-l-2xl md:rounded-none' : 'rounded-l-2xl'"
      icon-path="icons/lp-toolbar-sidebar"
      label="Menu"
    >
      <template #menu>
        <div class="hidden justify-between p-4 sm:flex">
          <button
            :title="$t('toolbar.sidebar')"
            data-test="toolbar-toggle-sidebar-small"
            class="flex items-center justify-start whitespace-nowrap text-white"
            @click="$emit('toggle-sidebar')"
          >
            <InlineSvg class="h-8 w-8 text-white" size="m" path="icons/lp-toolbar-sidebar" />
            <span class="ml-2 text-white">{{ $t('toolbar.sidebar') }}</span>
          </button>
        </div>
        <div class="flex justify-between">
          <button
            v-if="showNotesButton"
            data-test="toolbar-toggle-notes-small"
            :title="$t('toolbar.notes')"
            class="flex items-center justify-start whitespace-nowrap text-white"
            @click="$emit('toggle-notes')"
          >
            <InlineSvg class="h-8 w-8 text-white" size="m" path="icons/lp-toolbar-notes" />
            <span class="ml-2 text-white">{{ $t('toolbar.notes') }}</span>
          </button>
        </div>
      </template>
    </LessonToolbarItem>
    <LessonToolbarItem
      v-if="showAnnotationsButton"
      id="annotations-button"
      :active="showAnnotationTools"
      class="hidden md:flex md:rounded-none"
      :class="{ 'rounded-none md:rounded-l-2xl': !showSidebarButton }"
      icon-path="icons/edit"
      :label="$t('toolbar.annotate')"
      @click="toggleAnnotationTools"
    />

    <template
      v-if="showToolsButton && showToolsCoachmark && isStudent && canDismissibleShow($options.CM_TOOLBAR_TOOLS)"
    >
      <Coachmark
        :target="`#tools-${uniqueIdentifier}`"
        position="top-start"
        class="relative"
        popover-classes="tools-coachmark-container hidden sm:block"
        @close="closeDismissible($options.CM_TOOLBAR_TOOLS)"
      >
        <h4 class="my-0 text-base leading-8">{{ $t('student_tools.cm_heading') }}</h4>
        <p class="text-muted mb-10 mt-2">{{ $t('student_tools.cm_body') }}</p>
      </Coachmark>
    </template>
    <LessonToolbarItem
      v-if="showToolsButton"
      :id="`tools-${uniqueIdentifier}`"
      data-test="toolbar-toggle-tools-menu"
      class="hidden sm:flex"
      :class="{ active: openTool !== '', 'rounded-none md:rounded-l-2xl': !showSidebarButton && !showAnnotationTools }"
      :has-submenu="true"
      icon-path="icons/lp-toolbar-tools"
      :label="$t('toolbar.tools')"
    >
      <template #menu>
        <div
          v-for="tool in studentTools"
          :key="tool.name"
          class="flex justify-between whitespace-nowrap px-2 py-3"
          :class="{ 'bg-white bg-opacity-30': tool.name == openTool.name }"
        >
          <button
            :title="$t(`student_tools.label.${tool.name}`)"
            type="button"
            data-test="activate-tools-button"
            class="flex cursor-pointer items-center gap-x-2 text-white"
            @click="toggleTool(tool)"
          >
            <LzIcon :path="`icons/${tool.name.replace('_', '-')}-small`" />
            {{ $t(`student_tools.label.${tool.name}`) }}
          </button>
          <a
            v-if="tool.helpUrl"
            :title="`${$t(`student_tools.label.${tool.name}`)} ${$t('common.help')}`"
            :href="tool.helpUrl"
            class="ml-2 cursor-pointer text-white"
            target="_blank"
          >
            <span class="sr-only">{{ $t(`student_tools.label.${tool.name}`) }} {{ $t('common.help') }}}</span>
            <LzIcon path="icons/help" />
          </a>
        </div>
      </template>
    </LessonToolbarItem>
    <LessonToolbarPositionIndicator
      :cards="cards"
      :selected-card-index="selectedCardIndex"
      @card-selected="$emit('card-selected', $event)"
      @go-previous="$emit('go-previous')"
      @go-next="$emit('go-next')"
    />
    <LessonToolbarItem
      v-if="showNotesButton"
      data-test="toolbar-toggle-notes"
      class="flex md:rounded-none"
      :class="{ 'rounded-r-2xl': !showSaveAndExitButton && !showFullscreenButton && !showPresenterButton }"
      :active="showNotes"
      icon-path="icons/lp-toolbar-notes"
      :label="$t('toolbar.notes')"
      @click="$emit('toggle-notes')"
    />
    <LessonToolbarItem
      v-if="showPresenterButton && !isRevision"
      data-test="toolbar-toggle-presenter"
      class="hidden md:flex"
      :class="{ 'rounded-r-2xl': !showSaveAndExitButton && !showFullscreenButton }"
      icon-path="icons/present"
      :label="$t('toolbar.presenter')"
      @click="$emit('open-presenter')"
    />
    <LessonToolbarItem
      v-if="showFullscreenButton"
      data-test="toolbar-toggle-fullscreen"
      class="hidden md:flex"
      :class="{ 'rounded-r-2xl': !showSaveAndExitButton }"
      :icon-path="fullscreenIcon"
      :label="$t(isFullscreen ? 'toolbar.exit_fullscreen' : 'toolbar.fullscreen')"
      @click="$emit('toggle-fullscreen')"
    />
    <LessonToolbarItem
      v-if="showSaveAndExitButton"
      data-test="toolbar-toggle-save-exit"
      class="hidden rounded-r-2xl md:flex"
      icon-path="icons/lp-toolbar-save-and-exit"
      :label="$t('toolbar.save_and_exit')"
      @click="$emit('exit-player')"
    />
  </div>
</template>

<script>
/** NOTE: This shares much of the look and feel with the live learn
 * toolbar (/app/javascript/vue_features/live_learn/components/LiveLearnToolbar.vue)
 * but they do not share code.  Eventually we'd like to abstract the common behavior into a single
 * class, but until then be aware you may have to make changes to both if UI changes
 * are being made.
 */
import { computed, nextTick } from 'vue'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import Coachmark from 'vue_features/shared/components/ui/Coachmark'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import LessonToolbarItem from './LessonToolbarItem'
import LessonToolbarPositionIndicator from './LessonToolbarPositionIndicator'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useCurrentUserStore, useAnnotationToolStore } from 'vue_features/shared/store/composables'
import { useDismissibles } from 'vue_features/shared/composables'

const CM_TOOLBAR_TOOLS = 'student-tools'

export default {
  name: 'LessonToolbar',
  CM_TOOLBAR_TOOLS,
  components: {
    LessonToolbarItem,
    InlineSvg,
    LzIcon,
    LessonToolbarPositionIndicator,
    Coachmark,
  },
  props: {
    showToolsCoachmark: {
      type: Boolean,
      default: false,
    },
    showSidebarButton: {
      type: Boolean,
      default: true,
    },
    showNotesButton: {
      type: Boolean,
      default: false,
    },
    showAnnotationsButton: {
      type: Boolean,
      default: false,
    },
    showToolsButton: {
      type: Boolean,
      default: false,
    },
    showPresenterButton: {
      type: Boolean,
      default: false,
    },
    showFullscreenButton: {
      type: Boolean,
      default: false,
    },
    showSaveAndExitButton: {
      type: Boolean,
      default: false,
    },
    cards: {
      type: Array,
      required: true,
    },
    showNotes: {
      type: Boolean,
      default: false,
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    selectedCardIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { openTool, studentTools, isRevision } = useLessonPlanStore()
    const { isFullscreen } = useFullscreenStore()
    const { toggleAnnotationTools, showAnnotationTools } = useAnnotationToolStore()
    const { closeDismissible, showDismissible, canDismissibleShow } = useDismissibles()
    const { isStudent } = useCurrentUserStore()

    const fullscreenIcon = computed(() => {
      if (isFullscreen.value) {
        return 'icons/fullscreen-exit'
      }
      return 'icons/fullscreen-expand'
    })

    const toggleTool = (selectedTool) => {
      openTool.value = ''
      nextTick(() => {
        openTool.value = selectedTool
      })
    }

    showDismissible(CM_TOOLBAR_TOOLS)

    const uniqueIdentifier = new Date().getTime()

    return {
      toggleTool,
      isStudent,
      isRevision,
      uniqueIdentifier,
      canDismissibleShow,
      closeDismissible,
      toggleAnnotationTools,
      isFullscreen,
      openTool,
      studentTools,
      showAnnotationTools,
      fullscreenIcon,
    }
  },
}
</script>

<style scoped>
.lesson-toolbar {
  background-color: rgba(var(--primary-700), 0.9125);
}

/* We need to do this until we have TWv3 since these hover classes aren't supported */
.lesson-toolbar-toggle:hover {
  @apply -top-7 h-7 !important;
}
</style>

<style>
.tools-coachmark-container {
  background-image: url('~images/icons/tools-bg.png');
  background-repeat: no-repeat;
  background-position: bottom left;
}
</style>
