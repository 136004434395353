<template>
  <div class="flex flex-row flex-wrap items-center gap-4 md:flex-nowrap">
    <slot />
    <ScoringLegendIcon
      v-if="includeNoScore"
      :label="noScoreLabel || $t('common.no_score')"
      icon-class="text-transparent rounded-full bg-neutral-100 border-2 border-neutral-600 border-dashed"
    />
    <ScoringLegendIcon
      v-if="includeNeedsGrading"
      :label="needsGradingLabel || $t('common.needs_grading')"
      icon="needs-grading"
      icon-class="text-primary-700 bg-primary-200"
    />
    <div class="flex flex-row flex-wrap gap-4" :class="borderClasses">
      <ScoringLegendIcon
        v-for="band in bandDetails"
        :key="`score-band-${band.band}`"
        :label="band.label"
        :icon="band.icon"
        :icon-class="band.iconClass"
        :icon-style="{
          background: band.backgroundColor,
          color: band.color,
        }"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { scoreBandIcon } from '../utils'
import { defaultScoreBandRanges, scoreBandColors, scoreBandBackgrounds } from 'vue_features/shared/utils/scoring'
import ScoringLegendIcon from './ScoringLegendIcon'

export default {
  name: 'ScoringLegend',
  components: {
    ScoringLegendIcon,
  },
  props: {
    includeNoScore: {
      type: Boolean,
      default: false,
    },
    includeNeedsGrading: {
      type: Boolean,
      default: false,
    },
    noScoreLabel: {
      type: String,
      default: '',
    },
    needsGradingLabel: {
      type: String,
      default: '',
    },
    scoreBands: {
      type: Object,
      default: () => defaultScoreBandRanges,
    },
  },
  setup(props, { slots }) {
    const showBorder = computed(() => props.includeNoScore || props.includeNeedsGrading || slots.default)
    const borderClasses = computed(() =>
      showBorder.value
        ? [
            'md:border-l',
            'md:border-t-0',
            'md:border-r-0',
            'md:border-b-0',
            'md:border-solid',
            'md:border-neutral-300',
            'md:pl-4',
          ]
        : '',
    )
    const bands = computed(() => props.scoreBands || {})
    const bandDetails = computed(() =>
      Object.keys(bands.value).map((band, index, all) => {
        const [min, max] = bands.value[band]

        let label
        if (index === 0) {
          label = `<${max}%`
        } else if (index === all.length - 1) {
          label = `≥${min}%`
        } else {
          label = `≥${min} to <${max}%`
        }

        return {
          band,
          icon: scoreBandIcon(band),
          color: scoreBandColors[band],
          backgroundColor: scoreBandBackgrounds[band],
          label,
        }
      }),
    )

    return { bandDetails, borderClasses }
  },
}
</script>
