<template>
  <div class="info-total-points space-x-1">
    <strong>{{ $t('common.label.total_points') }}</strong>
    <span>{{ totalPoints }}</span>
  </div>
</template>

<script>
export default {
  name: 'InfoTotalPoints',
  props: {
    totalPoints: {
      type: Number,
      required: false,
      default: null,
    },
  },
}
</script>
