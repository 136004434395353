<template>
  <div data-test="active-filters" class="flex flex-row flex-wrap items-center gap-1">
    <Chip
      v-for="chip in activeChips"
      :key="chip.key"
      :label="chip.label.value"
      color="secondary"
      class="flex-none"
      @remove="removeChip(chip.filter, chip.key)"
    />
    <button
      v-if="activeChips.length > 0"
      type="button"
      data-test="clear-active"
      class="text-accent hover:text-accent-hover ml-3 flex-none cursor-pointer py-2 text-sm"
      @click="clearFilters"
    >
      {{ $t('search.clear_all') }}
    </button>
  </div>
</template>

<script>
import Chip from 'vue_features/shared/components/ui/Chip'
import { computed, inject } from 'vue'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'

export default {
  name: 'ActiveFilters',
  components: { Chip },
  setup() {
    const root = inject('useRoot')()

    const { setOption, getOption, selectedOptions, page, query, clearFilters, QUERY_KEY } = useSearchFiltersStore()

    const removeChip = (filterName, filterKey) => {
      if (filterKey === QUERY_KEY) {
        page.value = 1
        query.value = ''
      } else {
        setOption(filterName, filterKey, false)
      }
    }
    const activeChips = computed(() => {
      const initialChips = query.value ? [{ key: QUERY_KEY, label: query }] : []

      return Object.entries(selectedOptions.value).reduce((chips, [filter, keys]) => {
        return chips.concat(
          keys.map((key) => {
            const opt = getOption(filter, key)
            const name = opt?.label || root.$t(`search.filter_labels.${filter}.${key}`)
            const label = computed(() => (opt?.master ? name + root.$t('search.filter_labels.all') : name))
            return { filter, key, label }
          }),
        )
      }, initialChips)
    })
    return {
      clearFilters,
      removeChip,
      activeChips,
    }
  },
}
</script>
