<template>
  <a :href="url" class="btn--demoted smaller" target="_blank">
    {{ $t('scoring_rubric_link.scoring_guidance') }}
    <span class="icon icon--right">
      <InlineSvg path="icons/next" />
    </span>
  </a>
</template>

<script>
import { computed } from 'vue'
import { pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { components } from 'vue_features/shared/i18n'
import InlineSvg from './ui/InlineSvg'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'

export default {
  name: 'ScoringRubricLink',
  i18n: { messages: pickLocaleNamespace(components, 'scoring_rubric_link') },
  components: { InlineSvg },
  props: {
    rubricUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { urlWithCrumbs } = usePathCrumbsStore()
    const url = computed(() => urlWithCrumbs(props.rubricUrl))
    return { url }
  },
}
</script>
