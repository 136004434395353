<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/tabindex-no-positive
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div ref="slideFormEl" class="form-group relative col-span-8 max-w-7xl">
    <span v-if="!lessonPlan">
      <label class="mr-2 inline" for="text-style">
        {{ $t('shared.text_style.label') }}
      </label>
      <select
        id="text-style"
        v-model="newTextStyle"
        :title="$t('shared.text_style.help')"
        class="select--inline"
        name="slide[text_style]"
      >
        <option value="elementary">{{ $t('shared.text_style.elementary') }}</option>
        <option value="secondary">{{ $t('shared.text_style.secondary') }}</option>
      </select>
    </span>
    <div id="preview-toggle" class="float-right inline-flex items-center">
      <LzIcon
        v-if="needsOrdering"
        :title="$t('slides.form_template.orderer_tooltip')"
        path="icons/notification"
        size="sm"
        class="notification has-tip"
        aria-haspopup="true"
        data-disable-hover="false"
        tabindex="1"
        data-tooltip=""
      />
      <label class="inline">{{ $t('common.preview') }}</label>
      <div class="toggle-btn smaller ml-4 inline">
        <label>
          <div
            :class="['toggle-btn__option', { selected: !isOrderingContent }]"
            data-test="stop-ordering-btn"
            @click="isOrderingContent = false"
          >
            <LzIcon path="icons/present" />
            <span>
              {{ $t('slides.form_template.normal') }}
            </span>
          </div>
        </label>
        <label>
          <div
            :class="['toggle-btn__option', { selected: isOrderingContent }]"
            data-test="order-btn"
            @click="startOrdering"
          >
            <LzIcon path="icons/arrows-up-down" />
            <span>
              {{ $t('slides.form_template.reading_order') }}
            </span>
          </div>
        </label>
      </div>
    </div>
    <div class="slide-toolbar">
      <OrdererToolbar v-if="isOrderingContent" />
      <EditorToolbar
        v-show="!isOrderingContent && currentSelection"
        :sorted-contents="editedContents"
        :current-selection="currentSelection"
        :edited-selection="editedSelection"
        :is-ordering-content="isOrderingContent"
        @deselect="deselectContent"
        @edit="edit"
        @remove-content="deleteCurrentSelection"
        @update-selected="updateSelected"
      />
      <template v-if="!isOrderingContent">
        <ContentDragButton
          v-for="option in dragButtons"
          :key="option.key"
          :path="`icons/${option.path}`"
          :label="option.label"
          @mount="mountDraggable($event, option.key, buttonOffset)"
          @unmount="unmountDraggable($event, option.key, buttonOffset)"
        />
      </template>
    </div>
    <input :value="contentsJSON" name="slide[contents]" class="hidden" />
    <input :value="ordered" name="slide[ordered]" class="hidden" />
    <p class="slide__editor-unsupported">{{ $t('slides.editor_templates.mobile_unsupported_message') }}</p>
    <SlideOrderer
      v-if="isOrderingContent"
      :contents="editedContents"
      :text-style="newTextStyle"
      @update-order="updateOrder"
    />
    <SlideEditor
      v-else
      v-bind="{
        contents: editedContents,
        textStyle: newTextStyle,
        currentSelection,
        editedSelection,
        isIndicatorActive,
        indicatorStyle,
        gutters,
        startResize,
      }"
      @select="select"
      @edit="edit"
      @update="updateContent"
      @mount="mountDraggable($event.element, $event.content, contentOffset)"
      @unmount="unmountDraggable($event.element, $event.content, contentOffset)"
    />
  </div>
</template>

<script>
import { ref, computed, inject, watch, watchEffect, onUnmounted, onMounted } from 'vue'
import { sortBy, difference, isEqual } from 'lodash'
import { config } from 'utils'
import { LzIcon } from 'vue_features/shared/components/ui'
import OrdererToolbar from './OrdererToolbar'
import EditorToolbar from './EditorToolbar'
import SlideEditor from './SlideEditor'
import SlideOrderer from './SlideOrderer'
import ContentDragButton from './ContentDragButton'
import useSortedContents from '../composables/use_sorted_contents'
import useDragDrop from '../composables/use_drag_drop'
import useSlideIndicator from '../composables/use_slide_indicator'
import useGutters from '../composables/use_gutters'
import { default as useGrid, COLUMNS, ROWS } from '../composables/use_grid'
import useSlideContentDrop from '../composables/use_slide_content_drop'
import useFilestackUploader from 'vue_features/shared/composables/use_filestack_uploader'
import { initNewContentId, slideContentJSON } from '../composables/models/slide_content_factory'

const DELETE_KEY = [46, 8]

export default {
  name: 'SlideForm',
  components: { ContentDragButton, LzIcon, OrdererToolbar, EditorToolbar, SlideEditor, SlideOrderer },
  props: {
    resource: { type: Object, required: true },
    textStyle: { type: String, default: 'secondary' },
    lessonPlan: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const dragButtons = [
      { key: 'text', path: 'text', label: root.$t('common.text') },
      { key: 'image', path: 'image-no-circle', label: root.$t('common.image') },
      { key: 'table', path: 'table', label: root.$t('common.table') },
      { key: 'dialogue', path: 'comment', label: root.$t('slides.form_template.dialogue.label') },
      { key: 'caption', path: 'document-remove', label: root.$t('common.caption') },
    ]
    const { sortedContents } = useSortedContents(props)

    const slideFormEl = ref(null)
    const currentSelection = ref(null)
    const editedSelection = ref(null)
    const isOrderingContent = ref(false)
    const needsOrdering = ref(false)
    const ordered = ref((props.resource.json || {}).ordered)
    const editedContents = ref(sortedContents.value.map((c) => ({ ...c })))
    const selectedImage = ref(null)
    const movingContent = ref(null)
    initNewContentId(editedContents)

    let oldContents = [...editedContents.value]
    const isEmpty = computed(() => editedContents.value.length <= 0)
    const contentsJSON = computed(() => JSON.stringify(editedContents.value.map((c) => slideContentJSON(c))))

    const grid = useGrid(editedContents)
    const addContent = (content) => {
      content.rowSpan = ROWS
      content.colSpan = COLUMNS
      editedContents.value.push(content)
    }
    const removeContent = (content) => {
      grid.reclaimSpace(content)
      const index = editedContents.value.indexOf(content)
      editedContents.value.splice(index, 1)
      deselectContent()
      return insert.bind(null, content)
    }
    const insert = (content) => {
      grid.makeRoomFor(content)
      const newSortedContents = [content, ...editedContents.value]
      const sortedIndex = sortBy(newSortedContents, ['row', 'column']).indexOf(content)
      editedContents.value.splice(sortedIndex, 0, content)
      return removeContent.bind(null, content)
    }
    const indicator = useSlideIndicator(grid, insert)
    const { gutters, startResize } = useGutters(editedContents, grid.resize)

    const onUploadDone = (response) => {
      const json = response.filesUploaded[0]
      selectedImage.value.url = json.url
      selectedImage.value.mimetype = json.mimetype
      selectedImage.value.policy = uploadOptions.value.policy
      selectedImage.value.signature = uploadOptions.value.signature
      selectedImage.value.processedUrl = null
      selectedImage.value.processedFile = null
      select(selectedImage.value)
    }
    const { launch, uploadFile, uploadOptions } = useFilestackUploader({
      accept: config.imageWithSvg.accept,
      maxSize: config.imageWithSvg.maxSize,
      onUploadDone,
    })
    const openImageUpload = () => {
      selectedImage.value = currentSelection.value
      launch()
    }

    const edit = (content) => {
      currentSelection.value = content
      editedSelection.value = content
      if (currentSelection.value.ckeditorInstance) currentSelection.value.ckeditorInstance.focus()
      else if (currentSelection.value.type === 'image') openImageUpload()
    }
    const select = (content) => {
      if (currentSelection.value === content) edit(content)
      else {
        currentSelection.value = content
        editedSelection.value = null
      }
    }
    const deselectContent = () => {
      currentSelection.value = null
      editedSelection.value = null
    }
    const updateContent = (event) => {
      const { content, updates } = event
      Object.entries(updates).forEach(([key, value]) => (content[key] = value))
    }
    const updateSelected = (updates) => {
      if (currentSelection.value) updateContent({ content: currentSelection.value, updates })
    }
    const deleteCurrentSelection = () => {
      if (currentSelection.value) removeContent(currentSelection.value)
    }
    const startOrdering = () => {
      ordered.value = true
      needsOrdering.value = false
      isOrderingContent.value = true
    }
    const updateOrder = (newOrderedContents) => (editedContents.value = newOrderedContents)
    const selectNewAddition = () => {
      if (editedContents.value.length > oldContents.length) {
        const newContents = difference(editedContents.value, oldContents)
        if (newContents.length) select(newContents[0])
      }
    }
    const updateNeedsOrdering = () => {
      if (ordered.value && !isOrderingContent.value) {
        if (oldContents.length !== editedContents.value.length) needsOrdering.value = true
        else {
          const oldPositions = oldContents.map((c) => ({ row: c.row, column: c.column }))
          const newPositions = editedContents.value.map((c) => ({ row: c.row, column: c.column }))
          needsOrdering.value = isEqual(oldPositions, newPositions)
        }
      }
    }

    const { onDragStart, onDrag, onDragStop, reset, mountDraggable, unmountDraggable } = useDragDrop(
      addContent,
      removeContent,
      indicator,
      deselectContent,
      isEmpty,
      movingContent,
    )
    const { addContentDropEventListeners, removeContentDropEventListeners } = useSlideContentDrop({
      onDragStart,
      onDrag,
      onDragStop,
      uploadFile,
      movingContent,
      reset,
    })
    onMounted(() => addContentDropEventListeners(slideFormEl.value))
    onUnmounted(() => removeContentDropEventListeners(slideFormEl.value))

    watch(
      () => [...editedContents.value],
      () => {
        selectNewAddition()
        updateNeedsOrdering()
        oldContents = [...editedContents.value]
      },
    )
    watchEffect(() => emit('update', { contents: contentsJSON.value, ordered: ordered.value }))

    const _keydownListener = (e) => {
      const focusedTag = document.activeElement?.tagName || null
      if (window.CKEDITOR.currentInstance || focusedTag === 'TEXTAREA') return

      const key = e.which
      if (DELETE_KEY.includes(key)) {
        deleteCurrentSelection()
        return false
      }
    }
    document.addEventListener('keydown', _keydownListener)
    onUnmounted(() => document.removeEventListener('keydown', _keydownListener))

    return {
      dragButtons,
      slideFormEl,
      currentSelection,
      editedSelection,
      isOrderingContent,
      needsOrdering,
      ordered,
      editedContents,
      contentsJSON,
      gutters,
      startResize,
      edit,
      select,
      deselectContent,
      updateContent,
      updateSelected,
      deleteCurrentSelection,
      startOrdering,
      updateOrder,
      mountDraggable,
      unmountDraggable,
      newTextStyle: ref(props.textStyle),
      isIndicatorActive: indicator.active,
      indicatorStyle: indicator.style,
      buttonOffset: { top: 45, left: 41 },
      contentOffset: { top: 92, left: 120 },
    }
  },
}
</script>
