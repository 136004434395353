<script setup>
import StandardsDownloadModal from './StandardsDownloadModal'
import StandardsUploadModal from './StandardsUploadModal'
import dayjs from 'dayjs'
import { LzButton, LzTooltip, LzIcon } from 'vue_features/shared/components/ui'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { LoadingSpinner } from 'vue_features/shared/components/ui'

defineProps({
  standardImports: {
    type: Array,
    default: () => [],
  },

  standardDownloads: {
    type: Array,
    default: () => [],
  },
})

function handleStandardsUpload() {
  openModal('StandardsUploadModal')
}

function handleStandardsDownload() {
  openModal('StandardsDownloadModal')
}
</script>

<template>
  <div class="page-content">
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <h3>{{ $t('import.standards') }}</h3>

      <div class="border-primary-200 flex w-full items-center rounded-md border-2 bg-white p-4 md:max-w-2xl">
        <div>
          <span class="bold text-primary-600 text-lg">{{ $t('import.standards') }}</span>
          <p>{{ $t('import.initial_instructions') }}</p>
        </div>
        <LzButton color="primary" class="ml-auto" data-test="standards-import-button" @click="handleStandardsUpload">
          {{ $t('common.import') }}
        </LzButton>
      </div>

      <h4 class="text-primary-600 bold m-0 mt-3 py-2 text-lg">{{ $t('past_imports') }}</h4>
      <table>
        <thead>
          <tr>
            <th>{{ $t('who') }}</th>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('common.filename') }}</th>
            <th>{{ $t('import.type') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="standardImports.length === 0">
            <td colspan="4">{{ $t('no_standard_imports') }}</td>
          </tr>
          <tr v-for="record in standardImports" v-else :key="record.id" :data-test="`import-row-${record.id}`">
            <td>{{ record.user.name }}</td>
            <td>{{ dayjs(record.createdAt).fromNow() }}</td>
            <td>{{ record.filename }}</td>
            <td>{{ record.importType }}</td>
          </tr>
        </tbody>
      </table>

      <StandardsUploadModal v-if="canModalShow('StandardsUploadModal')" />
    </div>

    <div class="mx-auto mt-12 max-w-7xl px-3 md:px-6">
      <h3>{{ $t('download.standards') }}</h3>

      <div class="border-primary-200 flex w-full items-center rounded-md border-2 bg-white p-4 md:max-w-2xl">
        <div>
          <span class="bold text-primary-600 text-lg">{{ $t('download.standards') }}</span>
          <p>{{ $t('download.initial_instructions') }}</p>
        </div>
        <LzButton
          color="primary"
          class="ml-auto"
          data-test="standards-download-button"
          @click="handleStandardsDownload"
        >
          {{ $t('common.download') }}
        </LzButton>
      </div>

      <h4 class="text-primary-600 bold m-0 mt-3 py-2 text-lg">{{ $t('past_downloads') }}</h4>
      <table>
        <thead>
          <tr>
            <th>{{ $t('who') }}</th>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('state') }}</th>
            <th>{{ $t('subject') }}</th>
            <th>{{ $t('status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="standardDownloads.length === 0">
            <td colspan="5">{{ $t('no_standard_downloads') }}</td>
          </tr>
          <tr v-for="record in standardDownloads" v-else :key="record.id" :data-test="`download-row-${record.id}`">
            <td>{{ record.user.name }}</td>
            <td>{{ dayjs(record.createdAt).fromNow() }}</td>
            <td>{{ record.state }}</td>
            <td>{{ record.subject }}</td>

            <td v-if="record.status == 'inactive'"></td>
            <td v-else-if="record.status == 'active'"><LoadingSpinner :inline="true" size="small" /></td>
            <td v-else-if="record.status == 'complete'">
              {{ $t('common.complete') }} (<a class="underline" :href="record.csvFileUrl">{{ $t('common.download') }}</a
              >)
            </td>
            <td v-else-if="record.status == 'failed'" class="flex items-center gap-x-1">
              <span class="text-red-600">{{ $t('common.failed') }}</span>

              <LzTooltip tag="span" :title="record.message" tooltip-classes="bg-gray-800 text-gray-100">
                <LzIcon size="sm" path="icons/info" icon-color-class="text-gray-500" />
              </LzTooltip>
            </td>
          </tr>
        </tbody>
      </table>

      <StandardsDownloadModal v-if="canModalShow('StandardsDownloadModal')" />
    </div>
  </div>
</template>
