import { ratingForScore } from 'vue_features/shared/utils/scoring'

const DEFAULTS = {
  textColorClass: 'text-black',
  backgroundColorClass: 'bg-white',
  ringColorClass: 'text-white',
  borderClass: 'border-transparent',
  bubbleStyles: '',
  icon: null,
}
const LOADING = {
  ...DEFAULTS,
  textColorClass: 'text-primary-700',
  ringColorClass: 'text-blue-100',
  ringPercentage: 100,
  icon: null,
}
const NOT_ATTEMPTED = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-inverted',
  textColorClass: 'text-white',
  borderClass: 'border-dashed border-2 border-primary-200',
  icon: null,
}
const NOT_ATTEMPTED_INVERTED = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-transparent',
  textColorClass: 'text-base',
  borderClass: 'border-dashed border-2 border-primary-200',
  icon: null,
}
const NEEDS_SCORING = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-transparent',
  textColorClass: 'text-white',
  borderClass: 'border-primary-200 border-2',
  icon: 'icons/needs-grading',
}
const NEEDS_SCORING_INVERTED = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-transparent',
  textColorClass: 'text-base',
  borderClass: 'border-primary-200 border-2',
  icon: 'icons/needs-grading',
}
const LOW_SCORE_BAND = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-red-100',
  textColorClass: 'text-red-600',
  borderClass: 'border-white',
  icon: 'icons/close',
}
const MEDIUM_SCORE_BAND = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-yellow-200',
  textColorClass: 'text-yellow-600',
  borderClass: 'border-white',
  icon: 'icons/minus',
}
const HIGH_SCORE_BAND = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-green-200',
  textColorClass: 'text-green-600',
  borderClass: 'border-white',
  icon: 'icons/check',
}
const UPDATING = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-white',
  textColorClass: 'text-base',
  borderClass: 'border-white',
  icon: null,
}
const UPDATING_INVERTED = {
  ...DEFAULTS,
  backgroundColorClass: 'bg-white',
  textColorClass: 'text-base',
  borderClass: 'border-white',
  bubbleStyles: 'box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3)',
  icon: null,
}

const themeForScoreRating = (scoreRating) => {
  if (scoreRating === 'low') {
    return LOW_SCORE_BAND
  } else if (scoreRating === 'medium') {
    return MEDIUM_SCORE_BAND
  } else {
    return HIGH_SCORE_BAND
  }
}

export const themeForScore = ({
  score,
  maxScore,
  unmarked = false,
  attempted = true,
  loading = false,
  updating = false,
  inverted = false,
}) => {
  if (loading) {
    return LOADING
  } else if (updating) {
    return inverted ? UPDATING_INVERTED : UPDATING
  } else if (!attempted && score === null) {
    return inverted ? NOT_ATTEMPTED_INVERTED : NOT_ATTEMPTED
  } else if (maxScore === null || score === null || unmarked === true) {
    return inverted ? NEEDS_SCORING_INVERTED : NEEDS_SCORING
  } else {
    const scorePercent = (score * 100.0) / maxScore
    const scoreRating = ratingForScore(scorePercent)
    return themeForScoreRating(scoreRating)
  }
}

export const scoreThemeForResponse = (response, loading = false, inverted = false) => {
  const score = response?.score
  const maxScore = response?.maxScore
  const unmarked = response?.unmarked
  const attempted = response?.attempted

  return themeForScore({ score, maxScore, unmarked, attempted, loading, inverted })
}

export const scoreThemeForItem = (item, loading = false, inverted = false) => {
  const score = item?.score
  const maxScore = item?.maxScore
  const unmarked = item?.unmarked
  const attempted = item?.attempted
  const updating = item?.updating

  return themeForScore({ score, maxScore, unmarked, attempted, loading, inverted, updating })
}
