<template>
  <EmptyStateWithIcon
    path="icons/empty-classes"
    :heading="isForActiveKlass ? $t('klasses.index.no_active_classes') : $t('klasses.index.no_archived_classes')"
    :body="isForActiveKlass && !isStudent ? $t('klasses.index.create_a_class') : null"
  >
    <KlassCreateButton v-if="isForActiveKlass && !isStudent" data-test="create-class-button" />
  </EmptyStateWithIcon>
</template>

<script>
import { EmptyStateWithIcon } from 'vue_features/shared/components/ui'
import KlassCreateButton from './KlassCreateButton'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'ListBlankSlate',
  components: { EmptyStateWithIcon, KlassCreateButton },
  props: {
    klassType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isStudent } = useCurrentUserStore()
    return { isStudent }
  },
  computed: {
    isForActiveKlass() {
      return isActive(this.klassType)
    },
  },
}
</script>
