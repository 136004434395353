import messages from './i18n/messages'
import CustomersShow from './components/CustomersShow'
import { useSiteAdminCustomerStore } from './store/use_site_admin_customer_store'

export default function ({ props, state }) {
  useSiteAdminCustomerStore().$set(state)
  props.initialCustomer = state.customer

  return { component: CustomersShow, messages, props }
}
