import { AssignmentReportService } from '../api'
import { newReportsApp } from 'clients/learnosity'
import { camelCaseKeys } from 'vue_features/shared/helpers/http_helper'
import GradePassbackWebSocketHandler from './grade_passback_web_socket_handler'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

import { merge, cloneDeep } from 'lodash'

export const useAssignmentReportStore = defineStore('assignmentReport', () => {
  const state = reactive({
    assignment: {},
    klasses: [],
    learnosityAssessmentConfig: {},
    standardInitiatives: [],
    commonCoreOnly: false,
    gradePassbackStatus: [],
    validLearnosityItems: [],
  })

  const validLearnosityItemReferences = computed(
    () => new Set(state.validLearnosityItems.map((item) => item.reference)),
  )

  function getAssignment() {
    return AssignmentReportService.getAssignment(state.assignment.code).then((assignment) => {
      state.assignment = assignment
    })
  }

  function getAssignmentReports({ learnosityIdentifier, klassId, page, perPage }) {
    return AssignmentReportService.getAssignmentReports({
      code: state.assignment.code,
      learnosityIdentifier,
      klassId,
      page,
      perPage,
    }).then((data) => {
      const hasReportData = data.learnosityReportConfig?.request

      updateAssignmentViews({
        klassBeingLoadedId: klassId,
        studentActivities: data.studentActivities,
        page,
      })

      if (hasReportData) return loadDataFromLearnosity({ assignmentReportData: data })
    })
  }

  function loadDataFromLearnosity({ assignmentReportData }) {
    const reportId = assignmentReportData.learnosityReportConfig.request.reports[0].id
    return newReportsApp(assignmentReportData.learnosityReportConfig).then((reportsApp) => {
      return new Promise((resolve) => {
        const learnosityReport = reportsApp.getReport(reportId)
        learnosityReport.on('load:data', (reportData) => {
          if (reportData) updateReportData(reportData)
          resolve()
        })
      })
    })
  }

  function updateKlasses(updatedKlasses) {
    const klasses = cloneDeep(state.klasses)
    updatedKlasses.forEach((updatedKlass) => {
      const updatedIndex = klasses.findIndex((klass) => klass.id === updatedKlass.id)
      const hasUpdated = updatedIndex > -1
      if (hasUpdated) klasses[updatedIndex] = merge({}, klasses[updatedIndex], updatedKlass)
      if (!hasUpdated) klasses.push(updatedKlass)
    })
    state.klasses = klasses
  }

  function getKlassesForAssignment() {
    return AssignmentReportService.getKlassesForAssignment(state.assignment.id).then((klasses) => {
      klasses.forEach((klass) => {
        if (!klass.assignmentViews) klass.assignmentViews = []
        if (!klass.pagination) klass.pagination = {}
      })
      updateKlasses(klasses)
    })
  }

  function changeAssignmentStateForKlass({ klassId, assignmentViewIds, assignmentState, openAt, closeAt }) {
    return AssignmentReportService.changeAssignmentStateForKlass({
      klassId,
      assignmentId: state.assignment.id,
      assignmentViewIds,
      assignmentState,
      openAt,
      closeAt,
    }).then((assignmentViews) => {
      assignmentViews.forEach((assignmentView) => {
        updateAssignmentViewState({
          id: assignmentView.id,
          newAssignmentViewState: assignmentView.assignmentState,
          newOpenAt: assignmentView.openAt,
          newCloseAt: assignmentView.closeAt,
        })
      })
      getKlassesForAssignment()
      getAssignment()
    })
  }

  function updateAssignmentViewState({ id, newAssignmentViewState, newOpenAt, newCloseAt }) {
    const klasses = cloneDeep(state.klasses)
    klasses.forEach((klass) => {
      if (klass.assignmentViews) {
        const view = klass.assignmentViews.find((v) => v.id === id)
        if (view) {
          view.assignmentState = newAssignmentViewState
          view.openAt = newOpenAt
          view.closeAt = newCloseAt
        }
      }
    })
    updateKlasses(klasses)
  }

  function updateAssignmentViews({ klassBeingLoadedId, studentActivities, page }) {
    const klass = cloneDeep(state.klasses.find((k) => k.id === klassBeingLoadedId))
    studentActivities.assignmentViews.forEach((view) => (view.reportData = { items: {} }))
    if (page === 1) klass.assignmentViews = studentActivities.assignmentViews
    if (typeof page === 'number' && page !== 1)
      klass.assignmentViews = [...klass.assignmentViews, ...studentActivities.assignmentViews]
    if (typeof page !== 'undefined') klass.pagination = studentActivities.meta.pagination

    updateKlasses([klass])
  }

  function reloadKlass({ klassId }) {
    return AssignmentReportService.getKlass(state.assignment.id, klassId).then((klass) => {
      if (!klass) return
      updateKlasses([klass])
    })
  }

  function updateReportData(reportData) {
    const klasses = cloneDeep(state.klasses)
    const userIds = Object.keys(reportData[state.assignment.learnosityActivityId].users)
    klasses.forEach((klass) => {
      klass.assignmentViews.forEach((view) => {
        const sessionId = view.studentId || view.learnositySessionId
        if (userIds.includes(`${sessionId}`)) {
          view.reportData = camelCaseKeys(
            withInvalidItemsRemoved(reportData[state.assignment.learnosityActivityId].users[`${sessionId}`]),
          )
        }
      })
    })
    updateKlasses(klasses)
  }

  function withInvalidItemsRemoved(userReportData) {
    userReportData.items = Object.fromEntries(
      Object.entries(userReportData.items)
        .filter(([key, item]) => {
          return validLearnosityItemReferences.value.has(key)
        })
        .map(([key, item], index) => [key, { ...item, itemNumber: index + 1 }]),
    )

    return userReportData
  }

  function listenForGradePassbackStatusUpdates({ courseAssignmentId }) {
    const handler = new GradePassbackWebSocketHandler(courseAssignmentId)
    handler.createGradePassbackListener()
  }

  function removeLzCodeKlass({ klassId }) {
    state.klasses = state.klasses.filter((k) => k.id !== klassId)
  }

  function updateActiveForLzCodeKlass({ klassId, active }) {
    state.klasses = state.klasses.map((k) => {
      if (k.id === klassId) {
        return {
          ...k,
          lzCodeKlass: {
            ...k.lzCodeKlass,
            active: active,
          },
        }
      }

      return k
    })
  }

  function setGradePassbackStatus({ googleClassroomCourseAssignmentId, klassId, students }) {
    let found = false

    state.gradePassbackStatus = state.gradePassbackStatus.map((status) => {
      if (status.googleClassroomCourseAssignmentId === googleClassroomCourseAssignmentId) {
        found = true
        return {
          ...status,
          students: { ...students },
        }
      }

      return status
    })

    if (!found) {
      state.gradePassbackStatus = [
        ...state.gradePassbackStatus,
        {
          googleClassroomCourseAssignmentId,
          klassId,
          students,
        },
      ]
    }
  }

  return {
    state,
    getAssignmentReports,
    loadDataFromLearnosity,
    getKlassesForAssignment,
    changeAssignmentStateForKlass,
    reloadKlass,
    listenForGradePassbackStatusUpdates,
    setGradePassbackStatus,
    removeLzCodeKlass,
    updateActiveForLzCodeKlass,
    validLearnosityItemReferences,
  }
})
