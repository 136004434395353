import messages from './i18n/messages'
import { useCustomersIndexStore } from './store/use_customers_index_store'
import CustomersIndex from './components/CustomersIndex'
import CustomersSearch from './components/CustomersSearch'
import { createLzRouter, scrollToTop } from 'vue_features/shared/router'

function paramAsArray(param) {
  return typeof param === 'string' ? [param] : param
}

function parseSearchParams(route) {
  const {
    query: { owner, site_types: siteTypes, customer_types: customerTypes, clever_types: cleverTypes, query },
  } = route

  return {
    currentSearch: {
      query: query || '',
      owner: owner || '',
      site_types: paramAsArray(siteTypes) || [],
      customer_types: paramAsArray(customerTypes) || [],
      clever_types: paramAsArray(cleverTypes) || [],
    },
  }
}

export default function ({ state }) {
  const routes = [{ path: '/sites', component: CustomersSearch, props: parseSearchParams }]

  const router = createLzRouter({
    routes,
    scrollBehavior: scrollToTop,
  })

  useCustomersIndexStore().$set(state)

  return { component: CustomersIndex, messages, router }
}
