<template>
  <div ref="observedElement" class="relative">
    <div v-if="mathIsLoading" class="absolute inset-0 z-20 bg-white" data-test="mathjax-loading-spinner">
      <LoadingSpinner size="large" monochrome />
    </div>
    <slot />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useMutationObserver } from 'vue_features/shared/composables'
import { LoadingSpinner } from 'vue_features/shared/components/ui'

const rawTexRegex = /\\\(.*\\\)/
const placeholderRegex = /\[\[math-placeholder\]\]/ // this is set up in our MathJax config

function useMathJaxLoadingSpinner() {
  const observedElement = ref(null)
  const mathIsLoading = ref(false)
  const elementContainsTex = () => observedElement.value.textContent.match(rawTexRegex)
  const elementContainsPlaceholder = () => observedElement.value.textContent.match(placeholderRegex)

  watch(observedElement, () => (mathIsLoading.value = elementContainsTex()))
  useMutationObserver(
    observedElement,
    () => {
      if (!mathIsLoading.value && elementContainsTex()) {
        mathIsLoading.value = true
      }

      if (mathIsLoading.value) {
        // watch for TeX expressions to be replaced with MathJax previews
        if (observedElement.value.querySelector('.MathJax_Preview') && !elementContainsPlaceholder()) {
          mathIsLoading.value = false
        }
      }
    },
    { subtree: true, childList: true },
  )

  return { observedElement, mathIsLoading }
}

export default {
  name: 'MathJaxLoadingSpinner',
  components: { LoadingSpinner },
  setup() {
    const { observedElement, mathIsLoading } = useMathJaxLoadingSpinner()

    return { observedElement, mathIsLoading }
  },
}
</script>
