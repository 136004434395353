<template>
  <div>
    <LzTabs
      v-if="hasStudentFunctionality"
      :links="[
        { name: 'teacher-login', label: $t('common.teacher'), default: true },
        { name: 'student-login', label: $t('common.student') },
      ]"
    />
    <div id="login-content">
      <Grid class="login__form border-base border-t py-4">
        <RouterView :previously-activated="previouslyActivated" class="col-span-12" />
      </Grid>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue'
import { LzTabs, Grid } from 'vue_features/shared/components/ui'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common } from 'vue_features/shared/i18n'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useRoute, useRouter } from 'vue-router'

const messages = namespaceLocaleObj(common, 'common', { only: ['teacher', 'student'] })

export default {
  name: 'Login',
  components: { Grid, LzTabs },
  i18n: { messages },
  props: {
    previouslyActivated: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    const route = useRoute()
    const router = useRouter()

    watch(
      () => route.name,
      () => {
        if (route.name === 'student-login' && !hasStudentFunctionality) {
          router.replace({ name: 'teacher-login' })
        }
      },
      { immediate: true },
    )
    return { hasStudentFunctionality }
  },
}
</script>
