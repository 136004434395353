<template>
  <ListDropdown
    :id="`crumb-select-${index}`"
    ref="rootEl"
    :data-test="`crumb-select-${index}`"
    :button-classes="['btn--small', isUnselected ? ['opacity-70 border-dashed'] : []]"
    :dropdown-classes="['w-auto max-w-none max-h-screen-64 overflow-y-auto']"
    @dropdown-toggled="handleDropdownToggle"
  >
    <template #button>
      <div class="max-w-64 min-w-48 flex justify-between">
        <div data-test="crumb-select-title" class="whitespace-nowrap pr-4" aria-hidden="true">{{ selectedTitle }}</div>
        <span class="sr-only">{{ ariaTitle }}</span>
        <LzIcon path="icons/caret" size="sm" aria-hidden="true" />
      </div>
    </template>

    <template #listItems>
      <li
        v-for="(item, i) in pathCrumb.items"
        ref="itemEls"
        :key="`crumb-item-${index}-${i}-${item.crumbId}`"
        class="block border-l-0"
        :data-test="`${item.type}.${item.id}`"
      >
        <PathCrumbItem v-bind="{ item, index }" @select="$emit('select', $event)" />
      </li>
    </template>
  </ListDropdown>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LzIcon } from 'vue_features/shared/components/ui'
import PathCrumbItem from './PathCrumbItem'

export default {
  name: 'PathCrumbSelect',
  components: {
    ListDropdown,
    LzIcon,
    PathCrumbItem,
  },
  props: {
    pathCrumb: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { findSelected, findSelectedIndex, pathCrumbs, trunc } = usePathCrumbsStore()
    const index = computed(() => pathCrumbs.value.indexOf(props.pathCrumb))

    const selectedTitle = computed(() => {
      const selected = findSelected(index.value)
      return trunc(selected?.title || root.$t('path_crumbs.select'), 30)
    })

    const ariaTitle = computed(() => {
      const selected = findSelected(index.value)
      return selected?.title || root.$t('path_crumbs.select')
    })

    const isUnselected = computed(() => !findSelected(index.value))

    const rootEl = ref()
    const itemEls = ref([])
    function handleDropdownToggle(isOpen) {
      if (isOpen) {
        const itemEl = itemEls.value[findSelectedIndex(index.value)]
        itemEl?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }

    return {
      index,
      selectedTitle,
      ariaTitle,
      rootEl,
      handleDropdownToggle,
      itemEls,
      isUnselected,
      trunc,
    }
  },
}
</script>
