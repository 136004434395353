<script setup>
import { Banner, TopSticky, LzButton, LzIcon } from 'vue_features/shared/components/ui'
import EditActionMenu from './EditActionMenu'
import Routes from 'routes/routes'
import { computed } from 'vue'
import { activeModal } from 'vue_features/shared/composables/use_global_modals'

const props = defineProps({
  objectId: { type: [String, Number], required: true },
  objectType: { type: String, required: true },
  objectName: { type: String, required: true },
  originalId: { type: [String, Number], required: true },
  canPrint: { type: Boolean, required: true },
})

const editRevisionPath =
  props.objectType === 'Resource'
    ? Routes.edit_resource_revision_path(props.originalId)
    : Routes.edit_lesson_plan_revision_path(props.originalId)

const hasActiveModal = computed(() => !!activeModal.value.id)
</script>

<template>
  <TopSticky :class="['z-20', { 'z-50': hasActiveModal }]">
    <Banner :has-top-border="false" class="bg-opacity-90">
      <p class="text-inverted hidden md:inline-block">{{ $t('revisions.you_are_previewing') }}</p>
      <EditActionMenu v-bind="{ objectId, objectType, objectName, originalId, canPrint }" is-revision />
      <LzButton :href="editRevisionPath" tag="a" color="green" size="small" class="flex items-center gap-1">
        <LzIcon path="icons/chevron-double-right" size="sm" type="span" />
        {{ $t('common.continue_revision') }}
      </LzButton>
    </Banner>
  </TopSticky>
</template>
