import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const AssignmentService = {
  getKlasses({ page, assignableIds }) {
    return HTTP.getJSON(Routes.teacher_klasses_path(), snakeKeys({ state: 'active', page, assignableIds }))
  },

  getStudents({ page, klassId, assignableIds }) {
    return HTTP.getJSON(Routes.teacher_klass_rosters_path(klassId), snakeKeys({ page, assignableIds }))
  },

  linkGoogleCourseToKlass({ klass, courseId }) {
    const url = Routes.teacher_klass_google_classroom_course_path(klass.id)
    const attrs = { googleClassroomCourseId: courseId }

    return HTTP.create(url, snakeKeys(attrs))
  },
}

export default AssignmentService
