export function modelGridStyle(row, column, rowSpan, colSpan) {
  return {
    '-ms-grid-row': row,
    '-ms-grid-row-span': rowSpan,
    '-ms-grid-column': column,
    '-ms-grid-column-span': colSpan,
    'grid-column': `${column} / span ${colSpan}`,
    'grid-row': `${row} / span ${rowSpan}`,
  }
}

export default function useContentParsers() {
  function gridStyle(content) {
    return modelGridStyle(content.row + 1, content.column + 1, content.rowSpan, content.colSpan)
  }

  function alignmentClasses(content) {
    const horizontalAlignment = `slide__content--h${content.horizontalAlignment}`

    const valign = content.isCaption && !content.verticalAlignment ? 'top' : content.verticalAlignment
    const verticalAlignment = `slide__content--v${valign}`

    return [horizontalAlignment, verticalAlignment]
  }

  function contentClasses(content) {
    const classes = ['slide__content', ...alignmentClasses(content)]
    if (content.isCaption) classes.push('slide__content--flush-vertical')
    return classes
  }

  return { gridStyle, contentClasses }
}
