<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <StudentDataRecurringExportDeletionConfirmModal
      v-if="canModalShow(confirmModalId)"
      :modal-id="confirmModalId"
      @confirm="removeExport"
    />
    <form
      data-test="export-form"
      class="lzui-tile relative mb-4 grid grid-cols-12 gap-4 p-4 md:w-1/2"
      @submit.prevent="saveExport"
    >
      <div
        v-if="isLoading"
        class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-y-4 rounded bg-white bg-opacity-90"
      >
        <LoadingSpinner />
        <p>{{ $t('student_data_recurring_export.connection_testing') }}</p>
      </div>
      <div class="sftp-host required col-span-8">
        <label class="sftp-host required" for="sftp_host">{{ $t('student_data_recurring_export.sftp_host') }}</label>
        <input
          id="sftp_host"
          v-model="sftpHost"
          :disabled="!isEditing"
          placeholder="host.example.com"
          class="required form-control no-fullstory"
          type="text"
          required="required"
          aria-required="true"
        />
      </div>
      <div class="sftp-port required col-span-4">
        <label class="sftp-port required" for="sftp_port">
          {{ $t('student_data_recurring_export.sftp_port') }}
        </label>
        <input
          id="sftp_port"
          v-model="sftpPort"
          :disabled="!isEditing"
          placeholder="22"
          class="required form-control no-fullstory"
          type="text"
          required="required"
          aria-required="true"
        />
      </div>
      <div class="sftp-username required col-span-12">
        <label class="sftp-username required" for="sftp_username">
          {{ $t('student_data_recurring_export.sftp_username') }}
        </label>
        <input
          id="sftp_username"
          v-model="sftpUsername"
          :disabled="!isEditing"
          placeholder="sftp_user"
          class="required form-control no-fullstory"
          type="text"
          required="required"
          aria-required="true"
        />
      </div>
      <div class="sftp-password required col-span-12">
        <label class="sftp-password required" for="sftp_password">
          {{ $t('student_data_recurring_export.sftp_password') }}
        </label>
        <input
          id="sftp_password"
          v-model="sftpPassword"
          :disabled="!isEditing"
          placeholder="password"
          class="required form-control no-fullstory"
          type="password"
          required="required"
          aria-required="true"
        />
      </div>
      <div class="sftp-directory required col-span-12">
        <label class="sftp-directory required" for="sftp_directory">
          {{ $t('student_data_recurring_export.sftp_directory') }}
        </label>
        <input
          id="sftp_directory"
          v-model="sftpDirectory"
          :disabled="!isEditing"
          placeholder="~/files"
          class="required form-control no-fullstory"
          type="text"
          required="required"
          aria-required="true"
        />
      </div>
      <div class="required col-span-12">
        <label class="lesson_types">{{ $t('student_data_recurring_export.lesson_types') }}</label>
        <div v-for="[lessonType, index] in availableLessonTypes" :key="index" class="space-x-2">
          <input
            :id="'lesson_type_' + lessonType"
            v-model="lessonTypes"
            type="checkbox"
            :value="lessonType"
            :disabled="!isEditing"
          />
          <label :for="'lesson_type_' + lessonType">{{
            $t(`student_data_recurring_export.available_lesson_types.${lessonType}`)
          }}</label>
        </div>
      </div>
      <div class="required col-span-12">
        <label class="score_scope">{{ $t('student_data_recurring_export.score_scope') }}</label>
        <select id="score_scope" v-model="scoreScope" required :disabled="!isEditing">
          <option v-for="scope in availableScoreScopes" :key="scope" :value="scope">
            {{ $t(`student_data_recurring_export.available_score_scopes.${scope}`) }}
          </option>
        </select>
      </div>
      <div class="frequency required col-span-12">
        <label class="frequency required" for="frequency">{{ $t('student_data_recurring_export.frequency') }}</label>
        <p>{{ $t('student_data_recurring_export.nightly_at_11pm') }}</p>
      </div>

      <p v-if="successMessage" class="col-span-12 text-green-500">{{ successMessage }}</p>

      <p v-if="errorMessage" class="col-span-12 text-red-500">{{ errorMessage }}</p>

      <div v-if="isEditing" class="col-span-12">
        <LzButton v-if="isEditing" type="button" class="mr-2" data-test="cancel-button" @click="cancelForm">
          {{ $t('common.cancel') }}
        </LzButton>
        <LzButton v-if="isEditing" type="submit" color="primary" data-test="submit-button">{{
          formButtonText
        }}</LzButton>
      </div>
      <div v-else class="col-span-12">
        <LzButton type="button" class="float-right ml-2" color="primary" data-test="edit-button" @click="editForm">
          {{ $t('common.edit') }}
        </LzButton>
        <LzButton
          type="button"
          class="float-right"
          caution
          data-test="remove-button"
          @click="openModal(confirmModalId)"
        >
          {{ $t('common.remove') }}
        </LzButton>
      </div>
    </form>
  </div>
</template>

<script>
import { LzButton } from 'vue_features/shared/components/ui'
import { SiteAdminStudentDataRecurringExportService } from '../api'
import StudentDataRecurringExportDeletionConfirmModal from './StudentDataRecurringExportDeletionConfirmModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { uniqueId } from 'lodash'

const DEFAULT_IMPORT = {
  sftpHost: '',
  sftpPort: '22',
  sftpUsername: '',
  sftpPassword: '',
  sftpDirectory: '~/',
  scoreScope: 'total',
  reportScope: 'all',
}

export default {
  name: 'StudentDataRecurringExport',
  components: {
    LzButton,
    LoadingSpinner,
    StudentDataRecurringExportDeletionConfirmModal,
  },
  inject: ['availableLessonTypes', 'availableScoreScopes', 'availableReportScopes'],
  props: {
    studentDataRecurringExport: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    return { openModal, canModalShow }
  },
  data() {
    const { id, sftpHost, sftpPort, sftpUsername, sftpPassword, sftpDirectory, lessonTypes, scoreScope, reportScope } =
      this.studentDataRecurringExport || {}

    return {
      isEditing: id == null,
      isLoading: false,
      errorMessage: '',
      successMessage: '',
      id,
      confirmModalId: uniqueId(StudentDataRecurringExportDeletionConfirmModal.name),
      sftpHost,
      sftpPort,
      sftpUsername,
      sftpPassword,
      sftpDirectory,
      lessonTypes: lessonTypes || [],
      scoreScope,
      reportScope,
    }
  },
  computed: {
    formButtonText() {
      return this.studentDataRecurringExport?.id ? this.$t('common.update') : this.$t('common.create')
    },
  },
  methods: {
    addExport() {
      this.isEditing = true

      this.sftpHost = DEFAULT_IMPORT.sftpHost
      this.sftpPort = DEFAULT_IMPORT.sftpPort
      this.sftpUsername = DEFAULT_IMPORT.sftpUsername
      this.sftpPassword = DEFAULT_IMPORT.sftpPassword
      this.sftpDirectory = DEFAULT_IMPORT.sftpDirectory
      this.lessonTypes = []
      this.scoreScope = DEFAULT_IMPORT.scoreScope
      this.reportScope = DEFAULT_IMPORT.reportScope
    },

    editForm() {
      this.isEditing = true
      this.$emit('startEdit')
    },

    cancelForm() {
      this.isEditing = false

      if (this.id == null) {
        this.$emit('remove')
      } else {
        this.$emit('finishEdit')
      }
    },

    saveExport() {
      if (this.lessonTypes.length === 0) {
        alert(this.$t('student_data_recurring_export.no_lesson_types'))
        return
      }

      this.errorMessage = ''
      this.successMessage = ''
      this.isLoading = true

      this.serviceMethod()({
        id: this.id,
        sftpHost: this.sftpHost,
        sftpPort: this.sftpPort,
        sftpUsername: this.sftpUsername,
        sftpPassword: this.sftpPassword,
        sftpDirectory: this.sftpDirectory,
        lessonTypes: this.lessonTypes,
        scoreScope: this.scoreScope,
        reportScope: this.reportScope,
      })
        .then((data) => {
          this.id = data.id
          this.isEditing = false
          this.$emit('finishEdit')
          this.successMessage = this.$t('student_data_recurring_export.connection_success')
        })
        .catch((error) => {
          const connectionError = error.responseJSON.errors.base[0]

          if (connectionError) {
            this.errorMessage = this.$t('student_data_recurring_export.connection_error', { error: connectionError })
          } else {
            this.errorMessage = error
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    removeExport() {
      SiteAdminStudentDataRecurringExportService.delete(this.id)
        .then(() => {
          this.$emit('remove')
        })
        .catch((error) => {
          alert(JSON.stringify(error))
        })
    },

    serviceMethod() {
      return this.id == null
        ? SiteAdminStudentDataRecurringExportService.create
        : SiteAdminStudentDataRecurringExportService.update
    },
  },
}
</script>
