<template>
  <div v-mathjax class="slide__dialogue">
    <Ckeditor
      :content="text"
      :options="$options.ckeditorOptions"
      :class="['dialogue', shapeClass, directionClass]"
      type="div"
      contenteditable="true"
      @change="updateText"
      @created-editor="$emit('update', { ckeditorInstance: $event })"
    />
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import useSlideDialogue from '../../composables/use_slide_dialogue'
import { CKEDITOR_OPTIONS } from './ckeditor_options'

export default {
  name: 'SlideEditorDialogue',
  ckeditorOptions: CKEDITOR_OPTIONS,
  components: { Ckeditor },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { shapeClass, directionClass } = useSlideDialogue(toRefs(props))
    const text = ref(props.content.text || '')
    function updateText(newText) {
      text.value = newText
      emit('update', { text: newText })
    }
    return { text, shapeClass, directionClass, updateText }
  },
}
</script>
