import { captureException } from './sentry'

// MathJax needs to be re-rendered for elements that were display: none
// see http://www.peterkrautzberger.org/0165/ for more information
export function rerenderMathJax($el) {
  if (window.MathJax) {
    window.MathJax.Hub.processSectionDelay = 0
    window.MathJax.Hub.processUpdateTime = 0
    window.MathJax.Hub.processUpdateDelay = 0

    prepareAllTables($el)
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, $el, ckeditorScrub], [cleanAllTables, $el])
  } else {
    captureException('MathJax failed to load')
  }
}

function ckeditorScrub() {
  document.querySelectorAll('lz-content ul > li script').forEach((node) => (node.textContent = ''))
}

export function prepareAllTables($el) {
  // This seems to be a known issue - I've implemented the fix, suggested
  // here, https://github.com/mathjax/MathJax/issues/2078
  // Additionally, I had to set a width to get this to work fully - otherwise it would still
  // collapse, though not as badly.
  const tables = Array.from($el.querySelectorAll('table'))
  if ($el.nodeName === 'TABLE') {
    tables.push($el)
  }
  tables.forEach((table) => prepareTablePlaceholders(table))
}

export function cleanAllTables($el) {
  const tables = Array.from($el.querySelectorAll('table'))
  if ($el.nodeName === 'TABLE') {
    tables.push($el)
  }
  tables.forEach(removeTablePlaceholders)
}

function prepareTablePlaceholders(table) {
  const tds = table.querySelectorAll('td')
  tds.forEach((td) => {
    if (td.querySelector('.math-tex')) {
      td.appendChild(buildPlaceholder(td.offsetWidth))
    }
  })
}

function removeTablePlaceholders(table) {
  const divsToRemove = table.querySelectorAll('.math-tex-placeholder')
  divsToRemove.forEach((d) => d.remove())
}

function buildPlaceholder(width) {
  const placeholder = document.createElement('div')
  placeholder.setAttribute('style', `width: ${width}px; height: 0; font-size: 1px;`)
  placeholder.setAttribute('class', 'math-tex-placeholder')
  placeholder.innerHTML = '\\[\\]'
  return placeholder
}
