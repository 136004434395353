import { merge } from 'lodash'
import {
  namespaceLocaleObj,
  elevateLocaleNamespace,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import { common, lti, ltiV13, search, shared } from 'vue_features/shared/i18n'

export default merge(
  {},
  elevateLocaleNamespace(lti, 'content_items.index'),
  elevateLocaleNamespace(ltiV13, 'content_items.index'),
  elevateLocaleNamespace(shared, 'lz_content_modal'),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(search, 'search'),
  pickLocaleNamespace(shared, 'action_menu'),
)
