<template>
  <div>
    <div id="assignment-preview">
      <div class="page-head page-head--wide">
        <div class="page-head__wrapper gap-4 pb-8">
          <div class="page-head__content p-0" data-test="title">
            <div class="page-head__pretext">{{ $t('show.assignment_preview') }}</div>
            <h1 class="font-semibold">{{ lessonPlanTitle }}</h1>
          </div>
          <div class="page-head__actions page-head__actions--pretext flex gap-2 lg:flex-col">
            <div v-if="isAssignable" class="page-head__button">
              <AssignmentModal />
              <AssignmentButton class="btn btn--primary btn--small lg:w-full" />
            </div>
            <a class="btn btn--small mr-1" :href="Routes.lesson_plan_path(lessonPlanId)" data-test="back-button">
              <LzIcon type="div" size="sm" style="vertical-align: -1px" path="icons/previous" />
              {{ $t('handout.back_to_lp', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="content page-content lesson-plan__lesson px-8">
      <AssignmentCardsContent :is-preview="true" />
    </div>
  </div>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'
import AssignmentModal from 'vue_features/assignments/prompt/components/AssignmentModal'
import AssignmentButton from 'vue_features/assignments/prompt/components/AssignmentButton'
import { AssignmentCardsContent } from 'vue_features/assignments/show/components'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import Routes from 'routes'

export default {
  name: 'LessonPlanPreview',
  components: { LzIcon, AssignmentModal, AssignmentButton, AssignmentCardsContent },
  props: {
    lessonPlanTitle: {
      type: String,
      required: true,
    },
    isAssignable: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { lessonPlanId, lessonType } = useLessonPlanStore()
    return { Routes, lessonPlanId, lessonType }
  },
}
</script>
