import { merge } from 'lodash'
import {
  pickLocaleNamespace,
  namespaceLocaleObj,
  elevateLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import {
  components,
  shared,
  common,
  lzCodes,
  lessonPlans,
  helpers,
  studentTools,
  featureGate,
  hyperlinks,
  resources,
  standardsCoverageTables,
} from 'vue_features/shared/i18n'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'

export default merge(
  {},
  lzCodes,
  namespaceLocaleObj(hyperlinks, 'hyperlinks', { only: 'content' }),
  namespaceLocaleObj(resources, 'resources', { only: 'processing.message' }),

  namespaceLocaleObj(helpers, 'helpers', { only: 'authentication' }),
  namespaceLocaleObj(featureGate, 'feature_gate'),

  namespaceLocaleObj(studentTools, 'student_tools'),
  namespaceLocaleObj(lessonPlans, '', {
    only: [
      'lesson_plan',
      'show',
      'handout',
      'assignment_card_deck',
      'assignment_slideshow_header',
      'lesson_card_templates',
      'readable_types',
      'toolbar',
    ],
  }),
  namespaceLocaleObj(shared, '', { only: ['live_learn_prompt', 'assignment_prompt'] }),
  elevateLocaleNamespace(shared, 'assignment_prompt'),
  namespaceLocaleObj(shared, '', { only: ['premium_prompt', 'copyable_input'] }),
  namespaceLocaleObj(shared, 'shared', { only: ['comment_editor', 'offline'] }),
  namespaceLocaleObj(components, ''),
  pickLocaleNamespace(components, 'embed_code_modal'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  namespaceLocaleObj(components, '', { only: ['audio_player', 'check_answers_button'] }),

  namespaceLocaleObj(common, 'common'),
  standardsCoverageTables,
  standardsMessages,
)
