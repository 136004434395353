import { computed } from 'vue'

export function useNameHelpers(person) {
  // `person` should be some reactive object (like a prop or data prop) so the
  // computed will re-trigger when the prop is changed.
  const fullName = computed(() => {
    if (person.name) return person.name

    return `${person.firstName} ${person.middleName ? person.middleName + ' ' : ''}${person.lastName}`
  })

  return {
    fullName,
  }
}
