<!-- eslint-disable vuejs-accessibility/label-has-for | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <h1 class="mt-12">{{ $t('edit.page_title') }}</h1>

      <div v-if="hasSisSync && !isStudent">
        <h4 class="pb-2">{{ $t('edit.sis_sync_enabled') }}</h4>
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-3 pb-12 md:px-6">
      <div class="w-1/2">
        <div class="no-fullstory">
          <div>
            <p class="pb-4">
              {{ $t('edit.not_this_user', { name }) }}
              <RailsForm :action="logoutReturn" method="delete" class="inline">
                <input
                  :value="$t('edit.different_user')"
                  type="submit"
                  class="text-accent hover:text-accent-hover cursor-pointer bg-transparent"
                />
              </RailsForm>
            </p>
          </div>

          <div :class="['form-box', { error: errors.firstName }]">
            <label for="first-name">
              {{ $t('common.first_name') }}
              <span v-if="errors.firstName" data-test="first-name-error">
                <span v-if="errors.firstName.presence">
                  {{ $t('edit.errors.first_name.presence') }}
                </span>
                <span v-if="errors.firstName.maxlength">
                  {{ $t('edit.errors.first_name.maxlength') }}
                </span>
              </span>
            </label>
            <input
              id="first-name"
              v-model="firstName"
              :disabled="editingDisabled"
              :placeholder="$t('edit.first_name_placeholder')"
              name="first_name"
              type="text"
            />
          </div>
          <div :class="['form-box', { error: errors.middleName }]">
            <label for="middle-name">
              {{ $t('common.middle_name') }}
              <span class="weak regular">{{ $t('edit.optional') }}</span>
              <span v-if="errors.middleName" data-test="middle-name-error">
                <span v-if="errors.middleName.maxlength">
                  {{ $t('edit.errors.middle_name.maxlength') }}
                </span>
              </span>
            </label>
            <input
              id="middle-name"
              v-model="middleName"
              :disabled="editingDisabled"
              :placeholder="$t('edit.middle_name_placeholder')"
              name="middle_name"
              type="text"
            />
          </div>
          <div :class="['form-box', { error: errors.lastName }]">
            <label for="last-name">
              {{ $t('common.last_name') }}
              <span v-if="errors.lastName" data-test="last-name-error">
                <span v-if="errors.lastName.presence">
                  {{ $t('edit.errors.last_name.presence') }}
                </span>
                <span v-if="errors.lastName.maxlength">
                  {{ $t('edit.errors.last_name.maxlength') }}
                </span>
              </span>
            </label>
            <input
              id="last-name"
              v-model="lastName"
              :disabled="editingDisabled"
              :placeholder="$t('edit.last_name_placeholder')"
              name="last_name"
              type="text"
            />
          </div>

          <div v-if="authorizedAsTeacher">
            <div :class="['form-box', { error: errors.studentFacingName }]">
              <label for="student-facing-name">
                {{ $t('edit.student_facing_label') }}
                <span class="weak regular">{{ $t('edit.optional') }}</span>
                <span v-if="errors.studentFacingName">
                  <span v-if="errors.studentFacingName.presence">
                    {{ $t('edit.errors.student_facing_name.presence') }}
                  </span>
                  <span v-if="errors.studentFacingName.maxlength">
                    {{ $t('edit.errors.student_facing_name.maxlength') }}
                  </span>
                </span>
              </label>
              <input
                id="student-facing-name"
                v-model="studentFacingName"
                :placeholder="$t('edit.student_facing_placeholder')"
                name="student_facing_name"
                type="text"
              />
            </div>

            <div :class="['form-box no-fullstory', { error: errors.email }]">
              <label for="email">
                {{ $t('common.email') }}
                <span v-if="errors.email">
                  <span v-if="errors.email.server">
                    {{ errors.email.server.replace($t('common.email'), '') }}
                  </span>
                  <span v-if="errors.email.presence">
                    {{ $t('edit.errors.email.presence') }}
                  </span>
                  <span v-if="errors.email.maxlength">
                    {{ $t('edit.errors.email.maxlength') }}
                  </span>
                  <span v-if="errors.email.emailAddress">
                    {{ $t('edit.errors.email.email_address') }}
                  </span>
                </span>
              </label>
              <input
                id="email"
                v-model="email"
                :placeholder="$t('edit.email_placeholder')"
                :disabled="editingDisabled"
                name="email"
                type="email"
              />
            </div>

            <div class="bg-focus mb-4 rounded p-2">
              <label class="wrapped-input w-full">
                <input v-model="feedbackOptin" name="feedback_optin" type="checkbox" />
                <span class="regular">{{ $t('edit.feedback') }}</span>
                <span class="weak regular">{{ $t('edit.optional') }}</span>
              </label>
            </div>
          </div>

          <div v-else>
            <div class="form-box">
              <label for="username">
                {{ $t('common.username') }}
              </label>
              <input id="username" :value="user.username" name="username" type="text" disabled />
            </div>
          </div>
        </div>

        <div v-if="!user.preventUserChanges">
          <ChangePasswordModal v-if="canModalShow(ChangePasswordModal)" />
          <a id="change-password" href="#" class="small" @click.prevent="confirmChangePassword">
            {{ $t('edit.change_password') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChangePasswordModal from './ChangePasswordModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useUsersEditStore } from '../store/use_users_edit_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { RailsForm } from 'vue_features/shared/components/forms'
import { computed } from 'vue'

const props = defineProps({
  logoImage: {
    type: String,
    required: true,
  },
  hasSisSync: {
    type: Boolean,
    required: true,
  },
  logoutReturn: {
    type: String,
    required: true,
  },
})

const { isStudent, authorizedAsTeacher } = useCurrentUserStore()
const {
  errors,
  user,
  profile,
  updateFirstName,
  updateMiddleName,
  updateLastName,
  updateEmail,
  updateStudentFacingName,
  updateFeedbackOptin,
} = useUsersEditStore()

const name = computed(() => `${user.value.firstName} ${user.value.lastName}`)

const firstName = computed({
  get: () => user.value.firstName,
  set(value) {
    updateFirstName(value)
  },
})

const middleName = computed({
  get: () => user.value.middleName,
  set(value) {
    updateMiddleName(value)
  },
})

const lastName = computed({
  get: () => user.value.lastName,
  set(value) {
    updateLastName(value)
  },
})

const email = computed({
  get: () => user.value.email,
  set(value) {
    updateEmail(value)
  },
})

const studentFacingName = computed({
  get: () => profile.value.studentFacingName,
  set(value) {
    updateStudentFacingName(value)
  },
})

const feedbackOptin = computed({
  get: () => profile.value.feedbackOptin,
  set(value) {
    updateFeedbackOptin(value)
  },
})

const editingDisabled = computed(() => props.hasSisSync || user.value.preventUserChanges)

const confirmChangePassword = () => {
  openModal(ChangePasswordModal)
}
</script>
