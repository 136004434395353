import { getBreakStyle, hasContent, isPrintedTag, isFlexRow, isGrid, removeEmptyParents } from './dom'
import { splitText } from './text_splitter'
import renderFlexingItem from './render_flexing_item'
import { isPdf, renderPdf } from './render_pdf'
import { createPage, isOverflowing, moveBreakAfterAvoids, pageHeight, renderElement, renderParentChain } from './page'

export default async function pager() {
  addRefs()
  await chunk()
}

function addRefs() {
  const elements = document.querySelector('#un-formatted').querySelectorAll('*')
  elements.forEach((el) => addRef(el))
}

function addRef(element) {
  element.setAttribute('data-ref', getDataRef())
}

function chunk() {
  return renderChildren(document.querySelector('#un-formatted'), [], [createPage()])
}

async function renderChildren(element, parentChain = [], pages) {
  const newParentChain = [...parentChain]
  if (element.id !== 'un-formatted') {
    newParentChain.push(element)
  }
  for (const child of Array.from(element.children)) {
    if (getBreakStyle(child).before === 'always') {
      pages.push(createPage())
    }
    if (isPdf(child)) {
      await renderPdf(child, newParentChain, pages)
    } else if (isGrid(child)) {
      await renderGrid(child, newParentChain, pages)
    } else if (isFlexRow(child)) {
      await renderFlexingItem(child, newParentChain, pages)
    } else if (
      !hasContent(child) &&
      !element.classList.contains('math-tex') &&
      !element.classList.contains('MathJax')
    ) {
      await renderChildren(child, newParentChain, pages)
    } else {
      let page = pages[pages.length - 1]
      let lastRenderedElement = await renderElement(child, newParentChain, page)
      let tries = 0
      while (isOverflowing(page) && !lastRenderedElement.getAttribute('data-unbreakable') && tries < 100) {
        const { newChunk, newPage } = breakPage(lastRenderedElement, newParentChain, page)
        lastRenderedElement = newChunk
        pages.push(newPage)
        page = newPage
        tries++
      }
    }
  }
}

async function renderGrid(gridItem, parentChain, pages) {
  const page = pages[pages.length - 1]
  const renderedGrid = await renderElement(gridItem, parentChain, page)
  if (isOverflowing(page)) {
    const oldParent = renderedGrid.parentElement
    const { newPage } = breakPage(renderedGrid, parentChain, page)
    removeEmptyParents(oldParent)
    moveBreakAfterAvoids(page, newPage)
    pages.push(newPage)
  }
}

export function breakPage(brokenElement, parentChain, page) {
  const newPage = createPage()
  const renderedParent = renderParentChain(parentChain, newPage, page)
  if (isOverflowing(newPage)) {
    let lastParent = renderedParent
    while (lastParent && !lastParent.classList.contains('page-content')) {
      lastParent.style['height'] = 'unset'
      lastParent = lastParent.parentElement
    }
  }

  let newChunk = brokenElement

  if (isPrintedTag(brokenElement) || isGrid(brokenElement)) {
    brokenElement.remove()
    renderedParent.appendChild(newChunk)
    if (isOverflowing(newPage)) {
      let lastParent = renderedParent
      while (!lastParent.classList.contains('page-content')) {
        lastParent.style.setProperty('height', 'unset', 'important')
        lastParent.style.setProperty('width', 'unset', 'important')
        lastParent.style.setProperty('margin', '0px', 'important')
        lastParent.style.setProperty('padding', '0px', 'important')
        lastParent = lastParent.parentElement
      }
      brokenElement.style['max-width'] = '100%'
      brokenElement.style['max-height'] = `${pageHeight(newPage) - 5}px`
      brokenElement.style['position'] = 'block'
      brokenElement.setAttribute('data-unbreakable', 'true')
    } else {
      moveBreakAfterAvoids(page, newPage)
    }
  } else {
    newChunk = brokenElement.cloneNode()
    // remove childNodes one word at a time
    // take removed children and render on next page
    Array.from(brokenElement.childNodes)
      .reverse()
      .every((node) => {
        return breakUpNode(node, newChunk, page)
      })
    renderedParent.appendChild(newChunk)
  }

  return { newChunk, newPage }
}

function breakUpNode(node, newChunk, page) {
  if (node.childNodes.length > 0) {
    const newNode = node.cloneNode()
    newChunk.prepend(newNode)
    Array.from(node.childNodes)
      .reverse()
      .every((node) => {
        return breakUpNode(node, newNode, page)
      })
  } else if (node.nodeName === '#text') {
    splitText(node, newChunk, page)
  } else {
    node.remove()
    newChunk.prepend(node)
  }
  return isOverflowing(page)
}

let PRINT_ID = 1
function getDataRef() {
  return `ele-${PRINT_ID++}`
}
