<template>
  <CommentEditor :comment="comment" :is-returned="isReturned" @update="updateComment" @delete="deleteComment" />
</template>

<script>
import { computed } from 'vue'
import { useGradingStore } from '../composables/use_grading_store'
import CommentEditor from './CommentEditor'

export default {
  name: 'ResponseCommentEditor',
  components: { CommentEditor },
  props: {
    response: { type: Object, default: () => {} },
  },
  setup(props) {
    const { currentSession, updateResponseComment, deleteResponseComment, storeUpdatedResponse } = useGradingStore()

    const comment = computed(() => props.response?.comment)
    const isReturned = computed(() => currentSession.value.assignmentState === 'returned')
    const updateComment = async (value, publish, done) => {
      const session = currentSession.value
      const response = props.response

      try {
        await updateResponseComment(session, response, value, publish)
        done()
      } catch (e) {
        storeUpdatedResponse(session, response)
        done(false)
      }
    }
    const deleteComment = async (id, done) => {
      try {
        await deleteResponseComment(currentSession.value, props.response, id)
        done()
      } catch (e) {
        storeUpdatedResponse(currentSession.value, props.response)
        done(false)
      }
    }

    return { comment, isReturned, updateComment, deleteComment }
  },
}
</script>
