<template>
  <div>
    <div v-if="errors.server" class="error error--page" data-test="error-page">
      <h2>{{ $t('errors.common.page_error_heading') }}</h2>
      <p>{{ $t('errors.common.page_error_generic') }}</p>
    </div>

    <UserInfo :logo-image="logoImage" :has-sis-sync="hasSisSync" :logout-return="logoutReturn" />

    <UserGoogleClassroomLink v-if="showGoogleClassroomLink" />

    <div key="edit-actions">
      <div class="mx-auto mt-6 max-w-7xl px-3 text-right md:px-6">
        <button name="save_profile" class="btn btn--primary mb-6" @click="next">
          {{ $t('common.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import location from 'utils/location'
import UserInfo from './UserInfo'
import UserGoogleClassroomLink from './UserGoogleClassroomLink'
import { useUsersEditStore } from '../store/use_users_edit_store'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { computed, ref, watch, nextTick } from 'vue'

defineProps({
  logoImage: {
    type: String,
    required: true,
  },
  hasSisSync: {
    type: Boolean,
    required: true,
  },
  logoutReturn: {
    type: String,
    required: true,
  },
})

const { redirect, errors, clearErrors, update, validate } = useUsersEditStore()
const { authorizedAsTeacher } = useCurrentUserStore()
const { googleClassroomEnabled } = useCurrentCustomerStore()

const pendingValidations = ref(0)

const showGoogleClassroomLink = computed(() => googleClassroomEnabled.value && authorizedAsTeacher.value)

watch(redirect, (path, nothing) => {
  if (path != nothing) location.assign(path)
})

const next = () => {
  clearErrors()
  pendingValidations.value++
  validate()
  validated()
}

const validated = () => {
  pendingValidations.value--
  if (pendingValidations.value === 0) isEmpty(errors.value) ? update() : error()
}

const error = () => {
  nextTick(() => {
    const firstErroredField = document.querySelector('.error')
    if (firstErroredField)
      window.scrollTo({
        top: firstErroredField.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
  })
}
</script>
