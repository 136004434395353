<template>
  <button
    v-if="showButton"
    type="button"
    data-test="assign-materials-button"
    class="flex items-center gap-x-2"
    :disabled="disabled"
    @click="launch"
  >
    <LzIcon :size="iconSize" path="icons/plus" />
    {{ $t('assign') }}
  </button>
</template>

<script>
import { computed } from 'vue'
import { redirectToLogin } from 'utils'
import { LzAnalytics } from 'clients'
import { LzIcon } from 'vue_features/shared/components/ui'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'AssignmentButton',
  components: { LzIcon },
  props: {
    iconSize: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const { isLoggedIn, authorizedAsTeacher } = useCurrentUserStore()
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    const { isActive, analyticsProperties } = useLessonPlanStore()

    const showButton = computed(() => isActive.value && hasStudentFunctionality.value && authorizedAsTeacher.value)
    const launch = () => {
      const eventName = 'Assignment Button Clicked'
      const eventProperties = {
        ...(analyticsProperties.value || {}),
        action: eventName,
        label: isLoggedIn.value ? 'authenticated' : 'unauthenticated',
        category: 'assignments',
      }
      LzAnalytics.track(eventName, eventProperties).finally(() => redirectToLogin(isLoggedIn.value, 'assign'))
      if (isLoggedIn.value) openModal('AssignmentModal')
    }

    return { showButton, launch }
  },
}
</script>
