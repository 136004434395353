<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div ref="cardWrapperEl" :style="cardWrapperStyle" class="slide-editor">
    <div ref="cardContentEl" :style="cardContentStyle">
      <div class="slide__container ck-editable">
        <div ref="slide" :class="['slide bg-white', `font-${textStyle}`]">
          <div v-if="isIndicatorActive" class="indicator" :style="indicatorStyle">
            <div id="indicator-box" />
          </div>
          <div
            v-if="contents.length === 0"
            :class="[
              'slide__content slide__content--vcenter slide__content--hcenter slide__drop',
              'slide__content--blank slide__fill hover:bg-neutral-50',
            ]"
          >
            <InlineSvg path="misc/drag-here" />
            <p class="h2 center text-accent">{{ $t('slides.editor_templates.drag_help_text') }}</p>
          </div>
          <div
            v-for="content in contents"
            v-else
            :key="content.id"
            :style="gridStyle(content)"
            :class="[...contentClasses(content), { 'slide__content--selected': content === currentSelection }]"
            :data-grid="gridData(content)"
            @click="$emit('select', content)"
            @dblclick="$emit('edit', content)"
          >
            <DragOverlay
              v-if="isDraggable(content)"
              @mount="$emit('mount', { element: $event, content })"
              @unmount="$emit('unmount', { element: $event, content })"
            />
            <component
              :is="`SlideEditor${capitalize(content.type)}`"
              :content="content"
              @update="$emit('update', { content, updates: $event })"
            />
          </div>
          <template v-for="gutter in gutters">
            <div
              v-if="gutter.showRight"
              :key="`${gutter.content.id}-right`"
              :style="gutter.rightStyle"
              class="resize-gutter resize-gutter--right"
              @mousedown="startResize(gutter, 'right', $event)"
            />
            <div
              v-if="gutter.showBottom"
              :key="`${gutter.content.id}-bottom`"
              :style="gutter.bottomStyle"
              class="resize-gutter resize-gutter--bottom"
              @mousedown="startResize(gutter, 'bottom', $event)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { capitalize } from 'lodash'
import * as editorComponents from './editor_components'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import useContentParsers from '../composables/use_content_parsers'
import useCardScaling from 'vue_features/resources/shared/composables/use_card_scaling'

export default {
  name: 'SlideEditor',
  components: { InlineSvg, ...editorComponents },
  props: {
    contents: {
      type: Array,
      required: true,
    },
    currentSelection: {
      type: Object,
      default: null,
    },
    editedSelection: {
      type: Object,
      default: null,
    },
    textStyle: {
      type: String,
      required: true,
    },
    isIndicatorActive: {
      type: Boolean,
      required: true,
    },
    indicatorStyle: {
      type: Object,
      required: true,
    },
    gutters: {
      type: Array,
      required: true,
    },
    startResize: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const cardWrapperEl = ref(null)
    const cardContentEl = ref(null)
    const { gridStyle, contentClasses } = useContentParsers()
    const { cardWrapperStyle, cardContentStyle } = useCardScaling(cardWrapperEl, cardContentEl)
    const gridData = (content) => {
      const { row, column, rowSpan, colSpan } = content
      return JSON.stringify({ row, column, rowSpan, colSpan })
    }
    const isDraggable = (content) =>
      props.editedSelection !== content && (content.type !== 'table' || !content.isCreating)

    return {
      capitalize,
      cardWrapperEl,
      cardContentEl,
      gridStyle,
      contentClasses,
      cardWrapperStyle,
      cardContentStyle,
      gridData,
      isDraggable,
    }
  },
}
</script>
