<template>
  <div id="lesson" ref="containerEl" class="active lesson-plan__lesson">
    <CardEditorModal v-if="canModalShow('CardEditorModal')" />
    <CardDeck
      v-if="slideshowCards.length > 0"
      v-bind="{
        cards: slideshowCards,
        showNotes: true,
        deckId: 'editor-deck',
        activeDeckId: 'editor-deck',
        isEnabled: !isEditingCard,
        selectedCardId,
      }"
      class="lp-deck"
      @current-card-change="changeCard"
    >
      <template #header="{ currentPosition, totalCount, setCurrentPosition }">
        <CardDeckEditorHeader v-bind="{ currentPosition, totalCount }" @set-current-position="setCurrentPosition" />
      </template>
      <template #footer="{ currentPosition, isEnabled, totalCount, goNext, goPrevious }">
        <CardDeckFooter
          v-bind="{ currentPosition, isEnabled, totalCount }"
          @go-next="goNext"
          @go-previous="goPrevious"
        />
      </template>
    </CardDeck>
    <CardDeckBlankSlate v-else />
  </div>
</template>

<script setup>
import CardDeck from 'vue_features/assignments/shared/card_deck/CardDeck'
import CardDeckFooter from 'vue_features/assignments/shared/card_deck/CardDeckFooter'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { ref, provide, inject, onDeactivated } from 'vue'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import CardEditorModal from './CardEditorModal'
import CardDeckEditorHeader from './CardDeckEditorHeader'
import CardDeckBlankSlate from './CardDeckBlankSlate'
import { canModalShow } from 'vue_features/shared/composables/use_global_modals'
import useCardUrlParam from 'vue_features/lesson_plans/show/composables/use_card_url_param'
import { useRoute, useRouter } from 'vue-router'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'

const root = inject('useRoot')()
const { lessonPlanTextStyle } = useLessonPlanStore()
provide('fontTextStyle', lessonPlanTextStyle)

const { slideshowCards, isEditingCard, selectedCardId, createBeginLessonCard } = useCardDeckEditorStore()
createBeginLessonCard(root)

const { initialCardId, changeCard } = useCardUrlParam({
  cards: slideshowCards,
  route: useRoute(),
  router: useRouter(),
  callback: (cardId) => (selectedCardId.value = cardId),
})

if (initialCardId) {
  selectedCardId.value = initialCardId
}

const containerEl = ref(null)
provide('fullscreenContainerEl', containerEl)

onDeactivated(useLearnosityClientStore().resetAll)
</script>
