<template>
  <div class="comments mx-auto mb-12 max-w-7xl px-3 py-6 print:hidden md:px-6">
    <h2>{{ $t('components.lz_comments.comments_header') }}</h2>

    <CommentForm :commentable-path="commentablePath" @new-comment="newComment" />

    <p v-if="loading" class="text-center"><LoadingSpinner inline /> {{ $t('common.loading') }}</p>
    <div v-for="comment in comments" v-else :key="comment.id" class="lzui-tile mb-12">
      <div class="comment">
        <Comment v-bind="comment" :top-level="true" @update-comment="updateComment" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import Comment from './commentable/Comment'
import CommentForm from './commentable/CommentForm'
import CommentableService from './commentable/api'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'

export default {
  name: 'Commentable',

  components: { Comment, CommentForm, LoadingSpinner },

  props: {
    commentablePath: { type: String, required: true },
  },

  setup(props) {
    const comments = ref([])
    const loading = ref(true)

    const fetchComments = () => {
      CommentableService.fetchComments(props.commentablePath).then((response) => {
        loading.value = false
        comments.value = response.comments
      })
    }
    const updateComment = (params) => {
      const comment = comments.value.find((comment) => comment.id == params.id)
      Object.assign(comment, params)
    }
    const newComment = (comment) => {
      comments.value.unshift(comment)
    }

    fetchComments()

    return { comments, loading, fetchComments, updateComment, newComment }
  },
}
</script>
