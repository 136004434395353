<template>
  <div
    :class="{
      hidden,
      'comment__first-level mb-4': topLevel && !id,
      'comment__second-level': !topLevel && !id,
    }"
  >
    <form
      data-test="comment-form"
      class="form-group grid grid-cols-12 gap-x-2.5 md:gap-x-3.5"
      :class="{ edit: !!id, new: !id, 'ml-12 px-2.5 py-4 md:ml-16 md:px-3.5': !topLevel }"
      @submit.prevent="submitComment"
    >
      <div class="col-span-9 space-y-4 rounded-lg lg:col-span-10">
        <textarea
          id="comment-content"
          v-model="newContent"
          :aria-label="$t('components.lz_comments.comment_placeholder')"
          class="form-control mb-0 resize rounded-lg py-4"
          :placeholder="$t('components.lz_comments.comment_placeholder')"
          rows="1"
          @keydown.ctrl.enter="submitComment"
          @keydown.meta.enter="submitComment"
        />

        <div class="flex justify-between">
          <FilestackUpload
            v-slot="{ launch, fileUrl, fileExists, filename }"
            :file="newFile"
            :accept="$options.accept"
            :max-size="$options.maxSize"
            @upload-done="updateFile"
          >
            <span v-if="fileExists">
              <LzButton type="button" demoted size="small" class="mr-0 p-0" @click="removeFile">{{
                $t('components.lz_comments.remove_attached_file')
              }}</LzButton>
              (<a class="small mr-0 truncate p-0" :href="fileUrl" target="_blank">{{
                filename || $t('components.comments.comment.attached_file')
                /* FIXME: Disable this eslint rule globally because it conflicts
                  with prettier */
                /* eslint-disable-next-line vue/html-closing-bracket-newline */
              }}</a
              >)
            </span>

            <LzButton
              v-else
              type="button"
              data-test="attach-file-button"
              demoted
              size="small"
              class="mr-4 p-0"
              @click="launch"
            >
              {{ $t('components.lz_comments.attach_file') }}
            </LzButton>
          </FilestackUpload>

          <div v-if="error" class="red">{{ $t('components.lz_comments.submission_error') }}</div>
        </div>
      </div>

      <div v-if="!id" class="col-span-3 lg:col-span-2">
        <LzButton
          v-if="topLevel"
          data-test="add-comment"
          color="green"
          size="small"
          class="w-full"
          :disabled="requestActive"
          @click="submitComment"
        >
          {{ $t('components.lz_comments.add_comment') }}
        </LzButton>

        <LzButton
          v-else
          data-test="submit-reply"
          color="primary"
          size="small"
          class="w-full"
          :disabled="requestActive"
          @click="submitComment"
        >
          {{ $t('components.lz_comments.submit_reply') }}
        </LzButton>
      </div>
      <div v-else class="col-span-3 lg:col-span-2">
        <LzButton
          data-test="update"
          color="primary"
          size="small"
          class="w-full"
          :disabled="requestActive"
          @click="submitComment"
        >
          {{ $t('common.update') }}
        </LzButton>

        <LzButton data-test="cancel" type="button" demoted size="small" class="w-full" @click="$emit('cancel-edit')">
          {{ $t('common.cancel') }}
        </LzButton>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'

import CommentableService from '../commentable/api'
import FilestackUpload from 'vue_features/shared/components/filestack_upload/FilestackUpload'
import { LzButton } from 'vue_features/shared/components/ui'

import { config } from 'utils'
const { accept, maxSize } = config.file

export default {
  name: 'CommentForm',

  components: { FilestackUpload, LzButton },

  accept,
  maxSize,

  props: {
    id: { type: Number, required: false, default: null },
    commentablePath: { type: String, required: true },
    file: { type: Object, default: () => {} },
    topLevel: { type: Boolean, default: true },
    content: { type: String, default: '' },
    hidden: { type: Boolean, default: false },
  },

  setup(props, { emit }) {
    const newContent = ref(props.content)
    const newFile = ref({ ...props.file })
    const requestActive = ref(false)
    const error = ref(false)

    const isNewComment = props.id == null
    const serviceMethod = isNewComment ? CommentableService.createComment : CommentableService.updateComment

    const submitComment = () => {
      requestActive.value = true
      error.value = false

      serviceMethod(props.commentablePath, {
        id: props.id,
        content: newContent.value,
        source_file_url: newFile.value?.baseUrl || null,
        source_filename: newFile.value?.filename || null,
      })
        .then((response) => {
          requestActive.value = false

          newContent.value = props.content
          newFile.value = props.file
          emit(isNewComment ? 'new-comment' : 'update-comment', response)
        })
        .catch((response) => {
          requestActive.value = false
          error.value = true
        })
    }
    const updateFile = (file) => {
      newFile.value = {
        baseUrl: file.baseUrl,
        filename: file.metadata.filename,
        filestackOptions: { ...file.filestackOptions },
      }
    }
    const removeFile = () => {
      newFile.value = {}
    }

    return { newContent, newFile, requestActive, error, submitComment, updateFile, removeFile }
  },
}
</script>
