<!-- FIXME: Replace with @morev/vue-transitions when node is upgraded -->
<script setup>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
</script>

<template>
  <CollapseTransition easing="ease">
    <slot />
  </CollapseTransition>
</template>
