<template>
  <section class="border-neutral-200 flex flex-row gap-4 overflow-hidden rounded-xl border-2 border-solid p-3">
    <div class="flex flex-grow items-center gap-3">
      <LzIcon :path="icon" size="lg" />
      <h5 class="flex-grow text-lg">{{ title }}</h5>
    </div>
    <slot />
  </section>
</template>

<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'

defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
})
</script>
