<template>
  <BaseDropdown
    v-slot="{ id, fullButtonClasses, isOpen, fullDropdownClasses, toggle, close }"
    v-bind="$props"
    @dropdown-toggled="$emit('dropdown-toggled', $event)"
  >
    <component :is="tag" class="vue-dropdown">
      <template v-if="hasTriggerSlot">
        <slot v-bind="{ buttonClasses, id, toggle, isOpen }" name="trigger" />
      </template>
      <button
        v-else
        :data-toggle="id"
        :aria-expanded="`${isOpen}`"
        :class="fullButtonClasses"
        :aria-label="label"
        @click.prevent.stop="toggle"
      >
        <slot name="button" />
      </button>
      <div v-if="showList(isOpen)" :id="id" :class="fullDropdownClasses">
        <slot :close="close" name="divContent" />
      </div>
    </component>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from './BaseDropdown'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

export default {
  name: 'DivDropdown',
  components: { BaseDropdown },
  props: {
    ...BaseDropdown.props,
    lazyList: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'span',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTriggerSlot() {
      return isSlotUsed('trigger')
    },
  },
  methods: {
    ...BaseDropdown.methods,
    showList(isOpen) {
      return !this.lazyList || isOpen
    },
  },
}
</script>
