<template>
  <div>
    <SearchBox
      v-model="tempQuery"
      :placeholder="$t('action_menu.merge_search_placeholder')"
      class="mb-4"
      @submit="changeQuery"
    />
    <SearchResults
      v-bind="{
        showHeader: false,
        hasActiveSearch,
        canLoadMore,
        isLoadingSearchResults,
        isLoading,
        searchResults,
        visibleItems,
      }"
      @load-more="handleLoadMore"
    >
      <template #search-results>
        <LessonPlanSearchResult
          v-for="lessonPlan in visibleItems"
          v-bind="{ lessonPlan, mergedLessonPlanIds, canCopyAssessments }"
          :key="lessonPlan.id"
          @merge="addMerge"
        />
      </template>
    </SearchResults>
  </div>
</template>

<script>
import { computed } from 'vue'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import URI from 'urijs'
import { location as $location } from 'utils'
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import { LESSON_TYPES } from 'vue_features/lesson_plans/components/InfoForm'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import LessonPlanSearchResult from './LessonPlanSearchResult'
import SearchBox from 'vue_features/shared/components/search/SearchBox'
import SearchResults from 'vue_features/shared/components/search/SearchResults'
import Routes from 'routes'

export default {
  name: 'LessonPlanSearch',
  components: { SearchResults, SearchBox, LessonPlanSearchResult },
  props: {
    mergedLessonPlanIds: { type: Array, required: true },
    canCopyAssessments: { type: Boolean, required: true },
  },
  setup() {
    const {
      tempQuery,
      search,
      searchPage,
      searchResults,
      resetSearch,
      modalFilters,
      isLoadingSearchResults,
      totalSearchCount,
      hasActiveSearch,
    } = useModalSearch()
    modalFilters.m = 'LessonPlan'
    modalFilters.cards = true

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: searchResults,
      totalCount: totalSearchCount,
      startCount: 5,
      moreCount: 5,
    })

    const idRegex = /lesson_plans\/(\d+)/

    const isSearchingId = computed(() => {
      return tempQuery.value.match(/^\d+$/)
    })
    const isSearchingUrl = computed(() => {
      const currentDomain = new URI($location.current()).domain()
      return !!(tempQuery.value.match(idRegex) && tempQuery.value.includes(currentDomain))
    })
    const parsedId = computed(() => {
      const match = tempQuery.value.match(idRegex)
      if (match) {
        return match[0].split('/')[1]
      }
      return null
    })
    const searchedId = computed(() => {
      return isSearchingId.value ? tempQuery.value : parsedId.value
    })

    return {
      totalSearchCount,
      searchResults,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      hasActiveSearch,
      tempQuery,
      search,
      searchPage,
      resetSearch,
      isLoadingSearchResults,
      searchedId,
      isSearchingUrl,
      isSearchingId,
    }
  },
  computed: {
    lessonTypes() {
      const values = LESSON_TYPES.map((lt) => lt.value)
      if (!this.canCopyAssessments) {
        values.splice(values.indexOf('assessment'), 1)
      }
      return values
    },
  },
  methods: {
    changeQuery() {
      if (this.tempQuery) {
        this.resetSearch()
        if (this.isSearchingId || this.isSearchingUrl) {
          HTTP.getJSON(Routes.lesson_plan_path(this.searchedId))
            .then((response) => {
              this.isLoadingSearchResults = false
              this.searchResults = [response]
            })
            .catch(() => (this.isLoadingSearchResults = false))
        } else {
          this.search()
        }
      }
    },
    handleLoadMore() {
      this.showMoreItems(this.search.bind(this))
    },
    canMerge(plan) {
      return this.canCopyAssessments || plan.lessonType !== 'assessment'
    },
    addMerge(plan) {
      if (this.canMerge(plan)) {
        this.$emit('toggle', plan)
      }
    },
  },
}
</script>
