<template>
  <div
    class="feedback-prompt text-primary z-100 fixed flex -rotate-90 transform items-center space-x-4 rounded-t-xl bg-white bg-opacity-60 px-4 py-2 shadow-lg transition-all hover:bg-opacity-100"
  >
    <button class="space-x-1" @click="$emit('show-feedback')">
      <LzIcon size="md" path="icons/chat" />
      <span>Feedback</span>
    </button>
  </div>
</template>

<script>
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'FeedbackPrompt',
  components: {
    LzIcon,
  },
}
</script>

<style scoped>
.feedback-prompt {
  right: -40px;
  top: 50vh;
}
</style>
