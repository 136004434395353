<template>
  <LzModal auto :modal-title="$t('lz_content_modal.title')" modal-id="lz-content-modal" class="lzui-modal--large">
    <section>
      <Alert
        v-if="hasErrors"
        :title="$t('lz_content_modal.error_heading')"
        :description="errorDescription"
        dismissible
        type="error"
      />

      <component :is="currentDisplay" v-bind="{ invalidIds }" @manage-content="manageContent" />
    </section>
    <footer class="border-base flex flex-row-reverse justify-between border-t">
      <div>
        <button
          v-if="isChoosingContent"
          class="btn"
          data-test="content-modal-close-btn"
          @click.prevent="closeModal('lz-content-modal')"
        >
          {{ $t('common.cancel') }}
        </button>
        <button v-else data-test="back-btn" class="btn" @click.prevent="isChoosingContent = true">
          {{ $t('common.back') }}
        </button>
        <button
          :class="['btn btn--primary', { disabled: !canMoveToNextStep || isProcessing }]"
          :disabled="!canMoveToNextStep || isProcessing"
          data-test="next-step-btn"
          @click.prevent="moveToNextStep"
        >
          {{ $t('lz_content_modal.insert_label') }}
        </button>
      </div>
      <div v-if="isChoosingContent">
        <button
          v-if="isSearching"
          :disabled="items.length <= 0"
          data-test="manage-content-btn"
          class="btn btn--demoted btn--small my-2 ml-0 p-0"
          @click.prevent="manageContent"
        >
          {{ items.length }}
          {{ $t('lz_content_modal.items_selected') }}
        </button>
        <button
          v-else
          :disabled="items.length <= 0"
          data-test="manage-content-btn"
          class="btn btn--demoted btn--small my-2 ml-0 p-0"
          @click.prevent="manageContent"
        >
          {{ $t('lz_content_modal.manage') }}
        </button>
      </div>
    </footer>
  </LzModal>
</template>

<script>
import { computed, ref, inject } from 'vue'
import { capitalize } from 'lodash'
import LzModal from 'vue_features/shared/components/ui/LzModal'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import Alert from 'vue_features/shared/components/ui/Alert'
import ChooseDisplay from './ChooseDisplay'
import PreviewDisplay from './PreviewDisplay'
import ResourcesService from 'vue_features/resources/api'
import LessonPlanService from 'vue_features/lesson_plans/api/lesson_plans_service'
import {
  initLzContentModalStore,
  useLzContentModalStore,
} from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'
import { toParams } from 'vue_features/resources/shared/composables/use_resource_persistable'

export default {
  name: 'LzContentModal',
  components: { Alert, LzModal, ChooseDisplay, PreviewDisplay },
  props: { contentType: { type: String, required: true }, onSave: { type: Function, required: true } },
  setup(props) {
    const root = inject('useRoot')()
    const errors = ref([])
    const isProcessing = ref(false)
    const hasErrors = computed(() => errors.value.length > 0)
    const errorDescription = computed(() => {
      if (errors.value.includes('previewError')) {
        return root.$t('lz_content_modal.error_preview')
      }
      return errors.value
    })
    initLzContentModalStore(props.contentType)
    const { currentTab, items, previews, viewType } = useLzContentModalStore()

    const isChoosingContent = ref(true)
    const invalidIds = ref([])
    const currentDisplay = computed(() => (isChoosingContent.value ? ChooseDisplay : PreviewDisplay))
    const isSearching = computed(() => currentTab.value === 'choose-search' && isChoosingContent.value)

    const manageContent = async () => {
      await getPreviewItems()
      if (!hasErrors.value) {
        isChoosingContent.value = false
      }
    }

    const canMoveToNextStep = computed(
      () =>
        (isChoosingContent.value && items.value.length > 0) || (!isChoosingContent.value && previews.value.length > 0),
    )

    const moveToNextStep = async () => {
      if (!isChoosingContent.value) {
        isProcessing.value = true
        save()
      } else if (items.value.length > 0) {
        isProcessing.value = true
        await getPreviewItems()
        if (!hasErrors.value) {
          if (invalidIds.value.length > 0) {
            isChoosingContent.value = false
            isProcessing.value = false
          } else {
            save()
          }
        } else {
          isProcessing.value = false
        }
      }
    }
    const save = () => {
      props.onSave(previews.value, viewType.value)
      closeModal('lz-content-modal')
      isProcessing.value = false
    }

    const saveContent = async () => {
      try {
        const payload = items.value[0]
        payload.coverImage = payload.coverImage?.baseUrl
        const savedContent =
          props.contentType === 'LessonPlan'
            ? await LessonPlanService.createLessonPlan(payload)
            : await ResourcesService.create(props.contentType, toParams(payload))
        items.value = [savedContent]
      } catch (e) {
        const errorResponse = e.responseJSON
        if (errorResponse && Array.isArray(errorResponse)) {
          errors.value = e.responseJSON
        } else if (errorResponse) {
          errors.value = Object.keys(errorResponse).flatMap((key) =>
            errorResponse[key].map((e) => `${capitalize(key)} ${e}`),
          )
        } else {
          errors.value = [root.$t('lz_content_modal.error_invalid')]
        }
      }
    }

    const getPreviewItems = async () => {
      errors.value = []
      if (currentTab.value === 'choose-new') {
        await saveContent()
      }
      const type = props.contentType === 'LessonPlan' ? 'lesson plan' : 'resource'
      try {
        const ids = items.value.map((i) => i.id)
        const result = await ResourcesService.embed(type, ids)
        previews.value = result.items.sort((a, b) => ids.indexOf(`${a.id}`) - ids.indexOf(`${b.id}`))
        invalidIds.value = result.invalid_ids
      } catch {
        errors.value = ['previewError']
      }
    }

    return {
      errors,
      hasErrors,
      errorDescription,
      closeModal,
      currentDisplay,
      items,
      previews,
      isChoosingContent,
      manageContent,
      moveToNextStep,
      isSearching,
      invalidIds,
      currentTab,
      canMoveToNextStep,
      isProcessing,
    }
  },
}
</script>
