<template>
  <LzModal
    :modal-title="$t('lesson_plans.reorder_dialog.title', { lesson_type: lessonPlan.readableType })"
    modal-id="reorder-modal"
    class="lzui-modal--medium"
    :autoclose="false"
    @close-modal="cancel"
  >
    <div class="reorder-modal">
      <draggable
        id="outline-sortable"
        v-model="reorderedCards"
        item-key="id"
        class="lp-deck__outline"
        tag="ol"
        handle=".handle"
        ghost-class="outline-placeholder"
        drag-class="ui-sortable-helper"
        @start="rememberOrder"
        @end="updateRank"
      >
        <template #item="{ element: card }">
          <li
            :class="{
              'lp-outline__title': isTitleCard(card),
              handle: isEditable(card),
              disabled: !isEditable(card),
            }"
          >
            <LzIcon path="icons/move-grip" />
            <span>{{ card.title }}</span>
          </li>
        </template>
      </draggable>
      <footer>
        <p class="align-right mb-0">
          <button class="btn" @click="cancel">{{ $t('common.cancel') }}</button>
          <LoadingSpinner v-if="isSaving" :inline="true" />
          <button v-else data-test="save-reorder-btn" class="btn btn--primary ml-2" name="save-changes" @click="save">
            {{ $t('common.save') }}
          </button>
        </p>
      </footer>
    </div>
  </LzModal>
</template>

<script>
import { ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import { LzModal, LzIcon } from 'vue_features/shared/components/ui'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import { closeAllModals } from 'vue_features/shared/composables/use_global_modals'
import draggable from 'vuedraggable'
import { getInsertRank } from '/utils/card_rank_helpers'
import CardsService from '../api/cards_service'
import CardEditsService from '../api/card_edits_service'
import useCardActiveEdit from '../composables/use_card_active_edit'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'

export default {
  name: 'CardReorderModal',
  components: { LoadingSpinner, LzIcon, LzModal, draggable },
  setup() {
    const { isCreatingMetaEdits } = useLessonPlanEditStore()
    const { cards } = useCardDeckEditorStore()
    const { lessonPlan } = useLessonPlanStore()
    const { resetAll: resetLearnosityState } = useLearnosityClientStore()
    const isSaving = ref(false)

    const reorderedCards = ref(cards.value.map((c) => cloneDeep(c)))
    let oldOrder = []

    const rememberOrder = () => (oldOrder = reorderedCards.value)
    const updateRank = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const index = oldIndex > newIndex ? newIndex : newIndex + 1
        reorderedCards.value[newIndex].rank = getInsertRank(index, oldOrder)
        if (isCreatingMetaEdits.value) {
          updateSurroundingIds(newIndex)
        }
      }
    }

    const updateSurroundingIds = (newIndex) => {
      if (newIndex > 0) {
        reorderedCards.value[newIndex].activeEdit.prevCardId = reorderedCards.value[newIndex - 1].id
      } else {
        reorderedCards.value[newIndex].activeEdit.prevCardId = null
      }

      if (newIndex < reorderedCards.value.length - 1) {
        reorderedCards.value[newIndex].activeEdit.nextCardId = reorderedCards.value[newIndex + 1].id
      } else {
        reorderedCards.value[newIndex].activeEdit.nextCardId = null
      }
    }

    const api = computed(() => (isCreatingMetaEdits.value ? CardEditsService : CardsService))

    const save = async () => {
      isSaving.value = true

      const editableCards = reorderedCards.value.filter((c) => isEditable(c))
      if (editableCards.length > 0) {
        await api.value.reorder(lessonPlan.value.id, editableCards)
        resetLearnosityState()
        cards.value = reorderedCards.value
      }

      isSaving.value = false
      closeAllModals()
    }
    const cancel = () => {
      closeAllModals()
    }

    const isEditable = (card) => useCardActiveEdit({ value: card.activeEdit }).isEditable.value

    return { isSaving, lessonPlan, reorderedCards, cancel, save, updateRank, rememberOrder, isEditable, isTitleCard }
  },
}
</script>
