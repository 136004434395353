import { onMounted, onBeforeUnmount, ref, computed, watch, watchEffect } from 'vue'
import { useLessonPlanShowStore } from 'vue_features/lesson_plans/show/store/use_lesson_plan_show_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { LzAnalytics } from 'clients'
import { useKeyboardNavigation } from 'vue_features/assignments/shared/card_deck/use_shortcut_navigation'
import usePresenter from 'vue_features/lesson_plans/show/composables/use_presenter'
import { throttle, debounce } from 'lodash'
import useIntersectionObserver from '../components/use_intersection_observer'
import useCardUrlParam from 'vue_features/lesson_plans/show/composables/use_card_url_param'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { useViewportSize } from 'vue_features/shared/composables'
import useEventListener from 'vue_features/shared/composables/use_event_listener'

const TOOLBAR_HEIGHT = 80
const BODY_THRESHOLD = 500

function useLessonPlayer() {
  const { isStudent } = useCurrentUserStore()
  const { analyticsProperties } = useLessonPlanStore()
  const { isPresenting } = useLessonPlanShowStore()

  const selectedCardIndex = ref(-1)
  const showSidebar = ref(false)
  const notesActive = ref(true)
  const showToolbar = ref(true)

  const allowNotes = computed(() => !isStudent.value && !isPresenting.value)
  const showNotes = computed(() => notesActive.value && allowNotes.value)

  const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
  }

  const toggleNotes = () => {
    notesActive.value = !notesActive.value
  }

  const toggleToolbar = () => {
    showToolbar.value = !showToolbar.value
  }

  const updateSelectedCardIndex = (idx) => {
    selectedCardIndex.value = idx
  }

  const generateAnalyticsEvent = (name) => {
    return {
      ...analyticsProperties.value,
      action: name,
      label: name,
    }
  }

  return {
    selectedCardIndex,
    showSidebar,
    notesActive,
    toggleSidebar,
    toggleNotes,
    allowNotes,
    showNotes,
    showToolbar,
    toggleToolbar,
    updateSelectedCardIndex,
    trackEvent: (action) => LzAnalytics.track('Lesson Plan Used', generateAnalyticsEvent(action)),
  }
}

function useLessonPlayerIterator({
  rootElRef,
  showToolbarRef,
  currentCardElementRef,
  selectedCardIndexRef,
  cardsRef,
  cardSelected = () => {},
}) {
  const hasPreviousCard = computed(() => {
    return selectedCardIndexRef.value > 0
  })

  const hasNextCard = computed(() => {
    return cardsRef.value.length > selectedCardIndexRef.value + 1
  })

  const { height } = useViewportSize()
  const intraCardScrollThresholdPx = computed(() => {
    return height.value - (showToolbarRef.value ? TOOLBAR_HEIGHT : 0)
  })

  const maxIntraCardScrollPx = computed(() => {
    return height.value * 0.75 - (showToolbarRef.value ? TOOLBAR_HEIGHT : 0)
  })

  const getScrollElement = () => {
    let el = rootElRef.value
    while (el) {
      if (!['visible', 'hidden'].includes(window.getComputedStyle(el).overflow)) {
        return el
      }
      el = el.parentElement
    }

    return document.documentElement
  }

  const goPrevious = () => {
    activateKeyboardNavigation()
    if (!currentCardElementRef.value) return

    const contentAboveViewportPx = Math.floor(
      Math.abs(Math.min(currentCardElementRef.value.getBoundingClientRect().top, 0)),
    )

    if (contentAboveViewportPx > 0) {
      const scrollElement = getScrollElement()
      scrollElement.scrollTop = scrollElement.scrollTop - Math.min(maxIntraCardScrollPx.value, contentAboveViewportPx)
    } else if (hasPreviousCard.value) {
      cardSelected(selectedCardIndexRef.value - 1)
    }
  }

  const goNext = () => {
    activateKeyboardNavigation()
    if (!currentCardElementRef.value) return

    const contentBelowViewportPx = Math.floor(
      Math.max(currentCardElementRef.value.getBoundingClientRect().bottom - intraCardScrollThresholdPx.value, 0),
    )

    if (contentBelowViewportPx > 0) {
      const scrollElement = getScrollElement()
      scrollElement.scrollTop = scrollElement.scrollTop + Math.min(maxIntraCardScrollPx.value, contentBelowViewportPx)
    } else if (hasNextCard.value) {
      cardSelected(selectedCardIndexRef.value + 1)
    }
  }

  const { activate: activateKeyboardNavigation } = useKeyboardNavigation(goPrevious, goNext, {
    next: [40],
    prev: [38],
    preventDefault: true,
  })

  return {
    goNext,
    goPrevious,
    activateKeyboardNavigation,
  }
}

function useLessonPlayerPresenterChannel({ selectedCardIndexRef, cardSelected = () => {}, notesRef }) {
  const { channel } = useLessonPlanShowStore()
  const { lessonPlan } = useLessonPlanStore()

  const emitHandler = (evt, idx) => {
    if (evt === 'set-current-position') {
      cardSelected(idx)
      resetNoteScroll()
    }
  }

  const resetNoteScroll = () => {
    notesRef?.value?.$el?.scrollTo(0, 0)
  }

  watchEffect(() => {
    if (channel.value) {
      channel.value.updatePosition(selectedCardIndexRef.value)
    }
  })

  usePresenter({ lessonPlan, position: selectedCardIndexRef, emit: emitHandler })
}

function useLessonPlayerPresenterIterator({ selectedCardIndexRef, cardsRef, cardSelected = () => {} }) {
  const hasPreviousCard = computed(() => {
    return selectedCardIndexRef.value > 0
  })

  const hasNextCard = computed(() => {
    return cardsRef.value.length > selectedCardIndexRef.value + 1
  })

  const goPrevious = () => {
    if (hasPreviousCard.value) {
      cardSelected(selectedCardIndexRef.value - 1)
    }
  }

  const goNext = () => {
    if (hasNextCard.value) {
      cardSelected(selectedCardIndexRef.value + 1)
    }
  }

  const { activate: activateKeyboardNavigation } = useKeyboardNavigation(goPrevious, goNext, {
    next: [40],
    prev: [38],
    preventDefault: true,
  })

  onMounted(() => {
    activateKeyboardNavigation()
  })

  return {
    goNext,
    goPrevious,
  }
}

function useLessonPlayerScrollManager({
  lessonPlayerElementRef,
  scrollManagerRootRef,
  selectedCardIndexRef,
  isPresenterControl,
  updateSelectedCard = () => {},
  trackEvent = () => {},
}) {
  const cardSelector = '.lesson-card-wrapper'
  const currentCardElement = computed(() => {
    const currentCardElements = cardElements()
    if (!currentCardElements || currentCardElements.length === 0) return null

    return currentCardElements[selectedCardIndexRef.value]
  })

  const cardElements = () => {
    if (scrollManagerRootRef.value) {
      return Array.from(scrollManagerRootRef.value.querySelectorAll(cardSelector))
    }
    return []
  }

  const { preserveCard, preservedCard, scrollToSelectedCard } = useAutomaticScrolling({
    lessonPlayerElementRef,
    selectedCardIndexRef,
    updateSelectedCard,
    cardElements,
    onNextOrPrevious: () => {
      if (!isPresenterControl) trackEvent('Next/Previous')
    },
  })

  const trackEventOnlyIfNoPreservedCard = (evt) => {
    if (preservedCard.value === null) {
      // when changing screens for fullscreen or doing presenter, which will collapse
      // notes, the screen size may "force" scrolling events.  To avoid these being captured
      // as user scrolling, we do NOT want to send this event if we have a "preservedCard",
      // which means we are in the middle of one of these changes, after which we will restore
      // the preserved card
      trackEvent('card scrolled')
    }
  }

  const { connectScrollHandling, setScrollObserving } = useScrollHandling({
    lessonPlayerElementRef,
    scrollManagerRootRef,
    selectedCardIndexRef,
    updateSelectedCard,
    cardScrolled: trackEventOnlyIfNoPreservedCard,
  })

  const { hasScrolledPastThreshold, currentOffsetTop, scrollToTop } = useBackToTop()

  return {
    connectScrollHandling,
    currentOffsetTop,
    hasScrolledPastThreshold,
    scrollToTop,
    scrollToSelectedCard,
    currentCardElement,
    preserveCard,
    setScrollObserving,
  }
}

function useScrollHandling({
  lessonPlayerElementRef,
  scrollManagerRootRef,
  selectedCardIndexRef,
  updateSelectedCard,
  cardScrolled = () => {},
}) {
  let unobserveCards = null
  const observingEnabled = ref(true)

  watchEffect(() => {
    if (lessonPlayerElementRef.value && scrollManagerRootRef.value) connectScrollHandling()
  })

  const connectScrollHandling = () => {
    if (unobserveCards) unobserveCards()

    const { observeCards, unobserveCards: observerUnobserveCards } = useIntersectionObserver(
      onCardScrolledIntoView,
      scrollManagerRootRef,
      null,
    )
    unobserveCards = observerUnobserveCards
    observeCards()
  }

  const onCardScrolledIntoView = (idx) => {
    if (selectedCardIndexRef.value === idx || !observingEnabled.value) return

    updateSelectedCard(idx)
    cardScrolled()
  }

  onBeforeUnmount(() => {
    if (unobserveCards) unobserveCards()
  })

  return {
    connectScrollHandling,
    setScrollObserving: (val) => {
      observingEnabled.value = val
    },
  }
}

function useAutomaticScrolling({
  lessonPlayerElementRef,
  selectedCardIndexRef,
  updateSelectedCard,
  cardElements,
  onNextOrPrevious = () => {},
}) {
  const preservedCard = ref(null)

  const scrollToSelectedCard = (idx, options = { force: false, silenceAnalyticsEvent: false }) => {
    if (idx === null) return
    if (selectedCardIndexRef.value === idx && !options.force) return

    updateSelectedCard(idx)
    scrollToCard(idx)

    if (!options.silenceAnalyticsEvent) {
      onNextOrPrevious()
    }
  }

  const preserveCard = (index = -1) => {
    if (!lessonPlayerElementRef.value) return
    preservedCard.value = index >= 0 ? index : selectedCardIndexRef.value
    resizeObserver.observe(lessonPlayerElementRef.value)

    setTimeout(() => {
      // cleanup in the event no observations occur
      if (preservedCard.value) {
        window.requestAnimationFrame(() => {
          restorePreservedCard()
        })
      }
      preservedCard.value = null
      if (lessonPlayerElementRef.value) {
        resizeObserver.unobserve(lessonPlayerElementRef.value)
      }
    }, 2500)
  }

  const restorePreservedCard = () => {
    scrollToSelectedCard(preservedCard.value, { force: true, silenceAnalyticsEvent: true })
    debouncedRestore()
  }

  const debouncedRestore = debounce(() => {
    preservedCard.value = null
  }, 250)

  const resizeObserver = new ResizeObserver(() => {
    if (preservedCard.value) {
      window.requestAnimationFrame(() => {
        restorePreservedCard()
      })
    }
  })

  const scrollToCard = (idx) => {
    const element = cardElements()[idx]

    if (element) {
      element.scrollIntoView()
      const focusable = element.querySelector('[data-focus-anchor]')
      if (focusable) {
        focusable.focus()
      } else {
        element.focus()
      }
    }
  }

  onBeforeUnmount(() => {
    resizeObserver.disconnect()
  })

  return {
    preserveCard,
    preservedCard,
    scrollToSelectedCard,
  }
}

function useBackToTop() {
  const hasScrolledPastThreshold = ref(document.documentElement.scrollTop >= BODY_THRESHOLD)
  const currentOffsetTop = ref(0)

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0
  }

  const throttledScrollWindow = throttle(() => {
    hasScrolledPastThreshold.value = document.documentElement.scrollTop >= BODY_THRESHOLD
    currentOffsetTop.value = document.documentElement.getBoundingClientRect().top
  }, 250)

  useEventListener('scroll', throttledScrollWindow)

  onMounted(() => {
    currentOffsetTop.value = document.documentElement.getBoundingClientRect().top
  })

  return {
    hasScrolledPastThreshold,
    currentOffsetTop,
    scrollToTop,
  }
}

function useLessonPlayerUrlSupport({ selectedCardIndexRef, cardsRef, onInitialCardIndex = () => {} }) {
  const { channel } = useLessonPlanShowStore()

  const cardPosition = (cardId) => cardsRef.value.findIndex((c) => c.id === cardId)

  const { changeCard, initialCardId } = useCardUrlParam({
    cards: cardsRef,
    callback: (cardId) => {
      if (channel.value) {
        const position = cardPosition(cardId)
        channel.value.updatePosition(position)
      }
    },
  })

  watch(selectedCardIndexRef, () => {
    const selectedCard = cardsRef.value[selectedCardIndexRef.value]

    if (!selectedCard) return
    changeCard(selectedCard.id)
  })

  onMounted(() => {
    if (initialCardId) {
      const position = cardPosition(initialCardId)
      onInitialCardIndex(position)
    }
  })
}

function useLessonPlayerViewManager({
  selectedCardIndexRef,
  trackEvent = () => {},
  cardSelected = () => {},
  preserveCard = () => {},
}) {
  const { toggleFullscreen, isFullscreen } = useFullscreenStore()
  const { isPresenting } = useLessonPlanShowStore()
  const { lessonPlan } = useLessonPlanStore()

  watchEffect(() => {
    if (isFullscreen.value) {
      trackEvent('present')
    }
  })

  watchEffect(() => {
    if (isPresenting.value) {
      trackEvent('presenter view')
    }
  })

  const presenterEmitHandler = (evt, idx) => {
    if (evt === 'set-current-position') {
      cardSelected(idx)
    }
  }

  const openPresenterWindow = () => {
    const { openPresenterWindow } = usePresenter({
      lessonPlan,
      position: selectedCardIndexRef,
      emit: presenterEmitHandler,
    })
    openPresenterWindow()
  }

  const handleToggleFullscreen = ({ onBeforeChange = () => {} } = {}) => {
    toggleFullscreen(
      'lesson-deck',
      (emitName) => {
        if (emitName === 'fullscreen-before-exit' || emitName === 'fullscreen-before-enter') {
          preserveCard()
          onBeforeChange()
        } else if (emitName === 'fullscreen-change') {
          handleFullscreenCleanup()
        }
      },
      document.documentElement,
    )
  }

  const handleFullscreenCleanup = () => {
    // This is a hack to work around the fact that Learnosity's ruler feature does not respond to layout
    // changes.  We reposition the ruler ourselves when we find it after a screen resize
    setTimeout(() => {
      const lrnImageTools = document.querySelectorAll('.lrn-float-element.lrn_imagetool')
      lrnImageTools.forEach((imageTool) => {
        const floatId = imageTool.getAttribute('data-lrn-float')
        if (!floatId) return

        const btn = document.getElementById(floatId)
        if (!btn) return

        const { top } = btn.getBoundingClientRect()
        const newY = document.documentElement.scrollTop + top
        imageTool.style.setProperty('top', `${newY}px`)
      })
    }, 1000)
  }

  return {
    openPresenterWindow,
    handleToggleFullscreen,
  }
}

export {
  useLessonPlayer,
  useLessonPlayerIterator,
  useLessonPlayerPresenterChannel,
  useLessonPlayerPresenterIterator,
  useLessonPlayerScrollManager,
  useLessonPlayerUrlSupport,
  useLessonPlayerViewManager,
}
