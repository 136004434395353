import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const StudentService = {
  update({ student, password, passwordConfirmation }) {
    const params = {
      student: {
        password,
        passwordConfirmation,
      },
    }

    return HTTP.put(Routes.teacher_student_path(student.studentId), snakeKeys(params))
  },
}

export default StudentService
