<template>
  <div v-if="presentActions.length > 0" data-test="action-menu">
    <Portal :disabled="!modalPortal" :to="modalPortal">
      <PublishConfirmModal v-if="actions.canPublish && canModalShow('PublishConfirmModal')" />
      <ProgressCover :visible="isModalBusy" />
      <EmbedCodeModal v-if="canModalShow('EmbedCodeModal')" :embed-url="polymorphicUrl(object)" />

      <DeleteObjectModal
        v-if="canModalShow('DeleteObjectModal')"
        :deletion-path="deletionPath"
        :object-name="objectName"
      />

      <StartRevisionModal
        v-if="canModalShow('StartRevisionModal')"
        :object-id="object.id"
        :object-is-resource="objectIsResource"
        :object-name="objectName"
      />
      <CopyObjectModal v-if="canModalShow('CopyObjectModal')" v-bind="{ original: object, copiesData }" />
      <PrintDialog v-if="canModalShow('PrintDialog')" :item-id="object.id" @close="finishPrintDialog" />

      <MergeLessonPlansModal
        v-if="canModalShow('MergeLessonPlansModal')"
        v-bind="{ original: object, canCopyAssessments }"
      />

      <CustomizationChoiceModal v-if="canModalShow('CustomizationChoiceModal')" :lesson-plan-id="object.id" />
    </Portal>

    <template v-if="hasOneAction">
      <a
        v-if="actions.canEditResource"
        :href="editResourcePath"
        class="btn btn--small flex items-center gap-x-2"
        :class="{ 'h-11': isLarge, 'btn--hover-neutral': neutralTheme }"
      >
        <LzIcon size="md" path="icons/action-edit" />
        {{ $t('common.edit') }}
      </a>
      <div v-if="actions.canCopy" class="media-fluid__action">
        <CopyObjectButton
          v-bind="{ object, objectIsResource }"
          class="btn btn--small mb-0 flex items-center gap-x-2 text-left"
          :class="{ 'h-11': isLarge, 'btn--hover-neutral': neutralTheme }"
        >
          <LzIcon size="md" path="icons/action-copy" />
        </CopyObjectButton>
      </div>
      <button
        v-if="actions.canEmbed && !isContentEmbedded"
        class="btn btn--small flex items-center gap-x-2"
        :class="{ 'h-11': isLarge, 'btn--hover-neutral': neutralTheme }"
        @click="openModal('EmbedCodeModal')"
      >
        <LzIcon size="md" path="icons/action-embed" />
        {{ $t('embed_code_modal.embed_link') }}
      </button>
      <div
        v-if="actions.canPrint"
        class="btn btn--small flex items-center gap-x-2"
        :class="{ 'h-11': isLarge, 'btn--hover-neutral': neutralTheme }"
      >
        <ActionPrint :item-id="object.id" @start-print-dialog="startPrintDialog" />
      </div>
    </template>
    <template v-else>
      <ListDropdown
        id="page-actions"
        :button-classes="[
          'inline-flex',
          'items-center',
          'btn',
          'btn-white',
          'px-4',
          'w-full',
          isLarge ? 'h-11' : '',
          neutralTheme ? 'btn--neutral-hover' : '',
        ]"
        class="w-full"
      >
        <template #button>
          {{ $t('common.actions') }}
          <LzIcon size="sm" class="ml-2" path="icons/caret" aria-hidden="true" />
        </template>

        <template #listItems>
          <li v-if="actions.canEditResource || actions.canEditLessonPlan" class="flex items-center whitespace-nowrap">
            <MenuButton
              :label="$t('common.edit')"
              :description="$t('action_menu.edit_description', { objectName })"
              :href="objectIsResource ? editResourcePath : editLessonPlanPath"
              icon="icons/action-edit"
            />
          </li>
          <li v-if="actions.canPublish" class="flex items-center whitespace-nowrap">
            <MenuButton
              :label="$t('common.publish')"
              :description="isLarge ? $t('action_menu.revise_description') : null"
              icon="icons/badge-check"
              @click="openModal('PublishConfirmModal')"
            />
          </li>
          <li v-if="actions.canRevise" class="flex items-center gap-x-2 whitespace-nowrap">
            <MenuButton
              v-if="object.revisionId"
              :label="$t('common.continue_revision')"
              :description="$t('action_menu.revise_description')"
              :href="editRevisionPath"
              icon="icons/chevron-double-right"
            />
            <MenuButton
              v-else
              :label="$t('common.start_revision')"
              :description="$t('action_menu.revise_description')"
              icon="icons/chevron-double-right"
              @click="openModal('StartRevisionModal')"
            />
          </li>
          <li v-if="actions.canMetaEdit || actions.canPersonalize" class="flex items-center gap-x-2 whitespace-nowrap">
            <MenuButton
              v-if="actions.canMetaEdit && actions.canPersonalize"
              :label="$t('common.customize')"
              :description="$t('action_menu.customize_description', { objectName })"
              icon="icons/action-customize"
              @click="openModal('CustomizationChoiceModal')"
            />
            <MenuButton
              v-else
              :label="$t('common.customize')"
              :href="metaEditPath"
              :description="$t('action_menu.customize_description', { objectName })"
              icon="icons/action-customize"
            />
          </li>
          <li v-if="actions.canCopy" class="flex items-center whitespace-nowrap">
            <CopyObjectButton v-bind="{ object, objectIsResource }" class="flex items-center gap-x-2">
              <LzIcon size="md" path="icons/action-copy" />
            </CopyObjectButton>
          </li>
          <li v-if="actions.canMerge" class="flex items-center whitespace-nowrap">
            <MenuButton
              :label="$t('common.merge')"
              icon="icons/action-merge"
              @click="openModal('MergeLessonPlansModal')"
            />
          </li>
          <li v-if="actions.canPrint" class="flex items-center gap-x-2 whitespace-nowrap">
            <ActionPrint :item-id="object.id" @start-print-dialog="startPrintDialog" />
          </li>
          <li v-if="actions.canEmbed && !isContentEmbedded">
            <MenuButton
              :label="$t('embed_code_modal.embed_link')"
              icon="icons/action-embed"
              @click="openModal('EmbedCodeModal')"
            />
          </li>
          <li v-if="actions.canDestroyUnavailable" class="flex items-center gap-x-2 whitespace-nowrap">
            <button class="disabled">
              <LzTooltip placement="left" reference-tag="div">
                {{ $t('action_menu.delete_unavailable') }}

                <template #content>
                  <span>{{ destroyUnavailableTitle }}</span>
                </template>
              </LzTooltip>
            </button>
          </li>
          <li v-if="actions.canDestroy" class="flex items-center gap-x-2 whitespace-nowrap">
            <button
              class="flex items-center gap-x-2 whitespace-nowrap text-red-600 hover:bg-red-50"
              @click="openModal('DeleteObjectModal')"
            >
              <LzIcon icon-color-class="text-red-400" size="md" path="icons/delete" />
              {{ $t('common.delete') }}
            </button>
          </li>
        </template>
      </ListDropdown>
    </template>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import EmbedCodeModal from './EmbedCodeModal'
import DeleteObjectModal from './DeleteObjectModal'
import CopyObjectModal from './CopyObjectModal'
import PublishConfirmModal from 'vue_features/lesson_plans/edit/components/PublishConfirmModal'
import CopyObjectButton from './CopyObjectButton'
import MergeLessonPlansModal from './MergeLessonPlansModal'
import StartRevisionModal from './StartRevisionModal'
import CustomizationChoiceModal from './CustomizationChoiceModal'
import { LzIcon, ProgressCover, LzTooltip } from 'vue_features/shared/components/ui'
import { polymorphicPath, polymorphicUrl } from 'routes/route_helpers'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { closeModal, openModal, canModalShow, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import PrintDialog from 'vue_features/print_settings/components/PrintDialog'
import { usePrintSettingsStore } from 'vue_features/print_settings/store/use_print_settings_store'
import { Portal } from 'portal-vue'
import ActionPrint from './ActionPrint'
import MenuButton from './MenuButton'
import Routes from 'routes'
import { useRoute } from 'vue-router'

export default {
  name: 'ActionMenu',
  components: {
    ActionPrint,
    ListDropdown,
    LzIcon,
    EmbedCodeModal,
    DeleteObjectModal,
    CopyObjectModal,
    CopyObjectButton,
    ProgressCover,
    LzTooltip,
    MergeLessonPlansModal,
    CustomizationChoiceModal,
    PrintDialog,
    MenuButton,
    StartRevisionModal,
    PublishConfirmModal,
    Portal,
  },
  props: {
    modalPortal: {
      type: String,
      default: null,
    },
    neutralTheme: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
      validator: (v) => ['sm', 'lg'].includes(v),
    },
    copiesData: { type: Object, required: true },
    object: { type: Object, required: true },
    actions: { type: Object, required: true },
    objectIsResource: { type: Boolean, required: true },
    canCopyAssessments: { type: Boolean, required: true },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { openTool } = useLessonPlanStore()
    const closeTool = () => (openTool.value = '')
    const { isContentEmbedded } = useGlobalStore()
    const { resetInstanceState: resetPrintDialog } = usePrintSettingsStore()

    const isLarge = computed(() => props.size === 'lg')
    const route = useRoute()

    const currentTab = computed(() => (route && route.name ? `/${route.name}` : ''))
    const presentActions = computed(() => Object.keys(props.actions).filter((key) => props.actions[key]))
    const readableType = computed(() => {
      if (props.object.state === 'revision') {
        return root.$t('common.revision')
      }
      return root.$t(`readable_types.${props.object.lessonType || 'full'}`)
    })
    const destroyUnavailableTitle = computed(() => {
      if (props.objectIsResource) return root.$t('action_menu.delete_ua_resource')
      return root.$t('action_menu.delete_ua_lp', { lesson_type: readableType.value })
    })
    const metaEditPath = computed(() => {
      const tab = currentTab.value === '/description' ? '' : currentTab.value
      if (props.objectIsResource) {
        return `${Routes.wiki_wiki_edits_path(props.object.id)}${tab}`
      } else {
        const param = props.actions.canMetaEdit ? '' : '?personalize=true'
        return `${Routes.lesson_plan_card_edits_path(props.object.id)}${tab}${param}`
      }
    })

    const editRevisionPath = props.objectIsResource
      ? Routes.edit_resource_revision_path(props.object.id)
      : Routes.edit_lesson_plan_revision_path(props.object.id)

    const startPrintDialog = () => {
      closeTool()
      resetPrintDialog()
      openModal(PrintDialog)
    }
    const finishPrintDialog = () => closeModal(PrintDialog)

    return {
      isLarge,
      polymorphicUrl,
      openModal,
      canModalShow,
      isModalBusy,
      isContentEmbedded,
      currentTab,
      presentActions,
      readableType,
      destroyUnavailableTitle,
      metaEditPath,
      startPrintDialog,
      finishPrintDialog,
      editRevisionPath,
      deletionPath: polymorphicPath(props.object),
      hasOneAction: computed(() => presentActions.value.length === 1),
      objectName: computed(() => (props.objectIsResource ? root.$t(getLabel(props.object)) : readableType.value)),
      editResourcePath: computed(() => Routes.edit_resource_path(props.object.idSlug)),
      editLessonPlanPath: computed(() => `${Routes.edit_lesson_plan_path(props.object.idSlug)}${currentTab.value}`),
      // Do not "fix" - the two routes above take idSlug instead of id because things break when they don't
    }
  },
}
</script>
