<template>
  <div id="additional-materials" class="lesson-plan__additional-material">
    <div>
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="_listing">
          <template v-for="group in groupedMaterials" :key="group.titleCard ? group.titleCard.id : group.id">
            <h3 v-if="group.titleCard">
              {{ group.titleCard.title }}
            </h3>
            <div>
              <ul class="mb-6 space-y-4">
                <MaterialDetailItem v-for="card in group.cards" :key="card.id" :card="card" />
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { computed } from 'vue'
import MaterialDetailItem from './MaterialDetailItem'
import { isTitleCard } from 'vue_features/shared/composables/use_card_types'

export default {
  name: 'AdditionalMaterials',
  components: { MaterialDetailItem },
  setup() {
    const { lessonPlan } = useLessonPlanStore()

    const groupedMaterials = computed(() => {
      const groups = [{ id: 0, titleCard: null, cards: [] }]
      lessonPlan.value.additionalMaterials.forEach((card, index) => {
        if (isTitleCard(card)) {
          index > 0 ? groups.push({ id: index, titleCard: card, cards: [] }) : (groups[0].titleCard = card)
        } else {
          groups[groups.length - 1].cards.push(card)
        }
      })
      return groups
    })

    return { groupedMaterials }
  },
}
</script>
