<template>
  <li
    :data-test="`school-list-item-${school.id}`"
    class="bg-focus border-neutral flex w-full gap-4 border-b-2 border-opacity-20 p-4"
  >
    <LzIcon path="icons/school" class="text-neutral-500 hidden h-10 w-10 flex-shrink-0 sm:inline-block" />
    <div class="flex-grow space-y-2">
      <div class="flex justify-between gap-2">
        <p class="text-md self-center text-base font-bold leading-6">{{ school.name }}</p>
        <SchoolActionDropdown v-if="!hasCleverId" :school="school" />
      </div>
      <div class="flex justify-items-stretch gap-4 sm:justify-start">
        <div class="text-muted flex flex-col sm:w-56">
          <span class="text-xs font-bold leading-4">{{ $t('index.date_created') }}</span>
          <p class="text-muted text-sm leading-4">
            {{ friendlyLongDateTime(school.createdAt) }}
          </p>
        </div>
        <div class="text-muted border-base flex flex-col border-l border-opacity-50 pl-4">
          <span class="leading- text-xs font-bold">{{ $t('index.number_of_users') }}</span>
          <p class="text-muted text-sm leading-4">
            {{ $n(school.userCount) }}
          </p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { computed } from 'vue'
import { friendlyLongDateTime } from 'vue_features/shared/filters/datetime'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import SchoolActionDropdown from './SchoolActionDropdown'

export default {
  name: 'SchoolListItem',
  components: { SchoolActionDropdown, LzIcon },
  props: {
    school: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hasCleverId = computed(() => !!props.school.cleverId)
    return {
      hasCleverId,
      friendlyLongDateTime,
    }
  },
}
</script>
