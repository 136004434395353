<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <div class="max-w-7xl">
      <label for="content-url-id">
        {{ $t('card_form_templates.url') }}
        <span v-if="hasError" class="error--inline">
          {{ $t('card_form_templates.enter_url_prompt') }}
        </span>
      </label>
      <input
        id="content-url-id"
        v-model="contentUrl"
        type="text"
        name="content-id"
        :placeholder="$t('card_form_templates.url')"
        class="mb-2"
        @change="parseInput"
        @blur="markInactive"
        @focus="markActive"
      />
      <p class="help-block ml-2 mt-0">
        {{
          $t('card_form_templates.url_id_extract_example', {
            url: Routes.lesson_plan_url('12345-some-lesson', { card: 46431 }),
          })
        }}
      </p>
      <div v-if="isCardUrl && canImportTeachingNotes" class="mt-4 flex">
        <label class="bg-base flex items-start px-4 py-2 text-sm">
          <input
            id="content-include-teachingnotes"
            v-model="isContentAndNoteImport"
            class="mr-2"
            type="checkbox"
            @change="emit('update-card', { importTeachingNotes: $event.target.checked })"
          />
          {{ $t('card_form_templates.import_teaching_notes') }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { get } from 'lodash'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import Routes from 'routes'

const props = defineProps({
  cardType: { type: String, required: true },
  content: { type: Object, required: true },
  isPersisted: { type: Boolean, required: true },
  allowLessonPlans: { type: Boolean, default: true },
  canImportTeachingNotes: { type: Boolean, default: false },
})

const emit = defineEmits(['update', 'update-card'])

const { isContentAndNoteImport, errors, addErrorChecker, removeErrorChecker } = useCardDeckEditorStore()

const contentUrl = ref(props.isPersisted && props.content.url ? `${window.location.origin}${props.content.url}` : '')
const touched = ref(false)
const active = ref(false)

const hasError = computed(() => {
  const errorDuringEditing = touched.value && !active.value && !valid.value && contentUrl.value
  const errorAfterSubmit = errors.value.includes('invalidExistingItemUrl')
  return errorDuringEditing || errorAfterSubmit
})

const valid = computed(() => {
  return !!inferredId.value && (props.allowLessonPlans || !isLessonPlanUrl.value)
})

const inferredId = computed(() => {
  const cardMatch = contentUrl.value.match(/\?.*card=(\d+)/)
  if (cardMatch && cardMatch[1]) {
    return cardMatch[1]
  }

  const match = contentUrl.value.match(
    /((lesson_plans|resources|documents|videos|audios|geogebra_items|wikis)\/)?(\d+)/,
  )

  return get(match, '[3]')
})

const inferredType = computed(() => {
  if (isCardUrl.value || isLessonPlanUrl.value) {
    return 'LessonPlanCard'
  } else if (isResourceUrl.value) {
    return 'ResourceCard'
  }
  return props.cardType
})

const isCardUrl = computed(() => {
  return !!contentUrl.value.match(/lesson_plans\/.*card=\d+/)
})

const isResourceUrl = computed(() => {
  return !!contentUrl.value.match(/(resources|documents|videos|audios|geogebra_items|wikis)\/(\d+)/)
})

const isLessonPlanUrl = computed(() => {
  return !isCardUrl.value && !!contentUrl.value.match(/lesson_plans\/(\d+)/)
})

function parseInput() {
  touched.value = true
  emit('update', { id: inferredId.value })
  if (valid.value) {
    emit('update-card', { type: inferredType.value, includesCard: isCardUrl.value })
  }
}

function markInactive() {
  active.value = false
}

function markActive() {
  active.value = true
}

function checkForErrors() {
  if (!valid.value) {
    errors.value.push('invalidExistingItemUrl')
  }
}

onMounted(() => {
  addErrorChecker(checkForErrors)
})

onUnmounted(() => {
  removeErrorChecker(checkForErrors)
})
</script>
