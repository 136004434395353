<template>
  <div>
    <label for="standards-selector-input">
      {{ label }}
      <span v-if="optional" class="weak regular">
        {{ $t('optional') }}
      </span>
    </label>

    <LzSelect
      ref="select"
      v-model="selectedStandards"
      :aria-label="$t('aria_label')"
      :filterable="false"
      :options="standardResults"
      :placeholder="$t('short_caption')"
      :outside-selections="allStandards"
      input-id="standards-selector-input"
      label="displayCode"
      multiple
      @update:modelValue="setStandards"
      @search="onSearch"
    >
      <template #no-options>
        {{ noResults ? $t('no_results') : $t('type_to_search') }}
      </template>

      <template #option="{ displayCode, subject, section, name }">
        <p v-if="includedElsewhere(displayCode)" class="small mb-0 pb-4">
          {{ $t('included_elsewhere', { coverageType: currentCoverage(displayCode) }) }}
        </p>
        <span class="result-display-code">{{ displayCode }}</span>
        <template v-if="includeDescription">
          -
          {{ subject }}
          -
          <template v-if="section">
            {{ section }}
            <template v-if="name">-</template>
          </template>
          {{ name }}
        </template>
      </template>
    </LzSelect>

    <p v-if="coverageType !== null" class="small help-block italic">
      {{ helpText }}
    </p>
  </div>
</template>

<script>
import { debounce, startCase } from 'lodash'
import StandardsService from '../api'
import { LzSelect } from 'vue_features/shared/components/selects'

export default {
  name: 'StandardsSelector',

  components: {
    LzSelect,
  },

  props: {
    standards: {
      type: Array,
      required: true,
    },
    coverageType: {
      type: String,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    includeDescription: {
      type: Boolean,
      default: true,
    },
    allStandards: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedStandards: [...this.standards],
      standardResults: [],
      noResults: false,
      inputEl: null,
    }
  },

  computed: {
    label() {
      switch (this.coverageType) {
        case 'buildingOn':
          return this.$t('building_on_label')
        case 'addressed':
          return this.$t('addressed_label')
        case 'buildingTowards':
          return this.$t('building_towards_label')
        default:
          return this.$t('common.label.standards')
      }
    },
    helpText() {
      switch (this.coverageType) {
        case 'buildingOn':
          return this.$t('building_on_help')
        case 'addressed':
          return this.$t('addressed_help')
        case 'buildingTowards':
          return this.$t('building_towards_help')
        default:
          return this.$t('select_help')
      }
    },
  },

  watch: {
    standards(newStandards = []) {
      if (newStandards.length < this.selectedStandards.length) {
        this.selectedStandards = [...newStandards]
      }
    },
  },

  created() {
    this.onSearch = debounce(this.onSearch, 300)
  },

  mounted() {
    this.inputEl = this.$refs.select.$el.querySelector('#standards-selector-input')
    this.inputEl.addEventListener('input', this.onSearchInput)
  },

  beforeUnmount() {
    if (this.inputEl) {
      this.inputEl.removeEventListener('input', this.onSearchInput)
    }
  },

  methods: {
    includedElsewhere(displayCode) {
      return (
        this.standards.every((s) => s.displayCode !== displayCode) &&
        this.allStandards.some((s) => s.displayCode === displayCode)
      )
    },
    currentCoverage(displayCode) {
      const standard = this.allStandards.find((s) => s.displayCode === displayCode)
      return startCase(standard.coverageType)
    },
    onSearchInput() {
      if (!this.inputEl.value) this.standardResults = []
    },

    setStandards(standards) {
      standards.forEach((s) => (s.coverageType = this.coverageType))
      this.selectedStandards = standards
      this.$emit('set-standards', this.selectedStandards)
      this.standardResults = []
    },

    onSearch(search = this.$refs.select.search, loading = this.$refs.select.toggleLoading) {
      loading(true)

      StandardsService.search(search).then((results) => {
        this.noResults = results.length === 0

        if (this.inputEl.value) this.standardResults = results

        loading(false)
      })
    },
  },
}
</script>
