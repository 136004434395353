<template>
  <div class="labeled-editor">
    <div class="rounded-t border border-b-0 border-gray-400 bg-gray-300 p-2" data-test="editor-label">{{ label }}</div>
    <div class="relative" :class="contentAreaClasses" @focusin="showToolbar" @focusout="hideToolbar">
      <div v-show="toolbarVisible && !disabled" :id="editorId" class="labeled-editor__toolbar absolute -top-11"></div>
      <Ckeditor
        :content="content"
        :options="ckeditorOptions"
        :form-field-name="formFieldName"
        @change="updateContent"
      />
      <input :value="value" :name="formFieldName" type="hidden" />
    </div>
  </div>
</template>

<script>
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import { uniqueId } from 'lodash'

export default {
  name: 'LabeledEditor',
  components: { Ckeditor },
  props: {
    label: {
      type: String,
      required: true,
    },
    ckeditorPresets: {
      type: String,
      required: true,
    },
    contentAreaClasses: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: '',
    },
    formFieldName: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toolbarVisible: false,
      value: this.content,
    }
  },
  computed: {
    ckeditorOptions() {
      return {
        toolbar: this.ckeditorPresets,
        inline: true,
        options: {
          readOnly: this.disabled,
          sharedSpaces: {
            top: this.editorId,
          },
        },
      }
    },
    editorId() {
      return uniqueId('labeled-editor-')
    },
  },
  methods: {
    showToolbar() {
      this.toolbarVisible = true
    },
    hideToolbar() {
      this.toolbarVisible = false
    },
    updateContent(newContent) {
      this.$emit('update-content', newContent)
      this.value = newContent
    },
  },
}
</script>

<style>
.labeled-editor__toolbar .cke_chrome {
  border-radius: 0.325rem;
}

.labeled-editor .cke_textarea_inline {
  display: block;
  width: 100%;
  min-height: inherit;
  padding: 0.5rem;
  color: #666;
  background-color: #fff;
  border: 1px solid #aaa;
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;
  transition: border-color ease-in-out 150ms;
  transition: box-shadow ease-in-out 150ms;
}

.labeled-editor .cke_textarea_inline:focus {
  border-color: #4cbdec;
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
