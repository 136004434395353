<template>
  <a
    href="#main"
    class="btn btn--primary z-75 absolute left-1/2 mx-auto -translate-x-1/2 -translate-y-full transform opacity-0 shadow-none focus:translate-y-2.5 focus:opacity-100"
  >
    {{ $t('common.skip_to_main') }}
  </a>
</template>
<script>
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common } from 'vue_features/shared/i18n'

export default {
  i18n: { messages: namespaceLocaleObj(common, 'common', { only: ['skip_to_main'] }) },
}
</script>
