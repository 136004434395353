<template>
  <div v-if="isToolOpen" id="student-tool" data-test="open-tool" class="col-span-12 lg:col-span-6">
    <div class="student-tool__wrapper shadow-lg">
      <div class="border-base flex flex-grow items-center justify-end gap-2 border-b p-2">
        <span class="flex-grow font-bold">{{ $t(`student_tools.label.${openTool.name}`) }}</span>

        <a
          v-if="openTool.helpUrl"
          class="border-base group flex h-10 w-10 items-center justify-center rounded-full border bg-white"
          target="_blank"
          :aria-label="$t('common.help')"
          :href="openTool.helpUrl"
          :title="$t('common.help')"
        >
          <LzIcon class="text-neutral-700 group-hover:text-neutral-900" path="icons/help" />
        </a>
        <button
          class="lzui-modal__close static flex h-10 w-10 items-center justify-center"
          data-test="close-tool"
          tabindex="0"
          :aria-label="$t('common.close')"
          @click="openTool = ''"
        >
          <LzIcon path="icons/x--small" />
        </button>
      </div>

      <iframe
        :title="$t('common.student') + ' ' + $t('common.tool')"
        allowfullscreen
        :src="openTool.url"
        class="min-h-96 h-full w-full overflow-y-auto bg-center bg-no-repeat"
        style="background-image: url('~images/misc/logo-hires.gif')"
      />
    </div>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'StudentTool',
  components: { LzIcon },
  setup() {
    const { openTool, isToolOpen } = useLessonPlanStore()
    return { openTool, isToolOpen }
  },
}
</script>
