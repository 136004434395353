<template>
  <div class="mx-auto max-w-7xl px-3 md:px-6">
    <ProgressCover :visible="submitting" />
    <div class="my-4 lg:w-3/4">
      <h1 class="h3 m-0">{{ $t('embed_header') }}</h1>
      <RailsForm v-show="acceptMultiple" id="lti-content-form" ref="ltiContentForm" :action="returnUrl" method="post">
        <LzAccordion
          v-if="selectedContents.length > 0"
          :label="$t('selected_contents')"
          :open="true"
          color="base"
          class="mb-6 mt-4"
        >
          <ul data-test="selected-contents" class="mb-6 space-y-2">
            <DetailListItem
              v-for="item in selectedContents"
              :key="item.id"
              :item-id="item.id"
              :title="item.name"
              :thumbnail="getThumbnail(item)"
              :href="item.url"
              thumbnail-size="m"
            />
          </ul>
        </LzAccordion>
        <Alert v-if="showAssignmentCreatePrompt" type="error">
          <p>{{ $t('create_assignment') }}</p>
          <div class="center space-x-4">
            <button data-test="create-assignment-btn" class="btn btn--primary" @click="chooseAssignment">{{
              $t('common.yeah')
            }}</button>
            <button class="btn" @click="submitForm">{{ $t('common.nope') }}</button>
          </div>
        </Alert>
        <input id="lti_message_type" value="contentItemSelection" name="lti_message_type" type="hidden" />
        <input id="lti_version" value="LTI-1p0" name="lti_version" type="hidden" />
        <input id="content_items" :value="contentItemsJson" name="content_items" type="hidden" />
        <input v-if="data" id="data" :value="JSON.stringify(data)" name="data" type="hidden" />

        <input type="hidden" name="oauth_version" :value="oauthSignature.version" />
        <input type="hidden" name="oauth_nonce" :value="oauthSignature.nonce" />
        <input type="hidden" name="oauth_timestamp" :value="oauthSignature.timestamp" />
        <input type="hidden" name="oauth_consumer_key" :value="oauthSignature.consumerKey" />
        <input type="hidden" name="oauth_callback" :value="oauthSignature.callback" />
        <input type="hidden" name="oauth_signature_method" :value="oauthSignature.signatureMethod" />
        <input type="hidden" name="oauth_signature" :value="oauthSignature.signature" />

        <div v-if="showSubmit" class="align-right">
          <button data-test="clear-btn" class="btn btn--demoted" @click="selectedContents = []">
            {{ $t('common.clear_selections') }}
          </button>
          <button data-test="submit-btn" type="submit" class="btn btn--primary">
            {{ $t('add_content') }}
          </button>
        </div>
      </RailsForm>
    </div>
    <div class="space-y-6">
      <div class="lg:w-3/4">
        <SearchBox v-model="tempQuery" :placeholder="$t('common.search_placeholder_short')" @submit="resetSearch" />
        <SearchResults
          v-bind="{
            showHeader: true,
            hasActiveSearch,
            canLoadMore,
            isLoadingSearchResults,
            isLoading,
            searchResults,
            visibleItems,
            selectedItems: selectedContents,
            hiddenFilters: ['content_type'],
          }"
          @load-more="handleLoadMore"
          @selected-item="selectContent"
          @deselected-item="(itemIndex) => selectedContents.splice(itemIndex, 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import URI from 'urijs'
import location from 'utils/location'
import { isEqual } from 'lodash'
import { ref, computed, watch, nextTick } from 'vue'
import RailsForm from 'vue_features/shared/components/forms/RailsForm'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import SearchBox from 'vue_features/shared/components/search/SearchBox'
import SearchResults from 'vue_features/shared/components/search/SearchResults'
import useModalSearch from 'vue_features/shared/composables/use_modal_search'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import useContentItemsJson from '../composables/use_content_items_json'
import Alert from 'vue_features/shared/components/ui/Alert'
import useOauthSignature from '../composables/use_oauth_signature'
import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import ProgressCover from 'vue_features/shared/components/ui/loaders/ProgressCover'
import LzAccordion from 'vue_features/shared/components/ui/LzAccordion'
import { getThumbnail } from 'vue_features/shared/composables/use_content_type_metadata'

export default {
  name: 'ContentItemsIndex',
  components: { LzAccordion, ProgressCover, Alert, SearchBox, SearchResults, DetailListItem, RailsForm },
  props: {
    isAssignmentSelectionRequest: { type: Boolean, required: true },
    returnUrl: { type: String, required: true },
    acceptMultiple: { type: Boolean, required: true },
    acceptMediaTypes: { type: Array, required: true },
    acceptTargets: { type: Array, required: true },
    outcomeIsOptional: { type: Boolean, required: true },
    choosingAssignment: { type: Boolean, required: true },
    isSchoologyRequest: { type: Boolean, required: true },
    data: { type: Object, default: () => null },
  },
  setup(props) {
    const {
      tempQuery,
      search,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      totalSearchCount,
      hasActiveSearch,
      modalFilters,
    } = useModalSearch()
    modalFilters.facets = true
    if (props.isAssignmentSelectionRequest) {
      modalFilters.lesson_type = 'assessment'
    }

    const showAssignmentCreatePrompt = computed(() => props.outcomeIsOptional && selectedContents.value.length > 0)
    const submitting = ref(false)
    const isCreatingAssignment = ref(props.choosingAssignment)

    const updateSearch = () => {
      resetSearch()
      search()
    }

    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: searchResults,
      totalCount: totalSearchCount,
      startCount: 20,
      moreCount: 20,
    })
    const handleLoadMore = () => showMoreItems(search)

    modalFilters.m = 'LessonPlan'

    const { filterParams, query } = useSearchFiltersStore()
    const lastSearch = filterParams.value

    updateSearch()

    watch(filterParams, () => {
      if (!isEqual(filterParams.value, lastSearch.value)) {
        tempQuery.value = query.value
        updateSearch()
      }
    })

    const ltiContentForm = ref(null)
    const selectedContents = ref([])
    const showSubmit = computed(() => {
      return selectedContents.value.length > 0
    })

    const { contentItemsJson, returnQueryParams } = useContentItemsJson({
      selectedContents,
      mediaTypes: props.acceptMediaTypes,
      targets: props.acceptTargets,
    })

    const selectContent = async (item) => {
      selectedContents.value.push(item)
      if (props.isSchoologyRequest) {
        const target = new URI(props.returnUrl)
        target.addQuery(returnQueryParams(item))
        return location.replace(target.toString())
      } else if (!props.acceptMultiple) {
        await nextTick()
        await submitForm()
      }
    }

    const { oauthSignature, updateFormSignature } = useOauthSignature(contentItemsJson, props.returnUrl, props.data)

    const submitForm = async () => {
      submitting.value = true
      await createAssignments()
      await updateFormSignature()
      await nextTick()
      ltiContentForm.value.$el.submit()
    }

    const createAssignments = async () => {
      if (isCreatingAssignment.value) {
        for (const content of selectedContents.value) {
          if (content.resultType === 'LessonPlan' && !content.lzCode) {
            content.lzCode = await AssignmentsService.createAssignment(content.id)
          }
        }
      }
    }

    const chooseAssignment = () => {
      isCreatingAssignment.value = true
      submitForm()
    }

    return {
      tempQuery,
      searchResults,
      resetSearch,
      isLoadingSearchResults,
      hasActiveSearch,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      handleLoadMore,
      selectedContents,
      showSubmit,
      selectContent,
      contentItemsJson,
      ltiContentForm,
      showAssignmentCreatePrompt,
      submitForm,
      chooseAssignment,
      oauthSignature,
      submitting,
      getThumbnail,
    }
  },
}
</script>
