<template>
  <LzConfirmModal :modal-id="$options.name" :modal-title="$t('common.delete')">
    <template #confirmBody>
      <p class="my-2">{{ $t('manage_modal.confirm_delete_prompt', { name: activeModal.payload.klass.name }) }}</p>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution" @click="handleConfirm">
        {{ $t('manage_modal.delete_assignment') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import AssignmentsService from '../../api/assignments_service'
import { openModal, closeModal, activeModal, isModalBusy } from 'vue_features/shared/composables/use_global_modals'
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { location as $location } from 'utils'
import AssignmentsErrorModal from './AssignmentsErrorModal'

export default {
  name: 'AssignmentsDeleteModal',
  AssignmentsErrorModal,
  components: { LzConfirmModal },
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
  },
  setup() {
    return { openModal, closeModal, activeModal, isModalBusy }
  },
  methods: {
    async handleConfirm() {
      this.isModalBusy = true
      const { lzCodeId, id } = this.activeModal.payload.lzCodeKlass
      try {
        await AssignmentsService.deleteAssignmentForKlass({ lzCodeId, id })
        if (this.onSuccess) {
          await this.onSuccess(this.activeModal.payload.klass.id)
          this.isModalBusy = false
          this.closeModal(this.$options.name)
        } else {
          $location.reload()
        }
      } catch (e) {
        this.isModalBusy = false
        this.closeModal(this.$options.name)
        this.openModal(this.$options.AssignmentsErrorModal)
      }
    },
  },
}
</script>
