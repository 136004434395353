<template>
  <div class="z-100 fixed left-0 top-0 flex h-screen w-screen items-center justify-center py-4">
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions | has focus trap and close buttons for keyboard -->
    <div class="absolute left-0 top-0 z-0 h-screen w-screen bg-black opacity-20" @click="onBackdropClick" />

    <div
      ref="modalBody"
      :title="$t('LzModal.dialog')"
      class="relative z-10 row-span-full h-auto max-h-full w-11/12 overflow-y-auto rounded-2xl bg-white px-8 pb-8 pt-10 shadow-lg xl:w-1/2"
      :class="contentClasses"
    >
      <div class="flex-full sticky top-0 flex justify-end">
        <button
          v-if="canCancel"
          class="lzui-modal__close relative -right-6 -top-8 -mb-6"
          data-test="close-button"
          :aria-label="$t('LzModal.close')"
          @click="onBackdropClick"
        >
          <InlineSvg path="icons/close" />
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { InlineSvg } from 'vue_features/shared/components/ui'
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { ref } from 'vue'
import useFocusTrap from 'vue_features/shared/composables/use_focus_trap'

export default {
  name: 'Modal',
  components: { InlineSvg },
  i18n: { messages: namespaceLocaleObj(common, 'LzModal', { only: ['close', 'dialog'] }) },
  props: {
    contentClasses: {
      type: [Array, String],
      default: '',
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const modalBody = ref()
    useFocusTrap(modalBody)

    const onBackdropClick = () => {
      emit('backdrop-click')
    }

    return {
      onBackdropClick,
      modalBody,
    }
  },
}
</script>
