<template>
  <div
    ref="containerEl"
    data-test="assignment-cards-content"
    class="active lesson-plan__lesson w-full"
    :class="wrapperClass"
  >
    <div class="flex h-full w-full flex-col items-center">
      <div
        id="lesson-player"
        ref="lessonPlayer"
        class="lesson-player flex w-full"
        :class="
          hasBothCardTypes
            ? 'lesson-player-dual-deck justify-start gap-x-4 px-2 md:gap-x-8 md:px-4'
            : 'max-w-7xl justify-center px-2 md:px-4 xl:px-0'
        "
        :data-fullscreen="isFullscreen ? true : null"
        :data-active-notes="null"
        :data-active-sidebar="showThumbs ? true : null"
      >
        <div
          v-if="isFullscreen"
          class="lesson-player-bg bg-neutral-200 fixed left-0 top-0 z-40 h-full w-full print:hidden"
        />

        <template v-if="!resettingLearnosity">
          <AssignmentCardsPrimaryDeck
            class="z-50 w-full transition-all"
            :class="{
              'opacity-10': trayExpanded,
              'opacity-100': !trayExpanded,
            }"
            :cards="primaryCards"
            :data-card-type="primaryDeckType"
            :show-back-to-top="!hasBothCardTypes"
            :allow-fullscreen="!hasBothCardTypes"
            :allow-sidebar="!hasBothCardTypes"
            :allow-notes="!hasBothCardTypes"
            :is-preview="isPreview || isStudentPreview"
            @exit-player="exitPlayer"
            @show-sidebar="showThumbs = $event"
          />
          <template v-if="secondaryCards && useMobileTray">
            <div
              class="secondary-tray fixed z-50 flex items-center justify-start transition-all"
              :class="{
                'secondary-tray--open': trayExpanded,
                'secondary-tray--closed': !trayExpanded,
              }"
            >
              <div class="bg-primary-800 h-full w-full rounded-l-2xl text-white">
                <button
                  type="button"
                  class="secondary-tray__handle bg-primary-800 absolute flex cursor-pointer items-center justify-center gap-x-2 rounded-t-xl text-sm shadow-xl transition-all"
                  @click="trayExpanded = !trayExpanded"
                >
                  <LzIcon
                    size="sm"
                    path="icons/chevron-down"
                    class="transition-transform"
                    :class="{ 'rotate-180 transform': !trayExpanded }"
                  />
                  {{ $t('show.additional_cards') }}
                </button>
                <div class="h-full w-full overflow-hidden p-2">
                  <div class="h-full w-full overflow-auto rounded-l-lg">
                    <AssignmentCardsTrayDeck
                      class="z-40 w-full"
                      :cards="secondaryCards"
                      :is-preview="isPreview || isStudentPreview"
                      data-card-type="assignment-card-deck"
                      @exit-player="exitPlayer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="secondaryCards">
            <AssignmentCardsSecondaryDeck
              class="sticky top-0 z-40 w-full"
              :cards="secondaryCards"
              :is-preview="isPreview || isStudentPreview"
              data-card-type="assignment-card-deck"
              @exit-player="exitPlayer"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, provide, onMounted, onDeactivated, onActivated, watch, nextTick } from 'vue'
import { useAssignmentShowStore, useAssignmentCardsStore } from 'vue_features/assignments/show/composables'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'
import { LzIcon } from 'vue_features/shared/components/ui'
import AssignmentCardsPrimaryDeck from './AssignmentCardsPrimaryDeck'
import AssignmentCardsSecondaryDeck from './AssignmentCardsSecondaryDeck'
import AssignmentCardsTrayDeck from './AssignmentCardsTrayDeck'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import { Routes } from 'vue_features/shared/helpers/http_helper'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { disableUnloadConfirm } from 'utils'
import { location as $location } from 'utils'
import { useViewportSize } from 'vue_features/shared/composables'
import { useRouter } from 'vue-router'

const BREAKPOINT_FOR_SIDE_TRAY = 900

export default {
  name: 'AssignmentCardsContent',
  components: {
    LzIcon,
    AssignmentCardsPrimaryDeck,
    AssignmentCardsSecondaryDeck,
    AssignmentCardsTrayDeck,
  },
  props: {
    isPreview: {
      type: Boolean,
      deafult: false,
    },
    isStudentPreview: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const {
      assignmentDisplayCards,
      lessonDisplayCards,
      hasAssignmentCards,
      hasLessonCards,
      hasQuestionCards,
      attemptedQuestionCardIds,
      allowNavigationAway,
    } = useAssignmentCardsStore()
    const { exitFullscreen, isFullscreen } = useFullscreenStore()

    const { assignmentViewKlassId } = useAssignmentShowStore()
    const { lessonPlanTextStyle, lessonPlan } = useLessonPlanStore()
    const { isStudent } = useCurrentUserStore()
    const containerEl = ref(null)
    const lessonPlayer = ref()

    const exitPlayer = async () => {
      if (isFullscreen.value) {
        exitFullscreen(false, emit)
      }
      await allowNavigationAway()

      isStudent.value ? exitPlayerForStudent() : exitPlayerForTeacher()
    }

    const exitPlayerForStudent = () => {
      if (assignmentViewKlassId.value) {
        return $location.assign(Routes.student_klass_path(assignmentViewKlassId.value))
      }
      $location.assign(Routes.student_klasses_path())
    }

    const router = useRouter()
    const exitPlayerForTeacher = () => {
      if (props.isStudentPreview) {
        return router.replace({ name: 'report' })
      }
      $location.assign(Routes.lesson_plan_path(lessonPlan.value.id))
    }

    provide('fontTextStyle', lessonPlanTextStyle)
    provide('fullscreenContainerEl', containerEl)
    provide('isTakingAssessment', true)

    const hasBothCardTypes = computed(() => hasAssignmentCards.value && hasLessonCards.value)

    const primaryDeckType = computed(() => {
      if (hasLessonCards.value) return 'lesson-card-deck'
      return 'assignment-card-deck'
    })

    const primaryCards = computed(() => {
      if (hasBothCardTypes.value) return lessonDisplayCards.value
      if (hasLessonCards.value) return lessonDisplayCards.value
      return assignmentDisplayCards.value
    })

    const secondaryCards = computed(() => {
      if (hasBothCardTypes.value) return assignmentDisplayCards.value
      return null
    })

    const { width } = useViewportSize()
    const useMobileTray = ref(width.value <= BREAKPOINT_FOR_SIDE_TRAY)
    const resettingLearnosity = ref(false)

    const resetLearnosity = async () => {
      resettingLearnosity.value = true
      if (hasQuestionCards.value) {
        useLearnosityClientStore().resetAll()
      }

      await nextTick()
      resettingLearnosity.value = false
    }

    if (hasBothCardTypes.value) {
      watch(width, () => {
        const isMobileWidth = width.value <= BREAKPOINT_FOR_SIDE_TRAY
        if (isMobileWidth !== useMobileTray.value) {
          resetLearnosity()
          useMobileTray.value = isMobileWidth
        }
      })
    }

    const trayExpanded = ref(false)
    watch(useMobileTray, () => {
      if (!useMobileTray.value) {
        trayExpanded.value = false
      }
    })

    onMounted(() => {
      document.documentElement.classList.remove('scroll-smooth')
    })

    onActivated(() => {
      attemptedQuestionCardIds.value = []
    })

    onDeactivated(() => {
      if (hasQuestionCards.value) {
        useLearnosityClientStore().resetAll()
      }
    })

    const showThumbs = ref(false)
    disableUnloadConfirm()

    return {
      resettingLearnosity,
      trayExpanded,
      useMobileTray,
      lessonPlayer,
      hasBothCardTypes,
      hasAssignmentCards,
      primaryDeckType,
      primaryCards,
      secondaryCards,
      hasLessonCards,
      assignmentDisplayCards,
      lessonDisplayCards,
      containerEl,
      hasQuestionCards,
      isFullscreen,
      exitPlayer,
      showThumbs,
    }
  },
}
</script>

<style scoped>
.secondary-tray {
  width: calc(100vw - 35px);
  height: 95vh;
  top: 2.5vh;
}

.secondary-tray__handle {
  height: 36px;
  width: 180px;
  transform: rotate(-90deg);
  left: -108px;
  top: 50%;
  opacity: 0.67;
}

.secondary-tray__handle:hover {
  opacity: 1;
}

.secondary-tray--open {
  left: 35px;
}

.secondary-tray--closed {
  left: calc(100vw - 5px);
}
</style>
