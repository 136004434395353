import AssignmentsService from 'vue_features/assignments/shared/api/assignments_service'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

export const useKlassAssignmentsStore = defineStore('klassAssignments', () => {
  const state = reactive({
    activeAssignmentsData: {
      assignments: [],
      meta: {
        pagination: { totalCount: 0 },
      },
    },
    inactiveAssignmentsData: {
      assignments: [],
      meta: {
        pagination: { totalCount: 0 },
      },
    },
    shareAssignment: {},
  })

  const activeAssignments = computed(() => state.activeAssignmentsData.assignments)
  const inactiveAssignments = computed(() => state.inactiveAssignmentsData.assignments)
  const hasAnyCodes = computed(() => {
    return activeAssignments.value.length > 0 || inactiveAssignments.value.length > 0
  })

  async function loadMoreAssignments({ klassId, assignmentType, nextPage }) {
    const assignmentsData = await AssignmentsService.getAssignments({ klassId, assignmentType, nextPage })
    const currentAssignmentsData = state[`${assignmentType}AssignmentsData`]
    currentAssignmentsData.assignments.push(...assignmentsData.assignments)
    currentAssignmentsData.meta = assignmentsData.meta
  }

  const updateStudentActivities = (assignmentType, assignmentId, assignmentViews) => {
    const currentAssignmentsData = state[`${assignmentType}AssignmentsData`]
    const currentAssignment = currentAssignmentsData.assignments.find((assignment) => assignment.id === assignmentId)
    if (!currentAssignment?.studentActivities) return
    assignmentViews.forEach((view) => {
      const found = currentAssignment.studentActivities.find((activity) => activity.id === view.id)
      if (!found) return
      found.assignmentState = view.assignmentState
      found.openAt = view.openAt
      found.closeAt = view.closeAt
    })
  }

  return {
    state,
    activeAssignments,
    inactiveAssignments,
    hasAnyCodes,
    loadMoreAssignments,
    updateStudentActivities,
    activeCount: computed(() => state.activeAssignmentsData.meta.pagination.totalCount),
    inactiveCount: computed(() => state.inactiveAssignmentsData.meta.pagination.totalCount),
  }
})
