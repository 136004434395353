<template>
  <div class="flex flex-row items-center">
    <nav v-if="hasFilters" :aria-label="$t('navigation.breadcrumb')" class="w-full items-center">
      <span v-for="breadcrumb in breadcrumbs" :key="breadcrumb.level" class="text-sm text-gray-200">
        <a
          href="#"
          :data-test="`breadcrumb-${breadcrumb.level}`"
          @click.prevent="updateFilters($router, breadcrumb.filters)"
        >
          {{ breadcrumb.text }}
        </a>
      </span>
    </nav>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { isEmpty, compact } from 'lodash'
import { useDataDashboardStore } from '../composables/data_dashboard_store'

export default {
  name: 'Breadcrumbs',
  setup() {
    const root = inject('useRoot')()
    const { updateFilters, allowedFilters } = useDataDashboardStore()
    const hasFilters = computed(() => !isEmpty(allowedFilters.value))
    const breadcrumbs = computed(() => {
      const currentFilters = allowedFilters.value || {}
      return compact([
        {
          level: 'site',
          filters: { gradeLevel: null, school: null, teacher: null, student: null },
          text: root.$t('site'),
        },
        currentFilters.gradeLevel && {
          level: 'grade-level',
          filters: { school: null, teacher: null, student: null },
          text: `${root.$t('grade_level')} ${currentFilters.gradeLevel}`,
        },
        currentFilters.school && {
          level: 'school',
          filters: { teacher: null, student: null },
          text: currentFilters.schoolName,
        },
        currentFilters.teacher && {
          level: 'teacher',
          filters: { student: null },
          text: currentFilters.teacherName,
        },
        currentFilters.student && { level: 'student', filters: {}, text: currentFilters.studentName },
      ])
    })

    return { updateFilters, hasFilters, breadcrumbs }
  },
}
</script>

<style scoped>
nav span + span::before {
  content: '>';
  margin: 0 0.25rem;
  color: rgb(var(--gray-700));
}
</style>
