<template>
  <div :id="domId"></div>
</template>

<script>
import { last, uniqueId } from 'lodash'
import { createClient } from 'clients/filestack'

/*
The import below uses Webpack's inline loader syntax in order to output our custom Filestack preview stylesheet
as its own file on our CDN. Behind the scenes, file-loader causes Webpack to copy the filestack_preview.css file
into the output directory and return the path to it as FILESTACK_PREVIEW_PATH. This is a one-off
use case that used to be handled by Rails Asset Pipeline and a constant in rails_constants.js.erb prior to Webpack
compiling our Sass.

This is necessary because Filestack needs to load this stylesheet into an iframe originating from its own servers
for content previews.

See the Webpack docs for info on inline loader syntax: https://webpack.js.org/concepts/loaders/#inline
Filestack API docs: https://filestack.github.io/filestack-js/classes/client.html#preview
*/
import FILESTACK_PREVIEW_PATH from 'file-loader?name=filestack_preview.[contenthash].css!css/filestack_preview.css'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    filestackOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      // due to the nature of lesson plan cards rendering + re-rendering
      // Filestack sometimes ignores "completed" preview requests w/ the same id
      // By making each preview unique, we circumvent this limitation
      domId: uniqueId('preview-'),
    }
  },

  computed: {
    handle() {
      return last(this.url.split('/'))
    },
    clientOptions() {
      return { id: this.domId, css: this.cssUrl }
    },
    cssUrl() {
      if (location.origin.includes('https') && !location.origin.includes('local')) {
        return `${location.origin}/${FILESTACK_PREVIEW_PATH}`
      } else {
        // It doesn't do any good to send FileStack a localzillion CSS URL. So
        // we don't have custom CSS in the local development environment, which
        // just means that we don't hide the redundant download button.
        return ''
      }
    },
  },

  mounted() {
    const client = createClient(this.filestackOptions)
    const iframe = client.preview(this.handle, this.clientOptions)
    iframe.title = this.title
  },
}
</script>
