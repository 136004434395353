<template>
  <li class="border-primary flex cursor-pointer rounded-2xl border shadow">
    <div class="flex-0">
      <div class="text-primary flex h-16 w-16 items-center justify-center">
        <LzIcon size="md" :path="iconPath" />
      </div>
    </div>
    <div class="flex flex-1 flex-col">
      <p class="text-primary py-4 text-sm font-semibold">
        {{ name }}
      </p>
    </div>
  </li>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'EditTeachingNotesItem',
  components: { LzIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    iconPath: {
      type: String,
      required: true,
    },
  },
}
</script>
