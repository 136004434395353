<template>
  <div data-test="assign-to-students" class="flex flex-col space-y-6">
    <AsyncContentLoader :loader="initializeKlasses">
      <div class="flex flex-col space-y-6">
        <div v-if="!klassesTotal" data-test="assign-no-classes">
          <p class="lzui-tile bg-orange-50 border-orange-200 center mb-6 p-4">
            {{ $t('assign_to_student_tab.no_classes') }}
            <a :href="Routes.teacher_klasses_path()">{{ $t('assign_to_student_tab.manage_classes') }}</a>
          </p>
        </div>
        <AssignStudentList v-for="klass in visibleItems" :key="klass.id" :klass="klass" />
        <LoadMoreButton v-if="canLoadMore" class="p-0" v-bind="{ isLoading }" @click="showMoreItems(handleLoadMore)">
          {{ $t('assign_to_student_tab.load_more_classes') }}
        </LoadMoreButton>
      </div>
    </AsyncContentLoader>
  </div>
</template>

<script>
import { AsyncContentLoader } from 'vue_features/shared/components/ui'
import { LoadMoreButton } from 'vue_features/shared/components/lists'
import AssignStudentList from './AssignStudentList'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import Routes from 'routes'

export default {
  name: 'AssignToStudents',
  components: { AsyncContentLoader, LoadMoreButton, AssignStudentList },
  setup() {
    const { klasses, klassesTotal, initializeKlasses, loadMoreKlasses } = useAssignmentPromptStore()
    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: klasses,
      totalCount: klassesTotal,
    })

    return {
      Routes,
      klassesTotal,
      initializeKlasses,
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
      handleLoadMore: (done) => loadMoreKlasses().then(done),
    }
  },
}
</script>
