<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div>
    <div v-if="isLoadingSearchResults" class="my-3 max-w-7xl">
      <p>
        <LoadingSpinner inline tag="span" />
      </p>
      {{ $t('common.loading') }}
    </div>
    <div v-else class="search-index my-3 max-w-7xl">
      <div id="content-search">
        <div v-if="showHeader" id="search-header">
          <div class="border-base border-b">
            <Grid disable-default="md:px" class="my-3">
              <slot name="header-action">
                <div class="col-span-9 md:col-span-8" />
              </slot>
              <div class="col-span-3 text-right md:col-span-4">
                <DivDropdown
                  id="filters-dropdown"
                  :button-classes="['btn--small']"
                  :dropdown-classes="['w-60 md:w-80 max-w-none flow-left p-4']"
                  :close-on-click="false"
                  tag="div"
                >
                  <template #button>
                    {{ $t('lz_content_search.filters') }}
                    <LzIcon path="icons/add" type="div" class="pl-1" />
                  </template>
                  <template #divContent>
                    <div class="grid grid-cols-3 pb-1">
                      <div v-if="selectedFilterCount > 0" class="col-span-2">
                        <h5>{{ selectedFilterCount }} {{ $t('search.shared.selected') }}</h5>
                      </div>
                      <a
                        v-if="selectedFilterCount > 0"
                        class="col-span-1 cursor-pointer text-right"
                        @click="clearFilters"
                      >
                        {{ $t('lz_content_choose.clear_all') }}
                      </a>
                    </div>
                    <SearchFilters :hidden-filters="hiddenFilters" />
                  </template>
                </DivDropdown>
              </div>
            </Grid>
          </div>
          <div class="my-3 max-w-7xl">
            <ActiveFilters :hidden-filters="hiddenFilters" />
          </div>
        </div>
        <div v-if="hasActiveSearch" class="search-index-wrapper">
          <div v-if="isLoadingSearchResults" class="mb-4">
            <p>
              <LoadingSpinner :inline="true" tag="span" />
            </p>
          </div>
          <div v-else class="search-index">
            <ul v-if="searchResults.length > 0" class="mb-6 space-y-2">
              <slot name="search-results">
                <SearchResult
                  v-for="item in visibleItems"
                  :key="item.id"
                  :item="item"
                  :is-selected="isItemSelected(item)"
                  @click="toggleItem(item)"
                />
              </slot>
              <li v-if="canLoadMore">
                <LoadMoreButton v-bind="{ isLoading }" class="p-0" @click="$emit('load-more')" />
              </li>
            </ul>
            <div v-else class="p-2">{{ $t('action_menu.no_results') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import DivDropdown from 'vue_features/shared/components/ui/dropdowns/DivDropdown'
import { Grid, LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import SearchFilters from 'vue_features/search/components/SearchFilters'
import ActiveFilters from 'vue_features/search/components/ActiveFilters'
import SearchResult from './SearchResult'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import { computed } from 'vue'

export default {
  name: 'SearchResults',
  components: { LoadMoreButton, DivDropdown, Grid, LoadingSpinner, LzIcon, SearchResult, SearchFilters, ActiveFilters },
  props: {
    showHeader: { type: Boolean, default: true },
    hasActiveSearch: { type: Boolean, default: true },
    canLoadMore: { type: Boolean, default: true },
    isLoadingSearchResults: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: true },
    searchResults: { type: Array, required: true },
    visibleItems: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
    hiddenFilters: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const { clearFilters, query, selectedOptions } = useSearchFiltersStore()
    const selectedFilterCount = computed(() => {
      const initialCount = query.value ? 1 : 0
      return Object.values(selectedOptions.value).reduce(
        (selectedKeyCount, selectedKeys) => selectedKeyCount + selectedKeys.length,
        initialCount,
      )
    })

    const getItemIndex = (item) => props.selectedItems.findIndex((i) => i.id === item.id)
    const isItemSelected = (item) => getItemIndex(item) >= 0
    const toggleItem = (item) => {
      if (isItemSelected(item)) {
        emit('deselected-item', getItemIndex(item))
      } else {
        emit('selected-item', item)
      }
    }
    return { isItemSelected, selectedFilterCount, clearFilters, toggleItem }
  },
}
</script>
