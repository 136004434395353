import { init } from './composables/use_grading_store'
import messages from './i18n/grading_messages'
import Grading from './components/Grading'

export default function ({ state }) {
  init(state)

  return {
    component: Grading,
    messages,
  }
}
