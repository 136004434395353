<script setup>
import LessonPlanContentView from './LessonPlanContentView'
import RevisionShowBanner from 'vue_features/shared/components/forms/RevisionShowBanner'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

const { lessonType, lessonPlanId: objectId, originalId } = useLessonPlanStore()
</script>

<template>
  <div>
    <RevisionShowBanner
      :object-id="objectId"
      :original-id="originalId"
      :object-name="$t(`readable_types.${lessonType || 'full'}`)"
      object-type="LessonPlan"
      can-print
    />
    <LessonPlanContentView />
  </div>
</template>
