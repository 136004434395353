import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import {
  annotation,
  common,
  lessonPlans,
  studentTools,
  featureGate,
  components,
  shared,
  lzCodes,
  hyperlinks,
  standardsCoverageTables,
} from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(annotation, 'annotation'),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(studentTools, 'student_tools'),
  namespaceLocaleObj(featureGate, 'feature_gate'),
  namespaceLocaleObj(lessonPlans, '', {
    only: [
      'lesson_plan',
      'show',
      'handout',
      'assignment_card_deck',
      'assignment_slideshow_header',
      'lesson_card_templates',
      'live_learn',
      'readable_types',
    ],
  }),
  namespaceLocaleObj(components, '', { only: ['audio_player', 'check_answers_button'] }),
  namespaceLocaleObj(lzCodes, '', { only: ['show'] }),
  pickLocaleNamespace(shared, 'content_unavailable'),
  namespaceLocaleObj(hyperlinks, 'hyperlinks'),
  standardsCoverageTables,
)
