import CustomersIndexService from '../api'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

export const useCustomersIndexStore = defineStore('customersIndex', () => {
  const state = reactive({
    customersData: {
      customers: [],
      meta: {
        pagination: {},
      },
    },
    filterableOwners: [],
    filterableSiteTypes: [],
    filterableCustomerTypes: [],
    filterableCleverTypes: [],
  })

  const customers = computed(() => state.customersData.customers)

  function getCustomers(params) {
    return CustomersIndexService.getCustomers(params).then((customersData) => {
      state.customersData = customersData
    })
  }

  return {
    state,
    customers,
    getCustomers,
  }
})
