<template>
  <table>
    <thead>
      <slot name="table-head" />
    </thead>
    <tbody>
      <slot v-for="item in visibleItems" name="table-rows" :item="item" />
      <slot name="button" v-bind="{ canLoadMore, isLoading, showMoreItems }">
        <LoadMoreButton v-if="canLoadMore" v-bind="{ isLoading }" @click="handleLoadMore" />
      </slot>
    </tbody>
  </table>
</template>

<script>
import LoadMoreButton from './LoadMoreButton'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { toRef } from 'vue'

export default {
  components: { LoadMoreButton },
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    startCount: {
      type: Number,
      default: 5,
    },
    moreCount: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: toRef(props, 'items'),
      totalCount: toRef(props, 'totalCount'),
      startCount: props.startCount,
      moreCount: props.moreCount,
    })

    return {
      visibleItems,
      canLoadMore,
      showMoreItems,
      isLoading,
    }
  },
  methods: {
    handleLoadMore() {
      this.showMoreItems((done) => this.$emit('load-more', done))
    },
  },
}
</script>
