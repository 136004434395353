<script>
import { h } from 'vue'
import {
  AudioPlayer,
  HtmlViewer,
  VideoPlayer,
  SlideViewer,
  ImageResource,
  HyperlinkResource,
  GeogebraPlayer,
  DocumentViewer,
} from 'vue_features/resources/index'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'
import { toBoolean } from 'utils'

function makeDocumentViewerProps(documentObj) {
  const aliasedProps = {
    baseUrl: documentObj.resourceUrl.baseUrl,
    filestackOptions: documentObj.resourceUrl.filestackOptions,
  }
  return { ...documentObj, ...aliasedProps }
}

export default {
  name: 'ResourceFromType',
  props: {
    type: {
      type: String,
      required: true,
    },
    serializedResource: {
      type: Object,
      required: true,
    },
  },
  render(props, context) {
    const { type, serializedResource } = props
    switch (type) {
      case 'Document':
        return h(DocumentViewer, { ...context.attrs, ...makeDocumentViewerProps(serializedResource) })
      case 'Html':
        return h(HtmlViewer, {
          ...context.attrs,
          content: serializedResource.sanitizedContent,
          textStyle: serializedResource.textStyle,
        })
      case 'Audio':
        return h(AudioPlayer, { ...context.attrs, audio: serializedResource })
      case 'Video':
        return h(VideoPlayer, {
          ...context.attrs,
          id: serializedResource.id,
          wistiaVideoId: serializedResource.wistiaVideoId,
          playedEvent: serializedResource.playedEvent,
        })
      case 'Hyperlink':
        return h(HyperlinkResource, {
          ...context.attrs,
          iframeable: serializedResource.iframeable,
          description: serializedResource.description,
          metadata: serializedResource.metadata,
          name: serializedResource.name,
          url: serializedResource.url,
        })
      case 'Image':
        return h(ImageResource, {
          ...context.attrs,
          sourceFileUrl: useFilestackAccessor(serializedResource.resourceUrl).fileUrl.value,
          customerId: serializedResource.customerId,
          downloadFileUrl: serializedResource.downloadFileUrl,
          resourceJson: serializedResource.resourceJson,
          downloadedEvent: serializedResource.downloadedEvent,
        })
      case 'Slide':
        return h(SlideViewer, {
          ...context.attrs,
          slideJson: serializedResource.json,
          textStyle: serializedResource.textStyle,
        })
      case 'GeogebraItem':
        return h(GeogebraPlayer, {
          ...context.attrs,
          materialId: serializedResource.materialId,
          showToolbar: toBoolean(serializedResource.showToolbar),
          autoscale: toBoolean(serializedResource.autoscale),
        })
      default:
        return null
    }
  },
}
</script>
