import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import {
  common,
  components,
  lessonPlans,
  shared,
  contentTypeMetadata,
  hyperlinks,
  resources,
  standardsCoverageTables,
  revisions,
} from 'vue_features/shared/i18n'
import teacherOnlyPromptMessages from './teacher_only_prompt_messages'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'
import actionMenuMessages from 'vue_features/action_menu/i18n/action_menu_messages'

export default merge(
  {},
  teacherOnlyPromptMessages,
  actionMenuMessages,
  elevateLocaleNamespace(shared, 'related_content'),
  namespaceLocaleObj(shared, 'shared', {
    only: ['text_style', 'action_menu', 'collection_map'],
  }),
  namespaceLocaleObj(contentTypeMetadata, 'content_type_metadata'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  pickLocaleNamespace(shared, 'premium_prompt'),
  pickLocaleNamespace(components, 'print_dialog'),
  namespaceLocaleObj(resources, 'resources'),
  pickLocaleNamespace(lessonPlans, 'readable_types'),
  namespaceLocaleObj(hyperlinks, 'hyperlinks'),
  namespaceLocaleObj(components, '', { only: ['audio_player'] }),
  standardsCoverageTables,
  standardsMessages,
  pickLocaleNamespace(components, 'path_crumbs'),
  namespaceLocaleObj(revisions, 'revisions'),
  namespaceLocaleObj(common, 'common', { only: ['next', 'previous', 'current_pagination'] }),
  namespaceLocaleObj(common, '', { only: ['form', 'submit'] }),
)
