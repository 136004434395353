<script setup>
import { computed } from 'vue'
import HtmlEmbedEditor from './HtmlEmbedEditor'
import SectionEditor from './SectionEditor'
import StandardsTableEditor from './StandardsTableEditor'
import ListableEditor from './ListableEditor'

const props = defineProps({
  block: { type: Object, required: true },
  editorId: { type: String, required: true },
  index: { type: Number, required: true },
  totalBlockCount: { type: Number, required: true },
  isRefreshing: { type: Boolean, required: true },
})

const displayType = computed(() => props.block.display + (props.block.detailType || props.block.contentType))
const component = computed(() => {
  switch (displayType.value) {
    case 'embedHtml':
      return HtmlEmbedEditor
    case 'embedSection':
      return SectionEditor
    case 'embedStandardsCoverageTable':
      return StandardsTableEditor
    default:
      return ListableEditor
  }
})
const editorProps = computed(() => {
  switch (displayType.value) {
    case 'embedHtml':
      return {
        content: props.block.content.content,
        editorId: props.editorId,
        startupFocus: !(props.block.id || props.block.oldId),
      }
    case 'embedSection':
      return {
        blocks: props.block.content.blocks,
        title: props.block.title,
        sectionId: props.block.id,
        index: props.index,
        totalBlockCount: props.totalBlockCount,
        editorId: props.editorId,
      }
    case 'embedStandardsCoverageTable':
      return { content: props.block.content, isRefreshing: props.isRefreshing }
    default:
      return { content: props.block.content, display: props.block.display }
  }
})
</script>

<template>
  <component :is="component" v-bind="{ ...$attrs, ...editorProps }" />
</template>
