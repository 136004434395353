import { merge } from 'lodash'
import {
  elevateLocaleNamespace,
  namespaceLocaleObj,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import filestackUploadMessages from 'vue_features/shared/i18n/filestack_upload_messages'
import {
  annotation,
  errors,
  geogebraItems,
  hyperlinks,
  wikis,
  lessonPlans,
  cardEdits,
  standardsCoverageTables,
  shared,
  featureGate,
  studentTools,
  slides,
  htmls,
  videos,
  components,
  standards,
  lzCodes,
  common,
  revisions,
} from 'vue_features/shared/i18n'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'
import actionMenuMessages from 'vue_features/action_menu/i18n/action_menu_messages'
import contactFormMessages from 'vue_features/contact_form/i18n/contact_form_messages'

export default merge(
  {},
  cardEdits,
  filestackUploadMessages,
  standardsMessages,
  standardsCoverageTables,
  shared,
  standards,
  actionMenuMessages,
  contactFormMessages,
  lzCodes,
  elevateLocaleNamespace(components, 'fullscreen_button'),
  elevateLocaleNamespace(videos, 'form_template'),
  elevateLocaleNamespace(lzCodes, 'assignment_response_report'),
  elevateLocaleNamespace(shared, 'assignment_prompt'),
  elevateLocaleNamespace(shared, 'related_content'),
  namespaceLocaleObj(annotation, 'annotation'),
  namespaceLocaleObj(geogebraItems, 'geogebra_items'),
  namespaceLocaleObj(hyperlinks, 'hyperlinks'),
  namespaceLocaleObj(wikis, 'wikis'),
  namespaceLocaleObj(shared, 'shared', {
    only: ['text_style', 'action_menu', 'collection_map'],
  }),
  namespaceLocaleObj(featureGate, 'feature_gate'),
  namespaceLocaleObj(studentTools, 'student_tools'),
  namespaceLocaleObj(slides, 'slides'),
  namespaceLocaleObj(htmls, 'htmls'),
  namespaceLocaleObj(errors, 'errors'),
  namespaceLocaleObj(components, '', {
    only: [
      'audio_player',
      'check_answers_button',
      'learnosity_item',
      'scoring_rubric',
      'learnosity_item_editor',
      'bookmark_button',
    ],
  }),
  namespaceLocaleObj(common, 'common'),
  pickLocaleNamespace(lessonPlans, 'info_form'),
  pickLocaleNamespace(lessonPlans, 'readable_types'),
  pickLocaleNamespace(shared, 'updated_notification'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  pickLocaleNamespace(shared, 'signup'),
  pickLocaleNamespace(shared, 'notify_privacy_and_terms'),
  pickLocaleNamespace(shared, 'premium_prompt'),
  pickLocaleNamespace(components, 'print_dialog'),
  lessonPlans,
  pickLocaleNamespace(components, 'path_crumbs'),
  namespaceLocaleObj(revisions, 'revisions'),
)
