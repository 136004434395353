import { merge } from 'lodash'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { common, components, lessonPlans, shared, standards } from 'vue_features/shared/i18n'

export default merge(
  {},
  namespaceLocaleObj(components, '', {
    only: ['learnosity_item', 'check_answers_button', 'scoring_rubric', 'learnosity_item_editor'],
  }),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(lessonPlans, '', {
    only: ['card_form_templates'],
  }),
  namespaceLocaleObj(shared, 'shared'),
  standards,
)
