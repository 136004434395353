<template>
  <LzModal
    :modal-title="$t('enable_new_lms1_1')"
    :modal-id="$options.name"
    class="lms-integrations-modal lzui-modal--medium"
    autoclose
  >
    <section>
      <form
        id="new_lms_integration"
        :action="Routes.site_admin_lms_integrations_path()"
        class="simple_form new_lms_integration"
        accept-charset="UTF-8"
        method="post"
      >
        <input name="utf8" type="hidden" value="✓" />
        <input :value="authenticityToken" type="hidden" name="authenticity_token" />
        <div class="form-box select required lms_integration_name">
          <label class="select required" for="lms_integration_name">{{ $t('lms_name') }}</label>
          <select
            id="lms_integration_name"
            v-model="selectedIntegration"
            class="select required"
            name="lms_integration[name]"
          >
            <option v-for="integrationName in inactiveLmsIntegrations" :key="integrationName" :value="integrationName">
              {{ integrationName }}
            </option>
          </select>
          <section v-if="isCanvasSelected" class="my-4">
            <label for="external_host">{{ $t('canvas_host') }}</label>
            <input
              id="external_host"
              placeholder="https://yourschoolname.instructure.com"
              type="text"
              name="lms_integration[external_host]"
            />
            <p class="help-block">
              {{ $t('canvas_host_help') }}
            </p>
            <label for="external_client_id">{{ $t('canvas_client_id') }}</label>
            <input
              id="external_client_id"
              placeholder="910200000XXXXX"
              type="text"
              name="lms_integration[external_client_id]"
            />
            <p class="help-block">
              {{ $t('canvas_client_id_help') }}
            </p>
            <label for="external_secret">{{ $t('canvas_secret') }}</label>
            <input id="external_secret" placeholder="" type="text" name="lms_integration[external_secret]" />
            <p class="help-block">
              {{ $t('canvas_secret_help') }}
            </p>
          </section>
        </div>
        <footer class="px-0">
          <div v-if="isCanvasSelected" class="icon small float-left">
            <LzIcon path="icons/help" />
            <a :href="$options.helpUrl" target="_blank" rel="noopener noreferrer">
              {{ $t('canvas_setup_help') }}
            </a>
          </div>
          <input :value="$t('create')" type="submit" name="commit" class="btn btn--primary float-right" />
          <div class="clear-both"></div>
        </footer>
      </form>
    </section>
  </LzModal>
</template>

<script>
import { HELP_CANVAS_LTI_URL } from 'rails_constants'
import { LzModal, LzIcon } from 'vue_features/shared/components/ui'
import Routes from 'routes'

export default {
  name: 'AddLMSIntegrationModal',
  helpUrl: HELP_CANVAS_LTI_URL,
  components: {
    LzModal,
    LzIcon,
  },
  props: {
    inactiveLmsIntegrations: {
      type: Array,
      required: true,
    },
    authenticityToken: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
  data() {
    return {
      selectedIntegration: this.inactiveLmsIntegrations[0],
    }
  },
  computed: {
    isCanvasSelected() {
      return this.selectedIntegration === 'canvas'
    },
  },
}
</script>
