<template>
  <ul class="flex flex-wrap">
    <LzContentItem v-for="item in lzContents" :key="item.id" :item="item" :is-block="true" />
  </ul>
</template>

<script>
import LzContentItem from './LzContentItem'

export default {
  name: 'LzContentBlock',
  components: { LzContentItem },
  props: {
    lzContents: {
      type: Array,
      required: true,
    },
  },
}
</script>
