<template>
  <div id="student-login" class="student-login content end space-y-4">
    <h2 v-if="hasGreeting" class="h3 m-0">{{ loginLabel }}</h2>
    <div v-if="isAuthIlclassroom" class="flex flex-col gap-y-10">
      <StudentLoginForm :in-assignment-prompt="inAssignmentPrompt" />
      <template v-if="hasClever || hasSingleIntegration">
        <Divider />
        <div :class="[{ 'justify-center': shouldCenterBtns }]">
          <LoginViaExternal />
        </div>
      </template>
    </div>
    <template v-if="isAuthClever">
      <div :class="['my-4 flex', { 'justify-center': shouldCenterBtns }]">
        <LoginViaExternal />
      </div>
      <div>
        <p v-if="!showingLoginForm" :class="{ center: shouldCenterBtns }" class="py-2">
          {{ $t('login_student.no_clever_message') }}
          <button
            id="reveal-teacher-login"
            type="button"
            class="text-accent hover:text-accent-hover"
            @click="toggleHiddenForm"
          >
            {{ $t('login_student.no_clever_login') }}
          </button>
        </p>
        <div v-else>
          <StudentLoginForm :in-assignment-prompt="inAssignmentPrompt" />
        </div>
      </div>
    </template>
    <div v-if="isAuthClasslink || isAuthOneRoster" class="flex flex-col gap-y-10 pt-6">
      <div :class="[{ 'justify-center': shouldCenterBtns }]">
        <LoginViaExternal />
      </div>
      <Divider />
      <StudentLoginForm :in-assignment-prompt="inAssignmentPrompt" />
    </div>
  </div>
</template>

<script>
import LoginViaExternal from './LoginViaExternal'
import StudentLoginForm from './StudentLoginForm'
import Divider from 'vue_features/shared/components/ui/Divider'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { shared } from 'vue_features/shared/i18n'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

const messages = namespaceLocaleObj(shared, '', { only: ['login', 'login_student'] })

export default {
  name: 'StudentLogin',
  components: { Divider, LoginViaExternal, StudentLoginForm },
  i18n: { messages },
  props: {
    centerBtns: {
      type: Boolean,
      default: false,
    },
    inAssignmentPrompt: {
      type: Boolean,
      default: false,
    },
    hasGreeting: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { name, hasClever, hasSingleIntegration, isAuthClasslink, isAuthClever, isAuthIlclassroom, isAuthOneRoster } =
      useCurrentCustomerStore()
    return {
      name,
      hasClever,
      hasSingleIntegration,
      isAuthClasslink,
      isAuthClever,
      isAuthIlclassroom,
      isAuthOneRoster,
    }
  },
  data() {
    return { showingLoginForm: false }
  },
  computed: {
    loginLabel() {
      return this.$t('login.login_label_customer', { customer_name: this.name })
    },
    shouldCenterBtns() {
      return this.centerBtns || this.inAssignmentPrompt
    },
  },
  methods: {
    toggleHiddenForm() {
      this.showingLoginForm = !this.showingLoginForm
    },
  },
}
</script>
