<template>
  <div class="content-unavailable">
    <div class="faux-text faux-text--half"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text faux-text--quarter mb-6"></div>
    <div class="faux-text faux-text--half"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text"></div>
    <div class="faux-text faux-text--quarter mb-6"></div>
    <p class="content-unavailable__notice">
      <template v-if="message">{{ message }}</template>
      <template v-else>
        {{ $t('content_unavailable.message') }}
        <template v-if="canEditResource">
          {{ $t('content_unavailable.resource_message') }}
        </template>
      </template>
    </p>
  </div>
</template>

<script>
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  components: {},
  props: {
    message: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup() {
    const { canEditResource } = useCurrentUserStore()
    return { canEditResource }
  },
}
</script>
