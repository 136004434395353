<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'
</script>

<template>
  <div
    class="hover:bg-neutral-100 cursor-grab active:cursor-grabbing focus:cursor-grabbing handle border-neutral-200 text-neutral-600 hover:text-accent flex h-auto w-6 items-center rounded-l-2xl border-r transition-colors transition-colors"
  >
    <LzIcon path="icons/reorder" style="top: calc(50% - 0.8rem)" />
  </div>
</template>
