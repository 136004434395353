<template>
  <div>
    <i18n-t tag="p" keypath="login_student.help_text" class="student-help-text">
      <template #contactUsLink>
        <a href="/contact">{{ $t('common.contact_us') }}</a>
      </template>
    </i18n-t>
    <RailsForm
      id="student_login_form"
      :action="Routes.auth_callback_path({ provider: 'identity' })"
      class="simple_form session mt-4"
      method="post"
    >
      <div class="form-box required session_username">
        <label class="required" for="session_username">{{ $t('common.username') }}</label>
        <input
          id="session_username"
          ref="username"
          name="auth_key"
          class="string required form-control no-fullstory"
          required="required"
          aria-required="true"
          type="text"
          autocomplete="username"
        />
      </div>
      <div class="form-box session_student hidden">
        <input id="session_student" name="student" value="true" class="hidden" type="hidden" />
      </div>
      <div class="form-box password required student_password">
        <label class="password required" for="student-password">{{ $t('common.password') }}</label>
        <input
          id="student-password"
          name="password"
          class="password required form-control no-fullstory"
          required="required"
          aria-required="true"
          type="password"
          autocomplete="current-password"
        />
      </div>
      <input v-if="isContentEmbedded" type="hidden" name="one_time_return_to" :value="defaultOneTimeReturnToPath" />
      <Grid disable-default="md:px">
        <div class="col-span-12 space-x-1 md:col-span-6">
          <template v-if="!hasSessionTimeout">
            <input
              id="session_stay_signed_in"
              value="1"
              checked="checked"
              type="checkbox"
              name="session[stay_signed_in]"
              data-test="stay-signed-in-checkbox"
            />
            <label class="string required regular" for="session_stay_signed_in">
              {{ $t('session.stay_signed_in') }}
            </label>
          </template>
        </div>
        <div v-if="!showingForgotPasswordInfo" class="col-span-12 md:col-span-6 md:text-right">
          <button
            type="button"
            class="student-forgot-link text-accent hover:text-accent-hover"
            @click="showForgotPasswordInfo"
          >
            {{ $t('common.forgot_password') }}
          </button>
        </div>
      </Grid>

      <div v-if="showingForgotPasswordInfo" class="student-forgot mx-auto max-w-7xl">
        <div class="border-base mt-4 rounded border p-4">
          <p>
            {{ $t('login_student.student_pw_instruction') }}
          </p>
        </div>
      </div>

      <input
        :value="inAssignmentPrompt ? $t('lesson_plan.begin_assignment') : $t('common.login')"
        :class="['btn', 'btn--primary', 'mt-4', 'w-full', ...assignmentBtnClasses]"
        type="submit"
        name="commit"
      />
    </RailsForm>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, shared, decorators, simpleForm } from 'vue_features/shared/i18n'
import { RailsForm } from 'vue_features/shared/components/forms'
import { useGlobalStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Grid from 'vue_features/shared/components/ui/Grid'
import Routes from 'routes'

const messages = merge(
  {},
  namespaceLocaleObj(decorators, '', { only: 'lesson_plan.begin_assignment' }),
  namespaceLocaleObj(shared, '', { only: ['login_student'] }),
  namespaceLocaleObj(common, 'common', {
    only: ['contact_us', 'login', 'username', 'password', 'forgot_password', 'or'],
  }),
  pickLocaleNamespace(simpleForm, 'labels.session'),
)

export default {
  name: 'StudentLoginForm',
  components: { Grid, RailsForm },
  i18n: { messages },
  props: {
    inAssignmentPrompt: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOneTimeReturnToPath, isContentEmbedded } = useGlobalStore()
    const { authPreference, hasSessionTimeout } = useCurrentCustomerStore()

    const username = ref(null)
    watch(username, (val) => val && val.focus())
    return { Routes, defaultOneTimeReturnToPath, isContentEmbedded, authPreference, hasSessionTimeout, username }
  },
  data() {
    return {
      showingForgotPasswordInfo: false,
    }
  },
  computed: {
    assignmentBtnClasses() {
      return this.inAssignmentPrompt ? ['w-full', 'py-4'] : []
    },
  },
  methods: {
    showForgotPasswordInfo() {
      this.showingForgotPasswordInfo = true
    },
  },
}
</script>
