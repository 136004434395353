<template>
  <div v-if="hasError" class="lzui-tile align-left mb-4 border-red-200 bg-red-50 p-4" data-test="import-error">
    <template v-if="hasAnotherRunningImport">
      <h3>{{ $t('another_running_import.header') }}</h3>
      <p>{{ $t('another_running_import.instructions') }}</p>
      <i18n-t tag="p" keypath="another_running_import.see_status" scope="global">
        <template #userJobsLink>
          <a :href="Routes.site_admin_user_imports_path()">{{ $t('another_running_import.user_jobs_tab') }}</a>
        </template>
      </i18n-t>
    </template>
    <template v-else>
      <h3>{{ $t('bad_file_format.header') }}</h3>
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
      <p>{{ $t('bad_file_format.instructions') }}</p>
    </template>
  </div>
</template>

<script>
import Routes from 'routes'

export default {
  name: 'ImportErrors',
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
  computed: {
    hasError() {
      return this.errors.length > 0
    },
    hasAnotherRunningImport() {
      return !!this.errors.find((e) => e === this.$t('another_import_running'))
    },
  },
}
</script>
