import messages from './i18n/messages'
import SessionsNew from './components/SessionsNew'
import { createLzRouter } from 'vue_features/shared/router'
import TeacherLogin from 'vue_features/shared/components/login/TeacherLogin'
import StudentLogin from 'vue_features/shared/components/login/StudentLogin'

export default function () {
  const router = createLzRouter({
    routes: [
      { path: '/login', component: TeacherLogin },
      { path: '/login/teacher-login', name: 'teacher-login', component: TeacherLogin, props: { hasGreeting: false } },
      { path: '/login/student-login', name: 'student-login', component: StudentLogin, props: { hasGreeting: false } },
    ],
  })

  return { component: SessionsNew, messages, router }
}
