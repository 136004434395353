import { isEqual, last } from 'lodash'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { reactive, computed } from 'vue'

import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export const useAnnotationToolStore = defineStore('annotationTool', () => {
  const state = reactive({
    isAnnotating: false,
    mode: '',
    strokeColor: null,
    selectedShape: null,
    showAnnotations: true,
    showAnnotationTools: false,
  })

  function toggleAnnotationTools(liveLearnSubscription) {
    const { authorizedAsTeacher } = useCurrentUserStore()

    state.showAnnotationTools = !state.showAnnotationTools
    state.isAnnotating = false
    state.mode = ''

    if (liveLearnSubscription && authorizedAsTeacher.value) {
      liveLearnSubscription.perform('update_annotation_state', {
        showAnnotationTools: state.showAnnotationTools,
        showAnnotations: state.showAnnotations,
      })
    }
  }

  function setAnnotationMode(newMode) {
    if (newMode === 'interact') {
      state.isAnnotating = false
    } else {
      state.isAnnotating = true
      state.showAnnotations = true
    }
    state.mode = newMode
  }

  function toggleShowAnnotations(liveLearnSubscription) {
    const { authorizedAsTeacher } = useCurrentUserStore()

    state.showAnnotations = !state.showAnnotations

    if (!state.showAnnotations) {
      setAnnotationMode('interact')
      state.isAnnotating = false
    }

    if (liveLearnSubscription && authorizedAsTeacher.value) {
      liveLearnSubscription.perform('update_annotation_state', {
        showAnnotations: state.showAnnotations,
      })
    }
  }

  const allowAnnotations = computed(() => {
    const { authorizedAsTeacher } = useCurrentUserStore()

    return authorizedAsTeacher.value
  })

  function checkUnload(event) {
    event.preventDefault()
    return (event.returnValue = 'You have annotations that will be lost. Are you sure you want to leave?')
  }

  function saveUndo(stateLoader, canvasState) {
    const lastState = last(history)

    if (
      stateLoader !== lastState?.stateLoader ||
      lastState == undefined ||
      !isEqual(canvasState, lastState.canvasState)
    ) {
      history.push({ stateLoader, canvasState })
      addEventListener('beforeunload', checkUnload, { capture: true })
    }
  }

  function loadUndo() {
    const lastState = history.pop()

    if (lastState != undefined) {
      const { stateLoader, canvasState } = lastState

      stateLoader(canvasState)
    }
  }

  function checkUndo(canvas) {
    const json = canvas.toJSON()
    const lastState = last(history)

    if (lastState != undefined && isEqual(json, lastState.json)) {
      history.pop()
    }
  }

  return {
    state,
    allowAnnotations,
    checkUndo,
    loadUndo,
    saveUndo,
    setAnnotationMode,
    toggleAnnotationTools,
    toggleShowAnnotations,
  }
})
