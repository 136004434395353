<template>
  <div class="w-full">
    <div class="border-primary-100 mb-4 rounded-2xl border shadow-sm">
      <table class="mb-0 table-fixed">
        <thead>
          <tr class="text-md font-bold">
            <th class="border-0 px-4 py-2">
              {{ $t('common.name') }}
            </th>
            <th class="border-0 px-4 py-2">
              {{ $t('common.email') }}
            </th>
            <th class="border-0 px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="teacher in teachers"
            :key="teacher.id"
            :class="teacher.inKlassViaClever ? 'bg-base' : 'bg-focus'"
            :data-test="`people-teacher-list-row-${teacher.id}`"
          >
            <td class="w-1/3 border-0 border-t px-4 align-middle text-base text-sm font-bold">
              <span>
                {{ teacher.studentFacingName || teacher.name }}
                <template v-if="teacher.archived">
                  <span class="inline-flex items-center rounded-full bg-gray-400 px-2 py-1 text-xs text-white">
                    <LzIcon path="icons/archive" />
                    <span>{{ $t('klasses.archived') }}</span>
                  </span>
                </template>
              </span>
            </td>
            <td class="gray-600 h-12 w-1/3 border-0 border-t px-4 align-middle text-sm font-normal">
              <span>{{ teacher.email }}</span>
            </td>
            <td class="h-12 w-1/3 border-0 border-t px-4 align-middle">
              <div class="flex w-full items-center justify-end">
                <template v-if="teacher.inKlassViaClever">
                  <LzIcon data-test="clever-image" path="icons/synced-arrows" size="sm" class="text-neutral-600" />
                </template>
                <LzButton
                  v-if="teachers.length > 1 && teacher.id === currentUserId && !teacher.inKlassViaClever"
                  size="small"
                  data-test="leave-button"
                  @click="$emit('remove-me-from-klass', teacher)"
                >
                  <LzIcon path="icons/logout" />
                  <span class="hidden md:inline">{{ $t('klasses.leave') }}</span>
                </LzButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { LzButton, LzIcon } from 'vue_features/shared/components/ui'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'PeopleTeacherList',
  components: {
    LzButton,
    LzIcon,
  },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { id: currentUserId } = useCurrentUserStore()
    return { currentUserId }
  },
}
</script>
