import onReadyLzuiBootstrap from './lzui_bootstrap'
import onReadyToggleDropdown from './toggle_dropdowns'
import onReadyCreateVueFeatures from './create_vue_features'

import onReadyResponsiveNavigation from './responsive_navigation'

import onReadyDebug from './debug'

import { onReady as onReadyCkeditor } from 'ckeditor_extensions/custom_ckeditor'

import initDatadogRum from './datadog_rum'

initDatadogRum()

export default function () {
  window.addEventListener('DOMContentLoaded', () => {
    onReadyLzuiBootstrap()
    onReadyToggleDropdown()
    onReadyResponsiveNavigation()
    onReadyCreateVueFeatures()
    onReadyDebug()
    onReadyCkeditor()
  })
}
