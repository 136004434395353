window.CKEDITOR.plugins.setLang('lztable', 'en', {
  lztablecolumn: 'Column',
  addColumnRight: 'Add column to right',
  addColumnLeft: 'Add column to left',
  deleteColumn: 'Delete column',
  lztablerow: 'Row',
  addRowBelow: 'Add row below',
  addRowAbove: 'Add row above',
  deleteRow: 'Delete row',
  makeRowHeader: 'Make header',
  removeRowHeader: 'Remove header',
  makeColumnHeader: 'Make header',
  removeColumnHeader: 'Remove header',
})
