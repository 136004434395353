export function currentSeason() {
  const d = new Date()
  const fullYear = d.getFullYear()
  const isLeapYear = fullYear % 4 === 0
  const seasonArray = [
    { name: 'spring', date: new Date(fullYear, 2, isLeapYear ? 19 : 20).getTime() },
    { name: 'summer', date: new Date(fullYear, 5, isLeapYear ? 20 : 21).getTime() },
    { name: 'fall', date: new Date(fullYear, 8, isLeapYear ? 22 : 23).getTime() },
    { name: 'winter', date: new Date(fullYear, 11, isLeapYear ? 20 : 21).getTime() },
  ]
  const season = seasonArray.filter(({ date }) => date <= d).slice(-1)[0] || { name: 'winter' }
  return season.name
}
