<template>
  <div v-if="klasses && klasses.length > 0">
    <AssignmentsDeleteModal v-if="canModalShow($options.AssignmentsDeleteModal)" :on-success="handleDeleteSuccess" />
    <AssignmentsArchiveModal v-if="canModalShow($options.AssignmentsArchiveModal)" />
    <AssignmentsUnarchiveModal v-if="canModalShow($options.AssignmentsUnarchiveModal)" />
    <AssignmentsErrorModal v-if="canModalShow($options.AssignmentsErrorModal)" />
    <AssignmentsErrorUnarchiveModal v-if="canModalShow($options.AssignmentsErrorUnarchiveModal)" />

    <AnnouncementBanner
      id="assignment-scheduling"
      :show-count-limit="4"
      :background-image="require('images/banner_backgrounds/student-banner.jpeg')"
      :heading="$t('assignment_report.scheduling_announcement.heading')"
      :body="$t('assignment_report.scheduling_announcement.body')"
      :cta-url="HELP_ASSIGNMENT_SCHEDULING_GUIDE_URL"
      :cta-label="$t('assignment_report.scheduling_announcement.cta_label')"
      class="mb-4 rounded-lg"
    />
    <div class="clear-both space-y-4">
      <div class="border-primary-100 overflow-hidden rounded-lg border border-solid bg-white p-3">
        <ScoringLegend
          include-needs-grading
          include-no-score
          :no-score-label="$t('assignment_report.legend.not_complete')"
        />
      </div>
      <div v-if="beyondCreatedStateOnLoad && !assignment.hasQuestions">
        <Alert
          type="notice"
          :title="$t('assignment_report.no_grading_required_title')"
          :description="$t('assignment_report.no_grading_required_description')"
          dismissible
        />
      </div>
      <div id="assignment-reports" class="space-y-4">
        <AssignmentReport v-for="(klass, index) in klasses" :key="klass.id" :klass="klass" :position="index" />
      </div>
    </div>

    <LinkStudentToGoogleModal
      v-if="canModalShow($options.LinkStudentToGoogleModal)"
      :klass="activeGoogleStudentKlass"
      :student-id="activeGoogleStudentId"
      :student-name="activeGoogleStudentName"
      :google-student="activeGoogleStudent"
      @google-classroom-linked-student="handleLinkedStudentChange"
      @google-classroom-unlinked-student="handleLinkedStudentChange"
    />
    <LinkErrorListener />
    <ScheduleAssignmentModal v-if="canModalShow($options.ScheduleAssignmentModal)" />
  </div>
  <EmptyStateWithIcon
    v-else-if="hasLoadedClasses"
    path="icons/chalkboard"
    :heading="$t('assignment_report.you_need_access')"
    :body="$t('assignment_report.ask_a_teacher_to_add_you')"
    data-test="empty-state-student-submissions"
  >
    <LzButton tag="a" :href="Routes.lesson_plan_path(lessonPlan.id)" color="primary">
      {{ $t('assignment_report.view_lesson') }}
    </LzButton>
  </EmptyStateWithIcon>
</template>

<script>
import { inject, provide } from 'vue'
import { useAssignmentStore } from 'vue_features/assignments/show/composables'
import { Alert, AnnouncementBanner, LzButton, EmptyStateWithIcon } from 'vue_features/shared/components/ui'
import AssignmentReport from './assignment_report/AssignmentReport'
import ScoringLegend from 'vue_features/shared/components/ScoringLegend'
import LinkStudentToGoogleModal from './assignment_report/LinkStudentToGoogleModal'
import ScheduleAssignmentModal from 'vue_features/assignments/reports/components/ScheduleAssignmentModal'
import LinkErrorListener from 'vue_features/shared/components/google_classroom/LinkErrorListener'
import { openModal, canModalShow, activeModal } from 'vue_features/shared/composables/use_global_modals'
import { location as $location } from 'utils'
import { HELP_ASSIGNMENT_SCHEDULING_GUIDE_URL } from 'rails_constants'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useAssignmentReportStore } from 'vue_features/assignments/reports/store'
import {
  AssignmentsDeleteModal,
  AssignmentsArchiveModal,
  AssignmentsUnarchiveModal,
  AssignmentsErrorModal,
  AssignmentsErrorUnarchiveModal,
} from 'vue_features/assignments/shared/components/modals'
import Routes from 'routes'

const POLLING_DELAY_IN_MS = 15 * 1000

export default {
  name: 'AssignmentReports',
  AssignmentsDeleteModal,
  AssignmentsArchiveModal,
  AssignmentsUnarchiveModal,
  AssignmentsErrorModal,
  AssignmentsErrorUnarchiveModal,
  LinkStudentToGoogleModal,
  ScheduleAssignmentModal,
  components: {
    Alert,
    AnnouncementBanner,
    AssignmentReport,
    LzButton,
    EmptyStateWithIcon,
    ScoringLegend,
    AssignmentsDeleteModal,
    AssignmentsArchiveModal,
    AssignmentsUnarchiveModal,
    AssignmentsErrorModal,
    AssignmentsErrorUnarchiveModal,
    LinkStudentToGoogleModal,
    LinkErrorListener,
    ScheduleAssignmentModal,
  },
  setup() {
    const { lessonPlan } = useAssignmentStore()
    const {
      isConnectedToGoogle,
      courseAssignmentsData,
      loadStudentsForCourse,
      loadCourses,
      loadLzStudents,
      loadCourseAssignments,
    } = useGoogleClassroomStore()
    const { assignment, klasses, getKlassesForAssignment, reloadKlass, removeLzCodeKlass, gradePassbackStatus } =
      useAssignmentReportStore()

    const root = inject('useRoot')()
    root.$on('schedule-assignment', ({ klassId, assignmentViews }) => {
      openModal(ScheduleAssignmentModal, { klassId, assignmentViews })
    })

    provide('linkGoogleStudentInClass', ({ klass, studentId, studentName, googleStudent }) => {
      openModal(LinkStudentToGoogleModal, {
        klass,
        studentId,
        studentName,
        googleStudent,
      })
    })

    return {
      Routes,
      lessonPlan,
      isConnectedToGoogle,
      courseAssignmentsData,
      loadStudentsForCourse,
      loadCourses,
      loadLzStudents,
      loadCourseAssignments,
      assignment,
      klasses,
      getKlassesForAssignment,
      reloadKlass,
      gradePassbackStatus,
      openModal,
      canModalShow,
      activeModal,
      removeLzCodeKlass,
      HELP_ASSIGNMENT_SCHEDULING_GUIDE_URL,
    }
  },
  data() {
    return {
      hasLoadedClasses: false,
      beyondCreatedStateOnLoad: false,
    }
  },

  computed: {
    hasKlassesWithGoogleClassroom() {
      return this.klasses.some((k) => !!k.googleClassroomCourse)
    },

    sessionReadonly() {
      return this.activeModal.payload.readonly
    },

    sessionId() {
      return this.activeModal.payload.sessionId
    },

    selectedItemNumber() {
      return this.activeModal.payload.selectedItemNumber
    },

    activeGoogleStudentId() {
      return this.activeModal.payload.studentId
    },

    activeGoogleStudentName() {
      return this.activeModal.payload.studentName
    },

    activeGoogleStudentKlass() {
      return this.activeModal.payload.klass
    },

    activeGoogleStudent() {
      return this.activeModal.payload.googleStudent
    },
  },

  created() {
    this.getKlassesForAssignment().then(() => {
      this.hasLoadedClasses = true
      this.beyondCreatedStateOnLoad = this.klasses.some((k) => k.assignmentStatus !== 'created')
    })
  },

  mounted() {
    if (this.isConnectedToGoogle) {
      this.loadCourses()
      this.loadLzStudents()
      this.loadCourseAssignmentsWithPolling(this.assignment.id)
    }
  },

  methods: {
    handleLinkedStudentChange() {
      this.loadStudentsForCourse({ courseId: this.activeGoogleStudentKlass.googleClassroomCourse.courseId })
      this.loadLzStudents({ force: true })
      this.reloadKlass({ klassId: this.activeGoogleStudentKlass.id })
    },
    async loadCourseAssignmentsWithPolling(assignmentId) {
      await this.loadCourseAssignments({ lzCodeId: assignmentId, force: true })
      if (this.courseAssignmentsData.some((a) => a.pending)) {
        await new Promise((r) => setTimeout(r, POLLING_DELAY_IN_MS))
        await this.loadCourseAssignmentsWithPolling(assignmentId)
      }
    },
    async handleDeleteSuccess(klassId) {
      this.removeLzCodeKlass({ klassId })
      if (this.klasses.length == 0) {
        await new Promise(() => {
          $location.assign(Routes.teacher_klass_path(klassId))
        })
      }
    },
  },
}
</script>
