<template>
  <div
    class="bg-primary-900 fixed z-40 flex h-full w-full items-center justify-center border-8 border-green-300 bg-opacity-95"
    data-test="sharing-screen"
  >
    <div
      class="absolute bottom-0 left-1/2 z-20 flex -translate-x-1/2 transform items-center overflow-hidden rounded-t-lg"
      data-test="sharing-screen-toolbar"
    >
      <div v-if="authorizedAsTeacher" class="whitespace-nowrap bg-green-300 px-4 py-1 font-bold text-green-900">
        {{ $t('live_learn.sharing') }} {{ sharingStudentName }}
      </div>
      <div v-else class="whitespace-nowrap bg-green-200 px-4 py-1 font-bold text-green-900">
        {{ $t('live_learn.viewing_shared_example') }}
      </div>
      <button
        v-if="authorizedAsTeacher"
        class="bg-red-500 px-4 py-1 text-white"
        data-test="stop-sharing-button"
        @click="stopSharing"
      >
        {{ $t('live_learn.stop') }}
      </button>
    </div>

    <div
      class="relative z-10 overflow-auto rounded-lg bg-white p-8 transition duration-200 lg:h-5/6 lg:w-11/12"
      style="max-width: 95%; max-height: 95%"
    >
      <AssignmentCardDeck
        type="assignment"
        :cards="assignmentDisplayCards"
        :selected-card-id="selectedAssignmentCardId"
        :is-tool-open="false"
      />
    </div>
  </div>
</template>

<script>
import { provide, computed } from 'vue'
import {
  useLiveLearnStore,
  useLiveLearnChannel,
  useAssignmentCardsStore,
  useAssignmentStore,
} from 'vue_features/assignments/show/composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import AssignmentCardDeck from 'vue_features/assignments/show/components/content/assignment_cards/AssignmentCardDeck'

export default {
  name: 'SharingScreen',
  components: { AssignmentCardDeck },
  setup() {
    const { lessonPlanTextStyle } = useAssignmentStore()
    const { sharingStudentId, students } = useLiveLearnStore()
    const { stopSharing } = useLiveLearnChannel()
    const { assignmentDisplayCards, selectedAssignmentCardId } = useAssignmentCardsStore()
    const { authorizedAsTeacher } = useCurrentUserStore()

    provide('fontTextStyle', lessonPlanTextStyle)

    const sharingStudentName = computed(() => students.value[sharingStudentId.value]?.name)

    return {
      sharingStudentId,
      sharingStudentName,
      stopSharing,
      assignmentDisplayCards,
      selectedAssignmentCardId,
      authorizedAsTeacher,
    }
  },
}
</script>
