<template>
  <LzTooltip v-if="showTooltip">
    <PositionIndicatorButton :card="card" :ordinal="ordinal" @click="$emit('click', card.id)" />
    <template #content>
      <PositionIndicatorTooltip :card="card" />
    </template>
  </LzTooltip>
  <PositionIndicatorButton v-else :card="card" :ordinal="ordinal" @click="$emit('click', card.id)" />
</template>

<script>
import { computed, toRef } from 'vue'
import { LzTooltip } from 'vue_features/shared/components/ui'
import PositionIndicatorButton from './PositionIndicatorButton'
import PositionIndicatorTooltip from './PositionIndicatorTooltip'
import { useCardTypes } from 'vue_features/shared/composables'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'PositionIndicator',
  components: { LzTooltip, PositionIndicatorButton, PositionIndicatorTooltip },
  props: {
    card: {
      type: Object,
      required: true,
    },
    ordinal: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { isStudent } = useCurrentUserStore()
    const { isQuestionCard: isQuestion } = useCardTypes(toRef(props, 'card'))
    const showTooltip = computed(() => isQuestion.value && !isStudent.value)

    return { showTooltip }
  },
}
</script>
