<template>
  <div class="bg-primary-600 flex flex-grow items-center">
    <div class="flex flex-none self-stretch">
      <button
        class="inline-flex items-center px-4 text-center"
        :title="$t('previous_question')"
        @click="onPreviousItemButtonClick"
      >
        <LzIcon path="icons/chevron-left" size="lg" icon-color-class="text-white" />
      </button>
    </div>

    <div
      class="relative flex flex-grow items-center space-x-2 overflow-auto p-4"
      style="box-shadow: inset 2px 0px 8px #000030, inset -8px 0px 8px rgba(0, 0, 48, 0.4)"
    >
      <div
        class="absolute top-0 -translate-x-1/2 -translate-y-1 transform transition-all"
        :style="positionIndicatorStyles"
      >
        <LzIcon
          v-show="sessionItems.length > 0"
          path="icons/active-indicator"
          size="md"
          icon-color-class="text-white"
        />
      </div>

      <ItemBubble v-if="sessionItems.length === 0">
        <LoadingSpinner />
      </ItemBubble>

      <ItemBubble
        v-for="(item, index) of sessionItems"
        v-bind="itemBubbleProps(item)"
        :key="item.reference"
        ref="itemBubbleRefs"
        :data-test="`item-bubble-${index}`"
        @click="onItemBubbleClick(index)"
      >
        <span>{{ index + 1 }}</span>
      </ItemBubble>
    </div>

    <div class="flex flex-none self-stretch">
      <button
        class="inline-flex items-center px-4 text-center"
        :title="$t('next_question')"
        @click="onNextItemButtonClick"
      >
        <LzIcon path="icons/chevron-right" size="lg" icon-color-class="text-white" />
      </button>
    </div>

    <div class="flex flex-none self-stretch">
      <button
        class="hover:bg-accent inline-flex flex-col items-center justify-center px-6 text-white transition-colors"
        @click="onShowTipsModalButtonClick"
      >
        <LzIcon path="icons/sparkles" size="md" icon-color-class="text-white" />
        <span class="text-sm font-bold tracking-wide">{{ $t('tips') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { LzIcon, LoadingSpinner } from 'vue_features/shared/components/ui'
import ItemBubble from './ItemBubble'
import { useGradingStore } from '../composables/use_grading_store'
import { scoreThemeForItem } from '../utils/score_theme'

export default {
  name: 'ItemNavigation',
  components: { LzIcon, ItemBubble, LoadingSpinner },
  setup() {
    const { sessionItems, currentItemIndex, loadingSession, gotoPreviousItem, gotoNextItem, gotoItem, showTipsModal } =
      useGradingStore()

    const itemBubbleRefs = ref([])

    const itemBubbleProps = (item) => {
      const bubbleProps = { number: item.number, ringPercentage: item.progress }
      const scoreTheme = scoreThemeForItem(item, loadingSession.value)
      return { ...bubbleProps, ...scoreTheme }
    }

    const positionIndicatorStyles = computed(() => {
      const itemBubbleRef = itemBubbleRefs.value[currentItemIndex.value]
      const left = itemBubbleRef ? itemBubbleRef.$el.offsetLeft + itemBubbleRef.$el.clientWidth / 2 : 0
      return {
        left: `${left}px`,
      }
    })

    const onPreviousItemButtonClick = () => {
      gotoPreviousItem()
    }

    const onNextItemButtonClick = () => {
      gotoNextItem()
    }

    const onItemBubbleClick = (index) => {
      gotoItem(index)
    }

    const onShowTipsModalButtonClick = () => {
      showTipsModal.value = true
    }

    watch(
      () => currentItemIndex.value,
      (index) => {
        const itemBubbleRef = itemBubbleRefs.value[index]

        if (itemBubbleRef) {
          itemBubbleRef.$el.scrollIntoView({ behavior: 'smooth', inline: 'center' })
        }
      },
    )

    return {
      sessionItems,
      currentItemIndex,
      itemBubbleRefs,
      itemBubbleProps,
      positionIndicatorStyles,
      loadingSession,
      onPreviousItemButtonClick,
      onNextItemButtonClick,
      onItemBubbleClick,
      onShowTipsModalButtonClick,
    }
  },
}
</script>
