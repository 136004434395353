<template>
  <div class="mx-auto max-w-7xl p-2.5 md:p-3.5">
    <AssignmentReports />
  </div>
</template>

<script>
import AssignmentReports from 'vue_features/assignments/reports/components/AssignmentReports'

export default {
  name: 'AssignmentReportContent',
  components: { AssignmentReports },
}
</script>
