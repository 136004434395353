<template>
  <div class="flex flex-col space-y-4 pb-4 md:flex-row md:space-x-4 md:space-y-0">
    <CopyTypeOption
      v-model="copyType"
      type="personal"
      :image-url="require('../../../../assets/images/gifs/copy-unlinked.gif')"
      :unavailable="[3, 4]"
    />
    <template v-if="authorizedAsAuthor">
      <CopyTypeOption
        v-model="copyType"
        type="linked"
        :image-url="require('../../../../assets/images/gifs/copy-linked.gif')"
        :unavailable="[2]"
      />
      <CopyTypeOption
        v-model="copyType"
        type="unlinked"
        :image-url="require('../../../../assets/images/gifs/copy-unlinked.gif')"
        :unavailable="[3]"
      />
    </template>
    <hr class="border-0" />
  </div>
</template>

<script>
import CopyTypeOption from './CopyTypeOption'
import { ref, watchEffect } from 'vue'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'CopyTypePicker',
  components: { CopyTypeOption },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const copyType = ref(props.modelValue)
    watchEffect(() => emit('update:modelValue', copyType.value))
    const { authorizedAsAuthor } = useCurrentUserStore()
    return { copyType, authorizedAsAuthor }
  },
}
</script>
