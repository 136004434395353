<template>
  <LzConfirmModal :modal-id="modalId">
    <template #confirmBody>
      <p class="my-2">
        {{ $t('index.confirm_delete') }}
      </p>
    </template>
    <template #confirmButton>
      <button data-test="delete-confirm-btn" class="btn btn--primary caution" @click="destroy">
        {{ $t('common.delete') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { LzConfirmModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'DeleteCardModal',
  components: { LzConfirmModal },
  props: {
    modalId: {
      type: String,
      default: function () {
        return 'DeleteCardModal'
      },
    },
  },
  setup() {
    return { closeModal }
  },
  methods: {
    destroy() {
      this.closeModal(this.modalId)
      this.$emit('deleting')
    },
  },
}
</script>
