import Routes from 'routes'

/* eslint-disable no-multi-spaces */
const parameterize = (string) =>
  string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/g, ' ') // convert unwanted chars to spaces
    .replace(/\s\s+/g, ' ') // convert multi spaces to single space
    .replace(/\s/g, '-') // convert spaces to hyphens
    .replace(/--+/g, '-') // convert multi hyphens to single hyphen

export const toParam = (id, slug) => `${id}-${parameterize(slug)}`

const objectUrlGenerator = (object, attrs) => {
  return ({ action, kind = 'url' } = {}) => {
    const id = action ? null : object.idSlug || object.id
    // assumes include Concerns::Routeable in serializer
    const helper = [action, object.routeName, kind].filter(Boolean).join('_')
    return Routes[helper](id, attrs)
  }
}

export const newPolymorphicPath = (object, attrs) => objectUrlGenerator(object, attrs)({ action: 'new', kind: 'path' })

export const newPolymorphicUrl = (object, attrs) => objectUrlGenerator(object, attrs)({ action: 'new' })

export const polymorphicPath = (object, attrs) => objectUrlGenerator(object, attrs)({ kind: 'path' })

export const polymorphicUrl = (object, attrs) => objectUrlGenerator(object, attrs)()
