<template>
  <div class="bg-focus border-neutral-300 rounded-xl border border bg-white p-6 py-3 shadow-sm">
    <div>
      <header class="flex flex-row place-content-between items-end">
        <div class="mb-2">
          <h3 class="m-0" :class="{ 'pt-0': !description }">{{ title }}</h3>
          <div v-if="subtitle" class="text-xs text-gray-600">
            {{ subtitle }}
          </div>
        </div>
        <slot name="header" />
      </header>
    </div>
    <main v-if="loading">
      <div class="flex h-48 items-center">
        <LoadingSpinner size="large" />
      </div>
    </main>
    <main v-else-if="error" class="pt-4">
      <DataError :title="$t('error_loading_data_header')" :message="error" />
    </main>
    <main v-else-if="hasEnoughData" class="pt-4">
      <slot></slot>
    </main>
    <main v-else>
      <DataError :title="$t('not_enough_data_yet')" :message="$t('try_adjusting_filters')" />
    </main>
  </div>
</template>

<script>
import { LoadingSpinner } from 'vue_features/shared/components/ui'
import DataError from './DataError'

export default {
  name: 'ChartPane',
  components: {
    LoadingSpinner,
    DataError,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    hasEnoughData: {
      default: false,
      validator: () => true,
    },
  },
}
</script>
