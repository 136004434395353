<template>
  <div v-if="isDescriptionBlank" class="empty-state empty-state--component">
    <DescriptionModal v-if="canModalShow('DescriptionModal')" />
    <InlineSvg path="misc/placeholder-text.svg" />
    <p>{{ $t('about_this_lesson.lesson_description_help', { lesson_type: $t(`readable_types.${lessonType}`) }) }}</p>

    <button class="btn btn--primary" @click="openEditor">
      <LzIcon path="icons/add" />
      {{ $t('about_this_lesson.add_a_description') }}
    </button>
  </div>
  <div v-else>
    <DescriptionModal v-if="canModalShow('DescriptionModal')" />
    <div class="edit-only mx-auto max-w-7xl">
      <button data-test="edit-description-btn" class="btn btn--small float-right mb-4 ml-4" @click="openEditor">
        <LzIcon path="icons/edit" />
        {{ $t('about_this_lesson.edit_description') }}
      </button>
    </div>
    <div class="mx-auto max-w-7xl">
      <!-- description sanitized in serializer -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-mathjax class="lesson-plan__description-text ck-editable" itemprop="description" v-html="description" />
    </div>
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { LzIcon, InlineSvg } from 'vue_features/shared/components/ui'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import DescriptionModal from './DescriptionModal'
import { enableUnloadConfirm } from 'utils'

export default {
  name: 'DescriptionEditor',
  components: { DescriptionModal, LzIcon, InlineSvg },
  setup() {
    const { lessonPlan, lessonType, description, isDescriptionBlank } = useLessonPlanStore()

    const openEditor = () => {
      openModal('DescriptionModal')
      enableUnloadConfirm()
    }

    return { lessonPlan, description, isDescriptionBlank, openEditor, canModalShow, lessonType }
  },
}
</script>
