<template>
  <component
    :is="listComponent"
    v-bind="{
      itemId: item.id,
      title: item.title,
      href,
      target: item.type === 'Hyperlink' ? '_blank' : '',
      icon: getIcon(item),
      thumbnail: getThumbnail(item),
      description: item.description,
      metadata: item.metadata,
      thumbnailSize,
      thumbnailClass,
    }"
    :data-type="item.type"
    :data-view="item.view"
  >
    <template #handle>
      <slot name="handle" />
    </template>
    <template #title>
      <div class="flex gap-3">
        <p class="text-accent vertical flex min-w-0 max-w-full flex-1 align-middle font-bold leading-6">
          <span class="line-clamp-2">
            {{ item.title }}
          </span>
          <LzIcon
            v-if="item.type === 'Hyperlink'"
            path="icons/external"
            size="sm"
            class="text-muted group-hover:text-accent ml-1 mt-0.5 flex-shrink-0 opacity-50 transition group-hover:opacity-100"
          />
        </p>
        <div class="flex gap-1 print:gap-0 print:space-x-1">
          <LzContentTeacherOnlyBadge v-if="!authorizedAsTeacher" :teacher-only="item.teacherOnly" />
          <LzContentPremiumBadge :premium="item.premium" />
          <LzContentStateBadge :state="item.state" />
        </div>
      </div>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </component>
</template>

<script>
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import BlockItem from 'vue_features/shared/components/lists/BlockItem'
import LzContentTeacherOnlyBadge from './LzContentTeacherOnlyBadge'
import LzContentPremiumBadge from './LzContentPremiumBadge'
import LzContentStateBadge from './LzContentStateBadge'
import { LzIcon } from 'vue_features/shared/components/ui'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import MetadataByline from 'vue_features/shared/components/lists/MetadataByline'
import { ExternalLink } from 'vue_features/shared/components/ui'
import { computed } from 'vue'
import { getThumbnail, getIcon } from 'vue_features/shared/composables/use_content_type_metadata'
import { usePathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'

export default {
  name: 'LzContentItem',
  components: {
    BlockItem,
    DetailListItem,
    ExternalLink,
    LzContentTeacherOnlyBadge,
    LzContentStateBadge,
    LzContentPremiumBadge,
    LzIcon,
    MetadataByline,
  },
  props: {
    item: { type: Object, required: true },
    isBlock: { type: Boolean, default: false },
    thumbnailSize: { type: String, default: '' },
    thumbnailClass: { type: String, default: '' },
  },
  setup(props) {
    const { authorizedAsTeacher } = useCurrentUserStore()
    const listComponent = computed(() => (props.isBlock ? 'BlockItem' : 'DetailListItem'))
    const { urlWithCrumbs } = usePathCrumbsStore()
    const href = computed(() => urlWithCrumbs(props.item.href))
    return { authorizedAsTeacher, listComponent, href, getThumbnail, getIcon }
  },
}
</script>
