import { HTTP, Routes } from 'vue_features/shared/helpers/http_helper'

const fetchRouteWithFilters = (route, routeParams = {}) => {
  const url = Routes[route](routeParams)
  const fetchDefaults = { method: 'GET', credentials: 'include' }

  return (filterParams, fetchParams = {}) => {
    return HTTP.fetch(url, { query: filterParams, ...{ ...fetchDefaults, ...fetchParams } })
  }
}

const ItemAnalysisService = {
  getItemPreviewConfig: (learnosityItemReference, fetchParams = {}) => {
    const route = Routes.item_analysis_item_previews_path({
      learnosity_item_reference: learnosityItemReference,
      format: 'json',
    })
    return HTTP.fetch(route, fetchParams)
  },
  getAvailableGradeLevels: fetchRouteWithFilters('grade_levels_item_analysis_suggestions_path', { format: 'json' }),
  getSchoolSuggestions: fetchRouteWithFilters('schools_item_analysis_suggestions_path', { format: 'json' }),
  getTeacherSuggestions: fetchRouteWithFilters('teachers_item_analysis_suggestions_path', { format: 'json' }),
  getKlassSuggestions: fetchRouteWithFilters('klasses_item_analysis_suggestions_path', { format: 'json' }),
  getLessonPlanSuggestions: fetchRouteWithFilters('lesson_plans_item_analysis_suggestions_path', { format: 'json' }),
  getItemScoreCounts: fetchRouteWithFilters('item_analysis_score_counts_path', { format: 'json' }),
  getAssignmentItemScoreSummary: fetchRouteWithFilters('item_analysis_score_summary_path', { format: 'json' }),
  getAssignmentItemStandards: fetchRouteWithFilters('item_analysis_standards_path', { format: 'json' }),
  getAssignmentAverageSessionScore: fetchRouteWithFilters('item_analysis_average_score_path', { format: 'json' }),
  refreshData: () => {
    return HTTP.fetch(Routes.item_analysis_refresh_data_path(), { method: 'POST', credentials: 'include' })
  },
}

export default ItemAnalysisService
