<template>
  <button class="bg-inverted hover:bg-accent btn--insert border-2 border-white p-1 text-white">
    <LzIcon size="sm" path="icons/add" class="flex-shrink-0" />
  </button>
</template>

<script>
import LzIcon from 'vue_features/shared/components/ui/LzIcon'

export default {
  name: 'InsertBtn',
  components: { LzIcon },
}
</script>
