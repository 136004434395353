import { startsWith } from 'lodash'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'

const URL_REPLACEMENTS = [
  // Input:  https://docs.google.com/presentation/.../edit?usp=sharing#hash
  // Output: https://docs.google.com/presentation/.../embed#hash
  { matcher: /^(https:\/\/docs.google.com\/presentation)(.*)?(\/edit)(\?.*?)?(#.*)?$/, replacer: '$1$2/embed$5' },
]

const URL_REGEX = /(https?):\/\/[a-z0-9\-._~:/?#[\]@!$&'()*+,;=]+\.[a-z0-9\-._~:/?#[\]@!$%&'()*+,;=]+/i

export function applyUrlReplacements(originalUrl) {
  return URL_REPLACEMENTS.reduce(
    (url, replacement) => url.replace(replacement.matcher, replacement.replacer),
    originalUrl,
  )
}

export function extractUrl(text) {
  const matches = URL_REGEX.exec(text)
  return matches ? matches[0] : null
}

export function prependProtocol(url) {
  if (/^https?:\/\//.exec(url)) {
    return url
  }
  return 'http://' + url
}

export function isValidIframeSrc(url) {
  const domainRegEx = new RegExp(`.*${useCurrentCustomerStore().state.domain}`, 'i')
  return startsWith(url, 'https://') && !domainRegEx.exec(url)
}

export function sanitizePreviewUrl(url) {
  return extractUrl(url) || prependProtocol(url)
}
