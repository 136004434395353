import messages from './i18n/resource_show_messages'
import ResourceShow from 'vue_features/resources/shared/components/ResourceShow'
import { initCollectionMapStore } from 'vue_features/collection_map/composables/use_collection_map_store'
import { initPathCrumbsStore } from 'vue_features/resources/shared/composables/use_path_crumbs_store'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { useContactDetailsStore } from 'vue_features/shared/composables/use_contact_details_store'

export default function ({ state }) {
  if (state?.path) {
    initCollectionMapStore(state)
  }

  state.pathCrumbsState.currentResource = state.resourceShowState.resource
  initPathCrumbsStore(state.pathCrumbsState)

  useResourceShowStore().$set(state.resourceShowState)
  useContactDetailsStore().$set(state.contactState)

  return { component: ResourceShow, messages }
}
