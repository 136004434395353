<template>
  <div class="mb-6">
    <h5>{{ $t('action_menu.existing_copies') }}</h5>
    <table>
      <thead>
        <tr>
          <th>{{ $t('common.name') }}</th>
          <th>{{ $t('common.created') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="copy in visibleItems" :key="copy.id">
          <td>
            <a :href="Routes.lesson_plan_path(copy.id)">
              {{ copy.title }}
            </a>
          </td>
          <td>{{ timeAgo(copy.createdAt) }}</td>
        </tr>
        <tr v-if="canLoadMore">
          <td colspan="100%">
            <LoadMoreButton v-bind="{ isLoading }" @click="handleLoadMore" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import { computed, reactive } from 'vue'
import { Routes } from 'vue_features/shared/helpers/http_helper'

export default {
  name: 'CopiesLoadMoreLIst',
  components: { LoadMoreButton },
  props: {
    copiesData: { type: Object, required: true },
    original: { type: Object, required: true },
  },
  setup(props) {
    const loadedData = reactive({
      copies: [],
      meta: {
        pagination: { nextPage: 2 },
      },
    })

    const allCopies = computed(() => props.copiesData.copies.concat(loadedData.copies))
    const { visibleItems, canLoadMore, showMoreItems, isLoading } = useLoadMoreList({
      itemsRef: allCopies,
      totalCount: props.copiesData.meta.pagination.totalCount,
    })

    return {
      Routes,
      visibleItems,
      showMoreItems,
      canLoadMore,
      isLoading,
      loadedData,
      timeAgo,
    }
  },
  methods: {
    async loadMoreCopies(params) {
      const url = Routes.lesson_plan_lesson_plan_copies_path(this.original.id)

      const data = await HTTP.getJSON(url, params)
      this.loadedData.copies = this.loadedData.copies.concat(data.copies)
      this.loadedData.meta = data.meta
    },
    handleLoadMore() {
      this.showMoreItems((done) => {
        this.loadMoreCopies({ page: this.loadedData.meta.pagination.nextPage }).then(done)
      })
    },
  },
}
</script>
