import { computed } from 'vue'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'

export default function useCardActiveEdit(activeEdit) {
  const { isCreatingMetaEdits } = useLessonPlanEditStore()

  const canBeHidden = computed(() => {
    return isCreatingMetaEdits.value && !isEdited.value
  })
  const isEditable = computed(() => {
    return !isCreatingMetaEdits.value || isInserted.value
  })
  const isEdited = computed(() => {
    return !!activeEdit.value
  })
  const isHidden = computed(() => {
    return isEdited.value && activeEdit.value.type === 'CardHide'
  })
  const isInserted = computed(() => {
    return isEdited.value && activeEdit.value.type === 'CardInsert'
  })
  const hasUpdate = computed(() => {
    return activeEdit.value && activeEdit.value.lastUpdate
  })

  return { canBeHidden, isEditable, isEdited, isHidden, isInserted, hasUpdate }
}
