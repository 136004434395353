import { merge } from 'lodash'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { shared, components, lessonPlans, common, standards, contentTypeMetadata } from 'vue_features/shared/i18n'

export default merge(
  {},
  pickLocaleNamespace(shared, 'action_menu'),
  pickLocaleNamespace(shared, 'copyable_input'),
  pickLocaleNamespace(shared, 'lz_content_modal'),
  pickLocaleNamespace(components, 'embed_code_modal'),
  pickLocaleNamespace(lessonPlans, 'readable_types'),
  pickLocaleNamespace(lessonPlans, 'copies_banner'),
  namespaceLocaleObj(common, 'common'),
  namespaceLocaleObj(standards, 'standards'),
  namespaceLocaleObj(contentTypeMetadata, 'content_type_metadata'),
)
