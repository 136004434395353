import { createLzRouter } from 'vue_features/shared/router'
import messages from './i18n/messages'
import LessonPlanEdit from './components/LessonPlanEdit'
import AdditionalMaterialsEditor from './components/AdditionalMaterialsEditor'
import CardDeckEditor from './components/CardDeckEditor'
import DescriptionEditor from './components/DescriptionEditor'
import { initLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'

export default function ({ state }) {
  let editPath = 'edit'
  if (state.lessonPlanEditState.isCreatingMetaEdits) {
    editPath = 'card_edits'
  } else if (state.lessonPlanEditState.isRevising) {
    editPath = 'revision/edit'
  }

  const basePath = `/lesson_plans/:id/${editPath}`
  const routes = [
    { path: basePath, component: CardDeckEditor },
    { path: `${basePath}/lesson`, name: 'lesson', component: CardDeckEditor },
    { path: `${basePath}/additional_materials`, name: 'additional_materials', component: AdditionalMaterialsEditor },
  ]

  if (!state.lessonPlanEditState.isCreatingMetaEdits) {
    routes.push({ path: `${basePath}/description`, name: 'description', component: DescriptionEditor })
  }

  const router = createLzRouter({ routes })

  initLessonPlanEditStore(state)

  return { component: LessonPlanEdit, messages, router }
}
