<template>
  <ListDropdown
    id="link-google-course-dropdown"
    :open-dropdown="userCompletedAuthentication"
    data-test="google-classroom-course-linker"
    :lazy-list="true"
    class="my-4"
    :dropdown-classes="['google-course-dropdown max-h-64 overflow-y-scroll']"
    @dropdown-toggled="$event ? loadCourses() : undefined"
  >
    <template #button>
      <LzIcon path="icons/google-signin" />
      {{ $t('klasses.google_classroom.select_course') }}
      <LzIcon size="sm" path="icons/caret" />
    </template>
    <template #listItems>
      <template v-if="coursesData && !coursesLoading">
        <li v-for="courseData in coursesData" :key="courseData.courseId">
          <button :title="courseData.name" type="button" @click="$emit('link-course', courseData.courseId)">
            <LzIcon v-if="courseData.klassId" size="sm" path="icons/class-linked" />
            {{ courseData.name }}
            <template v-if="courseData.archived"> ({{ $t('common.archived') }}) </template>
          </button>
        </li>
      </template>
      <template v-else-if="coursesLoading">
        <li class="p-4"><LoadingSpinner /></li>
      </template>
    </template>
  </ListDropdown>
</template>

<script>
import { LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'LinkToGoogleCourseDropdown',
  components: {
    ListDropdown,
    LoadingSpinner,
    LzIcon,
  },
  props: {
    userCompletedAuthentication: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { coursesData, coursesLoading, loadCourses } = useGoogleClassroomStore()
    return { coursesData, coursesLoading, loadCourses }
  },
}
</script>
