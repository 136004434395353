<template>
  <div
    id="live-learn"
    ref="containerEl"
    :class="[
      'bg-primary-900 flex h-screen w-screen flex-col overflow-hidden',
      { 'teacher-view': authorizedAsTeacher },
      { 'selected-student': selectedStudent },
    ]"
  >
    <ConnectionDebug v-if="showConnectionDebug" />

    <template v-if="!isConnecting">
      <Coachmark
        v-if="canDismissibleShow($options.CM_STUDENTS_BUTTON) && authorizedAsTeacher"
        target="#people-button"
        position="top-end"
        @close="closeDismissible($options.CM_STUDENTS_BUTTON)"
      >
        <h4 class="mb-2 mt-0">{{ $t('live_learn.coachmark.view_student_work_header') }}</h4>
        <p>{{ $t('live_learn.coachmark.see_students_work') }}</p>
      </Coachmark>

      <Coachmark
        v-if="canDismissibleShow($options.CM_PEOPLE_DRAWER) && authorizedAsTeacher && studentsActive"
        class="-mr-4"
        target="#students-list-item-0"
        position="left"
        @close="closeDismissible($options.CM_PEOPLE_DRAWER)"
      >
        <h4 class="mb-2 mt-0">{{ $t('live_learn.coachmark.view_student_work_header') }}</h4>
        <p>{{ $t('live_learn.coachmark.click_to_see_work') }}</p>
      </Coachmark>

      <transition
        enter-active-class="transition-opacity duration-200 ease-in-out motion-reduce:transition-none"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-200 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <SharingScreen v-if="sharingStudentId" />
      </transition>

      <div
        class="max-h-full-24 flex flex-1 overflow-y-auto overflow-x-hidden transition-all"
        :class="{ 'pl-12': authorizedAsTeacher && showAnnotationTools }"
      >
        <div class="transition-spacing flex-1 px-2 pb-16 pt-2 duration-300" tabindex="0" style="overflow-y: overlay">
          <div v-if="assignment.itemPoolPending" class="mx-auto my-8 w-1/2 rounded-lg bg-white p-8">
            <ItemPoolPendingNotice />
          </div>
          <AssignmentCardDeckGroup v-else @assignment-card-changed="handleAssignmentCardChange" />
        </div>
        <TeachingNotesDrawer v-if="authorizedAsTeacher" :is-open="notesActive" :content="selectedAssignmentCardNotes" />
        <PeopleDrawer
          v-if="authorizedAsTeacher"
          :is-open="studentsActive"
          @assignment-card-changed="handleAssignmentCardChange"
        />
      </div>

      <LiveLearnToolbar
        :active-drawer="activeDrawer"
        @change-active-drawer="changeActiveDrawer"
        @assignment-card-changed="handleAssignmentCardChange"
      />

      <AnnotationTools
        v-if="allowAnnotations"
        :selected-card-index="assignmentDisplayCardsPosition"
        :cards="assignmentDisplayCards"
      />
    </template>

    <transition
      enter-from-class="opacity-0"
      enter-to-class="duration-500 transition-opacity delay-500 opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="opacity-0 duration-500 transition-opacity delay-500"
      @after-leave="() => connectingTeardownSignal.emit()"
    >
      <div v-if="isConnecting" class="z-100 fixed left-0 top-0 h-screen w-screen" data-test="loading-overlay">
        <div class="flex h-full w-full flex-col items-center justify-center p-10 duration-500">
          <LottieAnimation
            :data="() => import('lottie-animations/websockets-connection.json')"
            :teardown-signal="connectingTeardownSignal"
            autoplay
            loop
            class="h-auto w-1/2 md:w-1/4"
          />
          <p class="mx-auto select-none text-2xl font-bold text-white drop-shadow">
            {{ $t('live_learn.establishing_connection') }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useDismissibles } from 'vue_features/shared/composables'
import { ref, provide, computed } from 'vue'
import AssignmentCardDeckGroup from 'vue_features/assignments/show/components/content/assignment_cards/AssignmentCardDeckGroup'
import TeachingNotesDrawer from './TeachingNotesDrawer'
import PeopleDrawer from './PeopleDrawer'
import LiveLearnToolbar from './LiveLearnToolbar'
import SharingScreen from './SharingScreen'
import ConnectionDebug from './ConnectionDebug'
import {
  useAssignmentStore,
  useAssignmentCardsStore,
  useLiveLearnChannel,
  useLiveLearnStore,
} from 'vue_features/assignments/show/composables'
import { useAnnotationToolStore } from 'vue_features/shared/store/composables'
import AnnotationTools from 'vue_features/shared/components/AnnotationTools'
import Coachmark from 'vue_features/shared/components/ui/Coachmark'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { ItemPoolPendingNotice } from 'vue_features/assignments/shared/components'
import { useCardNav } from 'vue_features/lesson_plans/show/composables/use_card_nav'
import LottieAnimation from 'vue_features/shared/components/LottieAnimation'
import { Signal } from 'utils'

const CM_STUDENTS_BUTTON = 'live-learn-students-button'
const CM_PEOPLE_DRAWER = 'live-learn-students-drawer'

export default {
  name: 'LiveLearn',
  CM_STUDENTS_BUTTON,
  CM_PEOPLE_DRAWER,
  components: {
    AnnotationTools,
    AssignmentCardDeckGroup,
    TeachingNotesDrawer,
    LiveLearnToolbar,
    PeopleDrawer,
    Coachmark,
    ItemPoolPendingNotice,
    SharingScreen,
    LottieAnimation,
    ConnectionDebug,
  },
  setup(_, { emit }) {
    const activeDrawer = ref(null)
    const containerEl = ref(null)
    const { showDismissible, closeDismissible, canDismissibleShow } = useDismissibles()
    const { authorizedAsTeacher } = useCurrentUserStore()
    const { allowAnnotations, showAnnotationTools } = useAnnotationToolStore()
    const { assignment } = useAssignmentStore()
    const {
      selectedAssignmentCardNotes,
      getAssignmentCardPosition,
      assignmentDisplayCardsPosition,
      assignmentDisplayCards,
      state: assignmentCardsState,
    } = useAssignmentCardsStore()
    const { isTeacherPaced, selectedStudent, sharingStudentId, showConnectionDebug } = useLiveLearnStore()
    const { subscription, isConnecting } = useLiveLearnChannel()
    const { selectedCardIndex } = useCardNav()
    const connectingTeardownSignal = new Signal()

    selectedCardIndex.value = 0

    provide('fullscreenContainerEl', containerEl)

    function handleAssignmentCardChange(selectedAssignmentCardId) {
      const position = getAssignmentCardPosition(selectedAssignmentCardId)
      subscription.value.perform('update_user_state', { position })

      if (authorizedAsTeacher.value) subscription.value.perform('update_root_state', { position })
      if (!isTeacherPaced.value) assignmentCardsState.selectedAssignmentCardId = selectedAssignmentCardId
    }

    function changeActiveDrawer(newDrawerValue) {
      if (newDrawerValue === 'people') closeDismissible(CM_STUDENTS_BUTTON)
      activeDrawer.value = activeDrawer.value === newDrawerValue ? null : newDrawerValue
      if (newDrawerValue === 'people') setTimeout(() => showDismissible(CM_PEOPLE_DRAWER), 300)
    }

    const notesActive = computed(() => activeDrawer.value === 'notes')
    const studentsActive = computed(() => activeDrawer.value === 'people')

    function closeStudentDrawerCoachmark() {
      closeDismissible(CM_PEOPLE_DRAWER)
    }
    provide('closeStudentDrawerCoachmark', closeStudentDrawerCoachmark)

    showDismissible(CM_STUDENTS_BUTTON)

    return {
      assignmentDisplayCards,
      assignmentDisplayCardsPosition,
      activeDrawer,
      allowAnnotations,
      assignment,
      changeActiveDrawer,
      containerEl,
      isConnecting,
      handleAssignmentCardChange,
      notesActive,
      selectedAssignmentCardNotes,
      showAnnotationTools,
      studentsActive,
      closeDismissible,
      canDismissibleShow,
      authorizedAsTeacher,
      selectedStudent,
      sharingStudentId,
      showConnectionDebug,
      connectingTeardownSignal,
    }
  },
}
</script>
