<template>
  <li :class="['tree-item', { active: isActiveNode }]">
    <div class="tree-item__title flex gap-1">
      <LzIcon
        v-if="isWiki"
        :data-test="`toggle-${itemId}`"
        :class="['text-gray-800', { '-rotate-90': !isOpen }]"
        size="sm"
        path="icons/caret"
        @click="toggle"
      />
      <div>
        <LzIcon :path="iconPath" size="sm" />
        <a v-if="isActiveNode" :title="title" @click.prevent>{{ displayTitle }}</a>
        <a v-else :href="href" :title="title">{{ displayTitle }}</a>
      </div>
    </div>
    <ChildList v-if="isOpen" :item-id="itemId" :level="level" />
  </li>
</template>

<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'
import { truncate } from 'lodash'
import { computed } from 'vue'
import { useCollectionMapStore } from '../composables/use_collection_map_store'
import { defineAsyncComponent } from 'vue'

const ChildList = defineAsyncComponent(() => import('./ChildList'))

const props = defineProps({
  block: { type: Object, required: true },
  level: { type: Number, default: 0 },
})
const { activeItemId, toggleOpened, openedItems } = useCollectionMapStore()
const itemId = computed(() => {
  return props.block.content?.id?.toString() || ''
})
const isActiveNode = computed(() => {
  return itemId.value === activeItemId.value
})

const isWiki = computed(() => {
  return props.block.detailType === 'Wiki'
})

const isOpen = computed(() => {
  return isWiki.value && openedItems.value.some((o) => o.level === props.level && o.id === itemId.value)
})

const toggle = () => {
  toggleOpened(props.level, itemId.value)
}

const title = computed(() => {
  return props.block.content.lzContent.title
})
const href = computed(() => {
  return props.block.content.lzContent.href
})
const displayTitle = computed(() => {
  return truncate(title.value, { length: 50 })
})
const iconPath = computed(() => {
  return `icons/${templatePrefix.value}-no-circle.svg`
})
const templatePrefix = computed(() => {
  switch (props.block.detailType) {
    case 'Audio':
      return 'audio'
    case 'Document':
      return 'doc'
    case 'Html':
      return 'html'
    case 'Image':
      return 'image'
    case 'Hyperlink':
      return 'url'
    case 'Video':
      return 'video'
    case 'Wiki':
      return 'wikipage'
    case 'LessonPlan':
      return 'lp'
    default:
      return 'cs'
  }
})
</script>
