<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <DetailListItem :title="integration.name" :show-thumbnail="false" icon="integration">
    <template #actions>
      <a name="delete" class="btn btn--small caution mr-1" @click.prevent="$emit('delete', integration.id)">
        {{ $t('delete') }}
      </a>
    </template>
    <template #metadata>
      <ul class="list-metadata text-sm">
        <li>
          <strong>{{ $t('external_host') }}</strong>
          {{ integration.externalHost }}
        </li>
        <li>
          <strong>{{ $t('oidc_url') }}</strong>
          {{ callbackUrl }}
        </li>
        <li class="mb-6">
          <strong>{{ $t('canvas_lti_13_key') }}</strong>
          {{ integration.externalClientId }}
        </li>
        <li>
          <strong>{{ $t('iss') }}</strong>
          {{ integration.iss }}
        </li>
        <li>
          <strong>{{ $t('oidc_url') }}</strong>
          {{ integration.oidcAddress }}
        </li>
        <li>
          <strong>{{ $t('jwks_url') }}</strong>
          {{ integration.jwksAddress }}
        </li>
        <li>
          <strong>{{ $t('api_token_url') }}</strong>
          {{ integration.oauth2Address }}
        </li>
        <li>
          <strong>{{ configurationInfoTitle }}</strong>
          {{ configurationInfoUrl }}
        </li>
        <li>
          <strong>{{ $t('deployment_id') }}</strong>
          {{ integration.deploymentId }}
        </li>
        <li>
          <strong>{{ $t('date_created') }}</strong>
          {{ createdAt }}
        </li>
      </ul>
    </template>
  </DetailListItem>
</template>

<script>
import dayjs from 'dayjs'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import Routes from 'routes'

export default {
  name: 'LtiV13IntegrationListItem',
  components: {
    DetailListItem,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      return dayjs(this.integration.createdAt).format('MMM D, YYYY @k:mmA UTC')
    },
    configurationInfoTitle() {
      return this.$t('configuration_url')
    },
    callbackUrl() {
      return Routes.auth_callback_url({ provider: 'ltiv13' })
    },
    configurationInfoUrl() {
      return Routes.lti_v13_config_url('canvas', { format: 'json' })
    },
  },
}
</script>
