<template>
  <div
    :class="[
      'col-span-12 w-full',
      { hidden: isFullscreen, 'card__notes max-h-full lg:col-span-4': !isToolOpen, 'mt-3': isToolOpen },
    ]"
  >
    <div class="card__notes-wrapper h-full">
      <div class="card__notes-head card__teaching-notes-head">
        <span>{{ $t('lesson_plans.card.teaching_notes') }}</span>
      </div>
      <div class="card__notes-body">
        <!-- notes sanitized in serializer -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div ref="notesEl" v-mathjax v-html="notes" />
        <template v-if="isLoggedIn">
          <div v-if="showStandards && standardsHtml" class="space-x-1">
            <strong>{{ $t('common.label.standards') }}</strong>
            <span>{{ standardsHtml }}</span>
          </div>
          <div v-if="isItemCard && scoringType" class="space-x-1">
            <strong>{{ $t('common.scoring_type') }}</strong>
            <span>{{ scoringTypeLabel }}</span>
          </div>
          <div v-if="availablePoints.length > 0" class="space-x-1">
            <strong>{{ $t('common.label.total_points') }}</strong>
            <span>{{ pointsDisplay }}</span>
          </div>
        </template>
        <div v-if="hasScoringRubric" class="card__notes__scoring-rubric">
          <div class="item-standards">
            <ScoringRubricLink :rubric-url="scoringRubricUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRef, inject } from 'vue'
import useLearnosityItemMetadata from 'vue_features/resources/learnosity_items/composables/use_learnosity_item_metadata'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import ScoringRubricLink from 'vue_features/shared/components/ScoringRubricLink'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useResourceTypes } from 'vue_features/shared/composables'
import { useCrumbableLinks } from 'vue_features/resources/shared/composables/use_crumbable_links'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import Routes from 'routes'
import { isActive } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'CardNotes',
  components: { ScoringRubricLink },
  props: {
    notes: {
      type: String,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const root = inject('useRoot')()
    const { openTool, isToolOpen } = useLessonPlanStore()
    const { isFullscreen } = useFullscreenStore()

    const { isQuestion: isItemCard } = useResourceTypes(toRef(props, 'content'))
    const { isPublicCustomer, showStandards } = useCurrentCustomerStore()
    const { isLoggedIn } = useCurrentUserStore()
    const standards = computed(() => (isItemCard.value ? props.content.standards : []))
    const customerVisibleStandards = computed(() => (isItemCard.value ? props.content.customerVisibleStandards : []))

    const contentAvailable = computed(() => isActive(props.content) || props.content.editable)

    const { scoringType, availablePoints } =
      isItemCard.value && contentAvailable.value
        ? useLearnosityItemMetadata(props.content.content, props.content.lrnoPreviewConfig)
        : { scoringType: ref(''), availablePoints: ref([]) }

    const currentInitiativeStandards = computed(() => {
      const withCurrentInitiative = isPublicCustomer.value ? standards.value : customerVisibleStandards.value
      return withCurrentInitiative.map((s) => s.displayCode)
    })
    const standardsHtml = computed(() => currentInitiativeStandards.value.join(', '))

    const scoringTypeLabel = computed(() => {
      switch (scoringType.value) {
        case 'dependent':
          return root.$t('common.ebsr_dependent')
        case 'per-question':
          return root.$t('common.per_question')
        default:
          return root.$t(`common.${scoringType.value}`)
      }
    })

    const pointsDisplay = computed(() => {
      const round = (p) => Math.round(p * 100) / 100
      const points = availablePoints.value
      if (points.length === 1) {
        return round(points[0])
      }

      const total = points.reduce((sum, p) => sum + p, 0)
      return `${round(total)} (${points.map((p) => round(p)).join(',')})`
    })

    const hasScoringRubric = computed(() => props.content && props.content.scoringRubricId)
    const scoringRubricUrl = computed(() => {
      return hasScoringRubric.value ? Routes.resource_url(props.content.scoringRubricId) : ''
    })

    const notesEl = ref(null)
    useCrumbableLinks(notesEl)
    return {
      isLoggedIn,
      isToolOpen,
      isItemCard,
      isPublicCustomer,
      showStandards,
      standards,
      standardsHtml,
      scoringType,
      scoringTypeLabel,
      availablePoints,
      pointsDisplay,
      hasScoringRubric,
      scoringRubricUrl,
      openTool,
      isFullscreen,
      notesEl,
    }
  },
}
</script>
