<template>
  <tr class="flex h-16 items-stretch">
    <td class="flex w-auto flex-grow items-center bg-transparent font-bold">
      {{ share.customerSubdomain }}.ilclassroom.com</td
    >
    <td v-if="processing" class="flex w-40 items-center bg-transparent">
      <div class="loading mx-0">
        <InlineSvg path="icons/loading-spinner" />
      </div>
    </td>
    <td v-else-if="isRejected" class="flex w-40 items-center bg-transparent">
      <button class="btn btn--small" @click.once="resendInvite">{{ $t('resend_invite') }}</button>
    </td>
    <td v-else class="flex w-40 items-center bg-transparent">{{ capitalize(translatedStatus) }}</td>
    <td class="flex flex-shrink-0 items-center bg-transparent">
      <DeleteShareButton @delete-share="$emit('delete', share)" />
    </td>
  </tr>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import { InlineSvg } from 'vue_features/shared/components/ui'
import { capitalize } from 'lodash'
import DeleteShareButton from './DeleteShareButton'

export default {
  name: 'SharingTableItem',
  components: { InlineSvg, DeleteShareButton },
  props: {
    share: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateCollectionShare } = useCollectionsIndexStore()
    return { updateCollectionShare }
  },
  data() {
    return { processing: false }
  },
  computed: {
    isRejected() {
      return this.share.status === 'rejected'
    },
    translatedStatus() {
      return this.$t(this.share.status)
    },
  },
  methods: {
    capitalize,
    async resendInvite() {
      this.processing = true
      await this.updateCollectionShare({ share: this.share, status: 'pending' })
      this.processing = false
    },
  },
}
</script>
