<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <FilterBar class="z-10 flex w-full items-center">
    <FilterDropdown
      name="who"
      portal-name="itemAnalysis"
      :label="$t('common.who')"
      :header="$t('who_filters_header')"
      :subhead="$t('who_filters_subhead')"
      :button-text="whoFilterTitle"
      button-class="max-w-sm"
    >
      <div class="z-10 mt-4 space-y-4">
        <div class="space-y-2">
          <label class="mb-0">{{ $t('common.grade') }}</label>

          <div
            v-if="!availableGradeLevelsForFilters || availableGradeLevelsForFilters.length === 0"
            class="bg-orange-50 flex flex-row justify-start rounded-lg p-2"
          >
            <LzIcon path="icons/synced-arrows" size="md" class="mr-2" />
            <p class="text-orange-900 mt-0.5 flex flex-col text-sm font-semibold leading-5">
              {{ $t('sis_grades_not_available') }}
              <span class="font-normal">{{ $t('sis_use_roster_sync') }}</span>
            </p>
          </div>

          <CheckButtons show-reset :value="filters.gradeLevel" @input="setGradeLevels">
            <CheckButtonOption
              v-for="gradeLevel in allGradeLevels"
              :key="`grade-level-${gradeLevel}`"
              :value="gradeLevel"
              :disabled="!availableGradeLevelsForFilters.includes(gradeLevel)"
            >
              {{ gradeLevel }}
            </CheckButtonOption>
          </CheckButtons>
        </div>

        <div>
          <label>{{ $t('common.school') }}</label>
          <v-select
            :value="filters.school"
            :reduce="(school) => school.id"
            :options="schoolFilterOptions"
            :placeholder="$t('filters.select_school_placeholder')"
            label="name"
            multiple
            @update:modelValue="setSchool"
          />
        </div>

        <div>
          <label>{{ $t('common.teacher') }}</label>
          <v-select
            :value="filters.teacher"
            :reduce="(teacher) => teacher.id"
            :options="teacherFilterOptions"
            :placeholder="teachersPlaceholder"
            :disabled="!enableTeacherSelect"
            label="name"
            multiple
            @update:modelValue="setTeacher"
          />
        </div>

        <div>
          <label>{{ $t('common.class') }}</label>
          <v-select
            :value="filters.klass"
            :reduce="(klass) => klass.id"
            :options="klassFilterOptions"
            :placeholder="klassesPlaceholder"
            :disabled="!enableKlassSelect"
            label="name"
            multiple
            @update:modelValue="setKlass"
          />
        </div>
      </div>
    </FilterDropdown>

    <FilterDropdown
      name="what"
      portal-name="itemAnalysis"
      :label="$t('common.what')"
      :header="$t('what_filters_header')"
      :subhead="$t('what_filters_subhead')"
      :button-text="whatFilterTitle"
      button-class="max-w-sm"
    >
      <div class="mt-6 flex flex-row flex-wrap justify-start gap-x-4 gap-y-2">
        <div>
          <label class="block">{{ $t('grading_status') }}</label>
          <ToggleButton name="status" :value="gradingStatus" @input="setGradingStatus">
            <ToggleButtonOption :value="null">{{ $t('common.all') }}</ToggleButtonOption>
            <ToggleButtonOption value="graded">{{ $t('common.graded') }}</ToggleButtonOption>
          </ToggleButton>
        </div>
      </div>
    </FilterDropdown>

    <FilterDropdown
      name="when"
      portal-name="itemAnalysis"
      :label="$t('common.when')"
      :header="$t('when_filters_header')"
      :subhead="$t('when_filters_subhead')"
      :button-text="whenFilterTitle"
      button-class="max-w-sm"
    >
      <DateRangeSelect
        :value="dateRange"
        class="mt-6 flex max-w-3xl flex-wrap justify-center"
        @update:model-value="setDateRange"
      />
    </FilterDropdown>

    <div class="flex flex-col gap-1" style="flex: 0 1 auto">
      <label class="mb-0 text-sm font-bold capitalize leading-none tracking-wide">{{ $t('assignment') }}</label>
      <LzButton class="bg-neutral-100 px-6" @click="showLessonPlanChooser = true">{{ $t('change') }}</LzButton>
    </div>

    <a
      :href="HELP_ITEM_ANALYSIS_GUIDE_URL"
      target="_blank"
      class="text-accent flex-end mt-6 flex cursor-pointer items-center gap-x-2 align-middle text-sm"
    >
      {{ $t('need_more_info') }}
      <LzIcon path="icons/external" size="sm" icon-color-class="text-accent" />
    </a>
  </FilterBar>
</template>

<script setup>
import {
  CheckButtons,
  CheckButtonOption,
  FilterBar,
  FilterDropdown,
  LzIcon,
  ToggleButton,
  ToggleButtonOption,
  LzButton,
} from 'vue_features/shared/components/ui'
import DateRangeSelect from 'vue_features/shared/components/selects/DateRangeSelect'
import { useItemAnalysisStore } from '../composables/use_item_analysis_store'
import { computed, inject, watchEffect } from 'vue'
import { sortGradeLevels } from '../utils'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { HELP_ITEM_ANALYSIS_GUIDE_URL } from 'rails_constants'
import { useRouter } from 'vue-router'

const root = inject('useRoot')()
const {
  availableGradeLevelsForFilters,
  allGradeLevels,
  dirty,
  filters,
  dateRange,
  setFilter,
  schoolFilterOptions,
  showLessonPlanChooser,
  teacherFilterOptions,
  klassFilterOptions,
  commitParams,
} = useItemAnalysisStore()

const gradingStatus = computed(() => filters.value.gradingStatus)

const enableTeacherSelect = computed(
  () => Boolean(filters.value.gradeLevel.length) || Boolean(filters.value.school.length),
)

const enableKlassSelect = computed(() => filters.value.teacher.length > 0)

const teachersPlaceholder = computed(() =>
  root.$t(enableTeacherSelect.value ? 'filters.select_placeholder' : 'filters.disabled_teachers_placeholder'),
)
const klassesPlaceholder = computed(() =>
  root.$t(enableKlassSelect.value ? 'filters.select_placeholder' : 'filters.disabled_klasses_placeholder'),
)

const setGradeLevels = (val) => {
  setFilter('gradeLevel')([val].flat())
}
const setGradingStatus = setFilter('gradingStatus')
const setSchool = setFilter('school')
const setTeacher = setFilter('teacher')
const setKlass = setFilter('klass')
const setDateRange = ({ start, end }) => {
  setFilter('afterDate')(start)
  setFilter('beforeDate')(end)
}

const stringifyArraySelections = (options, selections, pluralLabel) => {
  if (selections.length > 1) {
    return `${selections.length} ${pluralLabel}`
  } else {
    return options
      .filter((option) => selections.includes(String(option.id)))
      .map((option) => option.name)
      .join(', ')
  }
}

const longGradeLevelName = (gradeLevel) => {
  if (gradeLevel === 'K') {
    return root.$t('kindergarten')
  } else {
    const pluralRules = new Intl.PluralRules('en', { type: 'ordinal' })
    const pluralRule = pluralRules.select(gradeLevel)
    const suffix = root.$t(`common.ordinal_suffixes.${pluralRule}`)
    return `${gradeLevel}${suffix} ${root.$t('grade')}`
  }
}

const whoFilterTitle = computed(() => {
  const gradeLevelString = stringifyArraySelections(
    allGradeLevels.value.map((gradeLevel) => ({ id: gradeLevel, name: longGradeLevelName(gradeLevel) })),
    sortGradeLevels(filters.value.gradeLevel),
    root.$t('grade_levels'),
  )
  const schoolString = stringifyArraySelections(schoolFilterOptions.value, filters.value.school, root.$t('schools'))
  const teacherString = stringifyArraySelections(teacherFilterOptions.value, filters.value.teacher, root.$t('teachers'))
  const klassString = stringifyArraySelections(klassFilterOptions.value, filters.value.klass, root.$t('classes'))

  return [gradeLevelString, schoolString, teacherString, klassString].filter((string) => string !== '').join(', ')
})

const whatFilterTitle = computed(() => {
  const gradingStatusTitle = gradingStatus.value === 'graded' ? root.$t('graded') : root.$t('graded_and_ungraded')

  return [gradingStatusTitle].filter((title) => title).join(', ')
})

const whenFilterTitle = computed(() => {
  if (dateRange.value.start && dateRange.value.end) {
    return `${dayjs(dateRange.value.start).format('MMM D, YYYY')} - ${dayjs(dateRange.value.end).format('MMM D, YYYY')}`
  }
  return root.$t('common.nothing_selected')
})

const router = useRouter()
function applyFilters() {
  commitParams(router)
}

watchEffect(() => {
  if (dirty.value) applyFilters()
})
</script>
