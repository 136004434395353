<template>
  <LzModal :modal-title="$t('klasses.edit_class_modal.title')" :modal-id="modalId" class="lzui-modal--medium" autoclose>
    <div class="form-group">
      <label for="klass-name">{{ $t('klasses.new_class_modal.name_label') }}</label>
      <span v-if="hasNameBlankError" data-test="name-blank-error" role="alert" class="inline-help red">
        <span class="sr-only">{{ $t('klasses.new_class_modal.name_label') }}</span>
        {{ $t('klasses.new_class_modal.error_blank') }}
      </span>
      <span v-if="hasNameTooLongError" data-test="name-length-error" role="alert" class="inline-help red">
        <span class="sr-only">{{ $t('klasses.new_class_modal.name_label') }}</span>
        {{ $t('klasses.new_class_modal.error_length') }}
      </span>
      <input
        id="klass-name"
        v-model="newName"
        :placeholder="$t('klasses.new_class_modal.name_placeholder')"
        class="form-control"
        type="text"
        @keypress.enter="submit"
      />
    </div>
    <footer>
      <LoadingSpinner v-if="updating" />
      <template v-else>
        <button class="btn" @click.stop="hideModal">
          {{ $t('common.cancel') }}
        </button>
        <button data-test="klass-update-submit" class="btn btn--primary ml-2" @click="submit">
          {{ $t('common.save') }}
        </button>
      </template>
    </footer>
  </LzModal>
</template>

<script>
import { LoadingSpinner, LzModal } from 'vue_features/shared/components/ui'
import NameValidator from './name_validator_mixin'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useKlassesIndexStore } from 'vue_features/klasses/index/store/use_klasses_index_store'
import { useKlassShowStore } from 'vue_features/klasses/show/store'

export default {
  name: 'KlassUpdateModal',
  components: { LoadingSpinner, LzModal },
  mixins: [NameValidator],
  props: {
    klass: {
      type: Object,
      required: true,
    },
    onShowPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalId: {
      type: String,
      default: function () {
        return 'KlassUpdateModal'
      },
    },
  },
  setup() {
    const { updateKlassName: updateKlassIndexName } = useKlassesIndexStore()
    const { updateKlassName: updateKlassShowName } = useKlassShowStore()
    return { updateKlassIndexName, updateKlassShowName, closeModal }
  },
  data() {
    return {
      newName: this.klass.name,
      updating: false,
    }
  },
  methods: {
    hideModal() {
      this.closeModal(this.modalId)
    },
    submit() {
      if (!this.checkForErrors(this.newName)) {
        this.updating = true
        const payload = { klass: this.klass, name: this.newName },
          updateKlassName = this.onShowPage ? this.updateKlassShowName : this.updateKlassIndexName

        updateKlassName(payload).then(() => {
          this.hideModal()
        })
      }
    },
  },
}
</script>
