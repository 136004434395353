<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div>
    <div class="page-head my-4 pb-4">
      <SharedForm :resource="resource" disable-default="md:px" @update="$emit('update', $event)" />
    </div>
    <div v-if="!showBuilder" class="align-right max-w-7xl">
      <a class="small cursor-pointer" @click="showBuilder = true">
        {{ $t('resources.form_template.edit_info') }}
      </a>
    </div>
    <component
      :is="resourceBuilder"
      v-if="showBuilder"
      :resource="resource"
      :can-add-contents="false"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import useResourceBuilder from '../composables/use_resource_builder'
import { isWiki } from 'vue_features/shared/composables/use_resource_types'
import SharedForm from './SharedForm'

export default {
  name: 'ResourceFormTemplate',
  components: { SharedForm },
  props: { resource: { type: Object, required: true } },
  setup(props) {
    const showBuilder = ref(!isWiki(props.resource))

    const { resourceBuilder } = useResourceBuilder(props.resource.type)

    return {
      resourceBuilder,
      showBuilder,
    }
  },
}
</script>
