<template>
  <button
    v-if="showButton"
    type="button"
    data-test="create-live-learn-button"
    class="flex items-center gap-x-2"
    :disabled="disabled"
    @click="launch"
  >
    <LzIcon :size="iconSize" path="icons/live" />
    {{ $t('live_learn_prompt.live_learn') }}
  </button>
</template>

<script>
import { computed, inject } from 'vue'
import { LzAnalytics } from 'clients'
import { LzIcon } from 'vue_features/shared/components/ui'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { useGlobalStore, useCurrentUserStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'

export default {
  name: 'LiveLearnButton',
  components: { LzIcon },
  props: {
    lzCode: { type: String, default: '' },
    iconSize: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const { isContentEmbedded } = useGlobalStore()
    const { isLoggedIn, authorizedAsTeacher } = useCurrentUserStore()
    const { hasStudentFunctionality } = useCurrentCustomerStore()
    const { isActive, analyticsProperties } = useLessonPlanStore()
    const eventSource = inject('eventSource', '')

    const showButton = computed(
      () => isActive.value && hasStudentFunctionality.value && authorizedAsTeacher.value && !isContentEmbedded.value,
    )
    const launch = () => {
      const eventName = 'Live Learn Button Clicked'
      const eventProperties = {
        ...(analyticsProperties.value || {}),
        action: eventName,
        label: isLoggedIn.value ? 'authenticated' : 'unauthenticated',
        category: 'assignments',
        'LZ Code': props.lzCode,
        source: eventSource,
      }
      LzAnalytics.track(eventName, eventProperties)
      if (isLoggedIn.value) openModal('LiveLearnModal')
    }

    return { showButton, launch }
  },
}
</script>
