import { camelCaseKeys, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { filter, isNumeric, startOfSchoolYear } from 'utils'
import { defaultScoreBandRanges } from 'vue_features/shared/utils/scoring'
import dayjs from 'dayjs'

export const sortGradeLevels = (grades) =>
  grades.sort((a, b) => {
    if (!isNumeric(a)) return -1
    return a - b
  })

export const stateFromParams = (params) => {
  return {
    lessonPlan: params.lesson_plan_id ? { id: params.lesson_plan_id } : null,
    filters: filtersFromParams(params),
  }
}

export const paramsFromState = (state) => {
  return {
    lesson_plan_id: state.lessonPlan?.id,
    ...paramsFromFilters(state.filters),
  }
}

export const formatDateFilter = (date) => dayjs(date).startOf('day').format('YYYY-MM-DD')

export const filtersFromParams = (params) =>
  camelCaseKeys(
    Object.entries(params).reduce((out, [key, value]) => {
      switch (key) {
        case 'after_date':
        case 'before_date':
          out[key] = dayjs(value).toDate()
          break
        case 'grade_level':
          out[key] = sortGradeLevels([...new Set([value].flat())])
          break
        case 'teacher':
        case 'school':
        case 'klass':
        case 'grading_status':
          out[key] = value
      }

      return out
    }, {}),
  )

export const paramsFromFilters = (filters) =>
  Object.entries(snakeKeys(filter(filters))).reduce((out, [key, value]) => {
    switch (key) {
      case 'after_date':
      case 'before_date':
        out[key] = formatDateFilter(value)
        break
      case 'grade_level':
      case 'teacher':
      case 'school':
      case 'klass':
      case 'grading_status':
        out[key] = value
        break
    }

    return out
  }, {})

export const scoreBandsParams = Object.keys(defaultScoreBandRanges).reduce((all, key) => {
  all[`score_bands[${key}]`] = defaultScoreBandRanges[key][1]
  return all
}, {})

export const getDateRangePresets = () => {
  const end = dayjs().startOf('day')

  return [
    [7, 30, 90, 180].map((days) => ({
      numDays: days,
      start: end.startOf('day').subtract(days, 'days').toDate(),
      end: end.toDate(),
    })),
    {
      numDays: 'common.school_year_to_date',
      start: startOfSchoolYear(),
      end: end.toDate(),
    },
  ].flat()
}

export const defaultDateRange = () => {
  const schoolYearToDate = getDateRangePresets().at(-1)

  return {
    afterDate: schoolYearToDate.start,
    beforeDate: schoolYearToDate.end,
  }
}

export const formatMaxScoreRange = (rangeBegin, rangeEnd) => {
  const range = new Set([rangeBegin, rangeEnd])
  return [...range].join(' - ')
}

export const truncatedBreadcrumbs = (lessonPlan) => {
  const breadcrumbs = lessonPlan.breadcrumbs || []
  let formatted

  if (breadcrumbs.length >= 4) {
    formatted = [breadcrumbs.at(0), '...', breadcrumbs.at(-2), breadcrumbs.at(-3)]
  } else if (breadcrumbs.length > 0) {
    formatted = breadcrumbs
  } else {
    formatted = []
  }

  return formatted.join(' / ')
}
