<template>
  <div class="slide__dialogue">
    <!-- eslint-disable vue/no-v-html -->
    <!-- content is sanitized in SlideSerializer#json -->
    <div
      v-mathjax
      :class="['dialogue', shapeClass, directionClass]"
      :style="hasMathJax ? '' : 'min-width: 3rem;'"
      v-html="content.text"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import useSlideDialogue from '../../composables/use_slide_dialogue'
import { toRefs } from 'vue'

export default {
  name: 'SlideViewerDialogue',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { shapeClass, directionClass, hasMathJax } = useSlideDialogue(toRefs(props))
    return { shapeClass, directionClass, hasMathJax }
  },
}
</script>
