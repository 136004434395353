import { ref, reactive } from 'vue'
import { truncate } from 'lodash'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import { getResults } from 'vue_features/search/api'

export default function useModalSearch() {
  const { query, page, filterParams, requestParams, applyAggregates } = useSearchFiltersStore()
  const tempQuery = ref('')
  const isLoadingSearchResults = ref(false)
  const totalSearchCount = ref(0)
  const hasActiveSearch = ref(false)
  const searchResults = ref([])
  const modalFilters = reactive({})

  const resetSearch = () => {
    page.value = 1
    hasActiveSearch.value = true
    isLoadingSearchResults.value = true
    searchResults.value = []
    totalSearchCount.value = 0
    query.value = tempQuery.value
  }

  const search = async (done = () => {}) => {
    try {
      const { results, resultCount, aggregates } = await getResults({ ...modalFilters, ...requestParams.value })
      isLoadingSearchResults.value = false
      searchResults.value.push(...results)
      totalSearchCount.value = resultCount
      applyAggregates(aggregates)
    } catch {
      isLoadingSearchResults.value = false
    } finally {
      page.value += 1
      done()
    }
  }

  const displayName = (result) => {
    return truncate(result.name || result.title, { length: 60, separator: ' ' })
  }

  return {
    tempQuery,
    query,
    page,
    isLoadingSearchResults,
    totalSearchCount,
    hasActiveSearch,
    searchResults,
    resetSearch,
    search,
    displayName,
    modalFilters,
    filterParams,
  }
}
