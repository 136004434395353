<template>
  <form method="post" :action="action" @submit="isProcessing = true">
    <input type="hidden" name="_method" value="delete" />
    <LzButton :is-processing="isProcessing" type="submit" color="primary" caution class="m-2 mb-4">
      {{ value }}
    </LzButton>
    <input type="hidden" name="authenticity_token" :value="authenticityToken" />
  </form>
</template>

<script>
import { metaTags } from 'utils'
import LzButton from 'vue_features/shared/components/ui/LzButton'

export default {
  name: 'DeleteButton',
  components: { LzButton },
  props: {
    action: { type: String, required: true },
    value: {
      type: String,
      default() {
        return this.$t('common.delete')
      },
    },
  },
  data() {
    return { authenticityToken: metaTags.csrfToken(), isProcessing: false }
  },
}
</script>
