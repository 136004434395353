import { last, get } from 'lodash'
import Big from 'big.js'

export const MAX_FLOAT_SIZE = 17

export function findCardSpot(card, cardArray) {
  const index = cardArray.findIndex((c) => Big(c.rank).cmp(Big(card.rank)) === 1) // greater than
  return index >= 0 ? index : cardArray.length
}

export function getInsertRank(index, cardArray) {
  const displacedItem = cardArray[index]
  if (displacedItem) {
    const lowerRank = index === 0 ? 0 : cardArray[index - 1].rank
    return Big(lowerRank).plus(displacedItem.rank).div(2).toString()
  } else {
    return Big(get(last(cardArray), 'rank') || 0)
      .plus(1)
      .toString()
  }
}

function cardInsertRank(cardInsert, index, cardArray) {
  const displacedItem = cardArray[index] || {}
  const originalRank = Big(cardInsert.rank)
  const higherRank = Big(displacedItem.rank || 0)
  const lowerRank = index === 0 ? 0 : cardArray[index - 1].rank

  if (displacedItem.rank && (originalRank.gte(higherRank) || originalRank.lte(lowerRank))) {
    return getInsertRank(index, cardArray)
  } else {
    const lastRank = Big(get(last(cardArray), 'rank') || 0)
    return lastRank.lte(originalRank) ? increaseRank(lastRank) : originalRank.toString()
  }
}

export function increaseRank(rank, amt = 1) {
  return Big(rank).plus(amt).toString()
}

export function sortByRank(cardArray) {
  return (cardArray || []).sort((x, y) => Big(x.rank).cmp(Big(y.rank)))
}

export function addBlockInserts(blocksArray, blockInserts) {
  return addInserts(blocksArray, blockInserts, 'Block')
}

function addInserts(cardArray, cardInserts, idSelector = 'Card') {
  const newArray = [...cardArray]
  const prevSelector = `blockInsert.prev${idSelector}Id`
  const nextSelector = `blockInsert.next${idSelector}Id`
  cardInserts.forEach((cardInsert) => {
    const insertIndex = findInsertIndex(cardInsert, newArray, prevSelector, nextSelector)
    cardInsert.rank = cardInsertRank(cardInsert, insertIndex, newArray)
    newArray.splice(insertIndex, 0, cardInsert)
  })
  return newArray
}

function findInsertIndex(cardInsert, cardArray, prevSelector, nextSelector) {
  const prevCardIndex = cardArray.findIndex((c) => c.id === get(cardInsert, prevSelector))
  const nextCardIndex = cardArray.findIndex((c) => c.id === get(cardInsert, nextSelector))

  if (get(cardInsert, prevSelector) && prevCardIndex >= 0) {
    const prevCard = cardArray[prevCardIndex]
    if (!cardInsert.sectionId) {
      cardInsert.sectionId = prevCard.sectionId
    }
    return prevCardIndex + 1
  } else if (get(cardInsert, nextSelector) && nextCardIndex >= 0) {
    const nextCard = cardArray[nextCardIndex]
    if (!cardInsert.sectionId) {
      cardInsert.sectionId = nextCard.sectionId
    }
    return nextCardIndex
  } else {
    return findCardSpot(cardInsert, cardArray)
  }
}
