<template>
  <div class="lesson-nav flex w-16 flex-1 items-center justify-center rounded-2xl px-4 shadow-lg sm:w-32">
    <button
      :aria-label="$t('toolbar.previous')"
      :class="hasPreviousCard ? 'hover:text-primary-200 cursor-pointer opacity-100' : 'opacity-25'"
      data-test="previous-card-button"
      :disabled="!hasPreviousCard"
      class="flex text-white transition-colors"
      type="button"
      @click="$emit('go-previous')"
    >
      <InlineSvg size="l" class="mr-4 h-9 w-9" path="icons/chevron-left" />
    </button>
    <LearnosityRerenderer>
      <ul
        ref="lessonDots"
        class="lesson-dots relative flex w-full gap-x-2 overflow-x-scroll px-5 pb-1 pt-2"
        tabindex="0"
      >
        <li
          v-for="(card, index) in cards"
          ref="lessonDot"
          :key="card.id"
          :data-lesson-dot-index="index"
          class="first:ml-auto last:mr-auto"
        >
          <component
            :is="
              isReadyForLearnosity && isTakingAssessment && card.id !== END_CARD_ID && isQuestionCard(card)
                ? PositionIndicatorStatusDot
                : PositionIndicatorDot
            "
            :data-test="`position-dot-${card.id}`"
            :is-selected="selectedCardIndex === index"
            :card="card"
            class="lesson-dot mb-2 mt-2 flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-white text-white transition-colors duration-200"
            @click="$emit('card-selected', index)"
          >
            {{ index + 1 }}
          </component>
        </li>
      </ul>
    </LearnosityRerenderer>
    <button
      :aria-label="$t('toolbar.next')"
      data-test="next-card-button"
      :disabled="!hasNextCard"
      :class="hasNextCard ? 'hover:text-primary-200 cursor-pointer opacity-100' : 'opacity-25'"
      class="none flex text-white transition-colors"
      type="button"
      @click="$emit('go-next')"
    >
      <InlineSvg size="l" class="ml-2 h-9 w-9" path="icons/chevron-right" />
    </button>
  </div>
</template>

<script>
import { watch, toRef, computed, inject, ref, onMounted } from 'vue'
import { isQuestionCard } from 'vue_features/shared/composables/use_card_types'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import scrollItemToView from 'vue_features/lesson_plans/show/helpers/scroll_item_to_view'
import PositionIndicatorDot from './PositionIndicatorDot'
import PositionIndicatorStatusDot from './PositionIndicatorStatusDot'
import { END_CARD_ID } from 'vue_features/assignments/show/composables'
import LearnosityRerenderer from 'vue_features/resources/learnosity_items/components/LearnosityRerenderer'

export default {
  name: 'LessonToolbarPositionIndicator',
  components: { InlineSvg, LearnosityRerenderer },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    selectedCardIndex: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const lessonDots = ref()
    const lessonDot = ref([])
    const isTakingAssessment = inject('isTakingAssessment', false)
    const selectedCardIndex = toRef(props, 'selectedCardIndex')

    watch(selectedCardIndex, () => {
      if (lessonDot.value.length > selectedCardIndex.value) {
        const element = lessonDot.value.find(
          (dot) => parseInt(dot.getAttribute('data-lesson-dot-index'), 10) === selectedCardIndex.value,
        )
        if (!element) return
        scrollItemToView(element.children[0], lessonDots.value)
      }
    })

    const hasPreviousCard = computed(() => {
      return props.selectedCardIndex > 0
    })

    const hasNextCard = computed(() => {
      return props.cards.length > props.selectedCardIndex + 1
    })

    const cardsWithoutEndCard = computed(() => {
      return props.cards.filter((c) => c.id !== END_CARD_ID)
    })

    const isReadyForLearnosity = ref(false)
    onMounted(() => {
      isReadyForLearnosity.value = true
    })

    return {
      isQuestionCard,
      isTakingAssessment,
      lessonDot,
      lessonDots,
      hasPreviousCard,
      hasNextCard,
      PositionIndicatorDot,
      PositionIndicatorStatusDot,
      cardsWithoutEndCard,
      END_CARD_ID,
      isReadyForLearnosity,
    }
  },
}
</script>

<style scoped>
.lesson-dots::-webkit-scrollbar {
  height: 8px;
}
.lesson-dots::-webkit-scrollbar-track {
  @apply bg-primary-900;
  border-radius: 1rem;
}
.lesson-dots::-webkit-scrollbar-thumb {
  @apply bg-primary-accent;
  border-radius: 1rem;
}
.lesson-dots::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-300;
}
.lesson-nav button:disabled,
.lesson-nav button:disabled:hover {
  background-color: inherit !important;
}

.lesson-nav {
  @apply relative;
}
.lesson-nav:after {
  @apply pointer-events-none absolute left-0 top-0 h-full w-full;
  content: '';
  background-image: linear-gradient(
    90deg,
    transparent 4rem,
    rgba(var(--primary-700), 1) 4rem,
    rgba(var(--primary-700), 0) 5.225rem,
    rgba(var(--primary-700), 0) calc(100% - 5rem),
    rgba(var(--primary-700), 1) calc(100% - 3.725rem),
    transparent calc(100% - 3.725rem)
  );
}
</style>
