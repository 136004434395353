import Cookies from 'js-cookie'

import { parent } from 'utils'
import { returnToCookieName } from 'utils/config'
import { CLEVER as config } from 'environment'
import { HTTP } from 'vue_features/shared/helpers/http_helper'

export function instantLogin(loginUrl, options = {}) {
  const clientId = options.clientId || config.clientId

  HTTP.fetch(config.authenticatedUrl, {
    headers: { 'Content-Type': 'application/json', 'X-Client-ID': clientId },
    credentials: 'include',
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.authenticated) {
        if (options.returnTo) Cookies.set(returnToCookieName, options.returnTo, { secure: true, domain: '' })
        parent.postMessage({ message: 'authenticated', url: loginUrl }, '*')
      }
    })
}
