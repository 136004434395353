import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const FolderPlacementsService = {
  create(bookmarkId, folderId) {
    const url = Routes.bookmark_folder_placements_path(bookmarkId)

    return HTTP.create(url, snakeKeys({ folderPlacement: { folderId } }))
  },

  destroy(bookmarkId, folderId) {
    const url = Routes.bookmark_folder_placement_path(bookmarkId, folderId)

    return HTTP.delete(url)
  },

  update(bookmarkId, placementId, rank) {
    const url = Routes.bookmark_folder_placement_path(bookmarkId, placementId)

    return HTTP.put(url, snakeKeys({ folderPlacement: { rank } }))
  },
}

export default FolderPlacementsService
