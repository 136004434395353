<template>
  <LzConfirmModal modal-id="description-edit-modal" class="lzui-modal--large" style="text-align: left">
    <template #confirmBody>
      <div class="max-w-7xl px-3 py-2 md:px-6">
        <Ckeditor
          :options="{ toolbar: 'richText', options: {} }"
          :content="description"
          @change="description = $event"
        />
        <p class="small help-block italic">
          {{ $t('about_this_lesson.lesson_description_help', { lesson_type: $t(`readable_types.${lessonType}`) }) }}
          {{ $t('about_this_lesson.lesson_description_recommendation') }}
        </p>
      </div>
    </template>
    <template #cancelButton>
      <button class="btn" @click="hideModal">{{ $t('common.cancel') }}</button>
    </template>
    <template #confirmButton>
      <button data-test="submit-description-btn" class="btn btn--primary ml-2" @click="submit">
        {{ $t('common.save') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import LzConfirmModal from 'vue_features/shared/components/ui/LzConfirmModal'
import Ckeditor from 'vue_features/shared/components/ui/Ckeditor'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLessonPlanEditStore } from 'vue_features/lesson_plans/edit/store/use_lesson_plan_edit_store'
import { ref } from 'vue'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import LessonPlanService from 'vue_features/lesson_plans/api/lesson_plans_service'
import { disableUnloadConfirm } from 'utils'

export default {
  name: 'DescriptionModal',
  components: { Ckeditor, LzConfirmModal },
  setup() {
    const { lessonPlan, lessonType } = useLessonPlanStore()
    const { isProgressCoverDisplayed } = useLessonPlanEditStore()
    const description = ref(lessonPlan.value.description)

    const submit = async () => {
      isProgressCoverDisplayed.value = true
      const response = await LessonPlanService.updateLessonPlan(lessonPlan.value.id, {
        description: description.value,
      })
      lessonPlan.value = response
      hideModal()
      isProgressCoverDisplayed.value = false
    }

    const hideModal = () => {
      closeModal('DescriptionModal')
      disableUnloadConfirm()
    }

    return { description, lessonType, hideModal, submit }
  },
}
</script>
