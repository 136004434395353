<script setup>
import { ref, computed, nextTick } from 'vue'
import { useBookmarkButtonStore } from 'vue_features/lesson_plans/show/store/use_bookmark_button_store'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { LzButton, LoadingSpinner, LzIcon } from 'vue_features/shared/components/ui'
import { BookmarksService, FolderPlacementsService } from 'vue_features/bookmarks_index/api'
import { LzAnalytics } from 'clients'
import { location as $location } from 'utils'
import DivDropdown from 'vue_features/shared/components/ui/dropdowns/DivDropdown'
import useLoadMoreList from 'vue_features/shared/composables/use_load_more_list'
import LoadMoreButton from 'vue_features/shared/components/lists/LoadMoreButton'

const { lessonPlanId } = useLessonPlanStore()
const {
  bookmarkId,
  folders,
  initializeFolders,
  createFolder,
  currentFolderIds,
  includedIn,
  loadMoreFolders,
  totalFolders,
} = useBookmarkButtonStore()
const saved = ref(!!bookmarkId.value)
const lastTransaction = ref(() => {})

const performTransaction = async (transaction) => {
  await lastTransaction.value
  lastTransaction.value = transaction()
  await lastTransaction.value
}

const foldersInitialized = ref(false)

const emit = defineEmits(['started'])

const start = async (toggle) => {
  emit('started')
  if (!saved.value) {
    saved.value = true
    await performTransaction(async () => {
      if (!bookmarkId.value) {
        const path = $location.parseQuery().path
        const result = await BookmarksService.create({ lessonPlanId: lessonPlanId.value, path })
        bookmarkId.value = result.id
        trackAnalytics('saved lesson added')
      }
    })
  }

  toggle()
  await initializeFolders()
  foldersInitialized.value = true
}

const destroy = async (close) => {
  saved.value = false
  close()
  await performTransaction(async () => {
    if (bookmarkId.value) {
      await BookmarksService.destroy({ id: bookmarkId.value })
      currentFolderIds.value = []
      bookmarkId.value = null
      trackAnalytics('saved lesson deleted')
    }
  })
}

const trackAnalytics = (actionLabel) => {
  LzAnalytics.track('Save Button Clicked', { category: 'save', action: actionLabel, label: actionLabel })
}

const processingCreation = ref(false)
const isCreatingFolder = ref(false)
const newTitle = ref('')
const submitFolder = async () => {
  processingCreation.value = true
  isCreatingFolder.value = false

  await performTransaction(async () => {
    const newFolder = await createFolder(newTitle.value)
    await saveToFolder(newFolder.id)
    processingCreation.value = false
    newTitle.value = ''
  })
}

const saveToFolder = async (folderId) => {
  currentFolderIds.value.push(folderId)
  await FolderPlacementsService.create(bookmarkId.value, folderId)
}

const removeFromFolder = async (folderId) => {
  const index = currentFolderIds.value.indexOf(folderId)
  currentFolderIds.value.splice(index, 1)
  await FolderPlacementsService.destroy(bookmarkId.value, folderId)
}

const toggleFolder = async (folder) => {
  if (includedIn(folder)) {
    await performTransaction(() => removeFromFolder(folder.id))
  } else {
    await performTransaction(() => saveToFolder(folder.id))
  }
}

const {
  visibleItems: visibleFolders,
  canLoadMore,
  showMoreItems,
  isLoading,
} = useLoadMoreList({
  itemsRef: folders,
  totalCount: totalFolders,
  startCount: 10,
  moreCount: 10,
})

const handleLoadMore = async () => {
  await showMoreItems(loadMoreFolders)
}

const folderNameInput = ref(null)
const startCreatingFolder = () => {
  isCreatingFolder.value = true
  nextTick(() => folderNameInput.value.focus())
}

function cancelCreateFolder() {
  newTitle.value = ''
  isCreatingFolder.value = false
}

const openLabel = (isOpen) => (isOpen ? 'common.expanded' : 'common.collapsed')
const savedLabel = computed(() => (saved.value ? 'bookmark_button.saved' : 'bookmark_button.unsaved'))
</script>

<template>
  <DivDropdown
    id="bookmark-dropdown"
    :close-on-click="false"
    :dropdown-classes="['rounded-xl shadow md:w-80 max-w-unset md:flow-center']"
  >
    <template #trigger="{ id, toggle, isOpen }">
      <button
        id="bookmark-button"
        :data-toggle="id"
        :class="['save-to-list btn btn-white inline-flex h-11 items-center px-3', { 'save-to-list--checked': saved }]"
        :aria-label="[$t('bookmark_button.save_to_list'), $t(openLabel(isOpen)), $t(savedLabel)].join(', ')"
        data-test="bookmark-button"
        @click.prevent.stop="start(toggle)"
      >
        <LzIcon size="md" class="text-primary" path="icons/bookmark-plus" aria-hidden="true" />
      </button>
    </template>
    <template #divContent="{ close }">
      <div class="border-neutral-200 flex w-full items-center gap-1 border-b px-3">
        <div class="flex flex-grow flex-col py-4">
          <h1 class="text-md my-0 text-base font-bold leading-6">{{ $t('bookmark_button.saved') }}</h1>
          <span class="text-muted text-sm leading-none">
            {{ $t('bookmark_button.all_favorites') }}
          </span>
        </div>
        <button
          :class="[
            'save-to-list text-neutral-600 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full ring-red-500 transition-colors hover:bg-red-50',
            { 'save-to-list--checked': saved },
          ]"
          :aria-label="$t('bookmark_button.remove')"
          data-test="bookmark-remove-btn"
          @click="destroy(close)"
        >
          <LzIcon size="md" class="text-primary" path="icons/bookmark" aria-hidden="true" />
        </button>
      </div>
      <div class="flex max-h-72 flex-col gap-2 overflow-y-auto px-3 py-2">
        <div class="flex items-center justify-between">
          <span class="text-neutral-700 text-sm font-bold">Folders</span>
          <LzButton
            class="text-accent outline-none hover:text-accent-hover hover:bg-focus hover:border-base hover:ring-neutral-400 flex h-8 appearance-none items-center rounded-lg bg-transparent pl-2 pr-4 text-xs font-bold shadow-none transition-colors"
            @click="startCreatingFolder"
          >
            <LzIcon path="icons/add" />
            {{ $t('bookmark_button.new_folder') }}
          </LzButton>
        </div>
        <ul v-if="foldersInitialized" class="flex flex-col">
          <li v-if="isCreatingFolder" class="flex flex-col pb-2">
            <label class="mb-2 leading-none" for="folder-name">{{ $t('bookmark_button.name_label') }}</label>
            <input
              id="folder-name"
              ref="folderNameInput"
              v-model="newTitle"
              :placeholder="$t('bookmark_button.enter_folder_name')"
              type="text"
            />
            <div class="flex justify-end gap-1">
              <LzButton class="mt-2 max-w-min self-end" size="small" @click="cancelCreateFolder">
                {{ $t('common.cancel') }}
              </LzButton>
              <LzButton
                class="mt-2 max-w-min self-end"
                size="small"
                :disabled="newTitle.trim().length <= 0"
                color="green"
                @click="submitFolder"
              >
                {{ $t('common.create') }}
              </LzButton>
            </div>
          </li>
          <li v-for="folder in visibleFolders" :key="folder.id" class="flex items-center">
            <label
              :for="`folder-${folder.id}-toggle`"
              class="hover:bg-base focus-within:bg-base m-0 flex w-full items-center gap-2 rounded-lg px-3 py-2 font-bold"
            >
              <input
                :id="`folder-${folder.id}-toggle`"
                :checked="includedIn(folder)"
                type="checkbox"
                class="outline-none m-0 flex-shrink-0"
                @click="toggleFolder(folder)"
              />
              <span class="truncate">{{ folder.title }}</span>
            </label>
          </li>
          <li v-if="canLoadMore">
            <LoadMoreButton :is-loading="isLoading" button-class="btn--demoted" @click="handleLoadMore" />
          </li>
        </ul>
        <LoadingSpinner v-else class="my-6" />
      </div>
    </template>
  </DivDropdown>
</template>
