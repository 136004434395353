<template>
  <div>
    <label :for="id" class="h5" style="display: block">{{ label }}</label>
    <p v-if="info" class="p small">{{ info }}</p>
    <SwitchToggle
      :id="id"
      :model-value="modelValue"
      :disabled="!enabled"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script>
import SwitchToggle from 'vue_features/shared/components/ui/SwitchToggle'

export default {
  name: 'SettingsToggle',
  components: { SwitchToggle },
  props: {
    label: { type: String, required: true },
    info: { type: String, default: '' },
    id: { type: String, required: true },
    modelValue: { type: Boolean, required: true },
    enabled: { type: Boolean, required: true },
  },
}
</script>
