<template>
  <a
    v-if="willAutoPrint"
    :href="Routes.printable_resource_path(itemId)"
    rel="noreferrer"
    target="printable"
    class="flex items-center gap-x-2"
  >
    <LzIcon size="md" path="icons/action-print" />
    {{ $t('print_dialog.print') }}
  </a>
  <button v-else class="flex items-center gap-x-2" @click="$emit('start-print-dialog')">
    <LzIcon size="md" path="icons/action-print" />
    {{ $t('print_dialog.print') }}
  </button>
</template>

<script>
import { location } from 'utils'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { onMounted } from 'vue'
import { useEventListener } from 'vue_features/shared/composables'
import { usePrintSettingsStore } from 'vue_features/print_settings/store/use_print_settings_store'
import { Routes } from 'vue_features/shared/helpers/http_helper'

function hasPrintQuery() {
  const query = location.parseQuery()
  return query.print && query.print !== 'false'
}

const KEY_CODE_FOR_P = 80

export default {
  name: 'ActionPrint',
  components: {
    LzIcon,
  },
  props: {
    itemId: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props, { emit }) {
    const { willAutoPrint } = usePrintSettingsStore()

    const handlePrintKeyboardShortcut = (e) => {
      const pressedP = e.keyCode === KEY_CODE_FOR_P
      const pressedCommand = e.metaKey
      const pressedControl = e.ctrlKey

      const pressedMacPrintShortcut = pressedCommand && pressedP
      const pressedPCPrintShortcut = pressedControl && pressedP

      if (pressedMacPrintShortcut || pressedPCPrintShortcut) {
        e.preventDefault()
        emit('start-print-dialog')
      }
    }

    useEventListener('keydown', handlePrintKeyboardShortcut)
    onMounted(() => {
      if (hasPrintQuery()) {
        emit('start-print-dialog')
      }
    })

    return { willAutoPrint, Routes }
  },
}
</script>
