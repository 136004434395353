<template>
  <div class="flex h-full items-center space-x-2">
    <div class="flex h-6 w-6 items-center rounded-md px-1" :class="iconClass" :style="iconStyle">
      <InlineSvg class="flex h-4 w-4 rounded-sm" :path="`icons/${icon}`" />
    </div>
    <div class="text-xs sm:text-sm">{{ label }}</div>
  </div>
</template>

<script>
import { InlineSvg } from 'vue_features/shared/components/ui'

export default {
  name: 'ScoringLegendIcon',
  components: {
    InlineSvg,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'square',
    },
    iconClass: {
      type: String,
      default: '',
    },
    iconStyle: {
      type: [String, Object, Array],
      default: '',
    },
  },
}
</script>
