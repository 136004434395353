<template>
  <div
    v-if="openTool !== '' && !isFullscreen"
    data-test="student-tools-container"
    class="tools-container z-100 fixed bottom-28 right-20"
  >
    <StudentTool />
  </div>
</template>

<script>
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useFullscreenStore } from 'vue_features/shared/composables/use_fullscreen_store'
import StudentTool from 'vue_features/assignments/shared/card_deck/StudentTool'

export default {
  name: 'StudentToolsPanel',
  components: { StudentTool },
  setup() {
    const { openTool } = useLessonPlanStore()
    const { isFullscreen } = useFullscreenStore()

    return {
      openTool,
      isFullscreen,
    }
  },
}
</script>
