<template>
  <div class="space-y-6">
    <SchoolModalDelete v-if="canModalShow('SchoolModalDelete')" @show-notice="$emit('show-notice', $event)" />
    <SchoolModalEdit v-if="canModalShow('SchoolModalEdit')" @show-notice="$emit('show-notice', $event)" />
    <SchoolModalMerge v-if="canModalShow('SchoolModalMerge')" @show-notice="$emit('show-notice', $event)" />
    <ul>
      <SchoolListItem v-for="school in schools" :key="school.id" :school="school" />
    </ul>
  </div>
</template>

<script>
import SchoolListItem from './SchoolListItem'
import { SchoolModalDelete, SchoolModalEdit, SchoolModalMerge } from './modals'
import { canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useSiteAdminSchoolsStore } from '../store/use_site_admin_schools_store'

export default {
  name: 'SchoolList',
  components: { SchoolListItem, SchoolModalDelete, SchoolModalEdit, SchoolModalMerge },
  setup() {
    const { schools } = useSiteAdminSchoolsStore()
    return { schools, canModalShow }
  },
}
</script>
