<template>
  <LzModal
    v-slot="{ close }"
    :modal-title="$t('klasses.create_class')"
    :modal-id="$options.name"
    class="lzui-modal--medium"
  >
    <div class="form-group">
      <label for="klass-name">{{ $t('klasses.new_class_modal.name_label') }}</label>
      <span v-if="hasNameBlankError" role="alert" class="inline-help red">
        <span class="sr-only">{{ $t('klasses.new_class_modal.name_label') }}</span>
        {{ $t('klasses.new_class_modal.error_blank') }}
      </span>
      <span v-if="hasNameTooLongError" role="alert" class="inline-help red">
        <span class="sr-only">{{ $t('klasses.new_class_modal.name_label') }}</span>
        {{ $t('klasses.new_class_modal.error_length') }}
      </span>
      <input
        id="klass-name"
        v-model="newName"
        :placeholder="$t('klasses.new_class_modal.name_placeholder')"
        class="form-control"
        type="text"
        @keypress.enter="submit"
      />
    </div>
    <footer>
      <LoadingSpinner v-if="creating" />
      <template v-else>
        <button class="btn" @click.stop="close">
          {{ $t('common.cancel') }}
        </button>
        <button class="btn btn--primary" @click="submit">
          {{ $t('common.create') }}
        </button>
      </template>
    </footer>
  </LzModal>
</template>

<script>
import { LzModal, LoadingSpinner } from 'vue_features/shared/components/ui'
import NameValidator from './name_validator_mixin'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useKlassesIndexStore } from 'vue_features/klasses/index/store/use_klasses_index_store'
import Routes from 'routes'

export default {
  name: 'KlassCreateModal',
  components: { LzModal, LoadingSpinner },
  mixins: [NameValidator],
  setup() {
    const { createKlass } = useKlassesIndexStore()
    return { createKlass, closeModal }
  },
  data() {
    return {
      newName: '',
      creating: false,
    }
  },
  methods: {
    submit() {
      if (!this.checkForErrors(this.newName)) {
        this.creating = true
        this.createKlass({ klassName: this.newName }).then((klassData) => {
          location.assign(Routes.teacher_klass_path(klassData.id))
        })
      }
    },
  },
}
</script>
