<script>
import { h } from 'vue'
import {
  TitleCardContent,
  ImageResource,
  LearnosityItemResource,
  SlideViewer,
  WikiResource,
  GeogebraPlayer,
} from 'vue_features/resources'
import ResourceFromType from 'vue_features/resources/shared/components/ResourceFromType'
import { useLessonPlanStore } from 'vue_features/lesson_plans/store/use_lesson_plan_store'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'
import ContentUnavailable from 'vue_features/shared/components/views/ContentUnavailable'
import LearnosityRerenderer from 'vue_features/resources/learnosity_items/components/LearnosityRerenderer'
import {
  isTitleCard,
  isQuestionCard,
  isSlideCard,
  isWikiCard,
  cardContentType,
} from 'vue_features/shared/composables/use_card_types'
import { ACTIVE, ARCHIVED } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'CardContent',
  components: {
    TitleCardContent,
    ImageResource,
    ResourceFromType,
    LearnosityItemResource,
    SlideViewer,
    WikiResource,
    GeogebraPlayer,
    ContentUnavailable,
    LearnosityRerenderer,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  render(props, context) {
    const serializedResource = this.card.content

    if (isTitleCard(this.card)) {
      return h(TitleCardContent, { title: this.card.title })
    }

    const { hasAssignment } = useLessonPlanStore()
    const { learnosityConfig } = useLearnosityConfigStore()
    const hasResource = !!serializedResource
    const isActiveOrArchived = hasResource && [ACTIVE, ARCHIVED].includes(serializedResource.state)
    const editable = hasResource && serializedResource.editable && !hasAssignment.value

    if (!isActiveOrArchived && !editable) {
      return h(ContentUnavailable, context.attrs)
    }

    if (isQuestionCard(this.card)) {
      const contentProps = { itemReference: serializedResource.content }
      const { lessonPlan } = useLessonPlanStore()

      contentProps.config = learnosityConfig.value || serializedResource.lrnoPreviewConfig

      contentProps.lessonPlanCustomerId = lessonPlan.value.customerId
      contentProps.hasCheckableAnswers = this.card.checkableAnswers

      return h(LearnosityRerenderer, context.attrs, { default: () => h(LearnosityItemResource, contentProps) })
    }

    if (isSlideCard(this.card)) {
      const { json, textStyle } = serializedResource
      return h('div', { id: 'slide-viewer-feature', class: 'slide-viewer-feature' }, [
        h(SlideViewer, { ...context.attrs, slideJson: json, textStyle }),
      ])
    }

    if (isWikiCard(this.card)) {
      const { blocks, insertedBlocks } = serializedResource
      // Retaining legacy markup
      return h('div', { ...context.attrs, class: 'wiki-page-viewer page-layout' }, [
        h('div', { class: 'page-head' }, [
          h('div', { class: 'page-head-info pbn' }, [
            h('div', { class: 'page-head__wrapper' }, [
              h('div', { class: 'page-head__content' }, [
                h('h2', serializedResource.name),
                h('p', serializedResource.description),
              ]),
            ]),
          ]),
        ]),
        h('div', { class: 'page-content' }, [
          h('div', { class: 'col-span-12' }, [h(WikiResource, { blocks, insertedBlocks })]),
        ]),
      ])
    }
    return h(ResourceFromType, { ...context.attrs, type: cardContentType(this.card), serializedResource })
  },
}
</script>
