<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/label-has-for
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <section v-if="canSave" class="lz-content__preview">
    <h3 v-if="invalidIds.length > 0" data-test="invalid-ids-error" class="small red">
      {{ $t('lz_content_preview.no_content') }}
      <span class="break-words">{{ invalidIds.join(', ') }}</span>
    </h3>
    <Grid>
      <div class="col-span-8">
        <h4 class="mb-2 mt-0">{{ $t('common.preview') }}</h4>
        <template v-if="isEmbedded">
          <LzContentEmbed v-for="embed in previews" v-bind="embed" :key="embed.lzContent.id" />
        </template>
        <LzContentList v-else :lz-contents="lzContents" :is-block="isBlock" />
      </div>
      <div class="col-span-4">
        <h4 class="my-0">{{ $t('lz_content_preview.heading_id') }}</h4>
        <p class="break-words">{{ validIds.join(', ') }}</p>

        <h4 class="my-2">{{ $t('lz_content_preview.heading_layout') }}</h4>
        <div class="toggle-btn">
          <label>
            <input id="list-view" v-model="viewType" type="radio" value="list" />
            <div
              :class="['toggle-btn__option', { selected: viewType === 'list' }]"
              data-test="list-toggle"
              @click="viewType = 'list'"
            >
              <LzIcon size="sm" path="icons/list" />
              <span class="font-semibold tracking-wide">{{ $t('lz_content_preview.list_label') }}</span>
            </div>
          </label>
          <label>
            <input id="block-view" v-model="viewType" type="radio" value="block" />
            <div
              :class="['toggle-btn__option', { selected: isBlock }]"
              data-test="block-toggle"
              @click="viewType = 'block'"
            >
              <LzIcon size="sm" path="icons/block-view" />
              <span class="font-semibold tracking-wide">{{ $t('lz_content_preview.block_label') }}</span>
            </div>
          </label>
          <label v-if="hasEmbedView">
            <input id="embed-view" v-model="viewType" type="radio" value="embed" />
            <div
              :class="['toggle-btn__option', { selected: isEmbedded }]"
              data-test="embed-toggle"
              @click="viewType = 'embed'"
            >
              <LzIcon size="sm" path="icons/embed-resource" />
              <span class="font-semibold tracking-wide">{{ $t('lz_content_preview.embed_label') }}</span>
            </div>
          </label>
        </div>
      </div>
    </Grid>
  </section>
  <div v-else class="center max-w-7xl px-3 md:px-6">
    <h3 class="small red">
      {{ $t('lz_content_preview.no_content_indeterminate') }}
    </h3>
    <div class="mb-4 inline-block w-28"> <InlineSvg path="icons/unavailable-object.svg" /> </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import LzContentList from 'vue_features/lz_content/components/LzContentList'
import LzContentEmbed from 'vue_features/resources/wikis/components/LzContentEmbed'
import LzIcon from 'vue_features/shared/components/ui/LzIcon'
import { isValidIframeSrc } from 'vue_features/resources/hyperlinks/composables/use_url_helpers'
import { useLzContentModalStore } from 'vue_features/resources/wikis/composables/use_lz_content_modal_store'
import Grid from 'vue_features/shared/components/ui/Grid'
import { isHyperlink } from 'vue_features/shared/composables/use_resource_types'

export default {
  name: 'PreviewDisplay',
  components: { Grid, LzIcon, LzContentEmbed, LzContentList, InlineSvg },
  props: {
    invalidIds: { type: Array, required: true },
  },
  setup() {
    const { previews, viewType } = useLzContentModalStore()
    viewType.value = 'list'
    const canSave = computed(() => previews.value.length > 0)
    const isEmbedded = computed(() => viewType.value === 'embed')
    const isBlock = computed(() => viewType.value === 'block')

    const validIds = computed(() => previews.value.map((i) => i.lzContent.id))
    const hasEmbedView = computed(() =>
      previews.value.every((item) => {
        const serializedResource = item.serializedResource
        return !!serializedResource || (isHyperlink(item.resource) && isValidIframeSrc(item.lzContent.url))
      }),
    )

    const lzContents = computed(() => previews.value.map((i) => i.lzContent))

    return { previews, canSave, viewType, isEmbedded, isBlock, validIds, hasEmbedView, lzContents }
  },
}
</script>
