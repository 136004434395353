<template>
  <div class="page-content" style="padding-bottom: 0">
    <ProgressCover :visible="showProgressCover" />
    <div class="mx-auto max-w-7xl px-3 md:px-6">
      <SettingsErrors :errors="errors" />

      <template v-if="!isPublic">
        <button
          :class="[{ 'ml-2': isEditing }, 'btn btn--primary btn--small float-right']"
          :disabled="hasErrors && isEditing"
          data-test="edit-save"
          @click="clickSave"
        >
          {{ editBtnTxt }}
        </button>
        <button v-if="isEditing" class="btn btn--gray btn--small float-right" @click="cancel">
          {{ $t('common.cancel') }}
        </button>

        <SiteSettings
          v-bind="{
            isEditing,
            siteTypeOptions,
            customerTypeOptions,
            adminOptions,
            availableLocales,
          }"
          v-model:name="name"
          v-model:subdomain="subdomain"
          v-model:site-type="siteType"
          v-model:customer-type="customerType"
          v-model:owner-id="ownerId"
          v-model:logo-file="logoFile"
          v-model:maintenance-mode="maintenanceMode"
        />

        <OptionsSettings
          v-bind="{ hasErrors, isEditing }"
          v-model:google-classroom-enabled="googleClassroomEnabled"
          v-model:student-functionality="studentFunctionality"
          @update:studentFunctionality="alignGoogleClassroomEnabledWithStudentFunctionality"
        />

        <SecuritySettings
          v-bind="{ hasErrors, isEditing }"
          v-model:is-content-open="isContentOpen"
          v-model:bypass-homepage="bypassHomepage"
          v-model:has-session-timeout="hasSessionTimeout"
          v-model:session-timeout="sessionTimeout"
        />

        <ContentSettings
          v-bind="{ isEditing, standardInitiatives, standardInitiativeIds }"
          v-model:show-public-content="showPublicContent"
          v-model:teacher-assessment-copy-enabled="teacherAssessmentCopyEnabled"
          v-model:show-standards="showStandards"
          v-model:teacher-copy-enabled="teacherCopyEnabled"
          @update:teacherCopyEnabled="alignTeacherAssessmentCopyWithTeacherCopy"
          @toggle-standard="updateStandardInitiativeIds"
        />

        <div class="border-base my-6 border-b" />
      </template>
      <NavigationForm />
    </div>

    <DeleteCustomerSection v-if="showDeleteSection" />
    <div v-if="!isPublic && authorizedAsCustomerAdmin" class="created-info mx-auto max-w-7xl px-3 py-6 md:px-6">
      {{
        $t('show.created_info', {
          created_at: new Date(customer.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          created_by: customer.createdBy || '[Unknown]',
        })
      }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import NavigationForm from './NavigationForm'
import DeleteCustomerSection from './DeleteCustomerSection'
import ProgressCover from 'vue_features/shared/components/ui/loaders/ProgressCover'
import SettingsErrors from './SettingsErrors'
import SiteSettings from './SiteSettings'
import SecuritySettings from './SecuritySettings'
import OptionsSettings from './OptionsSettings'
import ContentSettings from './ContentSettings'
import location from 'utils/location'
import { useCurrentCustomerStore, useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useSiteAdminCustomerStore } from '../store/use_site_admin_customer_store'

export default {
  name: 'CustomersShow',
  components: {
    SettingsErrors,
    SiteSettings,
    SecuritySettings,
    OptionsSettings,
    ContentSettings,
    ProgressCover,
    DeleteCustomerSection,
    NavigationForm,
  },
  props: {
    initialCustomer: { type: Object, required: true },
    standardInitiatives: { type: Array, required: true },
    siteTypeOptions: { type: Array, required: true },
    customerTypeOptions: { type: Array, required: true },
    adminOptions: { type: Array, required: true },
    availableLocales: { type: Array, required: true },
  },
  setup() {
    const { isPublic } = useCurrentCustomerStore()
    const { authorizedAsCustomerAdmin } = useCurrentUserStore()
    const { customer, updateCustomer } = useSiteAdminCustomerStore()

    const showDeleteSection = computed(
      () => !isPublic.value && !customer.value.protectFromDeletion && authorizedAsCustomerAdmin.value,
    )

    return {
      isPublic,
      authorizedAsCustomerAdmin,
      customer,
      updateCustomer,
      showDeleteSection,
    }
  },
  data() {
    return {
      errors: [],
      showProgressCover: false,
      isEditing: false,
      hasSessionTimeout: this.initialCustomer.sessionTimeout !== null,
      isContentOpen: !this.initialCustomer.gateContent,
      initialSubdomain: this.initialCustomer.subdomain,
      ...this.initialCustomer,
      sessionTimeout: this.initialCustomer.sessionTimeout || 60,
      standardInitiativeIds: this.initialCustomer.standardInitiativeIds.slice(0),
    }
  },
  computed: {
    editBtnTxt() {
      return this.isEditing ? this.$t('common.save_changes') : this.$t('common.edit')
    },
    hasErrors() {
      const ONE_YEAR_IN_MINUTES = 525600
      return this.hasSessionTimeout && (this.sessionTimeout < 60 || this.sessionTimeout > ONE_YEAR_IN_MINUTES)
    },
    isSubdomainUpdated() {
      return this.subdomain !== this.initialSubdomain
    },
    persistentAttributes() {
      return {
        customer: {
          bypassHomepage: this.isContentOpen && this.bypassHomepage,
          customerType: this.customerType,
          teacherCopyEnabled: this.teacherCopyEnabled,
          teacherAssessmentCopyEnabled: this.teacherAssessmentCopyEnabled,
          gateContent: !this.isContentOpen,
          logo: this.logoFile.baseUrl || '',
          maintenanceMode: this.maintenanceMode,
          name: this.name,
          sessionTimeout: this.hasSessionTimeout ? this.sessionTimeout : null,
          siteType: this.siteType,
          showPublicContent: this.showPublicContent,
          showStandards: this.showStandards,
          standardInitiativeIds: this.standardInitiativeIds,
          studentFunctionality: this.studentFunctionality,
          googleClassroomEnabled: this.googleClassroomEnabled,
          subdomain: this.subdomain,
          ownerId: this.ownerId,
        },
      }
    },
  },
  methods: {
    cancel() {
      Object.keys(this.customer).forEach((key) => (this[key] = this.customer[key]))
      this.sessionTimeout = this.sessionTimeout || 60
      this.isEditing = false
    },
    clickSave() {
      if (this.isEditing) {
        this.saveChanges()
      } else {
        this.isEditing = true
      }
    },
    alignTeacherAssessmentCopyWithTeacherCopy(teacherCopyEnabled) {
      if (!teacherCopyEnabled) {
        this.teacherAssessmentCopyEnabled = false
      }
    },
    alignGoogleClassroomEnabledWithStudentFunctionality(studentFunctionalityEnabled) {
      if (!studentFunctionalityEnabled) {
        this.googleClassroomEnabled = false
      }
    },
    updateStandardInitiativeIds(input) {
      const value = Number.parseInt(input.value),
        ids = this.standardInitiativeIds
      input.checked ? ids.push(value) : ids.splice(ids.indexOf(value), 1)
    },
    reloadWithNewSubdomain() {
      const newHref = location.href().replace(this.initialSubdomain, this.subdomain)
      location.replace(newHref)
    },
    saveChanges() {
      this.errors = []
      this.showProgressCover = true
      this.updateCustomer(this.persistentAttributes).then(
        () => {
          this.showProgressCover = false
          this.isEditing = false
          if (this.isSubdomainUpdated) this.reloadWithNewSubdomain()
        },
        (xhr) => {
          this.errors = xhr.responseJSON
          this.showProgressCover = false
        },
      )
    },
  },
}
</script>
