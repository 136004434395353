<!-- eslint-disable vuejs-accessibility/form-control-has-label  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div
    class="section-accordion bg-focus border-neutral-300 relative mb-4 mr-3 mt-3 w-full rounded-2xl border shadow-sm"
    style="width: 100%"
  >
    <div>
      <h2 class="hover:text-important-hover m-0 flex cursor-pointer items-center px-4 py-5">
        <InlineSvg
          path="icons/caret"
          :class="['text-accent mr-4 inline-block h-7 w-7 transform cursor-pointer', { '-rotate-90': !isOpen }]"
          @click="toggle"
        />
        <input v-if="isTitleEditable" v-model="newTitle" type="text" />
        <template v-else>
          {{ title }}
        </template>
      </h2>
    </div>
    <CollapseTransition>
      <div v-show="isOpen" class="section-accordion__body rounded-b-2xl pb-6 pl-10 pr-4">
        <WikiDraggableBlocks
          v-bind="{ sectionId, inSection: true, editorId, totalBlockCount, isAddingInserts }"
          v-model="sectionBlocks"
          @update-block="updateBlock"
          @remove-block="removeBlock"
        />
      </div>
    </CollapseTransition>
  </div>
</template>

<script setup>
import { InlineSvg } from 'vue_features/shared/components/ui'
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import { getInsertRank } from '/utils/card_rank_helpers'
import { ref, computed, watch, defineAsyncComponent } from 'vue'

const WikiDraggableBlocks = defineAsyncComponent(() => import('./WikiDraggableBlocks.vue'))
const props = defineProps({
  blocks: { type: Array, default: () => [] },
  title: { type: String, default: '' },
  sectionId: { type: Number, default: null },
  index: { type: Number, required: true },
  editorId: { type: String, required: true },
  totalBlockCount: { type: Number, required: true },
  isAddingInserts: { type: Boolean, default: false },
})

const isOpen = ref(true)
const newTitle = ref(props.title)
let singleBlockRank = '1'
const sectionBlocks = ref([...props.blocks])

const isTitleEditable = computed(() => {
  return !props.isAddingInserts
})

watch(
  () => props.blocks,
  (newVal, oldVal) => {
    // ensure that if we drag the last card out of the section,
    // we replace it w/ a unique html embed otherwise cannot drag cards below section (weird bug)
    singleBlockRank = oldVal.length === 1 ? getInsertRank(0, oldVal) : singleBlockRank
    if (newVal.length === 0) {
      sectionBlocks.value.push({
        rank: singleBlockRank,
        contentType: 'Html',
        detailType: 'Html',
        display: 'embed',
        content: { content: '' },
      })
    }
  },
)

const emit = defineEmits(['update-block'])

watch(
  sectionBlocks,
  (newBlocks) => {
    emit('update-block', { content: { blocks: newBlocks } })
  },
  { deep: true },
)
watch(newTitle, (title) => {
  emit('update-block', { title })
})
const toggle = () => {
  isOpen.value = !isOpen.value
}
const updateBlock = ({ block, updates }) => {
  const index = sectionBlocks.value.indexOf(block)
  if (index >= 0) {
    sectionBlocks.value.splice(index, 1, { ...block, ...updates })
  }
}
const removeBlock = (index) => {
  sectionBlocks.value.splice(index, 1)
}
</script>
