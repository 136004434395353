<template>
  <div class="h-full w-full pb-6">
    <div v-observe-visibility="onVisibilityChange" class="link-iframe--wrapper">
      <iframe :src="iframeSrc" allowfullscreen="true" :title="name" />
    </div>

    <ExternalLink :href="previewUrl" class="smaller block w-full p-2 text-center print:hidden">
      {{ $t('hyperlinks.content.view_original') }}
    </ExternalLink>
  </div>
</template>

<script>
import { truncate } from 'lodash'
import { ExternalLink } from 'vue_features/shared/components/ui'
import { extractUrl, prependProtocol } from '../composables/use_url_helpers'

export default {
  name: 'IframeHyperlinkResource',
  components: { ExternalLink },
  props: {
    url: { type: String, required: true },
    name: { type: String, required: true },
  },
  data() {
    return {
      iframeSrc: '',
      isVisible: false,
    }
  },
  computed: {
    forPrintReference() {
      return this.printLayout === 'reference'
    },
    loadIframeSource() {
      return this.isVisible || this.isPrintEnabled
    },
    previewUrl() {
      return extractUrl(this.url) || prependProtocol(this.url)
    },
    truncatedUrl() {
      return truncate(this.url, { length: 45 })
    },
  },
  watch: {
    loadIframeSource: {
      immediate: true,
      handler(load) {
        if (load) {
          // lazy load, inspired by https://iframely.com/docs/lazy-load
          this.iframeSrc = this.url
        }
      },
    },
  },
  methods: {
    onVisibilityChange(isVisible) {
      this.isVisible = isVisible
    },
  },
}
</script>
