<template>
  <LzModal
    :modal-title="$t('assignment_response_report.google_classroom.link_student_modal_title')"
    :modal-id="modalId"
    class="lzui-modal--medium"
    :autoclose="false"
    @close-modal="hideModal"
  >
    <div class="no-fullstory">
      <i18n-t
        tag="p"
        keypath="assignment_response_report.google_classroom.link_student_modal_call_to_action"
        scope="global"
      >
        <template #studentName>
          <strong>{{ studentName }}</strong>
        </template>
      </i18n-t>
      <StudentSearch
        v-if="!studentsLoadingForCourse(courseId) && !processingUpdate"
        :google-students="studentsForCourse(courseId)"
        :current-google-student-profile-id="googleStudent ? googleStudent.profileId : null"
        :course-name="klass.googleClassroomCourse.name"
        :course-link="klass.googleClassroomCourse.link"
        :inline-confirm="true"
        @link-google-student="updateLink"
        @unlink-google-student="updateLink"
      />
      <LoadingSpinner v-else />
    </div>
    <footer>
      <button class="btn" @click="cancel">{{ $t('common.close') }}</button>
    </footer>
  </LzModal>
</template>

<script>
import { LzModal, LoadingSpinner } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import StudentSearch from 'vue_features/shared/components/google_classroom/StudentSearch'
import { useGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'

export default {
  name: 'LinkStudentToGoogleModal',
  components: { LzModal, StudentSearch, LoadingSpinner },
  props: {
    klass: {
      type: Object,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    studentName: {
      type: String,
      required: true,
    },
    googleStudent: {
      type: Object,
      default: null,
      validator: (value) => {
        return typeof value === 'object' || value === null
      },
    },
    modalId: {
      type: String,
      default: function () {
        return 'LinkStudentToGoogleModal'
      },
    },
  },
  setup() {
    const {
      studentsForCourse,
      studentsLoadingForCourse,
      associateGoogleStudent,
      disassociateGoogleStudent,
      loadStudentsForCourse,
    } = useGoogleClassroomStore()
    return {
      studentsForCourse,
      studentsLoadingForCourse,
      associateGoogleStudent,
      disassociateGoogleStudent,
      loadStudentsForCourse,
      closeModal,
    }
  },
  data() {
    return {
      processingUpdate: false,
    }
  },
  computed: {
    courseId() {
      return this.klass.googleClassroomCourse.courseId
    },
  },
  created() {
    this.loadStudentsForCourse({ courseId: this.klass.googleClassroomCourse.courseId })
  },
  methods: {
    hideModal() {
      this.closeModal(this.modalId)
    },
    cancel() {
      this.hideModal()
    },
    async updateLink(googleStudent) {
      if (googleStudent) {
        await this.linkGoogleStudent(googleStudent)
      } else {
        await this.unlinkGoogleStudent()
      }
      this.hideModal()
    },
    linkGoogleStudent(googleStudent) {
      this.processingUpdate = true
      return this.associateGoogleStudent({
        studentId: this.studentId,
        googleClassroomStudentId: googleStudent.profileId,
      })
        .then(() => {
          this.$emit('google-classroom-linked-student', {
            studentId: this.studentId,
            googleClassroomUserData: googleStudent,
          })
        })
        .finally(() => {
          this.processingUpdate = false
          this.loadStudentsForCourse({ courseId: this.courseId, force: true })
        })
    },

    unlinkGoogleStudent() {
      this.inProgress = true
      return this.disassociateGoogleStudent({
        studentId: this.studentId,
      })
        .then(() => {
          return this.$emit('google-classroom-unlinked-student', { studentId: this.studentId })
        })
        .finally(() => {
          this.inProgress = false
          this.loadStudentsForCourse({ courseId: this.courseId, force: true })
        })
    },
  },
}
</script>
