import { merge } from 'lodash'
import {
  pickLocaleNamespace,
  namespaceLocaleObj,
  elevateLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import {
  common,
  shared,
  resources,
  hyperlinks,
  components,
  standardsCoverageTables,
  wikis,
  lessonPlans,
  geogebraItems,
  slides,
  htmls,
  documents,
  images,
  videos,
  audios,
  errors,
  search,
  contentTypeMetadata,
  revisions,
} from 'vue_features/shared/i18n'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'
import filestackUploadMessages from 'vue_features/shared/i18n/filestack_upload_messages'

export default merge(
  {},
  namespaceLocaleObj(common, 'common'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  pickLocaleNamespace(shared, 'updated_notification'),
  pickLocaleNamespace(shared, 'action_menu'),
  // for form partials
  elevateLocaleNamespace(shared, 'lz_content_modal'),
  namespaceLocaleObj(resources, 'resources'),
  namespaceLocaleObj(wikis, 'wikis'),
  namespaceLocaleObj(geogebraItems, 'geogebra_items'),
  namespaceLocaleObj(hyperlinks, 'hyperlinks'),
  namespaceLocaleObj(slides, 'slides'),
  namespaceLocaleObj(htmls, 'htmls'),
  namespaceLocaleObj(documents, 'documents'),
  namespaceLocaleObj(images, 'images'),
  namespaceLocaleObj(videos, 'videos'),
  namespaceLocaleObj(audios, 'audios'),
  elevateLocaleNamespace(videos, 'form_template'),
  namespaceLocaleObj(errors, 'errors'),
  namespaceLocaleObj(shared, 'shared'),
  pickLocaleNamespace(components, 'audio_player'),
  pickLocaleNamespace(lessonPlans, 'info_form'),
  pickLocaleNamespace(lessonPlans, 'form_template'),
  namespaceLocaleObj(search, 'search'),
  namespaceLocaleObj(contentTypeMetadata, 'content_type_metadata'),
  elevateLocaleNamespace(hyperlinks, 'content'), // for embeded IframeHyperlinkResource
  standardsCoverageTables,
  standardsMessages,
  filestackUploadMessages,
  namespaceLocaleObj(revisions, 'revisions'),
)
