import { controllablePromise } from 'utils'

export default function watchLearnosityLoading() {
  const loadingPromises = []
  const config = { childList: true, subtree: true }
  document.querySelectorAll('.learnosity-item').forEach((itemElement) => {
    const parent = itemElement.parentElement // learnosity removes our actual element
    const checkpoints = {
      addedSpinner: false,
      stoppedSpinner: false,
      hasGeogebra: false,
      loadedGeogebra: false,
      mathIsLoading: false,
    }

    const promise = controllablePromise()
    const callback = (mutationList, observer) => {
      if (!checkpoints.addedSpinner) {
        checkpoints.addedSpinner = addedElement('lrn_spinner', mutationList)
      }

      if (checkpoints.addedSpinner && !checkpoints.stoppedSpinner) {
        // easier than checking removed elements since a parent gets removed
        checkpoints.stoppedSpinner = !parent.querySelector('.lrn_spinner')
      }

      if (checkpoints.stoppedSpinner && !checkpoints.mathIsLoading) {
        checkpoints.mathIsLoading = elementContainsTex(parent)
      }

      if (checkpoints.mathIsLoading) {
        checkpoints.mathIsLoaded = parent.querySelector('.MathJax_Preview') && !elementContainsPlaceholder(parent)
      }

      if (checkpoints.stoppedSpinner && !checkpoints.hasGeogebra) {
        checkpoints.hasGeogebra = addedElement('geogebra-exercise', mutationList)
      }

      if (checkpoints.stoppedSpinner && checkpoints.hasGeogebra) {
        checkpoints.loadedGeogebra = removedElement('ggb_preview', mutationList)
      }

      if (
        checkpoints.stoppedSpinner &&
        (!checkpoints.hasGeogebra || checkpoints.loadedGeogebra) &&
        (!checkpoints.mathIsLoading || checkpoints.mathIsLoaded)
      ) {
        observer.disconnect()
        promise.resolve()
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(parent, config)
    loadingPromises.push(promise)
  })

  // conservative bailout since lrno doesn't give us a real event
  window.setTimeout(() => {
    loadingPromises.forEach((p) => p.resolve())
  }, 4000)

  return loadingPromises
}

const rawTexRegex = /\\\(.*\\\)/
const placeholderRegex = /\[\[math-placeholder\]\]/ // this is set up in our MathJax config
const elementContainsTex = (element) => element.textContent.match(rawTexRegex)
const elementContainsPlaceholder = (element) => element.textContent.match(placeholderRegex)
// mutation helpers
function addedElement(clazz, mutationList) {
  return affectedElement(clazz, mutationList, 'addedNodes')
}

function removedElement(clazz, mutationList) {
  return affectedElement(clazz, mutationList, 'removedNodes')
}

function affectedElement(clazz, mutationList, nodeList) {
  let affectedClazz = false
  for (const mutation of mutationList) {
    affectedClazz = Array.from(mutation[nodeList]).some((node) => {
      return node.classList && node.classList.contains(clazz)
    })
    if (affectedClazz) {
      break
    }
  }
  return affectedClazz
}
