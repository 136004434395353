<template>
  <button
    v-if="TYPES[type].show(assignment)"
    :data-test="`${type}_button`"
    @click="openModal(modal, { klass: klass, lzCodeKlass: assignment.lzCodeKlass })"
  >
    <LzIcon size="sm" :path="`icons/${type === 'unarchive' ? 'un-archive' : type}`" />
    <span class="hidden md:inline">{{ $t(`${type === 'delete' ? 'common' : 'manage_button'}.${type}`) }}</span>
  </button>
</template>

<script>
import { capitalize } from 'lodash'
import { LzIcon } from 'vue_features/shared/components/ui'
import { openModal } from 'vue_features/shared/composables/use_global_modals'

const TYPES = {
  archive: { show: (assignment) => assignment.viewers > 0 && assignment.lzCodeKlass.active },
  unarchive: { show: (assignment) => !assignment.lzCodeKlass.active },
  delete: { show: (assignment) => assignment.viewers === 0 },
}

export default {
  name: 'AssignmentsManageButton',
  components: { LzIcon },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => Object.keys(TYPES).includes(value),
    },
    assignment: {
      type: Object,
      required: true,
    },
    klass: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return { TYPES, openModal, modal: `Assignments${capitalize(props.type)}Modal` }
  },
}
</script>
