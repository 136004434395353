<script setup>
import { computed, inject } from 'vue'
import { Badge } from 'vue_features/shared/components/ui'
import { isArchived, isDraft, isRevision } from 'vue_features/shared/composables/use_record_states'

const props = defineProps({
  state: { type: String, required: true },
})

const root = inject('useRoot')()

const text = computed(() => {
  if (isArchived(props.state)) {
    return root.$t('common.archived')
  } else if (isDraft(props.state)) {
    return root.$t('common.draft')
  } else if (isRevision(props.state)) {
    return root.$t('common.revision')
  }
  return ''
})
const color = computed(() => {
  if (isRevision(props.state)) return 'accent'
  if (isArchived(props.state)) return 'pink'
  if (isDraft(props.state)) return 'dark-gray'
  return ''
})
</script>

<template>
  <Badge v-if="text" :color="color" data-test="badge">
    {{ text }}
  </Badge>
</template>
