/*
File generated by js-routes 1.4.14
Based on Rails 7.0.6 routes of Lrnz::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// activate => /activate(.:format)
  // function(options)
  activate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
activate_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// activation => /activations/:id(.:format)
  // function(id, options)
  activation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
activation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// activations => /activations(.:format)
  // function(options)
  activations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
activations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// api_employee_user_archivals => /api/employee_user_archivals(.:format)
  // function(options)
  api_employee_user_archivals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"employee_user_archivals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
api_employee_user_archivals_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"employee_user_archivals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// assignments_archive => /assignments/archive(.:format)
  // function(options)
  assignments_archive_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
assignments_archive_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// assignments_inactive => /assignments/inactive(.:format)
  // function(options)
  assignments_inactive_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[6,"inactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
assignments_inactive_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[6,"inactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// audio => /audios/:id(.:format)
  // function(id, options)
  audio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
audio_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// audios => /audios(.:format)
  // function(options)
  audios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
audios_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// auth_callback => /auth/:provider/callback(.:format)
  // function(provider, options)
  auth_callback_path: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
auth_callback_url: Utils.route([["provider",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[3,"provider",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// auth_failure => /auth/failure(.:format)
  // function(options)
  auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
auth_failure_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// authored_lesson_plans => /authored_lesson_plans(.:format)
  // function(options)
  authored_lesson_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"authored_lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
authored_lesson_plans_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"authored_lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// bookmark => /saved/:id(.:format)
  // function(id, options)
  bookmark_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
bookmark_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// bookmark_folder => /bookmark_folders/:id(.:format)
  // function(id, options)
  bookmark_folder_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookmark_folders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
bookmark_folder_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookmark_folders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// bookmark_folder_placement => /saved/:bookmark_id/folder_placements/:id(.:format)
  // function(bookmark_id, id, options)
  bookmark_folder_placement_path: Utils.route([["bookmark_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"bookmark_id",false],[2,[7,"/",false],[2,[6,"folder_placements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
bookmark_folder_placement_url: Utils.route([["bookmark_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"bookmark_id",false],[2,[7,"/",false],[2,[6,"folder_placements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// bookmark_folder_placements => /saved/:bookmark_id/folder_placements(.:format)
  // function(bookmark_id, options)
  bookmark_folder_placements_path: Utils.route([["bookmark_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"bookmark_id",false],[2,[7,"/",false],[2,[6,"folder_placements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
bookmark_folder_placements_url: Utils.route([["bookmark_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[2,[7,"/",false],[2,[3,"bookmark_id",false],[2,[7,"/",false],[2,[6,"folder_placements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// bookmark_folders => /bookmark_folders(.:format)
  // function(options)
  bookmark_folders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookmark_folders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
bookmark_folders_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookmark_folders",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// bookmarks => /saved(.:format)
  // function(options)
  bookmarks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
bookmarks_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// browse => /browse(.:format)
  // function(options)
  browse_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"browse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
browse_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"browse",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// card => /cards/:id(.:format)
  // function(id, options)
  card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
card_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// card_teaching_notes => /cards/:card_id/teaching_notes(.:format)
  // function(card_id, options)
  card_teaching_notes_path: Utils.route([["card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"card_id",false],[2,[7,"/",false],[2,[6,"teaching_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
card_teaching_notes_url: Utils.route([["card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[3,"card_id",false],[2,[7,"/",false],[2,[6,"teaching_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// changelog_index => /changelog(.:format)
  // function(options)
  changelog_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"changelog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
changelog_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"changelog",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// clever_auth_check => /clever_auth_check(.:format)
  // function(options)
  clever_auth_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clever_auth_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
clever_auth_check_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clever_auth_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// clever_login => /auth/clever(.:format)
  // function(options)
  clever_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"clever",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
clever_login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"clever",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// comment => /comments/:id(.:format)
  // function(id, options)
  comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
comment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// comment_comments => /comments/:comment_id/comments(.:format)
  // function(comment_id, options)
  comment_comments_path: Utils.route([["comment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
comment_comments_url: Utils.route([["comment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"comment_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// contact_form_submit => /contact_form/submit(.:format)
  // function(options)
  contact_form_submit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_form",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
contact_form_submit_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_form",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// content_item_lti_content_items => /lti/content_items/:content_type/:id(.:format)
  // function(content_type, id, options)
  content_item_lti_content_items_path: Utils.route([["content_type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[3,"content_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
content_item_lti_content_items_url: Utils.route([["content_type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[3,"content_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// content_item_lti_v13_content_items => /lti_v13/content_items/:content_type/:id(.:format)
  // function(content_type, id, options)
  content_item_lti_v13_content_items_path: Utils.route([["content_type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[3,"content_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
content_item_lti_v13_content_items_url: Utils.route([["content_type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[3,"content_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_maintenance => /customer_maintenance(.:format)
  // function(options)
  customer_maintenance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
customer_maintenance_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// customer_selects => /site_select(.:format)
  // function(options)
  customer_selects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
customer_selects_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_select",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// customer_session_from_public_session => /session/from_public(.:format)
  // function(options)
  customer_session_from_public_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"from_public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
customer_session_from_public_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"from_public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// customers => /sites(.:format)
  // function(options)
  customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
customers_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// data_dashboard_index => /data_dashboard(.:format)
  // function(options)
  data_dashboard_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
data_dashboard_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// data_dashboard_performance_available_customers => /data_dashboard/performance/available_customers(.:format)
  // function(options)
  data_dashboard_performance_available_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"available_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_available_customers_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"available_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_category_items_rollup_index => /data_dashboard/performance/category_items_rollup(.:format)
  // function(options)
  data_dashboard_performance_category_items_rollup_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_items_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_category_items_rollup_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_items_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_category_rollup_index => /data_dashboard/performance/category_rollup(.:format)
  // function(options)
  data_dashboard_performance_category_rollup_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_category_rollup_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_category_standards_rollup_index => /data_dashboard/performance/category_standards_rollup(.:format)
  // function(options)
  data_dashboard_performance_category_standards_rollup_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_standards_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_category_standards_rollup_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"category_standards_rollup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_ranked_standards => /data_dashboard/performance/ranked_standards(.:format)
  // function(options)
  data_dashboard_performance_ranked_standards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"ranked_standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_ranked_standards_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"ranked_standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_score_bands => /data_dashboard/performance/score_bands(.:format)
  // function(options)
  data_dashboard_performance_score_bands_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"score_bands",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_score_bands_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"score_bands",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_performance_standards_alignment_index => /data_dashboard/performance/standards_alignment(.:format)
  // function(options)
  data_dashboard_performance_standards_alignment_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"standards_alignment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
data_dashboard_performance_standards_alignment_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"performance",false],[2,[7,"/",false],[2,[6,"standards_alignment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// data_dashboard_usage_index => /data_dashboard/usage(.:format)
  // function(options)
  data_dashboard_usage_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
data_dashboard_usage_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_dashboard",false],[2,[7,"/",false],[2,[6,"usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// document => /documents/:id(.:format)
  // function(id, options)
  document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
document_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// documents => /documents(.:format)
  // function(options)
  documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
documents_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// edit_activation => /activations/:id/edit(.:format)
  // function(id, options)
  edit_activation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_activation_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_audio => /audios/:id/edit(.:format)
  // function(id, options)
  edit_audio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_audio_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_document => /documents/:id/edit(.:format)
  // function(id, options)
  edit_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_document_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_geogebra_item => /geogebra_items/:id/edit(.:format)
  // function(id, options)
  edit_geogebra_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_geogebra_item_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_html => /htmls/:id/edit(.:format)
  // function(id, options)
  edit_html_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_html_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_hyperlink => /hyperlinks/:id/edit(.:format)
  // function(id, options)
  edit_hyperlink_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_hyperlink_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_image => /images/:id/edit(.:format)
  // function(id, options)
  edit_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_image_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_lesson_plan => /lesson_plans/:id/edit(.:format)
  // function(id, options)
  edit_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_lesson_plan_revision => /lesson_plans/:lesson_plan_id/revision/edit(.:format)
  // function(lesson_plan_id, options)
  edit_lesson_plan_revision_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"revision",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_lesson_plan_revision_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"revision",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_password_reset => /password_resets/:id/edit(.:format)
  // function(id, options)
  edit_password_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_password_reset_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_rails_conductor_inbound_email_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_resource => /resources/:id/edit(.:format)
  // function(id, options)
  edit_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_resource_revision => /resources/:resource_id/revision/edit(.:format)
  // function(resource_id, options)
  edit_resource_revision_path: Utils.route([["resource_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"resource_id",false],[2,[7,"/",false],[2,[6,"revision",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
edit_resource_revision_url: Utils.route([["resource_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"resource_id",false],[2,[7,"/",false],[2,[6,"revision",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// edit_slide => /slides/:id/edit(.:format)
  // function(id, options)
  edit_slide_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_slide_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_student_session => /student/session/edit(.:format)
  // function(options)
  edit_student_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_student_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_user => /user/edit(.:format)
  // function(options)
  edit_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// edit_video => /videos/:id/edit(.:format)
  // function(id, options)
  edit_video_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_video_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_wiki => /wikis/:id/edit(.:format)
  // function(id, options)
  edit_wiki_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_wiki_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// embed => /embed(.:format)
  // function(options)
  embed_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"embed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
embed_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"embed",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// filestack_options => /filestack_options(.:format)
  // function(options)
  filestack_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filestack_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
filestack_options_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"filestack_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// geogebra_item => /geogebra_items/:id(.:format)
  // function(id, options)
  geogebra_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
geogebra_item_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// geogebra_items => /geogebra_items(.:format)
  // function(options)
  geogebra_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
geogebra_items_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// google_classroom_course_students => /google_classroom/courses/:course_id/students(.:format)
  // function(course_id, options)
  google_classroom_course_students_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
google_classroom_course_students_url: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// google_classroom_courses => /google_classroom/courses(.:format)
  // function(options)
  google_classroom_courses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
google_classroom_courses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// google_classroom_data_sync_requests => /google_classroom/data_sync_requests(.:format)
  // function(options)
  google_classroom_data_sync_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"data_sync_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
google_classroom_data_sync_requests_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"data_sync_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// google_classroom_profile => /google_classroom/profile(.:format)
  // function(options)
  google_classroom_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
google_classroom_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// grade_levels_item_analysis_suggestions => /item_analysis/suggestions/grade_levels(.:format)
  // function(options)
  grade_levels_item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"grade_levels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
grade_levels_item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"grade_levels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// grading => /grading/:lz_code(.:format)
  // function(lz_code, options)
  grading_path: Utils.route([["lz_code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
grading_url: Utils.route([["lz_code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// grading_session => /grading/:lz_code/sessions/:lz_code_view_id(.:format)
  // function(lz_code, lz_code_view_id, options)
  grading_session_path: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
grading_session_url: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// grading_session_comment => /grading/:lz_code/sessions/:lz_code_view_id/comment(.:format)
  // function(lz_code, lz_code_view_id, options)
  grading_session_comment_path: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
grading_session_comment_url: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// grading_session_response_comment => /grading/:lz_code/sessions/:lz_code_view_id/responses/:learnosity_response_id/comment(.:format)
  // function(lz_code, lz_code_view_id, learnosity_response_id, options)
  grading_session_response_comment_path: Utils.route([["lz_code",true],["lz_code_view_id",true],["learnosity_response_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"learnosity_response_id",false],[2,[7,"/",false],[2,[6,"comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
grading_session_response_comment_url: Utils.route([["lz_code",true],["lz_code_view_id",true],["learnosity_response_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"responses",false],[2,[7,"/",false],[2,[3,"learnosity_response_id",false],[2,[7,"/",false],[2,[6,"comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// grading_session_responses => /grading/:lz_code/sessions/:lz_code_view_id/responses(.:format)
  // function(lz_code, lz_code_view_id, options)
  grading_session_responses_path: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
grading_session_responses_url: Utils.route([["lz_code",true],["lz_code_view_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"grading",false],[2,[7,"/",false],[2,[3,"lz_code",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"lz_code_view_id",false],[2,[7,"/",false],[2,[6,"responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// handout_lesson_plan => /lesson_plans/:id/handout(.:format)
  // function(id, options)
  handout_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"handout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
handout_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"handout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// heartbeat => /heartbeat(.:format)
  // function(options)
  heartbeat_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"heartbeat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
heartbeat_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"heartbeat",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// home => /home(.:format)
  // function(options)
  home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
home_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// html => /htmls/:id(.:format)
  // function(id, options)
  html_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
html_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// htmls => /htmls(.:format)
  // function(options)
  htmls_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
htmls_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// hyperlink => /hyperlinks/:id(.:format)
  // function(id, options)
  hyperlink_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
hyperlink_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// hyperlinks => /hyperlinks(.:format)
  // function(options)
  hyperlinks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
hyperlinks_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// image => /images/:id(.:format)
  // function(id, options)
  image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
image_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// images => /images(.:format)
  // function(options)
  images_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
images_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// item_analysis_average_score => /item_analysis/average_score(.:format)
  // function(options)
  item_analysis_average_score_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"average_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_average_score_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"average_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_index => /item_analysis(.:format)
  // function(options)
  item_analysis_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
item_analysis_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// item_analysis_item_previews => /item_analysis/item_previews(.:format)
  // function(options)
  item_analysis_item_previews_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"item_previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_item_previews_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"item_previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_refresh_data => /item_analysis/refresh_data(.:format)
  // function(options)
  item_analysis_refresh_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"refresh_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_refresh_data_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"refresh_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_score_counts => /item_analysis/score_counts(.:format)
  // function(options)
  item_analysis_score_counts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"score_counts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_score_counts_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"score_counts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_score_summary => /item_analysis/score_summary(.:format)
  // function(options)
  item_analysis_score_summary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"score_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_score_summary_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"score_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_standards => /item_analysis/standards(.:format)
  // function(options)
  item_analysis_standards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_standards_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// item_analysis_suggestions => /item_analysis/suggestions(.:format)
  // function(options)
  item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// key_lesson_plan => /lesson_plans/:id/key(.:format)
  // function(id, options)
  key_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
key_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// klasses_item_analysis_suggestions => /item_analysis/suggestions/klasses(.:format)
  // function(options)
  klasses_item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"klasses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
klasses_item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"klasses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// launch_lti_v13_content_items => /lti_v13/content_items/launch(.:format)
  // function(options)
  launch_lti_v13_content_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[6,"launch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
launch_lti_v13_content_items_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[2,[7,"/",false],[2,[6,"launch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// learnosity_answers_preview_configs => /learnosity_answers_preview_configs(.:format)
  // function(options)
  learnosity_answers_preview_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learnosity_answers_preview_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
learnosity_answers_preview_configs_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learnosity_answers_preview_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// lesson_plan => /lesson_plans/:id(.:format)
  // function(id, options)
  lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lesson_plan_card_edit => /lesson_plans/:lesson_plan_id/card_edits/:id(.:format)
  // function(lesson_plan_id, id, options)
  lesson_plan_card_edit_path: Utils.route([["lesson_plan_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lesson_plan_card_edit_url: Utils.route([["lesson_plan_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lesson_plan_card_edits => /lesson_plans/:lesson_plan_id/card_edits(.:format)
  // function(lesson_plan_id, options)
  lesson_plan_card_edits_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plan_card_edits_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plan_cards => /lesson_plans/:lesson_plan_id/cards(.:format)
  // function(lesson_plan_id, options)
  lesson_plan_cards_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plan_cards_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plan_comments => /lesson_plans/:lesson_plan_id/comments(.:format)
  // function(lesson_plan_id, options)
  lesson_plan_comments_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plan_comments_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plan_copy => /lesson_plan_copies/:id(.:format)
  // function(id, options)
  lesson_plan_copy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plan_copies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lesson_plan_copy_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plan_copies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lesson_plan_lesson_plan_copies => /lesson_plans/:lesson_plan_id/lesson_plan_copies(.:format)
  // function(lesson_plan_id, options)
  lesson_plan_lesson_plan_copies_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"lesson_plan_copies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plan_lesson_plan_copies_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"lesson_plan_copies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plan_revision => /lesson_plans/:lesson_plan_id/revision(.:format)
  // function(lesson_plan_id, options)
  lesson_plan_revision_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"revision",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plan_revision_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"revision",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plans => /lesson_plans(.:format)
  // function(options)
  lesson_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
lesson_plans_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// lesson_plans_item_analysis_suggestions => /item_analysis/suggestions/lesson_plans(.:format)
  // function(options)
  lesson_plans_item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plans_item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lesson_plans_resource => /resources/:id/lesson_plans(.:format)
  // function(id, options)
  lesson_plans_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lesson_plans_resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// link_to_google_classroom_teacher_student => /teacher/students/:id/link_to_google_classroom(.:format)
  // function(id, options)
  link_to_google_classroom_teacher_student_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"link_to_google_classroom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
link_to_google_classroom_teacher_student_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"link_to_google_classroom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// live_learn_klass => /live_learn/:id/class/:klass_id(.:format)
  // function(id, klass_id, options)
  live_learn_klass_path: Utils.route([["id",true],["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"live_learn",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"class",false],[2,[7,"/",false],[2,[3,"klass_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
live_learn_klass_url: Utils.route([["id",true],["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"live_learn",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"class",false],[2,[7,"/",false],[2,[3,"klass_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// login => /login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// logout => /logout(.:format)
  // function(options)
  logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
logout_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// lti_config => /lti/configs/:id(.:format)
  // function(id, options)
  lti_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lti_config_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lti_security_signatures => /lti/security_signatures(.:format)
  // function(options)
  lti_security_signatures_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"security_signatures",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lti_security_signatures_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"security_signatures",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lti_v13_config => /lti_v13/configs/:id(.:format)
  // function(id, options)
  lti_v13_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lti_v13_config_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lti_v13_jwks => /lti_v13/jwks(.:format)
  // function(options)
  lti_v13_jwks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"jwks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lti_v13_jwks_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"jwks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lti_v13_login_initiations => /lti_v13/login_initiations(.:format)
  // function(options)
  lti_v13_login_initiations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"login_initiations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lti_v13_login_initiations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"login_initiations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lti_v13_observer_session => /lti_v13/observer_session(.:format)
  // function(options)
  lti_v13_observer_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"observer_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lti_v13_observer_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"observer_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lz_code => /assignments/:id(.:format)
  // function(id, options)
  lz_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lz_code_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lz_code_assignment_reports => /assignments/:lz_code_id/assignment_reports(.:format)
  // function(lz_code_id, options)
  lz_code_assignment_reports_path: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"assignment_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lz_code_assignment_reports_url: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"assignment_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lz_code_google_classroom_course_assignments => /assignments/:lz_code_id/google_classroom_course_assignments(.:format)
  // function(lz_code_id, options)
  lz_code_google_classroom_course_assignments_path: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"google_classroom_course_assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lz_code_google_classroom_course_assignments_url: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"google_classroom_course_assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lz_code_item_pool_status => /assignments/:lz_code_id/item_pool_status(.:format)
  // function(lz_code_id, options)
  lz_code_item_pool_status_path: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"item_pool_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lz_code_item_pool_status_url: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"item_pool_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lz_code_klass => /assignments/:lz_code_id/classes/:id(.:format)
  // function(lz_code_id, id, options)
  lz_code_klass_path: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lz_code_klass_url: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lz_code_klasses => /assignments/:lz_code_id/classes(.:format)
  // function(lz_code_id, options)
  lz_code_klasses_path: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lz_code_klasses_url: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lz_code_lz_code_klass => /assignments/:lz_code_id/lz_code_klasses/:id(.:format)
  // function(lz_code_id, id, options)
  lz_code_lz_code_klass_path: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"lz_code_klasses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lz_code_lz_code_klass_url: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"lz_code_klasses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lz_code_lz_code_view => /assignments/:lz_code_id/views/:id(.:format)
  // function(lz_code_id, id, options)
  lz_code_lz_code_view_path: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lz_code_lz_code_view_url: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"views",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lz_code_lz_code_views => /assignments/:lz_code_id/views(.:format)
  // function(lz_code_id, options)
  lz_code_lz_code_views_path: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lz_code_lz_code_views_url: Utils.route([["lz_code_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"views",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lz_code_student_response => /assignments/:lz_code_id/student_responses/:id(.:format)
  // function(lz_code_id, id, options)
  lz_code_student_response_path: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"student_responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lz_code_student_response_url: Utils.route([["lz_code_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"lz_code_id",false],[2,[7,"/",false],[2,[6,"student_responses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lz_codes => /assignments(.:format)
  // function(options)
  lz_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
lz_codes_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// lzui => /lzui/:id(.:format)
  // function(id, options)
  lzui_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lzui",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lzui_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lzui",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lzui_index => /lzui(.:format)
  // function(options)
  lzui_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lzui",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
lzui_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lzui",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// new_audio => /audios/new(.:format)
  // function(options)
  new_audio_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_audio_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_customer => /sites/new(.:format)
  // function(options)
  new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_customer_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_document => /documents/new(.:format)
  // function(options)
  new_document_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_document_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_geogebra_item => /geogebra_items/new(.:format)
  // function(options)
  new_geogebra_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_geogebra_item_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_google_classroom_auth_completion => /google_classroom/auth_completions/new(.:format)
  // function(options)
  new_google_classroom_auth_completion_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"auth_completions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_google_classroom_auth_completion_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"auth_completions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_google_classroom_auth_initiation => /google_classroom/auth_initiations/new(.:format)
  // function(options)
  new_google_classroom_auth_initiation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"auth_initiations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_google_classroom_auth_initiation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"auth_initiations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_google_classroom_customer_auth_completion => /google_classroom/customer_auth_completions/new(.:format)
  // function(options)
  new_google_classroom_customer_auth_completion_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"customer_auth_completions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_google_classroom_customer_auth_completion_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"google_classroom",false],[2,[7,"/",false],[2,[6,"customer_auth_completions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_html => /htmls/new(.:format)
  // function(options)
  new_html_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_html_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_hyperlink => /hyperlinks/new(.:format)
  // function(options)
  new_hyperlink_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_hyperlink_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_image => /images/new(.:format)
  // function(options)
  new_image_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_image_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_lesson_plan => /lesson_plans/new(.:format)
  // function(options)
  new_lesson_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_lesson_plan_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_lti_v13_observer_session => /lti_v13/observer_session/new(.:format)
  // function(options)
  new_lti_v13_observer_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"observer_session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_lti_v13_observer_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"observer_session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_password_reset => /password_resets/new(.:format)
  // function(options)
  new_password_reset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_password_reset_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_rails_conductor_inbound_email_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
new_rails_conductor_inbound_email_source_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// new_resource => /resources/new(.:format)
  // function(options)
  new_resource_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_resource_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_session => /session/new(.:format)
  // function(options)
  new_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_slide => /slides/new(.:format)
  // function(options)
  new_slide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_slide_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_student_session => /student/session/new(.:format)
  // function(options)
  new_student_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_student_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_user => /user/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_video => /videos/new(.:format)
  // function(options)
  new_video_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_video_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_wiki => /wikis/new(.:format)
  // function(options)
  new_wiki_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_wiki_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// page => /:id(.:format)
  // function(id, options)
  page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// password_reset => /password_resets/:id(.:format)
  // function(id, options)
  password_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
password_reset_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// password_resets => /password_resets(.:format)
  // function(options)
  password_resets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
password_resets_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password_resets",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// people_rosterview_teacher_klass => /teacher/classes/:id/people/rosterview(.:format)
  // function(id, options)
  people_rosterview_teacher_klass_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"rosterview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
people_rosterview_teacher_klass_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"rosterview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// people_teacher_klass => /teacher/classes/:id/people(.:format)
  // function(id, options)
  people_teacher_klass_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
people_teacher_klass_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// personal_copies => /personal_copies(.:format)
  // function(options)
  personal_copies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"personal_copies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
personal_copies_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"personal_copies",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// practice_sets => /practice_sets(.:format)
  // function(options)
  practice_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"practice_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
practice_sets_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"practice_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// presenter_lesson_plan => /lesson_plans/:id/presenter(.:format)
  // function(id, options)
  presenter_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"presenter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
presenter_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"presenter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// preview_lesson_plan => /lesson_plans/:id/preview(.:format)
  // function(id, options)
  preview_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
preview_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// printable_handout => /printable/handouts/:id(.:format)
  // function(id, options)
  printable_handout_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"handouts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
printable_handout_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"handouts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// printable_resource => /printable/resources/:id(.:format)
  // function(id, options)
  printable_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
printable_resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// printable_side_by_side => /printable/side_by_sides/:id(.:format)
  // function(id, options)
  printable_side_by_side_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"side_by_sides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
printable_side_by_side_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"side_by_sides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// printable_teacher_reference => /printable/teacher_references/:id(.:format)
  // function(id, options)
  printable_teacher_reference_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"teacher_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
printable_teacher_reference_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"teacher_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// printable_with_note => /printable/with_notes/:id(.:format)
  // function(id, options)
  printable_with_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"with_notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
printable_with_note_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"printable",false],[2,[7,"/",false],[2,[6,"with_notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// privacy => /privacy(.:format)
  // function(options)
  privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
privacy_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
rails_blob_representation_url: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
rails_blob_representation_proxy_url: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_incinerate_url: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_reroute_url: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_sources_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_conductor_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
rails_direct_uploads_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_disk_service_url: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_mailgun_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_mandrill_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_mandrill_inbound_health_check_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_postmark_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_relay_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_sendgrid_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
rails_service_blob_url: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
rails_service_blob_proxy_url: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// related_lessons_audio => /audios/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_audio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_audio_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_document => /documents/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_document_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_geogebra_item => /geogebra_items/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_geogebra_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_geogebra_item_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_html => /htmls/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_html_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_html_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_hyperlink => /hyperlinks/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_hyperlink_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_hyperlink_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_image => /images/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_image_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_slide => /slides/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_slide_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_slide_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// related_lessons_video => /videos/:id/related-lessons(.:format)
  // function(id, options)
  related_lessons_video_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
related_lessons_video_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// reorder_lesson_plan_card_edits => /lesson_plans/:lesson_plan_id/card_edits/reorder(.:format)
  // function(lesson_plan_id, options)
  reorder_lesson_plan_card_edits_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
reorder_lesson_plan_card_edits_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"card_edits",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// reorder_lesson_plan_cards => /lesson_plans/:lesson_plan_id/cards/reorder(.:format)
  // function(lesson_plan_id, options)
  reorder_lesson_plan_cards_path: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
reorder_lesson_plan_cards_url: Utils.route([["lesson_plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"lesson_plan_id",false],[2,[7,"/",false],[2,[6,"cards",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// resource => /resources/:id(.:format)
  // function(id, options)
  resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// resource_content_audio => /audios/:id/resource-content(.:format)
  // function(id, options)
  resource_content_audio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_audio_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"audios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_document => /documents/:id/resource-content(.:format)
  // function(id, options)
  resource_content_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_document_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_geogebra_item => /geogebra_items/:id/resource-content(.:format)
  // function(id, options)
  resource_content_geogebra_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_geogebra_item_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"geogebra_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_html => /htmls/:id/resource-content(.:format)
  // function(id, options)
  resource_content_html_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_html_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"htmls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_hyperlink => /hyperlinks/:id/resource-content(.:format)
  // function(id, options)
  resource_content_hyperlink_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_hyperlink_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"hyperlinks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_image => /images/:id/resource-content(.:format)
  // function(id, options)
  resource_content_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_image_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_slide => /slides/:id/resource-content(.:format)
  // function(id, options)
  resource_content_slide_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_slide_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_content_video => /videos/:id/resource-content(.:format)
  // function(id, options)
  resource_content_video_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_content_video_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resource-content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resource_revision => /resources/:resource_id/revision(.:format)
  // function(resource_id, options)
  resource_revision_path: Utils.route([["resource_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"resource_id",false],[2,[7,"/",false],[2,[6,"revision",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
resource_revision_url: Utils.route([["resource_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"resource_id",false],[2,[7,"/",false],[2,[6,"revision",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// resources => /resources(.:format)
  // function(options)
  resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
resources_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// restore_site_admin_settings => /site_admin/settings/restore(.:format)
  // function(options)
  restore_site_admin_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
restore_site_admin_settings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// revision_publish_job => /revision_publish_jobs/:id(.:format)
  // function(id, options)
  revision_publish_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"revision_publish_jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
revision_publish_job_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"revision_publish_jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// revision_publish_jobs => /revision_publish_jobs(.:format)
  // function(options)
  revision_publish_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"revision_publish_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
revision_publish_jobs_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"revision_publish_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// robots => /robots.:format
  // function(format, options)
  robots_path: Utils.route([["format",true]], {}, [2,[7,"/",false],[2,[6,"robots",false],[2,[8,".",false],[3,"format",false]]]]),
robots_url: Utils.route([["format",true]], {}, [2,[7,"/",false],[2,[6,"robots",false],[2,[8,".",false],[3,"format",false]]]], true),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
root_url: Utils.route([], {}, [7,"/",false], true),
// root_lti_content_items => /lti/content_items(.:format)
  // function(options)
  root_lti_content_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"content_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
root_lti_content_items_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"content_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// root_lti_v13_content_items => /lti_v13/content_items(.:format)
  // function(options)
  root_lti_v13_content_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
root_lti_v13_content_items_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_v13",false],[2,[7,"/",false],[2,[6,"content_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// scanii_comment => /comments/:id/scanii(.:format)
  // function(id, options)
  scanii_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"scanii",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
scanii_comment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"scanii",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// scanii_resource => /resources/:id/scanii(.:format)
  // function(id, options)
  scanii_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"scanii",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
scanii_resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"scanii",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// school_districts => /school_districts(.:format)
  // function(options)
  school_districts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"school_districts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
school_districts_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"school_districts",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// schools_item_analysis_suggestions => /item_analysis/suggestions/schools(.:format)
  // function(options)
  schools_item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
schools_item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// search => /search(.:format)
  // function(options)
  search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
search_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// session => /session(.:format)
  // function(options)
  session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// short_lesson_plan => /l/:id(.:format)
  // function(id, options)
  short_lesson_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
short_lesson_plan_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// short_resource => /r/:id(.:format)
  // function(id, options)
  short_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
short_resource_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
sidekiq_web_url: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]], true),
// site_admin_admin_access => /site_admin/admin_accesses/:id(.:format)
  // function(id, options)
  site_admin_admin_access_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"admin_accesses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_admin_access_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"admin_accesses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_admin_accesses => /site_admin/admin_accesses(.:format)
  // function(options)
  site_admin_admin_accesses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"admin_accesses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_admin_accesses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"admin_accesses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_archived_users_deletions => /site_admin/archived_users_deletions(.:format)
  // function(options)
  site_admin_archived_users_deletions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"archived_users_deletions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_archived_users_deletions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"archived_users_deletions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_clever => /site_admin/clever(.:format)
  // function(options)
  site_admin_clever_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"clever",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_clever_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"clever",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_collection => /site_admin/collections/:id(.:format)
  // function(id, options)
  site_admin_collection_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_collection_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_collection_collection_export => /site_admin/collections/:collection_id/export(.:format)
  // function(collection_id, options)
  site_admin_collection_collection_export_path: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_collection_collection_export_url: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_collection_collection_publish_job => /site_admin/collections/:collection_id/publish(.:format)
  // function(collection_id, options)
  site_admin_collection_collection_publish_job_path: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_collection_collection_publish_job_url: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_collection_collection_share => /site_admin/collections/:collection_id/collection_shares/:id(.:format)
  // function(collection_id, id, options)
  site_admin_collection_collection_share_path: Utils.route([["collection_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"collection_shares",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
site_admin_collection_collection_share_url: Utils.route([["collection_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"collection_shares",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// site_admin_collection_collection_shares => /site_admin/collections/:collection_id/collection_shares(.:format)
  // function(collection_id, options)
  site_admin_collection_collection_shares_path: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"collection_shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_collection_collection_shares_url: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"collection_shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_collection_shares_batch => /site_admin/collections/:collection_id/shares_batch(.:format)
  // function(collection_id, options)
  site_admin_collection_shares_batch_path: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"shares_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_collection_shares_batch_url: Utils.route([["collection_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"collection_id",false],[2,[7,"/",false],[2,[6,"shares_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_collections => /site_admin/collections(.:format)
  // function(options)
  site_admin_collections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_collections_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_lms_integration => /site_admin/lms_integrations/:id(.:format)
  // function(id, options)
  site_admin_lms_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lms_integrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_lms_integration_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lms_integrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_lms_integrations => /site_admin/lms_integrations(/page/:page)(.:format)
  // function(options)
  site_admin_lms_integrations_path: Utils.route([["page",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lms_integrations",false],[2,[1,[2,[7,"/",false],[2,[6,"page",false],[2,[7,"/",false],[3,"page",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
site_admin_lms_integrations_url: Utils.route([["page",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lms_integrations",false],[2,[1,[2,[7,"/",false],[2,[6,"page",false],[2,[7,"/",false],[3,"page",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// site_admin_lti_v13_integration => /site_admin/lti_v13_integrations/:id(.:format)
  // function(id, options)
  site_admin_lti_v13_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lti_v13_integrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_lti_v13_integration_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lti_v13_integrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_lti_v13_integrations => /site_admin/lti_v13_integrations(.:format)
  // function(options)
  site_admin_lti_v13_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lti_v13_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_lti_v13_integrations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"lti_v13_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_question_banks => /site_admin/question_banks(.:format)
  // function(options)
  site_admin_question_banks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"question_banks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_question_banks_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"question_banks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_recurring_export => /site_admin/recurring_exports/:id(.:format)
  // function(id, options)
  site_admin_recurring_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_exports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_recurring_export_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_exports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_recurring_exports => /site_admin/recurring_exports(.:format)
  // function(options)
  site_admin_recurring_exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_recurring_exports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_recurring_import => /site_admin/recurring_import(.:format)
  // function(options)
  site_admin_recurring_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_recurring_import_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"recurring_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_root => /site_admin(.:format)
  // function(options)
  site_admin_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
site_admin_root_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// site_admin_school => /site_admin/schools/:id(.:format)
  // function(id, options)
  site_admin_school_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_school_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_school_merge_school => /site_admin/schools/:school_id/merge_schools/:id(.:format)
  // function(school_id, id, options)
  site_admin_school_merge_school_path: Utils.route([["school_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"school_id",false],[2,[7,"/",false],[2,[6,"merge_schools",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
site_admin_school_merge_school_url: Utils.route([["school_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"school_id",false],[2,[7,"/",false],[2,[6,"merge_schools",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// site_admin_schools => /site_admin/schools(.:format)
  // function(options)
  site_admin_schools_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_schools_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_settings => /site_admin/settings(.:format)
  // function(options)
  site_admin_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_settings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_single_integration => /site_admin/single_integration(.:format)
  // function(options)
  site_admin_single_integration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"single_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_single_integration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"single_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_standard_download => /site_admin/standard_downloads/:id(.:format)
  // function(id, options)
  site_admin_standard_download_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_downloads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_standard_download_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_downloads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_standard_downloads => /site_admin/standard_downloads(.:format)
  // function(options)
  site_admin_standard_downloads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_downloads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_standard_downloads_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_downloads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_standard_import => /site_admin/standard_imports/:id(.:format)
  // function(id, options)
  site_admin_standard_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_standard_import_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_standard_imports => /site_admin/standard_imports(.:format)
  // function(options)
  site_admin_standard_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_standard_imports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standard_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_standards => /site_admin/standards(.:format)
  // function(options)
  site_admin_standards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_standards_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_user => /site_admin/users/:id(.:format)
  // function(id, options)
  site_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
site_admin_user_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// site_admin_user_import_csv_previews => /site_admin/user_import_csv_previews(.:format)
  // function(options)
  site_admin_user_import_csv_previews_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_import_csv_previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_user_import_csv_previews_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_import_csv_previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_user_import_original => /site_admin/user_imports/:user_import_id/original(.:format)
  // function(user_import_id, options)
  site_admin_user_import_original_path: Utils.route([["user_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[2,[7,"/",false],[2,[3,"user_import_id",false],[2,[7,"/",false],[2,[6,"original",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_user_import_original_url: Utils.route([["user_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[2,[7,"/",false],[2,[3,"user_import_id",false],[2,[7,"/",false],[2,[6,"original",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_user_import_report => /site_admin/user_imports/:user_import_id/report(.:format)
  // function(user_import_id, options)
  site_admin_user_import_report_path: Utils.route([["user_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[2,[7,"/",false],[2,[3,"user_import_id",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
site_admin_user_import_report_url: Utils.route([["user_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[2,[7,"/",false],[2,[3,"user_import_id",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// site_admin_user_imports => /site_admin/user_imports(.:format)
  // function(options)
  site_admin_user_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_user_imports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_user_reports => /site_admin/user_reports(.:format)
  // function(options)
  site_admin_user_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_user_reports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"user_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// site_admin_users => /site_admin/users(.:format)
  // function(options)
  site_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
site_admin_users_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"site_admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// slide => /slides/:id(.:format)
  // function(id, options)
  slide_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
slide_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// slides => /slides(.:format)
  // function(options)
  slides_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
slides_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slides",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// standard => /standards/:code(.:format)
  // function(code, options)
  standard_path: Utils.route([["code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"standards",false],[2,[7,"/",false],[2,[3,"code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
standard_url: Utils.route([["code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"standards",false],[2,[7,"/",false],[2,[3,"code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// standards => /standards(.:format)
  // function(options)
  standards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
standards_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// standards_coverage_table => /standards_coverage_tables/:id(.:format)
  // function(id, options)
  standards_coverage_table_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"standards_coverage_tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
standards_coverage_table_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"standards_coverage_tables",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// standards_coverage_tables => /standards_coverage_tables(.:format)
  // function(options)
  standards_coverage_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"standards_coverage_tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
standards_coverage_tables_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"standards_coverage_tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// student_data_report => /student_data_reports/:id(.:format)
  // function(id, options)
  student_data_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"student_data_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_data_report_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"student_data_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_data_reports => /student_data_reports(.:format)
  // function(options)
  student_data_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student_data_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
student_data_reports_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student_data_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// student_enhanced_login => /student/enhanced_login(.:format)
  // function(options)
  student_enhanced_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"enhanced_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_enhanced_login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"enhanced_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_klass => /student/classes/:id(.:format)
  // function(id, options)
  student_klass_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
student_klass_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// student_klasses => /student/classes(.:format)
  // function(options)
  student_klasses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_klasses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_login => /student/login(.:format)
  // function(options)
  student_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_logout => /student/logout(.:format)
  // function(options)
  student_logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_logout_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_root => /student(.:format)
  // function(options)
  student_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
student_root_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// student_session => /student/session(.:format)
  // function(options)
  student_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
student_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// student_session_new => /student/session/new(.:format)
  // function(options)
  student_session_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
student_session_new_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// student_tools => /student_tools(.:format)
  // function(options)
  student_tools_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student_tools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
student_tools_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"student_tools",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// students_teacher_klass => /teacher/classes/:id/students(.:format)
  // function(id, options)
  students_teacher_klass_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
students_teacher_klass_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// tags => /tags(.:format)
  // function(options)
  tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
tags_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// teacher_google_classroom_token => /teacher/google_classroom_token(.:format)
  // function(options)
  teacher_google_classroom_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"google_classroom_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_google_classroom_token_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"google_classroom_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teacher_klass => /teacher/classes/:id(.:format)
  // function(id, options)
  teacher_klass_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
teacher_klass_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// teacher_klass_enrollment => /teacher/classes/:klass_id/enrollments/:id(.:format)
  // function(klass_id, id, options)
  teacher_klass_enrollment_path: Utils.route([["klass_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
teacher_klass_enrollment_url: Utils.route([["klass_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// teacher_klass_enrollments => /teacher/classes/:klass_id/enrollments(.:format)
  // function(klass_id, options)
  teacher_klass_enrollments_path: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"enrollments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
teacher_klass_enrollments_url: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"enrollments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// teacher_klass_google_classroom_course => /teacher/classes/:klass_id/google_classroom_course(.:format)
  // function(klass_id, options)
  teacher_klass_google_classroom_course_path: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"google_classroom_course",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
teacher_klass_google_classroom_course_url: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"google_classroom_course",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// teacher_klass_rosters => /teacher/classes/:klass_id/rosters(.:format)
  // function(klass_id, options)
  teacher_klass_rosters_path: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"rosters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
teacher_klass_rosters_url: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"rosters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// teacher_klass_teacher_klasses => /teacher/classes/:klass_id/teacher_klasses(.:format)
  // function(klass_id, options)
  teacher_klass_teacher_klasses_path: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"teacher_klasses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
teacher_klass_teacher_klasses_url: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"teacher_klasses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// teacher_klass_teachers => /teacher/classes/:klass_id/teachers(.:format)
  // function(klass_id, options)
  teacher_klass_teachers_path: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
teacher_klass_teachers_url: Utils.route([["klass_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[2,[7,"/",false],[2,[3,"klass_id",false],[2,[7,"/",false],[2,[6,"teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// teacher_klasses => /teacher/classes(.:format)
  // function(options)
  teacher_klasses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_klasses_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teacher_login => /teacher/login(.:format)
  // function(options)
  teacher_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teacher_root => /teacher(.:format)
  // function(options)
  teacher_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
teacher_root_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// teacher_students => /teacher/students(.:format)
  // function(options)
  teacher_students_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_students_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teacher_students_search => /teacher/students_search(.:format)
  // function(options)
  teacher_students_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_students_search_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"students_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teacher_teachers_search => /teacher/teachers_search(.:format)
  // function(options)
  teacher_teachers_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"teachers_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
teacher_teachers_search_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teacher",false],[2,[7,"/",false],[2,[6,"teachers_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// teachers_item_analysis_suggestions => /item_analysis/suggestions/teachers(.:format)
  // function(options)
  teachers_item_analysis_suggestions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
teachers_item_analysis_suggestions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_analysis",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
update_rails_disk_service_url: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user => /user(.:format)
  // function(options)
  user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// user_deletion => /user/deletion(.:format)
  // function(options)
  user_deletion_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"deletion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_deletion_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"deletion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_impersonation => /users/:user_id/impersonation(.:format)
  // function(user_id, options)
  user_impersonation_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"impersonation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_impersonation_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"impersonation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// video => /videos/:id(.:format)
  // function(id, options)
  video_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
video_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// videos => /videos(.:format)
  // function(options)
  videos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
videos_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// wiki => /wikis/:id(.:format)
  // function(id, options)
  wiki_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
wiki_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// wiki_wiki_edits => /wikis/:wiki_id/wiki_edits(.:format)
  // function(wiki_id, options)
  wiki_wiki_edits_path: Utils.route([["wiki_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"wiki_id",false],[2,[7,"/",false],[2,[6,"wiki_edits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
wiki_wiki_edits_url: Utils.route([["wiki_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[2,[7,"/",false],[2,[3,"wiki_id",false],[2,[7,"/",false],[2,[6,"wiki_edits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// wikis => /wikis(.:format)
  // function(options)
  wikis_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
wikis_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wikis",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true)}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
