<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <LzModal
    id="add-users-modal"
    :modal-title="$t('add_new_user_header')"
    :class="{ 'lzui-modal--confirmation': isTransacting }"
    :modal-id="$options.name"
    class="lzui-modal--large"
    :autoclose="false"
    @bg-click="close"
    @close-modal="close"
  >
    <ProgressCover :visible="isTransacting" />
    <section class="space-y-4">
      <div
        v-for="user in newUsers"
        :key="user.id"
        class="new-user lzui-tile border-base bg-base max-w-7xl space-y-4 p-4"
      >
        <template v-if="user.persisted">
          <div class="mb-2 px-2.5 md:px-3.5">
            <label>{{ $t('label.role') }}</label>
            <span>{{ $t(`common.${user.role}`) }}</span>
          </div>
          <Grid>
            <div class="line-clamp-2 col-span-12 md:col-span-6">
              <label>{{ $t('common.label.first_name') }}</label>
              <span class="no-fullstory">{{ user.firstName }}</span>
            </div>
            <div class="line-clamp-2 col-span-12 md:col-span-6">
              <label>{{ $t('common.label.middle_name') }}</label>
              <span class="no-fullstory">{{ user.middleName }}</span>
            </div>
            <div class="line-clamp-2 col-span-12 md:col-span-6">
              <label>{{ $t('common.label.last_name') }}</label>
              <span class="no-fullstory">{{ user.lastName }}</span>
            </div>
            <div v-if="user.username" class="line-clamp-2 col-span-12 md:col-span-6">
              <label>{{ $t('common.label.username') }}</label>
              <span class="no-fullstory">{{ user.username }}</span>
            </div>
            <div v-if="user.email" class="line-clamp-2 col-span-12 md:col-span-6">
              <label>{{ $t('common.label.email') }}</label>
              <span class="no-fullstory">{{ user.email }}</span>
            </div>
          </Grid>
        </template>
        <template v-else>
          <div v-if="user.errors.length" class="lzui-tile border-red-200 bg-red-50 p-4">
            <strong class="mt-4">{{ $t('creation_error_title') }}</strong>
            <ul>
              <li v-for="error in user.errors" :key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <LzIcon
            v-if="newUsers.length > 1"
            type="a"
            size="sm"
            class="float-right text-gray-800"
            path="icons/close"
            @click="removeUser(user)"
          />
          <div>
            <label class="smaller">
              {{ $t('role') }}
            </label>
            <select v-model="user.role">
              <option
                v-for="role in filterableRoles"
                :key="role"
                :value="role"
                :disabled="role === 'student' && !hasStudentFunctionality"
              >
                {{ $t(`common.${role}`) }}
              </option>
            </select>
            <div v-if="!isPublic && !hasStudentFunctionality" class="lzui-tile my-0 border-blue-200 bg-blue-50 px-4">
              <h3>{{ $t('students_disabled_title') }}</h3>
              <p>{{ $t('students_disabled_info') }}</p>
            </div>
          </div>
          <Grid disable-default="md:px">
            <div class="col-span-12 md:col-span-4">
              <label class="smaller">
                {{ $t('common.first_name') }}
              </label>
              <input
                v-model="user.firstName"
                :placeholder="$t('first_name_placeholder')"
                class="no-fullstory"
                type="text"
                data-test="first-name-input"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label class="smaller">
                {{ $t('common.middle_name') }}
                <span class="weak">{{ $t('common.optional') }}</span>
              </label>
              <input
                v-model="user.middleName"
                :placeholder="$t('middle_name_placeholder')"
                class="no-fullstory"
                type="text"
                data-test="middle-name-input"
              />
            </div>
            <div class="col-span-12 md:col-span-4">
              <label class="smaller">
                {{ $t('common.last_name') }}
              </label>
              <input
                v-model="user.lastName"
                :placeholder="$t('last_name_placeholder')"
                class="no-fullstory"
                type="text"
                data-test="last-name-input"
              />
            </div>
            <div v-if="user.role === 'student'" class="col-span-12">
              <label class="smaller">{{ $t('common.username') }}</label>
              <input v-model="user.username" :placeholder="$t('common.username')" class="no-fullstory" type="text" />
            </div>
            <div v-else class="col-span-12">
              <label class="smaller">{{ $t('common.email') }}</label>
              <input
                v-model="user.email"
                :placeholder="$t('email_address')"
                class="no-fullstory"
                type="email"
                data-test="email-input"
              />
            </div>
          </Grid>
          <Grid disable-default="md:px">
            <template v-if="user.role === 'student'">
              <div class="col-span-12 md:col-span-6">
                <label class="smaller">{{ $t('common.password') }}</label>
                <input
                  v-model="user.password"
                  :placeholder="$t('student_password')"
                  class="no-fullstory"
                  type="password"
                />
              </div>
              <div class="col-span-12 md:col-span-6">
                <label class="smaller">{{ $t('common.password_confirmation') }}</label>
                <input
                  v-model="user.passwordConfirmation"
                  :placeholder="$t('confirm_password')"
                  class="no-fullstory"
                  type="password"
                />
              </div>
            </template>
          </Grid>
        </template>
      </div>
    </section>
    <footer class="flex justify-between px-0">
      <button class="btn--demoted m-0" @click="addUser">
        {{ $t('add_another_user') }}
      </button>
      <div class="flex">
        <button class="btn btn--small" @click="close">
          {{ closeBtnTxt }}
        </button>
        <button v-if="unpersistedUsers.length" class="btn btn--primary btn--small" @click="submit">
          {{ $t('common.submit') }}
        </button>
      </div>
    </footer>
  </LzModal>
</template>

<script>
import { LzIcon, LzModal, ProgressCover } from 'vue_features/shared/components/ui'
import UsersService from '../api/users_service'
import { location } from 'utils'
import User from '../user'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useUsersIndexStore } from 'vue_features/site_admin/users/store/use_users_index_store'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import Grid from 'vue_features/shared/components/ui/Grid'

export default {
  name: 'UserAddModal',
  components: { Grid, ProgressCover, LzIcon, LzModal },
  setup() {
    const { hasStudentFunctionality, isPublic } = useCurrentCustomerStore()
    const { filterableRoles } = useUsersIndexStore()
    return { hasStudentFunctionality, isPublic, filterableRoles, closeModal }
  },
  data() {
    return { newUsers: [], isTransacting: false }
  },
  computed: {
    closeBtnTxt() {
      return this.unpersistedUsers.length ? this.$t('common.cancel') : this.$t('common.close')
    },
    defaultRole() {
      return this.isPublic ? 'multi_site_viewer' : 'teacher'
    },
    unpersistedUsers() {
      return this.newUsers.filter((u) => !u.persisted)
    },
  },
  created() {
    this.addUser()
  },
  methods: {
    addUser() {
      this.newUsers.push(new User({ role: this.defaultRole }))
    },
    checkForm() {
      this.unpersistedUsers.forEach((user) => {
        user.isUpdatingPassword = user.isStudent()
        user.checkErrors(this.$t.bind(this))
      })
      return this.unpersistedUsers.every((u) => u.errors.length === 0)
    },
    close() {
      if (this.isTransacting) {
        return
      }
      if (this.newUsers.some((u) => u.persisted)) {
        location.reload()
      }
      this.closeModal(this.$options.name)
    },
    removeUser(user) {
      const index = this.newUsers.indexOf(user)

      this.newUsers.splice(index, 1)
    },
    submit() {
      if (this.checkForm()) {
        this.isTransacting = true
        const usersData = this.unpersistedUsers.map((u) => u.attrs())
        UsersService.create(usersData).then(
          () => {
            this.newUsers.forEach((u) => (u.persisted = true))
            this.isTransacting = false
          },
          (response) => {
            const errors = response.responseJSON.errors
            errors.forEach((e) => (this.unpersistedUsers[e.i].errors = e.msg))
            this.unpersistedUsers.forEach((u) => (u.persisted = u.errors.length === 0))
            this.isTransacting = false
          },
        )
      }
    },
  },
}
</script>
