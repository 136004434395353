<script setup>
import SettingsToggle from './SettingsToggle'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

const STANDARDS_PRODUCT_MATRIX_URL =
  'https://docs.google.com/spreadsheets/d/1wxdSh_GT1GNSfbiHaaWZGiRCuQ1nfxDo-5IuSmxlVYo/view'

defineProps({
  isEditing: { type: Boolean, required: true },
  showPublicContent: { type: Boolean, required: true },
  teacherCopyEnabled: { type: Boolean, required: true },
  teacherAssessmentCopyEnabled: { type: Boolean, required: true },
  showStandards: { type: Boolean, required: true },
  standardInitiativeIds: { type: Array, required: true },
  standardInitiatives: { type: Array, required: true },
})
const { authorizedAsCustomerAdmin } = useCurrentUserStore()
</script>

<template>
  <div class="space-y-6 pt-6">
    <h2 class="h3">{{ $t('show.content.header') }}</h2>
    <SettingsToggle
      id="public-content-toggle"
      :model-value="showPublicContent"
      :enabled="isEditing"
      :label="$t('show.content.lz_owned')"
      @update:modelValue="$emit('update:showPublicContent', $event)"
    />

    <fieldset>
      <SettingsToggle
        id="teacher-copy-enabled-toggle"
        :model-value="teacherCopyEnabled"
        :enabled="isEditing"
        :label="$t('show.content.teacher_copy_enabled')"
        :info="$t('show.content.teacher_copy_enabled_info')"
        @update:modelValue="$emit('update:teacherCopyEnabled', $event)"
      />
    </fieldset>

    <fieldset class="pl-12">
      <SettingsToggle
        id="teacher-assessment-copy-enabled-toggle"
        :model-value="teacherAssessmentCopyEnabled"
        :enabled="isEditing && teacherCopyEnabled"
        :label="$t('show.content.teacher_assessment_copy_enabled')"
        :info="$t('show.content.teacher_assessment_copy_enabled_info')"
        @update:modelValue="$emit('update:teacherAssessmentCopyEnabled', $event)"
      />
    </fieldset>

    <div v-if="authorizedAsCustomerAdmin">
      <SettingsToggle
        id="standards-toggle"
        :model-value="showStandards"
        :enabled="isEditing"
        :label="$t('show.standards.header')"
        class="mb-0 mt-6"
        @update:modelValue="$emit('update:showStandards', $event)"
      />
      <fieldset v-if="showStandards" class="ml-12 mt-6">
        <legend class="mb-2" style="font-size: 1rem; width: auto">
          {{ $t('show.standards.initiatives') }}
        </legend>
        <p class="smaller help-text">
          {{ $t('show.standards.alignment_warning') }}
          <a :href="STANDARDS_PRODUCT_MATRIX_URL" target="_blank">{{ $t('show.standards.see_the_details') }}</a>
        </p>
        <div v-for="initiative in standardInitiatives" :key="initiative.id">
          <div>
            <label :for="`toggle-standard-${initiative.id}`" class="small regular">
              <input
                :id="`toggle-standard-${initiative.id}`"
                :value="initiative.id"
                :checked="standardInitiativeIds.includes(initiative.id)"
                :disabled="!isEditing"
                class="mr-1"
                type="checkbox"
                @change="$emit('toggle-standard', $event.target)"
              />
              {{ initiative.name }}
            </label>
            <br />
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>
