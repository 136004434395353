<template>
  <div>
    <label for="attachments-input" style="min-width: 100px">{{ $t('form.file_attachments') }}</label>
    <span class="text-sm italic text-gray-400">{{ $t('optional') }}</span>
    <p class="help-block mb-2 mt-0 p-0">{{ $t('form.attach_screenshots') }}</p>
    <input
      id="attachments-input"
      ref="attachmentsInput"
      type="file"
      accept=".png,.jpg,.jpeg,.gif,.mov,.wmv,.avi,.m4v,.mpg,.mp4,.m4a"
      multiple
      @change="attachmentsUpdated"
    />
    <p v-if="attachmentLimitsExceeded" class="text-sm text-red-700" data-test="attachment-limits-exceeded-error">
      {{ $t('form.attachment_limits') }}
    </p>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

const ATTACHMENT_COUNT_LIMIT = 5
const ATTACHMENT_SIZE_LIMIT = 50000000

export default {
  name: 'AttachmentsInput',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const attachmentsInput = ref(null)
    const attachments = ref([])
    const attachmentLimitsExceeded = ref(false)

    const attachmentsUpdated = (event) => {
      attachmentLimitsExceeded.value = false
      attachments.value = [...event.target.files]
      if (
        attachments.value.length > ATTACHMENT_COUNT_LIMIT ||
        attachments.value.some((attachment) => attachment.size > ATTACHMENT_SIZE_LIMIT)
      ) {
        attachmentLimitsExceeded.value = true
        resetAttachments()
      } else {
        emit('update:modelValue', attachments.value)
      }
    }

    const resetAttachments = () => {
      attachmentsInput.value.value = null
      attachments.value = []
    }

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue.length === 0) {
          resetAttachments()
        }
      },
    )

    return { attachmentsInput, attachmentLimitsExceeded, attachmentsUpdated }
  },
}
</script>
