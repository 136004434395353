import { camelCaseKeys, snakeKeys } from 'vue_features/shared/helpers/http_helper'
import { filter } from 'utils'

export function filtersFromParams(params) {
  return camelCaseKeys(params)
}

export function paramsFromFilters(filters) {
  return filter(snakeKeys(filters))
}
