<template>
  <LzModal
    :modal-id="$options.name"
    class="lzui-modal--medium"
    :autoclose="false"
    @bg-click="hideModal"
    @close-modal="hideModal"
  >
    <div class="space-y-4">
      <h5 class="align-left">{{ title }}</h5>
      <div class="border-base w-full border-b border-l-0 border-r-0 border-t-0 border-solid"></div>

      <template v-if="context === 'lesson_plan'">
        <PrintDialogRangeSelect v-if="allowRange" />
        <PrintDialogLayoutPicker />
        <div v-if="hasLearnosityItem" class="lzui-tile bg-orange-50 border-orange-200 p-4">
          <p class="align-left mb-0 text-xs">
            {{ $t('print_dialog.learnosity_item_notice') }}
          </p>
        </div>
      </template>
      <template v-else>
        <div class="loading loading--large py-20">
          <InlineSvg path="icons/loading-spinner" />
        </div>
      </template>

      <footer class="border-base border-t">
        <div class="align-center">
          <a href="#" class="btn small inplace-form__close" data-test="close-modal" @click.prevent.stop="hideModal">
            {{ $t('common.cancel') }}
          </a>
          <a :href="printPath" rel="noreferrer" target="printable" class="btn--primary btn btn--print mb-0">
            <template v-if="willAutoPrint">
              {{ $t('print_dialog.manual_print') }}
            </template>
            <template v-else>
              {{ $t('print_dialog.select_printer') }}
            </template>
          </a>
        </div>
      </footer>
    </div>
  </LzModal>
</template>

<script>
import { computed } from 'vue'
import { usePrintSettingsStore } from '../store/use_print_settings_store'
import { Routes } from 'vue_features/shared/helpers/http_helper'

import { LzModal, InlineSvg } from 'vue_features/shared/components/ui'
import PrintDialogLayoutPicker from './PrintDialogLayoutPicker'
import PrintDialogRangeSelect from './PrintDialogRangeSelect'
import messages from '../messages'

export default {
  name: 'PrintDialog',

  components: {
    LzModal,
    InlineSvg,
    PrintDialogLayoutPicker,
    PrintDialogRangeSelect,
  },

  i18n: { messages },

  props: {
    itemId: {
      type: [Number, String],
      default: null,
    },
  },

  setup(props, { emit }) {
    const {
      context,
      hasLearnosityItem,
      analyticsProperties,
      layout,
      willAutoPrint,
      analyticsName,
      includeAboutLesson,
    } = usePrintSettingsStore()

    const printPath = computed(() => {
      if (!props.itemId) {
        return ''
      }
      if (willAutoPrint.value) {
        return Routes.printable_resource_path(props.itemId)
      }
      switch (layout.value) {
        case 'handout':
          return Routes.printable_handout_path(props.itemId)
        case 'sideBySide':
          return Routes.printable_side_by_side_path(props.itemId)
        case 'noteTaking':
          return Routes.printable_with_note_path(props.itemId)
        case 'reference':
          return Routes.printable_teacher_reference_path(props.itemId, {
            include_about_this_lesson: includeAboutLesson.value,
          })
        default:
          return Routes.printable_handout_path(props.itemId)
      }
    })

    const hideModal = () => {
      emit('close')
    }

    if (willAutoPrint.value) {
      window.open(Routes.printable_resource_path(props.itemId), 'printable', 'noreferrer')
    }

    return {
      context,
      hasLearnosityItem,
      analyticsProperties,
      layout,
      willAutoPrint,
      analyticsName,
      printPath,
      print,
      hideModal,
      allowRange: false, // temporary until this feature can be re-enabled
    }
  },

  computed: {
    title() {
      return this.willAutoPrint ? this.$t('print_dialog.print') : this.$t('print_dialog.select_options')
    },
  },
}
</script>
