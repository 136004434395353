<template>
  <div class="detail-item__content">
    <div class="detail-item__title font-bold">
      <ExternalLink :href="url">{{ name }}</ExternalLink>
    </div>

    <p class="detail-item__description">
      {{ description }}
    </p>

    <ul class="detail-item__metadata list-metadata">
      <template v-for="(value, label) in metadata" :key="label">
        <li :class="{ 'print:hidden': label !== 'url' }">
          <strong class="print:hidden">
            {{ upperFirst(label) }}
          </strong>
          {{ textForMetadataItem(label, value) }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { upperFirst } from 'lodash'
import { ExternalLink } from 'vue_features/shared/components/ui'

export default {
  name: 'SimpleHyperlinkResource',
  components: { ExternalLink },
  props: {
    description: { type: String, default: null },
    metadata: { type: Object, default: () => ({}) },
    name: { type: String, required: true },
    url: { type: String, required: true },
  },
  methods: {
    upperFirst,
    textForMetadataItem(label, value) {
      return label === 'from' ? value.name : value
    },
  },
}
</script>
