<template>
  <LzModal
    id="what-filter-modal"
    :modal-id="$options.name"
    :modal-title="$t('what_filter_modal.title')"
    modal-classes="w-full max-w-4xl"
    :autoclose="true"
    @close-modal="closeModal($options.name)"
  >
    <div class="what-filter-content w-full max-w-4xl overflow-auto px-4">
      <div class="flex items-center gap-x-10">
        <div v-if="subjectOptions && subjectOptions.length" class="flex flex-col">
          <span class="text-sm font-bold">{{ $t('subject') }}</span>
          <span class="text-xs">{{ $t('what_filter_modal.subject_info') }}</span>
          <div class="py-2">
            <ToggleButton :value="currentSelections.subject" @input="selectSubjectFilter">
              <ToggleButtonOption
                v-for="subjectOption in subjectOptions"
                :key="subjectOption.value"
                :value="subjectOption.value"
                :data-test="`subject-${subjectOption}`"
              >
                {{ subjectOption.label }}
              </ToggleButtonOption>
            </ToggleButton>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex items-center gap-x-10">
            <div class="flex flex-col">
              <span class="text-sm font-bold">{{ $t('grading_status') }}</span>
              <span class="text-xs">
                {{ $t('what_filter_modal.grading_status_info') }}
                <a href="/resources/2677118-data-dashboard/#grading-status" class="underline" target="_blank">
                  {{ $t('what_filter_modal.grading_status_info_link') }}
                </a>
              </span>
              <div class="py-2">
                <ToggleButton :value="currentSelections.gradingStatus" @input="selectGradingStatusFilter">
                  <ToggleButtonOption :value="null" data-test="grading-status-all">
                    {{ $t('all') }}
                  </ToggleButtonOption>

                  <ToggleButtonOption value="graded" data-test="grading-status-graded">
                    {{ $t('graded') }}
                  </ToggleButtonOption>
                </ToggleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingCustomers" class="flex h-24 w-full items-center justify-center">
        <LoadingSpinner size="small" />
      </div>
      <template v-else>
        <p class="py-4 text-lg font-bold">{{ $t('what_filter_modal.assignment_types') }}</p>
        <div class="flex-between flex-no-wrap flex gap-x-4 pb-8">
          <WhatFilterTypeGuidance
            :active="currentSelections.lessonTypes.includes(LESSON_TYPES.ASSESSMENT)"
            :guidance="assessmentsGuidance"
            class="flex w-full"
            @toggle="(active) => handleTypeToggle(LESSON_TYPES.ASSESSMENT, active)"
          />
          <WhatFilterTypeGuidance
            :active="currentSelections.lessonTypes.includes(LESSON_TYPES.ACTIVITY)"
            :guidance="activitiesGuidance"
            class="flex w-full"
            @toggle="(active) => handleTypeToggle(LESSON_TYPES.ACTIVITY, active)"
          />
          <WhatFilterTypeGuidance
            :active="currentSelections.lessonTypes.includes(LESSON_TYPES.FULL_LESSON)"
            :guidance="fullLessonsGuidance"
            class="flex w-full"
            @toggle="(active) => handleTypeToggle(LESSON_TYPES.FULL_LESSON, active)"
          />
        </div>
      </template>
    </div>
    <div class="border-base w-full border-t p-6">
      <button class="btn btn--green" :disabled="currentSelections.lessonTypes.length === 0" @click="updateFilters">
        {{ $t('view_results') }}
      </button>
      <span v-if="currentSelections.lessonTypes.length === 0" class="text-red text-sm">
        {{ $t('what_filter_modal.no_assignment_types_selected') }}
      </span>
    </div>
  </LzModal>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import { LoadingSpinner, LzModal, ToggleButton, ToggleButtonOption } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { useDataDashboardStore, LESSON_TYPES } from '../composables/data_dashboard_store'
import WhatFilterTypeGuidance from './WhatFilterTypeGuidance'
import { assessmentsGuidance, activitiesGuidance, fullLessonsGuidance } from '../utils'

export default {
  name: 'WhatFilterModal',
  components: { LzModal, LoadingSpinner, ToggleButton, ToggleButtonOption, WhatFilterTypeGuidance },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const root = inject('useRoot')()
    const { loadAvailableCustomers, availableSubjects } = useDataDashboardStore()

    const currentSelections = ref({
      gradingStatus: props.filters.gradingStatus,
      subject: props.filters.subject,
      lessonTypes: props.filters.lessonTypes || [LESSON_TYPES.ASSESSMENT],
    })

    const subjectOptions = computed(() => {
      const options = availableSubjects.value.map((sub) => ({
        label: root.$t(sub),
        value: sub,
      }))

      if (availableSubjects.value.length > 1) {
        options.unshift({ label: root.$t('all'), value: null })
      }

      return options
    })

    const selectGradingStatusFilter = (value) => {
      currentSelections.value.gradingStatus = value
    }

    const selectSubjectFilter = (value) => {
      currentSelections.value.subject = value
    }

    const handleTypeToggle = (type, active) => {
      const lessonTypes = currentSelections.value.lessonTypes
      if (active && !lessonTypes.includes(type)) {
        currentSelections.value.lessonTypes = [...lessonTypes, type]
      } else if (!active && lessonTypes.includes(type)) {
        currentSelections.value.lessonTypes = lessonTypes.filter((t) => t !== type)
      }
    }

    const loadingCustomers = ref(true)

    onMounted(async () => {
      if (subjectOptions.value.length === 1) {
        currentSelections.value.subject = subjectOptions.value[0].value
      }

      await loadAvailableCustomers()
      loadingCustomers.value = false
    })

    const updateFilters = () => {
      emit('apply-filters', {
        ...currentSelections.value,
      })
    }

    return {
      closeModal,
      selectGradingStatusFilter,
      subjectOptions,
      selectSubjectFilter,
      currentSelections,
      loadingCustomers,
      assessmentsGuidance,
      activitiesGuidance,
      fullLessonsGuidance,
      updateFilters,
      handleTypeToggle,
      LESSON_TYPES,
    }
  },
}
</script>

<style>
.what-filter-content {
  max-height: min(60vh, 600px);
}
#what-filter-modal .lzui-modal__body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#what-filter-modal .lzui-modal__dialog > header {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
