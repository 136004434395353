<template>
  <div id="navigation-option">
    <div class="mx-auto max-w-7xl">
      <div class="space-x-2 text-right">
        <button v-show="!isEditing" class="btn btn--primary btn--small" @click="isEditing = !isEditing">
          {{ $t('common.edit') }}
        </button>
        <button v-show="isEditing" data-test="cancel-btn" class="btn btn--gray btn--small" @click="cancelEdit">
          {{ $t('common.cancel') }}
        </button>
        <button
          v-show="isEditing"
          :disabled="navOptionsHaveErrors"
          class="btn btn--primary btn--small"
          @click="saveChanges"
        >
          {{ $t('common.save_changes') }}
        </button>
      </div>
      <div>
        <h4 class="my-0">
          {{ $t('show.navigation.header') }}
        </h4>
        <p class="smaller help-text text-muted mt-1">
          {{ $t('show.navigation.info') }}
        </p>
        <table class="table-opened border-neutral-400 mt-4 border border-solid">
          <thead>
            <tr>
              <th class="w-2/12 md:w-3/12">
                {{ $t('show.navigation.tab') }}
              </th>
              <th class="w-3/12 md:w-7/12">
                {{ $t('show.navigation.url') }}
              </th>
              <th class="w-3/12 md:w-2/12"></th>
              <th></th>
            </tr>
          </thead>
          <tbody ref="navTable">
            <NavigationOptionRow
              v-for="(navigationOption, index) in localNavigationOptions"
              :key="`navRow${index}`"
              :is-editing="isEditing"
              :errors="errors(navigationOption)"
              :nav-option-prop="navigationOption"
              @update:nav-option-prop="navigationOption = $event"
            />
            <tr v-if="isEditing">
              <td colspan="4">
                <button class="btn btn--gray btn--small inline-block" @click="addCustomNavigationOption">
                  {{ $t('show.navigation.add_tab') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isEqual, differenceWith } from 'lodash'
import { createNavOption } from '../api'
import NavigationOptionRow from './NavigationOptionRow'
import { useSiteAdminCustomerStore } from '../store/use_site_admin_customer_store'
import { nextTick, ref } from 'vue'

export default {
  name: 'NavigationForm',
  components: {
    NavigationOptionRow,
  },
  setup() {
    const { navigationOptions, updateNavigationOptions } = useSiteAdminCustomerStore()
    const navTable = ref(null)
    return { navigationOptions, updateNavigationOptions, navTable }
  },
  data() {
    return {
      isEditing: false,
      localNavigationOptions: [],
    }
  },
  computed: {
    changedNavigationOptions() {
      return differenceWith(this.localNavigationOptions, this.navigationOptions, isEqual)
    },
    navOptionsHaveErrors() {
      return this.localNavigationOptions
        .filter((navOption) => navOption._destroy !== true)
        .some((navOption) => this.errors(navOption).hasError)
    },
    hasDuplicateKeys() {
      return new Set(this.navigationKeys).size !== this.localNavigationOptions.length
    },
    allValidKeys() {
      return this.localNavigationOptions
        .filter((navOption) => navOption._destroy !== true)
        .map((navOption) => navOption.key)
    },
  },
  mounted() {
    this.resetLocalNavigationOptions()
  },
  methods: {
    saveChanges() {
      this.isEditing = false

      if (this.changedNavigationOptions.length === 0) return

      this.updateNavigationOptions(this.changedNavigationOptions)
        .then(() => {
          this.resetLocalNavigationOptions()
        })
        .catch(() => {
          this.resetLocalNavigationOptions()
        })
    },

    async addCustomNavigationOption() {
      const newNavOption = createNavOption({ key: this.$t('show.navigation.new_tab') })
      this.localNavigationOptions.push(newNavOption)

      await nextTick()

      // FIXME: replace w/ onMounted focus in child component
      const options = this.navTable.querySelectorAll('tr')
      const newlyAddedNavOption = options[options.length - 2]
      newlyAddedNavOption.querySelector('input').focus()
    },

    cancelEdit() {
      this.resetLocalNavigationOptions()

      this.isEditing = false
    },

    resetLocalNavigationOptions() {
      this.localNavigationOptions = cloneDeep(this.navigationOptions)
    },

    errors(navOption) {
      const error = {
        emptyKey: isEmpty(navOption.key) ? this.$t('common.empty') : false,
        invalidUrl: !/^https?:\/\/.*/.test(navOption.url) ? this.$t('show.navigation.invalid_url') : false,
        duplicateKey: this.arrayHasMultipleOf(this.allValidKeys, navOption.key) ? this.$t('common.duplicate') : false,
      }

      return {
        ...error,
        hasError: error.emptyKey || error.invalidUrl || error.duplicateKey,
      }
    },

    arrayHasMultipleOf(array, element) {
      let count = 0
      array.forEach((arrayElement) => arrayElement.toLowerCase() === element.toLowerCase() && count++)
      return count > 1
    },
  },
}
</script>
