<template>
  <div class="max-w-7xl">
    <div>
      <label for="material-id">{{ $t('geogebra_items.form_template.geogebra_url_prompt') }}</label>
      <input
        id="material-id"
        v-model="materialId"
        name="geogebra_item[material_id]"
        type="text"
        class="mb-2"
        @input="$emit('update', { materialId })"
      />
    </div>
    <div>
      <input
        id="gg-show-toolbar"
        v-model="showToolbar"
        name="geogebra_item[show_toolbar]"
        type="checkbox"
        class="mr-2"
        @change="$emit('update', { showToolbar })"
      />
      <label for="gg-show-toolbar">{{ $t('geogebra_items.form_template.show_toolbar') }}</label>
    </div>
    <div>
      <input
        id="gg-autoscale"
        v-model="autoscale"
        name="geogebra_item[autoscale]"
        type="checkbox"
        class="mr-2"
        @change="$emit('update', { autoscale })"
      />
      <label for="gg-autoscale" class="mb-0">{{ $t('geogebra_items.form_template.scale_to_fit') }}</label>
      <p class="help-block ml-6 mt-0">{{ $t('geogebra_items.form_template.scale_to_fit_help') }}</p>
    </div>
  </div>
</template>

<script>
import { toBoolean } from 'utils'

export default {
  name: 'GeogebraForm',
  props: { resource: { type: Object, required: true } },
  data() {
    return {
      materialId: this.resource.materialId || '',
      showToolbar: toBoolean(this.resource.showToolbar),
      autoscale: toBoolean(this.resource.autoscale),
    }
  },
}
</script>
