<template>
  <button
    :class="[
      'toolbar-item flex w-20 flex-shrink-0 cursor-pointer flex-col items-center justify-center space-y-2 px-2 py-4 transition duration-200 sm:w-28 sm:px-3',
      {
        'bg-accent hover:bg-accent-600': active,
        'text-red-300 hover:bg-red-600 hover:text-white': dangerous,
        'hover:bg-accent-hover text-white': !dangerous,
        'bg-transparent opacity-50': disabled,
      },
    ]"
    data-test="notes-button"
    :disabled="disabled"
  >
    <LzIcon class="h-8 w-8" :path="iconPath" />
    <span class="whitespace-nowrap text-xs text-current">{{ label }}</span>
  </button>
</template>

<script>
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'LiveLearnToolbarButton',
  components: { LzIcon },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    dangerous: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    iconPath: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
button.toolbar-item:disabled:hover {
  background-color: transparent !important;
}
</style>
