<template>
  <LzConfirmModal :modal-id="$options.name">
    <template #confirmBody>
      <strong class="h3">
        {{ $t('modals.delete_school.heading', { school_name: school.name }) }}
      </strong>
      <p class="mt-1">
        {{ $t('modals.delete_school.message') }}
      </p>
    </template>
    <template #cancelButton>
      <button class="btn" @click.stop="hideModal">
        {{ $t('modals.delete_school.cancel_button') }}
      </button>
    </template>
    <template #confirmButton>
      <button class="btn btn--primary caution" @click="destroy">
        {{ $t('modals.delete_school.confirm_button') }}
      </button>
    </template>
  </LzConfirmModal>
</template>

<script>
import { inject } from 'vue'
import LzConfirmModal from 'vue_features/shared/components/ui/LzConfirmModal'
import useSchoolModals from './use_school_modals.js'
import { useSiteAdminSchoolsStore } from '../../store/use_site_admin_schools_store'

const NAME = 'SchoolModalDelete'

export default {
  name: NAME,
  components: { LzConfirmModal },
  setup(_, { emit }) {
    const root = inject('useRoot')()
    const { deleteSchool } = useSiteAdminSchoolsStore()
    const { school, hideModal } = useSchoolModals(NAME)

    const destroy = async () => {
      await deleteSchool(school.value.id)
      hideModal()
      emit('show-notice', root.$t('index.success_delete_school'))
    }

    return { school, destroy, hideModal }
  },
}
</script>
