<template>
  <slot v-if="renderSlot" v-bind="$attrs" />
</template>

<script>
/*
  This component destroys and recreates its child (slot) if it receives a 'rerender' event from the child. This is
  useful when we need to forcibly rerender Learnosity content. It also rerenders onActivated, as detailed below.
  TBD, but pretty likely any component that calls useLearnosityItemResource() (and isn't nested in another) should be
  wrapped with this component and emit('rerender'), if needed.
*/

import { ref, onMounted, onActivated, nextTick, provide } from 'vue'
import { useLearnosityClientStore } from 'vue_features/resources/learnosity_items/composables/use_learnosity_client_store'

export default {
  name: 'LearnosityRerenderer',
  props: {
    renderOnMount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const renderSlot = ref(true)

    async function forceRerender() {
      renderSlot.value = false
      await nextTick()
      renderSlot.value = true
      await nextTick()
      emit('on-rerender')
    }

    /*
      Since Vue Router doesn't preserve the DOM when changing routes, this forces a rerender when a user navigates away from
      the card deck then comes back. (i.e. as a teacher, visit the Reports tab then come back to the assignment tab).
      Note: This should execute for ALL instances of LearnosityRerenderer, in the same tick, before actual slot content is rendered.
    */
    onActivated(() => {
      // if client was reset elsewhere (presumably with onDeactivated)
      if (useLearnosityClientStore().isReset.value) {
        forceRerender()
      }
    })

    onMounted(() => {
      if (props.renderOnMount) {
        nextTick(() => {
          if (useLearnosityClientStore().isReset.value) {
            forceRerender()
          }
        })
      }
    })

    provide('rerender', forceRerender)

    return { renderSlot }
  },
}
</script>
