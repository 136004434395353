import StandardsSelectorModalFeature from 'vue_features/standards_selector/standards_selector_modal_feature'
import emitter from 'tiny-emitter/instance'

// Define CKE plugin
window.CKEDITOR.plugins.add('lzstandards', {
  lang: 'en',
  icons: 'lzstandards',
  requires: 'widget',

  init(editor) {
    const vm = window.CKEDITOR.plugins.lzstandards.initStandardsSelectorModal()

    window.CKEDITOR.plugins.lzstandards.initPlugin(editor, vm)
    window.CKEDITOR.plugins.lzstandards.initWidget(editor)
  },
})

window.CKEDITOR.plugins.lzstandards = {
  initStandardsSelectorModal() {
    // Mount the initialized StandardsSelectorModal to the DOM
    if (!window.CKEDITOR.plugins.lzstandards.standardsSelectorModal) {
      const element = document.createElement('div')
      element.id = 'standards-selector-modal'
      window.CKEDITOR.plugins.lzstandards.standardsSelectorModal = StandardsSelectorModalFeature().mount(element)
      const vm = window.CKEDITOR.plugins.lzstandards.standardsSelectorModal
      document.body.appendChild(element)

      emitter.on('hide-modal', () => vm.closeModal(vm.$options.StandardsSelectorModal))
      emitter.on('select-standards', ({ editor, standards }) => {
        window.CKEDITOR.plugins.lzstandards.replaceStandardObjects(vm, editor)

        const element = window.CKEDITOR.plugins.lzstandards.createStandardsElement(standards)

        editor.insertHtml(element.getOuterHtml())
      })
    }

    return window.CKEDITOR.plugins.lzstandards.standardsSelectorModal
  },

  initPlugin(editor, vm) {
    // Add plugin toolbar button
    editor.ui.addButton('Lzstandards', {
      label: editor.lang.lzstandards.selectStandards,
      command: 'selectStandards',
      icon: 'lzstandards',
    })

    // Add plugin toolbar button command
    editor.addCommand('selectStandards', {
      exec() {
        window.CKEDITOR.plugins.lzstandards.replaceStandardObjects(vm, editor)

        vm.openModal(vm.$options.StandardsSelectorModal)
      },
    })

    // Event to edit existing standards widget
    editor.on('doubleclick', (e) => {
      let element = e.data.element

      while (element && !element.hasClass('lz-standards')) {
        element = element.getParent()
      }

      if (element && element.hasClass('lz-standards')) {
        window.CKEDITOR.plugins.lzstandards.replaceStandardObjects(
          vm,
          editor,
          window.CKEDITOR.plugins.lzstandards.createStandardObjects(element),
        )

        vm.openModal(vm.$options.StandardsSelectorModal)
      }
    })
  },

  initWidget(editor) {
    editor.widgets.add('lzstandards', {
      template: '<span class="lz-standards"></span>',
      requiredContent: 'span(!lz-standards)',
      allowedContent: 'span(!lz-standard,has-tip,initiative-*)[data-uuid,title]',

      init() {
        this.setData('standards', window.CKEDITOR.plugins.lzstandards.createStandardObjects(this.element))
      },

      data() {
        this.element = window.CKEDITOR.plugins.lzstandards.createStandardsElement(this.data.standards)
      },

      upcast(element) {
        return element.name === 'span' && element.hasClass('lz-standards')
      },
    })
  },

  // Utility functions

  findStandardInitiativeId(classList) {
    let id

    for (const className of classList) {
      if (className.match('initiative-')) {
        id = className.split('-')[1]

        break
      }
    }

    return id
  },

  createStandardsElement(standards) {
    const element = window.CKEDITOR.dom.element.createFromHtml('<span class="lz-standards"></span>')

    standards.forEach((standard) => {
      // CKEDITOR.dom.element.createFromHtml does not handle quotes very well...
      const child = document.createElement('span')

      child.dataset.uuid = standard.uuid
      child.innerText = standard.displayCode
      child.title = standard.name
      child.className = `lz-standard has-tip initiative-${standard.standardInitiative.id}`

      // yet the CKEDITOR.dom.element constructor does? LAME
      element.append(new window.CKEDITOR.dom.element(child))
    })

    return element
  },

  createStandardObject(child) {
    return {
      displayCode: child.innerText,
      uuid: child.dataset ? child.dataset.uuid : null,
      name: child.title,
      standardInitiative: {
        id: this.findStandardInitiativeId(child.classList || []),
      },
    }
  },

  createStandardObjects(element) {
    const data = []

    for (const child of element.getChildren().$) {
      if (child) {
        data.push(this.createStandardObject(child))
      }
    }

    return data
  },

  replaceStandardObjects(vm, editor, selectedStandards) {
    vm.standards = selectedStandards || []
    vm.editor = editor
  },
}
