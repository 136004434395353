<template>
  <div class="resource__content collection-page__content">
    <RevisionShowBanner
      v-if="isRevision"
      :object-id="resource.id"
      :original-id="originalId"
      :object-name="$t(getLabel(resource))"
      :can-print="canPrint"
      object-type="Resource"
    />
    <ResourceHeader />
    <div class="page-head-nav mt-8">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <TabMenu @activated-tab="tabChanged">
          <TabLink target="resource-content" :label="resource.type" initial />
          <TabLink target="related-lessons" :label="$t('resources.included_in_lessons')" />
        </TabMenu>
      </div>
    </div>
    <div v-if="isGated" class="page-content mx-auto max-w-7xl border-none">
      <ImageTag class="mx-auto my-12 max-h-80" path="misc/content-placeholder.png" alt="Content unavailable" />
    </div>
    <div v-else id="resource-content" class="active page-content border-none">
      <Grid
        :id="isVideo ? 'standalone-video' : ''"
        :class="['mx-auto', { 'my-6': $options.my6Types.includes(resource.type) }]"
        :style="{ 'min-height': isGeogebra ? '600px' : '' }"
      >
        <WikiResource
          v-if="!resource.contentUnavailable && isWiki"
          :blocks="resource.blocks"
          :inserted-blocks="resource.insertedBlocks"
        />
        <div
          v-else-if="!resource.contentUnavailable && isSlide"
          ref="cardWrapperEl"
          :style="cardWrapperStyle"
          class="col-span-12 lg:col-span-8"
        >
          <div ref="cardContentEl" :style="cardContentStyle">
            <SlideViewer :text-style="resource.textStyle" :slide-json="resource.json" />
          </div>
        </div>
        <div
          v-else
          :class="[
            'col-span-12',
            {
              'mb-8 break-words': ['GeogebraItem', 'Hyperlink'].includes(resource.type),
            },
          ]"
        >
          <ContentUnavailable v-if="resource.contentUnavailable" />
          <ResourceFromType v-else :type="resource.type" :serialized-resource="resource" />
        </div>
      </Grid>
    </div>
    <div id="related-lessons" class="page-content">
      <div class="mx-auto max-w-7xl px-3 md:px-6">
        <AsyncContentLoader v-if="hasShownIncludedLessons" :loader="loadIncludedLessons" class="w-full">
          <template #loadingContent>
            <div class="min-h-64 flex w-full flex-col items-center justify-center">
              <InlineSvg class="mx-auto my-0 w-3/12 max-w-xl md:w-52" path="icons/chalkboard" />
              <p class="text-3xl font-bold">{{ $t('resources.included_in_lessons_loading') }}</p>
            </div>
          </template>

          <LzContentList v-if="includedLessons.length > 0" :lz-contents="includedLessons" />
          <div v-else class="min-h-64 flex w-full flex-col items-center justify-center">
            <InlineSvg class="mx-auto my-0 w-3/12 max-w-xl md:w-52" path="icons/open-book" />
            <p class="text-3xl font-bold">{{ $t('resources.included_in_lessons_none') }}</p>
          </div>
        </AsyncContentLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { InlineSvg, AsyncContentLoader } from 'vue_features/shared/components/ui'
import ImageTag from 'vue_features/shared/components/ui/ImageTag'
import ContentUnavailable from 'vue_features/shared/components/views/ContentUnavailable'
import ResourceFromType from './ResourceFromType'
import LzContentList from 'vue_features/lz_content/components/LzContentList'
import TabMenu from 'vue_features/shared/components/ui/tabs/TabMenu'
import TabLink from 'vue_features/shared/components/ui/tabs/TabLink'
import ResourceHeader from './ResourceHeader'
import WikiResource from 'vue_features/resources/wikis/components/WikiResource'
import Grid from 'vue_features/shared/components/ui/Grid'
import { useResourceTypes } from 'vue_features/shared/composables'
import { ref } from 'vue'
import SlideViewer from 'vue_features/resources/slides/components/SlideViewer'
import useCardScaling from 'vue_features/resources/shared/composables/use_card_scaling'
import { useResourceShowStore } from 'vue_features/resources/shared/composables/use_resource_show_store'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import RevisionShowBanner from 'vue_features/shared/components/forms/RevisionShowBanner'

export default {
  name: 'ResourceContent',
  components: {
    SlideViewer,
    Grid,
    WikiResource,
    TabLink,
    TabMenu,
    LzContentList,
    ResourceFromType,
    ImageTag,
    ContentUnavailable,
    ResourceHeader,
    AsyncContentLoader,
    InlineSvg,
    RevisionShowBanner,
  },
  props: { canPrint: { type: Boolean, default: false } },
  setup() {
    const { loadIncludedLessons, includedLessons, resource, isGated, cardTitle, isRevision, originalId } =
      useResourceShowStore()
    const { isVideo, isGeogebra, isWiki, isSlide } = useResourceTypes(resource)

    const cardWrapperEl = ref()
    const cardContentEl = ref()
    const { cardWrapperStyle, cardContentStyle } = useCardScaling(cardWrapperEl, cardContentEl)

    const hasShownIncludedLessons = ref(false)
    const tabChanged = (tab) => {
      if (tab === 'related-lessons') {
        hasShownIncludedLessons.value = true
      }
    }

    return {
      isVideo,
      isGeogebra,
      isWiki,
      isSlide,
      cardWrapperEl,
      cardContentEl,
      cardWrapperStyle,
      cardContentStyle,
      includedLessons,
      resource,
      isGated,
      cardTitle,
      hasShownIncludedLessons,
      loadIncludedLessons,
      tabChanged,
      isRevision,
      originalId,
      getLabel,
    }
  },
  my6Types: ['GeogebraItem', 'Document', 'Html', 'Hyperlink', 'Image', 'Audio'],
}
</script>
