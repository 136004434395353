import { merge } from 'lodash'
import {
  namespaceLocaleObj,
  elevateLocaleNamespace,
  pickLocaleNamespace,
} from 'vue_features/shared/helpers/i18n_helper'
import {
  components,
  shared,
  common,
  lessonPlans,
  studentTools,
  featureGate,
  hyperlinks,
  resources,
  standardsCoverageTables,
} from 'vue_features/shared/i18n'
import standardsMessages from 'vue_features/standards_selector/i18n/standards_selector_messages'

export default merge(
  {},
  namespaceLocaleObj(hyperlinks, 'hyperlinks', { only: 'content' }),
  namespaceLocaleObj(featureGate, 'feature_gate'),
  namespaceLocaleObj(resources, 'resources', { only: 'processing.message' }),
  namespaceLocaleObj(studentTools, 'student_tools'),
  namespaceLocaleObj(lessonPlans, '', {
    only: [
      'lesson_plan',
      'show',
      'handout',
      'assignment_card_deck',
      'assignment_slideshow_header',
      'lesson_card_templates',
      'readable_types',
      'toolbar',
    ],
  }),
  elevateLocaleNamespace(shared, 'assignment_prompt'),
  pickLocaleNamespace(shared, 'content_unavailable'),
  namespaceLocaleObj(components, 'components'),
  namespaceLocaleObj(components, '', { only: ['audio_player', 'check_answers_button'] }),

  namespaceLocaleObj(common, 'common'),
  standardsCoverageTables,
  standardsMessages,
)
