import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

export default {
  updateSession(lzCode, lzCodeViewId, updatedSession) {
    const url = Routes.grading_session_path(lzCode, lzCodeViewId)

    return HTTP.patch(
      url,
      snakeKeys({
        lzCodeView: {
          id: updatedSession.id,
          assignmentState: updatedSession.assignmentState,
        },
      }),
    )
  },

  updateComment(lzCode, lzCodeViewId, comment, publish) {
    const url = Routes.grading_session_comment_path(lzCode, lzCodeViewId)
    return HTTP.put(url, snakeKeys({ comment: { id: comment.id, content: comment.content, publish } }))
  },

  deleteComment(commentId) {
    return HTTP.delete(Routes.comment_path(commentId) + '/hard')
  },

  markCommentsRead(lzCode, lzCodeViewId) {
    const url = Routes.grading_session_comment_path(lzCode, lzCodeViewId)
    return HTTP.put(url, snakeKeys({ comment: { read: true } }))
  },
}
