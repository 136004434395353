<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div>
    <label v-if="label" class="block">{{ label }}</label>
    <div class="flex justify-center">
      <ToggleButton :value="dateRange" name="date_range_preset" class="mb-8" @input="selectPreset">
        <slot />
      </ToggleButton>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import ToggleButton from 'vue_features/shared/components/ui/ToggleButton'
import dayjs from 'dayjs'
import { startOfSchoolYear } from 'utils/dates'
import debug from 'debug'

const log = debug('vue_features:shared/components/selects/date_range_presets')

export function getDefaultPresets() {
  return [
    [7, 30, 90, 180].map((days) => ({
      numDays: days,
      start: dayjs().startOf('day').subtract(days, 'day').toDate(),
      end: dayjs().startOf('day').toDate(),
    })),
    {
      numDays: 'common.school_year_to_date',
      start: startOfSchoolYear(),
      end: dayjs().startOf('day').toDate(),
    },
  ].flat()
}

export default {
  name: 'DateRangePresetSelect',
  components: { ToggleButton },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const state = inject('dateRangeSelectState')
    const dateRange = computed(() => JSON.stringify(state.dateRange))
    const selectPreset = (v) => {
      const { start, end } = Object.entries(JSON.parse(v)).reduce((out, [key, val]) => {
        out[key] = new Date(val)
        return out
      }, {})
      log('Preset selected. Start: %O, end: %O', start, end)
      state.updateDateRange({ start, end })
    }

    return { state, dateRange, selectPreset }
  },
}
</script>
