import messages from './i18n/lesson_plan_preview_messages'
import LessonPlanPreview from './components/LessonPlanPreview'
import { initAssignmentStore, useAssignmentCardsStore } from 'vue_features/assignments/show/composables'
import { useAssignmentPromptStore } from 'vue_features/assignments/prompt/store/use_assignment_prompt_store'
import { initGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import { useLearnosityConfigStore } from 'vue_features/shared/store/composables/use_learnosity_config_store'

export default function ({ state }) {
  initAssignmentStore({
    assignment: state.assignment,
    lessonPlan: state.lessonPlan,
    analyticsProperties: state.analyticsProperties,
  })
  useAssignmentCardsStore().$set({ cards: state.lessonPlan.cards })
  useAssignmentPromptStore().$set({ hasKlasses: state.hasKlasses })
  initGoogleClassroomStore(state.googleClassroom)
  useLearnosityConfigStore().$set({ learnosityConfig: state.learnosityConfig })

  return { component: LessonPlanPreview, messages }
}
