<template>
  <DetailListItem :title="customer.name" :href="Routes.root_url(urlOptions)" :show-thumbnail="false" class="my-2">
    <template #header>
      <Alert
        v-if="customer.isPendingDeletion"
        :title="$t('index.pending_deletion', { customer_name: customer.name })"
        type="notice"
      />
    </template>
    <template #actions>
      <a :href="Routes.site_admin_settings_url(urlOptions)" target="_blank" class="btn btn--small">
        {{ $t('common.edit') }}
      </a>
    </template>
    <template #metadata>
      <ul class="list-metadata">
        <li class="mt-2">
          {{ $tc('index.active_students', customer.activeStudentCount, { count: customer.activeStudentCount }) }},
          {{ $tc('index.active_teachers', customer.activeTeacherCount, { count: customer.activeTeacherCount }) }}
        </li>
        <li class="mb-2">
          {{ $tc('index.archived_students', customer.archivedStudentCount, { count: customer.archivedStudentCount }) }},
          {{ $tc('index.archived_teachers', customer.archivedTeacherCount, { count: customer.archivedTeacherCount }) }}
        </li>
        <li>
          <strong>{{ $t('new.owner') }}</strong>
          {{ startCase(customer.ownerName) }}
        </li>
        <li>
          <strong>{{ $t('index.last_visit') }}</strong>
          {{ capitalize(timeAgo(customer.userLastVisited)) }}
        </li>
        <li>
          <strong>{{ $t('new.site_type') }}</strong>
          {{ startCase($t(`new.site_types.${customer.siteType}`)) }}
        </li>
        <li v-if="showCustomerType">
          <strong>{{ $t('new.customer_type') }}</strong>
          {{ capitalize(customer.customerType ? $t(`new.customer_types.${customer.customerType}`) : 'none') }}
        </li>
        <template v-if="customer.hasClever">
          <li class="clever-status">
            <strong>{{ $t('index.clever_status') }}:</strong>
            {{ customer.cleverEnabled ? $t('common.enabled') : $t('common.disabled') }}
          </li>
          <li class="auth-preference">
            <strong>{{ $t('index.authentication_preference') }}:</strong>
            {{ capitalize(customer.authPreference) }}
          </li>
        </template>
      </ul>
    </template>
  </DetailListItem>
</template>

<script>
import { capitalize, startCase } from 'lodash'
import DetailListItem from 'vue_features/shared/components/lists/DetailListItem'
import Alert from 'vue_features/shared/components/ui/Alert'
import { timeAgo } from 'vue_features/shared/filters/datetime'
import { customer } from 'utils/config'
import Routes from 'routes'

export default {
  name: 'CustomerListItem',
  components: { DetailListItem, Alert },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { Routes }
  },
  computed: {
    urlOptions() {
      return { host: this.customer.host }
    },

    showCustomerType() {
      return customer.siteTypesRequiringCustomerType.includes(this.customer.siteType)
    },
  },
  methods: {
    capitalize,
    startCase,
    timeAgo,
  },
}
</script>
