import { merge } from 'lodash'
import { createLzRouter } from 'vue_features/shared/router'
import { useKlassShowStore, useKlassAssignmentsStore } from './store'
import { initGoogleClassroomStore } from 'vue_features/google_classroom/shared/use_google_classroom_store'
import messages from './i18n/klasses_show_messages'
import KlassesShow from './components/KlassesShow'
import PeopleTab from './components/PeopleTab'
import KlassTabs from './components/KlassTabs'
import AssignmentsTab from './components/AssignmentsTab'

export const routes = [
  {
    path: '/:role(student|teacher)/classes/:id',
    component: KlassTabs,
    children: [
      {
        name: 'assignments',
        path: '',
        component: AssignmentsTab,
        props: (route) => ({ klassId: route.params.id, assignmentType: 'active' }),
      },
      {
        name: 'assignments-inactive',
        path: 'assignments/inactive',
        component: AssignmentsTab,
        props: (route) => ({ klassId: route.params.id, assignmentType: 'inactive' }),
      },
      {
        name: 'people',
        path: 'people',
        component: PeopleTab,
      },
    ],
  },
]

export default function ({ state }) {
  const router = createLzRouter({ routes })

  useKlassShowStore().$set(merge(state.shared, state.klassesShow))
  useKlassAssignmentsStore().$set(merge(state.shared, state.assignments))

  initGoogleClassroomStore(state.googleClassroom)

  return { component: KlassesShow, messages, router }
}
