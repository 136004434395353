<template>
  <li :key="googleStudent.id" :class="liClasses" class="flex">
    <button
      class="small flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap text-left"
      data-test="link-student-list-item-button"
      :class="googleStudent.userId ? 'google-blue block' : 'text-gray-500'"
      :title="googleStudent.name"
      @click="$emit('link-student')"
    >
      <span v-if="googleStudent.userId" class="google-blue">
        <LzIcon size="sm" path="icons/student-linked" />
        {{ googleStudent.name }}
      </span>
      <span v-else class="text-gray-500">
        {{ googleStudent.name }}
      </span>
    </button>
    <div class="mt-0.5" style="width: 25px">
      <LzTooltip tooltip-classes="google-dark-tooltip">
        <LzIcon size="sm" path="icons/question-mark" />

        <template #content>
          {{ googleStudent.name }}
          <br />
          {{ googleStudent.email }}
        </template>
      </LzTooltip>
    </div>
    <div v-if="confirming" class="google-classroom-associate-dropdown__confirming">
      <p class="smaller mt-2">
        {{ $t('common.google_classroom.student_linked', { student_name: googleStudent.name }) }}
      </p>
      <div>
        <button class="small text-gray-800" @click="$emit('link-reject')">
          {{ $t('common.google_classroom.cancel_link_student_modal') }}
        </button>
        <button class="small google-blue ml-6" @click="$emit('link-confirm')">
          {{ $t('common.google_classroom.confirm_link_student_modal') }}
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import { LzIcon, LzTooltip } from 'vue_features/shared/components/ui'

export default {
  name: 'LinkStudentListItem',
  components: {
    LzIcon,
    LzTooltip,
  },
  props: {
    googleStudent: {
      type: Object,
      required: true,
    },
    selectedItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirming: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    liClasses() {
      const classes = ['cursor-pointer p-2 google-classroom-associate-dropdown']
      if (this.selectedItem) classes.push('bg-google-blue-light')

      return classes.join(' ')
    },
  },
}
</script>
