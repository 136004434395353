<template>
  <div class="page-content mb-6 space-y-6">
    <WhatFilterModal v-if="canModalShow('WhatFilterModal')" :filters="filters" @apply-filters="applyWhatFilters" />
    <WhenFilterModal
      v-if="canModalShow('WhenFilterModal')"
      :date-range="dateRange"
      @update-date-range="applyRangeChanges"
    />

    <div class="content mx-auto mt-4 w-full max-w-7xl space-y-2 px-4">
      <div v-if="dataOutOfRangeMessage" data-test="data-out-of-range-message" class="-mt-4">
        <Alert :description="dataOutOfRangeMessage" />
      </div>

      <!-- Top of page level indicator and breadcrumbs -->
      <div class="content mx-auto w-full max-w-7xl px-4">
        <h2 class="m-0 flex py-0 text-left font-bold capitalize">
          {{ drillDownHeading.toLowerCase() }}
        </h2>

        <Breadcrumbs v-if="showBreadcrumbsAndAllStandards" class="-mt-2" data-test="breadcrumbs" />
      </div>
    </div>

    <div class="content z-25 sticky top-0 mx-auto mt-4 w-full max-w-7xl space-y-2 px-4">
      <div
        class="bg-neutral-200 border-neutral-400 flex flex-row flex-wrap items-center gap-x-4 gap-y-4 rounded-xl border border-solid p-4"
      >
        <div class="flex items-center gap-x-2">
          <h5 class="my-0 text-sm font-semibold">{{ $t('what_filter_label') }}</h5>
          <button class="btn" @click="openModal('WhatFilterModal')">
            {{ subjectFilterLabel }} | {{ gradingStatusFilterLabel }} | {{ lessonTypeFilterLabel }}
          </button>
        </div>

        <div class="flex items-center gap-x-2">
          <h5 class="my-0 text-sm font-semibold">{{ $t('when_filter_label') }}</h5>
          <button class="btn" @click="openModal('WhenFilterModal')">
            {{ whenButtonLabel }}
          </button>
        </div>

        <a :href="announcementBannerUrl" target="_blank" class="text-accent flex cursor-pointer items-center text-sm">
          {{ $t('need_more_info') }}
          <LzIcon path="icons/external" size="sm" class="ml-2" icon-color-class="text-accent" />
        </a>
      </div>
    </div>

    <!-- Assignment type(s) and legend -->
    <div class="content mx-auto mb-4 w-full max-w-7xl">
      <div
        class="content border-neutral-300 mx-4 flex flex-col items-start gap-x-2 rounded-2xl border bg-white px-4 sm:flex-row sm:items-center"
      >
        <h3 class="flex-start flex">
          {{ lessonTypeFilterLabel }}
        </h3>

        <ScoringLegend
          include-no-score
          class="flex flex-grow flex-row flex-wrap items-center justify-start py-2 sm:justify-end sm:py-0"
        />
      </div>
    </div>

    <!-- Widgets + Empty state message if no data -->
    <div class="content mx-auto flex w-full max-w-7xl flex-col space-y-8">
      <section class="content mx-4">
        <div v-if="!hasAnyData && !loadingData" class="bg-focus border-neutral-200 rounded-2xl border">
          <EmptyStateWithIcon
            path="data_dashboard/NoData"
            :heading="$t('check_back_soon')"
            :body="$t('data_will_appear')"
            class="col-span-3"
          />
        </div>

        <div class="grid gap-6 lg:grid-cols-3">
          <DonutChart
            v-if="hasAnyOverviewData || overviewLoading"
            :legend-title="$t('total_assignment_scores')"
            :total-label="$t('assignments')"
            :title="donutChartTitle"
            :subtitle="$t('overview_subtitle')"
            :data="donutChartData"
            :loading="donutChartStatus.loading"
            :error="donutChartStatus.error"
            data-test="donut-chart"
          />
          <StandardsRankChart
            v-if="hasAnyOverviewData || overviewLoading"
            :data="topStandards"
            :loading="topStandardsStatus.loading"
            :error="topStandardsStatus.error"
            :title="$t('top_5_standards')"
            :subtitle="$t('standards_chart_subtitle')"
            :description="$t('top_5_standards_description')"
            :show-standards-alignment-warning="isPartiallyAligned"
            ranking-type="top"
            data-test="top-5-ranked-standards"
          />
          <StandardsRankChart
            v-if="hasAnyOverviewData || overviewLoading"
            :data="bottomStandards"
            :loading="bottomStandardsStatus.loading"
            :error="bottomStandardsStatus.error"
            :title="$t('bottom_5_standards')"
            :subtitle="$t('standards_chart_subtitle')"
            :description="$t('bottom_5_standards_description')"
            :show-standards-alignment-warning="isPartiallyAligned"
            ranking-type="bottom"
            data-test="bottom-5-ranked-standards"
          />
        </div>
      </section>

      <!-- Performance + Usage Section -->
      <section
        class="row content border-neutral-300 mx-4 mb-4 space-y-6 rounded-2xl border bg-white px-6 pb-8 shadow-sm"
      >
        <header class="relative z-10 flex items-center gap-x-2">
          <div class="flex flex-grow gap-x-2">
            <h3 class="mb-0 font-bold">{{ $t('performance_and_usage') }}</h3>
            <LzTooltip tag="span" :title="$t('performance_tooltip_title')" class="v-align-top pt-8 text-gray-700">
              <LzIcon path="icons/help" size="sm" icon-color-class="text-gray-500" />
              <template #content>
                <h5 class="font-bold text-gray-600">{{ $t('performance_tooltip_heading') }}</h5>
                <p class="text-sm">
                  {{ $t('performance_tooltip_averagescore') }}
                  {{ $t('performance_tooltip_lessons') }}
                </p>
              </template>
            </LzTooltip>
          </div>
        </header>
        <div>
          <p class="-mt-6 text-base">{{ $t('click_on_row') }}</p>
        </div>

        <PerformanceAndUsageTable data-test="performance-and-usage-table" />
      </section>

      <!-- Standards Section -->
      <section class="row content border-neutral-300 mx-4 space-y-6 rounded-2xl border bg-white px-6 pb-8 shadow-sm">
        <header class="flex flex-col items-start justify-between gap-y-2 md:flex-row md:items-center">
          <div class="flex flex-wrap items-center gap-x-2">
            <h3 class="mb-0 font-bold">{{ $t('standards') }}</h3>

            <LzTooltip tag="span" :title="$t('standards_tooltip_title')" class="mt-8 text-gray-700">
              <LzIcon path="icons/help" size="sm" class="text-gray-500" />
              <template #content>
                <h5 class="font-bold text-gray-600">{{ $t('standards_tooltip_heading') }}</h5>
                <p class="text-sm">
                  {{ $t('standards_tooltip_title') }}
                </p>
              </template>
            </LzTooltip>

            <div class="filters mt-6 flex flex-row">
              <ToggleButton :value="standardsTableFilter" @input="setStandardsTableFilter">
                <ToggleButtonOption value="top">
                  {{ $t('top_5') }}
                </ToggleButtonOption>

                <ToggleButtonOption value="bottom" data-test="grading-status-graded">
                  {{ $t('bottom_5') }}
                </ToggleButtonOption>

                <ToggleButtonOption v-if="showBreadcrumbsAndAllStandards" value="all">
                  {{ $t('all') }}
                </ToggleButtonOption>
              </ToggleButton>
            </div>
          </div>

          <div class="flex gap-x-2 self-start md:items-center md:justify-end md:self-center">
            <LzTooltip tag="span" :title="$t('performance_tooltip_title')" class="v-align-top text-gray-700">
              <Chip
                v-if="isPartiallyAligned"
                data-test="standards-alignment-warning"
                :label="$t('alignment_criteria')"
                color="primary"
                size="sm"
              />
              <template #content>
                <h5 class="font-bold text-gray-600">{{ $t('alignment_criteria') }}</h5>
                <p class="text-sm">
                  {{ $t('alignment_criteria_tooltip') }}
                </p>
              </template>
            </LzTooltip>

            <div v-if="showStandardsCount" class="flex items-center">
              <span class="font-bold">{{ $t('standards') }}:&nbsp;</span>
              <span>{{ standardsCount }}</span>
            </div>
          </div>
        </header>
        <div>
          <p class="-mt-6 text-base">{{ $t('click_on_row') }}</p>
        </div>

        <StandardsTable data-test="standards-table" class="pb-4" />
        <div v-if="noCleverGradeLevels" class="row content" data-test="no-clever-grade-levels">
          <Notice :title="$t('clever_notice_title')" icon="icons/synced-arrows">
            {{ $t('clever_notice_body') }}
          </Notice>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue'
import dayjs from 'dayjs'
import debug from 'debug'
import {
  LzTooltip,
  Chip,
  LzIcon,
  Alert,
  EmptyStateWithIcon,
  ToggleButton,
  ToggleButtonOption,
} from 'vue_features/shared/components/ui'
import DonutChart from './DonutChart'
import PerformanceAndUsageTable from './PerformanceAndUsageTable'
import StandardsRankChart from './StandardsRankChart'
import StandardsTable from './StandardsTable'
import WhatFilterModal from './WhatFilterModal'
import WhenFilterModal from './WhenFilterModal'
import Breadcrumbs from './Breadcrumbs'
import Notice from './shared/Notice'
import ScoringLegend from 'vue_features/shared/components/ScoringLegend'
import { capitalize, get } from 'lodash'
import { useDataDashboardStore, LESSON_TYPES } from '../composables/data_dashboard_store'
import { formatDateFilter } from 'vue_features/item_analysis/utils'
import { HELP_DATA_DASHBOARD_GUIDE_URL } from 'rails_constants'
import { canModalShow, closeModal, openModal } from 'vue_features/shared/composables/use_global_modals'
import { useRouter } from 'vue-router'

const log = debug('app:vue_features/data_dashboard/ui')

export default {
  name: 'DataDashboardUi',
  components: {
    Alert,
    Breadcrumbs,
    Notice,
    EmptyStateWithIcon,
    LzTooltip,
    Chip,
    LzIcon,
    StandardsTable,
    DonutChart,
    StandardsRankChart,
    PerformanceAndUsageTable,
    ToggleButton,
    ToggleButtonOption,
    ScoringLegend,
    WhatFilterModal,
    WhenFilterModal,
  },
  props: {},
  setup() {
    const root = inject('useRoot')()
    const {
      filters,
      standardsTableFilter,
      hasAnyOverviewData,
      overviewLoading,
      donutChartData,
      topStandards,
      bottomStandards,
      standardsTableData,
      donutChartStatus,
      topStandardsStatus,
      bottomStandardsStatus,
      standardsTableStatus,
      allowedFilters,
      hasAnyData,
      loadingData,
      noCleverGradeLevels,
      updateFilters,
      isPartiallyAligned,
    } = useDataDashboardStore()

    const subjectFilterLabel = computed(() => {
      return capitalize(filters.value.subject || root.$t('all_subjects_full'))
    })

    const gradingStatusFilterLabel = computed(() => {
      return capitalize(filters.value.gradingStatus || root.$t('all_grading'))
    })

    const typeLabels = {
      assessment: root.$t('type_assessment'),
      activity: root.$t('type_activity'),
      full: root.$t('type_full'),
    }
    const lessonTypeFilterLabel = computed(() => {
      const lessonTypes = filters.value.lessonTypes || [LESSON_TYPES.ASSESSMENT]
      return lessonTypes.length === 0 || lessonTypes.length === 3
        ? root.$t('all_lesson_types')
        : lessonTypes.map((type) => typeLabels[type]).join(', ')
    })

    const router = useRouter()
    function selectSubject({ value: subject }) {
      updateFilters(router, { subject })
      log('set subject value: %s', subject)
    }
    const setStandardsTableFilter = (value) => {
      if (standardsTableFilter.value === value) {
        standardsTableFilter.value = null
        return
      }

      standardsTableFilter.value = value
    }
    const announcementBannerUrl = HELP_DATA_DASHBOARD_GUIDE_URL

    const drillDownHeading = computed(() => {
      const currentFilters = allowedFilters.value
      if (!currentFilters) {
        return ''
      } else if (currentFilters.drillDownLevel === 'gradeLevel') {
        return root.$t('site')
      } else if (currentFilters.drillDownLevel === 'school') {
        return `${root.$t('grade_level_n', { num: currentFilters.gradeLevel })}`
      } else if (currentFilters.drillDownLevel === 'teacher') {
        return currentFilters.schoolName
      } else if (currentFilters.drillDownLevel === 'student') {
        return currentFilters.teacherName
      } else if (currentFilters.drillDownLevel === 'assignment') {
        return currentFilters.studentName
      } else {
        return ''
      }
    })

    const showBreadcrumbsAndAllStandards = computed(() => get(allowedFilters.value, 'drillDownLevel') !== 'gradeLevel')

    const dataOutOfRangeMessage = computed(() => {
      const currentFilters = allowedFilters.value
      if (currentFilters && currentFilters.earliestDate) {
        return root.$t('data_out_of_range_message')
      } else {
        return null
      }
    })

    const fullSubjectTranslations = {
      ela: root.$t('ela_full'),
      math: root.$t('math_full'),
      science: root.$t('science_full'),
    }

    const donutChartTitle = computed(() => {
      return fullSubjectTranslations[allowedFilters.value?.subject] || root.$t('all_subjects_full')
    })

    const showStandardsCount = computed(
      () =>
        standardsTableFilter.value === 'all' &&
        standardsTableData.value &&
        standardsTableData.value.standards &&
        standardsTableData.value.standards.length > 0,
    )

    const standardsCount = computed(() => {
      if (!standardsTableData.value || !standardsTableData.value.standards) {
        return 0
      }

      return standardsTableData.value.standards.length
    })

    const dateRange = ref({ start: filters.value.afterDate, end: filters.value.beforeDate })

    const whenButtonLabel = computed(() => {
      if (dateRange.value.start && dateRange.value.end) {
        return `${dayjs(dateRange.value.start).format('MMM D, YYYY')} - ${dayjs(dateRange.value.end).format(
          'MMM D, YYYY',
        )}`
      }
      return root.$t('common.nothing_selected')
    })

    const applyRangeChanges = ({ start, end }) => {
      dateRange.value.start = start
      dateRange.value.end = end

      updateFilters(router, { afterDate: formatDateFilter(start), beforeDate: formatDateFilter(end) })
      closeModal('WhenFilterModal')
    }

    const applyWhatFilters = ({ subject, gradingStatus, lessonTypes }) => {
      closeModal('WhatFilterModal')
      updateFilters(router, {
        gradingStatus,
        lessonTypes,
        subject,
      })
    }

    return {
      openModal,
      canModalShow,
      dateRange,
      whenButtonLabel,
      applyRangeChanges,
      filters,
      hasAnyOverviewData,
      overviewLoading,
      setStandardsTableFilter,
      isPartiallyAligned,
      selectSubject,
      donutChartData,
      topStandards,
      bottomStandards,
      standardsTableData,
      donutChartStatus,
      topStandardsStatus,
      bottomStandardsStatus,
      standardsTableStatus,
      drillDownHeading,
      hasAnyData,
      loadingData,
      noCleverGradeLevels,
      standardsTableFilter,
      dataOutOfRangeMessage,
      allowedFilters,
      donutChartTitle,
      showBreadcrumbsAndAllStandards,
      showStandardsCount,
      standardsCount,
      announcementBannerUrl,
      applyWhatFilters,
      subjectFilterLabel,
      gradingStatusFilterLabel,
      lessonTypeFilterLabel,
    }
  },
}
</script>
