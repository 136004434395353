<template>
  <div v-if="hasPendingInvites" class="mb-12 mt-6">
    <p class="mb-0 py-4">
      <strong>{{ totalCount }}</strong>
      {{ $t('pending_collections') }}
    </p>
    <LoadMoreList
      v-slot="{ item: invite }"
      :items="pendingInvites"
      :total-count="totalCount"
      :start-count="10"
      @load-more="handleLoadMore"
    >
      <Invite :key="invite.id" :invite="invite" />
    </LoadMoreList>
  </div>
</template>

<script>
import { useCollectionsIndexStore } from '../store/use_collections_index_store'
import Invite from './Invite'
import LoadMoreList from 'vue_features/shared/components/lists/LoadMoreList'
import { computed } from 'vue'

export default {
  name: 'InviteList',
  components: { LoadMoreList, Invite },
  setup() {
    const { getPendingInvites, pendingInvites, pendingInvitesData } = useCollectionsIndexStore()
    const totalCount = computed(() => pendingInvitesData.value.meta.pagination.totalCount)

    return {
      pendingInvites,
      getPendingInvites,
      totalCount,
    }
  },
  computed: {
    hasPendingInvites() {
      return this.pendingInvites.length > 0
    },
  },
  methods: {
    handleLoadMore(done) {
      const page = this.pendingInvites.filter((invite) => invite.customerShare.status === 'pending').length
      this.getPendingInvites(page).then(done)
    },
  },
}
</script>
