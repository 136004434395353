import { computed, unref } from 'vue'
import { useDataTable } from 'vue_features/shared/components/data_table/use_data_table'

export const useItemScoreDataTable = (assignmentViews) => {
  const assignmentViewsRef = computed(() => unref(assignmentViews))

  const items = computed(() => {
    const viewWithItems = assignmentViewsRef.value.find((view) => Object.values(view.reportData.items).length > 0)

    if (!viewWithItems) {
      return []
    }

    return Object.values(viewWithItems.reportData.items).sort((a, b) => {
      if (a.itemNumber === b.itemNumber) {
        return 0
      }

      return a.itemNumber < b.itemNumber ? -1 : 1
    })
  })

  const rows = computed(() =>
    assignmentViewsRef.value.map((assignmentView) => {
      const assignmentScore = { score: 0.0, maxScore: 0.0 }
      const assignmentItems = Object.values(assignmentView.reportData.items) || []
      const itemsWithScore = assignmentItems.map((item) => {
        if (item.maxScore > 0) {
          assignmentScore.score += item.score || 0
          assignmentScore.maxScore += item.maxScore
        }

        return {
          ...item,
          sortableScore: sortableScoreForItem(item),
        }
      })
      const someItemsUnscored = assignmentItems.some((item) => item.unmarked)

      return {
        ...assignmentView,
        items: itemsWithScore,
        sortableAssignmentScore: sortableScoreForAssignmentView(
          {
            ...assignmentView,
            unmarked: someItemsUnscored,
          },
          Math.round((assignmentScore.score * 100.0) / assignmentScore.maxScore),
        ),
        ...itemsWithScore.reduce((scoreColumns, itemWithScore) => {
          scoreColumns[`item-${itemWithScore.itemNumber}`] = itemWithScore.sortableScore
          return scoreColumns
        }, {}),
      }
    }),
  )

  const dataTableOptions = computed(() => {
    const sortFieldAs = {
      date: (assignmentView) => [assignmentView.closeAt, assignmentView.openAt],
    }

    for (const [index, item] of unref(items).entries()) {
      sortFieldAs[`item-${item.itemNumber}`] = (assignmentView) => {
        return assignmentView.items[index]?.sortableScore
      }
    }

    return { sortFieldAs }
  })

  const dataTable = useDataTable(rows, dataTableOptions)

  return {
    items,
    rows: dataTable.rows,
    dataTable,
  }
}

function sortableScoreForAssignmentView(assignmentView, score) {
  const notAttempted = assignmentView.learnositySessionId === null
  const closedOrReturned = assignmentView.assignmentState === 'closed' || assignmentView.assignmentState === 'returned'

  if (notAttempted) {
    return 999999
  } else if (closedOrReturned && assignmentView.unmarked) {
    return -1
  } else if (closedOrReturned) {
    return score
  } else {
    return 101
  }
}

function sortableScoreForItem(item) {
  const score = item.score || 0
  const maxScore = item.maxScore || 0
  const needsGrading = item.attempted && item.score === null

  if (!item.attempted) {
    return 999999
  } else if (needsGrading) {
    return -1
  } else {
    return maxScore === 0 ? 0 : (score * 1.0) / maxScore
  }
}
