<!-- eslint-disable vuejs-accessibility/label-has-for | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div id="wiki-edit-feature" class="resource__content collection-page__content">
    <EditBanner
      :state="wiki.state"
      :object-name="$t(getLabel(wiki))"
      :object-id="wiki.id"
      :cancel-path="polymorphicPath(wiki)"
      action-name="customize"
      object-type="Resource"
      @save="save"
    />
    <Header :resource="wiki" />
    <div class="mb-6">
      <StandardsCoverageTableModal
        v-if="canModalShow(`standards-coverage-table-modal`)"
        v-bind="standardsTableContent"
        :on-save="onSave"
      />
      <LzContentModal v-if="canModalShow('lz-content-modal')" :content-type="contentType" :on-save="onSave" />
      <div id="wiki-content" class="mx-auto max-w-7xl px-3 md:px-6">
        <div class="text-right">
          <label class="border-base bg-focus mb-4 inline-flex cursor-pointer rounded-md border border-solid px-3 py-2">
            <input v-model="showHiddenBlocks" type="checkbox" />
            <span class="ml-2">{{ $t('wikis.show_hidden') }}</span>
          </label>
        </div>
        <div class="form-group">
          <TopSticky :id="editorId" class="wiki-editor-toolbar" />
          <WikiDraggableBlocks
            v-model="blocks"
            :editor-id="editorId"
            :total-block-count="totalBlockCount"
            :is-adding-inserts="true"
            :class="{ 'hide-hidden': !showHiddenBlocks }"
            @update-block="updateBlock"
            @remove-block="removeBlock"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from 'vue_features/resources/shared/components/Header'
import StandardsCoverageTableModal from 'vue_features/standards_coverage_table/components/StandardsCoverageTableModal'
import LzContentModal from 'vue_features/resources/wikis/components/lz_content_modal/LzContentModal'
import { activeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { watch, ref, computed } from 'vue'
import { get, uniqueId } from 'lodash'
import WikiDraggableBlocks from 'vue_features/resources/wikis/components/WikiDraggableBlocks'
import WikiEditsService from '../api'
import { location } from 'utils'
import useEditableBlocks from 'vue_features/resources/wikis/composables/use_editable_blocks'
import useDeletedBlocks from 'vue_features/resources/wikis/composables/use_deleted_blocks'
import useDeletedEdits from 'vue_features/wiki_edits/store/use_deleted_edits'
import useBlockEdits from '../composables/use_block_edits'
import Routes from 'routes'
import TopSticky from 'vue_features/shared/components/ui/TopSticky'
import EditBanner from 'vue_features/shared/components/forms/EditBanner'
import { getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import { polymorphicPath } from 'routes/route_helpers'

export default {
  name: 'WikiEditsIndex',
  components: {
    EditBanner,
    TopSticky,
    Header,
    StandardsCoverageTableModal,
    LzContentModal,
    WikiDraggableBlocks,
  },
  props: {
    wiki: { type: Object, required: true },
  },
  setup(props) {
    const onSave = computed(() => {
      return get(activeModal.value.payload, 'onSave')
    })
    const standardsTableContent = computed(() => {
      return get(activeModal.value.payload, 'content')
    })
    const contentType = computed(() => {
      return get(activeModal.value.payload, 'contentType') || ''
    })
    const { blocks, totalBlockCount, updateBlock, removeBlock } = useEditableBlocks(props.wiki.blocks)
    const errors = ref([])

    const { blockInserts, blockInsertIds, newBlockHides } = useBlockEdits(blocks)
    const { deletedEditIds, deleteInsert } = useDeletedEdits()
    watch(blockInsertIds, (newBlockInsertIds, oldBlockInsertIds) => {
      if (newBlockInsertIds.length < oldBlockInsertIds.length) {
        oldBlockInsertIds.forEach((insertId) => {
          if (!newBlockInsertIds.includes(insertId)) {
            deleteInsert(insertId)
          }
        })
      }
    })

    const { deletedBlocks } = useDeletedBlocks()
    const deletedBlockIds = computed(() => {
      return deletedBlocks.value
        .filter((block) => {
          return !deletedEditIds.value.includes(block.blockInsert.id)
        })
        .map((b) => b.id)
    })

    const showHiddenBlocks = ref(true)
    const isSaving = ref(false)

    return {
      Routes,
      blocks,
      totalBlockCount,
      updateBlock,
      removeBlock,
      onSave,
      standardsTableContent,
      contentType,
      canModalShow,
      errors,
      deletedEditIds,
      deletedBlockIds,
      blockInserts,
      newBlockHides,
      showHiddenBlocks,
      isSaving,
      getLabel,
    }
  },
  computed: {
    editorId() {
      return uniqueId('wiki-editor-toolbar-')
    },
  },
  methods: {
    polymorphicPath,
    save() {
      this.errors = []
      this.isSaving = true
      WikiEditsService.updateCustomerEdits({
        wikiId: this.wiki.id,
        blockInserts: this.blockInserts,
        blockHides: this.newBlockHides,
        deletedBlockIds: this.deletedBlockIds,
        deletedEditIds: this.deletedEditIds,
      })
        .then(() => {
          location.assign(Routes.wiki_path(this.wiki.id))
        })
        .catch(({ responseJSON }) => {
          if (!responseJSON) {
            this.errors = [this.$t('wikis.unexpected_error')]
          }
          this.errors = Object.keys(responseJSON.errors).flatMap((key) => responseJSON.errors[key])
          this.isSaving = false
        })
    },
  },
}
</script>
