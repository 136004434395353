<template>
  <!-- eslint-disable-next-line vuejs-accessibility/alt-text | Component has been added to the plugin allowlist so parent components that implement must add alt tag -->
  <img :src="src" />
</template>

<script>
import { captureException } from 'clients/sentry'
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return { src: null }
  },
  created() {
    import(
      /* webpackMode: "eager" */
      /* webpackExclude: /\.svg$/ */
      `images/${this.path}`
    )
      .then(({ default: src }) => (this.src = src))
      .catch(captureException)
  },
}
</script>
