<template>
  <LzModal
    :modal-id="$options.name"
    :modal-title="studentOnly ? $t('show.student_login') : null"
    :class="['lzui-modal--medium lzui-modal--confirmation', modalClasses]"
    :can-cancel="false"
  >
    <AssignmentStudentLoginForm v-if="studentOnly" />
    <AssignmentLoginForm v-else />
  </LzModal>
</template>

<script>
import { computed } from 'vue'
import { LzModal } from 'vue_features/shared/components/ui'
import AssignmentLoginForm from './AssignmentLoginForm'
import AssignmentStudentLoginForm from './AssignmentStudentLoginForm'

export default {
  name: 'AssignmentLoginModal',
  components: { LzModal, AssignmentStudentLoginForm, AssignmentLoginForm },
  props: {
    studentOnly: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      modalClasses: computed(() => (props.studentOnly ? 'text-left' : '')),
    }
  },
}
</script>
