import { ref, reactive, computed, watch } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import GradingSessionService from '../api/grading_session_service'
import { useLzCodeViewChannel } from './use_lz_code_view_channel'
import { newQuestionsApp } from 'clients/learnosity'
import RandomNameGenerator from '../utils/random_name_generator'
import { useLearnositySessionResponseStore } from './use_learnosity_session_response_store'
import { useLearnositySessionItemStore } from './use_learnosity_session_item_store'
import { groupBy, sortBy } from 'lodash'
import { useCurrentUserStore } from 'vue_features/shared/store/composables/use_current_user_store'

export const useGradingStore = defineStore('Grading', () => {
  const state = reactive({
    assignment: null,
    questions: {},
    sessions: [],
    items: [],
    lzCodeKlasses: [],
    currentSessionId: null,
    currentItemIndex: 0,
    loadingSessionResponses: false,
    loadedItemScores: false,
    reviewingSessionIds: [],
    subscriptionsBySession: {},
    hideGraded: false,
    hideNames: false,
    hideOpenAssignments: false,
    showSessionChooser: false,
    showSessionCommentModal: false,
    sortNamesBy: 'first_name',
    showTipsModal: false,
    totalAssigned: 0,
  })

  const { learnosityItemScoresBySession, storeUpdatedItemScore } = useLearnositySessionItemStore()

  const {
    learnosityQuestionsApiConfig,
    learnosityResponseScoresBySession,
    storeUpdatedResponse,
    loadResponseScores,
    updateResponseScores,
    updateResponseComment,
    deleteResponseComment,
  } = useLearnositySessionResponseStore()

  const { id: currentUserId } = useCurrentUserStore()

  const renderingLearnosityQuestions = ref(false)

  const loadingSession = computed(() => state.loadingSessionResponses || renderingLearnosityQuestions.value)

  const questionsByReference = computed(() => {
    return groupBy(state.questions, 'reference')
  })

  const questionsByItem = computed(() => {
    return groupBy(state.questions, 'itemReference')
  })

  const questionsForItem = (itemReference) => {
    return questionsByItem.value[itemReference]
  }

  const progressByItem = computed(() => {
    return Object.values(learnosityItemScoresBySession.value).reduce((all, items) => {
      items.forEach((item) => {
        all[item.reference] ||= 0

        if (item.attempted) {
          all[item.reference] += 1
        }
      })

      return all
    }, {})
  })

  const progressForItem = (itemReference) => {
    return progressByItem.value[itemReference]
  }

  const averageScoresByItem = computed(() => {
    return Object.values(learnosityItemScoresBySession.value).reduce((all, items) => {
      items.forEach((item) => {
        all[item.reference] ||= 0

        if (!item.unmarked && item.maxScore > 0) {
          all[item.reference] += (item.score * 100.0) / item.maxScore
        }
      })

      return all
    }, {})
  })

  const averageScoreForItem = (itemReference) => {
    const score = averageScoresByItem.value[itemReference]

    if (score) {
      return score / state.sessions.length
    } else {
      return 0
    }
  }

  const lzCodeKlassesById = computed(() => groupBy(state.lzCodeKlasses, 'id'))

  const unfilteredSessions = computed(() => {
    return sortBy(
      state.sessions.map((session, index) => {
        const itemScores = learnosityItemScoresBySession.value[session.learnositySessionId] || []
        let responseScores = learnosityResponseScoresBySession.value[session.learnositySessionId] || []
        responseScores = responseScores.map((response) => {
          const question = questionsByReference.value[response.questionReference][0]
          return {
            ...response,
            maxScore: response.maxScore ?? question.maxScore,
          }
        })
        const unmarked = itemScores.some((itemScore) => itemScore.unmarked || itemScore.score === null)
        const lzCodeKlass = lzCodeKlassesById.value[session.lzCodeKlassId][0] || {}
        const studentInitials = [session.studentFirstName, session.studentLastName].map((name) => name.at(0)).join('')

        const studentNameObject = state.hideNames
          ? randomizedNameForSession(session)
          : { firstName: session.studentFirstName, lastName: session.studentLastName }
        const studentName =
          state.sortNamesBy === 'first_name'
            ? `${studentNameObject.firstName} ${studentNameObject.lastName}`
            : `${studentNameObject.lastName}, ${studentNameObject.firstName}`

        return {
          id: session.id,
          learnositySessionId: session.learnositySessionId,
          klassId: lzCodeKlass.klassId,
          lzCode: state.assignment.code,
          comment: session.comment,
          assignmentState: session.assignmentState,
          items: itemScores,
          responses: responseScores,
          studentInitials,
          studentName,
          unmarked,
        }
      }),
      'klassId',
      'studentName',
    )
  })

  const filteredSessions = computed(() => {
    let sessions = unfilteredSessions.value

    if (state.reviewingSessionIds.length > 0) {
      sessions = sessions.filter((session) => state.reviewingSessionIds.includes(session.id))
    }

    if (state.hideGraded) {
      sessions = sessions.filter((session) => session.unmarked)
    }

    if (state.hideOpenAssignments) {
      sessions = sessions.filter((session) => session.assignmentState !== 'opened')
    }

    return sessions
  })

  const randomizedNamesForSessions = {}

  const randomizedNameForSession = (session) => {
    randomizedNamesForSessions[session.id] ||= RandomNameGenerator.generate()
    return randomizedNamesForSessions[session.id]
  }

  const storeUpdatedSession = (updatedSession) => {
    const sessionIndex = state.sessions.findIndex((session) => session.id === updatedSession.id)

    if (sessionIndex === -1) {
      return
    }

    const existingSession = state.sessions[sessionIndex]
    const sessions = state.sessions
    sessions.splice(sessionIndex, 1, { ...existingSession, ...updatedSession })

    state.sessions = sessions
  }

  const updateSessionState = (session, state) => {
    const updatedSession = { ...session, assignmentState: state }

    return GradingSessionService.updateSession(session.lzCode, session.id, updatedSession)
  }

  const updateSessionComment = async (session, comment, publish) => {
    const existingComment = session.comment || {}
    const updatedComment = { ...existingComment, content: comment, published: comment && publish }
    const data = await GradingSessionService.updateComment(session.lzCode, session.id, updatedComment, publish)
    storeUpdatedSession({ ...session, comment: data.comment })
  }

  const deleteSessionComment = async (session, id) => {
    const data = await GradingSessionService.deleteComment(id)
    if (data) storeUpdatedSession({ ...session, comment: {} })
  }

  const subscriptionForSession = (session) => {
    return state.subscriptionsBySession[session.learnositySessionId]
  }

  const currentSessionIndex = computed(() => {
    const index = filteredSessions.value.findIndex((session) => session.id === state.currentSessionId)
    return index !== -1 ? index : 0
  })

  const currentSession = computed(() => {
    return filteredSessions.value[currentSessionIndex.value]
  })

  const gotoSession = (index) => {
    if (loadingSession.value) {
      return
    }

    const clampedIndex = Math.max(0, Math.min(filteredSessions.value.length - 1, index))
    state.currentSessionId = filteredSessions.value[clampedIndex]?.id
  }

  const gotoPreviousSession = () => {
    if (currentSessionIndex.value === 0) {
      return false
    } else {
      gotoSession(currentSessionIndex.value - 1)
      return true
    }
  }

  const gotoNextSession = () => {
    if (currentSessionIndex.value === filteredSessions.value.length - 1) {
      return false
    } else {
      gotoSession(currentSessionIndex.value + 1)
      return true
    }
  }

  const sessionResponses = computed(() => {
    return currentSession.value.responses || []
  })

  const sessionResponsesByItem = computed(() =>
    sessionResponses.value.reduce((map, response) => {
      map[response.itemReference] ||= []
      map[response.itemReference].push(response)
      return map
    }, {}),
  )

  const sessionResponsesForItem = (itemReference) => {
    return sessionResponsesByItem.value[itemReference] || []
  }

  const sessionResponsesByQuestion = computed(() =>
    sessionResponses.value.reduce((map, response) => {
      map[response.questionReference] = response
      return map
    }, []),
  )

  const sessionResponseToQuestion = (question) => {
    return sessionResponsesByQuestion.value[question.reference] || {}
  }

  const sessionItems = computed(() => {
    const itemScoresByItemReference = currentSession.value.items.reduce(
      (map, item) => ({
        ...map,
        [item.reference]: item,
      }),
      {},
    )

    return state.items.map((item, index) => {
      const itemScore = itemScoresByItemReference[item.itemReference]
      const responseScores = sessionResponsesForItem(item.itemReference)
      const progress = progressForItem(item.itemReference) || 0
      const progressPercentage = state.totalAssigned > 0 ? (progress * 100.0) / state.totalAssigned : 0

      return {
        ...item,
        reference: item.itemReference,
        number: index + 1,
        progress: progressPercentage,
        score: itemScore?.score,
        maxScore: itemScore?.maxScore,
        attempted: itemScore?.attempted,
        unmarked: itemScore?.unmarked,
        updating: responseScores.some((response) => response.updating),
      }
    })
  })

  const currentItem = computed(() => sessionItems.value[state.currentItemIndex])

  const gotoItem = (index) => {
    state.currentItemIndex = Math.max(0, Math.min(sessionItems.value.length - 1, index))
  }

  const gotoPreviousItem = () => {
    if (state.currentItemIndex === 0) {
      return false
    } else {
      gotoItem(state.currentItemIndex - 1)
      return true
    }
  }

  const gotoNextItem = () => {
    if (state.currentItemIndex === sessionItems.value.length - 1) {
      return false
    } else {
      gotoItem(state.currentItemIndex + 1)
      return true
    }
  }

  const sessionLzCodeKlasses = computed(() => new Set(state.sessions.map((session) => session.lzCodeKlassId)))
  const klasses = computed(() =>
    state.lzCodeKlasses
      .filter((lzCodeKlass) => sessionLzCodeKlasses.value.has(lzCodeKlass.id))
      .map((lzCodeKlass) => lzCodeKlass.klass),
  )
  const currentSessionKlass = computed(() => klasses.value.find((klass) => klass.id === currentSession.value?.klassId))

  const teacherIds = computed(() => {
    return new Set(klasses.value.flatMap((klass) => klass.teacherIds))
  })

  const currentUserTeachesKlass = computed(() => {
    return teacherIds.value.has(currentUserId.value)
  })

  watch(currentSession, async (session, previousSession) => {
    const sessionChanged = session?.id !== previousSession?.id

    if (session?.id && sessionChanged) {
      state.loadingSessionResponses = true
      await loadResponseScores(session, state.items)
      state.loadingSessionResponses = false
    }
  })

  watch([learnosityQuestionsApiConfig, () => state.loadedItemScores], ([config, loaded]) => {
    if (!config || !loaded) {
      return
    }

    renderingLearnosityQuestions.value = true

    newQuestionsApp(config).finally(() => {
      renderingLearnosityQuestions.value = false
    })
  })

  return {
    state,
    questionsForItem,
    klasses,
    currentItem,
    unfilteredSessions,
    filteredSessions,
    currentSession,
    currentSessionIndex,
    gotoSession,
    gotoPreviousSession,
    gotoNextSession,
    gotoItem,
    gotoPreviousItem,
    gotoNextItem,
    sessionItems,
    sessionResponses,
    sessionResponsesForItem,
    sessionResponseToQuestion,
    loadResponseScores,
    updateResponseScores,
    storeUpdatedResponse,
    storeUpdatedItemScore,
    loadingSession,
    averageScoreForItem,
    subscriptionForSession,
    updateResponseComment,
    deleteResponseComment,
    updateSessionState,
    updateSessionComment,
    deleteSessionComment,
    storeUpdatedSession,
    currentUserTeachesKlass,
    randomizedNameForSession,
    currentSessionKlass,
  }
})

export const init = (initialState) => {
  const { $set, state, unfilteredSessions, storeUpdatedResponse } = useGradingStore()
  const { loadItemScores, storeUpdatedItemScore } = useLearnositySessionItemStore()

  const reviewingSessionIds = initialState.reviewingSessionIds || []

  delete initialState.reviewingSessionIds

  $set(initialState)

  if (reviewingSessionIds.length === 0) {
    state.reviewingSessionIds = unfilteredSessions.value.map((session) => session.id)
  } else {
    state.reviewingSessionIds = reviewingSessionIds
  }

  const handleSessionMessages = (session, message) => {
    const { updatedResponseScores, updatedItemScores } = message

    if (updatedResponseScores) {
      for (const updatedResponseScore of updatedResponseScores) {
        storeUpdatedResponse(session, updatedResponseScore)
      }
    }

    if (updatedItemScores) {
      for (const updatedItemScore of updatedItemScores) {
        storeUpdatedItemScore(session, updatedItemScore)
      }
    }
  }

  state.subscriptionsBySession = state.sessions.reduce((all, session) => {
    const { subscription, isConnecting, connect } = useLzCodeViewChannel()

    all[session.learnositySessionId] = { isConnecting, subscription }

    connect(session.id, {
      onReceived: (message) => handleSessionMessages(session, message),
    })

    return all
  }, {})

  loadItemScores(state.assignment, state.items, { perPage: state.sessions.length }).then(
    () => (state.loadedItemScores = true),
  )
}
