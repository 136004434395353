<template>
  <LzModal
    modal-title="Change User Association"
    :modal-id="modalId"
    class="lzui-modal--medium"
    :autoclose="false"
    @close-modal="hideModal"
  >
    <div class="no-fullstory">
      <p>{{
        $t('common.google_classroom.confirm_link_student', {
          google_student_name: googleClassroomStudent.name,
          student_name: studentName,
        })
      }}</p>
    </div>
    <footer>
      <button class="btn" @click="cancel">{{ $t('common.cancel') }}</button>
      <button class="btn btn--primary caution" @click="confirm">{{ $t('confirm_modal.confirm') }}</button>
    </footer>
  </LzModal>
</template>

<script>
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal } from 'vue_features/shared/composables/use_global_modals'

export default {
  name: 'LinkGoogleConfirmModal',
  components: { LzModal },
  props: {
    studentName: {
      type: String,
      required: true,
    },
    googleClassroomStudent: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
      default: function () {
        return 'LinkGoogleConfirmModal'
      },
    },
  },
  setup() {
    return { closeModal }
  },
  methods: {
    hideModal() {
      this.closeModal(this.modalId)
    },
    cancel() {
      this.hideModal()
      this.$emit('cancel')
    },
    confirm() {
      this.hideModal()
      this.$emit('confirm', this.googleClassroomStudent)
    },
  },
}
</script>
