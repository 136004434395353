<template>
  <DetailListItem
    :title="title"
    :icon="defaultIcon"
    :show-thumbnail="false"
    :metadata="metadata"
    :href="content && content.url ? content.url : ''"
    class="overflow-visible"
    :class="[{ 'meta-hide': isHidden, 'meta-insert': isInserted, 'has-update': hasUpdate }]"
  >
    <slot />
    <ProgressCover :visible="isLoading" class="loading-spinner__cover" />
    <template #handle>
      <!--FIXME
          Is the tall-move-grip the correct icon here?
      <-->
      <DragHandle v-if="isEditable" />
    </template>
    <template #title>
      <span v-if="isLostContent" class="flex flex-col gap-1">
        <span class="w-full overflow-x-hidden truncate">
          {{ title }}
        </span>
        <span class="max-w-max rounded-full bg-red-200 px-2 text-sm font-bold leading-5 tracking-wide text-red-700">
          {{ $t('index.content_lost') }}
        </span>
      </span>
      <template v-if="isNormalCard">
        <h3 class="text-accent group-hover:text-accent-hover my-0 text-xl font-bold leading-7">
          {{ title }}
        </h3>
        <LzContentStateBadge :state="content.state" />
      </template>
      <h3 v-if="isTitleCard" class="my-0">
        {{ title }}
      </h3>
      <MetadataByline v-if="fromMetadata" :logo="fromMetadata.logo" :name="fromMetadata.name" />
    </template>
    <template #actions>
      <template v-if="isEditable">
        <button class="btn text-center" data-test="edit-material-btn" @click.prevent="toggleEditing(card)">
          {{ $t('common.edit') }}
        </button>
        <button data-test="remove-material" class="btn" @click.prevent="openModal(deleteCardModalId)">
          {{ $t('common.delete') }}
        </button>
      </template>
      <button v-if="isHidden" class="btn" @click.prevent="show">{{ capitalize($t('common.show')) }}</button>
      <button v-if="canBeHidden" class="btn" @click.prevent="hide">{{ capitalize($t('common.hide')) }}</button>
    </template>
    <UpdatedNotification v-if="hasUpdate" v-bind="activeEdit.lastUpdate" :creation-verb="creationVerb" />
    <template #unlinked-content>
      <DeleteCardModal v-if="canModalShow(deleteCardModalId)" :modal-id="deleteCardModalId" @deleting="deleteCard" />
    </template>
  </DetailListItem>
</template>

<script>
import { capitalize } from 'lodash'
import { DetailListItem, DragHandle } from 'vue_features/shared/components/lists'
import DeleteCardModal from './DeleteCardModal'
import LzContentStateBadge from 'vue_features/lz_content/components/LzContentStateBadge'
import { ProgressCover } from 'vue_features/shared/components/ui'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import UpdatedNotification from 'vue_features/shared/components/UpdatedNotification'
import { useAdditionalMaterialsStore } from 'vue_features/lesson_plans/edit/store/use_additional_materials_store'
import { ref, computed } from 'vue'
import useCardActiveEdit from '../composables/use_card_active_edit'
import { useCurrentCustomerStore } from 'vue_features/shared/store/composables'
import MetadataByline from 'vue_features/shared/components/lists/MetadataByline'
import { getIcon, getLabel } from 'vue_features/shared/composables/use_content_type_metadata'
import { isDraft } from 'vue_features/shared/composables/use_record_states'

export default {
  name: 'MaterialDetailItem',
  components: {
    MetadataByline,
    DeleteCardModal,
    DetailListItem,
    DragHandle,
    LzContentStateBadge,
    ProgressCover,
    UpdatedNotification,
  },
  props: {
    card: { type: Object, required: true },
    cardId: { type: Number, required: true },
    cardType: { type: String, required: true },
    content: { type: Object, default: null },
    title: { type: String, required: true },
    activeEdit: { type: Object, default: null },
  },
  setup(props) {
    const { cardServiceFor, toggleEditing } = useAdditionalMaterialsStore()
    const { hideCard, showCard, destroyCard } = cardServiceFor({})
    const isLoading = ref(false)
    const deleteCard = () => {
      isLoading.value = true
      destroyCard(props.card)
    }
    const activeEdit = computed(() => props.activeEdit || null)
    const { canBeHidden, isHidden, isInserted, hasUpdate, isEditable } = useCardActiveEdit(activeEdit)

    const { id } = useCurrentCustomerStore()
    return {
      openModal,
      canModalShow,
      toggleEditing,
      isLoading,
      deleteCard,
      hideCard,
      showCard,
      canBeHidden,
      isHidden,
      isInserted,
      hasUpdate,
      isEditable,
      id,
    }
  },
  computed: {
    defaultIcon() {
      if (this.isTitleCard) {
        return ''
      } else if (this.isLostContent) {
        return 'unavailable-object.svg'
      } else {
        return getIcon(this.content)
      }
    },
    item() {
      return this.isLostContent || this.isTitleCard ? {} : this.content
    },
    isTitleCard() {
      return this.cardType === 'TitleCard'
    },
    isInaccessibleContent() {
      return this.content && isDraft(this.content) && this.content.customerId !== this.id
    },
    isLostContent() {
      return this.isInaccessibleContent || (!this.isTitleCard && this.content === null)
    },
    isNormalCard() {
      return !this.isLostContent && !this.isTitleCard
    },
    fromMetadata() {
      if (this.isNormalCard) {
        return this.content.fromMetadata
      }
      return null
    },
    metadata() {
      if (this.isTitleCard || this.isLostContent) {
        return {}
      }

      return {
        id: this.content.id,
        type: capitalize(this.$t(getLabel(this.content))),
      }
    },
    deleteCardModalId() {
      return `${DeleteCardModal.name}-${this.card.id}`
    },
    creationVerb() {
      return this.isHidden ? this.$t('updated_notification.hid') : this.$t('updated_notification.added')
    },
  },
  methods: {
    capitalize,
    show() {
      this.showCard(this.card)
    },
    hide() {
      this.hideCard(this.card)
    },
  },
}
</script>
