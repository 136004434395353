<template>
  <div v-if="canImpersonate || canRemove">
    <RemoveConfirmModal v-if="canModalShow(removeConfirmModalId)" v-bind="{ student, modalId: removeConfirmModalId }" />

    <ListDropdown :id="dropdownId" :button-classes="['text-sm']">
      <template #button>
        <slot name="button">
          {{ $t('common.actions') }}
          <LzIcon type="div" path="icons/caret" />
        </slot>
      </template>
      <template #listItems>
        <li v-if="canImpersonate">
          <RailsForm :action="student.startImpersonationPath" method="post" class="outline-none inline">
            <input
              :value="$t('klasses.show.impersonate')"
              type="submit"
              class="outline-none w-full cursor-pointer bg-transparent px-1.5 py-2.5 text-left text-base"
              data-test="impersonate-link"
            />
          </RailsForm>
        </li>
        <li v-if="canRemove">
          <button class="text-red-500" type="button" data-test="remove-link" @click="openModal(removeConfirmModalId)">{{
            $t('common.remove')
          }}</button>
        </li>
      </template>
    </ListDropdown>
  </div>
</template>

<script>
import { computed } from 'vue'
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import { LzIcon } from 'vue_features/shared/components/ui'
import RemoveConfirmModal from './modals/RemoveConfirmModal'
import { openModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { RailsForm } from 'vue_features/shared/components/forms'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'

export default {
  name: 'StudentEditButton',
  components: {
    ListDropdown,
    LzIcon,
    RailsForm,
    RemoveConfirmModal,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    klassIsFromClever: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { impersonated } = useCurrentUserStore()

    const canImpersonate = computed(() => !props.student.archived && !impersonated.value)
    const canRemove = computed(() => !props.klassIsFromClever || !props.student.isFromClever)

    return { openModal, canModalShow, impersonated, canRemove, canImpersonate }
  },
  computed: {
    dropdownId() {
      return `${this.student.id}-edit-dropdown`
    },
    removeConfirmModalId() {
      return `${RemoveConfirmModal.name}-${this.student.id}`
    },
  },
}
</script>
