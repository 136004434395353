import { HTTP, Routes, snakeKeys } from 'vue_features/shared/helpers/http_helper'

const SiteAdminCleverService = {
  update(params) {
    if (params.siteAdminCleverSettings.persisted) {
      return HTTP.put(Routes.site_admin_clever_path(), snakeKeys(params))
    } else {
      return HTTP.create(Routes.site_admin_clever_path(), snakeKeys(params))
    }
  },
}

export default SiteAdminCleverService
