import { reactive, computed } from 'vue'
import { fetchItemsApp, reset as resetItemsApps } from 'clients/learnosity'
import { defineStore } from 'vue_features/shared/composables/store_helpers'

export function useLearnosityConfig(config) {
  const sessionId = computed(() => config.request.session_id)
  const canRetrieveTags = computed(() => !!config.request.retrieve_tags)
  const isReviewing = computed(() => config.request.state === 'review')

  return { sessionId, canRetrieveTags, isReviewing }
}

export const useLearnosityClientStore = defineStore('learnosityClient', () => {
  const state = reactive({
    appsByConfig: {},
    fetchPromisesByConfig: {},
  })

  function fetchLearnosityClient(config) {
    const { sessionId } = useLearnosityConfig(config)
    if (!sessionId.value) {
      throw new Error('config must be a config object for one or many Learnosity items.')
    }
    const fetchPromise = state.fetchPromisesByConfig[sessionId.value]
    if (fetchPromise) return fetchPromise

    state.fetchPromisesByConfig[sessionId.value] = new Promise((resolve, reject) => {
      const app = state.appsByConfig[sessionId.value]
      if (app) {
        resolve(app)
      } else {
        fetchItemsApp(config)
          .then((app) => {
            state.appsByConfig[sessionId.value] = app
            resolve(app)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
    return state.fetchPromisesByConfig[sessionId.value]
  }

  function resetAll() {
    resetItemsApps()
    useLearnosityClientStore().$reset()
  }

  const isReset = computed(() => {
    return Object.keys(state.fetchPromisesByConfig).length === 0
  })

  const isLoaded = computed(() => {
    const promisesCount = Object.keys(state.fetchPromisesByConfig).length
    const appsCount = Object.keys(state.appsByConfig).length

    return promisesCount === appsCount
  })
  return {
    state,
    resetAll,
    isReset,
    isLoaded,
    fetchLearnosityClient,
  }
})
