<template>
  <div class="hyperlink__preview">
    <div class="hyperlink__preview-wrapper">
      <component
        v-bind="previewProps"
        :is="previewComponent"
        :key="key"
        class="absolute left-0 flex h-full w-full items-center justify-center"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { DocumentViewer, ImageResource, VideoPlayer, GeogebraPlayer } from 'vue_features/resources'
import { useCardDeckEditorStore } from '../composables/use_card_deck_editor_store'
import useFilestackAccessor from 'vue_features/shared/composables/use_filestack_accessor'
import { useResourceTypes } from 'vue_features/shared/composables'

export default {
  name: 'ResourcePreview',
  components: { GeogebraPlayer, VideoPlayer },
  setup() {
    const { contentInEdit } = useCardDeckEditorStore()
    const { isDocument, isGeogebra } = useResourceTypes(contentInEdit)

    const previewComponent = computed(() => {
      switch (contentInEdit.value.type) {
        case 'Image':
          return ImageResource
        case 'Document':
          return DocumentViewer
        case 'GeogebraItem':
          return GeogebraPlayer
        default:
          return VideoPlayer
      }
    })

    const previewProps = computed(() => {
      if (isDocument.value) {
        const { fileUrl } = useFilestackAccessor(contentInEdit.value.resourceUrl)
        return {
          baseUrl: contentInEdit.value.resourceUrl.baseUrl,
          pdfUrl: fileUrl.value,
          filestackOptions: contentInEdit.value.resourceUrl.filestackOptions,
        }
      } else {
        return contentInEdit.value
      }
    })

    const key = computed(() => {
      const item = contentInEdit.value
      if (isGeogebra.value) {
        return `${item.materialId}-${item.showToolbar}-${item.autoscale}`
      } else {
        return item.resourceUrl.baseUrl
      }
    })

    return { contentInEdit, previewComponent, previewProps, isDocument, key }
  },
}
</script>
